import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { RootState } from 'typings/RootState'
import styled from 'styled-components'
import { Button, Col } from 'antd'
import { selectFilter } from 'features/admin/user/selector'
import { Filter } from 'features/admin/user/types'
import { updateFilter, clearFilter } from 'features/admin/user/actions'
import { ModuleFilter, UserTypeFilter, UserStatusFilter } from './Components'

type Props = {
  onSearch?: () => void
  withPadding?: boolean
} & ReduxProps &
  DispatchProps

const UserFilter: React.FunctionComponent<Props> = props => {
  const { filter, onSearch = () => {}, withPadding = false } = props

  const dispatch = useDispatch()
  const onFilterChange = (filter: Filter) => {
    dispatch(updateFilter(filter))
  }

  useEffect(() => {
    dispatch(clearFilter())
    return () => {
      dispatch(clearFilter())
    }
  }, [])

  const onClick = () => {
    dispatch(updateFilter(filter))
    onSearch()
  }

  return (
    <MainPage className={withPadding ? 'with-padding' : ''}>
      <div className="filters">
        <Col span={24} className="filters-button">
          <ModuleFilter onFilterChange={onFilterChange} filter={filter} />
          <UserTypeFilter onFilterChange={onFilterChange} filter={filter} />
          <UserStatusFilter onFilterChange={onFilterChange} filter={filter} />
          <Button type="primary" onClick={onClick}>
            กรองข้อมูล
          </Button>
        </Col>
      </div>
    </MainPage>
  )
}

const MainPage = styled.div`
  &.with-padding {
    padding: 0 120px;
  }
  background-color: #f5f6fa;
`

const mapStateToProps = (state: RootState) => {
  return {
    filter: selectFilter(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(UserFilter)
