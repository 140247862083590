import React from 'react'
import imgMcWhite from 'assets/img/ic-maincon-white.png'
import imgMcBlue from 'assets/img/ic-maincon-blue.png'
import BaseIcon from './BaseIcon'

type Props = {
  active?: boolean
}

const ConstructionIcon = (props: Props) => {
  const { active = false } = props
  return (
    <BaseIcon
      active={active}
      activeIcon={imgMcBlue}
      inactiveIcon={imgMcWhite}
    />
  )
}

export default ConstructionIcon
