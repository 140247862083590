import React from 'react'
import styled from 'styled-components'
import { ContentRow } from 'Components/Shared/Table'
import { CheckedIcon, UncheckedIcon } from 'Components/Shared/Icon'
import { Data, TypeOfTask } from 'features/cmpj/zone/seemore/types'

type DefaultProps = {
  type?: string
  task: Data
  isSelected?: boolean
  onClick?: () => void
}
type Props = DefaultProps

const RowItem: React.FC<Props> = props => {
  const {
    type = TypeOfTask.TODO,
    task,
    isSelected = false,
    onClick = () => {},
  } = props

  const isComplete = task.PassedSubTasks === task.TotalSubTasks

  const getStatus = () => {
    let text = 'กำลังดำเนินการ'
    if (isComplete) {
      text = 'พร้อมตรวจด้วยตัวเอง'
    }
    return <TextGreen>{text}</TextGreen>
  }

  const getRadioBox = () => {
    return (
      <Radio
        onClick={evt => {
          evt.preventDefault()
          onClick()
        }}
      >
        {isSelected ? <CheckedIcon size={24} /> : <UncheckedIcon size={24} />}
      </Radio>
    )
  }

  const todoData = [
    {
      title: task.Unit,
      span: 7,
    },
    {
      title: task.Floor,
      span: 5,
    },
    {
      title: `${task.PassedSubTasks}/${task.TotalSubTasks}`,
      span: 5,
    },
    {
      title: getStatus(),
      span: 5,
    },
    {
      title: task.PassedSubTasks > 0 && getRadioBox(),
      span: 2,
    },
  ]

  const inproData = [
    {
      title: task.Unit,
      span: 4,
    },
    {
      title: task.Floor,
      span: 4,
    },
    {
      title: task.CheckNumber,
      span: 4,
    },
    {
      title: <TextGreen>{task.CheckNumber} ข้อ</TextGreen>,
      span: 3,
    },
    {
      title: <TextRed>{task.NumOfFail} ข้อ</TextRed>,
      span: 3,
    },
    {
      title: getRadioBox(),
      span: 2,
    },
  ]

  const content = type === TypeOfTask.TODO ? todoData : inproData

  return <ContentRow items={content} />
}

const TextGreen = styled.div`
  color: #5bc79e;
`

const TextRed = styled.div`
  color: #e36b67;
`

const Radio = styled.div`
  margin-left: 4px;
`

export default RowItem
