import React, { useState } from 'react'
import styled from 'styled-components'
import { Tag, Input } from 'antd'
import { cloneDeep } from 'lodash'

type Props = {
  emailList?: string[]
  onChange: (emails: string[]) => void
}

const EmailBox: React.FC<Props> = props => {
  const { emailList = [], onChange } = props
  const [editVal, setEditVal] = useState('')
  const [editIndex, setEditIndex] = useState(-1)
  const [addMode, setAddMode] = useState(false)
  const [addVal, setAddVal] = useState('')

  const onAddEmail = (emailTxt: string) => {
    if (emailTxt !== '') {
      emailList.push(emailTxt)
      onChange(emailList)
    }
    setAddMode(false)
  }

  const onUpdateEmail = (index: number, newValue: string) => {
    emailList[index] = newValue
    onChange(emailList)
    setEditIndex(-1)
  }

  const onRemoveEmail = (email: string, index: number) => {
    const newEmail = cloneDeep(emailList).filter(
      (item: string, eindex: number) => eindex !== index && item !== email
    )
    onChange(newEmail)
  }

  const onClickEdit = (oldValue: string, index: number) => {
    setEditVal(oldValue)
    setEditIndex(index)
    setAddMode(false)
  }

  const onClickAdd = () => {
    setAddVal('')
    setAddMode(true)
  }

  const addEmailInput = (
    <Input
      key={'input-tag-add'}
      size="small"
      className="tag-input"
      placeholder="ระบุอีเมล"
      value={addVal}
      onChange={evt => setAddVal(evt.target.value)}
      onBlur={() => setAddMode(false)}
      onPressEnter={() => onAddEmail(addVal)}
    />
  )

  const addEmailButton = (
    <Tag className="site-tag-plus" onClick={onClickAdd}>
      + เพิ่มอีเมล
    </Tag>
  )

  const editEmailInput = (index: number) => {
    return (
      <Input
        key={`input-tag-edit-${index}`}
        size="small"
        className="tag-input"
        placeholder="ระบุอีเมล"
        value={editVal}
        onChange={evt => setEditVal(evt.target.value)}
        onBlur={() => setEditIndex(-1)}
        onPressEnter={() => onUpdateEmail(index, editVal)}
        autoFocus={true}
      />
    )
  }

  const emailTag = (email: string, index: number) => {
    return (
      <Tag
        className="edit-tag"
        key={email + index}
        closable={true}
        onClose={() => onRemoveEmail(email, index)}
      >
        <span onDoubleClick={e => onClickEdit(email, index)}>{email}</span>
      </Tag>
    )
  }

  const renderEmailTags = (emails: string[]) => {
    return emails.map((email: string, index: number) => {
      return editIndex >= 0 && editIndex === index
        ? editEmailInput(index)
        : emailTag(email, index)
    })
  }

  const renderAddButton = () => (addMode ? addEmailInput : addEmailButton)

  return (
    <Box>
      {renderEmailTags(emailList)}
      {renderAddButton()}
    </Box>
  )
}

const Box = styled.div`
  width: 100%;
  height: 232px;
  border-radius: 5px;
  border: solid 1px #e4e7eb;
  background-color: #fff;
  overflow: scroll;
  padding: 12px;
  font-family: 'DB Helvethaica X 55 Regular';
  font-size: 16px;

  .ant-tag {
    border-radius: 10px;
    border: solid 1px #e4e7eb;
    background-color: #fff;
    padding: 1px 10px;
    font-size: 16px;
    margin: 5px;
  }

  .site-tag-plus {
    background-color: #003168;
    color: #fff;
  }

  .edit-tag {
    user-select: none;
  }
  .tag-input {
    width: 78px;
    border-radius: 10px;
    border: solid 1px #e4e7eb;
    background-color: #fff;
    padding: 1px 10px;
    font-size: 16px;
    margin: 5px;
  }
`
const IconDiv = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
`

const IconStyle = styled.div`
  padding: 0 9px;
  cursor: pointer;
`
export default EmailBox
