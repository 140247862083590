import React from 'react'
import { getProgressColor } from 'utils/color-map'
import { Progress } from 'antd'
import styled from 'styled-components'

interface Props {
  task: any
}

const TaskProgress: React.FC<Props> = props => {
  const { task } = props
  const taskConfig = getProgressColor(
    task.TaskStatus,
    task.Progress,
    task.LastReqProgress,
    task.LastCurProgress
  )

  return (
    <Data>
      <Progress
        percent={taskConfig.progress}
        successPercent={taskConfig.success}
        strokeColor={taskConfig.color}
        size="small"
        format={v => `${task.Progress}%`}
        className={`task-progress ${
          taskConfig.color ? '' : taskConfig.className
        }`}
      />
    </Data>
  )
}

const Data = styled.div`
  width: 100%;
  .task-progress {
    width: 100%;
    text-align: left;
    .ant-progress-outer {
      width: 80%;
    }
    .ant-progress-text {
      padding-left: 10px;
    }
  }
`

export default TaskProgress
