import React from 'react'
import styled from 'styled-components'
import iconQC from 'assets/img/ic-qc-checked.png'
import iconBySelf from 'assets/img/ic-checked-by.png'

interface Props {
  isSelfCheck?: boolean
  size?: number
  isMaincon?: boolean
}

const CheckStatusIcon = (props: Props) => {
  const { isSelfCheck = false, isMaincon = false } = props
  let title = ''
  if (isSelfCheck) {
    title = 'ตรวจด้วยตัวเอง'
    if (isMaincon) {
      title = 'จาก CM'
    }
  } else {
    title = 'จาก QC'
  }
  return (
    <Center>
      <img src={isSelfCheck ? iconBySelf : iconQC} width="32" />
      <TextBy>{title}</TextBy>
    </Center>
  )
}

const Center = styled.div`
  width: 100%;
  text-align: center;
`

const TextBy = styled.p`
  font-size: 18px;
  margin-bottom: 4px;
  font-family: 'DB Helvethaica X 55 Regular';
  color: #8b99a7;
`

export default CheckStatusIcon
