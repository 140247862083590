import withAPIAction from 'utils/withAPIAction'
import { verifyReq } from 'utils/verify'
import { APIPath } from '../../../services/APIPath'
import { FetchActivityRequest, SendActivityReq } from './types'
import {
  getActivityDetailRequest,
  getCheckListRequest,
  acceptActivity,
  sendReqToCMAsyncReq,
} from './actions'

export const getActivityById = (data: FetchActivityRequest, token: string) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<FetchActivityRequest>({
    method: 'POST',
    path: 'GetQCActivityById',
    data,
    token: `Bearer ${token}`,
  })(getActivityDetailRequest)
}

export const getQCReportById = (data: FetchActivityRequest, token: string) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<FetchActivityRequest>({
    method: 'POST',
    path: 'GetQCReportById',
    data,
    token: `Bearer ${token}`,
  })(getActivityDetailRequest)
}

export const getCheckListById = (data: FetchActivityRequest, token: string) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<FetchActivityRequest>({
    method: 'POST',
    path: 'GetMainconCheckListForQC',
    data,
    token: `Bearer ${token}`,
  })(getCheckListRequest)
}

export const acceptActivityByMC = (
  data: FetchActivityRequest,
  token: string
) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<FetchActivityRequest>({
    method: 'POST',
    path: 'AcceptActivityByMainCon',
    data,
    token: `Bearer ${token}`,
  })(acceptActivity)
}

export const sendActivityToCM = (data: SendActivityReq, token: string) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<SendActivityReq>({
    method: 'POST',
    path: 'SendActivityRequestForCM',
    data,
    token: `Bearer ${token}`,
  })(sendReqToCMAsyncReq)
}
