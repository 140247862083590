import React, { useState } from 'react'
import styled from 'styled-components'
import { PrimaryButton } from 'Components/Shared/Button'
import { Row, Col } from 'antd'
import { ConstructPlanReport } from 'features/report/constructPlan/types'

type Props = {
  report: ConstructPlanReport
  isLoading?: boolean
  onSend: () => void
}

const RejectFooter: React.ComponentType<Props> = props => {
  const { report, isLoading = false, onSend } = props

  const invalidData = () => {
    const { CheckNumber } = report
    const result = report.DefectList.find(item => {
      return (
        CheckNumber === item.CheckNumber &&
        item.PinStatus === 'ToBeFix' &&
        item.HowToFix.length === 0
      )
    })
    return result !== undefined
  }

  return (
    <>
      <Col />
      <Col>
        <Button>
          <PrimaryButton
            text={'ส่งรายงานแก้ไข'}
            disabled={isLoading || invalidData()}
            onClick={onSend}
          />
        </Button>
      </Col>
    </>
  )
}

const Footer = styled.div`
  position: fixed;
  bottom: 0px;
  height: auto;
  width: 100%;
  background-color: white;
  padding: 22px 80px;
  z-index: 20;
`

const Button = styled.div`
  width: 200px;
  margin-left: 16px;
  float: left;
`

export default RejectFooter
