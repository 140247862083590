import React, { useEffect } from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import TopNav from 'Components/Shared/TopNav'
import TokenExpired from 'Components/Pages/TokenExpired'
import UnitDashboard from 'Components/QC/Dashboard/Unit'

import QcUnitReport from 'Components/QC/Unit/Report'
import TodoList from 'Components/QC/Unit/TodoList'
import FunctionCheck from 'Components/QC/Unit/FunctionCheck'
import DocumentCheck from 'Components/QC/Unit/DocumentCheck'
import ProjectList from 'Components/QC/ProjectList'
import ImageReport from 'Components/QC/Unit/ImageReport'
import QCActivity from 'Components/QC/Unit/Activity'

type Props = {}

const QcUnitApp: React.FunctionComponent<Props> = props => {
  return (
    <>
      <TopNav title="QC" displayProject={false} secondaryTitle="ยูนิต" />
      <Switch>
        <Route exact path="/" component={UnitDashboard} />
        <Route exact path="/dashboard" component={UnitDashboard} />
        <Route exact path="/unit/dashboard" component={UnitDashboard} />
        <Route
          exact
          path="/unit/report/:project/:unit/:qctype/:requestID"
          component={QcUnitReport}
        />
        <Route exact path="/unit/todo/:project" component={TodoList} />

        <Route
          exact
          path="/unit/document/:project/:unit/:qcType/:requestID/:checkno/:qcTypeVersion"
          component={DocumentCheck}
        />
        <Route
          exact
          path="/unit/function/:project/:unit/:qcType/:requestID/:checkno/:qcTypeVersion"
          component={FunctionCheck}
        />

        <Route
          exact
          path="/unit/imagereport/:project/:unit/:qcType/:requestID/:checkno"
          component={ImageReport}
        />

        <Route
          exact
          path="/unit/activity/:project/:unit/:qcType/:requestID/:checkno/:qcTypeVersion/:activity"
          component={QCActivity}
        />

        <Route exact path="/:type/projects" component={ProjectList} />
        <Route exact path="/token-expired" component={TokenExpired} />
      </Switch>
    </>
  )
}

export default QcUnitApp
