import React from 'react'
import { Col } from 'antd'
import styled from 'styled-components'
import { TableRow } from 'Components/Shared/Table'
import { EditIcon } from 'Components/Shared/Icon'
import { Link } from 'react-router-dom'
import { Commisson } from 'features/admin/commission/types'

type DefaultProps = {
  item: Commisson
}

type Props = DefaultProps

const RowItem: React.FC<Props> = ({ item }) => {
  const path = `/check-commission/${item.CheckListID}/edit/${item.CheckListType}`
  let type = ''
  if (item.CheckListType === 1) {
    type = 'commission'
  } else if (item.CheckListType === 2) {
    type = 'safety'
  }
  return (
    <RowModel>
      <TableRow minHeight={60} spaceBetweenRow={8} paddingLeft={24}>
        <Col span={11}>
          <Link to={path}>
            <Name>{item.CheckListName}</Name>
          </Link>
        </Col>
        <Col span={4}>{type}</Col>

        <Col span={7}>
          {item.UpdatedDate} - โดย {item.UpdatedBy}
        </Col>
        <Col span={2}>
          <Icon>
            <Link to={path}>
              <EditIcon size={20} color="#8b99a7" />
            </Link>
          </Icon>
        </Col>
      </TableRow>
    </RowModel>
  )
}

const RowModel = styled.div`
  cursor: pointer;
`

const Name = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  color: #000;
  display: flex;
  align-items: center;
  .img {
    height: 40px;
    width: 40px;
    margin-right: 22px;
  }
`
const Icon = styled.div`
  float: left;
  margin-right: 20px;
  margin-top: 5px;
  cursor: pointer;
`

export default RowItem
