import React from 'react'
import { Row, Col, Slider, InputNumber } from 'antd'
import styled from 'styled-components'
import { color } from 'utils/color-map'

interface Props {
  lastProgress?: number
  originalProgress?: number
  progress?: number
  disabled?: boolean
  onChangeProgress?: (value: number) => void
}

const ProgressUpdate = (props: Props) => {
  const {
    lastProgress = 0,
    progress = 0,
    originalProgress = 0,
    disabled = false,
    onChangeProgress = () => {},
  } = props

  const sliderDiv = (
    <Slider
      min={lastProgress}
      max={100}
      value={progress}
      disabled={disabled}
      onChange={value => onProgress(value)}
    />
  )

  const onProgress = (value: any) => {
    if (value < originalProgress) {
      return
    }
    onChangeProgress(value)
  }

  const progressDiv =
    originalProgress === 0 ? (
      <ProgressZeroBar>{sliderDiv}</ProgressZeroBar>
    ) : (
      <ProgressBar>{sliderDiv}</ProgressBar>
    )

  const onEditProgress = (value: number | undefined) => {
    value = Number(value)
    if (value) {
      value = Math.floor(value)
      onProgress(value)
    }
  }

  const validateProgress = (evt: any) => {
    const code = evt.keyCode
    if ((code >= 48 && code <= 57) || code === 8) return
    evt.preventDefault()
  }

  return (
    <Row>
      <Col>
        <TextItem>ความคืบหน้า (%)</TextItem>
      </Col>
      <Col>
        <Row type="flex" align="middle">
          <Col span={21}>
            <Row>
              <Col span={12} style={{ width: `${lastProgress}%` }}>
                <Line />
              </Col>
              <Col span={12} style={{ width: `${100 - lastProgress}%` }}>
                {progressDiv}
              </Col>
            </Row>
          </Col>
          <Col span={3}>
            <ProgressInput>
              <InputNumber
                min={lastProgress}
                max={100}
                value={progress}
                disabled={disabled}
                style={css.input}
                onChange={onEditProgress}
                onKeyDown={validateProgress}
              />
              <span className="text">%</span>
            </ProgressInput>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

const css: any = {
  input: {
    fontSize: '20px',
    borderRadius: '5px',
    float: 'right',
  },
}

const Line = styled.div`
  width: 100%;
  background-color: ${color.GREEN};
  height: 8px;
  margin-top: 4px;
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
`

const ProgressZeroBar = styled.div`
  width: 100%;
  .ant-slider {
    margin: 0px;
  }

  .ant-slider > .ant-slider-rail {
    border-radius: 6px;
    height: 8px;
  }

  .ant-slider > .ant-slider:hover,
  .ant-slider-track {
    border-radius: 6px;
    background-color: ${color.YELLOW} !important;
    height: 8px;
  }

  .ant-slider-disabled .ant-slider-track {
    background-color: ${color.YELLOW} !important;
  }

  .ant-slider > .ant-slider-handle {
    width: 24px;
    height: 24px;
    margin-top: -8px;
    background-color: #fefefe;
    border: solid 2px #dddddd;
    z-index: 3;
  }
`

const ProgressBar = styled.div`
  width: 100%;
  .ant-slider {
    margin: 0px;
  }

  .ant-slider > .ant-slider-rail {
    border-radius: 0px;
    border-bottom-right-radius: 6px;
    border-top-right-radius: 6px;
    height: 8px;
  }

  .ant-slider > .ant-slider:hover,
  .ant-slider-track {
    border-radius: 0px;
    background-color: ${color.YELLOW} !important;
    border-bottom-right-radius: 6px;
    border-top-right-radius: 6px;
    height: 8px;
  }

  .ant-slider-disabled .ant-slider-track {
    background-color: ${color.YELLOW} !important;
  }

  .ant-slider > .ant-slider-handle {
    width: 24px;
    height: 24px;
    margin-top: -8px;
    background-color: #fefefe;
    border: solid 2px #dddddd;
    z-index: 3;
  }
`

const ProgressInput = styled.div`
  display: flex;
  align-items: flex-end;
  float: right;

  .ant-input-number {
    width: 60px;
    height: 48px;
    display: flex;
    align-items: center;
  }

  .ant-input-number > .ant-input-number-handler-wrap {
    display: none;
  }

  .ant-input-number > .ant-input-number-input-wrap > .ant-input-number-input {
    text-align: center;
  }

  .text {
    margin-left: 5px;
    height: 35px;
    font-size: 18px;
  }
`

const TextItem = styled.div`
  font-size: 20px;
  font-family: 'DB Helvethaica X 75 Bd';
`

export default ProgressUpdate
