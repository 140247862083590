import { put, take, takeEvery, select, call } from 'redux-saga/effects'
import { APIPath } from 'services/APIPath'
import { getType } from 'typesafe-actions'
import * as actions from './actions'
import * as uploadAction from '../../uploadfile/actions'

import { GetFloorPlanList, getItemForFloorPlan } from './api'
import { File } from '../../types/common'

import { uploadFileReq } from '../../uploadfile/api'

import APIManager, { configWithToken } from 'services/APIManager'
import {
  serviceTokenSelector,
  userEmailSelector,
} from 'features/sensitive/sensitive.selector'

function* uploadFileList(userEmail: string, token: string, img?: File) {
  if (img && img.FileID === 0) {
    const formData = new FormData()
    formData.set('UserEmail', userEmail)
    formData.append('Files', img.FileName)
    yield put(uploadFileReq(formData, token))
    const resp = yield take(uploadAction.uploadFileAsyncReq.success)
    if (resp.payload.ResponseData[0]) {
      return resp.payload.ResponseData[0]
    }
  }
  return img
}

function* sendRequest(action: ReturnType<typeof actions.updateReq.request>) {
  try {
    const Token = yield select(serviceTokenSelector)
    const UserEmail = yield select(userEmailSelector)
    const { floorPlan, ProjectCode, page } = action.payload
    const img = yield uploadFileList(UserEmail, Token, floorPlan.ImageFloorPlan)

    yield call(
      APIManager.call,
      floorPlan.FloorPlanID ? APIPath.UpdateFloorPlan : APIPath.CreateFloorPlan,
      'POST',
      {
        ...floorPlan,
        ImageFloorPlan: img,
        ProjectCode,
        UserEmail,
      },
      configWithToken(`Bearer ${Token}`)
    )
    yield put(actions.updateReq.success())
    yield put(GetFloorPlanList(page, ProjectCode))
    yield put(getItemForFloorPlan(ProjectCode))
  } catch (e) {
    yield put(actions.updateReq.failure(e))
  }
}

function* sendDeleteRequest(
  action: ReturnType<typeof actions.deleteReq.request>
) {
  try {
    const UserEmail = yield select(userEmailSelector)
    const Token = yield select(serviceTokenSelector)
    const { page, ProjectCode, FloorPlanID } = action.payload

    yield call(
      APIManager.call,
      APIPath.DeleteFloorPlan,
      'POST',
      {
        ProjectCode,
        FloorPlanID,
        UserEmail,
      },
      configWithToken(`Bearer ${Token}`)
    )
    yield put(actions.deleteReq.success())
    yield put(GetFloorPlanList(page, ProjectCode))
  } catch (error) {
    yield put(actions.deleteReq.failure(error))
  }
}

function* allSaga() {
  yield takeEvery(getType(actions.updateReq.request), sendRequest)
  yield takeEvery(getType(actions.deleteReq.request), sendDeleteRequest)
}

export default allSaga
