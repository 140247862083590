import React, { useState } from 'react'
import { Col } from 'antd'
import styled from 'styled-components'
import { DataItem } from 'features/admin/position/types'
import { TableRow } from '../../Shared/Table'
import { EditIcon, TrashIcon } from 'Components/Shared/Icon'

type Props = {
  onClick?: (evt: any, item: any) => void
  onDelete?: () => void
  item: DataItem
  selected?: boolean
  isMasterPlan?: boolean
}

const RowItem: React.FC<Props> = props => {
  const { item, selected, onDelete = () => {}, isMasterPlan = false } = props
  const onClick = (evt: any) => {
    if (props.onClick) {
      props.onClick(evt, item)
    }
  }

  return (
    <>
      <TableRow onClick={onClick} selected={selected} minHeight={60}>
        <Col lg={6}>
          <Name>{item.PositionName}</Name>
        </Col>
        <Col lg={6}>{item.SOWCode}</Col>
        {!isMasterPlan && (
          <Col lg={6}>
            {item.VendorCode} - {item.VendorName}
          </Col>
        )}

        <Col lg={6}>
          <IconDiv>
            <IconStyle>
              <EditIcon onClick={onClick} color="#8b99a7" />
            </IconStyle>
            <IconStyle>
              <TrashIcon onClick={onDelete} color="#8b99a7" />
            </IconStyle>
          </IconDiv>
        </Col>
      </TableRow>
    </>
  )
}

const Name = styled.div`
  padding-left: 25px;
  font-size: 20px;
  font-family: 'DB Helvethaica X 75 Bd';
`

const Status = styled.div`
  height: 20px;
  border-radius: 10px;
  color: white;
  text-align: center !important;
  font-size: 16px;
  padding: 5px 15px;
  font-size: 16px;
  align-items: center;
  display: inline-flex;
`

const Primavera = styled.div`
  font-size: 20px;
  p {
    margin: 0;
    line-height: 0.5;
  }
`

const IconDiv = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
`

const IconStyle = styled.div`
  padding: 0 9px;
  cursor: pointer;
`

const WarningText = styled.span`
  color: #d0494c;
`

const Highlight = styled.div`
  width: 24px;
  height: 24px;
  background-color: #4983c4;
  border-radius: 50%;
  font-size: 10px;
  font-family: 'DB Helvethaica X 75 Bd';
  color: #fff;
  justify-content: center;
  display: flex;
  align-items: center;
  text-align: center;
  margin-left: 12px;
`

export default RowItem
