import React from 'react'
import styled from 'styled-components'
import { Row, Col, Layout } from 'antd'
import { RadioBox } from 'Components/Shared/Form'
import { CheckList } from 'features/report/function/types'
import { InteriorStatus, InteriorStatusText } from 'features/types/status';

interface Props {
  data: CheckList[]
  onUpdate: (data: CheckList[]) => void
  readonly ?: boolean
}

const InteriorCheckList: React.FC<Props> = props => {
  const { data ,onUpdate , readonly = false} = props

  const interiorStatus = [
    {
      name: InteriorStatusText.No,
      value: InteriorStatus.No,
    },
    {
      name: InteriorStatusText.Have,
      value: InteriorStatus.Have,
    },
  ]

  const onSelectStatus = (id: number, value: string) => {
    data.map(item => {
      if (item.ItemID === id) {
        return (item.Status = value)
      }
    })
    if(!readonly){
      onUpdate(data)
    }
  }

  return (
    <InteriorBox>
      <TitleSecond>องค์ประกอบภายในห้องชุด</TitleSecond>
      <ContentBoxStyle>
        {data.map((item: CheckList, index) => {
          return (
            <CheckListBox key={index}>
              <Row type="flex" justify="space-between" align="middle">
                <Col>{item.Name}</Col>
                <Col span={4}>
                  <Row type="flex" justify="space-between">
                    {interiorStatus.map((status, index) => {
                      return (
                        <Col key={index}>
                          <RadioBox
                            label={status.name}
                            value={status.value}
                            isCheck={item.Status === status.value}
                            onClick={val => onSelectStatus(item.ItemID, val)}
                            spaceBtwText={16}
                            size={24}
                          />
                        </Col>
                      )
                    })}
                  </Row>
                </Col>
              </Row>
            </CheckListBox>
          )
        })}
      </ContentBoxStyle>
    </InteriorBox>
  )
}

const ContentBoxStyle = styled.div`
  margin-top: 16px;
  background-color: #fff;
  padding: 0 20px;
  border-radius: 5px;
  border: solid 1px #f2f2f2;
  font-size: 20px;
  font-family: 'DB Helvethaica X 55 Regular';
`

const TitleSecond = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 20px;
  color: #000;
`

const CheckListBox = styled.div`
  padding: 15px 0;
  border-bottom: 1px solid #f2f2f2;
  &:last-child {
    border-bottom: none;
  }
`

const InteriorBox = styled.div``

export default InteriorCheckList
