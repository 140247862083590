export const toArray = (data: string): any[] => {
  const rows = data.split('\n')
  const content = rows.map(row => {
    const line = row.split(',')
    return line
  })

  return content
}

export const getInt = (row: string[], index: number): number => {
  try {
    const val = index < row.length ? +row[index] : 0

    return val || 0
  } catch (e) {
    return 0
  }
}

export const getText = (row: string[], index: number): string => {
  try {
    const val = index < row.length ? row[index] : ''

    return val.trim()
  } catch (e) {
    return ''
  }
}

export const getScore = (row: string[]): any => {
  return {
    PassMinPoint: getInt(row, 5),
    PassMaxPoint: getInt(row, 6),
    ToFixMinPoint: getInt(row, 3),
    ToFixMaxPoint: getInt(row, 4),
    NoPassMinPoint: getInt(row, 1),
    NoPassMaxPoint: getInt(row, 2),
  }
}

export const isDuplicate = (
  name: string,
  value: string,
  list: any[] = [],
  rowIndex: number
) => {
  const item = list.find(it => it[name] === value)

  if (item !== undefined) {
    throw new Error(`Row at ${rowIndex} ${name} ${value} มีแล้ว`)
  }
}
