export const version = 'v 3.0.8'

export const baseUrl = 'https://localhost:3000/'
export const appConfig = {
  apiBaseUrl: process.env.REACT_APP_API_BASEURI || 'http://localhost:8000/',
  appEnvironment: process.env.REACT_APP_ENV || 'development',
  timezone: process.env.REACT_APP_TIMEZONE || 'Asia/Bangkok',
}

// By default 10 activities will be shown on each tasklanes
export const TASKLANE_ACTIVITY_LIMIT =
  Number(process.env.REACT_APP_TASKLANE_ACTIVITY_LIMIT) || 10

export const azureAuthConfig = {
  clientId: process.env.REACT_APP_AZURE_APP_CLIENT_ID || '',
  authority:
    process.env.REACT_APP_AZURE_APP_AUTHORITY_URL ||
    'https://login.microsoftonline.com/common',
  validateAuthority: process.env.REACT_APP_AZURE_APP_VALIDATE_AUTHORITY || true,
  postLogoutRedirectUri:
    process.env.REACT_APP_AZURE_APP_LOGOUT_REDIRECT_URI ||
    'https://sirius-api-dev.sansiri.net/api/',
  navigateToLoginRequestUrl:
    process.env.REACT_APP_AZURE_APP_NAVIGATE_LOGIN_REQUEST || false,
}

export default appConfig

export const DATE_PICKER_DATE_FORMAT = 'DD/MM/YYYY'
export const DATETIME_PICKER_DATE_FORMAT = 'DD/MM/YYYY H:mm'
export const DATE_FORMAT = 'DD/MM/YYYY'
export const SAVE_DATE_FORMAT = 'DDMMYYYY'
export const activityPerPage =
  Number(process.env.REACT_APP_ACTIVITY_PER_PAGE) || 10

export const acceptRoleList = [
  'QCI1000',
  'QCI2000',
  'QCI3000',
  'QCI3100',
  'QCI3200',
  'QCI3300',
  'QCI2100',
  'QCI2200',
]

export const moduleConfig = {
  master_plan: ['QCI3100', 'QCI3200', 'QCI3300', 'QCI2100'], //Master Plan,
  approval: ['QCI3000', 'QCI2000'],
  main_con: ['QCI3300', '??', 'QCI3000'],
  cm_pj: ['??', 'QCI3000'],
  qc: '??',
}

export const DEFAULT_SEARCH_RESULT_ITEMS_PER_PAGE = 10

export const LIMIT_FILE = {
  image: 5000000, // 5mb
  document: 10000000, // 10mb
}
