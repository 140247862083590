import { select, take, put, takeEvery } from 'redux-saga/effects'
import { getType } from 'typesafe-actions'
import * as actions from './search.actions'
import { submitSearchRequest } from './search.api'
import { getActivityFromSearchSelector } from './search.selector'
import { getWbsPathMapSelector } from 'features/wbs/wbs.selector'
import { Task } from 'features/tasklane/tasklane.types'
import { History } from 'history'
import * as loginActions from 'features/login/login.actions'
import projectHelper from 'utils/projectHelper'
import accountHelper from 'utils/account'

function* searchByKeyword(action: any) {
  try {
    const { keyword, page, rowPerPage, token, type } = action.payload

    // Redirect user to search result page if not already here
    const history: any = action.payload.history
    history.push(
      `/search-results?keyword=${keyword}&page=${page}&rowPerPage=${rowPerPage}&type=${type}`
    )

    const { email = '' } = accountHelper.getAccessToken()
    const projectCode = `${projectHelper.getProjectCode()}-MS`
    const serviceToken: string = localStorage.getItem('tokencode') || ''

    const data = {
      UserEmail: email,
      ProjectCode: projectCode,
      Wording: keyword,
      Page: page,
      RowPerPage: rowPerPage,
    }

    yield put(submitSearchRequest(data, token))
    const wbsPathMap = yield select(getWbsPathMapSelector)
    // Wait for submit request to complete
    const response = yield take(actions.searchByKeywordAsyncRequest.success)

    if (response.payload.ResponseMsg === 'Success') {
      const taskList: Task[] = yield select(getActivityFromSearchSelector)
      const list = taskList.map(item => {
        item.WbsName = wbsPathMap[item.WbsID]
        return item
      })
      yield put(actions.updateSearchTask(list))
    }
  } catch (error) {
    yield put(actions.searchByKeywordAsyncRequest.failure(error))
  }
}

function* verifyFail(action: any) {
  const { payload = {} } = action
  let { response } = payload
  if (!response) {
    response = { status: 401 }
  }

  const { status = 200 } = response
  if (status === 401) {
    yield put(loginActions.logoutUser())
  }
  yield take(actions.searchByKeywordAsyncRequest.failure)
}

function* allSaga() {
  yield takeEvery(getType(actions.performSearchRequest), searchByKeyword)
  yield takeEvery(
    getType(actions.searchByKeywordAsyncRequest.failure),
    verifyFail
  )
}
export default allSaga
