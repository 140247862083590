import React, { useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import RootState from 'typings/RootState'
import {
  selectEvent,
  selectConstrucProject,
} from 'features/qc/sitevisite/selector'
import { GetProjectsReq, CreatePlanReq } from 'features/qc/sitevisite/types'
import {
  getProjectForAuditPlan,
  createAudittPlanByQC,
} from 'features/qc/sitevisite/api'
import {
  userEmailSelector,
  serviceTokenSelector,
} from 'features/sensitive/sensitive.selector'
import { EventModal } from 'Components/Shared'
import RequestModal from '../RequestModal'

type FormProps = {
  onClose: () => void
  onSuccess: () => void
  visible?: boolean
}

type Props = ReduxProps & DispatchProps & FormProps

const CreateRequest = (props: Props) => {
  const {
    visible = false,
    onClose,
    onSuccess,
    projects,
    UserEmail,
    token,
    event,
  } = props

  const dispatch = useDispatch()
  const [enableModal, setEnableModal] = useState(false)

  useEffect(() => {
    setEnableModal(false)
    if (visible) {
      getProjectData()
    }
  }, [visible])

  const getProjectData = () => {
    const req: GetProjectsReq = {
      UserEmail: UserEmail,
      status: 'Ready',
    }
    dispatch(getProjectForAuditPlan(token, req))
  }

  const onSendRequest = (projectCode: string, date: any) => {
    setEnableModal(true)
    const req: CreatePlanReq = {
      UserEmail: UserEmail,
      ProjectCode: projectCode,
      AppointmentDate: date.format('YYYYMMDDHHmm'),
    }
    dispatch(createAudittPlanByQC(token, req))
  }

  const successCallback = () => {
    setEnableModal(false)
    getProjectData()
    onSuccess()
  }

  return (
    <>
      <RequestModal
        onClose={onClose}
        onSendRequest={onSendRequest}
        projects={projects}
        visible={visible}
        title="สร้างคำขอ - Site visit เปรียบเทียบแบบจัดสรร"
      />
      <EventModal
        event={[event]}
        enable={enableModal}
        successCalback={successCallback}
      />
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    projects: selectConstrucProject(state),
    event: selectEvent(state),
    token: serviceTokenSelector(state),
    UserEmail: userEmailSelector(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateRequest)
