import React, { useRef, useState } from 'react'
import { Col, Layout, Row } from 'antd'
import styled from 'styled-components'
import {
  ReportHeader,
  ReportLogo,
  DescItem,
  StatusTag,
} from 'Components/AdminQC/Report/Components'
import { DataDetail } from 'features/adminReport/certificate/types'
import CertificateForm from './CertificateForm'
import { ConfirmModal } from 'Components/Defect'

const { Content } = Layout

type Props = {
  data: DataDetail
  sign: any[]
  onCancelCert?: () => void
  onSaveCert?: () => void
  onUpdateSign: (index: number, data: any) => void
  date: any
  onUpdateDate: (date: number) => void
}

const ReportDetail: React.ComponentType<Props> = props => {
  const componentPrint = useRef(null)
  const [modal, setModal] = useState(false)
  const {
    data,
    onCancelCert = () => {},
    onUpdateSign,
    sign,
    onSaveCert = () => {},
    date,
    onUpdateDate,
  } = props
  const renderDescItem = (title: string, value: any) => {
    return <DescItem title={title} value={value} percentW={20} />
  }

  const isPass = data.Status === 'Pass'
  const percent = data.PercentOfFail
  const statusTxt = isPass
    ? `ผ่านเกณฑ์ต่ำกว่า ${percent}%`
    : `ไม่ผ่านเกณฑ์ต่ำกว่า ${percent}%`
  const result = (
    <Row type="flex" justify="start" align="top">
      <Col>
        <StatusTag text={statusTxt} isPass={isPass} />
      </Col>
      {!isPass && (
        <Col>
          <LinkText onClick={() => setModal(true)}>
            ส่ง Defect 6.2 กลับ PMR
          </LinkText>
        </Col>
      )}
    </Row>
  )
  const DescContent = (
    <DesBox>
      <Row type="flex">
        {renderDescItem('โครงการ', `${data.ProjectCode}-${data.ProjectNameTH}`)}
        {renderDescItem('ผู้รับเหมา', data.VendorName)}
        {renderDescItem('จำนวนโซน', `${data.NumOfCheckUnit}/${data.TotalUnit}`)}
        <DescItem title={'ผลรายงานใบรับรอง'} value={result} percentW={40} />
      </Row>
    </DesBox>
  )

  return (
    <Content className="mid-content">
      <div className="main-content activity-detail">
        <ReportHeader
          title="รายงานใบรับรองคุณภาพ"
          componentPrint={componentPrint}
          showExport={false}
          showSave={true}
          onSave={onSaveCert}
        />
        {DescContent}
        <Box ref={componentPrint}>
          <ReportLogo title="" isCenter={true} />
          <CertificateForm
            data={data}
            onUpdateSign={onUpdateSign}
            sign={sign}
            date={date}
            onUpdateDate={onUpdateDate}
          />
        </Box>
      </div>
      <ConfirmModal
        onClose={() => setModal(false)}
        onSubmit={() => {
          onCancelCert()
          setModal(false)
        }}
        title="ยืนยันส่ง Defect กลับ"
        descSecond="ยืนยันที่จะส่งรายการ Defect ขอแก้ไขภายหลัง (ข้อ 6.2) กลับไปที่ PMR ใช่หรือไม่?"
        visible={modal}
      />
    </Content>
  )
}

const Title = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 28px;
  color: #000;
`

const Box = styled.div`
  background-color: rgba(0, 49, 104, 0.05);
  border-radius: 8px;
  border: solid 1px #e4e7eb;
  color: #000;
`

const DesBox = styled.div`
  padding: 12px 24px;
  background-color: #fff;
`

const LinkText = styled.span`
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 18px;
  text-decoration: underline;
  color: #003168;
  margin-left: 12px;
  cursor: pointer;
`

export default ReportDetail
