import React from 'react'
import styled from 'styled-components'
import { CorrectIcon, InCorrectIcon } from 'Components/Shared/Icon'

interface Props {
  isCorrect: boolean
  label: string
}

const SignDescription: React.FC<Props> = props => {
  const { isCorrect, label } = props

  const renderIcon = (isCorrect: boolean) => {
    return isCorrect ? <CorrectIcon /> : <InCorrectIcon />
  }

  return (
    <CheckBox>
      {renderIcon(isCorrect)}
      <span>{label}</span>
    </CheckBox>
  )
}

const CheckBox = styled.div`
  float: left;
  margin-right: 24px;
  span {
    font-family: 'DB Helvethaica X 55 Regular';
    font-size: 18px;
    padding-left: 8px;
    color: #000;
  }
`

export default SignDescription
