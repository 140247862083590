import React from 'react'
import BaseIcon from './BaseIcon'
import img from 'assets/img/ic-check.png'

const CheckedIcon = (props: any) => {
  return (
    <BaseIcon
      img={img}
      onClick={props.onClick}
      size={props.size}
      disabled={props.disabled}
    />
  )
}

export default CheckedIcon
