import React, { useState, useEffect } from 'react'
import RootState from 'typings/RootState'
import { connect, useDispatch } from 'react-redux'
import { Row, Col, Layout } from 'antd'
import styled from 'styled-components'
import { DefectColor } from 'features/types/status'
import EventModal from 'Components/Shared/Modal/EventModal'

import {
  selectReport,
  selectDefect,
  selectCheckListQC,
  selectEvent,
} from 'features/report/zone/selector'

import { submitDefectReq, updateDefect } from 'features/report/zone/actions'

import {
  userEmailSelector,
  serviceTokenSelector,
} from 'features/sensitive/sensitive.selector'

import { selectUploadEvent } from 'features/uploadfile/selector'

import {
  ZoneReport,
  DefectItem,
  CheckListForQC,
  DefectTypeReq,
  SendDefectReq,
} from 'features/report/zone/types'

import { getDefectColor } from 'utils/color-map'

import DefectImage from '../DefectImage'
import FooterView from './FooterView'
import projectHelper from 'utils/projectHelper'

const { Content } = Layout

type Props = {
  ProjectCode: string
  RequestID: number
  readOnly?: boolean
  onClose?: () => void
  onCompeted?: () => void
} & ReduxProps

const DefectView: React.FunctionComponent<Props> = props => {
  const [enableModal, setEnableModal] = useState(false)
  const noFunc = () => {}
  const {
    Token,
    UserEmail,
    ProjectCode,
    RequestID,
    report,
    defect,
    checkList,
    readOnly = false,

    onClose = noFunc,
    onCompeted = noFunc,

    event,
    uploadEvent,
  } = props

  const moduleCode = projectHelper.getModule()

  const dispatch = useDispatch()

  const closeEnableModal = () => setEnableModal(false)
  const onSaveCompleted = () => {
    closeEnableModal()
    onCompeted()
  }

  const updateAfterImage = (file: any) => {
    defect.ImageAfter = file
    dispatch(updateDefect({ ...defect }))
  }

  const onSave = () => {
    setEnableModal(true)
    const req: SendDefectReq = {
      ProjectCode,
      UserEmail,
      Token,
      RequestID,
      CheckListID: checkList.CheckListID,
      Report: report,
      Defect: defect,
      Module: moduleCode,
    }

    dispatch(submitDefectReq(req))
  }

  const title: string = `หมุดที่ ${defect.PinNumber}`

  const defectStatus =
    defect.PinStatus === 'Pass'
      ? { color: DefectColor.PASS, text: 'ผ่าน' }
      : { color: DefectColor.TOBEFIX, text: 'ไม่ผ่าน' }

  const header = (
    <HeaderTitle>
      <Row type="flex" justify="space-between">
        <Col>
          <Title>
            {title}
            <Status style={{ backgroundColor: defectStatus.color }}>
              {defectStatus.text}
            </Status>
          </Title>
        </Col>
      </Row>
    </HeaderTitle>
  )

  const renderInfo = (title: string, value: string) => {
    return (
      <Col span={8}>
        <DisplayItem>
          <label>{title}</label>
          <p>{value ? value : '-'}</p>
        </DisplayItem>
      </Col>
    )
  }

  const pinDetail = (
    <MainData>
      <Row>
        {renderInfo('ลักษณะงานหลัก', defect.JobTypeName)}
        {renderInfo('ลักษณะงานรอง', defect.SubJobTypeName)}
        {renderInfo('รายละเอียด', defect.DetailName)}
      </Row>
      <Row>
        {renderInfo('พื้นที่', defect.LocationName)}
        {renderInfo('หมายเหตุ', defect.Remark)}
        {renderInfo('แสนสิริจัดหา', defect.IsSansiriFixed ? 'เป็น' : 'ไม่เป็น')}
      </Row>
      <Row>{renderInfo('ผู้รับเหมา', defect.VendorName)}</Row>
    </MainData>
  )

  const planImg = defect.ImageFloorPlan ? defect.ImageFloorPlan.FileURL : ''

  return (
    <>
      <EventModal
        event={[event]}
        err={[uploadEvent]}
        enable={enableModal}
        successCalback={onSaveCompleted}
        errorCalback={closeEnableModal}
      />
      <Content className="mid-content">
        <div className="main-content activity-detail">
          {header}
          {pinDetail}
          <DefectImage
            planImg={planImg}
            defect={defect}
            onAddAfterImage={image => updateAfterImage(image)}
            viewMode={true}
            canUpAfterImg={!readOnly}
          />
        </div>
      </Content>
      {!readOnly && <FooterView onCancel={onClose} onSave={onSave} />}
    </>
  )
}

const Title = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 28px;

  span {
    font-family: 'DB Helvethaica X 55 Regular';
    font-size: 16px;
    color: white;
    padding: 1px 10px;
    border-radius: 10px;
    margin-left: 12px;
  }
`

const MainData = styled.div`
  border-radius: 5px;
  border: solid 1px #eeeff2;
  background-color: white;
  padding: 24px;
`

const DisplayItem = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 20px;
  padding: 0 5px;
  p {
    font-family: 'DB Helvethaica X 55 Regular';
  }
`

const UnitInfo = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 28px;
  margin-bottom: 16px;
`

const Desc = styled.span`
  font-family: 'DB Helvethaica X 55 Regular';
  font-size: 18px;
`

const HeaderTitle = styled.div`
  margin-bottom: 10px;
`

const Status = styled.span`
  border-radius: 10px;
  padding: 2px 10px;
  font-size: 16px;
  color: white;
`

const mapStateToProps = (state: RootState) => {
  return {
    Token: serviceTokenSelector(state),
    UserEmail: userEmailSelector(state),

    report: selectReport(state),
    defect: selectDefect(state),
    checkList: selectCheckListQC(state),
    event: selectEvent(state),
    uploadEvent: selectUploadEvent(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(DefectView)
