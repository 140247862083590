import React, { useState, useEffect } from 'react'
import { useDispatch, connect } from 'react-redux'
import RootState from 'typings/RootState'
import styled from 'styled-components'
import { RouteComponentProps } from 'react-router-dom'
import { BreadcrumbLink } from 'Components/Shared/BreadCrumb/BreadCrumb'
import SubnavBar from 'Components/Shared/SubnavBar'

import { PaginationType } from 'features/types/common'

import {
  userEmailSelector,
  serviceTokenSelector,
} from 'features/sensitive/sensitive.selector'

import { getReportList, exportReportList } from 'features/adminReport/score/api'
import { GetReportListReq } from 'features/adminReport/score/types'
import {
  selectEvent,
  selectFilter,
  selectReportList,
  selectInitFilter,
} from 'features/adminReport/score/selector'

import Menu from 'Components/MenuAdmin/AdminQCMenu'

import { EventModal } from 'Components/Shared'
import Filter from 'Components/AdminQC/Report/Filter/ScoreFilter'
import { toDateText } from 'utils/datetime'
import LineChart from './LineChart'

const breadcrumbs: BreadcrumbLink[] = [
  {
    url: '/dashboard',
    title: 'Home',
    icon: '/home.png',
    active: true,
    showTitle: false,
  },
  {
    url: '/report/score',
    title: 'รายงานคะแนนเฉลี่ย',
    active: false,
    showTitle: true,
  },
]

type Props = ReduxProps & DispatchProps & RouteComponentProps

const ReportList: React.ComponentType<Props> = ({
  UserEmail,
  token,

  dataList,
  event,
  history,
  filter: currentFilter,
  initFilter,
  dataExport,
  exportEvent,
}) => {
  const dispatch = useDispatch()
  const [enableModal, setEnableModal] = useState(false)
  const [qctype, setQcType] = useState('' as string)
  const [pagination, setPagination] = useState<PaginationType>({
    Page: 1,
    RowPerPage: 10,
  })

  useEffect(() => {
    onFindReport()
  }, [pagination])

  const onFindReport = (
    isClearFilter: boolean = false,
    isExport: boolean = false
  ) => {
    setEnableModal(true)
    const filter = isClearFilter ? initFilter : currentFilter
    const req: GetReportListReq = {
      UserEmail,
      ProjectTypeID: filter.ProjectTypeID,
      StartDate: toDateText(filter.Date.StartDate || ''),
      EndDate: toDateText(filter.Date.FinishDate || ''),
      ProjectCode: filter.ProjectCode,
      CheckNumber: filter.CheckNumber,
      QCTypeID: filter.QCTypeID,
      Page: isExport ? 1 : pagination.Page,
      RowPerPage: pagination.RowPerPage,
    }

    setQcType(filter.QCTypeID)

    if (isExport) {
      dispatch(exportReportList(token, req))
    } else {
      dispatch(getReportList(token, req))
    }
  }

  const onSuccess = () => {
    setEnableModal(false)
  }

  return (
    <>
      <SubnavBar breadcrumbs={breadcrumbs} hideSearch={true} />
      <EventModal
        name="report-score"
        event={[event, exportEvent]}
        enable={enableModal}
        successCalback={onSuccess}
      />
      <Menu selected={'report.score'}>
        <MainPage>
          <Header>รายงานคะแนนเฉลี่ย</Header>
          <Filter
            onSearch={onFindReport}
            isDownLoading={exportEvent.IsLoading}
            onExport={() => onFindReport(false, true)}
            exportData={dataExport}
          />
          <LineChart datas={dataList} qcTypeID={qctype} />
        </MainPage>
      </Menu>
    </>
  )
}

const MainPage = styled.div`
  padding: 40px 45px;
  width: 100%;
`

const Header = styled.div`
  margin-bottom: 27px;
  font-size: 28px;
  line-height: 1.07;
  font-family: 'DB Helvethaica X 75 Bd';
  color: #000;
`

const mapStateToProps = (state: RootState) => {
  return {
    dataList: selectReportList(state),
    event: selectEvent(state),
    filter: selectFilter(state),
    UserEmail: userEmailSelector(state),
    token: serviceTokenSelector(state),
    initFilter: selectInitFilter(state),
    dataExport: state.adminReportScore.DataExport,
    exportEvent: state.adminReportScore.ExportEvent,
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>
const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(ReportList)
