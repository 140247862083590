import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { connect, useDispatch } from 'react-redux'
import SubnavBar from 'Components/Shared/SubnavBar'
import { Row, Col } from 'antd'
import { RouteComponentProps } from 'react-router-dom'
import RootState from 'typings/RootState'
import { BreadcrumbLink } from 'Components/Shared/BreadCrumb/BreadCrumb'
import { selectedProjectCodeSelector } from 'features/project/project.selector'
import Pagination from 'Components/Shared/Pagination'
import PageIndex from 'Components/Shared/Pagination/PageIndex'
import { LoadingIcon } from 'Components/Shared/Icon'
import { AlertError, AlertSuccess, LoadingView } from 'Components/Shared'
import { getUserProfile } from 'features/sensitive/sensitive.selector'

import { translate } from 'i18n'
import TKeys from 'i18n/translationKey'

import {
  userEmailSelector,
  serviceTokenSelector,
} from 'features/sensitive/sensitive.selector'

import {
  selectDataList,
  selectEvent,
  selectSorting,
  selectTotalRows,
} from 'features/maincon/seemore/selector'

import { selectEvent as selectCMEvent } from 'features/cmpj/activity/selector'

import { sortingTask } from 'features/maincon/seemore/actions'
import {
  getTodoActivityList,
  getProgressActivityList,
} from 'features/maincon/seemore/api'

import { GetActivityRequest } from 'features/maincon/seemore/types'
import { selectActivityFilter } from 'features/filter/selector'

import { toDateText } from 'utils/datetime'
import { mergeArray } from 'utils/format'
import ActivityFilterPage from 'Components/Filter/ActivityFilterPage'

import TableHeader from './TableHeader'
import RowItem from './RowItem'
import { getEvent } from 'features/wbs/wbs.selector'
import { useLocation } from 'react-router'
import queryString from 'query-string'
import { ActivityFilter } from 'features/filter/types'
import PrimaryButton from 'Components/Shared/Button/PrimaryButton'
import { SecondaryButton } from 'Components/Shared/Button'
import { Activity } from 'features/maincon/dashboard/types'
import ConfirmModal from 'Components/Defect/ConfirmModal'
import { TakeActivityListByCMReq } from 'features/cmpj/activity/types'
import { takeActivityListByCM } from 'features/cmpj/activity/api'

const text = {
  todo: translate(TKeys.Dashboard.header_todo),
  in_progress: translate(TKeys.Dashboard.header_in_progress),
  start_day: translate(TKeys.Dashboard.header_sort_by_recent_update),
  end_day: translate(TKeys.Dashboard.header_sort_by_last_update),
}

type Props = ReduxProps & DispatchProps & RouteComponentProps<{ type: string }>

const CmSeemore: React.ComponentType<Props> = props => {
  const {
    match,
    dataList,
    event,
    sorting,
    token,
    ProjectCode,
    UserEmail,
    totalRows,
    filter,
    wbsEvent,
    activityEvent,
    userProfile,
  } = props
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const { params } = match
  const { type } = params
  const isTodoTask = type === 'todo'
  const [isCanTakeAllActivity, setIsCantTakeAllActivity] = useState(false)
  const [totalActivityTake, setTotalActivityTake] = useState(0)
  const [selectAll, setSelectAll] = useState(false)
  const [isSelectUnitWarning, setIsSelectUnitWarning] = useState(false)
  const [takeActivityList, setTakeActivityList] = useState<Activity[]>([])
  const [isShowConfirm, setIsShowConfirm] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const dispatch = useDispatch()
  const searchParam = useLocation()
  const curModule = userProfile.RoleName as 'CM/PJ' | 'Main Con'

  useEffect(() => {
    const sortName = isTodoTask ? 'StartDate' : 'FinishDate'
    dispatch(sortingTask({ name: sortName, sort: 'asc' }))
  }, [])

  useEffect(() => {
    onGetData()
  }, [ProjectCode, dispatch])

  useEffect(() => {
    onGetData(true)
  }, [page, perPage])

  const onGetData = (isFilter: boolean = false) => {
    if (searchParam.search) {
      const param = queryString.parse(searchParam.search)
      const activityFilter: ActivityFilter = JSON.parse(
        param.search!.toString()
      )

      filter.Date = activityFilter.Date
      filter.ConstructionTypeList = activityFilter.ConstructionTypeList
      filter.UnitList = activityFilter.UnitList
      filter.FloorList = activityFilter.FloorList
      filter.LeafWbsList = activityFilter.LeafWbsList
    }

    const data: GetActivityRequest = {
      ProjectCode,
      UserEmail,
      Sort: {},
      StartDate: toDateText(filter.Date.StartDate || ''),
      FinishDate: toDateText(filter.Date.FinishDate || ''),
      ConstructionTypeList: filter.ConstructionTypeList,
      FloorList: filter.FloorList,
      UnitList: mergeArray([filter.UnitList, filter.ZoneList]),
      ZoneList: [],
      WbsList: filter.LeafWbsList,
      Page: page,
      RowPerPage: perPage,
    }

    if (isFilter) {
      data.StartDate = toDateText(filter.Date.StartDate || '')
      data.FinishDate = toDateText(filter.Date.FinishDate || '')
      data.ConstructionTypeList = filter.ConstructionTypeList
      data.FloorList = filter.FloorList
      data.UnitList = mergeArray([filter.UnitList, filter.ZoneList])
      data.WbsList = filter.LeafWbsList
    }

    if (isTodoTask) {
      dispatch(getTodoActivityList(token, data))
      setIsCantTakeAllActivity(data.UnitList.length > 0 ? true : false)
    } else {
      dispatch(getProgressActivityList(token, data))
    }
  }

  const onSort = (name: string) => {
    dispatch(sortingTask({ name }))
  }

  const onSelectAll = () => {
    if (isCanTakeAllActivity) {
      setSelectAll(!selectAll)
      setTotalActivityTake(0)
      setTakeActivityList([])
    } else {
      setIsSelectUnitWarning(true)
    }
  }

  const onSelectActivity = (item: Activity) => {
    let currentList = takeActivityList

    if (currentList.find(o => o.ActivityID === item.ActivityID)) {
      currentList = currentList.filter(o => o.ActivityID !== item.ActivityID)
    } else {
      currentList.push(item)
    }

    setTakeActivityList(currentList)
    setTotalActivityTake(currentList.length)
  }

  const onClearSelected = () => {
    setTakeActivityList([])
    setTotalActivityTake(0)
    setSelectAll(false)
    onGetData(true)
  }

  const onSaveTakeActivityList = () => {
    const data: TakeActivityListByCMReq = {
      ProjectCode,
      UserEmail,
      ActivityList: takeActivityList.map(item => {
        return item.ActivityID
      }),
      UnitList: mergeArray([filter.UnitList, filter.ZoneList]),
      TakeAll: selectAll,
      Module: curModule === 'CM/PJ' ? 'CMPJ' : 'MC',
    }
    dispatch(takeActivityListByCM(data, token))
    setIsShowConfirm(false)
    // setIsSuccess(true)
  }

  const content = dataList.map((item, index) => {
    return (
      <RowItem
        type={type}
        task={item}
        key={index}
        search={searchParam.search}
        selected={
          takeActivityList.filter(o => o.ActivityID === item.ActivityID)
            .length === 1 || selectAll
        }
        onSelect={() => onSelectActivity(item)}
      />
    )
  })

  let title = 'งานทั้งหมด'
  let breadcrumbTxt = 'งานทั้งหมด'
  switch (type) {
    case 'todo':
      title = `สิ่งที่ต้องทำ (${totalRows})`
      breadcrumbTxt = 'สิ่งที่ต้องทำ'
      break
    case 'in-progress':
      title = `${text.in_progress} (${totalRows})`
      breadcrumbTxt = text.in_progress
      break
  }

  const breadcrumbs: BreadcrumbLink[] = [
    {
      url: '/',
      title: 'Home',
      icon: '/home.png',
      active: true,
      showTitle: false,
    },
    {
      url: '/',
      title: 'บอร์ด',
      active: true,
      showTitle: true,
    },
    {
      url: '/',
      title: breadcrumbTxt,
      active: false,
      showTitle: false,
    },
  ]

  return (
    <>
      <SubnavBar breadcrumbs={breadcrumbs} hideSearch={true} />
      <ActivityFilterPage
        onSearch={() => {
          setPage(1)
          onGetData(true)
        }}
        withPadding={true}
      />
      <ConfirmModal
        onClose={() => setIsShowConfirm(false)}
        onSubmit={onSaveTakeActivityList}
        title="ยืนยันการรับงาน"
        descSecond={`จำนวนที่รับงาน${
          selectAll ? ' (รับงานทั้งหมด)' : ` (${totalActivityTake}) งาน`
        }`}
        visible={isShowConfirm}
      />
      <AlertError
        text={activityEvent.SubmitError}
        isShow={activityEvent.SubmitError !== undefined}
        callback={() => setIsShowConfirm(false)}
      />
      <AlertSuccess
        text={'รับงานสำเร็จ'}
        isShow={activityEvent.SubmitSuccess}
        callback={() => {
          onClearSelected()
        }}
      />
      <AlertError
        text={"กรุณากรองข้อมูล 'โซน' หรือ 'แปลง' ก่อนเลือกรับงานทั้งหมด"}
        isShow={!isCanTakeAllActivity && isSelectUnitWarning}
        callback={() => setIsSelectUnitWarning(false)}
      />
      <LoadingView
        isShow={
          event.isFetching || wbsEvent.IsLoading || activityEvent.IsLoading
        }
      />
      {event.isFetching && <LoadingIcon />}
      {!event.isFetching && (
        <MainPage>
          <Row>
            <Col span={12}>
              <Title>{title}</Title>
            </Col>
            <Col span={12}>
              <PageIndex
                currentPage={page}
                totalRows={totalRows}
                perPage={perPage}
                onClick={page => setPage(page)}
              />
            </Col>
          </Row>
          {isTodoTask && (
            <Row type="flex" justify="start" align="middle">
              <Col>
                <Btn>
                  <PrimaryButton
                    text={`รับงาน${
                      selectAll ? 'ทั้งหมด' : ` (${totalActivityTake}) งาน`
                    }`}
                    disabled={false}
                    onClick={() => {
                      if (totalActivityTake > 0 || selectAll)
                        setIsShowConfirm(true)
                    }}
                  />
                </Btn>
              </Col>
            </Row>
          )}
          <TableHeader
            type={type}
            onSort={name => onSort(name)}
            sorting={sorting}
            onSelectAll={onSelectAll}
            selectAll={selectAll && isCanTakeAllActivity}
          />
          {content}
          <Pagination
            perPage={perPage}
            totalRows={totalRows}
            currentPage={page}
            onClickPage={page => setPage(page)}
            onChangeNumber={number => setPerPage(number)}
          />
        </MainPage>
      )}
    </>
  )
}

const Btn = styled.div`
  margin-left: 16px;
  margin-bottom: 2rem;
`

const MainPage = styled.div`
  padding: 0 120px;
  background-color: #f5f6fa;
  width: 100vw;
  -khtml-user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  overflow-x: scroll;
`

const Title = styled.h2`
  font-size: 28px;
  font-family: 'DB Helvethaica X 75 Bd';
  height: 30px;
  margin-bottom: 47px;
`

const mapStateToProps = (state: RootState) => {
  return {
    dataList: selectDataList(state),
    totalRows: selectTotalRows(state),
    event: selectEvent(state),
    sorting: selectSorting(state),
    token: serviceTokenSelector(state),
    ProjectCode: selectedProjectCodeSelector(state),
    UserEmail: userEmailSelector(state),
    filter: selectActivityFilter(state),
    wbsEvent: getEvent(state),
    activityEvent: selectCMEvent(state),
    userProfile: getUserProfile(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {
  sortingTask,
  takeActivityListByCM,
}

type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(CmSeemore)
