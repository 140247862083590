import { CheckListState, CheckListAction, UnitCheckListItem } from './types'
import { cloneDeep } from 'lodash'

import produce from 'immer'
import { getType } from 'typesafe-actions'
import * as actions from './actions'
import { CheckListDetail } from './types'
import parseErrorMessage from 'utils/parseErrorMessage'

const eventer = {
  IsLoading: false,
  SubmitError: '',
  SubmitSuccess: false,
  SubmitType: '',
  MessageSuccess: '',
}

export const original: CheckListDetail = {
  CheckListID: 0,
  CheckListName: '',
  ProjectTypeID: 0,
  ProjectTypeName: '',
  Status: 'Ready',

  ScoreRange: {
    PassMinPoint: 90,
    PassMaxPoint: 100,
    ToFixMinPoint: 70,
    ToFixMaxPoint: 89,
    NoPassMinPoint: 0,
    NoPassMaxPoint: 69,
  },
  CriteriaList: [],
  QCTypeList: [],
  ProjectList: [],
}

export const originalFunc = {
  CheckList: [],
  ProjectTypeID: 0,
  ProjectTypeName: '',
  CheckListID: '',
  CheckListName: '',
  Status: 'Ready',
  InspectionList: [],
  WarningList: [],
}

const initialState: CheckListState = {
  CheckList: {
    Total: 0,
    List: [],
  },
  FunctionList: {
    TotalRows: 0,
    List: [],
  },
  FunctionDetail: cloneDeep(originalFunc),
  CheckListDetail: cloneDeep(original),
  QCTypeList: [],
  Event: cloneDeep(eventer),
  UnitEvent: cloneDeep(eventer),
  CheckListEvent: cloneDeep(eventer),
  UnitCheckListByCode: {},
  UnitCheckListByCodeResult: {},
}

const recheckQCType = (checklist: CheckListDetail, qcTypeList: any[]) => {
  if (checklist.QCTypeList.length !== 0 && qcTypeList.length !== 0) {
    checklist.QCTypeList = checklist.QCTypeList.map(it => {
      const qcType = qcTypeList.find(qcType => qcType.QCTypeID === it.QCTypeID)
      if (qcType) {
        it.QCTypeName = qcType.QCTypeName
      }

      return it
    })
  }

  return checklist
}

export default function(
  state: CheckListState = initialState,
  action: CheckListAction
): CheckListState {
  return produce(state, draft => {
    switch (action.type) {
      case getType(actions.updateCheckListDetail):
        draft.CheckListDetail = recheckQCType(action.payload, draft.QCTypeList)

        break

      case getType(actions.clearCheckListDetail):
        draft.CheckListDetail = cloneDeep(original)
        draft.UnitCheckListByCode = {}
        draft.UnitCheckListByCodeResult = {}
        break
      case getType(actions.clearFunctionDetail):
        draft.FunctionDetail = cloneDeep(originalFunc)
        break

      // getCheckListReq
      case getType(actions.getCheckListReq.request):
        draft.Event = cloneDeep(eventer)
        draft.Event.IsLoading = true
        draft.CheckList = {
          Total: 0,
          List: [],
        }
        break
      case getType(actions.getCheckListReq.success):
        draft.CheckList = action.payload.ResponseData
        draft.Event.IsLoading = false
        break

      case getType(actions.createUpdateCheckListDetailReq.success):
        if (action.payload) {
          draft.CheckListDetail = action.payload
        }
        draft.Event.IsLoading = false
        draft.Event.SubmitError = undefined
        draft.Event.SubmitSuccess = true
        draft.Event.MessageSuccess = 'ทำรายการสำเร็จ'
        break

      case getType(actions.updateUnitCheckListResult):
        draft.UnitCheckListByCodeResult = action.payload
        break

      case getType(actions.updateFuncDetail):
        draft.FunctionDetail = action.payload
        break

      case getType(actions.getQCTypeListReq.success):
        draft.QCTypeList = action.payload.ResponseData.List
        draft.CheckListDetail = recheckQCType(
          draft.CheckListDetail,
          draft.QCTypeList
        )
        break

      case getType(actions.getFunctionCheckListReq.success):
        if (action.payload.ResponseData.List) {
          draft.FunctionList = action.payload.ResponseData
        }
        draft.Event.IsLoading = false
        break

      case getType(actions.getFunctionCheckListDetailReq.success):
        const temp = action.payload.ResponseData
        temp.InspectionList.forEach(data => {
          if (data.Data == null) {
            data.Data = []
          } else {
            data.Data.forEach(data2 => {
              if (data2.Data == null) {
                data2.Data = []
              }
            })
          }
        })
        draft.FunctionDetail = temp
        draft.Event.IsLoading = false
        break

      case getType(actions.createUpdateFunctionReq.success):
        if (action.payload) {
          draft.FunctionDetail = action.payload
        }
        draft.Event.IsLoading = false
        draft.Event.SubmitError = undefined
        draft.Event.SubmitSuccess = true
        draft.Event.MessageSuccess = 'ทำรายการสำเร็จ'
        break

      case getType(actions.deleteFunctionReq.success):
      case getType(actions.deleteCheckListDetailReq.success):
        draft.Event.IsLoading = false
        break

      case getType(actions.deleteFunctionReq.request):
      case getType(actions.getFunctionCheckListDetailReq.request):
      case getType(actions.getFunctionCheckListReq.request):
      case getType(actions.createUpdateFunctionReq.request):
      case getType(actions.deleteCheckListDetailReq.request):
      case getType(actions.createUpdateCheckListDetailReq.request):
        draft.Event.SubmitError = undefined
        draft.Event.IsLoading = true
        draft.Event.SubmitSuccess = false
        break

      case getType(actions.deleteFunctionReq.failure):
      case getType(actions.getFunctionCheckListDetailReq.failure):
      case getType(actions.createUpdateFunctionReq.failure):
      case getType(actions.getFunctionCheckListReq.failure):
      case getType(actions.getCheckListReq.failure):
      case getType(actions.createUpdateCheckListDetailReq.failure):
      case getType(actions.deleteCheckListDetailReq.failure):
        draft.Event.IsLoading = false
        draft.Event.SubmitError = parseErrorMessage(action.payload)
        break

      case getType(actions.getUnitByUnitCheckListReq.failure):
        draft.UnitEvent.IsLoading = false
        draft.UnitEvent.SubmitError = parseErrorMessage(action.payload)
        break

      case getType(actions.getUnitByUnitCheckListReq.request):
        draft.UnitEvent = cloneDeep(eventer)
        draft.UnitEvent.IsLoading = true
        break

      case getType(actions.getUnitByUnitCheckListReq.success):
        const res: UnitCheckListItem = action.payload.ResponseData
        if (res) {
          res.ModelList = res.ModelList.filter(m => m.DirectionName !== '')
          draft.UnitCheckListByCode[res.ProjectCode] = res
          draft.UnitCheckListByCodeResult[res.ProjectCode] = {
            ProjectCode: res.ProjectCode,
            ProjectName: res.ProjectName,
            ModelList: [],
          }
        }

        draft.UnitEvent.IsLoading = false
        break

      case getType(actions.getCheckListDetailReq.request):
        draft.CheckListEvent = cloneDeep(eventer)
        draft.CheckListEvent.IsLoading = true
        break

      case getType(actions.getCheckListDetailReq.failure):
        draft.CheckListEvent.IsLoading = false
        draft.CheckListEvent.SubmitError = parseErrorMessage(action.payload)
        break

      case getType(actions.getCheckListDetailReq.success):
        if (action.payload.ResponseData.CheckListID) {
          draft.CheckListDetail = action.payload.ResponseData
        }
        draft.CheckListEvent.IsLoading = false
        break
      default:
        return state
    }
  })
}
