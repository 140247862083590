import React from 'react'
import { Col } from 'antd'
import styled from 'styled-components'
import { TableRow } from '../../../Shared/Table'
import { EditIcon, TrashIcon } from '../../../Shared/Icon'
import { Link } from 'react-router-dom'
import { Function } from 'features/checklist/unit/types'

type DefaultProps = {
  item: Function
  showDeleteModal: (item: Function) => void
}

type Props = DefaultProps

const RowItem: React.FC<Props> = props => {
  const { item, showDeleteModal } = props

  return (
    <TableRow minHeight={60} spaceBetweenRow={8}>
      <Col span={6}>
        <Link to={`/check-function/${item.CheckListID}/edit`}>
          <Name>{item.CheckListName}</Name>
        </Link>
      </Col>
      <Col span={3}>{item.ProjectTypeName}</Col>
      <Col span={4}>{item.ProjectUsedCounter}</Col>
      <Col span={3}>{item.CreatedDate}</Col>
      <Col span={4}>
        <LastUpdated>
          {item.UpdatedDate} - โดย {item.UpdatedBy}
        </LastUpdated>
      </Col>
      <Col span={4}>
        <Link to={`/check-function/${item.CheckListID}/edit`}>
          <Icon>
            <EditIcon size={20} color="#8b99a7" />
          </Icon>
        </Link>
        {item.Status.toLocaleLowerCase() !== 'used' && (
          <Icon onClick={() => showDeleteModal(item)}>
            <TrashIcon size={20} color="#8b99a7" />
          </Icon>
        )}
      </Col>
    </TableRow>
  )
}

const Name = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  margin: 0px 27px;
  color: #000;
`

const LastUpdated = styled.div`
  padding-right: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const Icon = styled.div`
  float: left;
  margin-right: 20px;
  margin-top: 5px;
  cursor: pointer;
`

export default RowItem
