import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import {
  CheckListDetail,
  WorkType,
  QCType,
} from 'features/checklist/constructRoad/types'

import ContentBox from 'Components/Shared/Card/ContentBox'
import { Row, Col } from 'antd'
import { AddList, EditDeleteButton } from 'Components/Shared/Button'
import DeleteCheckListJobModal, {
  DeleteWithCheckHandles,
} from 'Components/Shared/Modal/DeleteWithCheckModal'
import { textToLowerCase } from 'utils/format'
import { RowSelectedItem } from 'Components/AdminQC/Components'

interface Props {
  item?: CheckListDetail
  selected: number
  onSelected: (index: number) => void
  onDelete: (it: WorkType) => void
  disabled: boolean
}

const QcTypeBox: React.ComponentType<Props> = ({
  item,
  selected,
  onSelected,
  onDelete,
}) => {
  const modalRef = useRef<DeleteWithCheckHandles>(null)
  let content: JSX.Element[] = []

  const countScore = (qcType: QCType) => {
    let score = 0
    qcType.JobTypeList.forEach(job => {
      score += job.Score
    })

    return score
  }

  if (item) {
    const filterList = item.QCTypeList

    content = filterList.map((it: QCType, index: number) => {
      return (
        <RowSelectedItem
          key={index}
          title={it.QCTypeID}
          right={<span>{countScore(it)} คะแนน</span>}
          onClick={() => onSelected(index)}
          isSelected={index === selected}
        />
      )
    })
  }

  return (
    <>
      <ContentBox title="หมวดงาน" isEmpty={false} hasSearch={false}>
        <ListSection>{content}</ListSection>
      </ContentBox>
      <DeleteCheckListJobModal ref={modalRef} onOk={onDelete} />
    </>
  )
}

const ListSection = styled.div`
  margin-top: 16px;
  height: 256px;
  overflow: auto;
  padding: 0 8px;
  padding-bottom: 30px;
`

const Item = styled.div`
  font-size: 20px;
  border-bottom: 1px solid #e8ebf0;
  height: 56px;
  align-items: center;
  display: flex;
  padding: 0px 16px;
  cursor: pointer;

  &.selected {
    border-radius: 5px;
    border: solid 1px #003168;
  }

  .row-item {
    width: 100%;
  }
`

export default QcTypeBox
