import { textToUpperCase } from './format'
import { toMomentDateTime, toDateText, toTimeText } from './datetime'

export const getStatusText = (status: string) => {
  // just for release first
  switch (textToUpperCase(status)) {
    case 'INIT':
      return 'สิ่งที่ต้องทำ'
    case 'PEND':
      return 'รออนุมัติ'
    case 'APPV':
      return 'อนุมัติแล้ว'
    case 'REJT':
      return 'ไม่อนุมัติ'
    case 'INPRO':
      return 'กำลังดำเนินการ'
    default:
      return status
  }
}

export const getQCTypeVersionForToDoList = (data: any) => {
  return `${data.Unit} (${data.CheckNumber})`
}

export const getQCTypeVersion = (data: any) => {
  return `${data.QCTypeVersion || data.QCTypeName} (${data.CheckNumber})`
}

export const getQCTypeVersionForQC = (data: any) => {
  return `${data.QCTypeVersion || data.QCTypeName}`
}

export const getQCType = (data: any) => {
  return data.QCTypeVersion || data.QCTypeID
}

export const getRoadLocation = (task: any) => {
  const num =
    task.NumOfSelectLocation ||
    (task.SelectLocationList && task.SelectLocationList.length) ||
    0
  const textLocation =
    num === 0
      ? 'ยังไม่เลือกพื้นที่'
      : num === task.TotalLocation
      ? 'พื้นที่ทั้งหมด'
      : `พื้นที่บางส่วน (${num})`
  return textLocation
}

export const getDateTimeText = (dateString: string) => {
  const appointmentDate = toMomentDateTime(dateString)
  const date = toDateText(appointmentDate)
  const time = toTimeText(appointmentDate)
  return `${date} เวลา  ${time}`
}

export const getDateText = (dateString: string) => {
  if (!dateString) return dateString
  const appointmentDate = toMomentDateTime(dateString)
  const date = toDateText(appointmentDate)
  return `${date}`
}

export const hasText = (data: string, searchTxt: string) => {
  return data.toLowerCase().includes(searchTxt.toLowerCase())
}

export default {
  getRoadLocation,
}
