import { createStandardAction, createAsyncAction } from 'typesafe-actions'
import { GetZoneDashboardReq, GetActivityApiResponse, Filter } from './types'

export const getZoneDashbaordAction = createAsyncAction(
  'cmpj/zone/dashboard/FETCH_ZONE_DASHBOARD_REQUEST',
  'cmpj/zone/dashboard/FETCH_ZONE_DASHBOARD_SUCCESS',
  'cmpj/zone/dashboard/FETCH_ZONE_DASHBOARD_FAILURE'
)<GetZoneDashboardReq, GetActivityApiResponse, any>()

export const sortingDashboard = createStandardAction(
  'cmpj/zone/dashboard/SORTING'
)<string>()

export const updateFilter = createStandardAction(
  'cmpj/zone/CONFIG_FILTER_DASHBOARD'
)<Filter>()

export const clearFilter = createStandardAction(
  'cmpj/zone/CLEAR_FILTER_DASHBOARD'
)<void>()
