import React from 'react'
import styled from 'styled-components'

import PinView from './PinView'

type Props = {
  image: any
  pin: any
  hasMargin?: boolean
}

const ImageFloorplan: React.ComponentType<Props> = props => {
  const { image, hasMargin = true, pin } = props
  const imageUrl = image.FileURL || ''

  return (
    <ImgCardBox className={hasMargin ? 'margin' : ''}>
      <ImgCard>
        <PinView floorImage={imageUrl} pin={pin} />
      </ImgCard>
    </ImgCardBox>
  )
}

const ImgCardBox = styled.div`
  height: auto;
  background-color: #fff;
  border: 1px solid #e4e7eb;
  border-radius: 4px;
  @media print {
    margin: 6px;
  }
  &.margin {
    margin: 6px;
  }
`
const ImgCard2 = styled.img`
  width: 100%;
  height: 241px;
  object-fit: cover;
  @media print {
    height: 150px;
  }
`

const ImgCard = styled.div`
  height: 241px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 100%;
    object-fit: cover;
  }
  @media print {
    height: 150px;
  }
`

export default ImageFloorplan
