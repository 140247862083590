import React, { useState } from 'react'
import { Row, Col } from 'antd'
import styled from 'styled-components'
import SingleImgPicker from './SingleImgPicker'
import PinView from './PinView'
import ThumbnailDisplay from 'Components/Shared/ThumbnailDisplay'

type Props = {
  planImg: any
  onAddBeforeImage?: (image: any) => void
  onAddAfterImage?: (image: any) => void
  onEditPin?: () => void
  viewMode?: boolean
  pin: any
  floor: number
  canUpAfterImg?: boolean
}

const AddDefectImage: React.FunctionComponent<Props> = props => {
  const {
    planImg,
    onEditPin,
    onAddBeforeImage = (image: any) => {},
    onAddAfterImage = (image: any) => {},
    viewMode = false,
    pin,
    floor,
    canUpAfterImg = true,
  } = props
  const afterImg: any = pin.ImageAfter
  const beforeImg: any = pin.ImageBefore
  const [previewImg, setPreviewImg] = useState(false)
  const [previewType, setPreviewType] = useState('' as 'before' | 'after')
  const afterImgViewMode = viewMode && !canUpAfterImg

  const floorDesc = (
    <Col>
      <Desc>ชั้น {floor}</Desc>
    </Col>
  )
  const pinDesc = (
    <Row type="flex" justify="space-between">
      <Col>
        <Desc>ลากตำแหน่งหมุดไปยังจุดที่ต้องการ</Desc>
      </Col>
      {floorDesc}
    </Row>
  )

  const planBoxH = viewMode ? '240px' : '210px'

  const isAfterImg = previewType === 'after'
  const imageSelect = isAfterImg ? afterImg : beforeImg
  const readonly = isAfterImg ? afterImgViewMode : viewMode
  const thumnailPreview = (
    <ThumbnailDisplay
      onClose={() => setPreviewImg(false)}
      readOnly={readonly}
      images={[imageSelect]}
      index={0}
      onChange={images => {
        const newImage = images[0] || undefined
        if (isAfterImg) {
          onAddAfterImage(newImage)
        } else {
          onAddBeforeImage(newImage)
        }
      }}
    />
  )

  return (
    <ImageData>
      <Row type="flex" justify="space-around">
        <Col span={8}>
          <Box>
            <Row type="flex" justify="space-between">
              <Col>
                <Header>ตำแหน่ง</Header>
              </Col>
              {viewMode && floorDesc}
            </Row>
            {!viewMode && pinDesc}
            <PlanBox onClick={onEditPin} style={{ height: planBoxH }}>
              <PinView floorImage={planImg} pin={pin} />
            </PlanBox>
          </Box>
        </Col>
        <Col span={8}>
          <SingleImgPicker
            title="Before"
            onChange={onAddBeforeImage}
            Image={beforeImg}
            viewMode={viewMode}
            onThumbnailOpen={images => {
              if (images) {
                setPreviewImg(true)
                setPreviewType('before')
              }
            }}
          />
        </Col>
        <Col span={8}>
          <SingleImgPicker
            title="After"
            onChange={onAddAfterImage}
            Image={afterImg}
            viewMode={afterImgViewMode}
            onThumbnailOpen={images => {
              if (images) {
                setPreviewImg(true)
                setPreviewType('after')
              }
            }}
          />
        </Col>
      </Row>
      {previewImg && thumnailPreview}
    </ImageData>
  )
}

const ImageData = styled.div`
  border-radius: 5px;
  border: solid 1px #eeeff2;
  background-color: white;
  margin-top: 24px;
  padding: 5px;
`
const Box = styled.div`
  padding: 15px;
  width: 100%;
`
const PlanBox = styled.div`
  margin-top: 10px;
  padding: 8px;
  height: 240px;
  border-radius: 3px;
  border: solid 1px #b9c6d3;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 100%;
  }
`
const Header = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 20px;
`

const Desc = styled.div`
  font-family: 'DB Helvethaica X 55 Regular';
  font-size: 20px;
`
export default AddDefectImage
