import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { connect, useDispatch } from 'react-redux'
import { Row, Col } from 'antd'
import RootState from 'typings/RootState'
import { CloseIcon } from 'Components/Shared/Icon'
import PrimaryButton from 'Components/Shared/Button/PrimaryButton'
import { InputText } from 'Components/Shared/Input'
import { DataItem, Squad } from 'features/admin/team/types'
import SquadBox from './SquadBox'
import { cloneDeep } from 'lodash'

type Props = ReduxProps &
  DispatchProps & {
    item: DataItem
    onSave?: (item: DataItem) => void
    onClose: () => void
    isUpdateMode?: boolean
  }

const AdminDetail: React.ComponentType<Props> = props => {
  const { item, isUpdateMode = false } = props
  const [dataItem, setdataItem] = useState({} as DataItem)

  useEffect(() => {
    const newItem = cloneDeep(item)
    newItem.SquadList = modifieldList(newItem.SquadList)
    setdataItem(newItem)
  }, [item])

  const onAddSquad = (item: Squad) => {
    dataItem.SquadList.push(item)
    setdataItem({ ...dataItem })
  }

  const onUpdateSquad = (item: Squad) => {
    dataItem.SquadList = dataItem.SquadList.map(squad => {
      if (
        squad.SquadID === item.SquadID &&
        squad.SquadName === item.SquadName
      ) {
        return item
      } else {
        return squad
      }
    })
    setdataItem({ ...dataItem })
  }

  const onDeleteSquad = (item: Squad) => {
    dataItem.SquadList.map(squad => {
      if (
        squad.SquadID === item.SquadID &&
        squad.SquadName === item.SquadName
      ) {
        return item
      } else {
        return squad
      }
    })
    dataItem.SquadList = filterData(dataItem.SquadList)
    setdataItem({ ...dataItem })
  }

  const filterData = (squadList: Squad[]) => {
    return squadList.filter(
      item => !(item.SquadID === 0 && item.DeleteFlag === 'Y')
    )
  }

  const onSave = () => {
    if (props.onSave) {
      props.onSave(dataItem)
    }
  }

  const modifieldList = (squadList: Squad[]) => {
    return (
      squadList &&
      squadList.map(item => {
        item.DeleteFlag = 'N'
        return item
      })
    )
  }

  return (
    <MainPage>
      <Header>
        <CloseIcon color="#8b99a7" size={24} onClick={props.onClose} />
      </Header>
      <Row>
        <Col span={24}>
          <Title>Tribe</Title>
        </Col>
        <Col span={24}>
          <BodyArea>
            <InputText
              prefix="ชื่อ"
              value={dataItem.TeamName}
              onChange={(val: string) => {
                dataItem.TeamName = val
                setdataItem({ ...dataItem })
              }}
              placeholder="ระบุชื่อ"
            />
          </BodyArea>
        </Col>
        <Col span={24}>
          <Title>Squad</Title>
        </Col>
        <Col span={24}>
          <SquadBox
            squadList={dataItem.SquadList}
            onAddSquad={onAddSquad}
            onUpdateSquad={onUpdateSquad}
            onDeleteSquad={onDeleteSquad}
          />
        </Col>
      </Row>
      <Row type="flex" justify="end">
        <SaveArea>
          <PrimaryButton onClick={onSave} text="บันทึก" />
        </SaveArea>
      </Row>
    </MainPage>
  )
}

const MainPage = styled.div`
  -khtml-user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  overflow-x: scroll;
`

const Header = styled.div`
  text-align: right;
  height: 40px;

  svg {
    cursor: pointer;
  }
`

const Title = styled.div`
  color: black;
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 28px;
  margin-bottom: 14px;
`

const Text = styled.div`
  color: black;
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 20px;
`

const SaveArea = styled.div`
  margin-top: 32px;
  float: right;
  width: 160px;
`

const BodyArea = styled.div`
  font-family: 'DB Helvethaica X 55 Regular';
  span {
    font-size: 20px;
  }
  .ant-dropdown-trigger {
    margin-bottom: 12px;
  }
  .input-title {
    margin-bottom: 12px;
  }
`

const mapStateToProps = (state: RootState) => {
  return {}
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminDetail)
