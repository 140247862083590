import React, { useState, useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { BreadcrumbLink } from 'Components/Shared/BreadCrumb/BreadCrumb'

import Activity from '../../Activity'
type FormProps = {}

type Props = FormProps &
  RouteComponentProps<{
    project: string
    unit: string
    qcType: string
    requestID: string
    checkno: string
    qcTypeVersion: string
    activity: string
  }>

const QCActivity = (props: Props) => {
  const { match } = props

  const {
    project,
    unit,
    qcType,
    requestID,
    checkno,
    qcTypeVersion,
    activity,
  } = match.params

  const link = `${project}/${unit}/${qcType}/${requestID}/${checkno}/${qcTypeVersion}`
  const breadcrumbs: BreadcrumbLink[] = [
    {
      url: '/unit/dashboard',
      title: 'Home',
      icon: '/home.png',
      active: true,
      showTitle: false,
    },
    {
      url: '/unit/dashboard',
      title: 'บอร์ด',
      active: true,
      showTitle: true,
    },
    {
      url: `/unit/document/${link}`,
      title: 'ตรวจเอกสาร',
      active: true,
      showTitle: true,
    },
  ]

  return (
    <Activity
      breadcrumbs={breadcrumbs}
      ProjectCode={project}
      ActivityID={activity}
    />
  )
}

export default QCActivity
