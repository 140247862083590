import { ActionType } from 'typesafe-actions'
import { Moment } from 'moment-timezone'
import * as actions from './actions'
import { Event } from 'features/types/event'

export type DashboardAction = ActionType<typeof actions>

export interface StoreState {
  data: DashboardData
  originalData: DashboardData
  event: Event
  query: DashboardQuery
  sorting: any
  filter: Filter
}

export type Todo = {
  RequestID: number
  Unit: string
  Zone: string
  QCTypeID: string
  QCTypeName: string
  QCTypeVersion: string
  Floor: string
  State: string
  Status: string
  PassedSubTasks: number
  TotalSubTasks: number
  CheckNumber: number
}

export type InProgress = {
  RequestID: number
  Unit: string
  Zone: string
  QCTypeID: string
  QCTypeName: string
  QCTypeVersion: string
  Floor: string
  FloorPlanName: string
  CheckNumber: number
  State: string
  Status: string
  NumOfPass: number
  NumOfFail: number
}

export type QCChecking = {
  RequestID: number
  Unit: string
  Zone: string
  QCTypeID: string
  QCTypeName: string
  QCTypeVersion: string
  CheckNumber: number
  Floor: string
  FloorPlanName: string
  State: string
  Status: string
  RequestDate: string
  AppointmentDate: string
}

export type FixFromQC = {
  RequestID: number
  Unit: string
  Zone: string
  QCTypeID: string
  QCTypeName: string
  QCTypeVersion: string
  Floor: string
  FloorPlanName: string
  CheckNumber: number
  State: string
  Status: string
  NumOfPass: number
  NumOfFail: number
  LastUpdatedDate: string
  LastCheckedBy: string
  LastCheckedModule: string
}

export interface GetZoneDashboardReq {
  ProjectCode: string
  UserEmail: string
  FloorList: string[]
  UnitList: string[]
  WbsList: number[]
}

export interface GetActivityApiResponse extends GetApiResponse<DashboardData> {}

export interface GetApiResponse<T> {
  ResponseData: T
  ResponseCode: string
  ResponseMsg: 'Success' | 'Error'
  RequestTime: string
  Responsetime: string
}

export type DashboardData = {
  TotalToDo: number
  ToDo: Todo[]
  TotalInProgress: number
  InProgress: InProgress[]
  TotalQCChecking: number
  QCChecking: QCChecking[]
  TotalFixFromQC: number
  FixFromQC: FixFromQC[]
}

export type DashboardEvent = {
  isFetching: boolean
  error: string
}
export type Filter = {
  ZoneList: string[]
  FloorList: string[]
  WbsList: number[]
  LeafWbsList: number[]
}

export type DashboardFilter = {}

export type DashboardQuery = {
  date: {
    start: undefined | Moment
    end: undefined | Moment
  }
}

export enum TypeOfTask {
  TODO = 'todo',
  InProgress = 'inProgress',
  QCChecking = 'qcChecking',
  FixFromQC = 'fixFromQC',
}
