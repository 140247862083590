import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { RootState } from 'typings/RootState'
import styled from 'styled-components'

import { getProjectType, getQCTypeList } from 'features/filter/api'
import { GetProjectTypeReq, GetQCTypeListReq } from 'features/filter/types'
import {
  selectProjectType,
  selectUnitList,
  selectQCTypeList,
} from 'features/filter/selector'
import { Col, Row } from 'antd'
import { selectedProjectCodeSelector } from 'features/project/project.selector'
import {
  serviceTokenSelector,
  userEmailSelector,
} from 'features/sensitive/sensitive.selector'
import { DropdownList } from 'Components/Shared/Form'
import { selectFilter } from 'features/adminReport/monthly/selector'
import { Filter } from 'features/adminReport/monthly/types'
import { updateFilter, clearFilter } from 'features/adminReport/monthly/actions'
import { ProjectTypeFilter, DateFilter, ButtonFilter } from './Components'
import { getDataList as getTeamList } from 'features/admin/team/api'
import { GetDataListReq as GetTeamListReq } from 'features/admin/team/types'
import { selectDataList as selectTeamList } from 'features/admin/team/selector'

type Props = {
  onSearch?: (isClear?: boolean) => void
  withPadding?: boolean
  onExport?: () => void
  exportData?: any[]
  isDownLoading?: boolean
} & ReduxProps &
  DispatchProps

const MonthlyFilter: React.FunctionComponent<Props> = props => {
  const {
    ProjectCode,
    UserEmail,
    token,
    filter,
    onSearch = () => {},
    withPadding = false,
    projectType,
    qctypeList,
    teamList,
    onExport,
    exportData,
    isDownLoading,
  } = props

  useEffect(() => {
    dispatch(clearFilter())
    onGetProjectType()
    onGetQCTypeList()
    onGetTeamList()
    return () => {
      dispatch(clearFilter())
    }
  }, [ProjectCode, UserEmail])

  const onGetProjectType = () => {
    const req: GetProjectTypeReq = {
      UserEmail,
      Page: 1,
      RowPerPage: 100,
    }
    dispatch(getProjectType(token, req))
  }

  const onGetQCTypeList = () => {
    const req: GetQCTypeListReq = {
      UserEmail,
      UnitType: 'Unit',
      Status: 'Active',
    }
    dispatch(getQCTypeList(token, req))
  }

  const onGetTeamList = () => {
    const req: GetTeamListReq = {
      UserEmail,
    }
    dispatch(getTeamList(token, req))
  }

  const getSquads = (teamID: number) => {
    const team = teamList.List.find(item => item.TeamID === teamID)
    if (!team) return []
    return team.SquadList.map((item, index) => {
      if (index === 0) {
        return {
          name: `ทั้งหมด`,
          value: index,
        }
      }
      return {
        name: item.SquadName,
        value: item.SquadID,
      }
    })
  }

  const dispatch = useDispatch()
  const onFilterChange = (filter: Filter) => {
    dispatch(updateFilter(filter))
  }

  const onClearSearch = () => {
    dispatch(clearFilter())
    onSearch(true)
  }

  const isFilter = () => {
    return (
      filter.TeamID > 0 ||
      filter.SquadID > 0 ||
      filter.QCTypeID !== '' ||
      filter.Date.Mode !== '' ||
      filter.ProjectTypeID !== 0
    )
  }

  const renderQcType = () => {
    return qctypeList.List.map(item => {
      return {
        name: item.QCTypeName,
        value: item.QCTypeID,
      }
    })
  }

  const renderTibe = () => {
    return teamList.List.map((item, index) => {
      if (index === 0) {
        return {
          name: `ทั้งหมด`,
          value: index,
        }
      }
      return {
        name: item.TeamName,
        value: item.TeamID || 0,
      }
    })
  }

  return (
    <MainPage className={withPadding ? 'with-padding' : ''}>
      <Row type="flex" justify="start">
        <Col span={8}>
          <ProjectTypeFilter
            filter={filter}
            onFilterChange={onFilterChange}
            projectType={projectType}
          />
        </Col>
        <Col span={8}>
          <Box>
            <DropdownList
              placeholder="ทั้งหมด"
              label="Tribe"
              value={filter.TeamID}
              menu={renderTibe()}
              onChange={(val: any) => {
                filter.TeamID = val
                filter.SquadID = 0
                onFilterChange(filter)
              }}
            />
          </Box>
        </Col>
        <Col span={8}>
          <Box>
            <DropdownList
              placeholder="ทั้งหมด"
              label="Squad"
              disabled={filter.TeamID <= 0}
              value={filter.SquadID}
              menu={getSquads(filter.TeamID)}
              onChange={(val: any) => {
                filter.SquadID = val
                onFilterChange(filter)
              }}
            />
          </Box>
        </Col>
        <Col span={8}>
          <Box>
            <DropdownList
              placeholder="เลือกหมวดงาน"
              label="หมวดงาน"
              value={filter.QCTypeID}
              menu={renderQcType()}
              onChange={(val: any) => {
                filter.QCTypeID = val
                onFilterChange(filter)
              }}
            />
          </Box>
        </Col>
        <Col span={8}>
          <DateFilter filter={filter} onFilterChange={onFilterChange} />
        </Col>
        <Col span={8}>
          <ButtonFilter
            onClearSearch={onClearSearch}
            onSearch={onSearch}
            isFilter={isFilter()}
            onExport={onExport}
            isDownLoading={isDownLoading}
            exportData={exportData}
            fileName="Monthly report"
          />
        </Col>
      </Row>
    </MainPage>
  )
}

const MainPage = styled.div`
  margin-bottom: 24px;
  &.with-padding {
    padding: 0 120px;
  }

  background-color: #f5f6fa;
`

const Box = styled.div`
  padding: 0 12px 12px 0;
`

const mapStateToProps = (state: RootState) => {
  return {
    ProjectCode: selectedProjectCodeSelector(state),
    UserEmail: userEmailSelector(state),
    token: serviceTokenSelector(state),
    filter: selectFilter(state),
    unitList: selectUnitList(state),
    qctypeList: selectQCTypeList(state),
    projectType: selectProjectType(state),
    teamList: selectTeamList(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(MonthlyFilter)
