import React from 'react'
import { HeaderRow } from 'Components/Shared/Table'
import { TypeOfTask } from 'features/cmpj/zone/seemore/types'
import { RadioBox } from 'Components/Shared/Form'

type Props = {
  onSort(name: string): void
  sorting: any
  showRadio?: boolean
  isSelectAll?: boolean
  onSelectAll?: () => void
}

const TableHeader: React.FunctionComponent<Props> = props => {
  const {
    onSort,
    sorting,
    isSelectAll = false,
    onSelectAll = () => {},
    showRadio = false,
  } = props

  const getRadioBox = () => {
    return (
      <RadioBox
        value={''}
        label={''}
        isCheck={isSelectAll}
        onClick={value => onSelectAll()}
        size={24}
      />
    )
  }

  const headerItems = [
    {
      title: 'พื้นที่',
      span: 7,
      key: 'Unit',
    },
    {
      title: 'ชื่อหมวดงาน',
      span: 5,
      key: 'QCTypeName',
    },
    {
      title: 'ตรวจครั้งที่',
      span: 5,
      key: 'CheckNumber',
    },
    {
      title: 'ความคืบหน้า',
      span: 5,
      key: 'Progress',
    },
    {
      title: showRadio && getRadioBox(),
      span: 2,
      key: '',
      showSort: false,
    },
  ]

  return (
    <HeaderRow
      onSort={key => onSort(key)}
      sorting={sorting}
      items={headerItems}
    />
  )
}

export default TableHeader
