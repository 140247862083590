import withAPIAction from 'utils/withAPIAction'
import { verifyReq } from 'utils/verify'
import {
  GetCheckingUnitAction,
  AcceptTaskAction,
  RejectTaskAction,
} from './actions'

import {
  GetCheckingUnitReq,
  AcceptCheckingTaskReq,
  RejectCheckingTaskReq,
} from './types'

export const GetCheckingUnitByQC = (
  token: string,
  data: GetCheckingUnitReq
) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<GetCheckingUnitReq>({
    method: 'POST',
    path: 'GetCheckingUnitByQC',
    data,
    token: `Bearer ${token}`,
  })(GetCheckingUnitAction)
}

export const AcceptCheckingTaskByQC = (
  token: string,
  data: AcceptCheckingTaskReq
) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<AcceptCheckingTaskReq>({
    method: 'POST',
    path: 'AcceptCheckingTaskByQC',
    data,
    token: `Bearer ${token}`,
  })(AcceptTaskAction)
}

export const RejectCheckingTaskByQC = (
  token: string,
  data: RejectCheckingTaskReq
) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<RejectCheckingTaskReq>({
    method: 'POST',
    path: 'RejectCheckingTaskByQC',
    data,
    token: `Bearer ${token}`,
  })(RejectTaskAction)
}
