import React, { useState } from 'react'
import { TableHeader as Table, TableColHeader } from 'Components/Shared/Table'
import { CheckedIcon, UncheckedIcon } from 'Components/Shared/Icon'

type Props = {
  onSort(name: string): void
  sorting: any
  showRadio?: boolean
  isSelectAll?: boolean
  onSelectAll?: () => void
  type?: 'unit' | 'zone' | 'commissioning' | 'road'
}

const TableHeader: React.FunctionComponent<Props> = props => {
  const {
    onSort,
    sorting,
    isSelectAll = false,
    onSelectAll,
    showRadio = false,
    type = 'unit',
  } = props

  const getType = (colName: string) => {
    if (sorting.name === colName) {
      return sorting.type
    }
    return ''
  }

  const getRadioBox = () => {
    return (
      <div onClick={() => onSelectAll && onSelectAll()}>
        {isSelectAll ? <CheckedIcon size={24} /> : <UncheckedIcon size={24} />}
      </div>
    )
  }

  const unitHeader = [
    {
      title: 'ยูนิต',
      span: 3,
      key: 'Unit',
    },
    {
      title: 'หมวดงาน',
      span: 3,
      key: 'CheckNumber',
    },
    {
      title: 'ModelCode',
      span: 5,
      key: 'ModelCode',
    },
    {
      title: 'วันที่ CM ส่งคำขอตรวจ',
      span: 5,
      key: 'RequestDate',
    },
    {
      title: 'การประเมินคะแนน',
      span: 5,
      key: 'IsInspection',
    },
    {
      title: showRadio && getRadioBox(),
      span: 3,
      key: '',
      showSort: false,
    },
  ]

  const zoneHeader = [
    {
      title: 'โซน',
      span: 7,
      key: 'Unit',
    },
    {
      title: 'ชั้น',
      span: 5,
      key: 'Floor',
    },
    {
      title: 'ตรวจครั้งที่',
      span: 5,
      key: 'CheckNumber',
    },
    {
      title: 'วันที่ CM ส่งคำขอตรวจ',
      span: 5,
      key: 'RequestDate',
    },
    {
      title: showRadio && getRadioBox(),
      span: 2,
      key: '',
      showSort: false,
    },
  ]

  const commissioningHeader = [
    {
      title: 'ระบบ',
      span: 7,
      key: 'ActivityName',
    },
    {
      title: 'จำนวนรายการ',
      span: 5,
      key: 'NumOfCheckList',
    },
    {
      title: 'หมวดงาน',
      span: 5,
      key: 'CheckNumber',
    },
    {
      title: 'วันที่ CM ส่งคำขอตรวจ',
      span: 5,
      key: 'RequestDate',
    },
    {
      title: showRadio && getRadioBox(),
      span: 2,
      key: '',
      showSort: false,
    },
  ]

  const roadConstructHeader = [
    {
      title: 'ระบบ',
      span: 8,
      key: 'Unit',
    },
    {
      title: 'หมวดงาน',
      span: 8,
      key: 'CheckNumber',
    },
    {
      title: 'วันที่ CM ส่งคำขอตรวจ',
      span: 6,
      key: 'RequestDate',
    },
    {
      title: showRadio && getRadioBox(),
      span: 2,
      key: '',
      showSort: false,
    },
  ]

  const renderColumn = (column: any[]) => {
    return column.map((item: any, index: number) => {
      return (
        <TableColHeader
          key={index}
          offset={item.offset}
          span={item.span}
          onSort={() => onSort(item.key)}
          type={getType(item.key)}
          sort={item.showSort}
        >
          {item.title}
        </TableColHeader>
      )
    })
  }

  const renderHeader = () => {
    switch (type) {
      case 'unit':
        return renderColumn(unitHeader)
      case 'zone':
        return renderColumn(zoneHeader)
      case 'commissioning':
        return renderColumn(commissioningHeader)
      case 'road':
        return renderColumn(roadConstructHeader)
      default:
        return renderColumn(unitHeader)
    }
  }

  return <Table paddingLeft={24}>{renderHeader()}</Table>
}

export default TableHeader
