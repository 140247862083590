import { UnitStatus } from 'features/types/status'

export interface StatusMeta {
  status: string
}

const unitStatusMap = new Map<string, StatusMeta>()

unitStatusMap.set(UnitStatus.Pass, {
  status: 'ผ่าน',
})

unitStatusMap.set(UnitStatus.PassToFix, {
  status: 'ผ่านแก้ไข',
})

unitStatusMap.set(UnitStatus.PassByFunc, {
  status: 'ผ่าน function',
})

unitStatusMap.set(UnitStatus.NotPass, {
  status: 'ไม่ผ่าน',
})

unitStatusMap.set(UnitStatus.NotPassByFunction, {
  status: 'ไม่ผ่าน function',
})

unitStatusMap.set(UnitStatus.NotPassByRequireDefect, {
  status: 'ไม่ผ่านเงื่อนไข',
})

unitStatusMap.set(UnitStatus.NotInspection, {
  status: 'ไม่ประเมินคะแนน',
})

export const mapUnitStatus = (checkStatus: UnitStatus | string): StatusMeta => {
  const taskMeta = unitStatusMap.get(checkStatus) as StatusMeta
  const status = taskMeta || unitStatusMap.get(UnitStatus.NotPass)
  return status
}

export const unitStatusIsPass = (
  isSelfCheck: boolean,
  CheckStatus: UnitStatus | string,
  DocStatus?: UnitStatus
): boolean => {
  let isPass = false
  if (isSelfCheck) {
    isPass =
      CheckStatus === UnitStatus.Pass ||
      CheckStatus === UnitStatus.PassByFunc ||
      CheckStatus === UnitStatus.PassToFix ||
      CheckStatus === UnitStatus.NotInspection
  } else {
    isPass =
      (CheckStatus === UnitStatus.Pass ||
        CheckStatus === UnitStatus.PassByFunc ||
        CheckStatus === UnitStatus.NotInspection ||
        CheckStatus === UnitStatus.PassToFix) &&
      (DocStatus === UnitStatus.Pass || DocStatus === UnitStatus.PassByFunc)
  }
  return isPass
}

export default mapUnitStatus
