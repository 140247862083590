import RootState from 'typings/RootState'
import { Event } from '../../types/event'
import { DataList } from './types'

export const selectEvent = (state: RootState): Event =>
  state.adminReportMonthly.Event

export const selectReportList = (state: RootState): DataList =>
  state.adminReportMonthly.DataList

export const selectFilter = (state: RootState) =>
  state.adminReportMonthly.Filter

export const selectInitFilter = (state: RootState) =>
  state.adminReportMonthly.ClearFilter
