import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { connect, useDispatch } from 'react-redux'
import { Row, Col, Drawer } from 'antd'
import { RouteComponentProps } from 'react-router-dom'

import RootState from 'typings/RootState'
import SubnavBar from 'Components/Shared/SubnavBar'

import { BreadcrumbLink } from 'Components/Shared/BreadCrumb/BreadCrumb'
import { TableHeader, TableColHeader } from 'Components/Shared/Table'
import Pagination from 'Components/Shared/Pagination'
import AdminDetail from './Detail'
import Menu from '../../MenuAdmin'

import { projectCodeWithSuffixSelector } from 'features/project/project.selector'

import {
  userEmailSelector,
  serviceTokenSelector,
} from 'features/sensitive/sensitive.selector'

import {
  getDataList,
  createData,
  updateData,
  deleteData,
} from 'features/admin/position/api'
import { selectDataList, selectEvent } from 'features/admin/position/selector'
import {
  GetDataListReq,
  DataItem,
  SowType,
  CreateDataReq,
  UpdateDataReq,
  DeleteDataReq,
} from 'features/admin/position/types'

import RowItem from './RowItem'
import { PrimaryButton } from 'Components/Shared/Button'
import { EventModal, ConfirmDeleteModal } from 'Components/Shared'
import { SearchInput } from 'Components/Filter'

const breadcrumbs: BreadcrumbLink[] = [
  {
    url: '/dashboard',
    title: 'Home',
    icon: '/home.png',
    active: true,
    showTitle: false,
  },
  {
    url: '/position',
    title: 'Position list',
    active: false,
    showTitle: false,
  },
]

type DefultProps = {
  homepage?: boolean
}

type Props = DefultProps & ReduxProps & DispatchProps & RouteComponentProps

const AdminPosition: React.ComponentType<Props> = props => {
  const { token, ProjectCode, UserEmail, dataList, event } = props
  const [mode, setMode] = useState('' as 'update' | 'create' | 'delete' | '')
  const [deleteModal, setDeleteModal] = useState(false)
  const [disPlayModal, setdisPlayModal] = useState(false)
  const [selectedItem, setSelectedItem] = useState({} as any)
  const [enableModal, setEnableModal] = useState(false)
  const [sowType, setSowType] = useState('Detail' as SowType)
  const [searchText, setSearchText] = useState('')

  const [pagination, setPagination] = useState<{
    Page: number
    RowPerPage: number
  }>({
    Page: 1,
    RowPerPage: 10,
  })

  const isMasterPlan = sowType === SowType.MasterPlan
  const dispatch = useDispatch()

  useEffect(() => {
    onGetList()
  }, [])

  useEffect(() => {
    onGetList()
  }, [pagination, sowType])

  const onGetList = () => {
    const req: GetDataListReq = {
      UserEmail,
      IsMasterPlan: sowType === SowType.MasterPlan,
      Page: pagination.Page,
      RowPerPage: pagination.RowPerPage,
      TextFilter: searchText,
    }
    dispatch(getDataList(token, req))
  }

  const onCloseDrawer = () => {
    setSelectedItem({})
    setdisPlayModal(false)
    setEnableModal(false)
  }

  const onCreatePosition = (item: DataItem) => {
    setEnableModal(true)
    const req: CreateDataReq = {
      UserEmail,
      PositionName: item.PositionName,
      SOWCode: item.SOWCode,
      IsMasterPlan: item.IsMasterPlan,
      VendorCode: item.VendorCode || '',
    }
    dispatch(createData(token, req))
  }

  const onUpdatePosition = (item: DataItem) => {
    setEnableModal(true)
    const req: UpdateDataReq = {
      UserEmail,
      PositionID: item.PositionID,
      PositionName: item.PositionName,
      SOWCode: item.SOWCode,
      IsMasterPlan: item.IsMasterPlan,
      VendorCode: item.VendorCode,
    }
    dispatch(updateData(token, req))
  }

  const onDeletePosition = (item: DataItem) => {
    setDeleteModal(false)
    setEnableModal(true)
    const req: DeleteDataReq = {
      UserEmail,
      PositionID: item.PositionID,
    }
    dispatch(deleteData(token, req))
  }

  const onSave = (item: DataItem) => {
    if (mode === 'create') {
      onCreatePosition(item)
    }

    if (mode === 'update') {
      onUpdatePosition(item)
    }
  }
  const onCreate = () => {
    setSelectedItem({
      IsMasterPlan: isMasterPlan,
    } as DataItem)
    setdisPlayModal(true)
    setMode('create')
  }

  const onEdit = (item: any) => {
    setSelectedItem(item)
    setdisPlayModal(true)
    setMode('update')
  }

  const onDelete = (item: any) => {
    setSelectedItem(item)
    setDeleteModal(true)
    setMode('delete')
  }

  const onSuccess = () => {
    if (mode !== '') {
      onCloseDrawer()
      setMode('')
      onGetList()
    }
  }

  const onChangeType = (item: any) => {
    onCloseDrawer()
    setSowType(item)
  }

  const onSearch = (val: string) => {
    if (searchText === '' && val === '') return
    setSearchText(val)
    pagination.Page = 1
    setPagination({ ...pagination })
  }

  const content =
    dataList.List &&
    dataList.List.map((item: DataItem, index: number) => {
      const selected =
        item.PositionID === selectedItem.PositionID && mode !== 'delete'

      return (
        <RowItem
          item={item}
          selected={selected}
          onClick={() => onEdit(item)}
          onDelete={() => onDelete(item)}
          key={index}
          isMasterPlan={isMasterPlan}
        />
      )
    })

  const header = (
    <Header>
      <Row type="flex" align="middle" justify="space-between">
        <Col>
          <Title>SOW list</Title>
        </Col>
        <Col style={{ width: '160px' }}>
          <PrimaryButton
            text="สร้าง SOW ใหม่"
            disabled={false}
            onClick={onCreate}
          />
        </Col>
      </Row>
    </Header>
  )

  const column = (
    <TableHeader>
      <TableColHeader span={6} isPadding={true}>
        ชื่อ
      </TableColHeader>
      <TableColHeader span={6}>SOW code</TableColHeader>
      {!isMasterPlan && <TableColHeader span={6}> Vendor </TableColHeader>}
      <TableColHeader span={6} sort={false}>
        {' '}
      </TableColHeader>
    </TableHeader>
  )

  const pages = (
    <Pagination
      currentPage={pagination.Page}
      totalRows={dataList.Total}
      perPage={pagination.RowPerPage}
      onChangeNumber={(row: number) => {
        pagination.RowPerPage = row
        setPagination({ ...pagination })
      }}
      onClickPage={(page: number) => {
        pagination.Page = page
        setPagination({ ...pagination })
      }}
    />
  )

  const sow = () => {
    return (
      <SOWType>
        <Row type="flex">
          {[SowType.Detail, SowType.MasterPlan].map(item => {
            return (
              <Col onClick={() => onChangeType(item)}>
                <Text className={sowType === item ? 'active' : ''}>{item}</Text>
              </Col>
            )
          })}
        </Row>
      </SOWType>
    )
  }

  return (
    <>
      <SubnavBar
        breadcrumbs={breadcrumbs}
        hideSearch={true}
        adminSearchBox={<SearchInput onSearch={onSearch} />}
      />
      <EventModal
        name="sow"
        event={[event]}
        enable={enableModal}
        successCalback={onSuccess}
      />
      <Menu selected={'position'}>
        <MainPage>
          {header}
          {sow()}
          {column}
          {content}
          {pages}
        </MainPage>

        <Drawer
          title={null}
          className="drawer-project"
          closable={false}
          width={400}
          visible={disPlayModal}
        >
          <AdminDetail
            item={selectedItem}
            onSave={onSave}
            onClose={onCloseDrawer}
          />
        </Drawer>
        <ConfirmDeleteModal
          title="ลบรายการ"
          desc={'คุณยืนยันต้องการลบ SOW นี้'}
          onClose={() => setDeleteModal(false)}
          onSubmit={() => onDeletePosition(selectedItem)}
          visible={deleteModal}
        />
      </Menu>
    </>
  )
}

const MainPage = styled.div`
  padding: 40px 45px;
  width: 100%;
`

const Header = styled.div`
  height: 50px;
`

const Title = styled.h2`
  font-size: 28px;
  font-family: 'DB Helvethaica X 75 Bd';
  height: 30px;
`

const Text = styled.div`
  font-size: 18px;
  color: #96a3b1;
  margin-right: 45px;
  cursor: pointer;
  padding: 0 5px 5px 5px;
  &.active {
    font-family: 'DB Helvethaica X 75 Bd';
    color: #003168;
    border-bottom: 2px solid #003168;
  }
`

const SOWType = styled.div`
  margin-bottom: 20px;
  border-bottom: 1px solid #e8ebf0;
`

const mapStateToProps = (state: RootState) => {
  return {
    dataList: selectDataList(state),
    event: selectEvent(state),
    token: serviceTokenSelector(state),
    ProjectCode: projectCodeWithSuffixSelector(state),
    UserEmail: userEmailSelector(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(AdminPosition)
