import React from 'react'
import { Row, Col, Input } from 'antd'

type Props = {
  onChange: (value: string) => void
  value?: string
  readOnly?: boolean
}

const RemarkBox: React.FC<Props> = ({
  onChange,
  value = '',
  readOnly = false,
}) => {
  return (
    <Row>
      <Col span={24}>
        <div className="form-label">หมายเหตุ</div>
        <div>
          <Input.TextArea
            style={{ color: 'black', fontSize: '20px' }}
            value={value}
            onChange={evt => {
              onChange(evt.target.value)
            }}
            rows={3}
            autoSize={{ minRows: 3, maxRows: 4 }}
            maxLength={255}
            disabled={readOnly}
          />
        </div>
      </Col>
    </Row>
  )
}

export default RemarkBox
