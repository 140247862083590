import { createStandardAction, createAsyncAction } from 'typesafe-actions'
import {
  SignQCReportReq,
  SignZoneReportReq,
  EnableFunctionReq,
  SignCommissioningReq,
  SignConstructPlanReq,
  SignAuditPlanReq,
  SignConstructRoadReq,
  SignAuditRoadReq,
  RespApi,
} from './types'

export const saveSignatureAction = createAsyncAction(
  'report/common/UPDATE_SIGN_REQ',
  'report/common/UPDATE_SIGN_SUC',
  'report/common/UPDATE_SIGN_FAIL'
)<SignQCReportReq, RespApi, any>()

export const saveZoneSignatureAction = createAsyncAction(
  'report/common/UPDATE_SIGN_ZONE_REQ',
  'report/common/UPDATE_SIGN_ZONE_SUC',
  'report/common/UPDATE_SIGN_ZONE_FAIL'
)<SignZoneReportReq, RespApi, any>()

export const saveCommissionSignatureAction = createAsyncAction(
  'report/common/UPDATE_SIGN_COMMISSION_REQ',
  'report/common/UPDATE_SIGN_COMMISSION_SUC',
  'report/common/UPDATE_SIGN_COMMISSION_FAIL'
)<SignCommissioningReq, RespApi, any>()

export const saveConstructPlanSignatureAction = createAsyncAction(
  'report/common/UPDATE_SIGN_CONSTRUCT_PLAN_REQ',
  'report/common/UPDATE_SIGN_CONSTRUCT_PLAN_SUC',
  'report/common/UPDATE_SIGN_CONSTRUCT_PLAN_FAIL'
)<SignConstructPlanReq, RespApi, any>()

export const saveAuditPlanSignatureAction = createAsyncAction(
  'report/common/UPDATE_SIGN_AUDIT_PLAN_REQ',
  'report/common/UPDATE_SIGN_AUDIT_PLAN_SUC',
  'report/common/UPDATE_SIGN_AUDIT_PLAN_FAIL'
)<SignAuditPlanReq, RespApi, any>()

export const saveConstructRoadSignatureAction = createAsyncAction(
  'report/common/UPDATE_SIGN_CONSTRUCT_ROAD_REQ',
  'report/common/UPDATE_SIGN_CONSTRUCT_ROAD_SUC',
  'report/common/UPDATE_SIGN_CONSTRUCT_ROAD_FAIL'
)<SignConstructRoadReq, RespApi, any>()

export const saveAuditRoadSignatureAction = createAsyncAction(
  'report/common/UPDATE_SIGN_AUDIT_ROAD_REQ',
  'report/common/UPDATE_SIGN_AUDIT_ROAD_SUC',
  'report/common/UPDATE_SIGN_AUDIT_ROAD_FAIL'
)<SignAuditRoadReq, RespApi, any>()

export const submitSignReq = createStandardAction(
  'report/common/SUBMIT_SIGN_REQUEST'
)<any>()

export const submitZoneSignReq = createStandardAction(
  'report/common/SUBMIT_SIGN_ZONE_REQUEST'
)<any>()

export const submitCommissionSignReq = createStandardAction(
  'report/common/SUBMIT_SIGN_COMMISSION_REQUEST'
)<any>()

export const submitConstructPlanSignReq = createStandardAction(
  'report/common/SUBMIT_SIGN_CONSTRUCT_PLAN_REQ'
)<any>()

export const submitAuditPlanSignReq = createStandardAction(
  'report/common/SUBMIT_SIGN_AUDIT_PLAN_REQ'
)<any>()

export const submitConstructRoadSignReq = createStandardAction(
  'report/common/SUBMIT_SIGN_CONSTRUCT_ROAD_REQ'
)<any>()

export const submitAuditRoadSignReq = createStandardAction(
  'report/common/SUBMIT_SIGN_AUDIT_ROAD_REQ'
)<any>()

export const enableFunctionAction = createAsyncAction(
  'report/common/UPDATE_FUNC_REQ',
  'report/common/UPDATE_FUNC_SUC',
  'report/common/UPDATE_FUNC_FAIL'
)<EnableFunctionReq, RespApi, any>()

export const resetEvent = createStandardAction('report/common/RESET_EVENT')<
  void
>()
