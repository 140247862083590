import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Row, Col, Layout } from 'antd'

import { DropdownList } from 'Components/Shared/Form'
import { PrimaryButton } from 'Components/Shared/Button'

const { Footer: FooterPage } = Layout

type Props = {
  isLoading?: boolean
  visible?: boolean
  type: string
  onSendReqToQC: (typ: number) => void
}

const Footer: React.ComponentType<Props> = props => {
  const [menu, setMenu] = useState([] as any[])
  const [selected, setSelected] = useState(0)

  const { isLoading = false, visible = false, type, onSendReqToQC } = props

  useEffect(() => {
    let defMenu = CommissionMenu
    let defSelect = 1
    if (type !== 'Commissioning') {
      defMenu = SafetyMenu
      defSelect = 3
    }

    setMenu(defMenu)
    setSelected(defSelect)
  }, [type])

  if (visible === false) return <></>

  return (
    <FooterPage className="detail-page-footer">
      <FooterView>
        <Row type="flex" justify="space-between" align="middle">
          <Col />
          <Col>
            <Info>
              <ListBox>
                <DropdownList
                  label="หมวดงาน"
                  value={selected}
                  menu={menu}
                  onChange={(val: number) => setSelected(val)}
                />
              </ListBox>

              <Button>
                <PrimaryButton
                  text="ส่งรายงานหา QC"
                  disabled={isLoading}
                  onClick={() => onSendReqToQC(selected)}
                />
              </Button>
            </Info>
          </Col>
        </Row>
      </FooterView>
    </FooterPage>
  )
}

const CommissionMenu = [
  {
    value: 1,
    name: 'Q1',
  },
  {
    value: 2,
    name: 'Commissioning',
  },
]

const SafetyMenu = [
  {
    value: 3,
    name: 'Safety',
  },
]

const FooterView = styled.div`
  max-width: 1110px;
  color: rgb(0, 0, 0);
  font-size: 20px;
  padding-right: 20px;
  margin: 0px auto;
`

const Info = styled.div`
  display: flex;
`

const ListBox = styled.div`
  width: 317px;
`

const Button = styled.div`
  width: 200px;
  margin-left: 16px;
  float: left;
`

export default Footer
