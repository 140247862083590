import React, { useState } from 'react'
import { Button, Dropdown, Input, Menu, Col } from 'antd'
import styled from 'styled-components'
import icSearch from 'assets/img/ic-search-grey.png'
import { translate } from 'i18n'

import { ArrowDownIcon, ArrowUpIcon } from '../Icon'

interface MenuProps {
  name: string
  value: string | number
}

interface Props {
  menu?: MenuProps[]
  label: string
  value: number | string | undefined
  disabled?: boolean
  placeholder?: string
  menuChoice?: any
  onChange?: any
  hasSearch?: boolean
  onSearch?: (val: string) => void
  onClear?: any
  reset?: boolean
}

const DropdownList = ({
  menu = [],
  label = '',
  value = '',
  disabled = false,
  placeholder = 'เลือก',
  menuChoice,
  onChange,
  hasSearch = false,
  onSearch = () => {},
  reset = false,
  onClear,
}: Props) => {
  const [visible, setVisible] = useState(false)
  const [searchTxt, setSearchTxt] = useState('')

  const onSelected = (selected: any, index: number) => {
    if (onChange && value !== selected) {
      onChange(selected, index)
    }
    setVisible(false)
  }

  let nameValue = placeholder
  let cssPlaceholder = 'placeholder'
  if (!menuChoice) {
    const index = menu.findIndex((item: any) => item.value === value)
    if (index !== -1) {
      nameValue = menu[index].name
      cssPlaceholder = ''
    }
  }

  const onSearchTxt = (evt: any) => {
    setSearchTxt(evt.target.value)
    onSearch(evt.target.value)
  }

  const onClearValue = () => {
    onClear()
  }

  const content = (
    <Menu className="dropdown-list-main">
      <ContentBody>
        {hasSearch && (
          <SearchArea onClick={(event: any) => event.stopPropagation()}>
            <InputSearch>
              <Input
                placeholder={'ค้นหา'}
                suffix={<img src={icSearch} />}
                onChange={onSearchTxt}
                value={searchTxt}
                autoFocus={true}
              />
            </InputSearch>
          </SearchArea>
        )}

        {reset ? (
          <ResetArea>
            <Col span={24}>
              <span onClick={onClear} className="filter-clear std-font-regular">
                ยกเลิกค้นหา
              </span>
            </Col>
            <br></br>
          </ResetArea>
        ) : (
          <span></span>
        )}

        {menuChoice
          ? menuChoice
          : menu.map((item: any, index: number) => (
              <DataItem
                onClick={() => onSelected(item.value, index)}
                key={index}
                className={value === item.value ? 'active' : ''}
              >
                {item.name}
              </DataItem>
            ))}
      </ContentBody>
    </Menu>
  )

  const btn = (
    <Button disabled={disabled}>
      <label>{label}</label>
      <span className={cssPlaceholder}>
        {nameValue}&nbsp;&nbsp;
        {visible ? <ArrowUpIcon /> : <ArrowDownIcon />}
      </span>
    </Button>
  )

  if (disabled) {
    return <DisabledBtn className="ant-dropdown-trigger">{btn}</DisabledBtn>
  }
  return (
    <Dropdown
      overlayClassName="menu-dropdown-list"
      overlay={content}
      trigger={['click']}
      onVisibleChange={() => setVisible(!visible)}
    >
      <ItemView>{btn}</ItemView>
    </Dropdown>
  )
}

const ContentBody = styled.div`
  max-height: 200px;
  height: auto;
  width: 100%;
  overflow-y: scroll;
  font-family: 'DB Helvethaica X 55 Regular';
  font-size: 20px;
`

const DataItem = styled.div`
  padding: 5px 10px;

  &:hover {
    cursor: pointer;
    color: white;
    background-color: #003168;
  }

  &.active {
    font-family: 'DB Helvethaica X 75 Bd';
  }
`

const ItemView = styled.div`
  button,
  button:active,
  button:hover,
  button:focus {
    width: 100%;
    height: 48px;
    border-radius: 5px;
    border: solid 1px #e4e7eb;
    color: black;
    text-align: left;
    background-color: white;
    font-family: 'DB Helvethaica X 55 Regular';
    font-size: 20px;

    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  button > span {
    font-family: 'DB Helvethaica X 75 Bd';
    padding-left: 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  button > span.placeholder {
    font-family: 'DB Helvethaica X 55 Regular';
  }

  @media screen and (max-width: 425px) {
  }
`

const DisabledBtn = styled.div`
  button[disabled] {
    width: 100%;
    height: 48px;
    border-radius: 5px;
    border: solid 1px #e4e7eb;
    color: black;
    text-align: left;
    background-color: #f3f3f3;
    border: solid 1px #e4e7eb;
    font-size: 20px;
    font-family: 'DB Helvethaica X 55 Regular';
  }

  button[disabled]:hover {
    background-color: #f3f3f3;
    border: solid 1px #e4e7eb;
    color: black;
  }

  button > label {
    float: left;
  }

  button[disabled] > span {
    float: right;
    font-family: 'DB Helvethaica X 75 Bd';
    padding-left: 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 80%;
  }

  button > span.placeholder {
    float: right;
    font-family: 'DB Helvethaica X 55 Regular';
  }
`

const SearchArea = styled.div`
  margin: 10px 0;
  padding: 5px 10px;
`

const InputSearch = styled.div`
  .ant-input-affix-wrapper {
    .ant-input {
      height: 48px;
      border-radius: 5px;
      font-size: 18px;
      font-family: 'DB Helvethaica X 55 Regular';
    }
  }

  .ant-input-affix-wrapper > .ant-input-suffix {
    img {
      width: 16px;
      height: 16px;
    }
  }
`

const ResetArea = styled.div`
  margin: 10px 5px;
  padding: 5px;
`

export default DropdownList
