import React, { useEffect } from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import TopNav from 'Components/Shared/TopNav'
import TokenExpired from 'Components/Pages/TokenExpired'

import ConstructRoadDashboard from 'Components/QC/Dashboard/Road/ConstructRoad'
import TodoList from 'Components/QC/Road/ConstructRoad/TodoList'
import ConstructRoadReport from 'Components/QC/ConstructRoad/Report'

type Props = {}

const QcConstructRoadApp: React.FunctionComponent<Props> = props => {
  return (
    <>
      <TopNav title="QC" displayProject={false} secondaryTitle="Road" />
      <Switch>
        <Route
          exact
          path="/construct-road"
          component={ConstructRoadDashboard}
        />

        <Route
          exact
          path="/construct-road/todo/:project"
          component={TodoList}
        />

        <Route
          exact
          path="/construct-road/report/:project/:unit/:qcTypeID/:requestID/:checkNumber"
          component={ConstructRoadReport}
        />

        <Route exact path="/token-expired" component={TokenExpired} />
      </Switch>
    </>
  )
}

export default QcConstructRoadApp
