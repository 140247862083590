import React from 'react'
import { getApprovalByStatus } from 'utils/statusLookup'
import { BreadcrumbLink } from 'Components/Shared/BreadCrumb/BreadCrumb'
import SubnavBar from 'Components/Shared/SubnavBar'
import TKeys from 'i18n/translationKey'
import { translate } from 'i18n/translate'

type Props = {
  activity: any
  name?: string
  onClose?: () => void
}

const Breadcrumbs: React.ComponentType<Props> = props => {
  const { activity, name } = props
  const breadcrumbs: BreadcrumbLink[] = [
    {
      url: '/',
      title: 'Home',
      icon: '/home.png',
      active: true,
      showTitle: false,
    },
    {
      url: '/list',
      title: 'รายการคำขอ',
      active: true,
      showTitle: true,
    },
  ]

  if (activity) {
    breadcrumbs.push({
      url: `/activity/${activity.ActivityID}`,
      title: activity.ActivityName || 'activity',
      active: name ? true : false,
      showTitle: name ? true : false,
      onClick: props.onClose,
    })

    if (name) {
      breadcrumbs.push({
        url: `/activity/${activity.ActivityID}`,
        title: name,
        active: false,
        showTitle: false,
      })
    }
  }

  return <SubnavBar breadcrumbs={breadcrumbs} hideSearch={true} />
}

export default Breadcrumbs
