import React, { useState, useEffect } from 'react'
import RootState from 'typings/RootState'
import { connect, useDispatch } from 'react-redux'
import { Row, Col } from 'antd'
import { BreadcrumbLink } from 'Components/Shared/BreadCrumb/BreadCrumb'
import { PrimaryButton, SecondaryButton } from 'Components/Shared/Button'

import ImageDisplay from 'Components/Shared/ImageDisplay'
import DocumentDisplay from 'Components/Shared/DocumentDisplay'
import ThumbnailDisplay from 'Components/Shared/ThumbnailDisplay'
import {
  selectOriginalActivity,
  selectCheckList,
  selectCurrentActivity,
  selectEvent,
  selectEventCheckList,
} from 'features/cmpj/activity/selector'

import { getWbsList } from 'features/wbs/wbs.actions'
import {
  getWbsPathMapSelector,
  getWbsMapParentsSelector,
  getEvent,
} from 'features/wbs/wbs.selector'
import { getActivityById, getCheckListById } from 'features/cmpj/activity/api'

import {
  userEmailSelector,
  serviceTokenSelector,
} from 'features/sensitive/sensitive.selector'

import ActivityDetail from './ActivityDetail'
import Breadcrumbs from './Breadcrumbs'
import { getHistoryActivity } from 'features/maincon/history/api'
import {
  selectData,
  selectEvent as selectHistoryEvent,
} from 'features/maincon/history/selector'
import { HistoryModal, HistoryTable } from 'Components/Activity'
import EventModal from 'Components/Shared/Modal/EventModal'
import {
  submitAttachmentListReq,
  setCurrentActivity,
} from 'features/cmpj/activity/actions'
import styled from 'styled-components'

type FormProps = {
  ProjectCode: string
  ActivityID: string
  breadcrumbs?: BreadcrumbLink[]
  onPage?: (name: string) => void
}

type Props = FormProps & ReduxProps

const ActivityInfo = (props: Props) => {
  const [lastPage, setLastPage] = useState('')
  const [page, setPage] = useState('')
  const [readOnly, setReadOnly] = useState(false)
  const [imageSelected, setImageSelected] = useState(0)
  const [imageList, setImageList] = useState([] as any[])
  const [enableModal, setEnableModal] = useState(false)
  const [documentList, setDocumentList] = useState([] as any[])
  const [showHistoryModal, setShowHistoryModal] = useState(false)
  const [removeList, setRemoveList] = useState([] as any[])

  const {
    Token,
    UserEmail,
    ProjectCode,
    ActivityID,
    CheckList,
    currentActivity,
    originalActivity,
    activityEvent,
    checklistEvent,
    wbs,
    historyData,
    historyEvent,
    breadcrumbs,
    onPage = (name: string) => {},
    wbsEvent,
  } = props

  const displayPage = (name: string) => {
    setPage(name)
    onPage(name)
  }

  const onPageClose = () => {
    displayPage(lastPage)
    if (lastPage === '') {
      setImageList([])
    } else {
      setLastPage('')
    }

    setDocumentList([])
  }

  const dispatch = useDispatch()

  useEffect(() => {
    setEnableModal(false)
  }, [originalActivity])

  useEffect(() => {
    const params: any = {
      UserEmail,
      ProjectCode,
      ActivityID,
    }
    dispatch(getActivityById(params, Token))

    dispatch(getCheckListById(params, Token))

    dispatch(getHistoryActivity(Token, params))

    dispatch(getWbsList({ UserEmail, ProjectCode, Token }))
  }, [dispatch, ProjectCode, UserEmail, ActivityID, Token])

  const onImageOpen = (list?: any[], readOnly?: boolean) => {
    setLastPage(page)
    displayPage('images')
    setReadOnly(readOnly || false)
    setImageList(list ? list : [])
  }

  const onDocumentOpen = (list?: any[], readOnly?: boolean) => {
    setLastPage(page)
    displayPage('documents')
    setReadOnly(readOnly || false)
    setDocumentList(list ? list : [])
  }

  const onThumbnailOpen = (index: number, list?: any[], readOnly?: boolean) => {
    setLastPage(page)
    displayPage('thumbnails')
    setReadOnly(readOnly || false)
    setImageList(list ? list : [])
    setImageSelected(index)
  }

  const onSetImage = (images: any[], FileID?: string) => {
    if (FileID) {
      let newList = removeList.concat([FileID])
      setRemoveList(newList)
    }

    currentActivity.Images = images
    setImageList(images)
    dispatch(setCurrentActivity({ ...currentActivity }))
  }

  const onSetDoc = (docs: any[]) => {
    currentActivity.Documents = docs
    setDocumentList(docs)
    dispatch(setCurrentActivity({ ...currentActivity }))
  }

  const closeEnableModal = () => {
    setEnableModal(false)
    window.location.reload()
  }

  const onChangeRemove = (list: any[]) => {
    let newList = removeList.concat(list)
    setRemoveList(newList)
  }

  const onSetActivity = (val: any) => dispatch(setCurrentActivity({ ...val }))
  const onEnableUpdateFiles = () => {
    const ImagesList = currentActivity.Images.filter(
      (img: any) => img.FileID == undefined
    )
    const DocumentsList = currentActivity.Documents.filter(
      (file: any) => file.FileID == undefined
    )

    setEnableModal(true)
    const req = {
      UserEmail: UserEmail,
      RequestID: originalActivity.RequestID,
      ImageList: ImagesList,
      DocumentsList: DocumentsList,
      RemoveFiles: removeList,
    }

    dispatch(submitAttachmentListReq({ req, Token }))
  }

  const historyModal = (
    <HistoryModal
      isShow={showHistoryModal}
      onCloseModal={() => setShowHistoryModal(false)}
      content={
        <HistoryTable
          items={historyData}
          fetchHistoryError={historyEvent.error}
          onImageOpen={onImageOpen}
          onDocumentOpen={onDocumentOpen}
        />
      }
    />
  )

  let disableConditionBtn =
    currentActivity.RequestProgress == 100 &&
    removeList.length == 0 &&
    currentActivity.Images.filter((img: any) => img.FileID == undefined)
      .length == 0 &&
    currentActivity.Documents.filter((file: any) => file.FileID == undefined)
      .length == 0

  let disableImgBlank = currentActivity.Images.length == 0

  switch (page) {
    case 'images':
      return (
        <ImageDisplay
          isMultiple={false}
          onClose={onPageClose}
          readOnly={readOnly}
          images={imageList}
          onThumbnailOpen={onThumbnailOpen}
          onChange={onSetImage}
          numberOfImage={50}
          onChangeRemove={onChangeRemove}
          breadcrumb={
            <Breadcrumbs
              breadcrumbs={breadcrumbs}
              activity={originalActivity}
              name="รูปภาพ"
              onClose={onPageClose}
            />
          }
        />
      )
    case 'documents':
      return (
        <DocumentDisplay
          onClose={onPageClose}
          readOnly={readOnly}
          documents={documentList}
          onChange={onSetDoc}
          numberOfFile={50}
          onChangeRemove={onChangeRemove}
          breadcrumb={
            <Breadcrumbs
              breadcrumbs={breadcrumbs}
              activity={originalActivity}
              name="เอกสาร"
              onClose={onPageClose}
            />
          }
        />
      )
    case 'thumbnails':
      return (
        <ThumbnailDisplay
          onClose={onPageClose}
          readOnly={readOnly}
          images={imageList}
          index={imageSelected}
          onChange={onSetImage}
          isHideTrash={true}
          isHideEdit={true}
        />
      )

    default:
      return (
        <>
          <EventModal
            event={[activityEvent, checklistEvent, wbsEvent]}
            enable={enableModal}
            successCalback={closeEnableModal}
            errorCalback={closeEnableModal}
          />
          <ActivityDetail
            breadcrumbs={breadcrumbs}
            onImageOpen={onImageOpen}
            onDocumentOpen={onDocumentOpen}
            onThumbnailOpen={onThumbnailOpen}
            checkListData={CheckList}
            originalData={originalActivity}
            currentData={currentActivity}
            wbs={wbs}
            onHistoryOpen={() => setShowHistoryModal(true)}
            onUpdateActivity={onSetActivity}
          />

          {currentActivity.RequestProgress === 100 &&
            originalActivity.TaskStatus === 'APPV' && (
              <FooterContent>
                <Row type="flex" justify="end" align="middle">
                  <Col md={4}>
                    <PrimaryButton
                      text="อัปเดตไฟล์"
                      onClick={() => onEnableUpdateFiles()}
                      disabled={disableConditionBtn || disableImgBlank}
                    />
                  </Col>
                </Row>
              </FooterContent>
            )}

          {historyModal}
        </>
      )
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    originalActivity: selectOriginalActivity(state),
    currentActivity: selectCurrentActivity(state),
    CheckList: selectCheckList(state),
    UserEmail: userEmailSelector(state),
    Token: serviceTokenSelector(state),
    wbsPathMap: getWbsPathMapSelector(state),
    activityEvent: selectEvent(state),
    checklistEvent: selectEventCheckList(state),
    wbs: getWbsMapParentsSelector(state),
    historyData: selectData(state),
    historyEvent: selectHistoryEvent(state),
    wbsEvent: getEvent(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

const FooterContent = styled.div`
  width: 100%;
  padding: 1%;
  font-size: 20px;
  padding-right: 40px;
  background-color: white;
`

export default connect(mapStateToProps, mapDispatchToProps)(ActivityInfo)
