import React from 'react'
import BaseIcon from './BaseIcon'
import img from 'assets/img/ic-uncheck-readonly.png'

const DisableUncheckedIcon = (props: any) => {
  return (
    <BaseIcon
      img={img}
      onClick={props.onClick}
      size={props.size}
      disabled={props.disabled}
    />
  )
}

export default DisableUncheckedIcon
