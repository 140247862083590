import { createStandardAction, createAsyncAction } from 'typesafe-actions'
import { GetDashboardReq, GetDashboardResp } from './types'

export const getDashbaordAction = createAsyncAction(
  'cmpj/road/audit/FETCH_DASHBOARD_REQUEST',
  'cmpj/road/audit/FETCH_DASHBOARD_SUCCESS',
  'cmpj/road/audit/FETCH_DASHBOARD_FAILURE'
)<GetDashboardReq, GetDashboardResp, any>()

export const sortingDashboard = createStandardAction('cmpj/road/audit/SORTING')<
  string
>()
