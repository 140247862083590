import React, { useState } from 'react'
import { Row, Col } from 'antd'
import styled from 'styled-components'
import { TableHeader as Table, TableColHeader } from 'Components/Shared/Table'
import CardListHeader, {
  CardListHeaderItem,
} from '../../../cards/CardList/CardListHeader'

type Props = {
  onSort(name: string): void
  sorting: any
  hideColumn?: string[]
  defaultColumn: any
}

const TableHeader: React.FunctionComponent<Props> = props => {
  const { onSort, sorting, hideColumn = [], defaultColumn } = props

  const getIcon = (
    name: string | undefined,
    type: string,
    colName: string
  ): string => {
    if (name === colName) {
      return type !== 'desc' ? '/img/ic-arrow-actions.png' : '/img/ic-down.png'
    }

    return '/img/ic-sort.svg'
  }

  const showColumn = defaultColumn.filter((item: any) => {
    return !hideColumn.includes(item.key)
  })

  const renderColumn = () => {
    return showColumn.map((item: any, index: number) => {
      return (
        <TableColHeader
          key={index}
          span={item.span}
          onSort={() => onSort(item.key)}
          type=""
          sort={false}
        >
          {item.title}
        </TableColHeader>
      )
    })
  }

  return <Table paddingLeft={27}>{renderColumn()}</Table>
}

export default TableHeader
