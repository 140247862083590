import { toArray, getInt, getText } from './common'
import { UnitModel } from 'features/admin/model/types'

export const toCheckList = (data: string): any => {
  let unitlist: UnitModel[] = [] as UnitModel[]
  const rows = toArray(data)
  rows.forEach((row: any, index: number) => {
    let unit = {} as UnitModel
    if (index > 3) {
      unit = {
        Seq: getInt(row, getIndex(1)),
        UnitCode: getText(row, getIndex(2)),
        UnitArea: getInt(row, getIndex(3)),
        Area: getInt(row, getIndex(4)),
        Floor: getText(row, getIndex(5)),
        Tower: getText(row, getIndex(6)),
        CarPark: getText(row, getIndex(7)),
        UnitType: getText(row, getIndex(8)),
        ModelCode: getText(row, getIndex(9)),
        ModelName: getText(row, getIndex(10)),
        FormulaCode: getInt(row, getIndex(11)),
        CalculationFlag: getInt(row, getIndex(12)),
        TotalPrice: getInt(row, getIndex(13)),
        PriceBahtSqm: getInt(row, getIndex(14)),
        BookingBaht: getInt(row, getIndex(15)),
        ContactTotalBaht: getInt(row, getIndex(17)), //16-17
        DownPaymentTotalBaht: getInt(row, getIndex(19)), //18-19
        DownPaymentNoOfDown: getInt(row, getIndex(20)),
        DownPaymentPerMonth: getInt(row, getIndex(21)),
        TransferTotalBaht: getInt(row, getIndex(23)), //22-23
        CommonAreaRatePerUnitBaht: getInt(row, getIndex(24)),
        CommonAreaFundRatePerUnitBaht: getInt(row, getIndex(25)),
        ElectricMeterInsuranceBaht: getInt(row, getIndex(26)),
        WaterMeterInsuranceBaht: getInt(row, getIndex(27)),
        PostFinancingNoOfYear1: getInt(row, getIndex(29)), //28-29
        PostFinancingPayBaht1: getInt(row, getIndex(30)),
        PostFinancingNoOfYear2: getInt(row, getIndex(31)),
        PostFinancingPayBaht2: getInt(row, getIndex(32)),
        TitleDeedNumber: getText(row, getIndex(33)),
        LandNumber: getText(row, getIndex(34)),
        SubDistrictNo: getText(row, getIndex(35)),
        Miscellaneous: getInt(row, getIndex(36)),
        ElectricMeterInstallingFee: getInt(row, getIndex(37)),
        WaterMeterInstallingFee: getInt(row, getIndex(38)),
        CommonAreaCostType: getInt(row, getIndex(39)),
        LR_Allocation: getText(row, getIndex(40)),
      }

      unitlist.push(unit)
    }
  })
  return unitlist
}

const getIndex = (col: number) => {
  return col - 1
}
