import { all, fork } from 'redux-saga/effects'
import searchSaga from 'features/search/search.saga'
import wbsSaga from 'features/wbs/wbs.saga'
import sensitiveSaga from 'features/sensitive/sensitive.saga'
import activitySaga from 'features/activity/activity.saga'
import tasklaneSaga from 'features/tasklane/tasklane.saga'
import projectSaga from 'features/project/project.saga'
import selectedProjectSaga from 'features/selectedProject/selectedProject.saga'
import loginSaga from 'features/login/login.saga'
import commonSaga from 'features/common/common.saga'
import mainconActivity from 'features/maincon/activity/saga'
import cmActivity from 'features/cmpj/activity/saga'
import masterChecklist from 'features/checklist/master/saga'
import localChecklist from 'features/checklist/local/saga'
import unitChecklist from 'features/checklist/unit/saga'
import localDocument from 'features/document/local/saga'
import adminModel from 'features/admin/model/saga'
import adminFloorPlan from 'features/admin/floorPlan/saga'
import adminZone from 'features/admin/zone/saga'

import unitReport from 'features/report/unit/saga'
import zoneReport from 'features/report/zone/saga'
import commissionReport from 'features/report/commissioning/saga'
import commonReport from 'features/report/common/saga'
import constructPlanReport from 'features/report/constructPlan/saga'
import auditPlanReport from 'features/report/auditPlan/saga'
import constructRoadReport from 'features/report/constructRoad/saga'
import auditRoadReport from 'features/report/auditRoad/saga'

import commission from 'features/admin/commission/saga'

import roadConstruct from 'features/cmpj/road/construct/checking/saga'

function* rootSaga() {
  // prettier-ignore
  yield all([
    fork(searchSaga),
    fork(wbsSaga),
    fork(sensitiveSaga),
    fork(activitySaga),
    fork(tasklaneSaga),
    fork(projectSaga),
    fork(selectedProjectSaga),
    fork(loginSaga),
    fork(commonSaga),
    fork(mainconActivity),
    fork(cmActivity),
    fork(masterChecklist),
    fork(localChecklist),
    fork(unitChecklist),
    fork(localDocument),
    fork(adminModel),
    fork(adminFloorPlan),
    fork(adminZone),

    fork(unitReport),
    fork(zoneReport),
    fork(commonReport),
    fork(commissionReport),
    fork(constructPlanReport),
    fork(auditPlanReport),
    fork(constructRoadReport),
    fork(auditRoadReport),

    fork(commission),
    fork(roadConstruct),

  ])
}

export default rootSaga
