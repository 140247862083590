import withAPIAction from 'utils/withAPIAction'
import {
  getUnitReportAction,
  getUnitCheckListForQCReq,
  createUnitDefectAction,
  updateUnitDefectAction,
  sendDefectForQCEditAction,
  setUnitDefectAction,
} from './actions'
import {
  GetUnitReportReq,
  GetCheckListForQCReq,
  UnitDefectType,
  EditDefectReq,
  SetUnitDefectReq,
} from './types'
import { verifyReq } from 'utils/verify'

export const getUnitReport = (token: string, data: GetUnitReportReq) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<GetUnitReportReq>({
    method: 'POST',
    path: 'GetUnitReportById',
    data,
    token: `Bearer ${token}`,
  })(getUnitReportAction)
}

// get unit checklist for qc
export const getUnitCheckListForQC = (
  token: string,
  data: GetCheckListForQCReq
) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<any>(state => ({
    method: 'POST',
    path: 'GetUnitCheckListForQC',
    data,
    token: `Bearer ${token}`,
  }))(getUnitCheckListForQCReq)
}

export const createUnitDefect = (token: string, data: UnitDefectType) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<UnitDefectType>({
    method: 'POST',
    path: 'CreateUnitDefect	',
    data,
    token: `Bearer ${token}`,
  })(createUnitDefectAction)
}

export const updateUnitDefect = (token: string, data: UnitDefectType) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<UnitDefectType>({
    method: 'POST',
    path: 'UpdateUnitDefect',
    data,
    token: `Bearer ${token}`,
  })(updateUnitDefectAction)
}

export const setUnitDefect = (token: string, data: SetUnitDefectReq) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<SetUnitDefectReq>({
    method: 'POST',
    path: 'SetUnitDefectStatus',
    data,
    token: `Bearer ${token}`,
  })(setUnitDefectAction)
}

export const sendDefectForQCEdit = (token: string, data: EditDefectReq) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<EditDefectReq>({
    method: 'POST',
    path: 'SendDefectForQCEdit			',
    data,
    token: `Bearer ${token}`,
  })(sendDefectForQCEditAction)
}
