import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { RootState } from 'typings/RootState'

import FilterButtons from 'Components/Shared/Filters/FilterButtons'

import {
  setFilterActivity,
  filterActivity,
} from 'features/activity/activity.actions'
import { getActivityFilterSelector } from 'features/activity/activity.selector'

import { ActivityFilter } from 'features/activity/activity.types'

type Props = { history: any } & ReduxProps & DispatchProps

const FilterPage: React.FunctionComponent<Props> = ({ filter, history }) => {
  const dispatch = useDispatch()
  const onFilterChange = (filter: ActivityFilter) => {
    dispatch(setFilterActivity(filter))
  }

  const onFind = () => {
    dispatch(filterActivity())
    history.push('./1')
  }

  return (
    <FilterButtons filter={filter} onChange={onFilterChange} onFind={onFind} />
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    filter: getActivityFilterSelector(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterPage)
