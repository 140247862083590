import React, { useState, useEffect, useCallback } from 'react'

import styled from 'styled-components'
import file from 'utils/file'
import { toLocalPosition } from 'utils/pin'
import { PinIcon } from 'Components/Shared/Icon'

type Props = {
  floorImage: string
  pinSize?: any
  pin?: any
}

const PinView: React.FunctionComponent<Props> = props => {
  const { floorImage, pinSize = { width: 24, height: 28.5 }, pin } = props

  const [myCanvas, setMyCanvas] = useState(undefined as any)
  const [imgSize, setImgSize] = useState(undefined as any)

  useEffect(() => {
    drawMyImage(myCanvas)
  }, [floorImage])

  const getColor = (status: string) => {
    switch (status) {
      case 'ToBeFix':
        return '#d0494c'
      case 'GoodWork':
        return '#79cadc'
      case 'Pass':
        return '#5bc79e'
      default:
        return '#e7a065'
        break
    }
  }

  const onRefCanvas = useCallback(node => {
    if (node) {
      setMyCanvas(node)
      const rect = node.getBoundingClientRect()
      drawMyImage(node)
    }
  }, [])

  const toNumber = (val: number) => {
    return Math.floor(val * 1000) / 1000
  }

  const getMaxSize = () => {
    let width = 300
    let height = 200

    return { width, height }
  }

  const drawMyImage = async (elem: any) => {
    if (elem) {
      const maxSize = getMaxSize()
      const img = await file.base64ToImage(floorImage)
      const newSize: any = await file.reduceByDimension(img, maxSize)
      const { width, height } = newSize
      elem.width = width
      elem.height = height

      const ctx = elem.getContext('2d')
      ctx.drawImage(img, 0, 0, width, height)

      setImgSize({
        original: { width: img.width, height: img.height },
        current: { width, height },
      })
    }
  }

  let pinItem
  if (pin && imgSize) {
    const { current, original } = imgSize
    const ratio = toNumber(current.width / original.width)
    const location = toLocalPosition(
      toNumber(+pin.XAxis * ratio),
      toNumber(+pin.YAxis * ratio),
      pinSize.width,
      pinSize.height
    )
    const cssPin = {
      top: `${location.y}px`,
      left: `${location.x}px`,
    }
    const color = getColor(pin.PinStatus)
    const cssNum = {
      left: pin.PinNumber > 9 ? '28%' : '36%',
    }
    pinItem = (
      <EditPin style={cssPin}>
        <PinIcon width={pinSize.width} height={pinSize.height} color={color} />
        <span style={cssNum}>{pin.PinNumber}</span>
      </EditPin>
    )
  }

  return (
    <MainView>
      <Plan>
        <PlanImg>
          <Canvas ref={onRefCanvas} />
          {pinItem}
        </PlanImg>
      </Plan>
    </MainView>
  )
}

const MainView = styled.div`
  width: 100%;
`

const Plan = styled.div`
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: white;
  margin: 0 auto;
`

const PlanImg = styled.div`
  position: relative;
  margin: 0 auto;
  padding: 0px;
  width: 300px;
  height: 200px;
`

export const Canvas = styled.canvas``

const Pin: any = styled.span`
  position: absolute;

  span {
    color: white;
    font-size: 14px;
    position: absolute;
    top: 6%;
  }
`

const EditPin = styled(Pin)``

export default PinView
