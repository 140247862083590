import React from 'react'
import styled from 'styled-components'
import { Row, Col, Layout } from 'antd'
import { PrimaryButton } from 'Components/Shared/Button'

import { DefectItem } from 'features/report/zone/types'
const { Footer } = Layout

type Props = {
  onDelete: () => void
  onSave: () => void
  defect: DefectItem
  requireLocation?: boolean
  addMode?: boolean
}

const DefectFooter: React.FC<Props> = ({
  onDelete,
  onSave,
  defect,
  requireLocation = false,
  addMode = true,
}) => {
  const jobEmpty = defect.JobTypeID <= 0
  const subjobEmpty = defect.SubJobTypeID <= 0
  const detailEmpty = defect.DetailID <= 0
  const locationEmpty = requireLocation ? defect.LocationID <= 0 : false
  const mcEmpty = defect.MCVendorCode === ''

  const beforeImgEmpty = defect.ImageBefore === undefined
  const disableSave =
    jobEmpty ||
    subjobEmpty ||
    detailEmpty ||
    locationEmpty ||
    beforeImgEmpty ||
    mcEmpty

  return (
    <Footer className="detail-page-footer">
      <FooterContent>
        <Row type="flex" justify="space-between" align="middle">
          <Col>
            {addMode && (
              <Button>
                <PrimaryButton text="ลบ" onClick={onDelete} btnType="Cancel" />
              </Button>
            )}
          </Col>
          <Col>
            <Row type="flex" align="middle">
              <Col>
                <Button>
                  <PrimaryButton
                    text="บันทึก"
                    disabled={disableSave}
                    onClick={onSave}
                  />
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </FooterContent>
    </Footer>
  )
}

const FooterContent = styled.div`
  max-width: 1110px;
  margin: 0 auto;
  color: #000;
  font-size: 20px;
  padding-right: 20px;
`

const Button = styled.div`
  width: 160px;
`

export default DefectFooter
