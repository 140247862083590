import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { connect, useDispatch } from 'react-redux'
import { RouteComponentProps, Link } from 'react-router-dom'
import RootState from 'typings/RootState'
import { BreadcrumbLink } from 'Components/Shared/BreadCrumb/BreadCrumb'
import Pagination from 'Components/Shared/Pagination'
import {
  userEmailSelector,
  serviceTokenSelector,
} from 'features/sensitive/sensitive.selector'
import {
  selectData,
  selectEvent,
  selectSorting,
} from 'features/qc/zone/checking/selector'
import {
  GetCheckingZoneByQC,
  AcceptCheckingZoneByQC,
  RejectCheckingZoneByQC,
} from 'features/qc/zone/checking/api'
import {
  GetCheckingZoneReq,
  Data,
  AcceptCheckingTaskReq,
  RejectCheckingTaskReq,
} from 'features/qc/zone/checking/types'
import { sortingTask } from 'features/qc/zone/checking/actions'
import { StructureListPage } from 'Components/Shared/Seemore'
import { NoCard, EventModal, Loading } from 'Components/Shared'
import PageIndex from 'Components/Shared/Pagination/PageIndex'
import { RowItem, TaskFooter, TableHeader } from 'Components/QC/ProjectDetail'
import { Row, Col } from 'antd'
import { PrimaryButton } from 'Components/Shared/Button'
import { ZoneFilterPage } from 'Components/Filter'
import { selectZoneFilter } from 'features/filter/selector'
import { getEvent } from 'features/wbs/wbs.selector'

type Props = ReduxProps &
  DispatchProps &
  RouteComponentProps<{ project: string }>

const TodoList: React.ComponentType<Props> = props => {
  const {
    match,
    data,
    event,
    sorting,
    token,
    UserEmail,
    filter,
    wbsEvent,
  } = props
  const { params } = match
  const { project } = params
  const [taskSelect, setTaskSelect] = useState([] as any[])
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [enableModal, setEnableModal] = useState(false)
  const { List, Total } = data

  const dispatch = useDispatch()

  useEffect(() => {
    callGetCheckingZone()
  }, [project, perPage, page])

  const callGetCheckingZone = () => {
    const data: GetCheckingZoneReq = {
      ProjectCode: project,
      UserEmail,
      Zone: filter.ZoneList,
      Floor: filter.FloorList,
      CheckNumber: filter.CheckNumber,
      WbsList: filter.LeafWbsList,
      Page: page,
      RowPerPage: perPage,
    }
    dispatch(GetCheckingZoneByQC(token, data))
  }

  const onCallSuccess = () => {
    callGetCheckingZone()
    setTaskSelect([])
  }

  const acceptTask = (date: string) => {
    setEnableModal(true)
    const req: AcceptCheckingTaskReq = {
      ProjectCode: project,
      UserEmail,
      AppointmentDate: date,
      TaskList: taskSelect,
    }
    dispatch(AcceptCheckingZoneByQC(token, req))
  }

  const rejectTask = () => {
    setEnableModal(true)
    const req: RejectCheckingTaskReq = {
      ProjectCode: project,
      UserEmail,
      TaskList: taskSelect,
    }
    dispatch(RejectCheckingZoneByQC(token, req))
  }

  let projectName = `${data.ProjectCode} - ${data.ProjectNameTH}`

  const breadcrumbs: BreadcrumbLink[] = [
    {
      url: '/zone/dashboard',
      title: 'Home',
      icon: '/home.png',
      active: true,
      showTitle: false,
    },
    {
      url: '/zone/dashboard',
      title: 'บอร์ด',
      active: true,
      showTitle: true,
    },
    {
      url: '/',
      title: projectName,
      active: false,
      showTitle: false,
    },
  ]

  const onSort = (name: string) => {
    dispatch(sortingTask({ name }))
  }

  useEffect(() => {
    setEnableModal(false)
  }, [List])

  const renderContent = () => {
    return List.map((item: any, index) => {
      const isSelect = verifySelected(item)
      return (
        <RowItem
          task={item}
          key={index}
          onClick={() => onSelectTask(item)}
          isSelected={isSelect}
          type="zone"
        />
      )
    })
  }

  const remove = (data: Data) => {
    return taskSelect.filter(
      item =>
        item.Unit !== data.Unit ||
        item.QCTypeID !== data.QCTypeID ||
        item.RequestID !== data.RequestID ||
        item.Floor !== data.Floor
    )
  }

  const verifySelectAll = () => {
    let isSelectAll = true
    List.map(item => {
      const isSelect = verifySelected(item)
      if (!isSelect) {
        isSelectAll = false
        return
      }
    })
    return isSelectAll
  }

  const verifySelected = (data: Data) => {
    const selectData = taskSelect.filter(item => {
      return (
        item.Unit === data.Unit &&
        item.QCTypeID === data.QCTypeID &&
        item.RequestID === data.RequestID &&
        item.Floor === data.Floor
      )
    })
    return selectData.length > 0 ? true : false
  }

  const onSelectTask = (item: Data) => {
    const isSelect = verifySelected(item)
    let newValue = taskSelect
    if (isSelect) {
      newValue = remove(item)
    } else {
      newValue.push({
        Unit: item.Unit,
        QCTypeID: item.QCTypeID,
        RequestID: item.RequestID,
        Floor: item.Floor,
      })
    }
    setTaskSelect([...newValue])
  }

  const onSelectAll = () => {
    const isSelectAll = verifySelectAll()
    let newValue = taskSelect
    if (isSelectAll) {
      newValue = []
    } else {
      List.map(item => {
        newValue.push({
          Unit: item.Unit,
          QCTypeID: item.QCTypeID,
          RequestID: item.RequestID,
          Floor: item.Floor,
        })
      })
    }
    setTaskSelect([...newValue])
  }

  const pagination = (
    <Pagination
      perPage={perPage}
      totalRows={Total}
      currentPage={page}
      onClickPage={page => setPage(page)}
      onChangeNumber={number => setPerPage(number)}
    />
  )

  const tableHeader = (
    <TableHeader
      onSort={name => onSort(name)}
      sorting={sorting}
      isSelectAll={verifySelectAll()}
      onSelectAll={onSelectAll}
      showRadio={List.length > 0}
      type="zone"
    />
  )

  const footer = (
    <TaskFooter
      taskSelect={taskSelect}
      onClick={date => acceptTask(date)}
      isLoading={event.IsLoading}
    />
  )

  const pageIndex = (
    <PageIndex
      currentPage={page}
      totalRows={Total}
      perPage={perPage}
      onClick={page => setPage(page)}
    />
  )

  const loading = (
    <div style={{ textAlign: 'center' }}>
      <Loading />
    </div>
  )

  const content =
    data.List && data.List.length > 0 ? (
      renderContent()
    ) : (
      <NoCard text={`ไม่มีรายการ Request`} />
    )

  const filterContent = (
    <Row type="flex" justify="space-between" align="middle">
      <Col>
        <ZoneFilterPage
          onSearch={() => {
            setPage(1)
            callGetCheckingZone()
          }}
          ProjectCode={project}
        />
      </Col>
      <Col>
        <Btn>
          <PrimaryButton
            text="Reject Request"
            disabled={taskSelect.length <= 0 || event.IsLoading}
            onClick={rejectTask}
            btnType="Cancel"
          />
        </Btn>
      </Col>
    </Row>
  )

  return (
    <>
      <EventModal
        event={[event, wbsEvent]}
        enable={enableModal}
        successCalback={onCallSuccess}
      />
      <StructureListPage
        breadcrumbs={breadcrumbs}
        tableHeader={tableHeader}
        pagination={pageIndex}
        filterHeader={filterContent}
        tableContent={event.IsLoading && !enableModal ? loading : content}
        title={projectName}
        footerPagination={pagination}
        footer={footer}
      />
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    data: selectData(state),
    event: selectEvent(state),
    sorting: selectSorting(state),
    token: serviceTokenSelector(state),
    UserEmail: userEmailSelector(state),
    filter: selectZoneFilter(state),
    wbsEvent: getEvent(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {
  sortingTask,
}

type DispatchProps = typeof mapDispatchToProps

const Btn = styled.div`
  width: 160px;
`

export default connect(mapStateToProps, mapDispatchToProps)(TodoList)
