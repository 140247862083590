import withAPIAction from 'utils/withAPIAction'
import { getZoneDashbaordAction } from './actions'
import { GetZoneDashboardReq } from './types'

export const getZoneDashboard = (token: string, data: GetZoneDashboardReq) => {
  if (token === '') {
    return { type: 'tasklane/no_token' }
  }
  if (data.ProjectCode === '') {
    return { type: 'activity/PAYLOAD_NOT_READY' }
  }

  return withAPIAction<GetZoneDashboardReq>({
    method: 'POST',
    path: 'GetDashboardForZone',
    data,
    token: `Bearer ${token}`,
  })(getZoneDashbaordAction)
}
