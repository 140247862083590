import withAPIAction from 'utils/withAPIAction'
import { verifyReq } from 'utils/verify'
import { GetActivitySeeMoreForQCAction } from './actions'

import { GetActivitySeemoreRequest } from './types'

export const getActivitySeeMoreForQCapi = (
  token: string,
  data: GetActivitySeemoreRequest
) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<GetActivitySeemoreRequest>({
    method: 'POST',
    path: 'GetActivitySeeMoreForQC',
    data,
    token: `Bearer ${token}`,
  })(GetActivitySeeMoreForQCAction)
}
