import { sortingList, sortingByMultiParams } from '../../../utils/helper'

export const sortingListConfig = (
  list: any[],
  type: string,
  name: string | undefined
): any[] => {
  try {
    let newList: any[] = list
    switch (name) {
      case 'ID':
        newList = sortingList(list, type, name, 'number')
        break
      default:
        newList = sortingList(list, type, name || '', 'string')
    }

    return newList
  } catch (e) {
    return list
  }
}
