import { getType } from 'typesafe-actions'
import * as actions from './actions'
import { StoreState, ConstrucAction } from './types'
import produce from 'immer'
import { Event } from '../../types/event'
import { cloneDeep } from 'lodash'
import parseErrorMessage from 'utils/parseErrorMessage'

const initEvent = {
  IsLoading: false,
  MessageSuccess: undefined,
  SubmitError: undefined,
  SubmitSuccess: false,
  SubmitType: undefined,
} as Event

const initialState: StoreState = {
  projects: [],
  event: cloneDeep(initEvent),
}

export default function(
  state: StoreState = initialState,
  action: ConstrucAction
): StoreState {
  return produce(state, draft => {
    switch (action.type) {
      case getType(actions.GetConstructPlanProjectAction.request):
      case getType(actions.GetAuditPlanProjectAction.request):
        draft.event = cloneDeep(initEvent)
        draft.event.IsLoading = true
        draft.projects = []
        break
      case getType(actions.GetConstructPlanProjectAction.success):
      case getType(actions.GetAuditPlanProjectAction.success):
        const res = action.payload.ResponseData
        draft.event.IsLoading = false
        draft.projects = action.payload.ResponseData || []
        break

      case getType(actions.CreateConstructPlanAction.request):
      case getType(actions.CreateAuditPlanAction.request):
        draft.event = cloneDeep(initEvent)
        draft.event.IsLoading = true
        break
      case getType(actions.CreateConstructPlanAction.success):
      case getType(actions.CreateAuditPlanAction.success):
        draft.event.IsLoading = false
        draft.event.SubmitError = undefined
        draft.event.SubmitSuccess = true
        draft.event.MessageSuccess = 'ทำรายการสำเร็จ'
        break

      case getType(actions.GetConstructPlanProjectAction.failure):
      case getType(actions.CreateConstructPlanAction.failure):
      case getType(actions.GetAuditPlanProjectAction.failure):
      case getType(actions.CreateAuditPlanAction.failure):
        draft.event.IsLoading = false
        draft.event.SubmitError = parseErrorMessage(action.payload)
        break

      default:
        return state
    }
  })
}
