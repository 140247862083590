import React, { useState } from 'react'
import styled from 'styled-components'
import { PrimaryButton } from 'Components/Shared/Button'
import { Row, Col } from 'antd'

import { ConstructPlanReport } from 'features/report/constructPlan/types'

type Props = {
  report: ConstructPlanReport
  onResend: () => void
}

const RejtFooter: React.ComponentType<Props> = props => {
  const { report, onResend } = props
  const invalidData = () => {
    const { CheckNumber } = report
    const result = report.DefectList.find(item => {
      return (
        CheckNumber === item.CheckNumber &&
        item.PinStatus === 'ToBeFix' &&
        item.VerifyStatus === 'None'
      )
    })
    return result !== undefined
  }

  return (
    <Footer>
      <Row type="flex" justify="space-between" align="middle">
        <Col />
        <Col>
          <Button>
            <PrimaryButton
              text="ส่งรายงาน"
              disabled={invalidData()}
              onClick={onResend}
            />
          </Button>
        </Col>
      </Row>
    </Footer>
  )
}

const Footer = styled.div`
  position: fixed;
  bottom: 0px;
  height: auto;
  width: 100%;
  background-color: white;
  padding: 22px 80px;
  z-index: 20;
`

const Button = styled.div`
  width: 200px;
  margin-left: 16px;
  float: left;
`

const IconBtn = styled.div`
  img {
    margin-right: 10px;
  }
`

export default RejtFooter
