import React from 'react'
import { Button, Col, Popover, Row } from 'antd'
import styled from 'styled-components'

interface Props {
  label: string
  content: any
  isActive?: boolean
  placeholder?: string
  value?: string
  disabled?: boolean
}

const DropdownPopupMenu: React.FunctionComponent<Props> = ({
  label,
  content,
  isActive = false,
  placeholder = 'กรุณาเลือก',
  value = '',
  disabled = false,
}) => {
  return (
    <Popover placement="bottomLeft" content={content} trigger="click">
      <Btn>
        <Button className={'dropdown-btn'} disabled={disabled}>
          <Row type="flex" justify="space-between">
            <Col>{label}</Col>
            <Col span={18}>
              <Row type="flex" justify="end">
                <Col className="value" span={20}>
                  <Txt className={isActive ? 'active' : ''}>
                    {isActive ? value : placeholder}
                  </Txt>
                </Col>
                <Col>
                  <span className="icon-down">
                    <img src="/img/arrow-down.svg" alt="down arrow" />
                  </span>
                </Col>
              </Row>
            </Col>
          </Row>
        </Button>
      </Btn>
    </Popover>
  )
}

const Btn = styled.div`
  .dropdown-btn {
    width: 100%;
    height: 48px;
    border-radius: 5px;
    border: solid 1px #e4e7eb;
    font-family: 'DB Helvethaica X 55 Regular';
    font-size: 20px;
    color: #000;
  }

  .ant-btn:hover,
  .ant-btn:focus,
  .ant-btn:active,
  .ant-btn.active {
    border: solid 1px #e4e7eb;
    color: #000;
  }

  .value {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  button[disabled] {
    width: 100%;
    height: 48px;
    border-radius: 5px;
    border: solid 1px #e4e7eb;
    color: black;
    text-align: left;
    background-color: #f3f3f3;
    border: solid 1px #e4e7eb;
    font-size: 20px;
    font-family: 'DB Helvethaica X 55 Regular';
  }

  button[disabled]:hover {
    background-color: #f3f3f3;
    border: solid 1px #e4e7eb;
    color: black;
  }

  button > label {
    float: left;
  }

  button[disabled] > span {
    float: right;
    font-family: 'DB Helvethaica X 75 Bd';
    padding-left: 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 80%;
  }

  button > span.placeholder {
    float: right;
    font-family: 'DB Helvethaica X 55 Regular';
  }
`

const Txt = styled.div`
  padding-right: 6px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: right;
  &.active {
    font-family: 'DB Helvethaica X 75 Bd';
  }
`

export default DropdownPopupMenu
