import React, { useState, useRef } from 'react'
import { Row, Col } from 'antd'
import styled from 'styled-components'

import InputText from 'Components/Shared/Input/InputText'
import InputNumber from 'Components/Shared/Input/InputNumber'
import JobModalLocation, { JobHandles } from './JobModalLocation'
import {
  CheckListDetail,
  QCType,
  LocationType,
  JobType,
} from 'features/checklist/unit/types'
import { DropdownList } from 'Components/Shared/Form'
import { SelectType } from '..'
import { TrashIcon, EditIcon } from 'Components/Shared/Icon'
import { BottomButton, AddList } from 'Components/Shared/Button'

interface Props {
  selected: SelectType
  checkListDetail: CheckListDetail
  editingObj?: JobType
  onSave: (job: JobType) => void
  closeModal: () => void
  disabled: boolean
}

const JobModal: React.FC<Props> = ({
  checkListDetail,
  selected,
  editingObj,
  onSave: onSaveProp,
  closeModal,
  disabled,
}) => {
  const modalRef = useRef<JobHandles>(null)
  const [error, seterror] = useState('')
  const [name, setname] = useState(editingObj ? editingObj.JobTypeName : '')
  const [point, setpoint] = useState<number | undefined>(
    editingObj && editingObj.Score
  )
  const [qcType, setqcType] = useState<QCType>(
    checkListDetail.QCTypeList[selected.qcType]
  )
  const [locationList, setlocationList] = useState<LocationType[]>(
    editingObj ? editingObj.LocationList : []
  )

  const showModal = (item?: LocationType) => {
    if (modalRef.current) {
      modalRef.current.showModal(item)
    }
  }

  const selectQcType = (item: QCType) => {
    setqcType(item)
  }
  const changeName = (val: string) => {
    setname(val)
  }
  const changePoint = (val: number) => {
    setpoint(val)
  }
  const addLocation = (item: LocationType) => {
    const newLocation = locationList.concat(item)
    setlocationList(newLocation)
  }
  const updateLocation = () => {
    setlocationList([...locationList])
  }

  const deleteLocation = (index: number) => {
    locationList.splice(index, 1)
    setlocationList([...locationList])
  }

  const onSave = () => {
    if (name !== '' && locationList.length !== 0 && point != null && qcType) {
      const found = editingObj
        ? null
        : qcType.JobTypeList.find(v => v.JobTypeName === name)
      if (found == null) {
        onSaveProp({
          JobTypeName: name,
          Score: point,
          LocationList: locationList,
          SubJobTypeList: editingObj ? editingObj.SubJobTypeList : [],
        })
      } else {
        seterror('ลักษณะงานหลักนี้ ได้มีอยู่แล้ว')
      }
    } else {
      seterror('กรุณาใส่ข้อมูลทั้งหมด')
    }
  }

  const menuChoice = () => {
    const result = checkListDetail.QCTypeList.map((item: QCType) => (
      <DataItem key={item.QCTypeID} onClick={() => selectQcType(item)}>
        {item.QCTypeName}
      </DataItem>
    ))
    return result
  }

  return (
    <MainView>
      <Row style={{ paddingTop: '20px' }}>
        <Col span={12} style={{ paddingRight: '7px' }}>
          <InputText
            prefix="ชื่อ"
            value={name}
            onChange={changeName}
            placeholder="กรอกชื่อ"
            openWithFocus
            disabled={disabled}
          />
        </Col>

        <Col span={12} style={{ paddingLeft: '7px' }}>
          <InputNumber
            prefix="คะแนนเต็มกลุ่ม"
            value={point}
            placeholder="กรอกคะแนน"
            onChange={changePoint}
            disabled={disabled}
          />
        </Col>
      </Row>
      <DropdownBox>
        <div className="dropdown-item">
          <DropdownList
            label="หมวดงาน"
            value=""
            placeholder={qcType ? qcType.QCTypeName : 'กรุณาเลือก'}
            menuChoice={menuChoice()}
            disabled={!!editingObj}
          />
        </div>
      </DropdownBox>
      <Title>Location</Title>
      <LocationContent>
        {locationList.map((item: LocationType, index: number) => {
          return (
            <LocationItem key={index}>
              <label>{item.LocationName}</label>
              {!disabled && (
                <IconDiv>
                  <IconStyle onClick={() => showModal(item)}>
                    <EditIcon color="#8b99a7" size={16} />
                  </IconStyle>
                  <IconStyle>
                    <TrashIcon
                      onClick={() => deleteLocation(index)}
                      color="#8b99a7"
                      size={16}
                    />
                  </IconStyle>
                </IconDiv>
              )}
            </LocationItem>
          )
        })}
        {!disabled && <AddList onClick={() => showModal()} />}
      </LocationContent>

      {!disabled && (
        <BottomButton closeModal={closeModal} onSave={onSave} error={error} />
      )}
      <JobModalLocation
        ref={modalRef}
        addLocation={addLocation}
        updateLocation={updateLocation}
      />
    </MainView>
  )
}

const MainView = styled.div``

const Title = styled.div`
  padding-top: 17px;
  font-size: 20px;
  color: #000000;
  font-family: 'DB Helvethaica X 75 Bd';
`

const DropdownBox = styled.div`
  padding-top: 14px;
  .dropdown-item {
    padding: 6px 0;
  }
`

const LocationContent = styled.div`
  width: 100%;
  height: 232px;
  border-radius: 5px;
  border: solid 1px #eeeff2;
  background-color: #ffffff;
  overflow: hidden;
  overflow-y: scroll;
`

const LocationItem = styled.div`
  font-family: 'DB Helvethaica X 55 Regular';
  font-size: 20px;
  margin: 0 auto;
  width: 90%;
  height: 56px;
  align-items: center;
  display: flex;
  border: 0px solid #e8ebf0;
  border-bottom-width: 1px;
  display: flex;
  justify-content: space-between;
`

const DataItem = styled.div`
  padding: 5px 10px;

  &:hover {
    cursor: pointer;
    color: white;
    background-color: #003168;
  }
`
const IconDiv = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`

const IconStyle = styled.div`
  padding: 0 9px;
  cursor: pointer;
`

export default JobModal
