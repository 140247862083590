import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'antd'
import { SwitchFunction } from 'Components/Defect'

type Props = {
  project: string
  RequestID: number
  report: any
}

const PageFooter: React.ComponentType<Props> = props => {
  const { report, project, RequestID } = props

  return (
    <Footer>
      <Row>
        <Col>
          <SwitchFunction
            readonly={true}
            RequestID={RequestID}
            projectCode={project}
            report={report}
          />
        </Col>
      </Row>
    </Footer>
  )
}

const Footer = styled.div`
  position: fixed;
  bottom: 0px;
  height: auto;
  width: 100%;
  background-color: white;
  padding: 22px 80px;
  z-index: 20;
`

export default PageFooter
