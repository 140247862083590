import { createStandardAction, createAsyncAction } from 'typesafe-actions'
import {
  GetReportReq,
  GetReportResp,
  GetCheckListReq,
  GetCheckListResp,
  DefectTypeReq,
  DefectItem,
  SendDefectReq,
  ConstructRoadReport,
  CommonResp,
  GetSelectPinReq,
  GetSelectPinResp,
  UpdateSelectPinReq,
} from './types'

export const getReportAction = createAsyncAction(
  'report/constructRoad/FETCH_REPORT_REQUEST',
  'report/constructRoad/FETCH_REPORT_SUCCESS',
  'report/constructRoad/FETCH_REPORT_FAILURE'
)<GetReportReq, GetReportResp, any>()

export const getCheckListAction = createAsyncAction(
  'report/constructRoad/GET_CHECKLIST_QC_REQ',
  'report/constructRoad/GET_CHECKLIST_QC_RESP',
  'report/constructRoad/GET_CHECKLIST_QC_FAIL'
)<GetCheckListReq, GetCheckListResp, any>()

export const resetDefect = createStandardAction(
  'report/constructRoad/RESET_DEFECT_ACTION'
)<void>()

export const updateDefect = createStandardAction(
  'report/constructRoad/UPDATE_DEFECT_ACTION'
)<DefectItem>()

export const createDefectAction = createAsyncAction(
  'report/constructRoad/CREATE_DEFECT_REQ',
  'report/constructRoad/CREATE_DEFECT_RESP',
  'report/constructRoad/CREATE_DEFECT_FAIL'
)<DefectTypeReq, CommonResp, any>()

export const updateDefectAction = createAsyncAction(
  'report/constructRoad/UPDATE_DEFECT_REQ',
  'report/constructRoad/UPDATE_DEFECT_RESP',
  'report/constructRoad/UPDATE_DEFECT_FAIL'
)<DefectTypeReq, CommonResp, any>()

export const updateReport = createStandardAction(
  'report/constructRoad/UPDATE_REPORT_ACTION'
)<ConstructRoadReport>()

export const submitDefectReq = createStandardAction(
  'report/constructRoad/SUBMIT_REQUEST'
)<SendDefectReq>()

export const resetEvent = createStandardAction(
  'report/constructPlan/RESET_EVENT_ACTION'
)<void>()

export const getSelectPinAction = createAsyncAction(
  'report/constructRoad/GET_SELECT_PIN_REQ',
  'report/constructRoad/GET_SELECT_PIN_RESP',
  'report/constructRoad/GET_SELECT_PIN_FAIL'
)<GetSelectPinReq, GetSelectPinResp, any>()

export const updateSelectedPintAction = createAsyncAction(
  'report/constructRoad/UPDATE_SELECTED_PIN_REQ',
  'report/constructRoad/UPDATE_SELECTED_PIN_RESP',
  'report/constructRoad/UPDATE_SELECTED_PIN_FAIL'
)<UpdateSelectPinReq, CommonResp, any>()
