import { createStandardAction, createAsyncAction } from 'typesafe-actions'
import { GetApiResponse, PaginationType } from '../../types/common'
import { BackwardUnitReq, CommonResp, GetDataListResp, Filter } from './types'
import { History } from 'history'

export const reset = createStandardAction('adminqc/backward-unit/RESET_ACTION')<
  void
>()

export const updateFilter = createStandardAction(
  'adminqc/backward-unit/UPDATE_FILTER_ACTION'
)<Filter, void>()

export const clearFilter = createStandardAction(
  'adminqc/backward-unit/CLEAR_FILTER_ACTION'
)<void>()

export const sortingTask = createStandardAction(
  'adminqc/backward-unit/SORTING'
)<{
  name: string
  sort?: string
}>()

export const getDataListAction = createAsyncAction(
  'adminqc/backward-unit/GET_CONFIG_LIST_REQUEST',
  'adminqc/backward-unit/GET_CONFIG_LIST_SUCCESS',
  'adminqc/backward-unit/GET_CONFIG_LIST_FAILURE'
)<PaginationType, GetDataListResp, Error>()

export const updateDataAction = createAsyncAction(
  'adminqc/backward-unit/UPDATE_CONFIG_DETAIL_REQUEST',
  'adminqc/backward-unit/UPDATE_CONFIG_DETAIL_SUCCESS',
  'adminqc/backward-unit/UPDATE_CONFIG_DETAIL_FAILURE'
)<BackwardUnitReq, CommonResp, any>()

export const resetEvent = createStandardAction(
  'adminqc/backward-unit/RESET_EVENT_ACTION'
)<void>()
