import RootState from 'typings/RootState'
import { DashboardFilter, DashboardQuery } from '../qcdashboard/types'
import { Event } from '../../types/event'
import { Activity } from './types'

export const selectData = (state: RootState) =>
  state.cmpjSeemore.data as Activity

export const selectEvent = (state: RootState): Event => state.cmpjSeemore.event

export const selectSorting = (state: RootState) => state.cmpjSeemore.sorting

export const selectFilter = (state: RootState): DashboardFilter =>
  state.cmpjSeemore.filter

export const selectQuery = (state: RootState): DashboardQuery =>
  state.cmpjSeemore.query
