import React, { useState } from 'react'
import RootState from 'typings/RootState'
import { connect, useDispatch } from 'react-redux'
import styled from 'styled-components'
import { Layout, Col, Row } from 'antd'

import { loginVendorReq } from 'features/sensitive/sensitive.api'

import { InputText } from 'Components/Shared/Input'
import { SecondaryButton } from 'Components/Shared/Button'

type LoginProps = {}

type Props = ReduxProps & DispatchProps & LoginProps

const MainconLoginPage: React.FunctionComponent<Props> = ({}) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [invalid, setInvalid] = useState(false)
  const dispatch = useDispatch()

  const onLogin = () => {
    if (email === '') {
      setInvalid(true)
      return
    }

    // verify auth credentials
    const text = `${email}:${password}`
    const bufferOne = Buffer.from(text)
    const token = bufferOne.toString('base64')

    dispatch(loginVendorReq(token))
  }

  const onChangeEmail = (email: string) => {
    setEmail(email)
  }

  return (
    <PageDiv>
      <Row justify="center" type="flex">
        <Col span={6}>
          <Row type="flex" justify="center">
            <Col span="24">
              <InputText
                prefix=""
                value={email}
                placeholder="Username"
                onChange={onChangeEmail}
              />
            </Col>
            {invalid && (
              <Col span="24">
                <WarningText>username ไม่ถูกต้อง</WarningText>
              </Col>
            )}

            <Col span="24">
              <InputText
                prefix=""
                value={password}
                placeholder="รหัสผ่าน"
                onChange={(val: string) => setPassword(val)}
                type="password"
              />
            </Col>
            <Col span="24">
              <BTN>
                <SecondaryButton
                  text="เข้าสู่ระบบ"
                  disabled={invalid}
                  onClick={onLogin}
                />
              </BTN>
            </Col>
          </Row>
        </Col>
      </Row>
    </PageDiv>
  )
}

const PageDiv = styled.div`
  width: 100%;
  .input-title {
    text-align: left;
    margin-bottom: 5px;
  }
  .ant-input-affix-wrapper {
    margin-top: 12px;
  }
`

const BTN = styled.div`
  margin-top: 42px;
`

const WarningText = styled.div`
  text-align: left;
  font-family: 'DB Helvethaica X 55 Regular';
  font-size: 20px;
  color: #d0494c;
`

const mapStateToProps = (state: RootState) => {
  return {}
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(MainconLoginPage)
