import React, { useState, useEffect } from 'react'
import { Modal, Row, Col } from 'antd'
import styled from 'styled-components'

import InputText from 'Components/Shared/Input/InputText'
import PrimaryButton from 'Components/Shared/Button/PrimaryButton'
import OutlineButton from 'Components/Shared/Button/OutlineButton'
import { RadioBox, DropdownList } from 'Components/Shared/Form'

import CloseIcon from 'Components/Shared/Icon/CloseIcon'
import { CheckListDetail } from 'features/checklist/master/types'
import { InspectionList } from 'features/types/checklist'

interface Props {
  visible?: boolean
  onClose: () => void
  onOk: (
    val: string,
    iCate: number,
    iItem: number,
    iSubItem: number,
    seq: string
  ) => void
  isEditMode?: boolean
  initCategory?: number
  initItem?: number
  initSubItem?: number
  checklist: CheckListDetail
  readOnly?: boolean
}

const AddModal: React.FC<Props> = ({
  visible = false,
  onClose,
  onOk,
  isEditMode = false,
  initCategory = -1,
  initItem = -1,
  initSubItem = -1,
  checklist,
  readOnly = false,
}) => {
  const [name, setName] = useState('' as string)
  const [checked, setChecked] = useState(0 as number)
  const [indexCategory, setIndexCategory] = useState(-1)
  const [indexItem, setIndexItem] = useState(-1)
  const [indexSubItem, setIndexSubItem] = useState(-1)

  const [inspectionList, setInspectionList] = useState([] as InspectionList[])

  useEffect(() => {
    let checkVal = 0

    let txtName = ''
    if (isEditMode) {
      txtName = getName(
        initCategory,
        initItem,
        initSubItem,
        checklist.InspectionList
      )
      checkVal = initSubItem !== -1 ? 2 : initItem !== -1 ? 1 : 0
    } else {
      checkVal = initCategory === -1 ? 0 : initItem === -1 ? 1 : 2
    }

    setChecked(checkVal)
    setIndexCategory(initCategory)
    setIndexItem(initItem)
    setIndexSubItem(initSubItem)
    setName(txtName)
  }, [initCategory, initItem, initSubItem, visible])

  useEffect(() => {
    setInspectionList(checklist.InspectionList)
  }, [checklist])

  const onSave = () => {
    let seq
    if (indexCategory !== -1) {
      if (indexItem !== -1) {
        if (indexSubItem !== -1) {
          seq = `${indexCategory + 1}.${indexItem + 1}.${indexSubItem + 1}`
        } else {
          const temp = inspectionList[indexCategory].Data[indexItem].Data
          const i = temp ? temp.length + 1 : 1
          seq = `${indexCategory + 1}.${indexItem + 1}.${i}`
        }
      } else {
        const temp = inspectionList[indexCategory].Data
        const i = temp ? temp.length + 1 : 1
        seq = `${indexCategory + 1}.${i}`
      }
    } else {
      seq = `${inspectionList.length + 1}`
    }

    const cateIndex = checked >= 0 ? indexCategory : -1
    const itIndex = checked >= 1 ? indexItem : -1
    const itSubIndex = checked >= 2 ? indexSubItem : -1

    onOk(name, cateIndex, itIndex, itSubIndex, seq)
  }

  const getName = (
    iCate: number,
    iItem: number,
    iSubItem: number,
    list: InspectionList[]
  ) => {
    let txt = ''
    if (iCate !== -1) {
      txt =
        iItem === -1
          ? list[iCate].InspectionCategory
          : iSubItem === -1
          ? list[iCate].Data[iItem].Name
          : list[iCate].Data[iItem].Data[iSubItem].Name
    }

    return txt
  }

  const onCategory = (val: any, index: number) => {
    setIndexCategory(index)
  }

  const onItem = (val: any, index: number) => {
    setIndexItem(index)
  }

  const renderCheckBox = () => {
    const checkOptions = [
      'เป็นรายการหลัก',
      'เป็นรายการย่อยระดับ 1',
      'เป็นรายการย่อยระดับ 2',
    ]
    const checkbox = checkOptions.map((item, index) => {
      return (
        <Col span={8} key={index}>
          <RadioBox
            value={index}
            label={item}
            isCheck={index == checked}
            disabled={isEditMode}
            onClick={value => setChecked(value)}
          />
        </Col>
      )
    })
    return (
      <CheckBoxSection>
        <Row>{checkbox}</Row>
      </CheckBoxSection>
    )
  }

  const menuCategory = inspectionList.map((item, index) => {
    return {
      name: item.InspectionCategory,
      value: index,
    }
  })

  let menuItem: any[] = []
  if (visible && indexCategory !== -1 && inspectionList.length > 0) {
    const cateItem = inspectionList[indexCategory]
    if (cateItem.Data) {
      menuItem = cateItem.Data.map((item, index) => {
        return {
          name: item.Name,
          value: index,
        }
      })
    }
  }

  let disabledSave = false
  if (name === '') disabledSave = true
  else if (checked === 1 && indexCategory === -1) disabledSave = true
  else if (checked === 2 && indexItem === -1) disabledSave = true

  return (
    <ModalCheckList>
      <Modal
        centered
        visible={visible}
        width="528px"
        footer={null}
        closeIcon={<CloseIcon color="#8b99a7" size={16} />}
        title={<Header>{isEditMode ? 'แก้ไขรายการ' : 'เพิ่มรายการ'}</Header>}
        className="add-checklist-modal"
        onCancel={onClose}
      >
        <ModalContent>
          <InputText
            prefix="ชื่อ"
            placeholder="กรุณากรอกชื่อ"
            value={name}
            onChange={(value: string) => setName(value)}
            disabled={readOnly}
          />
          <SelectType>
            <Title>เลือกประเภทรายการ</Title>
            {renderCheckBox()}

            <DropdownBox>
              <DropdownBoxItem>
                <DropdownList
                  label="รายการหลัก"
                  value={indexCategory}
                  placeholder="กรุณาเลือก"
                  menu={menuCategory}
                  disabled={
                    isEditMode || checked < 1 || menuCategory.length === 0
                  }
                  onChange={onCategory}
                />
              </DropdownBoxItem>

              <DropdownBoxItem>
                <DropdownList
                  label="รายการย่อย"
                  value={indexItem}
                  placeholder="กรุณาเลือก"
                  menu={menuItem}
                  disabled={isEditMode || checked < 2 || menuItem.length === 0}
                  onChange={onItem}
                />
              </DropdownBoxItem>
            </DropdownBox>
          </SelectType>
          <ButtonSection>
            <Row>
              <Col span={7} offset={9}>
                <OutlineButton text="ยกเลิก" onClick={onClose} />
              </Col>
              <Col span={7} offset={1}>
                <PrimaryButton
                  text="บันทึก"
                  disabled={disabledSave || readOnly}
                  onClick={onSave}
                />
              </Col>
            </Row>
          </ButtonSection>
        </ModalContent>
      </Modal>
    </ModalCheckList>
  )
}

const Header = styled.span`
  font-size: 28px;
  color: #000000;
  font-family: 'DB Helvethaica X 75 Bd';
`
const Title = styled.span`
  font-size: 20px;
  color: #000000;
  font-family: 'DB Helvethaica X 75 Bd';
`

const ModalCheckList = styled.div`
  .add-checklist-modal {
    .ant-modal-header {
      border-bottom: none;
    }
  }
`

const ModalContent = styled.div`
  padding: 0 24px 31px 24px;
`

const SelectType = styled.div`
  padding-top: 24px;
`

const CheckBoxSection = styled.div`
  padding-top: 16px;
`

const DropdownBox = styled.div`
  padding-top: 18px;
`

const DropdownBoxItem = styled.div`
  padding: 6px 0;
`

const ButtonSection = styled.div`
  padding-top: 18px;
`

export default AddModal
