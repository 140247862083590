import { all, take, put, select, takeEvery, fork } from 'redux-saga/effects'
import { getType } from 'typesafe-actions'
import * as actions from './login.actions'
import {
  resetState,
  getUserProfileAsyncAction,
} from 'features/sensitive/sensitive.actions'
import { getUserProfileRequest } from 'features/sensitive/sensitive.api'
import account from 'utils/account'
import { tokenExpiredSelector } from 'features/project/project.selector'
import { msalApp, GRAPH_REQUESTS } from 'utils/auth-utils'
import history from 'utils/history'
import { updateLoginMessage } from 'features/login/login.actions'
import { translate, translateWithVars } from 'i18n'
import TKeys from 'i18n/translationKey'

export const delay = (ms: number) => new Promise(res => setTimeout(res, ms))

function* clearLoginCredentials() {
  yield put(updateLoginMessage(''))

  const shouldClearCredentials = yield select(tokenExpiredSelector)

  if (shouldClearCredentials) {
    account.clearStorage()

    // If anything has to be done after state has been cleared, that can be
    // performed here.
    yield put(resetState())
  }
}

async function login() {
  try {
    const loginResp = await msalApp.loginPopup(GRAPH_REQUESTS.LOGIN)
    const tokenResp = await msalApp.acquireTokenSilent(GRAPH_REQUESTS.LOGIN)
    let accesstoken
    let email
    let name
    if (tokenResp) {
      accesstoken = tokenResp.accessToken
    }

    if (loginResp) {
      email = loginResp.account.userName
      name = loginResp.account.name
    }

    return {
      accesstoken,
      email,
      name,
      // TODO: Make sure that it's not being used anywhere then remove during refactor
      message: translate(TKeys.Login.accesstoken_acquired),
    }
  } catch (e) {
    return {
      accesstoken: null,
      email: null,
      name: null,
      // TODO: Make sure that it's not being used anywhere then remove during refactor
      message: e.message,
    }
  }
}

function* initLogin() {
  while (true) {
    try {
      yield take(getType(actions.initLogin))

      const initLoginResponse = yield login()
      const { accesstoken, email } = initLoginResponse

      if (accesstoken && email) {
        // Get token code by calling GetUserProfile and then change to dashboard
        yield put(
          updateLoginMessage(
            translateWithVars(TKeys.Login.login_get_token_for_username, {
              username: email,
            })
          )
        )
        // Uncomment below line to show virtual delay of 3000 seconds intentionally
        // to show that something is working on server
        // yield call(delay, 3000);
        account.saveAccessToken(accesstoken, email)
        yield put(updateLoginMessage(''))
        yield put(getUserProfileRequest({ email, token: accesstoken }))
      }
    } catch (e) {
      yield put(updateLoginMessage(e.message))
    }
  }
}

function* watchUserLogout() {
  while (true) {
    yield take(getType(actions.logoutUser))

    yield put(actions.updateLoginMessage(''))

    yield put(resetState())
    account.clearStorage()

    yield put(
      actions.userLoggedOut(translate(TKeys.Login.logout_success_message))
    )

    // Create virtual delay for sanity purpose to give user feeling that
    // something is working on server.
    yield put(actions.forceLogin(true))
    history.push('/')
  }
}

function* invalidLogin() {
  yield put(
    updateLoginMessage('Invalid username, try again or contact your admin.')
  )
}

function* allSaga() {
  yield all([
    fork(watchUserLogout),
    fork(initLogin),
    takeEvery(getType(actions.forceLogin), clearLoginCredentials),
    takeEvery(getType(getUserProfileAsyncAction.failure), invalidLogin),
  ])
}

export default allSaga
