import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'antd'
import { HintList, ProgressLine } from 'Components/Shared'
import { Activity } from 'features/cmpj/cmchecking/types'

type Props = {
  activityList: Activity[]
  successActivity?: number
  title?: string
  isUnit?: boolean
}

const ActivityList: React.ComponentType<Props> = props => {
  const {
    activityList,
    successActivity = 0,
    title = 'รายการตรวจสอบ QC',
    isUnit = true,
  } = props

  const renderCheckList = () => {
    return activityList.map((activity: Activity, index: number) => {
      return (
        <LineBox key={index}>
          <Row type="flex" justify="space-between" align="top">
            <Col span={13}>
              <Description>{activity.ActivityName}</Description>
            </Col>
            <Col span={10}>
              <ProgressLine task={activity} />
            </Col>
          </Row>
        </LineBox>
      )
    })
  }

  const total = activityList.length
  const task = `(${successActivity}/${total})`
  return (
    <Content>
      <TopicTitle>{title} </TopicTitle>
      {successActivity === total ? (
        <GreenTitle> {task}</GreenTitle>
      ) : (
        <GrayTitle> {task}</GrayTitle>
      )}
      {isUnit ? (
        <>
          <HintList
            hintList={[
              'ทุกรายการต้องมีความคืบหน้า 100% ก่อนเริ่มตรวจด้วยตนเองหรือส่งคำขอให้ QC',
            ]}
          />
          <HintList
            hintList={[
              'กรณีที่เร่งด่วน สามารถแบ่งตรวจได้ แต่ต้องมีเลข Memo กำกับ',
            ]}
          />
        </>
      ) : (
        <>
          <HintList
            hintList={[
              'ต้องมีความคืบหน้า 100% อย่างน้อย 1 รายการ ก่อนเริ่มตรวจด้วยตนเองหรือส่งคำขอให้ QC',
            ]}
          />
        </>
      )}
      {renderCheckList()}
    </Content>
  )
}

const Content = styled.div`
  width: calc(100% - 380px);
  height: auto;
  margin-left: 30px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.03);
  float: left;
  padding: 24px;
  padding-bottom: 0;
  margin-bottom: 10px;
`

const TopicTitle: any = styled.span`
  font-size: 24px;
  font-family: 'DB Helvethaica X 75 Bd';
`

const GreenTitle = styled(TopicTitle)`
  color: #5bc79e;
  margin-left: 5px;
`

const GrayTitle = styled(TopicTitle)`
  color: #8b99a7;
  margin-left: 5px;
`

const Description = styled.span`
  font-size: 20px;
  font-family: 'DB Helvethaica X 55 Regular';
`

const LineBox = styled.div`
  width: 100%;
  padding: 18px 0;
  border-bottom: 1px solid #f3f5f8;
`

export default ActivityList
