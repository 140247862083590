import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { RootState } from 'typings/RootState'
import styled from 'styled-components'
import { getFloorList, getZoneList } from 'features/filter/api'
import { GetFloorListReq, GetZoneListReq } from 'features/filter/types'
import { selectFloorList, selectZoneList } from 'features/filter/selector'
import { Button, Col } from 'antd'
import {
  selectedProjectCodeSelector,
  projectsByIdSelector,
} from 'features/project/project.selector'
import {
  serviceTokenSelector,
  userEmailSelector,
} from 'features/sensitive/sensitive.selector'
import { FloorFilter, ZoneFilter, ProjectsFilter } from './Components'
import { selectFilter } from 'features/pmr/selector'
import { Filter } from 'features/pmr/types'
import { updateFilter } from 'features/pmr/actions'
import { fetchProjects } from 'features/project/project.api'

type Props = {
  onSearch?: () => void
  withPadding?: boolean
} & ReduxProps &
  DispatchProps

const PMRFilter: React.FunctionComponent<Props> = props => {
  const {
    ProjectCode,
    UserEmail,
    token,
    floorList,
    zoneList,
    filter,
    onSearch = () => {},
    withPadding = false,
    projectsById,
  } = props

  useEffect(() => {
    dispatch(fetchProjects({ email: UserEmail, token }))
  }, [UserEmail])

  useEffect(() => {
    if (!filter.ProjectCode) return
    onGetFloorList()
    onGetZoneList()
  }, [filter.ProjectCode])

  const onGetFloorList = () => {
    const req: GetFloorListReq = {
      ProjectCode: filter.ProjectCode,
      UserEmail,
    }
    dispatch(getFloorList(token, req))
  }

  const onGetZoneList = () => {
    const req: GetZoneListReq = {
      ProjectCode: filter.ProjectCode,
      UserEmail,
    }
    dispatch(getZoneList(token, req))
  }

  const dispatch = useDispatch()
  const onFilterChange = (filter: Filter) => {
    dispatch(updateFilter(filter))
  }

  return (
    <MainPage className={withPadding ? 'with-padding' : ''}>
      <div className="filters">
        <Col span={24} className="filters-button">
          <ProjectsFilter
            filter={filter}
            onFilterChange={onFilterChange}
            projectsById={projectsById}
          />
          <FloorFilter
            onFilterChange={onFilterChange}
            filter={filter}
            floorList={floorList.List}
          />
          <ZoneFilter
            onFilterChange={onFilterChange}
            filter={filter}
            zoneList={zoneList.List}
          />
          <Button type="primary" onClick={onSearch}>
            กรองข้อมูล
          </Button>
        </Col>
      </div>
    </MainPage>
  )
}

const MainPage = styled.div`
  &.with-padding {
    padding: 0 120px;
  }

  background-color: #f5f6fa;
`

const mapStateToProps = (state: RootState) => {
  return {
    ProjectCode: selectedProjectCodeSelector(state),
    UserEmail: userEmailSelector(state),
    token: serviceTokenSelector(state),
    filter: selectFilter(state),
    floorList: selectFloorList(state),
    zoneList: selectZoneList(state),
    projectsById: projectsByIdSelector(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(PMRFilter)
