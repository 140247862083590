import React from 'react'
import PopupMenu from 'Components/Shared/Filters/PopupMenu'
import FloorContent from 'Components/Shared/Filters/FloorContent'

type Props = {
  onFilterChange: (filter: any) => void
  filter: any
  floorList: string[]
}

const FloorFilter: React.FunctionComponent<Props> = props => {
  const { onFilterChange, filter, floorList } = props

  return (
    <PopupMenu
      label={'ชั้น'}
      content={
        <FloorContent
          floors={floorList}
          filter={filter.FloorList}
          onChange={data => {
            filter.FloorList = data
            onFilterChange(filter)
          }}
        />
      }
      isActive={filter.FloorList.length > 0}
    />
  )
}

export default FloorFilter
