const getSearchQuery = (searchLocation: string) => {
  const params = new URLSearchParams(searchLocation)
  const keyword = params.get('keyword') || ''
  const page = Number(params.get('page')) || 1
  const rowPerPage = Number(params.get('rowPerPage')) || 2
  const type = params.get('type') || ''

  return {
    keyword,
    page,
    rowPerPage,
    type,
  }
}

export default getSearchQuery
