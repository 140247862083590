import React from 'react'
import CheckListGroup from './CheckListGroup'
import { InspectionList } from 'features/types/checklist'

type Props = {
  onUpdateCheckList: (inspectionList: InspectionList[]) => void
  inspectionList: InspectionList[]
  remarkStatus?: any[]
}

const FormChecklist: React.FC<Props> = (props: Props) => {
  const { inspectionList, remarkStatus } = props

  const onChange = (val: InspectionList, index: number) => {
    inspectionList[index] = val
    props.onUpdateCheckList(inspectionList)
  }

  const content = inspectionList.map((item, index) => {
    if (!item.isHide) {
      return (
        <CheckListGroup
          index={index}
          key={index}
          item={item}
          remarkStatus={remarkStatus}
          onChange={onChange}
        />
      )
    }
  })

  return <div>{content}</div>
}

export default FormChecklist
