import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { RootState } from 'typings/RootState'
import styled from 'styled-components'
import { Button, Col } from 'antd'
import { selectedProjectCodeSelector } from 'features/project/project.selector'
import {
  serviceTokenSelector,
  userEmailSelector,
} from 'features/sensitive/sensitive.selector'
import { selectFilter } from 'features/cmpj/commissioning/dashboard/selector'
import { Filter } from 'features/cmpj/commissioning/dashboard/types'
import { updateFilter } from 'features/cmpj/commissioning/dashboard/actions'

import { getLeafWbsList } from 'features/wbs/wbs.function'
import { getWbsListSelector } from 'features/wbs/wbs.selector'

import { WbsFilter, CommissioningTypeFilter } from './Components'
import { JsonParam, useQueryParam } from 'use-query-params'
import { useLocation } from 'react-router'
import queryString from 'query-string'

type Props = {
  onSearch?: () => void
  withPadding?: boolean
} & ReduxProps &
  DispatchProps

const CMCommissioningFilter: React.FunctionComponent<Props> = props => {
  const {
    ProjectCode,
    UserEmail,
    token,
    filter,
    onSearch = () => {},
    withPadding = false,
    wbsList,
  } = props

  const dispatch = useDispatch()

  const [search, setSearch] = useQueryParam('search', JsonParam)
  const searchParam = useLocation()

  useEffect(() => {
    onBindFilterFromParams()
  }, [searchParam.search])

  const onFilterChange = (filter: Filter) => {
    dispatch(updateFilter(filter))
  }

  const onBindFilterFromParams = () => {
    if (searchParam.search) {
      const param = queryString.parse(searchParam.search)
      const activityFilter: Filter = JSON.parse(param.search!.toString())
      onFilterChange(activityFilter)
      setSearch(activityFilter)
      onSearch()
    }
  }

  const onClick = () => {
    filter.LeafWbsList = getLeafWbsList(wbsList, filter.WbsList)
    dispatch(updateFilter(filter))
    setSearch(filter)
    onSearch()
  }

  return (
    <MainPage className={withPadding ? 'with-padding' : ''}>
      <div className="filters">
        <Col span={24} className="filters-button">
          <WbsFilter
            onFilterChange={onFilterChange}
            filter={filter}
            wbsList={wbsList}
          />

          <CommissioningTypeFilter
            onFilterChange={onFilterChange}
            filter={filter}
          />
          <Button type="primary" onClick={onClick}>
            กรองข้อมูล
          </Button>
        </Col>
      </div>
    </MainPage>
  )
}

const MainPage = styled.div`
  &.with-padding {
    padding: 0 120px;
  }

  background-color: #f5f6fa;
`

const mapStateToProps = (state: RootState) => {
  return {
    ProjectCode: selectedProjectCodeSelector(state),
    UserEmail: userEmailSelector(state),
    token: serviceTokenSelector(state),
    filter: selectFilter(state),
    wbsList: getWbsListSelector(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CMCommissioningFilter)
