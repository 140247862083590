import RootState from 'typings/RootState'
import { Activity } from './types'

import { Event } from '../../types/event'

export const selectList = (state: RootState): Activity[] =>
  state.approvalDashboard.list

export const selectProjectCode = (state: RootState): string =>
  state.approvalDashboard.projectCode

export const selectEvent = (state: RootState): Event =>
  state.approvalDashboard.event
