import React, { useState } from 'react'

import styled from 'styled-components'
import { AddIcon } from 'Components/Shared/Icon'
import { Picker } from 'Components/Shared/Form'
import Editor from 'Components/Pages/ActivityDetailPage/Editor'
import file from '../../utils/file'
import { LIMIT_FILE } from '../../config'
import imgPhoto from 'assets/img/ic-photo.png'

type Props = {
  onChange: (images: any) => void
  Image: any
  title?: string
  viewMode?: boolean
  onThumbnailOpen?: (image: any) => void
}

const SingleImgPicker: React.FunctionComponent<Props> = props => {
  const {
    onChange,
    Image,
    title = '',
    viewMode = false,
    onThumbnailOpen,
  } = props
  const [showEditor, setShowEditor] = useState(false)

  const [fileEditor, setFileEditor] = useState({
    base64: '',
    file: { name: '' },
    lastModified: 0,
  })

  const addFile = async (base64: any, fileItem: any) => {
    try {
      const lastModified = fileItem.lastModified
      let resp
      if (fileItem.type === 'image/heic') {
        resp = await file.heicToJpeg({
          file: fileItem,
          filename: fileItem.name,
        })
        base64 = resp.text
        fileItem = resp.file
      }

      if (fileItem.size < LIMIT_FILE.image) {
        resp = await file.compress({
          value: base64,
          file: fileItem,
          limit: LIMIT_FILE.image,
        })
        base64 = resp.text
        fileItem = resp.file
      }

      setShowEditor(true)
      setFileEditor({ base64, file: fileItem, lastModified })
    } catch (e) {}
  }

  const onAddImage = async (base64: string) => {
    try {
      const params = {
        data: base64,
        filename: fileEditor.file.name,
        lastModified: fileEditor.lastModified,
      }
      const fileItem: any = await file.base64ToFile(params)
      const newImage = {
        text: base64,
        file: fileItem,
        lastModified: fileItem.lastModified,
      }

      onChange(newImage)
      setFileEditor({
        base64: '',
        file: { name: '' },
        lastModified: 0,
      })
    } catch (e) {}
    setShowEditor(false)
  }

  const addImage = (
    <Picker
      name={title}
      loading={false}
      disabled={false}
      text={'ADA'}
      accept=".png,.jpg,.heic"
      onChange={addFile}
      customPickerBtn={
        <AddImage>
          <AddIcon size={32} color="#8b99a7" />
          <div>คลิกเพื่อเพิ่มรูปภาพ</div>
        </AddImage>
      }
    />
  )

  const noneImage = (
    <ActionImage>
      <img src={imgPhoto} alt="" />
      <div>ไม่มีรูปภาพ</div>
    </ActionImage>
  )

  const emptyImage = !Image
  const imgContent = !emptyImage
    ? Image.FileID
      ? Image.FileURL
      : Image.text
    : ''

  return (
    <>
      <Editor
        visible={showEditor}
        image={fileEditor.base64}
        onClose={() => setShowEditor(false)}
        onOk={onAddImage}
      />
      <Box>
        <Header>{title}</Header>
        <ImageBox
          style={{ backgroundImage: `url(${imgContent})` }}
          onClick={() => onThumbnailOpen && onThumbnailOpen(Image)}
        >
          {emptyImage && (viewMode ? noneImage : addImage)}
        </ImageBox>
      </Box>
    </>
  )
}

const Box = styled.div`
  padding: 15px;
  width: 100%;
`

const ImageBox = styled.div`
  margin-top: 10px;
  padding: 8px;
  height: 240px;
  border-radius: 3px;
  border: solid 1px #b9c6d3;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Header = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 20px;
`

const ActionImage: any = styled.div`
  font-family: 'DB Helvethaica X 55 Regular';
  color: #8b99a7;
  text-align: center;
  font-size: 20px;
  img {
    width: 28px;
    height: 27px;
    object-fit: contain;
    border: 0px;
    min-height: 27px;
    margin-bottom: 12px;
  }
`

const AddImage = styled(ActionImage)`
  cursor: pointer;
`

export default SingleImgPicker
