import withAPIAction from 'utils/withAPIAction'
import {
  getReportAction,
  getCheckListAction,
  createDefectAction,
  updateDefectAction,
  setDefectAction,
  getSelectedPinAction,
  updateSelectedPintAction,
  getMainconAction,
} from './actions'
import {
  GetReportReq,
  GetCheckListReq,
  DefectTypeReq,
  SetDefectReq,
  GetSelectPinReq,
  UpdateSelectPinReq,
  GetMainconReq,
} from './types'
import { verifyReq } from 'utils/verify'

export const getReport = (token: string, data: GetReportReq) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<GetReportReq>({
    method: 'POST',
    path: 'GetZoneReportById',
    data,
    token: `Bearer ${token}`,
  })(getReportAction)
}

export const getCheckList = (token: string, data: GetCheckListReq) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<GetCheckListReq>({
    method: 'POST',
    path: 'GetZoneCheckListForQC',
    data,
    token: `Bearer ${token}`,
  })(getCheckListAction)
}

export const createDefect = (token: string, data: DefectTypeReq) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<DefectTypeReq>({
    method: 'POST',
    path: 'CreateDefectZone	',
    data,
    token: `Bearer ${token}`,
  })(createDefectAction)
}

export const updateDefect = (token: string, data: DefectTypeReq) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<DefectTypeReq>({
    method: 'POST',
    path: 'UpdateDefectZone',
    data,
    token: `Bearer ${token}`,
  })(updateDefectAction)
}

export const setDefect = (token: string, data: SetDefectReq) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<SetDefectReq>({
    method: 'POST',
    path: 'SetDefectZoneStatus',
    data,
    token: `Bearer ${token}`,
  })(setDefectAction)
}

export const getSelectZonePinReport = (
  token: string,
  data: GetSelectPinReq
) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<GetSelectPinReq>({
    method: 'POST',
    path: 'GetSelectZonePinReport',
    data,
    token: `Bearer ${token}`,
  })(getSelectedPinAction)
}

export const updateSelectZonePinReport = (
  token: string,
  data: UpdateSelectPinReq
) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<UpdateSelectPinReq>({
    method: 'POST',
    path: 'SelectZonePinReport',
    data,
    token: `Bearer ${token}`,
  })(updateSelectedPintAction)
}

export const getMainconList = (token: string, data: GetMainconReq) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<GetMainconReq>({
    method: 'POST',
    path: 'GetMainconListForQC',
    data,
    token: `Bearer ${token}`,
  })(getMainconAction)
}
