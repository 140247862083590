import { getType } from 'typesafe-actions'
import * as actions from './actions'
import { StoreState, DashboardAction } from './types'
import produce from 'immer'
import parseErrorMessage from 'utils/parseErrorMessage'
import { FunctionStatus } from 'features/types/status'
import { Event } from 'features/types/event'
import { cloneDeep } from 'lodash'

const initEvent = {
  IsLoading: false,
  MessageSuccess: undefined,
  SubmitError: undefined,
  SubmitSuccess: false,
  SubmitType: undefined,
} as Event

const initialState: StoreState = {
  data: {
    CheckListID: '',
    CheckListName: '',
    FunctionStatus: FunctionStatus.Pass,
    ProjectTypeName: '',
    ProjectTypeID: '',
    InspectionList: [],
    CheckList: [],
    SendCheckStatus: 'N',
  },
  event: cloneDeep(initEvent),
}

export default function(
  state: StoreState = initialState,
  action: DashboardAction
): StoreState {
  return produce(state, draft => {
    switch (action.type) {
      case getType(actions.GetFunctionChecklisAction.request):
        draft.event.SubmitError = undefined
        draft.event.IsLoading = true
        draft.event.SubmitSuccess = false
        draft.data = state.data
        break
      case getType(actions.GetFunctionChecklisAction.success):
        const res = action.payload.ResponseData
        draft.event.IsLoading = false
        if (res) {
          res.InspectionList = res.InspectionList || []
          res.CheckList = res.CheckList || []
          res.InspectionList = res.InspectionList.map(item => {
            item.Data = item.Data || []
            item.Data.map(it => {
              it.Data = it.Data || []
              return it
            })

            return item
          })
          draft.data = res
        } else {
          draft.data = state.data
        }

        break

      case getType(actions.GetFunctionChecklisAction.failure):
        draft.event.IsLoading = false
        draft.event.SubmitError = parseErrorMessage(action.payload)
        break
      case getType(actions.SaveFunctionAction.request):
        draft.event.SubmitError = undefined
        draft.event.IsLoading = true
        draft.event.SubmitSuccess = false
        break
      case getType(actions.SaveFunctionAction.success):
        draft.event.IsLoading = false
        draft.event.SubmitError = undefined
        draft.event.SubmitSuccess = true
        draft.event.MessageSuccess = 'บันทึกสำเร็จ'
        break
      case getType(actions.SaveFunctionAction.failure):
        draft.event.IsLoading = false
        draft.event.SubmitError = parseErrorMessage(action.payload)
        break

      case getType(actions.UpdateData):
        const param: any = action.payload
        draft.data = param.value
        break

      case getType(actions.ResetEvent):
        draft.event = cloneDeep(initEvent)
        break
      default:
        return state
    }
  })
}
