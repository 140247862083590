import {
  ActivityState,
  CurrentActivity,
  MainconTask,
  Event,
  ActivityAction,
  SubmitType,
} from './types'

import produce from 'immer'
import { cloneDeep } from 'lodash'
import parseErrorMessage from 'utils/parseErrorMessage'

import { getType } from 'typesafe-actions'
import * as actions from './actions'

import { TaskStatus } from 'features/tasklane/tasklane.types'

const mockOriginal = {
  RowID: 1,
  ActivityID: '',
  ActivityName: '',
  BaselineStartDate: '',
  BaselineFinishDate: '',
  StartDate: '',
  FinishDate: '',
  ActualStartDate: '',
  ActualFinishDate: '',
  SowID: '',
  SowName: '',
  WbsID: 0,
  WbsName: '',
  Progress: 0,
  TaskStatus: 'APPV' as TaskStatus,
  Reason: '',
  Remark: '',
  Images: [],
  Documents: [],
  CmImages: [],
  CmDocuments: [],
  McImages: [],
  McDocuments: [],
  AmendmentNo: '',
  Unit: '',
  Building: '',
  Floor: '',
} as MainconTask

const mockCurrent = {
  Images: [],
  Documents: [],
  Remark: '',
  SubmitDate: '',
} as CurrentActivity

const initialState: ActivityState = {
  CurrentActivity: cloneDeep(mockCurrent),
  OriginalActivity: cloneDeep(mockOriginal),
  CheckList: {
    CheckListID: '',
    CheckListName: '',
    Status: 'INIT',
    InspectionList: [],
    WarningList: [],
  },
  UploadList: {} as any[],
  Event: {} as Event,
}

export default function (
  state: ActivityState = initialState,
  action: ActivityAction
): ActivityState {
  return produce(state, (draft) => {
    switch (action.type) {
      case getType(actions.setCurrentActivity):
        draft.CurrentActivity = action.payload
        break
      case getType(actions.getActivityDetailRequest.request):
        draft.Event.IsLoading = true
        draft.Event.SubmitError = ''
        draft.CurrentActivity = cloneDeep(mockCurrent)
        draft.OriginalActivity = cloneDeep(mockOriginal)
        break
      case getType(actions.getActivityDetailRequest.success):
        draft.OriginalActivity = action.payload.ResponseData
        draft.Event.IsLoading = false
        break
      case getType(actions.getActivityDetailRequest.failure):
        draft.Event.IsLoading = false
        draft.Event.SubmitError = parseErrorMessage(action.payload)
        break

      case getType(actions.getCheckListRequest.request):
        draft.Event.IsLoading = true
        draft.Event.SubmitError = ''
        draft.CheckList = {
          CheckListID: '',
          CheckListName: '',
          Status: 'INIT',
          InspectionList: [],
          WarningList: [],
        }
        break
      case getType(actions.getCheckListRequest.success):
        const respCheckList = action.payload.ResponseData
        if (respCheckList.CheckListID) {
          draft.CheckList = respCheckList
        }
        draft.Event.IsLoading = false
        break
      case getType(actions.getCheckListRequest.failure):
        draft.Event.IsLoading = false
        draft.Event.SubmitError = parseErrorMessage(action.payload)
        break
      case getType(actions.acceptActivity.request):
        draft.Event.SubmitError = undefined
        draft.Event.IsLoading = true
        draft.Event.SubmitType = SubmitType.ACCEPT
        draft.Event.SubmitSuccess = false
        break
      case getType(actions.acceptActivity.success):
        draft.Event.IsLoading = false
        draft.Event.SubmitError = undefined
        draft.Event.SubmitSuccess = true
        draft.Event.MessageSuccess = 'รับงานเรียบร้อย'
        break
      case getType(actions.acceptActivity.failure):
        draft.Event.IsLoading = false
        draft.Event.SubmitError = 'ไม่สามารถรับงานได้ กรุณาลองใหม่อีกครั้ง'
        break

      case getType(actions.sendReqToCMAsyncReq.request):
        draft.Event.SubmitError = undefined
        draft.Event.IsLoading = true
        draft.Event.SubmitType = SubmitType.ACCEPT
        draft.Event.SubmitSuccess = false
        break
      case getType(actions.sendReqToCMAsyncReq.success):
        draft.Event.IsLoading = false
        draft.Event.SubmitError = undefined
        draft.Event.SubmitSuccess = true
        draft.Event.MessageSuccess = 'ส่งคำขอให้ CM เรียบร้อย'
        break
      case getType(actions.sendReqToCMAsyncReq.failure):
        draft.Event.IsLoading = false
        draft.Event.SubmitError =
          'ไม่สามารถส่งคำขอให้ CM ได้ กรุณาลองใหม่อีกครั้ง'
        break

      default:
        return state
    }
  })
}
