import RootState from '../../../typings/RootState'
import { ModelList, ModelListItem, SelectedModel } from './types'
import { Event } from '../../types/event'

export const selectModelList = (state: RootState): ModelList =>
  state.adminModel.ModelList

export const selectModelSelected = (
  state: RootState
): ModelListItem | undefined => state.adminModel.ModelSelected

export const selectEvent = (state: RootState): Event => state.adminModel.Event

export const selectModelMappingList = (state: RootState): SelectedModel[] =>
  state.adminModel.ModelMappingList

export const selectImportEvent = (state: RootState): Event =>
  state.adminModel.ImportEvent
