import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import { SubJobType, DetailType } from 'features/checklist/unit/types'
import { AddList, EditDeleteButton } from 'Components/Shared/Button'
import ContentBox from 'Components/Shared/Card/ContentBox'
import DeleteCheckListJobModal, {
  DeleteWithCheckHandles,
} from 'Components/Shared/Modal/DeleteWithCheckModal'
import { Row } from 'antd'
import { RowSelectedItem } from 'Components/AdminQC/Components'

interface Props {
  item?: SubJobType
  selected: number
  onSelected: (index: number) => void
  showModal: (tab: number) => void
  onDelete: (it: DetailType) => void
  openModalEdit: (obj: DetailType, index: number, tab: number) => void
  disabled: boolean
}

const DetailBox: React.ComponentType<Props> = ({
  item,
  selected,
  onSelected: onSelectedProps,
  showModal,
  onDelete,
  openModalEdit,
  disabled,
}) => {
  const modalRef = useRef<DeleteWithCheckHandles>(null)

  function onSelected(it: DetailType) {
    if (item) {
      const resultIndex = item.DetailList.findIndex(
        v => v.DetailName === it.DetailName
      )

      onSelectedProps(resultIndex)
    }
  }

  const showDeleteModal = (it: DetailType) => {
    if (modalRef.current) {
      const menuTitle = `รายละเอียด: ${it.DetailName}`
      modalRef.current.showModal(it, [], menuTitle, [])
    }
  }

  const showEditeModal = (it: DetailType) => {
    if (item) {
      const resultIndex = item.DetailList.findIndex(
        v => v.DetailName === it.DetailName
      )
      openModalEdit(it, resultIndex, 3)
    }
  }

  let content: JSX.Element[] = []
  if (item) {
    content = item.DetailList.map((it: DetailType, index: number) => {
      return (
        <RowSelectedItem
          key={index}
          title={it.DetailName}
          right={
            <EditDeleteButton
              showEditeModal={() => showEditeModal(it)}
              showDeleteModal={() => showDeleteModal(it)}
              disableDelete={disabled}
            />
          }
          onClick={() => onSelected(it)}
          isSelected={index === selected}
        />
      )
    })
  }

  return (
    <>
      <ContentBox title="รายละเอียด" isEmpty={item == null}>
        <ListSection>
          {content}
          {!disabled && <AddList onClick={() => showModal(3)} />}
        </ListSection>
      </ContentBox>
      <DeleteCheckListJobModal ref={modalRef} onOk={onDelete} />
    </>
  )
}

const ListSection = styled.div`
  margin-top: 16px;
  height: 256px;
  overflow: auto;
  padding: 0 8px;
  padding-bottom: 30px;
`

const Item = styled.div`
  font-size: 20px;
  border-bottom: 1px solid #e8ebf0;
  align-items: center;
  display: flex;
  height: 56px;
  padding: 0px 16px;
  cursor: pointer;

  &.selected {
    border-radius: 5px;
    border: solid 1px #003168;
  }

  .row-item {
    width: 100%;
  }

  span {
    width: 85%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`

export default DetailBox
