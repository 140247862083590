import { createStandardAction, createAsyncAction } from 'typesafe-actions'
import { GetApiResponse, PaginationType } from '../../types/common'
import {
  ConfigList,
  ConfigListDetail,
  ConfigListDetailReq,
  GetDataListReq,
  CommonResp,
  GetDataListResp,
} from './types'
import { History } from 'history'

export const reset = createStandardAction('admin/settingConfig/RESET_ACTION')<
  void
>()

export const sortingTask = createStandardAction('admin/setting/SORTING')<{
  name: string
  sort?: string
}>()

export const getDataListAction = createAsyncAction(
  'admin/setting/GET_CONFIG_LIST_REQUEST',
  'admin/setting/GET_CONFIG_LIST_SUCCESS',
  'admin/setting/GET_CONFIG_LIST_FAILURE'
)<PaginationType, GetDataListResp, Error>()

export const getReasonListAction = createAsyncAction(
  'admin/setting/GET_CONFIG_LIST_REASON_REQUEST',
  'admin/setting/GET_CONFIG_LIST_REASON_SUCCESS',
  'admin/setting/GET_CONFIG_LIST_REASON_FAILURE'
)<PaginationType, GetDataListResp, Error>()

export const createDataAction = createAsyncAction(
  'admin/setting/CREATE_CONFIG_DETAIL_REQUEST',
  'admin/setting/CREATE_CONFIG_DETAIL_SUCCESS',
  'admin/setting/CREATE_CONFIG_DETAIL_FAILURE'
)<ConfigListDetailReq, CommonResp, any>()

export const updateDataAction = createAsyncAction(
  'admin/setting/UPDATE_CONFIG_DETAIL_REQUEST',
  'admin/setting/UPDATE_CONFIG_DETAIL_SUCCESS',
  'admin/setting/UPDATE_CONFIG_DETAIL_FAILURE'
)<ConfigListDetailReq, CommonResp, any>()

export const resetEvent = createStandardAction(
  'admin/qcType/RESET_EVENT_ACTION'
)<void>()
