/* eslint-disable */
export default {
  Common: {
    cancel_all: '',
    month: '',
    ok: 'ok',
    save: 'save',
    week: '',
    logout: 'Logout',
    day: '',
    dialogOk: 'OK',
    picture: 'picture',
    document: 'document',
    pick_picture: 'pick picture',
    pick_document: 'pick document',
    cancel: 'cancel',
  },
  Error: {
    login_error: 'login Error',
    filie_size_limit: 'Sorry file size is too big',
    file_wrong: '',
  },
  Menu: {
    profile: 'Profile',
  },
  Login: {
    login: 'Login with Sansiri Account',
    service_token_error:
      'Something went wrong. Cannot retrieve service token from API. Please clear cache and re-login.',
    connection_in_progress:
      'Please wait, trying to connect to backend service. This page will automatically redirect to dashboard after successful connection.',
    platform_name: 'High Level Platform',
    logout_in_progress: '',
    logout_success_message: '',
    login_get_token_for_username: '',
    accesstoken_acquired: '',
    logout_modal_description: 'You are about to logout from system',
  },
  Dashboard: {
    actual_end_date: '',
    actual_start_date: '',
    approved: '',
    category: '',
    custom: '',
    day_work: '',
    duration: '',
    filter: '',
    not_approved: '',
    pending: '',
    progress: '',
    search_placeholder: '',
    status: '',
    todo: '',
    header_in_progress: '',
    header_todo: '',
    header_complete: '',
    header_sort_by_recent_update: '',
    tasklane_empty_record: '',
    tasklane_loading: '',
    tasklane_seemore: '',
    project_list_empty_message: '',
    todo_label: '',
    inprogress_label: '',
    complete_label: '',
    header_sort_by_last_update: '',
    project_select: '',
    plan_current_start_date: '',
    plan_current_end_date: '',
    construction_type: '',
    floor: '',
    unit: '',
    zone: '',
    type: '',
    advance: '',
  },
  Module: {
    title: 'Please choose module',
  },
  ActivityDetail: {
    history_request: '',
    status_label: '',
    wbs_label: '',
    reject_reason: '',
    day_of_work_label: '',
    progress_label: '',
    submit_button_label: '',
    plan_start_date_label: '',
    plan_end_date_label: '',
    saving: '',
    start_date: 'Start Date',
    end_date: 'Finish Date',
    actual_start_date: 'Actual Start Date',
    actual_end_date: 'Actual Finish Date',
    baseline_start_date: 'Baseline Start Date',
    baseline_end_date: 'Baseline Finish Date',
    expect_end_date: 'Expect Finish Date',
    picture: '',
    picture_note: '',
    document: '',
    document_note: '',
    note: '',
    work_progress: '',
    created_date: '',
    upload_date: '',
    time_unit: '',
  },
  TaskLane: {
    delayed_by_day: '',
    start_within_day: '',
    plan_start_date: '',
    plan_end_date: '',
    init_text_th: '',
    appv_text_th: '',
    pend_text_th: '',
    rejt_text_th: '',
  },
  TopNav: {
    logout_modal_description: 'You are about to logout from system',
    logout_modal_confirm_description: '',
    logout_modal_ok_label: '',
    logout_modal_cancel_label: '',
  },
  RequireAuth: {
    back: 'Back to login page',
    error: 'You are not allowed to use this website',
    contact: 'please contact admin',
  },
  SearchResult: {
    search_results_label: 'Results',
    search_item_not_found: "Sorry, we couldn't find the item you searched for.",
    change_search_keyword: 'Please change search details and try again.',
  },
  Breadcrumb: {
    dashboard: 'Dashboard',
    home: 'Home',
    search_results: 'Search results',
    seemore: 'See More',
  },
  Pagination: {
    show: 'Show',
    of: 'of',
    entries: 'entries',
  },
  TokenExpired: {
    title: 'Token Expired',
    content: 'Your token has expired. Please login to continue.',
  },
  ActivityHistory: {
    description_req: 'description_req',
    description_rejt: 'description_rejt',
    description_appv: 'description_appv',
    status_appv: 'status_appv',
    column_date: 'column_date',
    column_time: 'column_time',
    column_description: 'column_description',
    column_role: 'column_role',
    column_status: 'column_status',
    column_file: 'Files',
    column_remark: 'Remark',
  },
}
