import React, { useState } from 'react'
import { Row } from 'antd'
import styled from 'styled-components'

import { translate } from 'i18n'
import TKeys from 'i18n/translationKey'
import Picker from '../../../Shared/Form/Picker'
import ErrorMessage from '../../../Shared/ErrorMessage'
import { toText } from 'utils/format'
import { LIMIT_FILE } from 'config'
import { getFileType } from '../utils'

import imgFile from 'assets/img/img-file.png'

type DefaultProps = {
  original: any
  onChange: (item: any) => void
  onDocumentOpen: () => void
  disabled: boolean
}

type Props = DefaultProps

const HandleDocument: React.FC<Props> = ({
  original,
  onChange,
  onDocumentOpen,
  disabled,
}) => {
  const [display, setDisplay] = useState(false)

  const addFile = (text: any, file: any) => {
    if (file.size > LIMIT_FILE.document) {
      setDisplay(true)
    } else {
      getFileType(file)
      original.Documents.unshift({ text, file })
      onChange({ ...original })
    }
  }

  const renderFile = (item: any, size: number = 0) => {
    let fileType = getFileType(item.file)
    let content = (
      <FileInfo>
        <img src={fileType.icon} alt="" />
        <span>{toText(fileType.name, 9)}</span>
      </FileInfo>
    )

    if (size > 0) {
      let newImg = { filter: 'brightness(0.4)' }
      return (
        <ImgMoreBlock onClick={onDocumentOpen}>
          <img style={newImg} src={imgFile} alt="" />
          {content}
          <TextOver className="std-font-bold">+{size}</TextOver>
        </ImgMoreBlock>
      )
    } else {
      return (
        <ImgBlock onClick={onDocumentOpen}>
          <img src={imgFile} alt="" />
          {content}
        </ImgBlock>
      )
    }
  }

  const textDoc = translate(TKeys.ActivityDetail.document)
  let content
  let moreContent
  const size = original.Documents.length

  if (size > 0) {
    content = renderFile(original.Documents[0])

    if (size > 1) {
      let more = size - 2
      moreContent = renderFile(original.Documents[1], more)
    }
  }

  return (
    <>
      <div className="form-label">
        {textDoc}
        <SubTitle>{translate(TKeys.ActivityDetail.document_note)}</SubTitle>
      </div>
      <Row>
        <ImgLine>
          <Picker
            disabled={size === 5 || disabled}
            text={textDoc}
            name="upload file"
            accept=".pdf,.xlsx,.xls,.docx,.doc,.csv"
            onChange={addFile}
            isMultiple={true}
          />

          {content}
          {moreContent}
        </ImgLine>
      </Row>
      <ErrorMessage
        visible={display}
        text={translate(TKeys.Error.filie_size_limit)}
        onClose={() => setDisplay(false)}
      />
    </>
  )
}

const SubTitle = styled.span`
  color: #8b99a7;
  font-size: 16px;
  margin-left: 8px;
`

const ImgLine = styled.div`
  display: inline-flex;
`

const CssImg = `
  text-align: center;
  width: 100px;
  height: 100px;
  margin-left: 8px;
  border: 1px solid #e4e7eb;
  border-radius: 3px;

  img {
    width: 35px;
    height: 40px;
    object-fit: contain;
    margin-top: 19px;
  }
`
const ImgBlock = styled.div`
  ${CssImg}
`

const ImgMoreBlock = styled.div`
  ${CssImg}
  background-color: rgba(0, 0, 0, 0.4);
  :hover {
    cursor: pointer;
  }
`

const FileInfo = styled.div`
  margin: 4px 0px 0px 4px;
  text-align: left;

  img {
    margin: 0px;
    margin-right: 4px;
    width: 16px;
    height: 16px;
  }

  span {
    font-size: 18px;
  }
`

const TextOver = styled.span`
  color: white;
  font-size: 28px;
  position: absolute;
  top: 55px;
  transform: translate(-50%, -50%);
`

export default HandleDocument
