import RootState from 'typings/RootState'
import { Event} from '../../../types/event'
import { Activity } from './types'

export const selectData = (state: RootState): Activity =>
  state.qcUnitChecking.data

export const selectEvent = (state: RootState): Event =>
  state.qcUnitChecking.event

export const selectSorting = (state: RootState) => state.qcUnitChecking.sorting

