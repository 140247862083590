import { ActionType } from 'typesafe-actions'
import { Moment } from 'moment-timezone'
import * as actions from './actions'
import { Event } from 'features/types/event'

export type DashboardAction = ActionType<typeof actions>

export interface StoreState {
  data: DashboardData
  originalData: DashboardData
  event: Event
  actionEvent: Event
  query: DashboardQuery
  sorting: any
  filter: DashboardFilter
  canSendStatus: boolean
}

export type InProgress = {
  RequestID: number
  CheckNumber: number
  AppointmentDate: string
  TotalLocation: number
  NumOfSelectLocation: number
  State: 'CM' | 'QC'
  Status: 'INIT' | 'INPRO' | 'APPV' | 'REJT'
}

export type FixFromQC = {
  RequestID: number
  CheckNumber: number
  TotalLocation: number
  NumOfSelectLocation: number
  CheckedDate: string
  State: 'CM' | 'QC'
  Status: 'INIT' | 'INPRO' | 'APPV' | 'REJT'
}

export interface GetDashboardReq {
  ProjectCode: string
  UserEmail: string
}

export interface GetCanSendStatusReq {
  ProjectCode: string
  UserEmail: string
}

export interface SendToQCReq {
  ProjectCode: string
  UserEmail: string
}

export interface GetDashboardResp extends GetApiResponse<DashboardData> {}
export interface GetCanSendStatusResp
  extends GetApiResponse<{ Status: boolean }> {}

export interface GetApiResponse<T> {
  ResponseData: T
  ResponseCode: string
  ResponseMsg: 'Success' | 'Error'
  RequestTime: string
  Responsetime: string
}

export type DashboardData = {
  InProgress: InProgress[]
  FixFromQC: FixFromQC[]
}

export type DashboardEvent = {
  isFetching: boolean
  error: string
}

export type DashboardFilter = {}

export type DashboardQuery = {
  date: {
    start: undefined | Moment
    end: undefined | Moment
  }
}

export enum TypeOfTask {
  InProgress = 'inProgress',
  FixFromQC = 'fixFromQC',
}
