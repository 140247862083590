import React from 'react'
import styled from 'styled-components'

interface ButtonProps {
  text: string
  image: string
}

const FileEmpty: React.FunctionComponent<ButtonProps> = props => {
  const { text, image } = props

  return (
    <ImgEmptyBlock>
      <EmptyDes>
        <img src={image} /> <span>{text}</span>
      </EmptyDes>
    </ImgEmptyBlock>
  )
}

const ImgEmptyBlock = styled.div`
  border: 1px solid #e4e7eb;
  border-radius: 3px;
  height: 100px;
  width: calc(100% - 50px);
  position: relative;
  text-align: center;
`
const EmptyDes = styled.div`
  color: #8b99a7;
  font-size: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  img {
    width: 24px;
    margin-right: 5px;
  }
`
export default FileEmpty
