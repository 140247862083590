import React, { useState, useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import RootState from 'typings/RootState'
import styled from 'styled-components'
import { Layout, Row, Col } from 'antd'
import { BreadcrumbLink } from 'Components/Shared/BreadCrumb/BreadCrumb'
import SubnavBar from 'Components/Shared/SubnavBar'
import InputText from 'Components/Shared/Input/InputText'
import PrimaryButton from 'Components/Shared/Button/PrimaryButton'
import CheckList from 'Components/Inspection/CheckList'
import WarningList from 'Components/Inspection/WarningList'
import { getCheckListById } from 'features/checklist/master/api'

import {
  reset,
  submitCheckListReq,
  updateCheckListDetail,
  deleteCheckListByIdReq,
  resetUpdateActivityCheckList,
} from 'features/checklist/master/actions'

import {
  selectCheckListDetail,
  selectDocument,
  selectCheckListActivity,
  selectActivityUpdate,
  selectEvent,
  selectEventActivity,
} from 'features/checklist/master/selector'

import {
  userEmailSelector,
  serviceTokenSelector,
} from 'features/sensitive/sensitive.selector'

import { selectEvent as selectMasterEvent } from 'features/masterData/selector'

import { CheckListDetail } from 'features/checklist/master/types'
import { InspectionList } from 'features/types/checklist'

import { selectedProjectCodeSelector } from 'features/selectedProject/selectedProject.selector'
import { SecondaryButton } from 'Components/Shared/Button'
import EventModal from 'Components/Shared/Modal/EventModal'

import Menu from '../../MenuAdmin'
import ActivityList from './ActivityList'

const { Content } = Layout

type Props = RouteComponentProps<{ id: string }> & ReduxProps

const MainconChecklistDetail: React.ComponentType<Props> = ({
  match,
  UserEmail,
  event,
  eventActivity,
  eventMaster,
  Token,
  checklist,
  doc,
  activityUpdate,
  projectCode,
  history,
}) => {
  const [enableModal, setEnableModal] = useState(false)
  const [selectTab, setSelectTab] = useState('')

  const dispatch = useDispatch()

  const CheckListID = match.params.id
  const isEditing = checklist.CheckListID !== ''
  console.log('isEditing' + CheckListID)
  console.log(isEditing)

  const readOnly: boolean = checklist.Status.toLocaleLowerCase() === 'used'
  const isMaster = match.path.startsWith('/master-checklist')
  useEffect(() => {
    return () => {
      dispatch(reset())
    }
  }, [])

  const breadcrumbs: BreadcrumbLink[] = [
    {
      url: '/dashboard',
      title: 'Home',
      icon: '/home.png',
      active: true,
      showTitle: false,
    },
    {
      url: '/master-checklist',
      title: 'Master Checklist mainCon',
      active: true,
      showTitle: true,
    },
    {
      url: '/maincon-checklist/:id',
      title: isEditing
        ? 'แก้ไข Master Maincon checklist'
        : 'เพิ่ม Master Maincon checklist',
      active: false,
      showTitle: false,
    },
  ]

  useEffect(() => {
    if (!isMaster) {
      breadcrumbs[1].url = '/local-checklist'
      breadcrumbs[1].title = 'Checklist mainCon'
      breadcrumbs[2].title = isEditing
        ? 'แก้ไข'
        : 'เพิ่ม' + ' ' + 'Maincon checklist'
    }
  }, [isMaster])

  useEffect(() => {
    setEnableModal(true)
    if (CheckListID) {
      const isMasterStr = isMaster ? 'Y' : 'N'
      if (isMaster === false) {
        if (projectCode) {
          dispatch(getCheckListById(CheckListID, isMasterStr, projectCode))
        }
      } else {
        dispatch(getCheckListById(CheckListID, isMasterStr))
      }
    }
  }, [CheckListID, projectCode])

  const onSave = () => {
    setEnableModal(true)
    checklist.isMaster = isMaster ? 'Y' : 'N'
    // checklist.CheckListID = CheckListID
    dispatch(
      submitCheckListReq({
        checklist,
        doc,
        activity: activityUpdate,
        ProjectCode: projectCode,
        UserEmail,
        Token,
        history,
      })
    )
  }

  const onDelete = () => {
    setEnableModal(true)
    dispatch(
      deleteCheckListByIdReq.request({
        CheckListID: checklist.CheckListID,
        history,
      })
    )
  }

  const onName = (val: string) => {
    checklist.CheckListName = val
    dispatch(updateCheckListDetail({ ...checklist }))
  }

  const onTemplateCode = (val: string) => {
    checklist.TemplateCode = val
    dispatch(updateCheckListDetail({ ...checklist }))
  }

  const onProcurementTaskID = (val: string) => {
    checklist.ProcurementTaskID = val
    dispatch(updateCheckListDetail({ ...checklist }))
  }

  const onChange = (val: CheckListDetail) => {
    dispatch(updateCheckListDetail(val))
  }

  const onWarningList = (val: string[]) => {
    checklist.WarningList = val
    dispatch(updateCheckListDetail({ ...checklist }))
  }

  const getCount = (list: InspectionList[]) => {
    let counter = 0
    list.forEach(lv1 => {
      lv1.Data.forEach(lv2 => (counter += lv2.Data.length))
      counter += lv1.Data.length
    })

    counter += list.length
    return counter
  }

  const hideModal = () => setEnableModal(false)

  const cloneCheckList = () => {
    checklist.CheckListID = ''
    checklist.CheckListName = ''
    checklist.Status = 'Ready'
    dispatch(updateCheckListDetail(checklist))
    dispatch(resetUpdateActivityCheckList())
    history.push('/local-checklist/create')
  }

  return (
    <>
      <EventModal
        event={[event, eventActivity, eventMaster]}
        enable={enableModal}
        errorCalback={hideModal}
        successCalback={hideModal}
      />
      <SubnavBar breadcrumbs={breadcrumbs} hideSearch={true} />
      <Content className="mid-content">
        <Menu selected={isMaster ? 'master-checklist' : 'local-checklist'}>
          <MainPage>
            <LeftSection>
              <FixSection>
                <Row type="flex" justify="space-between">
                  <Title>
                    {isEditing ? 'แก้ไข' : 'เพิ่ม'} Maincon checklist
                  </Title>

                  {!isMaster && CheckListID && (
                    <Col style={{ width: '160px', marginRight: '24px' }}>
                      <SecondaryButton
                        text="Clone Checklist"
                        onClick={cloneCheckList}
                      />
                    </Col>
                  )}
                </Row>

                <InputText
                  prefix="ชื่อ"
                  placeholder="กรุณากรอกชื่อ"
                  value={checklist.CheckListName}
                  onChange={onName}
                  disabled={readOnly}
                />

                <InputText
                  prefix="Template Code"
                  placeholder="กรุณากรอก Template Code"
                  value={checklist.TemplateCode ?? ''}
                  onChange={onTemplateCode}
                  disabled={readOnly}
                />

                <InputText
                  prefix="Procurement Task ID"
                  placeholder="กรุณากรอก Procurement Task ID"
                  value={checklist.ProcurementTaskID ?? ''}
                  onChange={onProcurementTaskID}
                  disabled={readOnly}
                />
              </FixSection>

              <TabHeader>
                <TabBtn
                  className={selectTab !== '' ? 'inactive' : undefined}
                  onClick={() => setSelectTab('')}
                >
                  รายการตรวจ ({getCount(checklist.InspectionList)})
                </TabBtn>
                <TabBtn
                  className={selectTab !== 'warning' ? 'inactive' : undefined}
                  onClick={() => setSelectTab('warning')}
                >
                  ข้อควรระวัง ({checklist.WarningList.length})
                </TabBtn>
              </TabHeader>

              <TabBody>
                <ScrollSection>
                  {selectTab === '' ? (
                    <CheckList
                      readOnly={readOnly}
                      checklist={checklist}
                      onChange={onChange}
                    />
                  ) : (
                    <WarningList
                      readOnly={readOnly}
                      list={checklist.WarningList}
                      onChange={onWarningList}
                    />
                  )}
                </ScrollSection>
              </TabBody>
            </LeftSection>

            <RightSection>
              <Row type="flex" justify="end">
                {isEditing && !readOnly && (
                  <Col style={{ width: '160px', marginRight: '24px' }}>
                    <PrimaryButton
                      text="ลบ Checklist"
                      onClick={onDelete}
                      btnType="Reject"
                    />
                  </Col>
                )}
                <Col style={{ width: '160px', marginBottom: 42 }}>
                  <PrimaryButton text="บันทึก" onClick={onSave} />
                </Col>
              </Row>

              {!isMaster && (
                <ActivityList
                  projectCode={projectCode}
                  CheckListID={checklist.CheckListID}
                />
              )}
            </RightSection>
          </MainPage>
        </Menu>
      </Content>
    </>
  )
}

const MainPage = styled.div`
  background-color: #f5f6fa;
  width: 100%;
  height: 100%;
  -khtml-user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  overflow-x: scroll;
`

const TabHeader = styled.div``

const TabBtn = styled.span`
  color: #003168;
  background-color: white;
  padding: 10px 16px;
  font-size: 20px;
  font-family: 'DB Helvethaica X 75 Bd';
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  margin-right: 5px;
  cursor: pointer;

  &.inactive {
    color: #8b99a7;
    background-color: rgba(139, 153, 167, 0.1);
  }
`

const TabBody = styled.div`
  background-color: white;
  padding: 16px;
  margin-top: 2px;
  border-radius: 5px;
  border-top-left-radius: 0px;
  min-height: 60vh;
`

const LeftSection = styled.div`
  width: 50%;
  background-color: rgba(139, 153, 167, 0.1);
  padding: 0px 32px;
  padding-top: 40px;
  padding-bottom: 20px;
  float: left;
`

const RightSection = styled.div`
  width: 40%;
  padding: 0px 24px;
  padding-top: 36px;
  float: left;
`

const Title = styled.h2`
  font-size: 28px;
  font-family: 'DB Helvethaica X 75 Bd';
  margin-bottom: 20px;
`

const FixSection = styled.div`
  padding-bottom: 34px;
`

const ScrollSection = styled.div`
  height: calc(100% - 224px);
  overflow-y: scroll;
`

type ReduxProps = ReturnType<typeof mapStateToProps>
const mapStateToProps = (state: RootState) => {
  return {
    checklist: selectCheckListDetail(state),
    doc: selectDocument(state),
    activityUpdate: selectActivityUpdate(state),
    event: selectEvent(state),
    eventActivity: selectEventActivity(state),
    eventMaster: selectMasterEvent(state),
    UserEmail: userEmailSelector(state),
    Token: serviceTokenSelector(state),
    projectCode: selectedProjectCodeSelector(state),
  }
}

const mapDispatchToProps = {}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MainconChecklistDetail)
