import React from 'react'
import styled from 'styled-components'

interface Props {
  projectTitle: string
  children: any
}

const ProjectBox: React.FC<Props> = props => {
  const { projectTitle, children } = props

  return (
    <MainCard>
      <Header>{projectTitle}</Header>
      <Content>{children}</Content>
    </MainCard>
  )
}

const MainCard = styled.div`
  margin-bottom: 16px;
`

const Header = styled.div`
  padding: 13px 24px;
  border-radius: 5px 5px 0 0;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.03);
  background-color: #003168;
  font-family: 'DB Helvethaica X 75 Bd';
  color: #fff;
  font-size: 20px;
`
const Content = styled.div`
  border-radius: 0 0 5px 5px;
  border: solid 1px #e4e7eb;
  border-bottom: none;
  background: #fff;
`

export default ProjectBox
