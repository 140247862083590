import React, { useState } from 'react'
import styled from 'styled-components'
import { Row, Col, Input } from 'antd'
import icSearch from 'assets/img/ic-search.png'
import empty from 'assets/img/project-empty.png'
import { Loading } from 'Components/Shared'

type Props = {
  title: string
  children?: any
  hasSearch?: boolean
  isEmpty?: boolean
  emptyText?: string
  height?: number
  onSearch?: (text: string) => void
  isLoading?: boolean
  isPercent?: boolean
}

const ContentBox = ({
  title,
  hasSearch = false,
  children,
  isEmpty = false,
  emptyText = '',
  height = 362,
  onSearch = () => {},
  isLoading = false,
  isPercent = false,
}: Props) => {
  const [text, setText] = useState('')

  const onChange = (evt: any) => {
    setText(evt.target.value)
    onSearch(evt.target.value)
  }

  const unit = isPercent ? '%' : 'px'

  return (
    <BoxContent style={{ height: height + unit }}>
      <Header>
        <Row type="flex" justify="space-between">
          <Col>
            <TitleContent>{title}</TitleContent>
          </Col>
          {!isEmpty && hasSearch && (
            <Col span={12}>
              <SearchInput>
                <Input
                  suffix={
                    <img
                      src={icSearch}
                      width="16px"
                      onClick={() => {
                        onSearch && onSearch(text)
                      }}
                    />
                  }
                  placeholder="ค้นหา"
                  value={text}
                  onChange={onChange}
                />
              </SearchInput>
            </Col>
          )}
        </Row>
      </Header>

      {!isEmpty ? (
        children
      ) : isLoading ? (
        <LoadingStyle>
          <Loading size={60} />
        </LoadingStyle>
      ) : (
        <EmptyBox>
          <Row>
            <Col>
              <EmptyIcon>
                <img src={empty} />
              </EmptyIcon>
            </Col>
            <Col>
              <EmptyText>{emptyText}</EmptyText>
            </Col>
          </Row>
        </EmptyBox>
      )}
    </BoxContent>
  )
}

const LoadingStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 256px;
`

const BoxContent = styled.div`
  width: 100%;
  background-color: #fff;
  border-radius: 5px;
  border: solid 1px #eeeff2;
  padding: 24px 0;
  overflow: hidden;
`

const TitleContent = styled.span`
  font-size: 24px;
  font-family: 'DB Helvethaica X 75 Bd';
  color: #000000;
`

const SearchInput = styled.div`
  width: 100%;
  .ant-input {
    font-size: 20px;
    padding-left: 0;
    border: none;
    border-bottom: 1px solid #8b99a7;
    border-radius: 0;
  }
  img {
    cursor: pointer;
  }
`

const Header = styled.div`
  padding: 0 24px;
`

const EmptyBox = styled.div`
  height: 256px;
  -webkit-box-pack: center;
  justify-content: center;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  text-align: center;
`

const EmptyIcon = styled.div`
  img {
    width: 70px;
  }
`

const EmptyText = styled.div`
  font-size: 20px;
  color: #000;
  padding-top: 10px;
`

export default ContentBox
