import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Picker } from 'Components/Shared/Form'
import { Row, Col, Modal, Button } from 'antd'
import { ModelListItem } from 'features/admin/model/types'
import { cloneDeep } from 'lodash'
import imgDefault from 'assets/img/empty-status-image.png'
import file from 'utils/file'
import { LIMIT_FILE } from 'config'
import {
  CloseIcon,
  AddIcon,
  TrashIcon,
  PlanIcon,
  FloorPlanIcon,
  EditIcon,
} from 'Components/Shared/Icon'
import FloorMenu from './FloorMenu'
import { SecondaryButton } from 'Components/Shared/Button'
import { ConfirmDeleteModal, AddModal } from 'Components/Shared'
import { ConfirmModal } from 'Components/Defect'
import { DropdownList } from 'Components/Shared/Form'

import InputText from 'Components/Shared/Input/InputText'
type Props = {
  direction: string
  item: ModelListItem
  onChangeData: (val: any) => void
  floor: number
}

const AreaFloor: React.ComponentType<Props> = props => {
  const { direction, item, onChangeData, floor } = props
  const [text, setText] = useState('')
  const [indexDelete, setIndexDelete] = useState(1)
  const [confirmDelete, setConfirmDelete] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [indexEdit, setIndexEdit] = useState(0)
  const content =
    floor != -1 &&
    item.ConfigAreaList.filter(
      area =>
        area.Floor.toString() ===
        (item.ConfigList.length > -1
          ? item.ConfigList[floor].UnitFloorName
          : item.ConfigList[0].UnitFloorName)
    ).length > 0 ? (
      item.ConfigAreaList.filter(
        area =>
          area.Floor.toString() ===
          (item.ConfigList.length > -1
            ? item.ConfigList[floor].UnitFloorName
            : item.ConfigList[0].UnitFloorName)
      )?.map((it: any, index) => {
        return (
          <Item key={index}>
            <ItemView>{it.AreaName}</ItemView>
            <Icon style={{ marginLeft: 20 + 'px' }}>
              {' '}
              <Pointer
                style={{
                  float: 'right',
                  marginTop: '0px',
                }}
              >
                <TrashIcon
                  size={17}
                  color="#8b99a7"
                  onClick={() => {
                    setIndexDelete(
                      item.ConfigAreaList.findIndex(
                        i => i.AreaName === it.AreaName && i.Floor == it.Floor
                      )
                    )
                    setConfirmDelete(true)
                  }}
                />{' '}
                <EditIcon
                  size={17}
                  color="#8b99a7"
                  onClick={() => {
                    setIsEdit(true)
                    setText(it.AreaName)
                    setIndexEdit(
                      item.ConfigAreaList.findIndex(
                        i => i.AreaName === it.AreaName && i.Floor == it.Floor
                      )
                    )
                  }}
                />
              </Pointer>
            </Icon>
          </Item>
        )
      })
    ) : (
      <Item>
        {' '}
        <ItemBlank>ไม่มีรายการ</ItemBlank>
      </Item>
    )

  const onClickConfirmDelete = () => {
    item.ConfigAreaList.splice(indexDelete, 1)
    setConfirmDelete(false)
    onChangeData(item)
  }

  return (
    <Margin>
      <ConfirmDeleteModal
        title="ลบรายการ"
        desc={'คุณยืนยันต้องการลบ พื้นที่รายการ นี้'}
        onClose={() => setConfirmDelete(false)}
        onSubmit={onClickConfirmDelete}
        visible={confirmDelete}
      />
      <Row type="flex" justify="space-between" align="middle">
        <Col span={24}>
          <ContentArea>
            <ChecklistHeader>
              <Row type="flex" justify="space-between" align="middle">
                <Col>รายการพื้นที่</Col>
                <Col>
                  {' '}
                  {`( ${
                    item.ConfigAreaList.filter(
                      area =>
                        area.Floor.toString() ===
                        (item.ConfigList.length > -1
                          ? item.ConfigList[floor].UnitFloorName
                          : item.ConfigList[0].UnitFloorName)
                    ).length
                  } )`}{' '}
                  รายการ
                </Col>
              </Row>
            </ChecklistHeader>
            <MarginInside>
              <Row>
                <Col span={19}>
                  <InputText
                    prefix="ชื่อพื้นที่"
                    value={text}
                    onChange={(val: string) => {
                      setText(val)
                    }}
                  />{' '}
                </Col>
                <Col span={1}></Col>
                <Col span={1}>
                  <Button
                    onClick={() => {
                      if (text != '') {
                        if (isEdit) {
                          console.log(indexEdit)
                          console.log(item.ConfigAreaList[indexEdit].AreaName)
                          item.ConfigAreaList[indexEdit].AreaName = text
                        } else {
                          item.ConfigAreaList.unshift({
                            Floor: floor == 0 ? 1 : floor,
                            AreaName: text,
                          })
                        }
                        onChangeData(item)
                        setIsEdit(false)
                        setText('')
                      }
                    }}
                  >
                    {isEdit ? (
                      <EditIcon size={14} color="#003168" />
                    ) : (
                      <AddIcon size={14} color="#003168" />
                    )}
                    <span style={{ marginLeft: '3px' }}>
                      {isEdit ? 'แก้ไข' : 'เพิ่ม'}
                    </span>
                  </Button>
                </Col>
                <Col span={1}> </Col>
              </Row>
            </MarginInside>
            {content}
          </ContentArea>{' '}
        </Col>
      </Row>
    </Margin>
  )
}

const Icon = styled.div`
  margin-top: -2px;
  float: left;
`

const ImgSetting = styled.div`
  width: 184px;
  height: 136px;
  border-radius: 8px;
  border: solid 1px #979797;
  background-color: #ffffff;
  img {
    border-radius: 8px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &:hover {
    cursor: pointer;
  }
`

const Description = styled.span`
  font-size: 18px;
  color: #003168;
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 20px;
  text-align: center;
  margin: 10px 0;
  text-decoration: underline;
  cursor: pointer;
`

const ModelSetting = styled.div`
  background-color: rgba(139, 153, 167, 0.1);
  margin-top: 25px;
  padding: 25px;
`

const Title = styled.div`
  color: black;
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 28px;
`

const HeaderSection = styled.div`
  margin-bottom: 12px;
`

const AddImage = styled.div`
  width: 100%;
  padding: 10px 0;
  border-radius: 5px;
  border: solid 1.5px #003168;
  font-family: 'DB Helvethaica X 75 Bd';
  color: #003168;
  font-size: 20px;
  text-align: center;
`

const AddImageAndArea = styled.div`
  width: 100%;
  padding: 10px 0;
  border-radius: 5px;
  border: solid 1.5px #003168;
  font-family: 'DB Helvethaica X 75 Bd';
  color: #003168;
  font-size: 20px;
  text-align: center;
  cursor: pointer;
`

const SetImageBtn = styled.div`
  background-color: white;
  margin-top: 25px;
`

const Pointer = styled.div`
  cursor: pointer;
`
// height: 224px;
const ContentArea = styled.div`
  height: 400px;
  border-radius: 5px;
  border: solid 1px #eeeff2;
  margin-top: 20px;
  overflow-y: scroll;
`
const BodyArea = styled.div`
  padding: 0px 24px;
  margin-top: 10px;
  font-family: 'DB Helvethaica X 55 Regular';
  span {
    font-size: 20px;
  }
  .ant-dropdown-trigger {
    margin-bottom: 6px;
  }
  .input-title {
    margin-bottom: 12px;
  }
`

const Item = styled.div`
  border: solid 0px #eeeff2;
  border-bottom-width: 1px;
  height: 56px;
  align-items: center;
  display: flex;
  width: 90%;
  margin: 0 auto;
  span {
    width: 245px;
  }

  justify-content: space-between;
`

const ItemView = styled.div`
  font-family: 'DB Helvethaica X 55 Regular';
  font-size: 18px;
  margin-left: 10px;
  .ant-checkbox-wrapper > .ant-checkbox > .ant-checkbox-inner {
    border-radius: 0px !important;
  }
`

const Margin = styled.div`
  margin-right: 20px;
  margin-top: 15px;
  margin-left: 5px;
`

const MarginInside = styled.div`
  margin: 10px;
  margin-right: 30px;
`

const ItemBlank = styled.div`
  font-family: 'DB Helvethaica X 55 Regular';
  font-size: 18px;
  text-align: 'center';
  align-items: 'center';
  margin-left: 140px;
`

const ChecklistHeader = styled.div`
  padding: 12px 24px;
  background-color: #003168;
  font-family: 'DB Helvethaica X 75 Bd';
  color: #fff;
  font-size: 20px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`

export default AreaFloor
