import withAPIAction from 'utils/withAPIAction'
import {
  getDataListAction,
  createDataAction,
  updateDataAction,
  deleteDataAction,
} from './actions'

import {
  GetDataListReq,
  CreateDataReq,
  UpdateDataReq,
  DeleteDataReq,
} from './types'

import { verifyReqNoProject } from 'utils/verify'

export const getDataList = (token: string, data: GetDataListReq) => {
  const isValid = verifyReqNoProject(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<GetDataListReq>({
    method: 'POST',
    path: 'GetTeamList',
    data,
    token: `Bearer ${token}`,
  })(getDataListAction)
}

export const createData = (token: string, data: CreateDataReq) => {
  const isValid = verifyReqNoProject(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<CreateDataReq>({
    method: 'POST',
    path: 'CreateTeam',
    data,
    token: `Bearer ${token}`,
  })(createDataAction)
}

export const updateData = (token: string, data: UpdateDataReq) => {
  const isValid = verifyReqNoProject(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<UpdateDataReq>({
    method: 'POST',
    path: 'UpdateTeam',
    data,
    token: `Bearer ${token}`,
  })(updateDataAction)
}

export const deleteData = (token: string, data: DeleteDataReq) => {
  const isValid = verifyReqNoProject(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<DeleteDataReq>({
    method: 'POST',
    path: 'DeleteTeam',
    data,
    token: `Bearer ${token}`,
  })(deleteDataAction)
}
