import React from 'react'
import { Col } from 'antd'
import styled from 'styled-components'
import { TableRow } from 'Components/Shared/Table'

import { BackwardUnitDetail } from 'features/adminqc/backward-unit/types'
import { toMomentDateTime, toDateText } from 'utils/datetime'
import { mapUnitStatus, unitStatusIsPass } from 'utils/status-map'
import {
  StatusTag,
  ReportLink as Link,
} from 'Components/AdminQC/Report/Components'
import { numberFormat } from 'utils/format'
import { UndoIcon, TrashIcon } from 'Components/Shared/Icon'
import { OutlineButton } from 'Components/Shared/Button'

type DefaultProps = {
  item: BackwardUnitDetail
  showBackwardModal: (item: BackwardUnitDetail) => void
}

type Props = DefaultProps

const RowItem: React.FC<Props> = props => {
  const { item, showBackwardModal } = props

  const unitStaus = mapUnitStatus(item.CheckStatus).status
  const isPass = unitStatusIsPass(true, item.CheckStatus)

  const renderData = (val: any, span: number = 2) => {
    return <Col span={span}>{val || '-'}</Col>
  }

  return (
    <RowModel>
      <TableRow minHeight={70} spaceBetweenRow={8} paddingLeft={24}>
        <Col span={3}>
          <Bold>{item.ReqID}</Bold>
        </Col>
        <Col span={2}>
          <TxtReg>{item.ProjectCode}</TxtReg>
        </Col>
        <Col span={2}>
          <TxtReg>{item.UnitCode}</TxtReg>
        </Col>
        <Col span={3}>
          <TxtReg>{item.ModelName}</TxtReg>
        </Col>
        <Col span={3}>
          <TxtReg>{item.QCTypeVersion}</TxtReg>
        </Col>
        <Col span={3}>
          <TxtReg>{item.InspectionScore}</TxtReg>
        </Col>
        <Col span={3}>
          <StatusTag text={unitStaus} isPass={isPass} />
        </Col>
        <Col span={3}>
          <TxtReg>{toDateText(toMomentDateTime(item.SendReportDate))}</TxtReg>
        </Col>

        <Col span={3}>
          {isPass &&
            (item.QCTypeVersion.split('.')[1] === '2' ||
              item.QCTypeVersion.split('.')[1] === undefined) && (
              <Icon onClick={() => showBackwardModal(item)}>
                <UndoIcon size={20} color="#8b99a7" />
              </Icon>
            )}
        </Col>
      </TableRow>
    </RowModel>
  )
}

const RowModel = styled.div`
  .ant-row {
    cursor: auto;
  }
`

const Score: any = styled.div`
  font-size: 20px;
  font-family: 'DB Helvethaica X 75 Bd';
  text-decoration: underline;
  cursor: pointer;
`

const PassScore = styled(Score)`
  color: #63cea6;
`

const FailScore = styled(Score)`
  color: #d0494c;
`

const Bold: any = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  color: #000;
  font-size: 20px;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  padding-right: 5px;
`

const TxtReg = styled(Bold)`
  font-family: 'DB Helvethaica X 55 Regular';
`

const Icon = styled.div`
  float: left;
  margin-right: 20px;
  margin-top: 5px;
  cursor: pointer;
`

export default RowItem
