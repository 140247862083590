import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import RootState from 'typings/RootState'
import { connect, useDispatch } from 'react-redux'
import { Row } from 'antd'

import { CloseIcon } from 'Components/Shared/Icon'
import { PrimaryButton } from 'Components/Shared/Button'
import HeaderConfig from './HeaderConfig'
import ImageSetting from './ImageSetting'
import MappingUnit from './MappingUnit'

import { updateData, updateReq } from 'features/admin/floorPlan/actions'
import { SelectedFloorPlan } from 'features/admin/floorPlan/types'
import { PaginationType } from 'features/types/common'

type Props = ReduxProps &
  DispatchProps & {
    item: SelectedFloorPlan
    Token: string
    UserEmail: string
    ProjectCode: string
    onClose: () => void
    onSaveDetail: () => void
    pagination: PaginationType
  }

const FloorPlanDetail: React.ComponentType<Props> = props => {
  const {
    item,
    floorPlanMappingList,
    ProjectCode,
    onClose,
    pagination,
    onSaveDetail,
  } = props

  const [errorValidate, setErrorValidate] = useState('')

  const dispatch = useDispatch()

  const onChangeData = (value: SelectedFloorPlan) => {
    dispatch(updateData({ ...value }))
  }

  useEffect(() => {
    if (errorValidate !== '') {
      setErrorValidate('')
    }
  }, [item])

  const onSave = () => {
    if (item.FloorPlanName === '') {
      setErrorValidate('กรุณากรอกชื่อ floorplan ก่อนบันทึก')
      return
    }
    if (item.ImageFloorPlan == null) {
      setErrorValidate('กรุณาเลือก อัพโหลดรูป ก่อนบันทึก')
      return
    }

    dispatch(
      updateReq.request({
        floorPlan: item,
        ProjectCode,
        page: {
          Page: pagination.Page,
          RowPerPage: pagination.RowPerPage,
        },
      })
    )
    setErrorValidate('')
    onSaveDetail()
  }

  return (
    <>
      <MainPage>
        <Header>
          <CloseIcon color="#8b99a7" size={24} onClick={onClose} />
        </Header>
        <HeaderConfig item={item} onChangeData={onChangeData} />
        <ImageSetting item={item} onChangeData={onChangeData} />
        <MappingUnit item={item} mappingList={floorPlanMappingList} />
        <Row type="flex" justify="space-between">
          <ErrorMsg>{errorValidate}</ErrorMsg>
          <SaveArea>
            <PrimaryButton onClick={onSave} text="บันทึก" />
          </SaveArea>
        </Row>
      </MainPage>
    </>
  )
}

const MainPage = styled.div`
  -khtml-user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  overflow-x: scroll;
`

const Header = styled.div`
  text-align: right;
  height: 40px;
  padding: 24px;

  svg {
    cursor: pointer;
  }
`

const SaveArea = styled.div`
  width: 160px;
  height: 200px;
  margin: 32px 24px 0 0;
`

const ErrorMsg = styled.div`
  margin: 45px 0 0 24px;
  color: red;
`

const mapStateToProps = (state: RootState) => {
  return {
    floorPlanMappingList: state.adminFloorPlan.FloorPlanMappingList,
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(FloorPlanDetail)
