import React from 'react'
import styled from 'styled-components'
import { Row, Col, Layout } from 'antd'
import DefectStatus from 'Components/Defect/DefectStatus'
import { DefectItem } from 'features/pmr/types'

type Props = {
  defect: DefectItem
}

const DefectInfo: React.FC<Props> = ({ defect }) => {
  const title: string = `หมุดที่ ${defect.PinNumber}`
  const renderInfo = (title: string, value: string, span: number = 8) => {
    return (
      <Col span={span}>
        <DisplayItem>
          <label>{title}</label>
          <p>{value ? value : '-'}</p>
        </DisplayItem>
      </Col>
    )
  }

  const renderWarningInfo = (
    title: string,
    value: string,
    span: number = 8
  ) => {
    return (
      <Col span={span}>
        <DisplayWarning>
          <label>{title}</label>
          <p>{value ? value : '-'}</p>
        </DisplayWarning>
      </Col>
    )
  }

  return (
    <>
      <HeaderTitle>
        <Row type="flex" justify="space-between">
          <Col>
            <Title>
              {title}
              <DefectStatus pinStatus={defect.PinStatus} />
            </Title>
          </Col>
        </Row>
      </HeaderTitle>
      <MainData>
        <Row>
          {renderInfo('ลักษณะงานหลัก', defect.JobTypeName)}
          {renderInfo('ลักษณะงานรอง', defect.SubJobTypeName)}
          {renderInfo('รายละเอียด', defect.DetailName)}
        </Row>
        <Row>
          {renderInfo('พื้นที่', defect.LocationName)}
          {renderInfo('หมายเหตุ', defect.Remark)}
          {renderInfo(
            'แสนสิริจัดหา',
            defect.IsSansiriFixed ? 'เป็น' : 'ไม่เป็น'
          )}
        </Row>
        <Row>
          {renderInfo('ผู้รับเหมา', defect.VendorName)}
          {defect.FixReason && renderInfo('กลุ่ม Defect', defect.FixReason)}
          {defect.PMRRemark &&
            renderWarningInfo('เหตุผลที่ไม่อนุมัติ', defect.PMRRemark)}
        </Row>
      </MainData>
    </>
  )
}

const Title = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 28px;

  span {
    font-family: 'DB Helvethaica X 55 Regular';
    font-size: 16px;
    color: white;
    padding: 1px 10px;
    border-radius: 10px;
    margin-left: 12px;
  }
`

const MainData = styled.div`
  border-radius: 5px;
  border: solid 1px #eeeff2;
  background-color: white;
  padding: 24px;
`

const DisplayItem: any = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 20px;
  padding: 0 5px;
  p {
    font-family: 'DB Helvethaica X 55 Regular';
  }
`

const DisplayWarning = styled(DisplayItem)`
  color: #d0494c;
`

const HeaderTitle = styled.div`
  margin-bottom: 10px;
`

export default DefectInfo
