import React, { useEffect } from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import TopNav from 'Components/Shared/TopNav'
import TokenExpired from 'Components/Pages/TokenExpired'

import AuditRoadDashboard from 'Components/QC/Dashboard/Road/AuditRoad'
import AuditRoadReport from 'Components/QC/AuditRoad/Report'

type Props = {}

const QcAuditRoadApp: React.FunctionComponent<Props> = props => {
  return (
    <>
      <TopNav title="QC" displayProject={false} secondaryTitle="Road" />
      <Switch>
        <Route exact path="/audit-road" component={AuditRoadDashboard} />
        <Route
          exact
          path="/audit-road/report/:project/:requestID/:checkNumber"
          component={AuditRoadReport}
        />
        <Route exact path="/token-expired" component={TokenExpired} />
      </Switch>
    </>
  )
}

export default QcAuditRoadApp
