import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { connect, useDispatch } from 'react-redux'
import { RouteComponentProps, Link } from 'react-router-dom'
import RootState from 'typings/RootState'
import { BreadcrumbLink } from 'Components/Shared/BreadCrumb/BreadCrumb'
import {
  userEmailSelector,
  serviceTokenSelector,
} from 'features/sensitive/sensitive.selector'
import {
  selectData,
  selectEvent,
  selectSorting,
} from 'features/qc/constructRoad/checking/selector'
import {
  getCheckingConstructRoadByQC,
  acceptCheckingConstructRoadByQC,
} from 'features/qc/constructRoad/checking/api'
import {
  GetCheckingReq,
  Data,
  AcceptCheckingTaskReq,
} from 'features/qc/constructRoad/checking/types'
import { sortingTask } from 'features/qc/constructRoad/checking/actions'
import { StructureListPage } from 'Components/Shared/Seemore'
import { NoCard, EventModal, Loading } from 'Components/Shared'
import { RowItem, TaskFooter, TableHeader } from 'Components/QC/ProjectDetail'
import { Row, Col } from 'antd'
import PageIndex from 'Components/Shared/Pagination/PageIndex'
import { RoadConstructFilterPage } from 'Components/Filter'
import { selectRoadConstructFilter } from 'features/filter/selector'
import { getEvent } from 'features/wbs/wbs.selector'
import { PrimaryButton } from 'Components/Shared/Button'
import { RejectCheckingTaskReq } from 'features/qc/commissioning/checking/types'
import { RejectCheckingTasks } from 'features/qc/commissioning/checking/api'

type Props = ReduxProps &
  DispatchProps &
  RouteComponentProps<{ project: string }>

const TodoList: React.ComponentType<Props> = props => {
  const {
    match,
    data,
    event,
    sorting,
    token,
    UserEmail,
    filter,
    wbsEvent,
  } = props
  const { params } = match
  const { project } = params
  const [taskSelect, setTaskSelect] = useState([] as any[])
  const [enableModal, setEnableModal] = useState(false)
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const { List, Total } = data

  const dispatch = useDispatch()

  useEffect(() => {
    callGetChecking()
  }, [project, page, perPage])

  const callGetChecking = () => {
    setEnableModal(true)
    const data: GetCheckingReq = {
      ProjectCode: project,
      UserEmail,
      Page: page,
      RowPerPage: perPage,
      QCTypeID: filter.QCTypeID,
      UnitList: filter.UnitList,
      WbsList: filter.LeafWbsList,
    }
    dispatch(getCheckingConstructRoadByQC(token, data))
  }

  const onCallSuccess = () => {
    callGetChecking()
    setTaskSelect([])
  }

  const acceptTask = (date: string) => {
    setEnableModal(true)
    const req: AcceptCheckingTaskReq = {
      ProjectCode: project,
      UserEmail,
      AppointmentDate: date,
      TaskList: taskSelect,
    }
    dispatch(acceptCheckingConstructRoadByQC(token, req))
  }

  const rejectTask = () => {
    setEnableModal(true)
    let taskreqIdList: any = []
    taskSelect.map(item => {
      taskreqIdList.push({ RequestID: item.RequestID })
    })
    const req: RejectCheckingTaskReq = {
      ProjectCode: project,
      UserEmail,
      Module: 'ConstructRoad',
      ActionByModule: 'QC',
      TaskList: taskreqIdList,
    }
    dispatch(RejectCheckingTasks(token, req))
  }

  let projectName = `${data.ProjectCode} - ${data.ProjectNameTH}`

  const breadcrumbs: BreadcrumbLink[] = [
    {
      url: '/construct-road',
      title: 'Home',
      icon: '/home.png',
      active: true,
      showTitle: false,
    },
    {
      url: '/construct-road',
      title: 'บอร์ด',
      active: true,
      showTitle: true,
    },
    {
      url: '/',
      title: projectName,
      active: false,
      showTitle: false,
    },
  ]

  const onSort = (name: string) => {
    dispatch(sortingTask({ name }))
  }

  useEffect(() => {
    setEnableModal(false)
  }, [List])

  const renderContent = () => {
    return List.map((item: any, index) => {
      const isSelect = verifySelected(item)
      return (
        <RowItem
          task={item}
          key={index}
          onClick={() => onSelectTask(item)}
          isSelected={isSelect}
          type="road"
        />
      )
    })
  }

  const remove = (data: Data) => {
    return taskSelect.filter(
      item =>
        item.RequestID !== data.RequestID ||
        item.Unit !== data.Unit ||
        item.QCTypeID !== data.QCTypeID ||
        item.CheckNumber !== data.CheckNumber
    )
  }

  const verifySelectAll = () => {
    let isSelectAll = true
    List.map(item => {
      const isSelect = verifySelected(item)
      if (!isSelect) {
        isSelectAll = false
        return
      }
    })
    return isSelectAll
  }

  const verifySelected = (data: Data) => {
    const selectData = taskSelect.filter(item => {
      return (
        item.RequestID === data.RequestID &&
        item.Unit === data.Unit &&
        item.QCTypeID === data.QCTypeID &&
        item.CheckNumber === data.CheckNumber
      )
    })
    return selectData.length > 0 ? true : false
  }

  const pushData = (item: Data) => {
    return {
      RequestID: item.RequestID,
      Unit: item.Unit,
      QCTypeID: item.QCTypeID,
      CheckNumber: item.CheckNumber,
    }
  }

  const onSelectTask = (item: Data) => {
    const isSelect = verifySelected(item)
    let newValue = taskSelect
    if (isSelect) {
      newValue = remove(item)
    } else {
      newValue.push(pushData(item))
    }
    setTaskSelect([...newValue])
  }

  const onSelectAll = () => {
    const isSelectAll = verifySelectAll()
    let newValue = taskSelect
    if (isSelectAll) {
      newValue = []
    } else {
      List.map(item => {
        newValue.push(pushData(item))
      })
    }
    setTaskSelect([...newValue])
  }

  const tableHeader = (
    <TableHeader
      onSort={name => onSort(name)}
      sorting={sorting}
      isSelectAll={verifySelectAll()}
      onSelectAll={onSelectAll}
      showRadio={List.length > 0}
      type="road"
    />
  )

  const footer = (
    <TaskFooter
      taskSelect={taskSelect}
      onClick={date => acceptTask(date)}
      isLoading={event.IsLoading}
      desc="รายการ Request"
    />
  )

  const loading = (
    <div style={{ textAlign: 'center' }}>
      <Loading />
    </div>
  )

  const content =
    data.List && data.List.length > 0 ? (
      renderContent()
    ) : (
      <NoCard text={`ไม่มีรายการ Request`} />
    )

  const filterContent = (
    <Row type="flex" justify="space-between" align="middle">
      <Col>
        <RoadConstructFilterPage
          onSearch={() => {
            setPage(1)
            callGetChecking()
          }}
          ProjectCode={project}
        />
      </Col>
      <Col>
        <Btn>
          <PrimaryButton
            text="Reject Request"
            disabled={taskSelect.length <= 0 || event.IsLoading}
            onClick={rejectTask}
            btnType="Cancel"
          />
        </Btn>
      </Col>
    </Row>
  )

  const pageIndex = (
    <PageIndex
      currentPage={page}
      totalRows={Total}
      perPage={perPage}
      onClick={page => setPage(page)}
    />
  )

  return (
    <>
      <EventModal
        event={[event, wbsEvent]}
        enable={enableModal}
        successCalback={onCallSuccess}
      />
      <StructureListPage
        breadcrumbs={breadcrumbs}
        tableHeader={tableHeader}
        filterHeader={filterContent}
        tableContent={event.IsLoading && !enableModal ? loading : content}
        title={projectName}
        footer={footer}
        pagination={pageIndex}
      />
    </>
  )
}

const CommissioningType: any = {
  Q1: 1,
  Commissioning: 2,
  Safety: 3,
}
const mapStateToProps = (state: RootState) => {
  return {
    data: selectData(state),
    event: selectEvent(state),
    sorting: selectSorting(state),
    token: serviceTokenSelector(state),
    UserEmail: userEmailSelector(state),
    filter: selectRoadConstructFilter(state),
    wbsEvent: getEvent(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {
  sortingTask,
}

type DispatchProps = typeof mapDispatchToProps

const Btn = styled.div`
  width: 160px;
`

export default connect(mapStateToProps, mapDispatchToProps)(TodoList)
