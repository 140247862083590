import React from 'react'
import { Row, Button, Col, DatePicker } from 'antd'
import moment, { Moment } from 'moment-timezone'
import { translate } from 'i18n'
import TKeys from 'i18n/translationKey'
import { ActivityFilter } from 'features/activity/activity.types'
import { MainconFilter } from 'features/maincon/dashboard/types'

interface Props {
  filter: ActivityFilter | MainconFilter
  onChange(filter: ActivityFilter | MainconFilter): void
}

const DurationContent: React.FunctionComponent<Props> = ({
  filter,
  onChange,
}) => {
  const onClear = () => {
    filter.date.mode = ''
    filter.date.start = undefined
    filter.date.end = undefined
    onChange(filter)
  }

  const onUpdate = (mode: string, start: Moment, end: Moment) => {
    const date = filter.date
    if (date.mode === mode) {
      date.mode = ''
      date.start = undefined
      date.end = undefined
    } else {
      date.mode = mode
      date.start = start
      date.end = end
    }

    filter.date = date
    onChange(filter)
  }

  const on3Week = () => {
    const today = moment().startOf('day')
    const next = moment()
      .startOf('day')
      .add(3, 'weeks')

    onUpdate('3week', today, next)
  }

  const on1Month = () => {
    let today = moment().startOf('day')
    let next = moment()
      .startOf('day')
      .add(1, 'months')

    onUpdate('1month', today, next)
  }

  const on3Month = () => {
    let today = moment().startOf('day')
    let next = moment()
      .startOf('day')
      .add(3, 'months')

    onUpdate('3month', today, next)
  }

  const onCustom = () => {
    let today = moment().startOf('day')
    let next = moment()
      .startOf('day')
      .add(7, 'days')
    onUpdate('custom', today, next)
  }

  const onStartChange = (val: null | Moment) => {
    filter.date.start = val ? val : moment().startOf('day')
    onChange(filter)
  }

  const onEndChange = (val: null | Moment) => {
    filter.date.end = val ? val : moment().startOf('day')
    onChange(filter)
  }

  const date = filter.date
  let content
  if (date.mode === 'custom') {
    content = (
      <Row style={{ marginTop: '24px' }}>
        <Col span={24}>
          <div className="std-font-bold" style={css.title}>
            {translate(TKeys.Dashboard.day_work)}
          </div>
        </Col>

        <Col span={24} className="datetime-form">
          <div className="input-datetime">
            <div className="label">
              {translate(TKeys.Dashboard.plan_current_start_date)}
            </div>
            <DatePicker
              className="date-picker"
              value={date.start}
              onChange={onStartChange}
              format="DD/MM/Y"
              placeholder="DD/MM/YYYY"
              allowClear={false}
            />
          </div>

          <div className="input-datetime" style={{ marginTop: '16px' }}>
            <div className="label">
              {translate(TKeys.Dashboard.plan_current_end_date)}
            </div>
            <DatePicker
              className="date-picker"
              value={date.end}
              onChange={onEndChange}
              format="DD/MM/Y"
              placeholder="DD/MM/YYYY"
              allowClear={false}
            />
          </div>
        </Col>
      </Row>
    )
  }

  const active = 'filter-range-btn-action'
  return (
    <div className="std-font-regular" style={css.page}>
      <Row>
        <Col span={24}>
          <div className="std-font-bold" style={css.title}>
            {translate(TKeys.Dashboard.duration)}
            <span onClick={onClear} className="filter-clear std-font-regular">
              {translate(TKeys.Common.cancel_all)}
            </span>
          </div>
        </Col>

        <Col span={24}>
          <div className="button-group">
            <Button
              className={`filter-range-btn ${
                date.mode === '3week' ? active : ''
              }`}
              onClick={on3Week}
            >
              3 {translate(TKeys.Common.week)}
            </Button>
            <Button
              className={`filter-range-btn ${
                date.mode === '1month' ? active : ''
              }`}
              onClick={on1Month}
            >
              1 {translate(TKeys.Common.month)}
            </Button>
            <Button
              className={`filter-range-btn ${
                date.mode === '3month' ? active : ''
              }`}
              onClick={on3Month}
            >
              3 {translate(TKeys.Common.month)}
            </Button>
            <Button
              className={`filter-range-btn ${
                date.mode === 'custom' ? active : ''
              }`}
              onClick={onCustom}
            >
              {translate(TKeys.Dashboard.custom)}
            </Button>
          </div>
        </Col>
      </Row>

      {content}
    </div>
  )
}

const css: any = {
  page: {
    width: '400px',
    height: 'auto',
    padding: '25px 24px',
  },
  title: {
    textAlign: 'left',
    color: 'black',
    fontSize: '20px',
    marginBottom: '25px',
  },
}

export default DurationContent
