import withAPIAction from 'utils/withAPIAction'
import { verifyReq } from 'utils/verify'
import {
  GetCheckingConstructRoadAction,
  AcceptTaskAction,
  RejectTasksAction,
} from './actions'

import {
  GetCheckingReq,
  AcceptCheckingTaskReq,
  RejectCheckingTaskReq,
} from './types'

export const getCheckingConstructRoadByQC = (
  token: string,
  data: GetCheckingReq
) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<GetCheckingReq>({
    method: 'POST',
    path: 'GetCheckingConstructRoadByQC',
    data,
    token: `Bearer ${token}`,
  })(GetCheckingConstructRoadAction)
}

export const acceptCheckingConstructRoadByQC = (
  token: string,
  data: AcceptCheckingTaskReq
) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<AcceptCheckingTaskReq>({
    method: 'POST',
    path: 'AcceptCheckingConstructRoadByQC',
    data,
    token: `Bearer ${token}`,
  })(AcceptTaskAction)
}

export const RejectCheckingTasks = (
  token: string,
  data: RejectCheckingTaskReq
) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<RejectCheckingTaskReq>({
    method: 'POST',
    path: 'RejectCheckingTask',
    data,
    token: `Bearer ${token}`,
  })(RejectTasksAction)
}
