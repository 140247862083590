import { createStandardAction, createAsyncAction } from 'typesafe-actions'
import { GetApiResponse, PaginationType } from '../../types/common'

import {
  GetItemForFloorPlanApi,
  FloorPlan,
  SelectedFloorPlan,
  FloorPlanList,
} from './types'

export const reset = createStandardAction('admin/floorPlan/RESET_ACTION')<
  void
>()

export const getFloorPlanListReq = createAsyncAction(
  'admin/floorPlan/GET_FLOORPLAN_LIST_REQUEST',
  'admin/floorPlan/GET_FLOORPLAN_LIST_SUCCESS',
  'admin/floorPlan/GET_FLOORPLAN_LIST_FAILURE'
)<PaginationType, GetApiResponse<FloorPlanList>, Error>()

export const getItemForFloorPlanReq = createAsyncAction(
  'admin/floorPlan/GET_ITEM_FLOORPLAN_LIST_REQUEST',
  'admin/floorPlan/GET_ITEM_FLOORPLAN_LIST_SUCCESS',
  'admin/floorPlan/GET_ITEM_FLOORPLAN_LIST_FAILURE'
)<{ ProjectCode: string }, GetItemForFloorPlanApi, Error>()

export const deleteReq = createAsyncAction(
  'admin/floorPlan/DELETE_MODEL_LIST_REQUEST',
  'admin/floorPlan/DELETE_MODEL_LIST_SUCCESS',
  'admin/floorPlan/DELETE_MODEL_LIST_FAILURE'
)<
  { FloorPlanID: number; ProjectCode: string; page: PaginationType },
  void,
  Error
>()

export const setSelectedItem = createStandardAction(
  'admin/floorPlan/SET_SELECTED_MODEL'
)<FloorPlan | undefined>()

export const updateData = createStandardAction('admin/floorPlan/UPDATE_FIELD')<
  SelectedFloorPlan
>()

export const updateReq = createAsyncAction(
  'admin/floorPlan/SUBMIT_CREATE_UPDATE_FLOORPLAN_REQUEST',
  'admin/floorPlan/SUBMIT_CREATE_UPDATE_FLOORPLAN_SUCCESS',
  'admin/floorPlan/SUBMIT_CREATE_UPDATE_FLOORPLAN_FAILURE'
)<
  {
    floorPlan: SelectedFloorPlan
    ProjectCode: string
    page: PaginationType
  },
  void,
  Error
>()
