import withAPIAction from 'utils/withAPIAction'
import { getActivityAction } from './actions'
import { GetActivityRequest } from './types'

export const getQCActivityList = (token: string, data: GetActivityRequest) => {
  if (token === '') {
    return { type: 'tasklane/no_token' }
  }
  if (data.ProjectCode === '') {
    return { type: 'activity/PAYLOAD_NOT_READY' }
  }

  return withAPIAction<GetActivityRequest>({
    method: 'POST',
    path: 'GetQCActivityList',
    data,
    token: `Bearer ${token}`,
  })(getActivityAction)
}
