import React from 'react'
import styled from 'styled-components'
import { Col, Row } from 'antd'
import { InputText } from 'Components/Shared/Input'
import { User } from 'features/adminReport/certificate/types'
type Props = {
  data: User
  onUpdate?: (data: User) => void
}

const SignatureBox: React.ComponentType<Props> = props => {
  const { data, onUpdate = () => {} } = props
  const { PositionName, UserName } = data

  const onPosition = (val: string) => {
    data.PositionName = val
    onUpdate(data)
  }

  const onName = (val: string) => {
    data.UserName = val
    onUpdate(data)
  }

  return (
    <SignBox>
      <Row type="flex" align="bottom">
        <Col span={6} className="form">
          <InputText
            prefix=""
            value={PositionName}
            onChange={onPosition}
            placeholder="กรุณาใส่ตำแหน่ง"
          />
        </Col>
        <Col
          span={6}
          className="display"
          style={{ textAlign: 'right', marginRight: '5px' }}
        >
          {PositionName}
        </Col>
        <Col offset={1}>ลงชื่อ</Col>
        <Col span={12} offset={1}>
          <NameBox> </NameBox>
        </Col>
        <Col
          span={15}
          offset={7}
          style={{ marginTop: '10px' }}
          className="form"
        >
          <InputText
            prefix=""
            value={UserName}
            onChange={onName}
            placeholder="กรุณาใส่ชื่อและนามสกุล"
          />
        </Col>
        <Col
          span={15}
          offset={4}
          style={{ marginTop: '10px', textAlign: 'center' }}
          className="display"
        >
          {UserName}
        </Col>
      </Row>
    </SignBox>
  )
}

const SignBox = styled.div`
  padding: 10px;
  margin-top: 10px;
  .ant-input {
    text-align: left;
  }
`

const NameBox = styled.div`
  border-bottom: 1px dotted #000;
`

export default SignatureBox
