import { ActionType } from 'typesafe-actions'
import { Moment } from 'moment-timezone'
import * as actions from './actions'

import { TaskStatus, UnitStatus } from '../../types/status'

export type DashboardAction = ActionType<typeof actions>

export interface StoreState {
  data: DashboardData
  originalData: DashboardData
  event: DashboardEvent
  query: MainconQuery
  sorting: any
  filter: MainconFilter
  response: GetActivityApiResponse
}

export type Activity = {
  RowID: number
  ActivityID: string
  ActivityName: string
  BaselineStartDate: string
  BaselineFinishDate: string
  ConstructionTypeName: string
  StartDate: string
  FinishDate: string
  ActualStartDate: string
  ActualFinishDate: string
  ExpectFinishDate: string
  AppointmentDate: string
  InspectionDate: string
  ActivityTypeID: string
  ActivityTypeName: string
  SowID: string
  SowName: string
  WbsID: number
  WbsName: string
  Progress: number
  LastCurProgress: number
  LastReqProgress: number
  Unit: string
  Zone: string
  FloorName: string
  QCTypeID: number
  QCTypeName: string
  TaskStatus: TaskStatus
  TotalRows: number
}

export type QCActivity = {
  RowID: number
  Unit: string
  Zone: string
  Floor: string
  QCTypeID: string
  QCTypeName: string
  QCTypeVersion: string
  WorkCategoryID: number
  WorkCategoryName: string
  InspectionScore: number
  DocumentScore: number
  BasePoint: number
  CheckNumber: number
  LastCheckedModule: string
  LastUpdatedDate: string
  LastCheckedBy: string
  RequestID: number
  CheckStatus: UnitStatus
  DocumentStatus: UnitStatus

  NumOfPass: number
  NumOfFail: number
}

export interface GetActivityRequest {
  ProjectCode: string
  UserEmail: string
  Sort: any
  StartDate?: string
  FinishDate?: string
  Page?: number
  RowPerPage?: number
}

export interface GetDashboardActivityRequest {
  ProjectCode: string
  UserEmail: string
  Sort: any
  StartDate: string
  FinishDate: string
  ZoneList: string[]
  FloorList: string[]
  UnitList: string[]
  ConstructionTypeList: string[]
  WbsList: number[]
}

export interface GetActivityApiResponse
  extends GetApiResponse<GetActivityResponse> {}

export interface GetActivityResponse {
  ToDo: Activity[]
  InProgress: Activity[]
  Requested: Activity[]
  CMChecking: Activity[]
  FixFromQC: QCActivity[]
  TotalTodo: number
  TotalInProgress: number
  TotalRequested: number
  TotalCMChecking: number
  TotalFixFromQC: number
}

export interface GetApiResponse<T> {
  ResponseData: T
  ResponseCode: string
  ResponseMsg: 'Success' | 'Error'
  RequestTime: string
  Responsetime: string
}

export type DashboardData = {
  todo: Activity[]
  progress: Activity[]
  requested: Activity[]
  checking: Activity[]
  qc: QCActivity[]
  TotalTodo: number
  TotalInProgress: number
  TotalRequested: number
  TotalCMChecking: number
  TotalFixFromQC: number
}

export type DashboardEvent = {
  isFetching: boolean
  error: string
}

export type MainconFilter = {
  progress: number[]
  status: TaskStatus[]
  wbs: number[]
  date: {
    mode: string
    start: undefined | Moment
    end: undefined | Moment
  }
  floor: number[]
  unit: string[]
  zone: string[]
  type: string[]
}

export type MainconQuery = {
  date: {
    start: undefined | Moment
    end: undefined | Moment
  }
}

export enum TypeOfLane {
  TODO = 'todo',
  PROGRESS = 'progress',
  REQUESTED = 'requested',
  CHECKING = 'checking',
  QC = 'qc',
}
