import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Row, Col } from 'antd'
import RadioBox from 'Components/Shared/Input/RadioBox'
import {
  ModelListItem,
  SelectedModel,
  UnitList,
} from 'features/admin/model/types'

type Props = {
  direction: string
  item: ModelListItem
  modelMappingList: SelectedModel[]
}

function findUnit(item: ModelListItem, it: SelectedModel) {
  return item.UnitList.findIndex(unit => unit.UnitName === it.Unit)
}

function calculateInit(
  modelMappingList: SelectedModel[],
  item: ModelListItem,
  direction: string
) {
  let totalSelected = 0
  const isCheckAll = false
  modelMappingList.forEach((it: SelectedModel) => {
    let isSelected = false
    let detail = ''
    let isReadOnly = false
    if (it.Status.toLocaleLowerCase() === 'used') {
      isSelected = false
      isReadOnly = true
      detail = 'ใช้แล้ว'
      detail = it.ModelID === item.ModelID ? 'ใช้แล้ว' : 'ใช้แล้วกับ Model อื่น'
    } else {
      if (it.ModelID === item.ModelID && it.DirectionName === direction) {
        const indexAdded = findUnit(item, it)
        if (indexAdded !== -1) {
          isSelected = item.UnitList[indexAdded].Status === 'Y'
        } else {
          isSelected = true
        }
        totalSelected++
      } else {
        const modelId = it.ModelID || 0
        const hasDirection =
          it.DirectionName === 'L' || it.DirectionName === 'R'
        if (modelId !== 0 && hasDirection) {
          isReadOnly = true
          isSelected = true
          detail = 'ผูกแล้วกับ Model อื่น'
        } else {
          const indexAdded = findUnit(item, it)
          if (indexAdded !== -1) {
            totalSelected++
            const unit: UnitList = item.UnitList[indexAdded]
            if (unit.Status === 'Y') {
              if (unit.DirectionName === direction) {
                isSelected = true
              } else {
                isSelected = true
                isReadOnly = true
                detail = 'ผูกแล้วกับ Model อื่น'
              }
            }
          }
        }
      }
    }
    it.isSelected = isSelected
    it.detail = detail
    it.isReadOnly = isReadOnly
  })
  return { total: totalSelected, all: isCheckAll }
}

const MappingUnit: React.ComponentType<Props> = props => {
  const { direction, item, modelMappingList } = props
  const [totalSelect, settotalSelect] = useState(0)
  const [isCheckedAll, setisCheckedAll] = useState(false)
  const [, setState] = useState(0)
  useEffect(() => {
    const { total, all } = calculateInit(modelMappingList, item, direction)
    settotalSelect(total)
    setisCheckedAll(all)
    setState(t => (t += 1))
  }, [modelMappingList, direction, item])

  const onCheck = (it: SelectedModel, { val }: { val: boolean }) => {
    it.isSelected = val
    const index = findUnit(item, it)
    if (index > -1) {
      item.UnitList.splice(index, 1)
      if (val === false) {
        settotalSelect(pre => --pre)
      }
    } else {
      item.UnitList.push({
        UnitName: it.Unit,
        DirectionName: direction,
        Status: val ? 'Y' : 'N',
      })
      if (val === true) {
        settotalSelect(pre => ++pre)
      }
    }
    setState(t => (t += 1))
  }

  const onCheckAll = (val: boolean) => {
    let count = 0
    modelMappingList.forEach((it: SelectedModel) => {
      if (it.isReadOnly === false) {
        if (val === true) {
          ++count
        }
        if (it.isSelected !== val) {
          onCheck(it, { val: val })
        }
      }
    })
    settotalSelect(count)
    setisCheckedAll(val)
  }

  const content = modelMappingList.map((it: SelectedModel, index) => {
    return (
      <Item key={index}>
        <RadioBox
          label={it.Unit}
          text={it.detail}
          isSelected={it.isSelected}
          readOnly={it.isReadOnly}
          value={it}
          onClick={onCheck}
          tag={{ val: !it.isSelected }}
        />
      </Item>
    )
  })

  return (
    <Margin>
      <Row>
        <Col span={24}>
          {/* <BodyArea> */}
          <ContentArea>
            <ChecklistHeader>
              <Row type="flex" justify="space-between">
                <Col>รายการที่ต้องการผูก</Col>
                <Col>( {totalSelect} ) รายการ </Col>{' '}
              </Row>
            </ChecklistHeader>
            <ListSection>
              <MarginInside>
                <RadioBox
                  label={`เลือกยูนิตทั้งหมด (${totalSelect})`}
                  isSelected={isCheckedAll}
                  value={!isCheckedAll}
                  onClick={onCheckAll}
                />
              </MarginInside>
              <ContentArea>{content}</ContentArea>
            </ListSection>
            {/* </BodyArea> */}
          </ContentArea>
        </Col>
      </Row>
    </Margin>
  )
}

const MarginInside = styled.div`
  margin: 10px;
  margin-right: 35px;
`

const Margin = styled.div`
  margin-left: 20px;
  margin-top: 20px;
  margin-right: 10px;
`

const ContentArea = styled.div`
  height: 400px;
  border-radius: 5px;
  border: solid 1px #eeeff2;
  margin-top: 15px;
  overflow-y: scroll;
`
const BodyArea = styled.div`
  margin-top: 24px;
  padding: 0px 24px;
  font-family: 'DB Helvethaica X 55 Regular';
  span {
    font-size: 20px;
  }
`

const Title = styled.div`
  color: black;
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 28px;
`
const ListSection = styled.div`
  margin-top: 12px;
`

const Item = styled.div`
  border: solid 0px #eeeff2;
  border-bottom-width: 1px;
  height: 56px;
  align-items: center;
  display: flex;
  width: 90%;
  margin: 0 auto;
  span {
    width: 245px;
  }
`

const TextSelectCout = styled.div`
  margin-top: 6px;
`

const ChecklistHeader = styled.div`
  padding: 12px 24px;
  background-color: #003168;
  font-family: 'DB Helvethaica X 75 Bd';
  color: #fff;
  font-size: 20px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`

export default MappingUnit
