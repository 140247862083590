import React from 'react'
import ItemCheckList from './ItemList'

type Props = {
  datas: any[]
  active?: any
  onChange?: (value: any) => void
  disabled?: boolean
}

const Location: React.FunctionComponent<Props> = props => {
  const { datas, active = '', onChange, disabled = false } = props

  return (
    <ItemCheckList
      label="พื้นที่"
      name="LocationName"
      value="LocationID"
      active={active}
      list={datas}
      onChange={onChange}
      disabled={disabled}
    />
  )
}

export default Location
