import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'

import { Layout } from 'antd'
import TopNav from 'Components/Shared/TopNav'
import TokenExpired from 'Components/Pages/TokenExpired'
import CmDashboard from 'Components/CM/Dashboard'
import CmSetting from 'Components/CM/Setting'
import CmSeemore from 'Components/CM/Seemore'
import CmActivity from 'Components/CM/Activity'
import CmUnitReport from 'Components/CM/Report'
import QcDashboard from 'Components/CM/QCDashboard'
import QcSeemore from 'Components/CM/QcSeemore'
import QcDetail from 'Components/CM/QcDetail'
import FunctionCheck from 'Components/QC/Unit/FunctionCheck'
import ZoneDashboard from 'Components/CM/Zone/Dashboard'
import ZoneSeemore from 'Components/CM/Zone/Seemore'
import GropingDefectList from 'Components/CM/Zone/GropingDefectList'
import PmrReport from 'Components/CM/Zone/PMRReport'

import ZoneReport from 'Components/CM/Zone/Report'
import RequestDetail from 'Components/CM/Zone/RequestDetail'

import CommissioningDashboard from 'Components/CM/Commissioning/Dashboard'
import CommissionActivity from 'Components/CM/Commissioning/Activity'
import CommissionDetail from 'Components/CM/Commissioning/Detail'

import ConstructPlanDashboard from 'Components/CM/SiteVisit/ConstructPlan/Dashboard'
import ConstructPlanReport from 'Components/CM/SiteVisit/ConstructPlan/Report'

import AuditPlanDashboard from 'Components/CM/SiteVisit/AuditPlan/Dashboard'
import AuditPlanReport from 'Components/CM/SiteVisit/AuditPlan/Report'

import AuditRoadDashboard from 'Components/CM/Road/AuditRoad/Dashboard'
import AuditRoadReport from 'Components/CM/Road/AuditRoad/Report'

import ConstructRoadDashboard from 'Components/CM/Road/ConstructRoad/Dashboard'
import ConstructRoadReport from 'Components/CM/Road/ConstructRoad/Report'
import ConstructRoadCreateRequest from 'Components/CM/Road/ConstructRoad/CreateRequest'

import MainconActivitySeemore from 'Components/Maincon/Seemore'
import { QueryParamProvider } from 'use-query-params'
import Siricon from './Siricon'

type Props = {}

const CmApp: React.FunctionComponent<Props> = props => {
  return (
    <BrowserRouter>
      <QueryParamProvider ReactRouterRoute={Route}>
        <Layout className="layout" hasSider={false}>
          <TopNav title="CMPJ" />
          <Route exact path="/" component={CmDashboard} />
          <Switch>
            <Route exact path="/dashboard" component={CmDashboard} />
            <Route exact path="/qcdashboard" component={QcDashboard} />
            <Route exact path="/setting" component={CmSetting} />
            <Route exact path="/seemore/:type" component={CmSeemore} />
            <Route exact path="/activity/:code" component={CmActivity} />
            <Route exact path="/qcseemore/:type" component={QcSeemore} />
            <Route
              exact
              path="/mcactivity/seemore/:type"
              component={MainconActivitySeemore}
            />
            <Route
              exact
              path="/qcreport/:unit/:qctype/:requestID"
              component={CmUnitReport}
            />
            <Route
              exact
              path="/qcdetail/:unit/:qctype/:requestID"
              component={QcDetail}
            />

            <Route
              exact
              path="/unit/function/:project/:unit/:qcType/:requestID/:checkno/:qcTypeVersion"
              component={FunctionCheck}
            />

            <Route
              exact
              path="/zone/report/:unit/:qcType/:requestID/:floor"
              component={ZoneReport}
            />

            <Route
              exact
              path="/zone/request/:unit/:qcType/:requestID/:floor"
              component={RequestDetail}
            />

            <Route exact path="/zone/dashboard" component={ZoneDashboard} />
            <Route exact path="/zone/seemore/:type" component={ZoneSeemore} />
            <Route
              exact
              path="/zone/defect-list"
              component={GropingDefectList}
            />
            <Route
              exact
              path="/zone/pmrreport/:project/:unit/:requestID/:qctypeID/:floor"
              component={PmrReport}
            />

            <Route
              exact
              path="/commissioning/dashboard"
              component={CommissioningDashboard}
            />

            <Route
              exact
              path="/commissioning/activity/:checkListID/:activityID"
              component={CommissionActivity}
            />

            <Route
              exact
              path="/commissioning/detail/:checkListID/:activityID/:requestID"
              component={CommissionDetail}
            />

            <Route
              exact
              path="/construct-plan"
              component={ConstructPlanDashboard}
            />

            <Route
              exact
              path="/construct-plan/report/:requestID/:checkno"
              component={ConstructPlanReport}
            />

            <Route exact path="/audit-plan" component={AuditPlanDashboard} />
            <Route
              exact
              path="/audit-plan/report/:requestID/:checkno"
              component={AuditPlanReport}
            />

            <Route exact path="/audit-road" component={AuditRoadDashboard} />
            <Route
              exact
              path="/audit-road/report/:requestID/:checkno"
              component={AuditRoadReport}
            />

            <Route
              exact
              path="/construct-road"
              component={ConstructRoadDashboard}
            />

            <Route
              exact
              path="/construct-road/report/:unit/:qcTypeID/:requestID/:checkno"
              component={ConstructRoadReport}
            />

            <Route
              exact
              path="/construct-road/create"
              component={ConstructRoadCreateRequest}
            />

            <Route exact path="/siricon-plan" component={Siricon} />

            <Route exact path="/token-expired" component={TokenExpired} />
          </Switch>
        </Layout>
      </QueryParamProvider>
    </BrowserRouter>
  )
}

export default CmApp
