import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { DropdownList } from 'Components/Shared/Form'
import { DataItem } from 'features/admin/user/types'
import { RoleListItem } from 'features/filter/types'

import UserBox from './UserBox'
import SowBox from './SowBox'

type Props = {
  user: DataItem
  roleList: RoleListItem
  onUpdate: (user: DataItem) => void
}

const EditUser: React.FunctionComponent<Props> = props => {
  const [roleMenu, setRoleMenu] = useState([] as any)
  const { user, roleList, onUpdate } = props

  useEffect(() => {
    const menu = roleList.List.map(it => {
      return {
        name: it.RoleName,
        value: it.RoleID,
      }
    })

    setRoleMenu(menu)
  }, [roleList])
  const status = [
    {
      name: 'Active',
      value: 'Active',
    },
    {
      name: 'Inactive',
      value: 'Inactive',
    },
  ]

  const isMasterPlan =
    !user.IsVendor &&
    user.ModuleList &&
    user.ModuleList.find(item => item.ModuleID === 1)
  const isShowSow = user.IsVendor || isMasterPlan
  const sowTitle = user.IsVendor ? 'SOW' : 'SOW (สำหรับ Master Plan)'

  return (
    <EditUserArea>
      <Title>เพิ่ม User</Title>
      <UserBox user={user} onUpdate={onUpdate} />
      {isShowSow && (
        <Sow>
          <Header>{sowTitle}</Header>
          <SowBox
            user={user}
            onSave={onUpdate}
            isMasterPlan={isMasterPlan ? true : false}
            currentVendorCode={user.IsVendor ? user.VendorCode : ''}
          />
        </Sow>
      )}
      <DropdownArea>
        <DropdownList
          label="สถานะ"
          value={user.Status}
          menu={status}
          onChange={(status: string) => {
            user.Status = status
            onUpdate(user)
          }}
        />
        <Space />
        <DropdownList
          label="Role"
          value={user.RoleID}
          menu={roleMenu}
          onChange={(id: any) => {
            user.RoleID = id
            onUpdate(user)
          }}
        />
      </DropdownArea>
    </EditUserArea>
  )
}

const Title = styled.div`
  color: black;
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 28px;
`

const EditUserArea = styled.div`
  padding-bottom: 24px;
`

const DropdownArea = styled.div`
  margin-top: 24px;
`

const Sow = styled.div`
  margin: 12px 0;
`

const Space = styled.div`
  margin-top: 10px;
`

const Header = styled.div`
  color: black;
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 20px;
`

export default EditUser
