import RootState from 'typings/RootState'
import { Event } from '../../../types/event'
import { Activity } from './types'

export const selectData = (state: RootState): Activity =>
  state.qcZoneChecking.data

export const selectEvent = (state: RootState): Event =>
  state.qcZoneChecking.event

export const selectSorting = (state: RootState) => state.qcZoneChecking.sorting
