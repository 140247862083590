import { put, take, takeEvery, select } from 'redux-saga/effects'
import { APIPath } from 'services/APIPath'
import { getType } from 'typesafe-actions'
import * as actions from './actions'
import * as uploadAction from '../../uploadfile/actions'
import { getUserProfile } from 'features/sensitive/sensitive.selector'

import {
  userEmailSelector,
  serviceTokenSelector,
} from '../../sensitive/sensitive.selector'

import { projectCodeWithSuffixSelector } from '../../project/project.selector'
import { sendActivityToCM } from './api'
import { uploadFileReq } from '../../uploadfile/api'
import { setWatermark } from '../../uploadfile/func'

function* uploadImgList(userEmail: string, token: string, list: any[]) {
  const sizePerReq = 5
  let result: any = []
  const profile = yield select(getUserProfile)
  const { FirstName = '', LastName = '' } = profile
  const username = `${FirstName} ${LastName} (MC)`

  const num = Math.ceil(list.length / sizePerReq)
  for (let i = 0; i < num; i++) {
    const start = i * sizePerReq
    const temp = list.slice(start, start + sizePerReq)
    let formData = new FormData()
    formData.set('UserEmail', userEmail)
    for (let index = 0; index < temp.length; index++) {
      const respFile = yield setWatermark(username, temp[index])
      formData.append('Files', respFile.file)
    }

    yield put(uploadFileReq(formData, token))
    const resp = yield take(uploadAction.uploadFileAsyncReq.success)
    const resultList = resp.payload.ResponseData
    result = result.concat(resultList)
  }

  return result
}

function* uploadFileList(userEmail: string, token: string, list: any[]) {
  const sizePerReq = 5
  let result: any = []
  const num = Math.ceil(list.length / sizePerReq)
  for (let i = 0; i < num; i++) {
    const start = i * sizePerReq
    const temp = list.slice(start, start + sizePerReq)
    let formData = new FormData()
    formData.set('UserEmail', userEmail)
    temp.forEach((item: any) => formData.append('Files', item.file))

    yield put(uploadFileReq(formData, token))
    const resp = yield take(uploadAction.uploadFileAsyncReq.success)
    const resultList = resp.payload.ResponseData
    result = result.concat(resultList)
  }

  return result
}

function* sendReqActivity(action: any) {
  const { req, token } = action.payload
  try {
    if (req.Images.length > 0) {
      req.Images = yield uploadImgList(req.UserEmail, token, req.Images)
    }

    if (req.Documents.length > 0) {
      req.Documents = yield uploadFileList(req.UserEmail, token, req.Documents)
    }

    yield put(sendActivityToCM(req, token))
  } catch (e) {}
}

function* allSaga() {
  yield takeEvery(getType(actions.submitReqActivity), sendReqActivity)
}

export default allSaga
