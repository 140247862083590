import RootState from 'typings/RootState'
import { AuditRoadReport } from './types'
import { Event } from '../../types/event'

export const selectReport = (state: RootState): AuditRoadReport =>
  state.reportAuditRoad.report

export const selectEvent = (state: RootState): Event =>
  state.reportAuditRoad.event

export const selectEventChecklist = (state: RootState): Event =>
  state.reportAuditRoad.eventChecklist

export const selectDefect = (state: RootState) => state.reportAuditRoad.defect

export const selectCheckListQC = (state: RootState) =>
  state.reportAuditRoad.checkListQC

export const selectPinList = (state: RootState) =>
  state.reportAuditRoad.pinSelected

export const selectLocationList = (state: RootState) =>
  state.reportAuditRoad.locationSelected

export const selectLocationEvent = (state: RootState): Event =>
  state.reportAuditRoad.locationEvent
