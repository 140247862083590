import React from 'react'
import { Col } from 'antd'
import styled from 'styled-components'
import { TableRow } from 'Components/Shared/Table'
import { DataItem } from 'features/adminReport/sqdbyproject/types'
import { numberFormat } from 'utils/format'

type DefaultProps = {
  item: DataItem
}

type Props = DefaultProps

const RowItem: React.FC<Props> = ({ item }) => {
  const renderData = (val: any, span: number = 6) => {
    return <Col span={span}>{val}</Col>
  }

  return (
    <RowModel>
      <TableRow
        minHeight={60}
        spaceBetweenRow={8}
        paddingLeft={24}
        cursor="default"
      >
        {renderData(
          <Bold>
            {item.ProjectCode}-{item.ProjectNameTH}
          </Bold>
        )}
        {renderData(<TxtReg>{item.TotalArea}</TxtReg>)}
        {renderData(<TxtReg>{numberFormat(item.UnitScoreFirst)}</TxtReg>, 4)}
        {renderData(<TxtReg>{numberFormat(item.UnitScoreLast)}</TxtReg>, 4)}
        {renderData(<TxtReg>{numberFormat(item.SiteVisitScore)}</TxtReg>, 4)}
      </TableRow>
    </RowModel>
  )
}

const RowModel = styled.div`
  cursor: pointer;
`

const Bold: any = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  color: #000;
  font-size: 20px;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  padding-right: 5px;
`

const TxtReg = styled(Bold)`
  font-family: 'DB Helvethaica X 55 Regular';
`
export default RowItem
