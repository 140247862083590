import React from 'react'

import { StatusOfTask, StateOfTask } from 'features/types/status'
import { BreadcrumbLink } from '../../Shared/BreadCrumb/BreadCrumb'
import SubnavBar from '../../Shared/SubnavBar'
import { translate } from '../../../i18n'
import TKeys from '../../../i18n/translationKey'
import { getCMByStatus } from 'utils/statusLookup'

type Props = {
  activity: any
  name?: string
  onClose?: () => void
}

const Breadcrumbs: React.ComponentType<Props> = props => {
  const { activity, name } = props
  const breadcrumbs: BreadcrumbLink[] = [
    {
      url: '/dashboard',
      title: 'Home',
      icon: '/home.png',
      active: true,
      showTitle: false,
    },
    {
      url: '/dashboard',
      title: translate(TKeys.Breadcrumb.dashboard),
      active: true,
      showTitle: true,
    },
  ]

  if (activity) {
    const status = getCMByStatus(activity.State, activity.TaskStatus)
    let type
    let headerTitle
    if (activity.State === StateOfTask.CM) {
      breadcrumbs.push({
        url: `/seemore/${status.type}`,
        title: status.text,
        active: true,
        showTitle: true,
      })
    } else {
      breadcrumbs.push({
        url: '',
        title: status.text,
        active: true,
        showTitle: true,
        onClick: () => {},
      })
    }

    breadcrumbs.push({
      url: `/activity/${activity.ActivityID}`,
      title: activity.ActivityName || 'activity',
      active: name ? true : false,
      showTitle: name ? true : false,
      onClick: props.onClose,
    })

    if (name) {
      breadcrumbs.push({
        url: `/activity/${activity.ActivityID}`,
        title: name,
        active: false,
        showTitle: false,
      })
    }
  }

  return <SubnavBar breadcrumbs={breadcrumbs} hideSearch={true} />
}

export default Breadcrumbs
