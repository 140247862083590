import React from 'react'
import { HistoryModal } from './index'
import styled from 'styled-components'

type Props = {
  isShow?: boolean
  onCloseModal: () => void
  warningList: any[]
}

const WarningListModal: React.FC<Props> = ({
  onCloseModal,
  isShow = false,
  warningList,
}) => {
  const contentWarningList = (
    <Content>
      <Title>ข้อควรระวัง</Title>
      {warningList && warningList.map((list, index) => {
        return (
          <Text key={index}>
            {index + 1}. {list}
          </Text>
        )
      })}
    </Content>
  )

  return (
    <HistoryModal
      content={contentWarningList}
      onCloseModal={onCloseModal}
      isShow={isShow}
    />
  )
}

export default WarningListModal

const Content = styled.div``

const Title = styled.div`
  font-size: 28px;
  font-family: 'DB Helvethaica X 75 Bd';
  color: #000000;
  margin-bottom : 25px;
`

const Text = styled.p`
  font-size: 20px;
  font-family: 'DB Helvethaica X 55 Regular';
  color: #000000;
`
