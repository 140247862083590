import React from 'react'
import AdminTab from 'Components/Shared/AdminTab'

interface Props {
  selected: 'Construct' | 'Audit'
}

const ReportSiteVisitTab: React.FunctionComponent<Props> = props => {
  const tabItem = [
    {
      link: '/report/constructplan',
      title: 'ระหว่างก่อสร้าง',
      isActive: props.selected == 'Construct',
    },
    {
      link: '/report/auditplan',
      title: 'เปรียบเทียบแบบจัดสรร',
      isActive: props.selected == 'Audit',
    },
  ]

  return <AdminTab tabItem={tabItem} />
}

export default ReportSiteVisitTab
