import RootState from 'typings/RootState'
import { createSelector } from 'reselect'
import { ZoneReport, PinList, MainconItem } from './types'
import { Event } from '../../types/event'

export const selectReport = (state: RootState): ZoneReport =>
  state.reportZone.report

export const selectEvent = (state: RootState): Event => state.reportZone.event

export const selectEventChecklist = (state: RootState): Event =>
  state.reportZone.eventChecklist

export const selectDefect = (state: RootState) => state.reportZone.defect

export const selectCheckListQC = (state: RootState) =>
  state.reportZone.checkListQC

export const selectPinList = (state: RootState): PinList =>
  state.reportZone.selectPinList

export const selecMainconList = (state: RootState): MainconItem[] =>
  state.reportZone.mainconList
