import React, { useState } from 'react'

import { AuditPlanReport } from 'features/report/auditPlan/types'

import InProFooter from './InProFooter'
import RejtFooter from './RejtFooter'

type Props = {
  report: AuditPlanReport
  visible?: boolean
  onSign: () => void
  onResend: () => void
}

const QcFooter: React.ComponentType<Props> = props => {
  const { report, visible = false, onSign, onResend } = props
  const { State, Status } = report

  if (State === 'QC' && visible) {
    if (Status === 'INPRO')
      return <InProFooter report={report} onSign={onSign} />
    else if (Status === 'REJT')
      return <RejtFooter report={report} onResend={onResend} />
  }

  return <div />
}

export default QcFooter
