import React from 'react'
import styled from 'styled-components'
import iconQC from 'assets/img/ic-qc-checked.png'
import iconBySelf from 'assets/img/ic-checked-by.png'
import { UnitStatus } from 'features/types/status'
import { numberFormat } from 'utils/format'
import { mapUnitStatus, unitStatusIsPass } from 'utils/status-map'

interface Props {
  score: number
  status: UnitStatus
  isShowStatus?: boolean
}

const UnitTaskStatus = (props: Props) => {
  const { score, status, isShowStatus = false } = props
  const scoreFormat = numberFormat(score)
  const isPass = unitStatusIsPass(true, status)

  if (isPass) {
    return (
      <>
        <PassScore>{scoreFormat} คะแนน</PassScore>
        {isShowStatus && (
          <PassStatus>{mapUnitStatus(status).status}</PassStatus>
        )}
      </>
    )
  } else {
    return (
      <>
        <FailScore>{scoreFormat} คะแนน</FailScore>
        {isShowStatus && (
          <FailStatus>{mapUnitStatus(status).status}</FailStatus>
        )}
      </>
    )
  }
}

const PassScore = styled.div`
  font-size: 24px;
  font-family: 'DB Helvethaica X 75 Bd';
  color: #5bc79e;
  line-height: 0.5;
`

const FailScore = styled.div`
  font-size: 24px;
  font-family: 'DB Helvethaica X 75 Bd';
  color: #d0494c;
  line-height: 0.5;
`

const PassStatus = styled.div`
  font-size: 16px;
  font-family: 'DB Helvethaica X 55 Regular';
  color: #5bc79e;
`

const FailStatus = styled.div`
  font-size: 16px;
  font-family: 'DB Helvethaica X 55 Regular';
  color: #d0494c;
`

export default UnitTaskStatus
