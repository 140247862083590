import React from 'react'
import { Row, Col } from 'antd'

import PageNumber from './PageNumber'
import PageIndex from './PageIndex'

type Props = {
  totalRows?: number
  currentPage?: number
  onClickPage?: (page: number) => void
  onChangeNumber?: (number: number) => void
  perPage?: number
  listRow?: number[]
}

const Pagination: React.FunctionComponent<Props> = props => {
  const {
    currentPage = 1,
    totalRows = 0,
    perPage = 20,
    onChangeNumber = () => {},
    onClickPage = () => {},
    listRow,
  } = props
  return (
    <Row className="content-footer">
      <Col span={10}>
        <PageNumber
          choices={listRow}
          totalRows={totalRows}
          defaultValue={perPage}
          onChange={number => {
            onChangeNumber(number)
            onClickPage(1)
          }}
        />
      </Col>
      <Col span={14}>
        <PageIndex
          currentPage={currentPage}
          totalRows={totalRows}
          perPage={perPage}
          onClick={page => onClickPage(page)}
        />
      </Col>
    </Row>
  )
}

export default Pagination
