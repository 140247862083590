import React, { useEffect } from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import TopNav from 'Components/Shared/TopNav'
import TokenExpired from 'Components/Pages/TokenExpired'
import CommissioningDashboard from 'Components/QC/Dashboard/Commissioning'
import TodoList from 'Components/QC/Commissioning/TodoList'
import QcCommissionDetail from 'Components/QC/Commissioning/Detail'
import ProjectList from 'Components/QC/ProjectList'

type Props = {}

const QcZoneApp: React.FunctionComponent<Props> = props => {
  return (
    <>
      <TopNav title="QC" displayProject={false} secondaryTitle="ส่วนกลาง" />
      <Switch>
        <Route
          exact
          path="/commissioning/dashboard"
          component={CommissioningDashboard}
        />
        <Route exact path="/commissioning/todo/:project" component={TodoList} />
        <Route
          exact
          path="/commissioning/detail/:project/:checkListID/:activityID/:requestID"
          component={QcCommissionDetail}
        />
        <Route exact path="/:type/projects" component={ProjectList} />
        <Route exact path="/token-expired" component={TokenExpired} />
      </Switch>
    </>
  )
}

export default QcZoneApp
