import withAPIAction from 'utils/withAPIAction'
import { APIPath } from 'services/APIPath'
import { fetchWbsListAsyncAction } from './wbs.actions'
import { FetchWbsListRequest } from './wbs.types'

type FetchWbsListRequestData = {
  email: string
  projectCode: string
  token: string
}

export const fetchWbsListRequest = (data: FetchWbsListRequestData) => {
  if (data.token === '') {
    return { type: 'tasklane/no_token' }
  }

  if (data.projectCode === '') {
    return { type: 'activity/PAYLOAD_NOT_READY' }
  }

  return withAPIAction<FetchWbsListRequest>({
    method: 'POST',
    path: APIPath.getWbsList,
    data: {
      UserEmail: data.email,
      ProjectCode: data.projectCode,
    },
    token: `Bearer ${data.token}`,
  })(fetchWbsListAsyncAction)
}
