import React from 'react'

type DefaultProps = {
  size?: number
  color?: string
  onClick?: () => void
}

const UndoIcon = (props: DefaultProps) => {
  const { size = 20, color = '#003168', onClick } = props
  return (
    <svg width={size} height={size} viewBox="0 0 17 16">
      <defs>
        <filter
          id="prefix__a"
          width="345.5%"
          height="393.1%"
          x="-122.7%"
          y="-97.7%"
          filterUnits="objectBoundingBox"
        >
          <feOffset dy="6" in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
            stdDeviation="5"
          />
          <feColorMatrix
            in="shadowBlurOuter1"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0"
          />
        </filter>
        <path
          id="prefix__b"
          d="M10.95 5.513H3.493l1.892-2.065c.322-.352.299-.898-.053-1.221-.352-.322-.898-.299-1.221.053L.893 5.793c-.311.34-.3.866.025 1.193l3.22 3.233c.168.17.389.254.61.254.222 0 .442-.083.61-.252.338-.336.34-.883.003-1.22L3.61 7.24h7.34c1.464 0 2.656 1.192 2.656 2.656 0 1.465-1.192 2.657-2.657 2.657H2.007c-.478 0-.864.387-.864.863 0 .478.386.864.864.864h8.942c2.418 0 4.384-1.967 4.384-4.384 0-2.417-1.966-4.384-4.384-4.384"
        />
      </defs>
      <g fill={color} fill-rule="evenodd" transform="translate(.873)">
        <use fill="#000" filter="url(#prefix__a)" href="#prefix__b" />
        <use fill={color} href="#prefix__b" />
      </g>
    </svg>
  )
}

export default UndoIcon
