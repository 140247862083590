import React from 'react'
import { Col, Row } from 'antd'
import styled from 'styled-components'
import { DescItem, ImageReport } from 'Components/AdminQC/Report/Components'
import { DefectItem as Defect } from 'features/report/auditRoad/types'
import DefectStatus from 'Components/Defect/DefectStatus'

type Props = {
  index: number
  defect: Defect
}

const DefectItem: React.ComponentType<Props> = props => {
  const { index, defect } = props

  const renderDescItem = (title: string, value: any) => {
    return <DescItem title={title} value={value || '-'} percentW={25} />
  }

  const getMethodType = () => {
    switch (defect.MethodTypeID) {
      case 1:
        return `${defect.WideOfDefect} x ${defect.LenOfDefect} ตารางเมตร`

      case 2:
        return `${defect.LenOfDefect} เมตร`

      case 3:
        return `${defect.NumOfPoint} จุด`
      default:
        return '-'
    }
  }

  const defectInfo = (
    <Row type="flex">
      {renderDescItem(
        `หมุดที่ ${defect.PinNumber}`,
        <DefectStatus pinStatus={defect.PinStatus} />
      )}
      {renderDescItem('ปริมาณวัด', getMethodType())}
      {renderDescItem('หมวดงานหลัก', defect.JobTypeName)}
      {renderDescItem('หมวดงานรอง', defect.SubJobTypeName)}
      {renderDescItem('รายละเอียด', defect.DetailName)}
      {renderDescItem('วิธีแก้ไข', defect.HowToFix)}
      {renderDescItem('พื้นที่', defect.Remark)}
    </Row>
  )

  // TODO: mock data for now, to be implemented
  const imageInfo = (
    <Row>
      <Col span={12}>
        <BeforeImage>
          <Title>Before</Title>
          <ImageReport
            image={defect.ImageBefore}
            remark={defect.ImageBefore.FileURL ? defect.Remark || '-' : ''}
            showRemark={false}
            hasMargin={false}
            title=""
          />
        </BeforeImage>
      </Col>
      <Col span={12}>
        <AfterImage>
          <Title>After</Title>
          <ImageReport
            image={defect.ImageAfter}
            remark={defect.ImageAfter.FileURL ? defect.HowToFix || '-' : ''}
            showRemark={false}
            hasMargin={false}
            title=""
          />
        </AfterImage>
      </Col>
    </Row>
  )

  const pageBreakBefore =
    index === 0 ? 'avoid' : index % 2 === 1 ? 'always' : 'avoid'
  return (
    <Box style={{ pageBreakBefore }}>
      {defectInfo}
      <Line />
      {imageInfo}
    </Box>
  )
}

const Box = styled.div`
  margin-top: 16px;
  border-radius: 8px;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.03);
  background-color: #fff;
  padding: 24px;
  @media print {
    border: 1px solid rgba(0, 49, 104, 0.05);
    border-radius: 5px;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.03);
  }
`

const Line = styled.div`
  width: 100%;
  background-color: rgba(0, 49, 104, 0.05);
  height: 1px;
  margin-top: 16px;
`

const BeforeImage = styled.div`
  margin: 0 6px;
  margin-top: 12px;
`

const AfterImage = styled.div`
  margin-left: 12px;
  margin-top: 12px;
`

const Title = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 20px;
  color: #000;
  margin-bottom: 12px;
`

export default DefectItem
