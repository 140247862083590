import RootState from 'typings/RootState'
import { createSelector } from 'reselect'
import { DocumentCheckList, GetDocumentReq, ActivityIDList } from './types'
import { Event } from '../../types/event'

export const selectData = (state: RootState): DocumentCheckList =>
  state.qcDocument.data

export const selectReq = (state: RootState): GetDocumentReq =>
  state.qcDocument.req

export const selectEvent = (state: RootState): Event => state.qcDocument.event

export const selectDocReject = (state: RootState): ActivityIDList[] =>
  state.qcDocument.selected
