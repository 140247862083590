import withAPIAction from 'utils/withAPIAction'
import {
  getConstructTypeAction,
  getProjectTypeAction,
  getUnitLisAction,
  getZoneLisAction,
  getFloorLisAction,
  getQCTypeLisAction,
  getRoleListAction,
  getActivityLisAction,
  getTowerLisAction,
} from './actions'

import {
  GetConstructTypeReq,
  GetProjectTypeReq,
  GetZoneListReq,
  GetUnitListReq,
  GetFloorListReq,
  GetQCTypeListReq,
  GetActivityReq,
  GetTowerListReq,
} from './types'

import { verifyReq, verifyReqNoProject } from 'utils/verify'

export const getConstructType = (token: string, data: GetConstructTypeReq) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<GetConstructTypeReq>({
    method: 'POST',
    path: 'GetConstructionTypeList',
    data,
    token: `Bearer ${token}`,
  })(getConstructTypeAction)
}

export const getProjectType = (token: string, data: GetProjectTypeReq) => {
  const isValid = verifyReqNoProject(data, token)
  if (isValid !== undefined) return isValid

  data.Page = 1
  data.RowPerPage = 20

  return withAPIAction<GetProjectTypeReq>(state => ({
    method: 'POST',
    path: 'GetProjectTypeList',
    data,
    token: `Bearer ${token}`,
  }))(getProjectTypeAction)
}

export const getZoneList = (token: string, data: GetZoneListReq) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<GetZoneListReq>({
    method: 'POST',
    path: 'GetZoneList',
    data,
    token: `Bearer ${token}`,
  })(getZoneLisAction)
}

export const getUnitList = (token: string, data: GetUnitListReq) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<GetUnitListReq>({
    method: 'POST',
    path: 'GetUnitList',
    data,
    token: `Bearer ${token}`,
  })(getUnitLisAction)
}

export const getFloorList = (token: string, data: GetFloorListReq) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<GetFloorListReq>({
    method: 'POST',
    path: 'GetFloorList',
    data,
    token: `Bearer ${token}`,
  })(getFloorLisAction)
}

export const getQCTypeList = (token: string, data: GetQCTypeListReq) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<GetQCTypeListReq>({
    method: 'POST',
    path: 'GetQCTypeList',
    data,
    token: `Bearer ${token}`,
  })(getQCTypeLisAction)
}

export const getRoleList = () => {
  return withAPIAction<any>(state => ({
    method: 'POST',
    path: 'GetRoleList',
    data: {
      UserEmail: state.sensitive.userEmail,
    },
    token: `Bearer ${state.sensitive.apiToken}`,
  }))(getRoleListAction)
}

export const getActivityListByType = (token: string, data: GetActivityReq) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<GetActivityReq>({
    method: 'POST',
    path: 'GetActivityListByType',
    data,
    token: `Bearer ${token}`,
  })(getActivityLisAction)
}

export const getTowerList = (token: string, data: GetTowerListReq) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<GetTowerListReq>({
    method: 'POST',
    path: 'GetTowerList',
    data,
    token: `Bearer ${token}`,
  })(getTowerLisAction)
}
