import React from 'react'
import { Col } from 'antd'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { CheckListItem } from 'features/checklist/local/types'
import { TableRow } from 'Components/Shared/Table'

type Props = {
  item: CheckListItem
}

const RowItem: React.FC<Props> = ({ item }) => {
  const link = `/local-checklist/${item.CheckListID}/edit`
  return (
    <Link to={link}>
      <TableRow minHeight={60} spaceBetweenRow={8} paddingLeft={27}>
        <Col span={2}>{item.RefID ? item.RefID : item.CheckListID}</Col>
        <Col span={7}>{item.CheckListName}</Col>
        <Col span={3}>{item.CheckItemCounter}</Col>
        <Col span={3}>{item.ActivityUsedCounter}</Col>
        <Col span={3}>{item.CreateDate}</Col>

        <Col span={6}>
          {item.UpdateDate} - โดย {item.UpdateBy}
        </Col>
      </TableRow>
    </Link>
  )
}

const Name = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  color: #000;
`

export default RowItem
