import React, { useState, useRef } from 'react'
import { Row, Col } from 'antd'
import { JobType, SubJobType, DetailType } from 'features/checklist/unit/types'
import JobBox from './JobBox'
import SubJobBox from './SubJobBox'
import DetailBox from './DetailBox'
import DetailConfigBox from './DetailConfigBox'
import Modal, { ModalHandles } from './Modal/Modal'
import { ZoneDetail, ZoneType } from 'features/admin/zone/types'
import { textToLowerCase } from 'utils/format'

export interface SelectType {
  job: number
  subJob: number
  detail: number
}

interface Props {
  isActive: boolean
  updateCheckList: (val: ZoneDetail) => void
  onSave: () => void

  zoneTypeList: ZoneType[]
  zoneDetail: ZoneDetail
}

const List: React.ComponentType<Props> = ({
  isActive,
  updateCheckList,
  onSave,
  zoneDetail,
  zoneTypeList,
}) => {
  const isUsed = textToLowerCase(zoneDetail.Status) === 'used'
  const [selected, setSelected] = useState<SelectType>({
    job: 0,
    subJob: 0,
    detail: 0,
  })
  const modalRef = useRef<ModalHandles>(null)

  const toggleModal = (tab: number) => {
    if (modalRef.current) {
      modalRef.current.showModal(tab)
    }
  }

  const onJob = (i: number) => {
    selected.job = i
    setSelected({ ...selected })
  }

  const onSubJob = (i: number) => {
    selected.subJob = i
    setSelected({ ...selected })
  }

  const onDetail = (i: number) => {
    selected.detail = i
    setSelected({ ...selected })
  }

  const getJob = () => {
    if (selected.job !== -1 && selected.job < zoneDetail.JobTypeList.length) {
      return zoneDetail.JobTypeList[selected.job]
    }

    return undefined
  }

  const getSubJob = (item: JobType | undefined) => {
    if (
      item &&
      selected.subJob !== -1 &&
      selected.subJob < item.SubJobTypeList.length
    ) {
      return item.SubJobTypeList[selected.subJob]
    }

    return undefined
  }

  const getDetail = (item: SubJobType | undefined) => {
    if (
      item &&
      selected.detail !== -1 &&
      selected.detail < item.DetailList.length
    ) {
      return item.DetailList[selected.detail]
    }

    return undefined
  }
  const jobItem: any | undefined = getJob()
  const subJobItem: any | undefined = getSubJob(jobItem)
  const detailItem: any | undefined = getDetail(subJobItem)

  const addJob = (job: any, index?: number) => {
    const obj = zoneDetail.JobTypeList

    if (index != undefined) {
      obj[index] = job
    } else {
      obj.push(job)
    }
    updateCheckList(zoneDetail)
  }

  const addSubJob = (subjob: any, index?: number) => {
    const obj = zoneDetail.JobTypeList[selected.job].SubJobTypeList

    if (index != undefined) {
      obj[index] = subjob
    } else {
      obj.push(subjob)
    }
    updateCheckList(zoneDetail)
  }

  const addDetail = (detail: any, index?: number) => {
    const obj =
      zoneDetail.JobTypeList[selected.job].SubJobTypeList[selected.subJob]
        .DetailList

    if (index != undefined) {
      obj[index] = detail
    } else {
      obj.push(detail)
    }
    updateCheckList(zoneDetail)
  }

  const deleteJob = (it: JobType) => {
    zoneDetail.JobTypeList = zoneDetail.JobTypeList.filter(
      job => job.JobTypeName !== it.JobTypeName
    )
    updateCheckList(zoneDetail)
  }

  const deleteSubJob = (it: SubJobType) => {
    if (jobItem) {
      jobItem.SubJobTypeList = jobItem.SubJobTypeList.filter(
        (job: any) => job.SubJobTypeName !== it.SubJobTypeName
      )

      zoneDetail.JobTypeList[selected.job] = jobItem
      updateCheckList(zoneDetail)
    }
  }

  const deleteDetail = (it: DetailType) => {
    if (subJobItem) {
      subJobItem.DetailList = subJobItem.DetailList.filter(
        (job: any) => job.DetailName !== it.DetailName
      )

      zoneDetail.JobTypeList[selected.job].SubJobTypeList[
        selected.subJob
      ] = subJobItem
      updateCheckList(zoneDetail)
    }
  }

  const openModalEdit = (obj: any, index: number, tab: number) => {
    if (modalRef.current) {
      modalRef.current.showModal(tab, obj, index, undefined)
    }
  }

  return (
    <div style={{ display: isActive ? '' : 'none' }}>
      <Row type="flex" justify="space-between">
        <Col span={12} style={css}>
          <JobBox
            item={zoneDetail}
            selected={selected.job}
            onSelected={onJob}
            showModal={toggleModal}
            onDelete={deleteJob}
            openModalEdit={openModalEdit}
            disabled={isUsed}
          />
        </Col>

        <Col span={12}>
          <SubJobBox
            item={jobItem}
            selected={selected.subJob}
            onSelected={onSubJob}
            showModal={toggleModal}
            onDelete={deleteSubJob}
            openModalEdit={openModalEdit}
            disabled={isUsed}
          />
        </Col>
      </Row>

      <Row type="flex" justify="space-between">
        <Col span={12} style={css}>
          <DetailBox
            item={subJobItem}
            selected={selected.detail}
            onSelected={onDetail}
            showModal={toggleModal}
            onDelete={deleteDetail}
            openModalEdit={openModalEdit}
            disabled={isUsed}
          />
        </Col>

        <Col span={12}>
          <DetailConfigBox
            item={detailItem}
            onSave={onSave}
            disabled={isUsed}
          />
        </Col>
      </Row>
      <Modal
        selected={selected}
        addJob={addJob}
        addSubJob={addSubJob}
        addDetail={addDetail}
        ref={modalRef}
        disabled={isUsed}
        zoneDetail={zoneDetail}
        zoneTypeList={zoneTypeList}
      />
    </div>
  )
}

const css = {
  paddingRight: '16px',
  marginBottom: '16px',
}
export default List
