import React from 'react'
import { Col } from 'antd'
import styled from 'styled-components'
import { TableRow, StatusCol } from 'Components/Shared/Table'
import { DefectItem } from 'features/report/zone/types'

import imgPhoto from 'assets/img/ic-photo.png'
import { RequireIcon } from 'Components/Shared/Icon'
import { RadioBox } from 'Components/Shared/Form'

type Props = {
  item: DefectItem
  onClick?: (item: DefectItem) => void
  isShowAfterImg?: boolean
  isShowRadio?: boolean
  isSelected?: boolean
  onSelected?: (item: DefectItem) => void
  showThumbnail?: boolean
}

const ReportItem: React.FunctionComponent<Props> = props => {
  const {
    item,
    onClick = (item: DefectItem) => {},
    isShowAfterImg = false,
    isShowRadio = false,
    isSelected = false,
    onSelected = (item: DefectItem) => {},
    showThumbnail = false,
  } = props

  const getRadioBox = (
    <Margin>
      <RadioBox
        value={''}
        label={''}
        isCheck={isSelected}
        onClick={onSelected}
        size={24}
      />
    </Margin>
  )

  const defect = item.PinStatus === 'Pass' ? DefectType.pass : DefectType.fail
  const color = defect.textColor
  const spanCondition = showThumbnail ? 4 : 5

  return (
    <RowView onClick={() => onClick(item)}>
      <TableRow>
        <Col span={2}>
          <StatusCol color={defect.color}>
            <Pin>{item.PinNumber}</Pin>
          </StatusCol>
        </Col>

        <Col span={spanCondition} style={{ color }}>
          {item.IsRequired && (
            <Space>
              <RequireIcon size={14} />
            </Space>
          )}
          {item.JobTypeName}
        </Col>

        <Col span={spanCondition} style={{ color }}>
          {item.SubJobTypeName}
        </Col>

        <Col span={spanCondition} style={{ color }}>
          {item.DetailName}
        </Col>

        <Col span={4}>
          <Status style={{ backgroundColor: defect.color }}>
            {defect.text}
          </Status>
        </Col>
        {isShowAfterImg && item.ImageAfter && (
          <Col span={2}>
            <img src={imgPhoto} alt="" width={22} />
          </Col>
        )}
        {showThumbnail && (
          <Col span={4}>
            <img
              src={
                item.ImageAfter
                  ? item.ImageAfter.FileURL
                  : item.ImageBefore.FileURL
              }
              alt=""
              width={100}
              height={70}
            />
          </Col>
        )}
        {isShowRadio && getRadioBox}
      </TableRow>
    </RowView>
  )
}

const DefectType = {
  pass: {
    color: '#5bc79e',
    textColor: 'black',
    text: 'ผ่าน',
  },
  fail: {
    color: '#d0494c',
    textColor: '#d0494c',
    text: 'ไม่ผ่าน',
  },
}

const RowView = styled.div`
  font-size: 20px;
  font-family: 'DB Helvethaica X 55 Regular';
  cursor: pointer;
`

const Pin = styled.div`
  padding-left: 24px;
  font-family: 'DB Helvethaica X 75 Bd';
`

const Status = styled.span`
  border-radius: 10px;
  padding: 2px 10px;
  font-size: 16px;
  color: white;
`

const Space = styled.div`
  padding-right: 5px;
  float: left;
`

const Margin = styled.div`
  margin-left: 4px;
`

export default ReportItem
