import React from 'react'
import { Switch } from 'antd'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

type Props = {
  onFunction?: () => void
  readonly?: boolean
  projectCode: string
  report: any
  RequestID: any
}

const SwitchFunction: React.FunctionComponent<Props> = props => {
  const {
    onFunction = () => {},
    projectCode = '',
    report,
    RequestID,
    readonly = false,
  } = props
  const functionUrl = `/unit/function/${projectCode}/${report.Unit}/${
    report.QCTypeID
  }/${RequestID}/${report.CheckNumber}/${report.QCTypeVersion}`

  const isEnable = report.FunctionStatus === 'Y'
  return (
    <>
      <Span>Function</Span>
      <Switch checked={isEnable} onChange={onFunction} disabled={readonly} />
      {isEnable && (
        <Link to={functionUrl}>
          <SeeList>ดูรายการ</SeeList>
        </Link>
      )}
    </>
  )
}

const SeeList = styled.span`
  font-size: 18px;
  font-family: 'DB Helvethaica X 75 Bd';
  color: #003168;
  margin-left: 12px;
  text-decoration: underline;
`

const Span = styled.span`
  font-size: 20px;
  font-family: 'DB Helvethaica X 55 Regular';
  color: #000;
  margin-right: 12px;
`

export default SwitchFunction
