import React, { useEffect, useState, useRef } from 'react'
import { connect, useDispatch } from 'react-redux'
import RootState from 'typings/RootState'
import styled from 'styled-components'
import { Row, Col } from 'antd'
import { RouteComponentProps } from 'react-router-dom'
import { BreadcrumbLink } from 'Components/Shared/BreadCrumb/BreadCrumb'
import SubnavBar from 'Components/Shared/SubnavBar'
import PrimaryButton from 'Components/Shared/Button/PrimaryButton'

import { selectedProjectCodeSelector } from 'features/selectedProject/selectedProject.selector'
import { getLocalCheckList } from 'features/checklist/local/api'
import { clearLocalCheckList } from 'features/checklist/local/actions'
import { getUserProfile } from 'features/sensitive/sensitive.selector'

import { getMasterCheckList } from 'features/checklist/master/api'
import { PaginationType } from 'features/types/common'
import {
  selectCheckList,
  selectEvent,
} from 'features/checklist/master/selector'

import { importMasterCheckListReq } from 'features/checklist/local/actions'
import { EventModal } from 'Components/Shared'
import AlertError from 'Components/Shared/AlertError'
import ImportBtn from 'Components/Shared/Table/ImportBtn'
import { projectsByIdSelector } from 'features/project/project.selector'
import { toCheckList } from 'importer/maincon'
import { updateImport } from 'features/checklist/master/actions'

import ImportModal, { Handles } from './ImportModal'
import EmptyData from './EmptyData'
import LocalCheckList from './LocalCheckList'
import Menu from '../../../MenuAdmin'
import { SearchInput } from 'Components/Filter'

const breadcrumbs: BreadcrumbLink[] = [
  {
    url: '/dashboard',
    title: 'Home',
    icon: '/home.png',
    active: true,
    showTitle: false,
  },
  {
    url: '/local-checklist',
    title: 'Checklist MainCon',
    active: false,
    showTitle: false,
  },
]

type Props = DispatchProps & RouteComponentProps & ReduxProps

const MainConChecklist: React.ComponentType<Props> = props => {
  const modalRef = useRef<Handles>(null)
  const [enableModal, setEnableModal] = useState(false)
  const [projectCode, setProjectCode] = useState('')
  const [err, setErr] = useState(undefined)
  const [searchText, setSearchText] = useState('')
  let hasData = true
  if (props.localChecklist && props.localChecklist.List) {
    hasData = props.localChecklist.List.length !== 0
  }

  const [pagination, setPagination] = useState<PaginationType>({
    Page: 1,
    RowPerPage: 10,
  })

  const dispatch = useDispatch()

  useEffect(() => {
    if (props.projectsById[props.projectCode]) {
      setProjectCode(props.projectCode)
    }
  }, [props.projectCode])

  useEffect(() => {
    return () => {
      props.clearLocalCheckList()
    }
  }, [])

  useEffect(() => {
    if (projectCode) {
      setEnableModal(true)
      props.getLocalCheckList(
        projectCode,
        pagination.Page,
        pagination.RowPerPage,
        searchText
      )
    }
  }, [projectCode, pagination])

  const onImport = (isSelectAll: boolean, selectList: string[]) => {
    if (projectCode) {
      setEnableModal(true)
      props.importMasterCheckList(projectCode, isSelectAll, selectList)
    }
  }

  const onShowModal = () => {
    if (modalRef.current) {
      modalRef.current.showModal()
    }
  }

  const onImportFile = (data: any) => {
    try {
      const checklist = toCheckList(data)
      dispatch(updateImport(checklist))
      props.history.push('/local-checklist/create')
    } catch (e) {
      setErr(e.message)
    }
  }

  const checkListBtnEnabled = props.userProfile.Roles.find(
    role => role.ModuleID === 9
  )
    ? true
    : false

  const header = (
    <Header>
      <Row type="flex" justify="space-between" align="top">
        <Col style={{ flex: 1 }}>
          Checklist Maincon
          {/* <SubTitle>
            กรุณาเลือก Checklist ที่ต้องการ เพื่อผูก Primavera activity
            ในโปรเจคของคุณ
          </SubTitle> */}
          <SubTitle>สำหรับสูตรก่อสร้าง R1 เป็นต้นไป</SubTitle>
          <SubTitle>Admin PJ map checklist เฉพาะงานถนนเท่านั้น</SubTitle>
          <SubTitle>
            สำหรับ Task บ้านรายแปลง ระบบจะ Map checklist maincon ให้อัตโนมัติ
          </SubTitle>
        </Col>

        {hasData && (
          <Col>
            <ButtonSection>
              <PrimaryButton text="Import dialog" onClick={onShowModal} />
            </ButtonSection>
            <ButtonSection>
              <ImportBtn text="Import checklist" onChange={onImportFile} />
            </ButtonSection>
            {/* {checkListBtnEnabled && (
              <ButtonSection>
                <PrimaryButton
                  text="เพิ่ม checklist"
                  onClick={() => props.history.push('/local-checklist/create')}
                />
              </ButtonSection>
            )} */}
          </Col>
        )}
      </Row>
    </Header>
  )

  const onSearch = (val: string) => {
    if (searchText === '' && val === '') return
    setSearchText(val)
    pagination.Page = 1
    setPagination({ ...pagination })
  }

  return (
    <>
      <EventModal event={[props.event]} enable={enableModal} />
      <AlertError
        isShow={err !== undefined}
        text={err}
        callback={() => setErr(undefined)}
      />
      <SubnavBar
        breadcrumbs={breadcrumbs}
        hideSearch={true}
        adminSearchBox={<SearchInput onSearch={onSearch} />}
      />
      <Menu selected="local-checklist">
        <MainPage>
          {header}
          {!hasData && searchText === '' && (
            <EmptyData onShowModal={onShowModal} projectCode={projectCode} />
          )}
          {hasData && (
            <LocalCheckList
              checklist={props.localChecklist}
              pagination={pagination}
              setPagination={setPagination}
            />
          )}
        </MainPage>
      </Menu>
      <ImportModal
        ref={modalRef}
        checklist={props.masterChecklist}
        isLoading={props.masterEvent.IsLoading}
        getMasterCheckList={props.getMasterCheckList}
        onOk={onImport}
      />
    </>
  )
}

const MainPage = styled.div`
  padding: 40px 45px;
  width: 100%;
`

const ButtonSection = styled.div`
  float: left;
  margin-left: 24px;
  width: 178px;
`

const Header = styled.div`
  position: relative;
  margin-bottom: 35px;
  font-size: 28px;
  line-height: 1.07;
  font-family: 'DB Helvethaica X 75 Bd';
  color: #000;
`

// const SubTitle = styled.div`
//   margin-top: 7px;
//   font-size: 18px;
//   color: #8b99a7;
// `

const SubTitle = styled.div`
  margin-top: 7px;
  font-size: 18px;
  color: #e53935;
`

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapStateToProps = (state: RootState) => {
  return {
    localChecklist: state.localChecklist.CheckList,
    event: state.localChecklist.Event,
    masterChecklist: selectCheckList(state),
    masterEvent: selectEvent(state),
    projectCode: selectedProjectCodeSelector(state),
    projectsById: projectsByIdSelector(state),
    userProfile: getUserProfile(state),
  }
}

const mapDispatchToProps = {
  getLocalCheckList,
  getMasterCheckList,
  clearLocalCheckList,
  importMasterCheckList: (
    ProjectCode: string,
    SelectedAll: boolean,
    selectList: string[]
  ) =>
    importMasterCheckListReq.request({
      ProjectCode,
      SelectedAll,
      selectList,
    }),
}

type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(MainConChecklist)
