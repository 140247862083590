import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { connect, useDispatch } from 'react-redux'
import { Row, Col, Drawer, Input } from 'antd'
import { RouteComponentProps } from 'react-router-dom'
import RootState from 'typings/RootState'
import SubnavBar from 'Components/Shared/SubnavBar'
import { BreadcrumbLink } from 'Components/Shared/BreadCrumb/BreadCrumb'
import { TableHeader, TableColHeader } from 'Components/Shared/Table'
import Pagination from 'Components/Shared/Pagination'
import AdminProjectDetail from './Detail'
import Menu from '../../MenuAdmin'
import { projectCodeWithSuffixSelector } from 'features/project/project.selector'

import {
  userEmailSelector,
  serviceTokenSelector,
} from 'features/sensitive/sensitive.selector'

import {
  getDataList,
  updateData,
  importMultiProject,
  exportCurrentData,
} from 'features/admin/project/api'
import {
  selectDataList,
  selectEvent,
  selectExportData,
} from 'features/admin/project/selector'
import {
  GetDataListReq,
  DataItem,
  UpdateDataReq,
  Tribe,
  UpdateMultiProjectReq,
} from 'features/admin/project/types'
import { getDataList as getTeamList } from 'features/admin/team/api'
import { GetDataListReq as GetTeamListReq } from 'features/admin/team/types'

import { selectDataList as selectTeamList } from 'features/admin/team/selector'

import RowItem from './RowItem'
import {
  AlertError,
  AlertSuccess,
  EventModal,
  LoadingView,
} from 'Components/Shared'
import { SearchInput } from 'Components/Filter'
import ImportBtn from 'Components/Shared/Table/ImportBtn'
import XLSX from 'xlsx'
import { SecondaryButton } from 'Components/Shared/Button'
import { toDateText } from 'utils/datetime'
import moment from 'moment'

const breadcrumbs: BreadcrumbLink[] = [
  {
    url: '/dashboard',
    title: 'Home',
    icon: '/home.png',
    active: true,
    showTitle: false,
  },
  {
    url: '/project',
    title: 'Project list',
    active: false,
    showTitle: false,
  },
]

type DefultProps = {
  homepage?: boolean
}

type Props = DefultProps & ReduxProps & DispatchProps & RouteComponentProps

const AdminProject: React.ComponentType<Props> = props => {
  const { token, UserEmail, projectList, event, teamList, exportData } = props
  const [displayDrawer, setDisplayDrawer] = useState(false)
  const [enableModal, setEnableModal] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [mode, setMode] = useState('' as 'update' | '')

  const [selectedItem, setSelectedItem] = useState({
    ProjectCode: undefined,
  } as any)
  const [pagination, setPagination] = useState<{
    Page: number
    RowPerPage: number
  }>({
    Page: 1,
    RowPerPage: 10,
  })

  const dispatch = useDispatch()

  useEffect(() => {
    onGetList()
  }, [pagination])

  useEffect(() => {
    setEnableModal(false)
    onGetTeamList()
  }, [])

  useEffect(() => {
    if (exportData.List.length > 0) onDataToExcel()
  }, [exportData.List.length > 0])

  const onGetList = () => {
    const req: GetDataListReq = {
      UserEmail,
      Page: pagination.Page,
      RowPerPage: pagination.RowPerPage,
      TextFilter: searchText,
    }
    dispatch(getDataList(token, req))
  }

  const onUpdateProject = (data: DataItem) => {
    setEnableModal(true)
    setMode('update')
    const req: UpdateDataReq = {
      UserEmail,
      ProjectCode: data.ProjectCode,
      TeamID: data.TeamID,
      SquadID: data.SquadID,
      ManagerName: data.ManagerUserName,
      ManagerUserID: data.ManagerUserID,
      // ManagerName : 'Test',
      // ManagerUserID : 1,
      ToEmailList: data.ToEmailList,
      CCEmailList: data.CCEmailList,
      Status: data.Status,
    }

    dispatch(updateData(token, req))
  }

  const onUpdateMultiProject = (Tribes: Tribe[]) => {
    const req: UpdateMultiProjectReq = {
      UserEmail,
      Tribes,
    }

    dispatch(importMultiProject(token, req))
  }

  const onGetTeamList = () => {
    setEnableModal(true)
    const req: GetTeamListReq = {
      UserEmail,
    }
    dispatch(getTeamList(token, req))
  }

  const onCloseDrawer = () => {
    setSelectedItem({})
    setDisplayDrawer(false)
  }

  const onOpenDrawer = (evt: any, item: any) => {
    setSelectedItem(item)
    setDisplayDrawer(true)
  }

  const onSave = (project: DataItem) => {
    onUpdateProject(project)
    setDisplayDrawer(false)
  }

  const onSuccess = () => {
    if (mode === 'update') {
      setMode('')
      setSelectedItem({})
      setEnableModal(false)
      onGetList()
    }
  }

  const onError = () => {
    if (mode === 'update') {
      setDisplayDrawer(true)
    }
  }

  const onAfterImportFile = (success: boolean, errMsg = '') => {
    if (success) {
      onGetList()
      onGetTeamList()
    }
    console.log('onAfterImport fail: ', errMsg)
  }

  const content = projectList.List.map((item: DataItem, index: number) => {
    const selected = item.ProjectCode === selectedItem.ProjectCode

    return (
      <RowItem
        item={item}
        selected={selected}
        onClick={onOpenDrawer}
        key={index}
      />
    )
  })

  const onSearch = (val: string) => {
    if (searchText === '' && val === '') return
    setSearchText(val)
    pagination.Page = 1
    setPagination({ ...pagination })
  }

  const onImportFile = (data: any, file: any, name: any) => {
    const wb = XLSX.read(data, { type: 'binary' })

    //support only first sheet
    const sheetName = wb.SheetNames[0]
    const rows = wb.Sheets[sheetName]
    const rawData = XLSX.utils.sheet_to_json(rows)

    if (rawData) {
      let Tribes: Tribe[] = []

      let tribeName = ''
      let squadName = ''
      let projectCode = ''
      let managerUserEmail = ''

      console.log(JSON.stringify(rawData))

      rawData.map((raw: any, index) => {
        const isHeadRow = Object.keys(raw).length === 4
        const toEmail = raw['ToEmails'] as string
        // const ccEmail = raw['CCEmails'] as string

        if (isHeadRow) {
          tribeName = ''
          squadName = ''
          projectCode = ''
          managerUserEmail = ''

          const tribeSquadData = raw['Tribe/Squad'] as string
          tribeName = tribeSquadData.split('/')[0] ?? ''
          squadName = tribeSquadData.split('/')[1] ?? ''
          projectCode = raw['Project'] as string
          managerUserEmail = raw['ManagerUserEmail'] as string

          if (Tribes.findIndex(tribe => tribe.Name === tribeName) === -1) {
            Tribes.push({
              Name: tribeName,
              Squads: [
                {
                  Name: squadName,
                  Projects: [
                    {
                      ManagerUserEmail: managerUserEmail,
                      ProjectCode: projectCode,
                      ToEmailList: [toEmail],
                      // CCEmailList: [ccEmail],
                    },
                  ],
                },
              ],
            })
          } else {
            let tribeIndex = Tribes.findIndex(tribe => tribe.Name === tribeName)
            let squadIndex = Tribes[tribeIndex]?.Squads.findIndex(
              squad => squad.Name === squadName
            )
            let projectIndex = Tribes[tribeIndex]?.Squads[
              squadIndex
            ]?.Projects.findIndex(
              project => project.ProjectCode === projectCode
            )

            if (squadIndex === -1) {
              Tribes[tribeIndex].Squads.push({
                Name: squadName,
                Projects: [
                  {
                    ManagerUserEmail: managerUserEmail,
                    ProjectCode: projectCode,
                    ToEmailList: [toEmail],
                    // CCEmailList: [ccEmail],
                  },
                ],
              })
            } else if (projectIndex === -1) {
              Tribes[tribeIndex].Squads[squadIndex].Projects.push({
                ManagerUserEmail: managerUserEmail,
                ProjectCode: projectCode,
                ToEmailList: [toEmail],
                // CCEmailList: [ccEmail],
              })
            }
          }
        } else {
          let mappedTribe = Tribes.findIndex(tribe => tribe.Name === tribeName)
          let mappedSquad = Tribes[mappedTribe]?.Squads.findIndex(
            squad => squad.Name === squadName
          )
          let mappedProject = Tribes[mappedTribe]?.Squads[
            mappedSquad
          ]?.Projects.findIndex(project => project.ProjectCode === projectCode)

          if (mappedTribe > -1 && mappedSquad > -1 && mappedProject > -1) {
            Tribes[mappedTribe]?.Squads[mappedSquad]?.Projects[
              mappedProject
            ].ToEmailList.push(toEmail)
            // Tribes[mappedTribe]?.Squads[mappedSquad]?.Projects[
            //   mappedProject
            // ].CCEmailList.push(ccEmail)
          }
        }
      })

      onUpdateMultiProject(Tribes)
    }
  }

  const onExportCurrentData = () => {
    const req: GetDataListReq = {
      UserEmail,
      Page: 1,
      RowPerPage: 999999,
      TextFilter: '',
    }

    dispatch(exportCurrentData(token, req))
  }

  const onExportFile = () => {
    onExportCurrentData()
  }

  const onDataToExcel = () => {
    let rawData: any[] = []

    exportData.List.map(data => {
      if (data && data.TeamName && data.SquadName) {
        const tribeSquadName = data.TeamName + '/' + data.SquadName

        if (
          rawData.findIndex(
            rd =>
              rd['Tribe/Squad'] + rd.Project ===
              tribeSquadName + data.ProjectCode
          ) === -1
        )
          rawData.push({
            'Tribe/Squad': tribeSquadName,
            Project: data.ProjectCode,
            ManagerUserEmail: data.ManagerUserEmail ?? 'xx',
            ToEmails: data.ToEmailList.length > 0 ? data.ToEmailList[0] : '',
            // CCEmails: data.CCEmailList.length > 0 ? data.CCEmailList[0] : '',
          })

        const toEmails =
          data.ToEmailList.length > 1
            ? data.ToEmailList.slice(1, data.ToEmailList.length)
            : []
        // const ccEmails =
        //   data.CCEmailList.length > 1
        //     ? data.CCEmailList.slice(1, data.CCEmailList.length)
        //     : []

        toEmails.map((m, i) => {
          rawData.push({
            ToEmails: m,
          })
        })

        // if (toEmails.length >= ccEmails!.length) {
        //   toEmails.map((m, i) => {
        //     rawData.push({
        //       ToEmails: m,
        //       CCEmails: ccEmails[i] ? ccEmails[i] : '',
        //     })
        //   })
        // } else {
        //   ccEmails.map((m, i) => {
        //     rawData.push({
        //       ToEmails: toEmails[i] ? toEmails[i] : '',
        //       CCEmails: m,
        //     })
        //   })
        // }
      }
    })

    console.log(exportData.List)
    console.log(rawData)

    const wb = XLSX.utils.book_new()
    const ws = XLSX.utils.json_to_sheet(rawData)
    XLSX.utils.book_append_sheet(wb, ws, 'CURRENT_DATA')
    XLSX.writeFile(
      wb,
      `CURRENT_TRIBE_SQUAD_PROJECT_${toDateText(moment())}.xlsx`
    )
  }

  return (
    <>
      <SubnavBar
        breadcrumbs={breadcrumbs}
        hideSearch={true}
        adminSearchBox={<SearchInput onSearch={onSearch} />}
      />
      <EventModal
        name="project"
        event={[event]}
        enable={enableModal}
        successCalback={onSuccess}
        errorCalback={onError}
      />
      <LoadingView isShow={event.IsLoading} />
      <AlertSuccess
        isShow={event.SubmitSuccess ? true : false}
        text={event.MessageSuccess}
        callback={() => onAfterImportFile(event.SubmitSuccess)}
      />
      <AlertError
        isShow={event.SubmitError ? true : false}
        text={event.SubmitError}
        callback={() => onAfterImportFile(false, event.SubmitError)}
      />
      <Menu selected={'project'}>
        <MainPage>
          <Header>
            <Row type="flex" align="middle">
              <Col span={12}>
                <Title>Project list</Title>
              </Col>
              <Col span={12}>
                <Filter>
                  แสดงรายการที่<span>ยังไม่ระบุ</span>เท่านั้น
                </Filter>
              </Col>
            </Row>
          </Header>
          <ImportBtnWrapper>
            <Row type="flex" justify="start">
              <ImportBtn
                text={'Import Excel'}
                onChange={onImportFile}
                type={'.xlsx'}
              ></ImportBtn>
              <SecondaryButton
                text={'Export Current Data'}
                onClick={onExportFile}
              ></SecondaryButton>
            </Row>
          </ImportBtnWrapper>

          <TableHeader>
            <TableColHeader span={6} isPadding={true}>
              Project name
            </TableColHeader>
            <TableColHeader span={3}>Tribe</TableColHeader>
            <TableColHeader span={3}>Squad</TableColHeader>
            <TableColHeader span={3}>ประเภทโครงการ</TableColHeader>
            <TableColHeader span={3}>ผู้จัดโครงการ</TableColHeader>
            <TableColHeader span={3}>สถานะ</TableColHeader>
            <TableColHeader span={2}>Last sync</TableColHeader>
          </TableHeader>
          {content}
          <Pagination
            currentPage={pagination.Page}
            totalRows={projectList.Total}
            perPage={pagination.RowPerPage}
            onChangeNumber={(row: number) => {
              pagination.RowPerPage = row
              setPagination({ ...pagination })
            }}
            onClickPage={(page: number) => {
              pagination.Page = page
              setPagination({ ...pagination })
            }}
          />
        </MainPage>

        <Drawer
          title={null}
          className="drawer-project"
          closable={false}
          width={400}
          visible={displayDrawer}
        >
          <AdminProjectDetail
            item={selectedItem}
            teamList={teamList.List}
            onSave={onSave}
            onClose={onCloseDrawer}
          />
        </Drawer>
      </Menu>
    </>
  )
}

const MainPage = styled.div`
  padding: 40px 45px;
  width: 100%;
`

const Header = styled.div`
  margin-bottom: 35px;
  height: 50px;
`

const Title = styled.h2`
  font-size: 28px;
  font-family: 'DB Helvethaica X 75 Bd';
  height: 30px;
`

const Filter = styled.div`
  font-size: 18px;
  font-family: 'DB Helvethaica X 55 Regular';
  height: 22px;
  text-align: right;

  span {
    font-family: 'DB Helvethaica X 75 Bd';
    margin: 0px 8px;
    font-size: 20px;
    color: #003168;
    text-decoration: underline;
  }
`

const ImportBtnWrapper = styled.div`
  margin-bottom: 2rem;
`

const mapStateToProps = (state: RootState) => {
  return {
    projectList: selectDataList(state),
    event: selectEvent(state),
    token: serviceTokenSelector(state),
    ProjectCode: projectCodeWithSuffixSelector(state),
    UserEmail: userEmailSelector(state),
    teamList: selectTeamList(state),
    exportData: selectExportData(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(AdminProject)
