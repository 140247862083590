import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import {
  CheckListDetail,
  CriteriaTypeWithoutScore,
} from 'features/checklist/unit/types'
import ContentBox from 'Components/Shared/Card/ContentBox'
import CriteriaModal, { CriteriaHandles } from './Modal/CriteriaModal'
import { AddList, EditDeleteButton } from 'Components/Shared/Button'
import { Row } from 'antd'
import { RowItem } from 'Components/AdminQC/Components'

type Props = {
  checkListDetail: CheckListDetail
  updateCheckList: () => void
  showDeleteCriteria: (index: number) => void
  disabled: boolean
}

const CriteriaBox: React.ComponentType<Props> = ({
  checkListDetail,
  updateCheckList,
  showDeleteCriteria,
  disabled,
}) => {
  const modalRef = useRef<CriteriaHandles>(null)
  const [criterias, setcriterias] = useState<CriteriaTypeWithoutScore[]>([])

  useEffect(() => {
    setcriterias(checkListDetail.CriteriaList)
  }, [checkListDetail.CriteriaList])

  const showDeleteModal = (index: number) => {
    showDeleteCriteria(index)
  }

  const showEditeModal = (ct: CriteriaTypeWithoutScore) => {
    if (modalRef.current) {
      modalRef.current.showModal(undefined, ct)
    }
  }

  const list = criterias.map((item, index) => {
    return (
      <RowItem
        key={index}
        title={item.CriteriaName}
        right={
          !disabled && (
            <EditDeleteButton
              showEditeModal={() => showEditeModal(item)}
              showDeleteModal={() => showDeleteModal(index)}
            />
          )
        }
      />
    )
  })

  const showAddCriteriaModal = () => {
    if (modalRef.current) {
      modalRef.current.showModal()
    }
  }

  const addNewCriteria = (value: string, cri?: CriteriaTypeWithoutScore) => {
    if (cri) {
      checkListDetail.QCTypeList.forEach(qc => {
        qc.JobTypeList.forEach(job => {
          job.SubJobTypeList.forEach(subjob => {
            subjob.DetailList.forEach(detail => {
              for (let i = 0; i < detail.CriteriaList.length; i++) {
                if (detail.CriteriaList[i].CriteriaName === cri.CriteriaName) {
                  detail.CriteriaList[i].CriteriaName = value
                  break
                }
              }
            })
          })
        })
      })
      cri.CriteriaName = value
    } else {
      const newList = criterias.concat({ CriteriaID: 0, CriteriaName: value })
      setcriterias(newList)
      checkListDetail.CriteriaList = newList
    }
    updateCheckList()
  }

  const content = (
    <ListSection>
      {list}
      {!disabled && (
        <AddList style={{ margin: 0 }} onClick={showAddCriteriaModal} />
      )}
    </ListSection>
  )

  return (
    <>
      <ContentBox
        height={320}
        title="Criteria"
        hasSearch={false}
        children={content}
        isEmpty={false}
        emptyText="กรุณาเลือก “ประเภทโครงการ”"
      />
      <CriteriaModal
        ref={modalRef}
        onOk={addNewCriteria}
        checkListDetail={checkListDetail}
      />
    </>
  )
}

const ListSection = styled.div`
  margin-top: 16px;
  height: 214px;
  overflow-y: scroll;
  padding: 0 24px;
  margin: 8px 24px;
  border-radius: 5px;
  border: solid 1px #eeeff2;
  background-color: #ffffff;
`

const Item = styled.div`
  font-size: 20px;
  border-bottom: 1px solid #e8ebf0;
  align-items: center;
  display: flex;
  height: 56px;
  padding: 0px 16px;
  cursor: pointer;

  &.selected {
    border-radius: 5px;
    border: solid 1px #003168;
  }

  .row-item {
    width: 100%;
  }
`
export default CriteriaBox
