import React from 'react'
import { DatePicker } from 'antd'
import { toMoment, toDateText } from 'utils/datetime'
import { DATE_PICKER_DATE_FORMAT } from 'config'
import moment, { Moment } from 'moment'

interface Props {
  value?: Moment
  minDate?: Moment
  maxDate?: Moment
  label?: string
  onSelectedDate?: (value: any) => void
  disabled?: boolean
  datePickerPlaceholder?: string
}

const Calendar = (props: Props) => {
  const {
    disabled = false,
    onSelectedDate,
    value,
    label = 'วันที่',
    minDate,
    maxDate,
    datePickerPlaceholder = 'DD/MM/YYYY',
  } = props

  const disabledFutureDate = (current: Moment | null | undefined): boolean => {
    if (current && maxDate) return current >= maxDate

    return false
  }

  const disabledPastDate = (current: Moment | null | undefined): boolean => {
    if (current && minDate) return current < minDate

    return false
  }

  const disabledDate = minDate
    ? disabledPastDate
    : maxDate
    ? disabledFutureDate
    : undefined

  return (
    <div
      className={`input-with-icon ${
        disabled ? 'input-with-icon-disabled' : ''
      }`}
    >
      <div className="label">{label}</div>
      <DatePicker
        className="date-picker"
        disabled={disabled}
        onChange={onSelectedDate}
        disabledDate={disabledDate}
        format={DATE_PICKER_DATE_FORMAT}
        placeholder={datePickerPlaceholder}
        value={value ? toMoment(value) : undefined}
      />
    </div>
  )
}

export default Calendar
