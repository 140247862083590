import React from 'react'
import { Col, Row } from 'antd'
import styled from 'styled-components'

import { DescItem, ImageReport } from 'Components/AdminQC/Report/Components'
import DefectScore from 'Components/Defect/DefectScore'
import { DefectItem as Defect } from 'features/report/constructRoad/types'
import DefectStatus from 'Components/Defect/DefectStatus'
import DefectRequired from 'Components/Defect/DefectRequired'
import { NcrPrefix } from 'Components/Shared'

type Props = {
  index: number
  defect: Defect
}

const DefectItem: React.ComponentType<Props> = props => {
  const { index, defect } = props

  const renderDescItem = (title: string, value: any) => {
    return <DescItem title={title} value={value || '-'} percentW={33} />
  }

  const defectInfo = (
    <Row type="flex" align="middle">
      <Col span={20}>
        <Row type="flex">
          {renderDescItem(
            `หมุดที่ ${defect.PinNumber}`,
            <DefectStatus pinStatus={defect.PinStatus} />
          )}
          {renderDescItem('ประเภทงาน', defect.WorkTypeName)}
          {renderDescItem('ลักษณะ Defect', defect.DefectTypeName)}
          {renderDescItem(
            'หมวดงานหลัก / หมวดงานรอง',
            `${defect.JobTypeName}/${defect.SubJobTypeName}`
          )}
          {renderDescItem('รายละเอียด', defect.DetailName)}
          {renderDescItem('หมายเหตุ', defect.Remark)}
        </Row>
      </Col>
      <Col span={4}>
        <Col>
          <Score>
            <DefectScore
              pinStatus={defect.PinStatus}
              pinScore={defect.Score}
              suffix={<Desc>คะแนน</Desc>}
              prefix={<NcrPrefix defect={defect} />}
            />
            <DefectRequired IsRequired={defect.IsRequired} />
          </Score>
        </Col>
      </Col>
    </Row>
  )

  const imageInfo = (
    <Row>
      <Col span={12}>
        <BeforeImage>
          <Title>Before</Title>
          <ImageReport
            image={defect.ImageBefore}
            showRemark={false}
            hasMargin={false}
          />
        </BeforeImage>
      </Col>
      <Col span={12}>
        <AfterImage>
          <Title>After</Title>
          <ImageReport
            image={defect.ImageAfter}
            showRemark={false}
            hasMargin={false}
          />
        </AfterImage>
      </Col>
    </Row>
  )

  const pageBreakBefore =
    index === 0 ? 'avoid' : index % 2 === 1 ? 'always' : 'avoid'
  return (
    <Box style={{ pageBreakBefore }}>
      {defectInfo}
      <Line />
      {imageInfo}
    </Box>
  )
}

const Box = styled.div`
  margin-top: 16px;
  border-radius: 8px;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.03);
  background-color: #fff;
  padding: 24px;

  @media print {
    border: 1px solid rgba(0, 49, 104, 0.05);
    border-radius: 5px;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.03);
  }
`

const Line = styled.div`
  width: 100%;
  background-color: rgba(0, 49, 104, 0.05);
  height: 1px;
  margin-top: 16px;
`

const BeforeImage = styled.div`
  margin: 0 6px;
  margin-top: 12px;
`

const AfterImage = styled.div`
  margin-left: 12px;
  margin-top: 12px;
`

const Title = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 20px;
  color: #000;
  margin-bottom: 12px;
`

const Desc = styled.span`
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 28px;
  margin-left: 8px;
`

const Score = styled.span`
  font-size: 28px;
`

export default DefectItem
