import React from 'react'
import { Col } from 'antd'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { CheckListItem } from 'features/checklist/master/types'
import { TableRow } from 'Components/Shared/Table'
import { EditIcon, TrashIcon } from 'Components/Shared/Icon'

type DefaultProps = {
  item: CheckListItem
  onDelete: (id: string) => void
}

type Props = DefaultProps

const RowItem: React.FC<Props> = props => {
  const { item, onDelete } = props
  const onDeleteItem = () => onDelete(item.CheckListID)

  const link = `/master-checklist/${item.CheckListID}/edit`
  return (
    <TableRow minHeight={60} spaceBetweenRow={8} paddingLeft={27}>
      <Col span={2}>
        <Link to={link}>{item.CheckListID}</Link>
      </Col>
      <Col span={7}>
        <Link to={link}>
          <Name>{item.CheckListName}</Name>
        </Link>
      </Col>
      <Col span={1}></Col>
      <Col span={2}>{item.CheckItemCounter}</Col>
      <Col span={3}>{item.IsRequireQC ? 'Yes' : 'No'}</Col>
      <Col span={3}>{item.TemplateCode}</Col>
      <Col span={4}>{item.ProcurementTaskID}</Col>
      <Col span={2}>
        <Icon>
          <Link to={link}>
            <EditIcon size={20} color="#8b99a7" />
          </Link>
        </Icon>
        <Icon onClick={onDeleteItem}>
          <TrashIcon size={20} color="#8b99a7" />
        </Icon>
      </Col>
    </TableRow>
  )
}

const Name = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  color: #000;
`
const Icon = styled.div`
  float: left;
  margin-right: 20px;
  margin-top: 5px;
  cursor: pointer;
`

export default RowItem
