import withAPIAction from 'utils/withAPIAction'
import {
  getReportAction,
  getCheckListAction,
  createDefectAction,
  updateDefectAction,
  setDefectAction,
  verifyDefectAction,
  resendReportAction,
  sendEditReportAction,
  getSelectPinAction,
  updateSelectedPintAction,
  getSelectLocationAction,
  updateSelectedLocationAction,
} from './actions'
import {
  GetReportReq,
  GetCheckListReq,
  DefectTypeReq,
  SetDefectReq,
  VerifyDefectReq,
  ResendReportReq,
  SendEditReportToQCReq,
  GetSelectPinReq,
  UpdateSelectPinReq,
  GetSelectPinResp,
  UpdateSelectLocationReq,
  GetSelectLocationReq,
} from './types'
import { verifyReq } from 'utils/verify'

export const getReport = (token: string, data: GetReportReq) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<GetReportReq>({
    method: 'POST',
    path: 'GetAuditRoadReportById',
    data,
    token: `Bearer ${token}`,
  })(getReportAction)
}

export const getCheckList = (token: string, data: GetCheckListReq) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<GetCheckListReq>({
    method: 'POST',
    path: 'GetAuditRoadCheckListForQC',
    data,
    token: `Bearer ${token}`,
  })(getCheckListAction)
}

export const createDefect = (token: string, data: DefectTypeReq) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<DefectTypeReq>({
    method: 'POST',
    path: 'CreateAuditRoadDefect	',
    data,
    token: `Bearer ${token}`,
  })(createDefectAction)
}

export const updateDefect = (token: string, data: DefectTypeReq) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<DefectTypeReq>({
    method: 'POST',
    path: 'UpdateAuditRoadDefect',
    data,
    token: `Bearer ${token}`,
  })(updateDefectAction)
}

export const setDefect = (token: string, data: SetDefectReq) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<SetDefectReq>({
    method: 'POST',
    path: 'SetAuditRoadDefect',
    data,
    token: `Bearer ${token}`,
  })(setDefectAction)
}

export const verifyDefect = (token: string, data: VerifyDefectReq) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<VerifyDefectReq>({
    method: 'POST',
    path: 'VerifyAuditRoadDefect',
    data,
    token: `Bearer ${token}`,
  })(verifyDefectAction)
}

export const resendReport = (token: string, data: ResendReportReq) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<ResendReportReq>({
    method: 'POST',
    path: 'ResendAuditRoadReportByQC',
    data,
    token: `Bearer ${token}`,
  })(resendReportAction)
}

export const sendEditReportToQC = (
  token: string,
  data: SendEditReportToQCReq
) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<SendEditReportToQCReq>({
    method: 'POST',
    path: 'SendAuditRoadForQCEdit',
    data,
    token: `Bearer ${token}`,
  })(sendEditReportAction)
}

export const getSelectPinReport = (token: string, data: GetSelectPinReq) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<GetSelectPinReq>({
    method: 'POST',
    path: 'GetSelectPinForAuditRoad',
    data,
    token: `Bearer ${token}`,
  })(getSelectPinAction)
}

export const updateSelectPinReport = (
  token: string,
  data: UpdateSelectPinReq
) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<UpdateSelectPinReq>({
    method: 'POST',
    path: 'SelectPinForAuditRoad',
    data,
    token: `Bearer ${token}`,
  })(updateSelectedPintAction)
}

export const getSelectLocation = (
  token: string,
  data: GetSelectLocationReq
) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<GetSelectLocationReq>({
    method: 'POST',
    path: 'GetSelectLocationForAuditRoad',
    data,
    token: `Bearer ${token}`,
  })(getSelectLocationAction)
}

export const updateSelectLocation = (
  token: string,
  data: UpdateSelectLocationReq
) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<UpdateSelectLocationReq>({
    method: 'POST',
    path: 'SelectLocationForAuditRoad',
    data,
    token: `Bearer ${token}`,
  })(updateSelectedLocationAction)
}
