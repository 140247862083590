import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { RootState } from 'typings/RootState'
import styled from 'styled-components'

import { getProjectType } from 'features/filter/api'
import { GetProjectTypeReq } from 'features/filter/types'
import { selectProjectType } from 'features/filter/selector'
import { Col, Row } from 'antd'
import {
  projectsByIdSelector,
  selectedProjectCodeSelector,
} from 'features/project/project.selector'
import {
  serviceTokenSelector,
  userEmailSelector,
} from 'features/sensitive/sensitive.selector'
import { fetchProjects } from 'features/project/project.api'
import { selectFilter } from 'features/adminReport/auditRoad/selector'
import { Filter } from 'features/adminReport/auditRoad/types'
import {
  updateFilter,
  clearFilter,
} from 'features/adminReport/auditRoad/actions'
import {
  ProjectTypeFilter,
  CheckNumberFilter,
  ProjectsFilter,
  DateFilter,
  ButtonFilter,
} from './Components'
import { DropdownPopupMenu } from 'Components/Shared/Form'
import MulticheckProject from 'Components/Shared/Filters/MulticheckProject'

type Props = {
  onSearch?: (isClearFilter?: boolean) => void
  withPadding?: boolean
  onExport?: () => void
  exportData?: any[]
  isDownLoading?: boolean
} & ReduxProps &
  DispatchProps

const AuditRoadFilter: React.FunctionComponent<Props> = props => {
  const {
    ProjectCode,
    UserEmail,
    token,
    filter,
    projectsById,
    onSearch = () => {},
    withPadding = false,
    projectType,
    onExport,
    exportData,
    isDownLoading,
  } = props

  useEffect(() => {
    dispatch(clearFilter())
    onGetProjectType()
    dispatch(fetchProjects({ email: UserEmail, token }))
    return () => {
      dispatch(clearFilter())
    }
  }, [ProjectCode, UserEmail])

  const onGetProjectType = () => {
    const req: GetProjectTypeReq = {
      UserEmail,
      Page: 1,
      RowPerPage: 100,
    }
    dispatch(getProjectType(token, req))
  }

  const dispatch = useDispatch()
  const onFilterChange = (filter: Filter) => {
    dispatch(updateFilter(filter))
  }

  const onClearSearch = () => {
    dispatch(clearFilter())
    onSearch(true)
  }

  const isFilter = () => {
    return (
      filter.CheckNumber > 0 ||
      filter.Date.Mode !== '' ||
      filter.ProjectCode.length > 0 ||
      filter.ProjectTypeID !== 0
    )
  }

  return (
    <MainPage className={withPadding ? 'with-padding' : ''}>
      <Row type="flex" justify="start">
        <Col span={8}>
          <ProjectTypeFilter
            filter={filter}
            onFilterChange={onFilterChange}
            projectType={projectType}
          />
        </Col>
        <Col span={8}>
          <DropdownPopupMenu
            label={'โครงการ'}
            placeholder="เลือกโครงการ"
            value={filter.ProjectCode.join(',')}
            disabled={false}
            content={
              <MulticheckProject
                projectList={projectsById}
                projectTypeID={filter.ProjectTypeID}
                filter={filter.ProjectCode}
                onChange={data => {
                  filter.ProjectCode = data
                  onFilterChange(filter)
                }}
              />
            }
            isActive={filter.ProjectCode.length > 0}
          />
        </Col>

        <Col span={8}>
          <CheckNumberFilter filter={filter} onFilterChange={onFilterChange} />
        </Col>
        <Col span={8}>
          <DateFilter filter={filter} onFilterChange={onFilterChange} />
        </Col>
        <Col span={8}>
          <ButtonFilter
            onClearSearch={onClearSearch}
            onSearch={onSearch}
            isFilter={isFilter()}
            onExport={onExport}
            isDownLoading={isDownLoading}
            exportData={exportData}
            fileName="รายงานถนน - ตรวจรับมอบถนนและก่อนหมดประกัน"
          />
        </Col>
      </Row>
    </MainPage>
  )
}

const MainPage = styled.div`
  margin-bottom: 24px;
  &.with-padding {
    padding: 0 120px;
  }

  background-color: #f5f6fa;
`

const mapStateToProps = (state: RootState) => {
  return {
    ProjectCode: selectedProjectCodeSelector(state),
    UserEmail: userEmailSelector(state),
    token: serviceTokenSelector(state),
    filter: selectFilter(state),
    projectType: selectProjectType(state),
    projectsById: projectsByIdSelector(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(AuditRoadFilter)
