import RootState from 'typings/RootState'
import { createSelector } from 'reselect'
import {
  DataList,
  SansiriItem,
  UserVandorItem,
  CompanyVandorItem,
  User,
  Filter,
} from './types'
import { Event } from '../../types/event'

export const selectDataList = (state: RootState): DataList =>
  state.adminUser.dataList

export const selectSansiriList = (state: RootState): SansiriItem[] =>
  state.adminUser.sansiriList

export const selectVandorList = (state: RootState): UserVandorItem[] =>
  state.adminUser.vandorList

export const selectCompanyVandorList = (
  state: RootState
): CompanyVandorItem[] => state.adminUser.companyList

export const selectEvent = (state: RootState): Event => state.adminUser.event

export const selectUserList = (state: RootState): User[] =>
  state.adminUser.userList

export const selectVendorEvent = (state: RootState): Event =>
  state.adminUser.vandorEvent

export const selectSansiriEvent = (state: RootState): Event =>
  state.adminUser.sansiriEvent

export const selectFilter = (state: RootState): Filter => state.adminUser.filter
