import React from 'react'
import styled from 'styled-components'
import { Bar } from 'Components/Shared/Chart'
import { Col, Row } from 'antd'
import { DataList, Project } from 'features/adminReport/monthly/types'

type Props = {
  dataList: DataList
}

const BarChart: React.ComponentType<Props> = props => {
  const { dataList } = props

  // config graph
  const options = {
    legend: {
      labels: {},
    },
    scales: {
      yAxes: [
        {
          stacked: true,
          ticks: {
            beginAtZero: true,
            suggestedMax: 10,
            suggestedMin: 0,
            stepSize: 1,
            precision: 0,
          },
        },
      ],
      xAxes: [
        {
          stacked: true,
        },
      ],
    },
  }

  const getProjectsName = (projectList: Project[]) => {
    return projectList.map(project => {
      return `${project.ProjectCode}-${project.ProjectNameTH}`
    })
  }

  const getNumOfPass = (projectList: Project[]) => {
    return projectList.map(project => {
      return project.NumOfPass
    })
  }

  const getNumOfPassToFix = (projectList: Project[]) => {
    return projectList.map(project => {
      return project.NumOfPassToFix
    })
  }

  const getNumOfPassByFunc = (projectList: Project[]) => {
    return projectList.map(project => {
      return project.NumOfPassByFunc
    })
  }

  const getNumOfNotPass = (projectList: Project[]) => {
    return projectList.map(project => {
      return project.NumOfNotPass
    })
  }

  const getNumOfNotPassByReqDefect = (projectList: Project[]) => {
    return projectList.map(project => {
      return project.NumOfNotPassByReqDefect
    })
  }

  const getNumOfNotPassByFunc = (projectList: Project[]) => {
    return projectList.map(project => {
      return project.NumOfNotPassByFunc
    })
  }

  const getNumOfNotInspction = (projectList: Project[]) => {
    return projectList.map(project => {
      return project.NumOfNotInspction
    })
  }

  const renderData = (projectList: Project[]) => {
    return {
      labels: getProjectsName(projectList),
      datasets: [
        {
          label: 'ผ่าน',
          data: getNumOfPass(projectList),
          backgroundColor: '#5bc79e',
        },
        {
          label: 'ผ่านแก้ไข',
          data: getNumOfPassToFix(projectList),
          backgroundColor: '#89c75b',
        },
        {
          label: 'ผ่าน Function',
          data: getNumOfPassByFunc(projectList),
          backgroundColor: '#aae284',
        },
        {
          label: 'ไม่ผ่าน',
          data: getNumOfNotPass(projectList),
          backgroundColor: '#d0494c',
        },
        {
          label: 'ไม่ผ่านเงื่อนไข',
          data: getNumOfNotPassByReqDefect(projectList),
          backgroundColor: '#efc122',
        },
        {
          label: 'ไม่ผ่าน Function',
          data: getNumOfNotPassByFunc(projectList),
          backgroundColor: '#b33030',
        },
        {
          label: 'ไม่ประเมินคะแนน',
          data: getNumOfNotInspction(projectList),
          backgroundColor: '#79cadc',
        },
      ],
    }
  }

  const renderBarGraph = () => {
    return dataList.List.map((item, index) => {
      return (
        <Col span={24} key={index}>
          <Graph>
            <Title>{`${item.TeamName},${item.SquadName},${item.QCTypeName}`}</Title>
            <Bar data={renderData(item.ProjectList)} options={options} />
          </Graph>
        </Col>
      )
    })
  }

  return (
    <Content>
      <Row type="flex" justify="space-between">
        <Col>
          <Title>Sum Unit by Squad</Title>
        </Col>
      </Row>
      <Row type="flex" justify="space-between">
        {renderBarGraph()}
      </Row>
    </Content>
  )
}

const Title = styled.span`
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 24px;
  color: #000;
`

const Graph = styled.div`
  font-family: 'DB Helvethaica X 55 Regular';
  margin-top: 32px;
  background-color: #fff;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.03);
  padding: 15px;
  border-radius: 9px;
  margin: 18px;
`

const Content = styled.div``

export default BarChart
