import React, { useState, useEffect } from 'react'
import { useDispatch, connect } from 'react-redux'
import RootState from 'typings/RootState'
import styled from 'styled-components'
import { RouteComponentProps } from 'react-router-dom'
import { BreadcrumbLink } from 'Components/Shared/BreadCrumb/BreadCrumb'
import SubnavBar from 'Components/Shared/SubnavBar'
import { HeaderRow, LineHeader } from 'Components/Shared/Table'
import Pagination from 'Components/Shared/Pagination'

import {
  serviceTokenSelector,
  userEmailSelector,
} from 'features/sensitive/sensitive.selector'

import { GetZoneList } from 'features/admin/zone/api'
import { Zone } from 'features/admin/zone/types'
import Menu from '../../../MenuAdmin'
import RowItem from './RowItem'
import { PaginationType } from 'features/types/common'
import { deleteReq, updateCheckList } from 'features/admin/zone/actions'
import { AlertError, LoadingView } from 'Components/Shared'
import { toCheckList } from 'importer/zone'
import { SearchInput } from 'Components/Filter'

const breadcrumbs: BreadcrumbLink[] = [
  {
    url: '/dashboard',
    title: 'Home',
    icon: '/home.png',
    active: true,
    showTitle: false,
  },
  {
    url: '/check-zone',
    title: 'ตรวจส่วนกลาง',
    active: false,
    showTitle: true,
  },
]

type Props = ReduxProps & DispatchProps & RouteComponentProps

const CheckZone: React.ComponentType<Props> = ({
  ZoneList,
  event,
  history,
}) => {
  const [enableModal, setEnableModal] = useState(false)
  const [err, setErr] = useState(undefined)
  const [searchText, setSearchText] = useState('')
  const [pagination, setPagination] = useState<PaginationType>({
    Page: 1,
    RowPerPage: 10,
  })
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(
      GetZoneList(
        {
          Page: pagination.Page,
          RowPerPage: pagination.RowPerPage,
        },
        searchText
      )
    )
  }, [pagination])

  const onDelete = (item: Zone) => {
    setEnableModal(true)
    dispatch(
      deleteReq.request({
        CheckListID: item.CheckListID,
        page: {
          Page: pagination.Page,
          RowPerPage: pagination.RowPerPage,
        },
      })
    )
  }

  const onImport = (data: any) => {
    try {
      const checklist = toCheckList(data)
      dispatch(updateCheckList(checklist))
      history.push('/check-zone/create')
    } catch (e) {
      console.log('import err:', e.message)
      setErr(e.message)
    }
  }

  const content = ZoneList.List.map((item: Zone, index: number) => {
    return <RowItem key={index} item={item} onDelete={onDelete} />
  })

  const onSearch = (val: string) => {
    if (searchText === '' && val === '') return
    setSearchText(val)
    pagination.Page = 1
    setPagination({ ...pagination })
  }

  return (
    <>
      <AlertError
        text={event.SubmitError}
        isShow={!!event.SubmitError && enableModal}
      />
      <AlertError
        isShow={err !== undefined}
        text={err}
        callback={() => setErr(undefined)}
      />
      <LoadingView isShow={event.IsLoading} />
      <SubnavBar
        breadcrumbs={breadcrumbs}
        hideSearch={true}
        adminSearchBox={<SearchInput onSearch={onSearch} />}
      />
      <Menu selected={'check-zone'}>
        <MainPage>
          <LineHeader
            title="ตรวจส่วนกลาง"
            textImport="นำเข้า Checklist"
            textCreate="สร้าง Checklist ใหม่"
            onCreate={() => history.push('/check-zone/create')}
            onImport={onImport}
          />

          <HeaderRow onSort={() => null} sorting={[]} items={columnsName} />
          {content}
          <Pagination
            currentPage={pagination.Page}
            totalRows={ZoneList.Total}
            perPage={pagination.RowPerPage}
            onChangeNumber={(page: number) => {
              pagination.RowPerPage = page
              setPagination({ ...pagination })
            }}
            onClickPage={(page: number) => {
              pagination.Page = page
              setPagination({ ...pagination })
            }}
          />
        </MainPage>
      </Menu>
    </>
  )
}

const MainPage = styled.div`
  padding: 40px 45px;
  width: 100%;
`

const columnsName = [
  {
    title: 'Checklist name',
    span: 7,
    key: 'FloorPlanName',
    showSort: false,
  },
  {
    title: 'ประเภทโครงการ',
    span: 3,
    key: 'UsedCounter',
    showSort: false,
  },
  {
    title: 'จำนวนโครงการที่ใช้',
    span: 3,
    key: 'Status',
    showSort: false,
  },
  {
    title: 'วันที่สร้าง',
    span: 3,
    key: 'Status',
    showSort: false,
  },
  {
    title: 'แก้ไขล่าสุด',
    span: 6,
    key: 'Status',
    showSort: false,
  },
  {
    title: 'Action',
    span: 2,
    key: 'Action',
    showSort: false,
  },
]

const mapStateToProps = (state: RootState) => {
  return {
    ZoneList: state.adminZone.ZoneList,
    event: state.adminZone.Event,
    token: serviceTokenSelector(state),
    UserEmail: userEmailSelector(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>
const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(CheckZone)
