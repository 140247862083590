import React, { useState } from 'react'
import styled from 'styled-components'

import { RadioBox, InputNumber, InputText } from 'Components/Shared/Input'

import {
  CheckListDetail,
  JobType,
} from 'features/checklist/constructRoad/types'

import { SelectType } from '..'
import { BottomButton } from 'Components/Shared/Button'
import QcTypeDropdown from './QcTypeDropdown'

interface Props {
  selected: SelectType
  checkListDetail: CheckListDetail
  editingObj?: JobType
  onSave: (job: JobType) => void
  closeModal: () => void
  disabled: boolean
}

const JobModal: React.FC<Props> = ({
  checkListDetail,
  selected,
  editingObj,
  onSave: onSaveProp,
  closeModal,
  disabled,
}) => {
  const [error, seterror] = useState('')
  const [name, setname] = useState(editingObj ? editingObj.JobTypeName : '')
  const [point, setpoint] = useState<number | undefined>(
    editingObj && editingObj.Score
  )

  const [workList, setWorkList] = useState<any[]>(
    editingObj ? editingObj.WorkTypeList : []
  )

  const qctype = checkListDetail.QCTypeList[selected.qctype]

  const changeName = (val: string) => {
    setname(val)
  }
  const changePoint = (val: number) => {
    if (val <= 0) return
    setpoint(val)
  }

  const onSelectWorkType = (it: any, index: number) => {
    if (index !== -1) {
      workList.splice(index, 1)
    } else {
      workList.push({
        WorkTypeID: it.WorkTypeID,
        Index: it.IndexID,
      })
    }

    setWorkList([...workList])
  }

  const onSave = () => {
    if (name !== '' && point != null && point >= 0) {
      const found = editingObj
        ? null
        : qctype.JobTypeList.find(v => v.JobTypeName === name)
      if (found == null) {
        onSaveProp({
          JobTypeID: editingObj ? editingObj.JobTypeID : 0,
          JobTypeName: name,
          Score: point,
          SubJobTypeList: editingObj ? editingObj.SubJobTypeList : [],
          WorkTypeList: workList,
        })
      } else {
        seterror('ลักษณะงานหลักนี้ ได้มีอยู่แล้ว')
      }
    } else {
      seterror('กรุณาใส่ข้อมูลทั้งหมด')
    }
  }

  const workTypeList = checkListDetail.WorkTypeList.map((it, index) => {
    const indexId = workList.findIndex(work => {
      return work.WorkTypeID !== 0
        ? work.WorkTypeID === it.WorkTypeID
        : work.Index === it.IndexID
    })

    return (
      <RadioBox
        key={index}
        label={it.WorkTypeName}
        value={it.WorkTypeName}
        isSelected={indexId !== -1}
        onClick={() => onSelectWorkType(it, indexId)}
        readOnly={disabled}
      />
    )
  })

  return (
    <div>
      <Box>
        <InputText
          prefix="ชื่อ"
          value={name}
          onChange={changeName}
          placeholder="กรอกชื่อ"
          openWithFocus
          disabled={disabled}
        />
      </Box>

      <Box>
        <InputNumber
          prefix="คะแนนเต็มกลุ่ม"
          value={point}
          placeholder="กรอกคะแนน"
          onChange={changePoint}
          disabled={disabled}
        />
      </Box>
      <Title>ประเภทงาน</Title>
      <WorkArea>{workTypeList}</WorkArea>

      <QcTypeDropdown selected={selected} checkListDetail={checkListDetail} />

      {!disabled && (
        <BottomButton closeModal={closeModal} onSave={onSave} error={error} />
      )}
    </div>
  )
}

const Box = styled.div`
  padding-top: 12px;
`

const Title = styled.div`
  padding-top: 17px;
  font-size: 20px;
  color: #000000;
  font-family: 'DB Helvethaica X 75 Bd';
`

const WorkArea = styled.div`
  width: 100%;
  height: 200px;
  border-radius: 5px;
  border: solid 1px #e4e7eb;
  background-color: #ffffff;
  padding: 13px 24px;
  overflow: scroll;
`

export default JobModal
