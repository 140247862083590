import React from 'react'
import styled from 'styled-components'
import { Row, Col, Layout } from 'antd'
import { PrimaryButton } from 'Components/Shared/Button'
import { FunctionStatus } from 'features/types/status'
import { CheckedIcon, FailIcon, UncheckedIcon } from 'Components/Shared/Icon'

const { Footer } = Layout

interface Props {
  status: string
  onUpdate: (status: FunctionStatus) => void
  onSave: () => void
  disabled?: boolean
  readonly?: boolean
}

const PageFooter: React.FC<Props> = props => {
  const { onUpdate, onSave, status, disabled = false, readonly = false } = props
  const statusValue = [
    {
      name: 'ผ่าน',
      value: FunctionStatus.Pass,
    },
    {
      name: 'ไม่ผ่าน',
      value: FunctionStatus.NotPass,
    },
  ]

  const getIconCheck = (value: FunctionStatus, status: string) => {
    if (value !== status) return <UncheckedIcon size={24} />

    switch (value) {
      case FunctionStatus.Pass:
        return <CheckedIcon size={24} />
      case FunctionStatus.NotPass:
        return <FailIcon size={24} />
      default:
        return <UncheckedIcon size={24} />
    }
  }

  const renderSelction = (name: string, value: string) => {
    return (
      <Row type="flex" justify="space-between" align="middle">
        <Col>
          <Name>{name}</Name>
        </Col>
        <Col span={18}>
          <Row type="flex" justify="start" align="middle">
            {statusValue.map((item, index) => {
              return (
                <Col onClick={() => onUpdate(item.value)} key={index}>
                  {getIconCheck(item.value, value)}
                  <TextBD>{item.name}</TextBD>
                </Col>
              )
            })}
          </Row>
        </Col>
      </Row>
    )
  }

  return (
    <Footer className="detail-page-footer">
      <FooterContent>
        <Row type="flex" justify="space-between" align="middle">
          <Col span={8}>
            <Description>{renderSelction('สถานะ', status)}</Description>
          </Col>
          <Col>
            {!readonly && (
              <Button>
                <PrimaryButton
                  text="บันทึก"
                  onClick={onSave}
                  disabled={disabled}
                />
              </Button>
            )}
          </Col>
        </Row>
      </FooterContent>
    </Footer>
  )
}

const FooterContent = styled.div`
  max-width: 1110px;
  margin: 0 auto;
  color: #000;
  font-size: 20px;
  padding-right: 20px;
`

const Button = styled.div`
  width: 200px;
  float: left;
`

const Description = styled.div`
  font-family: 'DB Helvethaica X 55 Regular';
  font-size: 20px;
  color: #000;
`

const Name = styled.span`
  padding-right: 8px;
`

const TextBD = styled.span`
  font-family: 'DB Helvethaica X 75 Bd';
  size : font-size: 24px;
  margin : 1px 32px 0 8px;
`

export default PageFooter
