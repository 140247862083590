import produce from 'immer'
import { getType, ActionType } from 'typesafe-actions'
import * as actions from './actions'
import { CheckListState, CheckListDetail } from './types'
import { cloneDeep } from 'lodash'
import parseErrorMessage from 'utils/parseErrorMessage'

type CheckListAction = ActionType<typeof actions>

const eventer = {
  IsLoading: false,
  SubmitError: '',
  SubmitSuccess: false,
  SubmitType: '',
  MessageSuccess: '',
}

export const original: CheckListDetail = {
  CheckListID: 0,
  CheckListName: '',
  ProjectTypeID: 0,
  ProjectTypeName: '',
  Status: 'Ready',

  JobTypeList: [],
  ProjectList: [],
}

const initialState: CheckListState = {
  CheckList: {
    Total: 0,
    List: [],
  },
  CheckListDetail: cloneDeep(original),
  ProjectList: [],
  Event: cloneDeep(eventer),
  ProjectEvent: cloneDeep(eventer),
}

export default function(
  state: CheckListState = initialState,
  action: CheckListAction
): CheckListState {
  return produce(state, draft => {
    switch (action.type) {
      case getType(actions.getCheckListAction.request):
        draft.Event = cloneDeep(eventer)
        draft.Event.IsLoading = true
        draft.CheckList = {
          Total: 0,
          List: [],
        }
        break

      case getType(actions.getCheckListAction.success):
        draft.CheckList = action.payload.ResponseData
        draft.Event.IsLoading = false
        break

      case getType(actions.getCheckListByIdAction.request):
        draft.Event = cloneDeep(eventer)
        draft.Event.IsLoading = true
        draft.CheckListDetail = cloneDeep(original)
        break

      case getType(actions.getCheckListByIdAction.success):
        draft.CheckListDetail = action.payload.ResponseData
        draft.Event.IsLoading = false
        break

      case getType(actions.createCheckListAction.request):
      case getType(actions.updateCheckListAction.request):
      case getType(actions.deleteCheckListAction.request):
        draft.Event = cloneDeep(eventer)
        draft.Event.IsLoading = true
        break

      case getType(actions.createCheckListAction.success):
      case getType(actions.updateCheckListAction.success):
      case getType(actions.deleteCheckListAction.success):
        draft.Event.IsLoading = false
        draft.Event.SubmitError = undefined
        draft.Event.SubmitSuccess = true
        draft.Event.MessageSuccess = 'ทำรายการสำเร็จ'
        break

      case getType(actions.createCheckListAction.failure):
      case getType(actions.updateCheckListAction.failure):
      case getType(actions.getCheckListAction.failure):
      case getType(actions.getCheckListByIdAction.failure):
      case getType(actions.deleteCheckListAction.failure):
        draft.Event.IsLoading = false
        draft.Event.SubmitError = parseErrorMessage(action.payload)
        break

      case getType(actions.clearCheckListDetail):
        draft.CheckListDetail = cloneDeep(original)
        break

      case getType(actions.updateCheckListDetail):
        draft.CheckListDetail = action.payload
        break

      case getType(actions.getProjectAction.request):
        draft.ProjectEvent = cloneDeep(eventer)
        draft.ProjectEvent.IsLoading = true
        draft.ProjectList = []
        break
      case getType(actions.getProjectAction.success):
        draft.ProjectList = action.payload.ResponseData
        draft.ProjectEvent.IsLoading = false
        break

      case getType(actions.getProjectAction.failure):
        draft.ProjectEvent.IsLoading = false
        draft.ProjectEvent.SubmitError = parseErrorMessage(action.payload)
        break
      default:
        break
    }
  })
}
