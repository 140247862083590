import { createStandardAction, createAsyncAction } from 'typesafe-actions'
import {
  GetFunctionChecklistReq,
  GetFunctionChecklisApiRes,
  FunctionChecklist,
  SaveFunctionChecklistReq,
  PostApiResponse,
} from './types'

export const GetFunctionChecklisAction = createAsyncAction(
  'report/function/FETCH_FUNCTION_REQUEST',
  'report/function/FETCH_FUNCTION_SUCCESS',
  'report/function/FETCH_FUNCTION_FAILURE'
)<GetFunctionChecklistReq, GetFunctionChecklisApiRes, any>()

export type UpdateFieldPayload = {
  value: FunctionChecklist
}

export const UpdateData = createStandardAction('report/function/UPDATE_FIELD')<
  UpdateFieldPayload
>()

export const SaveFunctionAction = createAsyncAction(
  'report/function/SAVE_FUNCTION_REQUEST',
  'report/function/SAVE_FUNCTION_RESPONSE',
  'report/function/SAVE_FUNCTION_FAILURE'
)<SaveFunctionChecklistReq, PostApiResponse, any>()

export const ResetEvent = createStandardAction(
  'report/function/RESET_EVENT_ACTION'
)<void>()
