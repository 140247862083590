export enum ProjectTypeName {
  HOME = 'บ้าน',
  CONDO = 'คอนโด',
  TOWNHOUSE = 'ทาวน์เฮ้าส์',
}

export enum ProjectTypeID {
  HOME = 1,
  CONDO = 2,
  TOWNHOUSE = 5,
}

export enum ProjectType {
  HORIZONTAL = 'แนวราบ',
  VERTICAL = 'แนวสูง',
}

export enum Type {
  VERTICAL = 'PH',
  HORIZONTAL = 'PL',
}
