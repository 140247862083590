import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import {
  CommissionInProActivity,
  CommissionList,
  CommissProjectList,
} from 'features/qc/dashboard/types'
import { Row, Col } from 'antd'
import ProjectBox from '../ProjectBox'
import LeftBoxContent from '../LeftBoxContent'
import CheckIconBox from '../CheckIconBox'
import SignDescription from '../SignDescription'

interface Props {
  type?: string
  task: CommissionInProActivity
  isSendReport?: boolean
  onClickReturn: (data: CommissionList) => void
  onSelectLists: (projectCode: string, data: CommissionList) => void
  projectList: CommissProjectList[]
}

const InprogressCard: React.FC<Props> = props => {
  const {
    task,
    isSendReport = false,
    onClickReturn,
    projectList,
    onSelectLists,
  } = props
  const { ProjectCode, ProjectNameTH, List } = task

  const renderTagStatus = (status: string) => {
    let tagStatus
    if (status === 'Pass') {
      tagStatus = <StatusPass>ผ่าน</StatusPass>
    }

    if (status === 'Fail') {
      tagStatus = <StatusReject>ไม่ผ่าน</StatusReject>
    }
    return tagStatus
  }

  const isSelected = (data: CommissionList) => {
    let isSelect = false
    projectList.forEach(project => {
      if (project && project.ProjectCode === ProjectCode) {
        project.List.forEach(task => {
          if (
            task.RequestID === data.RequestID &&
            task.CheckNumber === data.CheckNumber &&
            task.CheckListID === data.CheckListID &&
            task.ActivityID === data.ActivityID &&
            task.CommissioningType === data.CommissioningType
          ) {
            isSelect = true
          }
        })
      }
    })
    return isSelect
  }

  const renderInspectionCheck = (data: CommissionList) => {
    const isHasSign = data.HasSign
    const isHasImage = data.HasImage
    const showRadio = isHasSign && isHasImage
    return (
      <Row type="flex" className="score" align="middle">
        <Col span={2}>
          {isSendReport &&
            (showRadio ? (
              <CheckIconBox
                onClick={() => onSelectLists(task.ProjectCode, data)}
                isSelected={isSelected(data)}
              />
            ) : (
              <Space />
            ))}
        </Col>
        <Col span={22}>
          <BoxScore>
            <Row type="flex" justify="space-between">
              <Col span={14}>
                <span>สถานะ</span>
                {renderTagStatus(data.CheckStatus)}
              </Col>
              <Col span={10}>
                <SignDescription
                  isCorrect={isHasSign}
                  label={'ลงชื่อรับทราบ'}
                />
                <SignDescription
                  isCorrect={isHasImage}
                  label={'รูปส่งรายงาน'}
                />
              </Col>
            </Row>
          </BoxScore>
        </Col>
      </Row>
    )
  }

  const renderDataList = () => {
    return List.map((data, index) => {
      const className = index % 2 === 1 ? 'higlight' : ''
      const params = `${ProjectCode}/${data.CheckListID}/${data.ActivityID}/${data.RequestID}`
      const link = `/commissioning/detail/${params}`
      const commissDesc = <RightBox>{renderInspectionCheck(data)}</RightBox>
      return (
        <ContentBox key={index} className={className}>
          <Row type="flex">
            <Col span={12}>
              <LeftBoxContent
                onClickReturn={() => onClickReturn(data)}
                titleText={
                  <Link to={link}>
                    <BoldText> {data.ActivityName} </BoldText>
                    <p>
                      {data.CommissioningType}({data.CheckNumber})
                    </p>
                  </Link>
                }
                returnDesc="หากคืนผัง งานที่ตรวจไว้ก่อนหน้าจะถูก Reset ทั้งหมด คุณยืนยันที่จะคืนผังใช่หรือไม่"
              />
            </Col>
            <Col span={12}>
              {isSendReport ? (
                commissDesc
              ) : (
                <Link to={link}>{commissDesc}</Link>
              )}
            </Col>
          </Row>
        </ContentBox>
      )
    })
  }

  const title = `${ProjectCode} - ${ProjectNameTH}`
  return <ProjectBox projectTitle={title} children={renderDataList()} />
}

const ContentBox = styled.div`
  height: 100px;
  border-bottom: solid 1px #e4e7eb;
  &.higlight {
    background-color: rgba(0, 49, 104, 0.03);
  }
`

const RightBox = styled.div`
  float: right;
  width: 100%;
  .score {
    padding: 12px 32px;
    height: 100px;
    border-bottom: solid 1px #e4e7eb;
  }
  .score:last-child {
    border: none;
  }
`

const BoxScore = styled.div`
  font-family: 'DB Helvethaica X 55 Regular';
  font-size: 18px;
  padding-left: 8px;
  color: #000;
`

const BoldText = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 20px;
  color: #000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
const StatusLabel: any = styled.div`
  width: max-content;
  border-radius: 10px;
  height: 20px;
  text-align: center;
  color: #fff;
  padding: 0 12px;
  font-family: 'DB Helvethaica X 55 Regular';
  font-size: 16px;
  line-height: 1.4;
`

const StatusPass = styled(StatusLabel)`
  background-color: #5bc79e;
`

const StatusReject = styled(StatusLabel)`
  background-color: #d0494c;
`

const Space = styled.div`
  width: 40px;
  height: 1px;
  float: left;
`

export default InprogressCard
