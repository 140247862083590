import React from 'react'
import styled from 'styled-components'

type Props = {
  text: string
  isPass?: boolean
}

const StatusTag: React.ComponentType<Props> = props => {
  const { text = '', isPass = false } = props

  return <TagStatus className={isPass ? 'pass' : ''}>{text}</TagStatus>
}

const TagStatus = styled.div`
  width: max-content;
  text-align : center;
  padding : 0px 12px
  background-color : #d0494c;
  border-radius: 10px;
  font-size: 16px;
  color : #fff;
  line-height : 1.2;
  &.pass{
    background-color : #5bc79e;
  }
`

export default StatusTag
