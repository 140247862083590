import moment from 'moment'
import { DATE_FORMAT } from '../config'

export const sortingTask = (
  list: any[],
  type: string,
  name: keyof any
): any[] => {
  if (type === 'desc') {
    list.sort((a, b) => {
      const unixA = moment(a[name], DATE_FORMAT).unix()
      const unixB = moment(b[name], DATE_FORMAT).unix()

      return unixB - unixA
    })
  } else {
    list.sort((a, b) => {
      const unixA = moment(a[name], DATE_FORMAT).unix()
      const unixB = moment(b[name], DATE_FORMAT).unix()

      return unixA - unixB
    })
  }

  return list
}

export const sortingByString = (
  list: any[],
  type: string,
  name: keyof any
): any[] => {
  const options = {
    sensitivity: 'base',
  }
  if (type === 'asc') {
    list.sort((a: any, b: any) => {
      return `${a[name]}`.localeCompare(`${b[name]}`, 'en', options)
    })
  } else {
    list.sort((a: any, b: any) => {
      return `${b[name]}`.localeCompare(`${a[name]}`, 'en', options)
    })
  }

  return list
}

export const sortingByNumber = (
  list: any[],
  type: string,
  name: keyof any
): any[] => {
  if (type === 'asc') {
    list.sort((a: any, b: any) => {
      const valA = a[name]
      const valB = b[name]
      return valA === valB ? 0 : valA > valB ? 1 : -1
    })
  } else {
    list.sort((a: any, b: any) => {
      const valA = a[name]
      const valB = b[name]
      return valA === valB ? 0 : valA > valB ? -1 : 1
    })
  }

  return list
}
