import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { connect, useDispatch } from 'react-redux'
import { RootState } from 'typings/RootState'
import { RouteComponentProps } from 'react-router-dom'
import { History } from 'history'

import { BreadcrumbLink } from 'Components/Shared/BreadCrumb/BreadCrumb'
import SubnavBar from 'Components/Shared/SubnavBar'
import EventModal from 'Components/Shared/Modal/EventModal'
import { AlertError } from 'Components/Shared'
import Menu from '../../../MenuAdmin'

import { serviceTokenSelector } from 'features/sensitive/sensitive.selector'

import { getProjectType } from 'features/masterData/api'
import QCSetting from './Setting'
import QCList from './List'
import { selectProjectType } from 'features/masterData/selector'
import {
  getZoneDetail,
  getProjectZone,
  getZoneTypeList,
  updateCheckList as reqUpdateCheckList,
} from 'features/admin/zone/api'
import { clearZoneDetail, updateCheckList } from 'features/admin/zone/actions'
import { ZoneDetail } from 'features/admin/zone/types'

import { validateJobType } from 'utils/validator'

const editModeBreadcrumbs: BreadcrumbLink[] = [
  {
    url: '/dashboard',
    title: 'Home',
    icon: '/home.png',
    active: true,
    showTitle: false,
  },
  {
    url: '/check-zone',
    title: 'ตรวจส่วนกลาง',
    active: true,
    showTitle: true,
  },
  {
    url: '/check-zone/:id',
    title: 'แก้ไข ตรวจส่วนกลาง',
    active: false,
    showTitle: true,
  },
]

type Props = RouteComponentProps<{ id: string }> & ReduxProps & DispatchProps

const CheckZoneDetail: React.ComponentType<Props> = ({
  projectType,
  event,
  zoneEvent,
  match,
  history,

  getZoneDetail,
  zoneDetail,
  zoneTypeList,
  clearZoneDetail,
  getProjectZone,
  projectZoneList,
  getZoneTypeList,
  getProjectType,
}) => {
  const [enableModal, setEnableModal] = useState(false)
  const [enableModalValidate, setEnableModalValidate] = useState(false)
  const [errorValidate, setErrorValidate] = useState('')
  const [tab, setTab] = useState({ list: '', setting: 'active' })

  const dispatch = useDispatch()

  const CheckListID = match.params.id
  const isEditing = zoneDetail.CheckListID !== 0

  useEffect(() => {
    if (CheckListID) {
      getZoneDetail(parseInt(CheckListID, 10))
    }
    getProjectType({ Page: 1, RowPerPage: 200 })
    getProjectZone()
    getZoneTypeList()

    return () => {
      clearZoneDetail()
    }
  }, [])

  const setTabToFirst = () => setTab({ list: '', setting: 'active' })
  const setTabToSecond = () => setTab({ list: 'active', setting: '' })

  const updateZone = (val: ZoneDetail) => {
    dispatch(updateCheckList({ ...val }))
  }
  const onSave = () => {
    const errorMsg = validateJobType(zoneDetail)
    if (errorMsg === '' && enableModalValidate == false) {
      setEnableModalValidate(false)
      setEnableModal(true)
      const ProjectList = zoneDetail.ProjectList.filter(p => p.Selected)
      dispatch(
        reqUpdateCheckList({
          ...zoneDetail,
          ProjectList,
        })
      )
    } else {
      setEnableModalValidate(true)
      setErrorValidate(errorMsg)
    }
  }

  const duplicateZone = () => {
    history.push('/check-zone/create')
  }

  const callBackErrorModal = () => {
    setEnableModalValidate(false)
    setErrorValidate('')
  }

  const gotoDashboard = () => {
    setEnableModal(false)
    history.push('/check-zone')
  }

  const closeEnableModal = () => setEnableModal(false)

  return (
    <>
      <EventModal
        event={[event, zoneEvent]}
        enable={enableModal}
        successCalback={gotoDashboard}
        errorCalback={closeEnableModal}
      />
      <AlertError
        text={errorValidate}
        isShow={enableModalValidate}
        callback={callBackErrorModal}
      />

      <SubnavBar breadcrumbs={editModeBreadcrumbs} hideSearch={true} />
      <Menu selected="check-zone">
        <MainPage>
          <Title>{isEditing ? 'แก้ไข' : 'สร้าง'} ตรวจ ส่วนกลาง</Title>
          <Tab>
            <TabItem className={tab.setting} onClick={setTabToFirst}>
              ตั้งค่า
            </TabItem>
            <TabItem className={tab.list} onClick={setTabToSecond}>
              รายการ
            </TabItem>
          </Tab>
          <Content>
            <QCSetting
              isActive={tab.setting === 'active'}
              isEditing={isEditing}
              projectType={projectType}
              updateCheckList={updateZone}
              onSave={onSave}
              duplicateZone={duplicateZone}
              zoneDetail={zoneDetail}
              projectZoneList={projectZoneList}
            />
            <QCList
              zoneTypeList={zoneTypeList}
              isActive={tab.list === 'active'}
              updateCheckList={updateZone}
              onSave={onSave}
              zoneDetail={zoneDetail}
            />
          </Content>
        </MainPage>
      </Menu>
    </>
  )
}

const MainPage = styled.div`
  padding: 40px 45px;
  width: 100%;
`

const Title = styled.h2`
  font-size: 28px;
  font-family: 'DB Helvethaica X 75 Bd';
`

const Tab = styled.div`
  height: 28px;
  width: 100%;
  border-bottom: 1px solid #e8ebf0;
`

const TabItem = styled.div`
  font-size: 18px;
  float: left;
  margin-right: 24px;
  color: #8b99a7;
  cursor: pointer;
  &.active {
    color: #000000;
    border-bottom: 2px solid #003168;
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.03);
  }
`

const Content = styled.div`
  margin-top: 40px;
`

const mapStateToProps = (state: RootState) => {
  return {
    Token: serviceTokenSelector(state),
    projectType: selectProjectType(state),
    event: state.adminZone.Event,
    zoneEvent: state.adminZone.ZoneEvent,
    zoneDetail: state.adminZone.ZoneDetail,
    zoneTypeList: state.adminZone.zoneTypeList,
    projectZoneList: state.adminZone.ProjectZoneList,
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>
const mapDispatchToProps = {
  getZoneDetail,
  clearZoneDetail,
  getProjectZone,
  getZoneTypeList,
  getProjectType,
}

type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(CheckZoneDetail)
