import moment from 'moment';
import { Task } from './tasklane.types';
import { DATE_FORMAT } from '../../config';

export const sortingTask = (
  list: Task[],
  type: string,
  name: keyof Task
): Task[] => {
  if (type === 'desc') {
    list.sort((a, b) => {
      const unixA = moment(a[name], DATE_FORMAT).unix();
      const unixB = moment(b[name], DATE_FORMAT).unix();

      return unixB - unixA;
    });
  } else {
    list.sort((a, b) => {
      const unixA = moment(a[name], DATE_FORMAT).unix();
      const unixB = moment(b[name], DATE_FORMAT).unix();

      return unixA - unixB;
    });
  }

  return list;
};

export const getTaskIdsOnly = (list: Task[]): string[] => {
  return list.reduce((arr: string[], item: Task) => {
    arr.push(item.ActivityID);

    return arr;
  }, []);
};
