import React from 'react'
import { Row, Col } from 'antd'
import styled from 'styled-components'

import PrimaryButton from 'Components/Shared/Button/PrimaryButton'
import ImportBtn from './ImportBtn'

interface Props {
  title: string
  textImport?: string
  textCreate?: string
  onImport?: (data: any) => void
  onCreate?: () => void
  hideButton?: boolean
}

const LineHeader = (props: Props) => {
  const {
    title,
    onImport,
    onCreate,
    textImport = 'Import',
    textCreate = 'เพิ่ม',
    hideButton = false,
  } = props

  let contentBtn
  if (hideButton === false) {
    contentBtn = (
      <Col>
        <ButtonSection>
          <ImportBtn
            name={textImport}
            text={textImport}
            disabled={onImport === undefined}
            onChange={onImport}
          />
        </ButtonSection>
        <CreateSection>
          <PrimaryButton
            text={textCreate}
            disabled={onCreate === undefined}
            onClick={onCreate}
          />
        </CreateSection>
      </Col>
    )
  }
  return (
    <Header>
      <Row type="flex" justify="space-between" align="middle">
        <Col>
          <Title>{title}</Title>
        </Col>
        {contentBtn}
      </Row>
    </Header>
  )
}

const Header = styled.div`
  height: 50px;
  margin-bottom: 35px;
`

const Title = styled.div`
  font-size: 28px;
  line-height: 1.07;
  font-family: 'DB Helvethaica X 75 Bd';
  color: #000;
  height: 50px;
  align-items: center;
  display: flex;
`

const ButtonSection = styled.div`
  float: left;
`

const CreateSection = styled.div`
  float: left;
  margin-left: 24px;
  width: 198px;
`

export default LineHeader
