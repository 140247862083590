import React, { useState, useEffect } from 'react'
import { useDispatch, connect } from 'react-redux'
import RootState from 'typings/RootState'
import styled from 'styled-components'
import { RouteComponentProps } from 'react-router-dom'
import { BreadcrumbLink } from 'Components/Shared/BreadCrumb/BreadCrumb'
import SubnavBar from 'Components/Shared/SubnavBar'
import {
  userEmailSelector,
  serviceTokenSelector,
} from 'features/sensitive/sensitive.selector'

import {
  getReportList,
  exportReportList,
} from 'features/adminReport/monthly/api'
import { GetReportListReq } from 'features/adminReport/monthly/types'
import {
  selectEvent,
  selectFilter,
  selectReportList,
  selectInitFilter,
} from 'features/adminReport/monthly/selector'
import Menu from 'Components/MenuAdmin/AdminQCMenu'
import { EventModal } from 'Components/Shared'
import Filter from 'Components/AdminQC/Report/Filter/MonthlyFilter'
import { toDateText } from 'utils/datetime'
import BarChart from './BarChart'

const breadcrumbs: BreadcrumbLink[] = [
  {
    url: '/dashboard',
    title: 'Home',
    icon: '/home.png',
    active: true,
    showTitle: false,
  },
  {
    url: '/report/monthly',
    title: 'Monthly report',
    active: false,
    showTitle: true,
  },
]

type Props = ReduxProps & DispatchProps & RouteComponentProps

const ReportList: React.ComponentType<Props> = ({
  UserEmail,
  token,
  dataList,
  event,
  filter: currentFilter,
  initFilter,
  dataExport,
  exportEvent,
}) => {
  const dispatch = useDispatch()
  const [enableModal, setEnableModal] = useState(false)

  useEffect(() => {
    onFindReport()
  }, [])

  const onFindReport = (
    isClearFilter: boolean = false,
    isExport: boolean = false
  ) => {
    setEnableModal(true)
    const filter = isClearFilter ? initFilter : currentFilter
    const req: GetReportListReq = {
      UserEmail,
      ProjectTypeID: filter.ProjectTypeID,
      StartDate: toDateText(filter.Date.StartDate || ''),
      EndDate: toDateText(filter.Date.FinishDate || ''),
      QCTypeID: filter.QCTypeID,
      SquadID: filter.SquadID,
      TeamID: filter.TeamID,
    }

    if (isExport) {
      dispatch(exportReportList(token, req))
    } else {
      dispatch(getReportList(token, req))
    }
  }

  const onSuccess = () => {
    setEnableModal(false)
  }

  return (
    <>
      <SubnavBar breadcrumbs={breadcrumbs} hideSearch={true} />
      <EventModal
        name="report-monthly"
        event={[event, exportEvent]}
        enable={enableModal}
        successCalback={onSuccess}
      />
      <Menu selected={'report.monthly'}>
        <MainPage>
          <Header>Monthly report</Header>
          <Filter
            onSearch={onFindReport}
            isDownLoading={exportEvent.IsLoading}
            onExport={() => onFindReport(false, true)}
            exportData={dataExport}
          />
          <BarChart dataList={dataList} />
        </MainPage>
      </Menu>
    </>
  )
}

const MainPage = styled.div`
  padding: 40px 45px;
  width: 100%;
`

const Header = styled.div`
  margin-bottom: 27px;
  font-size: 28px;
  line-height: 1.07;
  font-family: 'DB Helvethaica X 75 Bd';
  color: #000;
`

const mapStateToProps = (state: RootState) => {
  return {
    dataList: selectReportList(state),
    event: selectEvent(state),
    filter: selectFilter(state),
    initFilter: selectInitFilter(state),
    UserEmail: userEmailSelector(state),
    token: serviceTokenSelector(state),
    dataExport: state.adminReportMonthly.DataExport,
    exportEvent: state.adminReportMonthly.ExportEvent,
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>
const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(ReportList)
