import React, { useEffect } from 'react'
import styled from 'styled-components'
import { connect, useDispatch } from 'react-redux'
import SubnavBar from 'Components/Shared/SubnavBar'
import { Row, Col } from 'antd'
import { RouteComponentProps } from 'react-router-dom'
import RootState from 'typings/RootState'

import { BreadcrumbLink } from '../../Shared/BreadCrumb/BreadCrumb'
import {
  userEmailSelector,
  serviceTokenSelector,
} from 'features/sensitive/sensitive.selector'

import { selectList, selectEvent } from 'features/approval/dashboard/selector'
import { fetchWbsListRequest } from 'features/wbs/wbs.api'

import { LoadingView } from 'Components/Shared'
import projectHelper from 'utils/projectHelper'
import { getEvent } from 'features/wbs/wbs.selector'

const breadcrumbs: BreadcrumbLink[] = [
  {
    url: '/',
    title: 'Home',
    icon: '/home.png',
    active: true,
    showTitle: false,
  },
]

type Props = ReduxProps & DispatchProps & RouteComponentProps

const ApprovalDashboard: React.ComponentType<Props> = props => {
  const { UserEmail, Token, Event, ProjectList, history, wbsEvent } = props

  const dispatch = useDispatch()
  const onSetProject = (code: string) => {
    history.push('/ms/list')
    projectHelper.saveProjectCode(code)
    dispatch(
      fetchWbsListRequest({
        email: UserEmail,
        projectCode: code,
        token: Token,
      })
    )
  }

  const content = ProjectList.map((item, index) => {
    return (
      <Col span={12}>
        <ProjectDetail onClick={() => onSetProject(item.ProjectCode)}>
          <ProjectName>
            {item.ProjectCode}-{item.ProjectName}
          </ProjectName>
          <span>รออนุมัติ {item.NumOfApprove} งาน</span>
          <Underline />
        </ProjectDetail>
      </Col>
    )
  })
  return (
    <>
      <LoadingView isShow={Event.IsLoading || wbsEvent.IsLoading} />
      <SubnavBar breadcrumbs={breadcrumbs} hideSearch={true} />
      <MainPage>
        <Row type="flex">{content}</Row>
      </MainPage>
    </>
  )
}

const MainPage = styled.div`
  background-color: #f5f6fa;
  width: 1110px;
  -khtml-user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  overflow-x: scroll;

  padding-top: 20px;
  margin: 0 auto;
`

const ProjectDetail = styled.div`
  width: 100%;
  line-height: 0.5;
  margin-bottom: 10px;
  margin-right: 10px;
  padding: 18px;
  cursor: pointer;
  span {
    font-family: 'DB Helvethaica X 55 Regular';
    font-size: 18px;
    color: #8b99a7;
  }
`

const ProjectName = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 24px;
  line-height: 1.08;
  color: #000000;
  padding: 0px;
  margin: 0px;
`

const Underline = styled.div`
  border: 0px solid #e8ebf0;
  border-bottom-width: 1px;
  margin-top: 15px;
`

const mapStateToProps = (state: RootState) => {
  return {
    UserEmail: userEmailSelector(state),
    Token: serviceTokenSelector(state),
    ProjectList: selectList(state),
    Event: selectEvent(state),
    wbsEvent: getEvent(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(ApprovalDashboard)
