import React from 'react'
import PopupMenu from 'Components/Shared/Filters/PopupMenu'
import Content from 'Components/Shared/Filters/CommonContent'
import { Filter } from 'features/admin/user/types'

type Props = {
  onFilterChange: (filter: any) => void
  filter: Filter
}

const UserTypeFilter: React.FunctionComponent<Props> = props => {
  const { onFilterChange, filter } = props

  const userType = [
    {
      name: 'Vendor',
      value: 'vendor',
    },
    {
      name: 'Sansiri',
      value: 'sansiri',
    },
  ]

  return (
    <PopupMenu
      label={'ประเภท'}
      content={
        <Content
          title="ประเภท"
          data={userType}
          filter={filter.TypeList}
          onChange={data => {
            filter.TypeList = data
            onFilterChange(filter)
          }}
          hasSearch={false}
        />
      }
      isActive={filter.TypeList && filter.TypeList.length > 0}
    />
  )
}

export default UserTypeFilter
