import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { RootState } from 'typings/RootState'
import styled from 'styled-components'
import { Button, Col } from 'antd'

import { getZoneList, getFloorList } from 'features/filter/api'
import {
  ZoneFilter,
  GetZoneListReq,
  GetFloorListReq,
} from 'features/filter/types'
import {
  selectUnitList,
  selectZoneFilter,
  selectZoneList,
  selectFloorList,
} from 'features/filter/selector'
import { clearFilter, updateZoneFilter } from 'features/filter/actions'
import PopupMenu from 'Components/Shared/Filters/PopupMenu'

import {
  serviceTokenSelector,
  userEmailSelector,
} from 'features/sensitive/sensitive.selector'

import { getWbsList } from 'features/wbs/wbs.actions'
import { getLeafWbsList } from 'features/wbs/wbs.function'
import { getWbsListSelector } from 'features/wbs/wbs.selector'

import ZoneContent from 'Components/Shared/Filters/ZoneContent'
import FloorContent from 'Components/Shared/Filters/FloorContent'
import CheckNumberContent from 'Components/Shared/Filters/CheckNumberContent'
import { WbsFilter } from './Components'

type Props = {
  onSearch?: () => void
  ProjectCode: string
  withPadding?: boolean
} & ReduxProps &
  DispatchProps

const ZoneFilterPage: React.FunctionComponent<Props> = props => {
  const {
    ProjectCode,
    UserEmail,
    token,
    zoneList,
    filter,
    onSearch = () => {},
    withPadding = false,
    floorList,
    wbsList,
  } = props

  useEffect(() => {
    dispatch(clearFilter())
    onGetZoneList()
    onGetFloorList()
    onGetWbsList()
  }, [ProjectCode, UserEmail])

  const onGetWbsList = () => {
    const req: any = {
      ProjectCode,
      UserEmail,
      Token: token,
    }
    dispatch(getWbsList(req))
  }

  const onGetZoneList = () => {
    const req: GetZoneListReq = {
      ProjectCode,
      UserEmail,
    }
    dispatch(getZoneList(token, req))
  }

  const onGetFloorList = () => {
    const req: GetFloorListReq = {
      ProjectCode,
      UserEmail,
    }
    dispatch(getFloorList(token, req))
  }

  const dispatch = useDispatch()
  const onFilterChange = (filter: ZoneFilter) => {
    dispatch(updateZoneFilter(filter))
  }

  const onClick = () => {
    filter.LeafWbsList = getLeafWbsList(wbsList, filter.WbsList)
    dispatch(updateZoneFilter(filter))
    onSearch()
  }

  return (
    <MainPage className={withPadding ? 'with-padding' : ''}>
      <div className="filters">
        <Col span={24} className="filters-button">
          <WbsFilter
            onFilterChange={onFilterChange}
            filter={filter}
            wbsList={wbsList}
          />

          <PopupMenu
            label={'โซน'}
            content={
              <ZoneContent
                zones={zoneList.List}
                filter={filter.ZoneList}
                onChange={data => {
                  filter.ZoneList = data
                  onFilterChange(filter)
                }}
              />
            }
            isActive={filter.ZoneList.length > 0}
          />

          <PopupMenu
            label={'ชั้น'}
            content={
              <FloorContent
                floors={floorList.List}
                filter={filter.FloorList}
                onChange={data => {
                  filter.FloorList = data
                  onFilterChange(filter)
                }}
              />
            }
            isActive={filter.FloorList.length > 0}
          />

          <PopupMenu
            label={'ตรวจครั้งที่'}
            content={
              <CheckNumberContent
                checknumbers={[1, 2]}
                filter={filter.CheckNumber}
                onChange={data => {
                  filter.CheckNumber = data
                  onFilterChange(filter)
                }}
              />
            }
            isActive={filter.CheckNumber.length > 0}
          />

          <Button type="primary" onClick={onClick}>
            กรองข้อมูล
          </Button>
        </Col>
      </div>
    </MainPage>
  )
}

const MainPage = styled.div`
  &.with-padding {
    padding: 0 120px;
  }

  background-color: #f5f6fa;
`

const mapStateToProps = (state: RootState) => {
  return {
    UserEmail: userEmailSelector(state),
    token: serviceTokenSelector(state),
    filter: selectZoneFilter(state),
    unitList: selectUnitList(state),
    zoneList: selectZoneList(state),
    floorList: selectFloorList(state),
    wbsList: getWbsListSelector(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(ZoneFilterPage)
