import React from 'react'

import styled from 'styled-components'

import MenuItem from './MenuItem'

const SideMenu = (props: any) => {
  const { selected, list, title, children } = props

  const content = list.map((item: any, index: number) => {
    return <MenuItem selected={selected} key={index} item={item} />
  })

  const titleItem = title ? <h3>{title}</h3> : undefined

  return (
    <PageView>
      <OuterMenu>{content}</OuterMenu>
      <Content>
        {titleItem}
        {children}
      </Content>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  height: auto;
  display: block;
  position: relative;
  box-sizing: border-box;
`

const OuterMenu = styled.div`
  background-color: #2e374d;
  padding: 26px;
  height: calc(100vh - 112px);
  overflow: scroll;
  width: 256px;
  position: absolute;
  left: 0;
`

const Content = styled.div`
  background-color: #f5f6fa;
  position: absolute;
  left: 256px;
  width: calc(100% - 256px);
  height: calc(100vh - 112px);
  overflow: scroll;
  h3 {
    height: 30px;
    font-family: 'DB Helvethaica X 75 Bd';
    font-size: 28px;
    line-height: 1.07;
    color: #000000;
  }

  -khtml-user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
`

export default SideMenu
