import { put, take, takeEvery, select, call } from 'redux-saga/effects'
import { APIPath } from 'services/APIPath'
import { getType } from 'typesafe-actions'
import * as actions from './actions'
import * as uploadAction from '../../uploadfile/actions'

import { getModelList, getItemForModel } from './api'
import { CreateModelReq, UpdateModelReq } from './types'

import { uploadFileReq } from '../../uploadfile/api'

import APIManager, { configWithToken } from 'services/APIManager'
import { serviceTokenSelector } from 'features/sensitive/sensitive.selector'

function* uploadFileList(userEmail: string, token: string, list: any[]) {
  const result: any = []
  const num = list.length

  for (let i = 0; i < num; i++) {
    const item = list[i]
    const { DirectionName } = item
    if (DirectionName === 'L' || DirectionName === 'R') {
      if (item.ImageFloorPlan.FileID === 0) {
        const formData = new FormData()
        formData.set('UserEmail', userEmail)
        formData.append('Files', item.ImageFloorPlan.FileName)
        yield put(uploadFileReq(formData, token))
        const resp = yield take(uploadAction.uploadFileAsyncReq.success)
        const resultList = resp.payload.ResponseData
        item.ImageFloorPlan = resultList[0]
      }

      result.push(item)
    }
  }

  return result
}

function* sendRequest(action: any) {
  const {
    model,
    ProjectCode,
    UserEmail,
    Token,
    modelListReq,
    mappingModelReq,
  } = action.payload
  try {
    const {
      ModelID,
      ModelCode,
      ModelName,
      Status,
      UnitArea,
      UnitList,
      ConfigAreaList,
    } = model

    const ConfigList = yield uploadFileList(UserEmail, Token, model.ConfigList)

    if (ModelID !== '') {
      const params: UpdateModelReq = {
        UserEmail,
        ProjectCode,
        ModelID,
        ModelCode,
        ModelName,
        Status,
        UnitArea: +UnitArea,
        ConfigList,
        UnitList,
        ConfigAreaList,
      }

      yield call(
        APIManager.call,
        APIPath.UpdateModel,
        'POST',
        params,
        configWithToken(`Bearer ${Token}`)
      )
    } else {
      const params: CreateModelReq = {
        UserEmail,
        ProjectCode,
        ModelCode,
        ModelName,
        Status,
        UnitArea: +UnitArea,
        ConfigList,
        UnitList,
        ConfigAreaList,
      }
      yield call(
        APIManager.call,
        APIPath.CreateModel,
        'POST',
        params,
        configWithToken(`Bearer ${Token}`)
      )
    }
    yield put(actions.submitModelReq.success())
    yield put(getModelList(modelListReq, Token))
    yield put(getItemForModel(mappingModelReq, Token))
  } catch (e) {
    yield put(actions.submitModelReq.failure(e))
  }
}

function* sendDeleteRequest(
  action: ReturnType<typeof actions.deleteModelReq.request>
) {
  try {
    const Token = yield select(serviceTokenSelector)
    const { pagination, de } = action.payload

    yield call(
      APIManager.call,
      APIPath.DeleteModel,
      'POST',
      de,
      configWithToken(`Bearer ${Token}`)
    )
    yield put(actions.deleteModelReq.success())
    yield put(getModelList(pagination, Token))
  } catch (error) {
    yield put(actions.deleteModelReq.failure(error))
  }
}

function* allSaga() {
  yield takeEvery(getType(actions.submitModelReq.request), sendRequest)
  yield takeEvery(getType(actions.deleteModelReq.request), sendDeleteRequest)
}

export default allSaga
