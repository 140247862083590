import React from 'react'
import { Button } from 'antd'
import { translate } from 'i18n'
import TKeys from 'i18n/translationKey'
import { useDispatch } from 'react-redux'
import history from 'utils/history'

type Props = {}

const LoginButton: React.FunctionComponent<Props> = props => {
  const dispatch = useDispatch()
  const onLogin = () => {
    dispatch({ type: 'login/INIT_LOGIN' })
    history.push('/')
  }
  return (
    <div className="sign-in-button-wrapper">
      <Button type="default" onClick={onLogin} className="btn-login">
        {translate(TKeys.Login.login)}
      </Button>
    </div>
  )
}

export default LoginButton
