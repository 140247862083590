import React from 'react'
import { Row } from 'antd'
import TaskLaneColumn from './columns/TaskLaneColumn'
import { Task } from 'features/tasklane/tasklane.types'
import { TASKLANE_ACTIVITY_LIMIT } from 'config'

type TaskLaneCollectionProps = {
  isFetching: boolean
  tasklanes: {
    todo: Task[]
    inProgress: Task[]
    completed: Task[]
  }
}

type Props = TaskLaneCollectionProps

const TaskLaneCollection: React.ComponentType<Props> = ({
  isFetching,
  tasklanes,
}) => {
  return (
    <Row style={{ marginTop: '13px' }} gutter={40} className="tasklane-wrapper">
      <TaskLaneColumn
        key={`${Math.random()}`}
        datasource={tasklanes.todo}
        type="todo"
        isFetching={isFetching}
        limit={TASKLANE_ACTIVITY_LIMIT}
      />

      <TaskLaneColumn
        key={`${Math.random()}`}
        datasource={tasklanes.inProgress}
        type="inProgress"
        isFetching={isFetching}
        limit={TASKLANE_ACTIVITY_LIMIT}
      />

      <TaskLaneColumn
        key={`${Math.random()}`}
        datasource={tasklanes.completed}
        type="complete"
        isFetching={isFetching}
        limit={TASKLANE_ACTIVITY_LIMIT}
      />
    </Row>
  )
}

export default TaskLaneCollection
