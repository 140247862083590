import RootState from 'typings/RootState'
import { DashboardData, DashboardEvent, DashboardQuery, Filter } from './types'

export const selectData = (state: RootState): DashboardData =>
  state.cmpjQcDashboard.data

export const selectEvent = (state: RootState): DashboardEvent =>
  state.cmpjQcDashboard.event

export const selectSorting = (state: RootState) => state.cmpjQcDashboard.sorting
export const selectFilter = (state: RootState): Filter =>
  state.cmpjQcDashboard.filter

export const selectInitFilter = (state: RootState): Filter =>
  state.cmpjQcDashboard.initFilter

export const selectQuery = (state: RootState): DashboardQuery =>
  state.cmpjQcDashboard.query
