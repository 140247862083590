import { createStandardAction, createAsyncAction } from 'typesafe-actions'
import { GetActivityReq, GetActivityApi } from './types'
import { UpdateActivityReq, UpdateActivityApi } from '../activity/types'

export const getApprovalListAction = createAsyncAction(
  'approval/list/FETCH_REQUEST',
  'approval/list/FETCH_SUCCESS',
  'approval/list/FETCH_FAILURE'
)<GetActivityReq, GetActivityApi, any>()

export const getMSApprovalListAction = createAsyncAction(
  'approval/ms_list/FETCH_REQUEST',
  'approval/ms_list/FETCH_SUCCESS',
  'approval/ms_list/FETCH_FAILURE'
)<GetActivityReq, GetActivityApi, any>()

export const updateActivity = createAsyncAction(
  'approval/list/UPDATE_ACTIVITY_REQUEST',
  'approval/list/UPDATE_ACTIVITY_SUCCESS',
  'approval/list/UPDATE_ACTIVITY_FAILURE'
)<UpdateActivityReq, UpdateActivityApi, any>()

export const updateMSActivity = createAsyncAction(
  'approval/ms_list/UPDATE_ACTIVITY_REQUEST',
  'approval/ms_list/UPDATE_ACTIVITY_SUCCESS',
  'approval/ms_list/UPDATE_ACTIVITY_FAILURE'
)<UpdateActivityReq, UpdateActivityApi, any>()

export const sortingTask = createStandardAction('approval/list/SORTING')<{
  name: string
  sort?: string
}>()
