import React from 'react'
import { Col, Row } from 'antd'
import styled from 'styled-components'
import { TableRow } from 'Components/Shared/Table'
import { DefectItem } from 'features/pmr/types'
import { RequireIcon } from 'Components/Shared/Icon'
import { PrimaryButton } from 'Components/Shared/Button'

type Props = {
  item: DefectItem
  onClick?: (item: DefectItem) => void
  onApprove?: (item: DefectItem) => void
  onReject?: (item: DefectItem) => void
  mode: 'PMR' | 'CM' | 'QC'
  showButton?: boolean
}

const ReportItem: React.FunctionComponent<Props> = props => {
  const {
    item,
    onClick = () => {},
    onApprove = () => {},
    onReject = () => {},
    showButton = false,
    mode,
  } = props

  const color = '#000'

  const getButton = () => {
    return (
      <Row type="flex" justify="space-around">
        <Col span={11}>
          <Btn>
            <PrimaryButton
              text="ไม่ผ่าน"
              disabled={false}
              onClick={() => onReject(item)}
              btnType="Reject"
            />
          </Btn>
        </Col>
        <Col span={11}>
          <Btn>
            <PrimaryButton
              text="ผ่าน"
              disabled={false}
              onClick={() => onApprove(item)}
              btnType="Approve"
            />
          </Btn>
        </Col>
      </Row>
    )
  }

  const span = mode === 'QC' ? 4 : 5

  return (
    <RowView onClick={() => onClick(item)}>
      <TableRow minHeight={60}>
        <Col span={2}>
          <Pin>{item.PinNumber}</Pin>
        </Col>
        <Col span={span}>
          <Text>
            {item.IsRequired && (
              <Space>
                <RequireIcon size={14} />
              </Space>
            )}

            {item.JobTypeName}
          </Text>
        </Col>

        <Col span={span}>
          <Text>{item.SubJobTypeName}</Text>
        </Col>

        <Col span={span}>
          <Text>{item.DetailName}</Text>
        </Col>
        {mode === 'QC' && (
          <Col span={3}>
            <Text>{item.FixReason}</Text>
          </Col>
        )}

        <Col span={2}>
          <Text>
            {item.CreatedByModule}({item.CheckNumber})
          </Text>
        </Col>
        {showButton && <Col span={4}>{getButton()}</Col>}

        <Col span={1} />
      </TableRow>
    </RowView>
  )
}

const RowView = styled.div`
  font-size: 20px;
  font-family: 'DB Helvethaica X 55 Regular';
  cursor: pointer;
`

const Pin = styled.div`
  padding-left: 24px;
  font-family: 'DB Helvethaica X 75 Bd';
`

const Space = styled.div`
  padding-right: 5px;
  float: left;
`

const Margin = styled.div`
  margin-left: 4px;
`

const Text = styled.div`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 10px;
`

const Btn = styled.div`
  .btn {
    height: 44px !important;
  }
`

export default ReportItem
