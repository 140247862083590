import React, { useRef } from 'react'
import { Col, Layout, Row } from 'antd'
import styled from 'styled-components'
import {
  ReportHeader,
  ReportLogo,
  DescItem,
  TaskScore,
  ReportTableHeader,
  ReportTableRow,
  Signature,
} from 'Components/AdminQC/Report/Components'
import { DataDetail, DefectData } from 'features/adminReport/unit/types'
import StatusTag from 'Components/AdminQC/Report/Components/StatusTag'
import DefectItem from './DefectItem'
import { mapUnitStatus, unitStatusIsPass } from 'utils/status-map'
import { toDateText, toMomentDateTime } from 'utils/datetime'
import { numberFormat } from 'utils/format'
import { UnitReportItem } from 'features/report/unit/types'

const { Content } = Layout

type Props = {
  data: DataDetail
}

const ReportDetail: React.ComponentType<Props> = props => {
  const componentPrint = useRef(null)
  const { data } = props
  const renderDescItem = (title: string, value: any) => {
    return <DescItem title={title} value={value || '-'} percentW={16} />
  }

  const projectName = `${data.ProjectCode}-${data.ProjectNameTH}`
  const statusTxt = mapUnitStatus(data.CheckStatus).status
  const isPass = unitStatusIsPass(true, data.CheckStatus)

  const DescContent = (
    <DesBox>
      <Row type="flex">
        {renderDescItem('โครงการ', projectName)}
        {renderDescItem('ผู้จัดการโครงการ', data.ProjectManager)}
        {renderDescItem('หมวด QC', `${data.QCTypeName}(${data.CheckNumber})`)}
        {renderDescItem('โมเดล', data.ModelName)}
        {renderDescItem('ยูนิต', data.Unit)}
        {renderDescItem(
          'ผลการตรวจ',
          <StatusTag text={statusTxt} isPass={isPass} />
        )}
        {renderDescItem(
          'วันที่ตรวจ',
          toDateText(toMomentDateTime(data.InspectDate))
        )}
        {renderDescItem(
          'วันที่ส่งรายงาน',
          toDateText(toMomentDateTime(data.CheckedDate))
        )}
        {renderDescItem('ผู้ตรวจ', data.CheckedBy)}
        {renderDescItem('ผู้ควบคุมงาน', data.ControllerUser)}
        {renderDescItem('ผู้รับเหมาหลัก', data.MainConUser)}
        <Col style={{ width: '20%' }}>
          <TaskScore
            checkStatus={data.CheckStatus}
            score={data.InspectionScore}
          />
        </Col>
      </Row>
    </DesBox>
  )

  const renderDefectItem = () => {
    return (
      data &&
      data.DefectList &&
      data.DefectList.map((defect: UnitReportItem, i: number) => {
        return <DefectItem key={i} index={i} defect={defect} />
      })
    )
  }

  const renderData = (item: DefectData) => {
    return [
      {
        title: item.JobTypeName,
        span: 6,
        isBold: true,
      },
      {
        title: item.SubJobTypeName,
        span: 5,
      },
      {
        title: numberFormat(item.TotalScore),
        span: 4,
      },
      {
        title: item.NumOfDefect,
        span: 3,
      },
      {
        title: numberFormat(item.SumOfDefectScore),
        span: 2,
      },
      {
        title: numberFormat(item.PercentOfDefect),
        span: 4,
        isBold: true,
      },
    ]
  }

  const defects = renderDefectItem()

  return (
    <Content className="mid-content">
      <div className="main-content activity-detail">
        <ReportHeader
          title="รายงานตรวจงานมาตรฐาน (ยูนิต / แปลง)"
          componentPrint={componentPrint}
        />
        <Box ref={componentPrint}>
          <ReportLogo title="รายงานตรวจงานมาตรฐาน (ยูนิต / แปลง)" />
          {DescContent}
          <DesBox>
            <TitleSection>สรุปคะแนนตามหมวดงาน</TitleSection>
            <ReportTableHeader columnsName={columnsName} />
            {data.SummaryDefectList.map((item: DefectData, i: number) => {
              return (
                <ReportTableRow key={i} data={renderData(item)} index={i} />
              )
            })}
          </DesBox>
          <ContentBox>
            <TitleSection>รายการ Defect</TitleSection>
            {defects}
          </ContentBox>
          <Signature data={data.Signature} />
        </Box>
      </div>
    </Content>
  )
}

const columnsName = [
  {
    title: 'หมวดงานหลัก',
    span: 6,
  },
  {
    title: 'หมวดงานรอง',
    span: 5,
  },
  {
    title: 'คะแนนเต็ม',
    span: 4,
  },
  {
    title: 'จำนวนหมุด',
    span: 3,
  },
  {
    title: 'คะแนนที่หัก',
    span: 2,
  },
  {
    title: 'คิดเป็นเปอร์เซนต์',
    span: 4,
  },
]

const Title: any = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 28px;
  color: #000;
`

const TitleSection = styled(Title)`
  font-size: 24px;
`

const Box = styled.div`
  background-color: rgba(0, 49, 104, 0.05);
  border-radius: 8px;
  border: solid 1px #e4e7eb;
  color: #000;

  @media print {
    background-color: white;
    border: solid 0px #e4e7eb;
  }
`

const DesBox = styled.div`
  padding: 12px 24px;
  background-color: #fff;
  page-break-before: alway;
`

const ContentBox = styled.div`
  padding: 24px;
  page-break-before: always;
`

export default ReportDetail
