import { createStandardAction, createAsyncAction } from 'typesafe-actions'
import { Moment } from 'moment-timezone'
import { Task, GetActivityApiResponseData } from '../tasklane/tasklane.types'
import {
  SaveActivityRequest,
  FetchActivitiesRequest,
  SaveActivityResponse,
  TaskById,
  SeeMoreQuery,
  ActivityFilter,
  FetchActivityHistoryRequest,
  ActivityHistory,
  FetchActivityRequest,
} from './activity.types'
import { OriginalActivity } from 'features/activity/activity.types'

export type UpdateFieldPayload = {
  fieldName: string
  value: any
}

export const sampleActivityAction = createStandardAction(
  'SAMPLE_ACTIVITY_ACTION'
)<void>()

export const setTaskListById = createStandardAction(
  'activity/SET_TASKLIST_BY_ID'
)<any>()

export const updatePaginationShowMorePages = createStandardAction(
  'activity/UPDATE_PAGINATION_SHOW_MORE_PAGES'
)<{ rowPerPage: number; totalRows: number }>()

export const updateField = createStandardAction('activity/UPDATE_FIELD')<
  UpdateFieldPayload
>()

export const updateOriginalActivity = createStandardAction(
  'activity/UPDATE_ORIGINAL_ACTIVITY'
)<OriginalActivity>()

export const resetSubmitErrorAction = createStandardAction(
  'activity/RESET_SUBMIT_ERROR'
)<void>()

export const clearSubmitError = createStandardAction(
  'activity/CLEAR_SUBMIT_ERROR'
)<undefined>()

export const currentBrowsingActivity = createStandardAction(
  'activity/SET_CURRENT_BROWSING_ACTIVITY'
)<string>()

export const currentSeeMorePage = createStandardAction(
  'activity/SET_CURRENT_SEE_MORE_PAGE'
)<{ query: SeeMoreQuery }>()

export const updateSeeMoreData = createStandardAction(
  'activity/UPDATE_SEE_MORE_DATA'
)<{ type: string; totalRows: number; page: number; data: TaskById }>()

export const seeMoreDataFetched = createStandardAction(
  'activity/SEE_MORE_DATA_FETCH_SUCCESS'
)<boolean>()

export const fetchSeeMoreByType = createStandardAction(
  'activity/FETCH_SEE_MORE_BY_TYPE'
)<{
  type: string
  page: number
  startDate?: Moment
  endDate?: Moment
  token: string
}>()

export const saveActivityDetailAsyncRequest = createAsyncAction(
  'activity/SAVE_ACTIVITY_REQUEST',
  'activity/SAVE_ACTIVITY_RESPONSE',
  'activity/SAVE_ACTIVITY_FAILURE'
)<SaveActivityRequest, SaveActivityResponse, any>()

export const fetchSeeMoreActivitiesAsyncRequest = createAsyncAction(
  'activity/FETCH_SEE_MORE_BY_TYPE_REQUEST',
  'activity/FETCH_SEE_MORE_BY_TYPE_RESPONSE',
  'activity/FETCH_SEE_MORE_BY_TYPE_FAILURE'
)<FetchActivitiesRequest, ApiResponse<GetActivityApiResponseData>, any>()

export const fetchActivityHistoryAsyncRequest = createAsyncAction(
  'activity/FETCH_ACTIVITY_HISTORY_REQUEST',
  'activity/FETCH_ACTIVITY_HISTORY_RESPONSE',
  'activity/FETCH_ACTIVITY_HISTORY_FAILURE'
)<FetchActivityHistoryRequest, ApiResponse<ActivityHistory[]>, any>()

export const sortingActivity = createStandardAction(
  'activity/SORTING_ACTIVITY'
)<{ name: string; sort?: string }>()

export const setFilterActivity = createStandardAction(
  'activity/CONFIG_FILTER_ACTIVITY'
)<ActivityFilter>()

export const setSeemoreActivity = createStandardAction(
  'activity/SET_SEEMORE_ACTIVITY'
)<{ type: string; list: Task[] }>()

export const filterActivity = createStandardAction('activity/FILTER_ACTIVITY')<
  void
>()

export const fetchActivityAsyncRequest = createAsyncAction(
  'activity/FETCH_ACTIVITY_DETAIL_REQUEST',
  'activity/FETCH_ACTIVITY_DETAIL_RESPONSE',
  'activity/FETCH_ACTIVITY_DETAIL_FAILURE'
)<FetchActivityRequest, ApiResponse<Task>, any>()

export const uploadFileAsyncRequest = createAsyncAction(
  'activity/UPLOAD_FILE_REQUEST',
  'activity/UPLOAD_FILE_RESPONSE',
  'activity/UPLOAD_FILE_FAILURE'
)<any, any, any>()

export const submitRequestActivity = createStandardAction(
  'activity/SUBMIT_REQUEST_ACTIVITY'
)<any>()
