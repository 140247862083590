import { createAsyncAction, createStandardAction } from 'typesafe-actions'
import { QCType } from './types'
import { GetApiResponse } from 'features/types/common'

export const GetMasterDocumentCheckListReq = createAsyncAction(
  'document/local/GET_DOCUMENT_CHECKLIST_REQ',
  'document/local/GET_DOCUMENT_CHECKLIST_RESP',
  'document/local/GET_DOCUMENT_CHECKLIST_FAIL'
)<any, GetApiResponse<{ QCTypeList: QCType[] }>, Error>()

export const GetActivityByDocumentCheckListReq = createAsyncAction(
  'document/local/GET_ACTIVITY_DOCUMENT_REQ',
  'document/local/GET_ACTIVITY_DOCUMENT_RESP',
  'document/local/GET_ACTIVITY_DOCUMENT_FAIL'
)<any, any, Error>()

export const clearDocument = createStandardAction(
  'document/local/CLEAR_DOCUMENT'
)<void>()

export const getActivityDocAfterUpdate = createStandardAction(
  'document/local/GET_ACTIVITY_DOCUMENT_AFTER_UPDATE'
)<{
  projectCode: string
  unitType: string
}>()
