import React from 'react'
import { DropdownList } from 'Components/Shared/Form'

type Props = {
  value?: any
  onChange?: (value: any) => void
  disabled?: boolean
}

const StatusList: React.FunctionComponent<Props> = props => {
  const { value = '', onChange, disabled = false } = props

  const status = [
    {
      value: 'Pass',
      name: 'ผ่าน',
    },
    {
      value: 'Fail',
      name: 'ไม่ผ่าน',
    },
  ]

  return (
    <DropdownList
      label="ผลการตรวจ"
      value={value}
      menu={status}
      onChange={onChange}
      disabled={disabled}
    />
  )
}

export default StatusList
