import { cloneDeep } from 'lodash'

import { toArray, getInt, getText, getScore, isDuplicate } from './common'

import {
  CheckListDetail,
  QCType,
  JobType,
  SubJobType,
} from 'features/checklist/constructRoad/types'

import { original } from 'features/checklist/constructRoad/reducer'

export const toCheckList = (data: string): any => {
  let checklist: CheckListDetail = cloneDeep(original)

  let mode = ''
  const rows = toArray(data)
  rows.forEach((row: any, index: number) => {
    const cmd = getText(row, 0).toLowerCase()
    switch (cmd) {
      case 'checklist name':
        mode = 'name'
        checklist.CheckListName = getText(row, 1)
        break

      case 'checklist type':
        mode = ''
        checklist.ProjectTypeID = getInt(row, 1)
        break

      case 'score':
        mode = 'score'
        checklist.ScoreRange = getScore(row)
        break

      case 'ncr':
        mode = 'ncr'
        addNcr(checklist, row, index)

        break
      case 'work type':
        mode = 'work type'
        addWorkType(checklist, row, index)

        break
      case 'description':
        mode = 'description'
        addDefectDetail(checklist, row, index)

        break

      case 'score range':
      case 'note':
        mode = ''
        break

      case 'end':
        return checklist

      default:
        if (mode === 'ncr') addNcr(checklist, row, index)
        else if (mode === 'work type') addWorkType(checklist, row, index)
        else if (mode === 'description') addDefectDetail(checklist, row, index)
        else mode = ''

        break
    }
  })

  return checklist
}

const addNcr = (checklist: CheckListDetail, row: string[], index: number) => {
  const name = getText(row, 1)
  if (name === '') return

  isDuplicate('DefectTypeName', name, checklist.DefectTypeList, index)
  checklist.DefectTypeList.push({
    DefectTypeID: 0,
    DefectTypeName: name,
    IsNCR: getText(row, 2) === 'yes',
  })
}

const addWorkType = (
  checklist: CheckListDetail,
  row: string[],
  index: number
) => {
  const name = getText(row, 1)
  if (name === '') return

  isDuplicate('WorkTypeName', name, checklist.WorkTypeList, index)
  checklist.WorkTypeList.push({
    WorkTypeID: 0,
    WorkTypeName: name,
    IndexID: getText(row, 2),
  })
}

const addDefectDetail = (
  checklist: CheckListDetail,
  row: string[],
  index: number
) => {
  const qcType = getText(row, 1)
  const job = getText(row, 2)
  const jobScore = getInt(row, 3)
  const subJob = getText(row, 4)
  const detail = getText(row, 5)
  const major = getText(row, 6)
  const supplier = getText(row, 7)
  const score = getInt(row, 8)
  const ncrScore = getInt(row, 9)

  if (detail === '') return

  let qcTypeItem: QCType = {
    QCTypeID: qcType,
    QCTypeName: '',
    JobTypeList: [],
  }

  if (qcType !== '') {
    isDuplicate('QCTypeID', qcType, checklist.QCTypeList, index)
    checklist.QCTypeList.push(qcTypeItem)
  }

  const qcIndex = checklist.QCTypeList.length - 1
  qcTypeItem = checklist.QCTypeList[qcIndex]

  let jobItem: JobType = {
    JobTypeID: 0,
    JobTypeName: job,
    Score: jobScore,
    SubJobTypeList: [],
    WorkTypeList: [],
  }

  if (job !== '') {
    isDuplicate('JobTypeName', job, qcTypeItem.JobTypeList, index)

    jobItem.WorkTypeList = getWorkType(row, 10, checklist.WorkTypeList.length)
    qcTypeItem.JobTypeList.push(jobItem)
  }

  const jobIndex = qcTypeItem.JobTypeList.length - 1
  jobItem = qcTypeItem.JobTypeList[jobIndex]

  let subJobItem: SubJobType = {
    SubJobTypeID: 0,
    SubJobTypeName: subJob,
    DetailList: [],
  }

  if (subJob !== '') {
    isDuplicate('SubJobTypeName', subJob, jobItem.SubJobTypeList, index)
    jobItem.SubJobTypeList.push(subJobItem)
  }

  const subJobIndex = jobItem.SubJobTypeList.length - 1
  subJobItem = jobItem.SubJobTypeList[subJobIndex]

  const detailItem = {
    DetailID: 0,
    DetailName: detail,
    IsRequired: major === 'yes',
    IsSupplier: supplier === 'yes',
    Score: score,
    NCRScore: ncrScore,
  }
  isDuplicate('DetailName', detail, subJobItem.DetailList, index)
  subJobItem.DetailList.push(detailItem)
}

const getWorkType = (row: string[], start: number, max: number) => {
  const work_list: any[] = []
  const len = row.length
  const lenMax = max + start
  for (let i = start; i < lenMax; i++) {
    const code = getText(row, i)
    if (code === '') return work_list

    work_list.push({
      WorkTypeID: 0,
      Index: code,
    })
  }

  return work_list
}
