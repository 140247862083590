import React from 'react'
import { BreadcrumbLink } from 'Components/Shared/BreadCrumb/BreadCrumb'
import SubnavBar from 'Components/Shared/SubnavBar'

type Props = {
  title: string
  name: string
}

const Breadcrumbs: React.ComponentType<Props> = props => {
  const { name ,title} = props
  const breadcrumbs: BreadcrumbLink[] = [
    {
      url: '/zone/dashboard',
      title: 'Home',
      icon: '/home.png',
      active: true,
      showTitle: false,
    },
    {
      url: '/zone/dashboard',
      title: 'บอร์ด',
      active: true,
      showTitle: true,
    },
    {
      url: '/zone/seemore/todo',
      title: title,
      active: true,
      showTitle: true,
    },
    {
      url: '/',
      title: name,
      active: false,
      showTitle: false,
    },
  ]

  return <SubnavBar breadcrumbs={breadcrumbs} hideSearch={true} />
}

export default Breadcrumbs
