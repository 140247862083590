import React from 'react'
import { CheckListForQC } from 'features/report/unit/types'
import { HistoryModal } from 'Components/Activity'
import styled from 'styled-components'

type Props = {
  isShow?: boolean
  onCloseModal: () => void
  checkList: CheckListForQC
}

const CheckListModal: React.FC<Props> = ({
  onCloseModal,
  isShow = false,
  checkList,
}) => {
  const contentModal = (
    <Content>
      <Title>รายการตรวจ</Title>
      <ChecklistBox>
        <ChecklistHeader>ลักษณะงานหลัก</ChecklistHeader>
        {checkList &&
          checkList.JobTypeList &&
          checkList.JobTypeList.map((item, index: number) => {
            return <List key={index}>{item.JobTypeName}</List>
          })}
      </ChecklistBox>
    </Content>
  )

  return (
    <HistoryModal
      isShow={isShow}
      onCloseModal={onCloseModal}
      content={contentModal}
    />
  )
}

const Content = styled.div``

const Title = styled.p`
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 28px;
  color: #000;
  margin-bottom: 10px;
`

const ChecklistBox = styled.div`
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #eeeff2;
  margin-bottom: 24px;
`

const ChecklistHeader = styled.div`
  padding: 12px 24px;
  background-color: #003168;
  font-family: 'DB Helvethaica X 75 Bd';
  color: #fff;
  font-size: 20px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`

const List = styled.div`
  margin: 0 30px;
  padding: 12px 0;
  font-family: 'DB Helvethaica X 55 Regular';
  font-size: 20px;
  color: #000;
  border-bottom: 1px solid #eeeff2;
  &:last-child {
    border-bottom: none;
  }
`

export default CheckListModal
