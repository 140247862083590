import withAPIAction from '../../../utils/withAPIAction'

import {} from './types'
import {
  GetMasterDocumentCheckListReq,
  GetActivityByDocumentCheckListReq,
} from './actions'

export const getMasterDocumentCheckList = (IsRequireQC: boolean) => {
  return withAPIAction<any>(state => ({
    method: 'POST',
    path: 'GetMasterDocumentCheckList',
    data: {
      UserEmail: state.sensitive.userEmail,
      IsRequireQC,
    },
    token: `Bearer ${state.sensitive.apiToken}`,
  }))(GetMasterDocumentCheckListReq)
}

export const getActivityByDocumentCheckList = (
  ProjectCode: string,
  UnitType: string
) => {
  return withAPIAction<any>(state => ({
    method: 'POST',
    path: 'GetActivityByDocumentCheckList',
    data: {
      UserEmail: state.sensitive.userEmail,
      ProjectCode,
      UnitType,
    },
    token: `Bearer ${state.sensitive.apiToken}`,
  }))(GetActivityByDocumentCheckListReq)
}
