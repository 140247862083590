import React from 'react'
import styled from 'styled-components'
import { Modal } from 'antd'

type Props = {
  url?: string
  onOk?: () => void
  onClose?: () => void
}
// `https://docs.google.com/gview?url=${url}&embedded=true`
const FileView = (props: Props) => {
  const { url, onOk, onClose } = props
  return (
    <Modal
      title=""
      footer=""
      visible={url ? true : false}
      className="document-view"
      onOk={onOk}
      onCancel={onClose}
      centered={false}
      closable={true}
      width="100%"
    >
      <Doc src={url} />
    </Modal>
  )
}

const Doc = styled.iframe`
  width: 100%;
  height: 800px;
`

export default FileView
