import React from 'react'
import { Row, Col } from 'antd'
import styled from 'styled-components'

import {
  BackBtn,
  SaveBtn,
  TrashWhiteBtn,
  ApproveBtn,
  RedoBtn,
  RedoDisableBtn,
  UndoBtn,
  UndoDisableBtn,
} from '../../../Shared/Button'

type Props = {
  edit: boolean
  onOk: () => void
  onBack: () => void
  onDelete: () => void
  onRedo?: () => void
  onUndo?: () => void
}

const TopMenu: React.ComponentType<Props> = props => {
  const { edit, onBack, onOk, onDelete, onUndo, onRedo } = props
  let rightBtn
  if (edit) {
    rightBtn = (
      <PageMenuRight>
        <TrashWhiteBtn onClick={onDelete} />
        <ApproveBtn onClick={onOk} />
      </PageMenuRight>
    )
  } else {
    rightBtn = (
      <PageMenuRight>
        <SaveBtn onClick={onOk} />
      </PageMenuRight>
    )
  }
  return (
    <PageMenu>
      <Row>
        <Col span={6}>
          <BackBtn onClick={onBack} />
        </Col>

        <Col span={12}>
          <PageMenuCenter>
            {onUndo ? <UndoBtn onClick={onUndo} /> : <UndoDisableBtn />}
            {onRedo ? <RedoBtn onClick={onRedo} /> : <RedoDisableBtn />}
          </PageMenuCenter>
        </Col>

        <Col span={6}>{rightBtn}</Col>
      </Row>
    </PageMenu>
  )
}

const PageMenu = styled.div`
  width: 100%;
  height: 64px;
  padding: 16px 24px 24px 24px;
`

const PageMenuCenter = styled.div`
  text-align: center;
  img {
    margin-left: 24px;
  }

  img:first-child {
    margin-left: 0px;
  }
`

const PageMenuRight = styled.div`
  text-align: right;

  img {
    margin-left: 24px;
  }

  img:first-child {
    margin-left: 0px;
  }
`

export default TopMenu
