import React, { useState, useEffect } from 'react'
import { Modal, Button, Row, Col } from 'antd'
import styled from 'styled-components'
import { SecondaryButton, PrimaryButton } from 'Components/Shared/Button'

type Props = {
  onOk: () => void
  visible?: boolean
  title?: string
  desc?: string
}

const WarningModal = (props: Props) => {
  const { visible = false, onOk, title = 'แจ้งเตือน', desc = '' } = props

  return (
    <Modal
      className="confirm-modal"
      title={false}
      centered
      visible={visible}
      footer={false}
      closable={false}
    >
      <Body>
        <Header>{title}</Header>
        <Description>
          <p>{desc}</p>
        </Description>
        <ButtonSection>
          <Row type="flex" justify="center">
            <Col>
              <div className="btn-submit">
                <PrimaryButton text="ตกลง" onClick={() => onOk()} />
              </div>
            </Col>
          </Row>
        </ButtonSection>
      </Body>
    </Modal>
  )
}

const Header = styled.div`
  text-align: center;
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 24px;
  color: #000;
`

const Description = styled.div`
  text-align: center;
  font-family: 'DB Helvethaica X 55 Regular';
  font-size: 20px;
  color: #000;
  margin-top: 16px;
  margin-bottom: 16px;
  white-space: pre-wrap;
`

const Body = styled.div`
  text-align: center;
`

const ButtonSection = styled.div`
  margin-top: 32px;
  .btn-submit {
    width: 132px;
    margin: 0 8px;
  }
`

export default WarningModal
