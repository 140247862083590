import { put, take, takeEvery, select } from 'redux-saga/effects'
import { APIPath } from 'services/APIPath'
import { getType } from 'typesafe-actions'
import * as actions from './activity.actions'
import { refreshTasklaneAction } from 'features/tasklane/tasklane.actions'
import {
  getSeeMoreQuerySelector,
  getShowMorePagesSelector,
  getActivityFilterSelector,
  getDataForSeeMoreKeyByActivityCodeSelector,
  getActivitySortingSelector,
} from './activity.selector'
import { Task, FetchTasklaneRequest } from 'features/tasklane/tasklane.types'
import { getDashboardQuerySelector } from 'features/tasklane/tasklane.selector'
import { fetchTasklanes } from 'features/tasklane/tasklane.api'
import {
  userEmailSelector,
  serviceTokenSelector,
} from '../sensitive/sensitive.selector'
import { getWbsPathMapSelector } from 'features/wbs/wbs.selector'
import { projectCodeWithSuffixSelector } from '../project/project.selector'
import {
  getActivitySeeMoreRequest,
  saveActivityRequest,
  uploadFileActivityRequest,
} from './activity.api'
import {
  convertDataToList,
  sortingTaskList,
  filterActivity,
} from './activity.function'
import keyByFieldName from 'utils/keyByFieldName'
import { dateCompare } from 'utils/helper'
import { toSaveFormat } from 'utils/datetime'

import { TaskById, FetchActivitiesRequest } from './activity.types'
import accountHelper from 'utils/account'

// Keys for map is type of activity for see-more page
// These keys should match with Tasktypes in TaskLaneColumn.tsx
// @see TaskLaneColumn.tsx, TaskLaneColumnProps
const map = new Map()
map.set('todo', 'ToDo')
map.set('in-progress', 'InProgress')
map.set('complete', 'Complete')

function* refreshTasklanes() {
  const UserEmail = yield select(userEmailSelector)
  const ProjectCode = yield select(projectCodeWithSuffixSelector)
  const token = yield select(serviceTokenSelector)
  const { date } = yield select(getDashboardQuerySelector)
  let path: string = APIPath.GetActivity
  let data: FetchTasklaneRequest = {
    UserEmail,
    ProjectCode,
  }
  if (date.start && date.end) {
    data.StartDate = toSaveFormat(date.start)
    data.FinishDate = toSaveFormat(date.end)
    data.Page = 1
    data.RowPerPage = 10
    path = APIPath.getActivityFilter
  }
  yield put(fetchTasklanes(path, token, data))
}

function* resetSubmitError() {
  yield put(actions.clearSubmitError())
}

function* watchSeeMoreRequest(action: any) {
  const userEmail = yield select(userEmailSelector)
  const projectCode = yield select(projectCodeWithSuffixSelector)

  if (projectCode === '-MS') {
    return
  }
  try {
    const { type, page, startDate, endDate, token } = action.payload
    const { rowPerPage, totalRows } = yield select(getShowMorePagesSelector)
    const totalPages = Math.ceil(totalRows / rowPerPage)

    let path = APIPath.getActivitySeeMore
    let data: FetchActivitiesRequest = {
      UserEmail: userEmail,
      ProjectCode: projectCode,
      Page: 1,
      RowPerPage: 1000,
    }

    if (startDate && endDate) {
      data.StartDate = toSaveFormat(startDate)
      data.FinishDate = toSaveFormat(endDate)
      path = APIPath.getActivityFilter
    }

    yield put(getActivitySeeMoreRequest(path, data, token))

    const seeMoreByTypeRequest = yield take(
      actions.fetchSeeMoreActivitiesAsyncRequest.success
    )
    const payload: FetchActivityResponse = seeMoreByTypeRequest.payload

    // Keys for map is type of activity for see-more page
    const key: string = map.get(type)

    const responseData: Task[] = payload.ResponseData[key]

    if (!responseData) {
      return
    }

    const wbsPathMap = yield select(getWbsPathMapSelector)
    const list = responseData.map(item => {
      item.WbsName = wbsPathMap[item.WbsID]
      return item
    })
    const keyedData: TaskById = keyByFieldName(list, 'ActivityID')
    const seeMoreQuery = {
      type,
      page,
      startDate,
      endDate,
    }

    yield put(actions.currentSeeMorePage({ query: seeMoreQuery }))

    if (responseData.length && keyedData) {
      yield put(
        actions.updateSeeMoreData({
          type,
          totalRows: responseData[0].TotalRows || 0,
          page,
          data: keyedData,
        })
      )
    }

    yield put(actions.seeMoreDataFetched(true))
  } catch (error) {
    console.log(error)
  }
}

function* watchFilterActivity(action: any) {
  const query = yield select(getSeeMoreQuerySelector)
  const sorting = yield select(getActivitySortingSelector)
  const filter = yield select(getActivityFilterSelector)
  const seemore = yield select(getDataForSeeMoreKeyByActivityCodeSelector)
  let startDate = filter.date.start
  let endDate = filter.date.end
  if (
    dateCompare(startDate, query.startDate) &&
    dateCompare(endDate, query.endDate)
  ) {
    let list = convertDataToList(seemore.data)
    list = filterActivity(list, filter)
    list = sortingTaskList(list, sorting.type, sorting.name)

    yield put(actions.setSeemoreActivity({ type: query.type, list }))
  } else {
    const token = accountHelper.getTokenCode()
    yield put(
      actions.fetchSeeMoreByType({
        type: query.type,
        page: 1,
        startDate,
        endDate,
        token,
      })
    )
  }
}

function* sendRequestActivity(action: any) {
  const { req, original, token } = action.payload
  try {
    // upload images
    if (original.Images.length > 0) {
      let formData = new FormData()
      formData.set('UserEmail', req.UserEmail)
      original.Images.forEach((item: any) =>
        formData.append('Files', item.file)
      )

      yield put(uploadFileActivityRequest(formData, token))
      const resp = yield take(actions.uploadFileAsyncRequest.success)
      req.Images = resp.payload.ResponseData
    }

    // upload files
    if (original.Documents.length > 0) {
      let formData = new FormData()
      formData.set('UserEmail', req.UserEmail)
      original.Documents.forEach((item: any) =>
        formData.append('Files', item.file)
      )

      yield put(uploadFileActivityRequest(formData, token))
      const resp = yield take(actions.uploadFileAsyncRequest.success)
      req.Documents = resp.payload.ResponseData
    }

    yield put(saveActivityRequest(req, token))
  } catch (e) {}
}

function* allSaga() {
  yield takeEvery(
    getType(actions.saveActivityDetailAsyncRequest.success),
    refreshTasklanes
  )

  yield takeEvery(getType(actions.submitRequestActivity), sendRequestActivity)

  yield takeEvery(getType(refreshTasklaneAction), refreshTasklanes)
  yield takeEvery(getType(actions.resetSubmitErrorAction), resetSubmitError)

  yield takeEvery(getType(actions.filterActivity), watchFilterActivity)
  yield takeEvery(getType(actions.fetchSeeMoreByType), watchSeeMoreRequest)
}

export default allSaga
