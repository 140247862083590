import { createAsyncAction, createStandardAction } from 'typesafe-actions'

export const sampleProjectAction = createStandardAction(
  'SAMPLE_PROJECT_ACTION'
)<undefined>()

export const projectsEmpty = createStandardAction(
  'project/NO_PROJECTS_AVAILABLE'
)<boolean>()

export const fetchProjectAsyncAction = createAsyncAction(
  'PROJECT/FETCH_PROJECT_LIST_REQUEST',
  'PROJECT/FETCH_PROJECT_LIST_SUCCESS',
  'PROJECT/FETCH_PROJECT_LIST_FAILURE'
)<any, ApiResponse, any>()
