import React from 'react'
import styled from 'styled-components'
import { numberFormat } from 'utils/format'

interface Props {
  checkStatus: string
  score: number
}

const TaskScore: React.FC<Props> = ({ checkStatus, score }) => {
  const isPass =
    checkStatus === 'Pass' ||
    checkStatus === 'PassToFix' ||
    checkStatus === 'PassByFunc' ||
    checkStatus === 'NotInspection'
  return isPass ? (
    <PassScore>{numberFormat(score)} คะแนน</PassScore>
  ) : (
    <FailScore> {numberFormat(score)} คะแนน</FailScore>
  )
}

const Score: any = styled.p`
  font-size: 34px;
  margin-bottom: 4px;
  font-family: 'DB Helvethaica X 75 Bd';
`

const PassScore = styled(Score)`
  color: #63cea6;
`

const FailScore = styled(Score)`
  color: #d0494c;
`

export default TaskScore
