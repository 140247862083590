import React from 'react'
import { Col } from 'antd'
import styled from 'styled-components'
import { TableRow } from '../../Shared/Table'
import { ProjectListData } from 'features/pmr/types'

type DefaultProps = {
  task: ProjectListData
}
type Props = DefaultProps

const RowItem: React.FC<Props> = props => {
  const { task } = props

  return (
    <TableRow minHeight={60} paddingLeft={24} spaceBetweenRow={8}>
      <Col span={6}>
        {task.ProjectCode}-{task.ProjectNameTH}
      </Col>
      <Col span={6}>{task.Unit}</Col>
      <Col span={6}>{task.Floor}</Col>
      <Col span={6}>{task.TotalDefect}</Col>
    </TableRow>
  )
}

const TextGreen = styled.div`
  color: #5bc79e;
`

const TextRed = styled.div`
  color: #d0494c;
`

export default RowItem
