import React, { useState } from 'react'
import { Row, Col } from 'antd'
import styled from 'styled-components'
import { Checkbox } from 'Components/Shared/Form'
import { ModuleItem } from 'features/admin/user/types'
import { modulesConfig } from 'utils/module-map'

type Props = {
  span?: number
  ModuleList?: ModuleItem[]
  onSave?: (ModuleList: ModuleItem[]) => void
  onSelectModule?: (moduleItem: any) => void
  activeModule?: ModuleItem
}

const ModuleBox: React.FunctionComponent<Props> = props => {
  const {
    span = 8,
    ModuleList = [],
    onSave = () => {},
    onSelectModule = () => {},
    activeModule,
  } = props

  const isSelected = (val: number) => {
    const hasModule = ModuleList.find(item => item.ModuleID === val)
    return hasModule ? true : false
  }

  const remove = (val: number) => {
    const modules = ModuleList.filter(item => item.ModuleID !== val)
    return modules
  }

  const onSelect = (val: number) => {
    let modules = ModuleList
    if (isSelected(val)) {
      modules = remove(val)
      onSelectModule({})
    } else {
      modules.push({
        ModuleID: val,
        CanAccessAll: false,
        ProjectList: [],
      })
      findSelectedModule(val)
    }
    onSave(modules)
  }

  const findSelectedModule = (moduleID: number) => {
    const selectModule = ModuleList.find(item => item.ModuleID === moduleID)
    if (selectModule) {
      onSelectModule(selectModule)
    } else {
      onSelectModule({})
    }
  }

  const countProject = (moduleID: number) => {
    let count = '' as any
    const module = ModuleList.find(item => item.ModuleID === moduleID)
    if (module) {
      count =
        module.ProjectList && module.ProjectList.length > 0
          ? module.ProjectList.length
          : ''
      if (module.CanAccessAll) {
        count = '*'
      }
    }
    return count
  }

  return (
    <Module>
      <Title>Module ที่ใช้งาน</Title>
      <Desc>กรุณาเลือก “Module”</Desc>
      <Row>
        {modulesConfig.map((item, index) => {
          const isActive = activeModule && activeModule.ModuleID === item.value
          return (
            <Col span={span} key={index}>
              <CheckboxArea>
                <Row type="flex" justify="start" align="middle">
                  <Col>
                    <Checkbox
                      label={''}
                      value={isSelected(item.value)}
                      onChange={() => onSelect(item.value)}
                    />
                  </Col>
                  <Col>
                    <CheckboxTxt
                      className={isActive ? 'active' : ''}
                      onClick={() => findSelectedModule(item.value)}
                    >
                      <Row type="flex" justify="space-between">
                        <Col>{item.name}</Col>
                        <Col>{countProject(item.value)}</Col>
                      </Row>
                    </CheckboxTxt>
                  </Col>
                </Row>
              </CheckboxArea>
            </Col>
          )
        })}
      </Row>
    </Module>
  )
}

const Title = styled.div`
  color: black;
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 20px;
`

const Desc = styled.div`
  color: #8b99a7;
  font-family: 'DB Helvethaica X 55 Regular'
  font-size: 20px;
`

const CheckboxArea = styled.div`
  padding: 8px 0;
`
const CheckboxTxt = styled.div`
  width: 200px;
  font-family: 'DB Helvethaica X 55 Regular';
  font-size: 20px;
  color: #000;
  padding: 2px 10px;
  cursor: pointer;
  &.active {
    border-radius: 5px;
    border: solid 1px #003168;
  }
`

const Module = styled.div`
  font-family: 'DB Helvethaica X 55 Regular';
  font-size: 20px;
  color: #000;
`

export default ModuleBox
