import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Row, Col } from 'antd'

import InputNumber from 'Components/Shared/Input/InputNumber'
import { CheckListDetail } from 'features/checklist/auditPlan/types'
import ContentBox from 'Components/Shared/Card/ContentBox'

type PropsTime = {
  title: string
  disabled?: boolean
  onChange: (val: number) => void
  value: number
}
const TimeItem = ({ title, disabled, value, onChange }: PropsTime) => {
  const [num, setNum] = useState(0)

  useEffect(() => {
    setNum(value)
  }, [value])

  const change = (val: number) => {
    onChange(val)
    setNum(val)
  }

  return (
    <Item>
      <Row type="flex" align="middle" justify="start">
        <Col span={10}>{title}</Col>
        <Col>
          <Row type="flex" align="middle" justify="start">
            <Col>
              <ScoreInput>
                <InputNumber
                  value={num}
                  onChange={change}
                  disabled={disabled}
                />
              </ScoreInput>
            </Col>
            <Col style={{ padding: 16 }}>วัน</Col>
          </Row>
        </Col>
      </Row>
    </Item>
  )
}

type PropsScore = {
  title: string
  min: number
  max: number
  onMin: (val: number) => void
  onMax: (val: number) => void
  disabled: boolean
}

const ScoreItem = ({
  title,
  min: minProps,
  max: maxProps,
  onMin: onMinProps,
  onMax: onMaxProps,
  disabled,
}: PropsScore) => {
  const [min, setmin] = useState<number>(minProps)
  const [max, setmax] = useState<number>(maxProps)

  useEffect(() => {
    setmin(minProps)
  }, [minProps])

  useEffect(() => {
    setmax(maxProps)
  }, [maxProps])

  const onMin = (val: number) => {
    onMinProps(val)
    setmin(val)
  }

  const onMax = (val: number) => {
    onMaxProps(val)
    setmax(val)
  }

  return (
    <Item>
      <Row type="flex" align="middle" justify="start">
        <Col span={10}>{title}</Col>
        <Col>
          <Row type="flex" align="middle" justify="start">
            <Col>
              <ScoreInput>
                <InputNumber value={min} onChange={onMin} disabled={disabled} />
              </ScoreInput>
            </Col>
            <Col>
              <BetweenScore>-</BetweenScore>
            </Col>
            <Col>
              <ScoreInput>
                <InputNumber value={max} onChange={onMax} disabled={disabled} />
              </ScoreInput>
            </Col>
          </Row>
        </Col>
      </Row>
    </Item>
  )
}

type Props = {
  checkListDetail: CheckListDetail
  disabled: boolean
}

const ScoreBox: React.ComponentType<Props> = ({
  checkListDetail,
  disabled,
}) => {
  const onNumberReturn = (val: number) => {
    checkListDetail.NumOfReturnDate = val
  }

  return (
    <ContentBox title="ตั้งค่าทั่วไป" hasSearch={false}>
      <Content>
        <TimeItem
          title="ระยะเวลาตอบกลับภายใน"
          value={checkListDetail.NumOfReturnDate}
          onChange={onNumberReturn}
          disabled={disabled}
        />
      </Content>
    </ContentBox>
  )
}

const Content = styled.div`
  padding: 0 24px;
`

const Item = styled.div`
  width: 100%;
  font-size: 20px;
  color: #000;
  padding-top: 16px;
`

const ScoreInput = styled.div`
  width: 67px;
  height: 48px;
  .input-title .ant-input {
    text-align: center;
  }
`

const BetweenScore = styled.div`
  padding: 0 16px;
  height: 40px;
`

export default ScoreBox
