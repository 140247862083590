import React from 'react'
import styled from 'styled-components'

interface Props {
  text?: string
}

const NoCard: React.FC<Props> = props => {
  const { text = 'ไม่มีรายการคำขอ' } = props
  return <MainCard>{text}</MainCard>
}

const MainCard = styled.div`
  background: #fff;
  height: 60px;
  border-radius: 10px;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.03);
  color: black;
  width: 100%;
  font-size: 18px;
  padding: 16px;
`

export default NoCard
