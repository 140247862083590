import React from 'react'
import { Tab } from 'Components/Shared'

interface Props {
  selected: 'Master Plan' | 'Maincon'
}

const ApprovalTab: React.FunctionComponent<Props> = props => {
  const tabItem = [
    {
      link: '/ms/list',
      title: 'Master Plan',
      isActive: props.selected == 'Master Plan',
    },
    {
      link: '/list',
      title: 'Maincon',
      isActive: props.selected == 'Maincon',
    },
  ]

  return <Tab tabItem={tabItem} />
}

export default ApprovalTab
