import React, { useState } from 'react'
import { Input, Row, Col, Icon } from 'antd'
import styled from 'styled-components'
import imgAdd from 'assets/img/ic-add.png'
import { EditIcon, TrashIcon } from 'Components/Shared/Icon'

import AddModal from './AddModal'
import DeleteModal from './DeleteModal'

interface Props {
  text: string
  index?: number
  onDelete: (i: number) => void
  onEdit: (i: number, text: string) => void
  readOnly?: boolean
}

const Item: React.FC<Props> = ({
  text,
  index = -1,
  onDelete,
  onEdit,
  readOnly = false,
}) => {
  const [visibleEdit, setVisibleEdit] = useState(false)
  const [visibleDel, setVisibleDel] = useState(false)
  const onEditItem = () => setVisibleEdit(true)
  const onDelItem = () => setVisibleDel(true)
  const closeEdit = () => setVisibleEdit(false)
  const closeDel = () => setVisibleDel(false)

  const confirmEdit = (val: string) => {
    onEdit(index, val)
    closeEdit()
  }

  const confirmDelete = () => {
    onDelete(index)
    closeDel()
  }

  return (
    <CheckList>
      <Row>
        <Col span={20}>
          <TextItem>{text}</TextItem>
        </Col>

        <Col span={4} className="icon-action">
          <LineBtn>
            <HoverIcon onClick={onEditItem}>
              <EditIcon color="#8b99a7" size={16} />
            </HoverIcon>
            {readOnly === false && (
              <HoverIcon onClick={onDelItem}>
                <TrashIcon color="#8b99a7" size={16} />
              </HoverIcon>
            )}
          </LineBtn>
        </Col>
      </Row>
      <AddModal
        text={text}
        visible={visibleEdit}
        onOk={confirmEdit}
        onClose={closeEdit}
        isEditMode={true}
        readOnly={readOnly}
      />

      <DeleteModal
        name={text}
        visible={visibleDel}
        onClose={closeDel}
        onOk={confirmDelete}
      />
    </CheckList>
  )
}

const CheckList = styled.div`
  margin-top: 8px;
  height: 56px;
  border-radius: 5px;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.03);
  background-color: #ffffff;
  font-size: 20px;
  padding: 12px 25px;
  border: 1px solid #eeeff2;
  font-family: 'DB Helvethaica X 75 Bd';

  &.add {
    font-family: 'DB Helvethaica X 75 Bd';
    color: #003168;
    cursor: pointer;
    img {
      width: 16px;
      margin-right: 13px;
      margin-top: -5px;
    }
  }
  .icon-action {
    display: none;
    cursor: pointer;
  }
  :hover .icon-action {
    display: block;
  }
`

const TextItem = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
`

const LineBtn = styled.div`
  display: inline-flex;
`

const HoverIcon = styled.div`
  padding: 2px 9px;
`

export default Item
