import React, { useEffect, useState, useCallback } from 'react'
import { connect, useDispatch } from 'react-redux'
import { RootState } from 'typings/RootState'
import { Layout, Row, Col, Spin, Icon } from 'antd'
import { RouteComponentProps } from 'react-router'
import { translate } from 'i18n'
import TKeys from 'i18n/translationKey'

import SubnavBar from '../../Shared/SubnavBar'
import { BreadcrumbLink } from '../../Shared/BreadCrumb/BreadCrumb'
import { lookup } from '../../../lookup'
import CardList from 'Components/cards/CardList/CardList'
import CardListHeader, {
  CardListHeaderItem,
} from '../../cards/CardList/CardListHeader'
import {
  fetchSeeMoreByType,
  updatePaginationShowMorePages,
  sortingActivity,
  setFilterActivity,
} from 'features/activity/activity.actions'

import {
  getDataForSeeMoreSelector,
  getSeeMoreQuerySelector,
  getActivitySortingSelector,
  getRowPerPageSelector,
  isLoadingSelector,
} from 'features/activity/activity.selector'
import { serviceTokenSelector } from 'features/sensitive/sensitive.selector'
import { selectedProjectCodeSelector } from 'features/selectedProject/selectedProject.selector'

import Pagination from '../../links/Pagination'

import { Task, TasklaneType } from 'features/tasklane/tasklane.types'
import { SeeMoreQuery } from 'features/activity/activity.types'

import PaginationShowMoreLink from './PaginationShowMore'
import FilterPage from './FilterPage'

const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />

const { Content } = Layout

type Props = ReduxProps &
  DispatchProps &
  RouteComponentProps<{ type: TasklaneType; page: string }>

const SeeMorePage: React.FunctionComponent<Props> = ({
  match,
  rows,
  totalRows,
  isSubmitting,
  isLoading,
  rowPerPage,
  paginationShowItemsPerPage,
  sorting,
  isSeemoreFetched,
  updatePaginationShowMorePages,
  history,
  projectCode,
  token,
}) => {
  const { params } = match
  const { type, page = 1 } = params

  const getIcon = (
    name: string | undefined,
    type: string,
    colName: string
  ): string => {
    if (name === colName) {
      return type !== 'desc' ? '/img/ic-arrow-actions.png' : '/img/ic-down.png'
    }

    return '/img/ic-sort.svg'
  }

  let headerTitle = lookup[type]
  let breadcrumbs: BreadcrumbLink[] = [
    {
      url: '/dashboard',
      title: 'Home',
      icon: '/home.png',
      active: true,
      showTitle: false,
    },
    {
      url: '/dashboard',
      title: translate(TKeys.Breadcrumb.dashboard),
      active: true,
      showTitle: true,
    },
    {
      url: `/see-more/type/${type}/page/${page}`,
      title: headerTitle,
      active: false,
      showTitle: true,
    },
  ]

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(
      sortingActivity({
        name: type === 'todo' ? 'actual_start_date' : 'actual_end_date',
        sort: 'asc',
      })
    )
  }, [dispatch])

  useEffect(() => {
    dispatch(
      setFilterActivity({
        progress: [],
        status: [],
        wbs: [],
        date: {
          mode: '',
          start: undefined,
          end: undefined,
        },
      })
    )
  }, [dispatch, type])

  useEffect(() => {
    if (projectCode && projectCode !== '') {
      console.log('page ', page)
      dispatch(fetchSeeMoreByType({ type, page: Number(page), token }))
    }
  }, [dispatch, type, page, projectCode, token])

  const onSort = (name: string) => {
    dispatch(sortingActivity({ name }))
  }

  const tasklaneType = type as TasklaneType
  const onShowMorePages = (value: number) => {
    history.push('./1')
    dispatch(
      updatePaginationShowMorePages({
        rowPerPage: value,
        totalRows: totalRows,
      })
    )
  }

  const countdown = type === 'todo' ? 'countdown-todo' : 'countdown'
  const headerItems = [
    {
      title: 'Activity',
      span: 6,
      onClick: () => onSort('activity_title'),
      img: getIcon(sorting.name, sorting.type, 'activity_title'),
    } as CardListHeaderItem,
    {
      title: 'หมวดหมู่',
      span: 4,
      onClick: () => onSort('wbs'),
      img: getIcon(sorting.name, sorting.type, 'wbs'),
    } as CardListHeaderItem,
    {
      title: 'วันเริ่มงาน',
      span: 2,
      onClick: () => onSort('actual_start_date'),
      img: getIcon(sorting.name, sorting.type, 'actual_start_date'),
    } as CardListHeaderItem,
    {
      title: 'วันสิ้นสุดงาน',
      span: 3,
      onClick: () => onSort('actual_end_date'),
      img: getIcon(sorting.name, sorting.type, 'actual_end_date'),
    } as CardListHeaderItem,
    {
      title: 'กำหนดการ',
      span: 4,
      onClick: () => onSort(countdown),
      img: getIcon(sorting.name, sorting.type, countdown),
    } as CardListHeaderItem,
    {
      title: 'ความคืบหน้า',
      span: 3,
      onClick: () => onSort('progress'),
      img: getIcon(sorting.name, sorting.type, 'progress'),
    } as CardListHeaderItem,
    {
      title: 'สถานะ',
      span: 2,
      onClick: () => onSort('status'),
      img: getIcon(sorting.name, sorting.type, 'status'),
    } as CardListHeaderItem,
  ]

  // By default show Todo header title on see more page
  if (['todo', 'in-progress', 'complete'].includes(type) === false) {
    headerTitle = lookup['todo']
  }

  const total: number = Number(rows.length)
  const start: number = (+page - 1) * rowPerPage
  const end: number = start + rowPerPage
  const dataList: any = rows.slice(start, end)
  return (
    <>
      <SubnavBar breadcrumbs={breadcrumbs} type={type} />
      <div className="mid-content">
        {isLoading && (
          <div className="loading-content">
            <Spin indicator={antIcon} tip="Loading..." />
          </div>
        )}
        {!isLoading && (
          <div className="see-more-page">
            <div className="main-content">
              <FilterPage history={history} />

              <Row className="content-header">
                <h1 className="page-title">{headerTitle}</h1>
                <Pagination
                  currentPage={Number(page)}
                  totalRows={total}
                  perPage={rowPerPage}
                  type={tasklaneType}
                />
              </Row>

              <Row className="search-reults" gutter={40}>
                <Col span={24}>
                  <CardListHeader items={headerItems} />
                </Col>
              </Row>
            </div>
            <Content
              style={{
                minHeight: '300px',
                maxHeight: '600px',
                overflow: 'auto',
              }}
            >
              <div className="main-content">
                <CardList
                  key={Math.random()}
                  type={type}
                  page={Number(page)}
                  items={dataList}
                  totalRows={total}
                />
                <Row className="content-footer">
                  <PaginationShowMoreLink
                    totalRows={total}
                    defaultValue={rowPerPage}
                    choices={paginationShowItemsPerPage}
                    onChange={onShowMorePages}
                  />
                  <Pagination
                    currentPage={Number(page)}
                    totalRows={total}
                    perPage={rowPerPage}
                    type={tasklaneType}
                  />
                </Row>
              </div>
            </Content>
          </div>
        )}
      </div>
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  const data = getDataForSeeMoreSelector(state) as {
    list: Task[]
    totalRows: number
  }

  return {
    rows: data.list,
    totalRows: data.totalRows,
    paginationQuery: getSeeMoreQuerySelector(state),
    isSubmitting: state.activity.isSubmitting,
    isLoading: isLoadingSelector(state),
    rowPerPage: getRowPerPageSelector(state),
    paginationShowItemsPerPage: [10, 20, 50, 100, 500],
    sorting: getActivitySortingSelector(state),
    isSeemoreFetched: state.activity.seeMoreDataFetch,
    projectCode: selectedProjectCodeSelector(state),
    token: serviceTokenSelector(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {
  updatePaginationShowMorePages,
}

type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(SeeMorePage)
