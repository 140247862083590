import React, { useState, useEffect } from 'react'
import { Input } from 'antd'
import styled from 'styled-components'
import icSearch from 'assets/img/ic-search-grey.png'

interface Props {
  value?: string
  onChange: (val: string) => void
}

const SearchSelection: React.FC<Props> = props => {
  const [txt, settxt] = useState('')

  useEffect(() => {
    if (props.value != null) {
      settxt(props.value)
    }
  }, [props.value])

  const onChange = (evt: any) => {
    settxt(evt.target.value)
    props.onChange(evt.target.value)
  }

  return (
    <InputSearch>
      <Input
        placeholder="ค้นหา"
        suffix={<img src={icSearch} />}
        onChange={onChange}
        value={txt}
      />
    </InputSearch>
  )
}

const InputSearch = styled.div`
  .ant-input-affix-wrapper {
    .ant-input {
      height: 48px;
      border-radius: 5px;
      font-size: 18px;
      font-family: 'DB Helvethaica X 55 Regular';
    }
  }

  .ant-input-affix-wrapper > .ant-input-suffix {
    img {
      width: 16px;
      height: 16px;
    }
  }
`

export default SearchSelection
