import RootState from 'typings/RootState'
import { Event } from '../../types/event'
import { CommissioningImageResp, ImageItem } from './types'

export const selectEvent = (state: RootState): Event =>
  state.reportCommission.event

export const selectImgEvent = (state: RootState): Event =>
  state.reportCommission.eventImg

export const selectCheckListQC = (state: RootState) =>
  state.reportCommission.checkListQC

export const selectSelectedImage = (state: RootState): CommissioningImageResp =>
  state.reportCommission.selectedImage

export const selectOriginalImageList = (state: RootState): ImageItem[] =>
  state.reportCommission.originalImageList
