import React, { useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import RootState from 'typings/RootState'
import { BreadcrumbLink } from 'Components/Shared/BreadCrumb/BreadCrumb'
import { selectedProjectCodeSelector } from 'features/project/project.selector'
import {
  userEmailSelector,
  serviceTokenSelector,
} from 'features/sensitive/sensitive.selector'
import {
  selectData,
  selectEvent,
  selectSorting,
  selectProgress,
} from 'features/cmpj/road/construct/checking/selector'
import { getCheckingConstructRoadByCM } from 'features/cmpj/road/construct/checking/api'
import {
  GetActivityReq,
  CheckingList,
} from 'features/cmpj/road/construct/checking/types'
import {
  sortingTask,
  sendMultiReport,
} from 'features/cmpj/road/construct/checking/actions'
import { StructureListPage } from 'Components/Shared/Seemore'
import { NoCard, EventModal, LoadingProgress } from 'Components/Shared'
import RowItem from './RowItem'
import TaskFooter from './TaskFooter'
import TableHeader from './TableHeader'
import Pagination from 'Components/Shared/Pagination'

type Props = ReduxProps & DispatchProps

const CmRoadCreateRequest: React.ComponentType<Props> = props => {
  const {
    data,
    event,
    sorting,
    token,
    ProjectCode,
    UserEmail,
    progress,
  } = props

  const [taskSelect, setTaskSelect] = useState([] as any[])
  const [enableModal, setEnableModal] = useState(false)
  const { List, Total } = data
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)

  const dispatch = useDispatch()

  useEffect(() => {
    callGetActivitySeeMoreForQC()
    setTaskSelect([])
    setEnableModal(false)
  }, [ProjectCode, page, perPage])

  useEffect(() => {
    dispatch(sortingTask({ name: 'CheckNumber', sort: 'desc' }))
  }, [])

  const callGetActivitySeeMoreForQC = () => {
    const data: GetActivityReq = {
      ProjectCode,
      UserEmail,
      Page: page,
      RowPerPage: perPage,
      QCTypeID: [],
    }
    dispatch(getCheckingConstructRoadByCM(token, data))
  }

  const onSendMultireport = () => {
    setEnableModal(true)
    const data = {
      Token: token,
      List: taskSelect,
    }
    dispatch(sendMultiReport(data))
  }

  let title = `สร้างคำขอ`

  const breadcrumbs: BreadcrumbLink[] = [
    {
      url: '/construct-road',
      title: 'Home',
      icon: '/home.png',
      active: true,
      showTitle: false,
    },
    {
      url: '/construct-road',
      title: 'ถนน',
      active: true,
      showTitle: true,
    },
    {
      url: '/',
      title: title,
      active: false,
      showTitle: false,
    },
  ]

  const onSort = (name: string) => {
    dispatch(sortingTask({ name }))
  }

  useEffect(() => {
    setEnableModal(false)
  }, [List])

  const renderContent = () => {
    return List.map((item: CheckingList, index) => {
      const isSelect = verifySelected(item)
      return (
        <RowItem
          task={item}
          key={index}
          onClick={() => onSelectTask(item)}
          isSelected={isSelect}
        />
      )
    })
  }

  const remove = (data: CheckingList) => {
    return taskSelect.filter(
      item =>
        item.Unit !== data.Unit ||
        item.QCTypeID !== data.QCTypeID ||
        item.CheckNumber !== data.CheckNumber
    )
  }

  const verifySelectAll = () => {
    let isSelectAll = true
    List.map(item => {
      const isSelect = verifySelected(item)
      if (!isSelect) {
        isSelectAll = false
        return
      }
    })
    return isSelectAll
  }

  const verifySelected = (data: CheckingList) => {
    const selectData = taskSelect.filter(item => {
      return (
        item.Unit === data.Unit &&
        item.QCTypeID === data.QCTypeID &&
        item.CheckNumber === data.CheckNumber
      )
    })
    return selectData.length > 0 ? true : false
  }

  const pushValue = (item: CheckingList) => {
    return {
      ProjectCode,
      UserEmail,
      Unit: item.Unit,
      QCTypeID: item.QCTypeID,
      CheckNumber: item.CheckNumber,
    }
  }

  const onSelectTask = (item: CheckingList) => {
    const isSelect = verifySelected(item)
    let newValue = taskSelect
    if (isSelect) {
      newValue = remove(item)
    } else {
      newValue.push(pushValue(item))
    }
    setTaskSelect([...newValue])
  }

  const onSelectAll = () => {
    const isSelectAll = verifySelectAll()
    let newValue = [] as any
    if (isSelectAll) {
      newValue = []
    } else {
      List.map(task => {
        newValue.push(pushValue(task))
      })
    }
    setTaskSelect([...newValue])
  }

  const tableHeader = (
    <TableHeader
      onSort={name => onSort(name)}
      sorting={sorting}
      isSelectAll={verifySelectAll()}
      onSelectAll={onSelectAll}
      showRadio={data.List.length > 0}
    />
  )

  const footer = (
    <TaskFooter
      taskSelect={taskSelect}
      onClick={onSendMultireport}
      isLoading={event.IsLoading}
      onSelectAll={onSelectAll}
      isSelectAll={data.List.length > 0 && verifySelectAll()}
    />
  )

  const calback = () => {
    setEnableModal(false)
    setTaskSelect([])
    callGetActivitySeeMoreForQC()
  }

  const pagination = (
    <Pagination
      perPage={perPage}
      totalRows={Total}
      currentPage={page}
      onClickPage={page => setPage(page)}
      onChangeNumber={number => setPerPage(number)}
    />
  )

  return (
    <>
      <EventModal
        event={[event]}
        enable={enableModal}
        successCalback={calback}
        errorCalback={calback}
      />
      <LoadingProgress
        isShow={progress.show}
        success={progress.success}
        total={progress.total}
      />
      <StructureListPage
        breadcrumbs={breadcrumbs}
        tableHeader={tableHeader}
        titleMargin={32}
        tableContent={
          data.List.length > 0 ? (
            renderContent()
          ) : (
            <NoCard text={`ไม่มีข้อมูล`} />
          )
        }
        title={title}
        footer={footer}
        footerPagination={pagination}
      />
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    data: selectData(state),
    event: selectEvent(state),
    sorting: selectSorting(state),
    token: serviceTokenSelector(state),
    ProjectCode: selectedProjectCodeSelector(state),
    UserEmail: userEmailSelector(state),
    progress: selectProgress(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {
  sortingTask,
}

type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(CmRoadCreateRequest)
