import React from 'react'
import { Layout } from 'antd'
import styled from 'styled-components'
import { TaskStatus, MainConChecklist } from 'Components/Activity'
import { MainconCheckList } from 'features/types/checklist'
import { MainconTask } from 'features/maincon/activity/types'
import TaskDetail from './TaskDetail'

type Props = {
  onImageOpen(list?: any[], readOnly?: boolean): void
  onDocumentOpen(list?: any[], readOnly?: boolean): void
  onThumbnailOpen(index: number, list?: any[], readOnly?: boolean): void
  originalData: MainconTask
  checkListData: MainconCheckList
  onHistoryOpen?: () => void
  isLoading?: boolean
  wbs?: any
  onReturnTask: () => void
}

const { Content, Footer } = Layout

const ActivityDetail: React.FunctionComponent<Props> = ({
  onImageOpen,
  onDocumentOpen,
  onThumbnailOpen,
  originalData,
  checkListData,
  onHistoryOpen,
  wbs,
  onReturnTask,
}) => {
  const cmStatusContent = (
    <TaskStatus
      originalTask={originalData}
      onDocumentOpen={onDocumentOpen}
      onImageOpen={onImageOpen}
      onThumbnailOpen={onThumbnailOpen}
    />
  )

  let readOnly = true
  let taskStatus = cmStatusContent
  const images = originalData.McImages
  const documents = originalData.McDocuments

  return (
    <>
      <Content className="mid-content">
        <div className="main-content activity-detail">
          <ActivityDetailArea>
            <TaskDetail
              isSubmitting={false}
              onImageOpen={() => onImageOpen(images, readOnly)}
              onDocumentOpen={() => onDocumentOpen(documents, readOnly)}
              onThumbnailOpen={(index: number) =>
                onThumbnailOpen(index, images, readOnly)
              }
              originalData={originalData}
              onHistoryOpen={onHistoryOpen}
              readOnly={readOnly}
              wbs={wbs}
              onReturnTask={onReturnTask}
            />
            {taskStatus}
            <MainConChecklist
              checkListData={checkListData}
              hideStatus={false}
            />
          </ActivityDetailArea>
        </div>
      </Content>
    </>
  )
}

const ActivityDetailArea = styled.div`
  .box-section {
    background-color: #fff;
    margin-top: 12px;
    border-radius: 5px;
    border: solid 1px #f2f2f2;
    padding: 24px;
    line-height: normal;
    &.no-space {
      padding: 0;
      margin-top: 0px;
    }
    .title {
      font-size: 20px;
      font-family: 'DB Helvethaica X 75 Bd';
    }
    .description {
      font-size: 20px;
    }
  }
  .space {
    margin-top: 24px;
  }
`

export default ActivityDetail
