import withAPIAction from 'utils/withAPIAction'
import { verifyReq } from 'utils/verify'
import { getActivityAction } from './actions'
import { GetActivityRequest } from './types'

export const getActivityForCM = (token: string, data: GetActivityRequest) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<GetActivityRequest>({
    method: 'POST',
    path: 'GetActivityForCM',
    data,
    token: `Bearer ${token}`,
  })(getActivityAction)
}
