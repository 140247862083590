import React, { useState } from 'react'
import { Row, Col } from 'antd'
import styled from 'styled-components'
import { TableHeader as Table, TableColHeader } from 'Components/Shared/Table'
import { CheckedIcon, UncheckedIcon } from 'Components/Shared/Icon'
import { RadioBox } from 'Components/Shared/Form'

type Props = {
  onSort(name: string): void
  sorting: any
  mode: 'PMR' | 'CM' | 'QC'
}

const ReportHeader: React.FunctionComponent<Props> = props => {
  const { onSort, sorting, mode } = props

  const column = [
    {
      title: 'หมุดที่',
      span: 2,
      key: 'PinNumber',
    },
    {
      title: 'ลักษณะงานหลัก',
      span: 5,
      key: 'JobTypeName',
    },
    {
      title: 'ลักษณะงานรอง',
      span: 5,
      key: 'SubJobTypeName',
    },
    {
      title: 'รายละเอียด',
      span: 5,
      key: 'DetailName',
    },
    {
      title: 'ตรวจครั้งที่',
      span: 2,
      key: 'DefectCheck',
    },
    {
      title: '',
      span: 4,
      key: '',
      sort: false,
    },
  ]

  const qcColumn = [
    {
      title: 'หมุดที่',
      span: 2,
      key: 'PinNumber',
    },
    {
      title: 'ลักษณะงานหลัก',
      span: 4,
      key: 'JobTypeName',
    },
    {
      title: 'ลักษณะงานรอง',
      span: 4,
      key: 'SubJobTypeName',
    },
    {
      title: 'รายละเอียด',
      span: 4,
      key: 'DetailName',
    },
    {
      title: 'กลุ่ม Defect',
      span: 3,
      key: 'FixReason',
    },
    {
      title: 'ตรวจครั้งที่',
      span: 2,
      key: 'DefectCheck',
    },
    {
      title: '',
      span: 4,
      key: '',
      sort: false,
    },
  ]

  const useColumn = mode === 'QC' ? qcColumn : column

  const getType = (colName: string) => {
    if (sorting.name === colName) {
      return sorting.type
    }
    return ''
  }

  const content = useColumn.map((item: any, index: number) => {
    return (
      <TableColHeader
        key={index}
        span={item.span}
        onSort={() => onSort(item.key)}
        type={getType(item.key)}
        sort={item.sort}
        isPadding={index === 0}
      >
        {item.title}
      </TableColHeader>
    )
  })

  return <Table>{content}</Table>
}

export default ReportHeader
