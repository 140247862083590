import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { connect, useDispatch } from 'react-redux'
import { Row, Col, Switch } from 'antd'
import { RouteComponentProps } from 'react-router-dom'
import RootState from 'typings/RootState'
import SubnavBar from 'Components/Shared/SubnavBar'
import { BreadcrumbLink } from 'Components/Shared/BreadCrumb/BreadCrumb'
import Menu from '../../MenuAdmin'
import {
  userEmailSelector,
  serviceTokenSelector,
} from 'features/sensitive/sensitive.selector'
import { AlertError, AlertSuccess, LoadingView } from 'Components/Shared'
import { selectEvent } from 'features/admin/sync-primavera-task/selector'
import { TableColHeader, TableHeader } from 'Components/Shared/Table'
import { SearchInput } from 'Components/Filter'
import { TableRow } from '../../Shared/Table'
import { selectDataList } from 'features/admin/sync-primavera-task/selector'
import {
  DataItem,
  GetDataListReq,
  UpdateDataReq,
} from 'features/admin/sync-primavera-task/types'
import { getDataList, updateData } from 'features/admin/sync-primavera-task/api'

const breadcrumbs: BreadcrumbLink[] = [
  {
    url: '/dashboard',
    title: 'Home',
    icon: '/home.png',
    active: true,
    showTitle: false,
  },
  {
    url: '/sync-primavera-task',
    title: 'Sync Primavera Data',
    active: false,
    showTitle: false,
  },
]

type Props = DispatchProps & RouteComponentProps & ReduxProps

const SyncPrimaveraTask: React.ComponentType<Props> = props => {
  const { token, UserEmail, event, dataList } = props
  const [searchText, setSearchText] = useState('')
  const dispatch = useDispatch()

  const columns = [
    {
      key: 'project',
      title: 'โครงการ',
      span: 16,
    },
    {
      key: 'isActive',
      title: 'สถานะการตั้งค่า',
      span: 8,
    },
  ]

  useEffect(() => {
    onGetList()
  }, [UserEmail, searchText])

  const onGetList = () => {
    const req: GetDataListReq = {
      UserEmail,
      TextSearch: searchText,
    }
    dispatch(getDataList(token, req))
    console.log(dataList)
  }

  const onSearch = (val: string) => {
    if (searchText === '' && val === '') return
    setSearchText(val)
  }

  const onClick = (item: DataItem, type: string) => {
    const { ProjectCode, IsActive } = item
    const req: UpdateDataReq = {
      UserEmail,
      ProjectCode,
      Active: !IsActive,
      Type: type,
    }

    dispatch(updateData(token, req))
  }

  const header = (
    <Header>
      <Row type="flex" justify="space-between" align="middle">
        <Col>ตั้งค่าโครงการที่ต้องการดึงข้อมูลจาก Primavera</Col>
        <Col>
          <Row type="flex" justify="space-between" align="middle">
            <Col></Col>
          </Row>
        </Col>
      </Row>
    </Header>
  )

  const onRenderTable = (type: string, data: DataItem[]) => {
    return (
      <Col style={{ padding: '0.5rem 1.5rem' }}>
        <Box>
          <Title>
            {type === 'PROJECT-DT'
              ? `(${type}) Detail Plan`
              : type === 'PROJECT-HL'
              ? `(${type}) Master Plan`
              : ''}
          </Title>
          {/* <TableHeader>
            {columns.map((item: any, index: number) => {
              return (
                <TableColHeader span={item.span} isPadding={true} key={index}>
                  {item.title}
                </TableColHeader>
              )
            })}
          </TableHeader> */}
          {data.map((item: DataItem, index: number) =>
            onRenderRow(item, type, index)
          )}
        </Box>
      </Col>
    )
  }

  const onRenderRow = (item: DataItem, type: string, index: number) => {
    const { ProjectCode, ProjectName, IsActive } = item
    return (
      <>
        <TableRow
          minHeight={60}
          spaceBetweenRow={8}
          paddingLeft={27}
          key={index}
        >
          <Col span={16}>
            <Name>
              {ProjectCode} - {ProjectName}
            </Name>
          </Col>
          <Col span={8}>
            <SwitchArea>
              <Switch
                checked={IsActive}
                onChange={() => {
                  onClick(item, type)
                }}
                disabled={false}
              />
            </SwitchArea>
          </Col>
        </TableRow>
      </>
    )
  }

  const content = (
    <Header>
      <Row type="flex" justify="start" align="top">
        {onRenderTable('PROJECT-DT', dataList.DTProjects)}
        {onRenderTable('PROJECT-HL', dataList.HLProjects)}
      </Row>
    </Header>
  )

  return (
    <>
      <LoadingView isShow={event.IsLoading} />
      <AlertSuccess
        isShow={event.SubmitSuccess ? true : false}
        text={event.MessageSuccess}
        callback={() => onGetList()}
      />
      <AlertError
        isShow={event.SubmitError ? true : false}
        text={event.SubmitError}
      />
      <SubnavBar
        breadcrumbs={breadcrumbs}
        hideSearch={true}
        adminSearchBox={<SearchInput onSearch={onSearch} />}
      />
      <Menu selected={'sync-primavera-task'}>
        <MainPage>
          {header}
          {content}
        </MainPage>
      </Menu>
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    event: selectEvent(state),
    token: serviceTokenSelector(state),
    UserEmail: userEmailSelector(state),
    dataList: selectDataList(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(SyncPrimaveraTask)

const MainPage = styled.div`
  padding: 40px 45px;
  width: 100%;
`

const Header = styled.div`
  margin-bottom: 10px;
  font-size: 28px;
  line-height: 1.07;
  font-family: 'DB Helvethaica X 75 Bd';
  color: #000;
`
const Title = styled.div`
  font-size: 20px;
  font-family: 'DB Helvethaica X 75 Bd';
  margin: 24px 0 8px 0;
  color: #000;
`

const Box = styled.div`
  width: 100%;
`

const Name = styled.div`
  //font-family: 'DB Helvethaica X 75 Bd';
  color: #000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 4px;
`
const SwitchArea = styled.div`
  padding: 0 30px;
  float: left;
`
