import React, { useState } from 'react'
import { Row, Col } from 'antd'
import styled from 'styled-components'
import {
  ConstructRoadReport,
  DefectItem,
} from 'features/report/constructRoad/types'

import ReportHeader from './ReportHeader'
import ReportItem from './ReportItem'
import img from 'assets/img/img-nodefect.png'

type Props = {
  report: ConstructRoadReport
  onClick?: (item: DefectItem) => void
  onSelected?: (item: DefectItem) => void
  isShowEditImg?: boolean
  isShowCorrectImg?: boolean
}

const DefectList: React.FunctionComponent<Props> = props => {
  const {
    report,
    onClick,
    onSelected,
    isShowEditImg = false,
    isShowCorrectImg = false,
  } = props

  const noDefect = (
    <Area>
      <Empty>
        <img src={img} />
        <p>ไม่มีรายการ Defect</p>
      </Empty>
    </Area>
  )

  const content = report.DefectList.map((item, index) => {
    return (
      <ReportItem
        item={item}
        key={index}
        onClick={onClick}
        onSelected={onSelected}
        isShowEditImg={isShowEditImg}
        isShowCorrectImg={isShowCorrectImg}
      />
    )
  })

  const defectList = (
    <TableArea>
      <ReportHeader onSort={() => {}} sorting={{}} />
      {content}
    </TableArea>
  )

  return <>{report.DefectList.length > 0 ? defectList : noDefect}</>
}

const Title = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 28px;
`

const Area = styled.div`
  width: 100%;
  height: 90%;
  text-align: center;
  position: relative;
`

const Empty = styled.div`
  margin: 0;
  position: absolute;
  top: 40%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  p {
    font-size: 20px;
    margin-top: 24px;
  }
  img {
    width: 144px;
  }
`

const TableArea = styled.div`
  margin-top: 20px;
`

export default DefectList
