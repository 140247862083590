import React from 'react'
import { Row } from 'antd'
import styled from 'styled-components'

const range = (start: number, end: number) => {
  const pages = [start]
  if (start === end) return pages
  for (let i = start + 1; i <= end; i++) {
    pages.push(i)
  }

  return pages
}

type Props = {
  currentPage: number
  totalRows: number
  perPage: number
  onClick?: (page: number) => void
}

const PageIndex: React.FunctionComponent<Props> = ({
  currentPage = 1,
  totalRows = 1,
  perPage = 10,
  onClick = () => {},
}) => {
  const totalPages = Math.ceil(totalRows / perPage)
  const firstPage = 1
  const previousPage = currentPage > 1 ? currentPage - 1 : 1
  const nextPage = currentPage === totalPages ? totalPages : currentPage + 1
  const lastPage = totalPages

  const getShowPage = () => {
    const maxIndex = 4
    let startPage = currentPage - 2
    let endPage
    let showPage
    if (startPage <= 0) {
      startPage = firstPage
    }

    endPage = startPage + maxIndex
    if (endPage > lastPage) {
      endPage = lastPage
    }

    showPage = endPage - startPage
    if (showPage <= maxIndex) {
      startPage = endPage - maxIndex
      if (startPage <= 0) {
        startPage = 1
      }
    }

    return [startPage, endPage]
  }

  const generatePaginationLinks = (end: number, currentPage = 1) => {
    const showPage = getShowPage()
    const pages = range(showPage[0], showPage[1])
    return pages.map(page => (
      <li key={page}>
        <div onClick={() => onClick(page)}>
          <div
            className={
              currentPage === page
                ? 'pagination-number active-page'
                : 'pagination-number'
            }
          >
            <span className="page">{page}</span>
          </div>
        </div>
      </li>
    ))
  }

  return (
    <MainView>
      <PaginationBlock>
        <li>
          <div onClick={() => onClick(firstPage)}>
            <span className="pagination-icon ic-first">
              <img src="/img/ic-first.svg" alt="go to first page" />
            </span>
          </div>
        </li>
        <li>
          <div onClick={() => onClick(previousPage)}>
            <span className="pagination-icon ic-previous">
              <img src="/img/ic-previous.svg" alt="go to previous page" />
            </span>
          </div>
        </li>
        {generatePaginationLinks(totalPages, currentPage)}
        <li>
          <div onClick={() => onClick(nextPage)}>
            <span className="pagination-icon ic-next">
              <img src="/img/ic-next.svg" alt="go to next page" />
            </span>
          </div>
        </li>
        <li>
          <div onClick={() => onClick(lastPage)}>
            <span className="pagination-icon ic-last">
              <img src="/img/ic-last.svg" alt="go to last page" />
            </span>
          </div>
        </li>
      </PaginationBlock>
    </MainView>
  )
}

const MainView = styled.div`
  text-align: right;
`

const PaginationBlock = styled.ul`
  list-style: none;
  float: right;
  margin: 0;
  li {
    float: left;
    line-height: 32px;
    margin: 0 4px;
    font-size: 18px;
  }

  li > div {
    cursor: pointer;
  }

  li > div > .pagination-number {
    cursor: pointer;
    user-select: none;
    display: block;
    width: 32px;
    height: 32px;
    text-align: center;
    border-radius: 5px;
    font-family: 'DB Helvethaica X 55 Regular';
  }

  li > div > .active-page {
    background: #003168;
    color: #fff;
    font-family: 'DB Helvethaica X 75 Bd';
  }
`

export default PageIndex
