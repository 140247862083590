import RootState from '../../../typings/RootState'
import { CurrentActivity, Activity } from './types'
import { MainconCheckList } from 'features/types/checklist'
import { Event } from '../../types/event'

export const selectCurrentActivity = (state: RootState): CurrentActivity =>
  state.cmpjActivityDetail.CurrentActivity
export const selectOriginalActivity = (state: RootState): Activity =>
  state.cmpjActivityDetail.OriginalActivity
export const selectCheckList = (state: RootState): MainconCheckList =>
  state.cmpjActivityDetail.CheckList
export const selectUploadList = (state: RootState): any[] =>
  state.cmpjActivityDetail.UploadList
export const selectEvent = (state: RootState): Event =>
  state.cmpjActivityDetail.Event

export const selectEventCheckList = (state: RootState): Event =>
  state.cmpjActivityDetail.EventCheckList
