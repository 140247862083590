import React, { useState } from 'react'
import { Col, Layout, Progress, Row } from 'antd'
import styled from 'styled-components'
import Breadcrumbs from './Breadcrumbs'
import { ApprovalFooter, RejectModal, ApproveModal } from 'Components/Activity'
import imgHistory from 'assets/img/ic-history.png'
import { ActivityItem } from 'features/approval/activity/types'
import { getProgressColor } from 'utils/color-map'
import { textToLowerCase } from 'utils/format'
import { getStatusText } from 'utils/getText'
import { toDateText } from 'utils/datetime'
import ImagePicker from 'Components/Shared/ImagePicker'
import DocumentPicker from 'Components/Shared/DocumentPicker'
import { ActivityHistory } from 'features/activity/activity.types'
import { cloneDeep } from 'lodash'

type Props = {
  onImageOpen(list?: any[], readOnly?: boolean): void
  onDocumentOpen(list?: any[], readOnly?: boolean): void
  onThumbnailOpen(index: number, list?: any[], readOnly?: boolean): void
  originalData: ActivityItem
  onHistoryOpen?: () => void
  onApproveTask: () => void
  onRejectTask: (remark: string) => void
  isLoading?: boolean
  wbs?: any
  historyData?: ActivityHistory[]
}

const { Content, Footer } = Layout

const ActivityDetail: React.FunctionComponent<Props> = ({
  onImageOpen,
  onDocumentOpen,
  onThumbnailOpen,
  originalData,
  onHistoryOpen,
  onApproveTask,
  onRejectTask,
  isLoading = false,
  wbs,
  historyData = [],
}) => {
  const [showRejectModal, setShowRejectModal] = useState(false)
  const [showApproveModal, setShowApproveModal] = useState(false)
  const isApproval = true
  const readOnly = true

  const getLogsInfo = () => {
    let img = [] as any
    let docs = [] as any
    let remark = '' as any
    let role = '' as any
    const history = cloneDeep(historyData)
    history &&
      history.map((item: ActivityHistory, index: number) => {
        if (index === 0) {
          img = item.Images
          docs = item.Documents
          remark = item.Remark
          role = item.UserEmail + ' / ' + item.UserRole
        }
      })

    return [img, docs, remark, role]
  }

  const [images, documents, remark, role] = getLogsInfo()

  const onSubmitReject = (val: string) => {
    onRejectTask(val)
    setShowRejectModal(false)
  }

  const onSubmitApprove = () => {
    onApproveTask()
    setShowApproveModal(false)
  }

  const rejectModal = (
    <RejectModal
      visible={showRejectModal}
      onClose={() => setShowRejectModal(false)}
      onSubmit={(val: string) => onSubmitReject(val)}
      title="ไม่อนุมัติ"
      placeholder="เหตุผลที่ไม่อนุมัติ"
    />
  )

  const approveModal = (
    <ApproveModal
      visible={showApproveModal}
      onClose={() => setShowApproveModal(false)}
      onSubmit={() => onSubmitApprove()}
      title="คุณต้องการอนุมัติงานนี้ใช่ไหม"
    />
  )

  console.log('originalData', originalData)

  const taskConfig = getProgressColor(
    originalData.TaskStatus || '',
    originalData.Progress,
    originalData.LastReqProgress,
    originalData.LastCurProgress
  )

  const status = textToLowerCase(originalData.TaskStatus)

  const renderStatus = () => {
    return (
      <Row type="flex" justify="space-between">
        <Col>
          <Key>สถานะ</Key>
        </Col>
        <Col>
          <Val className={`color-${status}`}>{getStatusText(status)}</Val>
        </Col>
      </Row>
    )
  }

  const renderDesc = (key: string, val: any) => {
    return (
      <Row type="flex" justify="space-between" style={{ marginTop: '5px' }}>
        <Col span={12}>
          <Key>{key}</Key>
        </Col>
        <Col span={12}>
          <Val>{val || '-'}</Val>
        </Col>
      </Row>
    )
  }

  const Header = (
    <Row type="flex" justify="space-between">
      <Col>
        <Title> {originalData.ActivityName}</Title>
      </Col>
      <Col>
        <HistoryBtn onClick={onHistoryOpen}>
          <img src={imgHistory} alt="Go to activity history" />
          ประวัติการส่งอนุมัติ
        </HistoryBtn>
      </Col>
    </Row>
  )

  const TaskInfo = (
    <Info>
      <Center>
        <Progress
          type="circle"
          percent={taskConfig.progress || taskConfig.success}
          successPercent={taskConfig.success}
          width={120}
          strokeWidth={9}
          strokeColor={taskConfig.color}
          format={percent => `${originalData.Progress}%`}
          className={taskConfig.color ? '' : taskConfig.className}
          strokeLinecap="round"
        />
      </Center>
      <Description>
        {renderStatus()}
        {renderDesc('หมวดหมู่', wbs)}
        {renderDesc(
          'แผนวันเริ่มงาน',
          toDateText(originalData.BaselineStartDate)
        )}
        {renderDesc(
          'แผนวันสิ้นสุดงาน',
          toDateText(originalData.BaselineFinishDate)
        )}
        {renderDesc(
          'แผนวันเริ่มงานปัจจุบัน',
          toDateText(originalData.StartDate)
        )}
        {renderDesc(
          'แผนวันสิ้นสุดงานปัจจุบัน',
          toDateText(originalData.FinishDate)
        )}
        {renderDesc(
          'วันเริ่มงานจริง',
          toDateText(originalData.ActualStartDate)
        )}
        {renderDesc(
          'วันที่คาดแล้วเสร็จ',
          toDateText(originalData.ExpectFinishDate)
        )}
        {renderDesc('ผู้ยื่นคำขอ', role)}
      </Description>
    </Info>
  )

  const filesInfo = (
    <Row type="flex" justify="space-between">
      <Col md={12}>
        <ImagePicker
          Images={images}
          onChange={() => {}}
          onImageOpen={() => onImageOpen(images, readOnly)}
          onThumbnailOpen={index => onThumbnailOpen(index, images, readOnly)}
          disabled={readOnly}
          showMax={3}
          numberOfImage={50}
          viewMode={readOnly}
        />
      </Col>
      <Col md={12}>
        <DocumentPicker
          Documents={documents}
          onChange={() => {}}
          onDocumentOpen={() => onDocumentOpen(documents)}
          disabled={readOnly}
          showMax={3}
          numberOfFile={50}
          viewMode={readOnly}
        />
      </Col>
    </Row>
  )

  return (
    <>
      <Breadcrumbs activity={originalData} />
      <Content className="mid-content">
        <div className="main-content activity-detail">
          {Header}
          <Box>
            <Row type="flex" justify="space-between">
              <Col span={8}>{TaskInfo}</Col>
              <Col span={16}>
                <FileInfo>
                  {filesInfo}
                  <Remark>
                    <Key> หมายเหตุ </Key>
                    <RemarkTxt> {remark || '-'} </RemarkTxt>
                  </Remark>
                </FileInfo>
              </Col>
            </Row>
          </Box>
        </div>
      </Content>
      {isApproval && (
        <Footer className="detail-page-footer">
          <ApprovalFooter
            onClickApprove={() => setShowApproveModal(true)}
            onClickReject={() => setShowRejectModal(true)}
            isLoading={isLoading}
          />
        </Footer>
      )}
      {rejectModal}
      {approveModal}
    </>
  )
}

const Info = styled.div`
  border-radius: 10px;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.03);
  background-color: #ffffff;
  padding: 24px;
  margin-right: 15px;
`
const FileInfo = styled.div`
  border-radius: 10px;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.03);
  background-color: #ffffff;
  padding: 24px;
  margin-left: 15px;
`

const Box = styled.div`
  margin-top: 15px;
`

const Center = styled.div`
  width: 100%;
  text-align: center;
  margin: 20px 0;
`

const Description = styled.div`
  width: 100%;
  text-align: center;
`

const Title = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 28px;
`

const Key = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 20px;
  text-align: left;
`

const Val = styled.div`
  font-family: 'DB Helvethaica X 55 Regular';
  font-size: 20px;
  text-align: right;
  word-break: break-all;
`

const RemarkTxt = styled.div`
  font-family: 'DB Helvethaica X 55 Regular';
  font-size: 20px;
`

const HistoryBtn = styled.span`
  color: #003168;
  font-size: 18px;
  font-family: 'DB Helvethaica X 75 Bd';
  img {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    margin-bottom: 5px;
  }

  :hover {
    cursor: pointer;
  }
`
const Remark = styled.div`
  margin-top: 16px;
`

export default ActivityDetail
