import { put, takeEvery, select } from 'redux-saga/effects'
import { getType } from 'typesafe-actions'
import { APIPath } from 'services/APIPath'
import { changeSelectedProjectAction } from './selectedProject.actions'
import {
  userEmailSelector,
  serviceTokenSelector,
} from '../sensitive/sensitive.selector'
import { projectCodeWithSuffixSelector } from '../project/project.selector'
import { fetchTasklanes } from '../tasklane/tasklane.api'
import { FetchTasklaneRequest } from 'features/tasklane/tasklane.types'

import { getDashboardQuerySelector } from 'features/tasklane/tasklane.selector'
import projectHelper from 'utils/projectHelper'
import { toSaveFormat } from 'utils/datetime'
import { selectedProjectCodeSelector } from './selectedProject.selector'

function* fetchTasklanesApi() {
  const UserEmail = yield select(userEmailSelector)
  const ProjectCode = yield select(projectCodeWithSuffixSelector)
  const token = yield select(serviceTokenSelector)
  const { date } = yield select(getDashboardQuerySelector)
  let path: string = APIPath.GetActivity
  let data: FetchTasklaneRequest = {
    UserEmail,
    ProjectCode,
  }
  if (date.start && date.end) {
    data.StartDate = toSaveFormat(date.start)
    data.FinishDate = toSaveFormat(date.end)
    data.Page = 1
    data.RowPerPage = 10
    path = APIPath.getActivityFilter
  }

  const projectCodeToSave = yield select(selectedProjectCodeSelector)
  projectHelper.saveProjectCode(projectCodeToSave)

  yield put(fetchTasklanes(path, token, data))
}

function* allSaga() {
  yield takeEvery(getType(changeSelectedProjectAction), fetchTasklanesApi)
}

export default allSaga
