import React from 'react'

interface Props {
  status?: string
}

const LaneColor = (props: Props) => {
  const { status = '' } = props
  const getLaneColor = (status: string) => {
    let lane
    switch (status) {
      case 'success':
        lane = <span className="lane-card-success" />
        break
      case 'fail':
        lane = <span className="lane-card-delayed" />
        break
      default:
        lane = <span />
        break
    }
    return lane
  }
  return getLaneColor(status)
}

export default LaneColor
