import RootState from '../../typings/RootState'
import {
  ModelTypeResp,
  ModelState,
  UnitItem,
  PrimaveraType,
  ProjectTypeResp,
  QcWork,
} from './types'

import { Event } from '../types/event'

export const selectEvent = (state: RootState): Event => state.masterData.Event

export const selectMasterData = (state: RootState): ModelState =>
  state.masterData

export const selectModelTypeList = (state: RootState): ModelTypeResp =>
  state.masterData.ModelType

export const selectUnitList = (state: RootState): UnitItem[] =>
  state.masterData.UnitList

export const selectPrimavera = (state: RootState): PrimaveraType =>
  state.masterData.Primavera

export const selectProjectType = (state: RootState): ProjectTypeResp =>
  state.masterData.ProjectType

export const selectQcWork = (state: RootState): QcWork =>
  state.masterData.QcWork
