import React, { useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import styled from 'styled-components'
import { PrimaryButton } from 'Components/Shared/Button'
import { Row, Col } from 'antd'
import RootState from 'typings/RootState'

import EventModal from 'Components/Shared/Modal/EventModal'

import { selectEvent } from 'features/report/common/selector'
import { enableFunction } from 'features/report/common/api'
import { EnableFunctionReq } from 'features/report/common/types'
import { CheckedIcon } from 'Components/Shared/Icon'

import {
  userEmailSelector,
  serviceTokenSelector,
} from 'features/sensitive/sensitive.selector'
import { SwitchFunction } from 'Components/Defect'

type Props = {
  project: string
  RequestID: number
  report: any
  onSign: () => void
  onCompleted: () => void
  onChooseImage: () => void
} & ReduxProps

const QcFooter: React.ComponentType<Props> = props => {
  const {
    report,
    project,
    RequestID,
    token,
    UserEmail,
    event,
    onSign,
    onCompleted,
    onChooseImage,
  } = props
  const [enableModal, setEnableModal] = useState(false)
  const dispatch = useDispatch()

  const {
    Unit,
    QCTypeID,
    CheckNumber,
    FunctionStatus = 'N',
    State,
    Status,
  } = report

  const isReadOnly = !(State === 'QC-QC' && Status === 'INPRO')
  if (isReadOnly) {
    return <div />
  }

  const onFunction = () => {
    setEnableModal(true)
    const req: EnableFunctionReq = {
      ProjectCode: project,
      UserEmail,
      Unit,
      QCTypeID,
      RequestID,
      CheckNumber,
      FunctionStatus: FunctionStatus === 'N' ? 'Y' : 'N',
    }
    dispatch(enableFunction(token, req))
  }

  const onFunctionCompleted = () => {
    setEnableModal(false)
    onCompleted()
  }

  const hasSign = report.HasSign ? (
    <IconBtn>
      <CheckedIcon />
      ลงชื่อรับทราบ
    </IconBtn>
  ) : (
    'ลงชื่อรับทราบ'
  )

  const hasImage = report.HasImage ? (
    <IconBtn>
      <CheckedIcon />
      เลือกรูปส่งรายงาน
    </IconBtn>
  ) : (
    'เลือกรูปส่งรายงาน'
  )

  return (
    <Footer>
      <EventModal
        event={[event]}
        enable={enableModal}
        successCalback={onFunctionCompleted}
        errorCalback={() => setEnableModal(false)}
      />
      <Row type="flex" justify="space-between" align="middle">
        <Col>
          <SwitchFunction
            onFunction={onFunction}
            RequestID={RequestID}
            report={report}
            projectCode={project}
          />
        </Col>
        <Col>
          <Button>
            <PrimaryButton text={hasSign} onClick={onSign} />
          </Button>
          <Button>
            <PrimaryButton text={hasImage} onClick={onChooseImage} />
          </Button>
        </Col>
      </Row>
    </Footer>
  )
}

const Footer = styled.div`
  position: fixed;
  bottom: 0px;
  height: auto;
  width: 100%;
  background-color: white;
  padding: 22px 80px;
  z-index: 20;
`

const Button = styled.div`
  width: 200px;
  margin-left: 16px;
  float: left;
`

const IconBtn = styled.div`
  img {
    margin-right: 10px;
  }
`

const mapStateToProps = (state: RootState) => {
  return {
    event: selectEvent(state),
    token: serviceTokenSelector(state),
    UserEmail: userEmailSelector(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QcFooter)
