import React from 'react'
import styled from 'styled-components'
import { AdminProject } from 'Components/Shared'

type Props = {
  onFilterChange: (filter: any) => void
  filter: any
  projectsById: any
}

const ProjectsFilter: React.FunctionComponent<Props> = props => {
  const { onFilterChange, filter, projectsById } = props

  const filterByProjectType = () => {
    if (filter.ProjectTypeID > 0) {
      let results = {} as any
      for (let [key, value] of Object.entries(projectsById)) {
        if (projectsById[key]['ProjectTypeID'] === filter.ProjectTypeID) {
          results[key] = value
        }
      }
      return results
    } else {
      return projectsById
    }
  }

  return (
    <Box>
      <AdminProject
        placeholder="เลือกโครงการ"
        projectList={filterByProjectType()}
        onSelectProject={(val: any) => {
          filter.ProjectCode = val
          onFilterChange(filter)
        }}
        activeProject={filter.ProjectCode}
      />
    </Box>
  )
}

const Box = styled.div`
  padding: 0 12px 12px 0;
`

export default ProjectsFilter
