import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { connect, useDispatch } from 'react-redux'
import { Row, Col } from 'antd'
import RootState from 'typings/RootState'
import { DropdownList } from 'Components/Shared/Form'
import { CloseIcon } from 'Components/Shared/Icon'
import PrimaryButton from 'Components/Shared/Button/PrimaryButton'
import { InputText } from 'Components/Shared/Input'
import { DataItem } from 'features/admin/qcType/types'

type Props = ReduxProps &
  DispatchProps & {
    item: DataItem
    onSave?: (item: DataItem) => void
    onClose: () => void
    isUpdateMode?: boolean
  }

const AdminDetail: React.ComponentType<Props> = props => {
  const { item, isUpdateMode = false } = props
  const [dataItem, setdataItem] = useState({} as DataItem)

  const dispatch = useDispatch()

  useEffect(() => {
    setdataItem(item)
  }, [item])

  const types = [
    {
      name: 'Unit',
      value: 'Unit',
    },
    {
      name: 'Road',
      value: 'Road',
    },
    {
      name: 'Commissioning',
      value: 'Commissioning',
    },
  ]

  const status = [
    {
      name: 'Active',
      value: 'Active',
    },
    {
      name: 'InActive',
      value: 'InActive',
    },
  ]

  const onSave = () => {
    if (props.onSave) {
      props.onSave(dataItem)
    }
  }

  return (
    <MainPage>
      <Header>
        <CloseIcon color="#8b99a7" size={24} onClick={props.onClose} />
      </Header>
      <Row>
        <Col span={24}>
          <Title>ตั้งค่า</Title>
        </Col>
        <Col span={24}>
          <BodyArea>
            <InputText
              prefix="ชื่อ"
              value={dataItem.QCTypeName}
              onChange={(val: string) => {
                dataItem.QCTypeName = val
                setdataItem({ ...dataItem })
              }}
              placeholder="ระบุชื่อ"
            />
          </BodyArea>
        </Col>
        <Col span={24}>
          <BodyArea>
            <InputText
              prefix="Primavera code"
              placeholder="ระบุ Code"
              disabled={isUpdateMode}
              value={dataItem.QCTypeID}
              onChange={(val: string) => {
                dataItem.QCTypeID = val
                setdataItem({ ...dataItem })
              }}
            />
          </BodyArea>
        </Col>
        <Col span={24}>
          <BodyArea>
            <DropdownList
              label="ประเภท"
              value={dataItem.UnitType}
              menu={types}
              onChange={(val: string) => {
                dataItem.UnitType = val
                setdataItem({ ...dataItem })
              }}
            />
          </BodyArea>
        </Col>
        <Col span={24}>
          <BodyArea>
            <DropdownList
              label="สถานะ"
              value={dataItem.Status}
              menu={status}
              onChange={(val: string) => {
                dataItem.Status = val
                setdataItem({ ...dataItem })
              }}
            />
          </BodyArea>
        </Col>
      </Row>
      <Row type="flex" justify="end">
        <SaveArea>
          <PrimaryButton onClick={onSave} text="บันทึก" />
        </SaveArea>
      </Row>
    </MainPage>
  )
}

const MainPage = styled.div`
  -khtml-user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  overflow-x: scroll;
`

const Header = styled.div`
  text-align: right;
  height: 40px;

  svg {
    cursor: pointer;
  }
`

const Title = styled.div`
  color: black;
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 28px;
  margin-bottom: 14px;
`

const Text = styled.div`
  color: black;
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 20px;
`

const SaveArea = styled.div`
  margin-top: 32px;
  float: right;
  width: 160px;
`

const BodyArea = styled.div`
  font-family: 'DB Helvethaica X 55 Regular';
  span {
    font-size: 20px;
  }
  .ant-dropdown-trigger {
    margin-bottom: 12px;
  }
  .input-title {
    margin-bottom: 12px;
  }
`

const mapStateToProps = (state: RootState) => {
  return {}
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminDetail)
