import React from 'react'
import styled from 'styled-components'
import { Collapse, Row, Col } from 'antd'
import imgFail from 'assets/img/ic-fail.png'
import imgCheck from 'assets/img/ic-check.png'
import arrowUp from 'assets/img/ic-up.png'
import arrowDown from 'assets/img/ic-down.png'
import {
  InspectionData,
  InspectionList,
  InspectionSubData,
} from 'features/types/checklist'
import { CheckListStatusText } from 'features/cmpj/activity/types'

type Props = {
  inspectionList: InspectionList[]
  hideStatus?: boolean
  remarkTxt?: string
}

const TaskChecklist: React.FC<Props> = (props: Props) => {
  const { Panel } = Collapse
  const {
    hideStatus = false,
    inspectionList,
    remarkTxt = 'หมายเหตุจาก CM/PJ',
  } = props

  const renderStatus = (status: string) => {
    let textStatus
    let iconStatus
    switch (status) {
      case 'PASS':
        textStatus = (
          <LabelStatus style={{ color: '#5bc79e' }}>
            {CheckListStatusText.PASS}
          </LabelStatus>
        )
        iconStatus = <IconStatus src={imgCheck} />
        break
      case 'FAIL':
        textStatus = (
          <LabelStatus style={{ color: '#d0494c' }}>
            {CheckListStatusText.FAIL}
          </LabelStatus>
        )

        iconStatus = <IconStatus src={imgFail} />
        break
      case 'SKIP':
        textStatus = (
          <LabelStatus style={{ color: '#5bc79e' }}>
            {CheckListStatusText.SKIP}
          </LabelStatus>
        )
        iconStatus = <IconStatus src={imgCheck} />
        break
      default:
        textStatus = (
          <LabelStatus style={{ color: '#8b99a7' }}>
            {CheckListStatusText.NOTCHECK}
          </LabelStatus>
        )
    }

    return (
      <>
        <Col span={5}>
          <TitleStatus>สถานะ</TitleStatus>
          {textStatus}
        </Col>
        <Col span={2}>{iconStatus}</Col>
      </>
    )
  }

  const renderSubChecklist = (items: InspectionSubData[]) => {
    return (
      items &&
      items.map((item: InspectionSubData, index: number) => {
        const sublist = subChecklist(item)
        return (
          <Collapse
            expandIconPosition="right"
            bordered={false}
            className="checklist"
            expandIcon={panelProps => arrow(panelProps)}
            key={index}
          >
            {item.Remark !== '' ? (
              <Panel header={sublist} key={index} style={{ padding: 0 }}>
                <div className="subitem">{renderRemark(item.Remark)}</div>
              </Panel>
            ) : (
              sublist
            )}
          </Collapse>
        )
      })
    )
  }

  const renderRemark = (remark: string) => {
    return (
      <ChecklistItem className="remark-box">
        <Row type="flex" justify="space-between" align="middle">
          <Col span={19}>
            <span className="remark-title">{remarkTxt}</span>
            &nbsp;&nbsp;&nbsp;
            <span>{remark}</span>
          </Col>
        </Row>
      </ChecklistItem>
    )
  }

  const subChecklist = (item: InspectionSubData) => {
    return (
      <ChecklistItem className="check-list-item">
        <Row type="flex" justify="space-between" align="middle">
          <Col span={17}>
            <TextSubItem>- {item.Name}</TextSubItem>
          </Col>
          {!hideStatus && renderStatus(item.Status)}
        </Row>
      </ChecklistItem>
    )
  }

  const renderChecklist = (item: any) => {
    return (
      <ChecklistItem className="check-list-item">
        <Row type="flex" justify="space-between" align="middle">
          <Col span={17}>
            <TextItem>
              {item.Seq} {item.Name}
            </TextItem>
          </Col>
          {!hideStatus && renderStatus(item.Status)}
        </Row>
      </ChecklistItem>
    )
  }

  const arrow = (panelProps: any) => {
    return (
      <img
        src={panelProps.isActive ? arrowUp : arrowDown}
        width="16px"
        height="16px"
      />
    )
  }

  const renderInspectionData = (data: InspectionData[]) => {
    return (
      data &&
      data.map((item: InspectionData, index: number) => {
        const checkListContent = renderChecklist(item)
        const content =
          item.Remark !== '' ? (
            <Panel header={checkListContent} key={index} style={{ padding: 0 }}>
              {renderRemark(item.Remark)}
            </Panel>
          ) : (
            checkListContent
          )

        return (
          <Collapse
            expandIconPosition="right"
            bordered={false}
            className="checklist"
            expandIcon={panelProps => arrow(panelProps)}
            key={index}
          >
            {content}
            {item.Data.length > 0 ? renderSubChecklist(item.Data) : ''}
          </Collapse>
        )
      })
    )
  }

  const list = inspectionList || []
  const content = list.map((group: InspectionList, index: number) => {
    if (!group.isHide) {
      return (
        <ChecklistBox key={index}>
          <ChecklistHeader>
            {group.Seq}. {group.InspectionCategory}
          </ChecklistHeader>
          {renderInspectionData(group.Data)}
        </ChecklistBox>
      )
    }
  })

  return <div>{content}</div>
}

const ChecklistBox = styled.div`
  background-color: #fff;
  border-radius: 5px;
  border: 1px solod #eeeff2;
  margin-bottom: 24px;
  .checklist {
    .ant-collapse-item {
      border: none;
    }
    border: none;
  }
  .ant-collapse-content-box {
    padding: 0 !important;
  }

  .ant-collapse-item-active {
    .check-list-item {
      border-bottom: none;
    }
  }

  .subitem {
    .remark-box {
      padding-left: 24px;
    }
  }

  @media print {
    padding: 4px 10px;
    margin: 0;
  }
`

const ChecklistHeader = styled.div`
  padding: 12px 24px;
  background-color: #003168;
  font-family: 'DB Helvethaica X 75 Bd';
  color: #fff;
  font-size: 20px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  @media print {
    padding: 4px 10px;
    margin: 0;
  }
`

const ChecklistItem = styled.div`
  padding: 16px 0px 16px 0;
  margin: 0 16px 0 24px;
  border-bottom: 1px solid #eeeff2;
  min-height: 63px;
  font-size: 20px;

  .ant-collapse-content-active {
    .remark {
      border-bottom: none;
      background-color: red;
    }
  }

  &.remark-box {
    padding-top: 0;
  }

  .remark-title {
    font-family: 'DB Helvethaica X 75 Bd';
  }

  @media print {
    padding: 4px 10px;
    margin: 0;
    min-height: auto;
  }
`

const TitleStatus = styled.span`
  font-size: 20px;
  font-family: 'DB Helvethaica X 75 Bd';
  margin-right: 10px;
  margin-left: 10px;
`

const LabelStatus = styled.label`
  font-size: 18px;
  font-family: 'DB Helvethaica X 55 Regular';
`

const TextItem = styled.span`
  font-size: 20px;
  color: #000;
`

const TextSubItem = styled.span`
  font-size: 20px;
  color: #000;
  padding-left: 15px;
`

const IconStatus = styled.img`
  width: 24px;
  height: 24px;
`

export default TaskChecklist
