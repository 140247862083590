import React from 'react'
import BaseImgBtn from './BaseImgBtn'

import img from 'assets/img/ic-trash.png'

const TrashBtn = (props: any) => {
  return <BaseImgBtn img={img} onClick={props.onClick} />
}

export default TrashBtn
