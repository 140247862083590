import RootState from 'typings/RootState'
import { Activity } from '../../cmpj/activity/types'
import { ActivityItem } from './types'
import { Event } from '../../types/event'

export const selectList = (state: RootState): Activity =>
  state.approvalActivity.detail

export const selectEvent = (state: RootState): Event =>
  state.approvalActivity.event

export const selectMsDetail = (state: RootState): ActivityItem =>
  state.approvalActivity.msDetail
