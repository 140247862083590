import React, { useState, useImperativeHandle, forwardRef } from 'react'
import { Modal, Row, Col } from 'antd'
import styled from 'styled-components'

import { CloseIcon } from 'Components/Shared/Icon'
import { PrimaryButton, OutlineButton } from 'Components/Shared/Button'

import InputText from 'Components/Shared/Input/InputText'

export interface Handles {
  showModal: (titleTxt: string, unitTxt: string, prev?: string) => void
}

interface Props {
  onOk: (name: string, prev?: string) => void
  readOnly?: any
}

const AddEditModal = forwardRef<Handles, Props>(
  ({ onOk: onOkProps, readOnly = false }, ref) => {
    const [visible, setvisible] = useState(false)
    const [openWithFocus, setOpenWithFocus] = useState(false)
    const [title, settitle] = useState('')
    const [unit, setunit] = useState('')
    const [name, setname] = useState('')
    const [prev, setprev] = useState('')

    const clearClose = () => {
      setvisible(false)
      settitle('')
      setunit('')
      setname('')
      setprev('')
      setOpenWithFocus(false)
    }

    const closeModal = () => {
      setvisible(false)
    }

    const onOk = () => {
      onOkProps(name, prev)
      clearClose()
    }

    useImperativeHandle(ref, () => ({
      showModal: (titleTxt: string, unitTxt: string, prev?: string) => {
        setvisible(true)
        settitle(titleTxt)
        setunit(unitTxt)
        if (prev) {
          setname(prev)
          setprev(prev)
        }
        setOpenWithFocus(true)
      },
    }))

    return (
      <ModalCheckList>
        <Modal
          centered
          visible={visible}
          width="528px"
          footer={null}
          closeIcon={<CloseIcon color="#8b99a7" size={16} />}
          title={<Header>{`${title}`}</Header>}
          className="add-checklist-modal"
          onCancel={closeModal}
        >
          <ModalContent>
            <InputText
              prefix=""
              placeholder={unit ? `กรอก ${unit}` : ''}
              onChange={setname}
              value={name}
              openWithFocus={openWithFocus}
              disabled={readOnly}
            />
            <ButtonSection>
              <Row>
                <Col span={7} offset={9}>
                  <OutlineButton
                    text="ยกเลิก"
                    disabled={false}
                    onClick={closeModal}
                  />
                </Col>
                <Col span={7} offset={1}>
                  <PrimaryButton
                    text="บันทึก"
                    disabled={readOnly}
                    onClick={onOk}
                  />
                </Col>
              </Row>
            </ButtonSection>
          </ModalContent>
        </Modal>
      </ModalCheckList>
    )
  }
)

const Header = styled.span`
  font-size: 28px;
  color: #000000;
  font-family: 'DB Helvethaica X 75 Bd';
`

const ModalCheckList = styled.div`
  .add-checklist-modal {
    .ant-modal-header {
      border-bottom: none;
    }
  }
`

const ModalContent = styled.div`
  padding: 0 24px 31px 24px;
  .ant-input {
    font-size: 20px;
  }
`

const ButtonSection = styled.div`
  padding-top: 18px;
`

export default AddEditModal
