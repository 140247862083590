import RootState from 'typings/RootState'
import { Activity } from './types'

import { Event } from '../../types/event'

export const selectList = (state: RootState): Activity[] =>
  state.approvalList.list

export const selectEvent = (state: RootState): Event => state.approvalList.event

export const selectSorting = (state: RootState) => state.approvalList.sorting
