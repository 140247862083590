import RootState from 'typings/RootState'
import { createSelector } from 'reselect'
import { DataList, SowList } from './types'
import { Event } from '../../types/event'

export const selectDataList = (state: RootState): DataList =>
  state.adminPosition.dataList

export const selectEvent = (state: RootState): Event =>
  state.adminPosition.event

export const selectSowList = (state: RootState): SowList =>
  state.adminPosition.sowList

export const selectSowEvent = (state: RootState): Event =>
  state.adminPosition.sowCodeEvent
