import React from 'react'
import styled from 'styled-components'
import { SitevisitInpro } from 'features/qc/dashboard/types'
import { Row, Col, Modal } from 'antd'
import CheckIconBox from '../CheckIconBox'
import SignDescription from '../SignDescription'
import { TrashIcon } from 'Components/Shared/Icon'
import iconLogout from 'assets/img/ic-approval-logout.png'
import { numberFormat } from 'utils/format'

interface Props {
  task: SitevisitInpro
  isSendReport?: boolean
  isSelected?: boolean
  onSelectLists: (task: SitevisitInpro) => void
  onCancel: (task: SitevisitInpro) => void
  type?: 'construct' | 'audit'
}

const InproCard: React.FC<Props> = props => {
  const {
    task,
    isSendReport = false,
    onSelectLists,
    isSelected = false,
    onCancel,
    type = 'construct',
  } = props
  const {
    ProjectCode,
    ProjectNameTH,
    CheckNumber,
    CheckStatus,
    InspectionScore,
    HasSign,
  } = task
  const isConstruct = type === 'construct'
  const showRadio = HasSign === 'Y'

  const showModal = () => {
    Modal.confirm({
      onOk() {
        onCancel(task)
      },
      width: '327px',
      onCancel() {},
      content: (
        <ModalContent>
          <img src={iconLogout} />
          <div className="message">
            <p>คุณยืนยันที่จะยกเลิกคำขอ</p>
            <p>
              {ProjectCode} - {ProjectNameTH} ใช่หรือไม่?
            </p>
          </div>
        </ModalContent>
      ),
      okText: 'ใช่',
      okButtonProps: {
        className: 'btn-rectangle ok-btn',
      },
      cancelText: 'ยกเลิก',
      cancelButtonProps: {
        className: 'btn-rectangle cancle-btn',
      },
      centered: true,
      icon: null,
      className: 'login-modal-window',
    })
  }

  const cancelIcon = (
    <CancelIcon
      onClick={(evt: any) => {
        evt.preventDefault()
        if (isSendReport) return
        showModal()
      }}
    >
      <TrashIcon size={14} color={isSendReport ? '#8b99a7' : '#d0494c'} />
      <CancleTxt className={isSendReport ? 'disabled' : ''}>
        ยกเลิกคำขอ
      </CancleTxt>
    </CancelIcon>
  )

  const leftContent = (
    <LeftBox>
      <Row type="flex" align="middle">
        {isSendReport && (
          <Col span={4}>
            {showRadio ? (
              <CheckIconBox
                onClick={() => onSelectLists(task)}
                isSelected={isSelected}
              />
            ) : (
              <Space />
            )}
          </Col>
        )}
        <Col span={isSendReport ? 20 : 24}>
          <TxtBold>
            {ProjectCode} - {ProjectNameTH}
          </TxtBold>
          <TxtReg>ตรวจครั้งที่ {CheckNumber}</TxtReg>
          {cancelIcon}
        </Col>
      </Row>
    </LeftBox>
  )

  const rightContent = (
    <RightBox>
      <Row type="flex" align="middle" justify="space-around">
        {isConstruct && (
          <Col>
            <Score className={CheckStatus === 'Pass' ? 'pass' : 'fail'}>
              <p>{numberFormat(InspectionScore)}</p>
              <p>คะแนน</p>
            </Score>
          </Col>
        )}
        <Col>
          <SignDescription label="ลงชื่อรับทราบ" isCorrect={HasSign === 'Y'} />
        </Col>
      </Row>
    </RightBox>
  )

  return (
    <CardContent>
      <Row type="flex" align="middle">
        <Col span={12}>{leftContent}</Col>
        <Col span={12}>{rightContent}</Col>
      </Row>
    </CardContent>
  )
}

const CardContent = styled.div`
  border-radius: 10px;
  background-color: #fff;
  margin-bottom: 12px;
`

const LeftBox = styled.div`
  padding: 16px 20px;
  border-right: 1px solid #e8ebf0;
`

const RightBox = styled.div`
  padding: 20px;
`

const TxtBold = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 20px;
  color #000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const TxtReg = styled.div`
  font-family: 'DB Helvethaica X 55 Regular';
  font-size: 20px;
  color #000;
`

const Space = styled.div`
  width: 40px;
  height: 1px;
  float: left;
`

const Score = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 24px;
  &.pass {
    color: #5bc79e;
  }

  &.fail {
    color: #d0494c;
  }

  p {
    line-height: 1.1;
    margin-bottom: 1px;
  }
`

const CancleTxt = styled.span`
  font-family: 'DB Helvethaica X 55 Regular';
  margin-left : 10px;
  color #d0494c;
  font-size: 18px;
  &.disabled{
    color : #8b99a7;
  }
`

const CancelIcon = styled.div`
  margin-top: -10px;
`
const ModalContent = styled.div`
  text-align: center;
  border-radius: 10px;
  .message {
    padding-top: 18px;
    p {
      font-size: 20px;
      color: #000;
      margin-bottom: 0;
    }
  }
  img {
    width: 60px;
  }
`
export default InproCard
