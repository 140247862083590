import React, { useState, useEffect } from 'react'
import { Modal, Row, Col } from 'antd'
import styled from 'styled-components'
import _ from 'lodash'

import TextArea from 'antd/lib/input/TextArea'
import { SecondaryButton, PrimaryButton } from 'Components/Shared/Button'

type Props = {
  onClose: () => void
  onSubmit: (value: string) => void
  visible?: boolean
  title?: string
  placeholder?: string
}

const RejectModal = (props: Props) => {
  const {
    visible = false,
    onClose,
    onSubmit,
    title = 'ตีกลับให้ผู้รับเหมา',
    placeholder = 'เหตุผลที่ตีกลับ',
  } = props
  const [remark, setRemark] = useState('')

  useEffect(() => {
    setRemark('')
  }, [visible])

  return (
    <Modal
      className="reject-modal"
      title={false}
      centered
      visible={visible}
      footer={false}
      closable={false}
    >
      <Body>
        <Header>{title}</Header>
        <Input>
          <TextArea
            value={remark}
            onChange={evt => setRemark(_.trimStart(evt.target.value))}
            placeholder={placeholder}
            maxLength={255}
          />
        </Input>
        <ButtonSection>
          <Row type="flex" justify="end">
            <Col>
              <div className="btn-submit">
                <SecondaryButton
                  text="ยกเลิก"
                  disabled={false}
                  onClick={() => onClose()}
                />
              </div>
            </Col>
            <Col>
              <div className="btn-submit">
                <PrimaryButton
                  text="ส่งเหตุผลที่ตีกลับ"
                  disabled={remark === ''}
                  onClick={() => onSubmit(remark.trim())}
                />
              </div>
            </Col>
          </Row>
        </ButtonSection>
      </Body>
    </Modal>
  )
}

const Header = styled.div`
  text-align: center;
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 24px;
  color: #000;
`

const Body = styled.div``

const Input = styled.div`
  margin-top: 10px;
  .ant-input,
  .ant-input:focus,
  .ant-input:hover {
    font-family: 'DB Helvethaica X 55 Regular';
    font-size: 20px;
    height: 112px;
    border: solid 1px #e4e7eb;
  }
`

const ButtonSection = styled.div`
  margin-top: 32px;
  .btn-submit {
    width: 132px;
    margin-left: 16px;
  }
`

export default RejectModal
