import { createStandardAction, createAsyncAction } from 'typesafe-actions'
import {
  GetReportListReq,
  GetReportListResp,
  Filter,
  FilterByQCType,
  GetReportDetailReq,
  GetReportDetailResp,
  GetDocumentDetailReq,
  GetDocumentDetailResp,
  GetUnitByQCTypeListResp,
  GetUnitByQCTypeListReq,
  GetUnitCheckListResp,
  GetUnitCheckListReq,
} from './types'

export const getReportListAction = createAsyncAction(
  'admin/report/unit/GET_LIST_REQ',
  'admin/report/unit/GET_LIST_RESP',
  'admin/report/unit/GET_LIST_FAIL'
)<GetReportListReq, GetReportListResp, any>()

export const getReportDetailAction = createAsyncAction(
  'admin/report/unit/GET_DETAIL_REQ',
  'admin/report/unit/GET_DETAIL_RESP',
  'admin/report/unit/GET_DETAIL_FAIL'
)<GetReportDetailReq, GetReportDetailResp, any>()

export const getDocumentDetailAction = createAsyncAction(
  'admin/report/unit/GET_DOCUMENT_REQ',
  'admin/report/unit/GET_DOCUMENT_RESP',
  'admin/report/unit/GET_DOCUMENT_FAIL'
)<GetDocumentDetailReq, GetDocumentDetailResp, any>()

export const getUnitByQCTypeListAction = createAsyncAction(
  'admin/report/unit/GET_QCTYPE_LIST_REQ',
  'admin/report/unit/GET_QCTYPE_LIST_RESP',
  'admin/report/unit/GET_QCTYPE_LIST_FAIL'
)<GetUnitByQCTypeListReq, GetUnitByQCTypeListResp, any>()

export const getUnitCheckListAction = createAsyncAction(
  'admin/report/unit/GET_UNIT_CHECKLIST_REQ',
  'admin/report/unit/GET_UNIT_CHECKLIST_RESP',
  'admin/report/unit/GET_UNIT_CHECKLIST_FAIL'
)<GetUnitCheckListReq, GetUnitCheckListResp, any>()

export const updateFilter = createStandardAction(
  'admin/report/unit/UPDATE_FILTER_ACTION'
)<Filter, void>()

export const updateQCTypeFilter = createStandardAction(
  'admin/report/unit/UPDATE_QCTYPE_FILTER_ACTION'
)<FilterByQCType, void>()

export const clearFilter = createStandardAction(
  'admin/report/unit/CLEAR_FILTER_ACTION'
)<void>()

export const sortingTask = createStandardAction('admin/report/unit/SORTING')<{
  name: string
  sort?: string
}>()

export const sortingQcTypeTask = createStandardAction(
  'admin/report/unit/SORTING_QC'
)<{
  name: string
  sort?: string
}>()

export const exportReportListAction = createAsyncAction(
  'admin/report/unit/EXPORT_LIST_REQ',
  'admin/report/unit/EXPORT_LIST_RESP',
  'admin/report/unit/EXPORT_LIST_FAIL'
)<GetReportListReq, GetReportListResp, any>()

export const exportReportQCTypeListAction = createAsyncAction(
  'admin/report/unit/EXPORT_QCTYPE_LIST_REQ',
  'admin/report/unit/EXPORT_QCTYPE_LIST_RESP',
  'admin/report/unit/EXPORT_QCTYPE_LIST_FAIL'
)<GetUnitByQCTypeListReq, GetUnitByQCTypeListResp, any>()
