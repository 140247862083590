import { put, take, select, takeEvery } from 'redux-saga/effects'
import { getType } from 'typesafe-actions'
import * as actions from './tasklane.actions'
import { setTaskListById } from '../activity/activity.actions'
import {
  getDashboardFilterSelector,
  getDashboardQuerySelector,
  sortingSelector,
  getOriginalSelector,
} from 'features/tasklane/tasklane.selector'
import { getWbsPathMapSelector } from 'features/wbs/wbs.selector'
import { filterActivity } from 'features/activity/activity.function'
import { sortingTask } from './tasklane.function'
import * as loginActions from 'features/login/login.actions'
import keyByFieldName from 'utils/keyByFieldName'
import { dateCompare } from 'utils/helper'

function* initTaskList(action: any) {
  const data = action.payload.ResponseData
  const completeOnly = data['Complete']
  const inProgressOnly = data['InProgress']
  const todoOnly = data['ToDo']

  const allTasks = todoOnly.concat(inProgressOnly).concat(completeOnly)
  const wbsPathMap = yield select(getWbsPathMapSelector)
  const list = allTasks.map((item: any) => {
    item.WbsName = wbsPathMap[item.WbsID]
    return item
  })
  const tasksById = keyByFieldName(list, 'ActivityID')
  yield put(setTaskListById(tasksById))
}

function* watchFilterDashboard(action: any) {
  const query = yield select(getDashboardQuerySelector)
  const sorting = yield select(sortingSelector)
  const filter = yield select(getDashboardFilterSelector)

  let startDate = filter.date.start
  let endDate = filter.date.end
  if (
    dateCompare(startDate, query.date.start) &&
    dateCompare(endDate, query.date.end)
  ) {
    const original = yield select(getOriginalSelector)
    let list = filterActivity(original.ToDo, filter)
    const todo = sortingTask(list, sorting.todo, 'BaselineStartDate')

    list = filterActivity(original.InProgress, filter)
    const inProgress = sortingTask(list, sorting.progress, 'BaselineFinishDate')

    list = filterActivity(original.Complete, filter)
    const completed = sortingTask(list, sorting.complete, 'BaselineFinishDate')

    yield put(actions.updateListAction({ todo, inProgress, completed }))
  } else {
    yield put(
      actions.setQueryDashboard({
        date: { start: startDate, end: endDate },
      })
    )

    yield put(actions.refreshTasklaneAction())
  }
}

function* verifyFail(action: any) {
  const { payload = {} } = action
  let { response } = payload
  if (!response) {
    response = { status: 401 }
  }

  const { status = 200 } = response
  if (status === 401) {
    yield put(loginActions.logoutUser())
  }
  yield take(actions.fetchTasklaneAsyncAction.failure)
}

function* allSaga() {
  yield takeEvery(
    getType(actions.fetchTasklaneAsyncAction.success),
    initTaskList
  )

  yield takeEvery(getType(actions.fetchTasklaneAsyncAction.failure), verifyFail)
  yield takeEvery(getType(actions.filterDashboard), watchFilterDashboard)
}

export default allSaga
