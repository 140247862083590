import React from 'react'
import styled from 'styled-components'
import { Row, Col, Progress } from 'antd'
import moment from 'moment-timezone'
import { Link } from 'react-router-dom'
import { translate } from 'i18n'
import TKeys from 'i18n/translationKey'
import { WarningIcon } from 'Components/Shared/Icon'
import {
  getCmTaskStatusText,
  getDelayedDate,
  toDateText,
  toTimeText,
  toMomentDateTime,
} from 'utils/datetime'
import { TypeOfTask } from 'features/cmpj/cmdashboard/types'
import { textToLowerCase } from 'utils/format'
import { Activity } from 'features/cmpj/cmdashboard/types'
import { ProgressCircle } from 'Components/Shared'
import { ContructionTagName } from 'Components/Shared'
import { isUnit, displayCategory } from 'utils/helper'
import iconNoImg2 from 'assets/img/ic-no-image2.png'

const text = {
  day: translate(TKeys.Common.day),
  start_in_day: translate(TKeys.TaskLane.start_within_day),
  delaye_in_day: translate(TKeys.TaskLane.delayed_by_day),
  delayed_day: 'เสร็จช้ากว่ากำหนด',
  remain: 'เหลืออีก',
  start_day: translate(TKeys.TaskLane.plan_start_date),
  end_day: translate(TKeys.TaskLane.plan_end_date),
}

interface Props {
  type: TypeOfTask
  task: Activity
  search: string
  onClick?: (code: string) => void
}

const Card: React.FC<Props> = ({ type, task, search, onClick }) => {
  const {
    StartDate,
    FinishDate,
    BaselineStartDate,
    BaselineFinishDate,
    RowID: id,
    ActivityName: activityTitle,
    TaskStatus: status,
    AppointmentDate: appointmentDate,
    Unit,
    QCTypeName,
    ActivityID,
    Zone,
    FloorName,
  } = task

  let delayed = getDelayedDate(BaselineFinishDate)

  let content
  let lane
  if (delayed < 0) {
    lane = <span className="lane-card-delayed" />
    content = (
      <div className="status status-danger">
        <WarningIcon />
        <span className="countdown">{getCmTaskStatusText(task, type)}</span>
      </div>
    )
  } else {
    content = (
      <div className={'status status-default'}>
        <span className="countdown">{getCmTaskStatusText(task, type)}</span>
      </div>
    )
  }

  const taskStatus = textToLowerCase(status)
  const startDate = toMomentDateTime(BaselineStartDate)
  const finishDate = toMomentDateTime(BaselineFinishDate)
  const appointDate = toMomentDateTime(appointmentDate)
  const renderAppointDate = (
    <Detail>
      วันที่นัดหมายตรวจ {toDateText(appointDate)}, {toTimeText(appointDate)}
    </Detail>
  )

  return (
    <Link to={`/activity/${ActivityID}${search}`}>
      <MainCard>
        {lane}
        <Row type="flex" justify="space-between">
          <Col span={17}>
            {content}
            <Title>{activityTitle}</Title>
            <Detail>{displayCategory(task)}</Detail>
          </Col>
          <Col>
            <MyProgress>
              <ProgressCircle task={task} width={48} />
            </MyProgress>
          </Col>
        </Row>
        <Row type="flex">
          <Col span={24}>
            <TextDate>
              {text.start_day} {toDateText(startDate)}
              <span>
                {text.end_day} {toDateText(finishDate)}
              </span>
            </TextDate>
            {type == TypeOfTask.Todo && renderAppointDate}

            <Row type="flex" align="middle" justify="space-between">
              <Col>
                {task.ConstructionTypeName && (
                  <ContructionTagName name={task.ConstructionTypeName} />
                )}
              </Col>
              <Col>
                {' '}
                {task.Reject && task.Reject.RejectReasonRemark === 'I' && (
                  <img src={iconNoImg2} width="36" />
                )}{' '}
              </Col>{' '}
            </Row>
          </Col>
        </Row>
      </MainCard>
    </Link>
  )
}

const MainCard = styled.div`
  background: #fff;
  border-radius: 10px;
  padding: 16px;
  background-clip: padding-box;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.03);
  margin-bottom: 12px;
  cursor: pointer;
  color: #000;
  width: 100%;
  position: relative;
`

const Title = styled.p`
  font-size: 20px;
  margin-bottom: 4px;
  font-family: 'DB Helvethaica X 75 Bd';
`

const Detail = styled.p`
  font-size: 18px;
  margin-bottom: 4px;
  font-family: 'DB Helvethaica X 55 Regular';
`

const TextDate = styled.div`
  font-size: 18px;
  font-family: 'DB Helvethaica X 55 Regular';

  span {
    margin-left: 8px;
  }
`

const MyProgress = styled.div`
  .ant-progress-text {
    font-size: 16px !important;
  }
`

export default Card
