import React from 'react'
import { Row, Col, Collapse } from 'antd'
import CheckAsOptions from '../Filters/CheckAsOptions'
import styled from 'styled-components'

import TreeItem from './TreeItem'

const { Panel } = Collapse

type Props = {
  items: Mock[]
}

type Mock = {
  id: string
  name: string
  detail?: string
  items?: Mock[]
  isSelected?: boolean
  readOnly?: boolean
  onChange?: (value: boolean, item: any) => void
  tag: any
}

const TreeCollapse: React.FunctionComponent<Props> = props => {
  const { items } = props

  const renderCollapse = (data: Mock[]) => {
    return data.map((item, index) => {
      const { items = [] } = item
      const hasItem = items.length > 0

      return <TreeItem key={index} item={item} />
    })
  }

  return <Content>{renderCollapse(items)}</Content>
}

const Content = styled.div`
  padding: 20px 24px;
  min-height: 550px;
  max-height: 1500px;
  overflow-y: scroll;
  font-family: 'DB Helvethaica X 55 Regular';
  background-color: white;
  border-radius: 5px;
  .colapse-tree {
    background-color: #fff;
    border: none;
    .ant-collapse-item {
      border: none;
    }
    .ant-collapse-content {
      border: none;
      .ant-collapse-content-box {
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }
`

export default TreeCollapse
