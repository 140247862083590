import React from 'react'
import styled from 'styled-components'
import { Row, Col, Layout } from 'antd'
import { PrimaryButton } from 'Components/Shared/Button'
import DefectScore from 'Components/Defect/DefectScore'
import { DefectItem } from 'features/report/constructPlan/types'
import { NcrPrefix } from 'Components/Shared'

const { Footer } = Layout

type Props = {
  defect: DefectItem
  onReject?: () => void
  onApprove?: () => void
}

const FooterView: React.FC<Props> = ({ defect, onReject, onApprove }) => {
  return (
    <Footer className="detail-page-footer">
      <FooterContent>
        <Row type="flex" justify="space-between" align="middle">
          <Col />

          <Col>
            <ContentLine>
              <Score>
                <DefectScore
                  prefix={<NcrPrefix defect={defect} />}
                  suffix={<Suffix>คะแนน</Suffix>}
                  pinStatus={defect.PinStatus}
                  pinScore={defect.Score}
                />
              </Score>
              <Button>
                <PrimaryButton
                  text="ไม่อนุมัติ"
                  onClick={onReject}
                  btnType="Cancel"
                />
              </Button>
              <Button>
                <PrimaryButton
                  text="อนุมัติ"
                  onClick={onApprove}
                  btnType="Approve"
                />
              </Button>
            </ContentLine>
          </Col>
        </Row>
      </FooterContent>
    </Footer>
  )
}

const FooterContent = styled.div`
  max-width: 1110px;
  margin: 0 auto;
  color: #000;
  font-size: 20px;
  padding-right: 20px;
`

const ContentLine = styled.div`
  float: left;
  display: inline-flex;
  align-items: center;
`

const Button = styled.div`
  width: 160px;
  margin-left: 16px;
`

const Score = styled.span`
  font-size: 44px;
`

const Suffix = styled.span`
  margin-left: 16px;
  margin-right: 32px;
  font-size: 20px;
  font-family: 'DB Helvethaica X 55 Regular';
`

export default FooterView
