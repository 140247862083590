import { createStandardAction, createAsyncAction } from 'typesafe-actions'
import { GetReportListReq, GetReportListResp, Filter } from './types'

export const getReportListAction = createAsyncAction(
  'admin/report/daily/GET_LIST_REQ',
  'admin/report/daily/GET_LIST_RESP',
  'admin/report/daily/GET_LIST_FAIL'
)<GetReportListReq, GetReportListResp, any>()

export const updateFilter = createStandardAction(
  'admin/report/daily/UPDATE_FILTER_ACTION'
)<Filter, void>()

export const clearFilter = createStandardAction(
  'admin/report/daily/CLEAR_FILTER_ACTION'
)<void>()

export const sortingTask = createStandardAction('admin/report/daily/SORTING')<{
  name: string
  sort?: string
}>()

export const exportReportListAction = createAsyncAction(
  'admin/report/daily/EXPORT_LIST_REQ',
  'admin/report/daily/EXPORT_LIST_RESP',
  'admin/report/daily/EXPORT_LIST_FAIL'
)<GetReportListReq, GetReportListResp, any>()
