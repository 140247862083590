import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { RootState } from 'typings/RootState'
import { Row, Col, DatePicker, Slider, Button, Input, InputNumber } from 'antd'
import { translate } from 'i18n'
import TKeys from 'i18n/translationKey'
import styled from 'styled-components'
import moment, { Moment } from 'moment-timezone'
import {
  getTaskByIdList,
  getCurrentActivity,
  submitErrorSelector,
  getOriginalActivity,
} from 'features/activity/activity.selector'

import { DATE_PICKER_DATE_FORMAT } from 'config'
import {
  updateOriginalActivity,
  UpdateFieldPayload,
} from 'features/activity/activity.actions'
import { toMoment, toDateText } from 'utils/datetime'
import { color } from 'utils/color-map'

import HandleDocument from './HandleDocument'
import HandleImage from './HandleImage'
import { notification } from 'antd'
import * as activityActions from 'features/activity/activity.actions'

const datePickerPlaceholder = 'DD/MM/YYYY'

type FormProps = {
  history: any
  isSubmitting: boolean
  onChange: (data: UpdateFieldPayload) => void
  onActivityDetailSave: () => void
  onImageOpen: () => void
  onDocumentOpen: () => void
  onThumbnailOpen: (index: number) => void
}

type Props = ReduxProps & DispatchProps & FormProps

const DetailForm: React.FunctionComponent<Props> = ({
  currentActivity,
  original,
  isSubmitting,
  submitError,
  projectProgressOptions,
  onChange,
  onActivityDetailSave,
  updateOriginalActivity,
  onImageOpen,
  onDocumentOpen,
  onThumbnailOpen,
}) => {
  const progress = currentActivity.Progress || 0
  const isPending = original.TaskStatus === 'PEND'

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(activityActions.clearSubmitError())
  }, [dispatch])

  useEffect(() => {
    if (submitError) {
      notification.error({
        key: 'save_activity',
        message: 'Error',
        description: submitError,
      })
    }
  }, [submitError])

  const onProgress = (value: any) => {
    if (value < original.Progress) {
      return
    }

    onChange({
      fieldName: 'Progress',
      value,
    })

    let date: string = ''
    if (value === 100) {
      date = toDateText(moment())
    }

    onChange({
      fieldName: 'ActualFinishDate',
      value: date,
    })
  }

  const onEditProgress = (value: number | undefined) => {
    value = Number(value)
    if (value) {
      value = Math.floor(value)
      onProgress(value)
    }
  }

  const validateProgress = (evt: any) => {
    const code = evt.keyCode
    if ((code >= 48 && code <= 57) || code === 8) return

    evt.preventDefault()
  }

  const disabledFutureDate = (current: Moment | null | undefined): boolean => {
    let valid = moment()
      .startOf('day')
      .add(1, 'day')
    return (current && current >= valid) || false
  }

  const disabledPastDate = (current: Moment | null | undefined): boolean => {
    let valid = moment().startOf('day')
    return (current && current < valid) || false
  }

  const onUpdateOriginal = (item: any) => {
    dispatch(updateOriginalActivity(item))
  }

  const start_txt = translate(TKeys.ActivityDetail.actual_start_date)
  const expect_txt = translate(TKeys.ActivityDetail.expect_end_date)

  const onSave = () => {
    if (currentActivity.ActualStartDate === '') {
      notification.warning({
        key: 'valid_save_activity',
        message: 'คําเตือน',
        description: `กรุณาใส่ค่า ${start_txt}`,
      })
    } else if (progress !== 100 && currentActivity.ExpectFinishDate === '') {
      notification.warning({
        key: 'valid_save_activity',
        message: 'คําเตือน',
        description: `กรุณาใส่ค่า ${expect_txt}`,
      })
    } else {
      onActivityDetailSave()
    }
  }

  const disabledStartDate = original.Progress !== 0
  const lastProgress = currentActivity.LastCurProgress
  let finishDate
  if (progress === 100) {
    finishDate = (
      <div className="input-with-icon input-with-icon-disabled">
        <div className="label">
          {translate(TKeys.ActivityDetail.actual_end_date)}
        </div>
        <DatePicker
          className="date-picker"
          disabled
          format={DATE_PICKER_DATE_FORMAT}
          placeholder={datePickerPlaceholder}
          value={toMoment(currentActivity.ActualFinishDate)}
        />
      </div>
    )
  } else {
    finishDate = (
      <div
        className={`input-with-icon ${
          isPending ? 'input-with-icon-disabled' : ''
        }`}
      >
        <div className="label">{expect_txt}</div>
        <DatePicker
          className="date-picker"
          onChange={date => {
            onChange({
              fieldName: 'ExpectFinishDate',
              value: toDateText(date),
            })
          }}
          disabledDate={disabledPastDate}
          format={DATE_PICKER_DATE_FORMAT}
          placeholder={datePickerPlaceholder}
          value={toMoment(currentActivity.ExpectFinishDate)}
          disabled={isPending}
        />
      </div>
    )
  }

  const sliderDiv = (
    <Slider
      min={lastProgress}
      max={100}
      value={progress}
      disabled={isSubmitting || original.Progress === 100 || isPending}
      onChange={onProgress}
    />
  )
  const progressDiv =
    original.Progress === 0 ? (
      <ProgressZeroBar>{sliderDiv}</ProgressZeroBar>
    ) : (
      <ProgressBar>{sliderDiv}</ProgressBar>
    )

  return (
    <Col span={16}>
      <Col className="activity-form">
        <div className="form-label">
          {translate(TKeys.ActivityDetail.day_of_work_label)}
        </div>

        <Row gutter={16}>
          <Col span={12}>
            <div
              className={`input-with-icon ${
                disabledStartDate ? 'input-with-icon-disabled' : ''
              }`}
            >
              <div className="label">
                {translate(TKeys.ActivityDetail.actual_start_date)}
              </div>
              <DatePicker
                disabled={disabledStartDate}
                onChange={date => {
                  onChange({
                    fieldName: 'ActualStartDate',
                    value: toDateText(date),
                  })
                }}
                disabledDate={disabledFutureDate}
                format={DATE_PICKER_DATE_FORMAT}
                placeholder={datePickerPlaceholder}
                value={toMoment(currentActivity.ActualStartDate)}
              />
            </div>
          </Col>
          <Col span={12} className="datetime-form">
            {finishDate}
          </Col>
        </Row>

        <SpaceLine />

        <Row>
          <Col span={20}>
            <div className="form-label">
              {translate(TKeys.ActivityDetail.progress_label)} (%)
            </div>
            <Row>
              <Col span={12} style={{ width: `${lastProgress}%` }}>
                <Line />
              </Col>

              <Col span={12} style={{ width: `${100 - lastProgress}%` }}>
                {progressDiv}
              </Col>
            </Row>
          </Col>
          <Col span={4}>
            <ProgressInput>
              <InputNumber
                min={lastProgress}
                max={100}
                value={progress}
                disabled={
                  isSubmitting || original.Progress === 100 || isPending
                }
                style={css.input}
                onChange={onEditProgress}
                onKeyDown={validateProgress}
              />
              <span className="text">%</span>
            </ProgressInput>
          </Col>
        </Row>

        <SpaceLine />

        <Row>
          <Col span={12}>
            <HandleImage
              activity={currentActivity}
              original={original}
              onChange={onUpdateOriginal}
              onImageOpen={onImageOpen}
              onThumbnailOpen={onThumbnailOpen}
              disabled={isPending}
            />
          </Col>

          <Col span={12}>
            <HandleDocument
              original={original}
              onChange={onUpdateOriginal}
              onDocumentOpen={onDocumentOpen}
              disabled={isPending}
            />
          </Col>
        </Row>

        <SpaceLine />

        <Row>
          <Col span={24}>
            <div className="form-label">
              {translate(TKeys.ActivityDetail.note)}
            </div>
            <div>
              <Input.TextArea
                value={original.Remark}
                style={{ color: 'black', fontSize: '20px' }}
                onChange={evt => {
                  original.Remark = evt.target.value
                  onUpdateOriginal({ ...original })
                }}
                rows={4}
                autoSize={{ minRows: 4, maxRows: 4 }}
                maxLength={255}
                disabled={isPending}
              />
            </div>
          </Col>
        </Row>

        <div className="form-actions">
          <Button
            type="primary"
            title={translate(TKeys.ActivityDetail.submit_button_label)}
            className={
              isSubmitting ? 'btn--submit btn__disabled' : 'btn--submit'
            }
            disabled={
              isSubmitting ||
              original.Progress === 100 ||
              original.TaskStatus === 'PEND' ||
              original.Progress === progress
            }
            loading={isSubmitting}
            onClick={onSave}
          >
            {translate(TKeys.ActivityDetail.submit_button_label)}
          </Button>
        </div>
      </Col>
    </Col>
  )
}

const css: any = {
  input: {
    fontSize: '20px',
    borderRadius: '5px',
    float: 'right',
  },
}

const SpaceLine = styled.div`
  margin-top: 16px;
`

const ProgressZeroBar = styled.div`
  width: 100%;
  .ant-slider {
    margin: 0px;
  }

  .ant-slider > .ant-slider-rail {
    border-radius: 6px;
    height: 8px;
  }

  .ant-slider > .ant-slider:hover,
  .ant-slider-track {
    border-radius: 6px;
    background-color: ${color.YELLOW} !important;
    height: 8px;
  }

  .ant-slider-disabled .ant-slider-track {
    background-color: ${color.YELLOW} !important;
  }

  .ant-slider > .ant-slider-handle {
    width: 24px;
    height: 24px;
    margin-top: -8px;
    background-color: #fefefe;
    border: solid 2px #dddddd;
    z-index: 3;
  }
`

const ProgressBar = styled.div`
  width: 100%;
  .ant-slider {
    margin: 0px;
  }

  .ant-slider > .ant-slider-rail {
    border-radius: 0px;
    border-bottom-right-radius: 6px;
    border-top-right-radius: 6px;
    height: 8px;
  }

  .ant-slider > .ant-slider:hover,
  .ant-slider-track {
    border-radius: 0px;
    background-color: ${color.YELLOW} !important;
    border-bottom-right-radius: 6px;
    border-top-right-radius: 6px;
    height: 8px;
  }

  .ant-slider-disabled .ant-slider-track {
    background-color: ${color.YELLOW} !important;
  }

  .ant-slider > .ant-slider-handle {
    width: 24px;
    height: 24px;
    margin-top: -8px;
    background-color: #fefefe;
    border: solid 2px #dddddd;
    z-index: 3;
  }
`

const ProgressInput = styled.div`
  display: flex;
  align-items: flex-end;
  width: 90px;
  height: 70px;
  float: right;

  .ant-input-number {
    width: 60px;
    height: 48px;
    display: flex;
    align-items: center;
  }

  .ant-input-number > .ant-input-number-handler-wrap {
    display: none;
  }

  .ant-input-number > .ant-input-number-input-wrap > .ant-input-number-input {
    text-align: center;
  }

  .text {
    margin-left: 5px;
    height: 35px;
    font-size: 18px;
  }
`

const Line = styled.div`
  width: 100%;
  background-color: ${color.GREEN};
  height: 8px;
  margin-top: 4px;
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
`

const mapStateToProps = (state: RootState) => {
  return {
    taskById: getTaskByIdList(state),
    currentActivity: getCurrentActivity(state),
    submitError: submitErrorSelector(state),
    projectProgressOptions: [0, 25, 50, 75, 100],
    original: getOriginalActivity(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {
  updateOriginalActivity,
}

type DispatchProps = typeof mapDispatchToProps

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DetailForm)
