import React from 'react'
import styled from 'styled-components'
import { ConstructPlanReport } from 'features/report/constructPlan/types'
import { getDateText } from 'utils/getText'
import { ReportTaskInfo, ReportScore } from 'Components/Shared/ReportInfo'

type Props = {
  report: ConstructPlanReport
}

const ReportTitle: React.ComponentType<Props> = props => {
  const { report } = props

  const renderInfo = (title: string, value: any) => {
    return <ReportTaskInfo title={title} value={value} />
  }

  return (
    <ScoreArea>
      <HeaderScore>
        <ReportScore
          isPass={report.CheckStatus === 'Pass'}
          score={report.InspectionScore}
        />
      </HeaderScore>
      <TaskInfo>
        {renderInfo('รหัสโครงการ', report.ProjectCode)}
        {renderInfo('โครงการ', report.ProjectNameTH)}
        {renderInfo('ตรวจครั้งที่', report.CheckNumber)}
        {renderInfo('วันที่ตรวจ', getDateText(report.InspectDate))}
        {renderInfo('วันที่ส่งรายงาน', getDateText(report.CheckedDate))}
      </TaskInfo>
    </ScoreArea>
  )
}

const ScoreArea = styled.div`
  padding: 140px 40px 0 86px;
`

const HeaderScore = styled.div`
  width: 100%;
  text-align: center;
`

const TaskInfo = styled.div`
  padding-top: 25px;
  font-family: 'DB Helvethaica X 55 Regular';
  font-size: 20px;
  color: #000;
`

export default ReportTitle
