import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import RootState from 'typings/RootState'

interface MenuProps {
  activePage?: string
}

interface ProjectSelectorProps {
  projects: any
  activeProject: number
}

const projects = [
  {
    id: 1,
    title: 'Project - 1',
  },
  {
    id: 2,
    title: 'Project - 2',
  },
]

const ProjectSelector = (props: ProjectSelectorProps) => {
  return (
    <select>
      <option value="">Choose Project</option>
      <option value="1">Project C</option>
    </select>
  )
}

const ProtectedMenu = (props: MenuProps) => {
  return (
    <ul className="nav navbar-nav navbar-left main-menu">
      <li>
        <Link to="/" className="active">
          High Level
        </Link>
      </li>
      <li>
        <Link
          to="/project"
          className={props.activePage === '/project' ? 'active' : ''}
        >
          โครงการ
        </Link>
      </li>
      <li>
        <ProjectSelector projects={projects} activeProject={10} />
      </li>
      <li>
        <Link
          to="/logout"
          className={props.activePage === '/logout' ? 'active' : ''}
        >
          Log Out
        </Link>
      </li>
    </ul>
  )
}

type Props = ReduxProps

const NavigationBar: React.FunctionComponent<Props> = props => {
  const homePath = '/'

  const [activePage, setActivePage] = useState(homePath)

  useEffect(() => {
    const location = window.top.location || homePath

    setActivePage(location.pathname)
  }, [])

  return (
    <div className="navbar-container">
      <div className="collapse navbar-collapse" id="mainnav-bar">
        <ProtectedMenu activePage={activePage} />
      </div>
    </div>
  )
}

const mapStateToProps = (state: RootState) => {
  const { protectedMenu } = state.login

  return {
    protectedMenu,
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

export default connect(mapStateToProps)(NavigationBar)
