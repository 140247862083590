import React from 'react'
import { Row, Col } from 'antd'
import { translate } from 'i18n'
import TKeys from 'i18n/translationKey'
import { ActivityFilter } from 'features/activity/activity.types'
import { TaskStatus } from 'features/tasklane/tasklane.types'

import CheckAsOptions from './CheckAsOptions'

interface Props {
  filter: ActivityFilter
  onChange(filter: ActivityFilter): void
}

const NameList: string[] = [
  translate(TKeys.Dashboard.todo),
  translate(TKeys.Dashboard.approved),
  translate(TKeys.Dashboard.pending),
  translate(TKeys.Dashboard.not_approved),
]

const ValueList: TaskStatus[] = ['INIT', 'APPV', 'PEND', 'REJT']

const StatusContent: React.FunctionComponent<Props> = ({
  filter,
  onChange,
}) => {
  const onValueChange = (val: TaskStatus) => {
    const index = filter.status.findIndex(item => item === val)
    if (index >= 0) {
      filter.status.splice(index, 1)
    } else {
      filter.status.push(val)
    }
    onChange(filter)
  }

  const onClear = () => {
    filter.status = []
    onChange(filter)
  }
  const status = filter.status

  const content = ValueList.map((item, index) => {
    return (
      <Col span={24} key={index}>
        <CheckAsOptions
          key={index}
          title={NameList[index]}
          value={status.includes(item)}
          item={item}
          onChange={onValueChange}
        />
      </Col>
    )
  })

  const clearBtn =
    filter.status.length > 0 ? (
      <span onClick={onClear} className="filter-clear std-font-regular">
        {translate(TKeys.Common.cancel_all)}
      </span>
    ) : (
      undefined
    )

  return (
    <div className="std-font-regular" style={css.page}>
      <Row>
        <Col span={24}>
          <div className="std-font-bold" style={css.title}>
            {translate(TKeys.Dashboard.status)}
            {clearBtn}
          </div>
        </Col>
      </Row>
      <Row className="pop-over-container-choices">{content}</Row>
    </div>
  )
}

const css: any = {
  page: {
    width: '296px',
    height: 'auto',
    padding: '25px 24px',
  },
  title: {
    textAlign: 'left',
    color: 'black',
    fontSize: '20px',
    marginBottom: '25px',
  },
  cancel: {
    float: 'right',
    color: '#003168',
    fontSize: '18px',
  },
}

export default StatusContent
