import React, { useState } from 'react'
import { Row, Col } from 'antd'
import styled from 'styled-components'
import { PMRReport, DefectItem } from 'features/pmr/types'

import ReportHeader from './ReportHeader'
import ReportItem from './ReportItem'
import img from 'assets/img/img-nodefect.png'
import { selectSortingDefect } from 'features/pmr/selector'
import { sortingDefect } from 'features/pmr/actions'
import { connect, useDispatch } from 'react-redux'
import RootState from 'typings/RootState'

type Props = {
  report: PMRReport
  onClick?: (item: DefectItem) => void
  onApprove?: (item: DefectItem) => void
  onReject?: (item: DefectItem) => void
  mode: 'PMR' | 'CM' | 'QC'
} & ReduxProps

const DefectList: React.FunctionComponent<Props> = props => {
  const { report, onClick, onApprove, onReject, sorting, mode } = props
  const dispatch = useDispatch()

  const onSort = (name: string) => {
    dispatch(sortingDefect({ name }))
  }

  const noDefect = (
    <Area>
      <Empty>
        <img src={img} />
        <p>ไม่มีรายการ Defect</p>
      </Empty>
    </Area>
  )

  const content = report.DefectList.map((item, index) => {
    return (
      <ReportItem
        item={item}
        key={index}
        onClick={onClick}
        onApprove={onApprove}
        onReject={onReject}
        showButton={mode !== 'CM'}
        mode={mode}
      />
    )
  })

  const defectList = (
    <TableArea>
      <ReportHeader onSort={onSort} sorting={sorting} mode={mode} />
      {content}
    </TableArea>
  )

  return <>{report.DefectList.length > 0 ? defectList : noDefect}</>
}

const Area = styled.div`
  width: 100%;
  height: 90%;
  text-align: center;
  position: relative;
`

const Empty = styled.div`
  margin: 0;
  position: absolute;
  top: 40%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  p {
    font-size: 20px;
    margin-top: 24px;
  }
  img {
    width: 144px;
  }
`

const TableArea = styled.div`
  margin-top: 20px;
`

const mapStateToProps = (state: RootState) => {
  return {
    sorting: selectSortingDefect(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DefectList)
