import { createStandardAction, createAsyncAction } from 'typesafe-actions'
import {
  GetCheckListReq,
  GetCheckListByIdReq,
  DeleteCheckListReq,
  CreateCheckListReq,
  UpdateCheckListReq,
  CheckListItem,
  CheckListDetail,
  CheckList,
  QcTypeList,
} from './types'
import { GetApiResponse, List } from 'features/types/common'
import { ProjectCheckListResp } from 'features/types/checklist'

export const getCheckListAction = createAsyncAction(
  'checklist/constructRoad/GET_CHECK_LIST_REQ',
  'checklist/constructRoad/GET_CHECK_LIST_RESP',
  'checklist/constructRoad/GET_CHECK_LIST_FAIL'
)<GetCheckListReq, GetApiResponse<CheckList<CheckListItem>>, Error>()

export const getCheckListByIdAction = createAsyncAction(
  'checklist/constructRoad/GET_CHECK_ID_REQ',
  'checklist/constructRoad/GET_CHECK_ID_RESP',
  'checklist/constructRoad/GET_CHECK_ID_FAIL'
)<GetCheckListByIdReq, GetApiResponse<CheckListDetail>, any>()

export const createCheckListAction = createAsyncAction(
  'checklist/constructRoad/CREATE_CHECK_ID_REQ',
  'checklist/constructRoad/CREATE_CHECK_ID_RESP',
  'checklist/constructRoad/CREATE_CHECK_ID_FAIL'
)<CreateCheckListReq, any, any>()

export const updateCheckListAction = createAsyncAction(
  'checklist/constructRoad/UPDATE_CHECK_ID_REQ',
  'checklist/constructRoad/UPDATE_CHECK_ID_RESP',
  'checklist/constructRoad/UPDATE_CHECK_ID_FAIL'
)<UpdateCheckListReq, any, any>()

export const deleteCheckListAction = createAsyncAction(
  'checklist/constructRoad/DELETE_CHECK_ID_REQ',
  'checklist/constructRoad/DELETE_CHECK_ID_RESP',
  'checklist/constructRoad/DELETE_CHECK_ID_FAIL'
)<DeleteCheckListReq, any, any>()

export const clearCheckListDetail = createStandardAction(
  'checklist/constructRoad/CLEAR_CHECK_LIST_DETAIL'
)<void>()

export const updateCheckListDetail = createStandardAction(
  'checklist/constructRoad/UPDATE_CHECKLIST_DETAIL'
)<CheckListDetail>()

export const getProjectAction = createAsyncAction(
  'checklist/constructRoad/GET_PROJECT_REQ',
  'checklist/constructRoad/GET_PROJECT_RESP',
  'checklist/constructRoad/GET_PROJECT_FAIL'
)<any, ProjectCheckListResp, any>()

export const getQcTypeListAction = createAsyncAction(
  'checklist/constructRoad/GET_QCTYPE_REQ',
  'checklist/constructRoad/GET_QCTYPE_RESP',
  'checklist/constructRoad/GET_QCTYPE_FAIL'
)<any, GetApiResponse<List<QcTypeList>>, any>()
