import React, { useState } from 'react'
import { Row } from 'antd'
import styled from 'styled-components'
import imgPhoto from 'assets/img/ic-photo.png'
import FileEmpty from 'Components/Shared/FileEmpty'

type DefaultProps = {
  Images: any[]
  onImageOpen?: () => void
  onThumbnailOpen?: (index: number) => void
  showMax?: number
  label?: string
  onClickAdd?: () => void
}
type Props = DefaultProps

const ImageBox: React.FC<Props> = ({
  Images,
  onImageOpen = () => {},
  onThumbnailOpen = (index: number) => {},
  showMax = 2,
  label = 'รูปภาพ',
  onClickAdd = () => {},
}) => {
  const iconCss = { fontSize: '40px', marginTop: '25%' }
  const textCss = { fontSize: '16px' }

  const renderImg = (file: any, size: number = 0, index: number = 0) => {
    const imgContent = file.FileID ? file.FileURL : file.text
    if (size > 0) {
      return (
        <ImgBlock onClick={onImageOpen}>
          <ImageMoreItem src={imgContent} />
          <span className="std-font-bold">+{size}</span>
        </ImgBlock>
      )
    } else {
      return (
        <ImgBlock onClick={() => onThumbnailOpen(index)}>
          <ImageItem src={imgContent} />
        </ImgBlock>
      )
    }
  }

  let contents = []
  let moreContent
  const size = Images.length

  if (size > 0) {
    for (let index = 1; index <= size; index++) {
      if (index >= showMax) {
        let more = size - showMax
        moreContent = renderImg(Images[showMax - 1], more, index - 1)
      } else {
        contents.push(renderImg(Images[index - 1], 0, index - 1))
      }
    }
  }

  const renderContent = contents.map((content, index) => {
    return <div key={index}>{content}</div>
  })

  return (
    <>
      <div className="form-label">{label}</div>
      <Row>
        <ImgLine>
          <ImageAddbtn onClick={onClickAdd}>
            <PickerBody>
              <div>
                <Icon style={iconCss}>+</Icon>
                <Text className="std-font-regular" style={textCss}>
                  รูปภาพ
                </Text>
              </div>
            </PickerBody>
          </ImageAddbtn>
          {Images.length <= 0 ? (
            <FileEmpty image={imgPhoto} text="ไม่มีรูปภาพ" />
          ) : (
            <>
              {renderContent}
              {moreContent}
            </>
          )}
        </ImgLine>
      </Row>
    </>
  )
}

const ImgLine = styled.div`
  display: inline-flex;
  width: 100%;
`

const ImgBlock = styled.div`
  position: relative;
  text-align: center;
  span {
    color: white;
    font-size: 28px;
    position: absolute;
    top: 55px;
    left: 55px;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }
`

const CssImg = `
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-right: 8px;
  border-radius: 3px;
`
const ImageItem = styled.img`
  ${CssImg}
`

const ImageMoreItem = styled.img`
  ${CssImg}
  filter: brightness(0.4);
  :hover {
    cursor: pointer;
  }
`
const ImageAddbtn = styled.div`
  margin-right: 8px;
  cursor : pointer;
`

const Icon = styled.p`
  padding: 0px;
  margin: 0px;
  margin-bottom: 5px;
  color: #8b99a7;
  line-height: 0.5;
`

const Text = styled.p`
  padding: 0px;
  margin: 0px;
`

const PickerBody = styled.div`
  text-align: center;
  border-radius: 3px;
  border: dashed 1px #b5b4b4;
  width: 100px;
  height: 100px;
`

export default ImageBox
