import React from 'react'
import styled from 'styled-components'
import { DropdownPopupMenu } from 'Components/Shared/Form'
import { ActivityItem } from 'features/filter/types'
import Content from 'Components/Shared/Filters/CommonContent'

type Props = {
  onFilterChange: (filter: any) => void
  filter: any
  activityList: ActivityItem[]
}

const ActivityFilter: React.FunctionComponent<Props> = props => {
  const { onFilterChange, filter, activityList } = props

  const renderData = () => {
    let data = [] as any[]
    activityList.map((item, index) => {
      data.push({
        name: item.ActivityName,
        value: item.ActivityID,
      })
    })

    return data
  }

  const getSelectedNames = () => {
    const names = filter.ActivityList.map((id: string) => {
      const result =
        activityList &&
        activityList.find((it: ActivityItem) => it.ActivityID == id)
      return result ? result.ActivityName : id
    })
    return names.join(',')
  }

  return (
    <Box>
      <DropdownPopupMenu
        label={'ระบบ'}
        placeholder="ทั้งหมด"
        value={getSelectedNames()}
        disabled={
          filter.ProjectCode === '' ||
          filter.CommissionTypeList.length <= 0 ||
          filter.TowerList.length <= 0
        }
        content={
          <Content
            title="ระบบ"
            data={renderData()}
            filter={filter.ActivityList}
            onChange={data => {
              filter.ActivityList = data
              onFilterChange(filter)
            }}
            hasSearch={true}
          />
        }
        isActive={filter.ActivityList.length > 0}
      />
    </Box>
  )
}

const Box = styled.div`
  padding: 0 12px 12px 0;
`

export default ActivityFilter
