import { getType } from 'typesafe-actions';
import * as actions from './login.actions';
import produce from 'immer';
import { LoginAction, LoginState } from './login';

const initialState: LoginState = {
  user: {
    token: '',
    email: '',
  },
  logged: false,
  protectedMenu: false,
  forceLogin: false,
  loginButtonClicked: false,
  message: '',
  isSubmitting: false,
  submitError: undefined,
  submitSuccess: true,
};

export default function(
  state: LoginState = initialState,
  action: LoginAction
): LoginState {
  return produce(state, draft => {
    switch (action.type) {
      case getType(actions.loginAsyncActions.request):
        draft.isSubmitting = true;
        break;
      case getType(actions.loginAsyncActions.success):
        draft.isSubmitting = false;
        draft.message = action.payload.message;
        draft.user = action.payload;
        draft.protectedMenu = true;
        break;
      case getType(actions.loginAsyncActions.failure):
        draft.isSubmitting = false;
        draft.logged = false;
        draft.submitError = 'Something is not working, cannot login user.';
        draft.submitSuccess = false;
        break;
      case getType(actions.logoutAsyncActions.request):
        draft.isSubmitting = true;
        break;
      case getType(actions.logoutAsyncActions.success):
      case getType(actions.logoutAsyncActions.failure):
        draft.isSubmitting = false;
        draft.message = undefined;
        draft.user = {
          token: '',
          email: '',
        };
        draft.protectedMenu = false;
        draft.logged = false;
        draft.submitError = undefined;
        break;
      case getType(actions.initUserData):
        draft.user = {
          token: action.payload.user.token,
          email: action.payload.user.email,
        };
        draft.logged = action.payload.logged;
        break;
      case getType(actions.protectedMenuStatus):
        draft.protectedMenu = action.payload;
        break;
      case getType(actions.forceLogin):
        draft.forceLogin = action.payload;
        break;
      case getType(actions.initLogin):
        draft.loginButtonClicked = true;
        break;
      case getType(actions.updateLoginMessage):
        draft.message = action.payload;
        break;
      case getType(actions.userLoggedOut):
        draft.message = action.payload;
        draft.logged = false;
        break;
      default:
        return state;
    }
  });
}
