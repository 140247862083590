import React from 'react'
import { Col } from 'antd'
import styled from 'styled-components'
import { TableRow } from '../../../Shared/Table'
import { CheckListItem } from 'features/document/master/types'

type DefaultProps = {
  item: CheckListItem
  selected?: boolean
  onClick: (item: CheckListItem) => void
}

type Props = DefaultProps

const RowItem: React.FC<Props> = props => {
  const { item, selected } = props

  const onClick = () => {
    props.onClick(item)
  }

  return (
    <RowContent>
      <TableRow
        onClick={onClick}
        selected={selected}
        minHeight={60}
        spaceBetweenRow={8}
      >
        <Col span={2}>
          <Name>{item.RefID !== 0 ? item.RefID : item.CheckListID}</Name>
        </Col>
        <Col span={6}>
          <Name>{item.CheckListName}</Name>
        </Col>
        <Col span={4}>{item.QCTypeID}</Col>
        <Col span={4}>{item.IsRequireQC ? 'Yes' : 'NO'}</Col>
        <Col span={4}>{item.Score}</Col>
        <Col span={4}>
          <LastUpdated>
            {item.UpdatedDate} - โดย {item.UpdatedBy}
          </LastUpdated>
        </Col>
      </TableRow>
    </RowContent>
  )
}

const Name = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  margin: 0px 27px;
  color: #000;
`

const LastUpdated = styled.div`
  padding-right: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const RowContent = styled.div`
  cursor: pointer;
`

export default RowItem
