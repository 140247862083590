import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { connect, useDispatch } from 'react-redux'
import { Layout } from 'antd'
import { RouteComponentProps } from 'react-router-dom'
import RootState from 'typings/RootState'

import { submitPinReq, updateReport } from 'features/report/unit/actions'
import { getUnitReport, getUnitCheckListForQC } from 'features/report/unit/api'
import {
  GetUnitReportReq,
  GetCheckListForQCReq,
  UnitDefectType,
  UnitReportItem,
  SendUnitDefectReq,
  UnitReport,
  EditDefectReq,
} from 'features/report/unit/types'
import {
  selectReport,
  selectUnitDefect,
  selectEvent,
  selectEventChecklist,
  selectCheckListQC,
} from 'features/report/unit/selector'

import {
  resetUnitDefect,
  updateUnitDefect,
  mapUnitDefectData,
} from 'features/report/unit/actions'

import { selectEvent as eventRequest } from 'features/cmpj/cmchecking/selector'
import {
  userEmailSelector,
  serviceTokenSelector,
} from 'features/sensitive/sensitive.selector'

import {
  Report,
  AddUnitDefect,
  ReportPinDetail,
  PinUnitLocation,
} from 'Components/Defect'
import Breadcrumbs from './Breadcrumbs'
import QcFooter from './QcFooter'

import EventModal from 'Components/Shared/Modal/EventModal'

import { resetEvent as resetCmCheckingEvent } from 'features/cmpj/cmchecking/actions'
import { resetEvent as resetUnitEvent } from 'features/report/unit/actions'

import { selectUploadEvent } from 'features/uploadfile/selector'
import { getQCTypeVersion } from 'utils/getText'

import Signature from './Signature'

const { Content } = Layout

type FormProps = {
  code: string
}

type Props = FormProps &
  RouteComponentProps<{
    project: string
    unit: string
    qctype: string
    requestID: string
  }> &
  ReduxProps

const QcUnitReport: React.ComponentType<Props> = props => {
  const [page, setPage] = useState(
    '' as
      | 'add.defect'
      | 'edit.defect'
      | 'add.defectDetail'
      | 'edit.defectDetail'
      | 'detail.pin'
      | 'edit.defect.from.add'
      | 'edit.defect.from.edit'
      | ''
  )
  const [enableModal, setEnableModal] = useState(false)
  const [displaySign, setDisplaySign] = useState(false)
  const [selectedItem, setSelectedItem] = useState(undefined as any)
  const {
    report,
    token,
    UserEmail,
    match,
    event,
    eventChecklist,
    eventRequest,
    unitDefect,
    checklist,
    history,
    uploadEvent,
  } = props
  const { params } = match
  const { project, unit, qctype, requestID } = params
  const RequestID = parseInt(requestID)
  const dispatch = useDispatch()

  const isReadOnly = !(report.State === 'QC-QC' && report.Status === 'INPRO')

  useEffect(() => {
    setEnableModal(false)
  }, [report, unitDefect, checklist, selectedItem])

  useEffect(() => {
    onResetEvent()
    onClear()
  }, [unit, qctype, requestID])

  useEffect(() => {
    callGetUnitReport()
    const reqCheckListQC: GetCheckListForQCReq = {
      UserEmail,
      ProjectCode: project,
      QCTypeID: qctype,
      Unit: unit,
    }
    dispatch(getUnitCheckListForQC(token, reqCheckListQC))
  }, [token, project, UserEmail])

  const callGetUnitReport = () => {
    const params: GetUnitReportReq = {
      UserEmail,
      ProjectCode: project,
      QCType: qctype,
      Unit: unit,
      RequestID,
      Page: 1,
      RowPerPage: 1000,
    }
    dispatch(getUnitReport(token, params))
  }

  const onSaveUnitDefect = () => {
    setEnableModal(true)
    const unitReq: UnitDefectType = {
      ...unitDefect,
      UserEmail,
      ProjectCode: project,
      Unit: report.Unit,
      CheckListID: checklist.CheckListID,
      RequestID,
      QCTypeID: report.QCTypeID,
    }

    const req: SendUnitDefectReq = {
      Token: token,
      Pin: unitReq,
      CheckNumber: report.CheckNumber,
    }

    dispatch(submitPinReq(req))
  }

  const refreshPage = () => {
    onClear()
    callGetUnitReport()
    const reqCheckListQC: GetCheckListForQCReq = {
      UserEmail,
      ProjectCode: project,
      QCTypeID: qctype,
      Unit: unit,
    }
    dispatch(getUnitCheckListForQC(token, reqCheckListQC))
  }

  const getTitle = () => {
    let title = 'รอดำเนินการ'

    if (report.State === 'QC-QC' && report.Status === 'INPRO') {
      title = 'ตรวจหน้างาน'
    }

    return title
  }

  const onAddDefect = (floor: number, pin: number, x: number, y: number) => {
    setPage('add.defectDetail')
    unitDefect.Floor = floor
    unitDefect.PinNumber = pin
    unitDefect.XAxis = x.toString()
    unitDefect.YAxis = y.toString()
    dispatch(updateUnitDefect(unitDefect))
  }

  const onEditDefect = (floor: number, pin: number, x: number, y: number) => {
    const addMode = page === 'edit.defect.from.add'
    const defectPage = addMode ? 'add.defectDetail' : 'edit.defectDetail'
    setPage(defectPage)
    unitDefect.Floor = floor
    unitDefect.PinNumber = pin
    unitDefect.XAxis = x.toString()
    unitDefect.YAxis = y.toString()
    dispatch(updateUnitDefect(unitDefect))
  }

  const onUpdateDefect = (value: any) => {
    dispatch(updateUnitDefect(value))
  }

  const onUpdateReport = (value: UnitReport) => {
    dispatch(updateReport(value))
  }

  const onResetDefect = () => {
    dispatch(resetUnitDefect())
  }

  const onUpdateReportDefect = (pinID: number, value: UnitReportItem) => {
    report.FixFromQC.map((item: UnitReportItem, index: number) => {
      if (item.PinID === pinID) {
        report.FixFromQC[index] = value
      }
    })
    onUpdateReport(report)
  }

  const onSelectedItem = (item: UnitReportItem) => {
    setSelectedItem(item)
    if (isReadOnly) {
      setPage('detail.pin')
    } else {
      setPage('edit.defectDetail')
      dispatch(mapUnitDefectData(item))
    }
  }

  const onClear = () => {
    setSelectedItem(undefined)
    setEnableModal(false)
    setDisplaySign(false)
    setPage('')
    onResetDefect()
  }

  const onResetEvent = () => {
    dispatch(resetCmCheckingEvent())
    dispatch(resetUnitEvent())
  }

  const gotoDashboard = () => {
    history.push('../../../unit/dashboard')
  }

  const closeEnableModal = () => setEnableModal(false)
  const title = getTitle()
  let addMode = true
  let defectPage: any = ''
  switch (page) {
    case 'add.defect':
      return (
        <PinUnitLocation
          floorList={checklist.FloorList}
          viewMode="add"
          defectList={report.FixFromQC}
          onClose={() => setPage('')}
          onSave={onAddDefect}
        />
      )
    case 'edit.defect':
      return (
        <PinUnitLocation
          defect={unitDefect}
          viewMode="edit"
          floorList={checklist.FloorList}
          defectList={report.FixFromQC}
          onClose={() => setPage('edit.defectDetail')}
          onSave={onEditDefect}
        />
      )
    case 'edit.defect.from.add':
    case 'edit.defect.from.edit':
      addMode = page === 'edit.defect.from.add'
      defectPage = addMode ? 'add.defectDetail' : 'edit.defectDetail'
      return (
        <PinUnitLocation
          defect={unitDefect}
          viewMode="edit"
          floorList={checklist.FloorList}
          defectList={report.FixFromQC}
          onClose={() => setPage(defectPage)}
          onSave={onEditDefect}
        />
      )
    case 'add.defectDetail':
    case 'edit.defectDetail':
      addMode = page === 'add.defectDetail'
      defectPage = addMode ? 'edit.defect.from.add' : 'edit.defect.from.edit'
      return (
        <>
          <Breadcrumbs
            title={title}
            name={addMode ? 'เพิ่ม Defect' : `หมุดที่ ${unitDefect.PinNumber}`}
            onBack={onClear}
          />
          <EventModal
            event={[event, eventRequest, eventChecklist]}
            err={[uploadEvent]}
            enable={enableModal}
            successCalback={refreshPage}
            errorCalback={closeEnableModal}
          />
          <AddUnitDefect
            unitDefect={unitDefect}
            checkList={checklist}
            onSave={onSaveUnitDefect}
            onDelete={onClear}
            onEditPin={() => setPage(defectPage)}
            onChange={onUpdateDefect}
            addMode={addMode}
            reportCheckNo={report.CheckNumber}
          />
        </>
      )
    case 'detail.pin':
      return (
        <>
          <Breadcrumbs
            title={title}
            name={`หมุดที่ ${selectedItem.PinNumber}`}
            onBack={onClear}
          />
          <ReportPinDetail
            defectItem={selectedItem}
            onClear={onClear}
            unit={report.Unit}
            qcType={getQCTypeVersion(report)}
            onUpdateAfterImg={item => {
              onUpdateReportDefect(selectedItem.PinID, item)
            }}
          />
        </>
      )
    default:
      break
  }

  return (
    <>
      <EventModal
        event={[event, eventRequest, eventChecklist]}
        err={[uploadEvent]}
        enable={enableModal}
        successCalback={gotoDashboard}
        errorCalback={closeEnableModal}
      />
      <Signature
        display={displaySign}
        project={project}
        Unit={report.Unit}
        QCTypeID={report.QCTypeID}
        RequestID={RequestID}
        onClose={() => setDisplaySign(false)}
        onCompleted={refreshPage}
      />
      <Breadcrumbs title={title} />
      <Content className="mid-content">
        <MainPage>
          <Report
            report={report}
            checklist={checklist}
            title={title}
            onAddDefect={() => setPage('add.defect')}
            isLoading={event.IsLoading}
            isViewMode={isReadOnly}
            onSelected={onSelectedItem}
            showAfterImg={true}
          />
        </MainPage>
      </Content>
      <QcFooter
        project={project}
        RequestID={RequestID}
        report={report}
        onSign={() => setDisplaySign(true)}
        onCompleted={refreshPage}
        onChooseImage={() =>
          history.push(
            `/unit/imagereport/${project}/${unit}/${qctype}/${requestID}/${report.CheckNumber}`
          )
        }
      />
    </>
  )
}

const MainPage = styled.div`
  background-color: #f5f6fa;
`

const mapStateToProps = (state: RootState) => {
  return {
    report: selectReport(state),
    event: selectEvent(state),
    eventChecklist: selectEventChecklist(state),
    token: serviceTokenSelector(state),
    UserEmail: userEmailSelector(state),
    eventRequest: eventRequest(state),
    unitDefect: selectUnitDefect(state),
    checklist: selectCheckListQC(state),
    uploadEvent: selectUploadEvent(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {
  resetCmCheckingEvent,
  resetUnitEvent,
}

type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(QcUnitReport)
