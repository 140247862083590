import React from 'react'
import { Button, Popover, Col } from 'antd'
import { translate } from 'i18n'
import TKeys from 'i18n/translationKey'

import { ActivityFilter } from 'features/activity/activity.types'
import StatusContent from './StatusContent'
import ProgressContent from './ProgressContent'
import DurationContent from './DurationContent'
import PreCons from './PreCons'

import PopupMenu from './PopupMenu'

interface Props {
  filter: ActivityFilter
  onChange(filter: ActivityFilter): void
  onFind(): void
}

const Filter: React.FunctionComponent<Props> = ({
  filter,
  onChange,
  onFind,
}) => {
  const icon = (
    <span className="icon-down">
      <img src="/img/arrow-down.svg" alt="down arrow" />
    </span>
  )

  return (
    <div className="filters">
      <Col span={24} className="filters-button">
        <PopupMenu
          className="popup-menu"
          label={translate(TKeys.Dashboard.category)}
          content={<PreCons filter={filter} onChange={onChange} />}
          isActive={filter.wbs.length > 0}
        />

        <PopupMenu
          label={translate(TKeys.Dashboard.duration)}
          content={DurationContent({ filter, onChange })}
          isActive={filter.date.mode !== ''}
        />

        <PopupMenu
          label={translate(TKeys.Dashboard.progress)}
          content={ProgressContent({ filter, onChange })}
          isActive={filter.progress.length > 0}
        />

        <PopupMenu
          label={translate(TKeys.Dashboard.status)}
          content={StatusContent({ filter, onChange })}
          isActive={filter.status.length > 0}
        />

        <Button type="primary" onClick={onFind}>
          {translate(TKeys.Dashboard.filter)}
        </Button>
      </Col>
    </div>
  )
}

export default Filter
