import { createStandardAction, createAsyncAction } from 'typesafe-actions'
import {
  GetActivityRequest,
  GetUnitDashboardResponse,
  GetZoneDashboardResponse,
  GetCommissioningDashboardResponse,
  SendReportRequest,
  PostApiResponse,
  ReturnTaskReq,
  GetZoneReq,
  SendZoneReportReq,
  ReturnZoneReq,
  GetCommissioningReq,
  SendCommissioningReportReq,
  ReturnCommissioningReq,
  GetConstructPlanReq,
  GetConstructPlanResp,
  SendConstructPlanReportReq,
  ReturnConstructReq,
  GetAuditPlanReq,
  GetAuditPlanResp,
  SendAuditPlanReportReq,
  ReturnAuditPlanReq,
  GetConstructRoadReq,
  GetConstructRoadResp,
  SendRoadConstructReportReq,
  ReturnRoadConstructReq,
  CommonAreaFilter,
  CommissioningFilter,
  UnitFilter,
} from './types'

export const getUnitForQCAction = createAsyncAction(
  'qc/dashboard/FETCH_UNIT_REQUEST',
  'qc/dashboard/FETCH_UNIT_SUCCESS',
  'qc/dashboard/FETCH_UNIT_FAILURE'
)<GetActivityRequest, GetUnitDashboardResponse, any>()

export const getZoneForQCAction = createAsyncAction(
  'qc/dashboard/FETCH_ZONE_REQUEST',
  'qc/dashboard/FETCH_ZONE_SUCCESS',
  'qc/dashboard/FETCH_ZONE_FAILURE'
)<GetZoneReq, GetZoneDashboardResponse, any>()

export const sendReportAction = createAsyncAction(
  'qc/dashboard/SEND_REPORT_REQUEST',
  'qc/dashboard/SEND_REPORT_RESPONSE',
  'qc/dashboard/SEND_REPORT_FAILURE'
)<SendReportRequest, PostApiResponse, any>()

export const getCommissioningForQCAction = createAsyncAction(
  'qc/dashboard/FETCH_COMMISSIONING_REQUEST',
  'qc/dashboard/FETCH_COMMISSIONING_SUCCESS',
  'qc/dashboard/FETCH_COMMISSIONING_FAILURE'
)<GetCommissioningReq, GetCommissioningDashboardResponse, any>()

export const returnTaskAction = createAsyncAction(
  'qc/dashboard/RETURN_TASK_REQUEST',
  'qc/dashboard/RETURN_TASK_RESPONSE',
  'qc/dashboard/RETURN_TASK_FAILURE'
)<ReturnTaskReq, PostApiResponse, any>()

export const sendZoneReportAction = createAsyncAction(
  'qc/dashboard/SEND_ZONE_REPORT_REQUEST',
  'qc/dashboard/SEND_ZONE_REPORT_RESPONSE',
  'qc/dashboard/SEND_ZONE_REPORT_FAILURE'
)<SendZoneReportReq, PostApiResponse, any>()

export const returnZoneAction = createAsyncAction(
  'qc/dashboard/RETURN_ZONE_REQUEST',
  'qc/dashboard/RETURN_ZONE_RESPONSE',
  'qc/dashboard/RETURN_ZONE_FAILURE'
)<ReturnZoneReq, PostApiResponse, any>()

export const sendCommissReportAction = createAsyncAction(
  'qc/dashboard/SEND_COMMISSIONING_REPORT_REQUEST',
  'qc/dashboard/SEND_COMMISSIONING_REPORT_RESPONSE',
  'qc/dashboard/SEND_COMMISSIONING_REPORT_FAILURE'
)<SendCommissioningReportReq, PostApiResponse, any>()

export const returnCommissioningAction = createAsyncAction(
  'qc/dashboard/RETURN_COMMISSIONING_REQUEST',
  'qc/dashboard/RETURN_COMMISSIONING_RESPONSE',
  'qc/dashboard/RETURN_COMMISSIONING_FAILURE'
)<ReturnCommissioningReq, PostApiResponse, any>()

// Construct Plan
export const getConstructPlanAction = createAsyncAction(
  'qc/dashboard/GET_CONSTRUCT_PLAN_REQUEST',
  'qc/dashboard/GET_CONSTRUCT_PLAN_RESPONSE',
  'qc/dashboard/GET_CONSTRUCT_PLAN_FAILURE'
)<GetConstructPlanReq, GetConstructPlanResp, any>()

export const sendConstructReportAction = createAsyncAction(
  'qc/dashboard/SEND_CONTRUCT_REPORT_REQUEST',
  'qc/dashboard/SEND_CONTRUCT_REPORT_RESPONSE',
  'qc/dashboard/SEND_CONTRUCT_REPORT_FAILURE'
)<SendConstructPlanReportReq, PostApiResponse, any>()

export const returnConstructAction = createAsyncAction(
  'qc/dashboard/RETURN_CONTRUCT_REQUEST',
  'qc/dashboard/RETURN_CONTRUCT_RESPONSE',
  'qc/dashboard/RETURN_CONTRUCT_FAILURE'
)<ReturnConstructReq, PostApiResponse, any>()

//Audit Plan
export const getAuditPlanAction = createAsyncAction(
  'qc/dashboard/GET_AUDIT_PLAN_REQUEST',
  'qc/dashboard/GET_AUDIT_PLAN_RESPONSE',
  'qc/dashboard/GET_AUDIT_PLAN_FAILURE'
)<GetAuditPlanReq, GetAuditPlanResp, any>()

export const sendAuditReportAction = createAsyncAction(
  'qc/dashboard/SEND_AUDIT_REPORT_REQUEST',
  'qc/dashboard/SEND_AUDIT_REPORT_RESPONSE',
  'qc/dashboard/SEND_AUDIT_REPORT_FAILURE'
)<SendAuditPlanReportReq, PostApiResponse, any>()

export const returnAuditPlanAction = createAsyncAction(
  'qc/dashboard/RETURN_AUDIT_PLAN_REQUEST',
  'qc/dashboard/RETURN_AUDIT_PLAN_RESPONSE',
  'qc/dashboard/RETURN_AUDIT_PLAN_FAILURE'
)<ReturnAuditPlanReq, PostApiResponse, any>()

//Construct Road
export const getConstructRoadAction = createAsyncAction(
  'qc/dashboard/GET_CONSTRUCT_ROAD_REQUEST',
  'qc/dashboard/GET_CONSTRUCT_ROAD_RESPONSE',
  'qc/dashboard/GET_CONSTRUCT_ROAD_FAILURE'
)<GetConstructRoadReq, GetConstructRoadResp, any>()

export const sendConstructRoadAction = createAsyncAction(
  'qc/dashboard/SEND_CONSTRUCT_ROAD_REPORT_REQUEST',
  'qc/dashboard/SEND_CONSTRUCT_ROAD_REPORT_RESPONSE',
  'qc/dashboard/SEND_CONSTRUCT_ROAD_REPORT_FAILURE'
)<SendRoadConstructReportReq, PostApiResponse, any>()

export const returnConstructRoadAction = createAsyncAction(
  'qc/dashboard/RETURN_CONSTRUCT_ROAD_REQUEST',
  'qc/dashboard/RETURN_ACONSTRUCT_ROAD_RESPONSE',
  'qc/dashboard/RETURN_CONSTRUCT_ROAD_FAILURE'
)<ReturnRoadConstructReq, PostApiResponse, any>()

export const updateCommonAreaFilter = createStandardAction(
  'qc/dashboard/UPDATE_COMMON_AREA_FILTER_ACTION'
)<CommonAreaFilter, void>()

export const updateCommissioningFilter = createStandardAction(
  'qc/dashboard/UPDATE_COMMISS_FILTER_ACTION'
)<CommissioningFilter, void>()

export const updateUnitFilter = createStandardAction(
  'qc/dashboard/UPDATE_UNIT_FILTER_ACTION'
)<UnitFilter, void>()

export const clearFilter = createStandardAction(
  'qc/dashboard/CLEAR_FILTER_ACTION'
)<void>()
