import React, { useState, useCallback } from 'react'
import RootState from 'typings/RootState'
import { connect, useDispatch } from 'react-redux'
import { Row, Col, Layout, Input } from 'antd'
import styled from 'styled-components'
import EventModal from 'Components/Shared/Modal/EventModal'
import { DefectStatus } from 'features/types/status'

import {
  selectReport,
  selectDefect,
  selectCheckListQC,
  selectEvent,
} from 'features/report/auditRoad/selector'

import {
  submitDefectReq,
  updateDefect,
  resetEvent,
} from 'features/report/auditRoad/actions'

import {
  userEmailSelector,
  serviceTokenSelector,
} from 'features/sensitive/sensitive.selector'

import { selectUploadEvent } from 'features/uploadfile/selector'

import { UnitItem } from 'features/masterData/types'

import DefectFooter from './DefectFooter'
import DefectImage from '../DefectImage'

import {
  DefectItem,
  CheckListForQC,
  DefectTypeReq,
  SendDefectReq,
} from 'features/report/auditRoad/types'

import {
  JobCheckList,
  SubJobCheckList,
  DetailCheckList,
  StatusCheckList,
  ItemCheckList,
} from 'Components/Defect/CheckList'

const { Content } = Layout

type Props = {
  ProjectCode: string
  RequestID: number
  onClose?: () => void
  onCompeted?: () => void
  addMode?: boolean
} & ReduxProps

const DefectDetail: React.FunctionComponent<Props> = props => {
  const [enableModal, setEnableModal] = useState(false)

  const noFunc = () => {}
  const dispatch = useDispatch()
  const {
    onClose = noFunc,
    onCompeted = noFunc,
    ProjectCode,
    RequestID,

    Token,
    UserEmail,

    report,
    defect,
    checkList,

    addMode = true,

    event,
    uploadEvent,
  } = props

  const { CheckNumber } = report

  const closeEnableModal = () => {
    setEnableModal(false)
    dispatch(resetEvent())
  }
  const onSaveCompleted = () => {
    closeEnableModal()
    onCompeted()
  }

  const title: string = addMode ? 'เพิ่ม Defect' : 'แก้ไข Defect'
  const onChange = (val: DefectItem) => {
    dispatch(updateDefect({ ...val }))
  }

  const onSave = () => {
    setEnableModal(true)
    const req: SendDefectReq = {
      ProjectCode,
      UserEmail,
      Token,
      RequestID,

      Report: report,
      Defect: defect,
    }
    dispatch(submitDefectReq(req))
  }

  const updateJob = (value: number) => {
    if (value !== defect.JobTypeID) {
      defect.JobTypeID = value
      defect.SubJobTypeID = 0
      defect.DetailID = 0
      onChange(defect)
    }
  }

  const updateSubJob = (value: number) => {
    if (value !== defect.SubJobTypeID) {
      defect.SubJobTypeID = value
      defect.DetailID = 0
      defect.MethodTypeID = 0
      onChange(defect)
    }
  }

  const updateFix = (value: number) => {
    defect.FixID = value
    onChange(defect)
  }

  const updateStatus = (value: string) => {
    defect.PinStatus = value
    onChange(defect)

    if (value === DefectStatus.GOODWORK) {
      onGoodWork()
    }
  }

  const resetDetailValu = () => {
    defect.LenOfDefect = ''
    defect.WideOfDefect = ''
    defect.NumOfPoint = ''

    onChange(defect)
  }

  const onGoodWork = () => {
    defect.DetailID = 0
    resetDetailValu()
    defect.MethodTypeID = 0
    defect.FixID = 0
    defect.FixOther = ''
    onChange(defect)
  }

  const updateRemark = (value: string) => {
    defect.Remark = value
    onChange(defect)
  }

  const updateFixOther = (value: string) => {
    defect.FixOther = value
    onChange(defect)
  }

  const updateImg = (value: any, name: string) => {
    switch (name) {
      case 'ImageBefore':
        defect.ImageBefore = value
        break
      case 'ImageAfter':
        defect.ImageAfter = value
        break
      default:
        break
    }

    onChange(defect)
  }

  const updateMethodVal = (type: string, val: string) => {
    const nVal: any = val || ''

    switch (type) {
      case 'len':
        defect.LenOfDefect = nVal
        break
      case 'wide':
        defect.WideOfDefect = nVal
        break
      case 'num':
        defect.NumOfPoint = nVal
        break
      default:
        break
    }

    onChange(defect)
  }

  const getJobList = (jobList: any[], jobId: number) => {
    const job = jobList.find((job: any) => job.JobTypeID === jobId) || {}
    const { SubJobTypeList = [], FixList = [] } = job

    return { SubJobTypeList, FixList }
  }

  const getDetail = (subjobList: any[], subJobId: number) => {
    const detail =
      subjobList.find((job: any) => job.SubJobTypeID === subJobId) || {}

    return detail.DetailList || []
  }

  const getMethodBox = () => {
    let input
    const height = '48px'
    switch (defect.MethodTypeID) {
      case 2:
        input = (
          <InputMethod>
            <Input
              value={defect.LenOfDefect}
              onChange={evt => updateMethodVal('len', evt.target.value)}
              placeholder="กรุณาระบุความยาว"
              maxLength={100}
              style={{ height }}
              type="number"
            />
            <span>เมตร</span>
          </InputMethod>
        )
        break
      case 3:
        input = (
          <InputMethod>
            <Input
              value={defect.NumOfPoint}
              onChange={evt => updateMethodVal('num', evt.target.value)}
              placeholder="กรุณาระบุจำนวน"
              maxLength={100}
              style={{ height }}
              type="number"
            />
            <span>จุด</span>
          </InputMethod>
        )
        break
      default:
        const disabled = defect.MethodTypeID !== 1
        input = (
          <InputSqm>
            <Input
              value={defect.WideOfDefect}
              onChange={evt => updateMethodVal('wide', evt.target.value)}
              placeholder="กรุณาระบุความกว้าง"
              maxLength={100}
              style={{ height }}
              disabled={disabled}
              type="number"
            />

            <Input
              value={defect.LenOfDefect}
              onChange={evt => updateMethodVal('len', evt.target.value)}
              placeholder="กรุณาระบุความยาว"
              maxLength={100}
              style={{ height }}
              disabled={disabled}
              type="number"
            />

            <span>ตร.ม.</span>
          </InputSqm>
        )
        break
    }

    return input ? (
      <>
        <Header>การวัด</Header>
        {input}
      </>
    ) : (
      undefined
    )
  }

  const { SubJobTypeList, FixList } = getJobList(
    checkList.JobTypeList,
    defect.JobTypeID
  )
  const detailDatas = getDetail(SubJobTypeList, defect.SubJobTypeID)

  const updateDetail = (value: number) => {
    const detail = detailDatas.find((it: any) => it.DetailID === value)
    if (detail) {
      defect.DetailID = value
      defect.MethodTypeID = detail.MethodTypeID
      resetDetailValu()
    }

    onChange(defect)
  }

  const isGoodWork = defect.PinStatus === DefectStatus.GOODWORK

  const BottomSection = (
    <ChecklistData>
      <Row type="flex" justify="space-between">
        <Col span={12}>
          <DropDownArea>
            <JobCheckList
              datas={checkList.JobTypeList}
              active={defect.JobTypeID}
              onChange={updateJob}
            />

            <SubJobCheckList
              datas={SubJobTypeList}
              active={defect.SubJobTypeID}
              onChange={updateSubJob}
              disabled={defect.JobTypeID <= 0}
            />

            <DetailCheckList
              datas={detailDatas}
              active={defect.DetailID}
              onChange={updateDetail}
              disabled={
                defect.SubJobTypeID <= 0 ||
                isGoodWork ||
                detailDatas.length === 0
              }
            />

            {getMethodBox()}
          </DropDownArea>
        </Col>
        <Col span={12}>
          <SecordLen>
            <ItemCheckList
              list={FixList}
              label="วิธีแก้ไข"
              value="FixID"
              name="FixName"
              active={defect.FixID}
              onChange={updateFix}
              disabled={FixList.length === 0 || isGoodWork}
            />

            <InputRemark>
              <Input
                value={defect.FixOther}
                onChange={evt => updateFixOther(evt.target.value)}
                placeholder="อื่น ๆ (ถ้ามีกรุณาระบุ)"
                maxLength={255}
                style={{ height: '48px' }}
                disabled={isGoodWork}
              />
            </InputRemark>
            <Space />
            <Header>ระบุพื้นที่</Header>
            <InputRemark>
              <Input
                value={defect.Remark}
                onChange={evt => updateRemark(evt.target.value)}
                placeholder=""
                maxLength={255}
                style={{ height: '45px' }}
              />
            </InputRemark>

            <Space />
            <StatusCheckList
              active={defect.PinStatus}
              onChange={updateStatus}
              label="สถานะ"
            />
          </SecordLen>
        </Col>
      </Row>
    </ChecklistData>
  )

  return (
    <>
      <EventModal
        event={[event]}
        err={[uploadEvent]}
        enable={enableModal}
        successCalback={onSaveCompleted}
        errorCalback={closeEnableModal}
      />
      <Content className="mid-content">
        <div className="main-content activity-detail">
          <Title>{title}</Title>
          <DefectImage defect={defect} onImage={updateImg} />

          {BottomSection}
        </div>
      </Content>
      <DefectFooter
        defect={defect}
        onDelete={onClose}
        onSave={onSave}
        addMode={addMode}
      />
    </>
  )
}

const Title = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 28px;

  span {
    font-family: 'DB Helvethaica X 55 Regular';
    font-size: 16px;
    color: white;
    padding: 1px 10px;
    border-radius: 10px;
    margin-left: 12px;
  }
`

const ChecklistData = styled.div`
  margin-top: 24px;
`

const Header = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 20px;
  line-height: 1;
  margin-bottom: 6px;
`

const DropDownArea = styled.div`
  padding-right: 15px;

  .ant-dropdown-trigger {
    margin-bottom: 14px;
    line-height: 0px;
  }
`

const SecordLen = styled.div`
  padding-left: 15px;
  .ant-dropdown-trigger {
    margin-bottom: 14px;
    line-height: 0px;
  }
`

const Space = styled.div`
  margin-top: 16px;
`

const InputRemark: any = styled.div`
  .ant-input,
  .ant-input:focus,
  .ant-input:hover {
    font-family: 'DB Helvethaica X 55 Regular';
    font-size: 20px;
    border: solid 1px #e4e7eb;
  }
`

const InputSqm = styled(InputRemark)`
  display: flex;
  align-items: center;
  place-content: space-between;

  .ant-input,
  .ant-input:focus,
  .ant-input:hover {
    width: 44%;
  }

  span {
    font-size: 20px;
    float: right;
  }
`

const InputMethod = styled(InputRemark)`
  display: flex;
  align-items: center;
  place-content: space-between;

  .ant-input,
  .ant-input:focus,
  .ant-input:hover {
    width: 90%;
  }

  span {
    font-size: 20px;
    float: right;
  }
`

const mapStateToProps = (state: RootState) => {
  return {
    Token: serviceTokenSelector(state),
    UserEmail: userEmailSelector(state),

    report: selectReport(state),
    defect: selectDefect(state),
    checkList: selectCheckListQC(state),

    event: selectEvent(state),
    uploadEvent: selectUploadEvent(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(DefectDetail)
