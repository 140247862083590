import React, { useState } from 'react'

import { AuditRoadReport } from 'features/report/auditRoad/types'

import InProFooter from './InProFooter'
import RejtFooter from './RejtFooter'

type Props = {
  report: AuditRoadReport
  visible?: boolean
  onSign: () => void
  onResend: () => void
  onSelectPin: () => void
  onSelectLocation: () => void
}

const QcFooter: React.ComponentType<Props> = props => {
  const {
    report,
    visible = false,
    onSign,
    onResend,
    onSelectPin,
    onSelectLocation,
  } = props
  const { State, Status } = report

  if (State === 'QC' && visible) {
    if (Status === 'INPRO')
      return (
        <InProFooter
          report={report}
          onSign={onSign}
          onSelectPin={onSelectPin}
          onSelectLocation={onSelectLocation}
        />
      )
    else if (Status === 'REJT')
      return <RejtFooter report={report} onResend={onResend} />
  }

  return <div />
}

export default QcFooter
