import React from 'react'
import {
  CheckedIcon,
  UncheckedIcon,
  FailIcon,
  UnselectHeadIcon,
} from 'Components/Shared/Icon'
import { CheckListStatus } from 'features/cmpj/activity/types'

type Props = {
  status: string
}

const StatusIcon: React.FC<Props> = (props: Props) => {
  const { status } = props

  const getIconCheck = () => {
    switch (status.toUpperCase()) {
      case CheckListStatus.PASS:
        return <CheckedIcon size={24} />
      case CheckListStatus.FAIL:
        return <FailIcon size={24} />
      case CheckListStatus.SKIP:
        return <CheckedIcon size={24} />
      case CheckListStatus.NOTCHECK:
        return <UncheckedIcon size={24} />
      default:
        return <UnselectHeadIcon size={24} />
    }
  }

  return getIconCheck()
}

export default StatusIcon
