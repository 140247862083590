import React from 'react'
import { Spin, Icon } from 'antd'

type DefaultProps = {
  size?: number
  color?: string
}

const LoadingIcon = (props: DefaultProps) => {
  const { size = 20, color = '#fff' } = props
  const icon = <Icon type="loading" style={{ fontSize: 24 }} spin />
  return (
    <div className="loading-content">
      <Spin indicator={icon} tip="Loading..." />
    </div>
  )
}

export default LoadingIcon
