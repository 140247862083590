import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Row, Col } from 'antd'
import InputText from 'Components/Shared/Input/InputText'
import ProjectsBox from './ProjectBox'
import {
  CommissionDetail,
  Activity,
  Project,
  SelectActivityByCode,
} from 'features/admin/commission/types'
import ActivityCom from './Activity'
import { textToLowerCase } from 'utils/format'

type Props = {
  isSafety?: boolean
  projects: Project[]
  getActivityByCommissioning: ({
    ProjectCode,
    CheckListType,
  }: {
    ProjectCode: string
    CheckListType: number
  }) => void
  commissionDetail: CommissionDetail
  ActvityList: Activity[]
  selectActivityByCode: SelectActivityByCode
}

const Setting: React.ComponentType<Props> = ({
  isSafety,
  projects,
  getActivityByCommissioning,
  commissionDetail,
  ActvityList,
  selectActivityByCode,
}) => {
  const [name, setname] = useState('')
  const [projectCode, setProjectCode] = useState('')

  useEffect(() => {
    setname(commissionDetail.CheckListName)
  }, [commissionDetail.CheckListName])

  const onNameChange = (val: string) => {
    commissionDetail.CheckListName = val
    setname(val)
  }

  const selectProject = (item: Project) => {
    setProjectCode(item.ProjectCode)
    getActivityByCommissioning({
      ProjectCode: item.ProjectCode,
      CheckListType: isSafety ? 2 : 1,
    })
  }

  const deleteProject = (item: Project) => {
    if (projectCode === item.ProjectCode) {
      setProjectCode('')
    }
  }

  const LeftContent = (
    <LeftSection>
      <TitleSecond>ประเภท {isSafety ? 'Safety' : 'Commissioning'}</TitleSecond>
      <BoxSpace>
        <InputText
          prefix="ชื่อ"
          placeholder="กรอกชื่อ"
          value={name}
          onChange={onNameChange}
          disabled={textToLowerCase(commissionDetail.Status) === 'used'}
        />
      </BoxSpace>
      <BoxSpace>
        <ProjectsBox
          projectCode={projectCode}
          projects={projects}
          selectProject={selectProject}
          deleteProject={deleteProject}
          commissionDetail={commissionDetail}
        />
      </BoxSpace>
    </LeftSection>
  )

  const Rightontent = (
    <RightSection>
      <ActivityCom
        selectActivityByCode={selectActivityByCode}
        ActvityList={ActvityList}
        projectCode={projectCode}
        commissionDetail={commissionDetail}
      />
    </RightSection>
  )

  return (
    <Row type="flex" justify="space-between">
      <Col span={12}>{LeftContent}</Col>
      <Col span={12}>{Rightontent}</Col>
    </Row>
  )
}

const TitleSecond = styled.h2`
  font-size: 24px;
  font-family: 'DB Helvethaica X 75 Bd';
`

const LeftSection = styled.div`
  padding-right: 8px;
`

const RightSection = styled.div`
  padding-left: 8px;
`
const BoxSpace = styled.div`
  margin-bottom: 9px;
`

export default Setting
