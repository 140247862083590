import withAPIAction from '../../../utils/withAPIAction'
import { verifyReq, verifyReqNoProject } from '../../../utils/verify'

import {
  FetchCheckListReq,
  FetchCheckListByIdReq,
  UpdateCheckListReq,
  FetchActivityByCheckListReq,
  UpdateCheckListActivityReq,
  CreateCheckListReq,
  ImportCheckListReq,
  GetTemplateCodeListReq,
} from './types'

import {
  getCheckListReq,
  getCheckListByIdReq,
  createCheckListReq,
  updateCheckListReq,
  getActivityByCheckListReq,
  updateActivityByCheckListReq,
  importCheckListReq,
  getTemplateCodeListReq,
} from './actions'

export const getMasterCheckList = (data: FetchCheckListReq) => {
  return withAPIAction<FetchCheckListReq>(state => ({
    method: 'POST',
    path: 'GetMasterMainconCheckList',
    data: {
      ...data,
      UserEmail: state.sensitive.userEmail,
    },
    token: `Bearer ${state.sensitive.apiToken}`,
  }))(getCheckListReq)
}

export const getCheckListById = (
  CheckListID: string,
  isMaster: string,
  projectCode?: string
) => {
  return withAPIAction<FetchCheckListByIdReq>(state => ({
    method: 'POST',
    path: 'GetMainconCheckListById',
    data: {
      UserEmail: state.sensitive.userEmail,
      CheckListID,
      isMaster,
      projectCode,
    },
    token: `Bearer ${state.sensitive.apiToken}`,
  }))(getCheckListByIdReq)
}

export const getActivityByCheckList = (
  data: FetchActivityByCheckListReq,
  token: string
) => {
  const isValid = verifyReqNoProject(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<FetchActivityByCheckListReq>({
    method: 'POST',
    path: 'GetActivityByMainconCheckList',
    data,
    token: `Bearer ${token}`,
  })(getActivityByCheckListReq)
}

export const createCheckList = (data: CreateCheckListReq, token: string) => {
  const isValid = verifyReqNoProject(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<CreateCheckListReq>({
    method: 'POST',
    path: 'CreateMainconCheckList',
    data,
    token: `Bearer ${token}`,
  })(createCheckListReq)
}

export const updateCheckList = (data: UpdateCheckListReq, token: string) => {
  const isValid = verifyReqNoProject(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<UpdateCheckListReq>({
    method: 'POST',
    path: 'UpdateMainconCheckList',
    data,
    token: `Bearer ${token}`,
  })(updateCheckListReq)
}

export const updateActivityByCheckList = (
  data: UpdateCheckListActivityReq,
  token: string
) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<UpdateCheckListActivityReq>({
    method: 'POST',
    path: 'UpdateActivityByMainconCheckList',
    data,
    token: `Bearer ${token}`,
  })(updateActivityByCheckListReq)
}

export const importCheckList = (data: ImportCheckListReq, token: string) => {
  const isValid = verifyReqNoProject(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<ImportCheckListReq>({
    method: 'POST',
    path: 'ImportMainconCheckList',
    data,
    token: `Bearer ${token}`,
  })(importCheckListReq)
}

export const getTemplateCodeList = () => {
  return withAPIAction<GetTemplateCodeListReq>(state => ({
    method: 'POST',
    path: 'GetTemplateCodeList',
    data: {
      UserEmail: state.sensitive.userEmail,
    },
    token: `Bearer ${state.sensitive.apiToken}`,
  }))(getTemplateCodeListReq)
}
