export const saveProjectCode = (code: string) => {
  localStorage.setItem('projectCode', code)
}

export const getProjectCode = (): string => {
  return localStorage.getItem('projectCode') || ''
}

export const saveModule = (code: string) => {
  try {
    localStorage.setItem('moduleCode', code)
  } catch (e) {
    console.log('save error:', e.message)
  }
}

export const getModule = (): string => {
  try {
    return localStorage.getItem('moduleCode') || ''
  } catch (e) {
    console.log('error:', e.message)
  }
  return ''
}

export const saveModuleID = (id: any) => {
  try {
    localStorage.setItem('moduleID', id)
  } catch (e) {
    console.log('save error:', e.message)
  }
}

export const getModuleID = (): number => {
  try {
    const moduleID = localStorage.getItem('moduleID') || 0
    return +moduleID
  } catch (e) {
    console.log('error:', e.message)
  }
  return 0
}

export const clear = () => {
  localStorage.removeItem('projectCode')
  localStorage.removeItem('moduleCode')
  localStorage.removeItem('moduleID')
}

export default {
  saveProjectCode,
  getProjectCode,
  saveModule,
  saveModuleID,
  getModuleID,
  getModule,
  clear,
}
