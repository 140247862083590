import { getType } from 'typesafe-actions'
import { cloneDeep } from 'lodash'
import * as actions from './actions'
import { StoreState, ReportAction, Filter } from './types'
import produce from 'immer'
import parseErrorMessage from 'utils/parseErrorMessage'
import { Event } from '../../types/event'
import { formatData } from './function'
import moment from 'moment'

const initList = {
  List: [],
}

const initFilter = {
  QCTypeID: '',
  Date: {
    FinishDate: moment().startOf('day'),
    StartDate: moment()
      .endOf('day')
      .subtract(3, 'months'),
    Mode: '3month',
  },
  ProjectTypeID: 0,
  SquadID: 0,
  TeamID: 0,
} as Filter

const initEvent = {
  IsLoading: false,
  MessageSuccess: undefined,
  SubmitError: undefined,
  SubmitSuccess: false,
  SubmitType: undefined,
} as Event

const initialState: StoreState = {
  Event: cloneDeep(initEvent),
  DataList: cloneDeep(initList),
  Filter: cloneDeep(initFilter),
  ClearFilter: cloneDeep(initFilter),
  DataExport: [],
  ExportEvent: cloneDeep(initEvent),
}

export default function(
  state: StoreState = initialState,
  action: ReportAction
): StoreState {
  return produce(state, draft => {
    switch (action.type) {
      case getType(actions.getReportListAction.request):
        draft.Event = cloneDeep(initEvent)
        draft.Event.IsLoading = true
        draft.DataList = cloneDeep(initList)
        break
      case getType(actions.getReportListAction.success):
        draft.Event.IsLoading = false
        const data = action.payload.ResponseData
        draft.DataList = {
          List: data.List,
        }
        break

      case getType(actions.getReportListAction.failure):
        draft.Event.IsLoading = false
        draft.Event.SubmitError = parseErrorMessage(action.payload)
        break

      case getType(actions.updateFilter):
        draft.Filter = { ...action.payload }
        break
      case getType(actions.clearFilter):
        draft.Filter = cloneDeep({ ...initFilter })
        break

      case getType(actions.exportReportListAction.request):
        draft.ExportEvent = cloneDeep(initEvent)
        draft.ExportEvent.IsLoading = true
        draft.DataExport = []
        break
      case getType(actions.exportReportListAction.success):
        draft.ExportEvent.IsLoading = false
        if (action.payload.ResponseData) {
          draft.DataExport = formatData(action.payload.ResponseData.List)
        }
        break
      case getType(actions.exportReportListAction.failure):
        draft.ExportEvent.IsLoading = false
        draft.ExportEvent.SubmitError = parseErrorMessage(action.payload)
        break

      default:
        return state
    }
  })
}
