import withAPIAction from 'utils/withAPIAction'
import { getDataListAction, updateDataAction } from './actions'
import { GetDataListReq, UpdateDataReq } from './types'
import { verifyReq } from 'utils/verify'

export const getDataList = (token: string, data: GetDataListReq) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<GetDataListReq>({
    method: 'POST',
    path: 'GetActivityList',
    data,
    token: `Bearer ${token}`,
  })(getDataListAction)
}

export const updateData = (token: string, data: UpdateDataReq) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<UpdateDataReq>({
    method: 'POST',
    path: 'ReturnActivityByID',
    data,
    token: `Bearer ${token}`,
  })(updateDataAction)
}
