import React, { useState } from 'react'
import styled from 'styled-components'

import { DropdownList } from 'Components/Shared/Form'
import InputText from 'Components/Shared/Input/InputText'
import { SelectType } from '..'
import {
  CheckListDetail,
  SubJobType,
  JobType,
  DetailType,
} from 'features/checklist/auditRoad/types'
import { BottomButton } from 'Components/Shared/Button'

interface Props {
  selected: SelectType
  checkListDetail: CheckListDetail
  editingObj?: DetailType
  onSave: (detail: DetailType) => void
  closeModal: () => void
  disabled: boolean
}

const DetailJobModal: React.FC<Props> = ({
  checkListDetail,
  selected,
  editingObj,
  onSave: onSaveProp,
  closeModal,
  disabled,
}) => {
  const [error, seterror] = useState('')
  const [name, setname] = useState(editingObj ? editingObj.DetailName : '')

  const [jobList, setjobList] = useState<JobType[]>(
    checkListDetail.JobTypeList ? checkListDetail.JobTypeList : []
  )
  const [job, setjob] = useState<JobType | null>(
    jobList ? jobList[selected.job] : null
  )
  const [subjobList, setsubjobList] = useState<SubJobType[]>(
    job ? job.SubJobTypeList : []
  )
  const [subjob, setsubjob] = useState<SubJobType | null>(
    subjobList ? subjobList[selected.subJob] : null
  )

  const changeName = (val: string) => {
    setname(val)
  }

  const selectJob = (item: JobType) => {
    setjob(item)
    setsubjobList(item.SubJobTypeList)
    setsubjob(null)
  }

  const selectSubJob = (item: SubJobType) => {
    setsubjob(item)
  }

  const onSave = () => {
    if (name && job && subjob) {
      const found = editingObj
        ? null
        : subjob.DetailList.find(v => v.DetailName === name)
      if (found == null) {
        const detail: DetailType = {
          DetailID: 0,
          DetailName: name,
          IsRequired: false,
          IsSupplier: false,
          MethodTypeID: 1,
        }

        if (editingObj) {
          detail.DetailID = editingObj.DetailID || 0
          detail.IsRequired = editingObj.IsRequired || false
          detail.IsSupplier = editingObj.IsSupplier || false
          detail.MethodTypeID = editingObj.MethodTypeID || 1
        }
        onSaveProp(detail)
      } else {
        seterror('รายละเอียดนี้ ได้มีอยู่แล้ว')
      }
    } else {
      seterror('กรุณาใส่ข้อมูลทั้งหมด')
    }
  }

  const menuChoiceJob = () => {
    const result = checkListDetail.JobTypeList.map(
      (item: JobType, index: number) => (
        <DataItem key={index} onClick={() => selectJob(item)}>
          {item.JobTypeName}
        </DataItem>
      )
    )
    return result
  }

  const menuChoiceSubJob = () => {
    const result = subjobList.map((item: SubJobType, index: number) => (
      <DataItem key={index} onClick={() => selectSubJob(item)}>
        {item.SubJobTypeName}
      </DataItem>
    ))
    return result
  }

  return (
    <MainView>
      <DropdownBox>
        <InputText
          prefix="ชื่อ"
          value={name}
          placeholder="กรอกชื่อ"
          onChange={changeName}
          openWithFocus
          disabled={disabled}
        />
      </DropdownBox>

      <DropdownBox>
        <div className="dropdown-item">
          <DropdownList
            label="ลักษณะงาน"
            value=""
            placeholder={job ? job.JobTypeName : 'กรุณาเลือก'}
            menuChoice={menuChoiceJob()}
            disabled={true}
          />
        </div>
      </DropdownBox>

      <DropdownBox>
        <div className="dropdown-item">
          <DropdownList
            label="ลักษณะงานรอง"
            value=""
            placeholder={subjob ? subjob.SubJobTypeName : 'กรุณาเลือก'}
            menuChoice={menuChoiceSubJob()}
            disabled={true}
          />
        </div>
      </DropdownBox>

      {!disabled && (
        <BottomButton closeModal={closeModal} onSave={onSave} error={error} />
      )}
    </MainView>
  )
}

const MainView = styled.div``

const DropdownBox = styled.div`
  padding-top: 12px;
`

const DataItem = styled.div`
  padding: 5px 10px;

  &:hover {
    cursor: pointer;
    color: white;
    background-color: #003168;
  }
`

export default DetailJobModal
