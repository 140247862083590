import React from 'react'
import styled from 'styled-components'

import { RequireIcon } from 'Components/Shared/Icon'
import { DefectColor } from 'features/types/status'

type Props = {
  IsRequired: any
}

const DefectRequired: React.FunctionComponent<Props> = props => {
  const { IsRequired } = props

  return IsRequired ? (
    <Required style={{ color: DefectColor.TOBEFIX }}>
      <RequireIcon color={DefectColor.TOBEFIX} /> defect เงื่อนไข
    </Required>
  ) : (
    <div />
  )
}

const Required = styled.div`
  padding: 0px 8px;
  font-size: 20px;
  display: inline-flex;
  align-items: center;

  svg {
    margin-right: 5px;
  }

  @media print {
    font-size: 14px;
  }
`

export default DefectRequired
