import React from 'react'
import styled from 'styled-components'
import { DropdownList } from 'Components/Shared/Form'

type Props = {
  onFilterChange: (filter: any) => void
  filter: any
}

const SuplierFilter: React.FunctionComponent<Props> = props => {
  const { onFilterChange, filter } = props

  const suplier = [
    {
      name: `ทั้งหมด`,
      value: '',
    },
    {
      name: `ผู้รับเหมาหลัก`,
      value: 'N',
    },
    {
      name: `Supplier`,
      value: 'Y',
    },
  ]

  return (
    <Box>
      <DropdownList
        label="ประเภทผู้รับเหมา"
        value={filter.Supplier}
        menu={suplier}
        onChange={(value: any) => {
          filter.Supplier = value
          onFilterChange(filter)
        }}
      />
    </Box>
  )
}

const Box = styled.div`
  padding: 0 12px 12px 0;
`

export default SuplierFilter
