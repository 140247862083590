import { createStandardAction, createAsyncAction } from 'typesafe-actions'
import {
  GetActivityRequest,
  GetActivityApiResponse,
  DashboardQuery,
  Filter,
} from './types'

export const getActivityAction = createAsyncAction(
  'cmpj/qcdashboard/FETCH_ACTIVITY_REQUEST',
  'cmpj/qcdashboard/FETCH_ACTIVITY_SUCCESS',
  'cmpj/qcdashboard/FETCH_ACTIVITY_FAILURE'
)<GetActivityRequest, GetActivityApiResponse, any>()

export const sortingDashboard = createStandardAction(
  'cmpj/qcdashboard/SORTING'
)<string>()

export const filterDashboard = createStandardAction(
  'cmpj/qcdashboard/FILTER_DASHBOARD'
)<void>()

export const updateFilter = createStandardAction(
  'cmpj/qcdashboard/CONFIG_FILTER_DASHBOARD'
)<Filter>()

export const clearFilter = createStandardAction(
  'cmpj/qcdashboard/CLEAR_FILTER_DASHBOARD'
)<void>()

export const setQueryDashboard = createStandardAction(
  'cmpj/qcdashboard/CONFIG_QUERY_DASHBOARD'
)<DashboardQuery>()
