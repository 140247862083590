import { sortingList, sortingByMultiParams } from 'utils/helper'

export const sortingActivityList = (
  list: any[],
  type: string,
  name: string | undefined
): any[] => {
  try {
    let newList: any[] = list
    let params = []
    switch (name) {
      case 'Unit':
        newList = sortingList(list, type, name, 'string')
        break
      case 'QCTypeName':
        newList = sortingList(list, type, name, 'string')
        break
      case 'CheckNumber':
        newList = sortingList(list, type, name, 'number')
        break
      case 'Progress':
        newList = sortingList(list, type, 'Progress', 'number')
        break
      default:
    }

    return newList
  } catch (e) {
    return list
  }
}

export const getTaskRatio = (list: any) => {
  let newList = [] as any
  list.map((itemdata: any) => {
    let newItem = itemdata
    newItem.TaskRatio = itemdata.PassedSubTasks / itemdata.TotalSubTasks
    newList.push(newItem)
  })
  return newList
}

export const getTaskStatus = (list: any) => {
  let newList = [] as any
  list.map((itemdata: any) => {
    let newItem = itemdata
    newItem.CompleteTask =
      itemdata.PassedSubTasks === itemdata.TotalSubTasks ? 1 : 0
    newList.push(newItem)
  })
  return newList
}
