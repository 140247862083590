import React, { useState, useEffect } from 'react'
import { Row, Col, Layout } from 'antd'
import styled from 'styled-components'
import { UnitReportItem } from 'features/report/unit/types'
import DefectStatus from './DefectStatus'
import DefectScore from './DefectScore'
import AddDefectImage from './AddDefectImage'
import AddAfterImgFooter from './AddAfterImgFooter'

const { Content } = Layout

type Props = {
  unit: String
  qcType: String
  defectItem: UnitReportItem
  onClear: () => void
  onUpdateAfterImg?: (item: UnitReportItem) => void
  canUpAfterImg?: boolean
  onSave?: () => void
  showScore?: boolean
}

const PinDetail: React.FunctionComponent<Props> = props => {
  const {
    defectItem,
    unit,
    qcType,
    onUpdateAfterImg = () => {},
    canUpAfterImg = true,
    onClear,
    onSave = () => {},
    showScore = true,
  } = props
  const [item, setCurrentItem] = useState(defectItem as UnitReportItem)
  const title: string = `หมุดที่ ${item.PinNumber}`

  const header = (
    <HeaderTitle>
      <UnitInfo>
        ยูนิต {unit}, หมวด {qcType}
      </UnitInfo>
      <Row type="flex" justify="space-between">
        <Col>
          <Title>
            {title}
            <DefectStatus pinStatus={item.PinStatus} />
          </Title>
        </Col>
        {showScore && (
          <Col>
            <DefectScore pinStatus={item.PinStatus} pinScore={item.Score} />
            <Desc>คะแนน</Desc>
          </Col>
        )}
      </Row>
    </HeaderTitle>
  )

  const renderInfo = (title: string, value: string) => {
    return (
      <Col span={8}>
        <DisplayItem>
          <label>{title}</label>
          <p>{value ? value : '-'}</p>
        </DisplayItem>
      </Col>
    )
  }

  const pinDetail = (
    <MainData>
      <Row>
        {renderInfo('ลักษณะงานหลัก', item.JobTypeName)}
        {renderInfo('ลักษณะงานรอง', item.SubJobTypeName)}
        {renderInfo('การประเมิน', item.DetailName)}
      </Row>
      <Row>
        {renderInfo('พื้นที่', item.LocationName)}
        {renderInfo('หมายเหตุ', item.Remark)}
      </Row>
    </MainData>
  )

  const updateAfterImage = (file: any) => {
    item.ImageAfter = file
    setCurrentItem({ ...item })
    onUpdateAfterImg(item)
  }

  const confirmSave = () => {
    onSave()
    //onClear()
  }

  let planImg = ''
  if (item.ImageFloorPlan) {
    planImg = item.ImageFloorPlan.FileURL
  }

  return (
    <>
      <Content className="mid-content">
        <MainView className="main-content activity-detail">
          {header}
          {pinDetail}
          <AddDefectImage
            planImg={planImg}
            pin={item}
            floor={item.UnitFloor}
            onEditPin={() => {}}
            onAddAfterImage={image => updateAfterImage(image)}
            onAddBeforeImage={() => {}}
            viewMode={true}
            canUpAfterImg={canUpAfterImg}
          />
        </MainView>
      </Content>
      {canUpAfterImg && (
        <AddAfterImgFooter onCancel={onClear} onSave={confirmSave} />
      )}
    </>
  )
}

const MainView = styled.div``

const Title = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 28px;

  span {
    font-family: 'DB Helvethaica X 55 Regular';
    font-size: 16px;
    color: white;
    padding: 1px 10px;
    border-radius: 10px;
    margin-left: 12px;
  }
`

const MainData = styled.div`
  border-radius: 5px;
  border: solid 1px #eeeff2;
  background-color: white;
  padding: 24px;
`

const DisplayItem = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 20px;
  padding: 0 5px;
  p {
    font-family: 'DB Helvethaica X 55 Regular';
  }
`

const UnitInfo = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 28px;
  margin-bottom: 16px;
`

const Desc = styled.span`
  font-family: 'DB Helvethaica X 55 Regular';
  font-size: 18px;
`

const HeaderTitle = styled.div`
  margin-bottom: 10px;
`

export default PinDetail
