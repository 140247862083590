import React from 'react'
import { Layout, Row, Col } from 'antd'
import styled from 'styled-components'
import { PrimaryButton, SecondaryButton } from 'Components/Shared/Button'

type Props = {
  taskSelect: any[]
  onClick: () => void
  onSelectAll?: () => void
  isLoading?: boolean
  isSelectAll?: boolean
}

const TaskFooter: React.FunctionComponent<Props> = props => {
  const {
    taskSelect,
    onClick,
    isLoading = false,
    onSelectAll = () => {},
    isSelectAll = false,
  } = props

  const renderButtonAction = () => {
    return (
      <SelfCheckBtn>
        <PrimaryButton
          text="ส่งคำขอหา QC"
          disabled={taskSelect.length > 0 && !isLoading ? false : true}
          onClick={onClick}
        />
      </SelfCheckBtn>
    )
  }

  const renderLeftButton = () => {
    return (
      <Row type="flex" justify="start" align="middle">
        <Col>
          <Button>
            <SecondaryButton
              text={`${isSelectAll ? 'ยกเลิกเลือกทั้งหมด' : 'เลือกทั้งหมด'}`}
              onClick={onSelectAll}
            />
          </Button>
        </Col>
        <Col>
          คุณเลือก {renderTextSelected(taskSelect.length)} รายการในการส่งรายงาน
        </Col>
      </Row>
    )
  }

  const renderTextSelected = (selected: number) => {
    if (selected > 0) {
      return <TextGreen>{selected}</TextGreen>
    } else {
      return <TextGray>{selected}</TextGray>
    }
  }

  return (
    <FooterContent>
      <Row type="flex" justify="space-between" align="middle">
        <Col>{renderLeftButton()}</Col>
        <Col>{renderButtonAction()}</Col>
      </Row>
    </FooterContent>
  )
}

const FooterContent = styled.div`
  color: #000;
  font-size: 20px;
  padding: 0 30px;
`

const SelfCheckBtn = styled.div`
  width: 160px;
`

const TextGreen = styled.span`
  color: #5bc79e;
  padding: 0 8px;
`

const TextGray = styled.span`
  color: #8b99a7;
  padding: 0 8px;
`

const Button = styled.div`
  width: 160px;
  margin-right: 32px;
`

export default TaskFooter
