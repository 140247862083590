import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'antd'
import { Link } from 'react-router-dom'
import { ProgressCircle, CheckListTypeTag } from 'Components/Shared'
import { WarningIcon } from 'Components/Shared/Icon'
import { getDelayedText, getDelayedDate, getStartText } from 'utils/datetime'
import { Todo } from 'features/cmpj/commissioning/dashboard/types'

interface Props {
  task: Todo
}

const TodoCard: React.FC<Props> = ({ task }) => {
  let delayed = getDelayedDate(task.FinishDate)
  let content
  let lane
  if (delayed < 0) {
    let days = Math.abs(delayed)
    const display = getDelayedText(days, '')
    lane = <span className="lane-card-delayed" />
    content = (
      <div className="status status-danger">
        <WarningIcon />
        <span className="countdown">{display}</span>
      </div>
    )
  } else {
    const display = getStartText(delayed, '')
    content = (
      <div className={'status status-default'}>
        <span className="countdown">{display}</span>
      </div>
    )
  }

  return (
    <Link to={`/commissioning/activity/${task.CheckListID}/${task.ActivityID}`}>
      <MainCard>
        {lane}
        <Row type="flex" justify="space-between">
          <Col span={17}>
            {content}
            <Title>{task.ActivityName}</Title>
          </Col>
          <Col>
            <MyProgress>
              <ProgressCircle task={task} width={48} />
            </MyProgress>
          </Col>
        </Row>
        <Row>
          <Col>
            <Detail>วันที่เริ่ม {task.StartDate}</Detail>
          </Col>
          <Col>
            <Detail>วันที่สิ้นสุด {task.FinishDate}</Detail>
          </Col>
        </Row>
        <Row type="flex">
          <CheckListTypeTag name={task.CheckListType} />
        </Row>
      </MainCard>
    </Link>
  )
}

const MainCard = styled.div`
  background: #fff;
  border-radius: 10px;
  padding: 16px;
  background-clip: padding-box;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.03);
  margin-bottom: 12px;
  cursor: pointer;
  color: #000;
  width: 100%;
  position: relative;
`

const Title = styled.p`
  font-size: 20px;
  margin-bottom: 4px;
  font-family: 'DB Helvethaica X 75 Bd';
`

const Detail = styled.p`
  font-size: 18px;
  margin-bottom: 4px;
  font-family: 'DB Helvethaica X 55 Regular';
`

const MyProgress = styled.div`
  .ant-progress-text {
    font-size: 16px !important;
  }
`

export default TodoCard
