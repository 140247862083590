import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'antd'
import { Link } from 'react-router-dom'
import { InProgress } from 'features/cmpj/commissioning/dashboard/types'
import { toMomentDateTime, toDateText, toTimeText } from 'utils/datetime'

interface Props {
  task: InProgress
}

const InProgressCard: React.FC<Props> = ({ task }) => {
  const getLaneColor = (status: string) => {
    let lane
    switch (status) {
      case 'success':
        lane = <span className="lane-card-success" />
        break
      case 'fail':
        lane = <span className="lane-card-delayed" />
        break
      default:
        break
    }
    return lane
  }

  const renderStatus = (isReady: boolean) => {
    const appointmentDate = toMomentDateTime(task.AppointmentDate)
    const date = toDateText(appointmentDate)
    const time = toTimeText(appointmentDate)
    return isReady ? (
      <TextStatus>
        วันที่นัดหมาย {date} เวลา {time}
      </TextStatus>
    ) : (
      <span>รอ QC รับคำขอ</span>
    )
  }

  const isInprogress = () => {
    return task.State === 'QC' && task.Status === 'INPRO'
  }

  const inprogress = isInprogress()
  return (
    <Link
      to={`/commissioning/detail/${task.CheckListID}/${task.ActivityID}/${
        task.RequestID
      }`}
    >
      <MainCard>
        <>
          {getLaneColor(inprogress ? 'success' : '')}
          <Row type="flex">
            <Col span={24}>
              <Title>{task.ActivityName}</Title>
              <Detail>
                {task.CommissioningType} ({task.CheckNumber})
              </Detail>
              <Detail>สถานะ: {renderStatus(inprogress)}</Detail>
            </Col>
          </Row>
        </>
      </MainCard>
    </Link>
  )
}

const MainCard = styled.div`
  background: #fff;
  border-radius: 10px;
  padding: 16px;
  background-clip: padding-box;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.03);
  margin-bottom: 12px;
  cursor: pointer;
  color: #000;
  width: 100%;
  position: relative;
`

const Title = styled.p`
  font-size: 20px;
  margin-bottom: 4px;
  font-family: 'DB Helvethaica X 75 Bd';
`

const Detail = styled.p`
  font-size: 18px;
  margin-bottom: 4px;
  font-family: 'DB Helvethaica X 55 Regular';
`

const TextStatus = styled.span`
  color: #63cea6;
`

export default InProgressCard
