import React, { useState } from 'react'
import { HeaderRow } from 'Components/Shared/Table'
import { TypeOfLane } from 'features/maincon/dashboard/types'
import { CheckedIcon, UncheckedIcon } from 'Components/Shared/Icon'

type Props = {
  type: string
  onSort(name: string): void
  sorting: any
  showRadio?: boolean
  isSelectAll?: boolean
  onSelectAll?: () => void
}

const TableHeader: React.FunctionComponent<Props> = props => {
  const {
    onSort,
    sorting,
    type,
    isSelectAll = false,
    onSelectAll,
    showRadio = false,
  } = props

  const getRadioBox = () => {
    return (
      <div onClick={() => onSelectAll && onSelectAll()}>
        {isSelectAll ? <CheckedIcon size={24} /> : <UncheckedIcon size={24} />}
      </div>
    )
  }

  const todoHeaderItems = [
    {
      title: 'ยูนิต',
      span: 7,
      key: 'Unit',
    },
    {
      title: 'หมวดงาน',
      span: 5,
      key: 'CheckNumber',
    },
    {
      title: 'จำนวนงาน',
      span: 5,
      key: 'TaskRatio',
    },
    {
      title: 'สถานะ',
      span: 5,
      key: 'Status',
    },
    {
      title: showRadio && getRadioBox(),
      span: 2,
      key: '',
      showSort: false,
    },
  ]

  const inprogressHeaderItems = [
    {
      title: 'ยูนิต',
      span: 8,
      key: 'Unit',
    },
    {
      title: 'หมวดงาน',
      span: 8,
      key: 'Category',
    },
    {
      title: 'คะแนน',
      span: 6,
      key: 'Count',
    },
    {
      title: 'กรุณาเลือก',
      span: 2,
      key: '',
      showSort: false,
    },
  ]

  const columns =
    type == TypeOfLane.TODO ? todoHeaderItems : inprogressHeaderItems
  return (
    <HeaderRow onSort={key => onSort(key)} sorting={sorting} items={columns} />
  )
}

export default TableHeader
