import RootState from 'typings/RootState'
import { Event } from '../../types/event'
import { DataList } from './types'

export const selectEvent = (state: RootState): Event =>
  state.adminReportScore.Event

export const selectReportList = (state: RootState): DataList =>
  state.adminReportScore.DataList

export const selectFilter = (state: RootState) => state.adminReportScore.Filter

export const selectInitFilter = (state: RootState) =>
  state.adminReportScore.ClearFilter
