import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { Row, Col, Button } from 'antd'
import { InputText, InputDouble } from 'Components/Shared/Input'
import { CloseIcon, TrashIcon, AddIcon } from 'Components/Shared/Icon'
import { DropdownList, RadioBox } from 'Components/Shared/Form'
import { QCType } from 'features/checklist/unit/types'
import { textToLowerCase } from 'utils/format'

// item: CheckListItem | undefined

type FormProps = {
  item?: any
  // QcTypeList: QCType[]
  QcTypeList: any
  onSave?: (doc: any) => void
  onClose: () => void
  readOnly?: boolean
  fromLocal?: boolean
}

type Props = FormProps & ReduxProps & DispatchProps

const QCCheckDocumentDetail: React.ComponentType<Props> = props => {
  const [docItem, setDocItem] = useState({ CheckList: [] } as any)
  const [qcTypeList, setQCTypeList] = useState([] as any[])
  const { item, QcTypeList, readOnly = false, fromLocal } = props
  const isUsed = textToLowerCase(docItem.Status) === 'used'
  useEffect(() => {
    const menu = QcTypeList.map((it: QCType) => {
      return {
        name: it.QCTypeID,
        value: it.QCTypeID,
      }
    })

    setQCTypeList(menu)
  }, [QcTypeList])

  useEffect(() => {
    setDocItem(item || { CheckList: [] })
  }, [item])

  const onSave = () => {
    if (props.onSave) {
      props.onSave(docItem)
    }
  }

  const onAdd = () => {
    docItem.CheckList.push({
      Name: '',
      Score: 0,
    })
    setDocItem({ ...docItem })
  }
  const onUpdate = (it: any, index: number) => {
    docItem.CheckList[index] = it
    setDocItem({ ...docItem })
  }

  const onRemove = (index: number) => {
    docItem.CheckList.splice(index, 1)
    setDocItem({ ...docItem })
  }

  const content = docItem.CheckList.map((it: any, index: number) => {
    return (
      <Col span={24} style={{ marginTop: '12px' }} key={index}>
        <Row>
          <Col span={14}>
            <InputText
              prefix="ชื่อ"
              value={it.Name}
              placeholder=""
              onChange={(val: string) => {
                it.Name = val
                onUpdate(it, index)
              }}
              disabled={readOnly || isUsed}
            />
          </Col>
          <Col span={8} style={{ paddingLeft: '12px' }}>
            <InputDouble
              prefix="Criteria"
              value={it.Score}
              onChange={(val: string) => {
                it.Score = val
                onUpdate(it, index)
              }}
              disabled={readOnly || isUsed}
            />
          </Col>
          <Col span={2}>
            <Icon
              onClick={readOnly || isUsed ? undefined : () => onRemove(index)}
            >
              <TrashIcon size={24} color="#8b99a7" />
            </Icon>
          </Col>
        </Row>
      </Col>
    )
  })

  return (
    <MainPage>
      <Header>
        <CloseIcon color="#8b99a7" size={24} onClick={props.onClose} />
      </Header>
      <Row>
        <Col span={24}>
          <Title>รายการตรวจ (3)</Title>
        </Col>

        <Col span={24}>
          <InputText
            prefix="ชื่อ Checklist"
            value={docItem.CheckListName}
            placeholder="กรอก Checklist"
            onChange={() => null}
            disabled={true}
          />
        </Col>

        <Col span={24} style={{ marginTop: 12 }}>
          <DropdownList
            label={'หมวดงาน'}
            value={docItem.QCTypeID}
            menu={qcTypeList}
            onChange={(val: string) => {
              docItem.QCTypeID = val
              setDocItem({ ...docItem })
            }}
            disabled={readOnly || fromLocal}
          />
        </Col>
        <Col span={24} style={{ margin: '12px 0 15px 0' }}>
          <RadioBox
            value=""
            label={'QC Require เป็นเอกสารตรวจ'}
            isCheck={docItem.IsRequireQC}
            readOnly={readOnly || isUsed}
            onClick={() => {
              docItem.IsRequireQC = !docItem.IsRequireQC
              setDocItem({ ...docItem })
            }}
          />
        </Col>

        {docItem.IsRequireQC && (
          <Col span={24}>
            <InputDouble
              prefix="คะแนนเต็ม"
              value={docItem.Score}
              placeholder=""
              onChange={(val: string) => {
                docItem.Score = val
                setDocItem({ ...docItem })
              }}
              disabled={readOnly || isUsed}
            />
          </Col>
        )}

        {docItem.IsRequireQC && content}

        {!isUsed && (
          <>
            {docItem.IsRequireQC && (
              <Col span={24}>
                <AddArea>
                  <Button onClick={onAdd} disabled={readOnly}>
                    <AddIcon size={14} color="#003168" /> เพิ่มรายการ
                  </Button>
                </AddArea>
              </Col>
            )}
            <Col span={24}>
              <SaveArea>
                <Button onClick={onSave} disabled={readOnly}>
                  บันทึก
                </Button>
              </SaveArea>
            </Col>
          </>
        )}
      </Row>
    </MainPage>
  )
}

const MainPage = styled.div`
  -khtml-user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
`

const Header = styled.div`
  text-align: right;
  height: 40px;
  padding: 24px;

  svg {
    cursor: pointer;
  }
`

const Title = styled.div`
  color: black;
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 28px;
  margin-bottom: 32px;
`

const Icon = styled.div`
  padding-top: 14px;
  padding-left: 12px;
  cursor: pointer;
`

const SaveArea = styled.div`
  text-align: right;
  width: 100%;
  height: 200px;
  margin-top: 32px;

  button {
    width: 116px;
    height: 50px;
    border-radius: 5px;
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.03);
    color: white;
    background-color: #003168;
    font-family: 'DB Helvethaica X 75 Bd';
    font-size: 20px;
  }
`

const AddArea = styled.div`
  text-align: center;
  margin-top: 24px;

  button {
    width: 100%;
    height: 50px;
    border-radius: 5px;
    border: solid 1.5px #003168;
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.03);
    color: #003168;
    background-color: white;
    font-family: 'DB Helvethaica X 75 Bd';
    font-size: 20px;
  }

  button > span {
    padding-left: 7px;
  }
`

const mapStateToProps = () => {
  return {}
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QCCheckDocumentDetail)
