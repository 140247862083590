import React from 'react'

type Props = {
  active?: boolean
  activeIcon: string
  inactiveIcon: string
}

const BaseIcon = (props: Props) => {
  const { active = false, activeIcon, inactiveIcon } = props
  return <img src={active ? activeIcon : inactiveIcon} width={24} height={24} />
}

export default BaseIcon
