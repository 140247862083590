import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { connect, useDispatch } from 'react-redux'
import { Row, Col } from 'antd'
import { RouteComponentProps, Link } from 'react-router-dom'
import SubnavBar from 'Components/Shared/SubnavBar'
import Loading from 'Components/Shared/Loading'
import { PrimaryButton } from 'Components/Shared/Button'
import { BreadcrumbLink } from 'Components/Shared/BreadCrumb/BreadCrumb'
import RootState from 'typings/RootState'
import {
  userEmailSelector,
  serviceTokenSelector,
} from 'features/sensitive/sensitive.selector'
import {
  selectAuditRoad,
  selectEvent,
  selectActionEvent,
  selectSorting,
} from 'features/qc/road/selector'
import {
  getAuditRoadForQC,
  sendAuditRoadReportByQC,
  returnAuditRoadByQC,
  acceptAuditRoadByQC,
  RejectCheckingTasks,
} from 'features/qc/road/api'
import {
  GetAuditRoadReq,
  AuditRoadToDo,
  AuditRoadInpro,
  AuditRoadReturn,
  SendAuditRoadReportReq,
  ReturnAuditRoadReq,
  AcceptAuditRoadReq,
  RejectCheckingTaskReq,
} from 'features/qc/road/types'

import { sortingDashboard } from 'features/qc/road/actions'

import { translate } from 'i18n'
import TKeys from 'i18n/translationKey'
import RoadTab from 'Components/Shared/Tab/RoadTab'

import Menu from '../../../Menu'
import ColumnHeader from '../../ColumnHeader'
import { NoCard, EventModal } from 'Components/Shared'

import PageFooter from '../../PageFooter'
import MainPage from '../../MainPage'
import TodoCard from './TodoCard'
import InproCard from './InproCard'
import ReturnCard from './ReturnCard'
import AcceptModal from './AcceptModal'

import { ColumnHeader as ColumnHeaderSort } from 'Components/Shared'

const breadcrumbs: BreadcrumbLink[] = [
  {
    url: '/',
    title: 'Home',
    icon: '/home.png',
    active: true,
    showTitle: false,
  },
  {
    url: '/',
    title: translate(TKeys.Breadcrumb.dashboard),
    active: false,
    showTitle: false,
  },
]

type Props = ReduxProps & DispatchProps & RouteComponentProps

const AuditRoadDashboard: React.ComponentType<Props> = props => {
  const {
    data,
    event,
    actionEvent,
    token,
    UserEmail,
    sortingTask,
    sorting,
  } = props
  const [sendReport, setSendReport] = useState(false)
  const [selectedList, setSelectedList] = useState([] as any[])
  const [enableModal, setEnableModal] = useState(false)
  const [acceptTask, setAcceptTask] = useState({} as any)

  const dispatch = useDispatch()
  useEffect(() => {
    setEnableModal(false)
  }, [data])

  useEffect(() => {
    getDashboard()
  }, [])

  const getDashboard = () => {
    const data: GetAuditRoadReq = {
      UserEmail: UserEmail,
      ProjectCode: [],
      CheckNumber: [],
    }
    dispatch(getAuditRoadForQC(token, data))
  }

  const onSubmitSendReport = () => {
    setEnableModal(true)
    const data: SendAuditRoadReportReq = {
      UserEmail: UserEmail,
      ProjectList: selectedList,
    }
    dispatch(sendAuditRoadReportByQC(token, data))
  }

  const onCancelRequest = (task: AuditRoadInpro) => {
    setEnableModal(true)
    const data: ReturnAuditRoadReq = {
      UserEmail: UserEmail,
      ProjectCode: task.ProjectCode,
      RequestID: task.RequestID,
      CheckNumber: task.CheckNumber,
    }
    dispatch(returnAuditRoadByQC(token, data))
  }

  const onSendAcceptReq = (task: AuditRoadToDo, date: any) => {
    setAcceptTask({})
    setEnableModal(true)

    const data: AcceptAuditRoadReq = {
      UserEmail: UserEmail,
      ProjectCode: task.ProjectCode,
      RequestID: task.RequestID,
      CheckNumber: task.CheckNumber,
      AppointmentDate: date.format('YYYYMMDDHHmm'),
    }
    dispatch(acceptAuditRoadByQC(token, data))
  }

  const onRejectTaskReq = (task: AuditRoadToDo) => {
    setAcceptTask({})
    setEnableModal(true)
    const req: RejectCheckingTaskReq = {
      ProjectCode: task.ProjectCode,
      UserEmail,
      Module: 'AuditRoad',
      ActionByModule: 'QC',
      TaskList: [{ RequestID: task.RequestID }],
    }
    dispatch(RejectCheckingTasks(token, req))
  }

  const onSuccess = () => {
    setEnableModal(false)
    setSendReport(false)
    getDashboard()
  }

  const onSendReport = () => {
    setSelectedList([])
    setSendReport(true)
  }

  const pushList = (task: AuditRoadInpro) => {
    return {
      ProjectCode: task.ProjectCode,
      RequestID: task.RequestID,
      CheckNumber: task.CheckNumber,
    }
  }

  const isSelected = (task: AuditRoadInpro) => {
    const result =
      selectedList &&
      selectedList.find(
        item =>
          item.ProjectCode === task.ProjectCode &&
          item.RequestID === task.RequestID
      )
    return result ? true : false
  }

  const remove = (task: AuditRoadInpro) => {
    return selectedList.filter(
      item =>
        item.ProjectCode !== task.ProjectCode ||
        item.RequestID !== task.RequestID
    )
  }

  const onSelect = (task: AuditRoadInpro) => {
    const selected = isSelected(task)
    let newList = selectedList as any[]
    if (selected) {
      newList = remove(task)
    } else {
      newList.push(pushList(task))
    }

    setSelectedList([...newList])
  }

  const onSelectAll = () => {
    let newList = [] as any[]
    data.InProgress.forEach((item: any) => {
      if (item.HasSign && item.HasSelectPin && item.NumOfSelectLocation > 0) {
        newList.push(pushList(item))
      }
    })
    setSelectedList([...newList])
  }

  const onAcceptReq = (task: AuditRoadToDo) => {
    setAcceptTask(task)
  }

  const loading = (
    <div style={{ textAlign: 'center' }}>
      <Loading />
    </div>
  )

  const getLink = (item: any) => {
    return `/audit-road/report/${item.ProjectCode}/${item.RequestID}/${item.CheckNumber}`
  }

  const renderTodoCard = (task: AuditRoadToDo[]) => {
    return task.map((activity: AuditRoadToDo, index) => {
      return <TodoCard key={index} task={activity} onAcceptReq={onAcceptReq} />
    })
  }

  const renderInprogressCard = (task: AuditRoadInpro[]) => {
    return task.map((activity: AuditRoadInpro, index) => {
      const card = (
        <InproCard
          key={index}
          isSendReport={sendReport}
          isSelected={isSelected(activity)}
          onSelectLists={task => onSelect(task)}
          task={activity}
          onCancel={task => onCancelRequest(task)}
        />
      )
      return sendReport ? (
        card
      ) : (
        <Link key={index} to={getLink(activity)}>
          {card}
        </Link>
      )
    })
  }

  const renderReturnCard = (task: AuditRoadReturn[]) => {
    return task.map((activity: AuditRoadReturn, index) => {
      return (
        <Link key={index} to={getLink(activity)}>
          <ReturnCard task={activity} />
        </Link>
      )
    })
  }

  const renderTodo = (task: AuditRoadToDo[]) => {
    const card =
      task.length > 0 ? renderTodoCard(task) : <NoCard text="ไม่มีรายการ" />
    const content = event.IsLoading ? loading : card
    return (
      <Col span={8}>
        <MarginRight>
          <ColumnHeader heading={`รายการขอตรวจ (${task.length})`} />
          {content}
        </MarginRight>
      </Col>
    )
  }

  const renderInprogress = (task: AuditRoadInpro[]) => {
    const card =
      task.length > 0 ? (
        renderInprogressCard(task)
      ) : (
        <NoCard text="ไม่มีรายการตรวจ กรุณา “สร้างคำขอ”" />
      )
    const content = event.IsLoading ? loading : card
    return (
      <Col span={8}>
        <MarginRight>
          <ColumnHeader heading={`กำลังดำเนินการ`} />
          {content}
        </MarginRight>
      </Col>
    )
  }

  const renderReturn = (task: AuditRoadReturn[]) => {
    const card =
      task.length > 0 ? renderReturnCard(task) : <NoCard text="ไม่มีรายการ" />
    const content = event.IsLoading ? loading : card
    return (
      <Col span={8}>
        <MarginLeft>
          <ColumnHeaderSort
            heading={`CM/PJ ตอบกลับ`}
            orderName={'ล่าสุด'}
            type={sorting['return']}
            onSort={() => {
              sortingTask('return')
            }}
          />
          {content}
        </MarginLeft>
      </Col>
    )
  }

  const header = (
    <>
      {sendReport ? (
        <Btn>
          <PrimaryButton
            text="ยกเลิก"
            onClick={() => setSendReport(false)}
            btnType="Cancel"
          />
        </Btn>
      ) : (
        <Btn>
          <PrimaryButton text="ส่งรายงาน" onClick={onSendReport} />
        </Btn>
      )}
    </>
  )

  return (
    <>
      <Menu selected="road" />
      <SubnavBar breadcrumbs={breadcrumbs} hideSearch={true} />
      <RoadTab selected="AuditRoad" />
      <EventModal
        event={[actionEvent]}
        enable={enableModal}
        successCalback={onSuccess}
        errorCalback={() => setEnableModal(false)}
      />
      <Header>
        <Row type="flex" align="middle" justify="end">
          <Col>
            <LineBtn>{header}</LineBtn>
          </Col>
        </Row>
      </Header>
      <MainPage hasFooter={sendReport}>
        <Row type="flex" justify="space-between">
          {renderTodo(data.ToDo)}
          {renderInprogress(data.InProgress)}
          {renderReturn(data.Return)}
        </Row>
      </MainPage>
      {sendReport && (
        <PageFooter
          total={selectedList.length || 0}
          onSubmit={onSubmitSendReport}
          label="รายการในการส่งรายงาน"
          onSelectAll={onSelectAll}
        />
      )}

      <AcceptModal
        task={acceptTask}
        onClose={() => setAcceptTask({})}
        onAcceptReq={onSendAcceptReq}
        onRejectReq={onRejectTaskReq}
      />
    </>
  )
}

const Header = styled.div`
  padding: 0 120px;
  background-color: #f5f6fa;
  margin: 40px 0;
`

const LineBtn = styled.div`
  display: inline-flex;
  justify-content: flex-end;
`

const Btn = styled.div`
  width: 160px;
  margin-left: 16px;
`

const MarginRight = styled.div`
  margin-right: 16px;
`

const MarginLeft = styled.div`
  margin-left: 16px;
`

const mapStateToProps = (state: RootState) => {
  return {
    data: selectAuditRoad(state),
    event: selectEvent(state),
    actionEvent: selectActionEvent(state),
    token: serviceTokenSelector(state),
    UserEmail: userEmailSelector(state),
    sorting: selectSorting(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {
  sortingTask: sortingDashboard,
}

type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(AuditRoadDashboard)
