import React from 'react'
import styled from 'styled-components'
import { AuditRoadReturn } from 'features/qc/road/types'
import { Row, Col } from 'antd'
import { getRoadLocation } from 'utils/getText'
import { toMomentDateTime, toDateText } from 'utils/datetime'

interface Props {
  task: AuditRoadReturn
}

const ReturnCard: React.FC<Props> = props => {
  const { task } = props
  const { ProjectCode, ProjectNameTH, CheckNumber, CheckedDate } = task

  const checkedDate = toMomentDateTime(CheckedDate)
  const date = toDateText(checkedDate)

  const leftContent = (
    <LeftBox>
      <Row type="flex" align="middle">
        <Col>
          <TxtBold>
            {ProjectCode} - {ProjectNameTH}
          </TxtBold>
          <TxtReg>{getRoadLocation(task)}</TxtReg>
          <TxtReg>ตรวจครั้งที่ {CheckNumber}</TxtReg>
          <TxtReg>วันที่ตรวจ: {date}</TxtReg>
        </Col>
      </Row>
    </LeftBox>
  )

  return (
    <CardContent>
      <Row type="flex" align="middle">
        <Col span={18}>{leftContent}</Col>
      </Row>
    </CardContent>
  )
}

const CardContent = styled.div`
  border-radius: 10px;
  background-color: #fff;
  margin-bottom: 12px;
`

const LeftBox = styled.div`
  padding: 20px;
`

const TxtBold = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 20px;
  color #000;
`

const TxtReg = styled.div`
  font-family: 'DB Helvethaica X 55 Regular';
  font-size: 20px;
  color #000;
`

export default ReturnCard
