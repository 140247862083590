import { cloneDeep } from 'lodash'
import { toArray, getInt, getText } from './common'

import { FunctionDetail } from 'features/checklist/unit/types'
import {
  InspectionList,
  InspectionData,
  InspectionSubData,
} from 'features/types/checklist'

import { originalFunc } from 'features/checklist/unit/reducer'

export const toCheckList = (data: string): any => {
  let checklist: FunctionDetail = cloneDeep(originalFunc)

  let mode = ''
  const rows = toArray(data)
  rows.forEach(row => {
    const cmd = getText(row, 0).toLowerCase()
    switch (cmd) {
      case 'checklist name':
        mode = 'name'
        checklist.CheckListName = getText(row, 1)
        break

      case 'checklist type':
        mode = ''
        checklist.ProjectTypeID = getInt(row, 1)
        break

      case 'description':
        mode = 'description'
        addDetail(checklist, row)
        break

      case 'component':
        mode = 'component'
        addComponent(checklist, row)
        break

      case 'note':
      case 'doument':
      case 'doclist':
        mode = ''
        break

      case 'end':
        return checklist

      default:
        if (mode === 'description') addDetail(checklist, row)
        else if (mode === 'component') addComponent(checklist, row)
        else mode = ''

        break
    }
  })

  return checklist
}

const addDetail = (checklist: FunctionDetail, row: string[]) => {
  const cate = getText(row, 1)
  const title = getText(row, 2)
  const subTitle = getText(row, 3)

  if (subTitle.length === 0) return

  let mainItem: InspectionList = {
    InspectionCategory: cate,
    Seq: '',
    Level: 1,
    Data: [],
    isHide: false,
  }

  if (cate !== '') {
    checklist.InspectionList.push(mainItem)
  }
  const mainIndex = checklist.InspectionList.length - 1
  mainItem = checklist.InspectionList[mainIndex]

  let titleItem: InspectionData = {
    Name: title,
    Status: '',
    Remark: '',
    Seq: '',
    Level: 2,
    Data: [],
  }
  if (title !== '') {
    mainItem.Data.push(titleItem)
  }
  const titleIndex = mainItem.Data.length - 1
  titleItem = mainItem.Data[titleIndex]

  const subTitleItem: InspectionSubData = {
    Name: subTitle,
    Status: '',
    Remark: '',
    Seq: '',
    Level: 3,
  }

  titleItem.Data.push(subTitleItem)
}

const addComponent = (checklist: FunctionDetail, row: string[]) => {
  const text = getText(row, 1)
  if (text !== '') checklist.CheckList.push(text)
}
