import React, { useState } from 'react'

import AddModal from './AddModal'
import Item from './Item'
import AddCheckList from '../CheckList/AddCheckList'

interface Props {
  list: string[]
  onChange: (val: string[]) => void
  readOnly: boolean
}

const WarningList: React.FC<Props> = ({ list, onChange, readOnly }) => {
  const [visibleAdd, setVisibleAdd] = useState(false)
  const [visibleDelete, setVisibleDelete] = useState(false)
  const [visibleEdit, setVisibleEdit] = useState(false)
  const [index, setIndex] = useState(-1)

  const onAdd = (i: number) => {
    setVisibleAdd(true)
    setIndex(i)
  }

  const onDelete = (i: number) => {
    list.splice(i, 1)
    onChange(list)
  }

  const onEdit = (i: number, text: string) => {
    list[i] = text
    onChange(list)
  }

  const closeAdd = () => setVisibleAdd(false)
  const closeDelete = () => setVisibleDelete(false)
  const closeEdit = () => setVisibleEdit(false)

  const onAddSave = (val: string, i: number) => {
    closeAdd()
    if (i === -1) {
      list.push(val)
    } else {
      list.splice(i, 0, val)
    }

    onChange(list)
  }

  const content = list.map((item: any, index: number) => {
    const no = `${index + 1}`
    return (
      <Item
        text={item}
        key={index}
        index={index}
        onDelete={onDelete}
        onEdit={onEdit}
        readOnly={readOnly}
      />
    )
  })

  return (
    <>
      {content}
      <AddCheckList
        text="เพิ่มรายการ"
        level="first"
        readOnly={readOnly}
        onClick={() => onAdd(-1)}
      />
      <AddModal
        text=""
        visible={visibleAdd}
        onOk={onAddSave}
        onClose={closeAdd}
      />
    </>
  )
}

export default WarningList
