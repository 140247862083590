import React, { useState, useImperativeHandle, forwardRef } from 'react'
import { Modal, Row, Col } from 'antd'
import styled from 'styled-components'

import { CloseIcon } from 'Components/Shared/Icon'
import { PrimaryButton, OutlineButton } from 'Components/Shared/Button'

import InputText from 'Components/Shared/Input/InputText'
import { FixType } from 'features/checklist/auditRoad/types'

export interface JobHandles {
  showModal: (obj: any) => void
}

interface Props {
  addFix: (item: FixType) => void
  updateFix: () => void
}

const JobModalFix = forwardRef<JobHandles, Props>((props, ref) => {
  const [visible, setvisible] = useState(false)
  const [openWithFocus, setOpenWithFocus] = useState(false)
  const [name, setname] = useState('')
  const [item, setItem] = useState<FixType | undefined>()

  const clearClose = () => {
    setvisible(false)
    setname('')
    setItem(undefined)
    setOpenWithFocus(false)
  }

  const closeModal = () => {
    setvisible(false)
  }

  const addFix = () => {
    if (name) {
      if (item) {
        item.FixName = name
        props.updateFix()
      } else {
        props.addFix({ FixID: 0, FixName: name })
      }

      clearClose()
    }
  }

  useImperativeHandle(ref, () => ({
    showModal: (item?: FixType) => {
      setvisible(true)
      if (item) {
        setname(item.FixName)
        setItem(item)
      }
      setOpenWithFocus(true)
    },
  }))

  const title = item ? 'แก้ไข' : 'เพิ่ม'

  return (
    <ModalCheckList>
      <Modal
        centered
        visible={visible}
        width="528px"
        footer={null}
        closeIcon={<CloseIcon color="#8b99a7" size={16} />}
        title={<Header>{`${title}รายการ`}</Header>}
        className="add-checklist-modal"
        onCancel={closeModal}
      >
        <ModalContent>
          <SelectType>
            <Title>{`${title} วิธีแก้ไข`}</Title>
          </SelectType>

          <InputText
            prefix=""
            placeholder="กรอก วิธีแก้ไข"
            onChange={setname}
            value={name}
            openWithFocus={openWithFocus}
          />
          <ButtonSection>
            <Row>
              <Col span={7} offset={9}>
                <OutlineButton
                  text="ยกเลิก"
                  disabled={false}
                  onClick={closeModal}
                />
              </Col>
              <Col span={7} offset={1}>
                <PrimaryButton
                  text="บันทึก"
                  disabled={false}
                  onClick={addFix}
                />
              </Col>
            </Row>
          </ButtonSection>
        </ModalContent>
      </Modal>
    </ModalCheckList>
  )
})

const Header = styled.span`
  font-size: 28px;
  color: #000000;
  font-family: 'DB Helvethaica X 75 Bd';
`
const Title = styled.span`
  font-size: 20px;
  color: #000000;
  font-family: 'DB Helvethaica X 75 Bd';
`

const ModalCheckList = styled.div`
  .add-checklist-modal {
    .ant-modal-header {
      border-bottom: none;
    }
  }
`

const ModalContent = styled.div`
  padding: 0 24px 31px 24px;
  .ant-input {
    font-size: 20px;
  }
`

const SelectType = styled.div`
  padding-top: 17px;
`

const ButtonSection = styled.div`
  padding-top: 18px;
`

export default JobModalFix
