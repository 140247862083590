import React, { useState, useRef } from 'react'
import { Row, Col } from 'antd'

import {
  CheckListDetail,
  WorkType,
  JobType,
  SubJobType,
  DetailType,
  DefectType,
  QCType,
} from 'features/checklist/constructRoad/types'

import WorkBox from './WorkBox'
import JobBox from './JobBox'
import SubJobBox from './SubJobBox'
import DetailBox from './DetailBox'
import DefectBox from './DefectBox'
import QcTypeBox from './QcTypeBox'
import DetailConfigBox from './DetailConfigBox'
import Modal, { ModalHandles } from './Modal/Modal'

export interface SelectType {
  qctype: number
  work: number
  job: number
  subJob: number
  detail: number
}

interface Props {
  isActive: boolean
  checkListDetail: CheckListDetail
  updateCheckList: (val: CheckListDetail) => void
  onSave: () => void
}

const QCList: React.ComponentType<Props> = ({
  isActive,
  checkListDetail,
  updateCheckList,
  onSave,
}) => {
  const isUsed = checkListDetail.Status.toLocaleLowerCase() === 'used'
  const [selected, setSelected] = useState<SelectType>({
    qctype: 0,
    work: 0,
    job: 0,
    subJob: 0,
    detail: 0,
  })
  const modalRef = useRef<ModalHandles>(null)

  const toggleModal = (tab: number) => {
    if (modalRef.current) {
      modalRef.current.showModal(tab)
    }
  }

  const onqcType = (i: number) => {
    selected.qctype = i
    setSelected({ ...selected })
  }

  const onworkType = (i: number) => {
    selected.work = i
    setSelected({ ...selected })
  }

  const onJob = (i: number) => {
    selected.job = i
    setSelected({ ...selected })
  }

  const onSubJob = (i: number) => {
    selected.subJob = i
    setSelected({ ...selected })
  }

  const onDetail = (i: number) => {
    selected.detail = i
    setSelected({ ...selected })
  }

  const getqcType = () => {
    if (
      selected.qctype !== -1 &&
      selected.qctype < checkListDetail.QCTypeList.length
    ) {
      return checkListDetail.QCTypeList[selected.qctype]
    }
    return undefined
  }

  const getJob = (item: QCType | undefined) => {
    if (
      item &&
      selected.job !== -1 &&
      item.JobTypeList &&
      selected.job < item.JobTypeList.length
    ) {
      return item.JobTypeList[selected.job]
    }

    return undefined
  }

  const getSubJob = (item: JobType | undefined) => {
    if (
      item &&
      selected.subJob !== -1 &&
      selected.subJob < item.SubJobTypeList.length
    ) {
      return item.SubJobTypeList[selected.subJob]
    }

    return undefined
  }

  const getDetail = (item: SubJobType | undefined) => {
    if (
      item &&
      selected.detail !== -1 &&
      selected.detail < item.DetailList.length
    ) {
      return item.DetailList[selected.detail]
    }

    return undefined
  }

  const qcTypeItem: QCType | undefined = getqcType()
  const jobItem: JobType | undefined = getJob(qcTypeItem)
  const subJobItem: SubJobType | undefined = getSubJob(jobItem)
  const detailItem: DetailType | undefined = getDetail(subJobItem)

  const addWork = (qc: WorkType, index?: number) => {
    const obj = checkListDetail.WorkTypeList

    if (index != undefined) {
      obj[index] = qc
    } else {
      obj.push(qc)
    }
    updateCheckList(checkListDetail)
  }

  const addJob = (job: JobType, index?: number) => {
    const obj = checkListDetail.QCTypeList[selected.qctype].JobTypeList

    if (index != undefined) {
      obj[index] = job
    } else {
      obj.push(job)
    }
    updateCheckList(checkListDetail)
  }

  const addSubJob = (subjob: SubJobType, index?: number) => {
    const obj =
      checkListDetail.QCTypeList[selected.qctype].JobTypeList[selected.job]
        .SubJobTypeList

    if (index != undefined) {
      obj[index] = subjob
    } else {
      obj.push(subjob)
    }
    updateCheckList(checkListDetail)
  }

  const addDetail = (detail: DetailType, index?: number) => {
    const obj =
      checkListDetail.QCTypeList[selected.qctype].JobTypeList[selected.job]
        .SubJobTypeList[selected.subJob].DetailList

    if (index != undefined) {
      obj[index] = detail
    } else {
      obj.push(detail)
    }
    updateCheckList(checkListDetail)
  }

  const deleteQc = (it: WorkType) => {
    checkListDetail.WorkTypeList.filter(
      qc => qc.WorkTypeName !== it.WorkTypeName
    )
    updateCheckList(checkListDetail)
  }

  const deleteJob = (it: JobType) => {
    if (qcTypeItem) {
      qcTypeItem.JobTypeList = qcTypeItem.JobTypeList.filter(
        job => job.JobTypeName !== it.JobTypeName
      )
      updateCheckList(checkListDetail)
    }
  }

  const deleteSubJob = (it: SubJobType) => {
    if (jobItem) {
      jobItem.SubJobTypeList = jobItem.SubJobTypeList.filter(
        job => job.SubJobTypeName !== it.SubJobTypeName
      )
      updateCheckList(checkListDetail)
    }
  }

  const deleteDetail = (it: DetailType) => {
    if (subJobItem) {
      subJobItem.DetailList = subJobItem.DetailList.filter(
        job => job.DetailName !== it.DetailName
      )
      updateCheckList(checkListDetail)
    }
  }

  const openModalEdit = (obj: any, index: number, tab: number) => {
    if (modalRef.current) {
      modalRef.current.showModal(
        tab,
        obj,
        index,
        qcTypeItem ? qcTypeItem.QCTypeName : undefined
      )
    }
  }

  return (
    <div style={{ display: isActive ? '' : 'none' }}>
      <Row type="flex" justify="space-between">
        <Col span={12} style={css}>
          <DefectBox checkList={checkListDetail} disabled={isUsed} />
        </Col>
        <Col span={12} style={css}>
          <WorkBox
            item={checkListDetail}
            selected={selected.work}
            onSelected={onworkType}
            showModal={toggleModal}
            onDelete={deleteQc}
            openModalEdit={openModalEdit}
            disabled={isUsed}
          />
        </Col>
      </Row>
      <Row type="flex" justify="space-between">
        <Col span={8} style={css}>
          <QcTypeBox
            item={checkListDetail}
            selected={selected.qctype}
            onSelected={onqcType}
            onDelete={deleteQc}
            disabled={isUsed}
          />
        </Col>
        <Col span={8} style={css}>
          <JobBox
            item={qcTypeItem}
            selected={selected.job}
            onSelected={onJob}
            showModal={toggleModal}
            onDelete={deleteJob}
            openModalEdit={openModalEdit}
            disabled={isUsed}
          />
        </Col>
        <Col span={8}>
          <SubJobBox
            item={jobItem}
            selected={selected.subJob}
            onSelected={onSubJob}
            showModal={toggleModal}
            onDelete={deleteSubJob}
            openModalEdit={openModalEdit}
            disabled={isUsed}
          />
        </Col>
      </Row>

      <Row type="flex" justify="space-between">
        <Col span={12} style={css}>
          <DetailBox
            item={subJobItem}
            selected={selected.detail}
            onSelected={onDetail}
            showModal={toggleModal}
            onDelete={deleteDetail}
            openModalEdit={openModalEdit}
            disabled={isUsed}
          />
        </Col>

        <Col span={12}>
          <DetailConfigBox
            item={detailItem}
            onSave={onSave}
            disabled={isUsed}
          />
        </Col>
      </Row>
      <Modal
        selected={selected}
        checkListDetail={checkListDetail}
        addworkType={addWork}
        addJob={addJob}
        addSubJob={addSubJob}
        addDetail={addDetail}
        ref={modalRef}
        disabled={isUsed}
      />
    </div>
  )
}

const css = {
  paddingRight: '16px',
  marginBottom: '16px',
}
export default QCList
