import React, { useState, useEffect } from 'react'
import { Modal, Button, Row, Col } from 'antd'
import styled from 'styled-components'
import { SecondaryButton, PrimaryButton } from 'Components/Shared/Button'

type Props = {
  onClose: () => void
  onSubmit: () => void
  visible?: boolean
  title?: string
  desc?: string
  descSecond?: string
}

const ConfirmModal = (props: Props) => {
  const {
    visible = false,
    onClose,
    onSubmit,
    title = 'ยืนยันดำเนินการ',
    desc = '',
    descSecond = '',
  } = props

  return (
    <Modal
      className="confirm-modal"
      title={false}
      centered
      visible={visible}
      footer={false}
      closable={false}
    >
      <Body>
        <Header>{title}</Header>
        <Description>
          <p>{desc}</p>
          <p>{descSecond}</p>
        </Description>
        <ButtonSection>
          <Row type="flex" justify="center">
            <Col>
              <div className="btn-submit">
                <SecondaryButton
                  text="ยกเลิก"
                  disabled={false}
                  onClick={() => onClose()}
                />
              </div>
            </Col>
            <Col>
              <div className="btn-submit">
                <PrimaryButton text="ยืนยัน" onClick={() => onSubmit()} />
              </div>
            </Col>
          </Row>
        </ButtonSection>
      </Body>
    </Modal>
  )
}

const Header = styled.div`
  text-align: center;
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 24px;
  color: #000;
`

const Description = styled.div`
  text-align: center;
  font-family: 'DB Helvethaica X 55 Regular';
  font-size: 20px;
  color: #000;
  margin-top: 16px;
  margin-bottom: 16px;
  white-space: pre-wrap;
  p {
    line-height: 1;
  }
`

const Body = styled.div`
  text-align: center;
`

const ButtonSection = styled.div`
  margin-top: 32px;
  .btn-submit {
    width: 132px;
    margin: 0 8px;
  }
`

export default ConfirmModal
