import withAPIAction from '../../../utils/withAPIAction'
import {
  getCheckListReq,
  getQCTypeListReq,
  getUnitByUnitCheckListReq,
  getCheckListDetailReq,
  getFunctionCheckListReq,
  getFunctionCheckListDetailReq,
} from './actions'

// get list
export const getCheckList = (
  Page: number,
  RowPerPage: number,
  TextFilter: string = ''
) => {
  return withAPIAction(state => ({
    method: 'POST',
    path: 'GetUnitCheckList',
    data: {
      UserEmail: state.sensitive.userEmail,
      Page,
      RowPerPage,
      TextFilter,
    },
    token: `Bearer ${state.sensitive.apiToken}`,
  }))(getCheckListReq)
}

// get detail
export const getCheckListById = (CheckListID: number) => {
  return withAPIAction(state => ({
    method: 'POST',
    path: 'GetUnitCheckListById',
    data: { CheckListID, UserEmail: state.sensitive.userEmail },
    token: `Bearer ${state.sensitive.apiToken}`,
  }))(getCheckListDetailReq)
}

// get qc list
export const getQCTypeList = () => {
  return withAPIAction(state => ({
    method: 'POST',
    path: 'GetQCTypeList',
    data: {
      UserEmail: state.sensitive.userEmail,
      Status: 'Active',
      UnitType: 'Unit',
    },
    token: `Bearer ${state.sensitive.apiToken}`,
  }))(getQCTypeListReq)
}

// get unit list
export const getUnitByUnitCheckList = (
  ProjectCode: string,
  CheckListID: number
) => {
  return withAPIAction(state => ({
    method: 'POST',
    path: 'GetUnitByUnitCheckList',
    data: {
      UserEmail: state.sensitive.userEmail,
      ProjectCode,
      CheckListID,
    },
    token: `Bearer ${state.sensitive.apiToken}`,
  }))(getUnitByUnitCheckListReq)
}

// get function
export const getFunctionCheckList = (
  Page: number,
  RowPerPage: number,
  TextFilter: string = ''
) => {
  return withAPIAction(state => ({
    method: 'POST',
    path: 'GetFunctionCheckList',
    data: {
      UserEmail: state.sensitive.userEmail,
      Page,
      RowPerPage,
      TextFilter,
    },
    token: `Bearer ${state.sensitive.apiToken}`,
  }))(getFunctionCheckListReq)
}

// get function detail
export const getFunctionCheckListDetail = (CheckListID: string) => {
  return withAPIAction(state => ({
    method: 'POST',
    path: 'GetFunctionCheckListById',
    data: {
      UserEmail: state.sensitive.userEmail,
      CheckListID: CheckListID,
    },
    token: `Bearer ${state.sensitive.apiToken}`,
  }))(getFunctionCheckListDetailReq)
}
