import React, { useState } from 'react'

import { connect, useDispatch } from 'react-redux'
import { Layout } from 'antd'
import { RouteComponentProps } from 'react-router-dom'
import moment from 'moment'
import RootState from 'typings/RootState'
import {
  selectReport,
  selectDefect,
  selectEvent,
} from 'features/report/auditRoad/selector'

import {
  userEmailSelector,
  serviceTokenSelector,
} from 'features/sensitive/sensitive.selector'
import { selectUploadEvent } from 'features/uploadfile/selector'
import { ResendReportReq } from 'features/report/auditRoad/types'

import { resendReport } from 'features/report/auditRoad/api'
import AuditRoadReport from 'Components/AuditRoad/Report'
import EventModal from 'Components/Shared/Modal/EventModal'

import Breadcrumbs from './Breadcrumbs'
import QcFooter from './QcFooter'
import Signature from './Signature'
import ChoosePin from './ChoosePin'
import LocationModal from './LocationModal'

type Props = RouteComponentProps<{
  project: string
  requestID: string
  checkNumber: string
}> &
  ReduxProps

const QcAuditPlanReport: React.ComponentType<Props> = props => {
  const [page, setPage] = useState('')
  const [refresh, setRefresh] = useState(0)
  const [enableModal, setEnableModal] = useState(false)
  const [displaySign, setDisplaySign] = useState(false)
  const [displaySelectPin, setDisplaySelectPin] = useState(false)
  const [showLocation, setShowLocation] = useState(false)
  const {
    UserEmail,
    Token,
    report,
    defect,
    event,
    uploadEvent,
    history,
    match,
  } = props

  const { project, requestID, checkNumber } = match.params
  const RequestID = +requestID || 0
  const CheckNumber = +checkNumber || 0
  const dispatch = useDispatch()

  const getName = () => {
    switch (page) {
      case 'add.defect':
        return 'เพิ่ม Defect'
      case 'edit.defect':
        return 'แก้ไข Defect'
      case 'view.defect':
        return `หมุดที่ ${defect.PinNumber}`
      default:
        return undefined
    }
  }

  const gotoDashboard = () => {
    setEnableModal(false)
    history.push('/audit-road')
  }

  const closeEnableModal = () => setEnableModal(false)
  const onRefreshPage = () => {
    onClear()
    setRefresh(moment().unix())
  }

  const onClear = () => {
    setEnableModal(false)
    setDisplaySign(false)
    setPage('')
  }

  const onResend = () => {
    setEnableModal(true)
    const req: ResendReportReq = {
      UserEmail,
      ProjectCode: project,
      RequestID,
      CheckNumber,
    }

    dispatch(resendReport(Token, req))
  }

  let addMode = false
  let readOnly = true
  let isApprove = false
  if (report.State === 'QC') {
    if (report.Status === 'INPRO') {
      addMode = true
      readOnly = false
    } else if (report.Status === 'REJT') {
      isApprove = true
    }
  }

  if (displaySelectPin) {
    return (
      <ChoosePin
        onClose={() => setDisplaySelectPin(false)}
        onCompleted={() => {
          setDisplaySelectPin(false)
          onRefreshPage()
        }}
        ProjectCode={project}
        RequestID={RequestID}
        CheckNumber={CheckNumber}
      />
    )
  }

  return (
    <>
      <EventModal
        event={[event]}
        err={[uploadEvent]}
        enable={enableModal}
        successCalback={gotoDashboard}
        errorCalback={closeEnableModal}
      />
      <LocationModal
        onClose={() => setShowLocation(false)}
        onCompleted={() => {
          setShowLocation(false)
          onRefreshPage()
        }}
        ProjectCode={project}
        RequestID={RequestID}
        CheckNumber={CheckNumber}
        visible={showLocation}
      />
      <Signature
        display={displaySign}
        project={project}
        RequestID={RequestID}
        CheckNumber={CheckNumber}
        onClose={() => setDisplaySign(false)}
        onCompleted={onRefreshPage}
      />
      <Breadcrumbs
        path={`/audit-road/report/${project}/${RequestID}/${CheckNumber}`}
        title="ตรวจรับมอบถนนและก่อนหมดประกัน"
        name={getName()}
        onBack={() => setPage('')}
      />
      <AuditRoadReport
        title={'ตรวจรับมอบถนนและก่อนหมดประกัน'}
        initPage={page}
        ProjectCode={project}
        RequestID={RequestID}
        CheckNumber={CheckNumber}
        onPage={(name: string) => setPage(name)}
        addMode={addMode}
        readOnly={readOnly}
        isApprove={isApprove}
        isShowEditImg={false}
        isShowCorrectImg={isApprove}
        refresh={refresh}
      />
      <QcFooter
        report={report}
        visible={page === ''}
        onSign={() => setDisplaySign(true)}
        onResend={onResend}
        onSelectPin={() => setDisplaySelectPin(true)}
        onSelectLocation={() => setShowLocation(true)}
      />
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    Token: serviceTokenSelector(state),
    UserEmail: userEmailSelector(state),
    report: selectReport(state),
    defect: selectDefect(state),
    event: selectEvent(state),
    uploadEvent: selectUploadEvent(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(QcAuditPlanReport)
