import React, { useState, useCallback } from 'react'
import { Row, Col, Layout } from 'antd'
import styled from 'styled-components'
import TextArea from 'antd/lib/input/TextArea'
import AddDefectFooter from './AddDefectFooter'
import AddDefectImage from './AddDefectImage'
import { UnitDefectType, CheckListForQC } from 'features/report/unit/types'
import { DefectStatus } from 'features/types/status'
import {
  JobCheckList,
  SubJobCheckList,
  LocationCheckList,
  DetailCheckList,
  StatusCheckList,
} from './CheckList'

const { Content } = Layout

type Props = {
  onSave: () => void
  onDelete: () => void
  onEditPin: () => void
  unitDefect: UnitDefectType
  checkList: CheckListForQC
  onChange: (value: any) => void
  addMode?: boolean
  reportCheckNo?: number
}

const AddUnitDefect: React.FunctionComponent<Props> = props => {
  const {
    onChange,
    onSave,
    onDelete,
    onEditPin,
    unitDefect,
    checkList,
    addMode = true,
    reportCheckNo = 1,
  } = props
  const title: string = addMode ? 'เพิ่ม Defect' : 'แก้ไข Defect'
  const CheckNoNotEqual = !addMode && reportCheckNo !== unitDefect.CheckNumber

  const updateJob = (value: number) => {
    if (value !== unitDefect.JobTypeID) {
      unitDefect.JobTypeID = value
      unitDefect.SubJobTypeID = 0
      unitDefect.LocationID = 0
      unitDefect.DetailID = 0
      unitDefect.Score = 0
      onChange({ ...unitDefect })
    }
  }

  const updateSubJob = (value: number) => {
    if (value !== unitDefect.SubJobTypeID) {
      unitDefect.SubJobTypeID = value
      unitDefect.DetailID = 0
      unitDefect.Score = 0
      onChange({ ...unitDefect })
    }
  }

  const updateLocation = (value: number) => {
    unitDefect.LocationID = value
    onChange({ ...unitDefect })
  }

  const updateDetail = (value: number) => {
    const defectScore = getScore(detailDatas, value)
    unitDefect.DetailID = value
    unitDefect.Score = defectScore
    onChange({ ...unitDefect })
  }

  const updateStatus = (value: string) => {
    unitDefect.PinStatus = value
    onChange({ ...unitDefect })
  }

  const updateRemark = (value: string) => {
    unitDefect.Remark = value
    onChange({ ...unitDefect })
  }

  const updateBeforeImg = (value: any) => {
    unitDefect.ImageBefore = value
    onChange({ ...unitDefect })
  }

  const updateAfterImg = (value: any) => {
    unitDefect.ImageAfter = value
    onChange({ ...unitDefect })
  }

  const getSubJob = (jobId: number) => {
    const job =
      checkList.JobTypeList &&
      checkList.JobTypeList.find((job: any) => job.JobTypeID === jobId)
    return (job && job.SubJobTypeList) || []
  }

  const getLocation = (jobId: number) => {
    const job =
      checkList.JobTypeList &&
      checkList.JobTypeList.find((job: any) => job.JobTypeID === jobId)
    return (job && job.LocationList) || []
  }

  const getDetail = (subjobList: any[], subJobId: number) => {
    const job = subjobList.find((job: any) => job.SubJobTypeID === subJobId)
    return (job && job.DetailList) || []
  }

  const getScore = (detailDatas: any[], detailId: number) => {
    const job = detailDatas.find((job: any) => job.DetailID === detailId)
    return (job && job.Score) || 0
  }

  const jobDatas = checkList.JobTypeList
  const subJobDatas = getSubJob(unitDefect.JobTypeID)
  const locationDatas = getLocation(unitDefect.JobTypeID)
  const detailDatas = getDetail(subJobDatas, unitDefect.SubJobTypeID)

  const floorIndex = checkList.FloorList.findIndex(
    item => item.Floor === unitDefect.Floor
  )
  let planImg = ''
  if (floorIndex > -1) {
    const floorItem = checkList.FloorList[floorIndex]
    planImg = floorItem.ImageFloorPlan.FileURL
  }

  const TopSection = (
    <AddDefectImage
      planImg={planImg}
      pin={unitDefect}
      floor={unitDefect.Floor}
      onEditPin={CheckNoNotEqual ? undefined : onEditPin}
      onAddAfterImage={updateAfterImg}
      onAddBeforeImage={updateBeforeImg}
      viewMode={CheckNoNotEqual}
      canUpAfterImg={CheckNoNotEqual}
    />
  )

  const inputRemark = (
    <>
      <Header>หมายเหตุ</Header>
      <InputRemark>
        <TextArea
          value={unitDefect.Remark}
          onChange={evt => updateRemark(evt.target.value)}
          placeholder=""
          maxLength={255}
        />
      </InputRemark>
    </>
  )

  const BottomSection = (
    <ChecklistData>
      <Row type="flex" justify="space-between">
        <Col span={12}>
          <DropDownArea>
            <JobCheckList
              datas={jobDatas}
              active={unitDefect.JobTypeID}
              onChange={updateJob}
              disabled={CheckNoNotEqual}
            />
            <SubJobCheckList
              datas={subJobDatas}
              active={unitDefect.SubJobTypeID}
              onChange={updateSubJob}
              disabled={unitDefect.JobTypeID <= 0 || CheckNoNotEqual}
            />
            <DetailCheckList
              datas={detailDatas}
              active={unitDefect.DetailID}
              onChange={updateDetail}
              disabled={
                unitDefect.SubJobTypeID <= 0 ||
                CheckNoNotEqual ||
                unitDefect.PinStatus === DefectStatus.GOODWORK
              }
            />
            <LocationCheckList
              datas={locationDatas}
              active={unitDefect.LocationID}
              onChange={updateLocation}
              disabled={unitDefect.JobTypeID <= 0 || CheckNoNotEqual}
            />
          </DropDownArea>
        </Col>
        <Col span={12}>
          <RemarkArea>
            {inputRemark}
            <Status>
              <StatusCheckList
                active={unitDefect.PinStatus}
                onChange={updateStatus}
                disabled={false}
              />
            </Status>
          </RemarkArea>
        </Col>
      </Row>
    </ChecklistData>
  )

  const footerSection = (
    <AddDefectFooter
      unitDefect={unitDefect}
      onDelete={onDelete}
      onSave={onSave}
      addMode={addMode}
    />
  )

  return (
    <>
      <Content className="mid-content">
        <div className="main-content activity-detail">
          <Title>{title}</Title>
          {TopSection}
          {BottomSection}
        </div>
      </Content>
      {footerSection}
    </>
  )
}

const Title = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 28px;

  span {
    font-family: 'DB Helvethaica X 55 Regular';
    font-size: 16px;
    color: white;
    padding: 1px 10px;
    border-radius: 10px;
    margin-left: 12px;
  }
`

const ChecklistData = styled.div`
  margin-top: 24px;
`

const Header = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 20px;
  line-height: 1;
`

const DropDownArea = styled.div`
  padding-right: 15px;

  .ant-dropdown-trigger {
    margin-bottom: 14px;
    line-height: 0px;
  }
`
const RemarkArea = styled.div`
  padding-left: 15px;
`

const Status = styled.div`
  margin-top: 15px;
`

const InputRemark = styled.div`
  margin-top: 6px;
  .ant-input,
  .ant-input:focus,
  .ant-input:hover {
    font-family: 'DB Helvethaica X 55 Regular';
    font-size: 20px;
    height: 144px;
    border: solid 1px #e4e7eb;
  }
`

export default AddUnitDefect
