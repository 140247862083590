import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { connect, useDispatch } from 'react-redux'
import SubnavBar from 'Components/Shared/SubnavBar'
import { Row, Col } from 'antd'
import { RouteComponentProps, Link } from 'react-router-dom'
import RootState from 'typings/RootState'
import { selectedProjectCodeSelector } from 'features/project/project.selector'
import {
  userEmailSelector,
  serviceTokenSelector,
} from 'features/sensitive/sensitive.selector'
import {
  selectZoneData,
  selectEvent,
  selectZoneFilter,
} from 'features/qc/dashboard/selector'
import {
  getZoneForQC,
  SendZoneReportByQC,
  ReturnZoneByQC,
} from 'features/qc/dashboard/api'
import {
  TypeOfTask,
  TodoActivity,
  ZoneInProgressActivity,
  GetZoneReq,
  TaskList,
  ZoneList,
  SendZoneReportReq,
  ReturnZoneReq,
} from 'features/qc/dashboard/types'
import { clearFilter } from 'features/qc/dashboard/actions'
import { translate } from 'i18n'
import TKeys from 'i18n/translationKey'
import { NoCard, EventModal } from 'Components/Shared'
import CommonAreaTab from 'Components/Shared/Tab/CommonAreaTab'
import LinkButton from 'Components/Shared/LinkButton'
import Loading from 'Components/Shared/Loading'
import { PrimaryButton } from 'Components/Shared/Button'
import { BreadcrumbLink } from 'Components/Shared/BreadCrumb/BreadCrumb'

import Menu from '../../Menu'
import ColumnHeader from '../ColumnHeader'
import TodoCard from '../TodoCard'
import InprogressCard from './InprogressCard'
import PageFooter from '../PageFooter'
import MainPage from '../MainPage'
import { QCZoneFilter } from 'Components/Filter'

const breadcrumbs: BreadcrumbLink[] = [
  {
    url: '/zone/dashboard',
    title: 'Home',
    icon: '/home.png',
    active: true,
    showTitle: false,
  },
  {
    url: '/zone/dashboard',
    title: translate(TKeys.Breadcrumb.dashboard),
    active: false,
    showTitle: false,
  },
]

type Props = ReduxProps & DispatchProps & RouteComponentProps

const CommonAreaDashboard: React.ComponentType<Props> = props => {
  const { data, event, token, ProjectCode, UserEmail, filter } = props
  const [sendReport, setSendReport] = useState(false)
  const [projectList, setProjectList] = useState([] as any[])
  const [enableModal, setEnableModal] = useState(false)

  const dispatch = useDispatch()
  useEffect(() => {
    getDashboardData()
  }, [getZoneForQC, ProjectCode, dispatch])

  useEffect(() => {
    setEnableModal(false)
  }, [data])

  const getDashboardData = () => {
    const req: GetZoneReq = {
      UserEmail: UserEmail,
      ProjectCode: filter.ProjectCode,
      UnitList: filter.ZoneList,
      FloorList: filter.FloorList,
    }

    dispatch(getZoneForQC(token, req))
  }

  const onSumitSendReport = () => {
    setEnableModal(true)
    const req: SendZoneReportReq = {
      UserEmail: UserEmail,
      ProjectList: projectList,
      TaskType: 'zone',
    }
    dispatch(SendZoneReportByQC(token, req))
  }

  const onReturnTask = (projectCode: string, zone: ZoneList) => {
    setEnableModal(true)
    const req: ReturnZoneReq = {
      UserEmail: UserEmail,
      ProjectCode: projectCode,
      Floor: zone.Floor,
      Unit: zone.Unit,
      CheckNumber: zone.CheckNumber,
      RequestID: zone.RequestID,
      QCTypeID: zone.QCTypeID,
    }

    dispatch(ReturnZoneByQC(token, req))
  }

  useEffect(() => {
    return () => {
      dispatch(clearFilter())
    }
  }, [])

  const onSearch = () => {
    getDashboardData()
  }

  const onSendReport = () => {
    setProjectList([])
    setSendReport(true)
  }

  const onSuccess = () => {
    setEnableModal(false)
    onCancelSendReport()
    getDashboardData()
  }

  const gotoPMRList = () => {
    props.history.push('/zone/pmr/list')
  }

  const isSelected = (tasks: TaskList[], zone: ZoneList) => {
    const result = tasks.find(task => task.Unit === zone.Unit)
    return result ? true : false
  }

  const remove = (tasks: TaskList[], zone: ZoneList) => {
    return tasks.filter(
      task => task.Unit !== zone.Unit || task.RequestID !== zone.RequestID
    )
  }

  const findProject = (projectList: any[], projectCode: string) => {
    const result = projectList.find(
      project => project.ProjectCode === projectCode
    )
    return result ? true : false
  }

  const pushTaskList = (zone: ZoneList) => {
    return {
      Unit: zone.Unit,
      QCTypeID: zone.QCTypeID,
      QCTypeVersion: zone.QCTypeVersion,
      RequestID: zone.RequestID,
      CheckNumber: zone.CheckNumber,
      Floor: zone.Floor,
    }
  }

  const onSelect = (projectCode: string, zone: ZoneList) => {
    const isHasProject = findProject(projectList, projectCode)
    if (isHasProject) {
      const project = projectList.map(project => {
        if (project.ProjectCode === projectCode) {
          const selected = isSelected(project.TaskList, zone)
          if (selected) {
            project.TaskList = remove(project.TaskList, zone)
          } else {
            project.TaskList.push(pushTaskList(zone))
          }
        }
        return project
      })
      setProjectList([...project])
    } else {
      let newProject = projectList
      newProject.push({
        ProjectCode: projectCode,
        TaskList: [pushTaskList(zone)],
      })
      setProjectList([...newProject])
    }
  }

  const isCorrect = (status: string) => status === 'Y'

  const onSelectAll = () => {
    let newProject = [] as any
    data.InProgress.forEach(project => {
      let tasks = [] as any
      project.ZoneList.forEach(zone => {
        if (isCorrect(zone.HasSelectedDefect) && isCorrect(zone.HasSign)) {
          tasks.push(pushTaskList(zone))
        }
      })
      newProject.push({
        ProjectCode: project.ProjectCode,
        TaskList: tasks,
      })
    })

    setProjectList([...newProject])
  }

  const onCancelSendReport = () => {
    setSendReport(false)
  }

  const renderTodoCard = (task: any[], type: string) => {
    let card = []
    task.map((activity, index) => {
      card.push(
        <Link to={`/zone/todo/${activity.ProjectCode}`} key={index}>
          <TodoCard key={index} task={activity} type={type} />
        </Link>
      )
    })

    if (task.length >= 1) {
      card.push(<LinkButton href={`/zone/projects`} title={'ดูเพิ่มเติม'} />)
    }
    return card
  }

  const renderInprogressCard = (
    task: ZoneInProgressActivity[],
    type: string
  ) => {
    let card = [] as any
    task.map((activity: ZoneInProgressActivity, index) => {
      card.push(
        <InprogressCard
          key={index}
          task={activity}
          type={type}
          isSendReport={sendReport}
          onClickReturn={zone => onReturnTask(activity.ProjectCode, zone)}
          onSelectLists={onSelect}
          projectList={projectList}
        />
      )
    })

    return card
  }

  const loading = (
    <div style={{ textAlign: 'center' }}>
      <Loading />
    </div>
  )

  const sumTodoTask = (task: TodoActivity[]) => {
    let total = 0
    task.forEach(item => {
      if (item.NumberOfTask >= 0) {
        total += item.NumberOfTask
      }
    })
    return total
  }

  const renderTodoTask = (task: TodoActivity[]) => {
    const card =
      task.length > 0 ? renderTodoCard(task, TypeOfTask.TODO) : <NoCard />
    const content = event.IsLoading ? loading : card
    const total = sumTodoTask(task)
    return (
      <Col span={8}>
        <FullColumn>
          <ColumnHeader heading={`รายการขอตรวจ (${total})`} />
          {content}
        </FullColumn>
      </Col>
    )
  }

  const sumInprogressTask = (task: ZoneInProgressActivity[]) => {
    let total = 0
    task.forEach((activity: ZoneInProgressActivity) => {
      total += activity.ZoneList.length
    })
    return total
  }

  const countSelected = () => {
    let total = 0
    projectList.forEach(project => {
      total += project.TaskList.length
    })
    return total
  }

  const renderInprogressTask = (task: ZoneInProgressActivity[]) => {
    const card =
      task.length > 0 ? (
        renderInprogressCard(task, TypeOfTask.INPROGRESS)
      ) : (
        <NoCard text="ไม่มีรายการตรวจ. กรุณาเลือก “โครงการขอตรวจ”" />
      )
    const content = event.IsLoading ? loading : card
    const total = sumInprogressTask(task)
    return (
      <Col span={16}>
        <ColumnHeader heading={`กำลังดำเนินการ (${total})`} />
        {content}
      </Col>
    )
  }

  const disableSendReport = !data.InProgress || data.InProgress.length <= 0
  const header = (
    <Header>
      <Row type="flex" justify="space-between" align="middle">
        <Col>
          <QCZoneFilter onSearch={onSearch} />
        </Col>
        <Col>
          <Row type="flex" justify="end" align="middle">
            <Col>
              <Button>
                <Link to="/common-area/reject-list">
                  <PrimaryButton
                    text="รายการตีกลับ"
                    onClick={gotoPMRList}
                    disabled={sendReport ? true : false}
                  />
                </Link>
              </Button>
              <Button>
                {!sendReport ? (
                  <PrimaryButton
                    text="ส่งรายงาน"
                    onClick={() => onSendReport()}
                    disabled={disableSendReport}
                  />
                ) : (
                  <PrimaryButton
                    text="ยกเลิก"
                    onClick={() => onCancelSendReport()}
                    btnType="Cancel"
                  />
                )}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Header>
  )

  const selectedTotal = countSelected()
  return (
    <>
      <EventModal
        event={[event]}
        enable={enableModal}
        successCalback={() => onSuccess()}
      />
      <Menu selected="commonArea" />
      <SubnavBar breadcrumbs={breadcrumbs} hideSearch={true} />
      <CommonAreaTab selected="Zone" />
      {header}
      <MainPage hasFooter={sendReport}>
        <Row type="flex">
          {renderTodoTask(data.ToDo)}
          {renderInprogressTask(data.InProgress)}
        </Row>
      </MainPage>
      {sendReport && (
        <PageFooter
          onSubmit={onSumitSendReport}
          label="รายการในการส่งรายงาน"
          total={selectedTotal}
          disabled={selectedTotal <= 0}
          onSelectAll={onSelectAll}
        />
      )}
    </>
  )
}

const FullColumn = styled.div`
  padding-right: 20px;
`

const Button = styled.div`
  width: 200px;
  float: left;
  margin-left: 24px;
`

const Header = styled.div`
  padding: 0 120px;
  background-color: #f5f6fa;
  margin: 40px 0;
`

const mapStateToProps = (state: RootState) => {
  return {
    data: selectZoneData(state),
    event: selectEvent(state),
    token: serviceTokenSelector(state),
    ProjectCode: selectedProjectCodeSelector(state),
    UserEmail: userEmailSelector(state),
    filter: selectZoneFilter(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {
  getZoneForQC,
}

type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(CommonAreaDashboard)
