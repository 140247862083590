import React from 'react'

type DefaultProps = {
  size?: number
  color?: string
  onClick?: any
}

const EditIcon = (props: DefaultProps) => {
  const { size = 20, color = '#fff', onClick } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      onClick={onClick}
    >
      <path
        fill={color}
        fillRule="evenodd"
        d="M21.966 5.122c0-.835-.327-1.62-.918-2.21a3.13 3.13 0 0 0-4.421.004L4.04 15.553c-.409.41-.721.916-.903 1.465l-.012.035L2 22l4.96-1.11.037-.013a3.752 3.752 0 0 0 1.467-.904L21.052 7.334c.59-.59.915-1.376.914-2.212zM4.751 19.784l-.526-.526.38-1.67 1.82 1.821-1.674.375zM19.946 6.232l-12.22 12.27-2.213-2.215 8.37-8.404 1.275 1.274 1.104-1.104-1.277-1.277 1.188-1.192 1.28 1.28 1.103-1.105-1.281-1.281.457-.46a1.567 1.567 0 0 1 2.673 1.105c0 .419-.162.812-.459 1.109zm-9.733 14.19h11.753v1.561H8.657l1.556-1.561z"
      />
    </svg>
  )
}

export default EditIcon
