import {
  UnitCheckListByCodeType,
  ModalType,
  UnitType,
  UnitCheckListItem,
} from 'features/checklist/unit/types'

type UnitIsCheckAllKey = {
  [key: string]: {
    isCheck: boolean
    isReadOnly: boolean
  }
}

function findModel(unitResult: UnitCheckListItem, modal: ModalType) {
  return unitResult.ModelList.find(
    m => m.ModelID === modal.ModelID && m.DirectionName === modal.DirectionName
  )
}

function filterModel(unitResult: UnitCheckListItem, modal: ModalType) {
  return unitResult.ModelList.filter(
    m =>
      !(m.ModelID === modal.ModelID && m.DirectionName === modal.DirectionName)
  )
}

export function calculateOnInitiate(
  CheckListID: number,
  unit: UnitCheckListByCodeType,
  unitIsCheckAll: UnitIsCheckAllKey,
  code: string
) {
  unitIsCheckAll = {
    [code]: {
      isCheck: false,
      isReadOnly: true,
    },
  }
  let selectedCount = 0
  let usedCount = 0

  unit[code].ModelList.forEach((modal: ModalType) => {
    selectedCount = 0
    usedCount = 0
    modal.UnitList.forEach((unit: UnitType) => {
      let Selected = 'N'
      let detail = ''
      let isReadOnly = false

      /*
          12/01/2021:
            https://trello.com/c/xqHan094/10-adminqc-%E0%B8%9B%E0%B8%A3%E0%B8%B1%E0%B8%9A%E0%B9%83%E0%B8%AB%E0%B9%89%E0%B8%AA%E0%B8%B2%E0%B8%A1%E0%B8%B2%E0%B8%A3%E0%B8%96%E0%B8%9B%E0%B8%A5%E0%B8%94%E0%B8%A5%E0%B9%8A%E0%B8%AD%E0%B8%84-checklist-%E0%B8%95%E0%B8%A3%E0%B8%A7%E0%B8%88%E0%B8%A1%E0%B8%B2%E0%B8%95%E0%B8%A3%E0%B8%90%E0%B8%B2%E0%B8%99%E0%B8%97%E0%B8%B5%E0%B9%88%E0%B8%96%E0%B8%B9%E0%B8%81%E0%B9%83%E0%B8%8A%E0%B9%89%E0%B9%81%E0%B8%A5%E0%B9%89%E0%B8%A7%E0%B9%83%E0%B8%99%E0%B8%A3%E0%B8%B2%E0%B8%A2%E0%B9%81%E0%B8%9B%E0%B8%A5%E0%B8%87%E0%B9%84%E0%B8%94%E0%B9%89-%E0%B9%80%E0%B8%9E%E0%B8%B7%E0%B9%88%E0%B8%AD%E0%B8%97%E0%B8%B5%E0%B9%88%E0%B8%88%E0%B8%B0%E0%B8%9C%E0%B8%B9%E0%B8%81%E0%B8%81%E0%B8%B1%E0%B8%9A-checklist-%E0%B9%83%E0%B8%AB%E0%B8%A1%E0%B9%88 
            Link to card: https://trello.com/c/xqHan094
            ปรับให้สามารถปลดล๊อค 
            Checklist ตรวจมาตรฐานที่ถูกใช้แล้วในรายแปลงได้ 
            เพื่อที่จะผูกกับ Checklist ใหม่
        */

      if (unit.Status.toLocaleLowerCase() === 'used') {
        if (unit.CheckListID === CheckListID) {
          detail = 'ใช้แล้ว'
          Selected = 'Y'
          isReadOnly = false
        } else {
          detail = 'ใช้แล้วกับ Checklist อื่น'
          Selected = 'N'
          isReadOnly = true
        }
      } else {
        const isSameID = CheckListID !== 0 && CheckListID === unit.CheckListID
        if (isSameID) {
          Selected = unit.Selected || 'Y'
        } else {
          const checkId = unit.CheckListID || 0
          if (checkId !== 0) {
            isReadOnly = true
            Selected = 'Y'
            detail = 'ผูกแล้วกับ Checklist อื่น'
          } else {
            Selected = unit.Selected || 'N'
          }
        }
      }
      unit.Selected = Selected
      unit.detail = detail
      unit.isReadOnly = isReadOnly

      if (isReadOnly) {
        usedCount += 1
      } else if (unit.Selected === 'Y') {
        selectedCount += 1
      }
    })

    if (
      selectedCount > 0 &&
      selectedCount + usedCount === modal.UnitList.length
    ) {
      unitIsCheckAll[code].isReadOnly = false
      modal.isCheck = true
    } else if (usedCount === modal.UnitList.length) {
      modal.isReadOnly = true
    } else {
      unitIsCheckAll[code].isReadOnly = false
      modal.isCheck = false
    }
  })
  return { unit, unitIsCheckAll }
}

export function updateModalsChange(
  val: string,
  modal: ModalType,
  unitResult: UnitCheckListItem
) {
  let foundUnit
  let foundNotReadOnly = false
  const selected: UnitType[] = []
  const fModel = findModel(unitResult, modal)

  // unit
  modal.UnitList.forEach(unit => {
    if (unit.isReadOnly === false && unit.Selected !== val) {
      unit.Selected = val
      foundNotReadOnly = true
      if (fModel == null) {
        selected.push({ ...unit, Unit: unit.UnitName, Selected: val })
      } else {
        foundUnit = false
        for (let i = 0; i < fModel.UnitList.length; i++) {
          if (fModel.UnitList[i].UnitName === unit.UnitName) {
            foundUnit = true
            if (fModel.UnitList[i].Selected !== val) {
              fModel.UnitList.splice(i, 1)
            }
            break
          }
        }
        if (foundUnit == false) {
          fModel.UnitList.push({
            UnitName: unit.UnitName,
            Unit: unit.UnitName,
            CheckListID: unit.CheckListID,
            Status: unit.Status,
            Selected: val,
          })
        }
      }
    }
  })

  // unitResult
  if (foundNotReadOnly && fModel == null && selected.length > 0) {
    unitResult.ModelList.push({
      ...modal,
      UnitList: selected,
    })
  } else if (fModel && fModel.UnitList.length === 0) {
    unitResult.ModelList = filterModel(unitResult, modal)
  }
}

export function updateUnitChange(
  val: string,
  it: UnitType,
  modal: ModalType,
  unitResult: UnitCheckListItem
) {
  it.Selected = val
  const found = findModel(unitResult, modal)
  if (found) {
    const unitIndex = found.UnitList.findIndex(u => u.UnitName === it.UnitName)
    if (unitIndex > -1) {
      if (found.UnitList.length === 1) {
        unitResult.ModelList = filterModel(unitResult, modal)
      } else {
        found.UnitList.splice(unitIndex, 1)
      }
    } else {
      found.UnitList.push({
        UnitName: it.UnitName,
        Unit: it.UnitName,
        CheckListID: it.CheckListID,
        Status: it.Status,
        Selected: val,
      })
    }
  } else {
    unitResult.ModelList.push({
      ...modal,
      UnitList: [
        {
          UnitName: it.UnitName,
          Unit: it.UnitName,
          CheckListID: it.CheckListID,
          Status: it.Status,
          Selected: val,
        },
      ],
    })
  }
}

export function updateModelChange(
  modal: ModalType,
  unitResult: UnitCheckListItem
) {
  const found = findModel(unitResult, modal)

  if (!found) {
    unitResult.ModelList.push({
      ...modal,
      UnitList: [],
    })
  }
}
