import React from 'react'
import styled from 'styled-components'
import { DropdownList } from 'Components/Shared/Form'
import { RoleListItem } from 'features/filter/types'

type Props = {
  onFilterChange: (filter: any) => void
  filter: any
  roles: RoleListItem
}

const RoleFilter: React.FunctionComponent<Props> = props => {
  const { onFilterChange, filter, roles } = props

  const renderData = () => {
    let data = [] as any[]
    roles.List.map((role, index) => {
      data.push({
        name: role.RoleName,
        value: role.RoleID,
      })
    })

    if (data.length > 0) {
      data.unshift({
        name: `ทั้งหมด`,
        value: 0,
      })
    }

    return data
  }

  return (
    <Box>
      <DropdownList
        label="กลุ่มผู้ตรวจ"
        value={filter.UserRoleID}
        menu={renderData()}
        onChange={(value: any) => {
          filter.UserRoleID = value
          filter.UserID = ''
          onFilterChange(filter)
        }}
      />
    </Box>
  )
}

const Box = styled.div`
  padding: 0 12px 12px 0;
`

export default RoleFilter
