import { getType } from 'typesafe-actions'
import { cloneDeep } from 'lodash'
import * as actions from './wbs.actions'
import { Event } from '../types/event'

import { WbsAction, WbsById, WbsState, WbsPathMap } from './wbs.types'
import produce from 'immer'
import { getWbsList } from './wbs.function'
import parseErrorMessage from 'utils/parseErrorMessage'
import keyByFieldName from 'utils/keyByFieldName'

const initEvent = {
  IsLoading: false,
  MessageSuccess: undefined,
  SubmitError: undefined,
  SubmitSuccess: false,
  SubmitType: undefined,
} as Event

const initialState: WbsState = {
  ProjectCode: '',
  wbsById: {} as WbsById,
  wbsPathMap: {} as { [key in number]: string },
  wbsList: [],
  wbsMapParent: {} as { [key in number]: string },
  event: cloneDeep(initEvent),
}

export default function(
  state: WbsState = initialState,
  action: WbsAction
): WbsState {
  return produce(state, draft => {
    switch (action.type) {
      case getType(actions.fetchWbsListAsyncAction.request):
        draft.event = cloneDeep(initEvent)
        draft.event.IsLoading = true
        break
      case getType(actions.fetchWbsListAsyncAction.success):
        const data = action.payload.ResponseData

        draft.wbsById = data.length ? keyByFieldName(data, 'WbsID') : {}
        draft.wbsList = getWbsList(data)

        draft.event.IsLoading = false
        break
      case getType(actions.fetchWbsListAsyncAction.failure):
        const payload = action.payload.response

        // When payload is null it means there is no internet connection. Backend API server is
        // unreachable.
        if (!payload) {
          draft.event.SubmitError =
            'Network connection error. Please check your internet connection.'
        } else {
          const response: ApiResponse = action.payload.response.data
          const message = parseErrorMessage(response)
          draft.event.SubmitError = message
        }

        draft.event.IsLoading = false

        break
      case getType(actions.updateWbsPathMap):
        const wbsPathMap: Map<number, string> = action.payload

        // Change Map to object as Map is still not supported on Redux Dev Tools
        // @see https://github.com/zalmoxisus/redux-devtools-extension/issues/124
        const obj = {} as WbsPathMap
        wbsPathMap.forEach((v, k) => (obj[k] = v))

        draft.wbsPathMap = obj
        break
      case getType(actions.updateWbsMapParent):
        draft.wbsMapParent = action.payload
        break

      case getType(actions.setWbsProjectCode):
        draft.ProjectCode = action.payload
        break

      case getType(actions.updateWbsEvent):
        draft.event.IsLoading = action.payload
        break

      default:
        return state
    }
  })
}
