import React, { useEffect } from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { Layout } from 'antd'
import TokenExpired from 'Components/Pages/TokenExpired'

import QcUnitApp from './QcUnitApp'
import QcZoneApp from './QcZoneApp'
import QcCommissApp from './QcCommissApp'
import QcConstructPlanApp from './QcConstructPlanApp'
import QcAuditPlanApp from './QcAuditPlanApp'

import QcAuditRoadApp from './QcAuditRoadApp'
import QcConstructRoadApp from './QcConstructRoadApp'
import Siricon from './Siricon'

type Props = {}

const QcApp: React.FunctionComponent<Props> = props => {
  return (
    <BrowserRouter>
      <Layout className="layout" hasSider={false}>
        <Switch>
          <Route path="/unit" component={QcUnitApp} />
          <Route path="/zone" component={QcZoneApp} />
          <Route path="/commissioning" component={QcCommissApp} />
          <Route path="/construct-plan" component={QcConstructPlanApp} />
          <Route path="/audit-plan" component={QcAuditPlanApp} />

          <Route path="/audit-road" component={QcAuditRoadApp} />
          <Route path="/construct-road" component={QcConstructRoadApp} />
          <Route path="/siricon-plan" component={Siricon} />
          <Route path="/" component={QcUnitApp} />
          <Route exact path="/token-expired" component={TokenExpired} />
        </Switch>
      </Layout>
    </BrowserRouter>
  )
}

export default QcApp
