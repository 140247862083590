import React, { useState } from 'react'
import styled from 'styled-components'

import { DropdownList } from 'Components/Shared/Form'
import InputText from 'Components/Shared/Input/InputText'
import {
  JobType,
  CheckListDetail,
  SubJobType,
} from 'features/checklist/constructRoad/types'
import { SelectType } from '..'
import { BottomButton } from 'Components/Shared/Button'
import QcTypeDropdown from './QcTypeDropdown'

interface Props {
  selected: SelectType
  checkListDetail: CheckListDetail
  editingObj?: SubJobType
  onSave: (subjob: SubJobType) => void
  closeModal: () => void
  disabled: boolean
}

const SubJobModal: React.FC<Props> = ({
  checkListDetail,
  selected,
  editingObj,
  onSave: onSaveProp,
  closeModal,
  disabled,
}) => {
  const [error, seterror] = useState('')
  const [name, setname] = useState(editingObj ? editingObj.SubJobTypeName : '')
  const qctype = checkListDetail.QCTypeList[selected.qctype]
  const job = qctype.JobTypeList[selected.job]

  const onSave = () => {
    if (name && job) {
      const found = editingObj
        ? null
        : job.SubJobTypeList.find(v => v.SubJobTypeName === name)
      if (found == null) {
        onSaveProp({
          SubJobTypeID: editingObj ? editingObj.SubJobTypeID : 0,
          SubJobTypeName: name,
          DetailList: editingObj ? editingObj.DetailList : [],
        })
      } else {
        seterror('ลักษณะงานรองนี้ ได้มีอยู่แล้ว')
      }
    } else {
      seterror('กรุณาใส่ข้อมูลทั้งหมด')
    }
  }

  const changeName = (val: string) => {
    setname(val)
  }

  const menuChoiceJob = () => {
    const result = qctype.JobTypeList.map((item: JobType, index: number) => (
      <DataItem key={index}>{item.JobTypeName}</DataItem>
    ))
    return result
  }

  return (
    <MainView>
      <Box>
        <InputText
          prefix="ชื่อ"
          value={name}
          placeholder="กรอกชื่อ"
          onChange={changeName}
          openWithFocus
          disabled={disabled}
        />
      </Box>
      <QcTypeDropdown selected={selected} checkListDetail={checkListDetail} />

      <Box>
        <div className="dropdown-item">
          <DropdownList
            label="ลักษณะงาน"
            value=""
            placeholder={job ? job.JobTypeName : 'กรุณาเลือก'}
            menuChoice={menuChoiceJob()}
            disabled={true}
          />
        </div>
      </Box>

      {!disabled && (
        <BottomButton closeModal={closeModal} onSave={onSave} error={error} />
      )}
    </MainView>
  )
}

const MainView = styled.div``

const Box = styled.div`
  padding-top: 12px;
`

const DataItem = styled.div`
  padding: 5px 10px;

  &:hover {
    cursor: pointer;
    color: white;
    background-color: #003168;
  }
`

export default SubJobModal
