import React from 'react'
import { Col } from 'antd'
import styled from 'styled-components'
import { TableRow } from 'Components/Shared/Table'
import { DataItem } from 'features/adminReport/sqdbymaincon/types'
import { numberFormat } from 'utils/format'

type DefaultProps = {
  item: DataItem
  getSpan(key: string): number
}

type Props = DefaultProps

const RowItem: React.FC<Props> = ({ item, getSpan }) => {
  const renderData = (val: any, key: string) => {
    return <Col span={getSpan(key)}>{val}</Col>
  }

  return (
    <RowModel>
      <TableRow
        minHeight={60}
        spaceBetweenRow={8}
        paddingLeft={24}
        cursor="default"
      >
        {renderData(<Bold>{item.VendorName}</Bold>, 'VendorName')}
        {renderData(
          <Bold>
            {item.ProjectCode}-{item.ProjectNameTH}
          </Bold>,
          'ProjectCode'
        )}
        {renderData(<TxtReg>{item.ProjectTypeName}</TxtReg>, 'ProjectTypeName')}
        {renderData(<TxtReg>{item.TotalArea}</TxtReg>, 'TotalArea')}
        {renderData(
          <TxtReg>{numberFormat(item.UnitScoreFirst)}</TxtReg>,
          'UnitScoreFirst'
        )}
        {renderData(
          <TxtReg>{numberFormat(item.UnitScoreLast)}</TxtReg>,
          'UnitScoreLast'
        )}
        {renderData(
          <TxtReg>{numberFormat(item.SiteVisitScore)}</TxtReg>,
          'SiteVisitScore'
        )}
      </TableRow>
    </RowModel>
  )
}

const RowModel = styled.div`
  cursor: pointer;
`

const Bold: any = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  color: #000;
  font-size: 20px;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  padding-right: 5px;
`

const TxtReg = styled(Bold)`
  font-family: 'DB Helvethaica X 55 Regular';
`

export default RowItem
