import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { RootState } from 'typings/RootState'
import styled from 'styled-components'
import { Button, Col } from 'antd'
import { JsonParam, useQueryParam } from 'use-query-params'

import {
  getConstructType,
  getFloorList,
  getZoneList,
  getUnitList,
} from 'features/filter/api'
import {
  ActivityFilter,
  GetConstructTypeReq,
  GetFloorListReq,
  GetZoneListReq,
  GetUnitListReq,
} from 'features/filter/types'
import {
  selectActivityFilter,
  selectConstructType,
  selectFloorList,
  selectZoneList,
  selectUnitList,
} from 'features/filter/selector'
import { getLeafWbsList } from 'features/wbs/wbs.function'

import { clearFilter, updateActivityFilter } from 'features/filter/actions'
import { selectedProjectCodeSelector } from 'features/project/project.selector'
import {
  serviceTokenSelector,
  userEmailSelector,
} from 'features/sensitive/sensitive.selector'

import { getWbsListSelector } from 'features/wbs/wbs.selector'

import PopupMenu from 'Components/Shared/Filters/PopupMenu'

import ConstructionList from 'Components/Shared/Filters/ConstructionTypeContent'
import FloorContent from 'Components/Shared/Filters/FloorContent'
import ZoneContent from 'Components/Shared/Filters/ZoneContent'
import UnitContent from 'Components/Shared/Filters/UnitContent'
import DateDurationContent from 'Components/Shared/Filters/DateDurationContent'
import WbsFilter from 'Components/Filter/Components/WbsFilter'
import { useLocation } from 'react-router'
import queryString from 'query-string'

type Props = {
  onSearch?: () => void
  withPadding?: boolean
  isAdmin?: boolean
} & ReduxProps &
  DispatchProps

/* 
    const search = props.history.location.search

    let activityFilter = filter

    if (search) {
      const param = queryString.parse(search)
      activityFilter = JSON.parse(param.search!.toString())
    }
  */

const ActivityFilterPage: React.FunctionComponent<Props> = props => {
  const {
    ProjectCode,
    UserEmail,
    token,
    constructTypes,
    floorList,
    zoneList,
    unitList,
    wbsList,
    filter,
    onSearch = () => {},
    withPadding = false,
    isAdmin = false,
  } = props

  const [search, setSearch] = useQueryParam('search', JsonParam)
  const searchParam = useLocation()

  useEffect(() => {
    dispatch(clearFilter())
    onGetConstructType()
    onGetFloorList()
    onGetZoneList()
    onGetUnitList()

    onBindFilterFromParams()
  }, [ProjectCode, UserEmail, searchParam.search])

  const onBindFilterFromParams = () => {
    if (searchParam.search) {
      const param = queryString.parse(searchParam.search)
      const activityFilter: ActivityFilter = JSON.parse(
        param.search!.toString()
      )
      console.log('activityFilter', activityFilter)
      setSearch(activityFilter)
      dispatch(updateActivityFilter(filter))
      onFilterChange(activityFilter)
      onSearch()
    }
  }

  const onGetConstructType = () => {
    const req: GetConstructTypeReq = {
      ProjectCode,
      UserEmail,
    }

    dispatch(getConstructType(token, req))
  }

  const onGetFloorList = () => {
    const req: GetFloorListReq = {
      ProjectCode,
      UserEmail,
    }
    dispatch(getFloorList(token, req))
  }

  const onGetZoneList = () => {
    const req: GetZoneListReq = {
      ProjectCode,
      UserEmail,
    }
    dispatch(getZoneList(token, req))
  }

  const onGetUnitList = () => {
    const req: GetUnitListReq = {
      ProjectCode,
      UserEmail,
      Zone: 'Resident Area',
    }
    dispatch(getUnitList(token, req))
  }

  const dispatch = useDispatch()
  const onFilterChange = (filter: ActivityFilter) => {
    dispatch(updateActivityFilter(filter))
  }

  const onClick = () => {
    filter.LeafWbsList = getLeafWbsList(wbsList, filter.WbsList)
    dispatch(updateActivityFilter(filter))
    setSearch(filter)
    onSearch()
  }

  return (
    <MainPage className={withPadding ? 'with-padding' : ''}>
      <div className="filters">
        <Col span={24} className="filters-button">
          {!isAdmin && (
            <WbsFilter
              filter={filter}
              wbsList={wbsList}
              onFilterChange={onFilterChange}
            />
          )}
          <PopupMenu
            className="popup-menu"
            label={'ประเภทก่อสร้าง '}
            content={
              <ConstructionList
                constructList={constructTypes.List}
                filter={filter.ConstructionTypeList}
                onChange={data => {
                  filter.ConstructionTypeList = data
                  onFilterChange(filter)
                }}
              />
            }
            isActive={filter.ConstructionTypeList.length > 0}
          />
          <PopupMenu
            label={'ชั้น'}
            content={
              <FloorContent
                floors={floorList.List}
                filter={filter.FloorList}
                onChange={data => {
                  filter.FloorList = data
                  onFilterChange(filter)
                }}
              />
            }
            isActive={filter.FloorList.length > 0}
          />

          <PopupMenu
            label={'โซน'}
            content={
              <ZoneContent
                zones={zoneList.List}
                filter={filter.ZoneList}
                onChange={data => {
                  filter.ZoneList = data
                  onFilterChange(filter)
                }}
              />
            }
            isActive={filter.ZoneList.length > 0}
          />

          <PopupMenu
            label={'ยูนิต'}
            content={
              <UnitContent
                units={unitList.List}
                filter={filter.UnitList}
                onChange={data => {
                  filter.UnitList = data
                  onFilterChange(filter)
                }}
              />
            }
            isActive={filter.UnitList.length > 0}
          />
          <PopupMenu
            label={'ช่วงเวลา'}
            content={
              <DateDurationContent
                filter={filter.Date}
                onChange={data => {
                  filter.Date = data
                  onFilterChange(filter)
                }}
              />
            }
            isActive={filter.Date.Mode !== ''}
          />
          <Button type="primary" onClick={onClick}>
            กรองข้อมูล
          </Button>
        </Col>
      </div>
    </MainPage>
  )
}

const MainPage = styled.div`
  &.with-padding {
    padding: 0 120px;
  }

  background-color: #f5f6fa;
`

const mapStateToProps = (state: RootState) => {
  return {
    ProjectCode: selectedProjectCodeSelector(state),
    UserEmail: userEmailSelector(state),
    token: serviceTokenSelector(state),
    filter: selectActivityFilter(state),
    constructTypes: selectConstructType(state),
    floorList: selectFloorList(state),
    zoneList: selectZoneList(state),
    unitList: selectUnitList(state),
    wbsList: getWbsListSelector(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(ActivityFilterPage)
