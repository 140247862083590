import { createStandardAction, createAsyncAction } from 'typesafe-actions'
import {
  GetActivityRequest,
  GetActivityApiResponse,
  MainconFilter,
  MainconQuery,
} from './types'

export const getActivityAction = createAsyncAction(
  'maincon/dashboard/FETCH_ACTIVITY_REQUEST',
  'maincon/dashboard/FETCH_ACTIVITY_SUCCESS',
  'maincon/dashboard/FETCH_ACTIVITY_FAILURE'
)<GetActivityRequest, GetActivityApiResponse, any>()

export const sortingDashboard = createStandardAction(
  'maincon/dashboard/SORTING'
)<string>()

export const filterDashboard = createStandardAction(
  'maincon/dashboard/FILTER_DASHBOARD'
)<void>()

export const setFilterDashboard = createStandardAction(
  'maincon/dashboard/CONFIG_FILTER_DASHBOARD'
)<MainconFilter>()

export const setQueryDashboard = createStandardAction(
  'maincon/dashboard/CONFIG_QUERY_DASHBOARD'
)<MainconQuery>()
