import React, { useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import styled from 'styled-components'
import { PrimaryButton, SecondaryButton } from 'Components/Shared/Button'
import { Row, Col } from 'antd'
import RootState from 'typings/RootState'
import {
  userEmailSelector,
  serviceTokenSelector,
} from 'features/sensitive/sensitive.selector'
import EventModal from 'Components/Shared/Modal/EventModal'
import { selectEvent } from 'features/report/common/selector'
import { enableFunction } from 'features/report/common/api'
import { EnableFunctionReq } from 'features/report/common/types'
import { SwitchFunction } from 'Components/Defect'

type Props = {
  project: string
  RequestID: number
  report: any
  onSendReqToMC: () => void
  onSendReqToQC: () => void
  onCompleted: () => void
  readonly?: boolean
} & ReduxProps

const CmInproFooter: React.ComponentType<Props> = props => {
  const [enableModal, setEnableModal] = useState(false)
  const dispatch = useDispatch()

  const {
    project,
    RequestID,
    report,

    onSendReqToMC,
    onSendReqToQC,
    onCompleted,

    event,
    token,
    UserEmail,
    readonly = false,
  } = props

  const { Unit, QCTypeID, CheckNumber, FunctionStatus = 'N' } = report

  const onFunction = () => {
    setEnableModal(true)
    const req: EnableFunctionReq = {
      ProjectCode: project,
      UserEmail,
      Unit,
      QCTypeID,
      RequestID,
      CheckNumber,
      FunctionStatus: FunctionStatus === 'N' ? 'Y' : 'N',
    }
    dispatch(enableFunction(token, req))
  }

  const onFunctionCompleted = () => {
    setEnableModal(false)
    onCompleted()
  }

  return (
    <Footer>
      <EventModal
        event={[event]}
        enable={enableModal}
        successCalback={onFunctionCompleted}
        errorCalback={() => setEnableModal(false)}
      />
      <Row type="flex" justify="space-between" align="middle">
        <Col>
          <SwitchFunction
            onFunction={onFunction}
            readonly={readonly}
            RequestID={RequestID}
            projectCode={project}
            report={report}
          />
        </Col>
        {!readonly && (
          <Col>
            <Button>
              <SecondaryButton
                text="ส่งรายงานหาผู้รับเหมา"
                onClick={onSendReqToMC}
              />
            </Button>
            <Button>
              <PrimaryButton text="ส่งรายงานหา QC" onClick={onSendReqToQC} />
            </Button>
          </Col>
        )}
      </Row>
    </Footer>
  )
}

const Footer = styled.div`
  position: fixed;
  bottom: 0px;
  height: auto;
  width: 100%;
  background-color: white;
  padding: 22px 80px;
  z-index: 20;
`

const Button = styled.div`
  width: 200px;
  margin-left: 16px;
  float: left;
`

const mapStateToProps = (state: RootState) => {
  return {
    event: selectEvent(state),
    token: serviceTokenSelector(state),
    UserEmail: userEmailSelector(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CmInproFooter)
