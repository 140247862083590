import React, { useState, useEffect } from 'react'
import RootState from 'typings/RootState'
import { BrowserRouter, Route } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import { Layout } from 'antd'

import Login from './Pages/Login'
import Auth from './Pages/Auth'
import '../css/app.less'
import ModulePage from './Pages/ModulePage'
import NotSupportPage from './Pages/NotSupportPage'

import history from 'utils/history'
import projectHelper from 'utils/projectHelper'

import { updateUserRule } from 'features/sensitive/sensitive.actions'

import MasterApp from './MasterApp'
import MainconApp from './MainconApp'
import AdminApp from './AdminApp'
import AdminQCApp from './AdminQCApp'
import CmApp from './CmApp'
import QcApp from './QcApp'
import ApprovalApp from './ApprovalApp'
import AdminPJApp from './AdminPJApp'
import PmrApp from './PmrApp'
import AdminReportApp from './AdminReportApp'

import { submitServiceTokenRequest } from '../features/sensitive/sensitive.api'
import {
  serviceTokenSelector,
  getUserProfile,
} from '../features/sensitive/sensitive.selector'

import account from 'utils/account'
import { isMobileTablet, isMobile } from 'utils/verify'

type AppProps = {}

type Props = ReduxProps & DispatchProps & AppProps

const App: React.FunctionComponent<Props> = ({ serviceToken, userProfile }) => {
  const [init, setInit] = useState({} as any)
  const dispatch = useDispatch()
  const roleName = userProfile.RoleName

  const isActiveModule = (moduleID: number) => {
    const data = userProfile.Roles.find(role => role.ModuleID === moduleID)
    return data ? true : false
  }

  const needAuthen: Boolean = serviceToken === '' || serviceToken === undefined
  useEffect(() => {
    if (needAuthen === false) {
      const id: number = 10
      if (init.module === 'report' && isActiveModule(id)) {
        const name: string = 'Report'

        dispatch(updateUserRule({ Name: name, Id: id }))
        projectHelper.saveModule(name)
        projectHelper.saveModuleID(id)

        history.push(init.pathname)
      }

      setInit({})
    }
  }, [needAuthen])

  useEffect(() => {
    const pathname: string = window.location.pathname
    const params = new URLSearchParams(window.location.search)
    const mod = params.get('module')

    if (mod) {
      setInit({
        pathname,
        module: mod,
      })
    } else {
      setInit({})
    }

    const { email, token } = account.getAccessToken()
    if (token && email) {
      dispatch(submitServiceTokenRequest({ email, token }))
    }
  }, [dispatch])

  const invalidDevice = isMobile() || isMobileTablet()
  if (invalidDevice) {
    return (
      <BrowserRouter>
        <Layout className="layout" hasSider={false}>
          <Route path="/*" component={NotSupportPage} />
        </Layout>
      </BrowserRouter>
    )
  }

  if (needAuthen) {
    return (
      <BrowserRouter>
        <Layout className="layout" hasSider={false}>
          <Route path="/*" component={Login} />
        </Layout>
      </BrowserRouter>
    )
  } else if (userProfile.Roles.length === 0) {
    return (
      <BrowserRouter>
        <Layout className="layout" hasSider={false}>
          <Route path="/*" component={Auth} />
        </Layout>
      </BrowserRouter>
    )
  }

  switch (roleName) {
    case 'Master Plan':
      return <MasterApp />
    case 'Main Con':
      return <MainconApp />
    case 'Administrator':
      return <AdminApp />
    case 'Admin QC':
      return <AdminQCApp />
    case 'CM/PJ':
      return <CmApp />
    case 'QC':
      return <QcApp />
    case 'Approval':
      return <ApprovalApp />
    case 'Admin PJ':
      return <AdminPJApp />
    case 'PMR':
      return <PmrApp />
    case 'Report':
      return <AdminReportApp />
    default:
      return (
        <BrowserRouter>
          <Layout className="layout" hasSider={false}>
            <Route path="/*" component={ModulePage} />
          </Layout>
        </BrowserRouter>
      )
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    serviceToken: serviceTokenSelector(state),
    userProfile: getUserProfile(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(App)
