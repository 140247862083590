import { sortingList } from '../../../utils/helper'
import { Activity } from '../dashboard/types'

export const sortingActivityList = (
  list: Activity[],
  type: string,
  name: string | undefined
): Activity[] => {
  try {
    let newList: Activity[] = list
    switch (name) {
      case 'ActivityName':
        newList = sortingList(list, type, name, 'string')
        break
      case 'StartDate':
        newList = sortingList(list, type, name, 'date')
        break
      case 'FinishDate':
        newList = sortingList(list, type, name, 'date')
        break
      case 'countdown-todo':
        newList = sortingList(list, type, 'StartDate', 'date')
        break
      case 'countdown-inprogress':
        newList = sortingList(list, type, 'FinishDate', 'date')
        break
      case 'Progress':
        newList = sortingList(list, type, name, 'number')
        break
      case 'Unit':
        newList = sortingList(list, type, name, 'string')
        break
      case 'ConstructionTypeName':
        newList = sortingList(list, type, name, 'string')
        break

      default:
    }

    return newList
  } catch (e) {
    return list
  }
}
