import moment from 'moment'
import { Task } from '../tasklane/tasklane.types'
import { TaskById, ActivityFilter } from './activity.types'
import { DATE_FORMAT } from '../../config'

export const filterActivity = (
  list: Task[],
  filter: ActivityFilter
): Task[] => {
  let filterProgress = filter.progress.length > 0
  let filterStatus = filter.status.length > 0
  let filterWbs = filter.wbs.length > 0
  let result = list.filter(item => {
    if (filterProgress) {
      let progress = filter.progress.findIndex(it => {
        return item.Progress >= it.start && item.Progress <= it.end
      })
      if (progress < 0) return false
    }

    if (filterStatus) {
      let status = filter.status.includes(item.TaskStatus)
      if (status === false) return false
    }

    if (filterWbs) {
      let wbs = filter.wbs.includes(item.WbsID)
      if (wbs === false) return false
    }

    return true
  })

  return result
}

const sortingByString = (
  list: Task[],
  type: string,
  name: keyof Task
): Task[] => {
  const options = {
    sensitivity: 'base',
  }
  if (type === 'asc') {
    list.sort((a, b) => {
      return `${a[name]}`.localeCompare(`${b[name]}`, 'en', options)
    })
  } else {
    list.sort((a, b) => {
      return `${b[name]}`.localeCompare(`${a[name]}`, 'en', options)
    })
  }

  return list
}

const sortingByStatus = (list: Task[], type: string): Task[] => {
  const status = {
    INIT: 1,
    PEND: 2,
    APPV: 3,
    REJT: 4,
  }

  if (type === 'asc') {
    list.sort((a, b) => {
      return Number(status[b.TaskStatus]) - Number(status[a.TaskStatus])
    })
  } else {
    list.sort((a, b) => {
      return Number(status[a.TaskStatus]) - Number(status[b.TaskStatus])
    })
  }

  return list
}

const sortingByNumber = (list: any[], type: string, name: keyof any): any[] => {
  if (type === 'asc') {
    list.sort((a, b) => {
      return Number(a[name]) - Number(b[name])
    })
  } else {
    list.sort((a, b) => {
      return Number(b[name]) - Number(a[name])
    })
  }

  return list
}

const sortingByDate = (list: any[], type: string, name: string): any[] => {
  if (type === 'asc') {
    list.sort((a, b) => {
      const unixA = moment(a[name], DATE_FORMAT).unix()
      const unixB = moment(b[name], DATE_FORMAT).unix()

      return unixA - unixB
    })
  } else {
    list.sort((a, b) => {
      const unixA = moment(a[name], DATE_FORMAT).unix()
      const unixB = moment(b[name], DATE_FORMAT).unix()

      return unixB - unixA
    })
  }

  return list
}

const sortingByCountDate = (list: any[], type: string, name: string): any[] => {
  const today = moment()
  if (type === 'asc') {
    list.sort((a, b) => {
      const unixA = moment(a[name], DATE_FORMAT).diff(today, 'days')
      const unixB = moment(b[name], DATE_FORMAT).diff(today, 'days')

      return unixA - unixB
    })
  } else {
    list.sort((a, b) => {
      const unixA = moment(a[name], DATE_FORMAT).diff(today, 'days')
      const unixB = moment(b[name], DATE_FORMAT).diff(today, 'days')

      return unixB - unixA
    })
  }

  return list
}

export const convertDataToList = (data: TaskById): Task[] => {
  const list: Task[] = []
  const keys = Object.keys(data)
  for (let key of keys) {
    list.push(data[key])
  }
  return list
}

export const sortingTaskList = (
  list: Task[],
  type: string,
  name: string | undefined
): Task[] => {
  try {
    let newList: Task[] = list
    switch (name) {
      case 'activity_title':
        newList = sortingByString(list, type, 'ActivityName')
        break
      case 'wbs':
        newList = sortingByString(list, type, 'WbsName')
        break
      case 'actual_start_date':
        newList = sortingByDate(list, type, 'StartDate')
        break
      case 'actual_end_date':
        newList = sortingByDate(list, type, 'FinishDate')
        break
      case 'countdown-todo':
        newList = sortingByCountDate(list, type, 'StartDate')
        break
      case 'countdown':
        newList = sortingByCountDate(list, type, 'FinishDate')
        break
      case 'progress':
        newList = sortingByNumber(list, type, 'Progress')
        break
      case 'status':
        newList = sortingByStatus(list, type)
        break
      default:
    }

    return newList
  } catch (e) {
    return list
  }
}
