import React from 'react'
import { Spin, Icon, Button } from 'antd'

interface Props {
  visible: boolean
  error?: string
  children: any
  onBack: any
}

const PageHandle = (props: Props) => {
  if (props.visible) {
    return (
      <div style={css.page}>
        <Spin
          indicator={
            <Icon
              type="loading"
              style={{ color: '#003168', fontSize: 40 }}
              spin
            />
          }
        />
        <h3 style={{ marginTop: '20px' }}>Please wait a moment..</h3>
      </div>
    )
  } else if (props.error) {
    let back
    if (props.onBack) {
      back = (
        <div style={{ width: '100%' }}>
          <Button style={css.btn} size="large" onClick={props.onBack}>
            BACK
          </Button>
        </div>
      )
    }
    return (
      <div style={css.page}>
        <h3 style={{ marginTop: '20px' }}>Sorry We got some problem.</h3>
        <p style={css.error}>{props.error}</p>
        {back}
      </div>
    )
  }

  return props.children
}

const css: any = {
  page: {
    width: '60%',
    margin: '0 auto',
    textAlign: 'center',
    padding: '20px',
    borderRadius: '10px',
    backgroundColor: 'white',
  },
  error: {
    color: '#881202',
    marginTop: '10px',
  },
  btn: {
    width: '140px',
    fontSize: '18px',
    borderRadius: '5px',
  },
}
export default PageHandle
