import withAPIAction from 'utils/withAPIAction'
import {
  getDataListAction,
  updateDataAction,
  getMainConListAction,
  getRequestHistoryAction,
} from './actions'

import {
  GetDataListReq,
  UpdateDataReq,
  GetMainConListReq,
  GetRequestHistoryReq,
} from './types'

import { verifyReqNoProject, verifyReq } from 'utils/verify'

export const getDataList = (token: string, data: GetDataListReq) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<GetDataListReq>({
    method: 'POST',
    path: 'GetUnitListByAdmin',
    data,
    token: `Bearer ${token}`,
  })(getDataListAction)
}

export const getMainconList = (token: string, data: GetMainConListReq) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<GetMainConListReq>({
    method: 'POST',
    path: 'GetMainconListForUnit',
    data,
    token: `Bearer ${token}`,
  })(getMainConListAction)
}

export const updateData = (token: string, data: UpdateDataReq) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<UpdateDataReq>({
    method: 'POST',
    path: 'UpdateUnitByAdmin',
    data,
    token: `Bearer ${token}`,
  })(updateDataAction)
}

export const getRequestHistory = (data?: GetRequestHistoryReq) => {
  return withAPIAction(state => ({
    method: 'POST',
    path: 'GetRequestHistory',
    data: {
      ...data,
      UserEmail: state.sensitive.userEmail,
    },
    token: `Bearer ${state.sensitive.apiToken}`,
  }))(getRequestHistoryAction)
}
