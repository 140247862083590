import ThumbnailDisplay from 'Components/Shared/ThumbnailDisplay'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import empty from 'assets/img/ic-photo.png'
import { Row, Col } from 'antd'
import Resizer from 'react-image-file-resizer'

type Props = {
  image: any
  remark?: string
  title?: string
  showRemark?: boolean
  hasMargin?: boolean
  canShowThump?: boolean
}

const ImageReport: React.ComponentType<Props> = props => {
  const {
    image,
    remark = '',
    title = '',
    showRemark = false,
    hasMargin = true,
    canShowThump = true,
  } = props

  const [showThump, setShowThump] = useState(false)
  const [imageResized, setImageResized] = useState('')

  const mr = hasMargin ? 'margin' : ''
  const pd = showRemark ? 'padding' : ''
  const imageUrl = image.FileURL || ''

  useEffect(() => {
    resizerImage()
  }, [imageResized])

  const openThumpImage = () => {
    if (!canShowThump) return
    setShowThump(true)
  }

  const closeThumpImage = () => {
    setShowThump(false)
  }

  const resizerImage = async () => {
    const originalImage = await fetch(imageUrl)
    const imageBlob = await originalImage.blob()

    try {
      Resizer.imageFileResizer(
        imageBlob,
        323,
        241,
        'WEBP',
        75,
        0,
        uri => {
          setImageResized(uri.toString())
        },
        'base64',
        323,
        241
      )
    } catch (e) {}
  }

  const emptyBox = (
    <EmptyBox>
      <Row>
        <Col>
          <EmptyIcon>
            <img src={empty} />
          </EmptyIcon>
        </Col>
        <Col>
          <EmptyText>ไม่มีรูปภาพ</EmptyText>
        </Col>
      </Row>
    </EmptyBox>
  )

  const loadingImage = (
    <EmptyBox>
      <Row>
        <Col>
          <EmptyIcon>
            <img src={empty} />
          </EmptyIcon>
        </Col>
        <Col>
          <EmptyText>กำลังโหลดรูปภาพ โปรดรอสักครู่</EmptyText>
        </Col>
      </Row>
    </EmptyBox>
  )

  return (
    <ImgCardBox className={`${mr} ${pd}`}>
      {imageResized && imageResized !== '' ? (
        <ImgCard src={imageResized} onClick={openThumpImage} />
      ) : imageUrl ? (
        loadingImage
      ) : (
        emptyBox
      )}
      {showRemark && (
        <ImgCaption>
          {title} {remark}
        </ImgCaption>
      )}
      {showThump && (
        <ThumbnailDisplay
          onClose={closeThumpImage}
          readOnly={true}
          images={[image]}
          index={0}
          onChange={() => {}}
        />
      )}
    </ImgCardBox>
  )
}

const ImgCardBox = styled.div`
  height: auto;
  background-color: #fff;
  border: 1px solid #e4e7eb;
  border-radius: 4px;
  cursor: pointer;
  @media print {
    margin: 6px;
    max-width: 230px;
  }
  &.margin {
    margin: 6px;
  }

  &.padding {
    padding: 12px;
  }
`

const ImgCard = styled.img`
  width: 100%;
  height: 241px;
  object-fit: cover;
  @media print {
    max-width: 230px;
    max-height: 150px;
  }
`

const EmptyBox = styled.div`
  height: 241px;
  -webkit-box-pack: center;
  justify-content: center;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  text-align: center;
  @media print {
    max-width: 230px;
    max-height: 150px;
  }
`

const ImgCaption = styled.div`
  font-family: 'DB Helvethaica X 55 Regular';
  font-size: 18px;
  padding: 8px 0;
  display: block;
  display: -webkit-box;
  max-width: 100%;
  height: 45px;
  margin: 0 auto;
  line-height: 1;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`

const EmptyIcon = styled.div`
  img {
    width: 50px;
  }
`

const EmptyText = styled.div`
  font-family: 'DB Helvethaica X 55 Regular';
  font-size: 20px;
  color: #000;
  padding-top: 10px;
`

export default ImageReport
