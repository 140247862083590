import React from 'react'
import styled from 'styled-components'

const ImportBtn = (props: any) => {
  const {
    name = 'import file',
    text = 'Import',
    disabled = false,
    type = '',
  } = props

  const onChange = async (evt: any) => {
    let files = evt.target.files
    if (files.length === 0) return
    let file = files[0]

    const reader = new FileReader()

    if (type === '.xlsx') {
      reader.readAsBinaryString(file)
    } else {
      reader.readAsText(file)
    }

    reader.addEventListener('load', () => {
      if (props.onChange) {
        props.onChange(reader.result, file, props.name)
      }
    })
  }

  const onClick = () => {
    const input: any = document.getElementById(name)
    if (input) input.value = null
  }

  return (
    <Section className={disabled ? undefined : 'pointer'}>
      <input
        id={name}
        type="file"
        accept={type === '' ? '.csv' : type}
        style={{ display: 'none' }}
        onChange={onChange}
        onClick={onClick}
      />

      <label htmlFor={disabled || name}>
        <PickerBody className={disabled ? 'disabled' : undefined}>
          {text}
        </PickerBody>
      </label>
    </Section>
  )
}

const Section = styled.div`
  color: #8b99a7;
  :hover: {
    cursor: pointer;
  }
`

const PickerBody = styled.div`
  font-size: 20px;
  border-radius: 5px;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.03);
  color: #ffffff;
  background-color: #003168;
  font-family: 'DB Helvethaica X 75 Bd';
  width: 177px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.disabled {
    background-color: rgba(0, 49, 104, 0.2);
  }
`

export default ImportBtn
