import React from 'react'
import styled from 'styled-components'

interface Props {
  name: string
}

const CheckListTypeTag: React.FC<Props> = props => {
  const { name } = props
  const isArchitectural = name === 'Commissioning'
  return <TagName className={isArchitectural ? 'blue' : ''}>{name}</TagName>
}

const TagName = styled.span`
  font-size: 16px;
  font-family: 'DB Helvethaica X 55 Regular';
  color: white;
  height: 22px;
  background-color: #cc886f;
  border-radius: 10px;
  padding: 0 12px;
  margin-top: 16px;
  &.blue {
    background-color: #79cadc;
  }
`
export default CheckListTypeTag
