import { getType } from 'typesafe-actions'
import * as actions from './actions'
import { StoreState, SeemoreAction, Data } from './types'
import produce from 'immer'
import parseErrorMessage from 'utils/parseErrorMessage'
import { filterActivity } from 'utils/helper'
import { sortingActivityList } from './function'
import { cloneDeep } from 'lodash'

const initData = {
  Total: 0,
  ProjectCode: '',
  ProjectNameEN: '',
  ProjectNameTH: '',
  List: [],
}

const initialState: StoreState = {
  data: cloneDeep(initData),
  event: {
    IsLoading: false,
    MessageSuccess: '',
    SubmitError: '',
    SubmitSuccess: false,
    SubmitType: '',
  },
  sorting: {
    name: 'RequestDate',
    type: 'asc',
  },
  query: {
    date: {
      start: undefined,
      end: undefined,
    },
  },
  filter: {
    progress: [],
    status: [],
    wbs: [],
    date: {
      mode: '',
      start: undefined,
      end: undefined,
    },
    floor: [],
    unit: [],
    zone: [],
    type: [],
  },
}

export default function(
  state: StoreState = initialState,
  action: SeemoreAction
): StoreState {
  return produce(state, draft => {
    const filter = state.filter
    switch (action.type) {
      case getType(actions.GetCheckingZoneAction.request):
        draft.event.SubmitError = undefined
        draft.event.IsLoading = true
        draft.event.SubmitSuccess = false
        draft.data = cloneDeep(initData)
        break
      case getType(actions.GetCheckingZoneAction.success):
        const res = action.payload.ResponseData
        res.List = res.List || []
        draft.event.IsLoading = false
        const dataList = filterActivity(res.List, filter)
        const dataSort = sortingActivityList(
          dataList,
          draft.sorting.type,
          draft.sorting.name
        )
        draft.data = {
          Total: res.Total,
          ProjectCode: res.ProjectCode,
          ProjectNameEN: res.ProjectNameEN,
          ProjectNameTH: res.ProjectNameTH,
          List: dataSort,
        }
        break

      case getType(actions.GetCheckingZoneAction.failure):
        draft.event.IsLoading = false
        draft.event.SubmitError = parseErrorMessage(action.payload)
        break

      case getType(actions.sortingTask):
        const sorting = state.sorting
        const params = action.payload
        if (sorting.name === params.name) {
          draft.sorting.type = sorting.type === 'asc' ? 'desc' : 'asc'
        } else {
          draft.sorting.name = params.name
          draft.sorting.type = 'asc'
        }
        if (params.sort != null) {
          draft.sorting.type = params.sort
        }
        const list = sortingActivityList(
          draft.data.List || [],
          draft.sorting.type,
          draft.sorting.name
        )
        draft.data.List = list
        break

      case getType(actions.AcceptTaskAction.request):
      case getType(actions.RejectTaskAction.request):
        draft.event.SubmitError = undefined
        draft.event.IsLoading = true
        draft.event.SubmitSuccess = false
        break
      case getType(actions.AcceptTaskAction.success):
      case getType(actions.RejectTaskAction.success):
        draft.event.IsLoading = false
        draft.event.SubmitError = undefined
        draft.event.SubmitSuccess = true
        draft.event.MessageSuccess = 'ทำรายการสำเร็จ'
        break
      case getType(actions.AcceptTaskAction.failure):
      case getType(actions.RejectTaskAction.failure):
        draft.event.IsLoading = false
        draft.event.SubmitError = parseErrorMessage(action.payload)
        break

      default:
        return state
    }
  })
}
