import React, { useState, useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux'
import RootState from 'typings/RootState'
import styled from 'styled-components'
import { Layout, Drawer } from 'antd'

import { BreadcrumbLink } from 'Components/Shared/BreadCrumb/BreadCrumb'
import SubnavBar from 'Components/Shared/SubnavBar'

import { fetchProjects } from 'features/project/project.api'
import { updateDocument } from 'features/document/master/api'
import {
  getMasterDocumentCheckList,
  getActivityByDocumentCheckList,
} from 'features/document/local/api'
import {
  userEmailSelector,
  serviceTokenSelector,
} from 'features/sensitive/sensitive.selector'
import { QCType, Document, Activity } from 'features/document/local/types'
import { UpdateCheckListReq } from 'features/document/master/types'

import Menu from '../../../MenuAdmin'
import { AdminProject, EventModal } from 'Components/Shared'
import { projectsByIdSelector } from 'features/project/project.selector'
import { DropdownList } from 'Components/Shared/Form'
import QCCheckDocumentDetail from '../../../Admin/MasterDocument/Detail'
import SearchSelection from 'Components/Inspection/Activity/ActivityList/SearchSelection'
import TreeCollapseDocument from 'Components/Shared/Input/TreeCollapseDocument'
import { selectEvent } from 'features/document/master/selector'
import {
  clearDocument,
  getActivityDocAfterUpdate,
} from 'features/document/local/actions'

const breadcrumbs: BreadcrumbLink[] = [
  {
    url: '/dashboard',
    title: 'Home',
    icon: '/home.png',
    active: true,
    showTitle: false,
  },
  {
    url: '/check-document',
    title: 'ตรวจเอกสาร',
    active: false,
    showTitle: false,
  },
]

const { Content } = Layout

type FormProps = {
  id: string
}
type Props = FormProps & RouteComponentProps<{ id: string }> & ReduxProps

const MainconChecklistDetail: React.ComponentType<Props> = ({
  UserEmail,
  event,
  Token,
  QcTypeList,
  projectsById,
  TowerList,
  masterEvent,
}) => {
  const [search, setSearch] = useState('')
  const [selectedItem, setSelectedItem] = useState<Activity | undefined>()
  const [enableModal, setEnableModal] = useState(false)
  const [selectProjectCode, setSelectProjectCode] = useState('')

  const dispatch = useDispatch()

  useEffect(() => {
    setEnableModal(true)
    dispatch(fetchProjects({ email: UserEmail, token: Token }))
    dispatch(getMasterDocumentCheckList(true))
    setEnableModal(false)
    return () => {
      dispatch(clearDocument())
    }
  }, [])

  useEffect(() => {
    if (selectProjectCode) {
      dispatch(getActivityByDocumentCheckList(selectProjectCode, 'unit'))
    }
  }, [selectProjectCode])

  const onSave = (act: Activity) => {
    if (act.IsRequireQC === false) {
      act.CheckList = act.CheckList.filter(c => c.Name !== '' && c.Score !== 0)
      if (act.Score === 0) {
        act.Score = 1
      }
    }
    act.Score = +act.Score
    act.CheckList.forEach(c => {
      c.Score = +c.Score
    })

    setEnableModal(true)
    const req: UpdateCheckListReq = {
      UserEmail,
      CheckListID: act.CheckListID,
      IsMaster: 'N',
      ProjectCode: selectProjectCode,
      CheckListName: act.CheckListName,
      QCTypeID: act.QCTypeID,
      Score: act.Score,
      IsRequireQC: act.IsRequireQC,
      CheckList: act.CheckList,
    }

    dispatch(
      getActivityDocAfterUpdate({
        projectCode: selectProjectCode,
        unitType: 'unit',
      })
    )
    dispatch(updateDocument(Token, req))
  }

  const onOpenDrawer = (item: Activity) => {
    setSelectedItem({ ...item })
  }

  const onCloseDrawer = () => {
    setSelectedItem(undefined)
  }

  const onChangeProject = (code: string) => {
    setSelectProjectCode(code)
  }

  const closeEnableModal = () => setEnableModal(false)

  const onSearch = (txt: string) => {
    setSearch(txt)
  }

  return (
    <>
      <EventModal
        event={[event, masterEvent]}
        enable={enableModal}
        successCalback={closeEnableModal}
        errorCalback={closeEnableModal}
      />
      <SubnavBar breadcrumbs={breadcrumbs} hideSearch={true} />
      <Content className="mid-content">
        <Menu selected="check-document">
          <MainPage>
            <Header>
              <div>
                <Title>แก้ไข Document checklist</Title>
                <SubTitle>
                  กรุณาตรวจสอบคะแนนหักในแต่ละยูนิตให้ถูกต้อง หรือปรึกษา Admin PJ
                  ในการปรับการผูก Primavera ให้ถูกต้อง
                </SubTitle>
              </div>
              <AdminProject
                projectList={projectsById}
                onSelectProject={onChangeProject}
                activeProject={selectProjectCode}
              />
            </Header>
            <LeftSection>
              <SearchSelection onChange={onSearch} />
              <TreeCollapseDocument
                towerList={TowerList}
                onSelect={onOpenDrawer}
                search={search}
              />
            </LeftSection>

            <RightSection>
              <RightStyle>
                <Title>Master Template ตรวจเอกสาร</Title>
                {QcTypeList.map((qc: QCType) => {
                  return (
                    <DocSection key={qc.QCTypeID}>
                      <div className="qcName">{qc.QCTypeID}</div>
                      {qc.DocumentList.map((doc: Document, index: number) => {
                        return (
                          <div key={index} className="doc">
                            <div className="docName">{doc.Name}</div>
                            <div className="docScore">{doc.Score} คะแนน</div>
                          </div>
                        )
                      })}
                    </DocSection>
                  )
                })}
              </RightStyle>
            </RightSection>
          </MainPage>
        </Menu>
      </Content>

      <Drawer
        title={null}
        className="drawer-project"
        closable={false}
        width={531}
        visible={selectedItem != undefined}
      >
        <QCCheckDocumentDetail
          item={selectedItem}
          QcTypeList={QcTypeList}
          onSave={onSave}
          onClose={onCloseDrawer}
          readOnly={enableModal}
          fromLocal={true}
        />
      </Drawer>
    </>
  )
}

const MainPage = styled.div`
  background-color: #f5f6fa;
  width: 100%;
  height: 100%;
  -khtml-user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  overflow-x: scroll;

  padding: 40px;
`

const DocSection = styled.div`
  font-size: 20px;
  font-family: 'DB Helvethaica X 75 Bd';
  .qcName {
    font-size: 24px;
  }
  .doc {
    display: flex;
    justify-content: space-between;
    font-family: 'DB Helvethaica X 55 Regular';

    .docName {
      font-size: 20px;
    }
    .docScore {
      color: #8b99a7;
    }
  }
`

const Title = styled.div`
  font-size: 28px;
  line-height: 1.07;
  font-family: 'DB Helvethaica X 75 Bd';
`

const SubTitle = styled.div`
  font-size: 18px;
  color: #8b99a7;
  margin-top: 4px;
`

const LeftSection = styled.div`
  width: 50%;
  padding-bottom: 20px;
  float: left;
`

const RightSection = styled.div`
  width: 50%;
  padding-left: 10px;
  float: left;
`

const RightStyle = styled.div`
  background-color: white;
  padding: 24px;
  height: 100%;
  min-height: 600px;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 35px;
  .ant-dropdown-trigger {
    width: 360px;
  }
`

type ReduxProps = ReturnType<typeof mapStateToProps>
const mapStateToProps = (state: RootState) => {
  return {
    UserEmail: userEmailSelector(state),
    Token: serviceTokenSelector(state),
    projectsById: projectsByIdSelector(state),
    masterEvent: selectEvent(state),
    event: state.localDocument.Event,
    QcTypeList: state.localDocument.QCTypeList,
    TowerList: state.localDocument.TowerList,
  }
}

const mapDispatchToProps = {}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MainconChecklistDetail)
