import React from 'react'

import { BreadcrumbLink } from 'Components/Shared/BreadCrumb/BreadCrumb'
import SubnavBar from 'Components/Shared/SubnavBar'

type Props = {
  breadcrumbs?: BreadcrumbLink[]
  activity: any
  name?: string
  onClose?: () => void
}

const Breadcrumbs: React.ComponentType<Props> = props => {
  const { breadcrumbs = [], activity, name } = props

  const list = [...breadcrumbs]
  list.push({
    url: `/activity/${activity.ActivityID}`,
    title: activity.ActivityName || 'activity',
    active: name ? true : false,
    showTitle: name ? true : false,
    onClick: props.onClose,
  })

  if (name) {
    list.push({
      url: `/activity/${activity.ActivityID}`,
      title: name,
      active: false,
      showTitle: false,
    })
  }

  return <SubnavBar breadcrumbs={list} hideSearch={true} />
}

export default Breadcrumbs
