import React, { useState, forwardRef, useImperativeHandle } from 'react'
import styled from 'styled-components'
import { Modal, Col, Row } from 'antd'

import { CloseIcon } from 'Components/Shared/Icon'
import { ImgCheckbox } from 'Components/Shared/Form'

import QCTypeModal from './QCTypeModal'
import JobModal from './JobModal'
import SubJobModal from './SubJobModal'
import DetailJobModal from './DetailJobModal'
import {
  QCType,
  CheckListDetail,
  JobType,
  SubJobType,
  DetailType,
  Function,
} from 'features/checklist/unit/types'
import { SelectType } from '..'

export interface ModalHandles {
  showModal: (
    tab: number,
    obj?: JobType | DetailType,
    index?: number,
    qCTypeID?: string
  ) => void
}

export interface SelectDetailType {
  detail: DetailType
  qCTypeID: string
  jobName: string
  subJobName: string
}

interface Props {
  selected: SelectType
  checkListDetail: CheckListDetail
  isEditMode?: boolean
  qCTypeList: QCType[]
  functionList: Function[]
  addQcType: (item: QCType, index?: number) => void
  addJob: (job: JobType, index?: number) => void
  addSubJob: (subjob: SubJobType, index?: number) => void
  addDetail: (detail: DetailType, index?: number) => void
  disabled: boolean
}

const ModalComponent = forwardRef<ModalHandles, Props>(
  (
    {
      selected,
      checkListDetail,
      isEditMode = false,
      qCTypeList,
      functionList,
      addQcType: addQcTypeProp,
      addJob: addJobProp,
      addSubJob: addSubJobProp,
      addDetail: addDetailProp,
      disabled,
    },
    ref
  ) => {
    const [visible, setvisible] = useState(false)
    const [tab, setTab] = useState(0)
    const [editingObj, setEditingObj] = useState<any | undefined>(undefined)
    const [editingIndex, setEditingIndex] = useState<number | undefined>(
      undefined
    )

    const closeModal = () => {
      setvisible(false)
      setTab(-1)
      setEditingObj(undefined)
      setEditingIndex(undefined)
    }

    useImperativeHandle(ref, () => ({
      showModal: (tab: number, obj?: any, index?: number) => {
        setvisible(true)
        setTab(tab)
        if (obj != null && index != null) {
          setEditingObj(obj)
          setEditingIndex(index)
        }
      },
    }))

    const addQcType = (item: QCType) => {
      addQcTypeProp(item, editingIndex)
      closeModal()
    }
    const addJob = (job: JobType) => {
      addJobProp(job, editingIndex)
      closeModal()
    }
    const addSubJob = (subjob: SubJobType) => {
      addSubJobProp(subjob, editingIndex)
      closeModal()
    }
    const addDetail = (detail: DetailType) => {
      addDetailProp(detail, editingIndex)
      closeModal()
    }

    let content
    switch (tab) {
      case 0:
        content = (
          <QCTypeModal
            editingObj={editingObj}
            checkListDetail={checkListDetail}
            qCTypeList={qCTypeList}
            functionList={functionList}
            closeModal={closeModal}
            onSave={addQcType}
            disabled={disabled}
          />
        )
        break
      case 1:
        content = (
          <JobModal
            editingObj={editingObj}
            selected={selected}
            checkListDetail={checkListDetail}
            closeModal={closeModal}
            onSave={addJob}
            disabled={disabled}
          />
        )
        break
      case 2:
        content = (
          <SubJobModal
            editingObj={editingObj}
            selected={selected}
            checkListDetail={checkListDetail}
            closeModal={closeModal}
            onSave={addSubJob}
            disabled={disabled}
          />
        )
        break
      case 3:
        content = (
          <DetailJobModal
            editingObj={editingObj}
            selected={selected}
            checkListDetail={checkListDetail}
            closeModal={closeModal}
            onSave={addDetail}
            disabled={disabled}
          />
        )
        break
      default:
        break
    }

    const checkTypeOptions = ['ลักษณะงานหลัก', 'ลักษณะงานรอง', 'รายละเอียดงาน']

    const checkboxType = checkTypeOptions.map((item, i) => {
      const index = i + 1
      return (
        <Col span={6} key={index}>
          <ImgCheckbox
            isCheck={index === tab}
            label={item}
            onClick={() => setTab(index)}
          />
        </Col>
      )
    })

    return (
      <ModalCheckList>
        <Modal
          centered
          visible={visible}
          width="528px"
          footer={null}
          closeIcon={<CloseIcon color="#8b99a7" size={16} />}
          title={
            <Header>
              {isEditMode || editingObj ? 'แก้ไขรายการ' : 'เพิ่มรายการ'}
            </Header>
          }
          className="add-checklist-modal"
          onCancel={closeModal}
        >
          <ModalContent>
            {!editingObj && (
              <SelectTypeStyle>
                <Title>เลือกประเภทรายการ</Title>
                <CheckBoxSection>
                  <Row>{checkboxType}</Row>
                </CheckBoxSection>
              </SelectTypeStyle>
            )}
            {content}
          </ModalContent>
        </Modal>
      </ModalCheckList>
    )
  }
)

const Header = styled.span`
  font-size: 28px;
  color: #000000;
  font-family: 'DB Helvethaica X 75 Bd';
`
const Title = styled.span`
  font-size: 20px;
  color: #000000;
  font-family: 'DB Helvethaica X 75 Bd';
`

const ModalCheckList = styled.div`
  .add-checklist-modal {
    .ant-modal-header {
      border-bottom: none;
    }
  }
`

const ModalContent = styled.div`
  padding: 0 24px 31px 24px;
`

const SelectTypeStyle = styled.div`
  padding-top: 17px;
`

const CheckBoxSection = styled.div`
  padding-top: 16px;
`
export default ModalComponent
