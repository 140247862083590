import React, { useEffect } from 'react'
import { Col } from 'antd'
import LinkButton from '../../Shared/LinkButton'
import Loading from '../../Shared/Loading'
import TaskLaneCard from '../../cards/TaskLaneCard'
import { translate } from 'i18n'
import TKeys from 'i18n/translationKey'
import {
  currentBrowsingActivity,
  resetSubmitErrorAction,
} from 'features/activity/activity.actions'
import { connect, useDispatch } from 'react-redux'
import RootState from 'typings/RootState'
import { Task } from 'features/tasklane/tasklane.types'

type TaskLaneColumnProps = {
  key: any
  datasource: Task[]
  isFetching: boolean
  limit: number
  type: 'todo' | 'inProgress' | 'complete'
}

type Props = DispatchProps & ReduxProps & TaskLaneColumnProps

const TaskLaneColumn: React.FunctionComponent<Props> = ({
  datasource,
  type,
  limit,
  isFetching,
  resetSubmitErrorAction,
}) => {
  useEffect(() => {
    resetSubmitErrorAction()
  }, [resetSubmitErrorAction])

  const showSeeMoreButton = datasource.length >= limit
  const noRecord = datasource.length === 0
  const tasklanes = datasource.slice(0, limit)
  const dispatch = useDispatch()
  const showMoreType = type === 'inProgress' ? 'in-progress' : type

  let content
  if (isFetching) {
    content = (
      <div style={{ textAlign: 'center' }}>
        <Loading />
      </div>
    )
  } else if (noRecord) {
    content = (
      <div className="tasklane__norecord">
        {translate(TKeys.Dashboard.tasklane_empty_record)}
      </div>
    )
  } else {
    let list = tasklanes.map((task, index) => {
      return (
        <TaskLaneCard
          key={`${task.ActivityID}-${index}`}
          type={type}
          taskLane={task}
          isFetching={isFetching}
          onClick={() => dispatch(currentBrowsingActivity(task.ActivityID))}
        />
      )
    })

    let seemore
    if (showSeeMoreButton) {
      seemore = (
        <LinkButton
          href={`/see-more/type/${showMoreType}/page/1`}
          title={translate(TKeys.Dashboard.tasklane_seemore)}
          className="btn--seemore"
        />
      )
    }
    content = (
      <>
        {list}
        {seemore}
      </>
    )
  }
  return (
    <Col span={8} className="tasklane">
      {content}
    </Col>
  )
}

const mapStateToProps = (state: RootState) => {
  return {}
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {
  resetSubmitErrorAction,
}

type DispatchProps = typeof mapDispatchToProps

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TaskLaneColumn)
