import {
  ActivityState,
  CurrentActivity,
  Activity,
  ActivityAction,
} from './types'

import { MainconCheckList } from 'features/types/checklist'

import produce from 'immer'
import { cloneDeep } from 'lodash'
import parseErrorMessage from 'utils/parseErrorMessage'

import { getType } from 'typesafe-actions'
import * as actions from './actions'
import { toSaveFormat } from 'utils/datetime'

const mockOriginal = {} as Activity

const initEvent = {
  IsLoading: false,
  MessageSuccess: undefined,
  SubmitError: undefined,
  SubmitSuccess: false,
  SubmitType: undefined,
}

const mockCurrent = {
  Images: [],
  Documents: [],
  CurrentProgress: 0,
  RequestProgress: 0,
  ExpectFinishDate: '',
  ActualStartDate: '',
  ActualFinishDate: '',
  Remark: '',
} as CurrentActivity

const mockCheckList = {
  CheckListID: '',
  CheckListName: '',
  Status: 'INIT',
  InspectionList: [],
  WarningList: [],
} as MainconCheckList

const initialState: ActivityState = {
  CurrentActivity: cloneDeep(mockCurrent),
  OriginalActivity: cloneDeep(mockOriginal),
  CheckList: cloneDeep(mockCheckList),
  UploadList: {} as any[],
  Event: cloneDeep(initEvent),
  EventCheckList: cloneDeep(initEvent),
}

export default function(
  state: ActivityState = initialState,
  action: ActivityAction
): ActivityState {
  return produce(state, draft => {
    switch (action.type) {
      case getType(actions.clearSubmitError):
        draft.Event.MessageSuccess = undefined
        draft.Event.SubmitError = undefined
        draft.Event.SubmitSuccess = false

        break
      case getType(actions.setCurrentActivity):
        draft.CurrentActivity = action.payload
        break
      case getType(actions.setCheckList):
        draft.CheckList = action.payload
        break
      case getType(actions.getActivityDetailRequest.request):
        draft.Event = cloneDeep(initEvent)
        draft.Event.IsLoading = true

        draft.CurrentActivity = cloneDeep(mockCurrent)
        draft.OriginalActivity = cloneDeep(mockOriginal)
        break

      case getType(actions.getActivityDetailRequest.success):
        const original = action.payload.ResponseData
        draft.OriginalActivity = original
        let ExpectFinishDate = original.ExpectFinishDate
        let ActualStartDate = original.ActualStartDate
        let ActualFinishDate = original.ActualFinishDate
        ExpectFinishDate = toSaveFormat(ExpectFinishDate)
        ActualStartDate = toSaveFormat(ActualStartDate)
        ActualFinishDate = toSaveFormat(ActualFinishDate)

        draft.CurrentActivity = {
          Images: original.CmImages,
          Documents: original.CmDocuments,
          CurrentProgress: original.LastCurProgress,
          RequestProgress: original.Progress,
          ExpectFinishDate,
          ActualStartDate,
          ActualFinishDate,
          Remark: original.CmRemark,
        }

        draft.Event.IsLoading = false
        break
      case getType(actions.getActivityDetailRequest.failure):
        draft.Event.IsLoading = false
        draft.Event.SubmitError = parseErrorMessage(action.payload)
        break

      case getType(actions.getCheckListRequest.request):
        draft.EventCheckList = cloneDeep(initEvent)
        draft.EventCheckList.IsLoading = true

        draft.CheckList = cloneDeep(mockCheckList)
        break
      case getType(actions.getCheckListRequest.success):
        const respCheckList = action.payload.ResponseData
        if (respCheckList.CheckListID) {
          draft.CheckList = respCheckList
        }

        draft.EventCheckList.IsLoading = false
        break
      case getType(actions.getCheckListRequest.failure):
        draft.EventCheckList.IsLoading = false
        draft.EventCheckList.SubmitError = parseErrorMessage(action.payload)
        break

      case getType(actions.rejectActivity.request):
        draft.Event = cloneDeep(initEvent)
        draft.Event.IsLoading = true
        break
      case getType(actions.rejectActivity.success):
        draft.Event.IsLoading = false
        draft.Event.SubmitError = undefined
        draft.Event.SubmitSuccess = true
        draft.Event.MessageSuccess = 'ส่งกลับให้ผู้รับเหมาเรียบร้อย'
        break
      case getType(actions.rejectActivity.failure):
        draft.Event.IsLoading = false
        draft.Event.SubmitError =
          'ไม่สามารถส่งกลับให้ผู้รับเหมาได้ กรุณาลองใหม่อีกครั้ง'
        break
      case getType(actions.acceptActivity.request):
      case getType(actions.cmTakeActivity.request):
        draft.Event = cloneDeep(initEvent)
        draft.Event.IsLoading = true
        break
      case getType(actions.cmTakeActivityList.request):
        draft.Event = cloneDeep(initEvent)
        draft.Event.IsLoading = true
        break
      case getType(actions.acceptActivity.success):
        draft.Event.IsLoading = false
        draft.Event.SubmitError = undefined
        draft.Event.SubmitSuccess = true
        draft.Event.MessageSuccess = 'รับคำขอเรียบร้อย'
        break
      case getType(actions.cmTakeActivity.success):
        draft.Event.IsLoading = false
        draft.Event.SubmitError = undefined
        draft.Event.SubmitSuccess = true
        draft.Event.MessageSuccess = 'รับงานเรียบร้อย'
        break
      case getType(actions.cmTakeActivityList.success):
        draft.Event.IsLoading = false
        draft.Event.SubmitError = undefined
        draft.Event.SubmitSuccess = true
        draft.Event.MessageSuccess = 'รับงานเรียบร้อย'
        break
      case getType(actions.acceptActivity.failure):
        draft.Event.IsLoading = false
        draft.Event.SubmitError = 'ไม่สามารถรับคำขอได้ กรุณาลองใหม่อีกครั้ง'
        break
      case getType(actions.cmTakeActivity.failure):
        draft.Event.IsLoading = false
        draft.Event.SubmitError = 'ไม่สามารถรับงานได้ กรุณาลองใหม่อีกครั้ง'
        break
      case getType(actions.cmTakeActivityList.failure):
        draft.Event.IsLoading = false
        draft.Event.SubmitError = 'ไม่สามารถรับงานได้ กรุณาลองใหม่อีกครั้ง'
        break
      case getType(actions.sendReqToPJAsyncReq.request):
        draft.Event = cloneDeep(initEvent)
        draft.Event.IsLoading = true
        break
      case getType(actions.sendReqToPJAsyncReq.success):
        draft.Event.IsLoading = false
        draft.Event.SubmitError = undefined
        draft.Event.SubmitSuccess = true
        draft.Event.MessageSuccess = 'ส่งคำขออนุมัติเรียบร้อย'
        break
      case getType(actions.sendReqToPJAsyncReq.failure):
        draft.Event.IsLoading = false
        draft.Event.SubmitError = parseErrorMessage(action.payload)
        break

      case getType(actions.sendAttachmentList.request):
        draft.Event = cloneDeep(initEvent)
        draft.Event.IsLoading = true
        break
      case getType(actions.sendAttachmentList.success):
        draft.Event.IsLoading = false
        draft.Event.SubmitError = undefined
        draft.Event.SubmitSuccess = true
        draft.Event.MessageSuccess = 'บันทึกเอกสารเรียบร้อย'
        break
      case getType(actions.sendAttachmentList.failure):
        draft.Event.IsLoading = false
        draft.Event.SubmitError = parseErrorMessage(action.payload)
        break

      default:
        return state
    }
  })
}
