import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Col, Row } from 'antd'
import { PrimaryButton, SecondaryButton } from 'Components/Shared/Button'
import exportFromJSON from 'export-from-json'
import moment from 'moment'

type Props = {
  onSearch: () => void
  onClearSearch: () => void
  isFilter?: boolean
  onExport?: () => void
  isDownLoading?: boolean
  exportData?: any[]
  fileName?: string
  isDisabled?: boolean
  btnText?: string
  showExport?: boolean
  isDisabledExport?: boolean
}

const ButtonFilter: React.FunctionComponent<Props> = props => {
  const {
    onSearch,
    onClearSearch,
    isFilter = false,
    exportData = [],
    onExport = () => {},
    fileName = 'report',
    isDownLoading = false,
    isDisabled = false,
    btnText = 'Export.csv',
    showExport = true,
    isDisabledExport = false,
  } = props
  const [isExport, setIsExport] = useState(false)
  const date = moment().format('YYYY-MM-DD HH:mm')

  useEffect(() => {
    if (isExport && exportData.length > 0 && !isDownLoading) {
      const data: any = {
        data: exportData,
        fileName: `${fileName}_${date}`,
        exportType: 'csv',
        withBOM: true,
      }
      exportFromJSON(data)
      setIsExport(false)
    }
  }, [isExport, exportData, isDownLoading])

  return (
    <Row type="flex" justify="space-between" align="middle">
      <Col span={9}>
        {showExport && (
          <Box>
            <SecondaryButton
              text={btnText}
              disabled={isDownLoading || isDisabledExport}
              onClick={() => {
                onExport()
                setIsExport(true)
              }}
            />
          </Box>
        )}
      </Col>
      <Col span={9}>
        <Box>
          <PrimaryButton
            text="ค้นหา"
            disabled={isDisabled}
            onClick={onSearch}
          />
        </Box>
      </Col>
      <Col span={6}>
        {isFilter && (
          <Box>
            <ClearTxt onClick={onClearSearch}>ยกเลิกค้นหา</ClearTxt>
          </Box>
        )}
      </Col>
    </Row>
  )
}

const Box = styled.div`
  padding: 0 12px 12px 0;
`

const ClearTxt = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  color: #003168;
  font-size: 18px;
  text-decoration: underline;
  cursor: pointer;
`

export default ButtonFilter
