import React, { useState } from 'react'
import RootState from 'typings/RootState'
import { connect, useDispatch } from 'react-redux'
import moment, { Moment } from 'moment-timezone'

import { getUserProfile } from 'features/sensitive/sensitive.selector'

import SideMenu from 'Components/Shared/SideMenu'
import EventModal from 'Components/Shared/Modal/EventModal'

type Props = ReduxProps &
  DispatchProps & {
    selected?: string
    children?: any
  }

const AdminQCMenu = (props: Props) => {
  const { userProfile, selected, children } = props

  const roleName = userProfile.RoleName
  const menu: any =
    roleName === 'Report' ? [report] : [config, utilities, checklist, report]

  return (
    <SideMenu selected={selected} list={menu}>
      {children}
    </SideMenu>
  )
}

const checklist: any = {
  name: 'Checklist',
  list: [
    {
      name: 'ตรวจมาตรฐาน',
      key: 'check.quality',
      link: `/check-unit`,
    },
    {
      name: 'ตรวจเอกสาร',
      key: 'check-document',
      link: `/check-document`,
    },
    {
      name: 'ตรวจ Function',
      key: 'check-function',
      link: `/check-function`,
    },
    {
      name: 'ตรวจ ส่วนกลาง',
      key: 'check-zone',
      link: `/check-zone`,
    },
    {
      name: 'ตรวจงาน Site visit ระหว่างก่อสร้าง',
      key: 'check-construct-plan',
      link: `/check-construct-plan`,
    },
    {
      name: 'ตรวจงาน Site visit เปรียบเทียบแบบจัดสรร',
      key: 'check-audit-plan',
      link: `/check-audit-plan`,
    },
    {
      name: 'ตรวจงาน Commissioning',
      key: 'check-commission',
      link: `/check-commission`,
    },
    {
      name: 'ตรวจระหว่างก่อสร้างถนน',
      key: 'check-construct-road',
      link: `/check-construct-road`,
    },
    {
      name: 'ตรวจรับมอบถนนและก่อนหมดประกัน',
      key: 'check-audit-road',
      link: `/check-audit-road`,
    },
  ],
}

const report: any = {
  name: 'Report',
  list: [
    {
      name: 'รายงานตรวจมาตรฐาน (ยูนิต/แปลง)',
      key: 'report.unit',
      link: `/report/unit`,
    },
    {
      name: 'รายงานตรวจส่วนกลาง',
      key: 'report.zone',
      link: `/report/zone`,
    },
    {
      name: 'รายงาน Site visit',
      key: 'report.site',
      link: `/report/constructplan`,
    },
    {
      name: 'รายงานถนน',
      key: 'report.road',
      link: `/report/constructroad`,
    },
    {
      name: 'รายงาน Performance',
      key: 'report.performance',
      link: `/report/performance`,
    },
    {
      name: 'รายงานคะแนนเฉลี่ย',
      key: 'report.score',
      link: `/report/score`,
    },
    {
      name: 'รายงาน Monthly',
      key: 'report.monthly',
      link: `/report/monthly`,
    },
    {
      name: 'Daily report audit',
      key: 'report.daily',
      link: `/report/daily`,
    },
    {
      name: 'รายงาน SQD',
      key: 'report.sqd',
      link: `/report/sqdbyproject`,
    },
  ],
}

const config: any = {
  name: 'Config',
  list: [
    {
      name: 'Model',
      key: 'model',
      link: `/model`,
    },
    {
      name: 'Floorplan ส่วนกลาง',
      key: 'floorplan',
      link: `/floorplan`,
    },
    {
      name: 'ยูนิต',
      key: 'unit',
      link: `/unit`,
    },
    {
      name: 'QC Work',
      key: 'qc work',
      link: `/qcwork`,
    },
    {
      name: 'ถอยสถานะการตรวจยูนิต',
      key: 'backward unit',
      link: `/backward-unit`,
    },
  ],
}

const utilities: any = {
  name: 'Utilities',
  list: [
    {
      name: 'ประวัติ',
      key: 'requestHistory',
      link: '/utilities/history',
    },
  ],
}

const mapStateToProps = (state: RootState) => {
  return {
    userProfile: getUserProfile(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>
const mapDispatchToProps = {}
type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(AdminQCMenu)
