import React, { useState, useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import RootState from 'typings/RootState'
import { connect, useDispatch } from 'react-redux'
import { setCurrentActivity } from 'features/maincon/activity/actions'
import {
  userEmailSelector,
  serviceTokenSelector,
} from 'features/sensitive/sensitive.selector'
import { EventModal } from 'Components/Shared'
import Menu from 'Components/MenuAdmin/AdminQCMenu'

import Breadcrumbs from './Breadcrumbs'
import styled from 'styled-components'
import { getReportDetail } from 'features/adminReport/constructRoad/api'
import { GetReportDetailReq } from 'features/adminReport/constructRoad/types'
import { toNumber } from 'lodash'
import ReportDetail from './ReportDetail'
import { ActivityDetail } from 'Components/AdminQC/Report/Components'
import {
  selectEvent,
  selectReportDetail,
} from 'features/adminReport/constructRoad/selector'

type FormProps = {
  id: string
}

type Props = FormProps &
  RouteComponentProps<{
    projectCode: string
    unit: string
    qcTypeID: string
    checkNo: string
    requestID: string
  }> &
  ReduxProps

const ReportDetailPage = (props: Props) => {
  const [lastPage, setLastPage] = useState('')
  const [page, setPage] = useState('')

  const [enable, setEnable] = useState(false)
  const { Token, UserEmail, match, data, event } = props

  const Unit = match.params.unit
  const QCTypeID = match.params.qcTypeID
  const CheckNumber = match.params.checkNo
  const RequestID = match.params.requestID
  const ProjectCode = match.params.projectCode

  const onPageClose = (page: string) => {
    if (lastPage !== '') {
      setLastPage('')
    }
    setPage(page)
  }

  const dispatch = useDispatch()

  useEffect(() => {
    setEnable(false)
  }, [data])

  useEffect(() => {
    onGetActivityDetail()
  }, [UserEmail, ProjectCode, Token, dispatch])

  const onGetActivityDetail = () => {
    setEnable(true)
    const data: GetReportDetailReq = {
      ProjectCode,
      UserEmail,
      QCTypeID,
      RequestID: toNumber(RequestID),
      Unit,
      CheckNumber: toNumber(CheckNumber),
    }
    dispatch(getReportDetail(Token, data))
  }

  const onSuccess = () => {
    setEnable(false)
    onGetActivityDetail()
  }

  const renderContent = () => {
    switch (page) {
      case 'detail':
      case 'detail.preview':
        return (
          <ActivityDetail
            ActivityID={data.ActivityID}
            ProjectCode={data.ProjectCode}
            onSetPage={setPage}
            page={page}
          />
        )
      default:
        return (
          <ReportDetail data={data} onSeeDetail={() => setPage('detail')} />
        )
    }
  }

  const bcName = page === 'detail' || page === 'detail.preview' ? data.Unit : ''

  return (
    <>
      <EventModal
        name="activity"
        event={[event]}
        enable={enable}
        successCalback={onSuccess}
      />
      <Breadcrumbs
        name={bcName}
        preview={page === 'detail.preview' ? 'เอกสาร' : ''}
        onClose={onPageClose}
      />
      <Menu selected={'report.road'}>{renderContent()}</Menu>
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    UserEmail: userEmailSelector(state),
    Token: serviceTokenSelector(state),
    data: selectReportDetail(state),
    event: selectEvent(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {
  setCurrentActivity,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportDetailPage)
