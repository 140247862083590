import React, { useState, useLayoutEffect, useCallback } from 'react'
import { Modal } from 'antd'
import styled from 'styled-components'

import { cloneDeep } from 'lodash'
import file from 'utils/file'
import { writeImage } from './utils'

import TopMenu from './TopMenu'
import ToolMenu from './ToolMenu'

import BaseEditor from './BaseEditor'
import TextEditor from './TextEditor'
import DrawEditor from './DrawEditor'
import StickerEditor from './StickerEditor'
import DisplayEditor from './DisplayEditor'

type Props = {
  visible: boolean
  image: string
  onOk: (base64: string) => void
  onClose: () => void
}

type PositionType = {
  x: number
  y: number
  width: number
  height: number
}

const Editor: React.ComponentType<Props> = props => {
  const [mode, setMode] = useState('')
  const [editIndex, setEditIndex] = useState(-1)
  const [myCanvas, setMyCanvas] = useState(undefined as any)
  const [config, setConfig] = useState({
    color: 'white',
    lineSize: 3,
    image: '',
    value: '',
    fontSize: 20,
  } as any)
  const [limitPosition, setLimitPosition] = useState({
    left: 0,
    top: 0,
    bottom: 1000,
    right: 1000,
    width: 25,
    height: 40,
  } as any)

  const [lineList, setLineList] = useState([])
  const [position, setPosition] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  } as PositionType)
  const [initial, setInitial] = useState({} as any)
  const [original, setOriginal] = useState({} as any)

  const [editList, setEditList] = useState([] as any[])
  const [history, setHistory] = useState({ index: -1, list: [] as any[] })

  const { visible, image, onClose } = props

  const onRefCanvas = useCallback(node => {
    if (node) {
      setMyCanvas(node)
      const rect = node.getBoundingClientRect()
      drawMyImage(node, image)
    }
  }, [])

  useLayoutEffect(() => {
    drawMyImage(myCanvas, image)
    setEditList([])
    setHistory({ index: -1, list: [] as any[] })
  }, [image])

  const getMaxSize = () => {
    const width = window.innerWidth - 150
    const height = window.innerHeight - 180
    return { width, height }
  }

  const drawMyImage = async (elem: any, base64: string) => {
    if (elem) {
      const maxSize = getMaxSize()
      const img = await file.base64ToImage(base64)
      const newSize: any = await file.reduceByDimension(img, maxSize)
      const { width, height } = newSize
      elem.width = width
      elem.height = height

      const ctx = elem.getContext('2d')
      ctx.drawImage(img, 0, 0, width, height)

      const limit = {
        left: 0,
        top: 0,
        bottom: height,
        right: width,
        width: 25,
        height: 25,
      }
      setLimitPosition(limit)

      const iSize = {
        width: width > 150 ? 150 : 50,
        height: height > 150 ? 150 : 50,
      }

      const iPosition = {
        x: width / 2 - iSize.width / 2,
        y: height / 2 - iSize.height / 2,
      }

      setPosition({
        x: iPosition.x,
        y: iPosition.y,
        width: iSize.width,
        height: iSize.height,
      })
      setOriginal({ size: iSize, position: iPosition })
    }
  }

  const onModeChange = (val: string) => {
    const nMode = mode === val ? '' : val
    setMode(nMode)
    setEditIndex(-1)
    let { width, height } = original.size
    const { x, y } = original.position
    switch (nMode) {
      case 'sticker':
      case 'circle':
      case 'rectangle':
        limitPosition.width = 50
        limitPosition.height = 50
        setLimitPosition({ ...limitPosition })

        setPosition({ x, y, width, height })
        setInitial({ size: { width, height }, position: { x, y } })
        break
      case 'text':
        width = 80
        height = 40
        limitPosition.width = width
        limitPosition.height = height

        setLimitPosition({ ...limitPosition })
        config.value = ''
        config.fontSize = 20

        setConfig({ ...config })
        setPosition({ x, y, width, height })
        setInitial({ size: { width, height }, position: { x, y } })
        break
      case 'draw':
        setLineList([])
        initial.lineList = []
        setInitial({ ...lineList })
        break
    }
  }

  const onToolConfigChange = (val: any) => {
    setConfig({ ...val })
  }

  const onPosition = (x: number, y: number, width: number, height: number) => {
    setPosition({ x, y, width, height })
  }

  const onBack = () => {
    if (mode === '') {
      onClose()
      setEditList([])
    } else {
      setMode('')
    }
  }

  const onOk = async () => {
    try {
      if (mode === '') {
        const ctx = myCanvas.getContext('2d')
        for (const item of editList) {
          if (item.mode === 'draw') {
            await writeImage(item.mode, ctx, item.position, item.data.lineList)
          } else {
            await writeImage(item.mode, ctx, item.position, item.data)
          }
        }

        const base64: string = myCanvas.toDataURL()
        props.onOk(base64)
        setEditList([])
      } else {
        processEditor()
        setMode('')
      }
    } catch (e) {
      console.log('on error:', e.message)
    }
  }

  const calcLine = (list: any[]) => {
    let minX = 10000,
      minY = 10000
    let maxX = 0,
      maxY = 0
    let x = 0,
      y = 0
    for (const item of lineList) {
      const { start, list }: any = item
      x = start.x
      y = start.y
      minX = x < minX ? x : minX
      maxX = x > maxX ? x : maxX

      minY = y < minY ? y : minY
      maxY = y > maxY ? y : maxY

      for (const point of list) {
        x = point.x
        y = point.y
        minX = x < minX ? x : minX
        maxX = x > maxX ? x : maxX

        minY = y < minY ? y : minY
        maxY = y > maxY ? y : maxY
      }
    }

    const newLineList = []
    for (const item of lineList) {
      const { start, setting, list }: any = item
      const newStart = {
        x: start.x - minX,
        y: start.y - minY,
      }

      const newList = []
      for (const point of list) {
        x = point.x - minX
        y = point.y - minY
        newList.push({ x, y })
      }

      newLineList.push({
        start: newStart,
        list: newList,
        setting,
        end: {},
      })
    }

    return { minX, minY, maxX, maxY, newLineList }
  }

  const processEditor = async () => {
    let params
    switch (mode) {
      case 'circle':
      case 'rectangle':
      case 'text':
      case 'sticker':
        params = { mode, position, data: config }
        break
      case 'draw':
        const item = calcLine(lineList)
        const { minX, minY, maxX, maxY, newLineList } = item
        const pos = {
          x: minX,
          y: minY,
          width: maxX - minX,
          height: maxY - minY,
        }
        params = {
          mode,
          position: pos,
          data: { ...config, lineList, newLineList },
        }
        break
    }

    if (params) {
      const newParams = cloneDeep(params)
      if (editIndex >= 0) {
        addToHistoryList('edit', editIndex, {
          old: editList[editIndex],
          new: newParams,
        })
        editList[editIndex] = newParams
      } else {
        editList.push(newParams)
        addToHistoryList('add', editList.length - 1, newParams)
      }

      setHistory(history)
      setEditList([...editList])
    }
  }

  const onEditItem = (item: any, index: number) => {
    setEditIndex(index)
    const pos = item.position
    setMode(item.mode.toString())
    setInitial({
      size: { width: pos.width, height: pos.height },
      position: { x: pos.x, y: pos.y },
      lineList: item.data.lineList,
    })
    setConfig({ ...item.data })
  }

  const onDelete = () => {
    if (editIndex >= 0) {
      addToHistoryList('delete', editIndex, editList[editIndex])
      editList.splice(editIndex, 1)
      setEditList([...editList])
    }

    onBack()
  }

  const addToHistoryList = (type: string, index: number, data: any) => {
    const len = history.list.length
    if (history.index + 1 !== len) {
      history.list.splice(index, len - history.index)
    }

    history.index += 1
    history.list.push({ type, index, data })
    setHistory(history)
  }

  const addHistory = (index: number, data: any) => {
    editList.splice(index, 0, data)
    setEditList([...editList])
  }

  const editHistory = (index: number, data: any) => {
    editList[index] = data
    setEditList([...editList])
  }

  const deleteHistory = (index: number) => {
    editList.splice(index, 1)
    setEditList([...editList])
  }

  const onUndo = () => {
    const { index, list } = history
    const item = list[index]
    switch (item.type) {
      case 'add':
        deleteHistory(item.index)
        break
      case 'edit':
        editHistory(item.index, item.data.old)
        break
      case 'delete':
        addHistory(item.index, item.data)
        break
    }

    setHistory({ index: index - 1, list })
  }

  const onRedo = () => {
    let { index, list } = history
    index += 1
    const item = list[index]
    switch (item.type) {
      case 'add':
        addHistory(item.index, item.data)
        break
      case 'edit':
        editHistory(item.index, item.data.new)
        break
      case 'delete':
        deleteHistory(item.index)
        break
    }

    setHistory({ index, list })
  }

  let editItem
  switch (mode) {
    case 'circle':
    case 'rectangle':
      editItem = (
        <BaseEditor
          initial={initial}
          isCircle={mode === 'circle'}
          limit={limitPosition}
          color={config.color}
          lineSize={config.lineSize}
          onPosition={onPosition}
        />
      )
      break
    case 'text':
      editItem = (
        <TextEditor
          initial={initial}
          value={config}
          limit={limitPosition}
          onPosition={onPosition}
          onChange={(val: any) => setConfig({ ...val })}
        />
      )
      break
    case 'draw':
      editItem = (
        <DrawEditor
          initial={initial}
          limit={limitPosition}
          color={config.color}
          lineSize={config.lineSize}
          onChange={(val: any) => setLineList(val || [])}
        />
      )
      break
    case 'sticker':
      editItem = (
        <StickerEditor
          initial={initial}
          image={config.image}
          limit={limitPosition}
          onPosition={onPosition}
        />
      )
      break
    default:
  }

  const cssSize = {
    width: `${limitPosition.right}px`,
    height: `${limitPosition.bottom}px`,
  }

  const displayItem = editList.map((item, index) => {
    if (mode !== '' && editIndex === index) return
    return (
      <DisplayEditor
        key={index}
        index={index}
        item={item}
        onClick={mode === '' ? () => onEditItem(item, index) : undefined}
      />
    )
  })

  return (
    <Modal
      title=""
      footer=""
      visible={visible}
      className="editor-view"
      onOk={onClose}
      onCancel={onClose}
      centered={false}
      closable={false}
      width="100%"
    >
      <PageView>
        <TopMenu
          edit={mode !== ''}
          onBack={onBack}
          onDelete={onDelete}
          onOk={onOk}
          onUndo={history.index >= 0 ? onUndo : undefined}
          onRedo={
            history.index + 1 === history.list.length ? undefined : onRedo
          }
        />
        <PageArea>
          <ImgArea style={cssSize}>
            <MyCanvas ref={onRefCanvas} />
            {editItem}
            {displayItem}
          </ImgArea>
        </PageArea>
        <ToolMenu
          mode={mode}
          config={config}
          onChange={onModeChange}
          onConfig={onToolConfigChange}
        />
      </PageView>
    </Modal>
  )
}

const PageView = styled.div`
  width: 100%;
  height: 100vh;
  background-color: black;
`

const PageArea = styled.div`
  padding: 0px 40px;
  align-items: center;
  display: flex;
  height: 84vh;
`

const ImgArea = styled.div`
  position: relative;
  margin: 0 auto;
  width: auto;
  height: auto;
`

const MyCanvas = styled.canvas`
  width: 100%;
`

export default Editor
