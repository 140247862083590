import RootState from 'typings/RootState'
import { Event } from 'features/types/event'
import { Activity } from './types'

export const selectData = (state: RootState): Activity =>
  state.cmpjZoneSeemore.data

export const selectEvent = (state: RootState): Event =>
  state.cmpjZoneSeemore.event

export const selectSorting = (state: RootState) => state.cmpjZoneSeemore.sorting
