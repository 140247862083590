import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'antd'
import { Link } from 'react-router-dom'
import {
  TypeOfTask,
  QCInProgressActivity,
  FixFromQCActivity,
} from 'features/cmpj/qcdashboard/types'
import {
  TodoActivity,
  CMInProgressActivity,
} from 'features/cmpj/qcdashboard/types'

import {
  CheckStatusIcon,
  UnitTaskStatus,
  RejectStatusIcon,
} from 'Components/Shared'
import { getQCTypeVersion } from 'utils/getText'

import { unitStatusIsPass } from 'utils/status-map'
import { toDateText, toTimeText, toMomentDateTime } from 'utils/datetime'

interface Props {
  type?: string
  task?: any
  onClick?: (code: string) => void
}

const Card: React.FC<Props> = ({ type = '', task, onClick }) => {
  const getLaneColor = (status: string) => {
    let lane
    switch (status) {
      case 'success':
        lane = <span className="lane-card-success" />
        break
      case 'fail':
        lane = <span className="lane-card-delayed" />
        break
      default:
        break
    }
    return lane
  }

  const renderTodoStatus = (PassedSubTasks: number, TotalSubTasks: number) => {
    return PassedSubTasks === TotalSubTasks ? (
      <TextStatus>พร้อมตรวจด้วยตัวเอง</TextStatus>
    ) : (
      'กำลังดำเนินการ'
    )
  }

  const renderQCInProgressStatus = (isReady: boolean) => {
    const appointmentDate = toMomentDateTime(task.AppointmentDate)
    const date = toDateText(appointmentDate)
    const time = toTimeText(appointmentDate)
    return isReady ? (
      <TextStatus>
        วันที่นัดหมาย {date} เวลา {time}
      </TextStatus>
    ) : (
      <span>รอ QC รับคำขอ</span>
    )
  }

  const getCountTask = (current: number, total: number) => {
    if (current === total) {
      return `จำนวนงาน ${current} /${total}`
    } else {
      return (
        <div>
          จำนวนงาน <TextBlue>{current}</TextBlue> /{total}
        </div>
      )
    }
  }

  const getQcTypeAndCheckNo = () => {
    return getQCTypeVersion(task)
  }

  const isComplete = () => {
    return task.PassedSubTasks === task.TotalSubTasks
  }

  const todoCard = (task: TodoActivity) => {
    const isTaskDone = task.PassedSubTasks === task.TotalSubTasks
    const countTask = getQcTypeAndCheckNo()
    return (
      <>
        {getLaneColor(isComplete() ? 'success' : '')}
        <Row type="flex">
          <Col span={24}>
            <Title>{task.Unit}</Title>
            <Detail>
              {isTaskDone ? countTask : <TextBlue>{countTask}</TextBlue>}
            </Detail>
            <Detail>
              {getCountTask(task.PassedSubTasks, task.TotalSubTasks)}
            </Detail>
            <Detail>
              สถานะ: {renderTodoStatus(task.PassedSubTasks, task.TotalSubTasks)}
            </Detail>
          </Col>
        </Row>
      </>
    )
  }

  const cmInProgressCard = (task: CMInProgressActivity) => {
    return (
      <Row type="flex" justify="space-between" align="bottom">
        <Col span={24}>
          <Row type="flex" justify="space-between" align="top">
            <Col>
              <Title>{task.Unit}</Title>{' '}
            </Col>
            {task.Reject != undefined &&
              task?.Reject.RejectReasonRemark == 'I' && (
                <Col>
                  <RejectStatusIcon
                    isRejectCheck={task.Reject.RejectReasonRemark == 'I'}
                    // reason={task.Reject.RejectReason}
                  />
                </Col>
              )}
          </Row>
          <Row type="flex" justify="space-between" align="bottom">
            <Col>
              <Detail>{getQcTypeAndCheckNo()} </Detail>
            </Col>
            <Col>
              {' '}
              <Space>
                <InspectText>
                  {task.CheckStatus_Insp == 'NotInspection'
                    ? '[ ไม่ประเมินคะแนน ]'
                    : ''}
                </InspectText>{' '}
              </Space>
            </Col>
          </Row>
          <Line />
          <Detail>คะแนนมาตรฐาน</Detail>
          <UnitTaskStatus
            score={task.InspectionScore}
            status={task.CheckStatus}
          />
        </Col>
      </Row>
    )
  }

  const qcInProgressCard = (task: QCInProgressActivity) => {
    const complete = task.TaskStatus === 'INPRO'
    return (
      <>
        {getLaneColor(complete ? 'success' : '')}
        <Row type="flex">
          <Col span={24}>
            <Title>{task.Unit}</Title>
            <Detail>{getQcTypeAndCheckNo()}</Detail>
            <Detail>วันที่ส่งคำขอ: {task.RequestDate}</Detail>
            <Detail>สถานะ: {renderQCInProgressStatus(complete)}</Detail>
          </Col>
        </Row>
      </>
    )
  }

  const fixFromQCCard = (task: FixFromQCActivity) => {
    const selfCheck = task.LastCheckedModule === 'CM'
    let isPass = unitStatusIsPass(
      selfCheck,
      task.CheckStatus,
      task.DocumentStatus
    )
    return (
      <>
        {getLaneColor(isPass ? 'success' : 'fail')}
        <Row type="flex" justify="space-between" align="bottom">
          <Col>
            <Title>{task.Unit}</Title>

            <Row type="flex" justify="space-between" align="bottom">
              <Col>
                <Detail>{getQcTypeAndCheckNo()} </Detail>
              </Col>

              <Col>
                {' '}
                <Space>
                  <InspectText>
                    {task.CheckStatus_Insp == 'NotInspection'
                      ? '[ ไม่ประเมินคะแนน ]'
                      : ''}
                  </InspectText>{' '}
                </Space>
              </Col>
            </Row>
          </Col>
          <Col span={8}>
            <CheckStatusIcon isSelfCheck={selfCheck} />
          </Col>
        </Row>
        <Line />
        <Row>
          <Col span={12}>
            <Detail>คะแนนมาตรฐาน</Detail>
            <UnitTaskStatus
              score={task.InspectionScore}
              status={task.CheckStatus}
              isShowStatus={true}
            />
          </Col>
          {!selfCheck && (
            <Col span={12}>
              <Detail>คะแนนเอกสาร</Detail>
              <UnitTaskStatus
                score={task.DocumentScore}
                status={task.DocumentStatus}
                isShowStatus={true}
              />
            </Col>
          )}
        </Row>
      </>
    )
  }

  const getCardDetail = (type: string) => {
    switch (type) {
      case TypeOfTask.TODO:
        return todoCard(task)
        break
      case TypeOfTask.CMInProgress:
        return cmInProgressCard(task)
        break
      case TypeOfTask.QCInProgress:
        return qcInProgressCard(task)
        break
      case TypeOfTask.FixFromQC:
        return fixFromQCCard(task)
        break
      default:
        break
    }
  }

  const getLink = () => {
    const complete = task.TaskStatus === 'INPRO' || task.CheckNumber > 1
    switch (type) {
      case TypeOfTask.TODO:
        return `/qcdetail/${task.Unit}/${task.QCTypeID}/${task.RequestID}`
      case TypeOfTask.CMInProgress:
        return `/qcreport/${task.Unit}/${task.QCTypeID}/${task.RequestID}`
      case TypeOfTask.QCInProgress:
        return `/${complete ? 'qcreport' : 'qcdetail'}/${task.Unit}/${
          task.QCTypeID
        }/${task.RequestID}`
      case TypeOfTask.FixFromQC:
        return `/qcreport/${task.Unit}/${task.QCTypeID}/${task.RequestID}`
      default:
        return ''
    }
  }

  return (
    <Link to={getLink()}>
      <MainCard>{getCardDetail(type)}</MainCard>
    </Link>
  )
}

const MainCard = styled.div`
  background: #fff;
  border-radius: 10px;
  padding: 16px;
  background-clip: padding-box;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.03);
  margin-bottom: 12px;
  cursor: pointer;
  color: #000;
  width: 100%;
  position: relative;
`

const Title = styled.p`
  font-size: 20px;
  margin-bottom: 4px;
  font-family: 'DB Helvethaica X 75 Bd';
`

const Detail = styled.p`
  font-size: 18px;
  margin-bottom: 4px;
  font-family: 'DB Helvethaica X 55 Regular';
`

const TextStatus = styled.span`
  color: #63cea6;
`

const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e8ebf0;
  margin: 5px 0;
`

const TextBlue = styled.span`
  color: #003168;
`

const InspectText = styled.p`
  font-size: 16px;
  margin-bottom: 0;
  font-family: 'DB Helvethaica X 75 Bd';
  line-height: 1;
  color: #20a1ef;
  margin: 10px 0;
`

const Space = styled.p`
  margin: 10px;
`

export default Card
