import React from 'react'
import { Row } from 'antd'
import PaginationLinkButton from 'Components/buttons/PaginationLinkButton'
import { TasklaneType } from 'features/tasklane/tasklane.types'

const range = (start: number, end: number) => {
  const pages = [start]

  if (start === end) return pages

  for (let i = start + 1; i <= end; i++) {
    pages.push(i)
  }

  return pages
}

type Props = {
  currentPage: number
  totalRows: number
  perPage: number
  type: TasklaneType
}

const Pagination: React.FunctionComponent<Props> = ({
  currentPage = 1,
  totalRows = 1,
  perPage = 10,
  type,
}) => {
  const totalPages = Math.ceil(totalRows / perPage)
  const firstPage = 1
  const previousPage = currentPage > 1 ? currentPage - 1 : 1
  const nextPage = currentPage === totalPages ? totalPages : currentPage + 1
  const lastPage = totalPages
  const tasklaneType = type as TasklaneType

  const getShowPage = () => {
    const maxIndex = 4
    let startPage = currentPage - 2
    let endPage
    let showPage
    if (startPage <= 0) {
      startPage = firstPage
    }

    endPage = startPage + maxIndex
    if (endPage > lastPage) {
      endPage = lastPage
    }

    showPage = endPage - startPage
    if (showPage <= maxIndex) {
      startPage = endPage - maxIndex
      if (startPage <= 0) {
        startPage = 1
      }
    }

    return [startPage, endPage]
  }

  const getBtn = (p: any, css: string, icon: string, alt: string) => {
    return (
      <li>
        <PaginationLinkButton type={tasklaneType} page={p}>
          <span className={css}>
            <img src={icon} alt={alt} />
          </span>
        </PaginationLinkButton>
      </li>
    )
  }

  const generatePaginationLinks = (
    type: TasklaneType,
    end: number,
    currentPage = 1
  ) => {
    const showPage = getShowPage()
    const pages = range(showPage[0], showPage[1])
    return pages.map(page => (
      <li key={page}>
        <PaginationLinkButton type={type} page={page}>
          <div
            className={
              currentPage === page
                ? 'pagination-number active-page'
                : 'pagination-number'
            }
          >
            <span className="page">{page}</span>
          </div>
        </PaginationLinkButton>
      </li>
    ))
  }

  return (
    <Row className="pagination-wrapper">
      <ul className="pagination-block">
        {getBtn(
          firstPage,
          'pagination-icon ic-first',
          '/img/ic-first.svg',
          'go to first page'
        )}
        {getBtn(
          previousPage,
          'pagination-icon ic-previous',
          '/img/ic-previous.svg',
          'go to previous page'
        )}

        {generatePaginationLinks(tasklaneType, totalPages, currentPage)}

        {getBtn(
          nextPage,
          'pagination-icon ic-next',
          '/img/ic-next.svg',
          'go to next page'
        )}
        {getBtn(
          lastPage,
          'pagination-icon ic-last',
          '/img/ic-last.svg',
          'go to last page'
        )}
      </ul>
    </Row>
  )
}

export default Pagination
