import { createAsyncAction, createStandardAction } from 'typesafe-actions'
import { SearchByKeywordRequest, SearchKeywordResponse } from './search.types'
import { Task } from 'features/tasklane/tasklane.types'
import { History } from 'history'

export const sampleSearchAction = createStandardAction('SAMPLE_SEARCH_ACTION')<
  void
>()

export const performSearchRequest = createStandardAction(
  'search/PERFORM_SEARCH'
)<any>()

export const updateSearchPaginationQuery = createStandardAction(
  'search/UPDATE_SEARCH_PAGINATION_QUERY'
)<{ page: number; rowPerPage: number }>()

export const searchByKeywordAsyncRequest = createAsyncAction(
  'search/SEARCH_BY_KEYWORD_REQUEST',
  'search/SEARCH_BY_KEYWORD_RESPONSE',
  'search/SEARCH_BY_KEYWORD_FAILURE'
)<SearchByKeywordRequest, SearchKeywordResponse, any>()

export const updateSearchTask = createStandardAction(
  'search/UPDATE_SEARCH_TASKBYID'
)<Task[]>()

export const sortingActivity = createStandardAction('activity/SORTING_SEARCH')<
  string
>()

export const updateWBS = createStandardAction('search/UPDATE_WBS')<any>()
