import React, { useState, useEffect } from 'react'
import { Row, Col } from 'antd'
import styled from 'styled-components'
import { InputSearch } from 'Components/Shared/Input'
import HintList from './HintList'

interface Props {
  list: any[]
  onChange: (newlist: any[]) => void
  seachable?: boolean
  warningComponent?: any
  updateMode?: boolean
}

const CheckListHeader = (props: Props) => {
  const {
    list,
    onChange,
    seachable = false,
    warningComponent = null,
    updateMode = true,
  } = props
  const [txtSearch, setTxtSearch] = useState('')

  const hasText = (data: string, searchTxt: string) => {
    if (data && searchTxt) {
      return data.toLocaleLowerCase().includes(searchTxt.toLocaleLowerCase())
    }
    return false
  }

  const onFilter = (val: string) => {
    setTxtSearch(val)
    const newList = list.map(item => {
      if (val.trim() === '') {
        item.isHide = false
      } else {
        if (!hasText(item.InspectionCategory, val)) {
          item.isHide = true
        } else {
          item.isHide = false
        }
      }
      return item
    })
    onChange([...newList])
  }

  return (
    <Body>
      <Row type="flex" justify="space-between" align="bottom">
        <Col>
          <Title> รายการตรวจสอบ</Title>
        </Col>
        {seachable && (
          <Col span={10}>
            <Row type="flex" justify="space-between" align="bottom">
              <Col span={4}>{warningComponent && warningComponent}</Col>
              <Col span={19}>
                <InputSearch
                  readOnly={false}
                  placeholder="พิมพ์ชื่องานที่ต้องการค้นหา..."
                  value={txtSearch}
                  onChange={onFilter}
                />
              </Col>
            </Row>
          </Col>
        )}
      </Row>
      {updateMode && (
        <HintList hintList={['กรุณาระบุหมายเหตุ ในกรณีที่ข้อนั้นไม่ผ่าน']} />
      )}
    </Body>
  )
}

const Body = styled.div`
  margin-bottom: 20px;
`
const Title = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 28px;
`

export default CheckListHeader
