import { ModelState, ModelAction } from './types'
import { cloneDeep } from 'lodash'
import produce from 'immer'
import parseErrorMessage from 'utils/parseErrorMessage'
import { getType } from 'typesafe-actions'
import * as actions from './actions'

import { Event } from '../types/event'

const initEvent = {
  IsLoading: false,
  MessageSuccess: undefined,
  SubmitError: undefined,
  SubmitSuccess: false,
  SubmitType: undefined,
} as Event

const initialState: ModelState = {
  ModelType: {
    Total: 0,
    List: [],
  },
  ConstructionTypeList: [],
  FloorList: [],
  ProjectTeamList: [],
  ProjectType: {
    Total: 0,
    List: [],
  },
  UnitList: [],
  Primavera: {
    Type: 'tower',
    List: [],
  },
  QcWork: {
    ProjectList: [],
  },
  Event: cloneDeep(initEvent),
}

export default function(
  state: ModelState = initialState,
  action: ModelAction
): ModelState {
  return produce(state, draft => {
    switch (action.type) {
      case getType(actions.getModelTypeList.request):
        draft.Event.IsLoading = true
        draft.Event.SubmitError = ''
        draft.ModelType = {
          Total: 0,
          List: [],
        }
        break
      case getType(actions.getModelTypeList.success):
        draft.ModelType = action.payload.ResponseData
        draft.Event.IsLoading = false
        break

      case getType(actions.getUnitListAction.request):
        draft.Event.IsLoading = true
        draft.Event.SubmitError = ''
        draft.UnitList = []
        break

      case getType(actions.getUnitListAction.success):
        draft.UnitList = action.payload.ResponseData.List || []
        draft.Event.IsLoading = false
        break

      case getType(actions.getPrimaveraActivityList.request):
        draft.Event = cloneDeep(initEvent)
        draft.Event.IsLoading = true

        draft.Primavera = {
          Type: 'tower',
          List: [],
        }
        break
      case getType(actions.getPrimaveraActivityList.success):
        draft.Event.IsLoading = false
        const pramavera: any = action.payload.ResponseData
        if (pramavera.TowerName) {
          draft.Primavera = {
            Type: 'tower',
            List: pramavera,
          }
        } else {
          draft.Primavera = {
            Type: 'construction',
            List: pramavera,
          }
        }

        break
      case getType(actions.getProjectTypeList.request):
        draft.Event = cloneDeep(initEvent)
        draft.Event.IsLoading = true

        draft.ProjectType = {
          Total: 0,
          List: [],
        }
        break
      case getType(actions.getProjectTypeList.success):
        draft.Event.IsLoading = false
        draft.ProjectType = action.payload.ResponseData
        break

      case getType(actions.getQcWorkAction.request):
        draft.Event = cloneDeep(initEvent)
        draft.Event.IsLoading = true

        draft.QcWork = { ProjectList: [] }
        break

      case getType(actions.getQcWorkAction.success):
        draft.Event.IsLoading = false
        draft.Event.SubmitSuccess = true
        draft.Event.MessageSuccess = 'ทำรายการสำเร็จ'

        draft.QcWork = action.payload.ResponseData
        break

      case getType(actions.getModelTypeList.failure):
      case getType(actions.getUnitListAction.failure):
      case getType(actions.getPrimaveraActivityList.failure):
      case getType(actions.getProjectTypeList.failure):
      case getType(actions.getQcWorkAction.failure):
        draft.Event.IsLoading = false
        draft.Event.SubmitError = parseErrorMessage(action.payload)
        break

      case getType(actions.resetPrimaveraActivityList):
        draft.Primavera = {
          Type: 'tower',
          List: [],
        }
        break
      default:
        return state
    }
  })
}
