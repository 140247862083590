import { createStandardAction, createAsyncAction } from 'typesafe-actions'
import {
  GetActivityRequest,
  GetTodoApiResponse,
  GetProgressApiResponse,
  GetQcApiResponse,
} from './types'

import { MainconFilter, MainconQuery } from '../dashboard/types'

export const getTodoActivityAction = createAsyncAction(
  'maincon/seemore/todo/FETCH_REQUEST',
  'maincon/seemore/todo/FETCH_SUCCESS',
  'maincon/seemore/todo/FETCH_FAILURE'
)<GetActivityRequest, GetTodoApiResponse, any>()

export const getProgressActivityAction = createAsyncAction(
  'maincon/seemore/progress/FETCH_REQUEST',
  'maincon/seemore/progress/FETCH_SUCCESS',
  'maincon/seemore/progress/FETCH_FAILURE'
)<GetActivityRequest, GetProgressApiResponse, any>()

export const getQcActivityAction = createAsyncAction(
  'maincon/seemore/qc/FETCH_REQUEST',
  'maincon/seemore/qc/FETCH_SUCCESS',
  'maincon/seemore/qc/FETCH_FAILURE'
)<GetActivityRequest, GetQcApiResponse, any>()

export const sortingTask = createStandardAction('maincon/seemore/SORTING')<{
  name: string
  sort?: string
}>()

export const filterSeemore = createStandardAction(
  'maincon/seemore/FILTER_SEEMORE'
)<void>()

export const setFilterSeemore = createStandardAction(
  'maincon/seemore/CONFIG_FILTER_SEEMORE'
)<MainconFilter>()

export const setQueryDashboard = createStandardAction(
  'maincon/seemore/CONFIG_QUERY_DASHBOARD'
)<MainconQuery>()
