import { createStandardAction, createAsyncAction } from 'typesafe-actions'
import {
  GetDocumentReq,
  GetDocumentChecklisApiResponse,
  DocumentCheckList,
  SaveDocumentReq,
  GetApiResponse,
  ActivityIDList,
} from './types'

export const getDocumentAction = createAsyncAction(
  'qc/document/FETCH_DOCUMENT_REQUEST',
  'qc/document/FETCH_DOCUMENT_SUCCESS',
  'qc/document/FETCH_DOCUMENT_FAILURE'
)<GetDocumentReq, GetDocumentChecklisApiResponse, any>()

export const saveDocumentAction = createAsyncAction(
  'qc/document/SAVE_DOCUMENT_REQUEST',
  'qc/document/SAVE_DOCUMENT_SUCCESS',
  'qc/document/SAVE_DOCUMENT_FAILURE'
)<SaveDocumentReq, GetApiResponse<any>, any>()

export const updateData = createStandardAction(
  'qc/documentChecklist/UPDATE_FIELD'
)<DocumentCheckList>()

export const updateRejectList = createStandardAction(
  'qc/documentChecklist/UPDATE_REJ'
)<ActivityIDList[]>()
