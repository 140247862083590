import RootState from 'typings/RootState'

export const wbsByIdSelector = (state: RootState) => state.wbs.wbsById

type Props = {
  wbsId: number | string
}

export const getWbsById = (state: RootState, props: Props) =>
  state.wbs.wbsById[props.wbsId]

export const getWbsPathMapSelector = (state: RootState) => state.wbs.wbsPathMap

export const getWbsListSelector = (state: RootState) => state.wbs.wbsList

export const getWbsMapParentsSelector = (state: RootState) =>
  state.wbs.wbsMapParent

export const getWbsProjectCode = (state: RootState) => state.wbs.ProjectCode

export const getEvent = (state: RootState) => state.wbs.event
