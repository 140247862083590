import { createStandardAction, createAsyncAction } from 'typesafe-actions'

import {
  GetModelListReq,
  GetModelListApi,
  GetItemForModelReq,
  GetItemForModelApi,
  DeleteModelReq,
  ModelListItem,
  ModelImportReq,
  PostApiResp,
} from './types'

export const reset = createStandardAction('admin/model/RESET_ACTION')<any>()

export const getModelListReq = createAsyncAction(
  'admin/model/GET_MODEL_LIST_REQUEST',
  'admin/model/GET_MODEL_LIST_SUCCESS',
  'admin/model/GET_MODEL_LIST_FAILURE'
)<GetModelListReq, GetModelListApi, any>()

export const deleteModelReq = createAsyncAction(
  'admin/model/DELETE_MODEL_LIST_REQUEST',
  'admin/model/DELETE_MODEL_LIST_SUCCESS',
  'admin/model/DELETE_MODEL_LIST_FAILURE'
)<{ de: DeleteModelReq; pagination: GetModelListReq }, void, any>()

export const getItemForModelReq = createAsyncAction(
  'admin/model/GET_ITEM_MODEL_LIST_REQUEST',
  'admin/model/GET_ITEM_MODEL_LIST_SUCCESS',
  'admin/model/GET_ITEM_MODEL_LIST_FAILURE'
)<GetItemForModelReq, GetItemForModelApi, any>()

export const setSelectedItem = createStandardAction(
  'admin/model/SET_SELECTED_MODEL'
)<ModelListItem | undefined>()

export type UpdateFieldPayload = {
  value: any
}

export const updateData = createStandardAction('admin/model/UPDATE_FIELD')<
  UpdateFieldPayload
>()

export const submitModelReq = createAsyncAction(
  'admin/model/SUBMIT_CHECKLIST_REQUEST',
  'admin/model/SUBMIT_CHECKLIST_SUCCESS',
  'admin/model/SUBMIT_CHECKLIST_FAILURE'
)<any, void, any>()

export const importModel = createAsyncAction(
  'admin/model/IMPORT_MODEL_REQUEST',
  'admin/model/IMPORT_MODEL_SUCCESS',
  'admin/model/IMPORT_MODEL_FAILURE'
)<ModelImportReq, PostApiResp, any>()
