import React, { useState } from 'react'
import styled from 'styled-components'
import { Collapse, Row, Col } from 'antd'
import arrowUp from 'assets/img/ic-up.png'
import arrowDown from 'assets/img/ic-down.png'
import { CheckListStatus } from 'features/cmpj/activity/types'
import RemarkBox from './RemarkBox'
import StatusIcon from './StatusIcon'
import { InspectionSubData } from 'features/types/checklist'

const { Panel } = Collapse

type Props = {
  index: number
  item: InspectionSubData
  onChange: (val: InspectionSubData) => void
  remarkStatus?: any[]
}

const InspectionSubItem: React.FC<Props> = (props: Props) => {
  const { item, remarkStatus } = props

  const arrow = (panelProps: any) => {
    return (
      <img
        src={panelProps.isActive ? arrowUp : arrowDown}
        width="16px"
        height="16px"
      />
    )
  }

  const onChangeStatus = (value: string) => {
    item.Status = value
    props.onChange(item)
  }

  const onChangeRemark = (value: string) => {
    item.Remark = value
    props.onChange(item)
  }

  const subitem = (
    <ChecklistItem className="check-list-item">
      <Row type="flex" justify="space-between" align="middle">
        <Col span={19}>
          <TextSubItem>- {item.Name}</TextSubItem>
        </Col>
        <Col
          onClick={() => {
            if (item.Status === CheckListStatus.NOTCHECK) {
              onChangeStatus(CheckListStatus.PASS)
            }
          }}
        >
          <StatusIcon
            status={item.Status ? item.Status : CheckListStatus.NOTCHECK}
          />
        </Col>
      </Row>
    </ChecklistItem>
  )

  return (
    <Collapse
      expandIconPosition="right"
      bordered={false}
      className="checklist"
      expandIcon={panelProps => arrow(panelProps)}
    >
      <Panel header={subitem} key={props.index} style={{ padding: 0 }}>
        <SubGroupItem>
          <RemarkBox
            status={item.Status}
            remark={item.Remark}
            remarkStatus={remarkStatus}
            onChangeStatus={status => onChangeStatus(status)}
            onChangeRemark={remark => onChangeRemark(remark)}
          />
        </SubGroupItem>
      </Panel>
    </Collapse>
  )
}

const ChecklistItem = styled.div`
  padding: 16px 81px 16px 0;
  margin: 0 16px 0 24px;
  border-bottom: 1px solid #eeeff2;
  height: 63px;
`

const TextSubItem = styled.span`
  font-size: 20px;
  color: #000;
  padding-left: 15px;
`

const SubGroupItem = styled.div`
  padding-left: 24px;
`

export default InspectionSubItem
