import { ActionType } from 'typesafe-actions'
import { Moment } from 'moment-timezone'
import * as actions from './actions'
import { Event } from 'features/types/event'

export type DashboardAction = ActionType<typeof actions>

export interface StoreState {
  data: DashboardData
  originalData: DashboardData
  event: Event
  query: DashboardQuery
  sorting: any
  filter: Filter
}

export type Todo = {
  CheckListID: number
  CheckListName: string
  CheckListType: string
  ActivityID: string
  ActivityName: string
  Progress: number
  LastCurProgress: number
  LastReqProgress: number
  State: 'MC' | 'CM' | 'PJPO'
  TaskStatus: 'INIT' | 'INPRO' | 'APPV' | 'REJT'
  BaselineStartDate: string
  BaselineFinishDate: string
  StartDate: string
  FinishDate: string
  ActualStartDate: string
  ActualFinishDate: string
}

export type InProgress = {
  RequestID: number
  ActivityID: string
  ActivityName: string
  CheckListID: number
  CheckListName: string
  CheckListType: string
  CommissioningType: string
  CheckNumber: number
  AppointmentDate: string
  State: 'CM' | 'QC'
  Status: 'INIT' | 'INPRO' | 'APPV' | 'REJT'
}

export type FixFromQC = {
  RequestID: number
  ActivityID: string
  ActivityName: string
  CheckListID: number
  CheckListName: string
  CheckListType: string
  CommissioningType: string
  CheckStatus: 'Pass' | 'Fail'
  NumOfPass: number
  NumOfFail: number
  CheckNumber: number
  State: 'CM' | 'QC'
  Status: 'INIT' | 'INPRO' | 'APPV' | 'REJT'
}

export interface GetDashboardReq {
  ProjectCode: string
  UserEmail: string
  CommissioningTypeList: number[]
  WbsList: number[]
}

export interface GetActivityApiResponse extends GetApiResponse<DashboardData> {}

export interface GetApiResponse<T> {
  ResponseData: T
  ResponseCode: string
  ResponseMsg: 'Success' | 'Error'
  RequestTime: string
  Responsetime: string
}

export type DashboardData = {
  TotalTodo: number
  Todo: Todo[]
  TotalInProgress: number
  InProgress: InProgress[]
  TotalFixFromQC: number
  FixFromQC: FixFromQC[]
}

export type DashboardEvent = {
  isFetching: boolean
  error: string
}

export type Filter = {
  CommissionTypeList: number[]
  WbsList: number[]
  LeafWbsList: number[]
}

export type DashboardFilter = {}

export type DashboardQuery = {
  date: {
    start: undefined | Moment
    end: undefined | Moment
  }
}

export enum TypeOfTask {
  TODO = 'todo',
  InProgress = 'inProgress',
  FixFromQC = 'fixFromQC',
}
