import RootState from 'typings/RootState'
import { Event } from 'features/types/event'
import { Activity } from './types'

export const selectData = (state: RootState): Activity =>
  state.cmpjConstructRoadChecking.data

export const selectEvent = (state: RootState): Event =>
  state.cmpjConstructRoadChecking.event

export const selectSorting = (state: RootState) =>
  state.cmpjConstructRoadChecking.sorting

export const selectProgress = (state: RootState) =>
  state.cmpjConstructRoadChecking.progress
