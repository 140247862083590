import React from 'react'
import BreadCrumb from './BreadCrumb/BreadCrumb'
import SearchInputBox from '../input/SearchInputBox/SearchInputBox'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { BreadcrumbLink } from '../Shared/BreadCrumb/BreadCrumb'

const getKeyword = (location: any) => {
  if (!location.search) {
    return ''
  }

  const params = new URLSearchParams(location.search)
  return params.get('keyword') || ''
}

interface SubnavBarProps {
  breadcrumbs: BreadcrumbLink[]
  hideSearch?: boolean
  adminSearchBox?: any
  type?: string
}

type Props = SubnavBarProps & RouteComponentProps

const SubnavBar: React.FunctionComponent<Props> = props => {
  const { hideSearch = false, adminSearchBox, type } = props

  const searchItem = hideSearch ? (
    <div />
  ) : (
    <SearchInputBox keyword={getKeyword(props.location)} seeMoreType={type} />
  )
  return (
    <React.Fragment>
      <div className="subnavbar">
        <div className="subnavbar-content">
          <BreadCrumb breadcrumbs={props.breadcrumbs} />
          {searchItem}
          {adminSearchBox && adminSearchBox}
        </div>
      </div>
    </React.Fragment>
  )
}

export default withRouter(SubnavBar)
