import colorMap, { TaskStatusMeta } from './color-map'
import { StatusOfTask, StateOfTask } from 'features/types/status'

export const getStatusText = (status: string): string => {
  const meta = colorMap.get(status) as TaskStatusMeta

  return meta && meta.text
}

export const getMainconByStatus = (state: string, status: string) => {
  let type
  let text
  let color
  if (state === StateOfTask.MC) {
    switch (status) {
      case StatusOfTask.INIT:
        type = 'todo'
        text = 'สิ่งที่ต้องทำ'
        color = '#8b99a7'
        break
      default:
        type = 'in-progress'
        text = 'กำลังดำเนินการ'
        color = '#003168'
    }
  } else {
    switch (status) {
      case StatusOfTask.INIT:
        type = 'requested'
        text = 'ส่งคำขอแล้ว'
        color = '#8b99a7'
        break
      default:
        type = 'cm-checking'
        text = 'CM กำลังตรวจสอบ'
        color = '#003168'
    }
  }

  return { type, text, color }
}

export const getCMByStatus = (state: string, status: string) => {
  let type
  let text
  let color
  if (state === StateOfTask.CM) {
    switch (status) {
      case StatusOfTask.INIT:
        type = 'todo'
        text = 'รายการคำขอ'
        color = '#8b99a7'
        break
      default:
        type = 'in-progress'
        text = 'กำลังดำเนินการ'
        color = '#003168'
    }
  } else if (state === StateOfTask.PJPO) {
    switch (status) {
      case StatusOfTask.INIT:
        type = 'todo'
        text = 'รอดำเนินการ'
        color = '#003168'
        break
      default:
        type = 'done'
        text = 'เสร็จสิ้น'
        color = '#003168'
    }
  } else if (state === StateOfTask.MC) {
    switch (status) {
      case StatusOfTask.INIT:
        type = 'todo'
        text = 'สิ่งที่ต้องทำ'
        color = '#003168'
        break
      default:
        type = 'done'
        text = 'เสร็จสิ้น'
        color = '#003168'
    }
  } else {
    switch (status) {
      case StatusOfTask.INIT:
        type = 'requested'
        text = 'ส่งคำขอแล้ว'
        color = '#8b99a7'
        break
      default:
        type = 'cm-checking'
        text = 'เสร็จสิ้น'
        color = '#003168'
    }
  }

  return { type, text, color }
}

export const getApprovalByStatus = (state: string, status: string) => {
  let type
  let text
  let color
  if (state === StateOfTask.PJPO) {
    switch (status) {
      case StatusOfTask.INIT:
        type = 'todo'
        text = 'รออนุมัติ'
        color = '#8b99a7'
        break
      default:
        type = 'cm-checking'
        text = 'เสร็จสิ้น'
        color = '#003168'
    }
  } else if (state === StateOfTask.MC) {
    type = 'requested'
    text = 'MC กำลังตรวจสอบ'
    color = '#8b99a7'
  } else if (state === StateOfTask.CM) {
    type = 'requested'
    text = 'CM กำลังตรวจสอบ'
    color = '#8b99a7'
  } else {
    type = 'requested'
    text = ''
    color = '#8b99a7'
  }

  return { type, text, color }
}

export const getAdminByStatus = (state: string, status: string) => {
  let type
  let text
  let color
  if (state === StateOfTask.PJPO) {
    switch (status) {
      case StatusOfTask.INIT:
        type = 'in-progress'
        text = 'PJPO-กำลังดำเนินการ'
        color = '#003168'
        break
      default:
        type = 'cm-checking'
        text = 'เสร็จสิ้น'
        color = '#003168'
    }
  } else if (state === StateOfTask.MC) {
    type = 'requested'
    text = 'MC-กำลังดำเนินการ'
    color = '#003168'
  } else if (state === StateOfTask.CM) {
    type = 'requested'
    text = 'CM-กำลังดำเนินการ'
    color = '#003168'
  } else {
    type = 'requested'
    text = ''
    color = '#003168'
  }

  return { type, text, color }
}

export default getStatusText
