import withAPIAction from 'utils/withAPIAction'

import { ReqUpdateActivityProgress } from './types'
import { verifyReq } from 'utils/verify'
import { updateDataAction } from './actions'

export const updateActivityProgress = (
  token: string,
  data: ReqUpdateActivityProgress
) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<ReqUpdateActivityProgress>({
    method: 'POST',
    path: 'UpdateProgressWithExcel',
    data,
    token: `Bearer ${token}`,
  })(updateDataAction)
}
