import React, { useState } from 'react'
import { Row } from 'antd'
import styled from 'styled-components'

import ErrorMessage from '../../../Shared/ErrorMessage'
import { translate } from 'i18n'
import TKeys from 'i18n/translationKey'
import { LIMIT_FILE } from 'config'
import file from 'utils/file'
import Picker from '../../../Shared/Form/Picker'
import Editor from '../Editor'

type DefaultProps = {
  activity: any
  original: any
  onChange: (item: any) => void
  onImageOpen: () => void
  onThumbnailOpen: (index: number) => void
  disabled: boolean
}
type Props = DefaultProps

const HandleImage: React.FC<Props> = ({
  activity,
  original,
  onChange,
  onImageOpen,
  onThumbnailOpen,
  disabled,
}) => {
  const [showEditor, setShowEditor] = useState(false)
  const [fileEditor, setFileEditor] = useState({
    base64: '',
    file: { name: '' },
    lastModified: 0,
  })
  const [loading, setLoading] = useState(false)
  const [showError, setShowError] = useState(false)
  const [error, setError] = useState('')

  const addFile = async (base64: any, fileItem: any) => {
    try {
      const lastModified = fileItem.lastModified
      setLoading(true)
      let resp
      if (fileItem.type === 'image/heic') {
        resp = await file.heicToJpeg({
          file: fileItem,
          filename: fileItem.name,
        })
        base64 = resp.text
        fileItem = resp.file
      }

      if (fileItem.size < LIMIT_FILE.image) {
        resp = await file.compress({
          value: base64,
          file: fileItem,
          limit: LIMIT_FILE.image,
        })
        base64 = resp.text
        fileItem = resp.file
      }
      // implement to handle multiple upload
      // setShowEditor(false)
      // setFileEditor({ base64, file: fileItem, lastModified })

      setShowEditor(false)
      setFileEditor({ base64, file: fileItem, lastModified })
      await onAddImage(base64, fileItem.name, lastModified)
    } catch (e) {
      setError(translate(TKeys.Error.file_wrong))
      setShowError(true)
    }

    setLoading(false)
  }

  const onAddImage = async (
    base64: string,
    fileName: string = '',
    lastModified: number = 0
  ) => {
    try {
      const params = {
        data: base64,
        filename: fileName,
        lastModified: lastModified,
      }
      const fileItem: any = await file.base64ToFile(params)
      original.Images.unshift({
        text: base64,
        file: fileItem,
        lastModified: fileItem.lastModified,
      })
      onChange({ ...original })
      setFileEditor({
        base64: '',
        file: { name: '' },
        lastModified: 0,
      })
    } catch (e) {
      setError(e.message)
      setShowError(true)
    }
    setShowEditor(false)
  }

  const renderImg = (file: any, size: number = 0, index: number = 0) => {
    if (size > 0) {
      return (
        <ImgBlock onClick={onImageOpen}>
          <ImageMoreItem src={file.text} />
          <span className="std-font-bold">+{size}</span>
        </ImgBlock>
      )
    } else {
      return (
        <ImgBlock onClick={() => onThumbnailOpen(index)}>
          <ImageItem src={file.text} />
        </ImgBlock>
      )
    }
  }

  const text = translate(TKeys.ActivityDetail.picture)

  let content
  let moreContent
  const size = original.Images.length

  if (size > 0) {
    content = renderImg(original.Images[0])

    if (size > 1) {
      let more = size - 2
      moreContent = renderImg(original.Images[1], more)
    }
  }

  return (
    <>
      <Editor
        visible={showEditor}
        image={fileEditor.base64}
        onClose={() => setShowEditor(false)}
        onOk={onAddImage}
      />
      <div className="form-label">
        {text}
        <SubTitle>{translate(TKeys.ActivityDetail.picture_note)}</SubTitle>
      </div>
      <Row>
        <ImgLine>
          <Picker
            disabled={size === 5 || disabled}
            loading={loading}
            text={text}
            accept=".png,.jpg,.heic"
            onChange={addFile}
            isMultiple={true}
          />

          {content}
          {moreContent}
        </ImgLine>
      </Row>
      <ErrorMessage
        visible={showError}
        text={error}
        onClose={() => setShowError(false)}
      />
    </>
  )
}

const ImgLine = styled.div`
  display: inline-flex;
`

const ImgBlock = styled.div`
  position: relative;
  text-align: center;
  span {
    color: white;
    font-size: 28px;
    position: absolute;
    top: 55px;
    left: 55px;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }
`

const SubTitle = styled.span`
  color: #8b99a7;
  font-size: 16px;
  margin-left: 8px;
`

const CssImg = `
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-left: 8px;
  border-radius: 3px;
`
const ImageItem = styled.img`
  ${CssImg}
`

const ImageMoreItem = styled.img`
  ${CssImg}
  filter: brightness(0.4);
  :hover {
    cursor: pointer;
  }
`

export default HandleImage
