import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import ReportCommissioning from 'Components/AdminQC/Report/Zone/Commissioning/List'
import ReportCommissioningDetail from 'Components/AdminQC/Report/Zone/Commissioning/Detail'
import ReportCommonArea from 'Components/AdminQC/Report/Zone/CommonArea/List'
import ReportCommonAreaDetail from 'Components/AdminQC/Report/Zone/CommonArea/Detail'
import ReportByUnitList from 'Components/AdminQC/Report/Unit/ByUnit/List'
import ReportByUnitDetail from 'Components/AdminQC/Report/Unit/ByUnit/Detail'
import ReportByUnitDocumentDetail from 'Components/AdminQC/Report/Unit/ByUnit/DocumentDetail'
import ReportUnitByQCType from 'Components/AdminQC/Report/Unit/ByQCType/List'

import ReportSiteVisitConstructList from 'Components/AdminQC/Report/SiteVisit/Construct/List'
import ReportSiteVisitConstructDetail from 'Components/AdminQC/Report/SiteVisit/Construct/Detail'

import ReportSiteVisitAuditList from 'Components/AdminQC/Report/SiteVisit/Audit/List'
import ReportSiteVisitAuditDetail from 'Components/AdminQC/Report/SiteVisit/Audit/Detail'

import ReportRoadConstructList from 'Components/AdminQC/Report/Road/Construct/List'
import ReportRoadConstructDetail from 'Components/AdminQC/Report/Road/Construct/Detail'

import ReportRoadAuditList from 'Components/AdminQC/Report/Road/Audit/List'
import ReportRoadAuditDetail from 'Components/AdminQC/Report/Road/Audit/Detail'

import ReportPerformanceList from 'Components/AdminQC/Report/Performance/List'

import ReportSQDByProject from 'Components/AdminQC/Report/SQD/ByProject/List'
import ReportSQDByMaincon from 'Components/AdminQC/Report/SQD/ByMaincon/List'

import ReportCertificateList from 'Components/AdminQC/Report/Zone/Certificate/List'
import ReportCertificateDetail from 'Components/AdminQC/Report/Zone/Certificate/Detail'

import ReportScore from 'Components/AdminQC/Report/Score'

import ReportDailyList from 'Components/AdminQC/Report/Daily/List'
import ReportMonthly from 'Components/AdminQC/Report/Monthly'
import TokenExpired from 'Components/Pages/TokenExpired'

type Props = {}

const AdminQCReportApp: React.FunctionComponent<Props> = () => {
  return (
    <Switch>
      <Route
        exact
        path="/report/commissioning"
        component={ReportCommissioning}
      />
      <Route
        exact
        path="/report/commissioning/:projectCode/:id/:checkNo/:requestID"
        component={ReportCommissioningDetail}
      />

      <Route exact path="/report/zone" component={ReportCommonArea} />
      <Route
        exact
        path="/report/zone/:projectCode/:unit/:floor/:qcTypeID/:checkNo/:requestID"
        component={ReportCommonAreaDetail}
      />

      <Route exact path="/report/cert" component={ReportCertificateList} />
      <Route
        exact
        path="/report/cert/:projectCode/:mcvendorcode"
        component={ReportCertificateDetail}
      />

      <Route exact path="/report/unit" component={ReportByUnitList} />
      <Route
        exact
        path="/report/unit/:projectCode/:unit/:qcTypeID/:checkNo/:requestID/:checkedByRole"
        component={ReportByUnitDetail}
      />

      <Route
        exact
        path="/report/unitdocument/:projectCode/:unit/:qcTypeID/:checkNo/:requestID"
        component={ReportByUnitDocumentDetail}
      />

      <Route exact path="/report/unitbyqc" component={ReportUnitByQCType} />

      <Route
        exact
        path="/report/constructplan"
        component={ReportSiteVisitConstructList}
      />
      <Route
        exact
        path="/report/constructplan/:projectCode/:checkNo"
        component={ReportSiteVisitConstructDetail}
      />

      <Route
        exact
        path="/report/auditplan"
        component={ReportSiteVisitAuditList}
      />
      <Route
        exact
        path="/report/auditplan/:projectCode/:checkNo"
        component={ReportSiteVisitAuditDetail}
      />

      <Route
        exact
        path="/report/constructroad"
        component={ReportRoadConstructList}
      />

      <Route
        exact
        path="/report/constructroad/:projectCode/:unit/:qcTypeID/:checkNo/:requestID"
        component={ReportRoadConstructDetail}
      />

      <Route exact path="/report/auditroad" component={ReportRoadAuditList} />
      <Route
        exact
        path="/report/auditroad/:projectCode/:checkNo/:requestID"
        component={ReportRoadAuditDetail}
      />

      <Route
        exact
        path="/report/performance"
        component={ReportPerformanceList}
      />

      <Route exact path="/report/sqdbyproject" component={ReportSQDByProject} />
      <Route exact path="/report/sqdbymaincon" component={ReportSQDByMaincon} />

      <Route exact path="/report/score" component={ReportScore} />

      <Route exact path="/report/daily" component={ReportDailyList} />

      <Route exact path="/report/monthly" component={ReportMonthly} />

      <Route exact path="/token-expired" component={TokenExpired} />
    </Switch>
  )
}

export default AdminQCReportApp
