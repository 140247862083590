import { sortingList, sortingByMultiParams } from '../../../utils/helper'
import { DataItem } from './types'

export const sortingActivityList = (
  list: any[],
  type: string,
  name: string | undefined
): any[] => {
  try {
    let newList: DataItem[] = list
    switch (name) {
      case 'InspectionScore':
      case 'DucumentScore':
      case 'Score':
      case 'DefectCounter':
      case 'TotalScore':
      case 'PercentOfDefect':
        newList = sortingList(list, type, name, 'number')
        break
      case 'InspectDate':
        newList = sortingList(list, type, name, 'date')
        break
      case 'CheckNumber':
        const params = ['CheckByModule', 'CheckNumber']
        newList = sortingByMultiParams(list, type, params)
        break
      default:
        newList = sortingList(list, type, name || '', 'string')
    }

    return newList
  } catch (e) {
    return list
  }
}
