import React from 'react'
import { Col } from 'antd'
import styled from 'styled-components'
import { TableRow } from 'Components/Shared/Table'
import { ReportLink as Link } from 'Components/AdminQC/Report/Components'
import { DataItem } from 'features/adminReport/commissioning/types'
import { StatusTag } from 'Components/AdminQC/Report/Components'

type DefaultProps = {
  item: DataItem
}

type Props = DefaultProps

const RowItem: React.FC<Props> = ({ item }) => {
  const path = `/report/commissioning/${item.ProjectCode}/${item.ActivityID}/${item.CheckNumber}/${item.RequestID}`

  return (
    <RowModel>
      <Link to={path}>
        <TableRow minHeight={60} spaceBetweenRow={8} paddingLeft={24}>
          <Col span={5}>
            <Bold>
              {item.ProjectCode}-{item.ProjectNameTH}
            </Bold>
          </Col>
          <Col span={5}>
            <TxtReg>{item.ActivityName}</TxtReg>
          </Col>
          <Col span={3}>
            <TxtReg>{item.CommissioningType}</TxtReg>
          </Col>
          <Col span={2}>
            <TxtReg>{item.CheckNumber}</TxtReg>
          </Col>
          <Col span={3}>
            <TxtReg>{item.CheckedDate}</TxtReg>
          </Col>
          <Col span={2}>
            <TxtReg>
              {' '}
              <StatusTag
                text={item.CheckStatus === 'Pass' ? 'ผ่าน' : 'ไม่ผ่าน'}
                isPass={item.CheckStatus === 'Pass'}
              />
            </TxtReg>
          </Col>
          <Col span={4}>
            <TxtReg>{item.CheckedBy}</TxtReg>
          </Col>
        </TableRow>
      </Link>
    </RowModel>
  )
}

const RowModel = styled.div`
  cursor: pointer;
`

const Bold: any = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  color: #000;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  padding-right: 5px;
`

const TxtReg = styled(Bold)`
  font-family: 'DB Helvethaica X 55 Regular';
`

export default RowItem
