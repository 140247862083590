import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import RootState from 'typings/RootState'
import styled from 'styled-components'
import { Row, Col } from 'antd'
import { RouteComponentProps } from 'react-router-dom'
import { DropdownList } from 'Components/Shared/Form'
import { BreadcrumbLink } from 'Components/Shared/BreadCrumb/BreadCrumb'
import SubnavBar from 'Components/Shared/SubnavBar'
import { TableHeader, TableColHeader } from 'Components/Shared/Table'
import LoadingView from 'Components/Shared/LoadingView'
import Pagination from 'Components/Shared/Pagination'
import PrimaryButton from 'Components/Shared/Button/PrimaryButton'
import { toCheckList } from 'importer/maincon'
import ImportBtn from 'Components/Shared/Table/ImportBtn'
import exportFromJSON from 'export-from-json'
import { SecondaryButton } from 'Components/Shared/Button'

import {
  getMasterCheckList,
  getTemplateCodeList,
} from 'features/checklist/master/api'
import {
  deleteCheckListByIdReq,
  updateImport,
} from 'features/checklist/master/actions'

import {
  selectCheckList,
  selectEvent,
  selectGetTemplateCodeList,
} from 'features/checklist/master/selector'
import { CheckListItem } from 'features/checklist/master/types'
import { AlertError } from 'Components/Shared'
import Menu from '../../../MenuAdmin'
import RowItem from './RowItem'
import { SearchInput } from 'Components/Filter'

const breadcrumbs: BreadcrumbLink[] = [
  {
    url: '/dashboard',
    title: 'Home',
    icon: '/home.png',
    active: true,
    showTitle: false,
  },
  {
    url: '/master-checklist',
    title: 'Master Checklist mainCon',
    active: false,
    showTitle: false,
  },
]

type Props = DispatchProps & RouteComponentProps & ReduxProps

const MasterCheckList: React.ComponentType<Props> = props => {
  const { checklist, event, history, tempList } = props
  const [enableModal, setEnableModal] = useState(false)
  const [err, setErr] = useState(undefined)
  const dispatch = useDispatch()
  const [searchText, setSearchText] = useState('')
  const [TempCodeFilter, setTempCodeFilter] = useState('')
  const [isExport, setIsExport] = useState(false)

  const [pagination, setPagination] = useState({
    Page: 1,
    RowPerPage: 10,
  })

  useEffect(() => {
    setEnableModal(true)
    dispatch(
      getMasterCheckList({
        Page: pagination.Page,
        RowPerPage: pagination.RowPerPage,
        TextFilter: searchText,
        TemplateCode: TempCodeFilter,
      })
    )
  }, [pagination, TempCodeFilter])

  useEffect(() => {
    setEnableModal(true)
    dispatch(getTemplateCodeList())
  }, [])

  const onDelete = (id: string) => {
    setEnableModal(true)
    dispatch(
      deleteCheckListByIdReq.request({
        CheckListID: id,
        getReq: {
          Page: pagination.Page,
          RowPerPage: pagination.RowPerPage,
          TemplateCode: TempCodeFilter,
          TextFilter: searchText,
        },
        history,
      })
    )
  }

  const onImportFile = (data: any) => {
    try {
      const checklist = toCheckList(data)
      dispatch(updateImport(checklist))
      props.history.push('/master-checklist/create')
    } catch (e) {
      setErr(e.message)
    }
  }

  useEffect(() => {
    if (!event.IsLoading && isExport) {
      const data: any = {
        data: checklist.List,
        fileName: 'Maincon-Checklist',
        exportType: 'csv',
        withBOM: true,
      }
      exportFromJSON(data)
      setIsExport(false)
      setEnableModal(true)
      dispatch(
        getMasterCheckList({
          Page: pagination.Page,
          RowPerPage: pagination.RowPerPage,
          TextFilter: searchText,
          TemplateCode: TempCodeFilter,
        })
      )
    }
  }, [event.IsLoading])

  const content = checklist.List.map((item: CheckListItem, index: number) => {
    return <RowItem item={item} onDelete={onDelete} key={index} />
  })

  const clearFilter = () => {
    setSearchText('')
    setTempCodeFilter('')
  }

  const hideModal = () => setEnableModal(false)
  const header = (
    <Header>
      <Row type="flex" justify="space-between" align="middle">
        <Col>
          <Row type="flex" justify="start" align="middle">
            Maincon checklist
            <Margin>
              <DropdownList
                reset={true}
                label="Filter"
                value={TempCodeFilter}
                menu={tempList?.map((item: any) => {
                  return {
                    value: item.TemplateCode,
                    name: item.TemplateCode,
                  }
                })}
                onChange={(value: any) => {
                  setTempCodeFilter(value)
                }}
                onClear={(value: any) => {
                  setTempCodeFilter('')
                }}
              />
            </Margin>
          </Row>
        </Col>

        <Col>
          {TempCodeFilter != '' && (
            <ButtonSection>
              <PrimaryButton
                text="Export.csv"
                onClick={() => {
                  setEnableModal(true)
                  dispatch(
                    getMasterCheckList({
                      Page: 1,
                      RowPerPage: checklist.Total,
                      TextFilter: searchText,
                      TemplateCode: TempCodeFilter,
                    })
                  )
                  setIsExport(true)
                }}
              />
            </ButtonSection>
          )}
          <ButtonSection>
            <ImportBtn text="Import checklist" onChange={onImportFile} />
          </ButtonSection>
          <ButtonSection>
            <PrimaryButton
              text="เพิ่ม checklist"
              onClick={() => history.push('./master-checklist/create')}
            />
          </ButtonSection>
        </Col>
      </Row>
    </Header>
  )

  const onSearch = (val: string) => {
    if (searchText === '' && val === '') return
    setSearchText(val)
    pagination.Page = 1
    setPagination({ ...pagination })
  }

  return (
    <>
      <LoadingView isShow={event.IsLoading} />
      <AlertError
        text={event.SubmitError}
        isShow={!!event.SubmitError && enableModal}
        callback={hideModal}
      />
      <AlertError
        isShow={err !== undefined}
        text={err}
        callback={() => setErr(undefined)}
      />
      <SubnavBar
        breadcrumbs={breadcrumbs}
        hideSearch={true}
        adminSearchBox={<SearchInput onSearch={onSearch} />}
      />
      <Menu selected="master-checklist">
        <MainPage>
          {header}
          <TableHeader>
            <TableColHeader span={2} isPadding={true}>
              Checklist ID{' '}
            </TableColHeader>
            <TableColHeader span={7} isPadding={true}>
              Checklist name
            </TableColHeader>
            <TableColHeader span={1}></TableColHeader>
            <TableColHeader span={2}>รายการตรวจ</TableColHeader>
            <TableColHeader span={3}>QC Require เป็นเอกสารตรวจ</TableColHeader>
            <TableColHeader span={3}>Template Code</TableColHeader>
            <TableColHeader span={4}>Procurement Task ID</TableColHeader>
            <TableColHeader sort={false} span={2}>
              Action
            </TableColHeader>
          </TableHeader>
          {content}
          <Pagination
            currentPage={pagination.Page}
            totalRows={checklist.Total}
            perPage={pagination.RowPerPage}
            onChangeNumber={(page: number) => {
              pagination.RowPerPage = page
              setPagination({ ...pagination })
            }}
            onClickPage={(page: number) => {
              pagination.Page = page
              setPagination({ ...pagination })
            }}
          />
        </MainPage>
      </Menu>
    </>
  )
}

const MainPage = styled.div`
  padding: 40px 45px;
  width: 100%;
`

const ButtonSection = styled.div`
  float: left;
  margin-left: 24px;
  width: 178px;
`

const Margin = styled.div`
  margin: 20px;
`

const Header = styled.div`
  margin-bottom: 35px;
  font-size: 28px;
  line-height: 1.07;
  font-family: 'DB Helvethaica X 75 Bd';
  color: #000;
  height: 50px;
`
const ClearTxt = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  color: #003168;
  font-size: 18px;
  text-decoration: underline;
  cursor: pointer;
`

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapStateToProps = (state: RootState) => {
  return {
    checklist: selectCheckList(state),
    event: selectEvent(state),
    tempList: selectGetTemplateCodeList(state),
  }
}

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(MasterCheckList)
