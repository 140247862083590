import React, { useState } from 'react'
import { Col } from 'antd'
import styled from 'styled-components'

import { TableRow } from '../../Shared/Table'

import { CheckedIcon, UncheckedIcon } from 'Components/Shared/Icon'
import { TypeOfLane } from 'features/maincon/dashboard/types'
import { Data } from 'features/cmpj/seemoreQc/types'
import { getQCTypeVersion } from 'utils/getText'

type DefaultProps = {
  type?: string
  task: Data
  isSelected?: boolean
  onClick?: () => void
}
type Props = DefaultProps

const RowItem: React.FC<Props> = props => {
  const {
    type = TypeOfLane.TODO,
    task,
    isSelected = false,
    onClick = () => {},
  } = props

  const isComplete = task.PassedSubTasks === task.TotalSubTasks

  const getStatus = () => {
    if (isComplete) {
      return <TextGreen>พร้อมตรวจด้วยตัวเอง</TextGreen>
    } else {
      return 'กำลังดำเนินการ'
    }
  }

  const getRadioBox = () => {
    return (
      <div
        onClick={evt => {
          evt.preventDefault()
          onClick()
        }}
      >
        {isSelected ? <CheckedIcon size={24} /> : <UncheckedIcon size={24} />}
      </div>
    )
  }

  const renderScore = (score: number) => {
    if (score > task.BasePoint) {
      return <TextGreen>{score} คะแนน</TextGreen>
    } else {
      return <TextRed>{score} คะแนน</TextRed>
    }
  }

  const todoTask = (
    <>
      <Col span={7}>{task.Unit}</Col>
      <Col span={5}>{getQCTypeVersion(task)}</Col>
      <Col span={5}>
        {task.PassedSubTasks}/{task.TotalSubTasks}
      </Col>
      <Col span={5}>{getStatus()}</Col>
      <Col span={2}>{isComplete && getRadioBox()}</Col>
    </>
  )

  const inprogressTask = (
    <>
      <Col span={8}>{task.Unit}</Col>
      <Col span={8}>{getQCTypeVersion(task)}</Col>
      <Col span={6}>{renderScore(task.InspectionScore)}</Col>
      <Col span={2}>{getRadioBox()}</Col>
    </>
  )

  const renderContent = () => {
    if (type === TypeOfLane.TODO) {
      return todoTask
    } else {
      return inprogressTask
    }
  }

  return (
    <TableRow minHeight={60} paddingLeft={24} spaceBetweenRow={8}>
      {renderContent()}
    </TableRow>
  )
}

const TextGreen = styled.div`
  color: #5bc79e;
`

const TextRed = styled.div`
  color: #d0494c;
`

export default RowItem
