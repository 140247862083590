import React, { useState, useRef, useEffect } from 'react'
import memoize from 'memoize-one'
import styled from 'styled-components'
import { Row } from 'antd'

import ContentBox from 'Components/Shared/Card/ContentBox'
import {
  CheckListDetail,
  ProjectListItem,
} from 'features/checklist/auditRoad/types'
import { ProjectCheckList } from 'features/types/checklist'
import { AddList, EditDeleteButton } from 'Components/Shared/Button'
import ProjectSelector, {
  ProjectModalHandles,
} from 'Components/AdminQC/Modal/ProjectSelector'
import { textToLowerCase } from 'utils/format'

type Props = {
  projects: ProjectCheckList[]
  showDeleteProject: (it: ProjectListItem) => void
  checkListDetail: CheckListDetail
  updateCheckList: (val: CheckListDetail) => void
}

const filterProject = memoize((list, filterText) => {
  return list.filter((item: ProjectListItem) => {
    if (filterText === '') return true
    const filterLower = textToLowerCase(filterText)
    return (
      textToLowerCase(item.ProjectCode).includes(filterLower) ||
      textToLowerCase(item.ProjectName).includes(filterLower)
    )
  })
})

const ProjectsBox: React.ComponentType<Props> = ({
  projects,
  checkListDetail,
  showDeleteProject,
  updateCheckList,
}) => {
  const modalRef = useRef<ProjectModalHandles>(null)
  const [filterText, setfilterText] = useState<string>('')

  const list = checkListDetail.ProjectList.filter(item => item.Selected !== 'N')
  const filterSelectProject: ProjectListItem[] = filterProject(list, filterText)

  const showSelectProjectModal = () => {
    if (modalRef.current) {
      modalRef.current.showModal()
    }
  }

  const selectProjectModal = (list: any[]) => {
    checkListDetail.ProjectList = list
    updateCheckList(checkListDetail)
  }

  function onSearch(text: string) {
    setfilterText(text)
  }

  const showDeleteModal = (it: ProjectListItem) => {
    showDeleteProject(it)
  }

  const isReadonly = (projectCode: string) => {
    const projectItem = projects.find(item => item.ProjectCode === projectCode)

    return projectItem ? projectItem.Status === 'Used' : false
  }

  console.log('checkListDetail', checkListDetail)

  return (
    <ContentBox
      title={`โครงการที่ใช้งาน (${list.length})`}
      emptyText="กรุณาเลือก “ประเภทโครงการ”"
      onSearch={onSearch}
    >
      <ListSection>
        {filterSelectProject.map((item: ProjectListItem) => {
          const deleteBtn = isReadonly(item.ProjectCode)
            ? undefined
            : () => showDeleteModal(item)
          return (
            <List key={item.ProjectCode}>
              <ListText>
                <Row type="flex" justify="space-between" className="row-item">
                  <div className="text-projectcode">
                    <span>{`${item.ProjectCode} - ${item.ProjectName}`}</span>
                  </div>
                  <EditDeleteButton showDeleteModal={deleteBtn} />
                </Row>
              </ListText>
            </List>
          )
        })}
        <AddList onClick={showSelectProjectModal} />
      </ListSection>

      <ProjectSelector
        ref={modalRef}
        CheckListID={checkListDetail.CheckListID}
        projectList={projects}
        selectList={checkListDetail.ProjectList}
        onOk={selectProjectModal}
      />
    </ContentBox>
  )
}

const ListSection = styled.div`
  margin-top: 16px;
  height: 256px;
  overflow: auto;
  padding: 0 8px;
`

const List = styled.div`
  font-size: 20px;
  width: 100%;
  cursor: pointer;
`

const ListText = styled.div`
  border-bottom: 1px solid #e8ebf0;
  height: 100%;
  margin: 0 16px;
  position: relative;

  .text-projectcode {
    padding: 13px 0;
    width: 100%;
  }
  .icon-action {
    position: absolute;
    right: 0px;
    top: 5px;
    padding: 10px 0;
  }
`

export default ProjectsBox
