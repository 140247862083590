import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import {
  CheckListDetail,
  DefectType,
} from 'features/checklist/constructPlan/types'

import ContentBox from 'Components/Shared/Card/ContentBox'
import { AddList, EditDeleteButton } from 'Components/Shared/Button'
import DeleteCheckListJobModal, {
  DeleteWithCheckHandles,
} from 'Components/Shared/Modal/DeleteWithCheckModal'
import DefectModal, { ModalDefectHandles } from './Modal/DefectModal'
import { RowItem } from 'Components/AdminQC/Components'

interface Props {
  checkList: CheckListDetail
  disabled: boolean
}

const DefectBox: React.ComponentType<Props> = ({ checkList, disabled }) => {
  const modalRef = useRef<DeleteWithCheckHandles>(null)
  const modalDefectRef = useRef<ModalDefectHandles>(null)
  const [_, setcounter] = useState(0)
  let content: JSX.Element[] = []

  const showModal = (index: number, item?: DefectType) => {
    if (modalDefectRef.current) {
      modalDefectRef.current.showModal(index, item)
    }
  }

  const onDelete = (index: number) => {
    checkList.DefectTypeList.splice(index, 1)
    setcounter(prev => ++prev)
  }

  const onAdd = (item: DefectType, index: number) => {
    if (index !== -1) {
      checkList.DefectTypeList[index] = item
    } else {
      checkList.DefectTypeList.push(item)
    }
    setcounter(prev => ++prev)
  }

  if (checkList) {
    content = checkList.DefectTypeList.map((it: DefectType, index: number) => {
      return (
        <RowItem
          key={index}
          title={it.DefectTypeName}
          right={
            <EditDeleteButton
              showEditeModal={() => showModal(index, it)}
              showDeleteModal={() => onDelete(index)}
              disableDelete={disabled}
            />
          }
        />
      )
    })
  }

  return (
    <>
      <ContentBox title="ลักษณะ Defect" isEmpty={false}>
        <ListSection>
          {content}
          {!disabled && <AddList onClick={() => showModal(-1)} />}
        </ListSection>
      </ContentBox>
      <DefectModal
        ref={modalDefectRef}
        checkList={checkList}
        onOk={onAdd}
        disabled={disabled}
      />
      <DeleteCheckListJobModal ref={modalRef} onOk={onDelete} />
    </>
  )
}

const ListSection = styled.div`
  margin-top: 16px;
  height: 256px;
  overflow: auto;
  padding: 0 8px;
  padding-bottom: 30px;
`

export default DefectBox
