import withAPIAction from 'utils/withAPIAction'
import { getDataListAction, updateDataAction } from './actions'
import { GetDataListReq, BackwardUnitReq } from './types'
import { verifyReq } from 'utils/verify'

export const getUnitForResetState = (token: string, data: GetDataListReq) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<GetDataListReq>({
    method: 'POST',
    path: 'GetUnitForResetState',
    data,
    token: `Bearer ${token}`,
  })(getDataListAction)
}

export const updateUnitForResetState = (
  token: string,
  data: BackwardUnitReq
) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<BackwardUnitReq>({
    method: 'POST',
    path: 'UpdateUnitForResetState',
    data,
    token: `Bearer ${token}`,
  })(updateDataAction)
}
