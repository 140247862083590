/* eslint-disable */
import file from 'utils/file'

export const writeImage = async (mode, ctx, position, data) => {
  switch (mode) {
    case 'circle':
      await drawCircle(ctx, position, data)
      break
    case 'rectangle':
      await drawRect(ctx, position, data)
      break
    case 'text':
      await drawText(ctx, position, data)
      break
    case 'draw':
      await drawLine(ctx, position, data)
      break
    case 'sticker':
      await drawSticker(ctx, position, data)
      break
  }
}

const drawCircle = (ctx, position, data) => {
  const { x = 0, y = 0, width, height } = position
  ctx.beginPath()
  ctx.strokeStyle = data.color
  ctx.lineWidth = data.lineSize

  const pointX = x + width / 2
  const pointY = y + height / 2
  ctx.ellipse(
    pointX,
    pointY,
    width / 2 - 3,
    height / 2 - 3,
    Math.PI * 2,
    0,
    Math.PI * 2
  )
  ctx.stroke()
}

const drawRect = (ctx, position, data) => {
  const { x = 0, y = 0, width, height } = position

  ctx.beginPath()
  ctx.strokeStyle = data.color
  ctx.lineWidth = data.lineSize
  let offsetA = 2
  let offsetB = 4
  switch (data.lineSize) {
    case 7:
    case 6:
      offsetA = 4
      offsetB = 8
      break
    case 5:
      offsetA = 3
      offsetB = 6
      break
    default:
  }
  ctx.rect(x + offsetA, y + offsetA, width - offsetB, height - offsetB)
  ctx.stroke()
}

const drawText = (ctx, position, data) => {
  const { x = 0, y = 0, width, height } = position
  ctx.beginPath()
  ctx.fillStyle = data.color

  ctx.font = `${data.fontSize}px 'DB Helvethaica X 55 Regular'`
  const offset = data.fontSize * 0.2
  ctx.fillText(data.value, x + 5, y + data.fontSize - offset)
}

const drawLine = (ctx, position, lineList = []) => {
  for (const item of lineList) {
    if (item === undefined) return

    const { start, setting, list } = item
    const { color, lineSize } = setting
    ctx.beginPath()
    ctx.strokeStyle = color
    ctx.lineWidth = lineSize

    ctx.moveTo(start.x, start.y)

    for (const point of list) {
      ctx.lineTo(point.x, point.y)
    }

    ctx.stroke()
    ctx.closePath()
  }
}

const drawSticker = async (ctx, position, data) => {
  const { x = 0, y = 0, width, height } = position
  const img = await file.base64ToImage(data.image)
  ctx.drawImage(img, x, y, width, height)
}

/* eslint-enable */
