import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { RootState } from 'typings/RootState'
import styled from 'styled-components'
import { getRoleList } from 'features/filter/api'
import { selectRoleItem } from 'features/filter/selector'
import { Col, Row } from 'antd'
import { selectedProjectCodeSelector } from 'features/project/project.selector'
import {
  serviceTokenSelector,
  userEmailSelector,
} from 'features/sensitive/sensitive.selector'
import { selectFilter } from 'features/adminReport/performance/selector'
import { Filter } from 'features/adminReport/performance/types'
import {
  updateFilter,
  clearFilter,
} from 'features/adminReport/performance/actions'
import { DateFilter, ButtonFilter, UserFilter, RoleFilter } from './Components'
import { FindUserReq } from 'features/admin/user/types'
import { findUserList } from 'features/admin/user/api'
import { selectUserList } from 'features/admin/user/selector'

type Props = {
  onSearch?: (isClear?: boolean) => void
  withPadding?: boolean
  onExport?: () => void
  exportData?: any[]
  isDownLoading?: boolean
} & ReduxProps &
  DispatchProps

const PerformanceFilter: React.FunctionComponent<Props> = props => {
  const {
    ProjectCode,
    UserEmail,
    token,
    filter,
    onSearch = () => {},
    withPadding = false,
    userList,
    roleList,
    onExport,
    exportData,
    isDownLoading,
  } = props

  useEffect(() => {
    dispatch(clearFilter())
    onFindUser()
    onGetRole()
    return () => {
      dispatch(clearFilter())
    }
  }, [ProjectCode, UserEmail])

  useEffect(() => {
    onFindUser()
  }, [filter.UserRoleID])

  const onFindUser = () => {
    const req: FindUserReq = {
      UserEmail,
      IsVendor: '',
      TextFilter: '',
    }
    dispatch(findUserList(token, req))
  }

  const onGetRole = () => {
    dispatch(getRoleList())
  }

  const dispatch = useDispatch()
  const onFilterChange = (filter: Filter) => {
    dispatch(updateFilter(filter))
  }

  const onClearSearch = () => {
    dispatch(clearFilter())
    onSearch(true)
  }

  const isFilter = () => {
    return (
      filter.UserID !== '' || filter.UserRoleID > 0 || filter.Date.Mode !== ''
    )
  }

  return (
    <MainPage className={withPadding ? 'with-padding' : ''}>
      <Row type="flex" justify="start">
        <Col span={8}>
          <RoleFilter
            filter={filter}
            onFilterChange={onFilterChange}
            roles={roleList}
          />
        </Col>
        <Col span={8}>
          <UserFilter
            filter={filter}
            onFilterChange={onFilterChange}
            users={userList}
          />
        </Col>
        <Col span={8}>
          <DateFilter filter={filter} onFilterChange={onFilterChange} />
        </Col>
        <Col span={8}>
          <ButtonFilter
            onClearSearch={onClearSearch}
            onSearch={onSearch}
            isFilter={isFilter()}
            onExport={onExport}
            isDownLoading={isDownLoading}
            exportData={exportData}
            fileName="รายงาน Performance"
          />
        </Col>
      </Row>
    </MainPage>
  )
}

const MainPage = styled.div`
  margin-bottom: 24px;
  &.with-padding {
    padding: 0 120px;
  }

  background-color: #f5f6fa;
`

const mapStateToProps = (state: RootState) => {
  return {
    ProjectCode: selectedProjectCodeSelector(state),
    UserEmail: userEmailSelector(state),
    token: serviceTokenSelector(state),
    filter: selectFilter(state),
    userList: selectUserList(state),
    roleList: selectRoleItem(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(PerformanceFilter)
