import React from 'react'

type Props = {
  heading: string
  type: string | undefined
  orderName: string
  onSort(): void
}

const TasklaneColumnHeader: React.FunctionComponent<Props> = ({
  heading,
  type,
  orderName,
  onSort,
}) => {
  let img = '/img/ic-arrow-actions.png'
  if (type === 'desc') {
    img = '/img/arrow-down.png'
  }
  return (
    <div className="tasklane-header">
      <span className="title">{heading}</span>
      <div className="detail">
        <span className="action" onClick={onSort}>
          {orderName}
        </span>
        <span className="arrow-sort" style={{ marginLeft: '8px' }}>
          <img
            src={img}
            width="16"
            height="16"
            alt="arrow icon"
            onClick={onSort}
          />
        </span>
      </div>
    </div>
  )
}

export default TasklaneColumnHeader
