import React from 'react'

type DefaultProps = {
  size?: number
  color?: string
}

const RequireIcon = (props: DefaultProps) => {
  const { size = 20, color = '#d0494c' } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 16 16"
    >
      <path
        fill={color}
        fill-rule="evenodd"
        d="M8 1c3.86 0 7 3.14 7 7s-3.14 7-7 7-7-3.14-7-7 3.14-7 7-7zm0 1.448c-3.062 0-5.552 2.49-5.552 5.552 0 3.062 2.49 5.552 5.552 5.552 3.062 0 5.552-2.49 5.552-5.552 0-3.062-2.49-5.552-5.552-5.552zm0 7.338c.666 0 1.207.54 1.207 1.207 0 .665-.54 1.206-1.207 1.206-.666 0-1.207-.541-1.207-1.206 0-.666.54-1.207 1.207-1.207zM8.155 3.8c.394 0 .715.32.715.715V8.29c0 .395-.32.714-.715.714h-.31c-.395 0-.715-.32-.715-.714V4.516c0-.395.32-.715.714-.715z"
      />
    </svg>
  )
}

export default RequireIcon
