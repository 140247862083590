import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

interface ButtonProps {
  title: string
  href: string
  className?: string
}

const LinkButton: React.FunctionComponent<ButtonProps> = props => {
  const { href, title, ...rest } = props

  return (
    <ItemView>
      <Link to={href} {...rest}>
        {title}
      </Link>
    </ItemView>
  )
}

const ItemView = styled.div`
  width: 100%;
  margin-bottom: 10px;
  a {
    line-height: 60px;
    text-align: center;
    display: block;
    font-family: 'DB Helvethaica X 75 Bd';
    border-radius: 10px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    font-weight: 700;
    font-size: 18px;
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.03);
    background-color: #fff;
    color: #000;
  }
`

export default LinkButton
