import { getType } from 'typesafe-actions'
import { cloneDeep } from 'lodash'
import * as actions from './actions'
import {
  StoreState,
  DocAction,
  DocumentCheckList,
  ActivityIDList,
} from './types'
import produce from 'immer'
import parseErrorMessage from 'utils/parseErrorMessage'

const initChecklist = {
  BaseDocumentPoint: 80,
  SendDocumentStatus: 'N',
  DocumentStatus: 'Pass',
  ActivityList: [],
} as DocumentCheckList

const initEvent = {
  IsLoading: false,
  MessageSuccess: undefined,
  SubmitError: undefined,
  SubmitSuccess: false,
  SubmitType: undefined,
}

const initReq = {
  UserEmail: '',
  ProjectCode: '',
  Unit: '',
  CheckNumber: 0,
  RequestID: 0,
  QCTypeID: '',
  QCTypeVersion: '',
}

const initSelected: ActivityIDList[] = []

const initialState: StoreState = {
  req: cloneDeep(initReq),
  data: cloneDeep(initChecklist),
  event: cloneDeep(initEvent),
  selected: cloneDeep(initSelected),
}

export default function(
  state: StoreState = initialState,
  action: DocAction
): StoreState {
  return produce(state, draft => {
    switch (action.type) {
      case getType(actions.getDocumentAction.request):
        draft.event = cloneDeep(initEvent)
        draft.event.IsLoading = true
        draft.data = cloneDeep(initChecklist)
        draft.selected = cloneDeep(initSelected)
        draft.req = action.payload
        break

      case getType(actions.getDocumentAction.success):
        draft.event.IsLoading = false
        const response = action.payload.ResponseData
        draft.data = response

        break

      case getType(actions.saveDocumentAction.failure):
      case getType(actions.getDocumentAction.failure):
        draft.event.IsLoading = false
        draft.event.SubmitError = parseErrorMessage(action.payload)
        break
      case getType(actions.updateData):
        draft.data = action.payload
        break

      case getType(actions.updateRejectList):
        draft.selected = action.payload
        break

      case getType(actions.saveDocumentAction.request):
        draft.event = cloneDeep(initEvent)
        draft.event.IsLoading = true
        break

      case getType(actions.saveDocumentAction.success):
        draft.event.IsLoading = false
        draft.event.SubmitSuccess = true
        draft.event.MessageSuccess = 'บันทึกสำเร็จ'
        draft.req = cloneDeep(initReq)
        draft.selected = cloneDeep(initSelected)
        break
      default:
        return state
    }
  })
}
