import { ActionType } from 'typesafe-actions'
import { Moment } from 'moment-timezone'
import * as actions from './actions'
import { TaskStatus } from '../../tasklane/tasklane.types'
import { UnitStatus } from 'features/types/status'

export type DashboardAction = ActionType<typeof actions>

export interface StoreState {
  data: DashboardData
  originalData: DashboardData
  event: DashboardEvent
  query: DashboardQuery
  sorting: any
  filter: Filter
  initFilter: Filter
  response: GetActivityApiResponse
}

export type Activity = {
  RowID: number
  ActivityID: string
  ActivityName: string
  BaselineStartDate: string
  BaselineFinishDate: string
  StartDate: string
  FinishDate: string
  ActualStartDate: string
  ActualFinishDate: string
  ExpectFinishDate: string
  InspectionDate: string
  ActivityTypeID: string
  ActivityTypeName: string
  SowID: string
  SowName: string
  WbsID: number
  WbsName: string
  Progress: number
  LastCurProgress: number
  LastReqProgress: number
  UnitID: number
  UnitName: string
  ZoneID: number
  ZoneName: string
  FloorID: number
  FloorName: string
  QCTypeID: number
  QCTypeName: string
  TaskStatus: TaskStatus
  TotalRows: number
}

export type TodoActivity = {
  RowID: number
  RequestID: number
  Unit: string
  Zone: string
  Floor: string
  QCTypeID: number
  QCTypeName: string
  PassedSubTasks: number
  TotalSubTasks: number
  CheckNumber: number
  TaskStatus: string
  State: string
}

export type RejectReason = {
  Id: number
  RejectReasonID: number
  RejectReasonRemark: string
  RejectReason: string
  Remark: string
  RejectBy: string
  RejectDate: string
}
export type CMInProgressActivity = {
  RowID: number
  RequestID: number
  Unit: string
  Zone: string
  Floor: string
  QCTypeID: number
  QCTypeName: string
  ModelName: string
  CheckNumber: number
  PassedSubTasks: number
  TotalSubTasks: number
  TaskStatus: string
  State: string
  InspectionScore: number
  BasePoint: number
  CheckStatus: UnitStatus
  CheckStatus_Insp: UnitStatus
  DocumentStatus: UnitStatus
  Reject?: RejectReason
}

export type QCInProgressActivity = {
  RowID: number
  RequestID: number
  Unit: string
  Zone: string
  Floor: string
  QCTypeID: number
  QCTypeName: string
  ModelName: string
  CheckNumber: number
  RequestDate: string
  AppointmentDate: string
  TaskStatus: string
  State: string
}

export type FixFromQCActivity = {
  RowID: number
  RequestID: number
  Unit: string
  Zone: string
  Floor: string
  QCTypeID: number
  QCTypeName: string
  Model: string
  RoomSize: string
  CheckNumber: number
  InspectionScore: number
  DocumentScore: number
  BasePoint: number
  LastUpdatedDate: string
  LastCheckedBy: string
  LastCheckedModule: string
  CheckStatus: UnitStatus
  CheckStatus_Insp: UnitStatus
  DocumentStatus: UnitStatus
}

export interface GetActivityRequest {
  ProjectCode: string
  UserEmail: string
  Sort: any
  UnitList: string[]
  QCTypeList: string[]
  WbsList: number[]
}

export type Filter = {
  UnitList: string[]
  QCTypeList: string[]
  WbsList: number[]
  LeafWbsList: number[]
}

export interface GetActivityApiResponse
  extends GetApiResponse<GetActivityResponse> {}

export interface GetActivityResponse {
  TotalToDo: number
  ToDo: TodoActivity[]
  TotalCMInProgress: number
  CMInProgress: CMInProgressActivity[]
  TotalQCInProgress: number
  QCInProgress: QCInProgressActivity[]
  TotalFixFromQC: number
  FixFromQC: FixFromQCActivity[]
}

export interface GetApiResponse<T> {
  ResponseData: T
  ResponseCode: string
  ResponseMsg: 'Success' | 'Error'
  RequestTime: string
  Responsetime: string
}

export type DashboardData = {
  TotalToDo: number
  ToDo: TodoActivity[]
  TotalCMInProgress: number
  CMInProgress: CMInProgressActivity[]
  TotalQCInProgress: number
  QCInProgress: QCInProgressActivity[]
  TotalFixFromQC: number
  FixFromQC: FixFromQCActivity[]
}

export type DashboardEvent = {
  isFetching: boolean
  error: string
}

export type DashboardFilter = {
  progress: number[]
  status: TaskStatus[]
  wbs: number[]
  date: {
    mode: string
    start: undefined | Moment
    end: undefined | Moment
  }
  floor: number[]
  unit: string[]
  zone: string[]
  type: string[]
}

export type DashboardQuery = {
  date: {
    start: undefined | Moment
    end: undefined | Moment
  }
}

export enum TypeOfTask {
  TODO = 'todo',
  CMInProgress = 'cmInProgress',
  QCInProgress = 'qcInProgress',
  FixFromQC = 'fixFromQC',
}
