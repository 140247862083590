import { getType } from 'typesafe-actions'
import * as actions from './actions'
import { StoreState, DashboardAction, TypeOfTask, Filter } from './types'
import produce from 'immer'
import parseErrorMessage from 'utils/parseErrorMessage'
import { sortingList } from 'utils/helper'

import { sortingActivityList } from '../seemoreQc/function'
import { cloneDeep } from 'lodash'

const initFilter = {
  QCTypeList: [],
  UnitList: [],
  WbsList: [],
  LeafWbsList: [],
} as Filter

const initialState: StoreState = {
  data: {
    TotalToDo: 0,
    ToDo: [],
    TotalCMInProgress: 0,
    CMInProgress: [],
    TotalQCInProgress: 0,
    QCInProgress: [],
    TotalFixFromQC: 0,
    FixFromQC: [],
  },
  originalData: {
    TotalToDo: 0,
    ToDo: [],
    TotalCMInProgress: 0,
    CMInProgress: [],
    TotalQCInProgress: 0,
    QCInProgress: [],
    TotalFixFromQC: 0,
    FixFromQC: [],
  },
  event: {
    isFetching: false,
    error: '',
  },
  sorting: {
    todo: 'asc',
    cmInProgress: 'asc',
    qcInProgress: 'asc',
    fixFromQC: 'asc',
  },
  query: {
    date: {
      start: undefined,
      end: undefined,
    },
  },
  filter: cloneDeep(initFilter),
  initFilter: cloneDeep(initFilter),
  response: {
    ResponseCode: '1',
    ResponseMsg: 'Success',
    RequestTime: '',
    Responsetime: '',
    ResponseData: {
      TotalToDo: 0,
      ToDo: [],
      TotalCMInProgress: 0,
      CMInProgress: [],
      TotalQCInProgress: 0,
      QCInProgress: [],
      TotalFixFromQC: 0,
      FixFromQC: [],
    },
  },
}

const sortingColumn = {
  todo: 'TaskRatio',
  cmInProgress: 'InspectionScore',
  qcInProgress: 'RequestDate',
  fixFromQC: 'LastUpdatedDate',
}

export default function(
  state: StoreState = initialState,
  action: DashboardAction
): StoreState {
  return produce(state, draft => {
    switch (action.type) {
      case getType(actions.getActivityAction.request):
        draft.event = {
          isFetching: true,
          error: '',
        }

        draft.data = state.data
        break
      case getType(actions.getActivityAction.success):
        draft.event.isFetching = false
        const response = action.payload.ResponseData

        const sorting = state.sorting

        const todoSort = sortingActivityList(
          response.ToDo,
          sorting.todo,
          sortingColumn.todo
        )

        const cmInProgressSort = sortingList(
          response.CMInProgress,
          sorting.cmInProgress,
          sortingColumn.cmInProgress,
          'number'
        )

        const qcInProgressSort = sortingList(
          response.QCInProgress,
          sorting.qcInProgress,
          sortingColumn.qcInProgress,
          'date'
        )

        const fixFromQCSort = sortingList(
          response.FixFromQC,
          sorting.fixFromQC,
          sortingColumn.fixFromQC,
          'date'
        )

        draft.data = {
          ToDo: todoSort,
          CMInProgress: cmInProgressSort,
          QCInProgress: qcInProgressSort,
          FixFromQC: fixFromQCSort,
          TotalToDo: response.TotalToDo,
          TotalCMInProgress: response.TotalCMInProgress,
          TotalQCInProgress: response.TotalQCInProgress,
          TotalFixFromQC: response.TotalFixFromQC,
        }

        draft.originalData = {
          ToDo: response.ToDo,
          CMInProgress: response.CMInProgress,
          QCInProgress: response.QCInProgress,
          FixFromQC: response.FixFromQC,
          TotalToDo: response.TotalToDo,
          TotalCMInProgress: response.TotalCMInProgress,
          TotalQCInProgress: response.TotalQCInProgress,
          TotalFixFromQC: response.TotalFixFromQC,
        }

        break
      case getType(actions.getActivityAction.failure):
        draft.event = {
          isFetching: false,
          error: parseErrorMessage(action.payload),
        }

        break

      case getType(actions.sortingDashboard):
        let typ: string = action.payload
        let orderBy: string = state.sorting[typ]
        orderBy = orderBy !== 'asc' ? 'asc' : 'desc'
        draft.sorting[typ] = orderBy

        if (typ === TypeOfTask.TODO) {
          const list = sortingActivityList(
            state.data.ToDo,
            orderBy,
            sortingColumn.todo
          )

          draft.response.ResponseData.ToDo = list
          draft.data.ToDo = list
        } else if (typ === TypeOfTask.CMInProgress) {
          const list = sortingList(
            state.data.CMInProgress,
            orderBy,
            sortingColumn.cmInProgress,
            'number'
          )

          draft.response.ResponseData.CMInProgress = list
          draft.data.CMInProgress = list
        } else if (typ === TypeOfTask.QCInProgress) {
          const list = sortingList(
            state.data.QCInProgress,
            orderBy,
            sortingColumn.qcInProgress,
            'date'
          )

          draft.response.ResponseData.QCInProgress = list
          draft.data.QCInProgress = list
        } else if (typ === TypeOfTask.FixFromQC) {
          const list = sortingList(
            state.data.FixFromQC,
            orderBy,
            sortingColumn.fixFromQC,
            'date'
          )

          draft.response.ResponseData.FixFromQC = list
          draft.data.FixFromQC = list
        }
        break
      case getType(actions.updateFilter):
        draft.filter = { ...action.payload }
        break
      case getType(actions.clearFilter):
        draft.filter = cloneDeep(initFilter)
        break
      default:
        return state
    }
  })
}
