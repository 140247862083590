import React from 'react'
import { Col, Row } from 'antd'
import styled from 'styled-components'
import { DescItem, ImageReport } from 'Components/AdminQC/Report/Components'
import { DefectItem as Defect } from 'features/report/auditPlan/types'
import DefectStatus from 'Components/Defect/DefectStatus'

type Props = {
  index: number
  defect: Defect
}

const DefectItem: React.ComponentType<Props> = props => {
  const { index, defect } = props

  const renderDescItem = (title: string, value: any) => {
    return <DescItem title={title} value={value || '-'} percentW={25} />
  }

  const defectInfo = (
    <Row type="flex">
      {renderDescItem(
        `หมุดที่ ${defect.PinNumber}`,
        <DefectStatus pinStatus={defect.PinStatus} />
      )}
      {renderDescItem(
        'หมวดงานหลัก / หมวดงานรอง',
        `${defect.JobTypeName}/${defect.SubJobTypeName}`
      )}
      {renderDescItem('รายละเอียด', defect.DetailName)}

      {renderDescItem('หมายเหตุ', defect.Remark)}
      {renderDescItem('ชื่อผู้รับเหมา', '-')}
      {renderDescItem('แนวทางการแก้ไข', defect.HowToFix)}
      {renderDescItem('พื้นที่', defect.LocationName)}
    </Row>
  )

  const imageInfo = (
    <Row>
      <Col span={8}>
        <FlorplanImage>
          <Title>แบบจัดสรร</Title>
          <ImageReport
            image={defect.ImageLocation}
            remark={''}
            showRemark={false}
            hasMargin={false}
          />
        </FlorplanImage>
      </Col>
      <Col span={8}>
        <BeforeImage>
          <Title>สภาพงานจริง</Title>
          <ImageReport
            image={defect.ImageBefore}
            remark={''}
            showRemark={false}
            hasMargin={false}
          />
        </BeforeImage>
      </Col>
      <Col span={8}>
        <AfterImage>
          <Title>After</Title>
          <ImageReport
            image={defect.ImageAfter}
            remark={''}
            showRemark={false}
            hasMargin={false}
          />
        </AfterImage>
      </Col>
    </Row>
  )
  return (
    <Box
      style={{
        pageBreakBefore:
          index === 0 ? 'avoid' : index % 2 === 1 ? 'always' : 'avoid',
      }}
    >
      {defectInfo}
      <Line />
      {imageInfo}
    </Box>
  )
}

const Box = styled.div`
  margin-top: 16px;
  border-radius: 8px;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.03);
  background-color: #fff;
  padding: 24px;

  @media print {
    border: 1px solid rgba(0, 49, 104, 0.05);
    border-radius: 5px;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.03);
  }
`

const Line = styled.div`
  width: 100%;
  background-color: rgba(0, 49, 104, 0.05);
  height: 1px;
  margin-top: 16px;
`

const BeforeImage = styled.div`
  margin: 0 6px;
  margin-top: 12px;
`

const FlorplanImage = styled.div`
  margin-right: 12px;
  margin-top: 12px;
`

const AfterImage = styled.div`
  margin-left: 12px;
  margin-top: 12px;
`

const Title = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 20px;
  color: #000;
  margin-bottom: 12px;
`

export default DefectItem
