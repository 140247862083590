import React from 'react'
import { Row } from 'antd'
import styled from 'styled-components'
import DescItem from './DescItem'
import { SignData } from 'features/report/common/types'

type Props = {
  data?: SignData
}

const Signature: React.ComponentType<Props> = props => {
  const { data } = props

  const image = data?.Image?.FileURL ? (
    <img width="60%" src={data?.Image?.FileURL || ''} />
  ) : (
    <span />
  )

  return (
    <Box>
      <TitleSection>ลงชื่อผู้รับทราบการตรวจสอบ</TitleSection>
      <Row type="flex">
        <DescItem
          title={'ผู้รับทราบการตรวจสอบ'}
          value={data?.Username || '-'}
          percentW={33}
        />
        <DescItem title={'ลายเซ็น'} value={image} percentW={33} />
        <DescItem
          title={'หมายเหตุ'}
          value={data?.Remark || '-'}
          percentW={33}
        />
      </Row>
    </Box>
  )
}

const Title: any = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 28px;
  color: #000;
`

const TitleSection = styled(Title)`
  font-size: 24px;
`

const Box = styled.div`
  padding: 12px 24px;
  background-color: #fff;
  color: #000;
  border-top: solid 1px #e4e7eb;
  margin-top: 5px;
`

export default Signature
