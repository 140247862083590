import { getType } from 'typesafe-actions'
import * as actions from './actions'
import { StoreState, DashboardAction, TypeOfLane } from './types'
import produce from 'immer'
import parseErrorMessage from 'utils/parseErrorMessage'
import { filterActivity, sortingList } from 'utils/helper'
import { sliceData } from './function'

const initialState: StoreState = {
  data: {
    todo: [],
    progress: [],
    requested: [],
    checking: [],
    qc: [],
    TotalCMChecking: 0,
    TotalFixFromQC: 0,
    TotalInProgress: 0,
    TotalRequested: 0,
    TotalTodo: 0,
  },
  originalData: {
    todo: [],
    progress: [],
    requested: [],
    checking: [],
    qc: [],
    TotalCMChecking: 0,
    TotalFixFromQC: 0,
    TotalInProgress: 0,
    TotalRequested: 0,
    TotalTodo: 0,
  },
  event: {
    isFetching: false,
    error: '',
  },
  sorting: {
    todo: 'asc',
    progress: 'asc',
    requested: 'asc',
    checking: 'asc',
    qc: 'asc',
  },
  query: {
    date: {
      start: undefined,
      end: undefined,
    },
  },
  filter: {
    progress: [],
    status: [],
    wbs: [],
    date: {
      mode: '',
      start: undefined,
      end: undefined,
    },
    floor: [],
    unit: [],
    zone: [],
    type: [],
  },
  response: {
    ResponseCode: '1',
    ResponseMsg: 'Success',
    RequestTime: '',
    Responsetime: '',
    ResponseData: {
      ToDo: [],
      InProgress: [],
      Requested: [],
      CMChecking: [],
      FixFromQC: [],
      TotalCMChecking: 0,
      TotalFixFromQC: 0,
      TotalInProgress: 0,
      TotalRequested: 0,
      TotalTodo: 0,
    },
  },
}

const sortingColumn = {
  todo: 'StartDate',
  progress: 'FinishDate',
  requested: 'FinishDate',
  checking: 'FinishDate',
  qc: 'LastUpdatedDate',
}

const sortingType = {
  todo: 'date',
  progress: 'date',
  requested: 'date',
  checking: 'date',
  qc: 'iso_date',
}

export default function(
  state: StoreState = initialState,
  action: DashboardAction
): StoreState {
  return produce(state, draft => {
    switch (action.type) {
      case getType(actions.getActivityAction.request):
        draft.event = {
          isFetching: true,
          error: '',
        }

        draft.data = state.data
        break
      case getType(actions.getActivityAction.success):
        draft.event.isFetching = false
        const response = action.payload.ResponseData
        const sorting = state.sorting
        const filter = state.filter

        let list = filterActivity(sliceData(response.ToDo), filter)
        const todoSort = sortingList(
          list,
          sorting.todo,
          sortingColumn.todo,
          sortingType.todo
        )

        list = filterActivity(sliceData(response.InProgress), filter)
        const progressSort = sortingList(
          list,
          sorting.progress,
          sortingColumn.progress,
          sortingType.progress
        )

        list = filterActivity(sliceData(response.Requested), filter)
        const requestedSort = sortingList(
          list,
          sorting.requested,
          sortingColumn.requested,
          sortingType.requested
        )

        list = filterActivity(sliceData(response.CMChecking), filter)
        const checkingSort = sortingList(
          list,
          sorting.checking,
          sortingColumn.checking,
          sortingType.checking
        )

        list = filterActivity(sliceData(response.FixFromQC), filter)
        const qcSort = sortingList(
          list,
          sorting.qc,
          sortingColumn.qc,
          sortingType.qc
        )

        draft.data = {
          todo: todoSort,
          progress: progressSort,
          requested: requestedSort,
          checking: checkingSort,
          qc: qcSort,
          TotalCMChecking: response.TotalCMChecking,
          TotalFixFromQC: response.TotalFixFromQC,
          TotalInProgress: response.TotalInProgress,
          TotalRequested: response.TotalRequested,
          TotalTodo: response.TotalTodo,
        }

        draft.originalData = {
          todo: response.ToDo,
          progress: response.InProgress,
          requested: response.Requested,
          checking: response.CMChecking,
          qc: response.FixFromQC,
          TotalCMChecking: response.TotalCMChecking,
          TotalFixFromQC: response.TotalFixFromQC,
          TotalInProgress: response.TotalInProgress,
          TotalRequested: response.TotalRequested,
          TotalTodo: response.TotalTodo,
        }

        break
      case getType(actions.getActivityAction.failure):
        draft.event = {
          isFetching: false,
          error: parseErrorMessage(action.payload),
        }

        break

      case getType(actions.sortingDashboard):
        let typ: string = action.payload
        let orderBy: string = state.sorting[typ]
        orderBy = orderBy !== 'asc' ? 'asc' : 'desc'
        draft.sorting[typ] = orderBy

        if (typ === TypeOfLane.TODO) {
          const list = sortingList(
            state.data.todo,
            orderBy,
            sortingColumn.todo,
            sortingType.todo
          )

          draft.response.ResponseData.ToDo = list
          draft.data.todo = list
        } else if (typ === TypeOfLane.PROGRESS) {
          const list = sortingList(
            state.data.progress,
            orderBy,
            sortingColumn.progress,
            sortingType.progress
          )

          draft.response.ResponseData.InProgress = list
          draft.data.progress = list
        } else if (typ === TypeOfLane.REQUESTED) {
          const list = sortingList(
            state.data.requested,
            orderBy,
            sortingColumn.progress,
            sortingType.requested
          )

          draft.response.ResponseData.Requested = list
          draft.data.requested = list
        } else if (typ === TypeOfLane.CHECKING) {
          const list = sortingList(
            state.data.checking,
            orderBy,
            sortingColumn.checking,
            sortingType.checking
          )

          draft.response.ResponseData.CMChecking = list
          draft.data.checking = list
        } else {
          const list = sortingList(
            state.data.qc,
            orderBy,
            sortingColumn.qc,
            sortingType.qc
          )
          draft.response.ResponseData.FixFromQC = list
          draft.data.qc = list
        }
        break
      case getType(actions.setFilterDashboard):
        draft.filter = { ...action.payload }
        break
      case getType(actions.filterDashboard):
        let filtterTodo = filterActivity(draft.originalData.todo, draft.filter)
        const sortingTodo = sortingList(
          filtterTodo,
          state.sorting.todo,
          sortingColumn.todo,
          sortingType.todo
        )

        let filtterProgress = filterActivity(
          draft.originalData.progress,
          draft.filter
        )
        const sortingProgress = sortingList(
          filtterProgress,
          state.sorting.progress,
          sortingColumn.progress,
          sortingType.progress
        )

        let filtterChecking = filterActivity(
          draft.originalData.checking,
          draft.filter
        )
        const sortingChecking = sortingList(
          filtterChecking,
          state.sorting.checking,
          sortingColumn.checking,
          sortingType.checking
        )

        let filtterRequested = filterActivity(
          draft.originalData.requested,
          draft.filter
        )
        const sortingRequested = sortingList(
          filtterRequested,
          state.sorting.requested,
          sortingColumn.requested,
          sortingType.requested
        )

        let filtterQC = filterActivity(draft.originalData.qc, draft.filter)
        const sortingQC = sortingList(
          filtterQC,
          state.sorting.qc,
          sortingColumn.qc,
          sortingType.qc
        )

        draft.data = {
          todo: sortingTodo,
          progress: sortingProgress,
          requested: sortingChecking,
          checking: sortingRequested,
          qc: sortingQC,
          TotalCMChecking: draft.originalData.TotalCMChecking,
          TotalFixFromQC: draft.originalData.TotalFixFromQC,
          TotalInProgress: draft.originalData.TotalInProgress,
          TotalRequested: draft.originalData.TotalRequested,
          TotalTodo: draft.originalData.TotalTodo,
        }
        break
      /*
   case getType(actions.updateListAction):
     const { todo = [], inProgress = [], completed = [] } = action.payload
     draft.todo = todo
     draft.inProgress = inProgress
     draft.completed = completed
     break
   case getType(actions.setFilterDashboard):
     draft.filter = { ...action.payload }
     break
   case getType(actions.setQueryDashboard):
     draft.query = { ...action.payload }
     break
     */
      default:
        return state
    }
  })
}
