import React, { useState } from 'react'
import { Row, Col } from 'antd'
import styled from 'styled-components'

type Props = {
  textLeft: string
  textRight: string
  leftSelected: boolean
  onLeftClick: () => void
  onRightClick: () => void
}

const ToggleBtn: React.FunctionComponent<Props> = props => {
  const { textLeft, textRight, leftSelected, onLeftClick, onRightClick } = props
  let leftItem, rightItem

  if (leftSelected) {
    leftItem = <span onClick={onLeftClick}>{textLeft}</span>
    rightItem = <label onClick={onRightClick}>{textRight}</label>
  } else {
    leftItem = <label onClick={onLeftClick}>{textLeft}</label>
    rightItem = <span onClick={onRightClick}>{textRight}</span>
  }
  return (
    <MainView>
      {leftItem}
      {rightItem}
    </MainView>
  )
}

const MainView = styled.div`
  width: 242px;
  height: 40px;
  border-radius: 20px;
  font-size: 18px;
  background-color: white;
  display: inline-flex;

  span {
    color: white;
    background-color: #003168;
    border-radius: 20px;
    padding: 0px 20px;
    height: 40px;
    align-items: center;
    display: flex;
    justify-content: center;
    width: 120px;
    cursor: pointer;
  }

  label {
    width: 120px;
    height: 40px;
    align-items: center;
    display: flex;
    justify-content: center;
    cursor: pointer;
    color: black;
  }
`

export default ToggleBtn
