import RootState from 'typings/RootState'
import { Event} from '../../types/event'
import { Activity } from './types'

export const selectData = (state: RootState): Activity =>
  state.cmpjSeemoreQc.data

export const selectEvent = (state: RootState): Event =>
  state.cmpjSeemoreQc.event

export const selectSorting = (state: RootState) => state.cmpjSeemoreQc.sorting

