import React, { useState } from 'react'
import styled from 'styled-components'

import { CheckListDetail, QCType } from 'features/checklist/constructRoad/types'
import { DropdownList } from 'Components/Shared/Form'
import { SelectType } from '..'

interface Props {
  selected: SelectType
  checkListDetail: CheckListDetail
}

const QcTypeDropdown: React.FC<Props> = ({ checkListDetail, selected }) => {
  const [qcType, setQcType] = useState<QCType>(
    checkListDetail.QCTypeList[selected.qctype]
  )

  const menuQcType = () => {
    const result = checkListDetail.QCTypeList.map(
      (item: QCType, index: number) => (
        <DataItem key={index}>{item.QCTypeID}</DataItem>
      )
    )
    return result
  }

  return (
    <Box>
      <div className="dropdown-item">
        <DropdownList
          label="หมวดงาน"
          value=""
          placeholder={qcType ? qcType.QCTypeID : 'กรุณาเลือก'}
          menuChoice={menuQcType()}
          disabled={true}
        />
      </div>
    </Box>
  )
}

const Box = styled.div`
  padding-top: 12px;
`
const DataItem = styled.div`
  padding: 5px 10px;

  &:hover {
    cursor: pointer;
    color: white;
    background-color: #003168;
  }
`

export default QcTypeDropdown
