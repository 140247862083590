import React from 'react'
import styled from 'styled-components'
import { Row, Col, Layout } from 'antd'
import { PrimaryButton } from 'Components/Shared/Button'
import DefectScore from 'Components/Defect/DefectScore'

import { DefectItem } from 'features/report/auditPlan/types'
import { DefectStatus } from 'features/types/status'

const { Footer } = Layout

type Props = {
  onDelete: () => void
  onSave: () => void
  defect: DefectItem
  addMode?: boolean
}

const DefectFooter: React.FC<Props> = ({
  onDelete,
  onSave,
  defect,
  addMode = true,
}) => {
  const subjobEmpty = defect.SubJobTypeID <= 0
  let detailEmpty = false
  let locationEmpty = false

  if (defect.PinStatus !== DefectStatus.GOODWORK) {
    detailEmpty = defect.DetailID <= 0
    locationEmpty = defect.LocationID <= 0
  }

  const imgEmpty =
    defect.ImageLocation === undefined || defect.ImageBefore === undefined
  const disableSave = subjobEmpty || detailEmpty || imgEmpty

  return (
    <Footer className="detail-page-footer">
      <FooterContent>
        <Row type="flex" justify="space-between" align="middle">
          <Col>
            {addMode && (
              <Button>
                <PrimaryButton text="ลบ" onClick={onDelete} btnType="Cancel" />
              </Button>
            )}
          </Col>
          <Col>
            <Row type="flex" align="middle">
              <Col />
              <Col>
                <Button>
                  <PrimaryButton
                    text="บันทึก"
                    disabled={disableSave}
                    onClick={onSave}
                  />
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </FooterContent>
    </Footer>
  )
}

const FooterContent = styled.div`
  max-width: 1110px;
  margin: 0 auto;
  color: #000;
  font-size: 20px;
  padding-right: 20px;
`

const Button = styled.div`
  width: 160px;
`

export default DefectFooter
