import { put, take, takeEvery, call, select } from 'redux-saga/effects'
import { getType } from 'typesafe-actions'
import * as actions from './actions'

import {
  createCheckList,
  updateCheckList,
  updateActivityByCheckList,
  getMasterCheckList,
  importCheckList,
} from './api'

import {
  UpdateCheckListReq,
  UpdateCheckListActivityReq,
  CreateCheckListReq,
  ImportCheckListReq,
} from './types'

import { InspectionList } from 'features/types/checklist'
import APIManager, { configWithToken } from 'services/APIManager'
import { APIPath } from 'services/APIPath'
import {
  serviceTokenSelector,
  userEmailSelector,
  getUserProfile,
} from 'features/sensitive/sensitive.selector'
import history from 'utils/history'

const setSeqNumber = (list: InspectionList[]): InspectionList[] => {
  list.forEach((it1, i1) => {
    const lv1 = i1 + 1
    it1.Level = 1
    it1.Seq = `${lv1}`
    it1.Data.forEach((it2, i2) => {
      const lv2 = i2 + 1
      it2.Level = 2
      it2.Seq = `${lv1}.${lv2}`
      it2.Data.forEach((it3, i3) => {
        const lv3 = i3 + 1
        it3.Level = 3
        it3.Seq = `${lv1}.${lv2}.${lv3}`
      })
    })
  })

  return list
}

function* sendRequest(action: ReturnType<typeof actions.submitCheckListReq>) {
  const {
    checklist,
    doc,
    activity,
    ProjectCode,
    UserEmail,
    Token,
    history,
  } = action.payload
  try {
    const { CheckListID, CheckListName, WarningList, isMaster } = checklist
    const InspectionList = setSeqNumber(checklist.InspectionList)

    if (CheckListID !== '') {
      if (checklist.Status === 'Ready') {
        const params: UpdateCheckListReq = {
          UserEmail,
          CheckListID,
          CheckListName: CheckListName.trim(),
          InspectionList,
          WarningList,
          isMaster,
          ProjectCode: isMaster === 'N' ? ProjectCode : '',
          ProcurementTaskID: checklist.ProcurementTaskID.trim() ?? '',
          TemplateCode: checklist.TemplateCode.trim() ?? '',
        }

        yield put(updateCheckList(params, Token))
        yield take(actions.updateCheckListReq.success)
      }
    } else {
      if (doc.QCTypeID === '') {
        const params: CreateCheckListReq = {
          UserEmail,
          CheckListName: CheckListName.trim(),
          InspectionList,
          WarningList,
          isMaster,
          ProjectCode: isMaster === 'N' ? ProjectCode : '',
          ProcurementTaskID: checklist.ProcurementTaskID.trim() ?? '',
          TemplateCode: checklist.TemplateCode.trim() ?? '',
        }

        yield put(createCheckList(params, Token))
        const resp = yield take(actions.createCheckListReq.success)
        checklist.CheckListID = resp.payload.ResponseData.CheckListID
      } else {
        const params: ImportCheckListReq = {
          UserEmail,
          CheckListName: CheckListName.trim(),
          InspectionList,
          WarningList,
          isMaster,
          ProjectCode: isMaster === 'N' ? ProjectCode : '',
          QCTypeID: doc.QCTypeID,
          Score: doc.Score,
          IsRequireQC: doc.IsRequireQC,
          CheckList: doc.CheckList,
          ProcurementTaskID: checklist.ProcurementTaskID.trim(),
          TemplateCode: checklist.TemplateCode.trim(),
        }

        yield put(importCheckList(params, Token))
        const resp = yield take(actions.importCheckListReq.success)
        checklist.CheckListID = resp.payload.ResponseData.CheckListID
      }

      yield put(actions.updateCheckListDetail(checklist))
    }

    if (checklist.CheckListID !== '' && isMaster === 'N') {
      const paramsActivity: UpdateCheckListActivityReq = {
        UserEmail,
        CheckListID: checklist.CheckListID,
        ProjectCode,
        ActivityList: activity,
      }
      yield put(updateActivityByCheckList(paramsActivity, Token))
      yield take(actions.updateActivityByCheckListReq.success)
      history.push('/local-checklist')
    } else if (isMaster === 'N') {
      history.push('/local-checklist')
    } else {
      history.push('/master-checklist')
    }
  } catch (e) {}
}

function* sendDeleteRequest(
  action: ReturnType<typeof actions.deleteCheckListByIdReq.request>
) {
  try {
    const { CheckListID, getReq, history } = action.payload

    const Token = yield select(serviceTokenSelector)
    const UserEmail = yield select(userEmailSelector)
    const userProfile = yield select(getUserProfile)
    const isMaster = userProfile.RoleName == 'Administrator'

    const res = yield call(
      APIManager.call,
      APIPath.DeleteMainconCheckList,
      'POST',
      { UserEmail: UserEmail, CheckListID, isMaster: isMaster ? 'Y' : 'N' },
      configWithToken(`Bearer ${Token}`)
    )

    yield put(actions.deleteCheckListByIdReq.success(res))
    if (getReq) {
      yield put(getMasterCheckList(getReq))
    } else {
      history.push('/master-checklist')
    }
  } catch (e) {
    yield put(actions.deleteCheckListByIdReq.failure(e))
  }
}

function* allSaga() {
  yield takeEvery(getType(actions.submitCheckListReq), sendRequest)
  yield takeEvery(
    getType(actions.deleteCheckListByIdReq.request),
    sendDeleteRequest
  )
}

export default allSaga
