import { Wbs } from './wbs.types'

export const getWbsList = (wbsList: Wbs[]): Wbs[] => {
  const list: Wbs[] = []
  wbsList.forEach(wbs => {
    if (wbs.ParentWbsID === 0) {
      list.push(wbs)
    } else {
      const found: any = wbsList.find(it => it.WbsID === wbs.ParentWbsID)
      if (found) {
        found.Children = found.Children || []
        found.Children.push(wbs)
      }
    }
  })

  return list
}

const findParent = (wbsName: string[], list: Wbs[], parentId: number) => {
  const data = list.find(p => p.WbsID === parentId)
  if (data) {
    wbsName.unshift(data.WbsIName)
    if (data.ParentWbsID > 0) {
      findParent(wbsName, list, data.ParentWbsID)
    }
  }
  return wbsName
}

export const getWbsMap = (list: Wbs[]): any => {
  let wbsList = [] as any
  list.forEach(wbs => {
    let name = []
    name.unshift(wbs.WbsIName)
    if (wbs.ParentWbsID > 0) {
      name = findParent(name, list, wbs.ParentWbsID)
    }
    wbsList[wbs.WbsID] = name.join(' / ')
  })
  return wbsList
}

export const getShortWbs = (wbsName: string) => {
  if (wbsName != '') {
    let wbs = wbsName.split('/')
    wbs = wbs.filter(v => v.trim() != '')
    const lastWbs = wbs.slice(wbs.length - 2, wbs.length)
    return lastWbs.join('/')
  }
  return wbsName
}

const addLeafNode = (
  wbs: Wbs,
  selectedList: number[],
  leafList: number[]
): number[] => {
  const { Children = [] } = wbs

  const len: number = Children.length
  if (len === 0) {
    if (selectedList.includes(wbs.WbsID)) {
      leafList.push(wbs.WbsID)
    }
  } else {
    for (let i = 0; i < len; i++) {
      leafList = addLeafNode(Children[i], selectedList, leafList)
    }
  }

  return leafList
}

export const getLeafWbsList = (
  wbsList: Wbs[],
  selectedList: number[]
): number[] => {
  let list: number[] = []

  const len: number = wbsList.length
  for (let i = 0; i < len; i++) {
    list = addLeafNode(wbsList[i], selectedList, list)
  }

  return list
}
