import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'antd'
import { SecondaryButton, PrimaryButton } from 'Components/Shared/Button'

interface Props {
  onSubmit: () => void
  label?: string
  onSelectAll?: () => void
  total?: number
  disabled?: boolean
}

const PageFooter: React.FC<Props> = props => {
  const {
    onSubmit,
    label = '',
    onSelectAll,
    total = 0,
    disabled = false,
  } = props

  const count = total > 0 ? <Green>{total}</Green> : <Grey>{total}</Grey>

  const leftContent = (
    <Col>
      <Row type="flex" align="middle">
        <Col>
          <Button>
            <SecondaryButton
              text="เลือกทั้งหมด"
              onClick={() => onSelectAll && onSelectAll()}
            />
          </Button>
        </Col>
        <Col>
          <Description>
            คุณเลือก {count} {label}
          </Description>
        </Col>
      </Row>
    </Col>
  )

  const rightContent = (
    <Col>
      <Button>
        <PrimaryButton
          text="ส่งรายงาน"
          onClick={() => onSubmit()}
          disabled={disabled}
        />
      </Button>
    </Col>
  )

  return (
    <Footer>
      <FooterContent>
        <Row type="flex" justify="space-between">
          {leftContent}
          {rightContent}
        </Row>
      </FooterContent>
    </Footer>
  )
}

const Footer = styled.div`
  position: fixed;
  bottom: 0px;
  height: auto;
  width: 100%;
  background-color: white;
  padding: 22px 80px;
  z-index: 20;
`

const FooterContent = styled.div`
  padding: 0 30px;
`

const Button = styled.div`
  width: 200px;
  float: left;
`

const Description = styled.div`
  font-family: 'DB Helvethaica X 55 Regular';
  font-size: 18px;
  color: #000;
  padding-left: 32px;
`

const Count: any = styled.span`
  font-family: 'DB Helvethaica X 75 Bd';
  padding: 0 8px;
`

const Grey = styled(Count)`
  color: #8b99a7;
`

const Green = styled(Count)`
  color: #5bc79e;
`

export default PageFooter
