import React from 'react'
import styled from 'styled-components'
import SubnavBar from 'Components/Shared/SubnavBar'
import { Layout, Row, Col } from 'antd'

import { LoadingView } from 'Components/Shared'
import { LoadingIcon } from '../Icon'

const { Content, Footer } = Layout

type Props = {
  breadcrumbs?: any
  filterHeader?: any
  title?: string
  titleMargin?: number
  pagination?: any
  tableHeader: any
  tableContent: any
  topNav?: any
  footer?: any
  footerPagination?: any
  isLoading?: boolean
  isCenter?: boolean
}

const StructureListPage: React.ComponentType<Props> = props => {
  const {
    topNav,
    breadcrumbs,
    filterHeader,
    title,
    pagination,
    tableHeader,
    tableContent,
    footer,
    footerPagination,
    isLoading = false,
    titleMargin = 0,
    isCenter = false,
  } = props

  return (
    <>
      {topNav && topNav}
      {breadcrumbs && <SubnavBar breadcrumbs={breadcrumbs} hideSearch={true} />}
      <Content className="mid-content">
        <LoadingView isShow={isLoading} />
        <MainPage
          className={isCenter ? 'main-content activity-detail' : 'normal'}
        >
          {filterHeader && filterHeader}
          <Row type="flex" justify="space-between" align="middle">
            <Col>
              {title && (
                <Title style={{ marginTop: titleMargin + 'px' }}>{title}</Title>
              )}
            </Col>
            <Col>{pagination && pagination}</Col>
          </Row>
          {tableHeader && tableHeader}
          {isLoading ? <LoadingIcon /> : tableContent}
          {footerPagination && footerPagination}
        </MainPage>
      </Content>
      {footer && (
        <Footer className="detail-page-footer">
          <FooterContent>{footer}</FooterContent>
        </Footer>
      )}
    </>
  )
}

const MainPage = styled.div`
  &.normal {
    padding: 0 80px;
    background-color: #f5f6fa;
    width: 100vw;
    -khtml-user-select: none;
    -o-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    overflow-x: scroll;
  }
`

const Title = styled.div`
  font-size: 28px;
  font-family: 'DB Helvethaica X 75 Bd';
  height: 30px;
  margin-bottom: 32px;
`

const FooterContent = styled.div`
  color: #000;
  font-size: 20px;
  padding: 0 30px;
`

export default StructureListPage
