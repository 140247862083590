import React from 'react'
import PopupMenu from 'Components/Shared/Filters/PopupMenu'
import Content from 'Components/Shared/Filters/QCTypeContent'

type Props = {
  onFilterChange: (filter: any) => void
  filter: any
  qctypeList: any[]
}

const QCTypeFilter: React.FunctionComponent<Props> = props => {
  const { onFilterChange, filter, qctypeList } = props

  return (
    <PopupMenu
      label={'หมวดงาน QC'}
      content={
        <Content
          qctypes={qctypeList}
          filter={filter.QCTypeList}
          onChange={data => {
            filter.QCTypeList = data
            onFilterChange(filter)
          }}
        />
      }
      isActive={filter.QCTypeList && filter.QCTypeList.length > 0}
    />
  )
}

export default QCTypeFilter
