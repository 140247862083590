import React from 'react'
import styled from 'styled-components'
import { numberFormat } from 'utils/format'
import { UnitStatus } from 'features/types/status'
import { unitStatusIsPass } from 'utils/status-map'

interface Props {
  score: number
  baseScore: number
  type: 'checklist' | 'document'
  status?: string
}

const ActivityScore: React.FC<Props> = props => {
  const { score, baseScore, type, status = '' } = props

  let isPass = false
  let isEnableFunction = false
  if (type === 'document') {
    if (score > baseScore) isPass = true
  }

  if (type === 'checklist') {
    isPass = unitStatusIsPass(true, status)
    if (
      status === UnitStatus.NotPassByFunction ||
      status === UnitStatus.PassByFunc
    ) {
      isEnableFunction = true
    }
  }

  const functionStatus = isPass ? (
    <PassScore>
      ผ่าน <br /> Function
    </PassScore>
  ) : (
    <FailScore>
      ไม่ผ่าน <br /> Function
    </FailScore>
  )

  const scoreStatus = isPass ? (
    <PassScore> {numberFormat(score)} คะแนน</PassScore>
  ) : (
    <FailScore> {numberFormat(score)} คะแนน</FailScore>
  )

  return isEnableFunction ? functionStatus : scoreStatus
}

const Score: any = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 24px;
  line-height: 1;
  text-align: right;
`
const PassScore = styled(Score)`
  color: #6bd5ae;
`

const FailScore = styled(Score)`
  color: #d0494c;
`

export default ActivityScore
