import { sortingList, sortingByMultiParams } from '../../../utils/helper'
import { BackwardUnitDetail } from './types'

export const sortingListUnit = (
  list: any[],
  type: string,
  name: string | undefined
): any[] => {
  try {
    let newList: any[] = list
    switch (name) {
      case 'ReqID':
        newList = sortingList(list, type, name, 'number')
        break
      case 'UnitCode':
        newList = sortingList(list, type, name, 'string')
        break
      case 'ModelName':
        newList = sortingList(list, type, name, 'string')
        break
      case 'QCTypeVersion':
        newList = sortingList(list, type, name, 'string')
        break
      case 'InspectionScore':
        newList = sortingList(list, type, name, 'number')
        break
      default:
        newList = sortingList(list, type, name || '', 'string')
    }

    return newList
  } catch (e) {
    return list
  }
}
