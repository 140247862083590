import React from 'react'
import { Row, Col, Input } from 'antd'
import styled from 'styled-components'
import ContructionTagName from 'Components/Shared/ContructionTagName'
import { getWbsName } from 'utils/wbs'

type Props = {
  originalData: any
  wbs?: any
}

const TaskInfo: React.FC<Props> = (props: Props) => {
  const { wbs, originalData } = props

  const wbsName = getWbsName(wbs, originalData.WbsID)

  return (
    <BoxItem>
      <Row type="flex" justify="space-between">
        <Col md={14}>
          <TextItem>
            หมวดหมู่ (ขั้นสูง)
            <div>{wbsName}</div>
          </TextItem>
        </Col>
        <Col md={6} offset={4}>
          <TextItem>
            ประเภทก่อสร้าง
            <div>
              <ContructionTagName name={originalData.ConstructionTypeName} />
            </div>
          </TextItem>
        </Col>
      </Row>
      <Space />
      <Row type="flex" justify="space-between">
        <Col md={6}>
          <TextItem>
            แผนวันเริ่มงาน
            <div>{originalData.BaselineStartDate}</div>
          </TextItem>
        </Col>
        <Col md={6}>
          <TextItem>
            แผนวันสิ้นสุดงาน
            <div>{originalData.BaselineFinishDate}</div>
          </TextItem>
        </Col>
        <Col md={6}>
          <TextItem>
            แผนวันเริ่มงานปัจจุบัน
            <div>{originalData.StartDate}</div>
          </TextItem>
        </Col>
        <Col md={6}>
          <TextItem>
            แผนวันสิ้นสุดงานปัจจุบัน
            <div>{originalData.FinishDate}</div>
          </TextItem>
        </Col>
      </Row>
    </BoxItem>
  )
}

const BoxItem = styled.div`
  background-color: #fff;
  margin-top: 12px;
  border-radius: 5px;
  border: solid 1px #f2f2f2;
  padding: 24px;
  line-height: normal;
`

const TextItem = styled.div`
  font-size: 20px;
  font-family: 'DB Helvethaica X 75 Bd';

  div {
    font-family: 'DB Helvethaica X 55 Regular';
  }
`

const Space = styled.div`
  margin-top: 24px;
`

export default TaskInfo
