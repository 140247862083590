import React from 'react'
import styled from 'styled-components'
import { TableHeader as Table, TableColHeader } from './index'

type HeaderProps = {
  title: string | any
  span: number
  key: string
  showSort?: boolean
  offset?: number
}

type Props = {
  onSort(name: string): void
  sorting: any
  items: HeaderProps[]
}

const HeaderRow: React.FunctionComponent<Props> = props => {
  const { onSort, sorting, items } = props

  const getType = (colName: string) => {
    if (sorting.name === colName) {
      return sorting.type
    }
    return ''
  }

  const renderColumn = (column: HeaderProps[]) => {
    return column.map((item: HeaderProps, index: number) => {
      return (
        <TableColHeader
          key={index}
          offset={item.offset}
          span={item.span}
          onSort={() => onSort(item.key)}
          type={getType(item.key)}
          sort={item.showSort}
        >
          <Text title={item.title}>{item.title}</Text>
        </TableColHeader>
      )
    })
  }

  return <Table paddingLeft={24}>{renderColumn(items)}</Table>
}

const Text = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  float: left;
  max-width: 70%;
`

export default HeaderRow
