import React from 'react'
import PopupMenu from 'Components/Shared/Filters/PopupMenu'
import Content from './WbsContent'

type Props = {
  onFilterChange: (filter: any) => void
  filter: any
  wbsList: any[]
}

const WbsFilter: React.FunctionComponent<Props> = props => {
  const { onFilterChange, filter, wbsList } = props

  return (
    <PopupMenu
      className="popup-menu"
      label={'หมวดหมู่'}
      content={
        <Content filter={filter} wbsList={wbsList} onChange={onFilterChange} />
      }
      isActive={filter.WbsList.length > 0}
    />
  )
}

export default WbsFilter
