import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { connect, useDispatch } from 'react-redux'
import SubnavBar from 'Components/Shared/SubnavBar'
import { Row, Col } from 'antd'
import { RouteComponentProps, Link } from 'react-router-dom'
import RootState from 'typings/RootState'
import {
  userEmailSelector,
  serviceTokenSelector,
} from 'features/sensitive/sensitive.selector'
import {
  selectUnitData,
  selectEvent,
  selectUnitFilter,
} from 'features/qc/dashboard/selector'
import {
  getUnitForQC,
  SendReportByQC,
  ReturnTaskByQC,
} from 'features/qc/dashboard/api'
import {
  GetActivityRequest,
  TypeOfTask,
  TodoActivity,
  InProgressActivity,
  ProjectList,
  UnitList,
  SendReportRequest,
  ReturnTaskReq,
  TaskList,
} from 'features/qc/dashboard/types'
import { clearFilter } from 'features/qc/dashboard/actions'
import { translate } from 'i18n'
import TKeys from 'i18n/translationKey'
import { NoCard, EventModal } from 'Components/Shared/'
import LinkButton from 'Components/Shared/LinkButton'
import Loading from 'Components/Shared/Loading'
import { BreadcrumbLink } from 'Components/Shared/BreadCrumb/BreadCrumb'
import { getQCType } from 'utils/getText'
import Menu from '../../Menu'
import ColumnHeader from '../ColumnHeader'
import TodoCard from '../TodoCard'
import InprogressCard from './InprogressCard'
import { PrimaryButton } from 'Components/Shared/Button'
import PageFooter from '../PageFooter'
import MainPage from '../MainPage'
import { SendReportStatus } from 'features/types/status'
import { QCUnitFilter } from 'Components/Filter'

const breadcrumbs: BreadcrumbLink[] = [
  {
    url: '/',
    title: 'Home',
    icon: '/home.png',
    active: true,
    showTitle: false,
  },
  {
    url: '/',
    title: translate(TKeys.Breadcrumb.dashboard),
    active: false,
    showTitle: false,
  },
]

type Props = ReduxProps & DispatchProps & RouteComponentProps

const UnitDashboard: React.ComponentType<Props> = props => {
  const { data, event, token, UserEmail, filter } = props
  const [sendReport, setSendReport] = useState(false)
  const [enableModal, setEnableModal] = useState(false)
  const [projectList, setProjectList] = useState([] as any[])

  const dispatch = useDispatch()
  useEffect(() => {
    getDashboardData()
  }, [dispatch])

  useEffect(() => {
    setEnableModal(false)
  }, [data])

  useEffect(() => {
    initProjectList()
  }, [sendReport])

  const getDashboardData = () => {
    const data: GetActivityRequest = {
      UserEmail: UserEmail,
      ProjectCode: filter.ProjectCode,
      QCTypeList: filter.QCTypeList,
    }

    dispatch(getUnitForQC(token, data))
  }

  const onSumitSendReport = () => {
    setEnableModal(true)
    const req: SendReportRequest = {
      UserEmail: UserEmail,
      ProjectList: projectList,
      TaskType: 'unit',
    }
    dispatch(SendReportByQC(token, req))
  }

  const onReturnTask = (projectCode: string, unit: UnitList) => {
    setEnableModal(true)
    const req: ReturnTaskReq = {
      UserEmail: UserEmail,
      ProjectCode: projectCode,
      ID: unit.Unit,
      TaskType: 'unit',
      CheckNumber: unit.CheckNumber,
      RequestID: unit.RequestID,
      QCTypeID: unit.QCTypeID,
    }

    dispatch(ReturnTaskByQC(token, req))
  }

  const defaultStatus = (isSend: boolean) => {
    return isSend ? SendReportStatus.None : SendReportStatus.No
  }

  const initProjectList = () => {
    let projectList = [] as ProjectList[]
    data.InProgress.forEach(project => {
      let taskList = [] as TaskList[]
      project.UnitList.forEach(item => {
        taskList.push({
          Unit: item.Unit,
          QCTypeID: item.QCTypeID,
          QCTypeVersion: item.QCTypeVersion,
          RequestID: item.RequestID,
          CheckNumber: item.CheckNumber,
          CheckListStatus: defaultStatus(item.SentChecklist),
          DocumentStatus: defaultStatus(item.SentDocument),
        })
      })
      projectList.push({
        ProjectCode: project.ProjectCode,
        TaskList: taskList,
      })
    })
    setProjectList([...projectList])
  }

  const getTaskStatus = (status: SendReportStatus) => {
    let newStatus = status
    if (status === SendReportStatus.No) {
      newStatus = SendReportStatus.Yes
    }
    if (status === SendReportStatus.Yes) {
      newStatus = SendReportStatus.No
    }
    return newStatus
  }

  const onSelect = (projectCode: string, type: string, unit: UnitList) => {
    const newProject = projectList.map(project => {
      if (project.ProjectCode === projectCode) {
        project.TaskList.map((task: TaskList) => {
          if (task.Unit === unit.Unit && getQCType(task) === getQCType(unit)) {
            if (type === 'document') {
              const status = getTaskStatus(task.DocumentStatus)
              task.DocumentStatus = status
            }
            if (type === 'checklist') {
              const status = getTaskStatus(task.CheckListStatus)
              task.CheckListStatus = status
            }
          }
          return task
        })
      }
      return project
    })
    setProjectList([...newProject])
  }

  const hasImgAndSign = (
    ProjectCode: string,
    Unit: string,
    QCTypeID: string
  ) => {
    let has = false
    const project = data.InProgress.find(
      project => project.ProjectCode === ProjectCode
    )
    if (project) {
      const unit = project.UnitList.find(
        item =>
          item.Unit === Unit &&
          getQCType(item) === QCTypeID &&
          item.HasImage &&
          item.HasSign
      )
      if (unit) {
        has = true
      }
    }
    return has
  }

  const checkAllStatus = (status: SendReportStatus) => {
    return status === SendReportStatus.None
      ? SendReportStatus.None
      : SendReportStatus.Yes
  }

  const onSelectListAll = () => {
    const newProject = projectList.map(project => {
      project.TaskList.map((task: TaskList) => {
        const complete = hasImgAndSign(
          project.ProjectCode,
          task.Unit,
          getQCType(task)
        )
        if (complete) {
          task.CheckListStatus = checkAllStatus(task.CheckListStatus)
        }
        task.DocumentStatus = checkAllStatus(task.DocumentStatus)

        return task
      })
      return project
    })
    setProjectList([...newProject])
  }

  const onCancleSendReport = () => {
    setSendReport(false)
  }

  const onSendReport = () => {
    setProjectList([])
    setSendReport(true)
  }

  useEffect(() => {
    return () => {
      dispatch(clearFilter())
    }
  }, [])

  const onSearch = () => {
    getDashboardData()
  }

  const sumNumberOfTask = (task: TodoActivity[]) => {
    let total = 0
    task.forEach(item => {
      if (item.NumberOfTask >= 0) {
        total += item.NumberOfTask
      }
    })
    return total
  }

  const renderTodoCard = (task: any[], type: string) => {
    let card = task.map((activity, index) => {
      return (
        <Link to={`/unit/todo/${activity.ProjectCode}`} key={index}>
          <TodoCard key={index} task={activity} type={type} />
        </Link>
      )
    })

    if (task.length >= 1) {
      card.push(<LinkButton href={`/unit/projects`} title={'ดูเพิ่มเติม'} />)
    }
    return card
  }

  const renderInprogressCard = (task: InProgressActivity[], type: string) => {
    let total = 0
    if (task.length === 0) {
      return {
        card: <NoCard text="ไม่มีรายการตรวจ. กรุณาเลือก “โครงการขอตรวจ”" />,
        total,
      }
    }

    const card = task.map((activity: InProgressActivity, index) => {
      total += activity.UnitList.length
      return (
        <InprogressCard
          key={index}
          task={activity}
          type={type}
          isSendReport={sendReport}
          onClickReturn={unit => onReturnTask(activity.ProjectCode, unit)}
          onSelectLists={onSelect}
          projectList={projectList}
        />
      )
    })

    return { card, total }
  }

  const loading = (
    <div style={{ textAlign: 'center' }}>
      <Loading />
    </div>
  )

  const renderTodoTask = (task: TodoActivity[]) => {
    const card =
      task.length > 0 ? renderTodoCard(task, TypeOfTask.TODO) : <NoCard />
    const content = event.IsLoading ? loading : card
    const total = sumNumberOfTask(task)
    return (
      <Col span={8}>
        <FullColumn>
          <ColumnHeader heading={`รายการขอตรวจ (${total})`} />
          {content}
        </FullColumn>
      </Col>
    )
  }

  const renderInprogressTask = (task: InProgressActivity[]) => {
    const cardContent = renderInprogressCard(task, TypeOfTask.INPROGRESS)
    const content = event.IsLoading ? loading : cardContent.card
    return (
      <Col span={16}>
        <ColumnHeader heading={`กำลังดำเนินการ (${cardContent.total})`} />
        {content}
      </Col>
    )
  }

  const disableSendReport = !data.InProgress || data.InProgress.length <= 0
  const header = (
    <Header>
      <Row type="flex" justify="space-between" align="middle">
        <Col>
          <QCUnitFilter onSearch={onSearch} />
        </Col>
        <Col>
          <Button>
            {!sendReport ? (
              <PrimaryButton
                text="ส่งรายงาน"
                onClick={onSendReport}
                disabled={disableSendReport}
              />
            ) : (
              <PrimaryButton
                text="ยกเลิก"
                onClick={onCancleSendReport}
                btnType="Cancel"
              />
            )}
          </Button>
        </Col>
      </Row>
    </Header>
  )

  const onSuccess = () => {
    setEnableModal(false)
    onCancleSendReport()
    getDashboardData()
  }

  const countSelected = () => {
    let total = 0
    projectList.forEach(project => {
      project.TaskList.forEach((task: TaskList) => {
        if (task.DocumentStatus === SendReportStatus.Yes) {
          total++
        }
        if (task.CheckListStatus === SendReportStatus.Yes) {
          total++
        }
      })
    })
    return total
  }

  return (
    <>
      <EventModal
        event={[event]}
        enable={enableModal}
        successCalback={() => onSuccess()}
      />
      <Menu selected="unit" />
      <SubnavBar breadcrumbs={breadcrumbs} hideSearch={true} />
      {header}
      <MainPage hasFooter={sendReport}>
        <Row type="flex">
          {renderTodoTask(data.ToDo)}
          {renderInprogressTask(data.InProgress)}
        </Row>
      </MainPage>
      {sendReport && (
        <PageFooter
          label="รายการในการส่งรายงาน"
          onSubmit={onSumitSendReport}
          onSelectAll={onSelectListAll}
          total={countSelected()}
        />
      )}
    </>
  )
}

const FullColumn = styled.div`
  padding-right: 20px;
`

const Button = styled.div`
  width: 200px;
`

const Header = styled.div`
  padding: 0 120px;
  background-color: #f5f6fa;
  margin: 40px 0;
`

const mapStateToProps = (state: RootState) => {
  return {
    data: selectUnitData(state),
    event: selectEvent(state),
    token: serviceTokenSelector(state),
    UserEmail: userEmailSelector(state),
    filter: selectUnitFilter(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {
  getUnitForQC,
}

type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(UnitDashboard)
