import { getType } from 'typesafe-actions'
import * as actions from './actions'
import produce from 'immer'
import { StoreState, ActivityAction } from './types'

import parseErrorMessage from 'utils/parseErrorMessage'
import { sortingTask } from 'utils/sorting'

const initialState: StoreState = {
  ActivityID: '',
  list: [],
  event: {
    IsLoading: false,
    SubmitError: undefined,
    SubmitSuccess: false,
    SubmitType: undefined,
    MessageSuccess: undefined,
  },
  sorting: {
    name: 'FinishDate',
    type: 'asc',
  },
  currentProjectCode: '',
}

export default function(
  state: StoreState = initialState,
  action: ActivityAction
): StoreState {
  return produce(state, draft => {
    switch (action.type) {
      case getType(actions.getApprovalListAction.request):
      case getType(actions.getMSApprovalListAction.request):
        draft.currentProjectCode = action.payload.ProjectCode
        draft.event.IsLoading = true
        draft.event.SubmitError = undefined
        draft.list = []
        break
      case getType(actions.getApprovalListAction.success):
        draft.event.IsLoading = false
        draft.list = action.payload.ResponseData.map(item => {
          item.State = 'PJPO'
          return item
        })
        break

      case getType(actions.getMSApprovalListAction.success):
        draft.event.IsLoading = false
        const responseData = action.payload.ResponseData.filter(
          item => item.ProjectCode === state.currentProjectCode
        )
        draft.list = responseData.map(item => {
          item.State = 'PJPO'
          return item
        })
        break
      case getType(actions.getApprovalListAction.failure):
      case getType(actions.getMSApprovalListAction.failure):
        draft.event.IsLoading = false
        draft.event.SubmitError = parseErrorMessage(action.payload)
        break

      case getType(actions.updateActivity.request):
      case getType(actions.updateMSActivity.request):
        draft.event.IsLoading = true
        draft.event.SubmitError = undefined
        draft.event.SubmitSuccess = false
        draft.ActivityID = action.payload.ActivityID
        break
      case getType(actions.updateActivity.success):
      case getType(actions.updateMSActivity.success):
        draft.event.IsLoading = false
        draft.event.SubmitError = undefined
        draft.event.SubmitSuccess = true
        draft.event.MessageSuccess = 'ทำรายการสำเร็จ'
        const index = draft.list.findIndex(
          it => it.ActivityID === draft.ActivityID
        )
        if (index > -1) {
          const list = draft.list
          list.splice(index, 1)
          draft.list = [...list]
        }
        break
      case getType(actions.updateActivity.failure):
      case getType(actions.updateMSActivity.failure):
        draft.event.IsLoading = false
        draft.event.SubmitError = parseErrorMessage(action.payload)
        break

      case getType(actions.sortingTask):
        const sorting = state.sorting
        const params = action.payload
        if (sorting.name === params.name) {
          draft.sorting.type = sorting.type === 'asc' ? 'desc' : 'asc'
        } else {
          draft.sorting.name = params.name
          draft.sorting.type = 'asc'
        }
        if (params.sort != null) {
          draft.sorting.type = params.sort
        }

        const list = sortingTask(
          draft.list,
          draft.sorting.type,
          draft.sorting.name
        )
        draft.list = list
        break

      default:
        return state
    }
  })
}
