import React from 'react'
import styled from 'styled-components'

interface Props {
  name: string
}

export enum Color {
  Architectural = '#ab7cd8',
  Commissioning = '#79cadc',
  Safety = '#efc122',
  Road = '#cc886f',
  Default = '#ab7cd8',
}

const ContructionTagName: React.FC<Props> = props => {
  const { name } = props
  let color = Color.Default
  switch (name) {
    case 'Architectural and M&E Work':
      color = Color.Architectural
      break
    case 'Commissioning':
      color = Color.Commissioning
      break
    case 'Safety':
      color = Color.Safety
      break
    case 'Road':
      color = Color.Road
      break
    default:
      break
  }

  return <TagName style={{ backgroundColor: color }}>{name}</TagName>
}

const TagName = styled.span`
  font-size: 16px;
  font-family: 'DB Helvethaica X 55 Regular';
  color: white;
  height: 20px;
  background-color: #cc886f;
  border-radius: 10px;
  padding: 0 12px;
  margin-top: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export default ContructionTagName
