import {
  CommonResp,
  GetDataListReq,
  GetDataListResp,
  ReqUpdatePrepareMapChecklistMaincon,
} from './types'
import { createAsyncAction, createStandardAction } from 'typesafe-actions'

export const updateDataAction = createAsyncAction(
  'admin/mappingTemplateCode/UPDATE_DATA_REQ',
  'admin/mappingTemplateCode/UPDATE_DATA_RESP',
  'admin/mappingTemplateCode/UPDATE_DATA_FAIL'
)<ReqUpdatePrepareMapChecklistMaincon, CommonResp, any>()

export const getDataListAction = createAsyncAction(
  'admin/mappingTemplateCode/FETCH_LIST_REQUEST',
  'admin/mappingTemplateCode/FETCH_LIST_SUCCESS',
  'admin/mappingTemplateCode/FETCH_LIST_FAILURE'
)<GetDataListReq, GetDataListResp, any>()

export const resetEvent = createStandardAction(
  'admin/mappingTemplateCode/RESET_EVENT_ACTION'
)<void>()
