import React from 'react'

import { translate } from '../../../i18n'
import TKeys from '../../../i18n/translationKey'
import Content from './Content'
import { RouteComponentProps } from 'react-router'
import Model from '../../AdminQC/Model/List'

type Props = RouteComponentProps

const AdminQC: React.FC<Props> = props => {
  return <Model {...props} homepage={true} />
}

export default AdminQC
