import React from 'react'
import { Col } from 'antd'
import styled from 'styled-components'
import { TableRow } from 'Components/Shared/Table'
import { ReportLink as Link } from 'Components/AdminQC/Report/Components'
import { DataItem } from 'features/adminReport/constructPlan/types'
import { toDateText, toMomentDateTime } from 'utils/datetime'
import { numberFormat } from 'utils/format'

type DefaultProps = {
  item: DataItem
}

type Props = DefaultProps

const RowItem: React.FC<Props> = ({ item }) => {
  const { ProjectCode, CheckNumber } = item
  const path = `/report/constructplan/${ProjectCode}/${CheckNumber}`

  const renderData = (val: any, span: number = 4) => {
    return <Col span={span}>{val}</Col>
  }
  const isPass = item.CheckStatus === 'Pass'

  const inSpectScore = (
    <>
      {isPass ? (
        <PassScore>{numberFormat(item.InspectionScore)}</PassScore>
      ) : (
        <FailScore>{numberFormat(item.InspectionScore)}</FailScore>
      )}
    </>
  )

  const returnDate =
    item.NumOfReturnDate === 0 ? 'ยังไม่ตอบกลับ' : `${item.NumOfReturnDate} วัน`

  const totalReturnDate = `${item.TotalReturnDate} วัน`

  const displayTotalReturnDate =
    item.TotalReturnDate === 0 ||
    item.TotalReturnDate <= item.ConfigReturnDate ? (
      <PassScore>{totalReturnDate}</PassScore>
    ) : (
      <FailScore>{totalReturnDate}</FailScore>
    )

  return (
    <RowModel>
      <Link to={path}>
        <TableRow minHeight={60} spaceBetweenRow={8} paddingLeft={24}>
          {renderData(
            <Bold>
              {item.ProjectCode}-{item.ProjectNameTH}
            </Bold>,
            5
          )}
          {renderData(<TxtReg>{item.CheckNumber}</TxtReg>, 3)}
          {renderData(<TxtReg>{returnDate}</TxtReg>, 3)}
          {renderData(<TxtReg>{displayTotalReturnDate}</TxtReg>, 3)}
          {renderData(
            <TxtReg>{toDateText(toMomentDateTime(item.InspectDate))}</TxtReg>,
            3
          )}
          {renderData(inSpectScore, 3)}
          {renderData(<TxtReg>{item.CheckedBy}</TxtReg>, 4)}
        </TableRow>
      </Link>
    </RowModel>
  )
}

const RowModel = styled.div`
  cursor: pointer;
`

const Bold: any = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  color: #000;
  font-size: 20px;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  padding-right: 5px;
`

const TxtReg = styled(Bold)`
  font-family: 'DB Helvethaica X 55 Regular';
`

const Score: any = styled.div`
  font-size: 20px;
  font-family: 'DB Helvethaica X 75 Bd';
  cursor: pointer;
`

const PassScore = styled(Score)`
  color: #63cea6;
`

const FailScore = styled(Score)`
  color: #d0494c;
`

export default RowItem
