import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { Row } from 'antd'
import RadioBoxForm from 'Components/Shared/Form/RadioBox'
import RadioBox from 'Components/Shared/Input/RadioBox'
import { ArrowDownIcon, ArrowUpIcon } from 'Components/Shared/Icon'

import {
  Activity,
  ActivityByCode,
  SelectActivityByCode,
  CommissionDetail,
} from 'features/admin/commission/types'
import SearchSelection from 'Components/Inspection/Activity/ActivityList/SearchSelection'
import { textToLowerCase } from 'utils/format'

type Props = {
  commissionDetail: CommissionDetail
  ActvityList: Activity[]
  projectCode: string
  selectActivityByCode: SelectActivityByCode
}

const filterActivity = (modalList: Activity[], filterText: string) => {
  return modalList.filter((item: Activity) => {
    if (filterText === '') return true
    return textToLowerCase(item.ActivityName).includes(
      textToLowerCase(filterText)
    )
  })
}

function calculateOnInitiate(
  ActvityList: Activity[],
  commissionDetail: CommissionDetail
) {
  let selectCount = 0
  ActvityList.map(a => {
    if (a.CheckListID === commissionDetail.CheckListID) {
      selectCount += 1
    }

    if (textToLowerCase(a.Status) === 'used') {
      a.isReadOnly = true
      a.isSelect = true

      if (
        a.CheckListID !== 0 &&
        a.CheckListID === commissionDetail.CheckListID
      ) {
        a.detail = 'ใช้แล้ว'
      } else {
        a.detail = 'ใช้แล้วกับ Checklist อื่น'
      }
    } else if (
      a.CheckListID !== 0 &&
      a.CheckListID === commissionDetail.CheckListID
    ) {
      a.isSelect = true
      a.isReadOnly = false
    } else if (a.CheckListID !== 0) {
      a.isSelect = true
      a.isReadOnly = true
      a.detail = 'ผูกแล้วกับ Checklist อื่น'
    }
  })

  return selectCount
}

const ActivityCom: React.ComponentType<Props> = ({
  selectActivityByCode,
  projectCode: code,
  ActvityList,
  commissionDetail,
}) => {
  const [filterText, setfilterText] = useState<string>('')
  const [act, setAct] = useState<ActivityByCode>({})
  const [_, setstate] = useState(1)
  const [isHide, setIsHide] = useState(false)
  const [all, setAll] = useState(false)
  const countSelect = useRef(0)

  useEffect(() => {
    if (code) {
      const cc = calculateOnInitiate(ActvityList, commissionDetail)
      countSelect.current = cc
      act[code] = ActvityList

      setAct(act)
      setstate(prev => ++prev)
      selectActivityByCode[code] = []
    }
  }, [code, ActvityList])

  function onSearch(text: string) {
    setfilterText(text)
  }

  const selectAct = (val: boolean, item: Activity) => {
    item.isSelect = val
    setstate(prev => ++prev)

    const index = selectActivityByCode[code].findIndex(
      a => a.ActivityID === item.ActivityID
    )
    if (index !== -1) {
      selectActivityByCode[code].splice(index, 1)
      countSelect.current -= 1
    } else {
      let Selected
      if (val) {
        countSelect.current += 1
        Selected = 'Y'
      } else {
        countSelect.current -= 1
        Selected = 'N'
      }
      selectActivityByCode[code].push({
        ActivityID: item.ActivityID,
        Selected,
      })
    }
  }

  const selectAll = (val: boolean) => {
    setAll(val)

    act[code].map(a => {
      const { isReadOnly = false } = a
      if (isReadOnly === false) {
        selectAct(val, a)
      }
    })
  }

  let content: JSX.Element[] = []
  let filterList: Activity[] = []

  if (act[code] && !isHide) {
    filterList = filterActivity(act[code], filterText)

    content = filterList.map((act: Activity, index: number) => {
      return (
        <List key={index}>
          <Row type="flex" align="middle" justify="space-between">
            <RadioBox
              value={!act.isSelect}
              label={act.ActivityName}
              isSelected={act.isSelect}
              readOnly={act.isReadOnly}
              text={act.detail}
              onClick={value => selectAct(value, act)}
            />
          </Row>
        </List>
      )
    })
  }

  return (
    <>
      <TitleSecond>
        ผูกรายการ Primavera activity ({countSelect.current})
      </TitleSecond>
      <SearchSelection onChange={onSearch} />

      <Line />
      <Content>
        {act[code] && (
          <Row
            type="flex"
            align="middle"
            justify="space-between"
            style={{ marginBottom: 6 }}
          >
            <RadioBoxForm
              value={!all}
              label={'Commissioning'}
              isCheck={all}
              readOnly={false}
              onClick={selectAll}
            />
            {isHide ? (
              <ArrowUpIcon onClick={() => setIsHide(false)} />
            ) : (
              <ArrowDownIcon onClick={() => setIsHide(true)} />
            )}
          </Row>
        )}

        {content}
      </Content>
    </>
  )
}

const TitleSecond = styled.h2`
  font-size: 24px;
  font-family: 'DB Helvethaica X 75 Bd';
`

const Line = styled.div`
  margin-top: 10px;
`

const List = styled.div`
  font-size: 20px;
  width: 100%;
  padding: 6px 0;
  margin-left: 16px;
`
const Content = styled.div`
  padding: 20px 24px;
  min-height: 550px;
  max-height: 1500px;
  overflow-y: scroll;
  font-family: 'DB Helvethaica X 55 Regular';
  background-color: white;
  border-radius: 5px;
  .colapse-tree {
    background-color: #fff;
    border: none;
    .ant-collapse-item {
      border: none;
    }
    .ant-collapse-content {
      border: none;
      .ant-collapse-content-box {
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }
`

export default ActivityCom
