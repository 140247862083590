import React from 'react'
import { Event } from 'features/types/event'
import { getEvent } from 'utils/helper'

import AlertError from '../AlertError'
import AlertSuccess from '../AlertSuccess'
import LoadingView from '../LoadingView'

interface Props {
  event: Event[]
  err?: Event[]
  enable?: boolean
  successCalback?: () => void
  errorCalback?: () => void
  name?: string
}

const EventModal: React.FC<Props> = (props) => {
  const {
    event,
    err = [],
    enable = false,
    successCalback = () => {},
    errorCalback = () => {},
  } = props

  const evt = getEvent(event, err)
  return (
    <>
      <AlertError
        text={evt.SubmitError}
        isShow={!!evt.SubmitError && enable}
        callback={errorCalback}
      />
      <AlertSuccess
        isShow={evt.SubmitSuccess && enable}
        text={evt.MessageSuccess}
        callback={successCalback}
      />
      <LoadingView isShow={evt.IsLoading} />
    </>
  )
}

export default EventModal
