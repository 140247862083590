import React, { useEffect, useState } from 'react'
import TaskLaneCollection from 'Components/TaskLaneCollection'
import SubnavBar from 'Components/Shared/SubnavBar'
import { Layout, Row, Col } from 'antd'
import MessageBox from '../../messages/MessageBox'
import { connect, useDispatch } from 'react-redux'
import { BreadcrumbLink } from '../../Shared/BreadCrumb/BreadCrumb'
import RootState from 'typings/RootState'
import {
  errorSelector,
  projectListStatusSelector,
} from 'features/project/project.selector'
import {
  isFetchingTasklaneSelector,
  rawTodoSelector,
  rawInProgressSelector,
  rawCompleteSelector,
  sortingSelector,
  rawTotalTodoSelector,
  rawTotalInProgressSelector,
  rawTotalCompletedSelector,
} from 'features/tasklane/tasklane.selector'
import {
  refreshTasklaneAction,
  sortingTask,
} from 'features/tasklane/tasklane.actions'
import { translate } from 'i18n'
import TKeys from 'i18n/translationKey'

import { RouteComponentProps } from 'react-router-dom'
import { clearSubmitError } from 'features/activity/activity.actions'
import TasklaneColumnHeader from 'Components/columns/TasklaneColumnHeader'

import FilterPage from './FilterPage'
import Menu from 'Components/Approval/MasterPlan/Menu'

const { Content } = Layout

const breadcrumbs: BreadcrumbLink[] = [
  {
    url: '/dashboard',
    title: 'Home',
    icon: '/home.png',
    active: true,
    showTitle: false,
  },
  {
    url: '/dashboard',
    title: translate(TKeys.Breadcrumb.dashboard),
    active: false,
    showTitle: false,
  },
]

type Props = ReduxProps & DispatchProps & RouteComponentProps

const DashboardPage: React.ComponentType<Props> = ({
  dashboardError,
  isFetching,
  tasklanes,
  emptyProjectList,
  refreshTasklaneAction,
  sorting,
  sortingTask,
}) => {
  const dispatch = useDispatch()
  useEffect(() => {
    refreshTasklaneAction()
    dispatch(clearSubmitError())
  }, [refreshTasklaneAction, dispatch])

  if (emptyProjectList) {
    return (
      <Content className="mid-content">
        <div className="main-content empty-project-list-message">
          {translate(TKeys.Dashboard.project_list_empty_message)}
        </div>
      </Content>
    )
  } else {
    const content = dashboardError ? (
      <MessageBox message={`Error : ${dashboardError}`} />
    ) : (
      <TaskLaneCollection isFetching={isFetching} tasklanes={tasklanes} />
    )

    return (
      <React.Fragment>
        <Menu selected="dashboard" />
        <SubnavBar breadcrumbs={breadcrumbs} />

        <div style={{ backgroundColor: '#f5f6fa' }}>
          <div className="main-content">
            <FilterPage />
            <Row gutter={40}>
              <Col span={8}>
                <TasklaneColumnHeader
                  onSort={() => {
                    sortingTask('todo')
                  }}
                  type={sorting.todo}
                  orderName={translate(
                    TKeys.Dashboard.header_sort_by_recent_update
                  )}
                  heading={`${translate(TKeys.Dashboard.header_todo)} (${
                    tasklanes.totalTodo
                  })`}
                />
              </Col>

              <Col span={8}>
                <TasklaneColumnHeader
                  onSort={() => {
                    sortingTask('progress')
                  }}
                  orderName={translate(
                    TKeys.Dashboard.header_sort_by_last_update
                  )}
                  type={sorting.progress}
                  heading={`${translate(TKeys.Dashboard.header_in_progress)} (${
                    tasklanes.totalInProgress
                  })`}
                />
              </Col>

              <Col span={8}>
                <TasklaneColumnHeader
                  onSort={() => {
                    sortingTask('complete')
                  }}
                  orderName={translate(
                    TKeys.Dashboard.header_sort_by_last_update
                  )}
                  type={sorting.complete}
                  heading={`${translate(TKeys.Dashboard.header_complete)} (${
                    tasklanes.totalCompleted
                  })`}
                />
              </Col>
            </Row>
          </div>
        </div>

        <Content className="mid-content">
          <div className="main-content">{content}</div>
        </Content>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state: RootState) => {
  const dashboardError = errorSelector(state)

  return {
    dashboardError,
    isFetching: isFetchingTasklaneSelector(state),
    tasklanes: {
      todo: rawTodoSelector(state),
      inProgress: rawInProgressSelector(state),
      completed: rawCompleteSelector(state),
      totalTodo: rawTotalTodoSelector(state),
      totalInProgress: rawTotalInProgressSelector(state),
      totalCompleted: rawTotalCompletedSelector(state),
    },
    sorting: sortingSelector(state),
    emptyProjectList: projectListStatusSelector(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {
  refreshTasklaneAction,
  sortingTask,
}

type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage)
