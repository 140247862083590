import React from 'react'
import styled from 'styled-components'
import { Popover } from 'antd'

type Props = {
  value: string
  onChange: (val: string) => void
}

const ColorList = [
  {
    code: 'white',
    base64:
      'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAAWUlEQVRYR+3SwQkAIAwEQdN/0bGI/YiM/wWZ3Ozunoff+GC8DsEIeAgSrAK1t0GCVaD2NkiwCtTeBglWgdrbIMEqUHsbJFgFam+DBKtA7W2QYBWovQ1+L3gByAWficgIGYEAAAAASUVORK5CYII=',
  },
  {
    code: 'black',
    base64:
      'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAAWUlEQVRYR+3SwQkAIRAEwTH/oM8g+iNH+W+Q2jnbvj38jg/G6xCMgCNIsArU3gYJVoHa2yDBKlB7GyRYBWpvgwSrQO1tkGAVqL0NEqwCtbdBglWg9jb4e8ELS/UoAco5JcQAAAAASUVORK5CYII=',
  },
  {
    code: '#48e9b1',
    base64:
      'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAAXklEQVRYR+3SsQ3AIBAEQb472nVZrsIUsQmyhnwlNH+z3+dbF7/xwXgdghFwESRYBWpvgwSrQO1tkGAVqL0NEqwCtbdBglWg9jZIsArU3gYJVoHa2yDBKlB7G/y94AHWq3NR4JeYhAAAAABJRU5ErkJggg==',
  },
  {
    code: '#ffc64d',
    base64:
      'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAAW0lEQVRYR+3SwQ0AEBQFQQrUtrYoYi8i476JzH/z7HXGw2/6YLwOwQg4CBKsArW3QYJVoPY2SLAK1N4GCVaB2tsgwSpQexskWAVqb4MEq0DtbZBgFai9DX4veAGrUnrRCrGvugAAAABJRU5ErkJggg==',
  },
  {
    code: '#f03232',
    base64:
      'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAAXElEQVRYR+3SQQkAIBQFQa1k/1A20BB7ERnvCzL/zb3WGQ+/6YPxOgQj4CBIsArU3gYJVoHa2yDBKlB7GyRYBWpvgwSrQO1tkGAVqL0NEqwCtbdBglWg9jb4veAFEphdIRzkg2cAAAAASUVORK5CYII=',
  },
  {
    code: '#3982e0',
    base64:
      'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAAXklEQVRYR+3SsQ3AIBAEQb4s2nOl7sQUsQmyhnwlNH+zn/dbF7/xwXgdghFwESRYBWpvgwSrQO1tkGAVqL0NEqwCtbdBglWg9jZIsArU3gYJVoHa2yDBKlB7G/y94AHT3mg5P6X+EgAAAABJRU5ErkJggg==',
  },
]

const ColorToolMenu: React.ComponentType<Props> = props => {
  const index = ColorList.findIndex(item => item.code === props.value)
  const color = index === -1 ? ColorList[0] : ColorList[index]

  const list = ColorList.map((item, index) => {
    return (
      <ColorItem
        key={index}
        src={item.base64}
        alt=""
        onClick={() => props.onChange(item.code)}
      />
    )
  })

  const content = <PageMenu>{list}</PageMenu>
  let css
  if (props.value === 'black') css = { border: '1px solid #8b99a7' }
  return (
    <Popover overlayClassName="tools-popup-menu" content={content}>
      <ColorItem style={css} src={color.base64} alt="" />
    </Popover>
  )
}

const PageMenu = styled.div`
  padding: 21px 24px;
  height: 80px;
  background-color: #4e4f50;
  border-radius: 5px;
  display: flex;
  align-items: center;

  img {
    margin-left: 16px;
  }

  img:first-child {
    margin-left: 0px;
  }
`

const ColorItem = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 50%;

  :hover {
    cursor: pointer;
  }
`

export default ColorToolMenu
