import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'antd'
import { PrimaryButton } from 'Components/Shared/Button'

type Props = {
  onSave: () => void
  isLoading?: boolean
}

const SaveFooter: React.FC<Props> = ({ onSave, isLoading = false }) => {
  return (
    <FooterContent>
      <Row type="flex" justify="end" align="middle">
        <Col>
          <div className="btn-submit">
            <PrimaryButton
              text="บันทึก"
              disabled={isLoading}
              onClick={onSave}
            />
          </div>
        </Col>
      </Row>
    </FooterContent>
  )
}

const FooterContent = styled.div`
  max-width: 1110px;
  margin: 0 auto;
  color: #000;
  font-size: 20px;

  .btn-submit {
    width: 160px;
    text-align: right;
    float: left;
    margin-right: 16px;
  }
`

export default SaveFooter
