import { TaskStatus, Task } from 'features/tasklane/tasklane.types';

export const dummyActivity: Task = {
  RowID: 1,
  ActivityID: '',
  ActivityName: '',
  BaselineStartDate: '01/06/2017',
  BaselineFinishDate: '14/06/2017',
  StartDate: '01/06/2017',
  FinishDate: '14/06/2017',
  ActualStartDate: '01/06/2017',
  ActualFinishDate: '',
  SowID: '',
  SowName: '',
  WbsID: 30239,
  WbsName: '',
  Progress: 0,
  LastCurProgress: 0,
  LastReqProgress: 0,
  TaskStatus: 'REJT' as TaskStatus,
};
