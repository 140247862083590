import React from 'react'
import styled from 'styled-components'
import {
  CheckedIcon,
  UncheckedIcon,
  DisableCheckedIcon,
  DisableUncheckedIcon,
} from 'Components/Shared/Icon'

interface Props {
  label: string
  value: any
  isCheck?: boolean
  onClick?: (value: any) => void
  spaceBtwText?: number
  disabled?: boolean
  readOnly?: boolean
  size?: number
}

const RadioBox: React.FC<Props> = ({
  label = 'Please Select',
  value,
  isCheck = false,
  spaceBtwText = 8,
  disabled = false,
  readOnly = false,
  onClick,
  size = 16

}) => {
  const handleClick = (evt: any) => {
    evt.stopPropagation()
    if (onClick && !readOnly) {
      onClick(value)
    }
  }

  let css
  let checkIcon

  if (readOnly) {
    const color = isCheck ? 'black' : '#8b99a7'
    css = { color }
    checkIcon = isCheck ? (
      <DisableCheckedIcon disabled={true} size={size}/>
    ) : (
      <DisableUncheckedIcon disabled={true} size={size}/>
    )
  } else {
    if (disabled === false) {
      css = { cursor: 'pointer' }
    }
    checkIcon = isCheck ? (
      <CheckedIcon disabled={disabled} size={size}/>
    ) : (
      <UncheckedIcon disabled={disabled} size={size}/>
    )
  }

  return (
    <ItemView onClick={handleClick} style={css}>
      <Icon style={{ marginRight: spaceBtwText + 'px' }}>{checkIcon}</Icon>
      {label}
    </ItemView>
  )
}

const ItemView = styled.div`
  font-family: 'DB Helvethaica X 55 Regular';
  font-size: 18px;

  .ant-checkbox-wrapper > .ant-checkbox > .ant-checkbox-inner {
    border-radius: 0px !important;
  }
`

const Icon = styled.div`
  margin-top: -2px;
  float: left;
`

export default RadioBox
