import React, { useState, useEffect } from 'react'
import RootState from 'typings/RootState'
import { connect, useDispatch } from 'react-redux'
import { Row, Col, Layout } from 'antd'
import styled from 'styled-components'

import EventModal from 'Components/Shared/Modal/EventModal'

import {
  selectReport,
  selectDefect,
  selectEvent,
} from 'features/report/auditRoad/selector'

import { setHowToFixReq, updateDefect } from 'features/report/auditRoad/actions'

import { verifyDefect } from 'features/report/auditRoad/api'

import {
  userEmailSelector,
  serviceTokenSelector,
} from 'features/sensitive/sensitive.selector'

import { selectUploadEvent } from 'features/uploadfile/selector'

import { SendDefectReq, VerifyDefectReq } from 'features/report/auditRoad/types'

import DefectStatus from 'Components/Defect/DefectStatus'
import DefectImage from '../DefectImage'
import SaveFooter from './SaveFooter'
import ApproveFooter from './ApproveFooter'
import { RejectModal } from 'Components/Activity'
import WarningText from 'Components/Shared/WarningText'

const { Content } = Layout

type Props = {
  ProjectCode: string
  RequestID: number
  readOnly?: boolean
  onClose?: () => void
  onCompeted?: () => void
  isApprove?: boolean
} & ReduxProps

const DefectView: React.FunctionComponent<Props> = props => {
  const [enableModal, setEnableModal] = useState(false)
  const [showRejectModal, setShowRejectModal] = useState(false)
  const noFunc = () => {}
  const {
    Token,
    UserEmail,
    ProjectCode,
    RequestID,
    report,
    defect,
    isApprove = false,

    onClose = noFunc,
    onCompeted = noFunc,
    event,
    uploadEvent,
  } = props

  let { readOnly = false } = props

  // set for view on qc inprogress
  readOnly =
    report.State === 'QC' && report.Status === 'INPRO' ? true : readOnly

  const dispatch = useDispatch()

  const closeEnableModal = () => setEnableModal(false)
  const [howToFixTxt, setHowToFixTxt] = useState('')
  useEffect(() => {
    setHowToFixTxt(defect.HowToFix)
  }, [defect.HowToFix])

  const onSaveCompleted = () => {
    closeEnableModal()
    onCompeted()
  }

  const updateAfterImage = (file: any) => {
    defect.ImageAfter = file
    dispatch(updateDefect({ ...defect }))
  }

  const onHowToFix = (value: string) => {
    defect.HowToFix = value
    dispatch(updateDefect({ ...defect }))
  }

  const onSave = () => {
    onHowToFix(howToFixTxt)
    setEnableModal(true)
    const req: SendDefectReq = {
      ProjectCode,
      UserEmail,
      Token,
      RequestID,

      Report: report,
      Defect: defect,
    }

    dispatch(setHowToFixReq(req))
  }

  const onVerify = (status: string, remark: string) => {
    setEnableModal(true)
    const req: VerifyDefectReq = {
      ProjectCode,
      UserEmail,
      RequestID,

      PinID: defect.PinID,

      VerifyStatus: status,
      VerifyRemark: remark,
    }

    dispatch(verifyDefect(Token, req))
  }

  const getMethodType = () => {
    switch (defect.MethodTypeID) {
      case 1:
        return `${defect.WideOfDefect} x ${defect.LenOfDefect} ตารางเมตร`

      case 2:
        return `${defect.LenOfDefect} เมตร`

      case 3:
        return `${defect.NumOfPoint} จุด`
      default:
        return '-'
    }
  }

  const getFixMethod = () => {
    if (defect.FixName && defect.FixOther) {
      return `${defect.FixName} - ${defect.FixOther}`
    }
    if (defect.FixName) {
      return `${defect.FixName}`
    }
    if (defect.FixOther) {
      return `${defect.FixOther}`
    }
    return ''
  }

  const title: string = `หมุดที่ ${defect.PinNumber}`
  const renderInfo = (title: string, value: string, span: number = 8) => {
    return (
      <Col span={span}>
        <DisplayItem>
          <label>{title}</label>
          <p>{value ? value : '-'}</p>
        </DisplayItem>
      </Col>
    )
  }

  const canChange =
    defect.PinStatus === 'ToBeFix' &&
    defect.VerifyStatus !== 'Approve' &&
    report.CheckNumber === defect.CheckNumber

  const howToFix =
    readOnly || canChange === false ? (
      <Text>
        <Row>{renderInfo('แนวทางแก้ไข', defect.HowToFix, 24)}</Row>
      </Text>
    ) : (
      <Input>
        <Textarea
          placeholder="แนวทางแก้ไข"
          value={howToFixTxt}
          onChange={(evt: any) => setHowToFixTxt(evt.target.value)}
          rows={2}
          maxLength={500}
        />
      </Input>
    )

  const rejectReason = isApprove ? (
    undefined
  ) : (
    <WarningText title="เหตุผลที่ไม่อนุมัติ" value={defect.VerifyRemark} />
  )

  let footer
  if (canChange) {
    if (isApprove && defect.VerifyStatus === 'None') {
      footer = (
        <ApproveFooter
          onReject={() => setShowRejectModal(true)}
          onApprove={() => onVerify('Approve', '')}
          onAccept={() => onVerify('Accept', '')}
        />
      )
    } else if (readOnly === false) {
      footer = <SaveFooter onCancel={onClose} onSave={onSave} />
    }
  }

  return (
    <>
      <EventModal
        event={[event]}
        err={[uploadEvent]}
        enable={enableModal}
        successCalback={onSaveCompleted}
        errorCalback={closeEnableModal}
      />
      <Content className="mid-content">
        <div className="main-content activity-detail">
          <HeaderTitle>
            <Row type="flex" justify="space-between">
              <Col>
                <Title>
                  {title}
                  <DefectStatus pinStatus={defect.PinStatus} />
                </Title>
              </Col>
            </Row>
          </HeaderTitle>
          <MainData>
            <Row>
              {renderInfo('ลักษณะงานหลัก', defect.JobTypeName)}
              {renderInfo('ลักษณะงานรอง', defect.SubJobTypeName)}
              {renderInfo('รายละเอียด', defect.DetailName)}
            </Row>
            <Row>
              {renderInfo('ปริมาณ', getMethodType())}
              {renderInfo('วิธีแก้ไข', getFixMethod())}
              {renderInfo('พื้นที่', defect.Remark)}
            </Row>
            <Row>{rejectReason}</Row>
          </MainData>
          <DefectImage
            defect={defect}
            onImage={(image: any, name: string) => updateAfterImage(image)}
            viewMode={true}
            canUpAfterImg={!readOnly && canChange}
            content={howToFix}
          />
        </div>
      </Content>
      {footer}
      <RejectModal
        visible={showRejectModal}
        onClose={() => setShowRejectModal(false)}
        onSubmit={(val: string) => onVerify('Reject', val)}
        title="ไม่อนุมัติ"
        placeholder="เหตุผลที่ไม่อนุมัติ"
      />
    </>
  )
}

const Title = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 28px;

  span {
    font-family: 'DB Helvethaica X 55 Regular';
    font-size: 16px;
    color: white;
    padding: 1px 10px;
    border-radius: 10px;
    margin-left: 12px;
  }
`

const MainData = styled.div`
  border-radius: 5px;
  border: solid 1px #eeeff2;
  background-color: white;
  padding: 24px;
`

const DisplayItem = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 20px;
  padding: 0 5px;
  p {
    font-family: 'DB Helvethaica X 55 Regular';
  }
`

const HeaderTitle = styled.div`
  margin-bottom: 10px;
`

const Input = styled.div`
  margin: 15px;
`

const Textarea = styled.textarea`
  width: 100%;
  border-radius: 5px;
  height: 96px;
  padding: 16px 19px;
  font-family: 'DB Helvethaica X 55 Regular';
  font-size: 20px;

  border: 1px solid #e4e7eb;
  outline: #e4e7eb auto 1px;

  &::focus {
    outline: #e4e7eb auto 1px;
  }

  &::placeholder {
    font-family: 'DB Helvethaica X 55 Regular';
    font-size: 20px;
  }
`

const Text = styled.div`
  padding: 0px 15px;
`

const mapStateToProps = (state: RootState) => {
  return {
    Token: serviceTokenSelector(state),
    UserEmail: userEmailSelector(state),

    report: selectReport(state),
    defect: selectDefect(state),
    event: selectEvent(state),
    uploadEvent: selectUploadEvent(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(DefectView)
