import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'

import SearchResultPage from './Pages/SearchResultPage'
import SeeMorePage from './Pages/SeeMorePage'
import ActivityDetailPage from './Pages/ActivityDetailPage'
import DashboardPage from './Pages/DashboardPage'

import { Layout } from 'antd'
import TopNav from 'Components/Shared/TopNav'
import TokenExpired from 'Components/Pages/TokenExpired'
import Siricon from './Siricon'
type Props = {}

const MasterApp: React.FunctionComponent<Props> = props => {
  return (
    <BrowserRouter>
      <Layout className="layout" hasSider={false}>
        <TopNav />
        <Route exact path="/" component={DashboardPage} />
        <Switch>
          <Route exact path="/dashboard" component={DashboardPage} />
          <Route
            exact
            path="/activity-detail/:code"
            component={ActivityDetailPage}
          />
          <Route
            exact
            path="/see-more/type/:type/page/:page"
            component={SeeMorePage}
          />
          <Route exact path="/search-results" component={SearchResultPage} />
          <Route exact path="/token-expired" component={TokenExpired} />
          <Route exact path="/siricon-plan" component={Siricon} />
        </Switch>
      </Layout>
    </BrowserRouter>
  )
}

export default MasterApp
