import React from 'react'
import styled from 'styled-components'
import { AdminProject } from 'Components/Shared'

type Props = {
  onFilterChange: (filter: any) => void
  filter: any
  projectsById: any
}

const ProjectsFilter: React.FunctionComponent<Props> = props => {
  const { onFilterChange, filter, projectsById } = props

  return (
    <Box>
      <AdminProject
        placeholder=""
        projectList={projectsById}
        onSelectProject={(val: any) => {
          filter.ProjectCode = val
          onFilterChange(filter)
        }}
        activeProject={filter.ProjectCode}
      />
    </Box>
  )
}

const Box = styled.div`
  float: left;
  margin-right: 8px;
  margin-top: 5px;
  button,
  button:hover,
  button:active,
  button:focus {
    border-radius: 20px;
    height: 40px;
    font-size: 18px;
  }
`

export default ProjectsFilter
