import React from 'react'
import styled from 'styled-components'
import { UncheckedIcon, CheckedIcon } from 'Components/Shared/Icon'

interface Props {
  onClick: () => void
  isSelected?: boolean
  size?: number
}

const CheckIconBox: React.FC<Props> = props => {
  const { onClick, size = 16, isSelected = false } = props

  return (
    <CheckBoxIcon
      onClick={(evt: any) => {
        evt.preventDefault()
        onClick()
      }}
    >
      {isSelected ? <CheckedIcon size={size} /> : <UncheckedIcon size={size} />}
    </CheckBoxIcon>
  )
}

const CheckBoxIcon = styled.div`
  padding-right: 24px;
  float: left;
`

export default CheckIconBox
