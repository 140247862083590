import withAPIAction from 'utils/withAPIAction'
import { verifyReq } from 'utils/verify'
import { getCheckingDataAction, sendReportAction } from './actions'
import { GetActivityReq, SendReportReq } from './types'

export const getCheckingConstructRoadByCM = (
  token: string,
  data: GetActivityReq
) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<GetActivityReq>({
    method: 'POST',
    path: 'GetCheckingConstructRoadByCM',
    data,
    token: `Bearer ${token}`,
  })(getCheckingDataAction)
}

export const sendConstructRoadToQC = (token: string, data: SendReportReq) => {
  console.log('sendConstructRoadToQC')
  console.log('data', data)
  console.log('token', token)
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<SendReportReq>({
    method: 'POST',
    path: 'SendConstructRoadToQCA',
    data,
    token: `Bearer ${token}`,
  })(sendReportAction)
}
