import React from 'react'
import styled from 'styled-components'
import { AuditRoadInpro } from 'features/qc/road/types'
import { Row, Col, Modal } from 'antd'
import { UndoIcon } from 'Components/Shared/Icon'
import iconLogout from 'assets/img/ic-approval-logout.png'

import CheckIconBox from '../../CheckIconBox'
import SignDescription from '../../SignDescription'
import { getRoadLocation } from 'utils/getText'

interface Props {
  task: AuditRoadInpro
  isSendReport?: boolean
  isSelected?: boolean
  onSelectLists: (task: AuditRoadInpro) => void
  onCancel: (task: AuditRoadInpro) => void
}

const InproCard: React.FC<Props> = props => {
  const {
    task,
    isSendReport = false,
    onSelectLists,
    isSelected = false,
    onCancel,
  } = props
  const {
    ProjectCode,
    ProjectNameTH,
    CheckNumber,
    TotalLocation,
    NumOfSelectLocation,

    HasSign,
    HasSelectPin,
  } = task

  const showModal = () => {
    Modal.confirm({
      onOk() {
        onCancel(task)
      },
      width: '327px',
      onCancel() {},
      content: (
        <ModalContent>
          <img src={iconLogout} />
          <div className="message">
            <p>คุณยืนยันที่จะคืนผัง</p>
            <p>
              {ProjectCode} - {ProjectNameTH} ใช่หรือไม่?
            </p>
          </div>
        </ModalContent>
      ),
      okText: 'ใช่',
      okButtonProps: {
        className: 'btn-rectangle ok-btn',
      },
      cancelText: 'ยกเลิก',
      cancelButtonProps: {
        className: 'btn-rectangle cancle-btn',
      },
      centered: true,
      icon: null,
      className: 'login-modal-window',
    })
  }

  const cancelIcon = (
    <CancelIcon
      onClick={(evt: any) => {
        evt.preventDefault()
        if (isSendReport) return
        showModal()
      }}
    >
      <UndoIcon size={14} color={isSendReport ? '#8b99a7' : '#003168'} />
      <CancleTxt className={isSendReport ? 'disabled' : ''}>คืนผัง</CancleTxt>
    </CancelIcon>
  )

  const leftContent = (
    <LeftBox>
      <Row type="flex" align="middle">
        <Col>
          <TxtBold>
            {ProjectCode} - {ProjectNameTH}
          </TxtBold>

          <TxtReg>{getRoadLocation(task)}</TxtReg>
          <TxtReg>ตรวจครั้งที่ {CheckNumber}</TxtReg>
        </Col>
      </Row>
    </LeftBox>
  )

  const signContent = (
    <RightBox>
      <Row type="flex" align="middle">
        <Col>
          <SignDescription
            label="เลือกพื้นที่"
            isCorrect={NumOfSelectLocation > 0}
          />
        </Col>
      </Row>
      <Row type="flex" align="middle">
        <Col span={12}>
          <SignDescription label="รูปส่งรายงาน" isCorrect={HasSelectPin} />
        </Col>
        <Col span={12}>
          <SignDescription label="ลงชื่อรับทราบ" isCorrect={HasSign} />
        </Col>
      </Row>
      {cancelIcon}
    </RightBox>
  )

  return (
    <CardContent>
      <Row>
        <Col span={isSendReport ? 2 : 0}>
          <CheckBox>
            {HasSign ? (
              <CheckIconBox
                onClick={() => onSelectLists(task)}
                isSelected={isSelected}
              />
            ) : (
              <Space />
            )}
          </CheckBox>
        </Col>

        <Col span={isSendReport ? 22 : 24}>
          {leftContent}

          {signContent}
        </Col>
      </Row>
    </CardContent>
  )
}

const CardContent = styled.div`
  border-radius: 10px;
  background-color: #fff;
  margin-bottom: 12px;
`

const CheckBox = styled.div`
  margin-top: 8px;
  margin-left: 20px;
`

const LeftBox = styled.div`
  padding: 16px 20px;
`

const RightBox = styled.div`
  padding: 10px 0px;
  margin: 0px 20px;
  border-top: 1px solid #e8ebf0;
`

const TxtBold = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 20px;
  color #000;
`

const TxtReg = styled.div`
  font-family: 'DB Helvethaica X 55 Regular';
  font-size: 20px;
  color #000;
`

const Space = styled.div`
  width: 40px;
  height: 1px;
  float: left;
`

const CancleTxt = styled.span`
  font-family: 'DB Helvethaica X 75 Bd';
  margin-left : 10px;
  color #003168;
  font-size: 18px;
  &.disabled{
    color : #8b99a7;
  }
`

const CancelIcon = styled.div`
  margin-top: -10px;
`

const ModalContent = styled.div`
  text-align: center;
  border-radius: 10px;
  .message {
    padding-top: 18px;
    p {
      font-size: 20px;
      color: #000;
      margin-bottom: 0;
    }
  }
  img {
    width: 60px;
  }
`
export default InproCard
