import React from 'react'
import img from 'assets/img/ic-add-white.png'
import styled from 'styled-components'

interface Props {
  icon?: any
  onClick ?: () => void
}

const FloatButton = (props: Props) => {
  const { icon = img,onClick } = props
  return (
    <Button onClick={onClick}>
      <img src={icon} />
    </Button>
  )
}

const Button = styled.div`
  width: 60px;
  height: 60px;
  background-color: #003168;
  color: #fff;
  border-radius: 50%;
  text-align: center;
  position: absolute;
  bottom: 150px;
  right: 80px;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.3);
  cursor: pointer;
  
  img {
    width: 28px;
    margin-top: 15px;
  }
`

export default FloatButton
