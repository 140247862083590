import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { connect, useDispatch } from 'react-redux'
import { Layout, Row, Col } from 'antd'
import { RouteComponentProps } from 'react-router-dom'
import RootState from 'typings/RootState'
import {
  userEmailSelector,
  serviceTokenSelector,
} from 'features/sensitive/sensitive.selector'
import { selectData, selectEvent } from 'features/report/imagelist/selector'
import {
  getImageListByQCApi,
  saveImageListByQCApi,
} from 'features/report/imagelist/api'
import {
  GetImageListReq,
  SaveImageListReq,
} from 'features/report/imagelist/types'
import { updateImageList } from 'features/report/imagelist/actions'
import Breadcrumbs from './Breadcrumbs'
import { EventModal } from 'Components/Shared'
import ImageBox from './ImageBox'
import ThumbnailDisplay from 'Components/Shared/ThumbnailDisplay'
import ImageDisplay from 'Components/Shared/ImageDisplay'
import { PrimaryButton } from 'Components/Shared/Button'
import { GetUnitReportReq } from 'features/report/unit/types'
import { getUnitReport } from 'features/report/unit/api'
import { selectReport } from 'features/report/unit/selector'
import ChooseImage from './ChooseImage'
import { FileDescription } from 'features/types/file'

const { Content } = Layout

type Props = ReduxProps &
  DispatchProps &
  RouteComponentProps<{
    project: string
    unit: string
    qcType: string
    checkno: string
    requestID: string
    qcTypeVersion: string
  }>

const ImageReport: React.ComponentType<Props> = props => {
  const { data, token, UserEmail, match, event, history, report } = props
  const [enableModal, setEnableModal] = useState(false)
  const [previewImg, setPreviewImg] = useState(false)
  const [chooseType, setChooseType] = useState('' as 'good' | 'bad')
  const [activePage, setActivePage] = useState('' as
    | 'seemore'
    | 'chooseimg'
    | '')
  const [imageSelected, setImageSelected] = useState(0)
  const [imageList, setImageList] = useState([] as any[])
  const { params } = match
  const { project, unit, checkno, requestID, qcType, qcTypeVersion } = params
  const RequestID = parseInt(requestID)
  const CheckNumber = parseInt(checkno)
  const dispatch = useDispatch()
  let reportPage = `/unit/report/${project}/${unit}/${qcType}/${requestID}`

  useEffect(() => {
    setEnableModal(false)
  }, [data, project, checkno, requestID, unit, dispatch])

  useEffect(() => {
    const req: GetImageListReq = {
      UserEmail: UserEmail,
      ProjectCode: project,
      CheckNumber,
      RequestID,
      Unit: unit,
      QCTypeID: qcType,
      TaskType: 'unit',
    }
    dispatch(getImageListByQCApi(token, req))
  }, [project, checkno, requestID, unit, dispatch])

  useEffect(() => {
    if (report.Unit !== unit) {
      const params: GetUnitReportReq = {
        UserEmail,
        ProjectCode: project,
        QCType: qcType,
        Unit: unit,
        RequestID,
        Page: 1,
        RowPerPage: 1000,
      }
      dispatch(getUnitReport(token, params))
    }
  }, [project, checkno, requestID, unit, dispatch])

  const onSaveImageList = () => {
    setEnableModal(true)
    const req: SaveImageListReq = {
      UserEmail,
      ProjectCode: project,
      CheckNumber,
      QCTypeID: qcType,
      RequestID,
      TaskType: 'unit',
      Unit: unit,
      BadImage: data.BadImage,
      GoodImage: data.GoodImage,
    }
    dispatch(saveImageListByQCApi(token, req))
  }

  const gotoDashboard = () => {
    setEnableModal(false)
    history.push(reportPage)
  }

  const onChooseImg = (type: 'good' | 'bad') => {
    setActivePage('chooseimg')
    setChooseType(type)
  }

  const updateGoodImage = (images: FileDescription[]) => {
    data.GoodImage = images
    dispatch(updateImageList(data))
  }

  const updateBadImage = (images: FileDescription[]) => {
    data.BadImage = images
    dispatch(updateImageList(data))
  }

  const updateImage = (images: FileDescription[]) => {
    setActivePage('')
    if (chooseType === 'good') {
      updateGoodImage(images)
    } else {
      updateBadImage(images)
    }
  }

  const goodImages = (
    <Row>
      <Col span={24}>
        <ImageBox
          label="เลือกรูปดี"
          Images={data.GoodImage}
          onImageOpen={() => {
            setImageList(data.GoodImage)
            setActivePage('seemore')
          }}
          onThumbnailOpen={(index: number) =>
            onThumbnailOpen(index, data.GoodImage)
          }
          showMax={7}
          onClickAdd={() => onChooseImg('good')}
        />
      </Col>
    </Row>
  )

  const badImages = (
    <Row>
      <Col span={24}>
        <ImageBox
          label="เลือกรูปไม่ดี"
          Images={data.BadImage}
          onImageOpen={() => {
            setImageList(data.BadImage)
            setActivePage('seemore')
          }}
          onThumbnailOpen={(index: number) =>
            onThumbnailOpen(index, data.BadImage)
          }
          showMax={7}
          onClickAdd={() => onChooseImg('bad')}
        />
      </Col>
    </Row>
  )

  const thumnailPreview = (
    <ThumbnailDisplay
      onClose={() => setPreviewImg(false)}
      readOnly={true}
      images={imageList}
      index={imageSelected}
      onChange={images => {}}
    />
  )

  const imageSeeMore = (
    <ImageDisplay
      onClose={() => setActivePage('')}
      readOnly={true}
      images={imageList}
      onThumbnailOpen={(index: number) => {
        onThumbnailOpen(index, imageList)
      }}
      onChange={() => {}}
      numberOfImage={50}
      breadcrumb={
        <Breadcrumbs
          reportPage={reportPage}
          name="รูปทั้งหมด"
          onClose={() => setActivePage('')}
        />
      }
    />
  )
  const chooseGoodImg = chooseType === 'good'
  const chooseImg = (
    <>
      <Breadcrumbs
        reportPage={reportPage}
        name={`เลือกรูป${chooseGoodImg ? 'ดี' : 'ไม่ดี'}`}
        onClose={() => setActivePage('')}
      />
      <ChooseImage
        defect={report.FixFromQC}
        imgType={chooseType}
        selected={chooseGoodImg ? data.GoodImage : data.BadImage}
        onSave={updateImage}
        onCancel={() => setActivePage('')}
      />
    </>
  )

  const onThumbnailOpen = (index: number, list: any[]) => {
    setPreviewImg(true)
    setImageList(list ? list : [])
    setImageSelected(index)
  }

  const renderPage = () => {
    switch (activePage) {
      case 'seemore':
        return imageSeeMore
      case 'chooseimg':
        return chooseImg
      default:
        return (
          <>
            <Breadcrumbs reportPage={reportPage} />
            <EventModal
              event={[event]}
              enable={enableModal}
              successCalback={gotoDashboard}
              errorCalback={() => setEnableModal(false)}
            />
            <Content className="mid-content">
              <div className="main-content activity-detail">
                <Header>
                  <Title>เลือกรูปส่งรายงาน</Title>
                </Header>
                <ContentBoxStyle>
                  {goodImages}
                  <Space />
                  {badImages}
                </ContentBoxStyle>
                <Row type="flex" justify="end">
                  <Col>
                    <Button>
                      <PrimaryButton text="บันทึก" onClick={onSaveImageList} />
                    </Button>
                  </Col>
                </Row>
              </div>
            </Content>
          </>
        )
    }
  }

  return (
    <>
      {previewImg && thumnailPreview}
      {renderPage()}
    </>
  )
}

const Header = styled.div`
  margin-top: 32px;
  margin-bottom: 24px;
`

const Title = styled.span`
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 28px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #000;
`

const ContentBoxStyle = styled.div`
  margin-top: 16px;
  background-color: #fff;
  padding: 21px;
  border-radius: 5px;
  border: solid 1px #eeeff2;
  font-size: 20px;
  font-family: 'DB Helvethaica X 55 Regular';
`

const Space = styled.div`
  margin-top: 16px;
`

const Button = styled.div`
  width: 160px;
  margin-top: 42px;
`

const mapStateToProps = (state: RootState) => {
  return {
    data: selectData(state),
    report: selectReport(state),
    event: selectEvent(state),
    token: serviceTokenSelector(state),
    UserEmail: userEmailSelector(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ImageReport)
