import React, { useState, useEffect, useRef } from 'react'
import { connect, useDispatch } from 'react-redux'
import RootState from 'typings/RootState'
import styled from 'styled-components'

import { selectPrimavera, selectEvent } from 'features/masterData/selector'
import { getPrimaveraList } from 'features/masterData/api'
import { resetPrimaveraActivityList } from 'features/masterData/actions'
import { getActivityByCheckList } from 'features/checklist/master/api'

import {
  updateActivityCheckList,
  resetUpdateActivityCheckList,
} from 'features/checklist/master/actions'
import {
  selectCheckListActivity,
  selectActivityUpdate,
} from 'features/checklist/master/selector'

import {
  userEmailSelector,
  serviceTokenSelector,
} from 'features/sensitive/sensitive.selector'
import { projectsByIdSelector } from 'features/project/project.selector'

import TreeCollapse from 'Components/Shared/Input/TreeCollapse'
import { ConfirmModal } from 'Components/Defect'

import SearchSelection from './SearchSelection'

type FormProps = {
  projectCode: string
  CheckListID: string
}
type Props = FormProps & DispatchProps & ReduxProps

const ActivityList: React.FC<Props> = (props: Props) => {
  const countSelect = useRef(0)
  const countInitSelect = useRef(0)
  const [search, setSearch] = useState('')
  const [activityList, setActivityList] = useState([] as any[])
  const [confirm, setConfirm] = useState(undefined as any)
  const [activityInit, setActivityListInit] = useState([] as any[])
  const {
    primavera,
    activity,
    activityUpdate,
    CheckListID,
    UserEmail,
    Token,
    projectCode,
  } = props

  const dispatch = useDispatch()

  useEffect(() => {
    const currentActivity = [...activity.ActivityList]

    countSelect.current = 0
    const list = getTower(primavera.List, currentActivity)
    setActivityList(list)
    setActivityListInit(list)
    countInitSelect.current = countSelect.current
    
  }, [primavera, activity])

  useEffect(() => {
    if (activity.ProjectCode !== projectCode) {
      getActivityByProject(projectCode)
    }
    const checklistReq: any = {
      UserEmail,
      ProjectCode: projectCode,
    }
    dispatch(getActivityByCheckList(checklistReq, Token))

  }, [projectCode])

  useEffect(() => {
    if (!search && activityInit.length > 0) {
      setActivityList(activityInit)
      countSelect.current = countInitSelect.current
    }
    else {
      // Debounce key search
      const timeOutId = setTimeout(() => {
        const currentActivity = [...activity.ActivityList]
        countSelect.current = 0
        if (currentActivity.length > 0) {
          const list = getTower(primavera.List, currentActivity)
          setActivityList(list)
        }
      }, 1000);
      return () => {
        clearTimeout(timeOutId);
      }
    }
  }, [search])

  const getActivityByProject = (ProjectCode: string) => {
    if (projectCode) {
      const acticityReq: any = {
        UserEmail,
        ProjectCode,
        Type: '',
      }
      dispatch(getPrimaveraList(acticityReq, Token))
    } else {
      dispatch(resetUpdateActivityCheckList())
      dispatch(resetPrimaveraActivityList())
    }
  }

  const calcSelectActivity = (
    selected: boolean,
    item: any,
    updatedList: any[]
  ) => {
    const { id, readOnly, tag } = item
    if (readOnly === true) return updatedList

    const { isNoneChecklist = false } = tag
    const index = updatedList.findIndex(it => it.ActivityID === id)
    if (index > -1) {
      const updated = updatedList[index]
      if (selected === false && isNoneChecklist === false) {
        updatedList.splice(index, 1)
      } else {
        updated.Selected = selected ? 'Y' : 'N'
        updatedList[index] = updated
      }
    } else {
      updatedList.push({
        ActivityID: id,
        Selected: selected ? 'Y' : 'N',
      })
    }

    return updatedList
  }

  const onActivity = (selected: boolean, item: any) => {
    if (item.detail === undefined) {
      const updatedList = calcSelectActivity(selected, item, activityUpdate)
      dispatch(updateActivityCheckList([...updatedList]))
    } else {
      setConfirm({ selected, item })
    }
  }

  const updateActivity = () => {
    if (confirm) {
      const { selected, item } = confirm
      const updatedList = calcSelectActivity(selected, item, activityUpdate)
      dispatch(updateActivityCheckList([...updatedList]))
    }
    setConfirm(undefined)
  }

  const onUpdateActivity = (
    selected: boolean,
    items: any[],
    updatedList: any[]
  ) => {
    items.forEach(it => {
      if (it.items) {
        updatedList = onUpdateActivity(selected, it.items, updatedList)
      } else {
        updatedList = calcSelectActivity(selected, it, updatedList)
      }
    })

    return updatedList
  }

  const onGroupSelected = (selected: boolean, item: any) => {
    const { items = [] } = item
    const updatedList = onUpdateActivity(selected, items, activityUpdate)
    dispatch(updateActivityCheckList([...updatedList]))
  }

  const getTower = (towers: any[], currentActivity: any[]) => {
    const result: any = []
    towers.forEach(it => {
      const items = getConstructionType(
        it.ConstructionTypeList,
        currentActivity
      )
      if (items.length !== 0) {
        const index = items.findIndex((acc: any) => acc.isSelected === false)
        result.push({
          id: it.TowerName,
          name: it.TowerName,
          isSelected: index === -1,
          items,
          onChange: onGroupSelected,
          tag: it,
        })
      }
    })
    return result
  }

  const getConstructionType = (
    constructions: any[],
    currentActivity: any[]
  ) => {
    const result: any = []
    constructions.forEach(it => {
      const items = getFloor(it.FloorList, currentActivity)
      if (items.length !== 0) {
        const index = items.findIndex((acc: any) => acc.isSelected === false)
        result.push({
          id: it.ConstructionTypeName,
          name: it.ConstructionTypeName,
          isSelected: index === -1,
          items,
          onChange: onGroupSelected,
          tag: it,
        })
      }
    })
    return result
  }

  const getFloor = (floors: any[], currentActivity: any[]) => {
    const result: any = []
    floors.forEach(it => {
      const items = getActivityItem(it.List, currentActivity)
      if (items.length !== 0) {
        const index = items.findIndex((acc: any) => acc.isSelected === false)
        result.push({
          id: it.FloorName,
          name: it.FloorName,
          isSelected: index === -1,
          items,
          onChange: onGroupSelected,
          tag: it,
        })
      }
    })
    return result
  }

  const getActivityItem = (itemList: any[], currentActivity: any[]) => {
    const result: any = []
    itemList.forEach(it => {
      const items = getQCType(it.QCTypeList, currentActivity)
      if (items.length !== 0) {
        const index = items.findIndex((acc: any) => acc.isSelected === false)
        result.push({
          id: it.Name,
          name: it.Name,
          isSelected: index === -1,
          items,
          onChange: onGroupSelected,
          tag: it,
        })
      }
    })
    return result
  }

  const getQCType = (qcList: any[], currentActivity: any[]) => {
    const result: any = []
    qcList.forEach(it => {
      const items = getActivity(it.ActivityList, currentActivity)
      if (items.length !== 0) {
        const index = items.findIndex((acc: any) => acc.isSelected === false)
        result.push({
          id: it.QCTypeID,
          name: it.QCTypeName,
          isSelected: index === -1,
          items,
          onChange: onGroupSelected,
          tag: it,
        })
      }
    })
    return result
  }

  const getActivity = (itemList: any[], currentActivity: any[]) => {
    const stxt: string = search.toLowerCase()
    const list = itemList
      .filter(a => a.ActivityName.toLowerCase().includes(stxt))
      .map(it => {
        let index = currentActivity.findIndex(
          acc => acc.ActivityID === it.ActivityID
        )

        let isSelected = false
        let readOnly = false
        let isNoneChecklist = false
        let detail
        let item: any
        if (index > -1) {
          item = currentActivity[index]
          const id = item.CheckListID || ''
          if (id !== '') {
            const isUsed = item.Status.toLocaleLowerCase() === 'used'
            isNoneChecklist = true
            readOnly = id !== CheckListID
            isSelected = id === CheckListID
            if (isSelected) {
              detail = isUsed ? 'ใช้แล้ว' : undefined
              countSelect.current += 1
            } else if (readOnly) {
              detail = `ผูกแล้ว (${id})`
            }
          }
        }

        if (readOnly === false) {
          index = activityUpdate.findIndex(
            acc => acc.ActivityID === it.ActivityID
          )

          if (index > -1) {
            const updateItem = activityUpdate[index]
            isSelected = updateItem.Selected === 'Y'
          }
        }
        const nodeItem = {
          id: it.ActivityID,
          name: it.ActivityName,
          isSelected,
          readOnly,
          detail,
          onChange: onActivity,
          tag: { isNoneChecklist },
        }

        return nodeItem
      })
    return list
  }

  const onSearch = (txt: string) => {
    setSearch(txt)
  }

  return (
    <>
      <TitleSecond>
        ผูกรายการ Primavera activity ({countSelect.current})
      </TitleSecond>
      <SearchSelection onChange={onSearch} />

      <Line />

      <TreeCollapse items={activityList} />
      <ConfirmModal
        onClose={() => setConfirm(undefined)}
        onSubmit={updateActivity}
        title="ยืนยันดำเนินการ"
        desc="Activity นี้ถูกนำไปแล้ว"
        descSecond={`ยืนยันการแก้ไข`}
        visible={confirm !== undefined}
      />
    </>
  )
}

const TitleSecond = styled.h2`
  font-size: 24px;
  font-family: 'DB Helvethaica X 75 Bd';
  margin-top: 60px;
`

const Line = styled.div`
  margin-top: 10px;
`

type ReduxProps = ReturnType<typeof mapStateToProps>
const mapStateToProps = (state: RootState) => {
  return {
    projectsById: projectsByIdSelector(state),
    primavera: selectPrimavera(state),
    activity: selectCheckListActivity(state),
    activityUpdate: selectActivityUpdate(state),
    event: selectEvent(state),
    UserEmail: userEmailSelector(state),
    Token: serviceTokenSelector(state),
  }
}

const mapDispatchToProps = {}
type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(ActivityList)
