import React, { useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { RouteComponentProps, Link } from 'react-router-dom'
import RootState from 'typings/RootState'
import { BreadcrumbLink } from 'Components/Shared/BreadCrumb/BreadCrumb'
import Pagination from 'Components/Shared/Pagination'
import {
  userEmailSelector,
  serviceTokenSelector,
} from 'features/sensitive/sensitive.selector'
import { sortingTask, clearFilter } from 'features/pmr/actions'
import { GetPMRList } from 'features/pmr/api'
import { GetPMRReq, ProjectListData } from 'features/pmr/types'
import {
  selectList,
  selectEvent,
  selectSorting,
  selectFilter,
} from 'features/pmr/selector'
import { NoCard, EventModal } from 'Components/Shared'
import { StructureListPage } from 'Components/Shared/Seemore'
import RowItem from './RowItem'
import TableHeader from './TableHeader'
import { PMRFilter } from 'Components/Filter'

type Props = ReduxProps & DispatchProps & RouteComponentProps<{ type: string }>

const PmrList: React.ComponentType<Props> = props => {
  const { match, data, event, sorting, token, UserEmail, filter } = props

  const { params } = match
  const { type } = params
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [enableModal, setEnableModal] = useState(false)
  const { ProjectList, Total } = data

  useEffect(() => {}, [type])

  const dispatch = useDispatch()

  useEffect(() => {
    callGetPmrList()
  }, [perPage, page])

  const callGetPmrList = () => {
    const data: GetPMRReq = {
      UserEmail,
      Mode: 'PMR',
      Sort: {
        OrderBy: 'ASC',
        SortBy: '',
      },
      UnitList: filter.ZoneList,
      FloorList: filter.FloorList,
      ProjectCode: filter.ProjectCode,
      Page: page,
      RowPerPage: perPage,
    }
    dispatch(GetPMRList(token, data))
  }

  useEffect(() => {
    return () => {
      dispatch(clearFilter())
    }
  }, [])

  const onSearch = () => {
    callGetPmrList()
  }

  let breadcrumbTxt = 'รายการอนุมัติ'
  const breadcrumbs: BreadcrumbLink[] = [
    {
      url: '/',
      title: 'Home',
      icon: '/home.png',
      active: true,
      showTitle: false,
    },
    {
      url: '/',
      title: breadcrumbTxt,
      active: false,
      showTitle: false,
    },
  ]

  const onSort = (name: string) => {
    dispatch(sortingTask({ name }))
  }

  useEffect(() => {
    setEnableModal(false)
  }, [ProjectList])

  const renderContent = () => {
    return ProjectList.map((item: ProjectListData) => {
      return (
        <Link
          to={`/report/${item.ProjectCode}/${item.Unit}/${item.RequestID}/${item.QCTypeID}/${item.Floor}`}
        >
          <RowItem task={item} />
        </Link>
      )
    })
  }

  const pagination = (
    <Pagination
      perPage={perPage}
      totalRows={Total}
      currentPage={page}
      onClickPage={page => setPage(page)}
      onChangeNumber={number => setPerPage(number)}
    />
  )

  const tableHeader = (
    <TableHeader onSort={name => onSort(name)} sorting={sorting} />
  )

  return (
    <>
      <EventModal
        event={[event]}
        enable={enableModal}
        successCalback={() => {}}
        errorCalback={() => {}}
      />
      <StructureListPage
        filterHeader={
          <PMRFilter
            onSearch={() => {
              setPage(1)
              onSearch()
            }}
          />
        }
        breadcrumbs={breadcrumbs}
        tableHeader={tableHeader}
        titleMargin={40}
        tableContent={
          data.ProjectList.length > 0 ? (
            renderContent()
          ) : (
            <NoCard text={`ไม่มีข้อมูล${breadcrumbTxt}`} />
          )
        }
        footerPagination={pagination}
        isCenter={true}
      />
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    data: selectList(state),
    event: selectEvent(state),
    sorting: selectSorting(state),
    token: serviceTokenSelector(state),
    UserEmail: userEmailSelector(state),
    filter: selectFilter(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {
  sortingTask,
}

type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(PmrList)
