import React from 'react'
import { Layout, Row, Col } from 'antd'
import styled from 'styled-components'
import { PrimaryButton, SecondaryButton } from 'Components/Shared/Button'

type Props = {
  onSave: () => void
  onCancel: () => void
  isLoading?: boolean
  cancelTxt?: string
  saveTxt?: string
  description?: string
  total?: number
}
const { Footer } = Layout

const TaskFooter: React.FunctionComponent<Props> = props => {
  const {
    total = 0,
    onSave,
    onCancel,
    isLoading = false,
    cancelTxt = 'ยกเลิก',
    saveTxt = 'บันทึก',
    description = 'รายการในการส่งรายงาน',
  } = props

  const saveBtn = (
    <Btn>
      <PrimaryButton
        text={saveTxt}
        disabled={!isLoading ? false : true}
        onClick={onSave}
      />
    </Btn>
  )

  const cancleBtn = (
    <Btn>
      <SecondaryButton text={cancelTxt} onClick={onCancel} />
    </Btn>
  )

  const renderTextSelected = (selected: number) => {
    if (selected > 0) {
      return <TextGreen>{selected}</TextGreen>
    } else {
      return <TextGray>{selected}</TextGray>
    }
  }

  return (
    <Footer className="detail-page-footer">
      <FooterContent>
        <Row type="flex" justify="space-between" align="middle">
          <Col>
            <Row type="flex" align="middle">
              <Col>{cancleBtn}</Col>
              <Col>
                คุณเลือก {renderTextSelected(total)}
                {description}
              </Col>
            </Row>
          </Col>
          <Col>{saveBtn}</Col>
        </Row>
      </FooterContent>
    </Footer>
  )
}

const FooterContent = styled.div`
  max-width: 1110px;
  margin: 0 auto;
  color: #000;
  font-size: 20px;
  padding-right: 20px;
`

const Btn = styled.div`
  width: 160px;
  margin-right: 24px;
`

const TextGreen = styled.span`
  color: #5bc79e;
  padding: 0 8px;
`

const TextGray = styled.span`
  color: #8b99a7;
  padding: 0 8px;
`

export default TaskFooter
