export const toPosition = (
  x: number,
  y: number,
  width: number,
  height: number
): any => {
  const newX = x + width / 2
  const newY = y + height
  return { x: newX, y: newY }
}

export const toLocalPosition = (
  x: number,
  y: number,
  width: number,
  height: number
): any => {
  const newX = x - width / 2
  const newY = y - height
  return { x: newX, y: newY }
}
