import React from 'react'
import { Col, Progress, Row } from 'antd'
import styled from 'styled-components'
import moment from 'moment'
import { Activity } from 'features/approval/list/types'
import { getProgressColor } from 'utils/color-map'
import { PrimaryButton } from 'Components/Shared/Button'
import { WarningIcon } from 'Components/Shared/Icon'
import { ProgressCircle } from 'Components/Shared'
import { Link } from 'react-router-dom'
import { getShortWbs } from 'features/wbs/wbs.function'

type DefaultProps = {
  item: Activity
  wbsName?: string
  onApproveTask: (item: Activity) => void
  onRejectTask: (item: Activity) => void
}
type Props = DefaultProps

const RowItem: React.FC<Props> = props => {
  const { item, wbsName = '', onApproveTask, onRejectTask } = props

  const isDelayed = true
  const today = moment().startOf('day')
  const delayed = moment(item.BaselineFinishDate, 'DD/MM/YYYY').diff(
    today,
    'days'
  )

  let content
  let lane
  if (delayed < 0) {
    let days = Math.abs(delayed)
    const text = `เกินกำหนดแล้ว ${days} วัน`
    lane = <span className="lane-card-delayed" />
    content = (
      <div className="status status-danger">
        <WarningIcon />
        <span className="countdown">{text}</span>
      </div>
    )
  } else {
    const text = `เหลืออีก ${delayed} วัน`
    content = (
      <div className={'status status-default'}>
        <span className="countdown">{text}</span>
      </div>
    )
  }

  const taskInfo = (
    <Row type="flex" justify="start" align="middle">
      <Col>
        <ProgressDetail>
          <ProgressCircle task={item} width={80} hideStatus={true} />
        </ProgressDetail>
      </Col>
      <Col>
        <ActivityDetail>
          <Remainder>{content}</Remainder>
          <Name>{item.ActivityName}</Name>
          <WbsName>{getShortWbs(wbsName)}</WbsName>
          <Status>รออนุมัติ</Status>
        </ActivityDetail>
      </Col>
    </Row>
  )

  const buttonAction = (
    <Row type="flex" justify="space-between">
      <RejectBtn>
        <PrimaryButton
          text="ไม่อนุมัติ"
          disabled={false}
          onClick={() => onRejectTask(item)}
          btnType="Cancel"
        />
      </RejectBtn>
      <ApproveBtn>
        <PrimaryButton
          text="อนุมัติ"
          disabled={false}
          onClick={() => onApproveTask(item)}
          btnType="Approve"
        />
      </ApproveBtn>
    </Row>
  )

  return (
    <Link to={`/ms/activity/${item.ActivityID}`}>
      <MainCard>
        {lane}
        <Row type="flex" justify="space-between" align="middle">
          <Col>{taskInfo}</Col>
          <Col>{buttonAction}</Col>
        </Row>
        {/* {rejectModal} */}
      </MainCard>
    </Link>
  )
}

const MainCard = styled.div`
  padding: 0 8px;
  background: #fff;
  border-radius: 10px;
  background-clip: padding-box;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.03);
  margin-bottom: 12px;
  cursor: pointer;
  color: #000;
  width: 100%;
  position: relative;
`

const ProgressDetail = styled.div`
  padding: 16px;
  text-align: center;
`

const ActivityDetail = styled.div`
  padding: 16px;
  line-height: 1.4;
`

const Name = styled.div`
  font-size: 24px;
  font-family: 'DB Helvethaica X 75 Bd';
`

const WbsName = styled.div`
  font-size: 18px;
`

const Remainder = styled.div`
  font-size: 18px;
  color: #8b99a7;
`

const Status = styled.div`
  font-size: 18px;
  color: #efc122;
`

const RejectBtn = styled.div`
  width: 120px;
`

const ApproveBtn = styled.div`
  width: 137px;
  margin-left: 16px;
`

export default RowItem
