import { createStandardAction, createAsyncAction } from 'typesafe-actions'
import {
  GetReportReq,
  GetReportResp,
  GetCheckListReq,
  GetCheckListResp,
  DefectTypeReq,
  DefectItem,
  SendDefectReq,
  AuditPlanReport,
  SetDefectReq,
  VerifyDefectReq,
  ResendReportReq,
  SendEditReportToQCReq,
  CommonResp,
} from './types'

export const getReportAction = createAsyncAction(
  'report/auditPlan/FETCH_REPORT_REQUEST',
  'report/auditPlan/FETCH_REPORT_SUCCESS',
  'report/auditPlan/FETCH_REPORT_FAILURE'
)<GetReportReq, GetReportResp, any>()

export const getCheckListAction = createAsyncAction(
  'report/auditPlan/GET_CHECKLIST_QC_REQ',
  'report/auditPlan/GET_CHECKLIST_QC_RESP',
  'report/auditPlan/GET_CHECKLIST_QC_FAIL'
)<GetCheckListReq, GetCheckListResp, any>()

export const resetDefect = createStandardAction(
  'report/auditPlan/RESET_DEFECT_ACTION'
)<void>()

export const updateDefect = createStandardAction(
  'report/auditPlan/UPDATE_DEFECT_ACTION'
)<DefectItem>()

export const createDefectAction = createAsyncAction(
  'report/auditPlan/CREATE_DEFECT_REQ',
  'report/auditPlan/CREATE_DEFECT_RESP',
  'report/auditPlan/CREATE_DEFECT_FAIL'
)<DefectTypeReq, CommonResp, any>()

export const updateDefectAction = createAsyncAction(
  'report/auditPlan/UPDATE_DEFECT_REQ',
  'report/auditPlan/UPDATE_DEFECT_RESP',
  'report/auditPlan/UPDATE_DEFECT_FAIL'
)<DefectTypeReq, CommonResp, any>()

export const setDefectAction = createAsyncAction(
  'report/auditPlan/SET_DEFECT_REQ',
  'report/auditPlan/SET_DEFECT_RESP',
  'report/auditPlan/SET_DEFECT_FAIL'
)<SetDefectReq, CommonResp, any>()

export const verifyDefectAction = createAsyncAction(
  'report/auditPlan/VERIFY_DEFECT_REQ',
  'report/auditPlan/VERIFY_DEFECT_RESP',
  'report/auditPlan/VERIFY_DEFECT_FAIL'
)<VerifyDefectReq, CommonResp, any>()

export const resendReportAction = createAsyncAction(
  'report/auditPlan/RESEND_REPORT_REQ',
  'report/auditPlan/RESEND_REPORT_RESP',
  'report/auditPlan/RESEND_REPORT_FAIL'
)<ResendReportReq, CommonResp, any>()

export const sendEditReportAction = createAsyncAction(
  'report/auditPlan/SEND_EDIT_REPORT_REQ',
  'report/auditPlan/SEND_EDIT_REPORT_RESP',
  'report/auditPlan/SEND_EDIT_REPORT_FAIL'
)<SendEditReportToQCReq, CommonResp, any>()

export const submitDefectReq = createStandardAction(
  'report/auditPlan/SUBMIT_REQUEST'
)<SendDefectReq>()

export const setHowToFixReq = createStandardAction(
  'report/auditPlan/SUBMIT_HOWTOFIX_REQUEST'
)<SendDefectReq>()

export const updateReport = createStandardAction(
  'report/auditPlan/UPDATE_REPORT_ACTION'
)<AuditPlanReport>()

export const resetEvent = createStandardAction(
  'report/auditPlan/RESET_EVENT_ACTION'
)<void>()
