import React, { useState } from 'react'
import { Row, Col } from 'antd'
import styled from 'styled-components'
import { UnitReportItem } from 'features/report/unit/types'
import {
  DefectStatus,
  DefectStatusText,
  DefectColor,
} from 'features/types/status'
import ReportHeader from './ReportHeader'
import ReportItem from './ReportItem'

type Props = {
  dataList: UnitReportItem[]
  onSelected: (item: any) => void
  showAfterImg?: boolean
  pagination ?: any
}

const ReportList: React.FunctionComponent<Props> = props => {
  const { dataList, onSelected, showAfterImg = false ,pagination} = props
  const content =
    dataList &&
    dataList.map((item, index) => {
      return (
        <ReportItem
          item={item}
          key={index}
          onClick={onSelected}
          showAfterImg={showAfterImg}
        />
      )
    })

  return (
    <TableArea>
      <ReportHeader onSort={() => {}} sorting={{}} />
      {content}
      {pagination && pagination}
    </TableArea>
  )
}

const TableArea = styled.div`
  margin-top: 20px;
`

export default ReportList
