import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { RootState } from 'typings/RootState'
import styled from 'styled-components'

import { getProjectType, getUnitList } from 'features/filter/api'
import { GetProjectTypeReq } from 'features/filter/types'
import {
  selectProjectType,
  selectUnitList,
  selectQCTypeList,
} from 'features/filter/selector'
import { Col, Row } from 'antd'
import {
  projectsByIdSelector,
  selectedProjectCodeSelector,
} from 'features/project/project.selector'
import {
  serviceTokenSelector,
  userEmailSelector,
} from 'features/sensitive/sensitive.selector'
import { DropdownList, DropdownPopupMenu } from 'Components/Shared/Form'
import { fetchProjects } from 'features/project/project.api'
import {
  selectQCTypeFilter,
  selectUnitCheckLits,
} from 'features/adminReport/unit/selector'
import { getUnitCheckList } from 'features/adminReport/unit/api'
import {
  FilterByQCType,
  GetUnitCheckListReq,
} from 'features/adminReport/unit/types'
import {
  updateQCTypeFilter,
  clearFilter,
} from 'features/adminReport/unit/actions'
import UnitContent from 'Components/Shared/Filters/UnitContent'
import { GetUnitListReq } from 'features/filter/types'
import {
  ProjectsFilter,
  ProjectTypeFilter,
  DateFilter,
  ButtonFilter,
  SuplierFilter,
} from './Components'
import MulticheckProject from 'Components/Shared/Filters/MulticheckProject'

type Props = {
  onSearch?: (isClear?: boolean) => void
  withPadding?: boolean
  onExport?: () => void
  exportData?: any[]
  isDownLoading?: boolean
} & ReduxProps &
  DispatchProps

const UnitByQCTypeFilter: React.FunctionComponent<Props> = props => {
  const {
    ProjectCode,
    UserEmail,
    token,
    filter,
    projectsById,
    onSearch = () => {},
    withPadding = false,
    projectType,
    unitList,
    unitCheckList,
    onExport,
    exportData,
    isDownLoading,
  } = props

  useEffect(() => {
    dispatch(clearFilter())
    onGetProjectType()
    onGetUnitList()
    onGetUnitCheckList()
    dispatch(fetchProjects({ email: UserEmail, token }))
    return () => {
      dispatch(clearFilter())
    }
  }, [ProjectCode, UserEmail])

  useEffect(() => {
    onGetUnitList()
    onGetUnitCheckList()
  }, [filter.ProjectCode.length === 1])

  useEffect(() => {
    filter.UnitList = []
    filter.QCTypeList = []
    onFilterChange(filter)
  }, [filter.ProjectCode.length !== 1])

  const onGetProjectType = () => {
    const req: GetProjectTypeReq = {
      UserEmail,
      Page: 1,
      RowPerPage: 100,
    }
    dispatch(getProjectType(token, req))
  }

  const onGetUnitList = () => {
    if (filter.ProjectCode.length === 1) {
      const req: GetUnitListReq = {
        ProjectCode: filter.ProjectCode[0],
        UserEmail,
        Zone: 'Resident Area',
      }
      dispatch(getUnitList(token, req))
    }
  }

  const onGetUnitCheckList = () => {
    if (filter.ProjectCode.length === 1) {
      const req: GetUnitCheckListReq = {
        UserEmail,
        ProjectCode: filter.ProjectCode[0],
      }
      dispatch(getUnitCheckList(token, req))
    }
  }

  const dispatch = useDispatch()
  const onFilterChange = (filter: FilterByQCType) => {
    dispatch(updateQCTypeFilter(filter))
  }

  const onClearSearch = () => {
    dispatch(clearFilter())
    onSearch(true)
  }

  const isFilter = () => {
    return (
      filter.UnitList.length > 0 ||
      filter.QCTypeList.length > 0 ||
      filter.Date.Mode !== '' ||
      filter.ProjectCode.length > 0 ||
      filter.ProjectTypeID !== 0
    )
  }

  const isQCTypeListNull = () => {
    return !unitCheckList.QCTypeList
  }

  const getQcType = () => {
    if (isQCTypeListNull()) return []
    return unitCheckList.QCTypeList.map(item => {
      return {
        name: item.QCTypeName,
        value: item.QCTypeName,
      }
    })
  }

  const getJobType = (qctypeName: string) => {
    if (isQCTypeListNull()) return []
    const qctype = unitCheckList.QCTypeList.find(
      item => item.QCTypeName === qctypeName
    )
    if (!qctype) return []
    return qctype.JobTypeList.map(item => {
      return {
        name: item.JobTypeName,
        value: item.JobTypeName,
      }
    })
  }

  const getSubJobType = (qctypeName: string, jobTypeName: string) => {
    if (isQCTypeListNull()) return []
    const qctype = unitCheckList.QCTypeList.find(
      item => item.QCTypeName === qctypeName
    )
    if (!qctype) return []

    const job = qctype.JobTypeList.find(
      item => item.JobTypeName === jobTypeName
    )
    if (!job) return []
    return job.SubJobTypeList.map(item => {
      return {
        name: item.SubJobTypeName,
        value: item.SubJobTypeName,
      }
    })
  }

  const getDetail = (
    qctypeName: string,
    jobTypeName: string,
    subJobName: string
  ) => {
    if (isQCTypeListNull()) return []
    const qctype = unitCheckList.QCTypeList.find(
      item => item.QCTypeName === qctypeName
    )
    if (!qctype) return []

    const job = qctype.JobTypeList.find(
      item => item.JobTypeName === jobTypeName
    )
    if (!job) return []

    const subJob = job.SubJobTypeList.find(
      item => item.SubJobTypeName === subJobName
    )
    if (!subJob) return []

    return subJob.DetailList.map(item => {
      return {
        name: item.DetailName,
        value: item.DetailName,
      }
    })
  }

  const filterQcTypeName = () => {
    return (
      (filter.QCTypeList.length > 0 && filter.QCTypeList[0].QCTypeName) || ''
    )
  }

  const filterJobTypeName = () => {
    return (
      (filter.QCTypeList.length > 0 &&
        filter.QCTypeList[0].JobTypeList.length > 0 &&
        filter.QCTypeList[0].JobTypeList[0].JobTypeName) ||
      ''
    )
  }

  const filterSubJobTypeName = () => {
    return (
      (filter.QCTypeList.length > 0 &&
        filter.QCTypeList[0].JobTypeList.length > 0 &&
        filter.QCTypeList[0].JobTypeList[0].SubJobTypeList.length > 0 &&
        filter.QCTypeList[0].JobTypeList[0].SubJobTypeList[0].SubJobTypeName) ||
      ''
    )
  }

  const filterDetail = () => {
    return (
      (filter.QCTypeList.length > 0 &&
        filter.QCTypeList[0].JobTypeList.length > 0 &&
        filter.QCTypeList[0].JobTypeList[0].SubJobTypeList.length > 0 &&
        filter.QCTypeList[0].JobTypeList[0].SubJobTypeList[0].DetailList
          .length > 0 &&
        filter.QCTypeList[0].JobTypeList[0].SubJobTypeList[0].DetailList[0]
          .DetailName) ||
      ''
    )
  }

  return (
    <MainPage className={withPadding ? 'with-padding' : ''}>
      <Row type="flex" justify="start">
        <Col span={8}>
          <ProjectTypeFilter
            filter={filter}
            onFilterChange={onFilterChange}
            projectType={projectType}
          />
        </Col>
        <Col span={8}>
          <Box>
            <DropdownPopupMenu
              label={'โครงการ'}
              placeholder="เลือกโครงการ"
              value={filter.ProjectCode.join(',')}
              disabled={false}
              content={
                <MulticheckProject
                  projectList={projectsById}
                  projectTypeID={filter.ProjectTypeID}
                  filter={filter.ProjectCode}
                  onChange={data => {
                    filter.ProjectCode = data
                    onFilterChange(filter)
                  }}
                />
              }
              isActive={filter.ProjectCode.length > 0}
            />
          </Box>
        </Col>
        <Col span={8}>
          <Box>
            <DropdownPopupMenu
              label={'ยูนิต'}
              placeholder="เลือกยูนิต"
              value={filter.UnitList.join(',')}
              disabled={filter.ProjectCode.length !== 1}
              content={
                <UnitContent
                  units={unitList.List}
                  filter={filter.UnitList}
                  onChange={data => {
                    filter.UnitList = data
                    onFilterChange(filter)
                  }}
                />
              }
              isActive={filter.UnitList.length > 0}
            />
          </Box>
        </Col>
        <Col span={8}>
          <Box>
            <DropdownList
              placeholder="หมวดงาน"
              label="หมวดงาน"
              disabled={filter.ProjectCode.length !== 1}
              value={filterQcTypeName()}
              menu={getQcType()}
              onChange={(data: any) => {
                filter.QCTypeList[0] = {
                  QCTypeName: data,
                  JobTypeList: [],
                }
                onFilterChange(filter)
              }}
            />
          </Box>
        </Col>
        <Col span={8}>
          <Box>
            <DropdownList
              placeholder="ทั้งหมด"
              label="หมวดงานหลัก"
              disabled={filterQcTypeName() === ''}
              value={filterJobTypeName()}
              menu={getJobType(filterQcTypeName())}
              onChange={(data: any) => {
                filter.QCTypeList[0].JobTypeList[0] = {
                  JobTypeName: data,
                  SubJobTypeList: [],
                }
                onFilterChange(filter)
              }}
            />
          </Box>
        </Col>
        <Col span={8}>
          <Box>
            <DropdownList
              placeholder="ทั้งหมด"
              label="หมวดงานรอง"
              disabled={filterJobTypeName() === ''}
              value={filterSubJobTypeName()}
              menu={getSubJobType(filterQcTypeName(), filterJobTypeName())}
              onChange={(data: any) => {
                filter.QCTypeList[0].JobTypeList[0].SubJobTypeList[0] = {
                  SubJobTypeName: data,
                  DetailList: [],
                }
                onFilterChange(filter)
              }}
            />
          </Box>
        </Col>
        <Col span={8}>
          <Box>
            <DropdownList
              placeholder="ทั้งหมด"
              label="รายละเอียด"
              disabled={filterSubJobTypeName() === ''}
              value={filterDetail()}
              menu={getDetail(
                filterQcTypeName(),
                filterJobTypeName(),
                filterSubJobTypeName()
              )}
              onChange={(data: any) => {
                filter.QCTypeList[0].JobTypeList[0].SubJobTypeList[0].DetailList[0] = {
                  DetailName: data,
                }
                onFilterChange(filter)
              }}
            />
          </Box>
        </Col>
        <Col span={8}>
          <SuplierFilter filter={filter} onFilterChange={onFilterChange} />
        </Col>
        <Col span={8}>
          <DateFilter filter={filter} onFilterChange={onFilterChange} />
        </Col>

        <Col span={8}>
          <ButtonFilter
            onClearSearch={onClearSearch}
            onSearch={onSearch}
            isFilter={isFilter()}
            onExport={onExport}
            isDownLoading={isDownLoading}
            // isDisabled={filter.ProjectCode.length !== 1}
            isDisabled={false}
            exportData={exportData}
            fileName="รายงานตรวจงานมาตรฐาน (ยูนิต / แปลง)-รายงานตามหมวดงาน"
          />
        </Col>
      </Row>
    </MainPage>
  )
}

const MainPage = styled.div`
  margin-bottom: 24px;
  &.with-padding {
    padding: 0 120px;
  }

  background-color: #f5f6fa;
`

const Box = styled.div`
  padding: 0 12px 12px 0;
`

const mapStateToProps = (state: RootState) => {
  return {
    ProjectCode: selectedProjectCodeSelector(state),
    UserEmail: userEmailSelector(state),
    token: serviceTokenSelector(state),
    filter: selectQCTypeFilter(state),
    unitList: selectUnitList(state),
    projectType: selectProjectType(state),
    projectsById: projectsByIdSelector(state),
    unitCheckList: selectUnitCheckLits(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(UnitByQCTypeFilter)
