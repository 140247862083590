import RootState from 'typings/RootState'
import { Event } from '../../types/event'
import { DataList, DataDetail } from './types'

export const selectEvent = (state: RootState): Event =>
  state.adminReportConstructRoad.Event

export const selectReportList = (state: RootState): DataList =>
  state.adminReportConstructRoad.DataList

export const selectReportDetail = (state: RootState): DataDetail =>
  state.adminReportConstructRoad.DataDetail

export const selectFilter = (state: RootState) =>
  state.adminReportConstructRoad.Filter

export const selectInitFilter = (state: RootState) =>
  state.adminReportConstructRoad.ClearFilter

export const selectSorting = (state: RootState) =>
  state.adminReportConstructRoad.sorting
