import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { CheckListForQC } from 'features/report/commissioning/types'
import { TaskChecklist, FormChecklist } from 'Components/Activity'
import {
  CheckListStatus,
  CheckListStatusText,
} from 'features/cmpj/activity/types'
import { cloneDeep } from 'lodash'
import { CheckListHeader } from 'Components/Shared'

type Props = {
  checklist: CheckListForQC
  onUpdate?: (val: CheckListForQC) => void
  readOnly?: boolean
}

const CheckList: React.FC<Props> = (props: Props) => {
  const [listFilter, setListFilter] = useState([] as any[])
  const { checklist, onUpdate = () => {}, readOnly = false } = props
  const list = checklist.List || []

  useEffect(() => {
    setListFilter(cloneDeep(list))
  }, [list])

  return (
    <ChecklistContent>
      <CheckListHeader
        list={list}
        onChange={newList => {
          setListFilter([...newList])
        }}
        seachable={true}
      />
      {readOnly ? (
        <TaskChecklist inspectionList={listFilter} remarkTxt="หมายเหตุ" />
      ) : (
        <FormChecklist
          inspectionList={listFilter}
          remarkStatus={defStatus}
          onUpdateCheckList={val => {
            const updateData = cloneDeep(checklist)
            updateData.List = val
            onUpdate(updateData)
          }}
        />
      )}
    </ChecklistContent>
  )
}

const defStatus = [
  {
    value: CheckListStatus.PASS,
    name: CheckListStatusText.PASS,
  },
  {
    value: CheckListStatus.FAIL,
    name: CheckListStatusText.FAIL,
  },
  {
    value: CheckListStatus.NOTCHECK,
    name: CheckListStatusText.NOTCHECK,
  },
]

const ChecklistContent = styled.div`
  margin-top: 40px;
`

export default CheckList
