import React from 'react'

type DefaultProps = {
  size?: number
  color?: string
  onClick?: any
}

const TrashIcon = (props: DefaultProps) => {
  const { size = 20, color = '#fff', onClick } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      onClick={evt => {
        if (!onClick) return
        evt.stopPropagation()
        evt.preventDefault()
        onClick()
      }}
    >
      <path
        fill={color}
        id="a"
        d="M13.8 2c1.491 0 2.7 1.221 2.7 2.727v.909h3.6c.459 0 .837.347.893.795l.007.114c0 .503-.403.91-.9.91l-.9-.001v11.819c0 1.45-1.12 2.636-2.536 2.722L16.5 22h-9c-1.491 0-2.7-1.221-2.7-2.727V7.454h-.9a.903.903 0 0 1-.893-.795L3 6.545c0-.502.403-.909.9-.909h3.6v-.909c0-1.45 1.12-2.636 2.536-2.722L10.2 2zm3.6 5.454H6.6v11.819c0 .463.343.846.787.902l.113.007h9c.497 0 .9-.407.9-.91V7.455zm-7.2 2.728c.497 0 .9.407.9.909v5.454c0 .503-.403.91-.9.91a.905.905 0 0 1-.9-.91v-5.454c0-.502.403-.91.9-.91zm3.6 0c.497 0 .9.407.9.909v5.454c0 .503-.403.91-.9.91a.905.905 0 0 1-.9-.91v-5.454c0-.502.403-.91.9-.91zm0-6.364h-3.6c-.497 0-.9.407-.9.91v.908h5.4v-.909a.906.906 0 0 0-.787-.902l-.113-.007z"
      />
    </svg>
  )
}

export default TrashIcon
