import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import RootState from 'typings/RootState'
import {
  userEmailSelector,
  serviceTokenSelector,
} from 'features/sensitive/sensitive.selector'
import { Layout } from 'antd'
import ReportHeader from 'Components/AuditRoad/DefectList/ReportHeader'
import ReportItem from 'Components/AuditRoad/DefectList/ReportItem'
import { TaskFooter, EventModal } from 'Components/Shared'
import {
  DefectItem,
  GetSelectPinReq,
  UpdateSelectPinReq,
  PinUpdate,
} from 'features/report/auditRoad/types'
import { connect, useDispatch } from 'react-redux'
import {
  selectReport,
  selectEvent,
  selectPinList,
} from 'features/report/auditRoad/selector'
import {
  getSelectPinReport,
  updateSelectPinReport,
} from 'features/report/auditRoad/api'
import Breadcrumbs from './Breadcrumbs'

const { Content } = Layout

interface FormProps {
  onClose: () => void
  onCompleted: () => void
  RequestID: number
  CheckNumber: number
  ProjectCode: string
}

type Props = FormProps & ReduxProps

const ChoosePin: React.FC<Props> = props => {
  const {
    onClose = () => {},
    onCompleted = () => {},
    RequestID,
    ProjectCode,
    UserEmail,
    token,
    report,
    selectedPin,
    event,
    CheckNumber,
  } = props

  const [enableModal, setEnableModal] = useState(false)
  const [pinList, setPinList] = useState([] as any[])
  const defect = report.DefectList || []
  const dispatch = useDispatch()

  useEffect(() => {
    setEnableModal(false)
    getSelectPin()
  }, [ProjectCode, RequestID, CheckNumber])

  useEffect(() => {
    if (selectedPin) {
      setPinList(selectedPin.PinList)
    } else {
      setPinList([])
    }
  }, [selectedPin])

  const getSelectPin = () => {
    if (CheckNumber <= 0) return
    setEnableModal(true)
    const req: GetSelectPinReq = {
      ProjectCode,
      UserEmail,
      RequestID,
      CheckNumber,
    }
    dispatch(getSelectPinReport(token, req))
  }

  const updateSelectedPin = () => {
    if (CheckNumber <= 0) return
    setEnableModal(true)
    const req: UpdateSelectPinReq = {
      ProjectCode,
      UserEmail,
      RequestID,
      CheckNumber,
      PinList: getSelectedPin(),
    }

    dispatch(updateSelectPinReport(token, req))
  }

  const getSelectedPin = () => {
    let list = [] as PinUpdate[]
    defect.map(item => {
      const inOldList = isSelected(
        (selectedPin && selectedPin.PinList) || [],
        item.PinID
      )
      const inNewList = isSelected(pinList, item.PinID)
      if (inOldList || inNewList) {
        list.push({
          PinID: item.PinID,
          Selected: inOldList && !inNewList ? 'N' : 'Y',
        })
      }
    })
    return list
  }

  const isSelected = (pinList: any[], pinID: number) => {
    const findData = pinList.find(item => item.PinID === pinID)
    return findData ? true : false
  }

  const isSelectAll = () => {
    let selectedAll = true
    if (defect.length <= 0) {
      selectedAll = false
    }

    defect.forEach(item => {
      const selected = isSelected(pinList, item.PinID)
      if (!selected) {
        selectedAll = false
        return
      }
    })
    return selectedAll
  }

  const filterData = (pinID: number) => {
    return pinList.filter(item => item.PinID !== pinID)
  }

  const onSelect = (item: DefectItem) => {
    let newData = pinList
    const selected = isSelected(pinList, item.PinID)
    if (selected) {
      newData = filterData(item.PinID)
    } else {
      newData.push({
        PinID: item.PinID,
      })
    }
    setPinList([...newData])
  }

  const onSelectAll = () => {
    const selectedAll = isSelectAll()
    if (selectedAll) {
      setPinList([])
    } else {
      let newData = [] as any
      defect.forEach(item => {
        newData.push({
          PinID: item.PinID,
        })
      })
      setPinList([...newData])
    }
  }

  const content = defect.map((item, index) => {
    return (
      <ReportItem
        item={item}
        key={index}
        onClick={() => {}}
        isShowRadio={true}
        isSelected={isSelected(pinList, item.PinID)}
        onSelected={() => onSelect(item)}
        readOnly={report.CheckNumber !== item.CheckNumber}
        showThumbnail={true}
      />
    )
  })

  const title = 'เลือกหมุดส่งรายงาน'

  return (
    <>
      <EventModal
        event={[event]}
        enable={enableModal}
        successCalback={onCompleted}
      />
      <Breadcrumbs
        path={`/audit-road/report/${RequestID}/${CheckNumber}`}
        title="ตรวจรับมอบถนนและก่อนหมดประกัน"
        name={title}
        onBack={() => onClose()}
      />
      <Content className="mid-content">
        <div className="main-content activity-detail">
          <Header>
            <Title>{title}</Title>
          </Header>
          <TableArea>
            <ReportHeader
              onSort={() => {}}
              sorting={{}}
              showRadio={true}
              isSelectAll={isSelectAll()}
              onSelectAll={onSelectAll}
              showThumbnail={true}
            />
            {content}
          </TableArea>
        </div>
      </Content>
      <TaskFooter
        total={pinList.length}
        onSave={() => updateSelectedPin()}
        onCancel={() => onClose()}
      />
    </>
  )
}

const Header = styled.div`
  margin-top: 32px;
  margin-bottom: 24px;
`

const Title = styled.span`
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 28px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #000;
`

const TableArea = styled.div`
  margin-top: 20px;
`

const mapStateToProps = (state: RootState) => {
  return {
    report: selectReport(state),
    event: selectEvent(state),
    token: serviceTokenSelector(state),
    UserEmail: userEmailSelector(state),
    selectedPin: selectPinList(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(ChoosePin)
