import React, { useState } from 'react'
import { Layout, Row, Col } from 'antd'
import styled from 'styled-components'
import { PrimaryButton, SecondaryButton } from 'Components/Shared/Button'
import { TypeOfLane } from 'features/maincon/dashboard/types'

type Props = {
  taskSelect: any[]
  type?: string
  onClick: () => void
  isLoading?: boolean
}
const { Content, Footer } = Layout

const TaskFooter: React.FunctionComponent<Props> = props => {
  const {
    taskSelect,
    type = TypeOfLane.TODO,
    onClick,
    isLoading = false,
  } = props

  const SelfCheckButton = (
    <Col>
      <SelfCheckBtn>
        <PrimaryButton
          text="เริ่มตรวจ QC ด้วยตัวเอง"
          disabled={taskSelect.length > 0 && !isLoading ? false : true}
          onClick={onClick}
        />
      </SelfCheckBtn>
    </Col>
  )

  const MCButton = (
    <Col>
      <MCBtn>
        <SecondaryButton
          text="ส่งรายงานหาผู้รับเหมา"
          disabled={taskSelect.length > 0 ? false : true}
        />
      </MCBtn>
    </Col>
  )

  const renderButtonAction = () => {
    if (type === TypeOfLane.TODO) {
      return SelfCheckButton
    } else {
      return (
        <Col>
          <Row type="flex" justify="space-between" align="middle">
            {MCButton}
            {SelfCheckButton}
          </Row>
        </Col>
      )
    }
  }

  const renderTextSelected = (selected: number) => {
    if (selected > 0) {
      return <TextGreen>{selected}</TextGreen>
    } else {
      return <TextGray>{selected}</TextGray>
    }
  }

  return (
    <FooterContent>
      <Row type="flex" justify="space-between" align="middle">
        <Col>คุณเลือก {renderTextSelected(taskSelect.length)} รายการ</Col>
        {renderButtonAction()}
      </Row>
    </FooterContent>
  )
}

const FooterContent = styled.div`
  color: #000;
  font-size: 20px;
  padding: 0 30px;
`

const SelfCheckBtn = styled.div`
  width: 222px;
`

const MCBtn = styled.div`
  width: 195px;
  margin-right: 24px;
`

const TextGreen = styled.span`
  color: #5bc79e;
  padding: 0 8px;
`

const TextGray = styled.span`
  color: #8b99a7;
  padding: 0 8px;
`

export default TaskFooter
