import withAPIAction from 'utils/withAPIAction'
import { getDashbaordAction } from './actions'
import { GetDashboardReq } from './types'

export const getDashboard = (token: string, data: GetDashboardReq) => {
  if (token === '') {
    return { type: 'tasklane/no_token' }
  }
  if (data.ProjectCode === '') {
    return { type: 'activity/PAYLOAD_NOT_READY' }
  }

  return withAPIAction<GetDashboardReq>({
    method: 'POST',
    path: 'GetDashboardForConstructRoad',
    data,
    token: `Bearer ${token}`,
  })(getDashbaordAction)
}
