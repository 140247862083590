import React from 'react'
import { Col, Layout, Row } from 'antd'
import styled from 'styled-components'
import logo from 'assets/img/logo-transparent.png'
import imgQuality from 'assets/img/img-quality-control.png'
import ReportHeader from 'Components/AdminQC/Report/Components/ReportHeader'

type Props = {
  title: string
  isCenter?: boolean
}

const ReportLogo: React.ComponentType<Props> = props => {
  const { title = '', isCenter = false } = props

  return (
    <TitleBox>
      <Row
        type="flex"
        justify={isCenter ? 'center' : 'space-between'}
        align="middle"
      >
        <Col>
          <Img src={logo} />
          <Img src={imgQuality} />
        </Col>
        {title && (
          <Col>
            <Title style={{ color: '#8b99a7' }}>{title}</Title>
          </Col>
        )}
      </Row>
    </TitleBox>
  )
}

const Title = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 28px;
  color: #000;
`

const TitleBox = styled.div`
  padding: 24px;
  background-color: rgba(0, 49, 104, 0.05);
`

const Img = styled.img`
  height: 32px;
  margin-right: 32px;
`

export default ReportLogo
