import { createStandardAction, createAsyncAction } from 'typesafe-actions'
import {
  GetAuditRoadReq,
  GetAuditRoadResp,
  SendAuditRoadReportReq,
  ReturnAuditRoadReq,
  AcceptAuditRoadReq,
  PostApiResp,
  RejectCheckingTaskReq,
} from './types'

//Audit Road
export const getAuditRoadAction = createAsyncAction(
  'qc/dashboard/road/GET_AUDIT_ROAD_REQUEST',
  'qc/dashboard/road/GET_AUDIT_ROAD_RESPONSE',
  'qc/dashboard/road/GET_AUDIT_ROAD_FAILURE'
)<GetAuditRoadReq, GetAuditRoadResp, any>()

export const sendAuditRoadAction = createAsyncAction(
  'qc/dashboard/road/SEND_AUDIT_ROAD_REQUEST',
  'qc/dashboard/road/SEND_AUDIT_ROAD_RESPONSE',
  'qc/dashboard/road/SEND_AUDIT_ROAD_FAILURE'
)<SendAuditRoadReportReq, PostApiResp, any>()

export const returnAuditRoadAction = createAsyncAction(
  'qc/dashboard/road/RETURN_AUDIT_ROAD_REQUEST',
  'qc/dashboard/road/RETURN_AUDIT_ROAD_RESPONSE',
  'qc/dashboard/road/RETURN_AUDIT_ROAD_FAILURE'
)<ReturnAuditRoadReq, PostApiResp, any>()

export const acceptAuditRoadAction = createAsyncAction(
  'qc/dashboard/road/ACCEPT_AUDIT_ROAD_REQUEST',
  'qc/dashboard/road/ACCEPT_AUDIT_ROAD_RESPONSE',
  'qc/dashboard/road/ACCEPT_AUDIT_ROAD_FAILURE'
)<AcceptAuditRoadReq, PostApiResp, any>()

export const sortingDashboard = createStandardAction(
  'qc/dashboard/road/dashboard/SORTING'
)<string>()

export const RejectTasksAction = createAsyncAction(
  'qc/commissioning/checking/REJECT_TASK_REQUEST',
  'qc/commissioning/checking/REJECT_TASK_RESPONSE',
  'qc/commissioning/checking/REJECT_TASK_FAILURE'
)<RejectCheckingTaskReq, PostApiResp, any>()
