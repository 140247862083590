import React, { useState, useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import RootState from 'typings/RootState'
import { connect, useDispatch } from 'react-redux'

import { BreadcrumbLink } from 'Components/Shared/BreadCrumb/BreadCrumb'

import {
  selectEvent,
  selectCheckListQC,
} from 'features/report/commissioning/selector'

import { GetCheckListReq } from 'features/report/commissioning/types'

import { getCheckListForQC } from 'features/report/commissioning/api'

import {
  userEmailSelector,
  serviceTokenSelector,
} from 'features/sensitive/sensitive.selector'
import { selectedProjectCodeSelector } from 'features/project/project.selector'
import EventModal from 'Components/Shared/Modal/EventModal'
import CommissionDetail from 'Components/Commissioning/Detail'
import Breadcrumbs from './Breadcrumbs'
import { useHistory, useLocation } from 'react-router'

type FormProps = {}

type Props = FormProps &
  RouteComponentProps<{
    checkListID: string
    activityID: string
    requestID: string
  }> &
  ReduxProps

const CmCommissionDetail = (props: Props) => {
  const [page, setPage] = useState('')
  const [enableModal, setEnableModal] = useState(false)
  const historyUrl = useHistory()
  const location = useLocation()

  const {
    Token,
    ProjectCode,
    UserEmail,

    history,
    match,
    checklist,
    event,
  } = props

  const { checkListID, activityID, requestID } = match.params
  const CheckListID = +checkListID
  const RequestID = +requestID

  const dispatch = useDispatch()

  useEffect(() => {
    const req: GetCheckListReq = {
      ProjectCode,
      UserEmail,
      CheckListID: +checkListID,
      ActivityID: activityID,
      RequestID,
    }

    dispatch(getCheckListForQC(Token, req))
  }, [ProjectCode, checkListID, activityID, location.search])

  const gotoDashboard = () => {
    setEnableModal(false)

    if (location.search) {
      historyUrl.goBack()
    } else {
      history.push('/commissioning/dashboard')
    }
  }

  const closeEnableModal = () => setEnableModal(false)
  const backPage = () => setPage('')

  const breadcrumbs: BreadcrumbLink[] = [
    {
      url: '/dashboard',
      title: 'Home',
      icon: '/home.png',
      active: true,
      showTitle: false,
    },
    {
      url: '/dashboard',
      title: 'งานทั้งหมด',
      active: true,
      showTitle: true,
    },
    {
      url: '',
      title: checklist.ActivityName,
      active: true,
      showTitle: true,
      onClick: backPage,
    },
  ]

  let breadcrumb
  if (page !== 'activity') {
    breadcrumb = (
      <Breadcrumbs name={page} onBack={backPage} breadcrumbs={breadcrumbs} />
    )
  }

  return (
    <>
      <EventModal
        event={[event]}
        enable={enableModal}
        successCalback={gotoDashboard}
        errorCalback={closeEnableModal}
      />
      {breadcrumb}
      <CommissionDetail
        ActivityID={activityID}
        breadcrumbs={breadcrumbs}
        page={page}
        onPage={(page: string) => setPage(page)}
      />
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    Token: serviceTokenSelector(state),
    ProjectCode: selectedProjectCodeSelector(state),
    UserEmail: userEmailSelector(state),

    checklist: selectCheckListQC(state),
    event: selectEvent(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(CmCommissionDetail)
