import RootState from 'typings/RootState'
import { createSelector } from 'reselect'
import {
  ProjectsById,
  ProjectListItem,
  ProjectListStatus,
} from './project.types'

export const projectsByIdSelector = (state: RootState): ProjectsById =>
  state.project.projectsById

export const projectListStatusSelector = (
  state: RootState
): ProjectListStatus => state.project.emptyProjectList

export const firstProjectListItemSelector = createSelector(
  projectsByIdSelector,
  (projects: ProjectsById): ProjectListItem => {
    const projectListItems: ProjectListItem[] = Object.values(projects).slice(
      0,
      1
    )

    return projectListItems[0]
  }
)

export const projectCodeSelector = createSelector(
  firstProjectListItemSelector,
  projectListItem => {
    if (projectListItem && projectListItem.ProjectCode) {
      return `${projectListItem.ProjectCode}-MS`
    }

    return ''
  }
)

export const projectSubmitErrorSelector = (state: RootState) =>
  state.project.submitError

export const isSubmittingSelector = (state: RootState) =>
  state.project.isSubmitting

export const errorSelector = createSelector(
  projectSubmitErrorSelector,
  projectError => {
    if (projectError && projectError.indexOf('Network Error') > -1) {
      return ` ${projectError}, Please check your internet connections. There is some problem connecting API service.`
    }
  }
)

export const tokenExpiredSelector = (state: RootState) =>
  state.project.tokenExpired

export const selectedProjectCodeSelector = (state: RootState) =>
  state.selectedProject.projectCode

export const projectCodeWithSuffixSelector = createSelector(
  selectedProjectCodeSelector,
  code => `${code === '' ? localStorage.getItem('projectCode') : code}-MS`
)
