import {
  CommonResp,
  GetDataListReq,
  GetDataListResp,
  UpdateDataReq,
} from './types'
import { createAsyncAction, createStandardAction } from 'typesafe-actions'

export const getDataListAction = createAsyncAction(
  'adminpj/modelHouse/FETCH_LIST_REQUEST',
  'adminpj/modelHouse/FETCH_LIST_SUCCESS',
  'adminpj/modelHouse/FETCH_LIST_FAILURE'
)<GetDataListReq, GetDataListResp, any>()

export const updateDataAction = createAsyncAction(
  'adminpj/modelHouse/UPDATE_DATA_REQ',
  'adminpj/modelHouse/UPDATE_DATA_RESP',
  'adminpj/modelHouse/UPDATE_DATA_FAIL'
)<UpdateDataReq, CommonResp, any>()

export const resetEvent = createStandardAction('admin/user/RESET_EVENT_ACTION')<
  void
>()
