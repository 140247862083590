import { getType } from 'typesafe-actions'
import * as actions from './actions'
import {
  StoreState,
  DashboardAction,
  PMRList,
  PMRReport,
  DefectItem,
  Filter,
} from './types'
import produce from 'immer'
import parseErrorMessage from 'utils/parseErrorMessage'
import { Event } from 'features/types/event'
import { cloneDeep } from 'lodash'
import { sortingActivityList } from './function'

const initEvent = {
  IsLoading: false,
  MessageSuccess: undefined,
  SubmitError: undefined,
  SubmitSuccess: false,
  SubmitType: undefined,
} as Event

const initPmrList = {
  Total: 0,
  ProjectList: [],
} as PMRList

const initFilter = {
  FloorList: [],
  ProjectCode: '',
  ZoneList: [],
} as Filter

const defect = {
  PinID: 0,
  PinNumber: 0,
  CheckNumber: 0,
  JobTypeID: 0,
  JobTypeName: '',
  SubJobTypeID: 0,
  SubJobTypeName: '',
  DetailID: 0,
  DetailName: '',
  LocationID: 0,
  LocationName: '',
  PinStatus: 'Fail',
  Remark: '',
  CheckedBy: '',
  ImageFloorPlan: '',
  ImageBefore: '',
  ImageAfter: '',
  IsRequired: false,
  IsSupplier: false,
  CreatedByModule: 'PMR',
  ZoneType: 'S&A',
  GroupType: 'None',
  PMRStatus: 'None',
  PMRRemark: '',
  FixReason: '',
  QCPMRStatus: 'None',
  IsSansiriFixed: false,
  VendorName: '',
} as DefectItem

const initReport = {
  CheckNumber: 0,
  CheckedDate: '',
  DefectList: [],
  Floor: '',
  QCTypeID: '',
  QCTypeName: '',
  State: 'PMR',
  Status: '',
  Unit: '',
  Zone: '',
  TotalDefect: 0,
  ProjectCode: '',
  ProjectNameTH: '',
} as PMRReport

const initialState: StoreState = {
  pmrlist: cloneDeep(initPmrList),
  report: cloneDeep(initReport),
  defect: cloneDeep(defect),
  event: {} as Event,
  sorting: {
    name: 'ProjectNameTH',
    type: 'desc',
  },
  defectSorting: {
    name: 'PinNumber',
    type: 'desc',
  },
  filter: cloneDeep(initFilter),
}

export default function(
  state: StoreState = initialState,
  action: DashboardAction
): StoreState {
  return produce(state, draft => {
    switch (action.type) {
      case getType(actions.getPMRListAction.request):
        draft.event = cloneDeep(initEvent)
        draft.event.IsLoading = true
        draft.pmrlist = state.pmrlist
        break
      case getType(actions.getPMRListAction.success):
        draft.event.IsLoading = false
        draft.pmrlist = action.payload.ResponseData
        break

      case getType(actions.getReportAction.request):
        draft.event = cloneDeep(initEvent)
        draft.event.IsLoading = true
        draft.report = cloneDeep(initReport)
        break

      case getType(actions.updateDefectAction.request):
      case getType(actions.setGroupDefectAction.request):
      case getType(actions.confirmGroupDefectAction.request):
        draft.event = cloneDeep(initEvent)
        draft.event.IsLoading = true
        break
      case getType(actions.updateDefectAction.success):
      case getType(actions.setGroupDefectAction.success):
      case getType(actions.confirmGroupDefectAction.success):
        draft.event.IsLoading = false
        draft.event.SubmitError = undefined
        draft.event.SubmitSuccess = true
        draft.event.MessageSuccess = 'ทำรายการสำเร็จ'
        break
      case getType(actions.getReportAction.success):
        draft.event.IsLoading = false
        draft.report = action.payload.ResponseData
        break
      case getType(actions.getPMRListAction.failure):
      case getType(actions.getReportAction.failure):
      case getType(actions.updateDefectAction.failure):
      case getType(actions.setGroupDefectAction.failure):
      case getType(actions.confirmGroupDefectAction.failure):
        draft.event.IsLoading = false
        draft.event.SubmitError = parseErrorMessage(action.payload)
        break

      case getType(actions.sortingTask):
        const sorting = state.sorting
        const params = action.payload
        if (sorting.name === params.name) {
          draft.sorting.type = sorting.type === 'asc' ? 'desc' : 'asc'
        } else {
          draft.sorting.name = params.name
          draft.sorting.type = 'asc'
        }
        if (params.sort != null) {
          draft.sorting.type = params.sort
        }

        const list = sortingActivityList(
          draft.pmrlist.ProjectList,
          draft.sorting.type,
          draft.sorting.name
        )
        draft.pmrlist.ProjectList = list
        break

      case getType(actions.sortingDefect):
        const sortingDefect = state.defectSorting
        const paramsSort = action.payload
        if (sortingDefect.name === paramsSort.name) {
          draft.defectSorting.type =
            sortingDefect.type === 'asc' ? 'desc' : 'asc'
        } else {
          draft.defectSorting.name = paramsSort.name
          draft.defectSorting.type = 'asc'
        }
        if (paramsSort.sort != null) {
          draft.defectSorting.type = paramsSort.sort
        }

        draft.report.DefectList = sortingActivityList(
          draft.report.DefectList,
          draft.defectSorting.type,
          draft.defectSorting.name
        )
        break

      case getType(actions.resetCurrentDefect):
        draft.defect = cloneDeep(defect)
        break
      case getType(actions.updateCurrentDefect):
        draft.defect = action.payload
        break
      case getType(actions.updateFilter):
        draft.filter = { ...action.payload }
        break
      case getType(actions.clearFilter):
        draft.filter = cloneDeep({ ...initFilter })
        break
      default:
        return state
    }
  })
}
