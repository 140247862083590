import { createStandardAction, createAsyncAction } from 'typesafe-actions'
import {
  GetDashboardReq,
  GetDashboardResp,
  GetCanSendStatusReq,
  GetCanSendStatusResp,
  SendToQCReq,
} from './types'

export const getDashbaordAction = createAsyncAction(
  'cmpj/road/audit/FETCH_DASHBOARD_REQUEST',
  'cmpj/road/audit/FETCH_DASHBOARD_SUCCESS',
  'cmpj/road/audit/FETCH_DASHBOARD_FAILURE'
)<GetDashboardReq, GetDashboardResp, any>()

export const getCanSendStatusAction = createAsyncAction(
  'cmpj/road/audit/FETCH_CAN_SEND_REQUEST',
  'cmpj/road/audit/FETCH_CAN_SEND_SUCCESS',
  'cmpj/road/audit/FETCH_CAN_SEND_FAILURE'
)<GetCanSendStatusReq, GetCanSendStatusResp, any>()

export const sendToQCAction = createAsyncAction(
  'cmpj/road/audit/FETCH_SEND_TO_QC_REQUEST',
  'cmpj/road/audit/FETCH_SEND_TO_QC_SUCCESS',
  'cmpj/road/audit/FETCH_SEND_TO_QC_FAILURE'
)<SendToQCReq, any, any>()

export const sortingDashboard = createStandardAction('cmpj/road/audit/SORTING')<
  string
>()
