import moment, { Moment } from 'moment-timezone'
import {
  DATE_PICKER_DATE_FORMAT,
  DATETIME_PICKER_DATE_FORMAT,
  DATE_FORMAT,
} from 'config'
import { TypeOfLane } from 'features/maincon/dashboard/types'
import { Activity, TypeOfTask } from 'features/cmpj/cmdashboard/types'

export const toSaveFormat = (
  date: Moment | string | null,
  format = 'YYYYMMDD'
) => {
  if (moment.isMoment(date)) {
    return date.format(format)
  } else if (typeof date === 'string' && date !== '') {
    return moment(date, 'DD/MM/YYYY').format(format)
  }
  return ''
}

export const toDateText = (
  date: Moment | string | null,
  format = 'DD/MM/YYYY'
) => (moment.isMoment(date) ? date.format(format) : date || '')

export const toTimeText = (date: Moment | string | null, format = 'H:mm') =>
  moment.isMoment(date) ? date.format(format) : date || ''

export const toMoment = (val: string | Moment): Moment | undefined => {
  if (typeof val === 'string') {
    return val === '' ? undefined : moment(val, DATE_PICKER_DATE_FORMAT)
  }

  return val
}

export const toDateTimeText = (
  date: Moment | string | null,
  format = 'DD/MM/YYYY H:mm'
) => (moment.isMoment(date) ? date.format(format) : date || '')

export const toMomentDateTime = (val: string | Moment): Moment | null => {
  if (typeof val === 'string') {
    return val === '' ? null : moment(val, DATETIME_PICKER_DATE_FORMAT)
  }

  return val
}

export const getDelayedDate = (date: string | Moment) => {
  const today = moment().startOf('day')
  let delayed = moment(date, DATE_FORMAT).diff(today, 'days')
  return delayed
}

export const getMSDelayedDate = (task: any) => {
  const today = moment().startOf('day')
  let delayed = moment(task.BaselineFinishDate, DATE_FORMAT).diff(today, 'days')
  if (task.TaskStatus === 'APPV') {
    delayed = moment(task.BaselineFinishDate, DATE_FORMAT).diff(
      moment(task.ActualFinishDate, DATE_FORMAT),
      'days'
    )
  }
  return delayed
}

export const getMSDelayedText = (remainDate: number, task: any) => {
  let days = Math.abs(remainDate)
  if (task.TaskStatus === 'APPV') {
    if (remainDate < 0) {
      return `เสร็จช้ากว่ากำหนด ${days} วัน`
    }

    if (remainDate > 0) {
      return `เสร็จเร็วกว่ากำหนด ${days} วัน`
    }

    if (remainDate === 0) {
      return `เสร็จตามกำหนด`
    }
  } else {
    if (remainDate < 0) {
      return `ล่าช้ากว่ากำหนด ${days} วัน`
    }

    if (remainDate >= 0) {
      return `เหลือเวลาอีก ${days} วัน`
    }
  }

  return ''
}

export const getDelayedText = (day: number, taskStatus: string) => {
  let txt
  if (taskStatus === TypeOfLane.TODO) {
    txt = 'เริ่มช้ากว่ากำหนด'
  } else {
    txt = 'เสร็จช้ากว่ากำหนด'
  }
  return `${txt} ${day} วัน`
}

export const getStartText = (day: number, taskStatus: string) => {
  let txt
  if (taskStatus === TypeOfLane.TODO) {
    txt = 'เริ่มงานภายใน'
  } else {
    txt = 'เหลืออีก'
  }
  return `${txt} ${day} วัน`
}

export const getNumBeetwenDate = (
  fromdate: string | Moment,
  todate: string | Moment
) => {
  const fdate = moment(fromdate, DATETIME_PICKER_DATE_FORMAT)
  const tdate = moment(todate, DATETIME_PICKER_DATE_FORMAT)
  let duration = moment.duration(tdate.diff(fdate))
  return duration
}

type TaskSchedule = Pick<
  Activity,
  'BaselineStartDate' | 'BaselineFinishDate' | 'ActualFinishDate'
>

export function getCmTaskStatusText(
  taskSchedule: TaskSchedule,
  taskType: TypeOfTask
) {
  const today = moment().startOf('day')
  let daysDiff: number
  let delayed: boolean

  switch (taskType) {
    case TypeOfTask.Done:
      ;[daysDiff, delayed] = diffInDays(
        taskSchedule.BaselineFinishDate,
        taskSchedule.ActualFinishDate
      )
      const onSchedule = daysDiff === 0
      return onSchedule
        ? 'เสร็จตามกำหนด'
        : `${
            delayed ? 'เสร็จช้ากว่ากำหนด' : 'เสร็จเร็วกว่ากำหนด'
          } ${daysDiff} วัน`

    case TypeOfTask.Inprogress:
      ;[daysDiff, delayed] = diffInDays(taskSchedule.BaselineFinishDate, today)
      return `${delayed ? 'ล่าช้ากว่ากำหนด' : 'เหลือเวลาอีก'} ${daysDiff} วัน`

    case TypeOfTask.Todo:
    default:
      ;[daysDiff, delayed] = diffInDays(taskSchedule.BaselineStartDate, today)
      return `${
        delayed ? 'เริ่มช้ากว่ากำหนด' : 'เริ่มงานภายใน'
      } ${daysDiff} วัน`
  }
}

function diffInDays(
  d1_: string | moment.Moment,
  d2_: string | moment.Moment,
  fmt: moment.MomentFormatSpecification = DATE_FORMAT
) {
  const d1 = moment.isMoment(d1_) ? d1_ : moment(d1_, fmt)
  const d2 = moment.isMoment(d2_) ? d2_ : moment(d2_, fmt)

  const diff = d1.diff(d2, 'days')
  return [Math.abs(diff), diff < 0] as const
}
