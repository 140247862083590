import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { connect, useDispatch } from 'react-redux'
import { Layout } from 'antd'
import { RouteComponentProps } from 'react-router-dom'
import RootState from 'typings/RootState'
import { getUnitReport, getUnitCheckListForQC } from 'features/report/unit/api'
import {
  GetUnitReportReq,
  UnitReportItem,
  GetCheckListForQCReq,
} from 'features/report/unit/types'
import {
  selectReport,
  selectUnitDefect,
  selectEvent,
  selectCheckListQC,
} from 'features/report/unit/selector'
import {
  userEmailSelector,
  serviceTokenSelector,
} from 'features/sensitive/sensitive.selector'
import { selectedProjectCodeSelector } from 'features/project/project.selector'
import { Report, ReportPinDetail } from 'Components/Defect'
import Breadcrumbs from './Breadcrumbs'
import { LoadingView } from 'Components/Shared'
import Pagination from 'Components/Shared/Pagination'
import { getQCTypeVersion } from 'utils/getText'
import PageFooter from './PageFooter'

const { Content } = Layout

type FormProps = {
  code: string
}

type Props = FormProps &
  RouteComponentProps<{ unit: string; qctype: string; requestID: string }> &
  ReduxProps

const UnitReport: React.ComponentType<Props> = props => {
  const [page, setPage] = useState('' as 'detail.pin' | '')
  const [enableModal, setEnableModal] = useState(false)
  const [selectedItem, setSelectedItem] = useState(undefined as any)
  const {
    report,
    token,
    ProjectCode,
    UserEmail,
    match,
    event,
    unitDefect,
    checklist,
  } = props
  const { params } = match
  const { unit, qctype, requestID } = params
  const dispatch = useDispatch()
  const [pageIndex, setPageIndex] = useState(1)
  const [perPage, setPerPage] = useState(10)

  const isReadOnly = true

  useEffect(() => {
    setEnableModal(false)
  }, [report, unitDefect, checklist, selectedItem])

  useEffect(() => {
    const reqCheckListQC: GetCheckListForQCReq = {
      UserEmail,
      ProjectCode,
      QCTypeID: qctype,
      Unit: unit,
    }
    dispatch(getUnitCheckListForQC(token, reqCheckListQC))
  }, [token, ProjectCode, UserEmail])

  useEffect(() => {
    const params: GetUnitReportReq = {
      UserEmail,
      ProjectCode,
      QCType: qctype,
      Unit: unit,
      RequestID: parseInt(requestID),
      Page: pageIndex,
      RowPerPage: perPage,
    }
    dispatch(getUnitReport(token, params))
  }, [token, ProjectCode, UserEmail, perPage, pageIndex])

  const onSelectedItem = (item: UnitReportItem) => {
    setSelectedItem(item)
    setPage('detail.pin')
  }

  const onClear = () => {
    setSelectedItem(undefined)
    setPage('')
  }

  const title = `รายการแก้ไขจาก ${report.CheckedBy}`
  const loading = <LoadingView isShow={event.IsLoading} />

  const pagination = (
    <Pagination
      perPage={perPage}
      totalRows={report.TotalRows}
      currentPage={pageIndex}
      onClickPage={page => setPageIndex(page)}
      onChangeNumber={num => setPerPage(num)}
    />
  )

  switch (page) {
    case 'detail.pin':
      return (
        <>
          <Breadcrumbs
            title={title}
            name={`หมุดที่ ${selectedItem.PinNumber}`}
            onBack={onClear}
          />
          <ReportPinDetail
            defectItem={selectedItem}
            onClear={onClear}
            unit={report.Unit}
            qcType={getQCTypeVersion(report)}
            canUpAfterImg={false}
            showScore={false}
          />
        </>
      )
    default:
      return (
        <>
          {loading}
          <Breadcrumbs title={title} />
          <Content className="mid-content">
            <MainPage>
              <Report
                report={report}
                checklist={checklist}
                title={title}
                onAddDefect={() => {}}
                isLoading={event.IsLoading}
                isViewMode={isReadOnly}
                onSelected={onSelectedItem}
                showAfterImg={true}
                pagination={pagination}
                hasFooter={false}
              />
            </MainPage>
          </Content>
          <PageFooter
            project={ProjectCode}
            RequestID={parseInt(requestID)}
            report={report}
          />
        </>
      )
  }
}

const MainPage = styled.div`
  background-color: #f5f6fa;
`

const mapStateToProps = (state: RootState) => {
  return {
    report: selectReport(state),
    event: selectEvent(state),
    token: serviceTokenSelector(state),
    ProjectCode: selectedProjectCodeSelector(state),
    UserEmail: userEmailSelector(state),
    unitDefect: selectUnitDefect(state),
    checklist: selectCheckListQC(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UnitReport)
