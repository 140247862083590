import RootState from 'typings/RootState'
import { Event } from '../../types/event'
import { DataList } from './types'

export const selectEvent = (state: RootState): Event =>
  state.adminReportSQDByProject.Event

export const selectReportList = (state: RootState): DataList =>
  state.adminReportSQDByProject.DataList

export const selectFilter = (state: RootState) =>
  state.adminReportSQDByProject.Filter

export const selectSorting = (state: RootState) =>
  state.adminReportSQDByProject.sorting

export const selectInitFilter = (state: RootState) =>
  state.adminReportSQDByProject.ClearFilter
