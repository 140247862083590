import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { connect, useDispatch } from 'react-redux'
import { Layout } from 'antd'
import { RouteComponentProps } from 'react-router-dom'
import RootState from 'typings/RootState'

import { DefectItem, ZoneReport } from 'features/report/zone/types'

import { selectDefect } from 'features/report/zone/selector'

import { selectEvent } from 'features/cmpj/zone/report/selector'
import {
  userEmailSelector,
  serviceTokenSelector,
} from 'features/sensitive/sensitive.selector'
import { selectedProjectCodeSelector } from 'features/project/project.selector'

import ZoneDefectReport from 'Components/Zone/Report'
import Breadcrumbs from './Breadcrumbs'

const { Content } = Layout

type FormProps = {
  code: string
}

type Props = FormProps &
  RouteComponentProps<{
    unit: string
    qcType: string
    requestID: string
    floor: string
  }> &
  ReduxProps

const QcZoneReport: React.ComponentType<Props> = props => {
  const [page, setPage] = useState('')
  const { Token, ProjectCode, UserEmail, defect, event, history, match } = props

  const { unit, qcType, floor, requestID } = match.params
  const RequestID = +requestID || 0
  const dispatch = useDispatch()

  const getName = () => {
    switch (page) {
      case 'view.defect':
        return `หมุดที่ ${defect.PinNumber}`
      default:
        return undefined
    }
  }

  return (
    <>
      <Breadcrumbs
        path={`/zone.report/${unit}/${qcType}/${requestID}/${floor}`}
        title="รายการแก้ไข"
        name={getName()}
        onBack={() => setPage('')}
      />
      <Content className="mid-content">
        <MainPage>
          <ZoneDefectReport
            title="ส่วนกลางทั่วไป"
            initPage={page}
            ProjectCode={ProjectCode}
            Unit={unit}
            QCTypeID={qcType}
            RequestID={RequestID}
            Floor={floor}
            onPage={(name: string) => setPage(name)}
            addMode={false}
            readOnly={true}
            isShowAfterImg={true}
          />
        </MainPage>
      </Content>
    </>
  )
}

const MainPage = styled.div`
  background-color: #f5f6fa;
`

const mapStateToProps = (state: RootState) => {
  return {
    Token: serviceTokenSelector(state),
    ProjectCode: selectedProjectCodeSelector(state),
    UserEmail: userEmailSelector(state),
    defect: selectDefect(state),
    event: selectEvent(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QcZoneReport)
