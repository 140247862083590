import React from 'react'
import styled from 'styled-components'
import { Modal, Row, Col } from 'antd'
import { ReportPin } from 'Components/Defect'

import { UnitReportItem } from 'features/report/unit/types'

type Props = {
  floorList: any[]
  defectList: UnitReportItem[]
  onClose: () => void
  onSave: (floor: number, pin: number, x: number, y: number) => void
  defect?: any
  viewMode?: string
}

const PinUnitLocation: React.ComponentType<Props> = props => {
  const { defect, viewMode, floorList, defectList, onClose, onSave } = props
  return (
    <Modal
      title=""
      footer=""
      visible={true}
      className="editor-view"
      onOk={onClose}
      onCancel={onClose}
      centered={false}
      closable={false}
      width="100%"
    >
      <PageView>
        <PageContent>
          <Title>ปรับตำแหน่งหมุด</Title>
          <ReportPin
            defect={defect}
            viewMode={viewMode}
            floorList={floorList}
            defectList={defectList}
            onClose={onClose}
            onSave={onSave}
            onSelected={() => {}}
          />
        </PageContent>
      </PageView>
    </Modal>
  )
}

const PageView = styled.div`
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  padding-top: 2%;
`

const Title = styled.div`
  width: 100%;
  text-align: center;
  height: 30px;
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 28px;
  color: black;
  margin-bottom: 32px;
`

const PageContent = styled.div`
  width: 1104px;
  border-radius: 5px;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.03);
  background-color: white;
  margin: 0 auto;
  padding: 26px;
`

export default PinUnitLocation
