import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'antd'
import { DropdownList } from 'Components/Shared/Form'
import InputText from 'Components/Shared/Input/InputText'
import InputDouble from 'Components/Shared/Input/InputDouble'
import { ModelListItem } from 'features/admin/model/types'

type Props = {
  direction: string
  item: ModelListItem
  onChangeData: (val: any) => void
  onDirection: (val: string) => void
}
const HeaderConfig: React.ComponentType<Props> = props => {
  const { direction, item, onChangeData, onDirection } = props

  const readOnly = item.Status.toLocaleLowerCase() === 'used'

  return (
    <Row>
      <Col span={24}>
        <BodyArea>
          <Title>{item.ModelID === '' ? 'เพิ่ม' : 'แก้ไข'} Model</Title>
        </BodyArea>
      </Col>
      <Col span={24}>
        <BodyArea>
          <InputText
            prefix="รหัส"
            value={item.ModelCode}
            onChange={(val: string) => {
              item.ModelCode = val
              onChangeData(item)
            }}
            disabled={readOnly}
          />
        </BodyArea>
        <BodyArea>
          <InputText
            prefix="ชื่อ"
            value={item.ModelName}
            onChange={(val: string) => {
              item.ModelName = val
              onChangeData(item)
            }}
            disabled={readOnly}
          />
        </BodyArea>
        <BodyArea>
          <InputDouble
            prefix="พื้นที่ (sq.m.)"
            value={`${item.UnitArea ? item.UnitArea : 0}`}
            onChange={(val: string) => {
              item.UnitArea = val
              onChangeData(item)
            }}
            disabled={readOnly}
          />
        </BodyArea>
        <BodyArea>
          <DropdownList
            label="หันซ้าย / ขวา"
            value={direction}
            menu={DirectionMenu}
            onChange={onDirection}
          />
        </BodyArea>
      </Col>
    </Row>
  )
}

const DirectionMenu = [
  { name: 'L', value: 'L' },
  { name: 'R', value: 'R' },
]

const Title = styled.div`
  color: black;
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 28px;
  padding-bottom: 16px;
  line-height: 1;
`
const BodyArea = styled.div`
  padding: 0px 24px;
  font-family: 'DB Helvethaica X 55 Regular';
  span {
    font-size: 20px;
  }
  .ant-dropdown-trigger {
    margin-bottom: 6px;
  }
  .input-title {
    margin-bottom: 12px;
  }
`

export default HeaderConfig
