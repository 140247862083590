import { put, take, takeEvery, select } from 'redux-saga/effects'
import { APIPath } from 'services/APIPath'
import { getType } from 'typesafe-actions'
import * as actions from './actions'
import { createDefect, updateDefect, setDefect } from './api'
import { DefectTypeReq, SetDefectReq } from './types'
import { uploadFile } from '../../uploadfile/func'
import { getUserProfile } from 'features/sensitive/sensitive.selector'

function* uploadFileList(
  userEmail: string,
  token: string,
  pin: DefectTypeReq,
  username: string
) {
  const { ImageBefore, ImageAfter } = pin
  if (ImageBefore && ImageBefore.file) {
    pin.ImageBefore = yield uploadFile(userEmail, token, username, ImageBefore)
  }

  if (ImageAfter && ImageAfter.file) {
    pin.ImageAfter = yield uploadFile(userEmail, token, username, ImageAfter)
  }

  return pin
}

function* sendDefectReq(action: any) {
  const {
    ProjectCode,
    UserEmail,
    Token,
    RequestID,
    CheckListID,
    Report,
    Defect,
    Module,
  } = action.payload
  try {
    const { CheckNumber } = Report

    const pin: DefectTypeReq = {
      ProjectCode,
      UserEmail,

      Unit: Report.Unit,
      RequestID,
      QCTypeID: Report.QCTypeID,
      Floor: Report.Floor,

      CheckNumber: Defect.CheckNumber === 0 ? CheckNumber : Defect.CheckNumber,

      CheckListID,

      PinID: Defect.PinID,
      PinNumber: Defect.PinNumber,
      JobTypeID: Defect.JobTypeID,
      SubJobTypeID: Defect.SubJobTypeID,
      DetailID: Defect.DetailID,
      LocationID: Defect.LocationID,

      PinStatus: Defect.PinStatus,
      Remark: Defect.Remark,
      CreatedByModule: Defect.CreatedByModule,
      // wait backend fix then remove it
      ZoneTypeID: Defect.ZoneTypeID ? Defect.ZoneTypeID : Defect.ZoneType,

      ImageBefore: Defect.ImageBefore,
      ImageAfter: Defect.ImageAfter,

      IsSansiriFixed: Defect.IsSansiriFixed,
      MCVendorCode: Defect.MCVendorCode,

      Module: Module,
    }

    const moduleApp =
      Report.State === 'CM-QC' ? 'CM' : Report.State === 'QC-QC' ? 'QC' : 'PMR'

    const profile = yield select(getUserProfile)
    const { FirstName = '', LastName = '' } = profile
    const username = `${FirstName} ${LastName} (${moduleApp})`
    const newPin = yield uploadFileList(UserEmail, Token, pin, username)
    if (Defect.PinID === 0) {
      newPin.CreatedByModule = moduleApp
      newPin.PinNumber =
        newPin.CreatedByModule === 'PMR'
          ? Report.LastedPinNumber + 1
          : Report.DefectList.length + 1

      yield put(createDefect(Token, newPin))
    } else {
      if (newPin.CheckNumber !== CheckNumber) {
        // update some part pin
        const setPinReq: SetDefectReq = {
          ProjectCode,
          UserEmail,
          PinID: newPin.PinID,
          PinNumber: newPin.PinNumber,
          Unit: newPin.Unit,
          CheckListID: newPin.CheckListID,
          RequestID: newPin.RequestID,
          QCTypeID: newPin.QCTypeID,
          CheckNumber: newPin.CheckNumber,

          PinStatus: newPin.PinStatus,
          Remark: newPin.Remark,
          ImageAfter: newPin.ImageAfter,
          Floor: newPin.Floor,
        }
        yield put(setDefect(Token, setPinReq))
      } else {
        yield put(updateDefect(Token, newPin))
      }
    }
  } catch (e) {
    console.log('e', e.message)
  }
}

function* allSaga() {
  yield takeEvery(getType(actions.submitDefectReq), sendDefectReq)
}

export default allSaga
