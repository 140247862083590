import RootState from 'typings/RootState'
import { createSelector } from 'reselect'
import { Event } from '../../types/event'
import { DataList } from './types'

export const selectEvent = (state: RootState): Event =>
  state.adminMappingTemplateCode.event

export const selectDataList = (state: RootState): DataList[] =>
  state.adminMappingTemplateCode.dataList
