import withAPIAction from 'utils/withAPIAction'
import {
  getReportAction,
  getCheckListAction,
  createDefectAction,
  updateDefectAction,
  getSelectPinAction,
  updateSelectedPintAction,
} from './actions'
import {
  GetReportReq,
  GetCheckListReq,
  DefectTypeReq,
  GetSelectPinReq,
  UpdateSelectPinReq,
} from './types'
import { verifyReq } from 'utils/verify'

export const getReport = (token: string, data: GetReportReq) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<GetReportReq>({
    method: 'POST',
    path: 'GetConstructRoadReportById',
    data,
    token: `Bearer ${token}`,
  })(getReportAction)
}

export const getCheckList = (token: string, data: GetCheckListReq) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<GetCheckListReq>({
    method: 'POST',
    path: 'GetConstructRoadCheckListForQC',
    data,
    token: `Bearer ${token}`,
  })(getCheckListAction)
}

export const createDefect = (token: string, data: DefectTypeReq) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<DefectTypeReq>({
    method: 'POST',
    path: 'CreateConstructRoadDefect	',
    data,
    token: `Bearer ${token}`,
  })(createDefectAction)
}

export const updateDefect = (token: string, data: DefectTypeReq) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<DefectTypeReq>({
    method: 'POST',
    path: 'UpdateConstructRoadDefect',
    data,
    token: `Bearer ${token}`,
  })(updateDefectAction)
}

export const getSelectPinReport = (token: string, data: GetSelectPinReq) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<GetSelectPinReq>({
    method: 'POST',
    path: 'GetSelectPinForConstructRoad',
    data,
    token: `Bearer ${token}`,
  })(getSelectPinAction)
}

export const updateSelectZonePinReport = (
  token: string,
  data: UpdateSelectPinReq
) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<UpdateSelectPinReq>({
    method: 'POST',
    path: 'SelectPinForConstructRoad',
    data,
    token: `Bearer ${token}`,
  })(updateSelectedPintAction)
}
