import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { connect, useDispatch } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import RootState from 'typings/RootState'
import { BreadcrumbLink } from 'Components/Shared/BreadCrumb/BreadCrumb'
import {
  userEmailSelector,
  serviceTokenSelector,
} from 'features/sensitive/sensitive.selector'
import {
  selectData,
  selectEvent,
  selectSorting,
} from 'features/qc/commissioning/checking/selector'
import {
  GetCheckingCommissioningByQC,
  AcceptCommissioningByQC,
  RejectCheckingTasks,
} from 'features/qc/commissioning/checking/api'
import {
  GetCheckingCommissReq,
  Data,
  AcceptCheckingTaskReq,
  RejectCheckingTaskReq,
} from 'features/qc/commissioning/checking/types'
import { sortingTask } from 'features/qc/commissioning/checking/actions'
import { StructureListPage } from 'Components/Shared/Seemore'
import { NoCard, EventModal, Loading } from 'Components/Shared'
import { RowItem, TaskFooter, TableHeader } from 'Components/QC/ProjectDetail'
import { getEvent } from 'features/wbs/wbs.selector'
import { Row, Col } from 'antd'
import { PrimaryButton } from 'Components/Shared/Button'

type Props = ReduxProps &
  DispatchProps &
  RouteComponentProps<{ project: string }>

const TodoList: React.ComponentType<Props> = props => {
  const { match, data, event, sorting, token, UserEmail, wbsEvent } = props
  const { params } = match
  const { project } = params
  const [taskSelect, setTaskSelect] = useState([] as any[])
  const [enableModal, setEnableModal] = useState(false)
  const { List } = data

  const dispatch = useDispatch()

  useEffect(() => {
    callGetChecking()
  }, [project])

  const callGetChecking = () => {
    setEnableModal(true)
    const data: GetCheckingCommissReq = {
      ProjectCode: project,
      UserEmail,
    }
    dispatch(GetCheckingCommissioningByQC(token, data))
  }

  const onCallSuccess = () => {
    callGetChecking()
    setTaskSelect([])
  }

  const acceptTask = (date: string) => {
    setEnableModal(true)
    const req: AcceptCheckingTaskReq = {
      ProjectCode: project,
      UserEmail,
      AppointmentDate: date,
      List: taskSelect,
    }
    dispatch(AcceptCommissioningByQC(token, req))
  }

  let projectName = `${data.ProjectCode} - ${data.ProjectNameTH}`

  const breadcrumbs: BreadcrumbLink[] = [
    {
      url: '/commissioning/dashboard',
      title: 'Home',
      icon: '/home.png',
      active: true,
      showTitle: false,
    },
    {
      url: '/commissioning/dashboard',
      title: 'บอร์ด',
      active: true,
      showTitle: true,
    },
    {
      url: '/',
      title: projectName,
      active: false,
      showTitle: false,
    },
  ]

  const onSort = (name: string) => {
    dispatch(sortingTask({ name }))
  }

  useEffect(() => {
    setEnableModal(false)
  }, [List])

  const renderContent = () => {
    return List.map((item: any, index) => {
      const isSelect = verifySelected(item)
      return (
        <RowItem
          task={item}
          key={index}
          onClick={() => onSelectTask(item)}
          isSelected={isSelect}
          type="commissioning"
        />
      )
    })
  }

  const remove = (data: Data) => {
    return taskSelect.filter(
      item =>
        item.RequestID !== data.RequestID ||
        item.CheckListID !== data.CheckListID ||
        item.ActivityID !== data.ActivityID ||
        item.CommissioningType !== data.CommissioningTypeID
    )
  }

  const verifySelectAll = () => {
    let isSelectAll = true
    List.map(item => {
      const isSelect = verifySelected(item)
      if (!isSelect) {
        isSelectAll = false
        return
      }
    })
    return isSelectAll
  }

  const verifySelected = (data: Data) => {
    const selectData = taskSelect.filter(item => {
      return (
        item.RequestID === data.RequestID &&
        item.CheckListID === data.CheckListID &&
        item.ActivityID === data.ActivityID &&
        item.CommissioningType === data.CommissioningTypeID
      )
    })
    return selectData.length > 0 ? true : false
  }

  const pushData = (item: Data) => {
    return {
      RequestID: item.RequestID,
      CheckListID: item.CheckListID,
      ActivityID: item.ActivityID,
      CommissioningType: item.CommissioningTypeID,
    }
  }

  const onSelectTask = (item: Data) => {
    const isSelect = verifySelected(item)
    let newValue = taskSelect
    if (isSelect) {
      newValue = remove(item)
    } else {
      newValue.push(pushData(item))
    }
    setTaskSelect([...newValue])
  }

  const onSelectAll = () => {
    const isSelectAll = verifySelectAll()
    let newValue = taskSelect
    if (isSelectAll) {
      newValue = []
    } else {
      List.map(item => {
        newValue.push(pushData(item))
      })
    }
    setTaskSelect([...newValue])
  }

  const rejectTask = () => {
    setEnableModal(true)
    let taskreqIdList: any = []
    taskSelect.map(item => {
      taskreqIdList.push({ RequestID: item.RequestID })
    })
    const req: RejectCheckingTaskReq = {
      ProjectCode: project,
      UserEmail,
      Module: 'Commissioning',
      ActionByModule: 'QC',
      TaskList: taskreqIdList,
    }
    dispatch(RejectCheckingTasks(token, req))
  }

  const tableHeader = (
    <TableHeader
      onSort={name => onSort(name)}
      sorting={sorting}
      isSelectAll={verifySelectAll()}
      onSelectAll={onSelectAll}
      showRadio={List.length > 0}
      type="commissioning"
    />
  )

  const filterContent = (
    <Space>
      <br></br>
      <Row type="flex" justify="space-between" align="middle">
        <Col></Col>
        <Col>
          <Btn>
            <PrimaryButton
              text="Reject Request"
              disabled={taskSelect.length <= 0 || event.IsLoading}
              onClick={rejectTask}
              btnType="Cancel"
            />
          </Btn>
        </Col>
      </Row>
    </Space>
  )

  const footer = (
    <TaskFooter
      taskSelect={taskSelect}
      onClick={date => acceptTask(date)}
      isLoading={event.IsLoading}
      desc="รายการตรวจสอบ"
    />
  )

  const loading = (
    <div style={{ textAlign: 'center' }}>
      <Loading />
    </div>
  )

  const content =
    data.List && data.List.length > 0 ? (
      renderContent()
    ) : (
      <NoCard text={`ไม่มีรายการ Request`} />
    )

  return (
    <>
      <EventModal
        event={[event, wbsEvent]}
        enable={enableModal}
        successCalback={onCallSuccess}
      />

      <StructureListPage
        breadcrumbs={breadcrumbs}
        tableHeader={tableHeader}
        filterHeader={filterContent}
        tableContent={event.IsLoading && !enableModal ? loading : content}
        title={projectName}
        footer={footer}
        titleMargin={40}
      />
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    data: selectData(state),
    event: selectEvent(state),
    sorting: selectSorting(state),
    token: serviceTokenSelector(state),
    UserEmail: userEmailSelector(state),
    wbsEvent: getEvent(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {
  sortingTask,
}

type DispatchProps = typeof mapDispatchToProps

const Btn = styled.div`
  width: 160px;
`

const Space = styled.div`
  margintop: '32px';
`
export default connect(mapStateToProps, mapDispatchToProps)(TodoList)
