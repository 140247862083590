import React from 'react'
import styled from 'styled-components'
import { UnitReport } from 'features/report/unit/types'
import {
  getQCTypeVersion,
  getDateText,
  getQCTypeVersionForQC,
} from 'utils/getText'
import { unitStatusIsPass } from 'utils/status-map'
import { UnitStatus } from 'features/types/status'
import {
  ReportScore,
  ReportTaskInfo,
  ReportTaskInfoWaring,
} from 'Components/Shared/ReportInfo'

type Props = {
  data: UnitReport
}

const ReportInfo: React.ComponentType<Props> = props => {
  const { data } = props

  const renderTaskInfo = (title: string, value: string) => {
    return <ReportTaskInfo title={title} value={value} />
  }

  const renderWaringInfo = (title: string, value: string) => {
    return <ReportTaskInfoWaring title={title} value={value} />
  }

  const isPass = unitStatusIsPass(true, data.CheckStatus)
  const enableFunction =
    data.CheckStatus === UnitStatus.PassByFunc ||
    data.CheckStatus === UnitStatus.NotPassByFunction

  const renderFunction = () => {
    if (isPass) {
      return <PassScore>ผ่าน Function</PassScore>
    } else {
      return <FailScore>ไม่ผ่าน Function</FailScore>
    }
  }

  return (
    <ScoreArea>
      <HeaderScore>
        {enableFunction ? (
          renderFunction()
        ) : (
          <ReportScore isPass={isPass} score={data.TotalScore} />
        )}
      </HeaderScore>
      <InspectText>
        {data.CheckStatus != 'NotInspection' ? '' : '[ ไม่ประเมินคะแนน ]'}
      </InspectText>
      <TaskInfo>
        {renderTaskInfo('หมวด QC', getQCTypeVersionForQC(data))}
        {renderTaskInfo('โมเดล', data.ModelName)}
        {renderTaskInfo('ยูนิต', data.UnitDisplay)}
        {renderTaskInfo('วันที่ตรวจ', getDateText(data.InspectDate))}
        {renderTaskInfo('วันที่ส่งรายงาน', getDateText(data.CheckedDate))}

        {data.Reject != undefined &&
          data.Reject.RejectReason !== '' &&
          renderWaringInfo('สาเหตุที่ Reject', data.Reject.RejectReason)}

        {data.Reject != undefined &&
          data.Reject.RejectReason !== '' &&
          renderWaringInfo(
            'หมายเหตุ',
            data.Reject.Remark != '' ? data.Reject.Remark : '-'
          )}
      </TaskInfo>
    </ScoreArea>
  )
}

const ScoreArea = styled.div`
  padding: 140px 40px 0 86px;
`

const HeaderScore = styled.div`
  width: 100%;
  text-align: center;
`

const ScoreText: any = styled.span`
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 44px;
  line-height: 0;
`

const PassScore = styled(ScoreText)`
  color: #5bc79e;
`

const FailScore = styled(ScoreText)`
  color: #d0494c;
`

const TaskInfo = styled.div`
  padding-top: 25px;
  font-family: 'DB Helvethaica X 55 Regular';
  font-size: 20px;
  color: #000;
`
const InspectText = styled.p`
  font-size: 20px;
  margin-bottom: 0;
  font-family: 'DB Helvethaica X 75 Bd';
  line-height: 1;
  color: #20a1ef;
`
export default ReportInfo
