import withAPIAction from 'utils/withAPIAction'
import { getReportListAction, exportReportListAction } from './actions'
import { GetReportListReq } from './types'
import { verifyReqNoProject } from 'utils/verify'

export const getReportList = (token: string, data: GetReportListReq) => {
  const isValid = verifyReqNoProject(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<GetReportListReq>(state => ({
    method: 'POST',
    path: 'GetUnitDailyReport',
    data,
    token: `Bearer ${token}`,
  }))(getReportListAction)
}

export const exportReportList = (token: string, data: GetReportListReq) => {
  const isValid = verifyReqNoProject(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<GetReportListReq>(state => ({
    method: 'POST',
    path: 'GetUnitDailyReport',
    data,
    token: `Bearer ${token}`,
  }))(exportReportListAction)
}
