import React, { useEffect } from 'react'
import styled from 'styled-components'
import { connect, useDispatch } from 'react-redux'
import SubnavBar from 'Components/Shared/SubnavBar'
import { Row } from 'antd'
import { RouteComponentProps } from 'react-router-dom'
import RootState from 'typings/RootState'

import { BreadcrumbLink } from '../../Shared/BreadCrumb/BreadCrumb'
import NoCard from 'Components/Shared/NoCard'
import LinkButton from 'Components/Shared/LinkButton'

import { selectedProjectCodeSelector } from 'features/project/project.selector'

import { getEvent, getWbsPathMapSelector } from 'features/wbs/wbs.selector'

import {
  userEmailSelector,
  serviceTokenSelector,
} from 'features/sensitive/sensitive.selector'

import {
  selectData,
  selectEvent,
  selectSorting,
} from 'features/maincon/dashboard/selector'

import {
  sortingDashboard,
  filterDashboard,
} from 'features/maincon/dashboard/actions'
import {
  selectActivityFilter,
  selectisClearFilter,
} from 'features/filter/selector'

import { getActivity } from 'features/maincon/dashboard/api'

import {
  TypeOfLane,
  GetDashboardActivityRequest,
} from 'features/maincon/dashboard/types'

import { translate } from 'i18n'
import TKeys from 'i18n/translationKey'
import ColumnHeader from './ColumnHeader'
import Card from './Card'
import UnitCard from './UnitCard'
import ZoneCard from './ZoneCard'
import CommissioningCard from './CommissioningCard'

import Loading from 'Components/Shared/Loading'
import moment from 'moment'
import { isUnit, isZone, isCommissioning } from 'utils/helper'
import { ActivityFilterPage } from 'Components/Filter'
import { toDateText } from 'utils/datetime'
import { mergeArray } from 'utils/format'
import { LoadingView } from 'Components/Shared'
import { useLocation } from 'react-router'
import queryString from 'query-string'
import { ActivityFilter } from 'features/filter/types'
import Menu from '../Menu'

const breadcrumbs: BreadcrumbLink[] = [
  {
    url: '/dashboard',
    title: 'Home',
    icon: '/home.png',
    active: true,
    showTitle: false,
  },
  {
    url: '/dashboard',
    title: translate(TKeys.Breadcrumb.dashboard),
    active: false,
    showTitle: false,
  },
]

const text = {
  todo: translate(TKeys.Dashboard.header_todo),
  in_progress: translate(TKeys.Dashboard.header_in_progress),

  start_day: 'วันที่เริ่มงาน',
  end_day: 'วันสิ้นสุดงาน',

  seemore: translate(TKeys.Dashboard.tasklane_seemore),
  requested: 'ส่งคำขอแล้ว',
  checking: 'CM กำลังตรวจสอบ',
  qcfixed: 'รายการแก้ไข',
}

type Props = ReduxProps & DispatchProps & RouteComponentProps

const MainConDashboard: React.ComponentType<Props> = props => {
  const {
    data,
    event,
    sorting,
    token,
    ProjectCode,
    UserEmail,
    wbs,
    sortingTask,
    filter,
    isClearFilter,
    wbsEvent,
  } = props

  const dispatch = useDispatch()
  const searchParam = useLocation()

  useEffect(() => {
    if (isClearFilter) {
      onGetData()
    }
  }, [isClearFilter, ProjectCode, dispatch])

  const onGetData = () => {
    // if (searchParam.search) {
    //   const param = queryString.parse(searchParam.search)
    //   const activityFilter: ActivityFilter = JSON.parse(
    //     param.search!.toString()
    //   )

    //   filter.Date = activityFilter.Date
    //   filter.ConstructionTypeList = activityFilter.ConstructionTypeList
    //   filter.UnitList = activityFilter.UnitList
    //   filter.FloorList = activityFilter.FloorList
    //   filter.LeafWbsList = activityFilter.LeafWbsList
    // }

    let data: GetDashboardActivityRequest = {
      ProjectCode,
      UserEmail,
      Sort: {},
      StartDate: toDateText(filter.Date.StartDate || ''),
      FinishDate: toDateText(filter.Date.FinishDate || ''),
      ConstructionTypeList: filter.ConstructionTypeList,
      FloorList: filter.FloorList,
      UnitList: mergeArray([filter.UnitList, filter.ZoneList]),
      ZoneList: [],
      WbsList: filter.LeafWbsList,
    }

    dispatch(getActivity(token, data))
  }

  const todoList = data.todo.map((task, index) => {
    return (
      <Card
        task={task}
        wbs={wbs}
        key={index}
        type={TypeOfLane.TODO}
        search={searchParam.search}
      />
    )
  })

  const progressList = data.progress.map((task, index) => {
    return (
      <Card
        task={task}
        wbs={wbs}
        key={index}
        type={TypeOfLane.PROGRESS}
        search={searchParam.search}
      />
    )
  })

  const requestedList = data.requested.map((task, index) => {
    return (
      <Card
        task={task}
        wbs={wbs}
        key={index}
        type={TypeOfLane.REQUESTED}
        search={searchParam.search}
      />
    )
  })

  const checkingList = data.checking.map((task, index) => {
    return (
      <Card
        task={task}
        wbs={wbs}
        key={index}
        type={TypeOfLane.CHECKING}
        search={searchParam.search}
      />
    )
  })

  const qcList = data.qc.map((task, index) => {
    if (isUnit(task.Zone)) {
      return <UnitCard task={task} key={index} />
    }
    if (isZone(task.Zone)) {
      return <ZoneCard task={task} key={index} />
    }
    if (isCommissioning(task.Zone)) {
      return <CommissioningCard task={task} key={index} />
    }
  })

  const loading = (
    <div style={{ textAlign: 'center' }}>
      <Loading />
    </div>
  )

  const renderCard = (
    cardlist: any,
    type: string,
    displaySeemore: boolean = true
  ) => {
    let tasklen = cardlist
    if (event.isFetching) {
      return loading
    }

    if (cardlist.length <= 0) {
      return <NoCard />
    } else if (cardlist.length >= 9 && displaySeemore) {
      tasklen.push(
        <LinkButton
          href={`/seemore/${type}${searchParam.search}`}
          title={text.seemore}
        />
      )
    }

    return tasklen
  }

  return (
    <>
      <Menu selected="dashboard" />
      <SubnavBar breadcrumbs={breadcrumbs} hideSearch={true} />
      <MainPage>
        <LoadingView isShow={event.isFetching || wbsEvent.IsLoading} />
        <ActivityFilterPage onSearch={onGetData} />
        <InfoPage>
          <Row type="flex">
            <MainColumn>
              <Title>Maincon</Title>
              <ColumnL>
                <ColumnHeader
                  heading={`${text.todo} (${data.TotalTodo})`}
                  orderName={text.start_day}
                  type={sorting.todo}
                  onSort={() => {
                    sortingTask(TypeOfLane.TODO)
                  }}
                />
                {renderCard(todoList, TypeOfLane.TODO)}
              </ColumnL>

              <ColumnR>
                <ColumnHeader
                  heading={`${text.in_progress} (${data.TotalInProgress})`}
                  orderName={text.end_day}
                  type={sorting.progress}
                  onSort={() => {
                    sortingTask(TypeOfLane.PROGRESS)
                  }}
                />
                {renderCard(progressList, `in-${TypeOfLane.PROGRESS}`)}
              </ColumnR>
            </MainColumn>

            <MainColumn style={{ backgroundColor: 'rgba(121, 202, 220, 0.1)' }}>
              <Title>CM/PJ</Title>
              <ColumnL className="col">
                <ColumnHeader
                  heading={`${text.requested} (${data.TotalRequested})`}
                  orderName={text.end_day}
                  type={sorting.requested}
                  onSort={() => {
                    sortingTask(TypeOfLane.REQUESTED)
                  }}
                />
                {renderCard(requestedList, TypeOfLane.REQUESTED, false)}
              </ColumnL>

              <ColumnR>
                <ColumnHeader
                  heading={`${text.checking} (${data.TotalCMChecking})`}
                  orderName={text.end_day}
                  type={sorting.checking}
                  onSort={() => {
                    sortingTask(TypeOfLane.CHECKING)
                  }}
                />
                {renderCard(checkingList, TypeOfLane.CHECKING, false)}
              </ColumnR>
            </MainColumn>
            <LastColumn>
              <Title>QC</Title>
              <FullColumn>
                <ColumnHeader
                  heading={`${text.qcfixed} (${data.TotalFixFromQC})`}
                  orderName={'วันที่ล่าสุด'}
                  type={sorting.qc}
                  onSort={() => {
                    sortingTask(TypeOfLane.QC)
                  }}
                />
                {renderCard(qcList, TypeOfLane.QC, false)}
              </FullColumn>
            </LastColumn>
          </Row>
        </InfoPage>
      </MainPage>
    </>
  )
}

const MainPage = styled.div`
  padding: 0 80px;
  background-color: #f5f6fa;
  width: 100vw;
  -khtml-user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  overflow-x: scroll;
`

const InfoPage = styled.div`
  width: 110vw;
`

const Title = styled.h2`
  font-size: 44px;
  font-family: 'DB Helvethaica X 75 Bd';
  height: 53px;
`

const cssCol = `
  display: block;
  position: relative;
  box-sizing: border-box;
  flex: 0 0 auto;
  padding: 24px;
  background-color: rgba(0, 49, 104, 0.1);
  min-height: 70vh;
`
const MainColumn = styled.div`
  ${cssCol}
  width: 40%;
`

const LastColumn = styled.div`
  ${cssCol}
  width: 20%;
`

const ColumnL = styled.div`
  width: 48%;
  flex: 0 0 auto;
  float: left;
`

const ColumnR = styled.div`
  width: 48%;
  flex: 0 0 auto;
  float: right;
`

const FullColumn = styled.div`
  width: 100%;
  flex: 0 0 auto;
  float: left;
`

const mapStateToProps = (state: RootState) => {
  return {
    data: selectData(state),
    event: selectEvent(state),
    sorting: selectSorting(state),
    token: serviceTokenSelector(state),
    ProjectCode: selectedProjectCodeSelector(state),
    UserEmail: userEmailSelector(state),
    wbs: getWbsPathMapSelector(state),
    filter: selectActivityFilter(state),
    isClearFilter: selectisClearFilter(state),
    wbsEvent: getEvent(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {
  sortingTask: sortingDashboard,
  filterDashboard: filterDashboard,
}

type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(MainConDashboard)
