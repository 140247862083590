import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { connect, useDispatch } from 'react-redux'
import { RouteComponentProps, Link } from 'react-router-dom'
import RootState from 'typings/RootState'
import { BreadcrumbLink } from 'Components/Shared/BreadCrumb/BreadCrumb'
import { selectedProjectCodeSelector } from 'features/project/project.selector'
import Pagination from 'Components/Shared/Pagination'
import TableHeader from './TableHeader'
import {
  userEmailSelector,
  serviceTokenSelector,
} from 'features/sensitive/sensitive.selector'
import {
  selectData,
  selectEvent,
  selectSorting,
} from 'features/cmpj/seemoreQc/selector'
import { getActivitySeeMoreForQCapi } from 'features/cmpj/seemoreQc/api'
import { GetActivitySeemoreRequest, Data } from 'features/cmpj/seemoreQc/types'
import { StartCheckingRequest } from 'features/cmpj/cmchecking/types'
import { StartCheckingByCMApi } from 'features/cmpj/cmchecking/api'
import { selectEvent as selectcmcheckingEvent } from 'features/cmpj/cmchecking/selector'
import { sortingTask } from 'features/cmpj/seemoreQc/actions'
import RowItem from './RowItem'
import TaskFooter from './TaskFooter'
import { TypeOfTask } from 'features/cmpj/qcdashboard/types'
import { StructureListPage } from 'Components/Shared/Seemore'
import {
  NoCard,
  LoadingView,
  AlertSuccess,
  AlertError,
} from 'Components/Shared'

type Props = ReduxProps & DispatchProps & RouteComponentProps<{ type: string }>

const QcSeemore: React.ComponentType<Props> = props => {
  const {
    match,
    data,
    event,
    sorting,
    token,
    ProjectCode,
    UserEmail,
    checkingEven,
  } = props

  const { params } = match
  const { type } = params
  const [taskSelect, setTaskSelect] = useState([] as any[])
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [enableModal, setEnableModal] = useState(false)
  const { DataList, TotalRows } = data

  useEffect(() => {}, [type])

  const dispatch = useDispatch()

  let pageType = 1
  switch (type) {
    case TypeOfTask.TODO:
      pageType = 1
      break
    case TypeOfTask.CMInProgress:
      pageType = 2
      break
  }

  useEffect(() => {
    callGetActivitySeeMoreForQC()
  }, [ProjectCode, perPage, page])

  const onClickStartChecking = () => {
    setEnableModal(true)
    const request: StartCheckingRequest = {
      ProjectCode,
      UserEmail,
      ItemList: taskSelect,
    }
    dispatch(StartCheckingByCMApi(token, request))
  }

  const callGetActivitySeeMoreForQC = () => {
    const data: GetActivitySeemoreRequest = {
      ProjectCode,
      UserEmail,
      Sort: {},
      PageType: pageType,
      Page: page,
      RowPerPage: perPage,
    }
    dispatch(getActivitySeeMoreForQCapi(token, data))
  }

  let title = `พร้อมตรวจด้วยตัวเอง (${TotalRows})`
  let breadcrumbTxt = 'พร้อมตรวจด้วยตัวเอง'
  switch (type) {
    case TypeOfTask.CMInProgress:
      title = `กำลังดำเนินการ (${TotalRows})`
      breadcrumbTxt = 'กำลังดำเนินการ'
      break
  }

  const breadcrumbs: BreadcrumbLink[] = [
    {
      url: '/qcdashboard',
      title: 'Home',
      icon: '/home.png',
      active: true,
      showTitle: false,
    },
    {
      url: '/qcdashboard',
      title: 'บอร์ด',
      active: true,
      showTitle: true,
    },
    {
      url: '/',
      title: breadcrumbTxt,
      active: false,
      showTitle: false,
    },
  ]

  const onSort = (name: string) => {
    dispatch(sortingTask({ name }))
  }

  useEffect(() => {
    setEnableModal(false)
  }, [DataList])

  const renderContent = () => {
    return DataList.map((item: Data, index) => {
      const isSelect = verifySelected(item.Unit, item.QCTypeID)
      return (
        <Link to={`/qcdetail/${item.Unit}/${item.QCTypeID}/${item.RequestID}`}>
          <RowItem
            type={type}
            task={item}
            key={index}
            onClick={() => onSelectTask(item)}
            isSelected={isSelect}
          />
        </Link>
      )
    })
  }

  const remove = (Unit: string, QCTypeID: string) => {
    return taskSelect.filter(
      item => item.Unit !== Unit || item.QCTypeID !== QCTypeID
    )
  }

  const verifySelectAll = () => {
    let isSelectAll = true
    DataList.map(item => {
      if (item.PassedSubTasks === item.TotalSubTasks) {
        const isSelect = verifySelected(item.Unit, item.QCTypeID)
        if (!isSelect) {
          isSelectAll = false
          return
        }
      }
    })
    return isSelectAll
  }

  const verifySelected = (Unit: string, QCTypeID: string) => {
    const selectData = taskSelect.filter(item => {
      return item.Unit === Unit && item.QCTypeID === QCTypeID
    })
    return selectData.length > 0 ? true : false
  }

  const showRadio = () => {
    let show = false
    DataList.map(item => {
      if (item.PassedSubTasks === item.TotalSubTasks) {
        show = true
      }
    })
    return show
  }

  const onSelectTask = (item: Data) => {
    const isSelect = verifySelected(item.Unit, item.QCTypeID)
    let newValue = taskSelect
    if (isSelect) {
      newValue = remove(item.Unit, item.QCTypeID)
    } else {
      newValue.push({
        Unit: item.Unit,
        QCTypeID: item.QCTypeID,
        RequestID: item.RequestID,
        ModelID: item.ModelID,
      })
    }
    setTaskSelect([...newValue])
  }

  const onSelectAll = () => {
    const isSelectAll = verifySelectAll()
    let newValue = taskSelect
    if (isSelectAll) {
      newValue = []
    } else {
      DataList.map(item => {
        if (item.PassedSubTasks === item.TotalSubTasks) {
          newValue.push({
            Unit: item.Unit,
            QCTypeID: item.QCTypeID,
          })
        }
      })
    }
    setTaskSelect([...newValue])
  }

  const pagination = (
    <Pagination
      perPage={perPage}
      totalRows={TotalRows}
      currentPage={page}
      onClickPage={page => setPage(page)}
      onChangeNumber={number => setPerPage(number)}
    />
  )

  const tableHeader = (
    <TableHeader
      type={type}
      onSort={name => onSort(name)}
      sorting={sorting}
      isSelectAll={verifySelectAll()}
      onSelectAll={onSelectAll}
      showRadio={showRadio()}
    />
  )

  const footer = (
    <TaskFooter
      taskSelect={taskSelect}
      type={type}
      onClick={onClickStartChecking}
      isLoading={event.IsLoading}
    />
  )

  return (
    <>
      <AlertError
        text={checkingEven.SubmitError}
        isShow={checkingEven.SubmitError && enableModal ? true : false}
        callback={() => setEnableModal(false)}
      />
      <AlertSuccess
        text={checkingEven.MessageSuccess}
        isShow={checkingEven.SubmitSuccess && enableModal ? true : false}
        callback={callGetActivitySeeMoreForQC}
      />
      <LoadingView isShow={event.IsLoading || checkingEven.IsLoading} />
      <StructureListPage
        breadcrumbs={breadcrumbs}
        tableHeader={tableHeader}
        titleMargin={32}
        tableContent={
          data.DataList.length > 0 ? (
            renderContent()
          ) : (
            <NoCard text={`ไม่มีข้อมูล${breadcrumbTxt}`} />
          )
        }
        title={title}
        footerPagination={pagination}
        footer={footer}
      />
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    data: selectData(state),
    event: selectEvent(state),
    sorting: selectSorting(state),
    token: serviceTokenSelector(state),
    ProjectCode: selectedProjectCodeSelector(state),
    UserEmail: userEmailSelector(state),
    checkingEven: selectcmcheckingEvent(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {
  sortingTask,
}

type DispatchProps = typeof mapDispatchToProps

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QcSeemore)
