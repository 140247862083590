import React, { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { Layout, Row, Col } from 'antd'
import { RouteComponentProps } from 'react-router-dom'

import InputText from 'Components/Shared/Input/InputText'
import PrimaryButton from 'Components/Shared/Button/PrimaryButton'
import AddEditModal, { Handles } from 'Components/Shared/Modal/AddEditModal'
import { LoadingView, AlertError, AlertSuccess } from 'Components/Shared'
import SubnavBar from 'Components/Shared/SubnavBar'
import { BreadcrumbLink } from 'Components/Shared/BreadCrumb/BreadCrumb'
import Menu from '../../../MenuAdmin'
import CheckList from 'Components/Inspection/CheckList'
import { DropdownList } from 'Components/Shared/Form'
import { AddList, EditDeleteButton } from 'Components/Shared/Button'

import { getFunctionCheckListDetail } from 'features/checklist/unit/api'
import { InspectionList } from 'features/types/checklist'
import RootState from 'typings/RootState'
import { userEmailSelector } from 'features/sensitive/sensitive.selector'
import { getProjectType } from 'features/masterData/api'
import { selectProjectType } from 'features/masterData/selector'
import { ProjectType } from 'features/masterData/types'

import {
  createUpdateFunctionReq,
  clearFunctionDetail,
} from 'features/checklist/unit/actions'
import { FunctionDetail } from 'features/checklist/unit/types'
import { CheckListDetail } from 'features/checklist/master/types'

type Props = ReduxProps & DispatchProps & RouteComponentProps<{ id: string }>

const breadcrumbs: BreadcrumbLink[] = [
  {
    url: '/dashboard',
    title: 'Home',
    icon: '/home.png',
    active: true,
    showTitle: false,
  },
  {
    url: '/check-function',
    title: 'ตรวจ function',
    active: true,
    showTitle: true,
  },
  {
    url: '/check-function/:id',
    title: 'แก้ไข Checklist function',
    active: false,
    showTitle: true,
  },
]

const getCount = (list: InspectionList[]) => {
  let counter = 0
  list.forEach(lv1 => {
    if (lv1.Data) {
      lv1.Data.forEach(lv2 => (counter += lv2.Data ? lv2.Data.length : 0))
      counter += lv1.Data.length
    }
  })

  counter += list.length
  return counter
}

const QCCheckFunctionDetail: React.ComponentType<Props> = ({
  match,
  event,
  FunctionDetail,
  getFunctionCheckListDetail,
  getProjectType,
  projectType,
  createUpdateFunction,
  clearFunctionDetail,
}) => {
  const modalRef = useRef<Handles>(null)
  const [enableModal, setEnableModal] = useState(false)
  const [detail, setDetail] = useState(FunctionDetail)
  const isUsed = detail.Status.toLocaleLowerCase() === 'used'

  const setSeqNumber = (list: any[]): any[] => {
    list.forEach((it1: any, i1: number) => {
      const lv1 = i1 + 1
      it1.Level = 1
      it1.Seq = `${lv1}`
      it1.Data.forEach((it2: any, i2: number) => {
        const lv2 = i2 + 1
        it2.Level = 2
        it2.Seq = `${lv1}.${lv2}`
        it2.Data.forEach((it3: any, i3: number) => {
          const lv3 = i3 + 1
          it3.Level = 3
          it3.Seq = `${lv1}.${lv2}.${lv3}`
        })
      })
    })

    return list
  }

  const onSave = () => {
    setEnableModal(true)
    const findItem = projectType.List.find(
      it => it.ProjectTypeID === detail.ProjectTypeID
    )
    if (findItem) {
      detail.ProjectTypeName = findItem.ProjectTypeName
    }
    detail.InspectionList = setSeqNumber(detail.InspectionList)
    createUpdateFunction(detail)
  }
  const CheckListID = match.params.id
  useEffect(() => {
    setDetail(FunctionDetail)
  }, [FunctionDetail])

  useEffect(() => {
    setEnableModal(true)
    if (CheckListID) {
      getFunctionCheckListDetail(CheckListID)
    }
    getProjectType({ Page: 1, RowPerPage: 200 })
    return () => {
      clearFunctionDetail()
    }
  }, [])

  const onTypeChange = (val: any) => {
    detail.ProjectTypeID = val
    setDetail({ ...detail })
  }

  const onName = (val: string) => {
    detail.CheckListName = val
    setDetail({ ...detail })
  }

  const onChangeCheckList = (val: CheckListDetail) => {
    setDetail({ ...detail, ...val })
  }

  const showAddChecklist = (value?: string) => {
    if (modalRef.current) {
      modalRef.current.showModal('กรอก องประกอบในห้องชุด', '', value)
    }
  }

  const showDeleteModal = (index: number) => {
    detail.CheckList.splice(index, 1)
    setDetail({ ...detail })
  }

  const onAdd = (newName: string, prev?: string) => {
    if (prev) {
      for (let i = 0; i < detail.CheckList.length; i++) {
        if (detail.CheckList[i] === prev) {
          detail.CheckList[i] = newName
          break
        }
      }
    } else {
      detail.CheckList.push(newName)
    }

    setDetail({ ...detail })
  }

  const list = detail.CheckList.map((item, index) => {
    return (
      <Item key={index}>
        <Row type="flex" justify="space-between" className="row-item">
          <span>{item}</span>
          <EditDeleteButton
            showEditeModal={() => showAddChecklist(item)}
            showDeleteModal={isUsed ? undefined : () => showDeleteModal(index)}
          />
        </Row>
      </Item>
    )
  })

  const content = (
    <ListSection>
      {list}
      {!isUsed && (
        <AddList style={{ margin: 0 }} onClick={() => showAddChecklist()} />
      )}
    </ListSection>
  )

  const typeMenu = projectType.List.map(item => {
    return {
      value: item.ProjectTypeID,
      name: item.ProjectTypeName,
    }
  })

  return (
    <>
      <LoadingView isShow={event.IsLoading} />
      <AlertError
        text={event.SubmitError}
        isShow={!!event.SubmitError && enableModal}
      />
      <AlertSuccess
        text={event.MessageSuccess}
        isShow={!!event.SubmitSuccess && enableModal}
      />
      <SubnavBar breadcrumbs={breadcrumbs} hideSearch={true} />
      <Layout.Content className="mid-content">
        <Menu selected="check-function">
          <MainPage>
            <LeftSection>
              <FixSection>
                <Title>แก้ไข checklist function</Title>

                <InputText
                  prefix="ชื่อ"
                  placeholder="กรุณากรอกชื่อ"
                  value={detail.CheckListName}
                  onChange={onName}
                  disabled={isUsed}
                />
              </FixSection>

              <TabHeader>
                <TabBtn>รายการตรวจ ({getCount(detail.InspectionList)})</TabBtn>
              </TabHeader>

              <TabBody>
                <ScrollSection>
                  <CheckList
                    readOnly={isUsed}
                    checklist={detail}
                    onChange={onChangeCheckList}
                  />
                </ScrollSection>
              </TabBody>
            </LeftSection>

            <RightSection>
              <FixSection>
                <Row type="flex" justify="end">
                  <Col style={{ width: '116px' }}>
                    <PrimaryButton text="บันทึก" onClick={onSave} />
                  </Col>
                </Row>

                <SizedBox />

                <DropdownList
                  label="ประเภทโครงการ"
                  value={detail.ProjectTypeID}
                  placeholder="กรุณาเลือก"
                  menu={typeMenu}
                  onChange={onTypeChange}
                  disabled={isUsed}
                />

                <TitleSecond>
                  องค์ประกอบภายในห้องชุด ({detail.CheckList.length})
                </TitleSecond>
                {content}
              </FixSection>
            </RightSection>
          </MainPage>
        </Menu>
      </Layout.Content>
      <AddEditModal ref={modalRef} readOnly={isUsed} onOk={onAdd} />
    </>
  )
}

const DataItem = styled.div`
  padding: 5px 10px;

  &:hover {
    cursor: pointer;
    color: white;
    background-color: #003168;
  }
`
const MainPage = styled.div`
  -khtml-user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  overflow-x: scroll;
`

const Title = styled.div`
  color: black;
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 28px;
  margin-bottom: 32px;
`

const FixSection = styled.div`
  height: 140px;

  .input {
    padding: 31px 0 34px 0;
  }
`

const TabHeader = styled.div``

const TabBtn = styled.span`
  color: #003168;
  background-color: white;
  padding: 10px 16px;

  font-size: 20px;
  font-family: 'DB Helvethaica X 75 Bd';
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  margin-right: 5px;
  cursor: pointer;

  &.inactive {
    color: #8b99a7;
    background-color: rgba(139, 153, 167, 0.1);
  }
`
const SizedBox = styled.div`
  height: 28px;
`
const TabBody = styled.div`
  background-color: white;
  padding: 16px;
  margin-top: 2px;
  border-radius: 5px;
  border-top-left-radius: 0px;
  min-height: 60vh;
`

const LeftSection = styled.div`
  width: 40%;

  background-color: rgba(139, 153, 167, 0.1);
  padding: 0px 32px;
  padding-top: 40px;
  padding-bottom: 20px;
  float: left;
`

const RightSection = styled.div`
  width: 60%;
  padding: 0px 24px;
  padding-top: 36px;
  float: left;
`
const TitleSecond = styled.h2`
  font-size: 20px;
  font-family: 'DB Helvethaica X 75 Bd';
  margin-top: 11px;
`

const ScrollSection = styled.div`
  height: calc(100% - 224px);
  overflow-y: scroll;
`

const ListSection = styled.div`
  margin-top: 16px;
  overflow-y: scroll;
  padding: 0 24px;
  margin: 8px 0px;
  border-radius: 5px;
  border: solid 1px #eeeff2;
  background-color: #ffffff;
`

const Item = styled.div`
  font-size: 20px;
  border-bottom: 1px solid #e8ebf0;
  align-items: center;
  display: flex;
  height: 56px;
  /* padding: 0px 16px; */
  cursor: pointer;

  &.selected {
    border-radius: 5px;
    border: solid 1px #003168;
  }

  .row-item {
    width: 100%;
  }
`
const mapStateToProps = (state: RootState) => {
  return {
    event: state.unitChecklist.Event,
    projectType: selectProjectType(state),
    FunctionDetail: state.unitChecklist.FunctionDetail,
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {
  getFunctionCheckListDetail,
  getProjectType,
  createUpdateFunctionReq,
  createUpdateFunction: (detail: FunctionDetail) =>
    createUpdateFunctionReq.request(detail),
  clearFunctionDetail,
}

type DispatchProps = typeof mapDispatchToProps

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QCCheckFunctionDetail)
