import App from './Components/App'
import { Provider } from 'react-redux'
import store from './store/index'
import React from 'react'
import { hot } from 'react-hot-loader/root'

const MainApp = () => {
  const getStore = store()

  return (
    <Provider store={getStore.store}>
      <App />
    </Provider>
  )
}

export default (process.env.REACT_APP_ENV === 'development'
  ? hot(MainApp)
  : MainApp)
