import { getType } from 'typesafe-actions'
import { cloneDeep } from 'lodash'

import * as actions from './actions'
import { StoreState, ReportAction, Filter } from './types'
import produce from 'immer'
import parseErrorMessage from 'utils/parseErrorMessage'
import { Event } from '../../types/event'

const initEvent = {
  IsLoading: false,
  MessageSuccess: undefined,
  SubmitError: undefined,
  SubmitSuccess: false,
  SubmitType: undefined,
} as Event

const initFilter = {
  TextFilter: '',
  ModuleList: [],
  TypeList: [],
  StatusList: [],
} as Filter

const initialState: StoreState = {
  dataList: {
    Total: 0,
    List: [],
  },
  sansiriList: [],
  vandorList: [],
  companyList: [],
  event: cloneDeep(initEvent),
  sansiriEvent: cloneDeep(initEvent),
  vandorEvent: cloneDeep(initEvent),
  userList: [],
  filter: cloneDeep(initFilter),
}

export default function(
  state: StoreState = initialState,
  action: ReportAction
): StoreState {
  return produce(state, draft => {
    switch (action.type) {
      case getType(actions.getDataListAction.request):
        draft.event = cloneDeep(initEvent)
        draft.event.IsLoading = true
        draft.dataList = {
          Total: 0,
          List: [],
        }

        break
      case getType(actions.getDataListAction.success):
        draft.event.IsLoading = false
        draft.dataList = action.payload.ResponseData
        break

      case getType(actions.getSansiriListAction.request):
        draft.sansiriEvent = cloneDeep(initEvent)
        draft.sansiriEvent.IsLoading = true
        draft.sansiriList = []
        break

      case getType(actions.getSansiriListAction.success):
        draft.sansiriEvent.IsLoading = false
        draft.sansiriList = action.payload.ResponseData.List
        break

      case getType(actions.getVandorListAction.request):
        draft.vandorEvent = cloneDeep(initEvent)
        draft.vandorEvent.IsLoading = true
        draft.vandorList = []
        break

      case getType(actions.getVandorListAction.success):
        draft.vandorEvent.IsLoading = false
        draft.vandorList = action.payload.ResponseData.List
        break
      case getType(actions.createDataAction.request):
      case getType(actions.updateDataAction.request):
        draft.event = cloneDeep(initEvent)
        draft.event.IsLoading = true
        break

      case getType(actions.createDataAction.success):
      case getType(actions.updateDataAction.success):
        draft.event.IsLoading = false
        draft.event.SubmitError = undefined
        draft.event.SubmitSuccess = true
        draft.event.MessageSuccess = 'ทำรายการสำเร็จ'
        break

      case getType(actions.updateDataAction.failure):
      case getType(actions.createDataAction.failure):
      case getType(actions.getDataListAction.failure):
        draft.event.IsLoading = false
        draft.event.SubmitError = parseErrorMessage(action.payload)
        break

      case getType(actions.getSansiriListAction.failure):
        draft.sansiriEvent.IsLoading = false
        draft.sansiriEvent.SubmitError = parseErrorMessage(action.payload)
        break

      case getType(actions.getVandorListAction.failure):
        draft.vandorEvent.IsLoading = false
        draft.vandorEvent.SubmitError = parseErrorMessage(action.payload)
        break

      case getType(actions.findUserAction.request):
        draft.event = cloneDeep(initEvent)
        draft.event.IsLoading = true
        draft.userList = []
        break
      case getType(actions.findUserAction.success):
        draft.event.IsLoading = false
        draft.userList = action.payload.ResponseData.List
        break
      case getType(actions.findUserAction.failure):
        draft.event.IsLoading = false
        draft.event.SubmitError = parseErrorMessage(action.payload)
        break

      case getType(actions.getCompanyVandorListAction.request):
        draft.vandorEvent = cloneDeep(initEvent)
        draft.vandorEvent.IsLoading = true
        draft.companyList = []
        break

      case getType(actions.getCompanyVandorListAction.success):
        draft.vandorEvent.IsLoading = false
        draft.companyList = action.payload.ResponseData
        break

      case getType(actions.getCompanyVandorListAction.failure):
        draft.vandorEvent.IsLoading = false
        draft.vandorEvent.SubmitError = parseErrorMessage(action.payload)
        break

      case getType(actions.resetEvent):
        draft.event = cloneDeep(initEvent)
        break

      case getType(actions.updateFilter):
        draft.filter = { ...action.payload }
        break
      case getType(actions.clearFilter):
        draft.filter = cloneDeep(initFilter)
        break

      default:
        return state
    }
  })
}
