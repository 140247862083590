import React from 'react'
import { BreadcrumbLink } from 'Components/Shared/BreadCrumb/BreadCrumb'
import SubnavBar from 'Components/Shared/SubnavBar'

type Props = {
  activity: any
  name?: any
  onClose?: () => void
}

const Breadcrumbs: React.ComponentType<Props> = props => {
  const { activity, name, onClose = () => {} } = props
  const breadcrumbs: BreadcrumbLink[] = [
    {
      url: '/primavera',
      title: 'Home',
      icon: '/home.png',
      active: true,
      showTitle: false,
    },
    {
      url: '/primavera',
      title: 'รายการ Primavera',
      active: true,
      showTitle: true,
    },
  ]
  if (activity) {
    breadcrumbs.push({
      url: `/primavera/${activity.ActivityID}/${activity.ActivityStatus}`,
      title: activity.ActivityID,
      active: name ? true : false,
      showTitle: name ? true : false,
      onClick: onClose,
    })
  }

  if (name) {
    breadcrumbs.push({
      url: `/primavera/${activity.ActivityID}/${activity.ActivityStatus}`,
      title: name,
      active: false,
      showTitle: false,
    })
  }

  return <SubnavBar breadcrumbs={breadcrumbs} hideSearch={true} />
}

export default Breadcrumbs
