import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import {
  CheckListDetail,
  WorkType,
} from 'features/checklist/constructPlan/types'

import ContentBox from 'Components/Shared/Card/ContentBox'
import { Row } from 'antd'
import { AddList, EditDeleteButton } from 'Components/Shared/Button'
import DeleteCheckListJobModal, {
  DeleteWithCheckHandles,
} from 'Components/Shared/Modal/DeleteWithCheckModal'
import { textToLowerCase } from 'utils/format'
import { RowSelectedItem } from 'Components/AdminQC/Components'

interface Props {
  item?: CheckListDetail
  selected: number
  onSelected: (index: number) => void
  showModal: (tab: number) => void
  onDelete: (it: WorkType) => void
  openModalEdit: (obj: WorkType, index: number, tab: number) => void
  disabled: boolean
}

const TypeBox: React.ComponentType<Props> = ({
  item,
  selected,
  onSelected,
  showModal,
  onDelete,
  openModalEdit,
  disabled,
}) => {
  const modalRef = useRef<DeleteWithCheckHandles>(null)
  let content: JSX.Element[] = []

  const showDeleteModal = (it: WorkType) => {
    if (modalRef.current) {
      const menu = [`รายการภายใต้ "${it.WorkTypeName}" จะถูกลบ`]
      const checks = [false]
      const menuTitle = `ประเภทงาน: ${it.WorkTypeName}`
      modalRef.current.showModal(it, menu, menuTitle, checks)
    }
  }

  const showEditeModal = (it: WorkType) => {
    if (item) {
      const resultIndex = item.WorkTypeList.findIndex(
        v => v.WorkTypeID === it.WorkTypeID
      )
      openModalEdit(it, resultIndex, 0)
    }
  }

  if (item) {
    content = item.WorkTypeList.map((it: WorkType, index: number) => {
      return (
        <RowSelectedItem
          key={index}
          title={it.WorkTypeName}
          right={
            <EditDeleteButton
              showEditeModal={() => showEditeModal(it)}
              showDeleteModal={() => showDeleteModal(it)}
              disableDelete={disabled}
            />
          }
          onClick={() => onSelected(index)}
          isSelected={index === selected}
        />
      )
    })
  }

  return (
    <>
      <ContentBox title="ประเภทงาน" isEmpty={false}>
        <ListSection>
          {content}
          {!disabled && <AddList onClick={() => showModal(0)} />}
        </ListSection>
      </ContentBox>
      <DeleteCheckListJobModal ref={modalRef} onOk={onDelete} />
    </>
  )
}

const ListSection = styled.div`
  margin-top: 16px;
  height: 256px;
  overflow: auto;
  padding: 0 8px;
  padding-bottom: 30px;
`

export default TypeBox
