import { ActivityState, ActivityAction } from './types'
import moment from 'moment'
import produce from 'immer'
import parseErrorMessage from 'utils/parseErrorMessage'
import { Event } from '../../types/event'
import { getType } from 'typesafe-actions'
import * as actions from './actions'
import { cloneDeep } from 'lodash'

const initEvent = {
  IsLoading: false,
  MessageSuccess: undefined,
  SubmitError: undefined,
  SubmitSuccess: false,
  SubmitType: '',
} as Event

const initialState: ActivityState = {
  data: {
    ActivityList: [],
    CanSendUrgentRequest: 'N',
    CheckNumber: 0,
    ModelID: 0,
    ModelName: '',
    QCTypeID: '',
    QCTypeName: '',
    QCTypeVersion: '',
    RequestID: 0,
    State: '',
    Status: '',
    Unit: '',
    Zone: '',
  },
  event: cloneDeep(initEvent),
}

export default function (
  state: ActivityState = initialState,
  action: ActivityAction
): ActivityState {
  return produce(state, (draft) => {
    let list
    let payload
    switch (action.type) {
      case getType(actions.getActivityOnUnitAction.request):
        draft.event = cloneDeep(initEvent)
        draft.event.IsLoading = true
        draft.data = state.data
        break
      case getType(actions.getActivityOnUnitAction.success):
        const res = action.payload.ResponseData
        draft.event.IsLoading = false
        draft.data = res
        break
      case getType(actions.getActivityOnUnitAction.failure):
        draft.event.IsLoading = false
        draft.event.SubmitError = parseErrorMessage(action.payload)
        break
      case getType(actions.StartCheckingByCMAction.request):
      case getType(actions.SendRequestToQcAction.request):
      case getType(actions.SendUrgentForQCRequestAction.request):
      case getType(actions.SendUrgentForCMRequestAction.request):
      case getType(actions.SendRequestToMCAction.request):
        draft.event = cloneDeep(initEvent)
        draft.event.IsLoading = true
        break
      case getType(actions.StartCheckingByCMAction.success):
      case getType(actions.SendRequestToQcAction.success):
      case getType(actions.SendUrgentForQCRequestAction.success):
      case getType(actions.SendUrgentForCMRequestAction.success):
      case getType(actions.SendRequestToMCAction.success):
        draft.event.IsLoading = false
        draft.event.SubmitError = undefined
        draft.event.SubmitSuccess = true
        draft.event.MessageSuccess = 'ทำรายการสำเร็จ'
        break
      case getType(actions.StartCheckingByCMAction.failure):
      case getType(actions.SendRequestToQcAction.failure):
      case getType(actions.SendUrgentForQCRequestAction.failure):
      case getType(actions.SendUrgentForCMRequestAction.failure):
      case getType(actions.SendRequestToMCAction.failure):
        draft.event.IsLoading = false
        draft.event.SubmitError = parseErrorMessage(action.payload)
        break
      case getType(actions.resetEvent):
        draft.event = cloneDeep(initEvent)
        break
      default:
        return state
    }
  })
}
