import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Row, Col, Button } from 'antd'
import ContentBox from 'Components/Shared/Card/ContentBox'

import { DetailType } from 'features/checklist/unit/types'
import { RadioBox } from 'Components/Shared/Form'

type Props = {
  item?: DetailType
  onSave: () => void
  disabled: boolean
}

type PropsItem = {
  title: string
  isCheck: boolean
  onChange: (value: boolean) => void
  disabled: boolean
}

const ConfigItemComponent = ({
  title,
  isCheck,
  onChange,
  disabled,
}: PropsItem) => {
  return (
    <ConfigItem>
      <Row>
        <Col span={14}> {title} </Col>
        <Col span={5}>
          <RadioBox
            label={'ไม่'}
            value={!isCheck}
            isCheck={!isCheck}
            onClick={onChange}
            readOnly={disabled}
          />
        </Col>

        <Col span={5}>
          <RadioBox
            label={'ใช่'}
            value={!isCheck}
            isCheck={isCheck}
            onClick={onChange}
            readOnly={disabled}
          />
        </Col>
      </Row>
    </ConfigItem>
  )
}

const DetailConfigBox: React.ComponentType<Props> = ({
  item,
  onSave,
  disabled,
}) => {
  const [isDefect, setisDefect] = useState<boolean>(false)
  const [isSupplier, setisSupplier] = useState<boolean>(false)

  useEffect(() => {
    if (item) {
      setisDefect(item.IsRequired)
      setisSupplier(item.IsSupplier)
    }
  }, [item])

  const updateDefect = (value: boolean) => {
    setisDefect(value)
    if (item) {
      item.IsRequired = value
    }
  }

  const updateSupplier = (value: boolean) => {
    setisSupplier(value)
    if (item) {
      item.IsSupplier = value
    }
  }

  return (
    <>
      <ContentBox
        title="ตั้งค่ารายละเอียด"
        isEmpty={item == null}
        emptyText="กรุณาเลือก “รายละเอียด”"
        hasSearch={false}
      >
        <ListSection>
          <ConfigItemComponent
            title="เป็น Defect พิเศษหรือไม่"
            isCheck={isDefect}
            onChange={updateDefect}
            disabled={disabled}
          />
          <ConfigItemComponent
            title="เป็น Supplier หรือไม่"
            isCheck={isSupplier}
            onChange={updateSupplier}
            disabled={disabled}
          />
          {!disabled && (
            <ButtonSection>
              <Button onClick={onSave}>บันทึก</Button>
            </ButtonSection>
          )}
        </ListSection>
      </ContentBox>
    </>
  )
}

const ListSection = styled.div`
  margin-top: 16px;
  height: 256px;
  overflow: auto;
  padding: 0 24px;
`

const ButtonSection = styled.div`
  margin-top: 110px;
  button {
    width: 160px;
    height: 50px;
    font-family: 'DB Helvethaica X 75 Bd';
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #003168;
    border: 1.5px solid #003168;
    border-radius: 5px;
  }
`

const ConfigItem = styled.div`
  font-family: 'DB Helvethaica X 55 Regular';
  font-size: 20px;
  padding-bottom: 16px;
`

export default DetailConfigBox
