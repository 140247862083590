import { cloneDeep } from 'lodash'
import { toArray, getInt, getText, isDuplicate } from './common'

import {
  CheckListDetail,
  DocumentDetail,
} from 'features/checklist/master/types'
import {
  InspectionList,
  InspectionData,
  InspectionSubData,
} from 'features/types/checklist'

import { original, originalDoc } from 'features/checklist/master/reducer'

export const toCheckList = (data: string): any => {
  let checklist: CheckListDetail = cloneDeep(original)
  let doc: DocumentDetail = cloneDeep(originalDoc)

  let mode = ''
  const rows = toArray(data)
  rows.forEach((row: any, index: number) => {
    const cmd = getText(row, 0).toLowerCase()
    switch (cmd) {
      case 'checklist name':
        mode = 'name'
        checklist.CheckListName = getText(row, 1)
        break

      case 'description':
        mode = 'description'
        addDetail(checklist, row, index)
        break

      case 'warning':
        mode = 'warning'
        addWarning(checklist, row)
        break

      case 'doument':
        mode = ''
        doc.QCTypeID = getText(row, 1)
        doc.Score = getInt(row, 2)
        doc.IsRequireQC = getText(row, 3) === 'yes'
        break

      case 'doclist':
        mode = 'doclist'
        addDocItem(doc, row)
        break

      case 'note':
        mode = ''
        break

      case 'templatecode':
        mode = 'templatecode'
        checklist.TemplateCode = getText(row, 1)
        break

      case 'procumenttaskid':
        mode = 'procumenttaskid'
        checklist.ProcurementTaskID = getText(row, 1)
        break

      case 'end':
        return checklist

      default:
        if (mode === 'description') addDetail(checklist, row, index)
        else if (mode === 'warning') addWarning(checklist, row)
        else if (mode === 'doclist') addDocItem(doc, row)
        else mode = ''
        break
    }
  })

  return { Checklist: checklist, Document: doc }
}

const addDetail = (
  checklist: CheckListDetail,
  row: string[],
  index: number
) => {
  const cate = getText(row, 1)
  const title = getText(row, 2)
  const subTitle = getText(row, 3)

  const len = cate.length + title.length + subTitle.length
  if (len === 0) return

  let mainItem: InspectionList = {
    InspectionCategory: cate,
    Seq: '',
    Level: 1,
    Data: [],
    isHide: false,
  }

  if (cate !== '') {
    checklist.InspectionList.push(mainItem)
  }
  const mainIndex = checklist.InspectionList.length - 1
  mainItem = checklist.InspectionList[mainIndex]

  let titleItem: InspectionData = {
    Name: title,
    Status: '',
    Remark: '',
    Seq: '',
    Level: 2,
    Data: [],
  }
  if (title !== '') {
    isDuplicate('Name', title, mainItem.Data, index)
    mainItem.Data.push(titleItem)
  }

  if (subTitle.length > 0) {
    const titleIndex = mainItem.Data.length - 1
    titleItem = mainItem.Data[titleIndex]

    const subTitleItem: InspectionSubData = {
      Name: subTitle,
      Status: '',
      Remark: '',
      Seq: '',
      Level: 3,
    }

    isDuplicate('Name', subTitle, titleItem.Data, index)
    titleItem.Data.push(subTitleItem)
  }
}

const addWarning = (checklist: CheckListDetail, row: string[]) => {
  const text = getText(row, 1)
  if (text !== '') checklist.WarningList.push(text)
}

const addDocItem = (doc: DocumentDetail, row: string[]) => {
  const name: string = getText(row, 1)
  const score: number = getInt(row, 2)
  if (name !== '') {
    doc.CheckList.push({
      Name: name,
      Score: score,
    })
  }
}
