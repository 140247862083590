import React, { useState, useEffect } from 'react'
import { Row, Col } from 'antd'
import styled from 'styled-components'
import { RadioBox } from 'Components/Shared/Form'
import InputSearch from 'Components/Shared/Input/InputSearch'
import { CheckedIcon, LoadingIcon } from 'Components/Shared/Icon'
import {
  GetFilterListReq,
  DataItem,
  UserVandorItem,
  SansiriItem,
} from 'features/admin/user/types'
import { getVandorList, getSansiriList } from 'features/admin/user/api'
import {
  serviceTokenSelector,
  userEmailSelector,
} from 'features/sensitive/sensitive.selector'
import {
  selectVandorList,
  selectVendorEvent,
  selectSansiriList,
  selectSansiriEvent,
} from 'features/admin/user/selector'
import RootState from 'typings/RootState'
import { useDispatch, connect } from 'react-redux'

type Props = {
  user: DataItem
  onUpdate: (user: DataItem) => void
} & ReduxProps

const UserBox: React.FunctionComponent<Props> = props => {
  const {
    UserEmail,
    token,
    user,
    onUpdate,
    vendorDatas,
    sansiriDatas,
    vendorEvent,
    sansiriEvent,
  } = props
  const [selectUser, setSelectUser] = useState({} as any)
  const [searchText, setSearchText] = useState('')
  const [query, setQuery] = useState('')
  let isSansiriUser = !user.IsVendor

  const dispatch = useDispatch()

  useEffect(() => {
    if (user.IsVendor) {
      onFindVendorList()
    } else {
      onFindSansiriList()
    }
  }, [searchText, user.IsVendor])

  useEffect(() => {
    const timeOutId = setTimeout(() => setSearchText(query), 1000)
    return () => clearTimeout(timeOutId)
  }, [query])

  const onFindVendorList = () => {
    const req: GetFilterListReq = {
      UserEmail,
      TextFilter: searchText,
    }
    dispatch(getVandorList(token, req))
  }

  const onFindSansiriList = () => {
    const req: GetFilterListReq = {
      UserEmail,
      TextFilter: searchText,
    }
    dispatch(getSansiriList(token, req))
  }

  const users = [
    {
      name: 'Sansiri',
      value: false,
    },
    {
      name: 'Vendor',
      value: true,
    },
  ]

  const onChangeUserType = (val: boolean) => {
    setSearchText('')
    setSelectUser({})
    user.IsVendor = val
    user.UserID = ''
    user.Username = ''
    onUpdate(user)
  }

  const onSelectUser = (item: any) => {
    console.log('item', item)
    setSelectUser(item)
    user.UserID = item.UserID
    user.Username = item.Username || item.UserEmail
    user.VendorCode = item.VendorCode || ''
    onUpdate(user)
  }

  const onClearUser = () => {
    setSelectUser({})
    setSearchText('')
    user.UserID = ''
    user.Username = ''
    user.VendorCode = ''
    onUpdate(user)
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (user.IsVendor) {
        onFindVendorList()
      } else {
        onFindSansiriList()
      }
    }
  }

  const renderUserType = (
    <Row>
      {users.map((item, index) => {
        return (
          <Col span={8} key={index}>
            <RadioBox
              label={item.name}
              value={item.value}
              isCheck={item.value === user.IsVendor}
              onClick={value => onChangeUserType(value)}
              spaceBtwText={7}
            />
          </Col>
        )
      })}
    </Row>
  )

  const renderDataItem = (header: string, value: string) => {
    return (
      <Row>
        <Col span={10}>{header}</Col>
        <Col span={14}>{value || '-'}</Col>
      </Row>
    )
  }

  const renderUserInfo = (
    <UserInfoArea>
      {isSansiriUser ? (
        <>
          {renderDataItem('ชื่อภาษาไทย', selectUser.UserNameTH)}
          {renderDataItem('ชื่อภาษาอังกฤษ', selectUser.UserNameEN)}
          {renderDataItem('อีเมล', selectUser.UserEmail)}
        </>
      ) : (
        <>
          {renderDataItem('ชื่อ', selectUser.UserNameTH)}
          {renderDataItem('บริษัท', selectUser.CompanyNameTH)}
          {renderDataItem('Vendor code', selectUser.VendorCode)}
          {renderDataItem('Username', selectUser.Username)}
        </>
      )}
    </UserInfoArea>
  )

  const renderSearchContent = (data: any[]) => {
    return data && data.length > 0 ? (
      <SearchContentArea>
        {data.map((item, index) => {
          const data = renderUserDataFormat(item)
          return (
            <SearchItem key={index} onClick={() => onSelectUser(item)}>
              {data}
            </SearchItem>
          )
        })}
      </SearchContentArea>
    ) : (
      <ContentBody>
        <Txt>ไม่มีข้อมูล</Txt>
      </ContentBody>
    )
  }

  const renderUserDataFormat = (item: any) => {
    let content
    if (isSansiriUser) {
      content = `${item.UserNameTH} - ${item.UserEmail}`
    } else {
      content = `${item.Username} - ${item.CompanyNameTH} - ${item.VendorCode}`
    }
    return content
  }

  const isObjectEmpty = (value: any) => {
    return Object.entries(value).length === 0
  }

  const userSelectedBox = (
    <UserSelected>
      <Row type="flex" justify="space-between" align="middle">
        <Col className="ellipsis" span={21}>
          <Icon>
            <CheckedIcon />
          </Icon>
          <Text>{renderUserDataFormat(selectUser)}</Text>
        </Col>
        <Col span={3}>
          <TextCancle onClick={onClearUser}>ยกเลิก</TextCancle>
        </Col>
      </Row>
    </UserSelected>
  )

  const loading = (
    <ContentBody>
      <LoadingIcon />
    </ContentBody>
  )

  const content = isSansiriUser
    ? renderSearchContent(sansiriDatas)
    : renderSearchContent(vendorDatas)

  const searchBox = (
    <InputSearch
      placeholder={
        isSansiriUser
          ? 'พิมพ์ชื่อหรือ อีเมลผู่ใช้'
          : 'พิมพ์ชื่อหรือ Vendor code'
      }
      value={query}
      onChange={(val: string) => setQuery(val)}
      showSuggess={true}
      searchContent={
        vendorEvent.IsLoading || sansiriEvent.IsLoading ? loading : content
      }
      onKeyDown={handleKeyDown}
    />
  )

  const renderSearch = (
    <SearchArea>
      {isObjectEmpty(selectUser) ? searchBox : userSelectedBox}
    </SearchArea>
  )

  return (
    <>
      {renderUserType}
      {renderSearch}
      {renderUserInfo}
    </>
  )
}

const SearchArea = styled.div`
  margin-top: 22px;
`
const UserInfoArea = styled.div`
  margin-top: 12px;
  padding: 24px 24px 14px 24px;
  border-radius: 5px;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.03);
  background-color: #fff;
  font-family: 'DB Helvethaica X 55 Regular';
  font-size: 20px;
`

const UserInfoText = styled.div`
  padding-bottom: 10px;
`

const EmptyText = styled.span`
  color: #8b99a7;
`

const DropdownArea = styled.div`
  margin-top: 24px;
`

const SearchContentArea = styled.div`
  max-height: 192px;
  overflow: scroll;
`

const SearchItem = styled.div`
  padding: 8px 24px;
  font-size: 20px;
  font-family: 'DB Helvethaica X 55 Regular';
  &:hover {
    background-color: #e6f7ff;
    cursor: pointer;
  }
`

const UserSelected = styled.div`
  height: 48px;
  border-radius: 5px;
  border: solid 1px #eeeff2;
  background-color: #fff;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.03);
  padding: 0 16px;
  .ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

const Text = styled.span`
  font-size: 20px;
  font-family: 'DB Helvethaica X 55 Regular';
`

const TextCancle = styled.span`
  font-size: 20px;
  font-family: 'DB Helvethaica X 55 Regular';
  color: #d0494c;
  cursor: pointer;
`

const Icon = styled.div`
  margin-right: 16px;
  float: left;
`

const ContentBody = styled.div`
  width: 100%;
  text-align: center;
  padding: 70px 0;
  height: 192px;
`

const Txt = styled.div`
  font-size: 20px;
  font-family: 'DB Helvethaica X 55 Regular';
`

const mapStateToProps = (state: RootState) => {
  return {
    token: serviceTokenSelector(state),
    UserEmail: userEmailSelector(state),
    vendorDatas: selectVandorList(state),
    vendorEvent: selectVendorEvent(state),
    sansiriEvent: selectSansiriEvent(state),
    sansiriDatas: selectSansiriList(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(UserBox)
