import withAPIAction from '../../utils/withAPIAction'
import { verifyReq, verifyReqNoProject } from 'utils/verify'

import { ListReq, MasterReqByPrimavera, GetUnitListReq } from './types'
import {
  getModelTypeList,
  getUnitListAction,
  getPrimaveraActivityList,
  getProjectTypeList,
  getQcWorkAction,
} from './actions'

export const getModelTypeListApi = (data: ListReq, token: string) => {
  const isValid = verifyReqNoProject(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<ListReq>({
    method: 'POST',
    path: 'GetModelTypeList',
    data,
    token: `Bearer ${token}`,
  })(getModelTypeList)
}

export const getUnitList = (data: GetUnitListReq, token: string) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<GetUnitListReq>({
    method: 'POST',
    path: 'GetUnitList',
    data,
    token: `Bearer ${token}`,
  })(getUnitListAction)
}

export const getPrimaveraList = (data: any, token: string) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<MasterReqByPrimavera>({
    method: 'POST',
    path: 'GetPrimaveraActivityList',
    data,
    token: `Bearer ${token}`,
  })(getPrimaveraActivityList)
}

export const getProjectType = (data: ListReq) => {
  return withAPIAction<ListReq>(state => ({
    method: 'POST',
    path: 'GetProjectTypeList',
    data: {
      ...data,
      UserEmail: state.sensitive.userEmail,
    },
    token: `Bearer ${state.sensitive.apiToken}`,
  }))(getProjectTypeList)
}

export const getQcWork = () => {
  return withAPIAction<any>(state => ({
    method: 'POST',
    path: 'GetQCWorkList',
    data: {
      UserEmail: state.sensitive.userEmail,
    },
    token: `Bearer ${state.sensitive.apiToken}`,
  }))(getQcWorkAction)
}
