import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { connect, useDispatch } from 'react-redux'
import { Layout } from 'antd'
import { RouteComponentProps } from 'react-router-dom'
import RootState from 'typings/RootState'
import { selectedProjectCodeSelector } from 'features/project/project.selector'
import {
  userEmailSelector,
  serviceTokenSelector,
} from 'features/sensitive/sensitive.selector'
import { StatusOfTask, StateOfTask } from 'features/types/status'
import {
  FetchActivityOnZoneReq,
  StartCheckingZoneReq,
  SendZoneToQcReq,
} from 'features/cmpj/zone/report/types'
import {
  getActivityOnZoneById,
  startCheckingZone,
  sendZoneToQcChecking,
} from 'features/cmpj/zone/report/api'
import { selectData, selectEvent } from 'features/cmpj/zone/report/selector'
import { ActivityList, ZoneInfo } from 'Components/CM/CmChecking'
import { QCByUserFooter } from 'Components/Activity'
import { EventModal } from 'Components/Shared'
import Breadcrumbs from './Breadcrumbs'

type Props = ReduxProps &
  DispatchProps &
  RouteComponentProps<{
    unit: string
    qcType: string
    requestID: string
    floor: string
  }>

const RequestDetail: React.ComponentType<Props> = props => {
  const [enableModal, setEnableModal] = useState(false)
  const { match, data, event, token, ProjectCode, UserEmail, history } = props
  const { ActivityList: activityList } = data
  const { params } = match
  const { unit, qcType, requestID, floor } = params
  const { Content, Footer } = Layout

  useEffect(() => {
    setEnableModal(false)
  }, [data])

  const gotoDashboard = () => {
    history.push('/zone/dashboard')
  }

  const RequestID = parseInt(requestID)

  const dispatch = useDispatch()
  useEffect(() => {
    const params: FetchActivityOnZoneReq = {
      UserEmail,
      ProjectCode,
      QCTypeID: qcType,
      Unit: unit,
      RequestID,
      Floor: floor,
    }
    dispatch(getActivityOnZoneById(params, token))
  }, [dispatch, UserEmail, ProjectCode, token, unit, qcType])

  const onStartCheckingByCM = () => {
    setEnableModal(true)
    const request: StartCheckingZoneReq = {
      ProjectCode,
      UserEmail,
      ItemList: [
        {
          Unit: data.Unit,
          QCTypeID: data.QCTypeID,
          RequestID,
          Floor: data.Floor,
          FloorPlanID: data.FloorPlanID,
        },
      ],
    }
    dispatch(startCheckingZone(token, request))
  }

  const onSendRequestToQc = () => {
    setEnableModal(true)
    const isCMChecked =
      data.State === 'CM-QC' && data.Status === 'INPRO' ? 'Y' : 'N'
    const request: SendZoneToQcReq = {
      ProjectCode,
      UserEmail,
      ItemList: [
        {
          Unit: data.Unit,
          QCTypeID: data.QCTypeID,
          RequestID,
          IsCMChecked: isCMChecked,
          Floor: data.Floor,
        },
      ],
    }
    dispatch(sendZoneToQcChecking(token, request))
  }

  const countSuccessActivity = () => {
    const successActivity =
      activityList &&
      activityList.filter(
        activity =>
          activity.Progress === 100 &&
          activity.State === StateOfTask.PJPO &&
          activity.TaskStatus === StatusOfTask.APPV
      )
    return successActivity ? successActivity.length : 0
  }

  const successActivity = countSuccessActivity()
  const isViewMode = data.State !== 'CM-QC' || data.Status !== 'INIT'

  const footer = (
    <Footer className="detail-page-footer">
      <QCByUserFooter
        onClickSendRequest={() => onSendRequestToQc()}
        onClickStartChecking={() => onStartCheckingByCM()}
        isLoading={event.IsLoading || successActivity < 1}
        selfCheckTxt="เริ่มตรวจส่วนกลางด้วยตัวเอง"
      />
    </Footer>
  )

  const title = !isViewMode ? 'พร้อมตรวจด้วยตัวเอง' : 'QC กำลังดำเนินการ'
  const zoneName = data.Unit

  return (
    <>
      <EventModal
        event={[event]}
        enable={enableModal}
        successCalback={gotoDashboard}
      />
      <Breadcrumbs title={title} name={zoneName} />
      <Content className="mid-content">
        <div className="main-content activity-detail">
          <TextHeader>{zoneName}</TextHeader>
          <ZoneInfo data={data} />
          <ActivityList
            activityList={activityList || []}
            successActivity={successActivity}
            title="รายการทำงาน"
            isUnit={false}
          />
        </div>
      </Content>
      {!isViewMode && footer}
    </>
  )
}

const TextHeader = styled.div`
  font-size: 28px;
  font-family: 'DB Helvethaica X 75 Bd';
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 20px;
`

const mapStateToProps = (state: RootState) => {
  return {
    data: selectData(state),
    event: selectEvent(state),
    token: serviceTokenSelector(state),
    ProjectCode: selectedProjectCodeSelector(state),
    UserEmail: userEmailSelector(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RequestDetail)
