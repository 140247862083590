import React, { useEffect } from 'react'
import styled from 'styled-components'
import { connect, useDispatch } from 'react-redux'
import SubnavBar from 'Components/Shared/SubnavBar'
import { Row, Col } from 'antd'
import { RouteComponentProps } from 'react-router-dom'
import RootState from 'typings/RootState'
import { BreadcrumbLink } from 'Components/Shared/BreadCrumb/BreadCrumb'
import { selectedProjectCodeSelector } from 'features/project/project.selector'
import {
  userEmailSelector,
  serviceTokenSelector,
} from 'features/sensitive/sensitive.selector'
import {
  selectData,
  selectEvent,
  selectSorting,
  selectFilter,
} from 'features/cmpj/zone/dashboard/selector'
import {
  sortingDashboard,
  clearFilter,
} from 'features/cmpj/zone/dashboard/actions'
import { getZoneDashboard } from 'features/cmpj/zone/dashboard/api'
import {
  GetZoneDashboardReq,
  TypeOfTask,
} from 'features/cmpj/zone/dashboard/types'
import { translate } from 'i18n'
import TKeys from 'i18n/translationKey'

import LinkButton from 'Components/Shared/LinkButton'
import NoCard from 'Components/Shared/NoCard'
import Loading from 'Components/Shared/Loading'
import CommonAreaTab from 'Components/Shared/Tab/CommonAreaTab'
import { PrimaryButton } from 'Components/Shared/Button'
import { CMZoneFilter } from 'Components/Filter'

import Menu from '../../Menu'
import ColumnHeader from './ColumnHeader'
import Card from './Card'
import { getEvent } from 'features/wbs/wbs.selector'
import { LoadingView } from 'Components/Shared'

const breadcrumbs: BreadcrumbLink[] = [
  {
    url: '/',
    title: 'Home',
    icon: '/home.png',
    active: true,
    showTitle: false,
  },
  {
    url: '/',
    title: translate(TKeys.Breadcrumb.dashboard),
    active: false,
    showTitle: false,
  },
]

type Props = ReduxProps & DispatchProps & RouteComponentProps

const ZoneDashboard: React.ComponentType<Props> = props => {
  const {
    data,
    event,
    sorting,
    token,
    ProjectCode,
    UserEmail,
    sortingTask,
    filter,
    wbsEvent,
  } = props

  const dispatch = useDispatch()
  useEffect(() => {
    onGetData()
  }, [ProjectCode])

  const onGetData = () => {
    const data: GetZoneDashboardReq = {
      ProjectCode,
      UserEmail,
      FloorList: filter.FloorList,
      UnitList: filter.ZoneList,
      WbsList: filter.LeafWbsList,
    }

    dispatch(getZoneDashboard(token, data))
  }

  useEffect(() => {
    return () => {
      dispatch(clearFilter())
    }
  }, [])

  const onSearch = () => {
    onGetData()
  }

  const loading = (
    <div style={{ textAlign: 'center' }}>
      <Loading />
    </div>
  )

  const renderCard = (task: any[], type: string) => {
    let card = task.map((activity, index) => (
      <Card key={index} task={activity} type={type} />
    ))

    if (type === TypeOfTask.TODO && data.TotalToDo >= 10) {
      card.push(
        <LinkButton href={`/zone/seemore/${type}`} title={'ดูเพิ่มเติม'} />
      )
    }
    return card
  }

  const renderTaskLen = (
    task: any[],
    header: string,
    orderName: string,
    type: string,
    total: number
  ) => {
    const card =
      task && task.length > 0 ? (
        renderCard(task, type)
      ) : (
        <NoCard text="ไม่มีรายการ" />
      )
    const content = event.IsLoading ? loading : card
    return (
      <Col span={6}>
        <FullColumn>
          <ColumnHeader
            heading={`${header} (${total})`}
            orderName={orderName}
            type={sorting[type]}
            onSort={() => {
              sortingTask(type)
            }}
          />
          {content}
        </FullColumn>
      </Col>
    )
  }

  const gotoDefectList = () => {
    props.history.push('/zone/defect-list')
  }

  const header = (
    <Header>
      <Row type="flex" justify="space-between" align="middle">
        <Col>
          <CMZoneFilter onSearch={onSearch} withPadding={false} />
        </Col>
        <Col>
          <Btn>
            <PrimaryButton text="จัดกลุ่ม Defect" onClick={gotoDefectList} />
          </Btn>
        </Col>
      </Row>
    </Header>
  )

  return (
    <>
      <Menu selected="commonArea" />
      <SubnavBar breadcrumbs={breadcrumbs} hideSearch={true} />
      <CommonAreaTab selected="Zone" />
      <LoadingView isShow={event.IsLoading || wbsEvent.IsLoading} />
      {header}
      <MainPage>
        <Row type="flex">
          {renderTaskLen(
            data.ToDo,
            'เตรียมพร้อมตรวจด้วยตัวเอง',
            'จำนวนงาน',
            TypeOfTask.TODO,
            data.TotalToDo
          )}
          {renderTaskLen(
            data.InProgress,
            'กำลังดำเนินการ',
            'คะแนน',
            TypeOfTask.InProgress,
            data.TotalInProgress
          )}
          {renderTaskLen(
            data.QCChecking,
            'QC กำลังดำเนินการ',
            'วันที่ส่งคำขอ',
            TypeOfTask.QCChecking,
            data.TotalQCChecking
          )}
          {renderTaskLen(
            data.FixFromQC,
            'รายการแก้ไข',
            'วันที่ส่งคำขอ',
            TypeOfTask.FixFromQC,
            data.TotalFixFromQC
          )}
        </Row>
      </MainPage>
    </>
  )
}

const MainPage = styled.div`
  padding: 0 120px;
  background-color: #f5f6fa;
  width: 100vw;
  -khtml-user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  overflow-x: scroll;
`

const FullColumn = styled.div`
  padding-right: 20px;
`

const Header = styled.div`
  padding: 0 120px;
`

const Btn = styled.div`
  width: 160px;
  margin-bottom: 40px;
`

const mapStateToProps = (state: RootState) => {
  return {
    data: selectData(state),
    event: selectEvent(state),
    sorting: selectSorting(state),
    token: serviceTokenSelector(state),
    ProjectCode: selectedProjectCodeSelector(state),
    UserEmail: userEmailSelector(state),
    filter: selectFilter(state),
    wbsEvent: getEvent(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {
  sortingTask: sortingDashboard,
}

type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(ZoneDashboard)
