import React from 'react'
import { Modal, Button, Row, Col } from 'antd'
import styled from 'styled-components'
import iconError from 'assets/img/ic-error.png'
import { translate } from 'i18n'
import TKeys from 'i18n/translationKey'

const ErrorMessage = (props: any) => {
  const {
    visible = false,
    onClose,
    text = 'Sorry, we got some problem.',
  } = props
  return (
    <Modal
      wrapClassName="message-box"
      style={css.page}
      visible={visible}
      closable={false}
      mask={false}
      onCancel={onClose}
      footer={null}
    >
      <Row>
        <Col span={24}>
          <Icon src={iconError} />
        </Col>
        <Col span={24}>
          <Text className="std-font-regular">{text}</Text>
        </Col>
        <Col span={24}>
          <Button className="std-font-bold" style={css.btn} onClick={onClose}>
            {translate(TKeys.Common.dialogOk)}
          </Button>
        </Col>
      </Row>
    </Modal>
  )
}

const Text = styled.div`
  font-size: 20px;
  line-height: 1.2;
`

const Icon = styled.img`
  width: 60px;
  height: 60px;
  margin-bottom: 16px;
`

const css: any = {
  page: {
    marginTop: '14%',
    maxWidth: '327px',
    maxHeight: '262px',
    textAlign: 'center',
    fontSize: '20px',
    color: 'black',
  },
  btn: {
    width: '100%',
    borderRadius: '5px',
    backgroundColor: '#003168',
    color: 'white',
    marginTop: '32px',
    fontSize: '20px',
  },
}

export default ErrorMessage
