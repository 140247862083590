import { createStandardAction, createAsyncAction } from 'typesafe-actions'
import { Moment } from 'moment-timezone'
import {
  CurrentActivity,
  FetchActivityRequest,
  GetActivityApi,
  GetCheckListApi,
  UpdateActivityApi,
  SendActivityReq,
} from './types'
import { OriginalActivity } from 'features/activity/activity.types'

export const setCurrentActivity = createStandardAction(
  'maincon/activity/SET_CURRENT_ACTIVITY'
)<CurrentActivity>()

export const getActivityDetailRequest = createAsyncAction(
  'maincon/activity/GET_ACTIVITY_REQUEST',
  'maincon/activity/GET_ACTIVITY_RESPONSE',
  'maincon/activity/GET_ACTIVITY_FAILURE'
)<FetchActivityRequest, GetActivityApi, any>()

export const getCheckListRequest = createAsyncAction(
  'maincon/activity/GET_CHECK_LIST_REQUEST',
  'maincon/activity/GET_CHECK_LIST_RESPONSE',
  'maincon/activity/GET_CHECK_LIST_FAILURE'
)<FetchActivityRequest, GetCheckListApi, any>()

export const acceptActivity = createAsyncAction(
  'maincon/activity/ACCEPT_ACTIVITY_REQUEST',
  'maincon/activity/ACCEPT_ACTIVITY_RESPONSE',
  'maincon/activity/ACCEPT_ACTIVITY_FAILURE'
)<FetchActivityRequest, UpdateActivityApi, any>()

export const submitReqActivity = createStandardAction(
  'maincon/activity/SUBMIT_REQUEST_ACTIVITY'
)<any>()

export const sendReqToCMAsyncReq = createAsyncAction(
  'maincon/activity/SEND_TO_CM_REQUEST',
  'maincon/activity/SEND_TO_CM_RESPONSE',
  'maincon/activity/SEND_TO_CM_FAILURE'
)<SendActivityReq, UpdateActivityApi, any>()
