import { ActionType } from 'typesafe-actions'
import { Moment } from 'moment-timezone'
import * as actions from './actions'
import { TaskStatus } from '../../tasklane/tasklane.types'

export type DashboardAction = ActionType<typeof actions>

export interface StoreState {
  data: DashboardData
  originalData: DashboardData
  event: DashboardEvent
  query: DashboardQuery
  sorting: any
  filter: DashboardFilter
  response: GetActivityApiResponse
}

export type Activity = {
  RowID: number
  ActivityID: string
  ActivityName: string
  BaselineStartDate: string
  BaselineFinishDate: string
  StartDate: string
  FinishDate: string
  ActualStartDate: string
  ActualFinishDate: string
  ExpectFinishDate: string
  AppointmentDate: string
  ConstructionTypeName: string
  WbsID: number[]
  Unit: string
  Zone: string
  FloorName: string
  QCTypeID: number
  QCTypeName: string
  Progress: number
  LastCurProgress: number
  LastReqProgress: number
  State: string
  TaskStatus: string
  Reject: Reject
}

export interface Reject {
  Id: number
  RejectReasonID: number
  RejectReason: string
  RejectReasonRemark: string
  Remark: string
  RejectBy: string
  RejectDate: string
}

export interface GetActivityRequest {
  ProjectCode: string
  UserEmail: string
  StartDate: string
  FinishDate: string
  UnitList: string[]
  ZoneList: string[]
  FloorList: string[]
  ConstructionTypeList: string[]
  WbsList: number[]
  SortToDo: {
    SortBy: string
    OrderBy: string
  }
  SortInProgress: {
    SortBy: string
    OrderBy: string
  }
  SortDone: {
    SortBy: string
    OrderBy: string
  }
}

export interface GetActivityApiResponse
  extends GetApiResponse<GetActivityResponse> {}

export interface GetActivityResponse {
  ToDo: Activity[]
  InProgress: Activity[]
  Done: Activity[]
  TotalTodo: number
  TotalProgress: number
  TotalDone: number
}

export interface GetApiResponse<T> {
  ResponseData: T
  ResponseCode: string
  ResponseMsg: 'Success' | 'Error'
  RequestTime: string
  Responsetime: string
}

export type DashboardData = {
  Todo: Activity[]
  InProgress: Activity[]
  Done: Activity[]
  TotalTodo: number
  TotalProgress: number
  TotalDone: number
}

export type DashboardEvent = {
  isFetching: boolean
  error: string
}

export type DashboardFilter = {
  progress: number[]
  status: TaskStatus[]
  wbs: number[]
  date: {
    mode: string
    start: undefined | Moment
    end: undefined | Moment
  }
  floor: number[]
  unit: string[]
  zone: string[]
  type: string[]
}

export type DashboardQuery = {
  date: {
    start: undefined | Moment
    end: undefined | Moment
  }
}

export enum TypeOfTask {
  Todo = 'todo',
  Inprogress = 'in-progress',
  Done = 'done',
}
