import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { connect, useDispatch } from 'react-redux'
import { Layout } from 'antd'
import RootState from 'typings/RootState'

import EventModal from 'Components/Shared/Modal/EventModal'

import { selectEvent } from 'features/report/common/selector'
import {
  submitConstructRoadSignReq,
  resetEvent,
} from 'features/report/common/actions'
import { SignConstructRoadReq } from 'features/report/common/types'

import {
  userEmailSelector,
  serviceTokenSelector,
} from 'features/sensitive/sensitive.selector'

import SignDialog from 'Components/Shared/SignDialog'

import { selectUploadEvent } from 'features/uploadfile/selector'

const { Content } = Layout

type FormProps = {
  project: string
  Unit: string
  QCTypeID: string
  RequestID: number
  CheckNumber: number
  display?: boolean

  onClose: () => void
  onCompleted: () => void
}

type Props = FormProps & ReduxProps

const Signature: React.ComponentType<Props> = props => {
  const {
    project,
    Unit,
    QCTypeID,
    RequestID,
    CheckNumber,
    token,
    UserEmail,
    event,
    uploadEvent,

    display = false,
    onClose,
    onCompleted,
  } = props
  const [enableModal, setEnableModal] = useState(false)

  const dispatch = useDispatch()

  const onSignCompleted = () => {
    setEnableModal(false)
    dispatch(resetEvent())
    onCompleted()
  }

  const onError = () => setEnableModal(false)

  const onSignature = (Username: string, Remark: string, SignImage: any) => {
    setEnableModal(true)

    const req: SignConstructRoadReq = {
      ProjectCode: project,
      UserEmail,
      Unit,
      QCTypeID,
      RequestID,
      CheckNumber,
      Username,
      Remark,
      SignImage,
    }

    const params: any = { token, req }
    dispatch(submitConstructRoadSignReq(params))
  }

  return (
    <>
      <EventModal
        event={[event]}
        enable={enableModal}
        successCalback={onSignCompleted}
        errorCalback={onError}
      />

      <SignDialog display={display} onClose={onClose} onSave={onSignature} />
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    event: selectEvent(state),
    token: serviceTokenSelector(state),
    UserEmail: userEmailSelector(state),
    uploadEvent: selectUploadEvent(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Signature)
