import { createStandardAction, createAsyncAction } from 'typesafe-actions'
import {
  GetActivitySeemoreReq,
  GetActivitySeemoreiRes,
} from './types'

export const GetActivitySeeMoreForQCAction = createAsyncAction(
  'cmpj/zone/seemore/FETCH_QC_REQUEST',
  'cmpj/zone/seemore/FETCH_QC_SUCCESS',
  'cmpj/zone/seemore/FETCH_QC_FAILURE'
)<GetActivitySeemoreReq, GetActivitySeemoreiRes, any>()

export const sortingTask = createStandardAction('cmpj/zone/seemore/SORTING')<{
  name: string
  sort?: string
}>()

export const setPageType = createStandardAction('cmpj/zone/seemore/SETPAGETYPE')<{
  pageType: number
}>()
