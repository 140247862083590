import { getType } from 'typesafe-actions'
import { cloneDeep } from 'lodash'

import * as actions from './actions'
import { StoreState, ReportAction } from './types'
import produce from 'immer'
import parseErrorMessage from 'utils/parseErrorMessage'
import { Event } from '../../types/event'

const initEvent = {
  IsLoading: false,
  MessageSuccess: undefined,
  SubmitError: undefined,
  SubmitSuccess: false,
  SubmitType: undefined,
} as Event

const initialState: StoreState = {
  dataList: {
    List: [],
  },
  event: cloneDeep(initEvent),
}

export default function(
  state: StoreState = initialState,
  action: ReportAction
): StoreState {
  return produce(state, draft => {
    switch (action.type) {
      case getType(actions.getDataListAction.request):
        draft.event = cloneDeep(initEvent)
        draft.event.IsLoading = true
        draft.dataList = {
          List: [],
        }

        break
      case getType(actions.getDataListAction.success):
        draft.event.IsLoading = false
        draft.dataList = action.payload.ResponseData

        break

      case getType(actions.updateDataAction.request):
      case getType(actions.createDataAction.request):
        draft.event = cloneDeep(initEvent)
        draft.event.IsLoading = true
        break

      case getType(actions.updateDataAction.success):
      case getType(actions.createDataAction.success):
        draft.event.IsLoading = false
        draft.event.SubmitError = undefined
        draft.event.SubmitSuccess = true
        draft.event.MessageSuccess = 'ทำรายการสำเร็จ'
        break

      case getType(actions.updateDataAction.failure):
      case getType(actions.getDataListAction.failure):
      case getType(actions.createDataAction.failure):
        draft.event.IsLoading = false
        draft.event.SubmitError = parseErrorMessage(action.payload)
        break

      case getType(actions.resetEvent):
        draft.event = cloneDeep(initEvent)
        break

      default:
        return state
    }
  })
}
