import React from 'react'
import { Col, Row } from 'antd'
import styled from 'styled-components'
import {
  DescItem,
  StatusTag,
  ImageReport,
} from 'Components/AdminQC/Report/Components'
import { DefectItem as Defect } from 'features/report/zone/types'

type Props = {
  index: number
  defect: Defect
}

const DefectItem: React.ComponentType<Props> = props => {
  const { index = 0, defect } = props

  const renderDescItem = (title: string, value: any) => {
    return <DescItem title={title} value={value} percentW={25} />
  }

  const isPass = defect.PinStatus.toLowerCase() === 'pass'
  const defectInfo = (
    <Row type="flex">
      {renderDescItem(
        `หมุดที่ ${defect.PinNumber}`,
        <StatusTag isPass={isPass} text={isPass ? 'ผ่าน' : 'ไม่ผ่าน'} />
      )}
      {renderDescItem(
        'แสนสิริจัดหา',
        defect.IsSansiriFixed ? 'เป็น' : 'ไม่เป็น'
      )}
      {renderDescItem('ประเภท', defect.ZoneType)}
      {renderDescItem('ผู้รับเหมาหลัก', defect.VendorName)}
      {renderDescItem('หมวดงานหลัก', defect.JobTypeName)}
      {renderDescItem('หมวดงานรอง', defect.SubJobTypeName)}
      {renderDescItem('รายละเอียด', defect.DetailName)}
    </Row>
  )

  const showRemark = true
  const imageInfo = (
    <Row>
      <Col span={12}>
        <BeforeImage>
          <Title>Before</Title>
          <ImageReport
            image={defect.ImageBefore}
            remark={defect.ImageBefore.FileURL ? defect.Remark || '-' : ''}
            showRemark={showRemark}
            hasMargin={false}
          />
        </BeforeImage>
      </Col>
      <Col span={12}>
        <AfterImage>
          <Title>After</Title>
          <ImageReport
            image={defect.ImageAfter}
            remark={defect.ImageAfter.FileURL ? defect.FixReason || '-' : ''}
            showRemark={showRemark}
            hasMargin={false}
          />
        </AfterImage>
      </Col>
    </Row>
  )

  const pageBreakBefore =
    index === 0 ? 'avoid' : index % 2 === 0 ? 'avoid' : 'always'
  console.log('pageBreakBefore:', index, pageBreakBefore)
  return (
    <Box style={{ pageBreakBefore }}>
      {defectInfo}
      {imageInfo}
    </Box>
  )
}

const Box = styled.div`
  margin-top: 16px;
  border-radius: 8px;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.03);
  background-color: #fff;
  padding: 24px;

  @media print {
    border: solid 1px rgba(0, 49, 104, 0.05);
    border-radius: 5px;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.03);
  }
`

const BeforeImage = styled.div`
  margin-right: 12px;
  margin-top: 12px;
`

const AfterImage = styled.div`
  margin-left: 12px;
  margin-top: 12px;
`

const Title = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 20px;
  color: #000;
  margin-bottom: 12px;
`

export default DefectItem
