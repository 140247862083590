import React, { useState, useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import RootState from 'typings/RootState'
import { connect, useDispatch } from 'react-redux'
import {
  userEmailSelector,
  serviceTokenSelector,
} from 'features/sensitive/sensitive.selector'
import { EventModal } from 'Components/Shared'
import Menu from 'Components/MenuAdmin/AdminQCMenu'
import Breadcrumbs from './Breadcrumbs'
import {
  getReportDetail,
  saveCertReport,
  cancelCertReport,
} from 'features/adminReport/certificate/api'
import {
  GetReportDetailReq,
  SaveCertReq,
  CancelCertReq,
  User,
} from 'features/adminReport/certificate/types'
import ReportDetail from './ReportDetail'
import {
  selectEvent,
  selectReportDetail,
} from 'features/adminReport/certificate/selector'
import { toDateText } from 'utils/datetime'

type FormProps = {
  id: string
}

type Props = FormProps &
  RouteComponentProps<{
    projectCode: string
    mcvendorcode: string
  }> &
  ReduxProps

const ReportDetailPage = (props: Props) => {
  const [lastPage, setLastPage] = useState('')

  const [enable, setEnable] = useState(false)
  const [isUpdate, setIsUpdate] = useState(false)
  const { Token, UserEmail, match, data, event } = props

  const [sign, setSign] = useState([] as User[])
  const [date, setDate] = useState({
    startDate: '',
    endDate: '',
  })

  const MCVendorCode = match.params.mcvendorcode
  const ProjectCode = match.params.projectCode

  const onPageClose = () => {
    if (lastPage !== '') {
      setLastPage('')
    }
  }

  const dispatch = useDispatch()

  useEffect(() => {
    setEnable(false)
    if (data && data.UserList) {
      let userList = [] as User[]
      for (let index = 1; index <= 5; index++) {
        const user = data.UserList.find(user => user.Seq === index)
        if (user) {
          userList.push(user)
        } else {
          userList.push({
            Seq: index,
            PositionName: '',
            UserName: '',
          })
        }
      }

      setSign([...userList])
    }
    if (data) {
      date.endDate = data.WarrantyEnd || ''
      date.startDate = data.WarrantyStart || ''
    }
  }, [data])

  useEffect(() => {
    onGetActivityDetail()
  }, [UserEmail, ProjectCode, Token, dispatch])

  const onSaveCertReport = () => {
    setEnable(true)
    setIsUpdate(true)
    const data: SaveCertReq = {
      ProjectCode,
      UserEmail,
      MCVendorCode,
      WarrantyStart: toDateText(date.startDate),
      WarrantyEnd: toDateText(date.endDate),
      UserList: clearEmptyData(sign),
    }
    dispatch(saveCertReport(Token, data))
  }

  const onCancelCertReport = () => {
    setEnable(true)
    setIsUpdate(true)
    const data: CancelCertReq = {
      ProjectCode,
      UserEmail,
      MCVendorCode,
    }
    dispatch(cancelCertReport(Token, data))
  }

  const onGetActivityDetail = () => {
    setEnable(true)
    const data: GetReportDetailReq = {
      ProjectCode,
      UserEmail,
      MCVendorCode,
    }
    dispatch(getReportDetail(Token, data))
  }

  const clearEmptyData = (sign: User[]) => {
    return sign.filter(user => user.PositionName !== '' && user.UserName !== '')
  }

  const onUpdateSign = (index: number, data: any) => {
    sign.map(item => {
      if (item.Seq === index) {
        item = data
      }
      return item
    })

    setSign([...sign])
  }

  const onUpdateDate = (date: any) => {
    setDate({ ...date })
  }

  const onSuccess = () => {
    setEnable(false)
    setIsUpdate(false)
    if (isUpdate) {
      onGetActivityDetail()
    }
  }

  return (
    <>
      <EventModal
        name="activity"
        event={[event]}
        enable={enable}
        successCalback={onSuccess}
      />
      <Breadcrumbs activity={data} onClose={onPageClose} />
      <Menu selected={'report.zone'}>
        <ReportDetail
          data={data}
          onCancelCert={onCancelCertReport}
          onUpdateSign={onUpdateSign}
          onSaveCert={onSaveCertReport}
          sign={sign}
          date={date}
          onUpdateDate={onUpdateDate}
        />
      </Menu>
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    UserEmail: userEmailSelector(state),
    Token: serviceTokenSelector(state),
    data: selectReportDetail(state),
    event: selectEvent(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ReportDetailPage)
