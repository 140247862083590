import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { connect, useDispatch } from 'react-redux'
import { Row, Col, Switch } from 'antd'
import { RouteComponentProps } from 'react-router-dom'
import RootState from 'typings/RootState'
import { getConfigColumns, saveConfigColumns } from 'utils/configColumnHelper'
import { BreadcrumbLink } from 'Components/Shared/BreadCrumb/BreadCrumb'
import SubnavBar from 'Components/Shared/SubnavBar'
import Pagination from 'Components/Shared/Pagination'
import exportFromJSON from 'export-from-json'
import { Button } from 'antd'

import {
  userEmailSelector,
  serviceTokenSelector,
} from 'features/sensitive/sensitive.selector'
import { selectedProjectCodeSelector } from 'features/project/project.selector'
import { getDataList, updateData } from 'features/adminpj/primavera/api'
import {
  selectDataList,
  selectEvent,
} from 'features/adminpj/primavera/selector'
import {
  GetDataListReq,
  DataItem,
  UpdateDataReq,
} from 'features/adminpj/primavera/types'
import AdminMenu from '../../../MenuAdmin'
import RowItem from './RowItem'
import TableHeader from './TableHeader'
import { ConfirmDeleteModal, EventModal } from 'Components/Shared'
import PrimaveraFilter from 'Components/AdminPJ/Filter/PrimaveraFilter'
import { ConfigColumn } from 'Components/Shared/Table'
import { ActivityFilterPage } from 'Components/Filter'
import {
  selectActivityFilter,
  selectisClearFilter,
} from 'features/filter/selector'
import { toDateText } from 'utils/datetime'
import { mergeArray } from 'utils/format'

const breadcrumbs: BreadcrumbLink[] = [
  {
    url: '/dashboard',
    title: 'Home',
    icon: '/home.png',
    active: true,
    showTitle: false,
  },
  {
    url: '/user',
    title: 'user',
    active: false,
    showTitle: false,
  },
]

type Props = DispatchProps & RouteComponentProps & ReduxProps

const PrimaveraList: React.ComponentType<Props> = props => {
  const [hideColumn, setHideColumn] = useState([] as string[])
  const [showDeleted, setShowDeleted] = useState(false)
  const [enableModal, setEnableModal] = useState(false)
  const [isExport, setIsExport] = useState(false)
  const [modal, setModal] = useState(false)
  const [activity, setActivity] = useState('')
  const [disabledExport, setDisabledExport] = useState(true)
  const [Loading, setLoading] = useState(false)

  const [pagination, setPagination] = useState<{
    Page: number
    RowPerPage: number
  }>({
    Page: 1,
    RowPerPage: 10,
  })
  const columnKeyStorage = 'primavera_column'
  const {
    dataList,
    event,
    token,
    UserEmail,
    ProjectCode,
    filter,
    isClearFilter,
  } = props

  const dispatch = useDispatch()

  useEffect(() => {
    if (isClearFilter) {
      onGetList()
    }
  }, [isClearFilter])

  useEffect(() => {
    onGetList()
  }, [ProjectCode, pagination, showDeleted])

  const onGetList = (page?: number, row?: number) => {
    setEnableModal(true)

    const req: GetDataListReq = {
      UserEmail,
      ProjectCode,
      ConstructionTypeList: filter.ConstructionTypeList,
      FloorList: filter.FloorList,
      QCTypeList: [],
      UnitList: mergeArray([filter.UnitList, filter.ZoneList]),
      ZoneList: [],
      Sort: {
        OrderBy: '',
        SortBy: '',
      },
      Status: showDeleted ? 'Deleted' : 'Ready',
      StartDate: toDateText(filter.Date.StartDate || ''),
      FinishDate: toDateText(filter.Date.FinishDate || ''),
      Page: page ? page : pagination.Page,
      RowPerPage: row ? row : pagination.RowPerPage,
    }

    const myPromise = () => Promise.resolve(dispatch(getDataList(token, req)))

    myPromise().then((res: any) => {
      if (res && page && row) {
        setLoading(true)
      }
    })
  }

  const onReturnActivity = (ActivityID: string) => {
    setModal(false)
    setEnableModal(true)
    const req: UpdateDataReq = {
      UserEmail,
      ProjectCode,
      ActivityID: ActivityID,
    }
    dispatch(updateData(token, req))
  }

  // useEffect(() => {
  //   if (isExport) {
  //     let totel = dataList.Total
  //     let perpage = totel >= 1000 ?  1000 : totel <= 99 ? 10: 100
  //     let page =  totel >= 1000 ? totel / 1000 : totel <= 99 ? (totel/10):(totel/100)
  //     page = Number(page.toFixed())
  //     for(let i= 1 ;i < page; i++){
  //       onGetList(perpage, i)
  //       ExportCSV(i)
  //     }
  //       setIsExport(false)
  //       pagination.Page = 1
  //       pagination.RowPerPage = 10
  //       setPagination({ ...pagination })
  //   }
  //   }, [isExport])
  // const ExportCSV = (index: number) => {
  //   const data: any = {
  //     data: dataList.List,
  //     fileName: 'รายการ Primavera_' + `${index}`,
  //     exportType: 'csv',
  //     withBOM: true,
  //   }
  //     exportFromJSON(data)
  // }

  useEffect(() => {
    if (!event.IsLoading && isExport) {
      const data: any = {
        data: dataList.List,
        fileName: 'รายการ Primavera',
        exportType: 'csv',
        withBOM: true,
      }

      exportFromJSON(data)
      setLoading(false)
      setIsExport(false)
      onGetList()
    }
  }, [event.IsLoading])

  useEffect(() => {
    const dataStorage = getConfigColumns(columnKeyStorage)
    const value = (dataStorage && dataStorage.data) || []
    setHideColumn([...value])
  }, [])

  const remove = (array: string[], value: string) => {
    return array.filter(el => el !== value)
  }

  const onSettingColumn = (value: string) => {
    let newValue = hideColumn
    if (!hideColumn.includes(value)) {
      newValue.push(value)
    } else {
      newValue = remove(hideColumn, value)
    }
    setHideColumn([...newValue])
    saveConfigColumns(newValue, columnKeyStorage)
  }

  const getSpan = (key: string) => {
    const item = column.find(item => item.key == key)
    return (item && item.span) || 0
  }

  const gotoDetailPage = (ActivityID: string, Status: string) => {
    props.history.push(`/primavera/${ActivityID}/${Status}`)
  }

  const onSuccess = () => {
    onGetList()
  }

  const content =
    dataList &&
    dataList.List.map((item: DataItem, index: number) => {
      return (
        <RowItem
          item={item}
          hideColumn={hideColumn}
          getSpan={key => getSpan(key)}
          key={index}
          selected={false}
          onClick={() => gotoDetailPage(item.ActivityID, item.Status)}
          defaultColumn={column}
          onReturn={() => {
            setModal(true)
            setActivity(item.ActivityID)
          }}
        />
      )
    })

  const header = (
    <Header>
      <Row type="flex" justify="space-between" align="middle">
        <Col>รายการ Primavera</Col>
        <Col>
          <Row type="flex" justify="space-between" align="middle">
            <Col>
              <SwithArea>
                <Switch
                  checked={showDeleted}
                  onChange={checked => setShowDeleted(checked)}
                  disabled={false}
                />
              </SwithArea>
              <Txt>แสดงรายการที่ถูกลบ</Txt>
            </Col>
          </Row>
        </Col>
      </Row>
    </Header>
  )

  const filterContent = (
    <Header>
      <Row type="flex" justify="space-between" align="middle">
        <Col>
          <ActivityFilterPage
            onSearch={() => {
              pagination.Page = 1
              setPagination({ ...pagination })
            }}
            isAdmin={true}
          />
        </Col>

        <Col>
          <Row type="flex" justify="start" align="middle">
            <Margin>
              <Button
                type="primary"
                size="large"
                onClick={() => {
                  if (dataList.Total > 0) {
                    onGetList(1, dataList.Total)
                    setIsExport(true)
                  }
                }}
              >
                Export.csv
              </Button>
            </Margin>

            <ConfigColumn
              hideColumn={hideColumn}
              onHideColumn={value => onSettingColumn(value)}
              column={column}
            />
          </Row>
        </Col>
      </Row>
    </Header>
  )

  return (
    <>
      <SubnavBar breadcrumbs={breadcrumbs} hideSearch={true} />
      <EventModal
        name="user"
        event={[event]}
        enable={enableModal}
        successCalback={onSuccess}
      />
      <AdminMenu selected="primavera">
        <MainPage>
          {header}
          {filterContent}
          <TableHeader
            onSort={() => {}}
            sorting={''}
            defaultColumn={column}
            hideColumn={hideColumn}
          />
          {content}
          <Pagination
            totalRows={(dataList && dataList.Total) || 0}
            perPage={pagination.RowPerPage}
            currentPage={pagination.Page}
            onChangeNumber={(page: number) => {
              pagination.RowPerPage = page
              setPagination({ ...pagination })
            }}
            onClickPage={(page: number) => {
              pagination.Page = page
              setPagination({ ...pagination })
            }}
          />
        </MainPage>
        <ConfirmDeleteModal
          onClose={() => setModal(false)}
          onSubmit={() => onReturnActivity(activity)}
          title="ยืนยันคืนงาน"
          desc="คุณยืนยันที่จะคืนงานใช่หรือไม่"
          visible={modal}
        />
      </AdminMenu>
    </>
  )
}

const column = [
  {
    key: 'ActivityID',
    title: 'Activity ID',
    span: 4,
  },
  {
    key: 'ActivityName',
    title: 'Activity Name',
    span: 4,
  },
  {
    key: 'Tower',
    title: 'ตึก',
    span: 4,
  },
  {
    key: 'ConstructionType',
    title: 'ประเภทก่อสร้าง',
    span: 3,
  },
  {
    key: 'Floor',
    title: 'ชั้น',
    span: 3,
  },
  {
    key: 'Zone',
    title: 'ประเภท',
    span: 3,
  },
  {
    key: 'Unit',
    title: 'ชื่อประเภท',
    span: 3,
  },
  {
    key: 'QCTypeID',
    title: 'หมวดงาน QC',
    span: 3,
  },
  {
    key: 'SOW',
    title: 'SOW',
    span: 3,
  },
]

const MainPage = styled.div`
  padding: 40px 45px;
  width: 100%;
`

const Header = styled.div`
  margin-bottom: 10px;
  font-size: 28px;
  line-height: 1.07;
  font-family: 'DB Helvethaica X 75 Bd';
  color: #000;
`

const SwithArea = styled.div`
  padding: 0 30px;
  float: left;
`

const Txt = styled.span`
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 20px;
  color: #003168;
`

const Margin = styled.div`
  margin: 10px;
`

const mapStateToProps = (state: RootState) => {
  return {
    dataList: selectDataList(state),
    event: selectEvent(state),
    token: serviceTokenSelector(state),
    UserEmail: userEmailSelector(state),
    ProjectCode: selectedProjectCodeSelector(state),
    filter: selectActivityFilter(state),
    isClearFilter: selectisClearFilter(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(PrimaveraList)
