import React, { useState, useEffect } from 'react'
import { Row, Col, Drawer } from 'antd'
import styled from 'styled-components'
import { CloseIcon } from 'Components/Shared/Icon'
import { DropdownList } from 'Components/Shared/Form'
import ModuleBox from '../Components/ModuleBox'
import ProjectBox from '../Components/ProjectBox'
import { PrimaryButton } from 'Components/Shared/Button'
import { DataItem, ModuleItem } from 'features/admin/user/types'
import { RoleListItem } from 'features/filter/types'

import SowBox from '../Components/SowBox'
import { cloneDeep } from 'lodash'

type Props = {
  user: DataItem
  roleList: RoleListItem
  visible: boolean
  onSave?: (user: DataItem) => void
  onClose?: () => void
}

const UserDrawer: React.FunctionComponent<Props> = props => {
  const { visible, onClose, onSave = () => {}, user, roleList } = props
  const [activeModule, setActiveModule] = useState({} as any)
  const [userData, setUserData] = useState({} as DataItem)
  const [roleMenu, setRoleMenu] = useState([] as any)

  useEffect(() => {
    const menu = roleList.List.map(it => {
      return {
        name: it.RoleName,
        value: it.RoleID,
      }
    })

    setRoleMenu(menu)
  }, [roleList])

  const status = [
    {
      name: 'Active',
      value: 'Active',
    },
    {
      name: 'Inactive',
      value: 'Inactive',
    },
  ]

  useEffect(() => {
    setUserData(cloneDeep(user))
    setActiveModule({})
  }, [user])

  const onActiveModule = (module: ModuleItem) => {
    setActiveModule(module)
  }

  const onUpdateProject = (module: ModuleItem) => {
    setActiveModule(module)
    const modules = userData.ModuleList.map((item: ModuleItem) => {
      if (item.ModuleID === module.ModuleID) {
        return module
      } else {
        return item
      }
    })
    userData.ModuleList = modules
    setUserData({ ...userData })
  }

  const isMasterPlan =
    userData.ModuleList && userData.ModuleList.find(item => item.ModuleID === 1)
  const isShowSow = userData.IsVendor || isMasterPlan

  const content = (
    <MainPage>
      <Header>
        <CloseIcon color="#8b99a7" size={24} onClick={onClose} />
      </Header>
      <BodyArea>
        <Title>แก้ไข User module</Title>
        <ConfigSow>
          <Row type="flex" justify="space-between">
            {isShowSow && (
              <Col span={12}>
                <SowBox
                  user={userData}
                  onSave={userData => setUserData({ ...userData })}
                  isMasterPlan={isMasterPlan ? true : false}
                />
              </Col>
            )}
            <Col span={11}>
              <DropdownList
                label="สถานะ"
                value={userData.Status}
                menu={status}
                onChange={(status: string) => {
                  userData.Status = status
                  setUserData({ ...userData })
                }}
              />
            </Col>
          </Row>

          <Line />
          <Row>
            <Col span={isShowSow ? 12 : 11}>
              <DropdownList
                label="Role"
                value={userData.RoleID}
                menu={roleMenu}
                onChange={(id: any) => {
                  userData.RoleID = id
                  setUserData({ ...userData })
                }}
              />
            </Col>
          </Row>
        </ConfigSow>

        <Row type="flex">
          <Col span={9}>
            <ModuleBox
              span={24}
              ModuleList={userData.ModuleList}
              onSave={(module: ModuleItem[]) => {
                userData.ModuleList = module
                setUserData({ ...userData })
              }}
              activeModule={activeModule}
              onSelectModule={onActiveModule}
            />
          </Col>
          <Col span={14} offset={1}>
            <ProjectBox moduleItem={activeModule} onUpdate={onUpdateProject} />
          </Col>
        </Row>
        <ButtonSubmit>
          <PrimaryButton
            text="บันทึก"
            disabled={false}
            onClick={() => onSave(userData)}
          />
        </ButtonSubmit>
      </BodyArea>
    </MainPage>
  )

  return (
    <Drawer
      title={null}
      className="drawer-project"
      closable={false}
      width={729}
      visible={visible}
      bodyStyle={{ padding: 0 }}
    >
      {content}
    </Drawer>
  )
}

const MainPage = styled.div`
  -khtml-user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  overflow-x: scroll;
  height: calc(100vh - 112px);
`

const Header = styled.div`
  text-align: right;
  height: 40px;
  padding: 24px;

  svg {
    cursor: pointer;
  }
`

const ButtonSubmit = styled.div`
  width: 116px;
  float: right;
  margin-bottom: 24px;
`

const BodyArea = styled.div`
  padding: 0px 24px;
`

const Title = styled.div`
  font-size: 28px;
  color: black;
  font-family: 'DB Helvethaica X 75 Bd';
`

const ConfigSow = styled.div`
  margin: 24px 0 30px 0;
  border-bottom: 1px solid #e4e7eb;
  padding-bottom: 24px;
`

const Line = styled.div`
  padding-bottom: 10px;
`

export default UserDrawer
