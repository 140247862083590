import withAPIAction from 'utils/withAPIAction'
import { verifyReq, verifyReqNoProject } from 'utils/verify'
import { updateDataAction, getDataListAction } from './actions'
import { GetDataListReq, ReqUpdatePrepareMapChecklistMaincon } from './types'

export const updatePrepareMapChecklistMaincon = (
  token: string,
  data: ReqUpdatePrepareMapChecklistMaincon
) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<ReqUpdatePrepareMapChecklistMaincon>({
    method: 'POST',
    path: 'UpdatePrepareMapChecklistMaincon',
    data,
    token: `Bearer ${token}`,
  })(updateDataAction)
}

export const getDataList = (token: string, data: GetDataListReq) => {
  const isValid = verifyReqNoProject(data, token)
  if (isValid !== undefined) return isValid
  return withAPIAction<GetDataListReq>({
    method: 'POST',
    path: 'GetPrepareMapCheckListMaincon',
    data,
    token: `Bearer ${token}`,
  })(getDataListAction)
}
