import {
  all,
  select,
  call,
  put,
  fork,
  take,
  takeEvery,
} from 'redux-saga/effects'
import { getType } from 'typesafe-actions'
import * as actions from './project.actions'
import { selectedProjectCodeSelector } from 'features/selectedProject/selectedProject.selector'
import { ProjectListItem } from './project.types'
import { changeSelectedProjectAction } from 'features/selectedProject/selectedProject.actions'
import projectHelper from 'utils/projectHelper'
import accountHelper from 'utils/account'
import * as loginActions from 'features/login/login.actions'
import * as sensitiveActions from 'features/sensitive/sensitive.actions'

function* projectSaga() {
  while (true) {
    yield take(getType(actions.sampleProjectAction))
  }
}

function* watchProjectFetch() {
  while (true) {
    try {
      const action = yield take(
        getType(actions.fetchProjectAsyncAction.success)
      )

      const tokenCode = accountHelper.getTokenCode()
      const email = accountHelper.getUserEmail()

      yield put(sensitiveActions.changeUserEmail(email))
      yield put(sensitiveActions.changeAccesstoken(tokenCode))

      const data: ProjectListItem[] = action.payload.ResponseData || []

      // When there is are no projects available for this user then we'll display
      // no project message on the page
      if (data.length === 0) {
        yield put(actions.projectsEmpty(true))
      }

      // If no project has been chosen by user yet then select the first project by default
      const selectedProject = yield select(selectedProjectCodeSelector)

      // Check if we have previously stored project code
      if (!selectedProject && data.length) {
        // Check if we have previously selected project in cache
        // Then we'll have to reinitialize project with same.
        const projectCodeFromCache = projectHelper.getProjectCode()
        if (projectCodeFromCache) {
          yield put(changeSelectedProjectAction(projectCodeFromCache))
        }
      }
    } catch (error) {
      console.error(error)
    }
  }
}

function* verifyFail(action: any) {
  const { payload = {} } = action
  let { response } = payload
  if (!response) {
    response = { status: 401 }
  }

  const { status = 200 } = response
  if (status === 401) {
    yield put(loginActions.logoutUser())
  }
  yield take(actions.fetchProjectAsyncAction.failure)
}

function* allSaga() {
  while (true) {
    yield takeEvery(
      getType(actions.fetchProjectAsyncAction.failure),
      verifyFail
    )
    yield all([fork(projectSaga), call(watchProjectFetch)])
  }
}

export default allSaga
