import React from 'react'
import { Row, Col } from 'antd'

import styled from 'styled-components'
import ImagePicker from 'Components/Shared/ImagePicker'
import DocumentPicker from 'Components/Shared/DocumentPicker'
import { ProgressCircle } from 'Components/Shared'

type ActivityInfoProps = {
  originalTask: any
  onImageOpen(list?: any[], readOnly?: boolean): void
  onDocumentOpen(list?: any[], readOnly?: boolean): void
  onThumbnailOpen(index: number, list?: any[], readOnly?: boolean): void
}

type Props = ActivityInfoProps

const TaskStatus: React.FC<Props> = ({
  originalTask,
  onImageOpen,
  onDocumentOpen,
  onThumbnailOpen,
}) => {
  const isTaskComplete = originalTask.progress === 100
  const completedDate = isTaskComplete
    ? originalTask.ActualFinishDate
    : originalTask.ExpectFinishDate
  return (
    <StatusContent>
      <HeaderTitle>CM กำลังดำเนินการ</HeaderTitle>
      <BoxItem>
        <Row type="flex" justify="space-between" align="middle">
          <Col md={6}>
            <Box>
              <ProgressCircle task={originalTask} width={120} />
            </Box>
          </Col>
          <Col md={17} offset={1}>
            <Row type="flex" justify="space-between">
              <Col md={12}>
                <TextItem>
                  วันเริ่มงานจริง
                  <div>
                    {originalTask.ActualStartDate
                      ? originalTask.ActualStartDate
                      : '-'}
                  </div>
                </TextItem>
              </Col>
              <Col md={12}>
                <TextItem>
                  {isTaskComplete ? 'วันแล้วเสร็จจริง' : 'วันที่คาดแล้วเสร็จ'}
                  <div>{completedDate ? completedDate : '-'}</div>
                </TextItem>
              </Col>
            </Row>

            <Row
              type="flex"
              justify="space-between"
              style={{ marginTop: '16px' }}
            >
              <Col md={12}>
                <TextItem>
                  หมายเหตุจาก CM
                  <div>{originalTask.CmRemark || '-'}</div>
                </TextItem>
              </Col>
              <Col md={12}>
                <TextItem>
                  หมายเหตุที่ไม่อนุมัติ
                  <div>{originalTask.RejectReason || '-'}</div>
                </TextItem>
              </Col>
            </Row>
          </Col>
        </Row>
      </BoxItem>
      <BoxItem>
        <BoxPadding>
          <Row type="flex" justify="space-between">
            <Col md={12}>
              <ImagePicker
                Images={originalTask.CmImages || []}
                onImageOpen={() => onImageOpen(originalTask.CmImages, true)}
                onThumbnailOpen={index =>
                  onThumbnailOpen(index, originalTask.CmImages, true)
                }
                disabled={true}
                showMax={4}
                viewMode={true}
              />
            </Col>
            <Col md={12}>
              <DocumentPicker
                Documents={originalTask.CmDocuments || []}
                onDocumentOpen={() =>
                  onDocumentOpen(originalTask.CmDocuments, true)
                }
                disabled={false}
                showMax={4}
                viewMode={true}
              />
            </Col>
          </Row>
        </BoxPadding>
      </BoxItem>
    </StatusContent>
  )
}

const StatusContent = styled.div`
  margin-top: 40px;
  .header {
    margin-bottom: 16px;
  }
`

const TextItem = styled.div`
  font-size: 20px;
  font-family: 'DB Helvethaica X 75 Bd';

  div {
    font-family: 'DB Helvethaica X 55 Regular';
  }
`

const BoxItem = styled.div`
  background-color: #fff;
  margin-top: 12px;
  border-radius: 5px;
  border: solid 1px #f2f2f2;
  line-height: normal;
  padding: 0;
  margin-top: 0px;
`

const Box = styled.div`
  text-align: center;
  padding: 24px;
  border-right: solid 1px #f2f2f2;
  .status {
    margin-top: 8px;
    font-size: 20px;
  }
  .ant-progress-text {
    font-size: 30px !important;
  }
`
const BoxPadding = styled.div`
  padding: 24px;
`

const HeaderTitle = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 28px;
  margin-bottom: 16px;
`

export default TaskStatus
