import { createStandardAction, createAsyncAction } from 'typesafe-actions'
import {
  GetActivityRequest,
  GetActivityApiResponse,
  DashboardFilter,
  DashboardQuery,
} from './types'

export const getActivityAction = createAsyncAction(
  'cmpj/cmdashboard/FETCH_ACTIVITY_REQUEST',
  'cmpj/cmdashboard/FETCH_ACTIVITY_SUCCESS',
  'cmpj/cmdashboard/FETCH_ACTIVITY_FAILURE'
)<GetActivityRequest, GetActivityApiResponse, any>()

export const sortingDashboard = createStandardAction(
  'cmpj/cmdashboard/SORTING'
)<string>()

export const filterDashboard = createStandardAction(
  'cmpj/cmdashboard/FILTER_DASHBOARD'
)<void>()

export const setFilterDashboard = createStandardAction(
  'cmpj/cmdashboard/CONFIG_FILTER_DASHBOARD'
)<DashboardFilter>()

export const setQueryDashboard = createStandardAction(
  'cmpj/cmdashboard/CONFIG_QUERY_DASHBOARD'
)<DashboardQuery>()
