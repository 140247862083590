import { getType } from 'typesafe-actions'
import * as actions from './actions'
import { StoreState, DashboardAction } from './types'
import produce from 'immer'
import parseErrorMessage from 'utils/parseErrorMessage'

const initialState: StoreState = {
  data: [],
  event: {
    IsLoading: false,
    MessageSuccess: '',
    SubmitError: '',
    SubmitSuccess: false,
    SubmitType: '',
  },
}

export default function(
  state: StoreState = initialState,
  action: DashboardAction
): StoreState {
  return produce(state, draft => {
    switch (action.type) {
      case getType(actions.GetUnitTodoForQCAction.request):
      case getType(actions.GetZoneTodoForQCAction.request):
        draft.event.SubmitError = undefined
        draft.event.IsLoading = true
        draft.event.SubmitSuccess = false
        draft.data = state.data
        break
      case getType(actions.GetUnitTodoForQCAction.success):
      case getType(actions.GetZoneTodoForQCAction.success):
        const res = action.payload.ResponseData
        draft.event.IsLoading = false
        draft.data = res
        break

      case getType(actions.GetUnitTodoForQCAction.failure):
      case getType(actions.GetZoneTodoForQCAction.failure):
        draft.event.IsLoading = false
        draft.event.SubmitError = parseErrorMessage(action.payload)
        break

      default:
        return state
    }
  })
}
