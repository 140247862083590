import { createStandardAction, createAsyncAction } from 'typesafe-actions'
import {
  GetDataListReq,
  GetDataListResp,
  CreateDataReq,
  UpdateDataReq,
  DeleteDataReq,
  CommonResp,
  GetFilterListReq,
  FindSowResp,
} from './types'

export const getDataListAction = createAsyncAction(
  'admin/position/FETCH_LIST_REQUEST',
  'admin/position/FETCH_LIST_SUCCESS',
  'admin/position/FETCH_LIST_FAILURE'
)<GetDataListReq, GetDataListResp, any>()

export const createDataAction = createAsyncAction(
  'admin/position/CREATE_DATA_REQ',
  'admin/position/CREATE_DATA_RESP',
  'admin/position/CREATE_DATA_FAIL'
)<CreateDataReq, CommonResp, any>()

export const updateDataAction = createAsyncAction(
  'admin/position/UPDATE_DATA_REQ',
  'admin/position/UPDATE_DATA_RESP',
  'admin/position/UPDATE_DATA_FAIL'
)<UpdateDataReq, CommonResp, any>()

export const deleteDataAction = createAsyncAction(
  'admin/position/DELETE_DATA_REQ',
  'admin/position/DELETE_DATA_RESP',
  'admin/position/DELETE_DATA_FAIL'
)<DeleteDataReq, CommonResp, any>()

export const getSowListAction = createAsyncAction(
  'admin/position/FIND_SOW_REQ',
  'admin/position/FIND_SOW_RESP',
  'admin/position/FIND_SOW_FAIL'
)<GetFilterListReq, FindSowResp, any>()

export const resetEvent = createStandardAction(
  'admin/position/RESET_EVENT_ACTION'
)<void>()
