import { createStandardAction, createAsyncAction } from 'typesafe-actions'
import { GetActivityReq, GetActivityApi } from './types'

export const getDashboardAction = createAsyncAction(
  'approval/dashboard/FETCH_REQUEST',
  'approval/dashboard/FETCH_SUCCESS',
  'approval/dashboard/FETCH_FAILURE'
)<GetActivityReq, GetActivityApi, any>()

export const setProjectCode = createStandardAction(
  'approval/dashboard/SET_PROJECT_CODE'
)<string>()
