/* eslint-disable */
import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import BaseEditor from './BaseEditor'
import file from 'utils/file'
import { writeImage } from './utils'

const DisplayEditor = props => {
  const { index, item } = props
  const { mode, position } = item
  const { x, y, width, height } = position

  const onRefCrop = useCallback(
    node => {
      if (node) {
        const ctx = node.getContext('2d')

        node.width = width
        node.height = height
        const pos = {
          width,
          height,
        }

        if (mode === 'draw') {
          writeImage(mode, ctx, pos, item.data.newLineList)
        } else {
          writeImage(mode, ctx, pos, item.data)
        }
      }
    },
    [item]
  )

  const css = { left: x, top: y, zIndex: index + 10 }
  return <Canvas style={css} ref={onRefCrop} onClick={props.onClick} />
}

export const Canvas = styled.canvas`
  position: absolute;
  background-color: transparent;
`

export default DisplayEditor
/* eslint-enable */
