import { Wbs } from './wbs.types';

class WbsPathGenerator {
  data: Wbs[];

  constructor(data: Wbs[]) {
    this.data = data;
  }

  makePath() {
    const reducerFunc = (acc: Map<number, string>, curr: Wbs) => {
      const wbsName = curr.WbsIName;
      const wbsId = curr.WbsID;
      const parentId = curr.ParentWbsID;

      if (parentId) {
        const parentNodeName = acc.get(parentId);
        const wbsNameWithParent = [parentNodeName];
        const nodeName = [wbsNameWithParent, wbsName].join(' / ');
        acc.set(wbsId, nodeName);
        return acc;
      }

      acc.set(wbsId, wbsName);

      return acc;
    };

    return this.data.reduce(reducerFunc, new Map<number, string>());
  }

  generate() {
    return this.makePath();
  }
}

export default WbsPathGenerator;
