import React from 'react'
import styled from 'styled-components'
import { Tab } from 'Components/Shared'
import { PrimaryButton } from 'Components/Shared/Button'

interface Props {
  selected: 'ConstructRoad' | 'AuditRoad'
  visible?: boolean
  onClick?: () => void
}

const RoadTab: React.FunctionComponent<Props> = props => {
  const { onClick, visible = false } = props
  const tabItem = [
    {
      link: '/construct-road',
      title: 'ระหว่างก่อสร้างถนน',
      isActive: props.selected == 'ConstructRoad',
    },
    {
      link: '/audit-road',
      title: 'ตรวจรับมอบถนนและก่อนหมดประกัน',
      isActive: props.selected == 'AuditRoad',
    },
  ]

  const btn = visible && (
    <Btn>
      <PrimaryButton
        text="สร้างคำขอ"
        disabled={onClick === undefined}
        onClick={onClick}
      />
    </Btn>
  )
  return <Tab tabItem={tabItem} btn={btn} />
}

const Btn = styled.div`
  width: 160px;
`

export default RoadTab
