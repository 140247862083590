import React from 'react'
import styled from 'styled-components'

import {
  TextBtn,
  TextDisableBtn,
  CircleBtn,
  CircleDisableBtn,
  RectBtn,
  RectDisableBtn,
  DrawBtn,
  DrawDisableBtn,
} from '../../../Shared/Button'

import StickerToolMenu from './StickerToolMenu'
import ColorToolMenu from './ColorToolMenu'
import LineSizeToolMenu from './LineSizeToolMenu'

type Props = {
  mode: string
  config: any
  onChange: (val: string) => void
  onConfig: (val: any) => void
}

const ToolMenu: React.ComponentType<Props> = props => {
  const {
    mode,
    config = { color: 'white', lineSize: 1 },
    onConfig,
    onChange,
  } = props

  const onColor = (val: string) => {
    config.color = val
    onConfig(config)
  }

  const onLineSize = (val: number) => {
    config.lineSize = val
    onConfig(config)
  }

  const onSticker = (val: string) => {
    config.image = val
    onConfig(config)
    onChange('sticker')
  }

  const onText = () => onChange('text')
  const onCircle = () => onChange('circle')
  const onRect = () => onChange('rectangle')
  const onDraw = () => onChange('draw')

  return (
    <PageMenu>
      {mode === 'text' ? (
        <TextDisableBtn onClick={onText} />
      ) : (
        <TextBtn onClick={onText} />
      )}
      {mode === 'circle' ? (
        <CircleDisableBtn onClick={onCircle} />
      ) : (
        <CircleBtn onClick={onCircle} />
      )}
      {mode === 'rectangle' ? (
        <RectDisableBtn onClick={onRect} />
      ) : (
        <RectBtn onClick={onRect} />
      )}
      {mode === 'draw' ? (
        <DrawDisableBtn onClick={onDraw} />
      ) : (
        <DrawBtn onClick={onDraw} />
      )}

      <StickerToolMenu onChange={onSticker} />
      <ColorToolMenu value={config.color} onChange={onColor} />
      <LineSizeToolMenu value={config.lineSize} onChange={onLineSize} />
    </PageMenu>
  )
}

const PageMenu = styled.div`
  position: fixed;
  bottom: 0px;
  width: 100%;
  height: 64px;
  padding: 16px 24px 24px 24px;

  text-align: center;
  img {
    margin-left: 24px;
    -khtml-user-select: none;
    -o-user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    user-select: none;
  }

  img:first-child {
    margin-left: 0px;
  }
`

export default ToolMenu
