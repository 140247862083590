import RootState from 'typings/RootState'
import { DashboardData, Filter } from './types'
import { Event } from 'features/types/event'

export const selectData = (state: RootState): DashboardData =>
  state.cmpjCommissioningDashboard.data
export const selectEvent = (state: RootState): Event =>
  state.cmpjCommissioningDashboard.event

export const selectSorting = (state: RootState) =>
  state.cmpjCommissioningDashboard.sorting

export const selectFilter = (state: RootState): Filter =>
  state.cmpjCommissioningDashboard.filter
