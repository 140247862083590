import React from 'react'
import BaseImgDisableBtn from './BaseImgDisableBtn'

import img from 'assets/img/ic-redo-disable.png'

const RedoDisableBtn = (props: any) => {
  return <BaseImgDisableBtn img={img} onClick={props.onClick} />
}

export default RedoDisableBtn
