import { getType } from 'typesafe-actions';
import * as actions from './common.actions';
import { CommonAction, CommonState } from './common.types';

const initialState: CommonState = {
  token: undefined,
  language: 'th',
};

export default function(
  state: CommonState = initialState,
  action: CommonAction
): CommonState {
  switch (action.type) {
    case getType(actions.changeToken):
      return {
        ...state,
        token: action.payload,
      };

    default:
      return state;
  }
}
