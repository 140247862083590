import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'antd'
import InputText from 'Components/Shared/Input/InputText'
import { SelectedFloorPlan } from 'features/admin/floorPlan/types'

type Props = {
  item: SelectedFloorPlan
  onChangeData: (val: SelectedFloorPlan) => void
}
const HeaderConfig: React.ComponentType<Props> = ({ item, onChangeData }) => {
  const readOnly = item.Status.toLocaleLowerCase() === 'used'

  return (
    <Row>
      <Col span={24}>
        <BodyArea>
          <Title>{item.FloorPlanID === 0 ? 'เพิ่ม' : 'แก้ไข'} Floorplan</Title>
        </BodyArea>
      </Col>
      <Col span={24}>
        <BodyArea>
          <InputText
            prefix="ชื่อ"
            value={item.FloorPlanName}
            onChange={(val: string) => {
              item.FloorPlanName = val
              onChangeData(item)
            }}
            disabled={readOnly}
          />
        </BodyArea>
      </Col>
    </Row>
  )
}

const Title = styled.div`
  color: black;
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 28px;
  padding-bottom: 16px;
  line-height: 1;
`
const BodyArea = styled.div`
  padding: 0px 24px;
  font-family: 'DB Helvethaica X 55 Regular';
  span {
    font-size: 20px;
  }
  .ant-dropdown-trigger {
    margin-bottom: 6px;
  }
  .input-title {
    margin-bottom: 12px;
  }
`

export default HeaderConfig
