import withAPIAction from 'utils/withAPIAction'
import {
  getReportAction,
  getCheckListAction,
  createDefectAction,
  updateDefectAction,
  setDefectAction,
  verifyDefectAction,
  resendReportAction,
  sendEditReportAction,
} from './actions'
import {
  GetReportReq,
  GetCheckListReq,
  DefectTypeReq,
  SetDefectReq,
  VerifyDefectReq,
  ResendReportReq,
  SendEditReportToQCReq,
} from './types'
import { verifyReq } from 'utils/verify'

export const getReport = (token: string, data: GetReportReq) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<GetReportReq>({
    method: 'POST',
    path: 'GetAuditPlanReportById',
    data,
    token: `Bearer ${token}`,
  })(getReportAction)
}

export const getCheckList = (token: string, data: GetCheckListReq) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<GetCheckListReq>({
    method: 'POST',
    path: 'GetAuditPlanCheckListForQC',
    data,
    token: `Bearer ${token}`,
  })(getCheckListAction)
}

export const createDefect = (token: string, data: DefectTypeReq) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<DefectTypeReq>({
    method: 'POST',
    path: 'CreateAuditPlanDefect	',
    data,
    token: `Bearer ${token}`,
  })(createDefectAction)
}

export const updateDefect = (token: string, data: DefectTypeReq) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<DefectTypeReq>({
    method: 'POST',
    path: 'UpdateAuditPlanDefect',
    data,
    token: `Bearer ${token}`,
  })(updateDefectAction)
}

export const setDefect = (token: string, data: SetDefectReq) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<SetDefectReq>({
    method: 'POST',
    path: 'SetAuditPlanDefect',
    data,
    token: `Bearer ${token}`,
  })(setDefectAction)
}

export const verifyDefect = (token: string, data: VerifyDefectReq) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<VerifyDefectReq>({
    method: 'POST',
    path: 'VerifyAuditPlanDefect',
    data,
    token: `Bearer ${token}`,
  })(verifyDefectAction)
}

export const resendReport = (token: string, data: ResendReportReq) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<ResendReportReq>({
    method: 'POST',
    path: 'ResendAuditPlanReportByQC',
    data,
    token: `Bearer ${token}`,
  })(resendReportAction)
}

export const sendEditReportToQC = (
  token: string,
  data: SendEditReportToQCReq
) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<SendEditReportToQCReq>({
    method: 'POST',
    path: 'SendAuditPlanForQCEdit',
    data,
    token: `Bearer ${token}`,
  })(sendEditReportAction)
}
