import { getType } from 'typesafe-actions'
import * as actions from './actions'
import { StoreState, DashboardAction } from './types'
import produce from 'immer'
import parseErrorMessage from 'utils/parseErrorMessage'
import { mockCheckingData, mockCheckListData } from './mockdata'

const initialState: StoreState = {
  checkingData: [],
  checklistData: mockCheckListData,
  event: {
    IsLoading: false,
    MessageSuccess: '',
    SubmitError: '',
    SubmitSuccess: false,
    SubmitType: '',
  },
  sorting: {
    name: 'CheckListName',
    type: 'asc',
  },
  response: {
    ResponseCode: '1',
    ResponseMsg: 'Success',
    RequestTime: '',
    Responsetime: '',
    ResponseData: [],
  },
}

export default function(
  state: StoreState = initialState,
  action: DashboardAction
): StoreState {
  return produce(state, draft => {
    switch (action.type) {
      case getType(actions.getCheckingCommissioningByQC.request):
        draft.event.SubmitError = undefined
        draft.event.IsLoading = true
        draft.event.SubmitSuccess = false
        draft.checkingData = mockCheckingData
        break
      case getType(actions.getCheckingCommissioningByQC.success):
        draft.event.IsLoading = false
        const response = action.payload.ResponseData
        draft.checkingData = response

        break
      case getType(actions.getCheckingCommissioningByQC.failure):
        draft.event.IsLoading = false
        draft.event.SubmitError = parseErrorMessage(action.payload)
        break

      case getType(actions.getCommissioningCheckListForQC.request):
        draft.event.SubmitError = undefined
        draft.event.IsLoading = true
        draft.event.SubmitSuccess = false
        draft.checklistData = mockCheckListData
        break
      case getType(actions.getCommissioningCheckListForQC.success):
        draft.event.IsLoading = false
        const checklistresponse = action.payload.ResponseData
        draft.checklistData = mockCheckListData

        break
      case getType(actions.getCommissioningCheckListForQC.failure):
        draft.event.IsLoading = false
        draft.event.SubmitError = parseErrorMessage(action.payload)
        break

      default:
        return state
    }
  })
}
