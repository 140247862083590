import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'antd'
import { Link } from 'react-router-dom'
import { FixFromQC } from 'features/cmpj/sitevisit/construct/dashboard/types'
import TaskScore from './TaskScore'
import { LaneColor } from 'Components/Shared'
import { toMomentDateTime, toDateText } from 'utils/datetime'

interface Props {
  task: FixFromQC
}

const FixFromQcCard: React.FC<Props> = ({ task }) => {
  const checkedDate = toMomentDateTime(task.CheckedDate)
  const date = toDateText(checkedDate)
  const isDeleyed = task.ReturnInDate < 0 || task.ReturnInHour < 0

  const renderDate = () => {
    const day = `${Math.abs(task.ReturnInDate)} วัน`
    const hours = `${Math.abs(task.ReturnInHour)} ชั่วโมง`
    if (isDeleyed) {
      return (
        <Detail>
          <WarningTxt>
            เลยกำหนดตอบกลับ: {day} {hours}
          </WarningTxt>
        </Detail>
      )
    } else {
      return (
        <Detail>
          กรุณาตอบกลับภายใน:{' '}
          <WarningTxt>
            {day} {hours}
          </WarningTxt>
        </Detail>
      )
    }
  }

  return (
    <Link to={`/construct-plan/report/${task.RequestID}/${task.CheckNumber}`}>
      <MainCard>
        <LaneColor status={isDeleyed ? 'fail' : ''} />
        <Row type="flex" justify="space-between" align="bottom">
          <Col>
            <Title>ตรวจครั้งที่ {task.CheckNumber}</Title>
            {renderDate()}
            <Detail>วันที่ตรวจ: {date}</Detail>
            <TaskScore
              checkStatus={task.CheckStatus}
              score={task.InspectionScore}
            />
          </Col>
        </Row>
      </MainCard>
    </Link>
  )
}

const MainCard = styled.div`
  background: #fff;
  border-radius: 10px;
  padding: 16px;
  background-clip: padding-box;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.03);
  margin-bottom: 12px;
  cursor: pointer;
  color: #000;
  width: 100%;
  position: relative;
`

const Title = styled.p`
  font-size: 20px;
  margin-bottom: 4px;
  font-family: 'DB Helvethaica X 75 Bd';
`

const Detail = styled.div`
  font-size: 18px;
  margin-bottom: 4px;
  font-family: 'DB Helvethaica X 55 Regular';
`

const WarningTxt = styled.span`
  color: #d0494c;
`

export default FixFromQcCard
