import React, { useState, useEffect, useReducer } from 'react'
import styled from 'styled-components'
import { Input, Row } from 'antd'
import ContentBox from 'Components/Shared/Card/ContentBox'
import RadioBox from 'Components/Shared/Form/RadioBox'
import RadioBoxInput from 'Components/Shared/Input/RadioBox'
import icSearch from 'assets/img/ic-search-grey.png'

import { DropdownList } from 'Components/Shared/Form'
import {
  CheckListDetail,
  UnitCheckListByCodeType,
  ModalType,
  UnitType,
} from 'features/checklist/unit/types'
import { ArrowDownIcon, ArrowUpIcon } from 'Components/Shared/Icon'
import {
  calculateOnInitiate,
  updateModalsChange,
  updateUnitChange,
  updateModelChange,
} from './UnitBoxUpdate'
import { ModelType } from 'features/masterData/types'

type UnitIsCheckAllKey = {
  [key: string]: {
    isCheck: boolean
    isReadOnly: boolean
  }
}

type Props = {
  currentProjectCode: string
  unitCheckListByCode: UnitCheckListByCodeType
  unitCheckListByCodeResult: UnitCheckListByCodeType
  checkListDetail: CheckListDetail
  unitLoading: boolean
}

const filterModal = (modalList: ModalType[], filterText: string) => {
  return modalList.filter((item: ModalType) => {
    if (filterText === '') return true
    return item.ModelCode.toLocaleLowerCase().includes(
      filterText.toLocaleLowerCase()
    )
  })
}

const UnitBox: React.ComponentType<Props> = ({
  currentProjectCode: code,
  unitCheckListByCode,
  unitCheckListByCodeResult,
  checkListDetail,
  unitLoading,
}) => {
  const [filterText, setfilterText] = useState<string>('')
  const [unit, setUnit] = useState<UnitCheckListByCodeType>(unitCheckListByCode)
  const [unitIsCheckAll, setUnitIsCheckAll] = useState<UnitIsCheckAllKey>({})
  const [search, setSearch] = useState<string>('')
  const [listBySearch, setListBySearch] = useState<ModalType[]>([])

  useEffect(() => {
    if (unitCheckListByCode[code] != null) {
      const { unit, unitIsCheckAll: newUnitIsCheckAll } = calculateOnInitiate(
        checkListDetail.CheckListID,
        unitCheckListByCode,
        unitIsCheckAll,
        code
      )
      setUnit(unit)
      setUnitIsCheckAll(newUnitIsCheckAll)
    } else {
      setUnit(unitCheckListByCode)
    }
  }, [code, unitCheckListByCode])

  function onSearch(text: string) {
    setfilterText(text)
  }

  const selectCriteria = (name: string, modal: ModalType) => {
    const unitResult = unitCheckListByCodeResult[code]

    unitResult.ModelList.forEach(m => {
      if (
        m.ModelID === modal.ModelID &&
        m.DirectionName === modal.DirectionName
      ) {
        m.CriteriaName = name
      }
    })
    modal.CriteriaName = name
    updateModelChange(modal, unitResult)
    setUnit({ ...unit })
  }

  const toggleAll = (val: boolean, filterList: ModalType[]) => {
    unitIsCheckAll[code].isCheck = !val

    filterList.forEach(m => {
      if (!m.isReadOnly) toggleSelectModals(val, m, true)
    })
    setUnitIsCheckAll({ ...unitIsCheckAll })
    setUnit({ ...unit })
  }

  const toggleSelectModals = (
    val: boolean,
    item: ModalType,
    fromParent?: boolean
  ) => {
    item.isCheck = !val
    const unitResult = unitCheckListByCodeResult[code]
    updateModalsChange(val ? 'N' : 'Y', item, unitResult)
    if (!fromParent) {
      setUnit({ ...unit })
    }
  }

  const toggleSelectModalUnit = (
    it: UnitType,
    selected: string,
    modal: ModalType
  ) => {
    updateUnitChange(
      selected === 'Y' ? 'N' : 'Y',
      it,
      modal,
      unitCheckListByCodeResult[code]
    )
    setUnit({ ...unit })
  }

  const hideModel = (modal: ModalType) => {
    modal.isHide = !modal.isHide
    setUnit({ ...unit })
  }

  const menuChoice = (modal: ModalType) => {
    const result = checkListDetail.CriteriaList.map((item, index: number) => (
      <DataItem
        key={index}
        onClick={() => selectCriteria(item.CriteriaName, modal)}
      >
        {item.CriteriaName}
      </DataItem>
    ))
    return result
  }

  let filterList: ModalType[] = []
  let name: string

  useEffect(() => {
    if (unit[code]) {
      const filtered = filterModal(unit[code].ModelList, filterText)

      setListBySearch(filtered)
    }
  }, [unit[code]])

  if (unit[code]) {
    filterList = filterModal(unit[code].ModelList, filterText)
  }

  return (
    <ContentBox
      title="เลือกยูนิตที่ต้องการผูก"
      isEmpty={unit[code] == null}
      emptyText="กรุณาเลือก “โครงการที่ใช้งาน”"
      isLoading={unitLoading}
      onSearch={onSearch}
    >
      <ListSection>
        <DivStyle>
          <InputSearch>
            <Input
              placeholder="ค้นหา: ยูนิต หรือ Model"
              suffix={<img src={icSearch} />}
              onChange={(evt: any) => {
                const searchText = evt.target.value
                setSearch(searchText)

                let filteredModelList: ModalType[] = []

                filterList.map((item, index) => {
                  const unitList = [
                    ...item.UnitList.filter((unitItem, unitIndex) => {
                      let model = item.DirectionName
                        ? `${item.ModelCode}-${item.DirectionName}`
                        : item.ModelCode
                      if (
                        (model + ' ' + unitItem.UnitName)
                          .toLocaleLowerCase()
                          .includes(searchText.toLocaleLowerCase())
                      ) {
                        return unitItem
                      }
                    }),
                  ]

                  if (unitList.length > 0) {
                    filteredModelList.push({ ...item, UnitList: unitList })
                  }
                })

                setListBySearch([...filteredModelList])
              }}
              value={search}
            />
          </InputSearch>
        </DivStyle>
        <DivStyle>
          <RadioBox
            value={unitIsCheckAll[code] ? unitIsCheckAll[code].isCheck : false}
            label={'เลือกทั้งหมด'}
            isCheck={
              unitIsCheckAll[code] ? unitIsCheckAll[code].isCheck : false
            }
            readOnly={
              unitIsCheckAll[code]
                ? listBySearch.length === 0 || unitIsCheckAll[code].isReadOnly
                : false
            }
            onClick={val => toggleAll(val, listBySearch)}
          />
        </DivStyle>
        {listBySearch.map((modal: ModalType, index: number) => {
          name = modal.DirectionName
            ? `${modal.ModelCode}-${modal.DirectionName}`
            : modal.ModelCode

          return (
            <List key={index}>
              <ListText>
                <Row type="flex" align="middle" justify="space-between">
                  <RadioBox
                    value={modal.isCheck}
                    label={name}
                    isCheck={modal.isCheck}
                    readOnly={modal.isReadOnly}
                    onClick={value => toggleSelectModals(value, modal)}
                  />
                  <Row type="flex" align="middle">
                    <div style={{ marginRight: 10 }}>
                      {modal.isHide ? (
                        <ArrowUpIcon onClick={() => hideModel(modal)} />
                      ) : (
                        <ArrowDownIcon onClick={() => hideModel(modal)} />
                      )}
                    </div>
                    <DropdownList
                      label="Criteria"
                      value=""
                      placeholder={modal.CriteriaName || 'กรุณาเลือก'}
                      menuChoice={menuChoice(modal)}
                      disabled={modal.isReadOnly}
                    />
                  </Row>
                </Row>
              </ListText>

              {!modal.isHide &&
                modal.UnitList.map((it: UnitType, index2: number) => {
                  return (
                    <ListText style={{ marginLeft: 40 }} key={index2}>
                      <RadioBoxInput
                        label={it.UnitName}
                        text={it.detail}
                        isSelected={it.Selected === 'Y'}
                        readOnly={it.isReadOnly}
                        value={it}
                        onClick={(val, tag) =>
                          toggleSelectModalUnit(val, tag, modal)
                        }
                        tag={it.Selected}
                      />
                    </ListText>
                  )
                })}
            </List>
          )
        })}
      </ListSection>
    </ContentBox>
  )
}

const InputSearch = styled.div`
  .ant-input-affix-wrapper {
    .ant-input {
      height: 48px;
      border-radius: 5px;
      font-size: 18px;
      font-family: 'DB Helvethaica X 55 Regular';
    }
  }

  .ant-input-affix-wrapper > .ant-input-suffix {
    img {
      width: 16px;
      height: 16px;
    }
  }
`

const ListSection = styled.div`
  margin-top: 16px;
  height: 256px;
  overflow-y: scroll;
  padding: 0 8px;
  padding-bottom: 30px;
`

const List = styled.div`
  font-size: 20px;
  width: 100%;
  &.selected {
    border-radius: 5px;
    border: solid 1px #003168;
  }
`

const ListText = styled.div`
  border-bottom: 1px solid #e8ebf0;
  height: 100%;
  padding: 13px 0;
  margin: 0 16px;
  .ant-dropdown-trigger {
    width: 250px;
  }
`

const DivStyle = styled.div`
  padding: 13px 0;
  margin: 0 16px;
  div {
    font-size: 20px;
    color: #003168;
    font-family: 'DB Helvethaica X 75 Bd';
  }
`

const DataItem = styled.div`
  padding: 5px 10px;

  &:hover {
    cursor: pointer;
    color: white;
    background-color: #003168;
  }
`

export default UnitBox
