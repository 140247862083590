import React, { useState, forwardRef, useImperativeHandle } from 'react'
import { Modal, Row, Col } from 'antd'
import CloseIcon from 'Components/Shared/Icon/CloseIcon'
import styled from 'styled-components'
import PrimaryButton from 'Components/Shared/Button/PrimaryButton'
import OutlineButton from 'Components/Shared/Button/OutlineButton'

export interface DeleteModalHandles {
  showModal: (txt: string, cond: number, obj?: any, titel?: string) => void
}

interface Props {
  onOk: (cond: number, obj?: any) => void
}

const DeleteModal = forwardRef<DeleteModalHandles, Props>(
  ({ onOk: onOkProps }, ref) => {
    const [visible, setvisible] = useState(false)
    const [text, settext] = useState('initialState')
    const [cond, setcond] = useState(0)
    const [obj, setobj] = useState(undefined)
    const [titel, setTitel] = useState('')

    const closeModal = () => {
      setvisible(false)
      setobj(undefined)
    }

    useImperativeHandle(ref, () => ({
      showModal: (txt: string, cond: number, obj?: any, titel?: string) => {
        setvisible(true)
        settext(txt)
        setTitel(titel ?? '')
        setcond(cond)
        if (obj != null) {
          setobj(obj)
        }
      },
    }))

    const onOk = () => {
      onOkProps(cond, obj)
      closeModal()
    }

    return (
      <ModalCheckList>
        <Modal
          centered
          visible={visible}
          width="528px"
          footer={null}
          closeIcon={<CloseIcon color="#8b99a7" size={16} />}
          title={<Header>{`${titel != '' ? titel : 'ลบรายการ'}`}</Header>}
          className="add-checklist-modal"
          onCancel={closeModal}
        >
          <ModalContent>
            <span>{text}</span>&nbsp;&nbsp;
            <ButtonSection>
              <Row type="flex" justify="space-around">
                <Col span={11}>
                  <OutlineButton
                    text="ยกเลิก"
                    disabled={false}
                    onClick={closeModal}
                  />
                </Col>
                <Col span={11}>
                  <PrimaryButton text="ใช่" btnType="Reject" onClick={onOk} />
                </Col>
              </Row>
            </ButtonSection>
          </ModalContent>
        </Modal>
      </ModalCheckList>
    )
  }
)

const Header = styled.span`
  font-size: 28px;
  color: #000000;
  font-family: 'DB Helvethaica X 75 Bd';
`

const ModalCheckList = styled.div`
  .add-checklist-modal {
    .ant-modal-header {
      border-bottom: none;
    }
  }
`

const ModalContent = styled.div`
  padding: 0 24px 31px 24px;
  font-family: 'DB Helvethaica X 55 Regular';
  font-size: 28px;
  font-size: 20px;
  .text-warning {
    font-family: 'DB Helvethaica X 75 Bd';
    color: #d0494c;
  }
`

const ButtonSection = styled.div`
  padding-top: 30px;
`

export default DeleteModal
