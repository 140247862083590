import React, { useEffect } from 'react'
import styled from 'styled-components'
import { connect, useDispatch } from 'react-redux'
import { Layout, Row, Col } from 'antd'
import { RouteComponentProps } from 'react-router-dom'
import { translate } from 'i18n'
import TKeys from 'i18n/translationKey'

import RootState from 'typings/RootState'
import SubnavBar from 'Components/Shared/SubnavBar'
import Pagination from 'Components/Shared/Pagination'

import MessageBox from '../../messages/MessageBox'
import { BreadcrumbLink } from 'Components/Shared/BreadCrumb/BreadCrumb'
import { TableHeader, TableColHeader } from 'Components/Shared/Table'
import Menu from '../../MenuAdmin'

import { projectCodeWithSuffixSelector } from 'features/project/project.selector'

import {
  userEmailSelector,
  serviceTokenSelector,
} from 'features/sensitive/sensitive.selector'
import RowItem from './RowItem'

const { Content } = Layout

const breadcrumbs: BreadcrumbLink[] = [
  {
    url: '/',
    title: 'Home',
    icon: '/home.png',
    active: true,
    showTitle: false,
  },
  {
    url: '/',
    title: 'Primavera list',
    active: false,
    showTitle: false,
  },
]

type Props = ReduxProps & DispatchProps & RouteComponentProps

const AdminPrimavera: React.ComponentType<Props> = props => {
  const { token, ProjectCode, UserEmail } = props
  const content = [1, 2, 3, 4].map((item, index) => {
    return <RowItem />
  })
  return (
    <>
      <SubnavBar breadcrumbs={breadcrumbs} hideSearch={true} />
      <Menu>
        <MainPage>
          <Header>
            <Title>Primavera list</Title>
          </Header>

          <TableHeader>
            <TableColHeader span={4} isPadding={true}>
              Activity ID
            </TableColHeader>
            <TableColHeader span={4}>Activity Name</TableColHeader>
            <TableColHeader span={4}>Building</TableColHeader>
          </TableHeader>
          {content}

          <Pagination />
        </MainPage>
      </Menu>
    </>
  )
}

const MainPage = styled.div`
  padding: 40px 45px;
  width: 100%;
`

const Header = styled.div`
  margin-bottom: 35px;
  height: 50px;
`

const Title = styled.h2`
  font-size: 28px;
  font-family: 'DB Helvethaica X 75 Bd';
  height: 30px;
`

const mapStateToProps = (state: RootState) => {
  return {
    token: serviceTokenSelector(state),
    ProjectCode: projectCodeWithSuffixSelector(state),
    UserEmail: userEmailSelector(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminPrimavera)
