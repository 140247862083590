import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { RootState } from 'typings/RootState'
import styled from 'styled-components'

import {
  getTowerList,
  getFloorList,
  getZoneList,
  getProjectType,
} from 'features/filter/api'
import {
  GetProjectTypeReq,
  GetZoneListReq,
  GetFloorListReq,
  GetTowerListReq,
} from 'features/filter/types'
import {
  selectProjectType,
  selectZoneList,
  selectFloorList,
  selectTowerList,
} from 'features/filter/selector'
import { Col, Row } from 'antd'
import {
  projectsByIdSelector,
  selectedProjectCodeSelector,
} from 'features/project/project.selector'
import {
  serviceTokenSelector,
  userEmailSelector,
} from 'features/sensitive/sensitive.selector'
import { DropdownPopupMenu } from 'Components/Shared/Form'
import { fetchProjects } from 'features/project/project.api'
import { selectFilter } from 'features/adminReport/commonArea/selector'
import { Filter } from 'features/adminReport/commonArea/types'
import {
  updateFilter,
  clearFilter,
} from 'features/adminReport/commonArea/actions'
import ZoneContent from 'Components/Shared/Filters/ZoneContent'
import FloorContent from 'Components/Shared/Filters/FloorContent'
import TowerContent from 'Components/Shared/Filters/TowerContent'
import {
  ProjectsFilter,
  ProjectTypeFilter,
  DateFilter,
  ButtonFilter,
} from './Components'
import MulticheckProject from 'Components/Shared/Filters/MulticheckProject'

type Props = {
  onSearch?: (isClear?: boolean) => void
  withPadding?: boolean
  onExport?: () => void
  exportData?: any[]
  isDownLoading?: boolean
} & ReduxProps &
  DispatchProps

const CommissioningFilter: React.FunctionComponent<Props> = props => {
  const {
    ProjectCode,
    UserEmail,
    token,
    zoneList,
    floorList,
    towerList,
    filter,
    projectsById,
    onSearch = () => {},
    withPadding = false,
    projectType,
    onExport,
    exportData,
    isDownLoading,
  } = props

  useEffect(() => {
    dispatch(clearFilter())
    onGetZoneList()
    onGetFloorList()
    onGetProjectType()
    onGetTowerList()
    dispatch(fetchProjects({ email: UserEmail, token }))
    return () => {
      dispatch(clearFilter())
    }
  }, [ProjectCode, UserEmail])

  useEffect(() => {
    onGetZoneList()
    onGetFloorList()
    onGetTowerList()
  }, [filter.ProjectCode.length === 1])

  useEffect(() => {
    filter.FloorList = []
    filter.ZoneList = []
    filter.TowerList = []
    onFilterChange(filter)
  }, [filter.ProjectCode.length !== 1])

  const onGetProjectType = () => {
    const req: GetProjectTypeReq = {
      UserEmail,
      Page: 1,
      RowPerPage: 100,
    }
    dispatch(getProjectType(token, req))
  }

  const onGetZoneList = () => {
    if (filter.ProjectCode.length === 1) {
      const req: GetZoneListReq = {
        ProjectCode: filter.ProjectCode[0],
        UserEmail,
        TowerList: filter.TowerList,
        FloorList: filter.FloorList,
      }
      dispatch(getZoneList(token, req))
    }
  }

  const onGetFloorList = () => {
    if (filter.ProjectCode.length === 1) {
      const req: GetFloorListReq = {
        ProjectCode: filter.ProjectCode[0],
        UserEmail,
        TowerList: filter.TowerList,
      }
      dispatch(getFloorList(token, req))
    }
  }

  const onGetTowerList = () => {
    if (filter.ProjectCode.length === 1) {
      const req: GetTowerListReq = {
        ProjectCode: filter.ProjectCode[0],
        UserEmail,
      }
      dispatch(getTowerList(token, req))
    }
  }

  const dispatch = useDispatch()
  const onFilterChange = (filter: Filter) => {
    dispatch(updateFilter(filter))
  }

  const onClearSearch = () => {
    dispatch(clearFilter())
    onSearch(true)
  }

  const isFilter = () => {
    return (
      filter.ZoneList.length > 0 ||
      filter.FloorList.length > 0 ||
      filter.Date.Mode !== '' ||
      filter.ProjectCode.length > 0 ||
      filter.ProjectTypeID !== 0 ||
      filter.TowerList.length > 0
    )
  }

  const selectedProject =
    filter.ProjectCode.length > 0
      ? `เลือก ${filter.ProjectCode.length} โครงการ`
      : ''

  return (
    <MainPage className={withPadding ? 'with-padding' : ''}>
      <Row type="flex" justify="start">
        <Col span={8}>
          <ProjectTypeFilter
            filter={filter}
            onFilterChange={onFilterChange}
            projectType={projectType}
          />
        </Col>
        <Col span={8}>
          <Box>
            <DropdownPopupMenu
              label={'โครงการ'}
              placeholder="เลือกโครงการ"
              value={filter.ProjectCode.join(',')}
              disabled={false}
              content={
                <MulticheckProject
                  projectList={projectsById}
                  projectTypeID={filter.ProjectTypeID}
                  filter={filter.ProjectCode}
                  onChange={data => {
                    filter.ProjectCode = data
                    onFilterChange(filter)
                  }}
                />
              }
              isActive={filter.ProjectCode.length > 0}
            />
          </Box>
        </Col>

        <Col span={8}>
          <DateFilter filter={filter} onFilterChange={onFilterChange} />
        </Col>

        <Col span={8}>
          <Box>
            <DropdownPopupMenu
              label={'อาคาร'}
              placeholder="ทั้งหมด"
              value={filter.TowerList.join(',')}
              disabled={filter.ProjectCode.length !== 1}
              content={
                <TowerContent
                  towers={towerList.List}
                  filter={filter.TowerList}
                  onChange={data => {
                    filter.TowerList = data
                    filter.ZoneList = []
                    filter.FloorList = []
                    onFilterChange(filter)
                    onGetFloorList()
                  }}
                />
              }
              isActive={filter.TowerList.length > 0}
            />
          </Box>
        </Col>
        <Col span={8}>
          <Box>
            <DropdownPopupMenu
              label={'ชั้น'}
              placeholder="ทั้งหมด"
              value={filter.FloorList.join(',')}
              disabled={filter.ProjectCode.length !== 1}
              content={
                <FloorContent
                  floors={floorList.List}
                  filter={filter.FloorList}
                  onChange={data => {
                    filter.ZoneList = []
                    filter.FloorList = data
                    onFilterChange(filter)
                    onGetZoneList()
                  }}
                />
              }
              isActive={filter.FloorList.length > 0}
            />
          </Box>
        </Col>
        <Col span={8}>
          <Box>
            <DropdownPopupMenu
              label={'โซน'}
              placeholder="เลือกโซน"
              value={filter.ZoneList.join(',')}
              disabled={filter.ProjectCode.length !== 1}
              content={
                <ZoneContent
                  hasSearch={true}
                  zones={zoneList.List}
                  filter={filter.ZoneList}
                  onChange={data => {
                    filter.ZoneList = data
                    onFilterChange(filter)
                  }}
                />
              }
              isActive={filter.ZoneList.length > 0}
            />
          </Box>
        </Col>
        <Col span={16}></Col>
        <Col span={8}>
          <ButtonFilter
            isDisabled={filter.ProjectCode.length != 1}
            onClearSearch={onClearSearch}
            onSearch={onSearch}
            isFilter={isFilter()}
            onExport={onExport}
            isDownLoading={isDownLoading}
            exportData={exportData}
            fileName="รายงานตรวจส่วนกลาง-ส่วนกลางทั่วไป"
          />
        </Col>
      </Row>
    </MainPage>
  )
}

const MainPage = styled.div`
  margin-bottom: 24px;
  &.with-padding {
    padding: 0 120px;
  }

  background-color: #f5f6fa;
`

const Box = styled.div`
  padding: 0 12px 12px 0;
`

const mapStateToProps = (state: RootState) => {
  return {
    ProjectCode: selectedProjectCodeSelector(state),
    UserEmail: userEmailSelector(state),
    token: serviceTokenSelector(state),
    filter: selectFilter(state),
    zoneList: selectZoneList(state),
    floorList: selectFloorList(state),
    projectType: selectProjectType(state),
    projectsById: projectsByIdSelector(state),
    towerList: selectTowerList(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(CommissioningFilter)
