import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { RootState } from 'typings/RootState'
import { Layout, Row } from 'antd'
import { Spin, Alert, Icon } from 'antd'
import { translate } from 'i18n'

import SubnavBar from '../../Shared/SubnavBar'
import { BreadcrumbLink } from '../../Shared/BreadCrumb/BreadCrumb'
import {
  getSearchDataSelector,
  isSubmittingSearchResultSelector,
  getTotalRows,
  searchResultError,
} from 'features/search/search.selector'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import TKeys from 'i18n/translationKey'
import NoSearchResultInfo from 'Components/NoSearchResultInfo'
import { performSearchRequest } from 'features/search/search.actions'
import { SearchQueryParams } from 'features/search/search.types'
import getSearchQuery from 'utils/getSearchQuery'
import { serviceTokenSelector } from 'features/sensitive/sensitive.selector'
import SearchResults from './SearchResults'

const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />

const { Content } = Layout

const breadcrumbs: BreadcrumbLink[] = [
  {
    url: '/dashboard',
    title: 'Home',
    icon: '/home.png',
    active: true,
    showTitle: false,
  },
  {
    url: '/dashboard',
    title: translate(TKeys.Breadcrumb.dashboard),
    active: true,
    showTitle: true,
  },
  {
    url: '/search-results',
    title: translate(TKeys.Breadcrumb.search_results),
    active: false,
    showTitle: false,
  },
]

type Props = ReduxProps & RouteComponentProps

const SearchResultPage: React.FunctionComponent<Props> = ({
  items,
  location,
  isFetchingSearchResult,
  history,
  totalRows,
  searchResultError,
  token,
}) => {
  const dispatch = useDispatch()
  const { keyword, page = 1, rowPerPage = 20, type }: any = getSearchQuery(
    location.search
  )

  useEffect(() => {
    if (keyword) {
      dispatch(
        performSearchRequest({
          keyword,
          page,
          rowPerPage,
          history,
          token,
          type,
        })
      )
    }
  }, [dispatch, history, keyword, page, rowPerPage])

  return (
    <>
      <SubnavBar breadcrumbs={breadcrumbs} />
      <Content className="mid-content">
        <div className="main-content search-result-page">
          {isFetchingSearchResult && (
            <div className="loading-content">
              <Spin indicator={antIcon} tip="Fetching Search Results..." />
            </div>
          )}
          {!isFetchingSearchResult && !!searchResultError && (
            <Alert
              style={{
                borderRadius: '5px',
                border: '1px solid #d0494c',
                backgroundColor: '#eadbde',
              }}
              message={
                <div>
                  <img
                    src="/img/ic-warning.svg"
                    width="16"
                    height="16"
                    style={{
                      paddingLeft: '1px',
                      display: 'inline-block',
                      marginTop: '-3px',
                      marginRight: '8px',
                      objectFit: 'contain',
                    }}
                    alt=""
                  />
                  <span
                    style={{
                      fontFamily: 'DB Helvethaica X 55 Regular',
                      fontSize: '18px',
                      height: '22px',
                      color: '#000',
                    }}
                  >
                    {searchResultError}
                  </span>
                </div>
              }
              type="error"
            />
          )}
          {!isFetchingSearchResult && !!searchResultError === false && (
            <Row style={{ marginBottom: '35px' }}>
              {!totalRows && <NoSearchResultInfo />}
              {totalRows > 0 && (
                <SearchResults
                  page={page || 1}
                  totalItemCount={totalRows}
                  rowPerPage={rowPerPage || 10}
                  items={items}
                  keyword={keyword || ''}
                  type={type || ''}
                />
              )}
            </Row>
          )}
        </div>
      </Content>
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    items: getSearchDataSelector(state),
    isFetchingSearchResult: isSubmittingSearchResultSelector(state),
    totalRows: getTotalRows(state),
    searchResultError: searchResultError(state),
    token: serviceTokenSelector(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

export default connect(mapStateToProps)(withRouter(SearchResultPage))
