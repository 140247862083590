import React from 'react'
import PopupMenu from 'Components/Shared/Filters/PopupMenu'
import Content from 'Components/Shared/Filters/UnitContent'

type Props = {
  onFilterChange: (filter: any) => void
  filter: any
  unitList: any[]
}

const UnitFilter: React.FunctionComponent<Props> = props => {
  const { onFilterChange, filter, unitList } = props

  return (
    <PopupMenu
      label={'ยูนิต'}
      content={
        <Content
          units={unitList}
          filter={filter.UnitList}
          onChange={data => {
            filter.UnitList = data
            onFilterChange(filter)
          }}
        />
      }
      isActive={filter.UnitList.length > 0}
    />
  )
}

export default UnitFilter
