/* eslint-disable */
import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import BaseEditor from './BaseEditor'

const StickerEditor = props => {
  const { initial, limit, image, onPosition } = props

  return (
    <BaseEditor
      isCircle={false}
      displaySide={false}
      initial={initial}
      limit={limit}
      onPosition={onPosition}
    >
      <Image src={image} alt="" />
    </BaseEditor>
  )
}

const Image = styled.img`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: autol;
  -khtml-user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
`

export default StickerEditor
/* eslint-enable */
