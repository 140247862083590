import React from 'react'
import { HeaderRow } from 'Components/Shared/Table'

type Props = {
  onSort(name: string): void
  sorting: any
}

const TableHeader: React.FunctionComponent<Props> = props => {
  const { onSort, sorting } = props

  const header = [
    {
      title: 'โครงการ',
      span: 6,
      key: 'ProjectNameTH',
    },
    {
      title: 'โซน',
      span: 6,
      key: 'Unit',
    },
    {
      title: 'ชั้น',
      span: 6,
      key: 'Floor',
    },
    {
      title: 'รายการรออนุมัติ',
      span: 6,
      key: 'TotalDefect',
    },
  ]

  return (
    <HeaderRow onSort={key => onSort(key)} sorting={sorting} items={header} />
  )
}

export default TableHeader
