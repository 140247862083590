import React from 'react'
import { Input, Button } from 'antd'
import styled from 'styled-components'

interface Props {
  text: string
  disabled?: boolean
  onClick?: () => void
}

const OutlineButton: React.FC<Props> = ({
  text,
  disabled = false,
  onClick,
}) => {
  return (
    <ButtonSection>
      <Button type="link" className="btn" disabled={disabled} onClick={onClick}>
        {text}
      </Button>
    </ButtonSection>
  )
}

const ButtonSection = styled.div`
  .btn,
  .btn:hover,
  .btn:focus {
    width: 100%;
    height: 50px;
    text-align: center;
    border-radius: 5px;
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.03);
    background-color: #fff;
    font-size: 20px;
    font-family: 'DB Helvethaica X 75 Bd';
    color: #8b99a7;
    border-color: #8b99a7;
    border: 1.5px solid;
    cursor: pointer;
  }

  .ant-btn[disabled]:focus,
  .ant-btn[disabled]:hover {
    background: #e5e5e5;
    border-color: #e5e5e5;
  }
`
export default OutlineButton
