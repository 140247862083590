import React, { useState, useEffect, useLayoutEffect, useCallback } from 'react'
import { Modal, Row, Col } from 'antd'
import styled from 'styled-components'
import { cloneDeep } from 'lodash'
import file from 'utils/file'

import DrawEditor from 'Components/Pages/ActivityDetailPage/Editor/DrawEditor'

type Props = {
  display?: boolean
  disabled?: boolean
  onChange: (val: any[]) => void
}

const Editor: React.ComponentType<Props> = (props) => {
  const [config, setConfig] = useState({
    color: 'black',
    lineSize: 3,
    image: '',
    value: '',
    fontSize: 20,
  } as any)
  const [limitPosition, setLimitPosition] = useState({
    left: 0,
    top: 0,
    bottom: 195,
    right: 625,
    width: 25,
    height: 40,
  } as any)

  const [initial, setInitial] = useState({ lineList: [] } as any)
  const { display, disabled, onChange } = props

  useEffect(() => {
    if (display === true) {
      setInitial([])
    }
  }, [display])

  const onClear = () => {
    onChange([])
    setInitial({ lineList: [] })
  }

  const cssSize = {
    width: `${limitPosition.right}px`,
    height: `${limitPosition.bottom}px`,
  }

  return (
    <PageView>
      <TitleCanvas>
        <label>ลายเซ็นต์</label>
        <span onClick={disabled ? undefined : onClear}>ล้าง</span>
      </TitleCanvas>
      <PageArea>
        <ImgArea style={cssSize}>
          <DrawEditor
            initial={initial}
            limit={limitPosition}
            color={config.color}
            lineSize={config.lineSize}
            onChange={onChange}
          />
        </ImgArea>
      </PageArea>
    </PageView>
  )
}

const PageView = styled.div`
  width: 100%;
  height: 240px;
  background-color: white;
  border-radius: 5px;
  border: 1px solid #e4e7eb;
`

const PageArea = styled.div`
  align-items: center;
  display: flex;
`

const ImgArea = styled.div`
  position: relative;
  margin: 0 auto;
  width: auto;
  height: auto;
`

const TitleCanvas = styled.div`
  padding: 12px 16px 2px 16px;
  font-size: 20px;

  label {
    color: #8b99a7;
    font-family: 'DB Helvethaica X 55 Regular';
  }

  span {
    float: right;
    text-decoration: underline;
    font-family: 'DB Helvethaica X 75 Bd';
    color: #003168;
    cursor: pointer;
  }
`

export default Editor
