import React from 'react'
import { Col } from 'antd'
import styled from 'styled-components'
import { TableRow } from 'Components/Shared/Table'
import { EditIcon, TrashIcon, CheckedIcon } from 'Components/Shared/Icon'
import { ModelListItem } from 'features/admin/model/types'

type DefaultProps = {
  item: ModelListItem
  selected?: boolean
  onClick: (item: ModelListItem) => void
  onDelete: (item: ModelListItem) => void
}

type Props = DefaultProps

const RowItem: React.FC<Props> = props => {
  const { item, selected } = props

  const onClick = () => {
    props.onClick(item)
  }
  const onDelete = () => {
    props.onDelete(item)
  }

  const isUsed = item.Status.toLocaleLowerCase() === 'used'
  const Status = isUsed ? (
    <RedText>มีการใช้งานแล้ว</RedText>
  ) : (
    <GreenText>แก้ไขได้</GreenText>
  )

  const img =
    item.ConfigList &&
    item.ConfigList[0] &&
    item.ConfigList[0].ImageFloorPlan &&
    item.ConfigList[0].ImageFloorPlan.FileURL

  return (
    <RowModel>
      <TableRow
        selected={selected}
        minHeight={60}
        spaceBetweenRow={8}
        paddingLeft={24}
      >
        <Col span={7}>
          <Name onClick={onClick}>
            {img ? (
              <img className="img" src={img} alt="" />
            ) : (
              <div className="img" />
            )}
            {item.ModelCode}
          </Name>
        </Col>
        <Col span={4}>{item.ModelName}</Col>
        <Col span={4}>{item.UnitUsedCounter}</Col>

        <Col span={3}>
          {item.ConfigAreaList && item.ConfigAreaList.length > 0 && (
            <Icon>
              <CheckedIcon size={20} color="#8b99a7" />
            </Icon>
          )}
        </Col>
        <Col span={3}>{Status}</Col>
        <Col span={3}>
          <Icon onClick={onClick}>
            <EditIcon size={20} color="#8b99a7" />
          </Icon>
          {!isUsed && (
            <Icon onClick={onDelete}>
              <TrashIcon size={20} color="#8b99a7" />
            </Icon>
          )}
        </Col>
      </TableRow>
    </RowModel>
  )
}

const RowModel = styled.div`
  cursor: pointer;
`

const Name = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  color: #000;
  display: flex;
  align-items: center;
  .img {
    height: 40px;
    width: 40px;
    margin-right: 22px;
  }
`
const Icon = styled.div`
  float: left;
  margin-right: 20px;
  margin-top: 5px;
  cursor: pointer;
`

const GreenText = styled.div`
  color: #5bc79e;
`

const RedText = styled.div`
  color: #d0494c;
`

export default RowItem
