import React, { useState } from 'react'
import styled from 'styled-components'
import { Collapse, Row, Col } from 'antd'
import { CheckListStatus } from 'features/cmpj/activity/types'
import InspectionItem from './InspectionItem'
import InspectionSubItem from './InspectionSubItem'
import StatusIcon from './StatusIcon'
import {
  InspectionList,
  InspectionSubData,
  InspectionData,
} from 'features/types/checklist'

type Props = {
  index: number
  item: InspectionList
  onChange: (val: InspectionList, index: number) => void
  remarkStatus?: any[]
}

const CheckListGroup: React.FC<Props> = (props: Props) => {
  const { item, remarkStatus } = props

  const isStatusCheckedAll = () => {
    let isChecked = true
    if (item.Data) {
      for (const parent of item.Data) {
        if (parent.Status !== CheckListStatus.PASS) {
          isChecked = false
          break
        }
        for (const subItem of parent.Data) {
          if (subItem.Status !== CheckListStatus.PASS) {
            isChecked = false
            break
          }
        }
      }
    }

    return isChecked
  }

  const onChangeGroupItem = () => {
    const isChecked = isStatusCheckedAll()
    const newItem = setStatus(
      isChecked ? CheckListStatus.NOTCHECK : CheckListStatus.PASS
    )

    props.onChange(newItem, props.index)
  }

  const setStatus = (status: string) => {
    if (item.Data) {
      for (const parent of item.Data) {
        parent.Status = status
        for (const subItem of parent.Data) {
          subItem.Status = status
        }
      }
    }
    return item
  }

  const onChangeItem = (val: InspectionData, index: number) => {
    item.Data[index] = val
    props.onChange(item, props.index)
  }

  const onChangeSubItem = (
    val: InspectionSubData,
    parentIndex: number,
    index: number
  ) => {
    const parent = item.Data[parentIndex]
    let subItem = parent.Data[index]
    subItem = val
    props.onChange(item, props.index)
  }

  const content =
    item.Data &&
    item.Data.map((it: InspectionData, pindex) => {
      const content = (
        <InspectionItem
          index={pindex}
          item={it}
          onChange={val => onChangeItem(val, pindex)}
          remarkStatus={remarkStatus}
        />
      )
      const subContent = it.Data.map((subitem, index) => {
        return (
          <InspectionSubItem
            key={index}
            index={index}
            item={subitem}
            onChange={val => onChangeSubItem(val, pindex, index)}
            remarkStatus={remarkStatus}
          />
        )
      })

      return (
        <div key={pindex}>
          {content}
          {subContent}
        </div>
      )
    })

  const isCheckedAll = isStatusCheckedAll()
  return (
    <ChecklistBox>
      <ChecklistHeader>
        <Row type="flex" justify="space-between">
          <Col>
            {item.Seq}. {item.InspectionCategory}
          </Col>
          <Col onClick={onChangeGroupItem}>
            <StatusIcon status={isCheckedAll ? CheckListStatus.PASS : ''} />
          </Col>
        </Row>
      </ChecklistHeader>
      {content}
    </ChecklistBox>
  )
}

const ChecklistBox = styled.div`
  background-color: #fff;
  border-radius: 5px;
  border: 1px solod #eeeff2;
  margin-bottom: 24px;
  .checklist {
    .ant-collapse-item {
      border: none;
    }
    border: none;
  }
  .ant-collapse-content-box {
    padding: 0 !important;
  }

  .ant-collapse-item-active {
    .check-list-item {
      border-bottom: none;
    }
  }

  .subitem {
    .remark-box {
      padding-left: 24px;
    }
  }
`

const ChecklistHeader = styled.div`
  padding: 12px 24px;
  background-color: #003168;
  font-family: 'DB Helvethaica X 75 Bd';
  color: #fff;
  font-size: 20px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`

export default CheckListGroup
