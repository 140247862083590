import { createStandardAction, createAsyncAction } from 'typesafe-actions'
import {
  GetCheckListReq,
  GetCheckListApi,
  UpdateCheckListReq,
  UpdateCheckListApi,
} from './types'

export const setCheckList = createStandardAction(
  'document/master/SET_CHECK_LIST'
)<any>()

export const getCheckListAction = createAsyncAction(
  'document/master/GET_CHECK_LIST_REQ',
  'document/master/GET_CHECK_LIST_RESP',
  'document/master/GET_CHECK_LIST_FAIL'
)<GetCheckListReq, GetCheckListApi, Error>()

export const updateCheckListAction = createAsyncAction(
  'document/master/UPDATE_CHECK_LIST_REQ',
  'document/master/UPDATE_CHECK_LIST_RESP',
  'document/master/UPDATE_CHECK_LIST_FAIL'
)<UpdateCheckListReq, UpdateCheckListApi, Error>()
