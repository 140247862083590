import React from 'react'
import { Row, Col } from 'antd'
import styled from 'styled-components'
import { CheckedIcon, UncheckedIcon } from '../Icon'

interface Props {
  panel?: any
  title: string
  value: boolean
  isActive?: boolean
  showArrow?: boolean
  onChange(item: any, value: boolean, parent?: any): void
  onToggle?: (item: any, value: boolean) => void
  parent?: any
  item?: any
}

const CheckAsOptions: React.FunctionComponent<Props> = props => {
  const {
    title,
    value,
    parent,
    item,
    isActive = false,
    showArrow = false,
  } = props

  const onChange = (evt: any) => {
    evt.preventDefault()
    props.onChange(item, !value, parent)
  }

  const onToggle = (evt: any) => {
    evt.preventDefault()
    if (props.onToggle) {
      props.onToggle(item, !isActive)
    }
  }

  let arrow
  if (showArrow) {
    let img = isActive ? '/img/arrow-up.png' : '/img/arrow-down.svg'
    arrow = (
      <span className="chk-icon" onClick={onToggle}>
        <img src={img} alt="collapse categories" width={16} height={16} />
      </span>
    )
  }

  const checkIcon = value ? <CheckedIcon /> : <UncheckedIcon />
  return (
    <Row
      className="checkbox-container"
      type="flex"
      justify="space-between"
      align="middle"
    >
      <Col span={22}>
        {/* <TextItem onClick={onChange}> */}
        <Row align="top" onClick={onChange} type="flex">
          <Col>{checkIcon}</Col>
          <Col span={20} style={{ marginLeft: '16px' }}>
            <span>{title}</span>
          </Col>
        </Row>
        {/* </TextItem> */}
      </Col>
      <Col>{arrow}</Col>
    </Row>
  )
}

const TextItem = styled.label`
  -khtml-user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;

  :hover,
  input {
    cursor: pointer;
  }

  img {
    vertical-align: initial;
    // margin-top: 2px;
  }
`

export default CheckAsOptions
