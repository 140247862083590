import { createStandardAction, createAsyncAction } from 'typesafe-actions'
import {
  GetReportReq,
  GetReportResp,
  GetCheckListReq,
  GetCheckListResp,
  DefectTypeReq,
  DefectItem,
  SendDefectReq,
  ZoneReport,
  SetDefectReq,
  CommonResp,
  GetSelectPinReq,
  GetSelectPinResp,
  UpdateSelectPinReq,
  GetMainconReq,
  GetMainconResp,
} from './types'

export const getReportAction = createAsyncAction(
  'report/zone/FETCH_REPORT_REQUEST',
  'report/zone/FETCH_REPORT_SUCCESS',
  'report/zone/FETCH_REPORT_FAILURE'
)<GetReportReq, GetReportResp, any>()

export const getCheckListAction = createAsyncAction(
  'report/zone/GET_CHECKLIST_QC_REQ',
  'report/zone/GET_CHECKLIST_QC_RESP',
  'report/zone/GET_CHECKLIST_QC_FAIL'
)<GetCheckListReq, GetCheckListResp, any>()

export const resetDefect = createStandardAction(
  'report/zone/RESET_DEFECT_ACTION'
)<void>()

export const updateDefect = createStandardAction(
  'report/zone/UPDATE_DEFECT_ACTION'
)<DefectItem>()

export const createDefectAction = createAsyncAction(
  'report/zone/CREATE_DEFECT_REQ',
  'report/zone/CREATE_DEFECT_RESP',
  'report/zone/CREATE_DEFECT_FAIL'
)<DefectTypeReq, CommonResp, any>()

export const updateDefectAction = createAsyncAction(
  'report/zone/UPDATE_DEFECT_REQ',
  'report/zone/UPDATE_DEFECT_RESP',
  'report/zone/UPDATE_DEFECT_FAIL'
)<DefectTypeReq, CommonResp, any>()

export const setDefectAction = createAsyncAction(
  'report/zone/SET_DEFECT_REQ',
  'report/zone/SET_DEFECT_RESP',
  'report/unit/SET_DEFECT_FAIL'
)<SetDefectReq, CommonResp, any>()

export const mapDefectData = createStandardAction(
  'report/zone/MAP_DEFECT_ACTION'
)<DefectItem>()

export const submitDefectReq = createStandardAction(
  'report/zone/SUBMIT_REQUEST'
)<SendDefectReq>()

export const updateReport = createStandardAction(
  'report/zone/UPDATE_REPORT_ACTION'
)<ZoneReport>()

export const resetEvent = createStandardAction(
  'report/zone/RESET_EVENT_ACTION'
)<void>()

export const getSelectedPinAction = createAsyncAction(
  'report/zone/FETCH_SELECTED_PIN_REQUEST',
  'report/zone/FETCH_SELECTED_PIN_SUCCESS',
  'report/zone/FETCH_SELECTED_PIN_FAILURE'
)<GetSelectPinReq, GetSelectPinResp, any>()

export const updateSelectedPintAction = createAsyncAction(
  'report/zone/UPDATE_SELECTED_PIN_REQ',
  'report/zone/UPDATE_SELECTED_PIN_RESP',
  'report/zone/UPDATE_SELECTED_PIN_FAIL'
)<UpdateSelectPinReq, CommonResp, any>()

export const getMainconAction = createAsyncAction(
  'report/zone/FETCH_MAINCON_REQUEST',
  'report/zone/FETCH_MAINCON_SUCCESS',
  'report/zone/FETCH_MAINCON_FAILURE'
)<GetMainconReq, GetMainconResp, any>()
