import React, { useState } from 'react'
import { Modal, Button, Row, Col, Select } from 'antd'
import styled from 'styled-components'
import { AddIcon } from '../Icon'

interface Props {
  onClick?: () => void
  style?: any
}

const AddList: React.FC<Props> = ({ onClick, style = {} }) => {
  return (
    <AddListItem onClick={onClick} style={style}>
      <AddIcon color="#003168" size={16} />
      <span>เพิ่มรายการ</span>
    </AddListItem>
  )
}

const AddListItem = styled.div`
  padding: 13px 0;
  margin: 0 16px;
  font-size: 20px;
  color: #003168;
  font-family: 'DB Helvethaica X 75 Bd';
  svg {
    position: relative;
    top: 2px;
  }
  span {
    margin-left: 7px;
  }
  &:hover {
    cursor: pointer;
  }
`

export default AddList
