import React from 'react'
import styled from 'styled-components'

interface Props {
  num: number
  isPass: boolean
}

const ScoreStatus: React.FC<Props> = props => {
  const { num, isPass } = props

  return isPass ? (
    <PassScore> {num} ข้อ ผ่าน</PassScore>
  ) : (
    <FailScore> {num} ข้อ ไม่ผ่าน</FailScore>
  )
}

const Score: any = styled.div`
  font-family: 'DB Helvethaica X 55 Regular';
  font-size: 18px;
  line-height: 1;
`

const PassScore = styled(Score)`
  color: #6bd5ae;
`

const FailScore = styled(Score)`
  color: #d0494c;
`

export default ScoreStatus
