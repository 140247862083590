import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, connect } from 'react-redux'
import RootState from 'typings/RootState'
import styled from 'styled-components'
import { Row, Col, Drawer } from 'antd'
import { RouteComponentProps } from 'react-router-dom'
import { BreadcrumbLink } from 'Components/Shared/BreadCrumb/BreadCrumb'
import SubnavBar from 'Components/Shared/SubnavBar'
import { HeaderRow } from 'Components/Shared/Table'
import PrimaryButton from 'Components/Shared/Button/PrimaryButton'
import Pagination from 'Components/Shared/Pagination'
import { DropdownList } from 'Components/Shared/Form'
import LoadingView from 'Components/Shared/LoadingView'

import { changeSelectedProjectAction } from 'features/selectedProject/selectedProject.actions'
import { fetchProjects } from 'features/project/project.api'
import {
  serviceTokenSelector,
  userEmailSelector,
} from 'features/sensitive/sensitive.selector'
import {
  selectedProjectCodeSelector,
  projectsByIdSelector,
  isSubmittingSelector,
} from 'features/project/project.selector'
import {
  selectModelList,
  selectModelSelected,
  selectEvent,
  selectImportEvent,
} from 'features/admin/model/selector'

import {
  getModelList,
  getItemForModel,
  ImportModel,
} from 'features/admin/model/api'
import {
  GetModelListReq,
  ModelListItem,
  ModelImportReq,
  UnitModel,
} from 'features/admin/model/types'
import {
  setSelectedItem,
  reset,
  deleteModelReq,
} from 'features/admin/model/actions'
import Menu from '../../../MenuAdmin'
import ModelDetail from '../Detail'
import RowItem from './RowItem'
import { MasterDataReqByProject } from 'features/masterData/types'
import { AdminProject, AlertError, EventModal } from 'Components/Shared'
import DeleteModal, {
  DeleteModalHandles,
} from 'Components/Shared/Modal/DeleteModal'
import { ProjectListItem } from 'features/project/project.types'
import { toCheckList } from 'importer/model'
import ImportBtn from 'Components/Shared/Table/ImportBtn'

const breadcrumbs: BreadcrumbLink[] = [
  {
    url: '/dashboard',
    title: 'Home',
    icon: '/home.png',
    active: true,
    showTitle: false,
  },
  {
    url: '/model',
    title: 'Model',
    active: false,
    showTitle: true,
  },
]

type Props = ReduxProps & {
  homepage: boolean
} & DispatchProps &
  RouteComponentProps

const Modellist: React.ComponentType<Props> = props => {
  const [enableModal, setEnableModal] = useState(false)
  const modalRef = useRef<DeleteModalHandles>(null)
  const [err, setErr] = useState(undefined)
  const [pagination, setPagination] = useState({
    Page: 1,
    RowPerPage: 10,
  } as any)

  const {
    homepage,
    token,
    UserEmail,
    data,
    event,
    selectedItem,
    ProjectCode,
    projectsById,
    isProjectLoading,
    importEvent,
  } = props

  const onCloseDrawer = () => {
    dispatch(setSelectedItem(undefined))
  }

  const onOpenDrawer = (item: ModelListItem) => {
    dispatch(setSelectedItem(item))
  }

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchProjects({ email: UserEmail, token }))
  }, [UserEmail, token])

  useEffect(() => {
    getUnitList()
  }, [UserEmail, ProjectCode])

  useEffect(() => {
    onGetModelList()
  }, [ProjectCode, UserEmail, pagination, getModelList, dispatch])

  const onGetModelList = () => {
    const modelListReq: GetModelListReq = {
      ProjectCode,
      UserEmail,
      Page: pagination.Page,
      RowPerPage: pagination.RowPerPage,
    }

    dispatch(getModelList(modelListReq, token))
  }

  const getUnitList = () => {
    const mappingModelReq: MasterDataReqByProject = {
      UserEmail,
      ProjectCode,
    }
    dispatch(getItemForModel(mappingModelReq, token))
  }

  const onProjectCode = (code: string) => {
    dispatch(changeSelectedProjectAction(code))
  }

  const onCreate = () => {
    dispatch(reset({}))
  }

  const showDeleteModal = (item: ModelListItem) => {
    if (modalRef.current) {
      modalRef.current.showModal('คุณยืนยันต้องการลบ model นี้', 0, item)
    }
  }

  const onDelete = (_: number, item: ModelListItem) => {
    setEnableModal(true)
    dispatch(
      deleteModelReq.request({
        de: {
          UserEmail,
          ProjectCode,
          ModelID: item.ModelID,
        },
        pagination: {
          ProjectCode,
          UserEmail,
          Page: pagination.Page,
          RowPerPage: pagination.RowPerPage,
        },
      })
    )
  }

  const callImportFileAPI = (unitList: UnitModel[]) => {
    const req: ModelImportReq = {
      UserEmail,
      ProjectCode,
      UnitList: unitList,
    }
    dispatch(ImportModel(req, token))
  }

  const onImportFile = (data: any) => {
    setEnableModal(true)
    try {
      const unitList = toCheckList(data)
      callImportFileAPI(unitList)
    } catch (e) {
      setErr(e.message)
    }
  }

  const onSaveDetail = () => {
    setEnableModal(true)
  }

  const onImportSuccess = () => {
    setEnableModal(false)
    onGetModelList()
    getUnitList()
  }

  const content = data.List.map((item: ModelListItem, index: number) => {
    return (
      <RowItem
        key={index}
        item={item}
        selected={selectedItem ? item.ModelID === selectedItem.ModelID : false}
        onClick={onOpenDrawer}
        onDelete={showDeleteModal}
      />
    )
  })

  const header = (
    <Row type="flex" justify="space-between" align="middle">
      <Col>
        <Header>Model</Header>
      </Col>
      <Col>
        <MainSection>
          <Row>
            <Col span={12} style={{ width: '360px' }}>
              <AdminProject
                projectList={projectsById}
                onSelectProject={onProjectCode}
                activeProject={ProjectCode}
              />
            </Col>
            <Col style={{ float: 'right' }}>
              <ButtonSection>
                <ImportBtn
                  text="Import model"
                  onChange={onImportFile}
                  disabled={ProjectCode === ''}
                />
              </ButtonSection>
              <ButtonSection>
                <PrimaryButton
                  disabled={ProjectCode === ''}
                  text="เพิ่ม Model"
                  onClick={onCreate}
                />
              </ButtonSection>
            </Col>
          </Row>
        </MainSection>
      </Col>
    </Row>
  )

  return (
    <>
      <AlertError
        text={event.SubmitError}
        isShow={!!event.SubmitError && enableModal}
      />
      <LoadingView isShow={isProjectLoading || event.IsLoading} />
      <EventModal
        name="import"
        event={[importEvent]}
        enable={enableModal}
        successCalback={onImportSuccess}
      />
      <SubnavBar breadcrumbs={breadcrumbs} hideSearch={true} />
      <Menu selected={'model'}>
        <MainPage>
          {header}
          <HeaderRow onSort={() => {}} sorting={[]} items={columnsName} />
          {content}
          <Pagination
            currentPage={pagination.Page}
            totalRows={data.Total}
            perPage={pagination.RowPerPage}
            onChangeNumber={(page: number) => {
              pagination.RowPerPage = page
              setPagination({ ...pagination })
            }}
            onClickPage={(page: number) => {
              pagination.Page = page
              setPagination({ ...pagination })
            }}
          />
        </MainPage>
        <Drawer
          title={null}
          className="drawer-project"
          closable={false}
          width={780}
          visible={selectedItem !== undefined}
          bodyStyle={{ padding: 0 }}
        >
          {selectedItem && (
            <ModelDetail
              item={selectedItem}
              Token={token}
              UserEmail={UserEmail}
              ProjectCode={ProjectCode}
              pagination={pagination}
              onClose={onCloseDrawer}
              onSaveDetail={onSaveDetail}
            />
          )}
        </Drawer>
      </Menu>
      <DeleteModal ref={modalRef} onOk={onDelete} />
    </>
  )
}

const MainPage = styled.div`
  padding: 40px 45px;
  width: 100%;
`

const MainSection = styled.div`
  height: 50px;
  margin-bottom: 35px;
`

const ButtonSection = styled.div`
  float: left;
  margin-left: 24px;
  width: 160px;
`

const Header = styled.div`
  margin-bottom: 27px;
  font-size: 28px;
  line-height: 1.07;
  font-family: 'DB Helvethaica X 75 Bd';
  color: #000;
`

const columnsName = [
  {
    title: 'Code',
    span: 7,
    key: 'Code',
    showSort: false,
  },
  {
    title: 'Name',
    span: 4,
    key: 'Name',
    showSort: false,
  },
  {
    title: 'จำนวนรายการที่ผูก',
    span: 4,
    key: 'Count',
    showSort: false,
  },
  {
    title: 'พื้นที่',
    span: 3,
    key: 'Area',
    showSort: false,
  },
  {
    title: 'สถานะ',
    span: 3,
    key: 'Status',
    showSort: false,
  },
  {
    title: 'Action',
    span: 3,
    key: 'Action',
    showSort: false,
  },
]

const mapStateToProps = (state: RootState) => {
  return {
    data: selectModelList(state),
    selectedItem: selectModelSelected(state),
    event: selectEvent(state),
    token: serviceTokenSelector(state),
    UserEmail: userEmailSelector(state),
    ProjectCode: selectedProjectCodeSelector(state),
    projectsById: projectsByIdSelector(state),
    isProjectLoading: isSubmittingSelector(state),
    importEvent: selectImportEvent(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>
const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(Modellist)
