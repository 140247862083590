import React, { useState, useRef, useEffect } from 'react'
import memoize from 'memoize-one'
import styled from 'styled-components'

import ContentBox from 'Components/Shared/Card/ContentBox'
import { AddList, EditDeleteButton } from 'Components/Shared/Button'
import { Row } from 'antd'
import ProjectModal, {
  ProjectModalHandles,
} from 'Components/AdminQC/CheckUnit/Detail/Setting/Modal/ProjectModal'
import { ProjectListItem } from 'features/project/project.types'
import DeleteCheckListModal, {
  DeleteModalHandles,
} from 'Components/Shared/Modal/DeleteModal'
import { CommissionDetail, Project } from 'features/admin/commission/types'

type Props = {
  projects: ProjectListItem[]
  selectProject: (item: ProjectListItem) => void
  deleteProject: (item: ProjectListItem) => void
  projectCode: string
  commissionDetail: CommissionDetail
}

const filterProject = memoize((list, filterText) => {
  return list.filter((item: Project) => {
    if (filterText === '') return true
    const filterLower = filterText.toLocaleLowerCase()
    return (
      item.ProjectCode.toLocaleLowerCase().includes(filterLower) ||
      item.ProjectNameTH.toLocaleLowerCase().includes(filterLower)
    )
  })
})

const ProjectsBox: React.ComponentType<Props> = props => {
  const modalRef = useRef<ProjectModalHandles>(null)
  const modalDeleteRef = useRef<DeleteModalHandles>(null)

  const [filterText, setfilterText] = useState<string>('')
  const [selectProjets, setSelectProjets] = useState<ProjectListItem[]>([])

  const filterSelectProject: ProjectListItem[] = filterProject(
    selectProjets,
    filterText
  )

  useEffect(() => {
    setSelectProjets(props.commissionDetail.ProjectList)
  }, [props.commissionDetail.ProjectList])

  const showSelectProjectModal = () => {
    if (modalRef.current) {
      modalRef.current.showModal()
    }
  }

  function onSearch(text: string) {
    setfilterText(text)
  }

  const addProject = (item: ProjectListItem) => {
    const foundProject = props.commissionDetail.ProjectList.find(
      p => p.ProjectCode === item.ProjectCode
    )
    if (!foundProject) {
      setSelectProjets([...selectProjets, item])
      props.selectProject(item)
      props.commissionDetail.ProjectList.push(item)
    }
  }

  const selectProject = (item: ProjectListItem) => {
    props.selectProject(item)
  }

  const showDeleteModal = (it: ProjectListItem) => {
    if (modalDeleteRef.current) {
      modalDeleteRef.current.showModal('คุณยืนยันต้องการลบโครงการนี้', 0, it)
    }
  }

  const onDeleteProject = (_: number, obj: ProjectListItem) => {
    setSelectProjets(
      selectProjets.filter(p => p.ProjectCode !== obj.ProjectCode)
    )
    props.deleteProject(obj)
  }

  return (
    <ContentBox
      title={`โครงการที่ใช้งาน (${selectProjets.length})`}
      emptyText="กรุณาเลือก “ประเภทโครงการ”"
      onSearch={onSearch}
    >
      <ListSection>
        {filterSelectProject.map((item: ProjectListItem) => {
          return (
            <List
              key={item.ProjectCode}
              className={
                item.ProjectCode === props.projectCode ? 'selected' : ''
              }
            >
              <ListText>
                <Row type="flex" justify="space-between" className="row-item">
                  <div
                    className="text-projectcode"
                    onClick={() => selectProject(item)}
                  >
                    <span>{`${item.ProjectCode} - ${item.ProjectNameTH}`}</span>
                  </div>
                  <EditDeleteButton
                    showDeleteModal={() => showDeleteModal(item)}
                  />
                </Row>
              </ListText>
            </List>
          )
        })}
        <AddList onClick={showSelectProjectModal} />
      </ListSection>

      <ProjectModal
        ref={modalRef}
        projects={props.projects}
        onOk={addProject}
      />
      <DeleteCheckListModal ref={modalDeleteRef} onOk={onDeleteProject} />
    </ContentBox>
  )
}

const ListSection = styled.div`
  margin-top: 16px;
  height: 256px;
  overflow: auto;
  padding: 0 8px;
  padding-bottom: 20px;
`

const List = styled.div`
  font-size: 20px;
  width: 100%;
  &.selected {
    border-radius: 5px;
    border: solid 1px #003168;
  }
`

const ListText = styled.div`
  border-bottom: 1px solid #e8ebf0;
  height: 100%;
  margin: 0 16px;
  position: relative;

  .text-projectcode {
    padding: 13px 0;
    width: 100%;
  }
  .icon-action {
    position: absolute;
    right: 0px;
    top: 5px;
    padding: 10px 0;
  }
`

export default ProjectsBox
