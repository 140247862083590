import React from 'react'
import PopupMenu from 'Components/Shared/Filters/PopupMenu'
import Content from 'Components/Shared/Filters/ZoneContent'

type Props = {
  onFilterChange: (filter: any) => void
  filter: any
  zoneList: string[]
}

const ZoneFilter: React.FunctionComponent<Props> = props => {
  const { onFilterChange, filter, zoneList } = props

  return (
    <PopupMenu
      label={'โซน'}
      content={
        <Content
          zones={zoneList}
          filter={filter.ZoneList}
          onChange={data => {
            filter.ZoneList = data
            onFilterChange(filter)
          }}
        />
      }
      isActive={filter.ZoneList.length > 0}
    />
  )
}

export default ZoneFilter
