import React, { useState, useEffect } from 'react'
import { useDispatch, connect } from 'react-redux'
import RootState from 'typings/RootState'
import styled from 'styled-components'
import { RouteComponentProps } from 'react-router-dom'
import { BreadcrumbLink } from 'Components/Shared/BreadCrumb/BreadCrumb'
import SubnavBar from 'Components/Shared/SubnavBar'
import { HeaderRow } from 'Components/Shared/Table'
import Pagination from 'Components/Shared/Pagination'

import { PaginationType } from 'features/types/common'

import {
  userEmailSelector,
  serviceTokenSelector,
} from 'features/sensitive/sensitive.selector'

import {
  getUnitByQCTypeList,
  exportUnitByQCTypeList,
} from 'features/adminReport/unit/api'
import { sortingQcTypeTask } from 'features/adminReport/unit/actions'
import {
  GetUnitByQCTypeListReq,
  UnitByQCTypeItem,
} from 'features/adminReport/unit/types'

import Menu from 'Components/MenuAdmin/AdminQCMenu'

import RowItem from './RowItem'
import { EventModal } from 'Components/Shared'
import Filter from 'Components/AdminQC/Report/Filter/UnitByQCTypeFilter'
import { toDateText } from 'utils/datetime'
import ReportUnitTab from 'Components/Shared/Tab/ReportUnitTab'
import { initPagination } from 'utils/helper'

const breadcrumbs: BreadcrumbLink[] = [
  {
    url: '/dashboard',
    title: 'Home',
    icon: '/home.png',
    active: true,
    showTitle: false,
  },
  {
    url: '/report/unit',
    title: 'รายงานตรวจมาตรฐาน (ยูนิต/แปลง)',
    active: false,
    showTitle: true,
  },
]

type Props = ReduxProps & DispatchProps & RouteComponentProps

const ReportCommissionList: React.ComponentType<Props> = ({
  UserEmail,
  token,

  dataList,
  event,
  history,
  filter: currentFilter,
  initFilter,
  sorting,
  dataExport,
  exportEvent,
}) => {
  const dispatch = useDispatch()
  const [enableModal, setEnableModal] = useState(false)
  const [pagination, setPagination] = useState<PaginationType>(initPagination())

  useEffect(() => {
    onFindReport()
  }, [pagination])

  const onFindReport = (
    isClearFilter: boolean = false,
    isExport: boolean = false
  ) => {
    setEnableModal(true)
    const filter = isClearFilter ? initFilter : currentFilter
    const req: GetUnitByQCTypeListReq = {
      UserEmail,
      ProjectTypeID: filter.ProjectTypeID,
      StartDate: toDateText(filter.Date.StartDate || ''),
      EndDate: toDateText(filter.Date.FinishDate || ''),
      ProjectCode: filter.ProjectCode,
      UnitList: filter.UnitList,
      QCTypeList: isClearFilter ? [] : currentFilter.QCTypeList,
      supplier: isClearFilter ? '' : currentFilter.Supplier,
      Page: isExport ? 1 : pagination.Page,
      RowPerPage: isExport ? dataList.Total : pagination.RowPerPage,
      ExportType: isExport ? 'raw' : '',
    }
    if (isExport) {
      dispatch(exportUnitByQCTypeList(token, req))
    } else {
      dispatch(getUnitByQCTypeList(token, req))
    }
  }

  const onSuccess = () => {
    setEnableModal(false)
  }

  const onSort = (name: string) => {
    dispatch(sortingQcTypeTask({ name }))
  }

  const content = dataList.List.map((item: UnitByQCTypeItem, index: number) => {
    return <RowItem key={index} item={item} />
  })

  return (
    <>
      <SubnavBar breadcrumbs={breadcrumbs} hideSearch={true} />
      <EventModal
        name="report-commissoning"
        event={[event, exportEvent]}
        enable={enableModal}
        successCalback={onSuccess}
      />
      <Menu selected={'report.unit'}>
        <MainPage>
          <Header>รายงานตรวจงานมาตรฐาน (ยูนิต / แปลง)</Header>
          <ReportUnitTab selected="ByQC" />
          <Filter
            onSearch={(isClear: boolean = false) => {
              pagination.Page = 1
              setPagination({ ...pagination })
              onFindReport(isClear)
            }}
            isDownLoading={exportEvent.IsLoading}
            onExport={() => onFindReport(false, true)}
            exportData={dataExport}
          />
          <HeaderRow
            onSort={name => onSort(name)}
            sorting={sorting}
            items={columnsName}
          />
          {content}
          <Pagination
            currentPage={pagination.Page}
            totalRows={dataList.Total}
            perPage={pagination.RowPerPage}
            onChangeNumber={(page: number) => {
              pagination.RowPerPage = page
              setPagination({ ...pagination })
            }}
            onClickPage={(page: number) => {
              pagination.Page = page
              setPagination({ ...pagination })
            }}
          />
        </MainPage>
      </Menu>
    </>
  )
}

const MainPage = styled.div`
  padding: 40px 45px;
  width: 100%;
`

const Header = styled.div`
  margin-bottom: 27px;
  font-size: 28px;
  line-height: 1.07;
  font-family: 'DB Helvethaica X 75 Bd';
  color: #000;
`

const columnsName = [
  {
    title: 'หมวด Q',
    span: 2,
    key: 'QCTypeName',
  },
  {
    title: 'หมวดหลัก',
    span: 3,
    key: 'JobTypeName',
  },
  {
    title: 'หมวดรอง',
    span: 2,
    key: 'SubJobTypeName',
  },
  {
    title: 'รายละเอียด',
    span: 2,
    key: 'DetailName',
  },
  {
    title: 'คะแนนเต็ม',
    span: 2,
    key: 'Score',
  },
  {
    title: 'จำนวนหมุด',
    span: 2,
    key: 'DefectCounter',
  },
  {
    title: 'คะแนนที่หัก',
    span: 2,
    key: 'TotalScore',
  },
  {
    title: 'คิดเป็นเปอร์เซนต์',
    span: 2,
    key: 'PercentOfDefect',
  },
  {
    title: 'โครงการ',
    span: 2,
    key: 'ProjectCode',
  },
  {
    title: 'ประเภทผู้รับเหมา',
    span: 2,
    key: 'IsSupplier',
  },
  {
    title: 'ยูนิต',
    span: 2,
    key: 'Unit',
  },
]

const mapStateToProps = (state: RootState) => {
  return {
    dataList: state.adminReportUnit.UnitByQCTypeList,
    event: state.adminReportUnit.QCTypeEvent,
    filter: state.adminReportUnit.QCTypeFilter,
    initFilter: state.adminReportUnit.ClearFilter,
    sorting: state.adminReportUnit.sortingQCType,
    UserEmail: userEmailSelector(state),
    token: serviceTokenSelector(state),
    dataExport: state.adminReportUnit.UnitByQCTypeDataExport,
    exportEvent: state.adminReportUnit.UnitByQCTypeExportEvent,
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>
const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportCommissionList)
