import { createStandardAction, createAsyncAction } from 'typesafe-actions'
import {
  GetReportListReq,
  GetReportListResp,
  Filter,
  GetReportDetailReq,
  GetReportDetailResp,
  GetPercentPMRReq,
  GetPercentPMRResp,
  UpdatePercentPMRReq,
  PostApiResp,
  SaveCertReq,
  CancelCertReq,
} from './types'

export const getReportListAction = createAsyncAction(
  'admin/report/certificate/GET_LIST_REQ',
  'admin/report/certificate/GET_LIST_RESP',
  'admin/report/certificate/GET_LIST_FAIL'
)<GetReportListReq, GetReportListResp, any>()

export const getReportDetailAction = createAsyncAction(
  'admin/report/certificate/GET_DETAIL_REQ',
  'admin/report/certificate/GET_DETAIL_RESP',
  'admin/report/certificate/GET_DETAIL_FAIL'
)<GetReportDetailReq, GetReportDetailResp, any>()

export const getPercentPMRAction = createAsyncAction(
  'admin/report/certificate/GET_PMR_REQ',
  'admin/report/certificate/GET_PMR_RESP',
  'admin/report/certificate/GET_PMR_FAIL'
)<GetPercentPMRReq, GetPercentPMRResp, any>()

export const setPercentPMRAction = createAsyncAction(
  'admin/report/certificate/SET_PMR_REQ',
  'admin/report/certificate/SET_PMR_RESP',
  'admin/report/certificate/SET_PMR_FAIL'
)<UpdatePercentPMRReq, PostApiResp, any>()

export const updateFilter = createStandardAction(
  'admin/report/certificate/UPDATE_FILTER_ACTION'
)<Filter, void>()

export const clearFilter = createStandardAction(
  'admin/report/certificate/CLEAR_FILTER_ACTION'
)<void>()

export const sortingTask = createStandardAction(
  'admin/report/certificate/SORTING'
)<{
  name: string
  sort?: string
}>()

export const saveCertAction = createAsyncAction(
  'admin/report/certificate/SAVE_DATA_REQ',
  'admin/report/certificate/SAVE_DATA_RESP',
  'admin/report/certificate/SAVE_DATA_FAIL'
)<SaveCertReq, PostApiResp, any>()

export const cancelCertAction = createAsyncAction(
  'admin/report/certificate/CANCEL_CERT_REQ',
  'admin/report/certificate/CANCEL_CERT_RESP',
  'admin/report/certificate/CANCEL_CERT_FAIL'
)<CancelCertReq, PostApiResp, any>()

export const exportReportListAction = createAsyncAction(
  'admin/report/certificate/EXPORT_LIST_REQ',
  'admin/report/certificate/EXPORT_LIST_RESP',
  'admin/report/certificate/EXPORT_LIST_FAIL'
)<GetReportListReq, GetReportListResp, any>()
