import projectHelper from 'utils/projectHelper'
import { UserProfile } from 'features/sensitive/sensitive.types'

export const saveAccessToken = (accesstoken: string, email: string) => {
  localStorage.setItem('accesstoken', accesstoken)
  localStorage.setItem('email', email)
}

export const removeAccessToken = () => {
  localStorage.removeItem('accesstoken')
  localStorage.removeItem('email')
}

export const getAccessToken = (): {
  token: string | undefined
  email: string | undefined
} => {
  const token = localStorage.getItem('accesstoken') || undefined
  const email = localStorage.getItem('email') || undefined

  return { token, email }
}

export const saveTokenCode = (tokenCode: string) => {
  localStorage.setItem('tokencode', tokenCode)
}

export const getTokenCode = (): string => {
  return localStorage.getItem('tokencode') || ''
}

export const saveUserProfile = (profile: UserProfile) => {
  localStorage.setItem('userprofile', JSON.stringify(profile))
}

export const getUserProfile = (): UserProfile => {
  const profile = localStorage.getItem('userprofile') || '{}'

  return JSON.parse(profile)
}

export const getUserEmail = (): string => localStorage.getItem('email') || ''

export const clearStorage = () => {
  localStorage.clear()
  sessionStorage.clear()
  projectHelper.clear()
}

export default {
  saveAccessToken,
  getAccessToken,
  saveTokenCode,
  getTokenCode,
  clearStorage,
  getUserEmail,
  getUserProfile,
  saveUserProfile,
}
