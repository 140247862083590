import React, { useState } from 'react'
import styled from 'styled-components'
import moment from 'moment'

import {
  WorkType,
  CheckListDetail,
} from 'features/checklist/constructPlan/types'
import { BottomButton } from 'Components/Shared/Button'
import { InputText } from 'Components/Shared/Input'

interface Props {
  checkListDetail: CheckListDetail
  editingObj?: WorkType
  onSave: (item: WorkType) => void
  closeModal: () => void
  disabled: boolean
}

const WorkModal: React.FC<Props> = ({
  checkListDetail,
  onSave: onSaveProp,
  closeModal,
  editingObj,
  disabled,
}) => {
  const [error, seterror] = useState('')
  const [name, setName] = useState(editingObj ? editingObj.WorkTypeName : '')

  const onSave = () => {
    if (name) {
      const found = editingObj
        ? null
        : checkListDetail.WorkTypeList.find(w => w.WorkTypeName === name)
      if (found == null) {
        onSaveProp({
          WorkTypeID: editingObj ? editingObj.WorkTypeID : 0,
          WorkTypeName: name,
          IndexID: moment().unix(),
        })
      } else {
        seterror('ประเภทงานนี้ ได้มีอยู่แล้ว')
      }
    } else {
      seterror('กรุณาใส่ข้อมูลทั้งหมด')
    }
  }

  return (
    <div>
      <Box>
        <InputText
          prefix="ชื่อ"
          value={name}
          onChange={setName}
          placeholder="กรอกชื่อ"
          openWithFocus
          disabled={disabled}
        />
      </Box>
      <div style={{ height: 120 }} />
      {!disabled && (
        <BottomButton closeModal={closeModal} onSave={onSave} error={error} />
      )}
    </div>
  )
}

const Box = styled.div`
  padding-top: 12px;
`
export default WorkModal
