import { getType } from 'typesafe-actions'
import * as actions from './sensitive.actions'
import { SensitiveAction, SensitiveState, UserProfile } from './sensitive.types'
import produce from 'immer'
import parseErrorMessage from 'utils/parseErrorMessage'
import projectHelper from 'utils/projectHelper'
import { saveTokenCode, saveAccessToken, saveUserProfile } from 'utils/account'
import { acceptRoleList } from 'config'

const initialState: SensitiveState = {
  apiToken: '',
  accesstoken: undefined,
  userEmail: '',
  isSubmitting: false,
  submitError: '',
  submitSuccess: true,
  redirectToDashboard: false,
  userInfo: {} as UserProfile,
}

export default function(
  state: SensitiveState = initialState,
  action: SensitiveAction
): SensitiveState {
  return produce(state, draft => {
    switch (action.type) {
      case getType(actions.redirecToDashboard):
        draft.redirectToDashboard = action.payload
        break
      case getType(actions.changeAccesstoken):
        draft.accesstoken = action.payload
        break
      case getType(actions.changeUserEmail):
        draft.userEmail = action.payload
        break
      case getType(actions.updateServiceTokenError):
        draft.submitError = action.payload
        break
      case getType(actions.getUserProfileAsyncAction.request):
      case getType(actions.getServiceTokenAsyncAction.request):
        draft.isSubmitting = true
        break
      case getType(actions.getUserProfileAsyncAction.success):
      case getType(actions.getServiceTokenAsyncAction.success):
        const responseData: UserProfile = action.payload.ResponseData
        draft.userInfo = responseData
        draft.apiToken = responseData.TokenCode
        draft.userEmail = responseData.Email
        draft.submitSuccess = true
        draft.isSubmitting = false

        const modules: string[] = []
        const role = draft.userInfo.Roles.forEach(item => {
          if (acceptRoleList.includes(item.QCInsRoleCode)) {
            modules.push(item.QCInsRoleName)
          }
        })

        const codeModule = projectHelper.getModule() || ''
        draft.userInfo.RoleName = codeModule
        draft.userInfo.Modules = modules
        break

      case getType(actions.loginVendorAction.failure):
      case getType(actions.getUserProfileAsyncAction.failure):
      case getType(actions.getServiceTokenAsyncAction.failure):
        draft.submitError = parseErrorMessage(action.payload)
        draft.isSubmitting = false
        break

      // NOTE: This will be triggered whenever user logout
      case getType(actions.resetState):
        draft.apiToken = ''
        draft.accesstoken = undefined
        draft.userEmail = ''
        draft.isSubmitting = false
        draft.submitError = ''
        draft.submitSuccess = true
        draft.redirectToDashboard = false
        draft.userInfo = {} as UserProfile
        break

      case getType(actions.updateUserRule):
        draft.userInfo.RoleName = action.payload.Name
        draft.userInfo.ModuleID = action.payload.Id
        break

      case getType(actions.loginVendorAction.request):
        draft.isSubmitting = true
        draft.submitError = ''
        draft.submitSuccess = false
        break

      case getType(actions.loginVendorAction.success):
        draft.isSubmitting = false
        draft.submitSuccess = true

        const resp = action.payload.ResponseData
        draft.apiToken = resp.TokenCode
        draft.userEmail = resp.Username
        draft.userInfo = {
          DeptCode: '',
          DeptName: '',
          Email: resp.Username,
          EmpCode: '',
          FirstName: resp.VendorNameT || resp.VendorNameE,
          ImgUrl: '',
          LastName: '',
          Roles: resp.Roles,
          TitleName: '',
          TokenCode: resp.TokenCode,
          ModuleID: 0,
          RoleName: '',
          Modules: [],
        }

        saveAccessToken(draft.apiToken, resp.Username)
        saveTokenCode(draft.apiToken)
        saveUserProfile(draft.userInfo)
        break
      default:
        return state
    }
  })
}
