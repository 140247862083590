import React, { useState } from 'react'

import { ConstructRoadReport } from 'features/report/constructRoad/types'

import InProFooter from './InProFooter'

type Props = {
  report: ConstructRoadReport
  visible?: boolean
  onSign: () => void
  onSelectPin: () => void
}

const QcFooter: React.ComponentType<Props> = props => {
  const { report, visible = false, onSign, onSelectPin } = props
  const { State, Status } = report

  if (State === 'QC' && visible) {
    if (Status === 'INPRO')
      return (
        <InProFooter
          report={report}
          onSign={onSign}
          onSelectPin={onSelectPin}
        />
      )
  }

  return <div />
}

export default QcFooter
