import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { connect, useDispatch } from 'react-redux'
import { Row, Col } from 'antd'
import { RouteComponentProps, Link } from 'react-router-dom'
import SubnavBar from 'Components/Shared/SubnavBar'
import Loading from 'Components/Shared/Loading'
import { PrimaryButton } from 'Components/Shared/Button'
import { BreadcrumbLink } from 'Components/Shared/BreadCrumb/BreadCrumb'
import RootState from 'typings/RootState'
import {
  userEmailSelector,
  serviceTokenSelector,
} from 'features/sensitive/sensitive.selector'
import {
  selectConstructPlan,
  selectEvent,
} from 'features/qc/dashboard/selector'
import {
  GetConstructPlan,
  SendConstructPlanReportByQC,
  CancelConstructPlanByQC,
} from 'features/qc/dashboard/api'
import {
  GetConstructPlanReq,
  SitevisitInpro,
  SitevisitReturn,
  SendConstructPlanReportReq,
  ReturnConstructReq,
} from 'features/qc/dashboard/types'
import { translate } from 'i18n'
import TKeys from 'i18n/translationKey'
import Menu from '../../../Menu'
import ColumnHeader from '../../ColumnHeader'
import { NoCard, EventModal } from 'Components/Shared'
import ConstructPlanTab from 'Components/Shared/Tab/ConstructPlanTab'
import PageFooter from '../../PageFooter'
import MainPage from '../../MainPage'
import CreateRequest from './CreateRequest'
import InproCard from '../InproCard'
import ReturnCard from '../ReturnCard'

const breadcrumbs: BreadcrumbLink[] = [
  {
    url: '/',
    title: 'Home',
    icon: '/home.png',
    active: true,
    showTitle: false,
  },
  {
    url: '/',
    title: translate(TKeys.Breadcrumb.dashboard),
    active: false,
    showTitle: false,
  },
]

type Props = ReduxProps & DispatchProps & RouteComponentProps

const ConstructPlanDashboard: React.ComponentType<Props> = props => {
  const { data, event, token, UserEmail } = props
  const [sendReport, setSendReport] = useState(false)
  const [selectedList, setSelectedList] = useState([] as any[])
  const [enableModal, setEnableModal] = useState(false)
  const [showReqModal, setShowReqModal] = useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    setEnableModal(false)
  }, [data])

  useEffect(() => {
    getDashboard()
  }, [])

  const getDashboard = () => {
    const data: GetConstructPlanReq = {
      UserEmail: UserEmail,
      ProjectCode: [],
      CheckNumber: [],
    }
    dispatch(GetConstructPlan(token, data))
  }

  const onSubmitSendReport = () => {
    setEnableModal(true)
    const data: SendConstructPlanReportReq = {
      UserEmail: UserEmail,
      ProjectList: selectedList,
    }
    dispatch(SendConstructPlanReportByQC(token, data))
  }

  const onCancelRequest = (task: SitevisitInpro) => {
    setEnableModal(true)
    const data: ReturnConstructReq = {
      UserEmail: UserEmail,
      ProjectCode: task.ProjectCode,
      RequestID: task.RequestID,
    }
    dispatch(CancelConstructPlanByQC(token, data))
  }

  const onSuccess = () => {
    setEnableModal(false)
    setShowReqModal(false)
    setSendReport(false)
    getDashboard()
  }

  const onSendReport = () => {
    setSelectedList([])
    setSendReport(true)
  }

  const pushList = (task: SitevisitInpro) => {
    return {
      ProjectCode: task.ProjectCode,
      RequestID: task.RequestID,
      CheckNumber: task.CheckNumber,
    }
  }

  const isSelected = (task: SitevisitInpro) => {
    const result =
      selectedList &&
      selectedList.find(
        item =>
          item.ProjectCode === task.ProjectCode &&
          item.RequestID === task.RequestID
      )
    return result ? true : false
  }

  const remove = (task: SitevisitInpro) => {
    return selectedList.filter(
      item =>
        item.ProjectCode !== task.ProjectCode ||
        item.RequestID !== task.RequestID
    )
  }

  const onSelect = (task: SitevisitInpro) => {
    const selected = isSelected(task)
    let newList = selectedList as any[]
    if (selected) {
      newList = remove(task)
    } else {
      newList.push(pushList(task))
    }

    setSelectedList([...newList])
  }

  const onSelectAll = () => {
    let newList = [] as any[]
    data.InProgress.forEach((item: any) => {
      if (item.HasSign === 'Y') {
        newList.push(pushList(item))
      }
    })
    setSelectedList([...newList])
  }

  const loading = (
    <div style={{ textAlign: 'center' }}>
      <Loading />
    </div>
  )

  const getLink = (item: any) => {
    return `/construct-plan/report/${item.ProjectCode}/${item.RequestID}/${
      item.CheckNumber
    }`
  }

  const renderInprogressCard = (task: SitevisitInpro[]) => {
    return task.map((activity: SitevisitInpro, index) => {
      const card = (
        <InproCard
          key={index}
          isSendReport={sendReport}
          isSelected={isSelected(activity)}
          onSelectLists={task => onSelect(task)}
          task={activity}
          onCancel={task => onCancelRequest(task)}
        />
      )
      return sendReport ? (
        card
      ) : (
        <Link key={index} to={getLink(activity)}>
          {card}
        </Link>
      )
    })
  }

  const renderReturnCard = (task: SitevisitReturn[]) => {
    return task.map((activity: SitevisitReturn, index) => {
      return (
        <Link key={index} to={getLink(activity)}>
          <ReturnCard key={index} task={activity} />
        </Link>
      )
    })
  }

  const renderInprogressTask = (task: SitevisitInpro[], total: number) => {
    const card =
      task.length > 0 ? (
        renderInprogressCard(task)
      ) : (
        <NoCard text="ไม่มีรายการตรวจ กรุณา “สร้างคำขอ”" />
      )
    const content = event.IsLoading ? loading : card
    return (
      <Col span={12}>
        <MarginRight>
          <ColumnHeader heading={`กำลังดำเนินการ (${total})`} />
          {content}
        </MarginRight>
      </Col>
    )
  }

  const renderReturnTask = (task: SitevisitReturn[], total: number) => {
    const card =
      task.length > 0 ? renderReturnCard(task) : <NoCard text="ไม่มีรายการ" />
    const content = event.IsLoading ? loading : card
    return (
      <Col span={12}>
        <MarginLeft>
          <ColumnHeader heading={`CM/PJ ตอบกลับ (${total})`} />
          {content}
        </MarginLeft>
      </Col>
    )
  }

  const header = (
    <>
      <Btn>
        <PrimaryButton
          text="สร้างคำขอ"
          onClick={() => setShowReqModal(true)}
          disabled={sendReport}
        />
      </Btn>

      {sendReport ? (
        <Btn>
          <PrimaryButton
            text="ยกเลิก"
            onClick={() => setSendReport(false)}
            btnType="Cancel"
          />
        </Btn>
      ) : (
        <Btn>
          <PrimaryButton text="ส่งรายงาน" onClick={onSendReport} />
        </Btn>
      )}
    </>
  )

  const createRequestmodal = (
    <CreateRequest
      onClose={() => setShowReqModal(false)}
      onSuccess={onSuccess}
      visible={showReqModal}
    />
  )

  return (
    <>
      <Menu selected="sitVisit" />
      <SubnavBar breadcrumbs={breadcrumbs} hideSearch={true} />
      <ConstructPlanTab selected="ConstructPlan" />
      <EventModal
        event={[event]}
        enable={enableModal}
        successCalback={onSuccess}
      />
      {createRequestmodal}
      <Header>
        <Row type="flex" align="middle" justify="end">
          <Col>
            <LineBtn>{header}</LineBtn>
          </Col>
        </Row>
      </Header>
      <MainPage hasFooter={sendReport}>
        <Row type="flex" justify="space-between">
          {renderInprogressTask(data.InProgress, data.TotalInProgress)}
          {renderReturnTask(data.Return, data.TotalReturn)}
        </Row>
      </MainPage>
      {sendReport && (
        <PageFooter
          total={selectedList.length || 0}
          onSubmit={onSubmitSendReport}
          label="รายการในการส่งรายงาน"
          onSelectAll={onSelectAll}
          disabled={selectedList.length <= 0}
        />
      )}
    </>
  )
}

const Header = styled.div`
  padding: 0 120px;
  background-color: #f5f6fa;
  margin: 40px 0;
`

const LineBtn = styled.div`
  display: inline-flex;
  justify-content: flex-end;
`

const Btn = styled.div`
  width: 160px;
  margin-left: 16px;
`

const MarginRight = styled.div`
  margin-right: 16px;
`

const MarginLeft = styled.div`
  margin-left: 16px;
`

const mapStateToProps = (state: RootState) => {
  return {
    data: selectConstructPlan(state),
    event: selectEvent(state),
    token: serviceTokenSelector(state),
    UserEmail: userEmailSelector(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConstructPlanDashboard)
