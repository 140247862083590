import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

interface TabProps {
  link: string
  title: string
  isActive: boolean
}

interface Props {
  tabItem: TabProps[]
  btn?: any
}

const Tab: React.FunctionComponent<Props> = props => {
  const { tabItem, btn } = props

  const getClass = (isSelected: boolean) => {
    return isSelected ? 'selected' : ''
  }

  const renderTabItem = () => {
    return tabItem.map((item, index) => {
      return (
        <Link to={item.link} key={index}>
          <Item className={getClass(item.isActive)}>
            <span>{item.title}</span>
          </Item>
        </Link>
      )
    })
  }

  const btnRight = btn ? <BtnArea>{btn}</BtnArea> : undefined

  return (
    <MainView>
      <ItemList>
        {renderTabItem()}
        {btnRight}
      </ItemList>
    </MainView>
  )
}

const MainView = styled.div`
  margin-top: 14px;
  height: 51px;
  width: 100%;
`

const ItemList = styled.div`
  width: 100%;
  display: inline-flex;
  justify-content: center;

  .selected {
    border-bottom-width: 3px;
    color: #003168;
    font-family: 'DB Helvethaica X 75 Bd';
  }
`

const BtnArea = styled.div`
  position: absolute;
  right: 50px;
`

const Item = styled.div`
  color: #8b99a7;
  padding: 0 15px;
  height: 40px;
  font-size: 20px;
  align-items: center;
  display: flex;
  margin-right: 32px;
  border: 0px solid #003168;
  cursor: pointer;
  justify-content: center;
`

export default Tab
