import React, { useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import RootState from 'typings/RootState'
import { Layout, Row, Col } from 'antd'
import styled from 'styled-components'
import { saveAs } from 'file-saver'
import { translate } from 'i18n'
import TKeys from 'i18n/translationKey'

import {
  getOriginalActivity,
  getCurrentActivity,
} from 'features/activity/activity.selector'
import { updateOriginalActivity } from 'features/activity/activity.actions'

import { LIMIT_FILE } from 'config'

import FileView from './FileView'
import Picker from '../../../Shared/Form/Picker'
import ErrorMessage from '../../../Shared/ErrorMessage'
import Breadcrumbs from '../Breadcrumbs'
import { TrashBtn, DownloadDisableBtn } from '../../../Shared/Button'
import { getFileType } from '../utils'
import { toText } from 'utils/format'
import imgCheck from 'assets/img/ic-check.png'
import imgFile from 'assets/img/img-file.png'

type FormProps = {
  readOnly: boolean
  documents?: any[]
  onClose: () => void
}

type Props = ReduxProps & DispatchProps & FormProps

const DocumentDisplay: React.ComponentType<Props> = props => {
  const [display, setDisplay] = useState(false)
  const [edit, setEdit] = useState(false)
  const [checkList, setCheckList] = useState([] as number[])
  const [displayDoc, setDisplayDoc] = useState(undefined)

  const dispatch = useDispatch()
  const { original, documents, readOnly } = props

  const documentList: any[] = readOnly ? documents || [] : original.Documents
  const addFile = async (text: any, file: any) => {
    try {
      if (file.size > LIMIT_FILE.document) {
        setDisplay(true)
      } else {
        getFileType(file)
        original.Documents.unshift({ text, file })
        dispatch(updateOriginalActivity({ ...original }))
        const newList: number[] = []
        checkList.forEach(item => newList.push(item + 1))
        setCheckList(newList)
      }
    } catch (e) {}
  }

  const addIndex = (index: number) => {
    checkList.push(index)
    setCheckList([...checkList])
  }

  const removeIndex = (index: number) => {
    let i = checkList.indexOf(index)
    if (i >= 0) {
      checkList.splice(i, 1)
      setCheckList([...checkList])
    }
  }

  const renderFile = (item: any, click?: () => void) => {
    let fileType = getFileType(item.file)
    const content = (
      <>
        <img src={imgFile} alt="" />
        <FileInfo>
          <img src={fileType.icon} alt="" />
          <span>{toText(fileType.name, 28)}</span>
        </FileInfo>
      </>
    )

    if (click) {
      return <LinkImgBlock onClick={click}>{content}</LinkImgBlock>
    }

    return <ImgBlock>{content}</ImgBlock>
  }

  const renderReadonlyFile = (item: any, idx: number, click?: () => void) => {
    let { ActualFileName } = item
    let index = ActualFileName.lastIndexOf('.')
    let type = ActualFileName.substr(index + 1)
    let fileType = getFileType({ type })

    const content = (
      <>
        <img src={imgFile} alt="" />
        <FileInfo>
          <img src={fileType.icon} alt="" />
          <span>{toText(ActualFileName, 28)}</span>
        </FileInfo>
        <DownloadLink
          target="_blank"
          id={`doc${idx}`}
          href={item.FileURL}
          download={ActualFileName}
        />
      </>
    )

    if (click) {
      return <LinkImgBlock onClick={click}>{content}</LinkImgBlock>
    }

    const clickDisplay =
      type === 'pdf' ? () => setDisplayDoc(item.FileURL) : undefined

    return <ImgBlock onClick={clickDisplay}>{content}</ImgBlock>
  }

  const onDownload = async () => {
    let counter = 0
    if (readOnly) {
      for (let index of checkList) {
        setTimeout(() => {
          let link = document.getElementById(`doc${index}`)
          if (link) link.click()
        }, 2500 * counter++)
      }
    } else {
      checkList.forEach(index => {
        setTimeout(() => {
          let item = documentList[index]
          saveAs(item.file, item.file.name)
        }, 2500 * counter++)
      })
    }
  }

  const onRemove = () => {
    if (checkList.length > 0) {
      let newList = []
      let index = 0
      for (let item of original.Documents) {
        if (checkList.indexOf(index) < 0) {
          newList.push(item)
        }
        index++
      }

      original.Documents = newList
      dispatch(updateOriginalActivity({ ...original }))
      setCheckList([])
    }
  }

  const textDoc = translate(TKeys.ActivityDetail.document)
  const textCancel = translate(TKeys.Common.cancel)

  let title
  if (edit) {
    let removeBtn = readOnly ? undefined : <TrashBtn onClick={onRemove} />
    title = (
      <Col span={24}>
        <EditTitle className="std-font-regular">
          <span onClick={() => setEdit(false)}>{textCancel}</span>
          <label>
            <DownloadDisableBtn onClick={onDownload} />
            {removeBtn}
          </label>
        </EditTitle>
      </Col>
    )
  } else {
    title = (
      <Col span={24}>
        <Title className="std-font-bold">
          <label>{textDoc}</label>
          <span className="std-font-regular" onClick={() => setEdit(true)}>
            {translate(TKeys.Common.pick_document)}
          </span>
        </Title>
      </Col>
    )
  }

  let content = documentList.map((item, index) => {
    let btn
    let click
    if (edit) {
      const hasChecked = checkList.includes(index)
      if (hasChecked) {
        click = () => removeIndex(index)
        btn = <Checked src={imgCheck} alt="" onClick={click} />
      } else {
        click = () => addIndex(index)
        btn = <Unchecked onClick={click} />
      }
    }

    let content = readOnly
      ? renderReadonlyFile(item, index, click)
      : renderFile(item, click)
    return (
      <Col span={6} style={index === 3 ? css.firstCol : css.col} key={index}>
        {content}
        {btn}
      </Col>
    )
  })

  return (
    <>
      <Breadcrumbs
        progress={original.Progress}
        activity={props.activity}
        name={textDoc}
        onClose={props.onClose}
      />
      <FileView url={displayDoc} onClose={() => setDisplayDoc(undefined)} />
      <Layout.Content className="mid-content">
        <div className="main-content activity-detail">
          <Row style={{ marginBottom: '24px' }}>{title}</Row>

          <Row>
            <Col span={6} style={css.firstCol}>
              <Picker
                disabled={original.Documents.length === 5 || readOnly}
                height="260"
                width="260"
                text={textDoc}
                accept=".pdf,.xlsx,.xls,.docx,.doc,.csv"
                onChange={addFile}
              />
            </Col>
            {content}
          </Row>
        </div>
      </Layout.Content>
      <ErrorMessage
        visible={display}
        text={translate(TKeys.Error.filie_size_limit)}
        onClose={() => setDisplay(false)}
      />
    </>
  )
}

const css: any = {
  firstCol: {
    width: '260px',
    marginBottom: '22px',
  },
  col: {
    textAlign: 'right',
    width: '260px',
    marginLeft: '23px',
    marginBottom: '22px',
  },
}

const Title = styled.div`
  height: 30px;
  label {
    font-size: 28px;
    color: black;
    position: absolute;
    bottom: 0;
  }

  span {
    font-size: 18px;
    color: #003168;
    position: absolute;
    bottom: 0;
    right: 0;
  }

  span:hover {
    cursor: pointer;
  }
`

const EditTitle = styled.div`
  font-size: 18px;
  color: #003168;
  height: 30px;

  span:hover {
    cursor: pointer;
  }

  label {
    float: right;

    img:last-child {
      margin-left: 24px;
    }
  }
`

const Checked = styled.img`
  width: 24px;
  height: 24px;
  object-fit: contain;
  position: absolute;
  z-index: 2;
  right: 8px;
  bottom: 8px;
  :hover {
    cursor: pointer;
  }
`

const Unchecked = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid white;
  position: absolute;
  z-index: 2;
  right: 8px;
  bottom: 8px;
  background-color: rgba(0, 0, 0, 0.3);
  :hover {
    cursor: pointer;
  }
`

const CssImg = `
  text-align: center;
  width: 260px;
  height: 260px;
  border: 1px solid #e4e7eb;
  border-radius: 5px;
  background-color: white;

  img {
    width: 80px;
    height: 80px;
    object-fit: contain;
    margin-top: 64px;
  }
`

const ImgBlock = styled.div`
  ${CssImg}
`

const LinkImgBlock = styled.div`
  ${CssImg}

  :hover {
    cursor: pointer;
  }
`

const FileInfo = styled.div`
  position: absolute;
  bottom: 0;
  margin: 8px 0px 0px 8px;
  text-align: left;

  img {
    margin: 0px;
    margin-right: 8px;
    width: 16px;
    height: 16px;
  }

  span {
    font-size: 18px;
  }
`

const DownloadLink = styled.a`
  display: none;
`

const mapStateToProps = (state: RootState) => {
  return {
    activity: getCurrentActivity(state),
    original: getOriginalActivity(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {
  updateOriginalActivity,
}

type DispatchProps = typeof mapDispatchToProps

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DocumentDisplay)
