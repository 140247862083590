import React from 'react'
import { Row, Col, Progress } from 'antd'
import styled from 'styled-components'
import { Task } from 'features/tasklane/tasklane.types'
import { OriginalActivity } from 'features/activity/activity.types'
import { getProgressColor } from 'utils/color-map'
import { translate } from 'i18n'
import TKeys from 'i18n/translationKey'
import { Spin, Icon } from 'antd'

import { getStatusText } from 'utils/getText'
import { textToLowerCase } from 'utils/format'

type ActivityInfoProps = {
  task: Task
  originalTask: OriginalActivity
  wbsPath: string
  isSubmitting: boolean
}

type Props = ActivityInfoProps

const Info: React.FC<Props> = ({
  task,
  originalTask,
  wbsPath,
  isSubmitting,
}) => {
  const antIcon = <Icon type="loading" style={{ fontSize: 115 }} spin />

  const taskConfig = getProgressColor(
    originalTask.TaskStatus,
    originalTask.Progress,
    originalTask.LastReqProgress,
    originalTask.LastCurProgress
  )

  const status = textToLowerCase(originalTask.TaskStatus)

  return (
    <Col span={8}>
      <Col className="activity-info">
        {isSubmitting && <Spin indicator={antIcon} tip="Saving..." />}
        {!isSubmitting && (
          <Progress
            type="circle"
            percent={taskConfig.progress || taskConfig.success}
            successPercent={taskConfig.success}
            width={120}
            strokeWidth={9}
            strokeColor={taskConfig.color}
            format={percent => `${originalTask.Progress}%`}
            className={taskConfig.color ? '' : taskConfig.className}
            strokeLinecap="round"
          />
        )}
        <Col>
          <Row>
            <div className={`activity-info-row info-row-${status}`}>
              <span className="label">
                {translate(TKeys.ActivityDetail.status_label)}
              </span>
              <Text className={`color-${status}`}>{getStatusText(status)}</Text>
            </div>
            <RowInfo>
              <div className="activity-info-row">
                <span className="label">
                  {translate(TKeys.ActivityDetail.wbs_label)}
                </span>
                <Text>{wbsPath}</Text>
              </div>
            </RowInfo>
            <RowInfo>
              <div className="activity-info-row">
                <span className="label">
                  {translate(TKeys.ActivityDetail.baseline_start_date)}
                </span>
                <Text>{task.BaselineStartDate}</Text>
              </div>
            </RowInfo>
            <RowInfo>
              <div className="activity-info-row">
                <span className="label">
                  {translate(TKeys.ActivityDetail.baseline_end_date)}
                </span>
                <Text>{task.BaselineFinishDate}</Text>
              </div>
            </RowInfo>
            <RowInfo>
              <div className="activity-info-row">
                <span className="label">
                  {translate(TKeys.ActivityDetail.start_date)}
                </span>
                <Text>{task.StartDate}</Text>
              </div>
            </RowInfo>
            <RowInfo>
              <div className="activity-info-row">
                <span className="label">
                  {translate(TKeys.ActivityDetail.end_date)}
                </span>
                <Text>{task.FinishDate}</Text>
              </div>
            </RowInfo>
            {status === 'rejt' && (
              <RowInfo>
                <div className="activity-info-row">
                  <span className="label">
                    {translate(TKeys.ActivityDetail.reject_reason)}
                  </span>
                  <TextError>{task.Remark}</TextError>
                </div>
              </RowInfo>
            )}
          </Row>
        </Col>
      </Col>
    </Col>
  )
}

const Text = styled.span`
  text-align: right;
  font-family: 'DB Helvethaica X 55 Regular';
  font-size: 18px;
  max-width: 60%;
  word-break: break-all;
  margin-top: 3px;
`
const TextError = styled.span`
  text-align: right;
  font-family: 'DB Helvethaica X 55 Regular';
  font-size: 18px;
  color: #d0494c;
  max-width: 50%;
  word-break: break-word;
  margin-top: 2px;
`

const RowInfo = styled.div`
  .activity-info-row {
    margin-top: 8px;
  }
`

export default Info
