import withAPIAction from 'utils/withAPIAction'
import {
  getCheckListAction,
  saveCheckListAction,
  sendCommissioningAction,
  sendCommissioningEditAction,
  getCommissioningImageAction,
  saveCommissioningImageAction,
} from './actions'
import {
  GetCheckListReq,
  SendCommissioningReq,
  SendCommissioningEditReq,
  SaveCommissioningReq,
  GetCommissioningImageReq,
  SaveCommissioningImageReq,
} from './types'
import { verifyReq } from 'utils/verify'
import { GetCommissioningReq } from 'features/qc/dashboard/types'

// get checklist for qc
export const getCheckListForQC = (token: string, data: GetCheckListReq) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<any>(state => ({
    method: 'POST',
    path: 'GetCommissioningCheckListForQC',
    data,
    token: `Bearer ${token}`,
  }))(getCheckListAction)
}

// save checklist for qc
export const saveCheckListForQC = (
  token: string,
  data: SaveCommissioningReq
) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<any>(state => ({
    method: 'POST',
    path: 'SaveCommissioningCheckListByQC',
    data,
    token: `Bearer ${token}`,
  }))(saveCheckListAction)
}

export const sendCommissioningToQC = (
  token: string,
  data: SendCommissioningReq
) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<SendCommissioningReq>({
    method: 'POST',
    path: 'SendCommissioningToQC	',
    data,
    token: `Bearer ${token}`,
  })(sendCommissioningAction)
}

export const sendCommissioningToQCEdit = (
  token: string,
  data: SendCommissioningEditReq
) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<SendCommissioningEditReq>({
    method: 'POST',
    path: 'SendCommissioningForQCEdit			',
    data,
    token: `Bearer ${token}`,
  })(sendCommissioningEditAction)
}

export const getCommissioningImageByQC = (
  token: string,
  data: GetCommissioningImageReq
) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<any>(state => ({
    method: 'POST',
    path: 'GetCommissioningImageByQC',
    data,
    token: `Bearer ${token}`,
  }))(getCommissioningImageAction)
}

export const saveCommissioningImageByQC = (
  token: string,
  data: SaveCommissioningImageReq
) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<any>(state => ({
    method: 'POST',
    path: 'SaveCommissioningImageByQC',
    data,
    token: `Bearer ${token}`,
  }))(saveCommissioningImageAction)
}
