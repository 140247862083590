import React, { useState } from 'react'
import { Row, Col } from 'antd'
import styled from 'styled-components'
import {
  UnitReport,
  UnitReportItem,
  CheckListForQC,
} from 'features/report/unit/types'
import ReportInfo from './ReportInfo'
import ToggleBtn from './ToggleBtn'
import ReportList from './ReportList'
import ReportPin from './ReportPin'
import ReportPinDetail from './ReportPinDetail'
import { FloatButton, SecondaryButton } from 'Components/Shared/Button'
import img from 'assets/img/img-nodefect.png'
import CheckListModal from './CheckListModal'

type Props = {
  report: UnitReport
  checklist: CheckListForQC
  onAddDefect: () => void
  isLoading?: boolean
  isViewMode?: boolean
  title: string
  onSelected: (item: UnitReportItem) => void
  showAfterImg?: boolean
  pagination?: any
  hasFooter?: boolean
}

const Report: React.FunctionComponent<Props> = props => {
  const {
    report,
    checklist,
    onAddDefect,
    isLoading = true,
    isViewMode = true,
    onSelected,
    title,
    showAfterImg = false,
    pagination,
    hasFooter = true,
  } = props
  const [selected, setSelected] = useState('')
  const [showModal, setShowModal] = useState(false)
  const { FixFromQC: list } = report

  const header = (
    <Row type="flex" justify="space-between" align="middle">
      <Col>
        <Title>{title}</Title>
      </Col>
      <Col>
        <Button>
          <SecondaryButton
            text="รายการตรวจ"
            onClick={() => setShowModal(true)}
          />
        </Button>
        <ToggleBtn
          textLeft="มุมมองรายการ"
          textRight="มุมมองแปลน"
          leftSelected={selected === ''}
          onLeftClick={() => setSelected('')}
          onRightClick={() => setSelected('right')}
        />
      </Col>
    </Row>
  )

  const haveDefect = list && list.length > 0
  const listMode = selected === ''
  const addDefectButton = <FloatButton onClick={onAddDefect} />
  const noDefect = (
    <Area>
      <Empty>
        <img src={img} />
        <p>ไม่มีรายการ Defect</p>
      </Empty>
    </Area>
  )

  const reportList = haveDefect ? (
    <ReportList
      dataList={list}
      onSelected={onSelected}
      showAfterImg={showAfterImg}
      pagination={pagination}
    />
  ) : (
    noDefect
  )

  const reportPin = (
    <ReportPin
      floorList={checklist.FloorList}
      defectList={list}
      onSelected={onSelected}
    />
  )

  return (
    <>
      {!isLoading && (
        <>
          <LeftContent>
            <ReportInfo data={report} />
          </LeftContent>
          <RightContent style={{ paddingBottom: `${hasFooter ? 140 : 0}px` }}>
            {header}
            {listMode ? reportList : reportPin}
            {!isViewMode && addDefectButton}
          </RightContent>
          <CheckListModal
            isShow={showModal}
            onCloseModal={() => setShowModal(false)}
            checkList={checklist}
          />
        </>
      )}
    </>
  )
}

const LeftContent = styled.div`
  width: 320px;
  height: calc(100vh - 112px);
  background-color: #fff;
  float: left;
  margin-top: 1px;
  overflow: scroll;
  padding-bottom: 94px;
`

const RightContent = styled.div`
  width: calc(100% - 320px);
  height: calc(100vh - 112px);
  float: left;
  padding: 32px 80px 0 27px;
  overflow: scroll;
`

const Title = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 28px;
`

const Button = styled.div`
  width: 184px;
  float: left;
  margin-right: 24px;
`

const Area = styled.div`
  width: 100%;
  height: 90%;
  text-align: center;
  position: relative;
`

const Empty = styled.div`
  margin: 0;
  position: absolute;
  top: 40%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  p {
    font-size: 20px;
    margin-top: 24px;
  }
  img {
    width: 144px;
  }
`

export default Report
