import { createStandardAction, createAsyncAction } from 'typesafe-actions'
import {
  GetImageListReq,
  GetImageListRes,
  PostApiResponse,
  Imagelist,
  SaveImageListReq
} from './types'

export const GetImageListAction = createAsyncAction(
  'report/imagelist/FETCH_IMG_LIST_REQUEST',
  'report/imagelist/FETCH_IMG_LIST_SUCCESS',
  'report/imagelist/FETCH_IMG_LIST_FAILURE'
)<GetImageListReq, GetImageListRes, any>()

export const SaveImageListAction = createAsyncAction(
  'report/imagelist/SAVE_IMG_LIST_REQUEST',
  'report/imagelist/SAVE_IMG_LIST_SUCCESS',
  'report/imagelist/SAVE_IMG_LIST_FAILURE'
)<SaveImageListReq, PostApiResponse, any>()

export const ResetEvent = createStandardAction(
  'report/imagelist/RESET_EVENT_ACTION'
)<void>()

export const updateImageList = createStandardAction(
  'report/imagelist/UPDATE_IMAGES_ACTION'
)<Imagelist>()
