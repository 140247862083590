import React, { useState } from 'react'
import styled from 'styled-components'
import { PrimaryButton } from 'Components/Shared/Button'
import { Row, Col } from 'antd'
import { ConfirmModal, SwitchFunction } from 'Components/Defect'
import { UnitReport } from 'features/report/unit/types'
import { DefectStatus } from 'features/types/status'

type Props = {
  project: string
  RequestID: number
  onSubmit: () => void
  currentCheckNo?: number
  isLoading?: boolean
  isCmChecked?: boolean
  report: UnitReport
}

const CmRejectFooter: React.ComponentType<Props> = props => {
  const {
    onSubmit,
    isLoading = false,
    currentCheckNo = 0,
    isCmChecked = true,
    report,
    project,
    RequestID,
  } = props
  const [confirmModal, setConfirmModal] = useState(false)

  const invalidData = () => {
    const item = report.FixFromQC.find(
      item => item.PinStatus === DefectStatus.TOBEFIX && item.ImageAfter
    )
    return item ? false : true
  }

  const onMySelfSubmit = () => {
    const showWarning = invalidData()
    if (showWarning) {
      setConfirmModal(true)
    } else {
      onSubmit()
    }
  }

  return (
    <Footer>
      <Row type="flex" justify="space-between" align="middle">
        <Col>
          <SwitchFunction
            readonly={true}
            projectCode={project}
            RequestID={RequestID}
            report={report}
          />
        </Col>
        <Col>
          <Button>
            {isCmChecked ? (
              <PrimaryButton
                text={`เริ่มตรวจ QC ด้วยตัวเอง (${currentCheckNo})`}
                disabled={isLoading}
                onClick={onMySelfSubmit}
              />
            ) : (
              <PrimaryButton
                text={`ส่งรายงานหา QC (${currentCheckNo})`}
                disabled={isLoading}
                onClick={onSubmit}
              />
            )}
          </Button>
        </Col>
      </Row>
      <ConfirmModal
        onClose={() => setConfirmModal(false)}
        onSubmit={() => {
          setConfirmModal(false)
          onSubmit()
        }}
        title="ยืนยันดำเนินการ"
        desc="ท่านยังแนบรูปแก้ไขในรายการหมุดไม่ครบ"
        descSecond={`ยืนยันจะเริ่มตรวจ QC ด้วยตัวอง (${currentCheckNo})`}
        visible={confirmModal}
      />
    </Footer>
  )
}

const Footer = styled.div`
  position: fixed;
  bottom: 0px;
  height: auto;
  width: 100%;
  background-color: white;
  padding: 22px 80px;
  z-index: 20;
`

const Button = styled.div`
  width: 200px;
  margin-left: 16px;
  float: left;
`

export default CmRejectFooter
