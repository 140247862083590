import React from 'react'
import AdminTab from 'Components/Shared/AdminTab'

interface Props {
  selected: 'Construct' | 'Audit'
}

const ReportRoadTab: React.FunctionComponent<Props> = props => {
  const tabItem = [
    {
      link: '/report/constructroad',
      title: 'ระหว่างก่อสร้างถนน',
      isActive: props.selected == 'Construct',
    },
    {
      link: '/report/auditroad',
      title: 'ตรวจรับมอบถนนและก่อนหมดประกัน',
      isActive: props.selected == 'Audit',
    },
  ]

  return <AdminTab tabItem={tabItem} />
}

export default ReportRoadTab
