import React, { useState } from 'react'
import { Input, Row, Col, Icon } from 'antd'
import styled from 'styled-components'

import { EditIcon, TrashIcon } from 'Components/Shared/Icon'

interface Props {
  text: string
  level?: 'first' | 'second' | 'third'
  iCate?: number
  iItem?: number
  iSubItem?: number
  onDelete: (
    name: string,
    iCate: number,
    iItem: number,
    iSubItem: number
  ) => void
  onEdit: (iCate: number, iItem: number, iSubItem: number) => void
  readOnly?: boolean
}

const CheckItem: React.FC<Props> = ({
  text,
  level = 'first',
  iCate = -1,
  iItem = -1,
  iSubItem = -1,
  onDelete,
  onEdit,
  readOnly = false,
}) => {
  const onEditItem = () => onEdit(iCate, iItem, iSubItem)
  const onDeleteItem = () => onDelete(text, iCate, iItem, iSubItem)

  return (
    <CheckList className={`${level}`}>
      <Row type="flex" justify="space-between">
        <Col className="text">
          <span>{text}</span>
        </Col>

        <Col className="icon-action">
          <Row type="flex">
            <HoverIcon onClick={onEditItem}>
              <EditIcon color="#8b99a7" size={16} />
            </HoverIcon>
            {readOnly === false && (
              <HoverIcon onClick={onDeleteItem}>
                <TrashIcon color="#8b99a7" size={16} />
              </HoverIcon>
            )}
          </Row>
        </Col>
      </Row>
    </CheckList>
  )
}

const CheckList = styled.div`
  margin-top: 8px;
  height: 56px;
  border-radius: 5px;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.03);
  background-color: #ffffff;
  font-size: 20px;
  padding: 12px 25px;
  border: 1px solid #eeeff2;
  &.first {
    font-family: 'DB Helvethaica X 75 Bd';
  }
  &.second {
    margin-left: 24px;
  }
  &.third {
    margin-left: 48px;
  }
  &.add {
    font-family: 'DB Helvethaica X 75 Bd';
    color: #003168;
    cursor: pointer;
    img {
      width: 16px;
      margin-right: 13px;
      margin-top: -5px;
    }
  }
  .icon-action {
    display: none;
    cursor: pointer;
  }
  :hover .icon-action {
    display: block;
  }

  .text {
    max-width: 70%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

const HoverIcon = styled.div`
  padding: 2px 9px;
`
export default CheckItem
