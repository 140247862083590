import { createAsyncAction, createStandardAction } from 'typesafe-actions'
import { CreateDataReq, CommonResp } from './types'

export const createDataAction = createAsyncAction(
  'admin/auto-email/FETCH_LIST_REQUEST',
  'admin/auto-email/FETCH_LIST_SUCCESS',
  'admin/auto-email/FETCH_LIST_FAILURE'
)<CreateDataReq, CommonResp, any>()

export const resetEvent = createStandardAction(
  'admin/auto-email/RESET_EVENT_ACTION'
)<void>()
