import React, { useState, useEffect } from 'react'
import { Modal, Row, Col, DatePicker, Input } from 'antd'
import styled from 'styled-components'
import { SecondaryButton, PrimaryButton } from 'Components/Shared/Button'
import { DATETIME_PICKER_DATE_FORMAT } from 'config'
import moment from 'moment'
import InputSearch from 'Components/Shared/Input/InputSearch'
import { Project } from 'features/qc/sitevisite/types'

type Props = {
  projects: Project[]
  onSendRequest: (projectCode: string, date: string) => void
  onClose: () => void
  visible?: boolean
  title?: string
}

const RequestModal = (props: Props) => {
  const {
    visible = false,
    onClose,
    projects = [],
    onSendRequest = () => {},
    title = 'สร้างคำขอ - Site visit ระหว่างก่อสร้าง',
  } = props

  const [date, setDate] = useState(null as any)
  const [projectCode, setProjectCode] = useState('')
  const [txtSearch, setTxtSearch] = useState('')
  const [projectsFilter, setProjectFilter] = useState([] as Project[])

  const disabledDate = (current: any) => {
    return current && current < moment().subtract(1, 'day')
  }

  useEffect(() => {
    setDate('')
    setProjectCode('')
    setTxtSearch('')
  }, [visible])

  useEffect(() => {
    setProjectFilter(projects)
  }, [projects])

  const hasText = (data: string, searchTxt: string) => {
    return data.toLowerCase().includes(searchTxt.toLowerCase())
  }

  const onFilter = (val: string) => {
    setTxtSearch(val)
    if (val.trim() === '') {
      setProjectFilter([...projects])
    } else {
      const newProject = projects.filter(item => {
        return (
          hasText(item.ProjectCode, val) || hasText(item.ProjectNameTH, val)
        )
      })
      setProjectFilter([...newProject])
    }
  }

  return (
    <>
      <Modal
        className="request-modal"
        title={false}
        centered
        visible={visible}
        footer={false}
        closable={true}
        closeIcon={
          <img
            src="/img/ic-cancel.svg"
            alt="close icon"
            title="close history modal"
          />
        }
        onCancel={onClose}
      >
        <Body>
          <Header>{title}</Header>
          <Projects>
            <SearchSection>
              <InputSearch
                readOnly={false}
                placeholder="ค้นหาโครงการ"
                value={txtSearch}
                onChange={(value: string) => onFilter(value)}
              />
            </SearchSection>
            <ProjectList>
              {projectsFilter.map((item, index) => {
                return (
                  <ProjectItem
                    key={index}
                    className={
                      projectCode === item.ProjectCode ? 'selected' : ''
                    }
                    onClick={() => setProjectCode(item.ProjectCode)}
                  >
                    {item.ProjectCode} - {item.ProjectNameTH}
                  </ProjectItem>
                )
              })}
            </ProjectList>
          </Projects>
          <Row type="flex" justify="space-between" align="middle">
            <Col span={24}>
              <div className="input-with-icon">
                <div className="label">วันเวลานัดหมาย</div>
                <DatePicker
                  className="date-picker"
                  format={DATETIME_PICKER_DATE_FORMAT}
                  placeholder={DATETIME_PICKER_DATE_FORMAT}
                  onChange={date => setDate(date)}
                  showTime={{
                    format: 'HH:mm',
                  }}
                  disabledDate={disabledDate}
                  value={date}
                  allowClear={true}
                  showToday={false}
                />
              </div>
            </Col>
          </Row>
          <ButtonSection>
            <Row type="flex" justify="end">
              <Col>
                <div className="btn-submit">
                  <SecondaryButton
                    text="ยกเลิก"
                    disabled={false}
                    onClick={() => onClose()}
                  />
                </div>
              </Col>
              <Col>
                <div className="btn-submit">
                  <PrimaryButton
                    text="สร้างคำขอ"
                    disabled={date === '' || projectCode === ''}
                    onClick={() => onSendRequest(projectCode, date)}
                  />
                </div>
              </Col>
            </Row>
          </ButtonSection>
        </Body>
      </Modal>
    </>
  )
}

const Header = styled.div`
  text-align: left;
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 24px;
  color: #000;
  margin-top: -10px;
  padding: 0 8px;
`

const Body = styled.div``

const Projects = styled.div`
  height: 280px;
  border-radius: 8px;
  border: solid 1px #e4e7eb;
  margin: 12px 0;
  padding: 16px;
`

const ButtonSection = styled.div`
  margin-top: 32px;
  .btn-submit {
    width: 160px;
    margin-left: 16px;
  }
`

const SearchSection = styled.div``

const ProjectList = styled.div`
  height: 200px;
  overflow: scroll;
  margin-top: 10px;
`

const ProjectItem = styled.div`
  font-family: 'DB Helvethaica X 55 Regular';
  font-size: 20px;
  color: #000;
  padding: 6px 8px;
  cursor: pointer;
  &.selected {
    border-radius: 5px;
    border: solid 1px #003168;
  }
`

export default RequestModal
