import withAPIAction from 'utils/withAPIAction'
import { GetFunctionChecklisAction, SaveFunctionAction } from './actions'
import { GetFunctionChecklistReq, SaveFunctionChecklistReq } from './types'
import { verifyReq } from 'utils/verify'

export const getFunctionCheckListApi = (
  token: string,
  data: GetFunctionChecklistReq
) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<GetFunctionChecklistReq>({
    method: 'POST',
    path: 'GetFunctionCheckListForQC	',
    data,
    token: `Bearer ${token}`,
  })(GetFunctionChecklisAction)
}

export const saveFunctionCheckListByQCApi = (
  token: string,
  data: SaveFunctionChecklistReq
) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<SaveFunctionChecklistReq>({
    method: 'POST',
    path: 'SaveFunctionCheckListByQC',
    data,
    token: `Bearer ${token}`,
  })(SaveFunctionAction)
}
