import React, { useState, useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import RootState from 'typings/RootState'
import { connect, useDispatch } from 'react-redux'
import {
  selectOriginalActivity,
  selectCheckList,
  selectEvent as selectEventActivity,
} from 'features/maincon/activity/selector'
import {
  getWbsPathMapSelector,
  getWbsMapParentsSelector,
  getEvent,
} from 'features/wbs/wbs.selector'
import { setCurrentActivity } from 'features/maincon/activity/actions'
import {
  getActivityById,
  getCheckListById,
} from 'features/maincon/activity/api'
import {
  userEmailSelector,
  serviceTokenSelector,
} from 'features/sensitive/sensitive.selector'

import { selectUploadEvent } from 'features/uploadfile/selector'

import { selectedProjectCodeSelector } from 'features/project/project.selector'
import { selectData, selectEvent } from 'features/maincon/history/selector'
import { GetHistoryActivityReq } from 'features/maincon/history/types'
import { getHistoryActivity } from 'features/maincon/history/api'
import { ConfirmDeleteModal, EventModal } from 'Components/Shared'

import ImageDisplay from 'Components/Shared/ImageDisplay'
import DocumentDisplay from 'Components/Shared/DocumentDisplay'
import ThumbnailDisplay from 'Components/Shared/ThumbnailDisplay'
import { HistoryModal, HistoryTable } from 'Components/Activity'
import AdminMenu from 'Components/MenuAdmin/AdminPJMenu'

import Breadcrumbs from './Breadcrumbs'
import ActivityDetail from './ActivityDetail'
import { UpdateDataReq } from 'features/adminpj/primavera/types'
import { updateData } from 'features/adminpj/primavera/api'
import { selectEvent as returnEvent } from 'features/adminpj/primavera/selector'

type FormProps = {
  id: string
}

type Props = FormProps &
  RouteComponentProps<{ id: string; status: string }> &
  ReduxProps

const PrimaveraDetail = (props: Props) => {
  const [lastPage, setLastPage] = useState('')
  const [page, setPage] = useState('')
  const [readOnly, setReadOnly] = useState(false)
  const [enable, setEnable] = useState(false)
  const [isUpdate, setIsUpdate] = useState(false)
  const [imageSelected, setImageSelected] = useState(0)
  const [imageList, setImageList] = useState([] as any[])
  const [documentList, setDocumentList] = useState([] as any[])
  const [modal, setModal] = useState(false)
  const [showHistoryModal, setShowHistoryModal] = useState(false)
  const {
    Token,
    UserEmail,
    ProjectCode,
    match,
    CheckList,
    originalActivity,

    historyData,
    historyEvent,
    activityEvent,

    wbs,

    returnEvent,
    wbsEvent,
  } = props

  const ActivityID = match.params.id
  const Status = match.params.status
  const onPageClose = () => {
    setPage(lastPage)
    if (lastPage !== '') {
      setLastPage('')
    }
  }

  const dispatch = useDispatch()

  useEffect(() => {
    setEnable(false)
  }, [originalActivity])

  useEffect(() => {
    onGetActivityDetail()
  }, [UserEmail, ProjectCode, ActivityID, Token, dispatch])

  useEffect(() => {
    const data: GetHistoryActivityReq = {
      ProjectCode,
      UserEmail,
      ActivityID,
    }

    dispatch(getHistoryActivity(Token, data))
  }, [UserEmail, ProjectCode, ActivityID, Token, dispatch])

  const onGetActivityDetail = () => {
    setEnable(true)
    dispatch(
      getActivityById(
        {
          UserEmail,
          ProjectCode,
          ActivityID,
          ActivityStatus: Status,
        },
        Token
      )
    )

    dispatch(
      getCheckListById(
        {
          UserEmail,
          ProjectCode: ProjectCode,
          ActivityID,
        },
        Token
      )
    )
  }

  const onReturnActivity = () => {
    setModal(false)
    setEnable(true)
    setIsUpdate(true)
    const req: UpdateDataReq = {
      UserEmail,
      ProjectCode,
      ActivityID,
    }
    dispatch(updateData(Token, req))
  }

  const onSuccess = () => {
    setEnable(false)
    if (isUpdate) {
      setIsUpdate(false)
      props.history.push(`/primavera`)
    } else {
      onGetActivityDetail()
    }
  }

  const onImageOpen = (list?: any[], readOnly?: boolean) => {
    setLastPage(page)
    setPage('images')
    setImageList(list ? list : [])
    setReadOnly(readOnly || false)
  }

  const onDocumentOpen = (list?: any[], readOnly?: boolean) => {
    setLastPage(page)
    setPage('documents')
    setReadOnly(readOnly || false)
    setDocumentList(list ? list : [])
  }

  const onThumbnailOpen = (index: number, list?: any[], readOnly?: boolean) => {
    setLastPage(page)
    setPage('thumbnails')
    setImageList(list ? list : [])
    setReadOnly(readOnly || false)
    setImageSelected(index)
  }

  const historyModal = (
    <HistoryModal
      isShow={showHistoryModal}
      onCloseModal={() => setShowHistoryModal(false)}
      content={
        <HistoryTable
          items={historyData}
          fetchHistoryError={historyEvent.error}
          onImageOpen={onImageOpen}
          onDocumentOpen={onDocumentOpen}
        />
      }
    />
  )

  let content
  let breadcrumpName

  switch (page) {
    case 'images':
      content = (
        <ImageDisplay
          onClose={onPageClose}
          readOnly={readOnly}
          images={imageList}
          onThumbnailOpen={onThumbnailOpen}
          onChange={() => {}}
          numberOfImage={50}
          breadcrumb={null}
        />
      )
      breadcrumpName = 'รูปภาพ'
      break
    case 'documents':
      content = (
        <DocumentDisplay
          onClose={onPageClose}
          readOnly={readOnly}
          documents={documentList}
          onChange={() => {}}
          numberOfFile={50}
          breadcrumb={null}
        />
      )
      breadcrumpName = 'เอกสาร'
      break
    case 'thumbnails':
      content = (
        <ThumbnailDisplay
          onClose={onPageClose}
          readOnly={readOnly}
          images={imageList}
          index={imageSelected}
          onChange={() => {}}
        />
      )
      break
    default:
      breadcrumpName = null
      content = (
        <>
          <EventModal
            name="activity"
            event={[activityEvent, returnEvent, wbsEvent]}
            enable={enable}
            successCalback={onSuccess}
          />
          <ActivityDetail
            onImageOpen={onImageOpen}
            onDocumentOpen={onDocumentOpen}
            onThumbnailOpen={onThumbnailOpen}
            checkListData={CheckList}
            originalData={originalActivity}
            onHistoryOpen={() => setShowHistoryModal(true)}
            isLoading={activityEvent.IsLoading}
            wbs={wbs}
            onReturnTask={() => setModal(true)}
          />
          <ConfirmDeleteModal
            onClose={() => setModal(false)}
            onSubmit={() => onReturnActivity()}
            title="ยืนยันคืนงาน"
            desc="คุณยืนยันที่จะคืนงานใช่หรือไม่"
            visible={modal}
          />
          {historyModal}
        </>
      )
  }

  return (
    <>
      <Breadcrumbs
        activity={originalActivity}
        name={breadcrumpName}
        onClose={onPageClose}
      />
      <AdminMenu selected="primavera">{content}</AdminMenu>
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    originalActivity: selectOriginalActivity(state),
    CheckList: selectCheckList(state),
    UserEmail: userEmailSelector(state),
    Token: serviceTokenSelector(state),
    ProjectCode: selectedProjectCodeSelector(state),
    wbsPathMap: getWbsPathMapSelector(state),
    historyData: selectData(state),
    historyEvent: selectEvent(state),
    activityEvent: selectEventActivity(state),
    wbs: getWbsMapParentsSelector(state),
    uploadEvent: selectUploadEvent(state),
    returnEvent: returnEvent(state),
    wbsEvent: getEvent(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {
  setCurrentActivity,
}

export default connect(mapStateToProps, mapDispatchToProps)(PrimaveraDetail)
