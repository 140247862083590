import RootState from 'typings/RootState';
import { createSelector } from 'reselect';
import {
  ActivitySeeMoreData,
  SeeMoreQuery,
  ActivityFilter,
} from './activity.types';
import { Task } from 'features/tasklane/tasklane.types';

export const getTaskByIdList = (state: RootState) => state.activity.taskById;

export const isSubmittingSelector = (state: RootState) =>
  state.activity.isSubmitting;
export const submitSuccessSelector = (state: RootState) =>
  state.activity.submitSuccess;
export const submitErrorSelector = (state: RootState) =>
  state.activity.submitError;
export const getCurrentActivity = (state: RootState) =>
  state.activity.currentActivity;
export const isLoadingSelector = (state: RootState) => state.activity.isLoading;

export const getOriginalActivity = (state: RootState) =>
  state.activity.originalActivity;

export const currentActivityCodeSelector = createSelector(
  getCurrentActivity,
  activity => activity.ActivityID
);

export const getCurrentBrowsingActivityCode = (state: RootState) =>
  state.activity.currentBrowsingActivityCode;

export const getActivityByActivityCode = createSelector(
  getTaskByIdList,
  getCurrentBrowsingActivityCode,
  (tasks, currentActivity) => {
    if (currentActivity) {
      return tasks[currentActivity];
    }

    return undefined;
  }
);

export const activitySeeMoreSelector = (
  state: RootState
): ActivitySeeMoreData => state.activity.seeMore;

export const getSeeMoreQuerySelector = createSelector(
  activitySeeMoreSelector,
  seeMore => seeMore.query as SeeMoreQuery
);

export const getShowMorePagesSelector = (
  state: RootState
): { rowPerPage: number; totalRows: number } => state.activity.showMorePages;

export const getRowPerPageSelector = createSelector(
  getShowMorePagesSelector,
  showMore => showMore.rowPerPage
);

export const getDataForSeeMoreSelector = createSelector(
  activitySeeMoreSelector,
  getSeeMoreQuerySelector,
  (activitySeeMore, query) => {
    const { type } = query;

    const dataType = activitySeeMore[type] as {
      data: Record<string, Task>;
      list: Task[];
      page: number;
      totalRows: number;
      query: SeeMoreQuery;
    };

    return {
      list: dataType.list as Task[],
      totalRows: dataType.totalRows,
    };
  }
);

export const getDataForSeeMoreKeyByActivityCodeSelector = createSelector(
  activitySeeMoreSelector,
  getSeeMoreQuerySelector,
  (activitySeeMore, query) => {
    const { type } = query;

    const dataType = activitySeeMore[type] as {
      data: Record<string, Task>;
      list: Task[];
      page: number;
      totalRows: number;
      query: SeeMoreQuery;
    };

    return {
      data: dataType.data,
      totalRows: dataType.totalRows,
    };
  }
);

export const getActivitySortingSelector = (state: RootState): any =>
  state.activity.sorting;

export const getActivityFilterSelector = (state: RootState): ActivityFilter =>
  state.activity.filter;

export const getActivityHistory = (state: RootState) => state.activity.history;

export const getActivityHistoryError = (state: RootState) =>
  state.activity.fetchHistoryError;
