import React, { useState, useEffect } from 'react'
import { Row, Col, Collapse } from 'antd'
import CheckAsOptions from '../Filters/CheckAsOptions'
import styled from 'styled-components'
import arrowUp from 'assets/img/ic-up.png'
import arrowDown from 'assets/img/ic-down.png'
import {
  CheckedIcon,
  UncheckedIcon,
  DisableCheckedIcon,
  DisableUncheckedIcon,
} from '../Icon'

const { Panel } = Collapse

type Props = {
  item: Mock
}

type Mock = {
  id: string
  name: string
  detail?: string
  items?: Mock[]
  isSelected?: boolean
  readOnly?: boolean
  onChange?: (value: boolean, item: any) => void
  tag: any
}

const TreeItem: React.FunctionComponent<Props> = props => {
  const { item = {} as any } = props
  const [isSelected, setIsSelected] = useState(item.isSelected)

  const { items = [] } = item
  const hasItem = items.length > 0

  const onClick = (item: Mock) => {
    const { onChange } = item
    const newState = !isSelected
    setIsSelected(newState)
    if (onChange) {
      onChange(newState, item)
    }
  }

  const arrow = (panelProps: any) => {
    return (
      <img
        src={panelProps.isActive ? arrowUp : arrowDown}
        className="icon"
        width="16px"
      />
    )
  }

  const header = () => {
    const { name = '', detail, readOnly = false } = item
    const click = () => onClick(item)
    let checkIcon
    let color = 'black'
    if (readOnly) {
      color = isSelected ? 'black' : '#8b99a7'
      checkIcon = isSelected ? (
        <DisableCheckedIcon width="16px" />
      ) : (
        <DisableUncheckedIcon width="16px" />
      )
    } else {
      checkIcon = isSelected ? (
        <CheckedIcon width="16px" onClick={click} />
      ) : (
        <UncheckedIcon width="16px" onClick={click} />
      )
    }

    const detailDiv = detail ? <Detail>{detail}</Detail> : undefined
    return (
      <Line style={{ paddingTop: '6px', paddingBottom: '6px' }}>
        {hasItem ? undefined : checkIcon}

        <Title style={{ color }} onClick={hasItem ? undefined : click}>
          {name}
        </Title>
        {detailDiv}
      </Line>
    )
  }

  const list: any[] = hasItem
    ? items.map((it: any, i: number) => <TreeItem key={i} item={it} />)
    : []
  return (
    <Row className="pop-over-container-choices">
      <Col span={24}>
        <Collapse
          expandIconPosition="right"
          defaultActiveKey={['0']}
          expandIcon={panelProps => arrow(panelProps)}
          className="colapse-tree"
        >
          <Panel key={item.id} header={header()} showArrow={hasItem}>
            {list}
          </Panel>
        </Collapse>
      </Col>
    </Row>
  )
}

const Line = styled.div`
  align-items: center;
  display: flex;
`

const Title = styled.span`
  font-size: 20px;
  padding-left: 15px;
`

const Detail = styled.span`
  color: #c4c3c3;
  font-size: 20px;
  position: absolute;
  right: 20px;
`

export default TreeItem
