export enum UserRole {
  MC = 'MC',
  CM = 'CM',
  PJ = 'PJ',
}

export enum Action {
  REQUEST = 'REQ',
  ACCEPT = 'ACPT',
  REJECT = 'REJT',
  APPROVE = 'APPV',
}

export enum Description {
  REQ_MC = 'ส่งคำขอ',
  REQ_CM = 'ส่งคำขอสถานะงาน {progress}%',
  ACPT_MC = 'รับงาน',
  ACPT_CM = 'รับคำขอ',
  REJT_CM = 'ส่งกลับผู้รับเหมา',
  REJT_PJ = 'ไม่อนุมัติสถานะงาน {progress}%',
  APPV_PJ = 'อนุมัติสถานะงาน {progress}%',
}
