import React, { useState, useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import RootState from 'typings/RootState'
import { connect, useDispatch } from 'react-redux'
import { setCurrentActivity } from 'features/maincon/activity/actions'
import {
  userEmailSelector,
  serviceTokenSelector,
} from 'features/sensitive/sensitive.selector'
import { EventModal } from 'Components/Shared'
import Menu from 'Components/MenuAdmin/AdminQCMenu'

import Breadcrumbs from './Breadcrumbs'
import styled from 'styled-components'
import { getReportDetail } from 'features/adminReport/commonArea/api'
import { GetReportDetailReq } from 'features/adminReport/commonArea/types'
import { toNumber } from 'lodash'
import ReportDetail from './ReportDetail'

type FormProps = {
  id: string
}

type Props = FormProps &
  RouteComponentProps<{
    projectCode: string
    unit: string
    floor: string
    qcTypeID: string
    checkNo: string
    requestID: string
    tower: string
  }> &
  ReduxProps

const CommonAreaDetail = (props: Props) => {
  const [lastPage, setLastPage] = useState('')

  const [enable, setEnable] = useState(false)
  const { Token, UserEmail, match, data, event } = props

  const Unit = match.params.unit
  const QCTypeID = match.params.qcTypeID
  const Floor = match.params.floor
  const CheckNumber = match.params.checkNo
  const RequestID = match.params.requestID
  const ProjectCode = match.params.projectCode
  const Tower = match.params.tower

  const onPageClose = () => {
    if (lastPage !== '') {
      setLastPage('')
    }
  }

  const dispatch = useDispatch()

  useEffect(() => {
    setEnable(false)
  }, [data])

  useEffect(() => {
    onGetActivityDetail()
  }, [UserEmail, ProjectCode, RequestID, Token, dispatch])

  const onGetActivityDetail = () => {
    setEnable(true)
    const req: GetReportDetailReq = {
      ProjectCode,
      UserEmail,
      CheckNumber: toNumber(CheckNumber),
      RequestID: toNumber(RequestID),
      Floor,
      QCTypeID,
      Unit,
      Tower,
    }
    dispatch(getReportDetail(Token, req))
  }

  const onSuccess = () => {
    setEnable(false)
    onGetActivityDetail()
  }

  return (
    <>
      <EventModal
        name="activity"
        event={[event]}
        enable={enable}
        successCalback={onSuccess}
      />
      <Breadcrumbs activity={data} name={''} onClose={onPageClose} />
      <Menu selected={'report.zone'}>
        <ReportDetail data={data} />
      </Menu>
    </>
  )
}

const Title = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 28px;
  color: #000;
`

const mapStateToProps = (state: RootState) => {
  return {
    UserEmail: userEmailSelector(state),
    Token: serviceTokenSelector(state),
    data: state.adminReportCommonArea.DataDetail,
    event: state.adminReportCommonArea.Event,
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {
  setCurrentActivity,
}

export default connect(mapStateToProps, mapDispatchToProps)(CommonAreaDetail)
