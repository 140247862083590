import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'antd'
import { Link } from 'react-router-dom'
import { UncheckedIcon, CheckedIcon } from 'Components/Shared/Icon'
import { InputSearch } from 'Components/Shared/Input'
import { RadioBoxReject } from 'Components/Shared/Form'
import { ActivityIDList } from 'features/qc/document/types'

interface Props {
  link: string
  activityData: any
  onUpdateData: (key: string, index: number, value: any) => void
  readOnly?: boolean
  onSelected: (value: number) => void
  selectedList: ActivityIDList[]
}

const ContentBox: React.FC<Props> = props => {
  const {
    link,
    activityData,
    onUpdateData,
    readOnly = false,
    onSelected,
    selectedList,
  } = props

  const renderCheckListRemark = (doc: any, index: number) => {
    return (
      <RemakSection>
        <Row type="flex" justify="space-between">
          <Col span={16}>
            <InputSearch
              placeholder="หมายเหตุ"
              value={doc.Remark}
              onChange={(value: string) => {
                onUpdateData('Remark', index, value)
              }}
              hideSuffix={true}
              readOnly={readOnly}
            />
          </Col>
          <Col>
            <Score>-{doc.Score} คะแนน</Score>
          </Col>
        </Row>
      </RemakSection>
    )
  }

  const renderChecklist = (documentList: any[]) => {
    return documentList.map((doc, index) => {
      const isSelected = doc.Status === 'Check'
      const updateValue = isSelected ? 'NotCheck' : 'Check'
      const onUpdate = readOnly
        ? undefined
        : () => onUpdateData('Status', index, updateValue)
      return (
        <CheckListBox key={index}>
          <Row type="flex" justify="space-between">
            <Col>
              <Name>{doc.Name}</Name>
            </Col>
            <Col onClick={onUpdate}>
              {isSelected ? <CheckedIcon /> : <UncheckedIcon />}
            </Col>
          </Row>
          {isSelected && renderCheckListRemark(doc, index)}
        </CheckListBox>
      )
    })
  }

  const isChecked = (activityID: number) => {
    const findData = selectedList.find(item => item.ActivityID === activityID)
    return findData ? true : false
  }

  return (
    <ContentBoxStyle>
      <Row type="flex" justify="space-between">
        <Col>
          <RadioBoxReject
            key={activityData.ActivityID}
            label={activityData.ActivityName}
            value={activityData.ActivityID}
            isCheck={isChecked(activityData.ActivityID)}
            onClick={value => onSelected(activityData.ActivityID)}
            spaceBtwText={16}
          />{' '}
        </Col>
      </Row>
      <TagDetail>
        <Link to={link}>ดูรายละเอียด</Link>
      </TagDetail>
      {renderChecklist(activityData.DocumentList)}
    </ContentBoxStyle>
  )
}

const ContentBoxStyle = styled.div`
  margin-top: 16px;
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  border: solid 1px #f2f2f2;
  padding-bottom: 0;
`

const TagDetail = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  color: #003168;
  font-size: 18px;
  text-decoration: underline;
`
const Title = styled.span`
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 28px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #000;
`

const CheckListBox = styled.div`
  padding: 7px 0;
  border-bottom: 1px solid #f2f2f2;
  &:last-child {
    border-bottom: none;
  }
`

const Name = styled.span`
  font-family: 'DB Helvethaica X 55 Regular';
  color: #000;
  font-size: 20px;
`

const Score = styled.span`
  font-family: 'DB Helvethaica X 75 Bd';
  color: #000;
  font-size: 24px;
`

const RemakSection = styled.div`
  padding: 10px 0;
`

export default ContentBox
