function keyByFieldName<T extends Record<string, any>, K extends keyof T>(
  items: T[],
  fieldName: K
) {
  let customList = {};

  items.forEach((item: T) => {
    const value = item[fieldName];

    customList = {
      ...customList,
      [value]: item,
    };
  });

  return customList;
}

export default keyByFieldName;
