import React, { useState, useEffect, useRef } from 'react'
import { Row, Col } from 'antd'
import styled from 'styled-components'

import InputText from 'Components/Shared/Input/InputText'
import { DropdownList } from 'Components/Shared/Form'
import { SelectType } from '..'
import JobModalLocation, { JobHandles } from './JobModalLocation'
import {
  ZoneDetail,
  ZoneType,
  JobType,
  LocationType,
} from 'features/admin/zone/types'
import { BottomButton, AddList } from 'Components/Shared/Button'
import { TrashIcon, EditIcon } from 'Components/Shared/Icon'

interface Props {
  selected: SelectType
  editingObj?: JobType
  onSave: (job: JobType) => void
  closeModal: () => void
  disabled: boolean
  zoneDetail: ZoneDetail
  zoneTypeList: ZoneType[]
}

const JobModal: React.FC<Props> = ({
  selected,
  editingObj,
  onSave: onSaveProp,
  closeModal,
  disabled,
  zoneDetail,
  zoneTypeList,
}) => {
  const modalRef = useRef<JobHandles>(null)
  const [error, seterror] = useState('')
  const [name, setname] = useState(editingObj ? editingObj.JobTypeName : '')
  const [locationList, setlocationList] = useState<LocationType[]>(
    editingObj ? editingObj.LocationList : []
  )

  const showModal = (item?: LocationType) => {
    if (modalRef.current) {
      modalRef.current.showModal(item)
    }
  }

  const [selectZone, setSelectZone] = useState<{
    ZoneTypeID: number
    ZoneType: string
  }>({
    ZoneTypeID: 0,
    ZoneType: '',
  })

  useEffect(() => {
    const { ZoneTypeID = 0 } = editingObj || {}
    const zoneType: any = zoneTypeList.find(
      (it: any) => it.ZoneTypeID === ZoneTypeID
    )
    setSelectZone({
      ZoneTypeID: zoneType ? zoneType.ZoneTypeID : 0,
      ZoneType: zoneType ? zoneType.ZoneType : '',
    })
  }, [editingObj])

  const selectZoneType = (zone: ZoneType) => {
    setSelectZone({
      ZoneTypeID: zone.ZoneTypeID,
      ZoneType: zone.ZoneType,
    })
  }

  const changeName = (val: string) => {
    setname(val)
  }

  const addLocation = (item: LocationType) => {
    const newLocation = locationList.concat(item)
    setlocationList(newLocation)
  }
  const updateLocation = () => {
    setlocationList([...locationList])
  }

  const deleteLocation = (index: number) => {
    locationList.splice(index, 1)
    setlocationList([...locationList])
  }

  const onSave = () => {
    if (name !== '' && selectZone.ZoneTypeID) {
      const found = editingObj
        ? null
        : zoneDetail.JobTypeList.find((v: JobType) => v.JobTypeName === name)

      if (found == null) {
        onSaveProp({
          JobTypeID: editingObj ? editingObj.JobTypeID : 0,
          JobTypeName: name,
          ZoneTypeID: selectZone.ZoneTypeID,
          ZoneType: selectZone.ZoneType,
          SubJobTypeList: editingObj ? editingObj.SubJobTypeList : [],
          LocationList: locationList,
        })
      } else {
        seterror('ลักษณะงานนี้ ได้มีอยู่แล้ว')
      }
    } else {
      seterror('กรุณาใส่ข้อมูลทั้งหมด')
    }
  }

  const menuChoice = () => {
    const result = zoneTypeList.map((item: ZoneType, index: number) => (
      <DataItem key={index} onClick={() => selectZoneType(item)}>
        {item.ZoneType}
      </DataItem>
    ))
    return result
  }

  return (
    <MainView>
      <Row style={{ paddingTop: '20px' }}>
        <Col style={{ paddingRight: '7px' }}>
          <InputText
            prefix="ชื่อ"
            value={name}
            onChange={changeName}
            placeholder="กรอกชื่อ"
            openWithFocus
            disabled={disabled}
          />
        </Col>
      </Row>
      <DropdownBox>
        <div className="dropdown-item">
          <DropdownList
            label="หมวดงาน"
            value={selectZone.ZoneTypeID}
            placeholder={selectZone.ZoneType || 'กรุณาเลือก'}
            menuChoice={menuChoice()}
            disabled={disabled}
          />
        </div>
      </DropdownBox>

      <Title>Location</Title>
      <LocationContent>
        {locationList.map((item: LocationType, index: number) => {
          return (
            <LocationItem key={index}>
              <label>{item.LocationName}</label>
              {!disabled && (
                <IconDiv>
                  <IconStyle onClick={() => showModal(item)}>
                    <EditIcon color="#8b99a7" size={16} />
                  </IconStyle>
                  <IconStyle>
                    <TrashIcon
                      onClick={() => deleteLocation(index)}
                      color="#8b99a7"
                      size={16}
                    />
                  </IconStyle>
                </IconDiv>
              )}
            </LocationItem>
          )
        })}
        {!disabled && <AddList onClick={() => showModal()} />}
      </LocationContent>

      {!disabled && (
        <BottomButton closeModal={closeModal} onSave={onSave} error={error} />
      )}

      <JobModalLocation
        ref={modalRef}
        addLocation={addLocation}
        updateLocation={updateLocation}
      />
    </MainView>
  )
}

const MainView = styled.div``

const DropdownBox = styled.div`
  padding-top: 14px;
  .dropdown-item {
    padding: 6px 0;
  }
`

const Title = styled.div`
  padding-top: 17px;
  font-size: 20px;
  color: #000000;
  font-family: 'DB Helvethaica X 75 Bd';
`

const LocationContent = styled.div`
  width: 100%;
  height: 232px;
  border-radius: 5px;
  border: solid 1px #eeeff2;
  background-color: #ffffff;
  overflow: hidden;
  overflow-y: scroll;
`

const LocationItem = styled.div`
  font-family: 'DB Helvethaica X 55 Regular';
  font-size: 20px;
  margin: 0 auto;
  width: 90%;
  height: 56px;
  align-items: center;
  display: flex;
  border: 0px solid #e8ebf0;
  border-bottom-width: 1px;
  display: flex;
  justify-content: space-between;
`

const IconDiv = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`

const IconStyle = styled.div`
  padding: 0 9px;
  cursor: pointer;
`

const DataItem = styled.div`
  padding: 5px 10px;

  &:hover {
    cursor: pointer;
    color: white;
    background-color: #003168;
  }
`

export default JobModal
