import moment from 'moment'
import { put, take, takeEvery, select } from 'redux-saga/effects'
import * as uploadAction from './actions'
import { uploadFileReq } from './api'
import { createWaterMark } from 'utils/file'
import logo from 'assets/img/watermark_logo.png'

export function* setWatermark(username: string, obj: any) {
  const { text, file } = obj
  const datetime = moment().format('DD/MM/YYYY HH:mm')
  const label = `วันที่อัพโหลด ${datetime} น. โดย ${username}`
  const respFile = yield createWaterMark({ text, file, label, logo })

  return respFile
}

export function* uploadFile(
  userEmail: string,
  token: string,
  username: string,
  obj: any
) {
  const respFile = yield setWatermark(username, obj)
  const formData = new FormData()
  formData.set('UserEmail', userEmail)
  formData.append('Files', respFile.file)
  yield put(uploadFileReq(formData, token))
  const resp = yield take(uploadAction.uploadFileAsyncReq.success)
  const resultList = resp.payload.ResponseData

  return resultList[0]
}
