import React from 'react'

type Props = {
  color?: string
}

const CloseIcon = (props: Props) => {
  const { color = 'white' } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <path
        fill={color}
        d="M13.487 12l5.205 5.205a1.052 1.052 0 0 1-1.487 1.487L12 13.487l-5.205 5.205a1.052 1.052 0 0 1-1.487-1.487L10.513 12 5.308 6.795a1.052 1.052 0 0 1 1.487-1.487L12 10.513l5.205-5.205a1.052 1.052 0 0 1 1.487 1.487L13.487 12z"
      />
    </svg>
  )
}

export default CloseIcon
