import React, { useMemo, useState, useRef } from 'react'
import styled from 'styled-components'
import { Row, Col } from 'antd'
import InputText from 'Components/Shared/Input/InputText'
import DropdownList from 'Components/Shared/Form/DropdownList'
import PrimaryButton from 'Components/Shared/Button/PrimaryButton'

import {
  CheckListDetail,
  UnitCheckListByCodeType,
  ProjectListItem as ProjectListItemUnit,
} from 'features/checklist/unit/types'
import ProjectsBox from './ProjectsBox'
import ScoreBox from './ScoreBox'
import UnitBox from './UnitBox'
import CriteriaBox from './CriteriaBox'
import { ProjectTypeResp } from 'features/masterData/types'
import { ProjectListItem } from 'features/project/project.types'
import DeleteCheckListModal, {
  DeleteModalHandles,
} from 'Components/Shared/Modal/DeleteModal'

type Props = {
  isActive: boolean
  isEditing: boolean
  projects: ProjectListItem[]
  projectType: ProjectTypeResp
  checkListDetail: CheckListDetail
  updateCheckList: () => void
  unitCheckListByCode: UnitCheckListByCodeType
  unitCheckListByCodeResult: UnitCheckListByCodeType
  updateUnitCheckList: (
    newObj: UnitCheckListByCodeType,
    newObjResult: UnitCheckListByCodeType
  ) => void
  getUnitByUnitCheckList: (projectCode: string) => void
  onSave: () => void
  onDelete: () => void
  unitLoading: boolean
  duplicateCheckList: () => void
}

function transformProjectType(projectType: ProjectTypeResp) {
  return projectType.List.map(item => {
    return {
      value: item.ProjectTypeID,
      name: item.ProjectTypeName,
    }
  })
}

const QCSetting: React.ComponentType<Props> = ({
  isActive,
  projects,
  checkListDetail,
  updateCheckList,
  updateUnitCheckList,
  onSave,
  onDelete,
  isEditing,
  projectType: projectTypeProps,
  unitCheckListByCode,
  unitCheckListByCodeResult,
  getUnitByUnitCheckList,
  unitLoading,
  duplicateCheckList,
}) => {
  const isUsed = checkListDetail.Status.toLocaleLowerCase() === 'used'
  const modalRef = useRef<DeleteModalHandles>(null)
  const projectType = useMemo(() => transformProjectType(projectTypeProps), [
    projectTypeProps,
  ])
  const [currentProjectCode, setcurrentProjectCode] = useState('')

  const onNameChange = (val: string) => {
    checkListDetail.CheckListName = val
    updateCheckList()
  }

  const onTypeChange = (val: number, index: number) => {
    checkListDetail.ProjectTypeID = val
    checkListDetail.ProjectTypeName = projectType[index].name
    updateCheckList()
  }
  const selectProjectCode = (ProjectCode: string) => {
    getUnitByUnitCheckList(ProjectCode)
    setcurrentProjectCode(ProjectCode)
  }

  const showDelete = () => {
    if (modalRef.current) {
      modalRef.current.showModal('คุณยืนยันต้องการลบ checklist นี้', 1)
    }
  }

  const showDeleteProject = (it: ProjectListItemUnit) => {
    if (modalRef.current && unitLoading !== true) {
      modalRef.current.showModal('คุณยืนยันต้องการลบโครงการนี้', 2, it)
    }
  }

  const showDeleteCriteria = (index: number) => {
    if (modalRef.current) {
      modalRef.current.showModal('คุณยืนยันต้องการลบ criteria นี้', 3, index)
    }
  }

  const onOkModal = (cond: number, obj: any) => {
    switch (cond) {
      case 1:
        onDelete()
        break
      case 2:
        checkListDetail.ProjectList = checkListDetail.ProjectList.filter(
          v => v.ProjectCode !== obj.ProjectCode
        )
        const newObj: UnitCheckListByCodeType = {}
        for (const [key, value] of Object.entries(unitCheckListByCode)) {
          if (key !== obj.ProjectCode) {
            newObj[key] = value
          }
        }

        const newObjResult: UnitCheckListByCodeType = {}
        for (const [key, value] of Object.entries(unitCheckListByCodeResult)) {
          if (key !== obj.ProjectCode) {
            newObjResult[key] = value
          }
        }

        updateCheckList()
        updateUnitCheckList(newObj, newObjResult)
        break
      case 3:
        checkListDetail.QCTypeList.forEach(qc => {
          qc.JobTypeList.forEach(job => {
            job.SubJobTypeList.forEach(subjob => {
              subjob.DetailList.forEach(detail => {
                detail.CriteriaList.splice(obj, 1)
              })
            })
          })
        })
        checkListDetail.CriteriaList.splice(obj, 1)
        updateCheckList()
        break

      default:
        break
    }
  }

  const LeftContent = (
    <LeftSection>
      <BoxSpace>
        <InputText
          prefix="ชื่อ"
          placeholder="กรอกชื่อ"
          value={checkListDetail.CheckListName}
          onChange={onNameChange}
          disabled={isUsed}
        />
      </BoxSpace>
      <BoxSpace>
        <ProjectsBox
          projects={projects}
          selectProjectCode={selectProjectCode}
          checkListDetail={checkListDetail}
          showDeleteProject={showDeleteProject}
        />
      </BoxSpace>
      <BoxSpace>
        <ScoreBox checkListDetail={checkListDetail} disabled={isUsed} />
      </BoxSpace>
    </LeftSection>
  )

  const Rightontent = (
    <RightSection>
      <BoxSpace>
        <DropdownList
          label="ประเภทโครงการ"
          value={checkListDetail.ProjectTypeID}
          placeholder="กรุณาเลือก"
          menu={projectType}
          onChange={onTypeChange}
          disabled={isUsed}
        />
      </BoxSpace>

      <BoxSpace>
        <UnitBox
          currentProjectCode={currentProjectCode}
          checkListDetail={checkListDetail}
          unitCheckListByCode={unitCheckListByCode}
          unitCheckListByCodeResult={unitCheckListByCodeResult}
          unitLoading={unitLoading}
        />
      </BoxSpace>
      <BoxSpace>
        <CriteriaBox
          checkListDetail={checkListDetail}
          updateCheckList={updateCheckList}
          showDeleteCriteria={showDeleteCriteria}
          disabled={isUsed}
        />
      </BoxSpace>
    </RightSection>
  )

  return (
    <div style={{ display: isActive ? '' : 'none' }}>
      <Row type="flex" justify="space-between">
        <Col span={12}>{LeftContent}</Col>
        <Col span={12}>{Rightontent}</Col>
      </Row>
      <ButtonSection>
        <Row type="flex" justify="end">
          {isEditing && (
            <Col style={{ width: '200px', marginRight: '24px' }}>
              <PrimaryButton
                text="Duplicate checklist"
                onClick={duplicateCheckList}
              />
            </Col>
          )}
          {!isUsed && isEditing && (
            <Col style={{ width: '200px', marginRight: '24px' }}>
              <PrimaryButton
                text="ลบ Checklist"
                onClick={showDelete}
                btnType="Reject"
              />
            </Col>
          )}
          <Col style={{ width: '200px' }}>
            <PrimaryButton text="บันทึก" onClick={onSave} />
          </Col>
        </Row>
      </ButtonSection>
      <DeleteCheckListModal ref={modalRef} onOk={onOkModal} />
    </div>
  )
}

const LeftSection = styled.div`
  padding-right: 8px;
`

const RightSection = styled.div`
  padding-left: 8px;
`
const BoxSpace = styled.div`
  margin-bottom: 16px;
`

const ButtonSection = styled.div`
  margin-top: 32px;
`

export default QCSetting
