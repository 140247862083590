import { createStandardAction, createAsyncAction } from 'typesafe-actions'
import { Moment } from 'moment-timezone'
import {
  FetchActivityOnUnitReq,
  GetActivityOnUnitResponse,
  StartCheckingRequest,
  UpdateActivityApi,
  SendRequestToQcRequest,
  SendUrgentForQCRequest,
  SendUrgentForCMRequest,
  SendRequestToMCReq,
} from './types'

export const getActivityOnUnitAction = createAsyncAction(
  'cmpj/cmchecking/GET_ACTIVITY_REQUEST',
  'cmpj/cmchecking/GET_ACTIVITY_RESPONSE',
  'cmpj/cmchecking/GET_ACTIVITY_FAILURE'
)<FetchActivityOnUnitReq, GetActivityOnUnitResponse, any>()

export const StartCheckingByCMAction = createAsyncAction(
  'cmpj/cmchecking/START_CHECKING_REQUEST',
  'cmpj/cmchecking/START_CHECKING_RESPONSE',
  'cmpj/cmchecking/START_CHECKING_FAILURE'
)<StartCheckingRequest, UpdateActivityApi, any>()

export const SendRequestToQcAction = createAsyncAction(
  'cmpj/cmchecking/REQUEST_QC_REQUEST',
  'cmpj/cmchecking/REQUEST_QC_RESPONSE',
  'cmpj/cmchecking/REQUEST_QC_FAILURE'
)<SendRequestToQcRequest, UpdateActivityApi, any>()

export const SendUrgentForQCRequestAction = createAsyncAction(
  'cmpj/cmchecking/URGENTT_QC_REQUEST',
  'cmpj/cmchecking/URGENTT_QC_RESPONSE',
  'cmpj/cmchecking/URGENTT_QC_FAILURE'
)<SendUrgentForQCRequest, UpdateActivityApi, any>()

export const SendUrgentForCMRequestAction = createAsyncAction(
  'cmpj/cmchecking/URGENTT_CM_REQUEST',
  'cmpj/cmchecking/URGENTT_CM_RESPONSE',
  'cmpj/cmchecking/URGENTT_CM_FAILURE'
)<SendUrgentForCMRequest, UpdateActivityApi, any>()

export const SendRequestToMCAction = createAsyncAction(
  'cmpj/cmchecking/REQUEST_MC_REQUEST',
  'cmpj/cmchecking/REQUEST_MC_RESPONSE',
  'cmpj/cmchecking/REQUEST_MC_FAILURE'
)<SendRequestToMCReq, UpdateActivityApi, any>()

export const resetEvent = createStandardAction(
  'cmpj/cmchecking/RESET_EVENT_ACTION'
)<void>()
