/* eslint-disable */
import heic2any from 'heic2any'

const displaySizeImage = (file, callback) => {
  const reader = new FileReader()
  reader.addEventListener('load', () => {
    callback(reader.result, file)
  })
  reader.readAsDataURL(file)
}

export const reduceByDimension = (original = {}, limit = {}) => {
  if (original.width <= limit.width && original.height <= limit.height) {
    return {
      width: original.width,
      height: original.height,
    }
  }

  const ratioW = original.width / limit.width
  const ratioH = original.height / limit.height
  const ratio = ratioW > ratioH ? ratioW : ratioH

  const newSize = {
    width: Math.round(original.width / ratio) || 0,
    height: Math.round(original.height / ratio) || 0,
  }

  return newSize
}

const reduceBySize = ({ original, limit, dimension }) => {
  if (original <= limit) {
    return dimension
  }

  let ratio = original / limit
  let newSize = {
    width: Math.round(dimension.width / ratio),
    height: Math.round(dimension.height / ratio),
  }
  return newSize
}

export const base64ToImage = (text, cross = false) => {
  return new Promise(async resolve => {
    const base64 = await fetch(text, { cache: 'no-cache' })
      .then(response => response.blob())
      .then(blob => {
        const reader = new FileReader()
        reader.readAsDataURL(blob)
        return new Promise(res => {
          reader.onloadend = () => {
            res(reader.result)
          }
        })
      })

    // resolve(base64)

    const img = new Image()
    img.crossOrigin = 'anonymous'
    img.onload = () => {
      resolve(img)
    }
    img.src = base64
  })
}

const canvasToResult = (canvas, filename) => {
  return new Promise(resolve => {
    canvas.toBlob(
      blob => {
        const newFile = new File([blob], filename, {
          type: 'image/jpeg',
          lastModified: Date.now(),
        })

        const reader = new FileReader()
        reader.addEventListener('load', () => {
          resolve({ text: reader.result, file: newFile })
        })
        reader.readAsDataURL(newFile)
      },
      'image/jpeg',
      1
    )
  })
}

export const compress = async ({ value, file, limit }) => {
  const filename = file.name
  const img = await base64ToImage(value)

  let dimension = { width: img.width, height: img.height }
  let newSize = reduceBySize({ original: file.size, limit, dimension })
  if (newSize.width !== img.width || newSize.height !== img.height) {
    const elem = document.createElement('canvas')
    elem.width = newSize.width
    elem.height = newSize.height
    const ctx = elem.getContext('2d')
    ctx.drawImage(img, 0, 0, newSize.width, newSize.height)

    const resp = canvasToResult(ctx.canvas, filename)
    return resp
  } else {
    return { text: value, file }
  }
}

export const blobToBase64 = data => {
  return new Promise(resolve => {
    let reader = new FileReader()
    reader.onloadend = () => {
      let base64data = reader.result
      resolve(base64data)
    }

    reader.readAsDataURL(data)
  })
}

export const base64toBlob = (data, type) => {
  let temp = data.split(',')
  let byteString = atob(temp[1])
  let ab = new ArrayBuffer(byteString.length)
  let ia = new Uint8Array(ab)

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }

  return new Blob([ab], { type })
}

export const heicToJpeg = async ({ file, filename }) => {
  const blob = new Blob([file])
  let resp = await heic2any({
    // required: the HEIF blob file
    blob,
    // (optional) MIME type of the target file
    // it can be "image/jpeg", "image/png" or "image/gif"
    // defaults to "image/png"
    toType: 'image/jpeg',
    quality: 0.8,
  })

  const newFile = new File([resp], filename, {
    type: 'image/jpeg',
    lastModified: Date.now(),
  })

  let base64 = await blobToBase64(resp)
  return { text: base64, file: newFile }
}

export const createWaterMark = async ({ text, file, label = '', logo }) => {
  const filename = file.name
  const img = await base64ToImage(text)
  const elem = document.createElement('canvas')
  elem.width = img.width
  elem.height = img.height
  const ctx = elem.getContext('2d')
  ctx.drawImage(img, 0, 0, img.width, img.height)

  const height = 40
  const startHeight = img.height - height
  const offsetHeight = 24

  ctx.fillStyle = '#003168'
  ctx.fillRect(0, startHeight, img.width, height)

  ctx.font = '22px DB Helvethaica X 55 Regular'
  ctx.fillStyle = 'white'
  ctx.fillText(label, 25, startHeight + offsetHeight)

  const imgLogo = await base64ToImage(logo)
  const startW = img.width - 200
  const startH = startHeight
  ctx.drawImage(imgLogo, img.width - 80, startHeight + 10, 61, 16)

  const resp = canvasToResult(ctx.canvas, filename)
  return resp
}

export const urlToBase64 = async ({ url, cross = true }) => {
  const img = await base64ToImage(url, cross)
  const elem = document.createElement('canvas')
  elem.width = img.width
  elem.height = img.height
  const ctx = elem.getContext('2d')
  ctx.drawImage(img, 0, 0, img.width, img.height)
  return elem.toDataURL()
}

export const base64ToFile = async ({
  data,
  filename,
  lastModified = Date.now(),
}) => {
  const type = 'image/jpeg'
  const blob = await base64toBlob(data, type)
  const newFile = new File([blob], filename, {
    type,
    lastModified,
  })

  return newFile
}

export default {
  urlToBase64,
  reduceByDimension,
  base64ToImage,
  heicToJpeg,
  base64ToFile,
  compress,
  createWaterMark,
  canvasToResult,
}

/* eslint-enable */
