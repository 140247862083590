import React, { useState } from 'react'
import styled from 'styled-components'
import { Row, Col, Layout } from 'antd'
import RootState from 'typings/RootState'

import { CheckedIcon, FailIcon, UncheckedIcon } from 'Components/Shared/Icon'
import { PrimaryButton } from 'Components/Shared/Button'

const { Footer: FooterPage } = Layout

type Props = {
  checklist: any
  visible?: boolean
  onSave: () => void
  onSign: () => void
  onChooseImage: () => void
  onUpdate: (val: any) => void
}

const Footer: React.ComponentType<Props> = props => {
  const {
    checklist,
    visible = false,
    onSave,
    onSign,
    onChooseImage,
    onUpdate,
  } = props

  const onUpdateStatus = (val: string) => {
    checklist.CheckStatus = val
    onUpdate(checklist)
  }

  const { CheckStatus, HasSign, HasImage } = checklist

  if (visible === false) {
    return <div />
  }

  const isDisable = () => {
    let disabled = !(CheckStatus === 'Pass' || CheckStatus === 'Fail')

    if (disabled) return true
    const index = checklist.List.findIndex((item: any) => {
      const iMenu = item.Data.findIndex((menu: any) => {
        if (menu.Status === 'NOTCHECK') return true

        const iSub = menu.Data.findIndex((sub: any) => {
          return sub.Status === 'NotCheck'
        })

        return iSub > -1
      })

      return iMenu > -1
    })

    return index > -1
  }

  const hasSign = HasSign ? (
    <IconBtn>
      <CheckedIcon />
      ลงชื่อรับทราบ
    </IconBtn>
  ) : (
    'ลงชื่อรับทราบ'
  )

  const hasImage = HasImage ? (
    <IconBtn>
      <CheckedIcon />
      เลือกรูปส่งรายงาน
    </IconBtn>
  ) : (
    'เลือกรูปส่งรายงาน'
  )

  if (CheckStatus === 'None') {
    onUpdateStatus('Fail')
  }

  const getIconCheck = (value: string) => {
    if (value !== CheckStatus) return <UncheckedIcon size={24} />

    switch (CheckStatus) {
      case 'Pass':
        return <CheckedIcon size={24} />
      case 'Fail':
        return <FailIcon size={24} />
      default:
        return <UncheckedIcon size={24} />
    }
  }

  return (
    <FooterPage className="detail-page-footer">
      <FooterView>
        <Row type="flex" justify="space-between" align="middle">
          <Col>
            <Button>
              <PrimaryButton text={hasSign} onClick={onSign} />
            </Button>
            <Button>
              <PrimaryButton text={hasImage} onClick={onChooseImage} />
            </Button>
          </Col>
          <Col>
            <ContentLine>
              <label>สถานะ</label>
              <Row type="flex" justify="space-between">
                <Col onClick={() => onUpdateStatus('Pass')}>
                  {getIconCheck('Pass')}
                  <TextBD>ผ่าน</TextBD>
                </Col>
                <Col onClick={() => onUpdateStatus('Fail')}>
                  {getIconCheck('Fail')}
                  <TextBD>ไม่ผ่าน</TextBD>
                </Col>
              </Row>

              <SaveBtn>
                <PrimaryButton text="บันทึก" onClick={onSave} />
              </SaveBtn>
            </ContentLine>
          </Col>
        </Row>
      </FooterView>
    </FooterPage>
  )
}

const FooterView = styled.div`
  max-width: 1110px;
  color: rgb(0, 0, 0);
  font-size: 20px;
  margin: 0px auto;
`

const ContentLine = styled.div`
  float: left;
  display: inline-flex;
  align-items: center;

  label {
    width: 37px;
    font-size: 20px;
    font-family: 'DB Helvethaica X 55 Regular';
    margin-right: 24px;
  }
`

const SaveBtn = styled.div`
  width: 160px;
`

const Button = styled.div`
  width: 200px;
  margin-right: 16px;
  float: left;
`

const IconBtn = styled.div`
  img {
    margin-right: 10px;
  }
`

const TextBD = styled.span`
  font-family: 'DB Helvethaica X 75 Bd';
  size : font-size: 24px;
  margin-right : 32px;
  margin-left : 8px;
`

export default Footer
