import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import { QCType, JobType } from 'features/checklist/unit/types'
import ContentBox from 'Components/Shared/Card/ContentBox'
import { Row, Col } from 'antd'
import { AddList, EditDeleteButton } from 'Components/Shared/Button'
import DeleteCheckListJobModal, {
  DeleteWithCheckHandles,
} from 'Components/Shared/Modal/DeleteWithCheckModal'
import { RowSelectedItem, HoverItem } from 'Components/AdminQC/Components'

interface Props {
  item?: QCType
  selected: number
  onSelected: (index: number) => void
  showModal: (tab: number) => void
  onDelete: (it: JobType) => void
  openModalEdit: (obj: JobType, index: number, tab: number) => void
  disabled: boolean
}

const JobBox: React.ComponentType<Props> = ({
  item,
  selected,
  onSelected: onSelectedProps,
  showModal,
  onDelete,
  openModalEdit,
  disabled,
}) => {
  const modalRef = useRef<DeleteWithCheckHandles>(null)

  function onSelected(it: JobType) {
    if (item) {
      const resultIndex = item.JobTypeList.findIndex(
        v => v.JobTypeName === it.JobTypeName
      )
      onSelectedProps(resultIndex)
    }
  }

  const showDeleteModal = (it: JobType) => {
    if (modalRef.current) {
      let detailCount = 0
      it.SubJobTypeList.forEach(de => {
        detailCount += de.DetailList.length
      })
      const menu = [
        `รายการภายใต้ "${it.JobTypeName}" จะถูกลบ`,
        `ลักษณะงานรอง (${it.SubJobTypeList.length} รายการ) จะถูกลบ`,
        `รายละเอียด (${detailCount} รายการ) จะถูกลบ`,
      ]
      const checks = [false, false, false]
      const menuTitle = `ลักษณะงานหลัก: ${it.JobTypeName}`
      modalRef.current.showModal(it, menu, menuTitle, checks)
    }
  }

  const showEditeModal = (it: JobType) => {
    if (item) {
      const resultIndex = item.JobTypeList.findIndex(
        v => v.JobTypeName === it.JobTypeName
      )
      openModalEdit(it, resultIndex, 1)
    }
  }

  let content: JSX.Element[] = []

  if (item) {
    content = item.JobTypeList.map((it: JobType, index: number) => {
      return (
        <RowSelectedItem
          key={index}
          title={it.JobTypeName}
          leftSpan={14}
          isSelected={index === selected}
          onClick={() => onSelected(it)}
          right={
            <HoverItem
              text={<span>{it.Score} คะแนนเต็มกลุ่ม</span>}
              icon={
                <EditDeleteButton
                  showEditeModal={() => showEditeModal(it)}
                  showDeleteModal={() => showDeleteModal(it)}
                  disableDelete={disabled}
                />
              }
            />
          }
        />
      )
    })
  }

  return (
    <>
      <ContentBox title="ลักษณะงานหลัก" isEmpty={item == null}>
        <ListSection>
          {content}
          {!disabled && <AddList onClick={() => showModal(1)} />}
        </ListSection>
      </ContentBox>
      <DeleteCheckListJobModal ref={modalRef} onOk={onDelete} />
    </>
  )
}

const ListSection = styled.div`
  margin-top: 16px;
  height: 256px;
  overflow: auto;
  padding: 0 8px;
  padding-bottom: 30px;
`

export default JobBox
