import withAPIAction from 'utils/withAPIAction'
import { verifyReq } from 'utils/verify'
import {
  GetConstructPlanProjectAction,
  CreateConstructPlanAction,
  GetAuditPlanProjectAction,
  CreateAuditPlanAction,
} from './actions'

import { GetProjectsReq, CreatePlanReq } from './types'

// construct
export const getProjectForConstructPlan = (
  token: string,
  data: GetProjectsReq
) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<GetProjectsReq>({
    method: 'POST',
    path: 'GetProjectForConstructPlan',
    data,
    token: `Bearer ${token}`,
  })(GetConstructPlanProjectAction)
}

export const createConstructPlanByQC = (token: string, data: CreatePlanReq) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<CreatePlanReq>({
    method: 'POST',
    path: 'CreateConstructPlanByQC',
    data,
    token: `Bearer ${token}`,
  })(CreateConstructPlanAction)
}

// audit

export const getProjectForAuditPlan = (token: string, data: GetProjectsReq) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<GetProjectsReq>({
    method: 'POST',
    path: 'GetProjectForAuditPlan',
    data,
    token: `Bearer ${token}`,
  })(GetAuditPlanProjectAction)
}

export const createAudittPlanByQC = (token: string, data: CreatePlanReq) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<CreatePlanReq>({
    method: 'POST',
    path: 'CreateAuditPlanByQC',
    data,
    token: `Bearer ${token}`,
  })(CreateAuditPlanAction)
}
