import React from 'react'
import RootState from 'typings/RootState'
import { BrowserRouter, Switch, Route } from 'react-router-dom'

import { Layout } from 'antd'
import TopNav from 'Components/Shared/TopNav'
import TokenExpired from 'Components/Pages/TokenExpired'

import MainConDashboard from 'Components/Maincon/Dashboard'
import MainConActivity from 'Components/Maincon/ActivityDetail'
import MainconSeeMore from 'Components/Maincon/Seemore'
import ActivityReport from 'Components/CM/Report'
import UnitReport from 'Components/Maincon/UnitReport'
import ZoneReport from 'Components/Maincon/ZoneReport'
import FunctionCheck from 'Components/QC/Unit/FunctionCheck'
import CommissioningDetail from 'Components/Maincon/CommissioningDetail'

import TestCard from 'Components/Test'
import { QueryParamProvider } from 'use-query-params'
import Siricon from './Siricon'

type Props = {}

const MainconApp: React.FunctionComponent<Props> = props => {
  return (
    <BrowserRouter>
      <QueryParamProvider ReactRouterRoute={Route}>
        <Layout className="layout" hasSider={false}>
          <TopNav title="MAINCON" />
          <Route exact path="/" component={MainConDashboard} />
          <Switch>
            <Route exact path="/test" component={TestCard} />
            <Route exact path="/dashboard" component={MainConDashboard} />
            <Route exact path="/activity/:code" component={MainConActivity} />
            <Route
              exact
              path="/qcreport/:unit/:qctype/:requestID"
              component={UnitReport}
            />
            <Route
              exact
              path="/zone.report/:unit/:qcType/:requestID/:floor"
              component={ZoneReport}
            />
            <Route exact path="/seemore/:type" component={MainconSeeMore} />

            <Route
              exact
              path="/unit/function/:project/:unit/:qcType/:requestID/:checkno/:qcTypeVersion"
              component={FunctionCheck}
            />

            <Route
              exact
              path="/commissioning/detail/:checkListID/:activityID/:requestID"
              component={CommissioningDetail}
            />

            <Route exact path="/siricon-plan" component={Siricon} />

            <Route exact path="/token-expired" component={TokenExpired} />
          </Switch>
        </Layout>
      </QueryParamProvider>
    </BrowserRouter>
  )
}

export default MainconApp
