import React from 'react'
import { Spin, Icon } from 'antd'

interface Props {
  color?: string
  size?: number
}

const Loading = (props: Props) => {
  const { color, size = 24 } = props
  return (
    <Spin
      indicator={<Icon type="loading" style={{ color, fontSize: size }} spin />}
    />
  )
}

export default Loading
