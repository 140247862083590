/* eslint-disable */
export default {
  Common: {
    cancel_all: 'ยกเลิกทั้งหมด',
    month: 'เดือน',
    ok: 'ปิดหน้าต่างนี้',
    save: 'จัดเก็บ',
    week: 'สัปดาห์',
    logout: 'ออกจากระบบ',
    day: 'วัน',
    dialogOk: 'ตกลง',
    picture: 'รูปภาพ',
    document: 'เอกสาร',
    pick_picture: 'เลือกรูปภาพ',
    pick_document: 'เลืือกเอกสาร',
    cancel: 'ยกเลิิก',
  },
  Error: {
    login_error: 'Login Error',
    filie_size_limit:
      'ขออภัย ขนาดไฟล์ของคุณเกินจำนวนที่กำหนด\nกรุณาอัพโหลดใหม่อีกครั้ง',
    file_wrong: 'ขออภัย ไม่สามารถใช้ไฟล์นี้ได้ กรุณาอัพโหลดใหม่อีกครั้ง',
  },
  Menu: {
    profile: 'Profile',
  },
  Login: {
    login: 'เข้าสู่ระบบด้วย Sansiri Account',
    service_token_error:
      'พบปัญหาทางเทคนิค ไม่สามารถ retrieve API service token ได้ กรุณา Clear cache และ Login ใหม่',
    connection_in_progress:
      'Please wait, trying to connect to backend service. This page will automatically redirect to dashboard after successful connection.',
    platform_name: 'High Level Platform',
    logout_in_progress: 'Logout in-progress.',
    logout_success_message: 'User logged out successfully.',
    login_get_token_for_username: 'Getting TokenCode from API for %{username}',
    accesstoken_acquired: 'accesstoken acquired',
    logout_modal_description: 'คุณต้องการออกจากระบบใช่ไหม',
  },
  Dashboard: {
    actual_end_date: 'วันเริ่มงานจริง',
    actual_start_date: 'วันแล้วเสร็จจริง',
    approved: 'อนุมัติแล้ว',
    category: 'หมวดหมู่',
    custom: 'กำหนดเอง',
    day_work: 'วันทำงาน',
    duration: 'ช่วงเวลา',
    filter: 'กรองข้อมูล',
    not_approved: 'ไม่ผ่านอนุมัติ',
    pending: 'รออนุมัติ',
    progress: 'ความคืบหน้า',
    search_placeholder: 'พิมพ์ชื่องานที่ต้องการค้นหา...',
    status: 'สถานะ',
    todo: 'สิ่งที่ต้องทำ',
    header_in_progress: 'กำลังดำเนินการ',
    header_todo: 'สิ่งที่ต้องทำ',
    header_complete: 'เสร็จสิ้น',
    header_sort_by_recent_update: 'วันที่เริ่มงานล่าสุด',
    tasklane_empty_record: 'ไม่มีรายการ',
    tasklane_loading: 'Loading',
    tasklane_seemore: 'ดูเพิ่มเติม',
    project_list_empty_message: 'คุณยังไม่มีโปรเจค',
    todo_label: 'สิ่งที่ต้องทำ',
    inprogress_label: 'กำลังดำเนินการ',
    complete_label: 'เสร็จสิ้น',
    header_sort_by_last_update: 'วันสิ้นสุดงานล่าสุด',
    project_select: 'เลือกโปรเจค',
    plan_current_start_date: 'แผนวันเริ่มงานปัจจุบัน',
    plan_current_end_date: 'แผนวันสิ้นสุดงานปัจจุบัน',
    construction_type: 'ประเภทก่อสร้าง',
    floor: 'ชั้น',
    unit: 'ยูนิต',
    zone: 'โซน',
    type: 'ประเภทงาน',
    advance: 'ขั้นสูง',
    tower: 'ตึก',
  },
  Module: {
    title: 'กรุณาเลือก Module',
  },
  ActivityDetail: {
    history_request: 'ประวัติการส่งอนุมัติ',
    status_label: 'สถานะ',
    wbs_label: 'หมวดหมู่',
    reject_reason: 'เหตุผลที่ไม่อนุมัติ',
    day_of_work_label: 'วันทำงาน',
    progress_label: 'ความคืบหน้า',
    submit_button_label: 'ส่งคำขออนุมัติ',
    plan_start_date_label: 'แผนวันที่เริ่มงาน',
    plan_end_date_label: 'แผนวันที่สิ้นสุดงาน',
    saving: 'บันทึกข้อมูล',
    start_date: 'แผนวันเริ่มงานปัจจุบัน',
    end_date: 'แผนวันสิ้นสุดงานปัจจุบัน',
    actual_start_date: 'วันเริ่มงานจริง',
    actual_end_date: 'วันแล้วเสร็จจริง',
    baseline_start_date: 'แผนวันเริ่มงาน',
    baseline_end_date: 'แผนวันสิ้นสุดงาน',
    expect_end_date: 'วันที่คาดแล้วเสร็จ',
    picture: 'รูปภาพ',
    picture_note: 'จำกัด 5 รูป  ขนาดไม่เกิน 5MB ต่อไฟล์ (.png .jpg .HEIC)',
    document: 'เอกสาร',
    document_note:
      'จำกัด 5 ไฟล์  ขนาดไม่เกิน 10MB ต่อไฟล์ (.docx, .pdf, .xls, csv)',
    note: 'หมายเหตุ',
    work_progress: 'ความคืบหน้างาน',
    created_date: 'วันที่่สร้าง',
    upload_date: 'วันที่อัพโหลด',
    time_unit: 'น.',
  },
  TaskLane: {
    delayed_by_day: 'เริ่มช้ากว่ากำหนด',
    start_within_day: 'เริ่มงานภายใน',
    plan_start_date: 'วันที่เริ่ม',
    plan_end_date: 'วันที่สิ้นสุด',
    init_text_th: 'init',
    appv_text_th: 'อนุมัติแล้ว',
    pend_text_th: 'รออนุมัติ',
    rejt_text_th: 'ไม่อนุมัติ',
  },
  TopNav: {
    logout_modal_description: 'คุณต้องการออกจากระบบใช่ไหม',
    logout_modal_confirm_description:
      'Please wait, you are being logged out. \nAfter logout you will be redirected to login page.',
    logout_modal_ok_label: 'ออกจากระบบ',
    logout_modal_cancel_label: 'ยกเลิก',
  },
  RequireAuth: {
    back: 'กลับไปหน้าเข้าสู่ระบบ',
    error: 'คุณไม่มีสิทธิ์ในการเข้าใช้งานเว็บไซต์นี้',
    contact: 'กรุณาติดต่อแอดมิน',
  },
  SearchResult: {
    search_results_label: 'ผลการค้นหา',
    search_item_not_found: 'ขออภัย เราไม่พบรายการที่คุณค้นหา',
    change_search_keyword: 'กรุณาเปลี่ยนรายละเอียดการค้นหาและลองอีกครั้ง',
  },
  Breadcrumb: {
    dashboard: 'งานทั้งหมด',
    home: 'home',
    search_results: 'ผลการค้นหา',
    seemore: 'ดูเพิ่มเติม',
  },
  Pagination: {
    show: 'Show',
    of: 'of',
    entries: 'entries',
  },
  TokenExpired: {
    title: 'ของคุณหมดอายุ',
    content: 'Token ของคุณหมดอายุ กรุณาล๊อคอินใหม่',
  },
  ActivityHistory: {
    description_req: 'ส่งคำขอสถานะงานเป็น',
    description_rejt: 'ไม่อนุมัติสถานะงาน',
    description_appv: 'อนุมัติสถานะงาน',
    status_appv: 'อนุมัติแล้ว',
    column_date: 'วันที่',
    column_time: 'เวลา',
    column_description: 'รายละเอียด',
    column_role: 'ผู้ทำรายการ',
    column_status: 'สถานะ',
    column_file: 'ไฟล์แนบ',
    column_remark: 'หมายเหตุ',
  },
}
