import React, { useState, useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import RootState from 'typings/RootState'
import { connect, useDispatch } from 'react-redux'

import { BreadcrumbLink } from 'Components/Shared/BreadCrumb/BreadCrumb'
import Activity from 'Components/QC/Activity'

import {
  selectEvent,
  selectCheckListQC,
} from 'features/report/commissioning/selector'

import {
  GetCheckListReq,
  SendCommissioningReq,
} from 'features/report/commissioning/types'

import {
  getCheckListForQC,
  sendCommissioningToQC,
} from 'features/report/commissioning/api'

import {
  userEmailSelector,
  serviceTokenSelector,
} from 'features/sensitive/sensitive.selector'
import { selectedProjectCodeSelector } from 'features/project/project.selector'

import EventModal from 'Components/Shared/Modal/EventModal'
import Footer from './Footer'

type FormProps = {}

type Props = FormProps &
  RouteComponentProps<{
    checkListID: string
    activityID: string
  }> &
  ReduxProps

const CommissionActivity = (props: Props) => {
  const [enableModal, setEnableModal] = useState(false)
  const [page, setPage] = useState('')

  const {
    Token,
    ProjectCode,
    UserEmail,

    history,
    match,
    checklist,
    event,
  } = props

  const { checkListID, activityID } = match.params
  const dispatch = useDispatch()

  useEffect(() => {
    const req: GetCheckListReq = {
      ProjectCode,
      UserEmail,
      CheckListID: +checkListID,
      ActivityID: activityID,
      RequestID: 0,
    }

    dispatch(getCheckListForQC(Token, req))
  }, [ProjectCode, checkListID, activityID])

  const onSendReqToQC = (typ: number) => {
    setEnableModal(true)
    const req: SendCommissioningReq = {
      ProjectCode,
      UserEmail,
      CheckListID: +checkListID,
      ActivityID: activityID,
      CommissioningType: typ,
    }

    dispatch(sendCommissioningToQC(Token, req))
  }

  const gotoDashboard = () => {
    setEnableModal(false)
    history.push('/commissioning/dashboard')
  }

  const closeEnableModal = () => setEnableModal(false)

  const breadcrumbs: BreadcrumbLink[] = [
    {
      url: '/commissioning/dashboard',
      title: 'Home',
      icon: '/home.png',
      active: true,
      showTitle: false,
    },
    {
      url: '/commissioning/dashboard',
      title: 'บอร์ด',
      active: true,
      showTitle: true,
    },
  ]

  const footer =
    page === '' ? (
      <Footer
        isLoading={enableModal}
        type={checklist.CheckListType}
        visible={checklist.State === 'CM' && checklist.Status === 'INIT'}
        onSendReqToQC={onSendReqToQC}
      />
    ) : (
      undefined
    )
  return (
    <>
      <EventModal
        event={[event]}
        enable={enableModal}
        successCalback={gotoDashboard}
        errorCalback={closeEnableModal}
      />
      <Activity
        breadcrumbs={breadcrumbs}
        ProjectCode={ProjectCode}
        ActivityID={activityID}
        onPage={name => setPage(name)}
      />
      {footer}
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    Token: serviceTokenSelector(state),
    ProjectCode: selectedProjectCodeSelector(state),
    UserEmail: userEmailSelector(state),

    checklist: selectCheckListQC(state),
    event: selectEvent(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CommissionActivity)
