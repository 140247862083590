import { createStandardAction, createAsyncAction } from 'typesafe-actions'
import {
  GetUnitReportReq,
  GetUnitReportApi,
  UnitDefectType,
  GetCheckListForQCReq,
  GetCheckListForQCApi,
  CreateDefectApi,
  UnitReportItem,
  SendUnitDefectReq,
  UnitReport,
  EditDefectReq,
  SetUnitDefectReq,
} from './types'

export const getUnitReportAction = createAsyncAction(
  'report/unit/FETCH_REPORT_REQUEST',
  'report/unit/FETCH_REPORT_SUCCESS',
  'report/unit/FETCH_REPORT_FAILURE'
)<GetUnitReportReq, GetUnitReportApi, any>()

export const getUnitCheckListForQCReq = createAsyncAction(
  'report/unit/GET_CHECKLIST_QC_REQ',
  'report/unit/GET_CHECKLIST_QC_RESP',
  'report/unit/GET_CHECKLIST_QC_FAIL'
)<GetCheckListForQCReq, GetCheckListForQCApi, any>()

export const resetUnitDefect = createStandardAction(
  'report/unit/RESET_UNIT_DEFECT_ACTION'
)<void>()

export const updateUnitDefect = createStandardAction(
  'report/unit/UPDATE_UNIT_DEFECT_ACTION'
)<UnitDefectType>()

export const createUnitDefectAction = createAsyncAction(
  'report/unit/CREATE_UNIT_DEFECT_REQ',
  'report/unit/CREATE_UNIT_DEFECT_RESP',
  'report/unit/CREATE_UNIT_DEFECT_FAIL'
)<UnitDefectType, CreateDefectApi, any>()

export const updateUnitDefectAction = createAsyncAction(
  'report/unit/UPDATE_UNIT_DEFECT_REQ',
  'report/unit/UPDATE_UNIT_DEFECT_RESP',
  'report/unit/UPDATE_UNIT_DEFECT_FAIL'
)<UnitDefectType, CreateDefectApi, any>()

export const setUnitDefectAction = createAsyncAction(
  'report/unit/SET_UNIT_DEFECT_REQ',
  'report/unit/SET_UNIT_DEFECT_RESP',
  'report/unit/SET_UNIT_DEFECT_FAIL'
)<SetUnitDefectReq, CreateDefectApi, any>()

export const mapUnitDefectData = createStandardAction(
  'report/unit/MAP_UNIT_DEFECT_ACTION'
)<UnitReportItem>()

export const submitPinReq = createStandardAction('report/unit/SUBMIT_REQUEST')<
  SendUnitDefectReq
>()

export const updateReport = createStandardAction(
  'report/unit/UPDATE_Report_ACTION'
)<UnitReport>()

export const submitEditDefect = createStandardAction(
  'report/unit/SUBMIT_EDIT_DEFECT'
)<{ request: EditDefectReq; token: string }>()

export const sendDefectForQCEditAction = createAsyncAction(
  'report/unit/EDIT_UNIT_DEFECT_REQ',
  'report/unit/EDIT_UNIT_DEFECT_RESP',
  'report/unit/EDIT_UNIT_DEFECT_FAIL'
)<EditDefectReq, CreateDefectApi, any>()

export const resetEvent = createStandardAction(
  'report/unit/RESET_EVENT_ACTION'
)<void>()
