import React, { useEffect } from 'react'
import { Row, Col, Input } from 'antd'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import moment from 'moment'

import { MainconTask, CurrentActivity } from 'features/maincon/activity/types'
import { Activity } from 'features/cmpj/activity/types'

import imgHistory from 'assets/img/ic-history.png'
import { StatusOfTask } from 'features/types/status'
import { getStatusText } from 'utils/getText'
import ContructionTagName from 'Components/Shared/ContructionTagName'
import ImagePicker from 'Components/Shared/ImagePicker'
import DocumentPicker from 'Components/Shared/DocumentPicker'
import { toDateText, toTimeText, toMomentDateTime } from 'utils/datetime'

type Props = {
  original: Activity
  onHistoryOpen?: () => void
  onImageOpen(list?: any[], readOnly?: boolean): void
  onDocumentOpen(list?: any[], readOnly?: boolean): void
  onThumbnailOpen(index: number, list?: any[], readOnly?: boolean): void
}

const MainConTaskInfo: React.FunctionComponent<Props> = ({
  original,
  onHistoryOpen,
  onImageOpen,
  onDocumentOpen,
  onThumbnailOpen,
}) => {
  const appointmentDate = moment(original.AppointmentDate, 'DD/MM/YYYY HH:mm')
  const mainconInfo = (
    <Row type="flex" justify="space-between">
      <Col md={6}>
        <TextItem>
          ผู้รับเหมา
          <div>{original.SowName}</div>
        </TextItem>
      </Col>
      <Col md={6}>
        <TextItem>
          วันที่นัดหมายตรวจ
          <div>{appointmentDate.format('DD/MM/YYYY')}</div>
        </TextItem>
      </Col>

      <Col md={6}>
        <TextItem>
          ช่วงเวลา
          <div>{appointmentDate.format('HH:mm')}</div>
        </TextItem>
      </Col>

      <Col md={6}>
        <TextItem>
          หมายเหตุ
          <div>{original.McRemark}</div>
        </TextItem>
      </Col>
    </Row>
  )

  const fileInfo = (
    <FileInfo>
      <Row type="flex" justify="space-between">
        <Col md={12}>
          <ImagePicker
            Images={original.McImages || []}
            onImageOpen={() => onImageOpen(original.McImages, true)}
            onThumbnailOpen={index =>
              onThumbnailOpen(index, original.McImages, true)
            }
            disabled={true}
            showMax={4}
            viewMode={true}
          />
        </Col>
        <Col md={12}>
          <DocumentPicker
            Documents={original.McDocuments || []}
            onDocumentOpen={() => onDocumentOpen(original.McDocuments, true)}
            disabled={false}
            showMax={4}
            viewMode={true}
          />
        </Col>
      </Row>
    </FileInfo>
  )

  return (
    <MainView>
      <TextHeader>รายละเอียดจากผู้รับเหมา</TextHeader>
      <BoxItem>
        {mainconInfo}
        <Space />
        {fileInfo}
      </BoxItem>
    </MainView>
  )
}

const MainView = styled.div`
  margin-top: 40px;
`
const TextHeader = styled.div`
  font-size: 28px;
  font-family: 'DB Helvethaica X 75 Bd';

  span {
    font-family: 'DB Helvethaica X 55 Regular';
    padding-left: 4px;
  }
`

const TextItem = styled.div`
  font-size: 20px;
  font-family: 'DB Helvethaica X 75 Bd';

  div {
    font-family: 'DB Helvethaica X 55 Regular';
    word-break: break-all;
  }
`

const Space = styled.div`
  margin-top: 24px;
`

const FileInfo = styled.div``

const BoxItem = styled.div`
  background-color: #fff;
  margin-top: 12px;
  border-radius: 5px;
  border: solid 1px #f2f2f2;
  padding: 24px;
  line-height: normal;
`

export default MainConTaskInfo
