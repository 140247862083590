import { createAsyncAction, createStandardAction } from 'typesafe-actions'

import { FetchCheckListReq, GetCheckListApi } from './types'

export const getCheckListReq = createAsyncAction(
  'checklist/local/GET_CHECK_LIST_REQ',
  'checklist/local/GET_CHECK_LIST_RESP',
  'checklist/local/GET_CHECK_LIST_FAIL'
)<FetchCheckListReq, GetCheckListApi, Error>()

export const importMasterCheckListReq = createAsyncAction(
  'checklist/local/IMPORT_MASTER_CHECKLSIT_REQ',
  'checklist/local/IMPORT_MASTER_CHECKLSIT_RESP',
  'checklist/local/IMPORT_MASTER_CHECKLSIT_FAIL'
)<
  {
    ProjectCode: string
    SelectedAll: boolean
    selectList: string[]
  },
  any,
  Error
>()

export const clearLocalCheckList = createStandardAction(
  'checklist/local/CLEAR'
)<void>()
