import { put, take, takeEvery, select } from 'redux-saga/effects'
import { cloneDeep } from 'lodash'
import { APIPath } from 'services/APIPath'
import { getType } from 'typesafe-actions'
import * as actions from './actions'

import {
  createUnitDefect,
  updateUnitDefect,
  setUnitDefect,
  sendDefectForQCEdit,
} from './api'
import { UnitDefectType, SetUnitDefectReq } from './types'
import { selectReport } from './selector'

import { uploadFile } from '../../uploadfile/func'
import { getUserProfile } from 'features/sensitive/sensitive.selector'

function* uploadFileList(
  userEmail: string,
  token: string,
  pin: UnitDefectType,
  username: string
) {
  const { ImageBefore, ImageAfter } = pin
  if (ImageBefore && ImageBefore.file) {
    pin.ImageBefore = yield uploadFile(userEmail, token, username, ImageBefore)
  }

  if (ImageAfter && ImageAfter.file) {
    pin.ImageAfter = yield uploadFile(userEmail, token, username, ImageAfter)
  }

  return cloneDeep(pin)
}
function* sendPinReq(action: any) {
  const { Token, Pin, CheckNumber } = action.payload
  try {
    const { PinID, ProjectCode, UserEmail } = Pin

    const report = yield select(selectReport)
    const profile = yield select(getUserProfile)
    const { FirstName = '', LastName = '' } = profile
    const moduleApp = report.State === 'CM-QC' ? 'CM' : 'QC'
    const username = `${FirstName} ${LastName} (${moduleApp})`

    const newPin = yield uploadFileList(UserEmail, Token, Pin, username)
    if (newPin.PinStatus === 'GoodWork') {
      delete newPin.DetailID
    }
    if (PinID === 0) {
      newPin.CheckNumber = CheckNumber
      yield put(createUnitDefect(Token, newPin))
    } else {
      if (newPin.CheckNumber !== CheckNumber) {
        // update some part pin
        const setPinReq: SetUnitDefectReq = {
          ProjectCode,
          UserEmail,
          PinID: newPin.PinID,
          PinNumber: newPin.PinNumber,
          Unit: newPin.Unit,
          CheckListID: newPin.CheckListID,
          RequestID: newPin.RequestID,
          QCTypeID: newPin.QCTypeID,
          CheckNumber: newPin.CheckNumber,
          PinStatus: newPin.PinStatus,
          Remark: newPin.Remark,
          ImageAfter: newPin.ImageAfter,
        }
        yield put(setUnitDefect(Token, setPinReq))
      } else {
        yield put(updateUnitDefect(Token, newPin))
      }
    }
  } catch (e) {
    console.log('e', e.message)
  }
}

function* submitEditDefect(action: any) {
  const { UserEmail, AfterImageList } = action.payload.request
  const token = action.payload.token
  try {
    yield put(
      sendDefectForQCEdit(token, {
        ...action.payload.request,
        AfterImageList: [],
      })
    )
  } catch (e) {
    console.log('e', e.message)
  }
}

function* allSaga() {
  yield takeEvery(getType(actions.submitPinReq), sendPinReq)
  yield takeEvery(getType(actions.submitEditDefect), submitEditDefect)
}

export default allSaga
