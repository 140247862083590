import React, { useState, useEffect } from 'react'
import RootState from 'typings/RootState'
import { connect, useDispatch } from 'react-redux'
import {
  GetReportReq,
  GetCheckListReq,
  DefectItem,
  GetMainconReq,
} from 'features/report/zone/types'

import {
  userEmailSelector,
  serviceTokenSelector,
} from 'features/sensitive/sensitive.selector'

import {
  getReport,
  getCheckList,
  getMainconList,
} from 'features/report/zone/api'
import { resetDefect, updateDefect } from 'features/report/zone/actions'
import {
  selectEvent,
  selectEventChecklist,
  selectReport,
} from 'features/report/zone/selector'

import EventModal from 'Components/Shared/Modal/EventModal'

import ReportInfo from '../ReportInfo'
import DefectDetail from '../DefectDetail'
import DefectView from '../DefectView'

type Props = {
  title: string
  initPage?: string
  ProjectCode: string
  Unit: string
  QCTypeID: string
  RequestID: number
  Floor: string
  onPage?: (name: string) => void
  readOnly?: boolean
  addMode?: boolean
  isShowAfterImg?: boolean
  refresh?: number
} & ReduxProps

const ZoneDefectReport: React.ComponentType<Props> = props => {
  const [page, setPage] = useState('')
  const [enableModal, setEnableModal] = useState(false)
  const dispatch = useDispatch()

  const {
    Token,
    UserEmail,
    event,
    eventChecklist,
    initPage = '',

    ProjectCode,
    Unit,
    QCTypeID,
    Floor,
    RequestID,
    onPage = (name: string) => {},
    isShowAfterImg,
    readOnly = false,
    addMode = true,
    refresh = 0,
    report,
  } = props

  useEffect(() => {
    setPage(initPage)
    setEnableModal(false)
  }, [initPage])

  useEffect(() => {
    const req: GetCheckListReq = {
      UserEmail,
      ProjectCode,
      Unit,
      QCTypeID,
      Floor,
    }

    dispatch(getCheckList(Token, req))

    const mainconReq: GetMainconReq = {
      UserEmail,
      ProjectCode,
      Unit,
      Zone: 'Common Area',
      QCTypeID,
      Floor,
    }

    dispatch(getMainconList(Token, mainconReq))
  }, [ProjectCode, Unit, QCTypeID, Floor, RequestID])

  useEffect(() => {
    getZoneReport()
  }, [ProjectCode, Unit, QCTypeID, Floor, RequestID, refresh])

  const getZoneReport = () => {
    setEnableModal(true)
    const req: GetReportReq = {
      ProjectCode,
      UserEmail,
      Unit,
      QCTypeID,
      RequestID,
      Floor,
    }

    dispatch(getReport(Token, req))
  }

  const onAdd = () => {
    const name = 'add.defect'
    setPage(name)
    onPage(name)
    dispatch(resetDefect())
  }

  const onEdit = (item: DefectItem) => {
    const name = addMode ? 'edit.defect' : 'view.defect'
    setPage(name)
    onPage(name)
    dispatch(updateDefect(item))
  }

  const onClose = () => {
    setPage('')
    onPage('')
  }

  const onCompeted = () => {
    setPage('')
    onPage('')
    getZoneReport()
  }

  switch (page) {
    case 'add.defect':
    case 'edit.defect':
      return (
        <DefectDetail
          ProjectCode={ProjectCode}
          RequestID={RequestID}
          onClose={onClose}
          addMode={page === 'add.defect'}
          onCompeted={onCompeted}
          report={report}
        />
      )
    case 'view.defect':
      return (
        <DefectView
          ProjectCode={ProjectCode}
          RequestID={RequestID}
          readOnly={readOnly}
          onClose={onClose}
          onCompeted={onCompeted}
        />
      )
    default:
      return (
        <>
          <EventModal
            event={[event, eventChecklist]}
            enable={enableModal}
            errorCalback={() => setEnableModal(false)}
          />
          <ReportInfo
            onAdd={addMode && readOnly === false ? onAdd : undefined}
            onEdit={onEdit}
            isShowAfterImg={isShowAfterImg}
          />
        </>
      )
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    Token: serviceTokenSelector(state),
    UserEmail: userEmailSelector(state),
    event: selectEvent(state),
    eventChecklist: selectEventChecklist(state),
    report: selectReport(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ZoneDefectReport)
