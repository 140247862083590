import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { connect, useDispatch } from 'react-redux'
import { Row, Col, Switch } from 'antd'
import { RouteComponentProps } from 'react-router-dom'
import RootState from 'typings/RootState'
import SubnavBar from 'Components/Shared/SubnavBar'
import { BreadcrumbLink } from 'Components/Shared/BreadCrumb/BreadCrumb'
import Menu from '../../MenuAdmin'
import {
  userEmailSelector,
  serviceTokenSelector,
} from 'features/sensitive/sensitive.selector'
import {
  AdminProject,
  AlertError,
  AlertSuccess,
  LoadingView,
} from 'Components/Shared'
import {
  selectEvent,
  selectDataList,
} from 'features/admin/mapping-template-code/selector'
import ImportBtn from 'Components/Shared/Table/ImportBtn'
import XLSX from 'xlsx'
import { projectsByIdSelector } from 'features/project/project.selector'
import { fetchProjects } from 'features/project/project.api'
import {
  updatePrepareMapChecklistMaincon,
  getDataList,
} from 'features/admin/mapping-template-code/api'
import {
  GetDataListReq,
  ReqUpdatePrepareMapChecklistMaincon,
} from 'features/admin/mapping-template-code/types'
import { TableColHeader, TableHeader, TableRow } from 'Components/Shared/Table'
import { Input } from 'antd'

const breadcrumbs: BreadcrumbLink[] = [
  {
    url: '/dashboard',
    title: 'Home',
    icon: '/home.png',
    active: true,
    showTitle: false,
  },
  {
    url: '/mapping-template-code',
    title: 'Mapping Template Code',
    active: false,
    showTitle: false,
  },
]

type Props = DispatchProps & RouteComponentProps & ReduxProps

const MappingTemplateCode: React.ComponentType<Props> = props => {
  const { token, UserEmail, event, projectsById, dataList } = props
  const dispatch = useDispatch()
  const [projectCode, setProjectCode] = useState('')
  const [activityName, setActivityName] = useState('')

  useEffect(() => {
    dispatch(fetchProjects({ email: UserEmail, token }))
  }, [UserEmail, token])

  useEffect(() => {
    onGetDataList()
  }, [projectCode])

  const onGetDataList = () => {
    const req: GetDataListReq = {
      UserEmail,
      ActivityName: activityName,
      ProjectCode: projectCode,
    }
    dispatch(getDataList(token, req))
  }

  const onImportFile = (data: any, file: any, name: any) => {
    const wb = XLSX.read(data, { type: 'binary' })

    const sheetName = wb.SheetNames[0]
    const rows = wb.Sheets[sheetName]
    let rawData = XLSX.utils.sheet_to_json(rows)

    const updateData: any[] = []
    console.log('rawData', rawData)

    if (rawData)
      rawData.map((raw: any, index) => {
        const item = {
          TemplateCode: raw['TemplateCode'],
          ProcurementTaskID: raw['ProcurementTaskID'],
          ChecklistID: raw['ChecklistID'],
        }
        updateData.push(item)
      })
    console.log('updateData', updateData)
    const req: ReqUpdatePrepareMapChecklistMaincon = {
      UserEmail,
      ProjectCode: projectCode,
      Data: [...updateData],
    }

    onUpdate(req)
  }

  const onUpdate = (req: any) => {
    dispatch(updatePrepareMapChecklistMaincon(token, req))
  }

  const header = (
    <Header>
      <Row type="flex" align="middle" justify="start">
        <Col>
          <Title>Mapping Template Code</Title>
        </Col>
      </Row>
      <Col>
        <Filter>
          <AdminProject
            projectList={projectsById}
            onSelectProject={projectCode => setProjectCode(projectCode)}
            activeProject={projectCode}
          />
          <Input
            key={'input-tag-add'}
            size="large"
            className="tag-input"
            placeholder="ActivityId or ActivityName"
            value={activityName}
            onChange={evt => setActivityName(evt.target.value)}
            onPressEnter={() => onGetDataList()}
          />
        </Filter>
      </Col>
      <Col>
        <ImportBtn
          text={'Import Excel'}
          onChange={onImportFile}
          type={'.xlsx'}
          disabled={projectCode === '' ?? false}
        ></ImportBtn>
      </Col>
    </Header>
  )

  const content = (
    <>
      <TableHeader>
        <TableColHeader span={3} isPadding={true}>
          ProjectCode
        </TableColHeader>
        <TableColHeader span={3}>ActivityID</TableColHeader>
        <TableColHeader span={3}>ActivityName</TableColHeader>
        <TableColHeader span={3}>TemplateCode</TableColHeader>
        <TableColHeader span={3}>ProcurementTaskID</TableColHeader>
        <TableColHeader span={3}>ChecklistID</TableColHeader>
        <TableColHeader span={3}>UpdateBy</TableColHeader>
        <TableColHeader span={3}>UpdateDate</TableColHeader>
      </TableHeader>
      {dataList &&
        dataList.map((row, i) => (
          <TableRow key={i}>
            <Col span={3}>{row.ProjectCode}</Col>
            <Col span={3}>{row.ActivityID}</Col>
            <Col span={3}>{row.ActivityName}</Col>
            <Col span={3}>{row.TemplateCode}</Col>
            <Col span={3}>{row.ProcurementTaskID}</Col>
            <Col span={3}>{row.ChecklistID}</Col>
            <Col span={3}>{row.UpdateBy}</Col>
            <Col span={3}>{row.UpdateDate}</Col>
          </TableRow>
        ))}
    </>
  )

  return (
    <>
      <LoadingView isShow={event.IsLoading} />
      <AlertSuccess
        isShow={event.SubmitSuccess ? true : false}
        text={event.MessageSuccess}
        callback={() => setProjectCode('')}
      />
      <AlertError
        isShow={event.SubmitError ? true : false}
        text={event.SubmitError}
      />
      <SubnavBar breadcrumbs={breadcrumbs} hideSearch={true} />
      <Menu selected={'mapping-template-code'}>
        <MainPage>
          {header}
          {content}
        </MainPage>
      </Menu>
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    dataList: selectDataList(state),
    event: selectEvent(state),
    token: serviceTokenSelector(state),
    UserEmail: userEmailSelector(state),
    projectsById: projectsByIdSelector(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(MappingTemplateCode)

const MainPage = styled.div`
  padding: 40px 45px;
  width: 100%;

  .tag-input {
    border-radius: 10px;
    border: solid 1px #e4e7eb;
    background-color: #fff;
    padding: 20px;
    font-size: 20px;
  }
`

const Header = styled.div`
  height: 50px;
  margin-bottom: 15rem;
`

const Title = styled.h2`
  font-size: 28px;
  font-family: 'DB Helvethaica X 75 Bd';
  height: 30px;
`

const Filter = styled.div`
  margin: 2rem 0;
  width: 30%;
`
