import React from 'react'
import styled from 'styled-components'
import AdminTab from 'Components/Shared/AdminTab'

interface Props {
  selected: 'CommonArea' | 'Commissioning' | 'Cert'
}

const ReportZoneTab: React.FunctionComponent<Props> = props => {
  const tabItem = [
    {
      link: '/report/zone',
      title: 'ส่วนกลางทั่วไป',
      isActive: props.selected == 'CommonArea',
    },
    {
      link: '/report/commissioning',
      title: 'Commissioning & Safety',
      isActive: props.selected == 'Commissioning',
    },
    {
      link: '/report/cert',
      title: 'รายงานใบรับรองคุณภาพ',
      isActive: props.selected == 'Cert',
    },
  ]

  return <AdminTab tabItem={tabItem} />
}

export default ReportZoneTab
