import React from 'react'
import styled from 'styled-components'

interface Props {}

const TestCard: React.FC<Props> = props => {
  return (
    <MainCard>
      <Pin>
        <span>1</span>
      </Pin>
    </MainCard>
  )
}

const MainCard = styled.div`
  background: #fff;
  padding: 40px;
  height: 95vh;
`

const Pin = styled.span`
  position: relative;
  color: white;

  span {
    position: absolute;
    font-size: 22px;
    left: 40%;
  }
`

export default TestCard
