import { getType } from 'typesafe-actions'
import { cloneDeep } from 'lodash'

import * as actions from './actions'
import { StoreState, ReportAction, SelectedPin } from './types'
import produce from 'immer'
import parseErrorMessage from 'utils/parseErrorMessage'
import { sortingByNumber } from 'utils/sorting'
import { Event } from '../../types/event'

const reportQC = {
  CheckListID: 0,
  CheckListName: '',

  ProjectCode: '',
  ProjectNameTH: '',
  ProjectNameEN: '',
  ActivityID: '',

  Unit: '',
  Zone: '',
  QCTypeID: '',
  QCTypeName: '',

  CheckedDate: '',
  InspectDate: '',
  ReturnDate: '',
  CheckNumber: 0,
  InspectionScore: 0,
  BasePoint: 0,
  CheckStatus: '',

  HasSign: false,
  HasSelectPin: false,

  State: 'CM',
  Status: 'INIT',

  DefectList: [],
  LastDefectList: [],
}

const checklistQC = {
  CheckListID: 0,
  CheckListName: '',

  ProjectTypeID: 0,
  ProjectTypeName: '',

  ScoreRange: {
    PassMinPoint: 0,
    PassMaxPoint: 0,
    ToFixMinPoint: 0,
    ToFixMaxPoint: 0,
    NoPassMinPoint: 0,
    NoPassMaxPoint: 0,
  },
  DefectTypeList: [],
  WorkTypeList: [],
}

const defect = {
  RequestID: 0,
  PinID: 0,
  PinNumber: 0,
  CheckNumber: 0,

  DefectTypeID: 0,
  DefectTypeName: '',
  IsNCR: false,

  WorkTypeID: 0,
  WorkTypeName: '',
  JobTypeID: 0,
  JobTypeName: '',
  SubJobTypeID: 0,
  SubJobTypeName: '',
  DetailID: 0,
  DetailName: '',

  Remark: '',
  CreatedBy: '',
  Score: 0,
  IsNCRScore: false,
  PinStatus: 'ToBeFix',

  ImageLocation: undefined,
  ImageBefore: undefined,
  ImageAfter: undefined,
  IsRequired: false,
  IsSupplier: false,

  UnitList: [],

  VerifyStatus: '',
  VerifyRemark: '',
  HowToFix: '',
}

const initEvent = {
  IsLoading: false,
  MessageSuccess: undefined,
  SubmitError: undefined,
  SubmitSuccess: false,
  SubmitType: undefined,
} as Event

const initPinList = {
  PinList: [],
} as SelectedPin

const initialState: StoreState = {
  report: cloneDeep(reportQC),
  event: cloneDeep(initEvent),
  eventChecklist: cloneDeep(initEvent),

  checkListQC: cloneDeep(checklistQC),
  defect: cloneDeep(defect),
  pinSelected: cloneDeep(initPinList),
}

export default function(
  state: StoreState = initialState,
  action: ReportAction
): StoreState {
  return produce(state, draft => {
    switch (action.type) {
      case getType(actions.getReportAction.request):
        draft.event = cloneDeep(initEvent)
        draft.event.IsLoading = true
        draft.report = cloneDeep(reportQC)

        break
      case getType(actions.getReportAction.success):
        draft.event.IsLoading = false
        const response = action.payload.ResponseData
        const { CheckNumber } = response
        const DefectList = response.DefectList.filter(
          (item: any) => item.CheckNumber === CheckNumber
        )
        const LastDefectList = response.DefectList.filter(
          (item: any) => item.CheckNumber !== CheckNumber
        )
        response.DefectList = sortingByNumber(DefectList, 'asc', 'PinNumber')

        response.LastDefectList = sortingByNumber(
          LastDefectList,
          'asc',
          'PinNumber'
        )
        draft.report = response
        break

      case getType(actions.resetDefect):
        draft.defect = cloneDeep(defect)
        break
      case getType(actions.updateDefect):
        draft.defect = action.payload
        break

      case getType(actions.getCheckListAction.request):
        draft.eventChecklist = cloneDeep(initEvent)
        draft.eventChecklist.IsLoading = true
        draft.checkListQC = cloneDeep(checklistQC)
        break

      case getType(actions.getCheckListAction.success):
        const checkQC = action.payload.ResponseData
        draft.checkListQC = checkQC
        draft.eventChecklist.IsLoading = false
        break

      case getType(actions.getCheckListAction.failure):
        draft.eventChecklist.IsLoading = false
        draft.eventChecklist.SubmitError = parseErrorMessage(action.payload)
        break

      case getType(actions.createDefectAction.request):
      case getType(actions.updateDefectAction.request):
      case getType(actions.updateSelectedPintAction.request):
        draft.event = cloneDeep(initEvent)
        draft.event.IsLoading = true
        break

      case getType(actions.createDefectAction.success):
      case getType(actions.updateDefectAction.success):
      case getType(actions.updateSelectedPintAction.success):
        draft.event.IsLoading = false
        draft.event.SubmitError = undefined
        draft.event.SubmitSuccess = true
        draft.event.MessageSuccess = 'ทำรายการสำเร็จ'
        break

      case getType(actions.getReportAction.failure):
      case getType(actions.createDefectAction.failure):
      case getType(actions.updateDefectAction.failure):
      case getType(actions.getSelectPinAction.failure):
      case getType(actions.updateSelectedPintAction.failure):
        draft.event.IsLoading = false
        draft.event.SubmitError = parseErrorMessage(action.payload)
        break

      case getType(actions.updateReport):
        draft.report = action.payload
        break

      case getType(actions.resetEvent):
        draft.event = cloneDeep(initEvent)
        break

      case getType(actions.getSelectPinAction.request):
        draft.event.IsLoading = true
        draft.pinSelected = {
          PinList: [],
        }
        break
      case getType(actions.getSelectPinAction.success):
        draft.event.IsLoading = false
        draft.pinSelected = action.payload.ResponseData
        break
      default:
        return state
    }
  })
}
