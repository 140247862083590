import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'antd'
import { PrimaryButton, SecondaryButton } from 'Components/Shared/Button'

type Props = {
  onClickSendRequest: () => void
  onClickStartChecking: () => void
  isLoading?: boolean
  selfCheckTxt?: string
}

const QCByUserFooter: React.FC<Props> = ({
  onClickSendRequest,
  onClickStartChecking,
  isLoading = false,
  selfCheckTxt = 'เริ่มตรวจ QC ด้วยตัวเอง',
}) => {
  return (
    <FooterContent>
      <Row type="flex" justify="space-between" align="middle">
        <Col>
          <div className="btn-submit">
            <SecondaryButton
              text="ส่งคำขอให้ QC"
              disabled={isLoading}
              onClick={onClickSendRequest}
            />
          </div>
        </Col>
        <Col>
          <div className="btn-submit">
            <PrimaryButton
              text={selfCheckTxt}
              disabled={isLoading}
              onClick={onClickStartChecking}
            />
          </div>
        </Col>
      </Row>
    </FooterContent>
  )
}

const FooterContent = styled.div`
  max-width: 1110px;
  margin: 0 auto;
  color: #000;
  font-size: 20px;
  padding-right: 20px;

  .btn-submit {
    width: 200px;
    text-align: right;
  }
`

export default QCByUserFooter
