import withAPIAction from 'utils/withAPIAction'
import {
  startCheckingAction,
  getActivityOnZoneAction,
  sendToMcAction,
  sendToQcAction,
  sendQCEditAction,
} from './actions'
import {
  StartCheckingZoneReq,
  FetchActivityOnZoneReq,
  SendZoneToMcReq,
  SendZoneToQcReq,
  SendZoneQCEditReq,
} from './types'
import { verifyReq } from 'utils/verify'

export const getActivityOnZoneById = (
  data: FetchActivityOnZoneReq,
  token: string
) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<FetchActivityOnZoneReq>({
    method: 'POST',
    path: 'GetActivityOnZoneById',
    data,
    token: `Bearer ${token}`,
  })(getActivityOnZoneAction)
}

export const startCheckingZone = (
  token: string,
  data: StartCheckingZoneReq
) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<StartCheckingZoneReq>({
    method: 'POST',
    path: 'StartCheckingZoneByCM',
    data,
    token: `Bearer ${token}`,
  })(startCheckingAction)
}

export const sendZoneToMcChecking = (token: string, data: SendZoneToMcReq) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<SendZoneToMcReq>({
    method: 'POST',
    path: 'SendZoneToMcForChecking',
    data,
    token: `Bearer ${token}`,
  })(sendToMcAction)
}

export const sendZoneToQcChecking = (token: string, data: SendZoneToQcReq) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<SendZoneToQcReq>({
    method: 'POST',
    path: 'SendZoneToQcForChecking',
    data,
    token: `Bearer ${token}`,
  })(sendToQcAction)
}

export const sendZoneToQcEdit = (token: string, data: SendZoneQCEditReq) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<SendZoneQCEditReq>({
    method: 'POST',
    path: 'SendZoneDefectForQCEdit',
    data,
    token: `Bearer ${token}`,
  })(sendQCEditAction)
}
