import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { connect, useDispatch } from 'react-redux'
import { Row, Col, Drawer } from 'antd'
import { RouteComponentProps } from 'react-router-dom'
import RootState from 'typings/RootState'
import SubnavBar from 'Components/Shared/SubnavBar'
import { BreadcrumbLink } from 'Components/Shared/BreadCrumb/BreadCrumb'
import { TableHeader, TableColHeader } from 'Components/Shared/Table'
import AdminQcTypeDetail from './Detail'
import Menu from '../../MenuAdmin'

import {
  userEmailSelector,
  serviceTokenSelector,
} from 'features/sensitive/sensitive.selector'

import { getDataList, createData, updateData } from 'features/admin/qcType/api'
import { selectDataList, selectEvent } from 'features/admin/qcType/selector'
import {
  GetDataListReq,
  DataItem,
  CreateDataReq,
  UpdateDataReq,
} from 'features/admin/qcType/types'

import RowItem from './RowItem'
import { EventModal } from 'Components/Shared'
import { PrimaryButton } from 'Components/Shared/Button'

const breadcrumbs: BreadcrumbLink[] = [
  {
    url: '/dashboard',
    title: 'Home',
    icon: '/home.png',
    active: true,
    showTitle: false,
  },
  {
    url: '/qc-type',
    title: 'QC Type list',
    active: false,
    showTitle: false,
  },
]

type DefultProps = {
  homepage?: boolean
}

type Props = DefultProps & ReduxProps & DispatchProps & RouteComponentProps

const AdminQcType: React.ComponentType<Props> = props => {
  const { token, UserEmail, dataList, event } = props
  const [mode, setMode] = useState('')
  const [disPlayModal, setdisPlayModal] = useState(false)
  const [selectedItem, setSelectedItem] = useState({} as any)
  const [enableModal, setEnableModal] = useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    onGetList()
    setEnableModal(false)
  }, [])

  const onGetList = () => {
    setEnableModal(true)
    const req: GetDataListReq = {
      UserEmail,
      Status: '',
      UnitType: '',
    }
    dispatch(getDataList(token, req))
  }

  const onCreateQcType = (item: DataItem) => {
    setEnableModal(true)
    const req: CreateDataReq = {
      UserEmail,
      QCTypeID: item.QCTypeID,
      QCTypeName: item.QCTypeName,
      Status: item.Status,
      UnitType: item.UnitType,
    }
    dispatch(createData(token, req))
  }

  const onUpdateQcType = (item: DataItem) => {
    setEnableModal(true)
    const req: UpdateDataReq = {
      UserEmail,
      QCTypeID: item.QCTypeID,
      QCTypeName: item.QCTypeName,
      Status: item.Status,
      UnitType: item.UnitType,
    }
    dispatch(updateData(token, req))
  }

  const onCreate = () => {
    setSelectedItem({} as DataItem)
    setdisPlayModal(true)
    setMode('create')
  }

  const onCloseDrawer = () => {
    setSelectedItem({})
    setdisPlayModal(false)
    setEnableModal(false)
  }

  const onOpenDrawer = (evt: any, item: any) => {
    setSelectedItem(item)
    setMode('update')
    setdisPlayModal(true)
  }

  const onSave = (item: DataItem) => {
    if (mode === 'create') {
      onCreateQcType(item)
    }

    if (mode === 'update') {
      onUpdateQcType(item)
    }
  }

  const onSuccess = () => {
    onCloseDrawer()
    if (mode !== '') {
      setMode('')
      onGetList()
    }
  }

  const content = dataList.List.map((item: DataItem, index: number) => {
    const selected = item.QCTypeID === selectedItem.QCTypeID

    return (
      <RowItem
        item={item}
        selected={selected}
        onClick={onOpenDrawer}
        key={index}
      />
    )
  })
  return (
    <>
      <SubnavBar breadcrumbs={breadcrumbs} hideSearch={true} />
      <EventModal
        name="defect"
        event={[event]}
        enable={enableModal}
        successCalback={onSuccess}
      />
      <Menu selected={'qctype'}>
        <MainPage>
          <Header>
            <Row type="flex" align="middle" justify="space-between">
              <Col>
                <Title>หมวดงาน QC</Title>
              </Col>
              <Col>
                <PrimaryButton
                  text="สร้างหมวดงาน QC"
                  disabled={event.IsLoading}
                  onClick={onCreate}
                />
              </Col>
            </Row>
          </Header>

          <TableHeader>
            <TableColHeader span={6} isPadding={true}>
              ชื่อ
            </TableColHeader>
            <TableColHeader span={4}>Primavera code</TableColHeader>
            <TableColHeader span={4}>ประเภท</TableColHeader>
            <TableColHeader span={4}>สถานะ</TableColHeader>
            <TableColHeader span={4} sort={false}>
              {' '}
            </TableColHeader>
          </TableHeader>
          {content}
        </MainPage>

        <Drawer
          title={null}
          className="drawer-project"
          closable={false}
          width={400}
          visible={disPlayModal}
        >
          <AdminQcTypeDetail
            item={selectedItem}
            onSave={onSave}
            onClose={onCloseDrawer}
            isUpdateMode={mode === 'update'}
          />
        </Drawer>
      </Menu>
    </>
  )
}

const MainPage = styled.div`
  padding: 40px 45px;
  width: 100%;
`

const Header = styled.div`
  margin-bottom: 35px;
  height: 50px;
`

const Title = styled.h2`
  font-size: 28px;
  font-family: 'DB Helvethaica X 75 Bd';
  height: 30px;
`

const Filter = styled.div`
  font-size: 18px;
  font-family: 'DB Helvethaica X 55 Regular';
  height: 22px;
  text-align: right;

  span {
    font-family: 'DB Helvethaica X 75 Bd';
    margin: 0px 8px;
    font-size: 20px;
    color: #003168;
    text-decoration: underline;
  }
`

const mapStateToProps = (state: RootState) => {
  return {
    dataList: selectDataList(state),
    event: selectEvent(state),
    token: serviceTokenSelector(state),
    UserEmail: userEmailSelector(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminQcType)
