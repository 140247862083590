import React from 'react'
import { DropdownList } from 'Components/Shared/Form'

type Props = {
  list: any[]
  label: string
  value: string
  name: string
  active?: any
  onChange?: (value: any) => void
  disabled?: boolean
}

const ItemList: React.FunctionComponent<Props> = props => {
  const {
    list,
    label,
    name,
    value,
    active = '',
    onChange,
    disabled = false,
  } = props

  const menu = list.map((data: any) => {
    return {
      value: data[value],
      name: data[name],
    }
  })

  return (
    <DropdownList
      label={label}
      value={active}
      menu={menu}
      onChange={onChange}
      disabled={disabled}
    />
  )
}

export default ItemList
