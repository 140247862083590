import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { DropdownList } from './Form'
import { ProjectListItem } from 'features/project/project.types'

interface Props {
  projectList: any
  activeProject: string
  onSelectProject: (projectCode: string) => void
  placeholder?: string
}

const AdminProject: React.FC<Props> = props => {
  const {
    projectList,
    activeProject,
    onSelectProject,
    placeholder = 'กรุณาเลือก',
  } = props
  const [projectFilter, setProjectFilter] = useState([] as ProjectListItem[])
  const [searchTxt, setSearchTxt] = useState('')

  useEffect(() => {
    const project: ProjectListItem[] = Object.values(projectList)
    setProjectFilter(project)
  }, [projectList])

  useEffect(() => {
    filterProject()
  }, [searchTxt])

  const getProjectItem = () => {
    let menu = [] as any
    menu.push({
      name: `โครงการทั้งหมด`,
      value: '',
    })

    projectFilter.forEach((item: ProjectListItem, index: number) => {
      menu.push({
        name: renderName(item),
        value: item.ProjectCode,
      })
    })
    return menu
  }

  const hasText = (data: string, searchTxt: string) => {
    if (data && searchTxt) {
      return data.toLocaleLowerCase().includes(searchTxt.toLocaleLowerCase())
    }
    return false
  }

  const filterProject = () => {
    let list: ProjectListItem[] = Object.values(projectList)
    if (searchTxt !== '') {
      list = list.filter((item: ProjectListItem) => {
        return (
          hasText(item.ProjectCode, searchTxt) ||
          hasText(item.ProjectNameTH, searchTxt) ||
          hasText(item.ProjectNameEN, searchTxt)
        )
      })
    }
    setProjectFilter(list)
  }

  const renderName = (item: ProjectListItem) => {
    return `${item.ProjectCode}  - ${item.ProjectNameTH}`
  }

  const getActiveProject = (projectCode: string) => {
    const project: ProjectListItem[] = Object.values(projectList)
    const projectActive = project.find(item => item.ProjectCode === projectCode)
    return projectActive ? renderName(projectActive) : ''
  }

  return (
    <DropdownList
      label="โครงการ"
      value={activeProject}
      placeholder={
        activeProject ? getActiveProject(activeProject) : placeholder
      }
      menu={getProjectItem()}
      onChange={onSelectProject}
      hasSearch={true}
      onSearch={setSearchTxt}
    />
  )
}

const DataItem = styled.div`
  padding: 5px 10px;

  &:hover {
    cursor: pointer;
    color: white;
    background-color: #003168;
  }
`

export default AdminProject
