import withAPIAction from 'utils/withAPIAction'
import { verifyReq } from 'utils/verify'
import {
  getActivity,
  updateActivity,
  updateMSActivity,
  getMSActivity,
} from './actions'
import { GetActivityReq, UpdateActivityReq, MSGetActivityReq } from './types'

export const getActivityById = (token: string, data: GetActivityReq) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<GetActivityReq>({
    method: 'POST',
    path: 'GetQCActivityById',
    data,
    token: `Bearer ${token}`,
  })(getActivity)
}

export const saveActionActivityForPJ = (
  data: UpdateActivityReq,
  token: string
) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<UpdateActivityReq>({
    method: 'POST',
    path: 'SaveActionActivityForPJ',
    data,
    token: `Bearer ${token}`,
  })(updateActivity)
}

export const saveMSActionActivity = (
  data: UpdateActivityReq,
  token: string
) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<UpdateActivityReq>({
    method: 'POST',
    path: 'SaveActionActivity',
    data,
    token: `Bearer ${token}`,
  })(updateMSActivity)
}

export const getMSActivityById = (token: string, data: MSGetActivityReq) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<MSGetActivityReq>({
    method: 'POST',
    path: 'GetActivityById',
    data,
    token: `Bearer ${token}`,
  })(getMSActivity)
}
