import { createStandardAction, createAsyncAction } from 'typesafe-actions'
import {
  GetDataListReq,
  GetDataListResp,
  CreateDataReq,
  UpdateDataReq,
  CommonResp,
  DeleteDataReq,
} from './types'

export const getDataListAction = createAsyncAction(
  'admin/team/FETCH_LIST_REQUEST',
  'admin/team/FETCH_LIST_SUCCESS',
  'admin/team/FETCH_LIST_FAILURE'
)<GetDataListReq, GetDataListResp, any>()

export const createDataAction = createAsyncAction(
  'admin/team/CREATE_DATA_REQ',
  'admin/qcTteamype/CREATE_DATA_RESP',
  'admin/team/CREATE_DATA_FAIL'
)<CreateDataReq, CommonResp, any>()

export const updateDataAction = createAsyncAction(
  'admin/team/UPDATE_DATA_REQ',
  'admin/team/UPDATE_DATA_RESP',
  'admin/team/UPDATE_DATA_FAIL'
)<UpdateDataReq, CommonResp, any>()

export const deleteDataAction = createAsyncAction(
  'admin/team/DELETE_DATA_REQ',
  'admin/team/DELETE_DATA_RESP',
  'admin/team/DELETE_DATA_FAIL'
)<DeleteDataReq, CommonResp, any>()

export const resetEvent = createStandardAction('admin/team/RESET_EVENT_ACTION')<
  void
>()
