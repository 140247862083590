import React, { useState } from 'react'
import { Col } from 'antd'
import styled from 'styled-components'
import { TableRow } from '../../../Shared/Table'
import { EditIcon, TrashIcon } from '../../../Shared/Icon'
import { Link } from 'react-router-dom'
import { CheckListItem } from 'features/checklist/unit/types'

type Props = {
  item: CheckListItem
  showDeleteModal: (item: CheckListItem) => void
}

const RowItem: React.FC<Props> = props => {
  const { item, showDeleteModal } = props

  return (
    <RowData>
      <TableRow minHeight={60} spaceBetweenRow={8} paddingLeft={27}>
        <Col span={7}>
          <Link to={`/check-construct-plan/${item.CheckListID}/edit`}>
            <Name>{item.CheckListName}</Name>
          </Link>
        </Col>
        <Col span={3}>{item.ProjectTypeName}</Col>
        <Col span={4}>{item.ProjectUsedCounter}</Col>
        <Col span={3}>{item.CreatedDate}</Col>
        <Col span={3} className="ellipsis">
          {item.UpdatedDate} - โดย {item.UpdatedBy}
        </Col>
        <Col span={3} offset={1}>
          <Link to={`/check-construct-plan/${item.CheckListID}/edit`}>
            <Icon>
              <EditIcon size={20} color="#8b99a7" />
            </Icon>
          </Link>

          {item.Status === 'Ready' && (
            <Icon onClick={() => showDeleteModal(item)}>
              <TrashIcon size={20} color="#8b99a7" />
            </Icon>
          )}
        </Col>
      </TableRow>
    </RowData>
  )
}

const Name = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  color: #000;
`
const Icon = styled.div`
  float: left;
  margin-right: 20px;
  margin-top: 5px;
  cursor: pointer;
`

const RowData = styled.div`
  .ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

export default RowItem
