import React, { useEffect } from 'react'
import SubnavBar from '../Shared/SubnavBar'
import { Layout, Modal, Row } from 'antd'
import { BreadcrumbLink } from '../Shared/BreadCrumb/BreadCrumb'
import { logoutUser } from 'features/login/login.actions'
import { translate } from 'i18n'
import TKeys from 'i18n/translationKey'
import { connect } from 'react-redux'

const { Content } = Layout

const breadcrumbs: BreadcrumbLink[] = [
  {
    url: '/dashboard',
    title: 'Home',
    icon: '/home.png',
    active: true,
    showTitle: true,
  },
]

type Props = DispatchProps

const TokenExpired: React.FunctionComponent<Props> = ({ logoutUser }) => {
  const showLogoutPopup = () => {
    const modal = Modal.error({
      title: translate(TKeys.TokenExpired.title),
      content: translate(TKeys.TokenExpired.content),
      onOk() {
        logoutUser()

        modal.update({
          content: translate(TKeys.TokenExpired.content),
          okButtonProps: {
            className: 'btn-rectangle ant-btn-lg',
            disabled: true,
            loading: true,
          },
          cancelButtonProps: {
            className: 'btn-rectangle ant-btn-lg',
            disabled: true,
          },
        })

        return () => {
          modal.destroy()
        }
      },
      okText: translate(TKeys.TopNav.logout_modal_ok_label),
      okButtonProps: {
        className: 'btn-rectangle ant-btn-lg',
      },
      className: 'login-modal-window',
    })
  }

  useEffect(() => {
    showLogoutPopup()
  }, [])

  return (
    <>
      <SubnavBar breadcrumbs={breadcrumbs} />
      <Content className="mid-content">
        <div className="main-content search-result-page">
          <Row
            style={{
              marginBottom: '35px',
              display: 'flex',
              justifyContent: 'center',
              alignContent: 'center',
            }}
          >
            <div>Your token has expired. Please re-login to continue.</div>
          </Row>
        </div>
      </Content>
    </>
  )
}

const mapDispatchToProps = {
  logoutUser,
}

type DispatchProps = typeof mapDispatchToProps

export default connect(
  null,
  mapDispatchToProps
)(TokenExpired)
