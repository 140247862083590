import { createStandardAction, createAsyncAction } from 'typesafe-actions'
import { GetReportListReq, GetReportListResp, Filter } from './types'

export const getReportListAction = createAsyncAction(
  'admin/report/score/GET_LIST_REQ',
  'admin/report/score/GET_LIST_RESP',
  'admin/report/score/GET_LIST_FAIL'
)<GetReportListReq, GetReportListResp, any>()

export const updateFilter = createStandardAction(
  'admin/report/score/UPDATE_FILTER_ACTION'
)<Filter, void>()

export const clearFilter = createStandardAction(
  'admin/report/score/CLEAR_FILTER_ACTION'
)<void>()

export const exportReportListAction = createAsyncAction(
  'admin/report/score/EXPORT_LIST_REQ',
  'admin/report/score/EXPORT_LIST_RESP',
  'admin/report/score/EXPORT_LIST_FAIL'
)<GetReportListReq, GetReportListResp, any>()
