import React, { useState, useEffect } from 'react'
import RootState from 'typings/RootState'
import { connect, useDispatch } from 'react-redux'
import styled from 'styled-components'
import { Drawer } from 'antd'
import { RouteComponentProps } from 'react-router-dom'

import EventModal from 'Components/Shared/Modal/EventModal'
import { BreadcrumbLink } from 'Components/Shared/BreadCrumb/BreadCrumb'
import SubnavBar from 'Components/Shared/SubnavBar'
import {
  TableHeader,
  TableColHeader,
  LineHeader,
} from 'Components/Shared/Table'

import Pagination from 'Components/Shared/Pagination'

import { selectCheckList, selectEvent } from 'features/document/master/selector'
import { setCheckList } from 'features/document/master/actions'
import { getDocumentList, updateDocument } from 'features/document/master/api'
import {
  GetCheckListReq,
  UpdateCheckListReq,
  CheckListItem,
} from 'features/document/master/types'

import { getQCTypeList } from 'features/checklist/unit/api'
import { selectQCTypeList } from 'features/checklist/unit/selector'

import {
  userEmailSelector,
  serviceTokenSelector,
} from 'features/sensitive/sensitive.selector'

import Menu from '../../../MenuAdmin'
import QCCheckDocumentDetail from '../Detail'
import RowItem from './RowItem'
import { SearchInput } from 'Components/Filter'

const breadcrumbs: BreadcrumbLink[] = [
  {
    url: '/dashboard',
    title: 'Home',
    icon: '/home.png',
    active: true,
    showTitle: false,
  },
  {
    url: '/master-document',
    title: 'Master ตรวจเอกสาร',
    active: false,
    showTitle: false,
  },
]

type Props = DispatchProps & RouteComponentProps & ReduxProps

const QCCheckDocument: React.ComponentType<Props> = ({
  checklist,
  event,
  token,
  QcTypeList,
  UserEmail,
}) => {
  const dispatch = useDispatch()
  const [selectedItem, setSelectedItem] = useState<CheckListItem | undefined>(
    undefined
  )
  const [enableModal, setEnableModal] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [pagination, setPagination] = useState({
    Page: 1,
    RowPerPage: 10,
  })

  useEffect(() => {
    dispatch(getQCTypeList())
  }, [])

  useEffect(() => {
    const req: GetCheckListReq = {
      UserEmail,
      Page: pagination.Page,
      RowPerPage: pagination.RowPerPage,
      TextFilter: searchText,
    }

    dispatch(getDocumentList(token, req))
  }, [pagination])

  const onCloseDrawer = () => {
    setSelectedItem(undefined)
  }

  const onOpenDrawer = (item: CheckListItem) => {
    setSelectedItem(item)
  }

  const onSave = (checklist: CheckListItem) => {
    setEnableModal(true)
    const req: UpdateCheckListReq = {
      UserEmail,
      CheckListID: checklist.CheckListID,
      IsMaster: 'Y',
      ProjectCode: '',
      CheckListName: checklist.CheckListName,
      QCTypeID: checklist.QCTypeID,
      Score: checklist.Score,
      IsRequireQC: checklist.IsRequireQC,
      CheckList: checklist.CheckList,
    }
    dispatch(updateDocument(token, req))
    setSelectedItem(checklist)
  }

  const updateSuccess = () => {
    if (selectedItem != undefined) {
      dispatch(setCheckList({ checklist: selectedItem }))
    }

    setSelectedItem(undefined)
    setEnableModal(false)
  }

  const closeEnableModal = () => setEnableModal(false)

  const content = checklist.List.map((item: any, index: number) => {
    return (
      <RowItem
        key={index}
        item={item}
        selected={selectedItem && selectedItem.CheckListID === item.CheckListID}
        onClick={onOpenDrawer}
      />
    )
  })

  const onSearch = (val: string) => {
    if (searchText === '' && val === '') return
    setSearchText(val)
    pagination.Page = 1
    setPagination({ ...pagination })
  }

  return (
    <>
      <EventModal
        event={[event]}
        enable={enableModal}
        successCalback={updateSuccess}
        errorCalback={closeEnableModal}
      />
      <SubnavBar
        breadcrumbs={breadcrumbs}
        hideSearch={true}
        adminSearchBox={<SearchInput onSearch={onSearch} />}
      />
      <Menu selected="master-document">
        <MainPage>
          <LineHeader title="Master ตรวจเอกสาร" hideButton={true} />

          <TableHeader>
            <TableColHeader span={2} isPadding={true}>
              ID
            </TableColHeader>
            <TableColHeader span={6} isPadding={true}>
              Checklist name
            </TableColHeader>
            <TableColHeader span={4}>หมวดงาน</TableColHeader>
            <TableColHeader span={4}>QC Require เป็นเอกสารตรวจ</TableColHeader>
            <TableColHeader span={4}>คะแนนเต็ม</TableColHeader>
            <TableColHeader span={4}>แก้ไขล่าสุด</TableColHeader>
          </TableHeader>
          {content}
          <Pagination
            currentPage={pagination.Page}
            totalRows={checklist.TotalRows}
            perPage={pagination.RowPerPage}
            onChangeNumber={(page: number) => {
              pagination.RowPerPage = page
              setPagination({ ...pagination })
            }}
            onClickPage={(page: number) => {
              pagination.Page = page
              setPagination({ ...pagination })
            }}
          />
        </MainPage>
      </Menu>

      <Drawer
        title={null}
        className="drawer-project"
        closable={false}
        width={531}
        visible={selectedItem != undefined}
      >
        <QCCheckDocumentDetail
          item={selectedItem}
          QcTypeList={QcTypeList}
          onSave={onSave}
          onClose={onCloseDrawer}
          readOnly={enableModal}
        />
      </Drawer>
    </>
  )
}

const MainPage = styled.div`
  padding: 40px 45px;
  width: 100%;
`

const mapStateToProps = (state: RootState) => {
  return {
    checklist: selectCheckList(state),
    QcTypeList: selectQCTypeList(state),
    event: selectEvent(state),
    token: serviceTokenSelector(state),
    UserEmail: userEmailSelector(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(QCCheckDocument)
