import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'antd'
import { Data } from 'features/cmpj/cmchecking/types'
import { getQCTypeVersion } from 'utils/getText'

type Props = {
  data: Data
}

const UnitInfo: React.ComponentType<Props> = props => {
  const { data } = props

  const renderData = (title: string, value: string) => {
    return (
      <Row type="flex" justify="space-between">
        <Col span={10}>
          <Title>{title}</Title>
        </Col>
        <Col span={14}>
          <Description>{value}</Description>
        </Col>
      </Row>
    )
  }

  return (
    <Content>
      <TopicTitle>รายละเอียด</TopicTitle>
      {renderData('หมวด QC', getQCTypeVersion(data))}
      {renderData('โมเดล', data.ModelName)}
      {renderData('ยูนิต', data.Unit)}
    </Content>
  )
}

const Content = styled.div`
  width: 350px;
  height: auto;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.03);
  float: left;
  padding: 24px;
`

const TopicTitle = styled.span`
  font-size: 24px;
  font-family: 'DB Helvethaica X 75 Bd';
`

const Title = styled.div`
  font-size: 20px;
  font-family: 'DB Helvethaica X 75 Bd';
`

const Description = styled.div`
  font-size: 20px;
  font-family: 'DB Helvethaica X 55 Regular';
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: right;
`

export default UnitInfo
