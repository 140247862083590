import withAPIAction from 'utils/withAPIAction'
import { verifyReq } from 'utils/verify'
import {
  GetCheckingZoneAction,
  AcceptTaskAction,
  RejectTasksAction,
} from './actions'

import {
  GetCheckingCommissReq,
  AcceptCheckingTaskReq,
  RejectCheckingTaskReq,
} from './types'

export const GetCheckingCommissioningByQC = (
  token: string,
  data: GetCheckingCommissReq
) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<GetCheckingCommissReq>({
    method: 'POST',
    path: 'GetCheckingCommissioningByQC',
    data,
    token: `Bearer ${token}`,
  })(GetCheckingZoneAction)
}

export const AcceptCommissioningByQC = (
  token: string,
  data: AcceptCheckingTaskReq
) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<AcceptCheckingTaskReq>({
    method: 'POST',
    path: 'AcceptCommissioningByQC',
    data,
    token: `Bearer ${token}`,
  })(AcceptTaskAction)
}

export const RejectCheckingTasks = (
  token: string,
  data: RejectCheckingTaskReq
) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<RejectCheckingTaskReq>({
    method: 'POST',
    path: 'RejectCheckingTask',
    data,
    token: `Bearer ${token}`,
  })(RejectTasksAction)
}
