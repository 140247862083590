import { sortingList, sortingByMultiParams } from '../../utils/helper'

export const sortingActivityList = (
  list: any[],
  type: string,
  name: string | undefined
): any[] => {
  try {
    let newList: any[] = list
    let params = []
    switch (name) {
      case 'ProjectNameTH':
        newList = sortingList(list, type, name, 'string')
        break
      case 'Unit':
        newList = sortingList(list, type, name, 'string')
        break
      case 'Floor':
        newList = sortingList(list, type, name, 'string')
        break
      case 'TotalDefect':
        newList = sortingList(list, type, name, 'number')
        break
      case 'CheckNumber':
        params = ['QCTypeVersion', 'CheckNumber']
        newList = sortingByMultiParams(list, type, params)
        break

      //defect
      case 'PinNumber':
        newList = sortingList(list, type, name, 'number')
        break
      case 'JobTypeName':
        newList = sortingList(list, type, name, 'string')
        break
      case 'SubJobTypeName':
        newList = sortingList(list, type, name, 'string')
        break
      case 'DetailName':
        newList = sortingList(list, type, name, 'string')
        break
      case 'DefectCheck':
        params = ['CreatedByModule', 'CheckNumber']
        newList = sortingByMultiParams(list, type, params)
        break
      case 'FixReason':
        newList = sortingList(list, type, name, 'string')
        break
      default:
    }

    return newList
  } catch (e) {
    return list
  }
}
