import React from 'react'

type DefaultProps = {
  size?: number
  color?: string
}

const AddIcon = (props: DefaultProps) => {
  const { size = 20, color = '#fff' } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 16 16"
    >
      <path
        fill={color}
        fillRule="evenodd"
        d="M13.75 6.75H9.5a.25.25 0 0 1-.25-.25V2.25a1.25 1.25 0 0 0-2.5 0V6.5a.25.25 0 0 1-.25.25H2.25a1.25 1.25 0 0 0 0 2.5H6.5a.25.25 0 0 1 .25.25v4.25a1.25 1.25 0 0 0 2.5 0V9.5a.25.25 0 0 1 .25-.25h4.25a1.25 1.25 0 0 0 0-2.5z"
      />
    </svg>
  )
}

export default AddIcon
