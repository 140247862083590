import React, { useEffect } from 'react'
import { Row, Col, Input } from 'antd'
import styled from 'styled-components'
import { CheckListForQC } from 'features/report/commissioning/types'
import ImagePicker from 'Components/Shared/ImagePicker'
import { toDateText, toMomentDateTime } from 'utils/datetime'

type Props = {
  item: CheckListForQC
  readOnly?: boolean
  onPage?: () => void
  images: any[]
  onClickSeemore?: () => void
  onThumbnailOpen: (index: number) => void
  onUpdateImage?: (images: any[]) => void
}

const Header: React.FunctionComponent<Props> = (props: Props) => {
  const {
    item,
    readOnly = true,
    onPage,
    onThumbnailOpen,
    onClickSeemore = () => {},
    onUpdateImage = () => {},
    images,
  } = props
  const {
    ProjectCode,
    ProjectNameTH,
    CheckListName,
    CommissioningType,
    CheckedDate,
    CheckNumber,
    CheckStatus,
    ActivityName,
  } = item

  const statusContent = readOnly ? (
    <Col md={6}>
      <TextItem>
        สถานะ
        {CheckStatus === 'Pass' ? (
          <StatusPass>ผ่าน</StatusPass>
        ) : (
          <StatusReject>ไม่ผ่าน</StatusReject>
        )}
      </TextItem>
    </Col>
  ) : (
    undefined
  )
  return (
    <MainView>
      <TextHeader>
        รายละเอียด
        <span onClick={onPage}>ดูรายละเอียดงาน</span>
      </TextHeader>
      <BoxItem>
        <Row type="flex" justify="space-between">
          <Col md={6}>
            <TextItem>
              โครงการ
              <div>
                {ProjectCode}-{ProjectNameTH}
              </div>
            </TextItem>
          </Col>
          <Col md={6}>
            <TextItem>
              หมวดงาน
              <div>
                {CommissioningType}({CheckNumber})
              </div>
            </TextItem>
          </Col>

          <Col md={6}>
            <TextItem>
              ระบบ
              <div>{ActivityName}</div>
            </TextItem>
          </Col>

          {statusContent}
        </Row>

        <Space />

        <FileInfo>
          <Row type="flex" justify="space-between">
            <Col span={24}>
              <ImagePicker
                Images={images}
                onImageOpen={onClickSeemore}
                onThumbnailOpen={index => onThumbnailOpen(index)}
                disabled={readOnly}
                showMax={readOnly ? 10 : 9}
                viewMode={readOnly}
                showMoreTxt={true}
                onChange={onUpdateImage}
                isLimit={false}
                numberOfImage={50}
                isMultiple={false}
              />
            </Col>
          </Row>
        </FileInfo>
      </BoxItem>
    </MainView>
  )
}

const MainView = styled.div`
  margin-top: 40px;
`
const TextHeader = styled.div`
  font-size: 28px;
  font-family: 'DB Helvethaica X 75 Bd';
  color: #000;
  span {
    float: right;
    padding-top: 15px;
    font-size: 18px;
    color: #003168;
    text-decoration: underline;
    cursor: pointer;
  }
`

const TextItem = styled.div`
  font-size: 20px;
  font-family: 'DB Helvethaica X 75 Bd';
  color: #000;

  div {
    font-family: 'DB Helvethaica X 55 Regular';
  }
`

const Space = styled.div`
  margin-top: 24px;
`

const FileInfo = styled.div``

const BoxItem = styled.div`
  background-color: #fff;
  margin-top: 12px;
  border-radius: 5px;
  border: solid 1px #f2f2f2;
  padding: 20px;
  line-height: normal;
`

const StatusLabel: any = styled.div`
  width: max-content;
  border-radius: 10px;
  height: 20px;
  text-align: center;
  color: #fff;
  padding: 0 12px;
  font-family: 'DB Helvethaica X 55 Regular';
  font-size: 16px;
  line-height: 1.4;
`

const StatusPass = styled(StatusLabel)`
  background-color: #5bc79e;
`

const StatusReject = styled(StatusLabel)`
  background-color: #d0494c;
`

export default Header
