import React from 'react'
import { Row, Col } from 'antd'
import styled from 'styled-components'

import {
  BackBtn,
  TrashWhiteBtn,
  DownloadBtn,
  EditBtn,
} from '../../../Shared/Button'

type Props = {
  readOnly: boolean
  onBack: () => void
  onDelete: () => void
  onEdit: () => void
  onDownload: () => void
}

const TopMenu: React.ComponentType<Props> = props => {
  const { readOnly, onBack, onDelete, onEdit, onDownload } = props

  const btn = readOnly ? (
    <PageMenuRight>
      <DownloadBtn onClick={onDownload} />
    </PageMenuRight>
  ) : (
    <PageMenuRight>
      <EditBtn onClick={onEdit} />
      <DownloadBtn onClick={onDownload} />
      <TrashWhiteBtn onClick={onDelete} />
    </PageMenuRight>
  )
  return (
    <PageMenu>
      <Row>
        <Col span={6}>
          <BackBtn onClick={onBack} />
        </Col>

        <Col span={12} />

        <Col span={6}>{btn}</Col>
      </Row>
    </PageMenu>
  )
}

const PageMenu = styled.div`
  width: 100%;
  height: 64px;
  padding: 16px 24px 24px 24px;
`

const PageMenuRight = styled.div`
  text-align: right;

  img {
    margin-left: 24px;
  }

  img:first-child {
    margin-left: 0px;
  }
`

export default TopMenu
