import { ActionType } from 'typesafe-actions'
import { Moment } from 'moment-timezone'
import * as actions from './actions'
import { Event } from 'features/types/event'

export type DashboardAction = ActionType<typeof actions>

export interface StoreState {
  data: DashboardData
  originalData: DashboardData
  event: Event
  query: DashboardQuery
  sorting: any
  filter: DashboardFilter
}

export type InProgress = {
  Unit: string
  Zone: string
  QCTypeID: string
  QCTypeName: string
  CheckNumber: number
  State: 'CM' | 'QC'
  Status: 'INIT' | 'INPRO' | 'APPV' | 'REJT'
  RequestDate: string
  AppointmentDate: string
  RequestID: number
}

export type FixFromQC = {
  Unit: string
  Zone: string
  QCTypeID: string
  QCTypeName: string
  CheckNumber: number
  State: 'CM' | 'QC'
  Status: 'INIT' | 'INPRO' | 'APPV' | 'REJT'
  RequestID: number
  CheckStatus: 'Pass' | 'NotPass' | 'NotPassByReqDefect'
  InspectionScore: number
  CheckedDate: string
}

export interface GetDashboardReq {
  ProjectCode: string
  UserEmail: string
}

export interface GetDashboardResp extends GetApiResponse<DashboardData> {}

export interface GetApiResponse<T> {
  ResponseData: T
  ResponseCode: string
  ResponseMsg: 'Success' | 'Error'
  RequestTime: string
  Responsetime: string
}

export type DashboardData = {
  InProgress: InProgress[]
  FixFromQC: FixFromQC[]
}

export type DashboardEvent = {
  isFetching: boolean
  error: string
}

export type DashboardFilter = {}

export type DashboardQuery = {
  date: {
    start: undefined | Moment
    end: undefined | Moment
  }
}

export enum TypeOfTask {
  InProgress = 'inProgress',
  FixFromQC = 'fixFromQC',
}
