import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'

import { Layout } from 'antd'
import TopNav from 'Components/Shared/TopNav'
import WelcomePage from 'Components/Admin/Welcome/AdminQC'

import AdminQCReportApp from './AdminQCReportApp'
import TokenExpired from 'Components/Pages/TokenExpired'
import ReportByUnitList from 'Components/AdminQC/Report/Unit/ByUnit/List'

type Props = {}

const AdminReportApp: React.FunctionComponent<Props> = () => {
  return (
    <BrowserRouter>
      <Layout className="layout" hasSider={false}>
        <TopNav title="ADMIN Report" displayProject={false} />
        <Route exact path="/" component={ReportByUnitList} />

        <Switch>
          <Route path="/report" component={AdminQCReportApp} />
          <Route exact path="/dashboard" component={WelcomePage} />
          <Route exact path="/token-expired" component={TokenExpired} />
        </Switch>
      </Layout>
    </BrowserRouter>
  )
}

export default AdminReportApp
