import { createStandardAction, createAsyncAction } from 'typesafe-actions'
import { GetDashboardReq, GetActivityApiResponse } from './types'

export const getDashbaordAction = createAsyncAction(
  'cmpj/sitevisit/audit/dashboard/FETCH_CONSTRUCT_DASHBOARD_REQUEST',
  'cmpj/sitevisit/audit/dashboard/FETCH_CONSTRUCT_DASHBOARD_SUCCESS',
  'cmpj/sitevisit/audit/dashboard/FETCH_CONSTRUCT_DASHBOARD_FAILURE'
)<GetDashboardReq, GetActivityApiResponse, any>()

export const sortingDashboard = createStandardAction(
  'cmpj/sitevisit/audit/dashboard/SORTING'
)<string>()
