import React, { useState } from 'react'
import styled from 'styled-components'
import { PrimaryButton } from 'Components/Shared/Button'
import { Row, Col } from 'antd'

import { AuditRoadReport } from 'features/report/auditRoad/types'
import { CheckedIcon } from 'Components/Shared/Icon'

type Props = {
  report: AuditRoadReport
  onSign: () => void
  onSelectPin: () => void
  onSelectLocation: () => void
}

const QcFooter: React.ComponentType<Props> = props => {
  const { report, onSign, onSelectPin, onSelectLocation } = props

  const hasLocation = report.HasLocation ? (
    <IconBtn>
      <CheckedIcon />
      เลือกพื้นที่
    </IconBtn>
  ) : (
    'เลือกพื้นที่'
  )

  const hasSign = report.HasSign ? (
    <IconBtn>
      <CheckedIcon />
      ลงชื่อรับทราบ
    </IconBtn>
  ) : (
    'ลงชื่อรับทราบ'
  )

  const hasSelectPin = report.HasSelectPin ? (
    <IconBtn>
      <CheckedIcon />
      เลือกรูปส่งรายงาน
    </IconBtn>
  ) : (
    'เลือกรูปส่งรายงาน'
  )

  return (
    <Footer>
      <Row type="flex" justify="space-between" align="middle">
        <Col />

        <Col>
          <Button>
            <PrimaryButton text={hasLocation} onClick={onSelectLocation} />
          </Button>

          <Button>
            <PrimaryButton text={hasSign} onClick={onSign} />
          </Button>

          <Button>
            <PrimaryButton text={hasSelectPin} onClick={onSelectPin} />
          </Button>
        </Col>
      </Row>
    </Footer>
  )
}

const Footer = styled.div`
  position: fixed;
  bottom: 0px;
  height: auto;
  width: 100%;
  background-color: white;
  padding: 22px 80px;
  z-index: 20;
`

const Button = styled.div`
  width: 200px;
  margin-left: 16px;
  float: left;
`

const IconBtn = styled.div`
  img {
    margin-right: 10px;
  }
`

export default QcFooter
