import React from 'react'
import { Link, LinkProps } from 'react-router-dom'

type Props = {
  children: React.ReactNode | React.ReactChild
} & LinkProps

const SearchPaginationLinkButton: React.FunctionComponent<Props> = ({
  to,
  children,
}) => <Link to={to}>{children}</Link>

export default SearchPaginationLinkButton
