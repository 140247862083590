import { Event } from '../../types/event'
import { InspectionList } from 'features/types/checklist'

export interface CommissonState {
  CommissionList: CommissionList
  CommissionDetail: CommissionDetail
  Q1Act?: InspectionList[]
  CommissionAct?: InspectionList[]
  SafetyAct?: InspectionList[]
  Event: Event
  ActvityList: Activity[]
}

export type CommissionList = {
  TotalRows: number
  List: Commisson[]
}

export type Commisson = {
  CheckListID: number
  CheckListName: string
  CheckListType: number
  CreatedDate: string
  UpdatedDate: string
  UpdatedBy: string
}

export type CommissionDetail = {
  CheckListID: number
  CheckListName: string
  CheckListType: number
  Status: string
  ProjectList: Project[]
  DataList: DataType[]
}

export type DataType = {
  CommissioningType: number
  CommissioningTypeName: string
  List: InspectionList[]
}

export type Project = {
  ProjectCode: string
  ProjectNameTH: string
  ProjectNameEN: string
}

export type ActivityByCode = {
  [key: string]: Activity[]
}

export type Activity = {
  ActivityID: string
  ActivityName: string
  CheckListID: number
  Status: string

  isSelect?: boolean
  isReadOnly?: boolean
  detail?: string
}

export type SelectActivityByCode = {
  [key: string]: SelectActivity[]
}

export type SelectActivity = {
  ActivityID: string
  Selected: string
}

export enum CommissioningType {
  Q1 = 1,
  COMMISSIONING = 2,
  SAFETY = 3,
}
