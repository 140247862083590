import { createStandardAction, createAsyncAction } from 'typesafe-actions'
import {
  GetDataListReq,
  GetDataListResp,
  CreateDataReq,
  UpdateDataReq,
  CommonResp,
} from './types'

export const getDataListAction = createAsyncAction(
  'admin/qcType/FETCH_LIST_REQUEST',
  'admin/qcType/FETCH_LIST_SUCCESS',
  'admin/qcType/FETCH_LIST_FAILURE'
)<GetDataListReq, GetDataListResp, any>()

export const createDataAction = createAsyncAction(
  'admin/qcType/CREATE_DATA_REQ',
  'admin/qcType/CREATE_DATA_RESP',
  'admin/qcType/CREATE_DATA_FAIL'
)<CreateDataReq, CommonResp, any>()

export const updateDataAction = createAsyncAction(
  'admin/qcType/UPDATE_DATA_REQ',
  'admin/qcType/UPDATE_DATA_RESP',
  'admin/qcType/UPDATE_DATA_FAIL'
)<UpdateDataReq, CommonResp, any>()

export const resetEvent = createStandardAction(
  'admin/qcType/RESET_EVENT_ACTION'
)<void>()
