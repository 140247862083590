import React from 'react'
import RootState from 'typings/RootState'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { Layout, Row, Col } from 'antd'
import { DefectItem } from 'features/report/zone/types'
import { FloatButton } from 'Components/Shared/Button'

import { selectReport } from 'features/report/zone/selector'

import ReportTitle from './ReportTitle'
import DefectList from './DefectList'
import { getUserProfile } from 'features/sensitive/sensitive.selector'

const { Content } = Layout

type Props = {
  title?: string
  onAdd?: () => void
  onEdit?: (item: DefectItem) => void
  isShowAfterImg?: boolean
} & ReduxProps

const ReportInfo: React.ComponentType<Props> = props => {
  const {
    title = '',
    report,
    onAdd,
    onEdit = (item: DefectItem) => {},
    isShowAfterImg,
    userProfile,
  } = props

  const curModule = userProfile.RoleName as 'QC' | 'CM/PJ' | 'Main Con'

  const getTitle = (status: string, checkby: string) => {
    let title = 'ส่วนกลางทั่วไป'

    if (status === 'REJT') {
      title = `รายการแก้ไขจาก ${checkby}`
      if (curModule === 'CM/PJ' && checkby === 'CM') {
        title = 'รายการแก้ไขจากตัวเอง'
      }

      if (curModule === 'QC' && checkby === 'QC') {
        title = 'รายการแก้ไขจากตัวเอง'
      }
    }
    return title
  }

  const onSelectedDefect = (item: DefectItem) => {}

  const titleHeader = getTitle(report.Status, report.CheckedBy)

  const addBtn = onAdd ? <FloatButton onClick={onAdd} /> : undefined

  return (
    <Content className="mid-content">
      <MainPage>
        <LeftContent>
          <ReportTitle report={report} />
        </LeftContent>
        <RightContent>
          <Row type="flex" justify="space-between" align="middle">
            <Col>
              <Title>{titleHeader}</Title>
            </Col>
          </Row>
          <DefectList
            title={title}
            report={report}
            onClick={onEdit}
            onSelected={onSelectedDefect}
            isShowAfterImg={isShowAfterImg}
          />
        </RightContent>
        {addBtn}
      </MainPage>
    </Content>
  )
}

const MainPage = styled.div`
  background-color: #f5f6fa;
`

const LeftContent = styled.div`
  width: 320px;
  height: calc(100vh - 112px);
  background-color: #fff;
  float: left;
  margin-top: 1px;
  overflow: scroll;
  padding-bottom: 94px;
`

const RightContent = styled.div`
  width: calc(100% - 320px);
  height: calc(100vh - 210px);
  float: left;
  padding: 32px 80px 0 27px;
  overflow: scroll;
`

const Title = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 28px;
`

const mapStateToProps = (state: RootState) => {
  return {
    report: selectReport(state),
    userProfile: getUserProfile(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportInfo)
