import React from 'react'
import { Link } from 'react-router-dom'
import { TasklaneType } from 'features/tasklane/tasklane.types'

const createPaginationUrl = (type: string, page: number) => {
  return {
    pathname: `/see-more/type/${type}/page/${page}`,
    state: { fromDashboard: true },
  }
}

type Props = {
  type: TasklaneType
  page: number
  children: React.ReactNode | React.ReactChild
}

const PaginationLinkButton: React.FunctionComponent<Props> = ({
  type,
  page,
  children,
}) => <Link to={createPaginationUrl(type, page)}>{children}</Link>

export default PaginationLinkButton
