import React, { useMemo, useRef } from 'react'
import styled from 'styled-components'
import { Row, Col } from 'antd'
import InputText from 'Components/Shared/Input/InputText'
import DropdownList from 'Components/Shared/Form/DropdownList'
import PrimaryButton from 'Components/Shared/Button/PrimaryButton'

import {
  CheckListDetail,
  ProjectListItem,
} from 'features/checklist/auditRoad/types'
import { ProjectTypeResp } from 'features/masterData/types'
import { ProjectCheckList } from 'features/types/checklist'
import DeleteCheckListModal, {
  DeleteModalHandles,
} from 'Components/Shared/Modal/DeleteModal'

import ProjectsBox from './ProjectsBox'

type Props = {
  isActive: boolean
  isEditing: boolean
  projects: ProjectCheckList[]
  projectType: ProjectTypeResp
  checkListDetail: CheckListDetail
  updateCheckList: (val: CheckListDetail) => void
  onSave: () => void
  onDelete: () => void
  duplicateCheckList: () => void
}

function transformProjectType(projectType: ProjectTypeResp) {
  return projectType.List.map(item => {
    return {
      value: item.ProjectTypeID,
      name: item.ProjectTypeName,
    }
  })
}

const QCSetting: React.ComponentType<Props> = ({
  isActive,
  projects,
  checkListDetail,
  updateCheckList,
  onSave,
  onDelete,
  isEditing,
  projectType: projectTypeProps,
  duplicateCheckList,
}) => {
  const isUsed = checkListDetail.Status.toLocaleLowerCase() === 'used'
  const modalRef = useRef<DeleteModalHandles>(null)
  const projectType = useMemo(() => transformProjectType(projectTypeProps), [
    projectTypeProps,
  ])

  const onNameChange = (val: string) => {
    checkListDetail.CheckListName = val
    updateCheckList(checkListDetail)
  }

  const onTypeChange = (val: number, index: number) => {
    checkListDetail.ProjectTypeID = val
    checkListDetail.ProjectTypeName = projectType[index].name
    updateCheckList(checkListDetail)
  }

  const showDelete = () => {
    if (modalRef.current) {
      modalRef.current.showModal('คุณยืนยันต้องการลบ checklist นี้', 1)
    }
  }

  const showDeleteProject = (it: ProjectListItem) => {
    if (modalRef.current) {
      modalRef.current.showModal('คุณยืนยันต้องการลบโครงการนี้', 2, it)
    }
  }

  const onOkModal = (cond: number, obj: any) => {
    switch (cond) {
      case 1:
        onDelete()
        break
      case 2:
        const index: number = checkListDetail.ProjectList.findIndex(
          v => v.ProjectCode === obj.ProjectCode
        )

        if (index > -1) {
          const projectItem: ProjectListItem =
            checkListDetail.ProjectList[index]

          if (projectItem.Selected === 'N') {
            delete projectItem.Selected
            checkListDetail.ProjectList[index] = projectItem
          } else if (projectItem.Selected === 'Y') {
            checkListDetail.ProjectList.splice(index, 1)
          } else {
            projectItem.Selected = 'N'
            checkListDetail.ProjectList[index] = projectItem
          }

          updateCheckList(checkListDetail)
        }
        break

      default:
        break
    }
  }

  const LeftContent = (
    <LeftSection>
      <BoxSpace>
        <InputText
          prefix="ชื่อ"
          placeholder="กรอกชื่อ"
          value={checkListDetail.CheckListName}
          onChange={onNameChange}
          disabled={isUsed}
        />
      </BoxSpace>

      <BoxSpace>
        <DropdownList
          label="ประเภทโครงการ"
          value={checkListDetail.ProjectTypeID}
          placeholder="กรุณาเลือก"
          menu={projectType}
          onChange={onTypeChange}
          disabled={isUsed}
        />
      </BoxSpace>
    </LeftSection>
  )

  const Rightontent = (
    <RightSection>
      <BoxSpace>
        <ProjectsBox
          projects={projects}
          checkListDetail={checkListDetail}
          updateCheckList={updateCheckList}
          showDeleteProject={showDeleteProject}
        />
      </BoxSpace>
    </RightSection>
  )

  return (
    <div style={{ display: isActive ? '' : 'none' }}>
      <Row type="flex" justify="space-between">
        <Col span={12}>{LeftContent}</Col>
        <Col span={12}>{Rightontent}</Col>
      </Row>
      <ButtonSection>
        <Row type="flex" justify="end">
          {isEditing && (
            <Col style={{ width: '200px', marginRight: '24px' }}>
              <PrimaryButton
                text="Duplicate checklist"
                onClick={duplicateCheckList}
              />
            </Col>
          )}
          {!isUsed && isEditing && (
            <Col style={{ width: '200px', marginRight: '24px' }}>
              <PrimaryButton
                text="ลบ Checklist"
                onClick={showDelete}
                btnType="Reject"
              />
            </Col>
          )}
          <Col style={{ width: '200px' }}>
            <PrimaryButton text="บันทึก" onClick={onSave} />
          </Col>
        </Row>
      </ButtonSection>
      <DeleteCheckListModal ref={modalRef} onOk={onOkModal} />
    </div>
  )
}

const LeftSection = styled.div`
  padding-right: 8px;
`

const RightSection = styled.div`
  padding-left: 8px;
`
const BoxSpace = styled.div`
  margin-bottom: 16px;
`

const ButtonSection = styled.div`
  margin-top: 32px;
`

export default QCSetting
