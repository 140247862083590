import React from 'react'
import styled from 'styled-components'
import { Row, Col, Progress } from 'antd'
import { Link } from 'react-router-dom'
import { TodoActivity } from 'features/qc/dashboard/types'

interface Props {
  type?: string
  task: TodoActivity
  onClick?: (code: string) => void
}

const TodoCard: React.FC<Props> = props => {
  const { task } = props
  const { ProjectCode, NumberOfTask, ProjectNameTH } = task

  return (
    <MainCard>
      <Row type="flex" justify="space-between">
        <Col span={17}>
          <Title>{ProjectCode}</Title>
          <Title>{ProjectNameTH}</Title>
          <Detail>{NumberOfTask} รายการ</Detail>
        </Col>
        {/* <Col>
          <TagNew>New</TagNew>
        </Col> */}
      </Row>
    </MainCard>
  )
}

const MainCard = styled.div`
  background: #fff;
  height: 104px;
  border-radius: 10px;
  padding: 16px 16px 16px 24px;
  background-clip: padding-box;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.03);
  margin-bottom: 12px;
  cursor: pointer;
  color: #000;
  width: 100%;
  position: relative;
`

const Title = styled.p`
  font-size: 20px;
  margin-bottom: 5px;
  font-family: 'DB Helvethaica X 75 Bd';
  line-height: 1;
`

const Detail = styled.p`
  font-size: 18px;
  margin-bottom: 4px;
  font-family: 'DB Helvethaica X 55 Regular';
`

const TagNew = styled.div`
  width: 24px;
  height: 24px;
  background-color: #4983c4;
  font-family: 'DB Helvethaica X 55 Regular';
  border-radius: 50%;
  font-size: 10px;
  color: #fff;
  line-height: 24px;
  text-align: center;
`

export default TodoCard
