import { createStandardAction, createAsyncAction } from 'typesafe-actions'
import {
  CurrentActivity,
  FetchActivityRequest,
  GetActivityApi,
  GetCheckListApi,
  UpdateActivityApi,
  RejectActivityReq,
  AcceptActivityReq,
  SendActivityReq,
  CmTakeActivityReq,
  TakeActivityListByCMReq,
  UpdateAttachmentReq,
} from './types'
import { MainconCheckList } from 'features/types/checklist'

export const setCurrentActivity = createStandardAction(
  'cmpj/activity/SET_CURRENT_ACTIVITY'
)<CurrentActivity>()

export const setCheckList = createStandardAction('cmpj/activity/SET_CHECKLIST')<
  MainconCheckList
>()

export const getActivityDetailRequest = createAsyncAction(
  'cmpj/activity/GET_ACTIVITY_REQUEST',
  'cmpj/activity/GET_ACTIVITY_RESPONSE',
  'cmpj/activity/GET_ACTIVITY_FAILURE'
)<FetchActivityRequest, GetActivityApi, any>()

export const getCheckListRequest = createAsyncAction(
  'cmpj/activity/GET_CHECK_LIST_REQUEST',
  'cmpj/activity/GET_CHECK_LIST_RESPONSE',
  'cmpj/activity/GET_CHECK_LIST_FAILURE'
)<FetchActivityRequest, GetCheckListApi, any>()

export const rejectActivity = createAsyncAction(
  'cmpj/activity/REJECT_ACTIVITY_REQUEST',
  'cmpj/activity/REJECT_ACTIVITY_RESPONSE',
  'cmpj/activity/REJECT_ACTIVITY_FAILURE'
)<RejectActivityReq, UpdateActivityApi, any>()

export const acceptActivity = createAsyncAction(
  'cmpj/activity/ACCEPT_ACTIVITY_REQUEST',
  'cmpj/activity/ACCEPT_ACTIVITY_RESPONSE',
  'cmpj/activity/ACCEPT_ACTIVITY_FAILURE'
)<AcceptActivityReq, UpdateActivityApi, any>()

export const submitActivityToPJ = createStandardAction(
  'cmpj/activity/SEND_ACTIVITY_TO_PJ'
)<any>()

export const sendReqToPJAsyncReq = createAsyncAction(
  'cmpj/activity/SEND_TO_PJ_REQUEST',
  'cmpj/activity/SEND_TO_PJ_RESPONSE',
  'cmpj/activity/SEND_TO_PJ_FAILURE'
)<SendActivityReq, UpdateActivityApi, any>()

export const cmTakeActivity = createAsyncAction(
  'cmpj/activity/CM_TAKE_ACTIVITY_REQUEST',
  'cmpj/activity/CM_TAKE_ACTIVITY_RESPONSE',
  'cmpj/activity/CM_TAKE_ACTIVITY_FAILURE'
)<CmTakeActivityReq, UpdateActivityApi, any>()

export const cmTakeActivityList = createAsyncAction(
  'cmpj/activity/CM_TAKE_ACTIVITY_LIST_REQUEST',
  'cmpj/activity/CM_TAKE_ACTIVITY_LIST_RESPONSE',
  'cmpj/activity/CM_TAKE_ACTIVITY_LIST_FAILURE'
)<TakeActivityListByCMReq, UpdateActivityApi, any>()

export const submitAttachmentListReq = createStandardAction(
  'cmpj/activity/SEND_ATTACHMENT_LIST'
)<any>()

export const sendAttachmentList = createAsyncAction(
  'cmpj/activity/SEND_ATTACHMENT_REQUEST',
  'cmpj/activity/SEND_ATTACHMENT_RESPONSE',
  'cmpj/activity/SEND_ATTACHMENT_FAILURE'
)<UpdateAttachmentReq, UpdateActivityApi, any>()

export const resetSubmitErrorAction = createStandardAction(
  'cmpj/activity/RESET_SUBMIT_ERROR'
)<void>()

export const clearSubmitError = createStandardAction(
  'cmpj/activity/CLEAR_SUBMIT_ERROR'
)<undefined>()
