import RootState from 'typings/RootState'
import { createSelector } from 'reselect'
import {
  DashboardData,
  DashboardEvent,
  MainconFilter,
  MainconQuery,
} from './types'

export const selectData = (state: RootState): DashboardData =>
  state.mainconDashboard.data
export const selectEvent = (state: RootState): DashboardEvent =>
  state.mainconDashboard.event

export const selectSorting = (state: RootState) =>
  state.mainconDashboard.sorting
export const selectFilter = (state: RootState): MainconFilter =>
  state.mainconDashboard.filter

export const selectQuery = (state: RootState): MainconQuery =>
  state.mainconDashboard.query
