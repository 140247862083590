import React from 'react'

type DefaultProps = {
  size?: number
  color?: string
  onClick?: () => void
}

const Zoomout = (props: DefaultProps) => {
  const { onClick } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      onClick={onClick}
    >
      <g fill="none" fillRule="evenodd">
        <path
          fill="#e5e5e5"
          d="M12.248 3.45a8.786 8.786 0 0 0-6.232 2.565C3.878 8.153 3.002 11.228 3.67 14.24c.71 3.198 3.333 5.823 6.53 6.53 3.013.673 6.09-.206 8.225-2.345 2.136-2.135 3.014-5.211 2.346-8.225-.708-3.196-3.333-5.821-6.53-6.53a9.23 9.23 0 0 0-1.993-.22m-.05 19.491c-.802 0-1.612-.087-2.419-.267a10.58 10.58 0 0 1-8.013-8.011c-.813-3.668.261-7.416 2.871-10.026C7.25 2.027 11 .954 14.662 1.766a10.582 10.582 0 0 1 8.013 8.013c.813 3.667-.261 7.416-2.871 10.026a10.716 10.716 0 0 1-7.605 3.136"
        />

        <path
          fill="white"
          d="M15.537 13.405H8.905a1.184 1.184 0 0 1 0-2.368h6.632a1.183 1.183 0 1 1 0 2.368"
        />
      </g>
    </svg>
  )
}

export default Zoomout
