import React from 'react'
import styled from 'styled-components'
import { PMRReport } from 'features/pmr/types'
import { ReportTaskInfo } from 'Components/Shared/ReportInfo'

type Props = {
  report: PMRReport
  mode: 'PMR' | 'CM' | 'QC'
}

const ReportTitle: React.ComponentType<Props> = props => {
  const { report, mode } = props

  const renderInfo = (title: string, value: any) => {
    return <ReportTaskInfo title={title} value={value} />
  }

  const title = mode === 'CM' ? 'รายการรอจัดกลุ่ม' : 'รายการรออนุมัติ'

  return (
    <ScoreArea>
      <TaskInfo>
        {renderInfo('รหัสโครงการ', report.ProjectCode)}
        {renderInfo('โครงการ', report.ProjectNameTH)}
        {renderInfo('โซน', report.Unit)}
        {renderInfo('ชั้น', report.Floor)}
        {renderInfo(title, report.TotalDefect)}
      </TaskInfo>
    </ScoreArea>
  )
}

const ScoreArea = styled.div`
  padding: 140px 40px 0 86px;
`

const TaskInfo = styled.div`
  padding-top: 25px;
  font-family: 'DB Helvethaica X 55 Regular';
  font-size: 20px;
  color: #000;
`

export default ReportTitle
