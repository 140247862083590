import withAPIAction from 'utils/withAPIAction'

import { getItemForFloorPlanReq, getFloorPlanListReq } from './actions'
import { PaginationType } from 'features/types/common'

export const GetFloorPlanList = (page: PaginationType, ProjectCode: string) => {
  return withAPIAction<PaginationType>(state => ({
    method: 'POST',
    path: 'GetFloorPlanList',
    data: {
      ...page,
      ProjectCode,
      UserEmail: state.sensitive.userEmail,
    },
    token: `Bearer ${state.sensitive.apiToken}`,
  }))(getFloorPlanListReq)
}

export const getItemForFloorPlan = (ProjectCode: string) => {
  return withAPIAction<{ ProjectCode: string }>(state => ({
    method: 'POST',
    path: 'GetFloorByProject',
    data: {
      UserEmail: state.sensitive.userEmail,
      ProjectCode,
    },
    token: `Bearer ${state.sensitive.apiToken}`,
  }))(getItemForFloorPlanReq)
}
