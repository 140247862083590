import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import { InputSearch } from 'Components/Shared/Input'
import { findUserList } from 'features/admin/user/api'
import { FindUserReq, User } from 'features/admin/user/types'
import { selectEvent, selectUserList } from 'features/admin/user/selector'
import { connect, useDispatch } from 'react-redux'
import RootState from 'typings/RootState'
import {
  serviceTokenSelector,
  userEmailSelector,
} from 'features/sensitive/sensitive.selector'
import { LoadingIcon } from 'Components/Shared/Icon'

type Props = {
  onSave: (user: User) => void
} & ReduxProps

const UserBox: React.ComponentType<Props> = ({
  onSave,
  token,
  UserEmail,
  userList,
  event,
}) => {
  const [searchText, setSearchText] = useState('')
  const [query, setQuery] = useState('')

  useEffect(() => {
    onFindUser()
  }, [searchText])

  useEffect(() => {
    const timeOutId = setTimeout(() => setSearchText(query), 1000)
    return () => clearTimeout(timeOutId)
  }, [query])

  const dispatch = useDispatch()

  const onFindUser = () => {
    const req: FindUserReq = {
      UserEmail,
      IsVendor: '',
      TextFilter: searchText,
    }
    dispatch(findUserList(token, req))
  }

  const renderUserDataFormat = (item: User) => {
    let content = `${item.UserNameTH} - ${item.UserEmail}`
    return content
  }

  const renderSearchContent = (data: User[]) => {
    const loading = (
      <ContentBody>
        <LoadingIcon />
      </ContentBody>
    )
    const empty = (
      <ContentBody>
        <Txt>ไม่มีข้อมูล</Txt>
      </ContentBody>
    )

    const content =
      data.length > 0 ? (
        <SearchContentArea>
          {data.map((item, index) => {
            const data = renderUserDataFormat(item)
            return (
              <SearchItem key={index} onClick={() => onSave(item)}>
                {data}
              </SearchItem>
            )
          })}
        </SearchContentArea>
      ) : (
        empty
      )

    return event.IsLoading ? loading : content
  }

  return (
    <InputSearch
      placeholder="พิมพ์ชื่อหรือ อีเมลผู้ใช้"
      value={query}
      onChange={(val: string) => setQuery(val)}
      showSuggess={true}
      searchContent={renderSearchContent(userList)}
    />
  )
}

const SearchContentArea = styled.div`
  max-height: 192px;
  overflow: scroll;
`

const SearchItem = styled.div`
  padding: 8px 24px;
  font-size: 20px;
  font-family: 'DB Helvethaica X 55 Regular';
  &:hover {
    background-color: #e6f7ff;
    cursor: pointer;
  }
`

const ContentBody = styled.div`
  width: 100%;
  text-align: center;
  padding: 70px 0;
  height: 192px;
`

const Txt = styled.div`
  font-size: 20px;
  font-family: 'DB Helvethaica X 55 Regular';
`

const mapStateToProps = (state: RootState) => {
  return {
    token: serviceTokenSelector(state),
    UserEmail: userEmailSelector(state),
    userList: selectUserList(state),
    event: selectEvent(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserBox)
