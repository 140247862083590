import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Row, Col } from 'antd'
import { PrimaryButton } from 'Components/Shared/Button'
import { RadioBox, InputText } from 'Components/Shared/Input'
import { DropdownList } from 'Components/Shared/Form'
import {
  DataItem,
  QCType,
  MainCon,
  MainconData,
} from 'features/adminqc/unit/types'
import { EmptyBox } from 'Components/Shared'
import { cloneDeep } from 'lodash'

type DefaultProps = {
  unit?: DataItem
  mainCon?: MainCon[]
  onSave: (unit: DataItem) => void
  height?: number
}

type Props = DefaultProps

const UnitSettingData: React.FC<Props> = props => {
  const { unit, mainCon = [], onSave, height = 470 } = props

  const [unitData, setUnitData] = useState({} as DataItem)
  useEffect(() => {
    if (unit) {
      setUnitData(cloneDeep(unit))
    }
  }, [unit])

  const validateForm = (unitData: DataItem) => {
    let passValidate = false
    let count = 0
    if (!passValidate) {
      unitData.QCTypeList.map((item: QCType) => {
        const condtion1 =
          item.IsInspection == false && item.MemoNumber.length != 0
        const condtion2 = item.IsInspection == true

        if (condtion1 || condtion2) {
          count += 1
          if (count === 3) {
            passValidate = true
            onSave(unitData)
          }
          return
        }
      })
    }
  }

  const header = (
    <Header>
      <Row type="flex" justify="space-between" align="middle">
        <Col>
          <TitleContent>ตั้งค่ายูนิต</TitleContent>
        </Col>
        {unit && (
          <Col span={8}>
            <PrimaryButton
              text="บันทึก"
              onClick={() => validateForm(unitData)}
            />
          </Col>
        )}
      </Row>
    </Header>
  )

  const getMainCon = (qctypeId: string) => {
    let selectData = [] as any
    const data = mainCon.find(item => item.QCTypeID === qctypeId)

    if (data) {
      data.MainconList.map((maincon: MainconData) => {
        selectData.push({
          name: maincon.MainconNameTH || maincon.MainconNameEN,
          value: maincon.MainconID,
        })
      })
    }

    return selectData
  }

  const radioBox = (IsInspection: boolean, number: number) => {
    return (
      <Row type="flex" align="middle" justify="space-around">
        <Col span={6}>
          <RadioBox
            label={'ปกติ'}
            value={true}
            isSelected={IsInspection}
            onClick={val => {
              unitData.QCTypeList[number].IsInspection = val
              setUnitData({ ...unitData })
            }}
          />
        </Col>
        <Col span={18}>
          <RadioBox
            label={'ไม่ประเมินคะแนน'}
            value={false}
            isSelected={!IsInspection}
            onClick={val => {
              unitData.QCTypeList[number].IsInspection = val
              setUnitData({ ...unitData })
            }}
          />
        </Col>
      </Row>
    )
  }

  const renderQcType = () => {
    return (
      unitData &&
      unitData.QCTypeList &&
      unitData.QCTypeList.map((item: QCType, index: number) => {
        return (
          <QcArea key={index}>
            <TxtBold>{item.QCTypeName}</TxtBold>
            <Row type="flex" justify="space-between" align="middle">
              <Col>
                <Txt>การประเมินคะแนน</Txt>
              </Col>
              <Col span={14}>{radioBox(item.IsInspection, index)}</Col>
            </Row>
            <BodyArea>
              <InputText
                prefix="เลข Memo"
                placeholder="กรุณากรอก"
                value={item.MemoNumber}
                onChange={(val: string) => {
                  unitData.QCTypeList[index].MemoNumber = val
                  setUnitData({ ...unitData })
                }}
                disabled={false}
              />
              {!unitData.QCTypeList[index].IsInspection &&
                unitData.QCTypeList[index].MemoNumber.length === 0 && (
                  <TxtError>
                    * กรุณากรอกเลข Memo เนื่องจากไม่ประเมินคะแนน
                  </TxtError>
                )}
            </BodyArea>
            <BodyArea>
              <DropdownList
                label="ผู้รับเหมาหลัก"
                value={item.MainconID}
                menu={getMainCon(item.QCTypeID)}
                onChange={(val: any) => {
                  unitData.QCTypeList[index].MainconID = val
                  setUnitData({ ...unitData })
                }}
              />
            </BodyArea>
          </QcArea>
        )
      })
    )
  }

  return (
    <BoxContent>
      {header}
      {unit ? (
        <DataSection>{renderQcType()}</DataSection>
      ) : (
        <EmptyBox text={`กรุณาเลือก "ยูนิต"`} height={400} />
      )}
    </BoxContent>
  )
}

const DataSection = styled.div`
  padding: 0 24px;
  margin-top: 16px;
  height: 100%;
  overflow: auto;
  padding-bottom: 50px;
`

const QcArea = styled.div``

const BoxContent = styled.div`
  width: 100%;
  background-color: #fff;
  border-radius: 5px;
  border: solid 1px #eeeff2;
  padding: 24px 0;
  overflow: hidden;
  height: 100%;
`

const BodyArea = styled.div`
  margin: 12px 0;
`

const Header = styled.div`
  padding: 0 24px;
`

const TitleContent = styled.span`
  font-size: 24px;
  font-family: 'DB Helvethaica X 75 Bd';
  color: #000000;
`

const TxtBold = styled.span`
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 20px;
  color: #000;
`

const Txt = styled.span`
  font-family: 'DB Helvethaica X 55 Regular';
  font-size: 20px;
  color: #000;
`

const TxtError = styled.span`
  font-family: 'DB Helvethaica X 55 Regular';
  font-size: 20px;
  color: red;
  padding: 10px;
`

export default UnitSettingData
