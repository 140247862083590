import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { connect, useDispatch } from 'react-redux'
import { RootState } from 'typings/RootState'
import { RouteComponentProps } from 'react-router-dom'
import { BreadcrumbLink } from 'Components/Shared/BreadCrumb/BreadCrumb'
import SubnavBar from 'Components/Shared/SubnavBar'
import {
  TableHeader,
  TableColHeader,
  LineHeader,
} from 'Components/Shared/Table'
import Pagination from 'Components/Shared/Pagination'
import EventModal from 'Components/Shared/Modal/EventModal'
import AlertError from 'Components/Shared/AlertError'
import Menu from '../../../MenuAdmin'

import { getCheckList, deleteCheckList } from 'features/checklist/auditRoad/api'
import { updateCheckListDetail } from 'features/checklist/auditRoad/actions'
import { CheckListItem } from 'features/checklist/auditRoad/types'

import DeleteModal, {
  DeleteModalHandles,
} from 'Components/Shared/Modal/DeleteModal'

import { toCheckList } from 'importer/auditRoad'

import RowItem from './RowItem'
import { SearchInput } from 'Components/Filter'

const breadcrumbs: BreadcrumbLink[] = [
  {
    url: '/dashboard',
    title: 'Home',
    icon: '/home.png',
    active: true,
    showTitle: false,
  },
  {
    url: '/check-audit-road',
    title: 'ตรวจรับมอบถนนและก่อนหมดประกัน',
    active: false,
    showTitle: false,
  },
]

type Props = ReduxProps & DispatchProps & RouteComponentProps

const RoadCheckList: React.ComponentType<Props> = ({
  checkList,
  event,
  history,
}) => {
  const modalRef = useRef<DeleteModalHandles>(null)
  const deleteItem = useRef<CheckListItem | null>(null)
  const [enableModal, setEnableModal] = useState(false)
  const [err, setErr] = useState(undefined)
  const [searchText, setSearchText] = useState('')
  const [pagination, setPagination] = useState<{
    Page: number
    RowPerPage: number
  }>({
    Page: 1,
    RowPerPage: 10,
  })
  const dispatch = useDispatch()

  useEffect(() => {
    onGetList()
  }, [pagination])

  const onGetList = () => {
    dispatch(getCheckList(pagination.Page, pagination.RowPerPage, searchText))
  }

  const onRefreshPage = () => {
    setEnableModal(false)
    onGetList()
  }

  const onDelete = async () => {
    if (deleteItem.current) {
      setEnableModal(true)
      dispatch(deleteCheckList(deleteItem.current.CheckListID))
    }
  }

  const onImport = (data: any) => {
    try {
      const checklist = toCheckList(data)
      dispatch(updateCheckListDetail(checklist))
      history.push('/check-audit-road/create')
    } catch (e) {
      console.log('import err:', e.message)
      setErr(e.message)
    }
  }

  const showDeleteModal = (item: CheckListItem) => {
    if (modalRef.current) {
      modalRef.current.showModal('คุณยืนยันต้องการลบ checklist นี้', 0)
      deleteItem.current = item
    }
  }

  const closeEnableModal = () => setEnableModal(false)

  const content = checkList.List.map((item: any, index: number) => {
    return <RowItem key={index} item={item} showDeleteModal={showDeleteModal} />
  })

  const onSearch = (val: string) => {
    if (searchText === '' && val === '') return
    setSearchText(val)
    pagination.Page = 1
    setPagination({ ...pagination })
  }

  return (
    <>
      <SubnavBar
        breadcrumbs={breadcrumbs}
        hideSearch={true}
        adminSearchBox={<SearchInput onSearch={onSearch} />}
      />
      <AlertError
        isShow={err !== undefined}
        text={err}
        callback={() => setErr(undefined)}
      />
      <EventModal
        event={[event]}
        enable={enableModal}
        successCalback={onRefreshPage}
        errorCalback={closeEnableModal}
      />
      <Menu selected="check-audit-road">
        <MainPage>
          <LineHeader
            title="ตรวจรับมอบถนนและก่อนหมดประกัน"
            textImport="นำเข้า Checklist"
            textCreate="สร้าง Checklist ใหม่"
            onCreate={() => {
              history.push('/check-audit-road/create')
            }}
            onImport={onImport}
          />

          <TableHeader>
            <TableColHeader span={7} isPadding={true}>
              Checklist name
            </TableColHeader>
            <TableColHeader span={3}>ประเภทโครงการ</TableColHeader>
            <TableColHeader span={4}>จำนวนโครงการที่ใช้งาน</TableColHeader>
            <TableColHeader span={3}>วันที่สร้าง</TableColHeader>
            <TableColHeader span={3}>แก้ไขล่าสุด</TableColHeader>
            <TableColHeader span={3} offset={1} sort={false}>
              Action
            </TableColHeader>
          </TableHeader>

          {content}
          <Pagination
            currentPage={pagination.Page}
            totalRows={checkList.Total}
            perPage={pagination.RowPerPage}
            onChangeNumber={(row: number) => {
              pagination.RowPerPage = row
              setPagination({ ...pagination })
            }}
            onClickPage={(page: number) => {
              pagination.Page = page
              setPagination({ ...pagination })
            }}
          />
        </MainPage>
      </Menu>
      <DeleteModal ref={modalRef} onOk={onDelete} />
    </>
  )
}

const MainPage = styled.div`
  padding: 40px 45px;
  width: 100%;
`

const mapStateToProps = (state: RootState) => {
  return {
    checkList: state.auditRoadChecklist.CheckList,
    event: state.auditRoadChecklist.Event,
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>
const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(RoadCheckList)
