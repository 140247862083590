import React from 'react'
import PrimaryButton from 'Components/Shared/Button/PrimaryButton'
import styled from 'styled-components'
import img from 'assets/img/img-nodefect.png'

type Props = {
  onShowModal: () => void
  projectCode: string
}
const EmptyData = ({ onShowModal, projectCode }: Props) => (
  <Area>
    <Empty>
      <img src={img} />
      {projectCode ? (
        <>
          <p>กรุณากดปุ่ม Import จาก Master เพื่อเริ่มตั้งค่าโครงการ</p>
          <div className="button">
            <PrimaryButton text="Import จาก Master" onClick={onShowModal} />
          </div>
        </>
      ) : (
          <p>กรุณาเลือกโปรเจค</p>
        )}
    </Empty>
  </Area>
)

const Area = styled.div`
  width: 100%;
  height: 90%;
  text-align: center;
`

const Empty = styled.div`
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  p {
    font-family: 'DB Helvethaica X 75 Bd';
    font-size: 20px;
    margin-top: 24px;
  }
  img {
    width: 108px;
  }
  .button {
    width: 200px;
    margin: auto;
  }
`

export default EmptyData
