import React, { useState, useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import RootState from 'typings/RootState'
import { connect, useDispatch } from 'react-redux'
import moment from 'moment'
import LoadingView from 'Components/Shared/LoadingView'
import { Row, Col } from 'antd'
import styled from 'styled-components'
import { PrimaryButton, SecondaryButton } from 'Components/Shared/Button'

import ImageDisplay from 'Components/Shared/ImageDisplay'
import DocumentDisplay from 'Components/Shared/DocumentDisplay'
import ThumbnailDisplay from 'Components/Shared/ThumbnailDisplay'
import {
  selectOriginalActivity,
  selectCheckList,
  selectCurrentActivity,
  selectEvent,
  selectEventCheckList,
} from 'features/cmpj/activity/selector'
import {
  getWbsPathMapSelector,
  getWbsMapParentsSelector,
  getEvent,
} from 'features/wbs/wbs.selector'
import { updateOriginalActivity } from 'features/activity/activity.actions'

import {
  setCurrentActivity,
  setCheckList,
  submitActivityToPJ,
} from 'features/cmpj/activity/actions'
import {
  getActivityById,
  getCheckListById,
  rejectActivityRequestForCM,
  acceptActivityRequestForCM,
  takeActivityByCM,
} from 'features/cmpj/activity/api'
import {
  RejectActivityReq,
  AcceptActivityReq,
  SendActivityReq,
  CmTakeActivityReq,
} from 'features/cmpj/activity/types'
import {
  userEmailSelector,
  serviceTokenSelector,
} from 'features/sensitive/sensitive.selector'
import { selectedProjectCodeSelector } from 'features/project/project.selector'

import ActivityDetail from './ActivityDetail'
import AlertError from 'Components/Shared/AlertError'
import AlertSuccess from 'Components/Shared/AlertSuccess'
import Breadcrumbs from './Breadcrumbs'
import { GetHistoryActivityReq } from 'features/maincon/history/types'
import { getHistoryActivity } from 'features/maincon/history/api'
import {
  selectData,
  selectEvent as selectHistoryEvent,
} from 'features/maincon/history/selector'
import { HistoryModal, HistoryTable } from 'Components/Activity'
import { CheckListStatus } from 'features/cmpj/activity/types'
import { InspectionList } from 'features/types/checklist'
import { WarningModal } from 'Components/Shared'
import { useLocation } from 'react-router'
import ErrorMessage from 'Components/Shared/ErrorMessage'
import projectHelper from 'utils/projectHelper'
import {
  submitAttachmentListReq,
  resetSubmitErrorAction,
} from 'features/cmpj/activity/actions'
type FormProps = {
  code: string
}

type Props = FormProps & RouteComponentProps<{ code: string }> & ReduxProps

const CmActivity = (props: Props) => {
  const [lastPage, setLastPage] = useState('')
  const [page, setPage] = useState('')
  const [readOnly, setReadOnly] = useState(false)
  const [imageSelected, setImageSelected] = useState(0)
  const [imageList, setImageList] = useState([] as any[])
  const [enableModal, setEnableModal] = useState(false)
  const [documentList, setDocumentList] = useState([] as any[])
  const [showHistoryModal, setShowHistoryModal] = useState(false)
  const [shoWarningAuto100, setShoWarningAuto100] = useState(false)
  const [passAll, setPassAll] = useState(false)
  const [errMessage, setErrMessage] = useState('')

  const [removeList, setRemoveList] = useState([] as any[])

  const {
    Token,
    UserEmail,
    ProjectCode,
    match,
    CheckList,
    currentActivity,
    originalActivity,
    activityEvent,
    checklistEvent,
    history,
    wbs,
    historyData,
    historyEvent,
    wbsEvent,
  } = props
  const searchParam = useLocation()

  const ActivityID = match.params.code

  const onPageClose = () => {
    setPage(lastPage)
    if (lastPage === '') {
      setImageList([])
    } else {
      setLastPage('')
    }

    setDocumentList([])
  }

  const dispatch = useDispatch()

  useEffect(() => {
    setEnableModal(false)
  }, [originalActivity])

  useEffect(() => {
    const params: any = {
      UserEmail,
      ProjectCode,
      ActivityID,
    }
    dispatch(getActivityById(params, Token))

    dispatch(getCheckListById(params, Token))

    dispatch(getHistoryActivity(Token, params))
  }, [dispatch, UserEmail, ProjectCode, ActivityID, Token])

  const onImageOpen = (list?: any[], readOnly?: boolean) => {
    setLastPage(page)
    setPage('images')
    setReadOnly(readOnly || false)
    setImageList(list ? list : [])
  }

  const onDocumentOpen = (list?: any[], readOnly?: boolean) => {
    setLastPage(page)
    setPage('documents')
    setReadOnly(readOnly || false)
    setDocumentList(list ? list : [])
  }

  const onThumbnailOpen = (index: number, list?: any[], readOnly?: boolean) => {
    setLastPage(page)
    setPage('thumbnails')
    setReadOnly(readOnly || false)
    setImageList(list ? list : [])
    setImageSelected(index)
  }

  const onChangeRemove = (list: any[]) => {
    let newList = removeList.concat(list)
    setRemoveList(newList)
  }

  const onRejectTask = (remark: string) => {
    setEnableModal(true)
    const data: RejectActivityReq = {
      ProjectCode,
      UserEmail,
      ActivityID,
      Remark: remark,
    }

    dispatch(rejectActivityRequestForCM(data, Token))
  }

  const onAcceptTask = () => {
    setEnableModal(true)
    const data: AcceptActivityReq = {
      ProjectCode,
      UserEmail,
      ActivityID,
    }
    dispatch(acceptActivityRequestForCM(data, Token))
  }

  const onTakeActivty = () => {
    setEnableModal(true)
    const data: CmTakeActivityReq = {
      ProjectCode,
      UserEmail,
      ActivityID,
    }
    dispatch(takeActivityByCM(data, Token))
  }

  const verifyNotCheck = (list: InspectionList[]) => {
    for (const item of list) {
      for (const ele of item.Data) {
        if (ele.Status === CheckListStatus.NOTCHECK) return false

        for (const sub of ele.Data) {
          if (sub.Status === CheckListStatus.NOTCHECK) return false
        }
      }
    }

    return true
  }

  const verifyPassAll = (list: InspectionList[]) => {
    for (const item of list) {
      for (const ele of item.Data) {
        if (ele.Status !== CheckListStatus.PASS) return false

        for (const sub of ele.Data) {
          if (sub.Status !== CheckListStatus.PASS) return false
        }
      }
    }

    return true
  }

  const onSendTask = () => {
    const {
      RequestProgress,
      ExpectFinishDate,
      Images,
      Documents,
      Remark,
      ActualStartDate,
      ActualFinishDate,
    } = currentActivity
    const req: SendActivityReq = {
      UserEmail,
      ProjectCode,
      ActivityID: originalActivity.ActivityID,
      RequestProgress,
      ActualStartDate,
      ActualFinishDate: RequestProgress !== 100 ? '' : ActualFinishDate,
      ExpectFinishDate:
        RequestProgress === 100 ? ActualFinishDate : ExpectFinishDate,
      Remark,
      Images,
      Documents,
      CheckListID: CheckList.CheckListID,
      InspectionList: CheckList.InspectionList,
    }

    if (RequestProgress === 100) {
      const isNotCheck = verifyNotCheck(CheckList.InspectionList)
      if (isNotCheck === false) {
        setErrMessage('กรุณาตรวจ "รายการตรวจสอบ"')
        setEnableModal(true)
        return
      }
    }

    setErrMessage('')
    setEnableModal(true)
    dispatch(submitActivityToPJ({ req, Token }))
  }

  const autoUpdateProgress = () => {
    setShoWarningAuto100(false)
    currentActivity.RequestProgress = 100
    currentActivity.ActualFinishDate = moment().format('YYYYMMDD')
    dispatch(setCurrentActivity({ ...currentActivity }))
  }

  const onSetCheckList = (val: any) => {
    dispatch(setCheckList(val))
    const isPassAll = verifyPassAll(CheckList.InspectionList)
    const constructionTypeName = originalActivity.ConstructionTypeName

    if (passAll !== isPassAll) {
      if (isPassAll && constructionTypeName.toLowerCase() !== 'road') {
        setShoWarningAuto100(true)
      }
    }
    setPassAll(isPassAll)
  }

  const onSetActivity = (val: any) => dispatch(setCurrentActivity({ ...val }))
  const gotoDashboard = () => {
    setEnableModal(false)

    history.push(`../../${searchParam.search}`)
  }

  const onSetImage = (images: any[], FileID?: string) => {
    if (FileID) {
      let newList = removeList.concat([FileID])
      setRemoveList(newList)
    }
    currentActivity.Images = images
    setImageList(images)
    dispatch(setCurrentActivity({ ...currentActivity }))
  }

  const onSetDoc = (docs: any[]) => {
    currentActivity.Documents = docs
    setDocumentList(docs)
    dispatch(setCurrentActivity({ ...currentActivity }))
  }

  const onEnableUpdateFiles = () => {
    /// Find User Role
    // const codeModule = projectHelper.getModule() || ''
    // const RoleName = codeModule
    const ImagesList = currentActivity.Images.filter(
      (img: any) => img.FileID == undefined
    )
    const DocumentsList = currentActivity.Documents.filter(
      (file: any) => file.FileID == undefined
    )

    setEnableModal(true)
    const req = {
      UserEmail: UserEmail,
      RequestID: originalActivity.RequestID,
      ImageList: ImagesList,
      DocumentsList: DocumentsList,
      RemoveFiles: removeList,
    }

    dispatch(submitAttachmentListReq({ req, Token }))
  }

  const onClearEvent = () => {
    dispatch(resetSubmitErrorAction())
  }

  const historyModal = (
    <HistoryModal
      isShow={showHistoryModal}
      onCloseModal={() => setShowHistoryModal(false)}
      content={
        <HistoryTable
          items={historyData}
          fetchHistoryError={historyEvent.error}
          onImageOpen={onImageOpen}
          onDocumentOpen={onDocumentOpen}
        />
      }
    />
  )

  switch (page) {
    case 'images':
      return (
        <ImageDisplay
          isMultiple={false}
          onClose={onPageClose}
          readOnly={readOnly}
          images={imageList}
          onThumbnailOpen={onThumbnailOpen}
          onChange={onSetImage}
          numberOfImage={50}
          onChangeRemove={onChangeRemove}
          breadcrumb={
            <Breadcrumbs
              activity={originalActivity}
              name="รูปภาพ"
              onClose={onPageClose}
            />
          }
        />
      )
    case 'documents':
      return (
        <DocumentDisplay
          onClose={onPageClose}
          readOnly={readOnly}
          documents={documentList}
          onChange={onSetDoc}
          numberOfFile={50}
          onChangeRemove={onChangeRemove}
          breadcrumb={
            <Breadcrumbs
              activity={originalActivity}
              name="เอกสาร"
              onClose={onPageClose}
            />
          }
        />
      )
    case 'thumbnails':
      return (
        <ThumbnailDisplay
          onClose={onPageClose}
          readOnly={readOnly}
          images={imageList}
          index={imageSelected}
          onChange={onSetImage}
          isHideTrash={true}
          isHideEdit={true}
        />
      )

    default:
      const err = errMessage !== '' ? errMessage : activityEvent.SubmitError
      const forceToDashboard = err?.includes('EA') || false

      // Check New Img & New File & Any Delete (Need To Have Something before send to API)
      let disableConditionBtn =
        currentActivity.RequestProgress == 100 &&
        removeList.length == 0 &&
        currentActivity.Images.filter((img: any) => img.FileID == undefined)
          .length == 0 &&
        currentActivity.Documents.filter(
          (file: any) => file.FileID == undefined
        ).length == 0

      let disableImgBlank = currentActivity.Images.length == 0

      return (
        <>
          {/* {!forceToDashboard && (
            <AlertError
              text={err}
              isShow={err && enableModal ? true : false}
              callback={() => setEnableModal(false)}
            />
          )} */}

          <ErrorMessage
            visible={err && enableModal == true ? true : false}
            text={err}
            onClose={() => {
              if (forceToDashboard) gotoDashboard()
              else {
                setEnableModal(false)
                setTimeout(() => onClearEvent(), 1000)
              }
            }}
          />
          <AlertSuccess
            text={activityEvent.MessageSuccess}
            isShow={activityEvent.SubmitSuccess && enableModal ? true : false}
            callback={gotoDashboard}
          />
          <LoadingView
            isShow={
              activityEvent.IsLoading ||
              checklistEvent.IsLoading ||
              wbsEvent.IsLoading
            }
          />
          <ActivityDetail
            onImageOpen={onImageOpen}
            onDocumentOpen={onDocumentOpen}
            onThumbnailOpen={onThumbnailOpen}
            checkListData={CheckList}
            originalData={originalActivity}
            currentData={currentActivity}
            onRejectTask={onRejectTask}
            onAcceptTask={onAcceptTask}
            onSendTask={onSendTask}
            onTakeActivity={onTakeActivty}
            onUpdateCheckList={onSetCheckList}
            onUpdateActivity={onSetActivity}
            wbs={wbs}
            isLoading={activityEvent.IsLoading}
            onHistoryOpen={() => setShowHistoryModal(true)}
          />

          {currentActivity.RequestProgress === 100 &&
            originalActivity.TaskStatus === 'APPV' && (
              <FooterContent>
                <Row type="flex" justify="end" align="middle">
                  <Col md={4}>
                    <PrimaryButton
                      text="อัปเดตไฟล์"
                      onClick={() => onEnableUpdateFiles()}
                      disabled={disableConditionBtn || disableImgBlank}
                    />
                  </Col>
                </Row>
              </FooterContent>
            )}

          <WarningModal
            onOk={autoUpdateProgress}
            desc="ระบบจะแก้ไขความคืบหน้า (%) ให้เป็น 100% ให้อัตโนมัติ กรณีที่คุณเลือกตรวจสอบผ่านทั้งหมด"
            visible={shoWarningAuto100}
          />
          {historyModal}
        </>
      )
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    originalActivity: selectOriginalActivity(state),
    currentActivity: selectCurrentActivity(state),
    CheckList: selectCheckList(state),
    UserEmail: userEmailSelector(state),
    Token: serviceTokenSelector(state),
    ProjectCode: selectedProjectCodeSelector(state),
    wbsPathMap: getWbsPathMapSelector(state),
    activityEvent: selectEvent(state),
    checklistEvent: selectEventCheckList(state),
    wbs: getWbsMapParentsSelector(state),
    historyData: selectData(state),
    historyEvent: selectHistoryEvent(state),
    wbsEvent: getEvent(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {
  updateOriginalActivity,
}

const FooterContent = styled.div`
  width: 100%;
  padding: 1%;
  font-size: 20px;
  padding-right: 40px;
  background-color: white;
`

export default connect(mapStateToProps, mapDispatchToProps)(CmActivity)
