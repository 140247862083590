import produce from 'immer'
import parseErrorMessage from 'utils/parseErrorMessage'
import { getType, ActionType } from 'typesafe-actions'
import * as actions from './actions'
import { CommissonState } from './types'

const initialState: CommissonState = {
  CommissionList: {
    TotalRows: 0,
    List: [],
  },
  CommissionDetail: {
    CheckListID: 0,
    CheckListName: '',
    CheckListType: 0,
    Status: 'Ready',
    ProjectList: [],
    DataList: [],
  },
  Q1Act: undefined,
  CommissionAct: undefined,
  SafetyAct: undefined,
  Event: {
    IsLoading: false,
    SubmitError: '',
    SubmitSuccess: false,
    SubmitType: '',
    MessageSuccess: '',
  },
  ActvityList: [],
}

export type CommissonAction = ActionType<typeof actions>

export default function(
  state: CommissonState = initialState,
  action: CommissonAction
): CommissonState {
  return produce(state, draft => {
    switch (action.type) {
      case getType(actions.clearEvent):
        draft.Event = {
          IsLoading: false,
          SubmitError: '',
          SubmitSuccess: false,
          SubmitType: '',
          MessageSuccess: '',
        }
        break
      case getType(actions.clearCommissionDetail):
        draft.CommissionDetail = {
          CheckListID: 0,
          CheckListName: '',
          CheckListType: 0,
          Status: 'Ready',
          ProjectList: [],
          DataList: [],
        }
        draft.Q1Act = undefined
        draft.CommissionAct = undefined
        draft.SafetyAct = undefined
        draft.ActvityList = []
        break

      case getType(actions.getCommissionListReq.request):
      case getType(actions.creatUpdateCommissionDetailReq.request):
      case getType(actions.deleteReq.request):
        draft.Event.SubmitError = ''
        draft.Event.IsLoading = true
        break

      case getType(actions.getCommissionDetailReq.request):
        // don't clear summit error, coz it will clear error if there is
        draft.Event.IsLoading = true
        break
      case getType(actions.getCommissionListReq.success):
        draft.Event.IsLoading = false
        draft.CommissionList = {
          TotalRows: action.payload.ResponseData.TotalRows || 0,
          List: action.payload.ResponseData.List || [],
        }
        break
      case getType(actions.getCommissionDetailReq.success):
        draft.Event.IsLoading = false
        const { ResponseData } = action.payload.res
        if (draft.CommissionDetail.CheckListID === 0) {
          draft.CommissionDetail = {
            ...ResponseData,
            ProjectList: ResponseData.ProjectList || [],
          }
        }

        if (
          ResponseData.DataList == null ||
          ResponseData.DataList.length === 0
        ) {
          const { CommissioningType } = action.payload
          if (CommissioningType === 1) {
            draft.Q1Act = []
          } else if (CommissioningType === 2) {
            draft.CommissionAct = []
          } else if (CommissioningType === 3) {
            draft.SafetyAct = []
          }
        } else {
          ResponseData.DataList.forEach(d => {
            const list = d.List || []
            d.List.forEach(data => {
              if (data.Data == null) {
                data.Data = []
              } else {
                data.Data.forEach(data2 => {
                  if (data2.Data == null) {
                    data2.Data = []
                  }
                })
              }
            })
            if (d.CommissioningType === 1) {
              draft.Q1Act = list
            } else if (d.CommissioningType === 2) {
              draft.CommissionAct = list
            } else if (d.CommissioningType === 3) {
              draft.SafetyAct = list
            }
          })
        }

        break
      case getType(actions.creatUpdateCommissionDetailReq.success):
        draft.Event.IsLoading = false
        draft.Event.SubmitError = undefined
        draft.Event.SubmitSuccess = true
        draft.Event.MessageSuccess = 'ทำรายการสำเร็จ'
        break

      case getType(actions.getActivityByCommissioningReq.request):
        draft.Event.IsLoading = true
        draft.ActvityList = []
        break

      case getType(actions.getActivityByCommissioningReq.success):
        draft.Event.IsLoading = false
        draft.ActvityList = action.payload.ResponseData.ActivityList || []
        break

      case getType(actions.getCommissionListReq.failure):
      case getType(actions.getCommissionDetailReq.failure):
      case getType(actions.creatUpdateCommissionDetailReq.failure):
      case getType(actions.getActivityByCommissioningReq.failure):
      case getType(actions.deleteReq.failure):
        draft.Event.IsLoading = false
        draft.Event.SubmitError = parseErrorMessage(action.payload)
        break

      case getType(actions.deleteReq.failure):
        draft.Event.IsLoading = false
        draft.Event.SubmitError = parseErrorMessage(action.payload)
        break

      case getType(actions.updateCheckListDetail):
        const {
          Name = '',
          Type = 0,
          Q1List,
          CommissionList,
          SafetyList,
        } = action.payload

        draft.ActvityList = []

        draft.Q1Act = Q1List ? Q1List : []
        draft.CommissionAct = CommissionList ? CommissionList : []
        draft.SafetyAct = SafetyList ? SafetyList : []

        const DataList: any = []
        if (Q1List)
          DataList.push({
            CommissioningType: 1,
            CommissioningTypeName: 'Q1',
            List: Q1List,
          })

        if (CommissionList)
          DataList.push({
            CommissioningType: 2,
            CommissioningTypeName: 'Commissioning',
            List: CommissionList,
          })

        if (SafetyList)
          DataList.push({
            CommissioningType: 3,
            CommissioningTypeName: 'Safety',
            List: SafetyList,
          })

        draft.CommissionDetail = {
          CheckListID: 0,
          CheckListName: Name,
          CheckListType: Type,
          Status: 'Ready',
          ProjectList: [],
          DataList,
        }

        break
      default:
        return state
    }
  })
}
