import React, { useState, useEffect } from 'react'
import RootState from 'typings/RootState'
import { connect, useDispatch } from 'react-redux'
import { Row, Col, Layout } from 'antd'
import styled from 'styled-components'
import { selectEvent } from 'features/pmr/selector'
import {
  userEmailSelector,
  serviceTokenSelector,
} from 'features/sensitive/sensitive.selector'

import { selectUploadEvent } from 'features/uploadfile/selector'
import { selectDefect } from 'features/pmr/selector'
import DefectStatus from 'Components/Defect/DefectStatus'
import DefectImage from 'Components/Zone/DefectImage'
import ApproveFooter from './ApproveFooter'
import { DefectItem } from 'features/pmr/types'
import DefectInfo from '../DefectInfo'

const { Content } = Layout

type Props = {
  onApprove?: (item: DefectItem) => void
  onReject?: (item: DefectItem) => void
  floor?: any
} & ReduxProps

const DefectView: React.FunctionComponent<Props> = props => {
  const noFunc = () => {}
  const { defect, onApprove = noFunc, onReject = noFunc, floor = 0 } = props

  let footer = (
    <ApproveFooter
      onReject={() => onReject(defect)}
      onApprove={() => onApprove(defect)}
    />
  )

  return (
    <>
      <Content className="mid-content">
        <div className="main-content activity-detail">
          <DefectInfo defect={defect} />
          <DefectImage
            planImg={
              defect.ImageFloorPlan ? defect.ImageFloorPlan.FileURL : undefined
            }
            defect={defect}
            onAddAfterImage={() => {}}
            viewMode={true}
            canUpAfterImg={false}
            floor={floor}
          />
        </div>
      </Content>
      {footer}
    </>
  )
}

const Title = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 28px;

  span {
    font-family: 'DB Helvethaica X 55 Regular';
    font-size: 16px;
    color: white;
    padding: 1px 10px;
    border-radius: 10px;
    margin-left: 12px;
  }
`

const MainData = styled.div`
  border-radius: 5px;
  border: solid 1px #eeeff2;
  background-color: white;
  padding: 24px;
`

const DisplayItem = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 20px;

  p {
    font-family: 'DB Helvethaica X 55 Regular';
  }
`

const mapStateToProps = (state: RootState) => {
  return {
    Token: serviceTokenSelector(state),
    UserEmail: userEmailSelector(state),
    defect: selectDefect(state),
    event: selectEvent(state),
    uploadEvent: selectUploadEvent(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DefectView)
