import React from 'react'
import { Col, Row, Switch } from 'antd'
import styled from 'styled-components'
import { TableRow } from '../../Shared/Table'
import { DataItem } from 'features/adminpj/modelHouse/types'

type DefaultProps = {
  item: DataItem
  onUpdate: (item: DataItem) => void
}

type Props = DefaultProps

const RowItem: React.FC<Props> = props => {
  const { item, onUpdate } = props

  const onClick = () => onUpdate(item)

  return (
    <>
      <TableRow minHeight={60} spaceBetweenRow={8} paddingLeft={27}>
        <Space />
        <Col span={4}>
          <Name>{item.Unit}</Name>
        </Col>
        <Col span={4}>
          <Name>{item.Model}</Name>
        </Col>
        <Col span={4}>
          <Name>{item.UpdateBy}</Name>
        </Col>
        <Col span={4}>
          <Name>{item.UpdateDate}</Name>
        </Col>
        <Col span={4}>
          <SwithArea>
            <Switch
              checked={item.ModelHouseFlag}
              onChange={onClick}
              disabled={false}
            />
          </SwithArea>
        </Col>
      </TableRow>
    </>
  )
}

const Name = styled.div`
  //font-family: 'DB Helvethaica X 75 Bd';
  color: #000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 4px;
`
const SwithArea = styled.div`
  padding: 0 30px;
  float: left;
`

const Space = styled.div``

export default RowItem
