import React, { useState } from 'react'
import styled from 'styled-components'
import { Row, Col, Menu, Dropdown } from 'antd'
import { ModelListItem } from 'features/admin/model/types'
import { cloneDeep } from 'lodash'
import imgAdd from 'assets/img/ic-add.png'

import { TrashIcon, ArrowDownIcon, ArrowUpIcon } from 'Components/Shared/Icon'

type Props = {
  direction: string
  item: ModelListItem
  onChangeData: (val: any) => void

  selectedFloor: number
  onSelectFloor: (val: number) => void
}

const ImagePlan = {
  ActualFileName: '',
  FileID: 0,
  FileName: '',
  FileSize: 0,
  FileURL: '',
}

const FloorMenu: React.ComponentType<Props> = props => {
  const { direction, item, onChangeData, selectedFloor, onSelectFloor } = props
  const [showMenu, setShowMenu] = useState(false)
  const isUsed = item.Status.toLocaleLowerCase() === 'used'

  const onAddFloor = (floor: number) => {
    const currentFloor = ++floor

    item.ConfigList.push({
      ImageFloorPlan: cloneDeep(ImagePlan),
      DirectionName: direction,
      UnitFloorName: `${currentFloor}`,
    })
    onChangeData(item)
    setShowMenu(false)
    onSelectFloor(item.ConfigList.length - 1)
  }

  const onRemoveFloor = (index: number) => {
    item.ConfigList.splice(index, 1)
    onChangeData(item)
    const idx = item.ConfigList.findIndex(it => it.DirectionName === direction)
    onSelectFloor(idx)
    setShowMenu(false)
  }

  const selectFloor = (index: number) => {
    onSelectFloor(index)
    setShowMenu(false)
  }

  const FloorName =
    selectedFloor > -1 && selectedFloor < item.ConfigList.length
      ? item.ConfigList[selectedFloor].UnitFloorName
      : 'เลือก'

  const filterMenu: any[] = []
  item.ConfigList.forEach((floor, index) => {
    if (floor.DirectionName === direction) {
      filterMenu.push({ floor, index })
    }
  })

  const menu = []
  const len = filterMenu.length
  filterMenu.forEach((it, idx) => {
    const isLastItem = len > 1 && idx === len - 1
    const { floor, index } = it
    menu.push(
      <Menu.Item key={idx}>
        <MenuItem onClick={() => selectFloor(index)}>
          <Row type="flex" justify="space-between" align="bottom">
            <Col>
              <MenuFloor>{floor.UnitFloorName}</MenuFloor>
            </Col>
            {isLastItem && (
              <Col>
                <div
                  className="transh-icon"
                  onClick={evt => onRemoveFloor(index)}
                >
                  <TrashIcon color="#8b99a7" size={16} />
                </div>
              </Col>
            )}
          </Row>
        </MenuItem>
      </Menu.Item>
    )
  })

  const menuLen = menu.length

  if (!isUsed) {
    menu.push(
      <Menu.Item key={menuLen}>
        <MenuAdd onClick={evt => onAddFloor(menuLen)}>
          <img src={imgAdd} />
          <span>เพิ่มชั้น</span>
        </MenuAdd>
      </Menu.Item>
    )
  }

  const menuList = <Menu>{menu}</Menu>

  return (
    <InList>
      <Dropdown
        overlay={menuList}
        trigger={['click']}
        overlayStyle={{ width: '192px' }}
        onVisibleChange={val => setShowMenu(val)}
      >
        <a onClick={e => e.preventDefault()}>
          <FloorSelected>
            ชั้น <span>{FloorName}</span>
            {showMenu ? <ArrowUpIcon /> : <ArrowDownIcon />}
          </FloorSelected>
        </a>
      </Dropdown>
    </InList>
  )
}

const InList = styled.div`
  p {
    margin: 0px;
    font-size: 20px;
    font-family: 'DB Helvethaica X 55 Regular';
    color: black;
  }

  .ant-dropdown-trigger {
    margin-bottom: 12px;
  }
  .input-title {
    margin-bottom: 14px;
  }
`

const MenuItem = styled.div`
  font-family: 'DB Helvethaica X 55 Regular';
  font-size: 20px;
  color: #000000;
  cursor: pointer;
  .transh-icon {
    display: none;
  }
  &:hover {
    .transh-icon {
      display: block;
    }
  }
`
const MenuFloor = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 20px;
  color: #003168;
  padding: 0px 10px;
`

const MenuAdd = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 20px;
  color: #003168;
  cursor: pointer;
  img {
    width: 16px;
  }
  padding: 0px 10px;
  span {
    padding-left: 8px;
  }
`

const FloorSelected = styled.div`
  width: 335px;
  height: 48px;
  border-radius: 5px;
  border: solid 1px #e4e7eb;
  color: black;
  background-color: #ffffff;
  align-items: center;
  display: flex;
  padding: 0px 16px;

  span {
    width: 125px;
    text-align: right;
  }
  img {
    position: absolute;
    right: 0px;
  }
`

export default FloorMenu
