import RootState from 'typings/RootState'
import { Event } from '../../types/event'
import { DataList, DataDetail } from './types'

export const selectEvent = (state: RootState): Event =>
  state.adminReportCommonArea.Event

export const selectReportList = (state: RootState): DataList =>
  state.adminReportCommonArea.DataList

export const selectReportDetail = (state: RootState): DataDetail =>
  state.adminReportCommonArea.DataDetail

export const selectFilter = (state: RootState) =>
  state.adminReportCommonArea.Filter

export const selectSorting = (state: RootState) =>
  state.adminReportCommonArea.sorting
