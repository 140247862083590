import React from 'react'
import { TableRow } from './index'
import { Col } from 'antd'

type HeaderProps = {
  title: string | any
  span: number
  offset?: number
}

type Props = {
  items: HeaderProps[]
  minHeight?: number
  spaceBetweenRow?: number
  paddingLeft?: number
}

const ContentRow: React.FunctionComponent<Props> = props => {
  const { items, minHeight = 60, spaceBetweenRow = 8, paddingLeft = 24 } = props

  const renderContent = (item: HeaderProps[]) => {
    return item.map((item: HeaderProps, index: number) => {
      return (
        <Col span={item.span} key={index} offset={item.offset}>
          {item.title}
        </Col>
      )
    })
  }

  return (
    <TableRow
      minHeight={minHeight}
      paddingLeft={paddingLeft}
      spaceBetweenRow={spaceBetweenRow}
    >
      {renderContent(items)}
    </TableRow>
  )
}

export default ContentRow
