import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Row, Col, Button } from 'antd'
import ContentBox from 'Components/Shared/Card/ContentBox'

import { DetailType } from 'features/checklist/constructRoad/types'
import { RadioBox } from 'Components/Shared/Form'
import { InputDouble } from 'Components/Shared/Input'

type Props = {
  item?: DetailType
  onSave: () => void
  disabled: boolean
}

type PropsItem = {
  title: string
  isCheck: boolean
  onChange: (value: boolean) => void
  disabled: boolean
}

const ConfigItemComponent = ({
  title,
  isCheck,
  onChange,
  disabled,
}: PropsItem) => {
  return (
    <ConfigItem>
      <Row type="flex" justify="space-between">
        <Col> {title} </Col>
        <Row type="flex" justify="space-around" style={{ width: 150 }}>
          <RadioBox
            label={'ไม่'}
            value={!isCheck}
            isCheck={!isCheck}
            onClick={onChange}
            readOnly={disabled}
          />
          <RadioBox
            label={'ใช่'}
            value={!isCheck}
            isCheck={isCheck}
            onClick={onChange}
            readOnly={disabled}
          />
        </Row>
      </Row>
    </ConfigItem>
  )
}

const DetailConfigBox: React.ComponentType<Props> = ({
  item,
  onSave,
  disabled,
}) => {
  const [isDefect, setisDefect] = useState<boolean>(false)
  const [isSupplier, setisSupplier] = useState<boolean>(false)
  const [point1, setpoint1] = useState('0')
  const [point2, setpoint2] = useState('0')

  useEffect(() => {
    if (item) {
      setisDefect(item.IsRequired)
      setisSupplier(item.IsSupplier)
      setpoint1(item.Score.toString())
      setpoint2(item.NCRScore.toString())
    }
  }, [item])

  useEffect(() => {
    if (item) {
      setisDefect(item.IsRequired)
      setisSupplier(item.IsSupplier)
    }
  }, [item])

  const updateDefect = (value: boolean) => {
    setisDefect(value)
    if (item) {
      item.IsRequired = value
    }
  }

  const updateSupplier = (value: boolean) => {
    setisSupplier(value)
    if (item) {
      item.IsSupplier = value
    }
  }

  const updatePoint1 = (value: string) => {
    setpoint1(value)
    if (item) {
      item.Score = parseFloat(value)
    }
  }

  const updatePoint2 = (value: string) => {
    setpoint2(value)
    if (item) {
      item.NCRScore = parseFloat(value)
    }
  }

  return (
    <>
      <ContentBox
        title="ตั้งค่ารายละเอียด"
        isEmpty={item == null}
        hasSearch={false}
        emptyText="กรุณาเลือก “รายละเอียด”"
      >
        <ListSection>
          <ConfigItemComponent
            title="เป็น Major Defect หรือไม่"
            isCheck={isDefect}
            onChange={updateDefect}
            disabled={disabled}
          />
          <ConfigItemComponent
            title="เป็น Supplier หรือไม่"
            isCheck={isSupplier}
            onChange={updateSupplier}
            disabled={disabled}
          />
          <InputDouble
            prefix="เกณฑ์หักคะแนน"
            placeholder="กรอกคะแนน"
            onChange={updatePoint1}
            value={point1}
            disabled={disabled}
          />
          <div style={{ height: 12 }} />
          <InputDouble
            prefix="ระดับคะแนนที่เกิดซ้ำ (NCR)"
            placeholder="กรอกคะแนน"
            onChange={updatePoint2}
            value={point2}
            disabled={disabled}
          />
          {!disabled && (
            <ButtonSection>
              <Button onClick={onSave}>บันทึก</Button>
            </ButtonSection>
          )}
        </ListSection>
      </ContentBox>
    </>
  )
}

const ListSection = styled.div`
  margin-top: 5px;
  overflow: auto;
  padding: 0 24px;
`

const ButtonSection = styled.div`
  margin-top: 25px;
  button {
    width: 160px;
    height: 50px;
    font-family: 'DB Helvethaica X 75 Bd';
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #003168;
    border: 1.5px solid #003168;
    border-radius: 5px;
  }
`

const ConfigItem = styled.div`
  font-family: 'DB Helvethaica X 55 Regular';
  font-size: 20px;
  padding-bottom: 10px;
`

export default DetailConfigBox
