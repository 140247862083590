import React from 'react'
import AdminTab from 'Components/Shared/AdminTab'

interface Props {
  selected: 'ByUnit' | 'ByQC'
}

const ReportUnitTab: React.FunctionComponent<Props> = props => {
  const tabItem = [
    {
      link: '/report/unit',
      title: 'รายงานตามยูนิต',
      isActive: props.selected == 'ByUnit',
    },
    {
      link: '/report/unitbyqc',
      title: 'รายงานตามหมวดงาน',
      isActive: props.selected == 'ByQC',
    },
  ]

  return <AdminTab tabItem={tabItem} />
}

export default ReportUnitTab
