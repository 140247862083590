import { sortingList, sortingByMultiParams } from '../../../utils/helper'
import { DataItem } from './types'

export const sortingActivityList = (
  list: DataItem[],
  type: string,
  name: string | undefined
): DataItem[] => {
  try {
    let newList: DataItem[] = list
    switch (name) {
      case 'CheckNumber':
      case 'NumOfGoodWork':
      case 'NumOfFixed':
      case 'NumOfPass':
      case 'NumOfToBeFix':
        newList = sortingList(list, type, name, 'number')
        break
      case 'InspectDate':
        newList = sortingList(list, type, name, 'date')
        break
      case 'Location':
        const params = ['NumOfSelectLocation', 'TotalLocation']
        newList = sortingByMultiParams(list, type, params)
        break
      default:
        newList = sortingList(list, type, name || '', 'string')
    }

    return newList
  } catch (e) {
    return list
  }
}
