import { CheckListState, CheckListAction } from './types'

import { Event } from '../../types/event'
import produce from 'immer'
import parseErrorMessage from 'utils/parseErrorMessage'
import { getType } from 'typesafe-actions'
import * as actions from './actions'
import { cloneDeep } from 'lodash'

const initEvent = {
  IsLoading: false,
  MessageSuccess: undefined,
  SubmitError: undefined,
  SubmitSuccess: false,
  SubmitType: '',
} as Event

const initialState: CheckListState = {
  CheckList: {
    TotalRows: 0,
    List: [],
  },
  Event: cloneDeep(initEvent),
}

export default function(
  state: CheckListState = initialState,
  action: CheckListAction
): CheckListState {
  return produce(state, draft => {
    switch (action.type) {
      case getType(actions.setCheckList):
        const { checklist } = action.payload
        const index = draft.CheckList.List.findIndex(
          item => item.CheckListID === checklist.CheckListID
        )
        if (index !== -1) {
          draft.CheckList.List[index] = checklist
        }

        break
      case getType(actions.getCheckListAction.request):
        draft.Event = cloneDeep(initEvent)
        draft.Event.IsLoading = true
        draft.CheckList = {
          TotalRows: 0,
          List: [],
        }
        break

      case getType(actions.getCheckListAction.success):
        if(action.payload.ResponseData  != null) {
          draft.CheckList = action.payload.ResponseData
        } else {
          draft.CheckList = {
            TotalRows: 0,
            List: [],
          }
        }
        draft.Event.IsLoading = false
        break

      case getType(actions.updateCheckListAction.request):
        draft.Event = cloneDeep(initEvent)
        draft.Event.IsLoading = true
        break

      case getType(actions.updateCheckListAction.success):
        draft.Event.IsLoading = false
        draft.Event.SubmitSuccess = true
        draft.Event.MessageSuccess = 'ทำรายการสำเร็จ'
        break

      case getType(actions.getCheckListAction.failure):
      case getType(actions.updateCheckListAction.failure):
        draft.Event.IsLoading = false
        draft.Event.SubmitError = parseErrorMessage(action.payload)
        break
      default:
        return state
    }
  })
}
