import React, { useRef } from 'react'
import { Col, Layout, Row } from 'antd'
import styled from 'styled-components'
import {
  ReportHeader,
  ReportLogo,
  DescItem,
  TaskScore,
  Signature,
  StatusTag,
} from 'Components/AdminQC/Report/Components'
import { DataDetail } from 'features/adminReport/constructPlan/types'
import DefectItem from './DefectItem'
import { toDateText, toMomentDateTime } from 'utils/datetime'
import { sortingList } from 'utils/helper'
import mapUnitStatus, { unitStatusIsPass } from 'utils/status-map'

const { Content } = Layout

type Props = {
  data: DataDetail
}

const ReportDetail: React.ComponentType<Props> = props => {
  const componentPrint = useRef(null)
  const { data } = props
  const renderDescItem = (title: string, value: any) => {
    return <DescItem title={title} value={value} percentW={16} />
  }

  const statusTxt = mapUnitStatus(data.CheckStatus).status
  const isPass = unitStatusIsPass(true, data.CheckStatus)

  const totalReturnDate = `${data.TotalReturnDate} วัน`

  const displayTotalReturnDate =
    data.TotalReturnDate === 0 ||
    data.TotalReturnDate <= data.ConfigReturnDate ? (
      <PassScore>{totalReturnDate}</PassScore>
    ) : (
      <FailScore>{totalReturnDate}</FailScore>
    )

  const DescContent = (
    <DesBox>
      <Row type="flex">
        {renderDescItem('โครงการ', `${data.ProjectCode}-${data.ProjectNameTH}`)}
        {renderDescItem('ตรวจครั้งที่', data.CheckNumber)}
        {renderDescItem(
          'วันที่ตรวจ',
          toDateText(toMomentDateTime(data.InspectDate))
        )}
        {renderDescItem('ระยะเวลาตอบกลับ', displayTotalReturnDate)}
        {renderDescItem('ผู้ตรวจสอบ', data.CheckedBy)}

        <Col style={{ width: '20%' }}>
          <Row>
            <Col span={24}>
              <DescItem
                title={'ผลการตรวจ'}
                value={<StatusTag text={statusTxt} isPass={isPass} />}
                percentW={100}
              />
            </Col>
            <Col span={24}>
              <TaskScore
                checkStatus={(data && data.CheckStatus) || ''}
                score={(data && data.InspectionScore) || 0}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </DesBox>
  )

  const DefectItemContent = data.DefectList.map((item, index) => {
    return <DefectItem index={index} defect={item} key={index} />
  })

  return (
    <Content className="mid-content">
      <div className="main-content activity-detail">
        <ReportHeader
          title="Site visit - ระหว่างก่อสร้าง"
          componentPrint={componentPrint}
        />
        <Box ref={componentPrint}>
          <ReportLogo title="Site visit - ระหว่างก่อสร้าง" />
          {DescContent}
          <ContentBox>{DefectItemContent}</ContentBox>
          <Signature data={data.Signature} />
        </Box>
      </div>
    </Content>
  )
}

const Box = styled.div`
  background-color: rgba(0, 49, 104, 0.05);
  border-radius: 8px;
  border: solid 1px #e4e7eb;
  color: #000;

  @media print {
    background-color: white;
    border: solid 0px #e4e7eb;
  }
`

const DesBox = styled.div`
  padding: 12px 24px;
  background-color: #fff;
`

const ContentBox = styled.div`
  padding: 24px;
`
const PassScore = styled.div`
  color: #63cea6;
`
const FailScore = styled.div`
  color: #d0494c;
`

export default ReportDetail
