/* eslint-disable */
import React, { useState, useCallback, useLayoutEffect } from 'react'
import styled from 'styled-components'

const BaseEditor = props => {
  let pointX = 0,
    pointY = 0,
    lastX = 0,
    lastY = 0
  let mode
  let {
    initial = {},
    displayConner = true,
    displaySide = true,
    displayBottom = false,
    hideBorder = false,
    isCircle = true,
    lineSize = 3,
    color = '#DEDEDE',
    limit = {
      left: 0,
      top: 0,
      bottom: 1000,
      right: 2000,
      width: 100,
      height: 50,
    },
    children,
  } = props

  const [cropper, setCropper] = useState({ offsetWidth: 0, offsetHeight: 0 })
  const [size, setSize] = useState({ width: 150, height: 150 })
  const [position, setPosition] = useState({ x: 424, y: 168 })

  const onRefCrop = useCallback(node => {
    if (node) {
      setCropper(node)
    }
  }, [])

  useLayoutEffect(() => {
    if (initial.size) {
      setSize(initial.size)
    }

    if (initial.position) {
      setPosition(initial.position)
    }
  }, [initial])

  const onPosition = ({ x, y, width = size.width, height = size.height }) => {
    if (props.onPosition) {
      props.onPosition(x, y, width, height)
    }
  }

  const setCropPosition = (x, y) => {
    if (x < limit.left) x = limit.left
    else if (x > limit.right - cropper.offsetWidth) {
      x = limit.right - cropper.offsetWidth
    }

    if (y < limit.top) y = limit.top
    else if (y > limit.bottom - cropper.offsetHeight) {
      y = limit.bottom - cropper.offsetHeight
    }

    setPosition({ x, y })
  }

  const onMoveDown = evt => {
    if (mode !== undefined) return

    mode = 'moving'
    lastX = evt.clientX
    lastY = evt.clientY

    document.onmouseup = onMoveUp
    document.onmousemove = onMove
  }

  const onResizeDown = (evt, m) => {
    if (mode !== undefined) return

    mode = m
    lastX = evt.clientX
    lastY = evt.clientY

    document.onmouseup = onMoveUp
    document.onmousemove = onResizing
  }

  const onMoveUp = evt => {
    document.onmouseup = null
    document.onmousemove = null
    mode = undefined
  }

  const onMove = evt => {
    if (mode !== 'moving') return
    evt.preventDefault()

    pointX = lastX - evt.clientX
    pointY = lastY - evt.clientY
    lastX = evt.clientX
    lastY = evt.clientY

    let x = cropper.offsetLeft - pointX
    let y = cropper.offsetTop - pointY

    if (x < limit.left) x = limit.left
    else if (x > limit.right - cropper.offsetWidth) {
      x = limit.right - cropper.offsetWidth
    }

    if (y < limit.top) y = limit.top
    else if (y > limit.bottom - cropper.offsetHeight) {
      y = limit.bottom - cropper.offsetHeight
    }

    setPosition({ x, y })
    onPosition({ x, y })
  }

  const checkSizeLimit = (w, h) => {
    return w < limit.width || h < limit.height
  }

  const onResizing = evt => {
    if (mode === 'moving' || mode === undefined) return
    evt.preventDefault()

    pointX = lastX - evt.clientX
    pointY = lastY - evt.clientY
    let val
    let x, y
    let nWidth = size.width
    let nHeight = size.height

    switch (mode) {
      case 'resize_top':
        nHeight = nHeight + pointY
        x = position.x
        y = position.y - pointY
        if (nHeight <= limit.height) nHeight = limit.height
        if (nHeight + position.y > limit.bottom) {
          nHeight = limit.bottom - position.y
        }
        if (y < 0) y = 0

        break
      case 'resize_down':
        nHeight = nHeight - pointY
        if (nHeight <= limit.height) nHeight = limit.height
        if (nHeight + position.y > limit.bottom) {
          nHeight = limit.bottom - position.y
        }
        break

      case 'resize_left':
        nWidth = nWidth + pointX
        x = position.x - pointX
        y = position.y
        if (nWidth <= limit.width) nWidth = limit.width
        if (nWidth + position.x > limit.right) {
          nWidth = limit.right - position.x
        }
        if (x < 0) x = 0
        break

      case 'resize_right':
        nWidth = nWidth - pointX
        if (nWidth <= limit.width) return
        if (nWidth + position.x > limit.right) {
          nWidth = limit.right - position.x
        }
        break

      case 'resize_right_bottom':
        nWidth = nWidth - pointX
        nHeight = nHeight - pointX

        if (checkSizeLimit(nWidth, nHeight)) return
        if (nWidth + position.x > limit.right) return
        if (nHeight + position.y > limit.bottom) return
        break

      case 'resize_right_top':
        nWidth = nWidth - pointX
        nHeight = nHeight - pointX
        x = position.x
        y = position.y + pointX

        if (checkSizeLimit(nWidth, nHeight)) return
        if (y < 0) return
        if (nWidth + x > limit.right) return
        break

      case 'resize_left_bottom':
        nHeight = nHeight + pointX
        nWidth = nWidth + pointX
        x = position.x - pointX
        y = position.y

        if (checkSizeLimit(nWidth, nHeight)) return
        if (x < 0) return
        if (nHeight + y > limit.bottom) return
        break

      case 'resize_left_top':
        nHeight = nHeight + pointX
        nWidth = nWidth + pointX
        x = position.x - pointX
        y = position.y - pointX

        if (checkSizeLimit(nWidth, nHeight)) return
        if (x < 0) return
        if (y < 0) return
        break

      default:
    }

    if (props.onValidate) {
      const isValid = props.onValidate({
        x: x || position.x,
        y: y || position.y,
        width: nWidth,
        height: nHeight,
      })
      if (isValid === false) return
    }

    if (x || y) {
      setCropPosition(x, y)
    } else {
      x = position.x
      y = position.y
    }

    const width = nWidth
    const height = nHeight
    setSize({ width, height })
    onPosition({ x, y, width, height })
  }

  let offset = -6
  let offset2 = -8
  switch (lineSize) {
    case 4:
      offset = -6
      offset2 = -10
      break
    case 5:
      offset = -7
      offset2 = -11
      break
    case 6:
      offset = -8
      offset2 = -12
      break
    case 7:
      offset = -8
      offset2 = -14
      break
    default:
  }

  const { width, height } = size
  const wHaft = Math.round(width / 2) + offset
  const hHaft = Math.round(height / 2) + offset2
  const wFull = width + offset2
  const hFull = height + offset2
  const borderRadius = isCircle ? '50%' : '2px'
  const css = {
    borderRadius,
    border: `${hideBorder ? 0 : lineSize}px solid ${color}`,
    width: `${width}px`,
    height: `${height}px`,
    left: `${position.x}px`,
    top: `${position.y}px`,
  }

  const innerCss = {
    borderRadius,
    width: `${width}px`,
    height: `${height}px`,
  }

  let sideDiv
  if (displaySide) {
    sideDiv = (
      <>
        <div
          className="resize-top"
          style={{ left: `${wHaft}px`, top: `${offset}px` }}
          onMouseDown={evt => onResizeDown(evt, 'resize_top')}
        />
        <div
          className="resize-bottom"
          style={{ left: `${wHaft}px`, top: `${hFull}px` }}
          onMouseDown={evt => onResizeDown(evt, 'resize_down')}
        />

        <div
          className="resize-right"
          style={{ left: `${wFull}px`, top: `${hHaft}px` }}
          onMouseDown={evt => onResizeDown(evt, 'resize_right')}
        />
        <div
          className="resize-left"
          style={{ left: `${offset}px`, top: `${hHaft}px` }}
          onMouseDown={evt => onResizeDown(evt, 'resize_left')}
        />
      </>
    )
  } else if (displayBottom) {
    sideDiv = (
      <div
        className="resize-bottom"
        style={{ left: `${wHaft}px`, top: `${hFull}px` }}
        onMouseDown={evt => onResizeDown(evt, 'resize_down')}
      />
    )
  }

  let connerDiv
  if (displayConner) {
    connerDiv = (
      <>
        <div
          className="resize-left-top"
          style={{ left: `${offset}px`, top: `${offset}px` }}
          onMouseDown={evt => onResizeDown(evt, 'resize_left_top')}
        />

        <div
          className="resize-left-bottom"
          style={{ left: `${offset}px`, top: `${hFull}px` }}
          onMouseDown={evt => onResizeDown(evt, 'resize_left_bottom')}
        />

        <div
          className="resize-right-top"
          style={{ left: `${wFull}px`, top: `${offset}px` }}
          onMouseDown={evt => onResizeDown(evt, 'resize_right_top')}
        />

        <div
          className="resize-right-bottom"
          style={{ left: `${wFull}px`, top: `${hFull}px` }}
          onMouseDown={evt => onResizeDown(evt, 'resize_right_bottom')}
        />
      </>
    )
  }
  return (
    <Cropper ref={onRefCrop} style={css} onMouseDown={onMoveDown}>
      <Circle style={innerCss} onDoubleClick={props.onDoubleClick}>
        {sideDiv}

        {connerDiv}
      </Circle>
      {children}
    </Cropper>
  )
}

const Cropper = styled.div`
  z-index: 1000;
  position: absolute;
  :hover {
    cursor: move;
  }
`

const point = `
  width: 8px;
  height: 8px;
  background-color: white;
  border: 1px solid #979797;
  position: absolute;
`

const Circle = styled.div`
  z-index: 4;
  position: relative;
  .resize-top,
  .resize-bottom {
    ${point}
    :hover {
      cursor: ns-resize;
    }
  }

  .resize-left,
  .resize-right {
    ${point}
    :hover {
      cursor: ew-resize;
    }
  }

  .resize-left-top,
  .resize-right-bottom {
    ${point}
    :hover {
      cursor: nwse-resize;
    }
  }

  .resize-left-bottom,
  .resize-right-top {
    ${point}
    :hover {
      cursor: nesw-resize;
    }
  }
`

export default BaseEditor
/* eslint-enable */
