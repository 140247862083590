import React from 'react'
import styled from 'styled-components'
import { Row, Col, Layout } from 'antd'
import { PrimaryButton } from 'Components/Shared/Button'
const { Footer } = Layout

type Props = {
  onSave?: () => void
  disabled?: boolean
}

const FooterView: React.FC<Props> = ({ onSave, disabled = false }) => {
  return (
    <Footer className="detail-page-footer">
      <FooterContent>
        <Row type="flex" justify="end" align="middle">
          <Col>
            <Button>
              <PrimaryButton
                text="ส่งรายงาน"
                onClick={onSave}
                disabled={disabled}
              />
            </Button>
          </Col>
        </Row>
      </FooterContent>
    </Footer>
  )
}

const FooterContent = styled.div`
  max-width: 1110px;
  margin: 0 auto;
  color: #000;
  font-size: 20px;
  padding-right: 20px;
`

const Button = styled.div`
  width: 160px;
`

export default FooterView
