import React from 'react'
import styled from 'styled-components'
import { numberFormat } from 'utils/format'

type Props = {
  isPass: boolean
  score: number
}

export const Score: React.FunctionComponent<Props> = ({ isPass, score }) => {
  const scoreFormat = numberFormat(score)
  const scoreText = isPass ? (
    <PassScore>{scoreFormat}</PassScore>
  ) : (
    <FailScore>{scoreFormat}</FailScore>
  )

  return (
    <HeaderScore>
      {scoreText}
      <ScoreDesc>คะแนน</ScoreDesc>
    </HeaderScore>
  )
}

const ScoreText: any = styled.span`
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 44px;
  line-height: 0;
`

const PassScore = styled(ScoreText)`
  color: #5bc79e;
`

const FailScore = styled(ScoreText)`
  color: #d0494c;
`

const ScoreDesc = styled.span`
  font-family: 'DB Helvethaica X 55 Regular';
  color: #8b99a7;
  font-size: 20px;
  line-height: 0;
  margin-left: 8px;
`

const HeaderScore = styled.div`
  width: 100%;
  text-align: left;
`

export default Score
