import React, { useState, useEffect } from 'react'
import RootState from 'typings/RootState'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { Layout } from 'antd'
import { cloneDeep } from 'lodash'
import { BreadcrumbLink } from 'Components/Shared/BreadCrumb/BreadCrumb'
import { selectCheckListQC } from 'features/report/commissioning/selector'

import Activity from 'Components/QC/Activity'
import { ImageWithCaption } from 'Components/Shared'
import ThumbnailDisplay from 'Components/Shared/ThumbnailDisplay'

import Header from './Header'
import CheckList from './Checklist'
import SaveFooter from './SaveFooter'

const { Content, Footer } = Layout

type FormProps = {
  readOnly?: boolean
  ActivityID: string
  breadcrumbs?: BreadcrumbLink[]
  page: string
  onPage: (val: string) => void
  onUpdate?: (val: any) => void
  onUpdateImageList?: (val: any) => void
  onUploadImage?: (image: any) => void
  onSave?: () => void
  selectedImage?: any[]
  onSaveCommissioningImage?: (selectedImage: any[]) => void
}
type Props = FormProps & ReduxProps

const CommissionDetail = (props: Props) => {
  const noFunc = () => {}
  const {
    checklist,
    ActivityID,
    breadcrumbs = [],
    readOnly = true,
    page = '',
    onUpdate = noFunc,
    onUpdateImageList = noFunc,
    onPage = noFunc,
    onUploadImage = noFunc,
    onSave = noFunc,
    selectedImage = [],
    onSaveCommissioningImage = noFunc,
  } = props

  const [lastPage, setLastPage] = useState('')
  const [currentImage, setCurrentImage] = useState(0)
  const [selectedImg, setSelectedImg] = useState([] as any[])
  const [showFooter, setShowFooter] = useState(true)

  useEffect(() => {
    setSelectedImg(cloneDeep(selectedImage))
  }, [page])

  const { ImageList } = checklist

  const onUpdateImage = (ImageList: any[]) => {
    const updateData = cloneDeep(checklist)

    console.log('updateData', updateData)
    updateData.ImageList = ImageList

    onUpdateImageList(updateData)
  }

  const onPageClose = () => {
    onPage(lastPage)
    if (lastPage !== '') {
      setLastPage('')
    }
  }

  const onThumbnailOpen = (index: number, lastpage: string) => {
    setLastPage(lastpage)
    onPage('thumbnails')
    setCurrentImage(index)
  }

  const onClickSeemoreImage = () => {
    setLastPage('images')
    onPage('images')
  }

  const findRemark = (FileID: number) => {
    const data = ImageList.find(item => item.Image.FileID === FileID)
    return (data && data.Remark) || ''
  }

  const updateThumpnailImage = (images: any[]) => {
    const imgList: any[] = images.map(image => {
      return {
        Image: image,
        Remark: findRemark(image.FileID),
      }
    })
    onUpdateImage(imgList)
  }

  const isSelected = (image: any) => {
    const img = selectedImg.find(item => item.Image.FileID === image.FileID)
    return img ? true : false
  }

  const remove = (image: any) => {
    return selectedImg.filter(el => el.Image.FileID !== image.FileID)
  }

  const onSelectImage = (imageItem: any) => {
    let newValue = selectedImg
    const hasSelect = isSelected(imageItem.Image)
    if (hasSelect) {
      newValue = remove(imageItem.Image)
    } else {
      newValue.push(imageItem)
    }
    setSelectedImg([...newValue])
  }

  const images: any[] = []
  ImageList.forEach(item => {
    if (item.Image) images.push(item.Image)
  })

  switch (page) {
    case 'images':
      return (
        <>
          <Content className="mid-content">
            <div className="main-content activity-detail">
              <ImageWithCaption
                mode="upload"
                onClose={() => {}}
                readOnly={readOnly}
                images={ImageList}
                onThumbnailOpen={index => onThumbnailOpen(index, 'images')}
                onChange={ImageList => onUpdateImage(ImageList)}
                numberOfImage={50}
                onUploadImage={image => onUploadImage(image)}
                onEditMode={isEditMode => setShowFooter(!isEditMode)}
              />
            </div>
          </Content>
          {!readOnly && showFooter && (
            <Footer className="detail-page-footer">
              <SaveFooter onSave={onSave} />
            </Footer>
          )}
        </>
      )
    case 'thumbnails':
      return (
        <ThumbnailDisplay
          onClose={onPageClose}
          readOnly={readOnly}
          images={images}
          index={currentImage}
          onChange={updateThumpnailImage}
        />
      )
    case 'chooseImages':
      return (
        <>
          <Content className="mid-content">
            <div className="main-content activity-detail">
              <ImageWithCaption
                mode="choose"
                onClose={() => {}}
                readOnly={readOnly}
                images={ImageList}
                onThumbnailOpen={index => onThumbnailOpen(index, 'images')}
                onChange={ImageList => onUpdateImage(ImageList)}
                selectedImage={selectedImg}
                onSelectImage={onSelectImage}
              />
            </div>
          </Content>
          {!readOnly && (
            <Footer className="detail-page-footer">
              <SaveFooter
                onSave={() => onSaveCommissioningImage(selectedImg)}
              />
            </Footer>
          )}
        </>
      )
    case 'activity':
      return (
        <Activity
          breadcrumbs={breadcrumbs}
          ProjectCode={checklist.ProjectCode}
          ActivityID={ActivityID}
        />
      )
    default:
      return (
        <Content className="mid-content">
          <div className="main-content activity-detail">
            <MainView>
              <Header
                readOnly={readOnly}
                item={checklist}
                onClickSeemore={onClickSeemoreImage}
                onThumbnailOpen={index => onThumbnailOpen(index, '')}
                onUpdateImage={image => onUploadImage(image[0])}
                // onUpdateImage={images => console.log('commissioning images', images)}
                images={images}
                onPage={() => onPage('activity')}
              />

              <CheckList
                readOnly={readOnly}
                checklist={checklist}
                onUpdate={onUpdate}
              />
            </MainView>
          </div>
        </Content>
      )
  }
}

const MainView = styled.div``

const mapStateToProps = (state: RootState) => {
  return {
    checklist: selectCheckListQC(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(CommissionDetail)
