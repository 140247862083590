import React from 'react'
import { BreadcrumbLink } from 'Components/Shared/BreadCrumb/BreadCrumb'
import SubnavBar from 'Components/Shared/SubnavBar'

type Props = {
  unit: string
  name: string
  preview?: string
  onClose?: (page: string) => void
}

const Breadcrumbs: React.ComponentType<Props> = props => {
  const { unit, name, onClose = () => {}, preview = '' } = props
  const breadcrumbs: BreadcrumbLink[] = [
    {
      url: '/report/unit',
      title: 'Home',
      icon: '/home.png',
      active: true,
      showTitle: false,
    },
    {
      url: '/report/unit',
      title: 'รายงานตรวจมาตรฐาน (ยูนิต/แปลง)',
      active: true,
      showTitle: true,
    },
  ]

  breadcrumbs.push({
    url: '',
    title: unit,
    active: name ? true : false,
    showTitle: name ? true : false,
    onClick: () => onClose(''),
  })

  if (name) {
    breadcrumbs.push({
      url: '',
      title: name,
      active: preview ? true : false,
      showTitle: preview ? true : false,
      onClick: () => onClose('detail'),
    })
  }

  if (preview) {
    breadcrumbs.push({
      url: '',
      title: preview,
      active: false,
      showTitle: false,
    })
  }

  return <SubnavBar breadcrumbs={breadcrumbs} hideSearch={true} />
}

export default Breadcrumbs
