import { createStandardAction } from 'typesafe-actions'

import { APIRequestSpec } from 'services/APIRequestSpec'

/**
 * Create an action creator with contains type `CALL_API` and receives an async action as a parameter.
 *
 * When receive `{ type: "CALL_API" }`, it will dispatch `request action` with parameter that is going to be sent to API as a payload.
 *
 * Then it will call API according to specification given as a param in this function.
 *
 * If it succeeded, it will dispatch `success action` with a response from API as a payload.
 *
 * Otherwise, it will dispatch `failure action` with an error from API as a payload.
 *
 *
 * @see The example at `example.api.ts`, and saga that process this action at `common.saga.ts`.
 * @param request API specification
 * @returns function that receive an async action as a parameter.
 */
export function withAPIAction<RequestBody>(
  request: APIRequestSpec<RequestBody>
) {
  // eslint-disable-next-line func-names
  return function<T1 extends any, T2 extends any, T3 extends any, ResponseType>(
    action: any
  ) {
    const meta = {
      request: action.request,
      success: action.success,
      failure: action.failure,
    }

    return createStandardAction('CALL_API')<
      APIRequestSpec<RequestBody>,
      typeof meta
    >()(request, meta)
  }
}
export default withAPIAction
