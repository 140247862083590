import React, { useEffect } from 'react'
import { Row, Col, Input } from 'antd'
import styled from 'styled-components'
import imgHistory from 'assets/img/ic-history.png'
import { getCMByStatus } from 'utils/statusLookup'
import { TaskInfo } from 'Components/Activity'
import { isUnit } from 'utils/helper'

type Props = {
  originalData: any
  onHistoryOpen?: () => void
  wbs?: any
}

const TaskHeader: React.FunctionComponent<Props> = ({
  originalData,
  onHistoryOpen,
  wbs,
}) => {
  const status = getCMByStatus(originalData.State, originalData.TaskStatus)
  const qcInfo = isUnit(originalData.Zone) ? (
    <TextHeader>
      หมวดงาน QC<span>{originalData.QCTypeName}</span>
    </TextHeader>
  ) : (
    undefined
  )

  const header = (
    <Row type="flex" justify="space-between" className="header">
      <Col span={12}>
        <Row type="flex" justify="space-between">
          <Col>
            <TextHeader>
              ตึก<span>{originalData.TowerName}</span>
            </TextHeader>
          </Col>
          <Col>
            <TextHeader>
              ชั้น<span>{originalData.FloorName}</span>
            </TextHeader>
          </Col>
          <Col>
            <TextHeader>
              ยูนิต<span>{originalData.Unit}</span>
            </TextHeader>
          </Col>
          <Col>{qcInfo}</Col>
        </Row>
      </Col>
      <Col span={8}>
        <RightConner>
          <HistoryBtn onClick={onHistoryOpen}>
            <img src={imgHistory} alt="Go to activity history" />
            ประวัติการทำงาน
          </HistoryBtn>
          <Status style={{ backgroundColor: status.color }}>
            {status.text}
          </Status>
        </RightConner>
      </Col>
    </Row>
  )

  return (
    <MainView>
      {header}
      <TaskInfo originalData={originalData} wbs={wbs} />
    </MainView>
  )
}

const MainView = styled.div``
const TextHeader = styled.div`
  font-size: 28px;
  font-family: 'DB Helvethaica X 75 Bd';
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  span {
    font-family: 'DB Helvethaica X 55 Regular';
    padding-left: 4px;
  }
`

const RightConner = styled.div`
  float: right;
`
const HistoryBtn = styled.span`
  color: #003168;
  font-size: 18px;
  font-family: 'DB Helvethaica X 75 Bd';
  img {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    margin-bottom: 5px;
  }

  :hover {
    cursor: pointer;
  }
`

const Status = styled.span`
  height: 20px;
  border-radius: 10px;
  font-size: 16px;
  color: #fff;
  text-align: center;
  line-height: 1.4;
  padding: 0px 12px;
  margin-left: 15px;

  &.init {
    background-color: #8b99a7;
  }

  &.progress {
    background-color: #003168;
  }
`

export default TaskHeader
