import React, { useEffect, useState } from 'react'
import { Row, Col } from 'antd'
import CheckAsOptions from './CheckAsOptions'
import { SearchInput } from '../Form'
import { cloneDeep } from 'lodash'
import { hasText } from 'utils/getText'

interface DataProps {
  name: string
  value: any
}

interface Props {
  filter: any[]
  onChange(filter: any[]): void
  data: DataProps[]
  title?: string
  hasSearch?: boolean
}

const CommonContent: React.FunctionComponent<Props> = ({
  filter,
  onChange,
  data,
  title = '',
  hasSearch = false,
}) => {
  const [textSearch, setTextsearch] = useState('')
  const [dataFilter, setDataFilter] = useState([] as DataProps[])

  useEffect(() => {
    setDataFilter(cloneDeep(data))
  }, [hasSearch, data])

  useEffect(() => {
    if (!hasSearch) return
    const list = cloneDeep(data)
    let filter = []
    if (textSearch === '') {
      filter = list
    } else {
      filter = list.filter(item => hasText(item.name, textSearch))
    }

    setDataFilter(filter)
  }, [textSearch])

  const onValueChange = (val: string) => {
    const index = filter && filter.findIndex(item => item === val)
    if (index >= 0) {
      filter.splice(index, 1)
    } else {
      filter.push(val)
    }
    onChange(filter)
  }

  const onClear = () => {
    filter = []
    onChange(filter)
  }

  const content = () => {
    return dataFilter.map((item, index) => {
      const findIndex = filter && filter.findIndex(it => it == item.value)
      return (
        <Col span={24} key={index}>
          <CheckAsOptions
            key={index}
            title={item.name}
            value={findIndex >= 0}
            item={item.value}
            onChange={onValueChange}
          />
        </Col>
      )
    })
  }

  const clearBtn =
    filter && filter.length > 0 ? (
      <span onClick={onClear} className="filter-clear std-font-regular">
        ยกเลิกทั้งหมด
      </span>
    ) : (
      undefined
    )
  return (
    <div className="std-font-regular" style={css.page}>
      <Row>
        <Col span={24}>
          <div className="std-font-bold" style={css.title}>
            {title}
            {clearBtn}
            {hasSearch && (
              <SearchInput onSearch={setTextsearch} searchTxt={textSearch} />
            )}
          </div>
        </Col>
      </Row>
      <Row className="pop-over-container-choices">{content()}</Row>
    </div>
  )
}

const css: any = {
  page: {
    width: '296px',
    height: 'auto',
    padding: '25px 24px',
  },
  title: {
    textAlign: 'left',
    color: 'black',
    fontSize: '20px',
    marginBottom: '25px',
  },
  cancel: {
    float: 'right',
    color: '#003168',
    fontSize: '18px',
  },
}

export default CommonContent
