import React from 'react'

type Props = {
  color?: string
}

const MenuIcon = (props: Props) => {
  const { color = 'white' } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <path
        fill={color}
        fillRule="evenodd"
        d="M.97 3.94h13.575a.97.97 0 1 0 0-1.94H.97a.97.97 0 1 0 0 1.94zm13.575 2.908H.97a.97.97 0 1 0 0 1.94h13.575a.97.97 0 1 0 0-1.94zm0 4.849H.97a.97.97 0 1 0 0 1.94h13.575a.97.97 0 1 0 0-1.94z"
      />
    </svg>
  )
}

export default MenuIcon
