import { createStandardAction, createAsyncAction } from 'typesafe-actions'
import {
  GetCheckingCommissReq,
  GetCheckingCommissRes,
  AcceptCheckingTaskReq,
  PostApiResponse,
  RejectCheckingTaskReq,
} from './types'

export const GetCheckingZoneAction = createAsyncAction(
  'qc/commissioning/checking/FETCH_CHECKING_REQUEST',
  'qc/commissioning/checking/FETCH_CHECKING_SUCCESS',
  'qc/commissioning/checking/FETCH_CHECKING_FAILURE'
)<GetCheckingCommissReq, GetCheckingCommissRes, any>()

export const sortingTask = createStandardAction(
  'qc/commissioning/checking/SORTING'
)<{
  name: string
  sort?: string
}>()

export const AcceptTaskAction = createAsyncAction(
  'qc/commissioning/checking/ACCEPT_TASK_REQUEST',
  'qc/commissioning/checking/ACCEPT_TASK_RESPONSE',
  'qc/commissioning/checking/ACCEPT_TASK_FAILURE'
)<AcceptCheckingTaskReq, PostApiResponse, any>()

export const RejectTasksAction = createAsyncAction(
  'qc/commissioning/checking/REJECT_TASK_REQUEST',
  'qc/commissioning/checking/REJECT_TASK_RESPONSE',
  'qc/commissioning/checking/REJECT_TASK_FAILURE'
)<RejectCheckingTaskReq, PostApiResponse, any>()
