import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'antd'
import { Link } from 'react-router-dom'
import {
  TypeOfTask,
  Todo,
  InProgress,
  QCChecking,
  FixFromQC,
} from 'features/cmpj/zone/dashboard/types'
import { CheckStatusIcon } from 'Components/Shared'
import { toDateText, toTimeText, toMomentDateTime } from 'utils/datetime'

interface Props {
  type?: string
  task?: any
  onClick?: (code: string) => void
}

const Card: React.FC<Props> = ({ type = '', task, onClick }) => {
  const getLaneColor = (status: string) => {
    let lane
    switch (status) {
      case 'success':
        lane = <span className="lane-card-success" />
        break
      case 'fail':
        lane = <span className="lane-card-delayed" />
        break
      default:
        break
    }
    return lane
  }

  const renderTodoStatus = (isComplete: boolean) => {
    return isComplete ? (
      <TextStatus>พร้อมตรวจด้วยตัวเอง</TextStatus>
    ) : (
      <TextStatus>กำลังดำเนินการ</TextStatus>
    )
  }

  const renderQCInProgressStatus = (isReady: boolean) => {
    const appointmentDate = toMomentDateTime(task.AppointmentDate)
    const date = toDateText(appointmentDate)
    const time = toTimeText(appointmentDate)
    return isReady ? (
      <TextStatus>
        วันที่นัดหมาย {date} เวลา {time}
      </TextStatus>
    ) : (
      <span>รอ QC รับคำขอ</span>
    )
  }

  const getNumberOfTask = (pass = 0, fail = 0) => {
    return (
      <Row>
        <Col span={12}>
          <PassScore>{pass} ข้อ</PassScore>
          <PassStatus>ผ่าน</PassStatus>
        </Col>
        <Col span={12}>
          <FailScore>{fail} ข้อ</FailScore>
          <FailStatus>ไม่ผ่าน</FailStatus>
        </Col>
      </Row>
    )
  }

  const getCountTask = (current: number, total: number) => {
    if (current === total) {
      return `จำนวนงาน ${current} /${total}`
    } else {
      return (
        <div>
          จำนวนงาน <TextBlue>{current}</TextBlue> /{total}
        </div>
      )
    }
  }

  const getFloor = (floor: string) => {
    return `ชั้น ${floor}`
  }

  const isComplete = () => {
    return task.PassedSubTasks === task.TotalSubTasks
  }

  const todoCard = (task: Todo) => {
    const complete = isComplete()
    return (
      <>
        {getLaneColor(complete ? 'success' : '')}
        <Row type="flex">
          <Col span={24}>
            <Title>{task.Unit}</Title>
            <Detail>{getFloor(task.Floor)}</Detail>
            <Detail>
              {getCountTask(task.PassedSubTasks, task.TotalSubTasks)}
            </Detail>
            <Detail>สถานะ: {renderTodoStatus(complete)}</Detail>
          </Col>
        </Row>
      </>
    )
  }

  const cmInProgressCard = (task: InProgress) => {
    return (
      <Row type="flex">
        <Col span={24}>
          <Title>{task.Unit}</Title>
          <Detail>ชั้น {task.Floor}</Detail>
          <Detail>ตรวจครั้งที่ {task.CheckNumber}</Detail>
          <Line />
          {getNumberOfTask(task.NumOfPass, task.NumOfFail)}
        </Col>
      </Row>
    )
  }

  const qcInProgressCard = (task: QCChecking) => {
    const complete = task.Status === 'INPRO'
    return (
      <>
        {getLaneColor(complete ? 'success' : '')}
        <Row type="flex">
          <Col span={24}>
            <Title>{task.Unit}</Title>
            <Detail>{getFloor(task.Floor)}</Detail>
            <Detail>ตรวจครั้งที่ {task.CheckNumber}</Detail>
            <Detail>วันที่ส่งคำขอ: {task.RequestDate}</Detail>
            <Detail>สถานะ: {renderQCInProgressStatus(complete)}</Detail>
          </Col>
        </Row>
      </>
    )
  }

  const fixFromQCCard = (task: FixFromQC) => {
    const selfCheck = task.LastCheckedModule === 'CM'
    let isFail = task.NumOfFail > 0
    return (
      <>
        {getLaneColor(isFail ? 'fail' : 'success')}
        <Row type="flex" justify="space-between" align="bottom">
          <Col>
            <Title>{task.Unit}</Title>
            <Detail>{getFloor(task.Floor)}</Detail>
            <Detail>ตรวจครั้งที่ {task.CheckNumber}</Detail>
          </Col>
          <Col span={8}>
            <CheckStatusIcon isSelfCheck={selfCheck} />
          </Col>
        </Row>
        <Line />
        {getNumberOfTask(task.NumOfPass, task.NumOfFail)}
      </>
    )
  }

  const getCardDetail = (type: string) => {
    switch (type) {
      case TypeOfTask.TODO:
        return todoCard(task)
        break
      case TypeOfTask.InProgress:
        return cmInProgressCard(task)
        break
      case TypeOfTask.QCChecking:
        return qcInProgressCard(task)
        break
      case TypeOfTask.FixFromQC:
        return fixFromQCCard(task)
        break
      default:
        break
    }
  }

  const getLink = () => {
    const complete = task.Status === 'INPRO' || task.CheckNumber > 1
    const params = `${task.Unit}/${task.QCTypeID}/${task.RequestID}/${
      task.Floor
    }`
    switch (type) {
      case TypeOfTask.TODO:
        return `/zone/request/${params}`
      case TypeOfTask.InProgress:
        return `/zone/report/${params}`
      case TypeOfTask.QCChecking:
        return `/${complete ? 'zone/report' : 'zone/request'}/${params}`
      case TypeOfTask.FixFromQC:
        return `/zone/report/${params}`
      default:
        return ''
    }
  }

  return (
    <Link to={getLink()}>
      <MainCard>{getCardDetail(type)}</MainCard>
    </Link>
  )
}

const MainCard = styled.div`
  background: #fff;
  border-radius: 10px;
  padding: 16px;
  background-clip: padding-box;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.03);
  margin-bottom: 12px;
  cursor: pointer;
  color: #000;
  width: 100%;
  position: relative;
`

const Title = styled.p`
  font-size: 20px;
  margin-bottom: 4px;
  font-family: 'DB Helvethaica X 75 Bd';
`

const Detail = styled.p`
  font-size: 18px;
  margin-bottom: 4px;
  font-family: 'DB Helvethaica X 55 Regular';
`

const TextStatus = styled.span`
  color: #63cea6;
`

const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e8ebf0;
  margin: 5px 0 20px 0;
`

const PassScore = styled.div`
  font-size: 24px;
  font-family: 'DB Helvethaica X 75 Bd';
  color: #5bc79e;
  line-height: 0.5;
`

const FailScore = styled.div`
  font-size: 24px;
  font-family: 'DB Helvethaica X 75 Bd';
  color: #d0494c;
  line-height: 0.5;
`

const PassStatus = styled.div`
  font-size: 16px;
  font-family: 'DB Helvethaica X 55 Regular';
  color: #5bc79e;
`

const FailStatus = styled.div`
  font-size: 16px;
  font-family: 'DB Helvethaica X 55 Regular';
  color: #d0494c;
`
const TextBlue = styled.span`
  color: #003168;
`

export default Card
