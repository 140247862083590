import React from 'react'
import styled from 'styled-components'
import { ContentRow } from 'Components/Shared/Table'
import { CheckedIcon, UncheckedIcon } from 'Components/Shared/Icon'
import { CheckingList } from 'features/cmpj/road/construct/checking/types'
import { ProgressLine } from 'Components/Shared'

type DefaultProps = {
  task: CheckingList
  isSelected?: boolean
  onClick?: () => void
}
type Props = DefaultProps

const RowItem: React.FC<Props> = props => {
  const { task, isSelected = false, onClick = () => {} } = props

  const isShowRadio =
    task.State === 'PJPO' && task.TaskStatus === 'APPV' && task.Progress === 100

  const getRadioBox = () => {
    return (
      <Radio
        onClick={evt => {
          evt.preventDefault()
          onClick()
        }}
      >
        {isSelected ? <CheckedIcon size={24} /> : <UncheckedIcon size={24} />}
      </Radio>
    )
  }

  const todoData = [
    {
      title: task.Unit,
      span: 7,
    },
    {
      title: task.QCTypeName,
      span: 5,
    },
    {
      title: task.CheckNumber,
      span: 5,
    },
    {
      title: <ProgressLine task={task} />,
      span: 5,
    },
    {
      title: getRadioBox(),
      span: 2,
    },
  ]

  return <ContentRow items={todoData} />
}

const Radio = styled.div`
  margin-left: 4px;
`

export default RowItem
