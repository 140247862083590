import { createStandardAction, createAsyncAction } from 'typesafe-actions'
import { GetApiResponse, PaginationType } from '../../types/common'
import {
  CommissionList,
  CommissionDetail,
  Activity,
  SelectActivityByCode,
} from './types'
import { History } from 'history'
import { InspectionList } from 'features/types/checklist'

export const reset = createStandardAction('admin/floorPlan/RESET_ACTION')<
  void
>()

export const getCommissionListReq = createAsyncAction(
  'admin/commission/GET_COMMISSION_LIST_REQUEST',
  'admin/commission/GET_COMMISSION_LIST_SUCCESS',
  'admin/commission/GET_COMMISSION_LIST_FAILURE'
)<PaginationType, GetApiResponse<CommissionList>, Error>()

export const getCommissionDetailReq = createAsyncAction(
  'admin/commission/GET_COMMISSION_DETAIL_REQUEST',
  'admin/commission/GET_COMMISSION_DETAIL_SUCCESS',
  'admin/commission/GET_COMMISSION_DETAIL_FAILURE'
)<
  {
    CheckListID: number
    CommissioningType: number
  },
  { res: GetApiResponse<CommissionDetail>; CommissioningType: number },
  Error
>()

export const creatUpdateCommissionDetailReq = createAsyncAction(
  'admin/commission/UPDATE_COMMISSION_DETAIL_REQUEST',
  'admin/commission/UPDATE_COMMISSION_DETAIL_SUCCESS',
  'admin/commission/UPDATE_COMMISSION_DETAIL_FAILURE'
)<
  {
    isSafety: boolean
    commissionDetail: CommissionDetail
    activityByCode: SelectActivityByCode
    q1?: InspectionList[]
    commission?: InspectionList[]
    safety?: InspectionList[]
    history: any
  },
  void,
  Error
>()

export const getActivityByCommissioningReq = createAsyncAction(
  'admin/commission/GET_ACTIVITY_REQUEST',
  'admin/commission/GET_ACTIVITY_SUCCESS',
  'admin/commission/GET_ACTIVITY_FAILURE'
)<
  {
    ProjectCode: string
    CheckListType: number
    UserEmail: string
  },
  GetApiResponse<{
    ProjectCode: string
    ActivityList: Activity[]
  }>,
  Error
>()

export const clearCommissionDetail = createStandardAction(
  'admin/commission/CLEAR_COMMISSION'
)<void>()

export const deleteReq = createAsyncAction(
  'admin/commission/DELETE_REQ',
  'admin/commission/DELETE_RESP',
  'admin/commission/DELETE_FAIL'
)<{ CheckListID: number; history: any }, void, Error>()

export const clearEvent = createStandardAction('admin/commission/CLEAR_EVENT')<
  void
>()

export const updateCheckListDetail = createStandardAction(
  'checklist/commission/UPDATE_CHECKLIST_DETAIL'
)<any>()
