import { ModuleItem } from 'features/admin/user/types'

export const modulesConfig = [
  {
    name: 'Master Plan',
    value: 1,
  },
  {
    name: 'Main Con',
    value: 2,
  },
  {
    name: 'CM/PJ',
    value: 3,
  },
  {
    name: 'QC',
    value: 4,
  },
  {
    name: 'Approval (Detail Plan)',
    value: 5,
  },
  {
    name: 'PMR',
    value: 6,
  },
  {
    name: 'Admin PJ',
    value: 7,
  },
  {
    name: 'Admin QC',
    value: 8,
  },
  {
    name: 'Administrator',
    value: 9,
  },
  {
    name: 'Report',
    value: 10,
  },
  {
    name: 'Approval (Master Plan)',
    value: 11,
  },
]

export const getModuleName = (moduleID: number) => {
  const find = modulesConfig.find(item => item.value === moduleID)
  return (find && find.name) || ''
}

export const getModuleNames = (module: ModuleItem[]) => {
  let name = module.map(item => {
    return getModuleName(item.ModuleID)
  })
  return name.join(',')
}

export default getModuleNames
