import RootState from 'typings/RootState'
import { createSelector } from 'reselect'
import {
  Task,
  GetActivityApiResponse,
  GetActivityApiResponseData,
  DashboardFilter,
  DashboardQuery,
} from './tasklane.types'

export const tasklaneSelector = (state: RootState) => state.tasklane
export const isFetchingTasklaneSelector = createSelector(
  tasklaneSelector,
  tasklane => tasklane.isFetchingTasklanes
)

export const tasklaneSelectorCreator = (
  type: 'ToDo' | 'InProgress' | 'Complete'
) => {
  const itemSelector = createSelector(tasklaneSelector, tasklane => {
    const responseData: GetActivityApiResponse<GetActivityApiResponseData> =
      tasklane.response
    const data = responseData.ResponseData

    // There can be no tasks for project, show empty records
    if (!data) {
      return []
    }

    return data && data[type]
  })
  return createSelector(itemSelector, items => {
    if (items && items.length) {
      return items.reduce((arr: string[], item: Task) => {
        arr.push(item.ActivityID)

        return arr
      }, [])
    }

    return []
  })
}

export const todoSelector = tasklaneSelectorCreator('ToDo')
export const inProgressSelector = tasklaneSelectorCreator('InProgress')
export const completeSelector = tasklaneSelectorCreator('Complete')

export const getOriginalSelector = (state: RootState) =>
  state.tasklane.response.ResponseData
export const rawTodoSelector = (state: RootState) => state.tasklane.todo
export const rawInProgressSelector = (state: RootState) =>
  state.tasklane.inProgress
export const rawCompleteSelector = (state: RootState) =>
  state.tasklane.completed

export const sortingSelector = (state: RootState) => state.tasklane.sorting
export const getDashboardFilterSelector = (state: RootState): DashboardFilter =>
  state.tasklane.filter

export const getDashboardQuerySelector = (state: RootState): DashboardQuery =>
  state.tasklane.query
/**
 * Note: this is work around always get total from 1st item
 * for each todo, inProgress and completed list item.
 */
export const rawTotalTodoSelector = (state: RootState) =>
  // state.tasklane?.todo[0]?.TotalRows || 0
  state.tasklane?.todo?.length || 0

export const rawTotalInProgressSelector = (state: RootState) =>
  // state.tasklane?.inProgress[0]?.TotalRows || 0
  state.tasklane?.inProgress?.length || 0

export const rawTotalCompletedSelector = (state: RootState) =>
  // state.tasklane?.completed[0]?.TotalRows || 0
  state.tasklane?.completed?.length || 0
