import { Col, Row } from 'antd'
import React from 'react'
import styled from 'styled-components'

interface Props {
  columnsName: any[]
}

const ReportTableHeader: React.FC<Props> = ({ columnsName }) => {
  return (
    <Header>
      <Row>
        {columnsName.map((col: any, i: number) => {
          return (
            <Col key={i} span={col.span}>
              {col.title}
            </Col>
          )
        })}
      </Row>
    </Header>
  )
}

const Header = styled.div`
  font-size: 18px;
  color: #8b99a7;
  font-family: 'DB Helvethaica X 55 Regular';
  padding: 10px 24px;

  @media print {
    padding: 5px 10px;
  }
`

export default ReportTableHeader
