import React, { useState } from 'react'
import { TableHeader as Table, TableColHeader } from '../Table'

type Props = {
  onSort(name: string): void
  sorting: any
  headerItem: any[]
}

const TableHeader: React.FunctionComponent<Props> = props => {
  const { onSort, sorting, headerItem } = props

  const getType = (colName: string) => {
    if (sorting.name === colName) {
      return sorting.type
    }
    return ''
  }

  const renderColumn = (column: any[]) => {
    return column.map((item: any, index: number) => {
      return (
        <TableColHeader
          key={index}
          offset={item.offset}
          span={item.span}
          onSort={() => onSort(item.key)}
          type={getType(item.key)}
          sort={item.showSort}
        >
          {item.title}
        </TableColHeader>
      )
    })
  }

  return <Table paddingLeft={24}>{renderColumn(headerItem)}</Table>
}

export default TableHeader
