import React from 'react'
import styled from 'styled-components'
import { ZoneReport } from 'features/report/zone/types'
import { ReportTaskInfo } from 'Components/Shared/ReportInfo'
import { getDateText } from 'utils/getText'

type Props = {
  report: ZoneReport
}

const ReportTitle: React.ComponentType<Props> = props => {
  const { report } = props

  const renderInfo = (title: string, value: any) => {
    return <ReportTaskInfo title={title} value={value} />
  }

  return (
    <ScoreArea>
      <TaskInfo>
        {renderInfo('ตรวจครั้งที่', report.CheckNumber)}
        {renderInfo('โซน', report.Unit)}
        {renderInfo('ชั้น', report.Floor)}
        {renderInfo('วันที่ตรวจ', getDateText(report.InspectDate))}
        {renderInfo('วันที่ส่งรายงาน', getDateText(report.CheckedDate))}
      </TaskInfo>
    </ScoreArea>
  )
}

const ScoreArea = styled.div`
  padding: 140px 40px 0 86px;
`

const TaskInfo = styled.div`
  padding-top: 25px;
  font-family: 'DB Helvethaica X 55 Regular';
  font-size: 20px;
  color: #000;
`

export default ReportTitle
