import React from 'react'
import { Row, Col } from 'antd'
import { translate } from 'i18n'
import TKeys from 'i18n/translationKey'
import {
  ActivityFilter,
  ProgressFilter,
} from 'features/activity/activity.types'
import CheckAsOptions from './CheckAsOptions'

interface Props {
  filter: ActivityFilter
  onChange(filter: ActivityFilter): void
}

const ValueList: ProgressFilter[] = [
  { start: 0, end: 24, text: '0-24%' },
  { start: 25, end: 49, text: '25-49%' },
  { start: 50, end: 74, text: '50-74%' },
  { start: 75, end: 99, text: '75-99%' },
  { start: 100, end: 100, text: '100%' },
]

const ProgressContent: React.FunctionComponent<Props> = ({
  filter,
  onChange,
}) => {
  const onValueChange = (val: ProgressFilter) => {
    const index = filter.progress.findIndex(
      item => item.start === val.start && item.end === val.end
    )
    if (index >= 0) {
      filter.progress.splice(index, 1)
    } else {
      filter.progress.push(val)
    }
    onChange(filter)
  }

  const onClear = () => {
    filter.progress = []
    onChange(filter)
  }

  const content = ValueList.map((item, index) => {
    const findIndex = filter.progress.findIndex(
      it => it.start === item.start && it.end === item.end
    )

    return (
      <Col span={24} key={index}>
        <CheckAsOptions
          key={index}
          title={item.text}
          value={findIndex >= 0}
          item={item}
          onChange={onValueChange}
        />
      </Col>
    )
  })

  const clearBtn =
    filter.progress.length > 0 ? (
      <span onClick={onClear} className="filter-clear std-font-regular">
        {translate(TKeys.Common.cancel_all)}
      </span>
    ) : (
      undefined
    )
  return (
    <div className="std-font-regular" style={css.page}>
      <Row>
        <Col span={24}>
          <div className="std-font-bold" style={css.title}>
            {translate(TKeys.Dashboard.progress)}
            {clearBtn}
          </div>
        </Col>
      </Row>
      <Row className="pop-over-container-choices">{content}</Row>
    </div>
  )
}

const css: any = {
  page: {
    width: '296px',
    height: 'auto',
    padding: '25px 24px',
  },
  title: {
    textAlign: 'left',
    color: 'black',
    fontSize: '20px',
    marginBottom: '25px',
  },
  cancel: {
    float: 'right',
    color: '#003168',
    fontSize: '18px',
  },
}

export default ProgressContent
