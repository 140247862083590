import { createStandardAction, createAsyncAction } from 'typesafe-actions'
import {
  GetCheckingZoneReq,
  GetCheckingZoneRes,
  AcceptCheckingTaskReq,
  RejectCheckingTaskReq,
  PostApiResponse,
} from './types'

export const GetCheckingZoneAction = createAsyncAction(
  'qc/zone/checking/FETCH_CHECKING_REQUEST',
  'qc/zone/checking/FETCH_CHECKING_SUCCESS',
  'qc/zone/checking/FETCH_CHECKING_FAILURE'
)<GetCheckingZoneReq, GetCheckingZoneRes, any>()

export const sortingTask = createStandardAction('qc/zone/checking/SORTING')<{
  name: string
  sort?: string
}>()

export const AcceptTaskAction = createAsyncAction(
  'qc/zone/checking/ACCEPT_TASK_REQUEST',
  'qc/zone/checking/ACCEPT_TASK_RESPONSE',
  'qc/zone/checking/ACCEPT_TASK_FAILURE'
)<AcceptCheckingTaskReq, PostApiResponse, any>()

export const RejectTaskAction = createAsyncAction(
  'qc/zone/checking/REJECT_TASK_REQUEST',
  'qc/zone/checking/REJECT_TASK_RESPONSE',
  'qc/zone/checking/REJECT_TASK_FAILURE'
)<RejectCheckingTaskReq, PostApiResponse, any>()
