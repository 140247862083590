import withAPIAction from '../../utils/withAPIAction'
import { uploadFileAsyncReq } from './actions'
export const uploadFileReq = (data: any, token: string) => {
  if (token === '') {
    return { type: 'activity/no_token' }
  }
  const config = { headers: { 'Content-Type': 'multipart/form-data' } }
  return withAPIAction<any>({
    method: 'POST',
    path: 'UploadFiles',
    data,
    config,
    token: `Bearer ${token}`,
  })(uploadFileAsyncReq)
}
