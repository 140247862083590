import { put, take, takeEvery, select } from 'redux-saga/effects'
import { APIPath } from 'services/APIPath'
import { getType } from 'typesafe-actions'
import * as actions from './actions'
import { createDefect, updateDefect } from './api'
import { DefectTypeReq } from './types'
import { uploadFile } from '../../uploadfile/func'
import { getUserProfile } from 'features/sensitive/sensitive.selector'

function* uploadFileList(
  userEmail: string,
  token: string,
  pin: any,
  username: string
) {
  const { ImageBefore, ImageAfter } = pin

  if (ImageBefore && ImageBefore.file) {
    pin.ImageBefore = yield uploadFile(userEmail, token, username, ImageBefore)
  }

  if (ImageAfter && ImageAfter.file) {
    pin.ImageAfter = yield uploadFile(userEmail, token, username, ImageAfter)
  }

  return pin
}

function* sendDefectReq(action: any) {
  const {
    ProjectCode,
    UserEmail,
    Token,
    RequestID,
    Report,
    Defect,
  } = action.payload
  try {
    const { CheckNumber } = Report
    const pin: DefectTypeReq = {
      ProjectCode,
      UserEmail,

      Unit: Report.Unit,
      QCTypeID: Report.QCTypeID,

      RequestID,
      CheckNumber: Defect.CheckNumber === 0 ? CheckNumber : Defect.CheckNumber,

      PinID: Defect.PinID,
      PinNumber: Defect.PinNumber,

      DefectTypeID: Defect.DefectTypeID,

      WorkTypeID: Defect.WorkTypeID,
      JobTypeID: Defect.JobTypeID,
      SubJobTypeID: Defect.SubJobTypeID,
      DetailID: Defect.DetailID,

      Score: Defect.Score,
      IsNCRScore: Defect.IsNCRScore,

      PinStatus: Defect.PinStatus,
      Remark: Defect.Remark,

      ImageBefore: Defect.ImageBefore,
      ImageAfter: Defect.ImageAfter,
    }

    const profile = yield select(getUserProfile)
    const { FirstName = '', LastName = '' } = profile
    const username = `${FirstName} ${LastName} (${Report.State})`

    const newPin = yield uploadFileList(UserEmail, Token, pin, username)
    if (Defect.PinID === 0) {
      newPin.PinNumber = Report.DefectList.length + 1

      yield put(createDefect(Token, newPin))
    } else {
      yield put(updateDefect(Token, newPin))
    }
  } catch (e) {
    console.log('e', e.message)
  }
}

function* allSaga() {
  yield takeEvery(getType(actions.submitDefectReq), sendDefectReq)
}

export default allSaga
