import React, { useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import RootState from 'typings/RootState'
import { Layout, Row, Col } from 'antd'
import styled from 'styled-components'
import { saveAs } from 'file-saver'
import { translate } from 'i18n'
import TKeys from 'i18n/translationKey'

import {
  getOriginalActivity,
  getCurrentActivity,
} from 'features/activity/activity.selector'
import { updateOriginalActivity } from 'features/activity/activity.actions'

import file from 'utils/file'
import { LIMIT_FILE } from 'config'

import Picker from '../../../Shared/Form/Picker'
import Breadcrumbs from '../Breadcrumbs'
import { TrashBtn, DownloadDisableBtn } from '../../../Shared/Button'
import Editor from '../Editor'

import imgCheck from 'assets/img/ic-check.png'

type FormProps = {
  readOnly: boolean
  images?: any[]
  onClose: () => void
  onThumbnailOpen: (index: number, list?: any[], readOnly?: boolean) => void
}

type Props = ReduxProps & DispatchProps & FormProps

const ImageDisplay: React.ComponentType<Props> = props => {
  const [showEditor, setShowEditor] = useState(false)
  const [fileEditor, setFileEditor] = useState({
    base64: '',
    file: { name: '' },
    lastModified: 0,
  })
  const [loading, setLoading] = useState(false)
  const [showError, setShowError] = useState(false)
  const [error, setError] = useState('')

  const [edit, setEdit] = useState(false)
  const [checkList, setCheckList] = useState([] as number[])

  const dispatch = useDispatch()
  const { original, images, readOnly } = props

  const imageList: any[] = readOnly ? images || [] : original.Images
  const addFile = async (base64: any, fileItem: any) => {
    try {
      const lastModified = fileItem.lastModified
      let resp
      if (fileItem.type === 'image/heic') {
        setLoading(true)
        resp = await file.heicToJpeg({
          file: fileItem,
          filename: fileItem.name,
        })
        base64 = resp.text
        fileItem = resp.file
        setLoading(false)
      }

      if (fileItem.size < LIMIT_FILE.image) {
        resp = await file.compress({
          value: base64,
          file: fileItem,
          limit: LIMIT_FILE.image,
        })
        base64 = resp.text
        fileItem = resp.file
      }

      setShowEditor(true)
      setFileEditor({ base64, file: fileItem, lastModified })
    } catch (e) {
      setError(translate(TKeys.Error.file_wrong))
      setShowError(true)
    }

    setLoading(false)
  }

  const onAddImage = async (base64: string) => {
    try {
      const params = {
        data: base64,
        filename: fileEditor.file.name,
        lastModified: fileEditor.lastModified,
      }
      const fileItem: any = await file.base64ToFile(params)

      original.Images.unshift({
        text: base64,
        file: fileItem,
        lastModified: fileItem.lastModified,
      })
      dispatch(updateOriginalActivity({ ...original }))

      const newList: number[] = []
      checkList.forEach(item => newList.push(item + 1))
      setCheckList(newList)
    } catch (e) {
      setError(e.message)
      setShowError(true)
    }
    setShowEditor(false)
  }

  const addIndex = (index: number) => {
    checkList.push(index)
    setCheckList([...checkList])
  }

  const removeIndex = (index: number) => {
    let i = checkList.indexOf(index)
    if (i >= 0) {
      checkList.splice(i, 1)
      setCheckList([...checkList])
    }
  }

  const onDownload = () => {
    if (readOnly) {
      checkList.forEach(async index => {
        let item = imageList[index]

        let img = await file.urlToBase64({ url: item.FileURL })
        await saveAs(img, item.FileName)
      })
    } else {
      checkList.forEach(index => {
        let item = imageList[index]
        saveAs(item.file, item.file.name)
      })
    }
  }

  const onRemove = () => {
    if (checkList.length > 0) {
      let newList = []
      let index = 0
      for (let item of original.Images) {
        if (checkList.indexOf(index) < 0) {
          newList.push(item)
        }
        index++
      }

      original.Images = newList
      dispatch(updateOriginalActivity({ ...original }))
      setCheckList([])
    }
  }

  const textPicture = translate(TKeys.Common.picture)
  const textCancel = translate(TKeys.Common.cancel)

  let title
  if (edit) {
    let removeBtn = readOnly ? undefined : <TrashBtn onClick={onRemove} />
    title = (
      <Col span={24}>
        <EditTitle className="std-font-regular">
          <span onClick={() => setEdit(false)}>{textCancel}</span>
          <label>
            <DownloadDisableBtn onClick={onDownload} />
            {removeBtn}
          </label>
        </EditTitle>
      </Col>
    )
  } else {
    title = (
      <Col span={24}>
        <Title className="std-font-bold">
          <label>{textPicture}</label>
          <span className="std-font-regular" onClick={() => setEdit(true)}>
            {translate(TKeys.Common.pick_picture)}
          </span>
        </Title>
      </Col>
    )
  }

  const content = imageList.map((item, index) => {
    let btn
    let imgDiv
    let img = readOnly ? item.FileURL : item.text
    if (edit) {
      const hasChecked = checkList.includes(index)
      let click
      if (hasChecked) {
        click = () => removeIndex(index)
        btn = <Checked src={imgCheck} alt="" onClick={click} />
      } else {
        click = () => addIndex(index)
        btn = <Unchecked onClick={click} />
      }

      imgDiv = <LinkImage src={img} alt="" onClick={click} />
    } else {
      imgDiv = (
        <Image
          src={img}
          alt=""
          onClick={() => props.onThumbnailOpen(index, imageList, readOnly)}
        />
      )
    }

    return (
      <Col span={6} style={index === 3 ? css.firstCol : css.col} key={index}>
        {imgDiv}
        {btn}
      </Col>
    )
  })

  return (
    <>
      <Editor
        visible={showEditor}
        image={fileEditor.base64}
        onClose={() => setShowEditor(false)}
        onOk={onAddImage}
      />
      <Breadcrumbs
        progress={original.Progress}
        activity={props.activity}
        name={textPicture}
        onClose={props.onClose}
      />
      <Layout.Content className="mid-content">
        <div className="main-content activity-detail">
          <Row style={{ marginBottom: '24px' }}>{title}</Row>

          <Row>
            <Col span={6} style={css.firstCol}>
              <Picker
                disabled={original.Images.length === 5 || readOnly}
                loading={loading}
                height="260"
                width="260"
                text={textPicture}
                accept=".png,.jpg,.heic"
                onChange={addFile}
              />
            </Col>
            {content}
          </Row>
        </div>
      </Layout.Content>
    </>
  )
}

const css: any = {
  firstCol: {
    width: '260px',
    marginBottom: '22px',
  },
  col: {
    textAlign: 'right',
    width: '260px',
    marginLeft: '23px',
    marginBottom: '22px',
  },
}

const Title = styled.div`
  height: 30px;
  label {
    font-size: 28px;
    color: black;
    position: absolute;
    bottom: 0;
  }

  span {
    font-size: 18px;
    color: #003168;
    position: absolute;
    bottom: 0;
    right: 0;
  }

  span:hover {
    cursor: pointer;
  }
`

const EditTitle = styled.div`
  font-size: 18px;
  color: #003168;
  height: 30px;

  span:hover {
    cursor: pointer;
  }

  label {
    float: right;

    img:last-child {
      margin-left: 24px;
    }
  }
`

const CssImg = `
  width: 260px;
  height: 260px;
  object-fit: cover;
  border-radius: 5px;
`
const Image = styled.img`
  ${CssImg}
`

const LinkImage = styled.img`
  ${CssImg}
  :hover {
    cursor: pointer;
  }
`

const Checked = styled.img`
  width: 24px;
  height: 24px;
  object-fit: contain;
  position: absolute;
  z-index: 2;
  right: 8px;
  bottom: 8px;
  :hover {
    cursor: pointer;
  }
`

const Unchecked = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid white;
  position: absolute;
  z-index: 2;
  right: 8px;
  bottom: 8px;
  background-color: rgba(0, 0, 0, 0.3);
  :hover {
    cursor: pointer;
  }
`

const mapStateToProps = (state: RootState) => {
  return {
    activity: getCurrentActivity(state),
    original: getOriginalActivity(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {
  updateOriginalActivity,
}

type DispatchProps = typeof mapDispatchToProps

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ImageDisplay)
