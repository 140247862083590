import React from 'react'

type DefaultProps = {
  id?: string
  width?: number
  height?: number
  color?: string
}

const PinIcon = (props: DefaultProps) => {
  const { id, width = 50, height = 60, color = '#fff' } = props
  return (
    <svg
      id={id}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 50 60"
    >
      <path
        fill={color}
        fill-rule="evenodd"
        d="M50 23.303c0 18.659-22.599 35.94-22.599 35.94-1.32 1.01-3.482 1.01-4.802 0 0 0-22.599-17.281-22.599-35.94C0 10.433 11.193 0 25 0s25 10.433 25 23.303z"
      />
    </svg>
  )
}

export default PinIcon
