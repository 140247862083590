import React, { useRef, useState } from 'react'
import { Col, Layout, Row } from 'antd'
import styled from 'styled-components'
import { TaskChecklist } from 'Components/Activity'
import {
  ReportHeader,
  ReportLogo,
  DescItem,
  ImageReport,
  StatusTag,
  Signature,
} from 'Components/AdminQC/Report/Components'
import { DataDetail } from 'features/adminReport/commissioning/types'
import { toDateText, toMomentDateTime } from 'utils/datetime'

const { Content } = Layout

type Props = {
  data: DataDetail
}

const ReportDetail: React.ComponentType<Props> = props => {
  const componentPrint = useRef(null)
  const { data } = props
  const renderDescItem = (title: string, value: any) => {
    return <DescItem title={title} value={value || '-'} percentW={20} />
  }

  const isPass = data.CheckStatus === 'Pass'
  const status = <StatusTag text={isPass ? 'ผ่าน' : 'ไมผ่าน'} isPass={isPass} />

  const DescContent = (
    <DesBox>
      <Row type="flex">
        {renderDescItem('โครงการ', data.ProjectNameTH)}
        {renderDescItem('ชื่อระบบ', data.ActivityName)}
        {renderDescItem('หมวดงาน', data.CommissioningType)}
        {renderDescItem('ผู้ตรวจสอบ', data.CheckedBy)}
        {renderDescItem('ผลการตรวจ', status)}
        {renderDescItem(
          'วันที่ตรวจ',
          toDateText(toMomentDateTime(data.InspectDate))
        )}
        {renderDescItem(
          'วันที่ส่งรายงาน',
          toDateText(toMomentDateTime(data.CheckedDate))
        )}
        {renderDescItem('ผู้ควบคุมงาน', data.ControllerUser)}
        {renderDescItem('ผู้จัดการโครงการ', data.ProjectManager)}
        {renderDescItem('ผู้รับเหมาหลัก', data.MainconUser)}
      </Row>
    </DesBox>
  )

  const renderReportImage = () => {
    return data.SelectedImageList.map(item => {
      return (
        <Col span={8}>
          <ImageReport
            image={item.Image}
            remark={item.Remark}
            showRemark={true}
            canShowThump={true}
            title="หมายเหตุใต้ภาพ - "
          />
        </Col>
      )
    })
  }

  return (
    <Content className="mid-content">
      <div className="main-content activity-detail">
        <ReportHeader
          title="รายงานตรวจส่วนกลาง - Commissioning"
          componentPrint={componentPrint}
        />
        <Box ref={componentPrint}>
          <ReportLogo title="รายงานตรวจส่วนกลาง - Commissioning" />
          {DescContent}
          <CheckListBox>
            <TitleSection>รายการตรวจสอบ</TitleSection>
            <TaskChecklist inspectionList={data.List} />
          </CheckListBox>
          <ImageBox>
            <TitleSection style={{ paddingLeft: '12px' }}>
              รูปรายละเอียดและการแก้ไข
            </TitleSection>
            <Row type="flex" justify="start">
              {renderReportImage()}
            </Row>
          </ImageBox>
          <Signature data={data.Signature} />
        </Box>
      </div>
    </Content>
  )
}

const Title: any = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 28px;
  color: #000;
`

const TitleSection = styled(Title)`
  font-size: 24px;
  margin-bottom: 10px;
`

const Box = styled.div`
  background-color: rgba(0, 49, 104, 0.05);
  border-radius: 8px;
  border: solid 1px #e4e7eb;
  color: #000;
`

const DesBox = styled.div`
  padding: 12px 24px;
  background-color: #fff;
`

const CheckListBox = styled.div`
  padding: 24px;
  font-family: 'DB Helvethaica X 55 Regular';
`

const ImageBox = styled.div`
  padding: 24px 18px;
  background-color: #fff;
  page-break-before: always;
`

export default ReportDetail
