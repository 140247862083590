import React from 'react'

import BaseImgBtn from './BaseImgBtn'
import img from 'assets/img/ic-back.png'

const BackBtn = (props: any) => {
  return <BaseImgBtn img={img} onClick={props.onClick} />
}

export default BackBtn
