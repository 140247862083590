import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { connect, useDispatch } from 'react-redux'
import { Row, Col, Switch } from 'antd'
import { RouteComponentProps } from 'react-router-dom'
import RootState from 'typings/RootState'
import SubnavBar from 'Components/Shared/SubnavBar'
import { BreadcrumbLink } from 'Components/Shared/BreadCrumb/BreadCrumb'
import Menu from '../../MenuAdmin'
import {
  userEmailSelector,
  serviceTokenSelector,
} from 'features/sensitive/sensitive.selector'
import {
  AdminProject,
  AlertError,
  AlertSuccess,
  LoadingView,
} from 'Components/Shared'
import { selectEvent } from 'features/admin/update-activity-progress/selector'
import ImportBtn from 'Components/Shared/Table/ImportBtn'
import XLSX from 'xlsx'
import { projectsByIdSelector } from 'features/project/project.selector'
import { fetchProjects } from 'features/project/project.api'
import { DropdownList } from 'Components/Shared/Form'
import { ReqUpdateActivityProgress } from 'features/admin/update-activity-progress/types'
import { updateActivityProgress } from 'features/admin/update-activity-progress/api'

const breadcrumbs: BreadcrumbLink[] = [
  {
    url: '/dashboard',
    title: 'Home',
    icon: '/home.png',
    active: true,
    showTitle: false,
  },
  {
    url: '/update-activity-progress',
    title: 'Update Activity Progress',
    active: false,
    showTitle: false,
  },
]

type Props = DispatchProps & RouteComponentProps & ReduxProps

const UpdateActivityPregress: React.ComponentType<Props> = props => {
  const { token, UserEmail, event, projectsById } = props
  // const [planType, setPlanType] = useState<'MASTER PLAN' | 'DETAIL PLAN'>(
  //   'MASTER PLAN'
  // )
  const [planType, setPlanType] = useState<'MASTER PLAN'>('MASTER PLAN')
  const dispatch = useDispatch()
  const [projectCode, setProjectCode] = useState('')
  const planTypeList = [
    {
      value: 'MASTER PLAN',
      name: 'Master Plan',
    },
    // {
    //   value: 'DETAIL PLAN',
    //   name: 'Detail Plan',
    // },
  ]

  const replaceDate = (date: string) => {
    if (!date || date === '') return ''

    date = date.replaceAll('-', '/')

    const dateStr = date.split('/')[0]
    const monthStr = date.split('/')[1]
    const yearStr = date.split('/')[2]

    const _date = dateStr.length === 1 ? '0' + dateStr : dateStr
    const _month = monthStr.length === 1 ? '0' + monthStr : monthStr

    return `${yearStr}${_month}${_date}`
  }

  useEffect(() => {
    dispatch(fetchProjects({ email: UserEmail, token }))
  }, [UserEmail, token])

  const onImportFile = (data: any, file: any, name: any) => {
    const wb = XLSX.read(data, { type: 'binary' })

    const sheetName = wb.SheetNames[0]
    const rows = wb.Sheets[sheetName]
    let rawData = XLSX.utils.sheet_to_json(rows)
    rawData = rawData.filter((raw: any) => {
      return parseInt(raw['Activity % Complete']) > 0
    })

    const updateData: any[] = []
    console.log('rawData', rawData)

    if (rawData)
      rawData.map((raw: any, index) => {
        const item = {
          ActivityID: raw['Activity ID'],
          ActualStartDate: replaceDate(raw['Actual Start']),
          ActualFinishDate: replaceDate(raw['Actual Finish']),
          BaseLineStartDate: replaceDate(raw['BL Project Start']),
          BaseLineFinishDate: replaceDate(raw['BL Project Finish']),
          TaskStatus: raw['Activity Status'] === 'Completed' ? 'APPV' : 'INPRO',
          Progress: parseInt(raw['Activity % Complete']),
        }

        updateData.push(item)
      })
    console.log('updateData', updateData)
    const req: ReqUpdateActivityProgress = {
      UserEmail,
      ProjectCode: projectCode,
      PlanType: planType,
      Data: [...updateData],
    }

    onUpdateProgressWithExcel(req)
  }

  const onUpdateProgressWithExcel = (req: any) => {
    dispatch(updateActivityProgress(token, req))
  }

  const header = (
    <Header>
      <Row type="flex" align="middle" justify="start">
        <Col>
          <Title>Update Activity Progress</Title>
        </Col>
      </Row>
      <Col>
        <Filter>
          <DropdownList
            label="สถานะ"
            value={planType}
            menu={planTypeList}
            onChange={(value: any) => {
              setPlanType(value)
            }}
          />
        </Filter>
      </Col>
      <Col>
        <Filter>
          <AdminProject
            projectList={projectsById}
            onSelectProject={projectCode => setProjectCode(projectCode)}
            activeProject={projectCode}
          />
        </Filter>
      </Col>
      <Col>
        <ImportBtn
          text={'Import Excel'}
          onChange={onImportFile}
          type={'.xlsx'}
          disabled={projectCode === '' ?? false}
        ></ImportBtn>
      </Col>
    </Header>
  )

  return (
    <>
      <LoadingView isShow={event.IsLoading} />
      <AlertSuccess
        isShow={event.SubmitSuccess ? true : false}
        text={event.MessageSuccess}
        callback={() => setProjectCode('')}
      />
      <AlertError
        isShow={event.SubmitError ? true : false}
        text={event.SubmitError}
      />
      <SubnavBar breadcrumbs={breadcrumbs} hideSearch={true} />
      <Menu selected={'update-activity-progress'}>
        <MainPage>{header}</MainPage>
      </Menu>
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    event: selectEvent(state),
    token: serviceTokenSelector(state),
    UserEmail: userEmailSelector(state),
    projectsById: projectsByIdSelector(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateActivityPregress)

const MainPage = styled.div`
  padding: 40px 45px;
  width: 100%;
`

const Header = styled.div`
  margin-bottom: 35px;
  height: 50px;
`

const Title = styled.h2`
  font-size: 28px;
  font-family: 'DB Helvethaica X 75 Bd';
  height: 30px;
`

const Filter = styled.div`
  margin: 2rem 0;
  width: 30%;
`
