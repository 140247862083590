import React, { useState } from 'react'
import styled from 'styled-components'
import { Row, Col, Progress } from 'antd'
import { Link } from 'react-router-dom'
import { AuditRoadToDo } from 'features/qc/road/types'

interface Props {
  task: AuditRoadToDo
  onAcceptReq: (task: AuditRoadToDo) => void
}

const TodoCard: React.FC<Props> = props => {
  const { task, onAcceptReq } = props
  const { ProjectCode, CheckNumber, ProjectNameTH } = task

  return (
    <MainCard onClick={() => onAcceptReq(task)}>
      <Row type="flex" justify="space-between">
        <Col span={17}>
          <Title>{ProjectCode}</Title>
          <Title>{ProjectNameTH}</Title>
          <Detail>ตรวจครั้งที่ {CheckNumber}</Detail>
        </Col>
      </Row>
    </MainCard>
  )
}

const MainCard = styled.div`
  background: #fff;
  height: 104px;
  border-radius: 10px;
  padding: 16px 16px 16px 24px;
  background-clip: padding-box;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.03);
  margin-bottom: 12px;
  cursor: pointer;
  color: #000;
  width: 100%;
  position: relative;
`

const Title = styled.p`
  font-size: 20px;
  margin-bottom: 5px;
  font-family: 'DB Helvethaica X 75 Bd';
  line-height: 1;
`

const Detail = styled.p`
  font-size: 18px;
  margin-bottom: 4px;
  font-family: 'DB Helvethaica X 55 Regular';
`

export default TodoCard
