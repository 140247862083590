import { getType } from 'typesafe-actions'
import { cloneDeep } from 'lodash'
import * as actions from './actions'
import {
  StoreState,
  DashboardAction,
  CommonAreaFilter,
  CommissioningFilter,
  UnitFilter,
} from './types'
import produce from 'immer'
import parseErrorMessage from 'utils/parseErrorMessage'
import { filterActivity } from 'utils/helper'
import { Event } from '../../types/event'

const initEvent = {
  IsLoading: false,
  MessageSuccess: undefined,
  SubmitError: undefined,
  SubmitSuccess: false,
  SubmitType: undefined,
} as Event

const initCommonAreaFilter = {
  ProjectCode: '',
  FloorList: [],
  ZoneList: [],
} as CommonAreaFilter

const initCommissioningFilter = {
  ProjectCode: '',
  CommissionTypeList: [],
} as CommissioningFilter

const initUnitFilter = {
  ProjectCode: '',
  QCTypeList: [],
} as UnitFilter

const initialState: StoreState = {
  unit: {
    data: {
      ToDo: [],
      InProgress: [],
    },
    originalData: {
      ToDo: [],
      InProgress: [],
    },
    filter: cloneDeep(initUnitFilter),
  },
  commissioning: {
    data: {
      ToDo: [],
      InProgress: [],
    },
    originalData: {
      ToDo: [],
      InProgress: [],
    },
    filter: cloneDeep(initCommissioningFilter),
  },
  zone: {
    data: {
      ToDo: [],
      InProgress: [],
    },
    originalData: {
      ToDo: [],
      InProgress: [],
    },
    filter: cloneDeep(initCommonAreaFilter),
  },
  constructPlan: {
    TotalInProgress: 0,
    InProgress: [],
    TotalReturn: 0,
    Return: [],
  },
  auditPlan: {
    TotalInProgress: 0,
    InProgress: [],
    TotalReturn: 0,
    Return: [],
  },
  constructRoad: {
    ToDo: [],
    InProgress: [],
  },
  event: cloneDeep(initEvent),
  query: {
    date: {
      start: undefined,
      end: undefined,
    },
  },
  filter: {
    progress: [],
    status: [],
    wbs: [],
    date: {
      mode: '',
      start: undefined,
      end: undefined,
    },
    floor: [],
    unit: [],
    zone: [],
    type: [],
  },
}

export default function(
  state: StoreState = initialState,
  action: DashboardAction
): StoreState {
  return produce(state, draft => {
    switch (action.type) {
      case getType(actions.getUnitForQCAction.request):
        draft.event = cloneDeep(initEvent)
        draft.event.IsLoading = true

        draft.unit.data = {
          ToDo: [],
          InProgress: [],
        }
        break
      case getType(actions.getUnitForQCAction.success):
        draft.event.IsLoading = false
        const unitResponse = action.payload.ResponseData
        draft.unit.data = {
          ToDo: unitResponse.ToDo || [],
          InProgress: unitResponse.InProgress || [],
        }
        break

      case getType(actions.sendReportAction.request):
      case getType(actions.returnTaskAction.request):
      case getType(actions.sendZoneReportAction.request):
      case getType(actions.returnZoneAction.request):
      case getType(actions.sendCommissReportAction.request):
      case getType(actions.returnCommissioningAction.request):
      case getType(actions.sendConstructReportAction.request):
      case getType(actions.returnConstructAction.request):
      case getType(actions.sendAuditReportAction.request):
      case getType(actions.returnAuditPlanAction.request):
      case getType(actions.sendConstructRoadAction.request):
      case getType(actions.returnConstructRoadAction.request):
        draft.event = cloneDeep(initEvent)
        draft.event.IsLoading = true

        break
      case getType(actions.sendReportAction.success):
      case getType(actions.returnTaskAction.success):
      case getType(actions.sendZoneReportAction.success):
      case getType(actions.returnZoneAction.success):
      case getType(actions.sendCommissReportAction.success):
      case getType(actions.returnCommissioningAction.success):
      case getType(actions.sendConstructReportAction.success):
      case getType(actions.returnConstructAction.success):
      case getType(actions.sendAuditReportAction.success):
      case getType(actions.returnAuditPlanAction.success):
      case getType(actions.sendConstructRoadAction.success):
      case getType(actions.returnConstructRoadAction.success):
        draft.event.IsLoading = false
        draft.event.SubmitError = undefined
        draft.event.SubmitSuccess = true
        draft.event.MessageSuccess = 'ทำรายการสำเร็จ'
        break

      case getType(actions.getUnitForQCAction.failure):
      case getType(actions.sendReportAction.failure):
      case getType(actions.returnTaskAction.failure):
      case getType(actions.sendZoneReportAction.failure):
      case getType(actions.returnZoneAction.failure):
      case getType(actions.sendCommissReportAction.failure):
      case getType(actions.returnCommissioningAction.failure):
      case getType(actions.sendConstructReportAction.failure):
      case getType(actions.returnConstructAction.failure):
      case getType(actions.sendAuditReportAction.failure):
      case getType(actions.returnAuditPlanAction.failure):
      case getType(actions.getZoneForQCAction.failure):
      case getType(actions.getCommissioningForQCAction.failure):
      case getType(actions.getConstructPlanAction.failure):
      case getType(actions.getAuditPlanAction.failure):
      case getType(actions.sendConstructRoadAction.failure):
      case getType(actions.returnConstructRoadAction.failure):
        draft.event.IsLoading = false
        draft.event.SubmitError = parseErrorMessage(action.payload)
        break

      case getType(actions.getZoneForQCAction.request):
        draft.event = cloneDeep(initEvent)
        draft.event.IsLoading = true

        draft.zone.data = {
          ToDo: [],
          InProgress: [],
        }
        break
      case getType(actions.getZoneForQCAction.success):
        draft.event.IsLoading = false
        const zoneResponse = action.payload.ResponseData
        const filter = state.filter
        let todoList = filterActivity(zoneResponse.ToDo || [], filter)
        let inprogressList = filterActivity(
          zoneResponse.InProgress || [],
          filter
        )

        draft.zone.data = {
          ToDo: todoList,
          InProgress: inprogressList,
        }

        draft.zone.originalData = {
          ToDo: zoneResponse.ToDo,
          InProgress: zoneResponse.InProgress,
        }
        break

      case getType(actions.getCommissioningForQCAction.request):
        draft.event = cloneDeep(initEvent)
        draft.event.IsLoading = true

        draft.commissioning.data = {
          ToDo: [],
          InProgress: [],
        }
        break

      case getType(actions.getCommissioningForQCAction.success):
        draft.event.IsLoading = false
        const commissioningResponse = action.payload.ResponseData

        draft.commissioning.data = {
          ToDo: commissioningResponse.ToDo,
          InProgress: commissioningResponse.InProgress,
        }
        break

      case getType(actions.getConstructPlanAction.request):
        draft.event = cloneDeep(initEvent)
        draft.event.IsLoading = true
        draft.constructPlan = {
          InProgress: [],
          Return: [],
          TotalInProgress: 0,
          TotalReturn: 0,
        }
        break
      case getType(actions.getConstructPlanAction.success):
        draft.event.IsLoading = false
        const construc = action.payload.ResponseData
        draft.constructPlan = construc

        break

      case getType(actions.getAuditPlanAction.request):
        draft.event = cloneDeep(initEvent)
        draft.event.IsLoading = true
        draft.auditPlan = {
          InProgress: [],
          Return: [],
          TotalInProgress: 0,
          TotalReturn: 0,
        }
        break
      case getType(actions.getAuditPlanAction.success):
        draft.event.IsLoading = false
        const audit = action.payload.ResponseData
        draft.auditPlan = audit
        break

      case getType(actions.getConstructRoadAction.request):
        draft.event = cloneDeep(initEvent)
        draft.event.IsLoading = true
        draft.constructRoad = {
          InProgress: [],
          ToDo: [],
        }
        break
      case getType(actions.getConstructRoadAction.success):
        draft.event.IsLoading = false
        draft.constructRoad = action.payload.ResponseData
        break

      case getType(actions.updateCommonAreaFilter):
        draft.zone.filter = { ...action.payload }
        break
      case getType(actions.updateCommissioningFilter):
        draft.commissioning.filter = { ...action.payload }
        break
      case getType(actions.updateUnitFilter):
        draft.unit.filter = { ...action.payload }
        break
      case getType(actions.clearFilter):
        draft.zone.filter = cloneDeep({ ...initCommonAreaFilter })
        draft.commissioning.filter = cloneDeep({ ...initCommissioningFilter })
        draft.unit.filter = cloneDeep({ ...initUnitFilter })
        break

      default:
        return state
    }
  })
}
