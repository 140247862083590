import React from 'react'
import RootState from 'typings/RootState'
import { connect, useDispatch } from 'react-redux'
import { Row, Col } from 'antd'
import { translate } from 'i18n'
import TKeys from 'i18n/translationKey'
import styled from 'styled-components'

import history from 'utils/history'
import projectHelper from 'utils/projectHelper'
import { getUserProfile } from '../../../features/sensitive/sensitive.selector'
import { updateUserRule } from '../../../features/sensitive/sensitive.actions'

import bg from 'assets/img/module-bg.png'
import logo from 'assets/img/logo-transparent.png'
import logoMasterplan from 'assets/img/role/img-masterplan.png'
import logoApproval from 'assets/img/role/img-approval.png'
import logoMaincon from 'assets/img/role/img-maincon.png'
import logoCMPJ from 'assets/img/role/img-cmpj.png'
import logoQC from 'assets/img/role/img-qc.png'
import logoAdmin from 'assets/img/role/img-administrator.png'
import logoAdminQc from 'assets/img/role/img-adminqc.png'
import logoAdminMaincon from 'assets/img/role/img-adminmaincon.png'
import logoPmr from 'assets/img/role/img-pmr.png'
import logoReport from 'assets/img/role/img-report.png'

type AppProps = {}
type Props = ReduxProps & DispatchProps & AppProps

const ModulePage = (props: Props) => {
  const { userProfile } = props

  const dispatch = useDispatch()
  const to = '/'
  const RolesModule = [
    {
      name: 'Master Plan',
      id: 1,
      icon: logoMasterplan,
      active: true,
      to,
    },
    { name: 'Approval', id: 5, icon: logoApproval, active: true, to },
    { name: 'Main Con', id: 2, icon: logoMaincon, active: true, to },
    { name: 'CM/PJ', id: 3, icon: logoCMPJ, active: true, to },
    { name: 'QC', id: 4, icon: logoQC, active: true, to },
    { name: 'PMR', id: 6, icon: logoPmr, active: true, to },
  ]

  const AdminModule = [
    {
      name: 'Administrator',
      id: 9,
      icon: logoAdmin,
      active: true,
      to,
    },
    {
      name: 'Admin PJ',
      id: 7,
      icon: logoAdminMaincon,
      active: true,
      to,
    },
    {
      name: 'Admin QC',
      id: 8,
      icon: logoAdminQc,
      active: true,
      to,
    },
    {
      name: 'Report',
      id: 10,
      icon: logoReport,
      active: true,
      to,
    },
  ]

  const onSelect = (item: any) => {
    dispatch(props.updateUserRule({ Name: item.name, Id: item.id }))
    projectHelper.saveModule(item.name)
    projectHelper.saveModuleID(item.id)

    if (item.to) history.push(item.to)
  }

  const isActiveModule = (moduleID: number) => {
    const data = userProfile.Roles.find(role => role.ModuleID === moduleID)
    return data ? true : false
  }

  const roles = RolesModule.map((element, index) => {
    const item = isActiveModule(element.id) ? (
      <Image
        src={element.icon}
        alt={element.name}
        onClick={() => onSelect(element)}
      />
    ) : (
      <DisableImage src={element.icon} alt={element.name} />
    )
    return (
      <Col xs={12} sm={4} md={4} lg={4} key={index}>
        <RoleItem>
          {item}
          <p>{element.name}</p>
        </RoleItem>
      </Col>
    )
  })

  const admin = AdminModule.map((element, index) => {
    const item = isActiveModule(element.id) ? (
      <Image
        src={element.icon}
        alt={element.name}
        onClick={() => onSelect(element)}
      />
    ) : (
      <DisableImage src={element.icon} alt={element.name} />
    )
    return (
      <Col xs={12} sm={4} md={4} lg={4} key={index}>
        <RoleItem>
          {item}
          <p>{element.name}</p>
        </RoleItem>
      </Col>
    )
  })

  return (
    <Contrainer>
      <BgImage>
        <img src={bg} />
      </BgImage>
      <Content>
        <Row
          type="flex"
          justify="center"
          align="middle"
          style={{ height: '90%' }}
        >
          <Col md={16}>
            <Header>
              <LogoImage src={logo} />
              <p>{translate(TKeys.Module.title)}</p>
            </Header>
            <Roles>
              <Row type="flex" justify="center">
                {roles}
              </Row>
              <Title>Administrator</Title>
              <Row type="flex" justify="center">
                {admin}
              </Row>
            </Roles>
          </Col>
        </Row>
      </Content>
    </Contrainer>
  )
}

const Contrainer = styled.div`
  width: 100%;
  height: 100vh;
  background-color: #fff;
`

const BgImage = styled.div`
  position: absolute;
  bottom: 0;
  img {
    width: 100%;
    height: 100%;
  }
`

const Content = styled.div`
  text-align: center;
  width: 100%;
  height: calc(100vh - 150px);
  padding-top: 50px;
`

const Header = styled.div`
  img {
    width: 200px;
    margin-bottom: 20px;
  }
  p {
    font-size: 24px;
  }
`

const LogoImage = styled.img`
  width: 200px;
  margin-bottom: 20px;
`

const Roles = styled.div`
  margin-top: 50px;
`

const RoleItem = styled.div`
  padding: 14px;
  p {
    margin-top: 10px;
    font-size: 20px;
    font-family: 'DB Helvethaica X 75 Bd';
  }
`

const DisableImage = styled.img`
  filter: grayscale(100%);
  cursor: not-allowed;
  width: 100%;
`

const Image = styled.img`
  cursor: pointer;
  width: 100%;
`

const Title = styled.p`
  font-size: 28px;
  font-family: 'DB Helvethaica X 75 Bd';
`

const mapStateToProps = (state: RootState) => {
  return {
    userProfile: getUserProfile(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {
  updateUserRule,
}

type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(ModulePage)
