import React, { useState, useEffect } from 'react'
import { Modal, Row, Col } from 'antd'
import styled from 'styled-components'
import { SecondaryButton, PrimaryButton } from 'Components/Shared/Button'
import { Tabs } from 'antd'
import InputSearch from 'Components/Shared/Input/InputSearch'
import { RadioBox } from 'Components/Shared/Input'
import { UnitItem } from 'features/masterData/types'
import { cloneDeep } from 'lodash'

const { TabPane } = Tabs

type Props = {
  onClose: () => void
  onSave: (selectedList: UnitItem[]) => void
  visible?: boolean
  unitList: UnitItem[]
  selectedList: UnitItem[]
  readonly?: boolean
}

const LocationModal = (props: Props) => {
  const {
    visible = false,
    readonly = false,
    onClose,
    onSave,
    unitList,
    selectedList,
  } = props
  const defaultActive = 'Resident Area'
  const [activeKey, setActiveKey] = useState('' as string)
  const [txtSearch, setTxtSearch] = useState('')
  const [listFilter, setListFilter] = useState([] as UnitItem[])
  const [listSelected, setListSelected] = useState([] as UnitItem[])

  useEffect(() => {
    setTxtSearch('')
    setActiveKey(defaultActive)
    if (visible) {
      setListFilter(unitList)
      setListSelected(cloneDeep(selectedList))
    }
  }, [visible])

  const onSelected = (val: UnitItem) => {
    const index = listSelected.findIndex(item => {
      return (
        item.Unit === val.Unit &&
        item.Zone === val.Zone &&
        item.Floor === val.Floor
      )
    })

    if (index === -1) listSelected.push(val)
    else listSelected.splice(index, 1)
    setListSelected([...listSelected])
  }

  const hasText = (data: string, searchTxt: string) => {
    if (data && searchTxt) {
      return data.toLocaleLowerCase().includes(searchTxt.toLocaleLowerCase())
    }
    return false
  }

  const onFilter = (val: string) => {
    setTxtSearch(val)
    if (val.trim() === '') {
      setListFilter([...unitList])
    } else {
      const newList = unitList.filter(item => {
        return hasText(item.Unit, val) || hasText(item.Floor, val)
      })
      setListFilter([...newList])
    }
  }

  const getText = (item: UnitItem) => {
    return item.Zone === 'Resident Area'
      ? item.Unit
      : `${item.Unit} - ชั้น ${item.Floor}`
  }

  const getSelected = (val: UnitItem) => {
    const index = listSelected.findIndex(item => {
      return (
        item.Unit === val.Unit &&
        item.Zone === val.Zone &&
        item.Floor === val.Floor
      )
    })
    return index !== -1
  }

  const countSelected = (type: 'Resident Area' | 'Common Area') => {
    let count = 0
    listSelected.forEach(item => {
      if (item.Zone === type) {
        count++
      }
    })
    return count
  }

  const renderContent = (type: 'Resident Area' | 'Common Area') => {
    const count = countSelected(type)
    const title =
      type === 'Resident Area' ? `ยูนิต (${count})` : `ส่วนกลาง (${count})`
    return (
      <TabPane tab={title} key={type}>
        <TabContent>
          <InputSearch
            readOnly={false}
            placeholder="พิมพ์ชื่อที่ต้องการค้นหา..."
            value={txtSearch}
            onChange={(value: string) => onFilter(value)}
          />
          <ItemList>
            {listFilter.map((item, index) => {
              if (item.Zone === type) {
                return (
                  <Item key={index}>
                    <RadioBox
                      label={getText(item)}
                      value={item}
                      spaceBtwText={16}
                      readOnly={readonly}
                      isSelected={getSelected(item)}
                      onClick={() => onSelected(item)}
                    />
                  </Item>
                )
              }
            })}
          </ItemList>
        </TabContent>
      </TabPane>
    )
  }

  const unitTab = renderContent('Resident Area')

  const zoneTab = renderContent('Common Area')

  return (
    <Modal
      className="location-modal"
      title={false}
      centered
      visible={visible}
      footer={false}
      closable={false}
    >
      <Body>
        <Header>เลือกพื้นที่</Header>
        <div>
          <Tabs
            activeKey={activeKey}
            onChange={setActiveKey}
            tabBarStyle={{ textAlign: 'center' }}
          >
            {unitTab}
            {zoneTab}
          </Tabs>
        </div>
        <ButtonSection>
          <Row type="flex" justify="space-between">
            <Col span={11}>
              <div className="btn-submit">
                <SecondaryButton
                  text="ยกเลิก"
                  disabled={false}
                  onClick={() => onClose()}
                />
              </div>
            </Col>
            <Col span={11}>
              <div className="btn-submit">
                <PrimaryButton
                  text="บันทึก"
                  disabled={readonly}
                  onClick={() => onSave(listSelected)}
                />
              </div>
            </Col>
          </Row>
        </ButtonSection>
      </Body>
    </Modal>
  )
}

const Header = styled.div`
  text-align: center;
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 24px;
  color: #000;
`

const Body = styled.div`
  .ant-tabs-nav {
    font-family: 'DB Helvethaica X 55 Regular';
    font-size: 20px;
    color: #8b99a7;
  }

  .ant-tabs-nav .ant-tabs-tab-active {
    color: #003168;
    font-family: 'DB Helvethaica X 75 Bd';
  }

  .ant-tabs-ink-bar {
    background-color: #003168 !important;
    height: 3px;
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.03);
  }

  .ant-tabs-nav {
    .ant-tabs-tab:hover,
    .ant-tabs-tab:active {
      color: #003168;
    }
  }

  .ant-tabs-bar {
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.07);
    border: none;
  }
`

const ButtonSection = styled.div`
  margin-top: 32px;
`

const TabContent = styled.div`
  font-family: 'DB Helvethaica X 55 Regular';
  font-size: 20px;
  color: #000;
`

const ItemList = styled.div`
  height: 376px;
  overflow: scroll;
  border-radius: 5px;
  border: solid 1px #e4e7eb;
  margin-top: 15px;
`

const Item = styled.div`
  padding: 12px 0;
  border-bottom: solid 1px #e4e7eb;
  margin: 0 16px;

  &:last-child {
    border: none;
  }
`

export default LocationModal
