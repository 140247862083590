import { createStandardAction, createAsyncAction } from 'typesafe-actions'
import {
  GetActivityCheckingRes,
  GetActivityReq,
  SendReportReq,
  UpdateResponse,
} from './types'
import { Event } from 'features/types/event'

export const getCheckingDataAction = createAsyncAction(
  'cmpj/road/construct/FETCH_CHECKING_REQUEST',
  'cmpj/road/construct/FETCH_CHECKING_SUCCESS',
  'cmpj/road/construct/FETCH_CHECKING_FAILURE'
)<GetActivityReq, GetActivityCheckingRes, any>()

export const sendReportAction = createAsyncAction(
  'cmpj/road/construct/SEND_REPORT_REQUEST',
  'cmpj/road/construct/SEND_REPORT_SUCCESS',
  'cmpj/road/construct/SEND_REPORT_FAILURE'
)<SendReportReq, UpdateResponse, any>()

export const sortingTask = createStandardAction('cmpj/road/construct/SORTING')<{
  name: string
  sort?: string
}>()

export const setProgress = createStandardAction(
  'cmpj/road/construct/SET_PROGRESS'
)<{
  success: number
  total: number
  show: boolean
}>()

export const setShowProgress = createStandardAction(
  'cmpj/road/construct/SET_SHOW_PROGRESS'
)<{
  isShow: boolean
}>()

export const sendMultiReport = createStandardAction(
  'cmpj/road/construct/SEND_REPORT_TO_QC'
)<any>()

export const setEvent = createStandardAction('cmpj/road/construct/SET_EVENT')<
  Event
>()
