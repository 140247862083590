// ** Please ignore this file **
// used for mock data only
// will remove when connected the API
import { CheckingData ,CheckListData} from './types'

export const mockCheckingData = [
  {
    CheckListID: '1',
    CheckListName: 'ระบบ A',
    ActivityID: '1',
    ActivityName: '',
    NumOfCheckList: 10,
    CheckNumber: 1,
    QCTypeName: 'Q3',
    FloorName: '',
    RequestDate: '09/08/2019',
  } as CheckingData,
  {
    CheckListID: '2',
    CheckListName: 'ระบบ B',
    ActivityID: '1',
    ActivityName: '',
    NumOfCheckList: 20,
    CheckNumber: 1,
    QCTypeName: 'Q2',
    FloorName: '',
    RequestDate: '24/02/2020',
  } as CheckingData,
  {
    CheckListID: '3',
    CheckListName: 'ระบบ C',
    ActivityID: '1',
    ActivityName: '',
    NumOfCheckList: 30,
    CheckNumber: 2,
    QCTypeName: 'Q3',
    FloorName: '',
    RequestDate: '15/03/2020',
  } as CheckingData,
]

export const mockCheckListData = {
  CheckListID : '1',
  CheckListName : 'ระบบ A',
  ProjectCode : '2511',
  ProjectName : 'เดอะ ไลน์ สุขุมวิท 101',
  QCTypeName : 'Q1',
  CheckNumber : 1,
  CheckListStatus : '',
  CommissioningStatus : '',
  ProjectTypeID : '',
  ProjectTypeName : '',
  CheckedDate : '22/01/2020',
  CommissioningType : '',
  ImageList : [],
  QList : [],
  CommissioningList : []
} as CheckListData