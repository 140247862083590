import React from 'react'
import { Col } from 'antd'
import styled from 'styled-components'
import { TableRow, StatusCol } from 'Components/Shared/Table'
import { DefectItem } from 'features/report/auditPlan/types'

import imgEdit from 'assets/img/ic-edit-defect.png'
import imgCorrect from 'assets/img/ic-correct-defect.png'
import { RequireIcon } from 'Components/Shared/Icon'
import { RadioBox } from 'Components/Shared/Form'
import DefectStatus from 'Components/Defect/DefectStatus'
import { getDefectColor } from 'utils/color-map'

type Props = {
  item: DefectItem
  onClick?: (item: DefectItem) => void
  isShowEditImg?: boolean
  isShowCorrectImg?: boolean
  isShowRadio?: boolean
  isSelected?: boolean
  readOnly?: boolean
  onSelected?: (item: DefectItem) => void
}

const ReportItem: React.FunctionComponent<Props> = props => {
  const {
    item,
    onClick = (item: DefectItem) => {},
    isShowEditImg = false,
    isShowCorrectImg = false,
    isShowRadio = false,
    isSelected = false,
    readOnly = false,
    onSelected = (item: DefectItem) => {},
  } = props

  const getRadioBox = (
    <Margin>
      <RadioBox
        value={''}
        label={''}
        isCheck={isSelected}
        onClick={onSelected}
        size={24}
      />
    </Margin>
  )

  const defect = getDefectColor(item.PinStatus)
  const color = defect.textColor

  let imgIcon
  if (isShowEditImg && item.HowToFix.length > 0) {
    imgIcon = (
      <Col span={2}>
        <img src={imgEdit} alt="" width={22} />
      </Col>
    )
  } else if (isShowCorrectImg && item.VerifyStatus !== 'None') {
    imgIcon = (
      <Col span={2}>
        <img src={imgCorrect} alt="" width={22} />
      </Col>
    )
  }
  return (
    <RowView onClick={() => onClick(item)}>
      <TableRow
        backgroundColor={readOnly ? 'rgba(0, 49, 104, 0.03)' : undefined}
      >
        <Col span={2}>
          <StatusCol color={defect.color}>
            <Pin>{item.PinNumber}</Pin>
          </StatusCol>
        </Col>

        <Col span={6} style={{ color }}>
          <Text>
            {item.IsRequired && (
              <Space>
                <RequireIcon size={14} />
              </Space>
            )}

            {item.JobTypeName}
          </Text>
        </Col>

        <Col span={6} style={{ color }}>
          <Text>{item.SubJobTypeName}</Text>
        </Col>

        <Col span={5} style={{ color }}>
          <Text>{item.DetailName}</Text>
        </Col>

        <Col span={3}>
          <DefectStatus pinStatus={item.PinStatus} />
        </Col>

        {imgIcon}
        {isShowRadio && getRadioBox}
      </TableRow>
    </RowView>
  )
}

const RowView = styled.div`
  font-size: 20px;
  font-family: 'DB Helvethaica X 55 Regular';
  cursor: pointer;
`

const Pin = styled.div`
  padding-left: 24px;
  font-family: 'DB Helvethaica X 75 Bd';
`

const Status = styled.span`
  border-radius: 10px;
  padding: 2px 10px;
  font-size: 16px;
  color: white;
`

const Space = styled.div`
  padding-right: 5px;
  float: left;
`

const Margin = styled.div`
  margin-left: 4px;
`

const Text = styled.div`
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 10px;
`

export default ReportItem
