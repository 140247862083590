import React, { useState } from 'react'
import { Col, Menu, Dropdown } from 'antd'
import styled from 'styled-components'
import RadioBox from 'Components/Shared/Form/RadioBox'
import { ArrowDownIcon } from '../../../Shared/Icon'

type DefaultProps = {
  hideColumn: string[]
  onHideColumn(key: string): void
  column: any
}

type Props = DefaultProps

const ConfigColumn: React.FC<Props> = props => {
  const { hideColumn, onHideColumn, column } = props

  const menu = (
    <Menu className="config-header">
      {column.map((item: any, index: number) => {
        return (
          <MenuItem key={index}>
            <RadioBox
              value={item.key}
              label={item.title}
              isCheck={!hideColumn.includes(item.key)}
              onClick={value => onHideColumn(value)}
              spaceBtwText={16}
            />
          </MenuItem>
        )
      })}
    </Menu>
  )

  return (
    <Dropdown overlay={menu} placement="bottomCenter" trigger={['click']}>
      <ColumnConfig>
        <Text>ตั้งค่าหัวข้อคอลัมน์</Text> <ArrowDownIcon />
      </ColumnConfig>
    </Dropdown>
  )
}

const MenuItem = styled.div`
  padding: 4.5px 16px;
`

const ColumnConfig = styled.div`
  font-family: 'DB Helvethaica X 55 Regular';
  font-size: 18px;
  padding: 9px 24px;
  border-radius: 20px;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.03);
  background-color: #fff;
  //background-color: red;
  margin-right: 32px;
  width: 173px;
  cursor: pointer;
`

const Text = styled.div`
  float: left;
  margin-right: 16px;
`

export default ConfigColumn
