import withAPIAction from 'utils/withAPIAction'
import { verifyReq } from 'utils/verify'
import {
  getDashbaordAction,
  getCanSendStatusAction,
  sendToQCAction,
} from './actions'
import { GetDashboardReq, GetCanSendStatusReq, SendToQCReq } from './types'

export const getDashboard = (token: string, data: GetDashboardReq) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<GetDashboardReq>({
    method: 'POST',
    path: 'GetDashboardForAuditRoad',
    data,
    token: `Bearer ${token}`,
  })(getDashbaordAction)
}

export const getCanSendStatus = (token: string, data: GetCanSendStatusReq) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<GetCanSendStatusReq>({
    method: 'POST',
    path: 'CanSendAuditRoadToQC',
    data,
    token: `Bearer ${token}`,
  })(getCanSendStatusAction)
}

export const sendToQC = (token: string, data: SendToQCReq) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<SendToQCReq>({
    method: 'POST',
    path: 'SendAuditRoadToQC',
    data,
    token: `Bearer ${token}`,
  })(sendToQCAction)
}
