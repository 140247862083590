import React from 'react'
import styled from 'styled-components'

import { CheckedIcon, UncheckedIcon } from '../Icon'

interface Props {
  label: string
  isCheck: boolean
  style?: React.CSSProperties
  onClick?: () => void
}

const ImgCheckbox = ({
  label = 'Please Select',
  isCheck,
  style,
  onClick,
}: Props) => {
  return (
    <ItemView onClick={onClick} style={style}>
      <span>{isCheck ? <CheckedIcon /> : <UncheckedIcon />}</span>
      <div>{label}</div>
    </ItemView>
  )
}

const ItemView = styled.div`
  font-family: 'DB Helvethaica X 55 Regular';
  font-size: 18px;
  height: 30px;
  align-items: center;
  display: flex;
  cursor: pointer;

  span {
    margin-right: 7px;
  }

  div {
    margin-top: 4px;
  }
`

export default ImgCheckbox
