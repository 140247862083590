import withAPIAction from 'utils/withAPIAction'
import { verifyReq } from 'utils/verify'
import { GetActivitySeeMoreForQCAction } from './actions'

import { GetActivitySeemoreReq } from './types'

export const getSeemoreForZone = (
  token: string,
  data: GetActivitySeemoreReq
) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<GetActivitySeemoreReq>({
    method: 'POST',
    path: 'GetSeemoreForZone',
    data,
    token: `Bearer ${token}`,
  })(GetActivitySeeMoreForQCAction)
}
