import React from 'react'
import { Modal, Row, Col, Spin, Icon } from 'antd'

import styled from 'styled-components'
import iconError from 'assets/img/ic-success.png'
import { translate } from 'i18n'
import TKeys from 'i18n/translationKey'

interface Props {
  isShow: boolean
  time?: number
  text?: string
  callback?: () => void
}

const LoadingView = (props: Props) => {
  const { isShow = false } = props

  return (
    <Modal
      wrapClassName="error-box"
      style={css.page}
      visible={isShow}
      closable={false}
      mask={false}
      footer={null}
    >
      <Body>
        <Spin indicator={<Icon type="loading" style={css.spin} spin />} />
      </Body>
    </Modal>
  )
}

const Body = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  height: 125px;
`
const css: any = {
  page: {
    marginTop: '14%',
    width: '20px',
    height: '20px',
    textAlign: 'center',
    fontSize: '20px',
    color: 'white',
  },
  spin: {
    color: 'white',
    fontSize: '70px',
  },
}

export default LoadingView
