import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { connect, useDispatch } from 'react-redux'
import { RouteComponentProps, Link } from 'react-router-dom'
import RootState from 'typings/RootState'
import { BreadcrumbLink } from 'Components/Shared/BreadCrumb/BreadCrumb'
import Pagination from 'Components/Shared/Pagination'
import {
  userEmailSelector,
  serviceTokenSelector,
} from 'features/sensitive/sensitive.selector'
import {
  selectData,
  selectEvent,
  selectSorting,
} from 'features/qc/unit/checking/selector'
import {
  GetCheckingUnitByQC,
  AcceptCheckingTaskByQC,
  RejectCheckingTaskByQC,
} from 'features/qc/unit/checking/api'
import {
  GetCheckingUnitReq,
  Data,
  AcceptCheckingTaskReq,
  RejectCheckingTaskReq,
  RejectReason,
} from 'features/qc/unit/checking/types'
import { sortingTask } from 'features/cmpj/seemoreQc/actions'
import { StructureListPage } from 'Components/Shared/Seemore'
import { NoCard, EventModal, Loading } from 'Components/Shared'
import PageIndex from 'Components/Shared/Pagination/PageIndex'
import { RowItem, TaskFooter, TableHeader } from 'Components/QC/ProjectDetail'
import { Row, Col, Modal } from 'antd'
import {
  PrimaryButton,
  SecondaryButton,
  OutlineButton,
} from 'Components/Shared/Button'
import { UnitFilterPage } from 'Components/Filter'
import { selectUnitFilter } from 'features/filter/selector'
import { getEvent } from 'features/wbs/wbs.selector'
import {
  GetDataListReq,
  ConfigListDetail,
} from 'features/admin/setting-config/types'
import { RadioBox } from 'Components/Shared/Form'
import { onGetConfigurationReason } from 'features/admin/setting-config/api'
import { CloseIcon } from 'Components/Shared/Icon'
import { selectDataList } from 'features/admin/setting-config/selector'
import TextArea from 'antd/lib/input/TextArea'

type Props = ReduxProps &
  DispatchProps &
  RouteComponentProps<{ project: string }>

const TodoList: React.ComponentType<Props> = props => {
  const {
    match,
    data,
    event,
    sorting,
    token,
    UserEmail,
    filter,
    wbsEvent,
    reasonList,
  } = props
  const { params } = match
  const { project } = params
  const [taskSelect, setTaskSelect] = useState([] as any[])
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [enableModal, setEnableModal] = useState(false)
  const { List, Total } = data
  const [selected, setSelected] = useState<any>({ index: '' })
  const [showRejectModal, setShowRejectModal] = useState(false)
  const [remark, setRemark] = useState('')
  const dispatch = useDispatch()

  useEffect(() => {
    callGetCheckingUnit()
    onGetList()
  }, [project, perPage, page])

  const callGetCheckingUnit = () => {
    const data: GetCheckingUnitReq = {
      ProjectCode: project,
      UserEmail,
      QCTypeID: filter.QCTypeList,
      UnitList: filter.UnitList,
      WbsList: filter.LeafWbsList,
      Page: page,
      RowPerPage: perPage,
    }
    dispatch(GetCheckingUnitByQC(token, data))
  }

  const onCallSuccess = () => {
    callGetCheckingUnit()
    setTaskSelect([])
  }

  const onGetList = () => {
    setEnableModal(true)
    const req: GetDataListReq = {
      UserEmail,
      Parameter: 'RejectReason_Unit',
      Active: 'A',
      Page: 1,
      RowPerPage: 10,
    }
    dispatch(onGetConfigurationReason(token, req))
  }

  const acceptTask = (date: string) => {
    setEnableModal(true)
    const req: AcceptCheckingTaskReq = {
      ProjectCode: project,
      UserEmail,
      AppointmentDate: date,
      TaskType: 'unit',
      TaskList: taskSelect,
    }
    dispatch(AcceptCheckingTaskByQC(token, req))
  }

  const onSubmitReject = () => {
    const resultIndex =
      reasonList.List.find(v => v.Value === selected.index) ?? null

    const rejectTask: RejectReason = {
      RejectReasonID: resultIndex?.ID != undefined ? resultIndex.ID : 0,
      Remark: remark,
    }
    setEnableModal(true)
    const req: RejectCheckingTaskReq = {
      ProjectCode: project,
      UserEmail,
      TaskType: 'unit',
      TaskList: taskSelect,
      Reject: rejectTask.RejectReasonID != 0 ? rejectTask : {},
    }
    dispatch(RejectCheckingTaskByQC(token, req))
    onClose()
  }

  const onRejectTask = () => {
    setShowRejectModal(true)
  }

  let projectName = `${data.ProjectCode} - ${data.ProjectNameTH}`

  const breadcrumbs: BreadcrumbLink[] = [
    {
      url: '/unit/dashboard',
      title: 'Home',
      icon: '/home.png',
      active: true,
      showTitle: false,
    },
    {
      url: '/unit/dashboard',
      title: 'บอร์ด',
      active: true,
      showTitle: true,
    },
    {
      url: '/',
      title: projectName,
      active: false,
      showTitle: false,
    },
  ]

  const onSort = (name: string) => {
    dispatch(sortingTask({ name }))
  }

  useEffect(() => {
    setEnableModal(false)
  }, [List])

  const renderContent = () => {
    return List.map((item: any, index) => {
      const isSelect = verifySelected(item.Unit, item.QCTypeID, item.RequestID)
      return (
        <RowItem
          task={item}
          key={index}
          onClick={() => onSelectTask(item)}
          isSelected={isSelect}
        />
      )
    })
  }

  const remove = (Unit: string, QCTypeID: string, RequestID: number) => {
    return taskSelect.filter(
      item =>
        item.Unit !== Unit ||
        item.QCType !== QCTypeID ||
        item.RequestID !== RequestID
    )
  }

  const verifySelectAll = () => {
    let isSelectAll = true
    List.map(item => {
      const isSelect = verifySelected(item.Unit, item.QCTypeID, item.RequestID)
      if (!isSelect) {
        isSelectAll = false
        return
      }
    })
    return isSelectAll
  }

  const verifySelected = (
    Unit: string,
    QCTypeID: string,
    RequestID: number
  ) => {
    const selectData = taskSelect.filter(item => {
      return (
        item.Unit === Unit &&
        item.QCType === QCTypeID &&
        item.RequestID === RequestID
      )
    })
    return selectData.length > 0 ? true : false
  }

  const onSelectTask = (item: Data) => {
    const isSelect = verifySelected(item.Unit, item.QCTypeID, item.RequestID)
    let newValue = taskSelect
    if (isSelect) {
      newValue = remove(item.Unit, item.QCTypeID, item.RequestID)
    } else {
      newValue.push({
        Unit: item.Unit,
        QCType: item.QCTypeID,
        RequestID: item.RequestID,
      })
    }
    setTaskSelect([...newValue])
  }

  const onSelectAll = () => {
    const isSelectAll = verifySelectAll()
    let newValue = taskSelect
    if (isSelectAll) {
      newValue = []
    } else {
      List.map(item => {
        newValue.push({
          Unit: item.Unit,
          QCType: item.QCTypeID,
          RequestID: item.RequestID,
        })
      })
    }
    setTaskSelect([...newValue])
  }

  const pagination = (
    <Pagination
      perPage={perPage}
      totalRows={Total}
      currentPage={page}
      onClickPage={page => setPage(page)}
      onChangeNumber={number => setPerPage(number)}
    />
  )

  const tableHeader = (
    <TableHeader
      onSort={name => onSort(name)}
      sorting={sorting}
      isSelectAll={verifySelectAll()}
      onSelectAll={onSelectAll}
      showRadio={List.length > 0}
    />
  )

  const footer = (
    <TaskFooter
      taskSelect={taskSelect}
      onClick={date => acceptTask(date)}
      isLoading={event.IsLoading}
    />
  )

  const pageIndex = (
    <PageIndex
      currentPage={page}
      totalRows={Total}
      perPage={perPage}
      onClick={page => setPage(page)}
    />
  )

  const loading = (
    <div style={{ textAlign: 'center' }}>
      <Loading />
    </div>
  )

  const content =
    data.List && data.List.length > 0 ? (
      renderContent()
    ) : (
      <NoCard text={`ไม่มีรายการ Request`} />
    )

  const closeIcon = <CloseIcon color="#8b99a7" size={24} />

  const filterContent = (
    <Row type="flex" justify="space-between" align="middle">
      <Col>
        <UnitFilterPage
          onSearch={() => {
            setPage(1)
            callGetCheckingUnit()
          }}
          ProjectCode={project}
        />
      </Col>
      <Col>
        <Btn>
          <PrimaryButton
            text="Reject Request"
            disabled={taskSelect.length <= 0 || event.IsLoading}
            onClick={onRejectTask}
            btnType="Cancel"
          />
        </Btn>
      </Col>
    </Row>
  )

  const checkOtherReason = () => {
    const checked =
      reasonList.List &&
      reasonList.List.find(
        (item: ConfigListDetail) => item.Value == selected.index
      )

    return checked?.Remark === 'OTHER' && remark.trim() === ''
  }

  const checkOtherReasonShowRemark = () => {
    const checked =
      reasonList.List &&
      reasonList.List.find(
        (item: ConfigListDetail) => item.Value == selected.index
      )

    return checked?.Remark === 'OTHER'
  }

  const isSelected = (value: string) => {
    const checked =
      reasonList.List &&
      reasonList.List.find((item: ConfigListDetail) => item.Value === value)
        ?.Value
    return checked === selected.index ? true : false
  }

  function onSelected(it: string) {
    if (it) {
      selected.index = it
      setSelected({ ...selected })
    }
  }

  const contentReject = reasonList.List && [
    reasonList.List.map((item: ConfigListDetail, index: number) => {
      return (
        <RadioBox
          key={index}
          label={item.Description}
          value={item.Value}
          isCheck={isSelected(item.Value)}
          onClick={value => onSelected(item.Value)}
          spaceBtwText={16}
        />
      )
    }),
  ]

  const onClose = () => {
    setShowRejectModal(false)
    setTimeout(() => {
      selected.index = ''
      setSelected({ ...selected })
      setRemark('')
    }, 500)
  }

  return (
    <>
      <Modal
        className="modal-preview"
        centered
        visible={showRejectModal}
        footer={null}
        onCancel={() => onClose()}
        closeIcon={closeIcon}
      >
        <Title>สาเหตุที่ Reject</Title>
        <hr></hr>
        <Padding>
          <Scroll>{contentReject}</Scroll>

          <Row type="flex" justify="start">
            <Col>
              <Header>หมายเหตุ</Header>
            </Col>

            <Col>
              {checkOtherReasonShowRemark() ? (
                <RemarkRequire> * </RemarkRequire>
              ) : (
                <></>
              )}{' '}
            </Col>
          </Row>
          <TextArea
            value={remark}
            onChange={evt => setRemark(evt.target.value)}
            placeholder=""
            maxLength={255}
            style={{ height: '70px' }}
          />
          <MarginTop></MarginTop>

          <Row type="flex" justify="space-between">
            <Col span={11}>
              <SecondaryButton
                text="ยกเลิก"
                disabled={false}
                onClick={() => onClose()}
              />
            </Col>

            <Col span={11}>
              <PrimaryButton
                text="ยืนยัน"
                disabled={
                  taskSelect.length <= 0 ||
                  event.IsLoading ||
                  selected.index == '' ||
                  checkOtherReason()
                }
                onClick={onSubmitReject}
                btnType="Cancel"
              />
            </Col>
          </Row>
        </Padding>
      </Modal>
      <EventModal
        event={[event, wbsEvent]}
        enable={enableModal}
        successCalback={onCallSuccess}
      />
      <StructureListPage
        breadcrumbs={breadcrumbs}
        tableHeader={tableHeader}
        pagination={pageIndex}
        filterHeader={filterContent}
        tableContent={event.IsLoading && !enableModal ? loading : content}
        title={projectName}
        footerPagination={pagination}
        footer={footer}
      />
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    data: selectData(state),
    event: selectEvent(state),
    sorting: selectSorting(state),
    token: serviceTokenSelector(state),
    UserEmail: userEmailSelector(state),
    filter: selectUnitFilter(state),
    wbsEvent: getEvent(state),
    reasonList: selectDataList(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {
  sortingTask,
}

type DispatchProps = typeof mapDispatchToProps

const Btn = styled.div`
  width: 160px;
`

const Title = styled.div`
  color: black;
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 28px;
`

const Padding = styled.div`
  padding: 20px;
`
const MarginTop = styled.div`
  margin-top: 30px;
`

const Scroll = styled.div`
  height: 95px;
  overflow-y: scroll;
`
const Header = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 20px;
  line-height: 1;
  margin-bottom: 6px;
`

const RemarkRequire = styled.div`
  color: #d0494c;
  margin-left: 4px;
`

export default connect(mapStateToProps, mapDispatchToProps)(TodoList)
