import React, { useMemo, useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import { CheckListDetail, QCType } from 'features/checklist/unit/types'

import ContentBox from 'Components/Shared/Card/ContentBox'
import { Row, Col } from 'antd'
import { EditDeleteButton } from 'Components/Shared/Button'
import DeleteCheckListJobModal, {
  DeleteWithCheckHandles,
} from 'Components/Shared/Modal/DeleteWithCheckModal'
import { RowSelectedItem, HoverItem } from 'Components/AdminQC/Components'

interface Props {
  item?: CheckListDetail
  selected: number
  onSelected: (index: number) => void
  qCTypeList: QCType[]
  showModal: (tab: number) => void
  onDelete: (it: QCType) => void
  openModalEdit: (obj: QCType, index: number, tab: number) => void
  disabled: boolean
}

function calculatePoint(item?: CheckListDetail) {
  if (item == null) return []

  let totalScore = 0

  const result: number[] = []
  item.QCTypeList.forEach(qc => {
    totalScore = 0
    qc.JobTypeList.forEach(job => {
      totalScore += job.Score
    })

    result.push(totalScore > 100 ? 100 : Math.round(totalScore))
  })
  return result
}

const QCTypeBox: React.ComponentType<Props> = ({
  item,
  selected,
  onSelected,
  qCTypeList,
  showModal,
  onDelete,
  openModalEdit,
  disabled,
}) => {
  const modalRef = useRef<DeleteWithCheckHandles>(null)
  const pointList = useMemo(() => calculatePoint(item), [item])
  let content: JSX.Element[] = []

  useEffect(() => {
    if (item && item.QCTypeList.length === 0) {
      item.QCTypeList = qCTypeList.map(qc => {
        return {
          ...qc,
          FunctionCheckListStatus: 'Inactive',
          FunctionCheckListID: 0,
          JobTypeList: [],
        }
      })
    }
  }, [qCTypeList, item])

  const showDeleteModal = (it: QCType) => {
    if (modalRef.current) {
      let detailCount = 0
      let subjobCount = 0
      it.JobTypeList.forEach(job => {
        job.SubJobTypeList.forEach(de => {
          detailCount += de.DetailList.length
        })
        subjobCount += job.SubJobTypeList.length
      })

      const menu = [
        `รายการภายใต้ "${it.QCTypeName}" จะถูกลบ`,
        `ลักษณะงานหลัก (${it.JobTypeList.length} รายการ) จะถูกลบ`,
        `ลักษณะงานรอง (${subjobCount} รายการ) จะถูกลบ`,
        `รายละเอียด (${detailCount} รายการ) จะถูกลบ`,
      ]
      const checks = [false, false, false, false]
      const menuTitle = `หมวดงาน: ${it.QCTypeName}`
      modalRef.current.showModal(it, menu, menuTitle, checks)
    }
  }

  const showEditeModal = (it: QCType) => {
    if (item) {
      const resultIndex = item.QCTypeList.findIndex(
        v => v.QCTypeID === it.QCTypeID
      )
      openModalEdit(it, resultIndex, 0)
    }
  }

  if (item) {
    content = item.QCTypeList.map((it: QCType, index: number) => {
      const point = pointList[index] || 0
      return (
        <RowSelectedItem
          key={index}
          title={it.QCTypeName}
          right={
            <HoverItem
              text={
                <span className={point === 100 ? 'active' : ''}>
                  {point} คะแนน
                </span>
              }
              icon={
                <EditDeleteButton
                  showEditeModal={() => showEditeModal(it)}
                  showDeleteModal={() => showDeleteModal(it)}
                  disableDelete={disabled}
                />
              }
            />
          }
          onClick={() => onSelected(index)}
          isSelected={index === selected}
        />
      )
    })
  }

  return (
    <>
      <ContentBox title="หมวดงาน" isEmpty={false}>
        <ListSection>{content}</ListSection>
      </ContentBox>
      <DeleteCheckListJobModal ref={modalRef} onOk={onDelete} />
    </>
  )
}

const ListSection = styled.div`
  margin-top: 16px;
  height: 256px;
  overflow: auto;
  padding: 0 8px;
  padding-bottom: 30px;
`

export default QCTypeBox
