import React from 'react'
import { Row } from 'antd'
import styled from 'styled-components'

interface Props {
  children?: any
  paddingLeft?: number
}

const TableHeader = (props: Props) => {
  const { paddingLeft = 0 } = props
  return (
    <Content style={{ paddingLeft: paddingLeft + 'px' }}>
      <Row>{props.children}</Row>
    </Content>
  )
}

const Content = styled.div`
  margin-bottom: 16px;
`

export default TableHeader
