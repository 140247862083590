export const toText = (text: string = '', limit: number = 100): string => {
  if (text.length < limit) return text

  return `${text.substr(0, limit - 3)}...`
}

export const textToLowerCase = (text: string = ''): string => {
  return text ? text.toLowerCase() : ''
}

export const textToUpperCase = (text: string = ''): string => {
  return text ? text.toUpperCase() : ''
}

export const numberFormat = (number: number = 0, digit: number = 2): string => {
  return number.toFixed(digit)
}

export const numberWithComma = (number: number = 0): string => {
  return number.toLocaleString()
}

export const mergeArray = (datas: any[]) => {
  let result = [] as any[]
  if (datas) {
    datas.forEach(item => {
      result = result.concat(item)
    })
  }

  return result
}
