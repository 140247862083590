import React from 'react'
import { Col } from 'antd'
import styled from 'styled-components'
import { TableRow } from 'Components/Shared/Table'
import { DataItem } from 'features/adminReport/daily/types'
import { StatusTag } from 'Components/AdminQC/Report/Components'
import { mapUnitStatus, unitStatusIsPass } from 'utils/status-map'

type DefaultProps = {
  item: DataItem
  columns: any[]
}

type Props = DefaultProps

const RowItem: React.FC<Props> = ({ item, columns }) => {
  const renderData = (val: any, span: number = 2) => {
    return <Col span={span}>{val}</Col>
  }
  const unitStaus = mapUnitStatus(item.CheckStatus).status
  const isPass = unitStatusIsPass(true, item.CheckStatus)
  const renderCheckList = () => {
    return columns.map(col => {
      if (col.key === 'QCType') {
        const name = item.ResultList.find(
          (res: any) => res.QCTypeName === col.title
        )
        const numOfChecked = name ? name.NumOfChecked : '-'
        return renderData(<TxtReg>{numOfChecked}</TxtReg>, col.span)
      }
    })
  }

  const getSpan = (key: string) => {
    const result = columns.find(col => col.key === key)
    return result ? result.span : 0
  }

  return (
    <RowModel>
      <TableRow
        minHeight={60}
        spaceBetweenRow={8}
        paddingLeft={24}
        cursor="default"
      >
        {renderData(
          <Bold>
            {item.ProjectCode}-{item.ProjectNameTH}
          </Bold>,
          getSpan('ProjectCode')
        )}
        {renderData(<TxtReg>{item.TeamName}</TxtReg>, getSpan('TeamName'))}
        {renderData(<TxtReg>{item.SquadName}</TxtReg>, getSpan('SquadName'))}
        {renderData(
          <StatusTag isPass={isPass} text={unitStaus} />,
          getSpan('CheckStatus')
        )}
        {renderCheckList()}
      </TableRow>
    </RowModel>
  )
}

const RowModel = styled.div`
  cursor: pointer;
`

const Bold: any = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  color: #000;
  font-size: 20px;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  padding-right: 5px;
`

const TxtReg = styled(Bold)`
  font-family: 'DB Helvethaica X 55 Regular';
`

export default RowItem
