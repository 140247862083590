import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, connect } from 'react-redux'
import RootState from 'typings/RootState'
import styled from 'styled-components'
import { Row, Drawer } from 'antd'
import { RouteComponentProps } from 'react-router-dom'
import { BreadcrumbLink } from 'Components/Shared/BreadCrumb/BreadCrumb'
import SubnavBar from 'Components/Shared/SubnavBar'
import { HeaderRow } from 'Components/Shared/Table'
import Pagination from 'Components/Shared/Pagination'
import { DropdownList } from 'Components/Shared/Form'
import LoadingView from 'Components/Shared/LoadingView'

import { changeSelectedProjectAction } from 'features/selectedProject/selectedProject.actions'
import { fetchProjects } from 'features/project/project.api'
import {
  serviceTokenSelector,
  userEmailSelector,
} from 'features/sensitive/sensitive.selector'
import {
  selectedProjectCodeSelector,
  projectsByIdSelector,
  isSubmittingSelector,
} from 'features/project/project.selector'

import {
  GetFloorPlanList,
  getItemForFloorPlan,
} from 'features/admin/floorPlan/api'
import { FloorPlan } from 'features/admin/floorPlan/types'
import {
  setSelectedItem,
  deleteReq,
  reset,
} from 'features/admin/floorPlan/actions'
import Menu from '../../../MenuAdmin'
import FloorPlanDetail from '../Detail'
import RowItem from './RowItem'
import { AdminProject, AlertError } from 'Components/Shared'
import DeleteModal, {
  DeleteModalHandles,
} from 'Components/Shared/Modal/DeleteModal'
import { PaginationType } from 'features/types/common'
import { ProjectListItem } from 'features/project/project.types'
import { PrimaryButton } from 'Components/Shared/Button'

const breadcrumbs: BreadcrumbLink[] = [
  {
    url: '/dashboard',
    title: 'Home',
    icon: '/home.png',
    active: true,
    showTitle: false,
  },
  {
    url: '/floorplan',
    title: 'floorplan',
    active: false,
    showTitle: true,
  },
]

type Props = ReduxProps & DispatchProps & RouteComponentProps

const FloorPlanListPage: React.ComponentType<Props> = ({
  token,
  UserEmail,
  FloorPlanList,
  event,
  selectedItem,
  ProjectCode,
  projectsById,
  isProjectLoading,
}) => {
  const [enableModal, setEnableModal] = useState(false)
  const modalRef = useRef<DeleteModalHandles>(null)
  const [pagination, setPagination] = useState<PaginationType>({
    Page: 1,
    RowPerPage: 10,
  })

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchProjects({ email: UserEmail, token }))
  }, [UserEmail, token])

  useEffect(() => {
    setEnableModal(true)
    if (ProjectCode) {
      dispatch(getItemForFloorPlan(ProjectCode))
    }
  }, [ProjectCode])

  useEffect(() => {
    if (ProjectCode) {
      dispatch(
        GetFloorPlanList(
          {
            Page: pagination.Page,
            RowPerPage: pagination.RowPerPage,
          },
          ProjectCode
        )
      )
    }
  }, [ProjectCode, pagination])

  const onCloseDrawer = () => {
    dispatch(setSelectedItem(undefined))
  }

  const onOpenDrawer = (item: FloorPlan) => {
    dispatch(setSelectedItem(item))
  }

  const onProjectCode = (code: string) => {
    dispatch(changeSelectedProjectAction(code))
  }

  const onCreate = () => {
    dispatch(reset())
  }

  const showDeleteModal = (item: FloorPlan) => {
    if (modalRef.current) {
      modalRef.current.showModal('คุณยืนยันต้องการลบ floorplan นี้', 0, item)
    }
  }

  const onDelete = (_: number, item: FloorPlan) => {
    setEnableModal(true)
    dispatch(
      deleteReq.request({
        FloorPlanID: item.FloorPlanID,
        ProjectCode,
        page: {
          Page: pagination.Page,
          RowPerPage: pagination.RowPerPage,
        },
      })
    )
  }

  const onSaveDetail = () => {
    setEnableModal(true)
  }

  const content = FloorPlanList.List.map((item: FloorPlan, index: number) => {
    return (
      <RowItem
        key={index}
        item={item}
        selected={
          selectedItem ? item.FloorPlanID === selectedItem.FloorPlanID : false
        }
        onClick={onOpenDrawer}
        onDelete={showDeleteModal}
      />
    )
  })

  const header = (
    <Row type="flex" justify="space-between" align="middle">
      <Header>Floorplan ส่วนกลาง</Header>
      <MainSection>
        <div style={{ width: 360 }}>
          <AdminProject
            projectList={projectsById}
            onSelectProject={onProjectCode}
            activeProject={ProjectCode}
          />
        </div>
        <ButtonSection>
          <PrimaryButton disabled={true} text="Import Floorplan" />
        </ButtonSection>
        <ButtonSection>
          <PrimaryButton
            disabled={ProjectCode === ''}
            text="เพิ่ม Floorplan"
            onClick={onCreate}
          />
        </ButtonSection>
      </MainSection>
    </Row>
  )

  return (
    <>
      <AlertError
        text={event.SubmitError}
        isShow={!!event.SubmitError && enableModal}
      />
      <LoadingView isShow={isProjectLoading || event.IsLoading} />
      <SubnavBar breadcrumbs={breadcrumbs} hideSearch={true} />
      <Menu selected={'floorplan'}>
        <MainPage>
          {header}
          <HeaderRow onSort={() => {}} sorting={[]} items={columnsName} />
          {content}
          <Pagination
            currentPage={pagination.Page}
            totalRows={FloorPlanList.Total}
            perPage={pagination.RowPerPage}
            onChangeNumber={(page: number) => {
              pagination.RowPerPage = page
              setPagination({ ...pagination })
            }}
            onClickPage={(page: number) => {
              pagination.Page = page
              setPagination({ ...pagination })
            }}
          />
        </MainPage>
        <Drawer
          title={null}
          className="drawer-project"
          closable={false}
          width={448}
          visible={selectedItem !== undefined}
          bodyStyle={{ padding: 0 }}
        >
          {selectedItem && (
            <FloorPlanDetail
              item={selectedItem}
              Token={token}
              UserEmail={UserEmail}
              ProjectCode={ProjectCode}
              onClose={onCloseDrawer}
              onSaveDetail={onSaveDetail}
              pagination={pagination}
            />
          )}
        </Drawer>
      </Menu>
      <DeleteModal ref={modalRef} onOk={onDelete} />
    </>
  )
}

const MainPage = styled.div`
  padding: 40px 45px;
  width: 100%;
`

const MainSection = styled.div`
  height: 50px;
  margin-bottom: 35px;
  display: flex;
`

const Header = styled.div`
  margin-bottom: 27px;
  font-size: 28px;
  line-height: 1.07;
  font-family: 'DB Helvethaica X 75 Bd';
  color: #000;
`

const ButtonSection = styled.div`
  float: left;
  margin-left: 24px;
  width: 160px;
`

const columnsName = [
  {
    title: 'ชั้น',
    span: 12,
    key: 'FloorPlanName',
    showSort: false,
  },
  {
    title: 'จำนวนรายการที่ผูก',
    span: 4,
    key: 'UsedCounter',
    showSort: false,
  },
  {
    title: 'สถานะ',
    span: 4,
    key: 'Status',
    showSort: false,
  },
  {
    title: 'Action',
    span: 4,
    key: 'Action',
    showSort: false,
  },
]

const mapStateToProps = (state: RootState) => {
  return {
    FloorPlanList: state.adminFloorPlan.FloorPlanList,
    selectedItem: state.adminFloorPlan.FloorPlanSelected,
    event: state.adminFloorPlan.Event,
    token: serviceTokenSelector(state),
    UserEmail: userEmailSelector(state),
    ProjectCode: selectedProjectCodeSelector(state),
    projectsById: projectsByIdSelector(state),
    isProjectLoading: isSubmittingSelector(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>
const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(FloorPlanListPage)
