import React from 'react'
import { BreadcrumbLink } from '../../Shared/BreadCrumb/BreadCrumb'
import SubnavBar from '../../Shared/SubnavBar'

type Props = {
  name: string
}

const Breadcrumbs: React.ComponentType<Props> = props => {
  const { name } = props
  const breadcrumbs: BreadcrumbLink[] = [
    {
      url: '/qcdashboard',
      title: 'Home',
      icon: '/home.png',
      active: true,
      showTitle: false,
    },
    {
      url: '/qcdashboard',
      title: 'บอร์ด',
      active: true,
      showTitle: true,
    },
    {
      url: '/',
      title: name,
      active: false,
      showTitle: false,
    },
  ]

  return <SubnavBar breadcrumbs={breadcrumbs} hideSearch={true} />
}

export default Breadcrumbs
