import React, { useState, useEffect } from 'react'

import { MainconCheckList } from 'features/types/checklist'

import FormChecklist from './FormChecklist'
import TaskChecklist from './TaskChecklist'
import WarningListModal from './WarningListModal'
import styled from 'styled-components'
import { Col } from 'antd'
import { CheckListHeader } from 'Components/Shared'
import { cloneDeep } from 'lodash'

type Props = {
  checkListData: MainconCheckList
  hideStatus?: boolean
  showWarningList?: boolean
  onUpdateCheckList?: (val: MainconCheckList) => void
  showHeader?: boolean
}

const Checklist: React.FC<Props> = (props: Props) => {
  const {
    checkListData,
    hideStatus = false,
    onUpdateCheckList,
    showWarningList = false,
    showHeader = true,
  } = props
  const [listFilter, setListFilter] = useState([] as any[])
  const [showModalWarning, setShowModalWarning] = useState(false)
  const list = checkListData.InspectionList || []

  useEffect(() => {
    setListFilter(cloneDeep(list))
  }, [list])

  const renderContent = () => {
    return onUpdateCheckList ? (
      <FormChecklist
        inspectionList={listFilter}
        onUpdateCheckList={val => {
          checkListData.InspectionList = val
          onUpdateCheckList(checkListData)
        }}
      />
    ) : (
      <TaskChecklist inspectionList={listFilter} hideStatus={hideStatus} />
    )
  }

  const warningComponent = showWarningList ? (
    <Col>
      <TextWarning onClick={() => setShowModalWarning(true)}>
        ข้อควรระวัง
      </TextWarning>
    </Col>
  ) : null

  const hasCheckList =
    checkListData &&
    checkListData.InspectionList &&
    checkListData.InspectionList.length > 0

  return (
    <ChecklistContent>
      {hasCheckList && (
        <>
          {showHeader && (
            <HeaderMr>
              <CheckListHeader
                list={list}
                onChange={newList => {
                  setListFilter([...newList])
                }}
                seachable={true}
                warningComponent={warningComponent}
                updateMode={onUpdateCheckList ? true : false}
              />
            </HeaderMr>
          )}

          {renderContent()}
          <WarningListModal
            isShow={showModalWarning}
            onCloseModal={() => setShowModalWarning(false)}
            warningList={checkListData.WarningList}
          />
        </>
      )}
    </ChecklistContent>
  )
}

const ChecklistContent = styled.div``

const HeaderMr = styled.div`
  margin-top: 40px;
`

const TextWarning = styled.span`
  font-size: 20px;
  color: #003168;
  text-decoration: underline;
  cursor: pointer;
`

export default Checklist
