import React from 'react'
import { Col } from 'antd'
import styled from 'styled-components'
import { TableRow } from 'Components/Shared/Table'
import { ReportLink as Link } from 'Components/AdminQC/Report/Components'
import { DataItem } from 'features/adminReport/certificate/types'
import { toDateText, toMomentDateTime } from 'utils/datetime'

type DefaultProps = {
  item: DataItem
}

type Props = DefaultProps

const RowItem: React.FC<Props> = ({ item }) => {
  const path = `/report/cert/${item.ProjectCode}/${item.MCVendorCode}`
  const isEqual = item.NumOfCheckUnit === item.TotalUnit
  const numCheck = `${item.NumOfCheckUnit}/${item.TotalUnit}`
  return (
    <RowModel>
      <Link to={path}>
        <TableRow minHeight={60} spaceBetweenRow={8} paddingLeft={24}>
          <Col span={5}>
            <Bold>
              {item.ProjectCode}-{item.ProjectNameTH}
            </Bold>
          </Col>
          <Col span={5}>
            <TxtReg>{item.VendorName}</TxtReg>
          </Col>
          <Col span={5}>
            <TxtReg>{item.MCVendorCode}</TxtReg>
          </Col>
          <Col span={5}>
            {isEqual ? (
              <TxtReg>{numCheck}</TxtReg>
            ) : (
              <TxtRed>{numCheck}</TxtRed>
            )}
          </Col>
          <Col span={4}>
            <TxtReg>{toDateText(toMomentDateTime(item.UpdatedDate))}</TxtReg>
          </Col>
        </TableRow>
      </Link>
    </RowModel>
  )
}

const RowModel = styled.div`
  cursor: pointer;
`

const Bold: any = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  color: #000;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  padding-right: 5px;
`

const TxtReg: any = styled(Bold)`
  font-family: 'DB Helvethaica X 55 Regular';
`

const TxtRed = styled(TxtReg)`
  color: #d0494c;
`

export default RowItem
