import withAPIAction from 'utils/withAPIAction'
import {
  saveSignatureAction,
  saveZoneSignatureAction,
  saveCommissionSignatureAction,
  saveConstructPlanSignatureAction,
  saveAuditPlanSignatureAction,
  saveConstructRoadSignatureAction,
  saveAuditRoadSignatureAction,
  enableFunctionAction,
} from './actions'

import {
  SignQCReportReq,
  SignZoneReportReq,
  SignCommissioningReq,
  EnableFunctionReq,
  SignConstructPlanReq,
  SignAuditPlanReq,
  SignConstructRoadReq,
  SignAuditRoadReq,
} from './types'
import { verifyReq } from 'utils/verify'

export const saveSignature = (token: string, data: SignQCReportReq) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<SignQCReportReq>({
    method: 'POST',
    path: 'SignReportByQC',
    data,
    token: `Bearer ${token}`,
  })(saveSignatureAction)
}

export const enableFunction = (token: string, data: EnableFunctionReq) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<EnableFunctionReq>({
    method: 'POST',
    path: 'EnableFunctionById',
    data,
    token: `Bearer ${token}`,
  })(enableFunctionAction)
}

export const saveZoneSignature = (token: string, data: SignZoneReportReq) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<SignZoneReportReq>({
    method: 'POST',
    path: 'SignZoneReportByQC',
    data,
    token: `Bearer ${token}`,
  })(saveZoneSignatureAction)
}

export const saveCommissionSignature = (
  token: string,
  data: SignCommissioningReq
) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<SignCommissioningReq>({
    method: 'POST',
    path: 'SignCommissioningReportByQC',
    data,
    token: `Bearer ${token}`,
  })(saveCommissionSignatureAction)
}

export const saveConstructPlanSignature = (
  token: string,
  data: SignConstructPlanReq
) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<SignConstructPlanReq>({
    method: 'POST',
    path: 'SignConstructPlanReportByQC',
    data,
    token: `Bearer ${token}`,
  })(saveConstructPlanSignatureAction)
}

export const saveAuditPlanSignature = (
  token: string,
  data: SignAuditPlanReq
) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<SignAuditPlanReq>({
    method: 'POST',
    path: 'SignAuditPlanReportByQC',
    data,
    token: `Bearer ${token}`,
  })(saveAuditPlanSignatureAction)
}

export const saveConstructRoadSignature = (
  token: string,
  data: SignConstructRoadReq
) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<SignConstructRoadReq>({
    method: 'POST',
    path: 'SignConstructRoadReportByQC',
    data,
    token: `Bearer ${token}`,
  })(saveConstructRoadSignatureAction)
}

export const saveAuditRoadSignature = (
  token: string,
  data: SignAuditRoadReq
) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<SignAuditRoadReq>({
    method: 'POST',
    path: 'SignAuditRoadReportByQC',
    data,
    token: `Bearer ${token}`,
  })(saveAuditRoadSignatureAction)
}
