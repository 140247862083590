import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { connect, useDispatch } from 'react-redux'
import SubnavBar from 'Components/Shared/SubnavBar'
import { Row, Col } from 'antd'
import { RouteComponentProps, Link } from 'react-router-dom'
import RootState from 'typings/RootState'
import { selectedProjectCodeSelector } from 'features/project/project.selector'
import {
  userEmailSelector,
  serviceTokenSelector,
} from 'features/sensitive/sensitive.selector'
import {
  selectConstructRoad,
  selectEvent,
} from 'features/qc/dashboard/selector'
import {
  getConstructRoadForQC,
  SendConstructRoadReportByQC,
  ReturnConstructRoadyQC,
} from 'features/qc/dashboard/api'
import {
  TypeOfTask,
  TodoActivity,
  GetConstructRoadReq,
  ConstructRoadInProActivity,
  ConstructRoadList,
  SendRoadConstructReportReq,
  ReturnRoadConstructReq,
} from 'features/qc/dashboard/types'
import { translate } from 'i18n'
import TKeys from 'i18n/translationKey'
import { NoCard, EventModal } from 'Components/Shared'
import CommonAreaTab from 'Components/Shared/Tab/CommonAreaTab'
import LinkButton from 'Components/Shared/LinkButton'
import Loading from 'Components/Shared/Loading'
import { PrimaryButton } from 'Components/Shared/Button'
import { BreadcrumbLink } from 'Components/Shared/BreadCrumb/BreadCrumb'

import InprogressCard from './InprogressCard'
import Menu from '../../../Menu'
import ColumnHeader from '../../ColumnHeader'
import TodoCard from '../../TodoCard'
import MainPage from '../../MainPage'
import PageFooter from '../../PageFooter'
import RoadTab from 'Components/Shared/Tab/RoadTab'

const breadcrumbs: BreadcrumbLink[] = [
  {
    url: '/construct-road',
    title: 'Home',
    icon: '/home.png',
    active: true,
    showTitle: false,
  },
  {
    url: '/construct-road',
    title: 'งานทั้งหมด',
    active: false,
    showTitle: false,
  },
]

type Props = ReduxProps & DispatchProps & RouteComponentProps

const CommisionningDashboard: React.ComponentType<Props> = props => {
  const { data, event, token, ProjectCode, UserEmail } = props
  const [sendReport, setSendReport] = useState(false)
  const [projectList, setProjectList] = useState([] as any[])
  const [enableModal, setEnableModal] = useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    setEnableModal(false)
  }, [data])

  useEffect(() => {
    getDashboardData()
  }, [ProjectCode, dispatch])

  const getDashboardData = () => {
    const data: GetConstructRoadReq = {
      UserEmail: UserEmail,
      ProjectCode: [''],
      CheckNumber: [],
    }
    dispatch(getConstructRoadForQC(token, data))
  }

  const onSumitSendReport = () => {
    setEnableModal(true)
    const req: SendRoadConstructReportReq = {
      UserEmail: UserEmail,
      ProjectList: projectList,
    }

    dispatch(SendConstructRoadReportByQC(token, req))
  }

  const onReturnTask = (projectCode: string, data: ConstructRoadList) => {
    setEnableModal(true)
    const req: ReturnRoadConstructReq = {
      UserEmail: UserEmail,
      ProjectCode: projectCode,
      Unit: data.Unit,
      RequestID: data.RequestID,
      CheckNumber: data.CheckNumber,
      QCTypeID: data.QCTypeID,
    }

    dispatch(ReturnConstructRoadyQC(token, req))
  }

  const onSendReport = () => {
    setProjectList([])
    setSendReport(true)
  }

  const onSuccess = () => {
    setEnableModal(false)
    onCancleSendReport()
    getDashboardData()
  }

  const onError = () => {
    setEnableModal(false)
    setProjectList([])
  }

  const onCancleSendReport = () => {
    setSendReport(false)
  }

  const isSelected = (tasks: ConstructRoadList[], data: ConstructRoadList) => {
    const result = tasks.find(
      task =>
        task.RequestID === data.RequestID &&
        task.CheckNumber === data.CheckNumber &&
        task.Unit === data.Unit &&
        task.QCTypeID === data.QCTypeID
    )
    return result ? true : false
  }

  const remove = (tasks: ConstructRoadList[], data: ConstructRoadList) => {
    return tasks.filter(
      task =>
        task.RequestID !== data.RequestID ||
        task.CheckNumber !== data.CheckNumber ||
        task.Unit !== data.Unit ||
        task.QCTypeID !== data.QCTypeID
    )
  }

  const findProject = (projectList: any[], projectCode: string) => {
    const result = projectList.find(
      project => project.ProjectCode === projectCode
    )
    return result ? true : false
  }

  const pushList = (data: ConstructRoadList) => {
    return {
      RequestID: data.RequestID,
      CheckNumber: data.CheckNumber,
      Unit: data.Unit,
      QCTypeID: data.QCTypeID,
    }
  }

  const onSelect = (projectCode: string, data: ConstructRoadList) => {
    const isHasProject = findProject(projectList, projectCode)
    if (isHasProject) {
      const project = projectList.map(project => {
        if (project.ProjectCode === projectCode) {
          const selected = isSelected(project.TaskList, data)
          if (selected) {
            project.TaskList = remove(project.TaskList, data)
          } else {
            project.TaskList.push(pushList(data))
          }
        }
        return project
      })
      setProjectList([...project])
    } else {
      let newProject = projectList
      newProject.push({
        ProjectCode: projectCode,
        TaskList: [pushList(data)],
      })
      setProjectList([...newProject])
    }
  }

  const onSelectAll = () => {
    let newProject = [] as any
    data.InProgress.forEach((project: any) => {
      let tasks = [] as any
      project.UnitList &&
        project.UnitList.forEach((data: any) => {
          if (data.HasSelectPin && data.HasSign) {
            tasks.push(pushList(data))
          }
        })
      if (tasks && tasks.length > 0) {
        newProject.push({
          ProjectCode: project.ProjectCode,
          TaskList: tasks,
        })
      }
    })

    setProjectList([...newProject])
  }

  const renderTodoCard = (task: any[], type: string) => {
    let card = [] as any
    task.map((activity, index) => {
      card.push(
        <Link to={`/construct-road/todo/${activity.ProjectCode}`}>
          <TodoCard key={index} task={activity} type={type} />
        </Link>
      )
    })

    return card
  }

  const renderInprogressCard = (
    task: ConstructRoadInProActivity[],
    type: string
  ) => {
    return task.map((activity: ConstructRoadInProActivity, index) => {
      return (
        <InprogressCard
          key={index}
          task={activity}
          type={type}
          isSendReport={sendReport}
          onClickReturn={data => onReturnTask(activity.ProjectCode, data)}
          onSelectLists={onSelect}
          projectList={projectList}
        />
      )
    })
  }

  const loading = (
    <div style={{ textAlign: 'center' }}>
      <Loading />
    </div>
  )

  const sumTodoTask = (task: TodoActivity[]) => {
    let total = 0
    task.forEach(item => {
      if (item.NumberOfTask >= 0) {
        total += item.NumberOfTask
      }
    })
    return total
  }

  const renderTodoTask = (task: TodoActivity[]) => {
    const card =
      task.length > 0 ? renderTodoCard(task, TypeOfTask.TODO) : <NoCard />
    const content = event.IsLoading ? loading : card
    const total = sumTodoTask(task)
    return (
      <Col span={8}>
        <FullColumn>
          <ColumnHeader heading={`รายการขอตรวจ (${total})`} />
          {content}
        </FullColumn>
      </Col>
    )
  }

  const sumInprogressTask = (task: ConstructRoadInProActivity[]) => {
    let total = 0
    task.forEach((activity: ConstructRoadInProActivity) => {
      total += activity.UnitList.length
    })
    return total
  }

  const renderInprogressTask = (task: ConstructRoadInProActivity[]) => {
    const card =
      task.length > 0 ? (
        renderInprogressCard(task, TypeOfTask.INPROGRESS)
      ) : (
        <NoCard text="ไม่มีรายการตรวจ กรุณาเลือก “โครงการขอตรวจ”" />
      )
    const content = event.IsLoading ? loading : card
    const total = sumInprogressTask(task)
    return (
      <Col span={16}>
        <ColumnHeader heading={`กำลังดำเนินการ (${total})`} />
        {content}
      </Col>
    )
  }

  const disableSendReport = !data.InProgress || data.InProgress.length <= 0

  const header = (
    <Header>
      <Row type="flex" justify="end" align="middle">
        <Col>
          <Button>
            {!sendReport ? (
              <PrimaryButton
                text="ส่งรายงาน"
                onClick={() => onSendReport()}
                disabled={disableSendReport}
              />
            ) : (
              <PrimaryButton
                text="ยกเลิก"
                onClick={() => onCancleSendReport()}
                btnType="Cancel"
              />
            )}
          </Button>
        </Col>
      </Row>
    </Header>
  )

  const countSelected = () => {
    let total = 0
    projectList.forEach(project => {
      total += project.TaskList.length
    })
    return total
  }

  const selected = countSelected()
  return (
    <>
      <Menu selected="road" />
      <SubnavBar breadcrumbs={breadcrumbs} hideSearch={true} />
      <RoadTab selected="ConstructRoad" />
      <EventModal
        event={[event]}
        enable={enableModal}
        successCalback={() => onSuccess()}
        errorCalback={() => onError()}
      />
      {header}
      <MainPage hasFooter={sendReport}>
        <Row type="flex">
          {renderTodoTask(data.ToDo)}
          {renderInprogressTask(data.InProgress)}
        </Row>
      </MainPage>
      {sendReport && (
        <PageFooter
          total={selected}
          onSubmit={onSumitSendReport}
          label="รายการในการส่งรายงาน"
          onSelectAll={onSelectAll}
          disabled={selected <= 0}
        />
      )}
    </>
  )
}

const FullColumn = styled.div`
  padding-right: 20px;
`

const Button = styled.div`
  width: 200px;
  float: left;
  margin-left: 24px;
`

const Header = styled.div`
  padding: 0 120px;
  background-color: #f5f6fa;
  margin: 40px 0;
`

const mapStateToProps = (state: RootState) => {
  return {
    data: selectConstructRoad(state),
    event: selectEvent(state),
    token: serviceTokenSelector(state),
    ProjectCode: selectedProjectCodeSelector(state),
    UserEmail: userEmailSelector(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CommisionningDashboard)
