import React, { useState, useEffect } from 'react'
import { Col, Row } from 'antd'
import styled from 'styled-components'
import { Calendar } from 'Components/Shared'
import { cloneDeep } from 'lodash'
import SignatureBox from './SignatureBox'
import { numberWithComma } from 'utils/format'
import moment from 'moment'
import { toDateText } from 'utils/datetime'
import { DataDetail, User } from 'features/adminReport/certificate/types'

type Props = {
  data: DataDetail
  onUpdateSign: (index: number, data: User) => void
  sign: User[]
  date: any
  onUpdateDate: (date: number) => void
}

const CertificateForm: React.ComponentType<Props> = props => {
  const { data, onUpdateSign, sign, date, onUpdateDate } = props
  const companyName = 'บจม.แสนสิริ'
  const vendorName = data.VendorName
  const ProjectNameTH = data.ProjectNameTH
  const ProjectTypeName = 'คอนโดมิเนียม'
  const Date = moment()
    .startOf('day')
    .format('DD/MM/YYYY')

  const onUpdateStartDate = (val: any) => {
    date.startDate = val || ''
    onUpdateDate({ ...date })
  }

  const onUpdateEndDate = (val: any) => {
    date.endDate = val || ''
    onUpdateDate({ ...date })
  }

  const replaceText = (text: string): string => {
    return text
      .replace('{{company}}', companyName)
      .replace('{{vendor}}', vendorName)
      .replace('{{projectType}}', ProjectTypeName)
  }

  const textConfig = {
    FirstCheck: 'รายการ Defect จากการตรวจ ครั้งที่ 1 ทั้งหมด (QC1)',
    QCCheckPass: replaceText('รายการ Defect ส่วนของ {{company}} จัดหามา'),
    QCCheckFail: 'รายการ Defect ที่ไม่ผ่านการตรวจครั้งที่ 2',
    QCCheckAdd: 'รายการ Defect ที่ขอเพิ่มจากการตรวจครั้งที่ 2',
    QCSecondAdd:
      'รายการ Defect คงเหลือจากการตรวจครั้งที่ 2 และ Defect ที่ขอเพิ่ม',
    PMR: {
      Title:
        'รายการ Defect คงเหลือจากการตรวจครั้งที่ 2 และ Defect เพิ่มจากการตรวจครั้งที่ 2 จำนวน Defect ทั้งหมด',
      Desc:
        'โดยฝ่าย PJ เป็นผู้ติดตามการแก้ไขและตรวจสอบร่วมกับฝ่าย PMR เพื่อให้แล้วเสร็จตามเงื่อนไขตรวจสอบของฝ่าย PJ-PMR สรุปผลการตรวจสอบโดยแยกรายละเอียด ดังนี้',
      NumOfFixedDefect: replaceText(
        'รายการ Defect ของ {{vendor}} ได้ดำเนินแก้ไขเสร็จแล้ว'
      ),
      NumOfFixLaterDefect: replaceText(
        'รายการ Defect ของ {{vendor}} ขอแก้ไขภายหลัง'
      ),
      NumOfNoFixConditionDefect: replaceText(
        'รายการ Defect ของ {{vendor}} ขอแก้ไขอย่างมีเงื่อนไข'
      ),
      NumOfSansiriDefect: replaceText(
        'รายการ Defect ส่วนของ {{company}} จัดหามา'
      ),
      NumOfPMRDefect: 'รายการ Defect ของ PMR เพิ่ม',
    },
    NumOfDefect: replaceText('รายการ Defect ของ {{vendor}}'),
    NumOfSansiriDefect: replaceText('รายการ Defect ของ {{company}} จัดหามา'),
    VendorType: replaceText(
      'เป็นผู้รับเหมาที่ทำการก่อสร้างงานอาคาร{{projectType}}พักอาศัย'
    ),
    Seven:
      'รายการ Defect ที่ไม่แล้วเสร็จข้อ 6.2 โดยฝ่าย PH จะเป็นผู้ติดตามการดำเนินการแก้ไขร่วมกับผู้รับเหมา จนแล้วเสร็จตามเงื่อนไข ตรวจสอบของฝ่าย PJ-PMR',
    Eight: replaceText(
      'รายการ Defect ที่ดำเนินการแก้ไขแล้วเสร็จและตรวจสอบผ่านโดยฝ่าย PMR และ Defect ที่ขอไม่แก้อย่างมีเงื่อนไขหากกรรมการนิติบุคคลมีการแจ้งให้แก้ไข Defect ในภายหลังทาง{{vendor}} ยินดีเข้าดำเนินการแก้ไขตามรายการแจ้งและรับผิดชอบค่าใช้จ่ายทั้งหมด'
    ),
    Finally: replaceText(
      'ความเห็นของฝ่ายควบคุมคุณภาพ {{company}} ขอส่งข้อมูลให้ฝ่าย PJ ดำเนินการในขั้นตอนต่อไป'
    ),
    Date: 'ในส่วนของรายการตามข้อ 1-5 ทั้งหมดเริ่มประกันผลงานตั้งแต่วันที่',
  }

  const projectDesc = (
    <>
      <Row type="flex">
        <Col span={2}>โครงการ</Col>
        <Col span={8}>{ProjectNameTH}</Col>
        <Col span={10} offset={2}>
          {Date}
        </Col>
      </Row>
      <Row type="flex">
        <Col span={2}>บริษัท</Col>
        <Col span={8}>{vendorName}</Col>
        <Col span={10} offset={2}>
          {textConfig.VendorType}
        </Col>
      </Row>
      <Row type="flex">
        <Col span={24}>
          ส่งตรวจสอบคุณภาพงานส่วนกลาง ตั้งแต่วันที่ {data.QCStartDate || '-'}
          ถึงวันที่ {data.QCEndDate || '-'}
          ซึ่งปัจจุบันฝ่ายควบคุมคุณภาพได้ดำเนินการตรวจสอบคุณภาพงานส่วนกลางแล้วเสร็จ
          จึงขอสรุปผลการตรวจรับมอบงานซึ่งมีรายละเอียดดังนี้
        </Col>
      </Row>
    </>
  )

  const defectHeader = (
    <Row type="flex">
      <Col span={7} offset={4}>
        รายละเอียด
      </Col>
      <Col span={3}>จำนวน S&A (ขอ)</Col>
      <Col span={3}>จำนวน M&E (ขอ)</Col>
      <Col span={2}>จำนวนรวม</Col>
      <Col span={4} offset={1}>
        หมายเหตุ
      </Col>
    </Row>
  )

  const renderDefectItem = (
    point: string,
    title: string,
    numOfDefect: number,
    numOfSansiriDefect: number,
    remark: string = ''
  ) => {
    const total = numOfDefect + numOfSansiriDefect
    return (
      <>
        <Row type="flex" align="middle">
          <Col span={11}>
            {point} {title}
          </Col>
          <Col span={8}>
            <DefectBox>{numberWithComma(total)}</DefectBox>
          </Col>
          <Col span={5}>
            <Text>{remark}</Text>
          </Col>
        </Row>
        <Row type="flex" align="middle">
          <Col span={10} offset={1}>
            {point}.1 {textConfig.NumOfDefect}
          </Col>
          <Col span={8}>
            <Defectchoice>{numberWithComma(numOfDefect)}</Defectchoice>
          </Col>
        </Row>
        <Row type="flex" align="middle">
          <Col span={10} offset={1}>
            {point}.2 {textConfig.NumOfSansiriDefect}
          </Col>
          <Col span={8}>
            <Defectchoice>{numberWithComma(numOfSansiriDefect)}</Defectchoice>
          </Col>
        </Row>
      </>
    )
  }

  const defectItem = (
    <>
      {renderDefectItem(
        '1',
        textConfig.FirstCheck,
        data.FirstCheck.NumOfDefect,
        data.FirstCheck.NumOfSansiriDefect,
        '(CM,QC,PMR)'
      )}
      {renderDefectItem(
        '2',
        textConfig.QCCheckPass,
        data.QCCheckPass.NumOfDefect,
        data.QCCheckPass.NumOfSansiriDefect,
        '(Defect ที่ผ่านการตรวจ QC 2)'
      )}
      {renderDefectItem(
        '3',
        textConfig.QCCheckFail,
        data.QCCheckFail.NumOfDefect,
        data.QCCheckFail.NumOfSansiriDefect
      )}
      {renderDefectItem(
        '4',
        textConfig.QCCheckAdd,
        data.QCCheckAdd.NumOfDefect,
        data.QCCheckAdd.NumOfSansiriDefect
      )}
      {renderDefectItem(
        '5',
        textConfig.QCSecondAdd,
        data.QCCheckFail.NumOfDefect + data.QCCheckAdd.NumOfDefect,
        data.QCCheckFail.NumOfSansiriDefect + data.QCCheckAdd.NumOfSansiriDefect
      )}
    </>
  )

  const renderPMRdefect = (
    point: string,
    title: string,
    defect: number,
    remark: string = ''
  ) => {
    return (
      <Row type="flex" align="top">
        <Col span={10} offset={1}>
          {point} {title}
        </Col>
        <Col span={3}>จำนวน</Col>
        <Col span={2} style={{ textAlign: 'right' }}>
          {numberWithComma(defect)}
        </Col>
        <Col span={1}>
          <Text>ข้อ</Text>
        </Col>
        <Col span={7}>
          <Text>{remark}</Text>
        </Col>
      </Row>
    )
  }

  const {
    NumOfFixLaterDefect,
    NumOfFixedDefect,
    NumOfNoFixConditionDefect,
    NumOfPMRDefect,
    NumOfPMRSansiriDefect,
  } = data.PMR
  const totalPMR =
    NumOfFixLaterDefect +
      NumOfFixedDefect +
      NumOfNoFixConditionDefect +
      NumOfPMRDefect +
      NumOfPMRSansiriDefect || 0
  const pmrDefect = (
    <>
      <Row type="flex" align="middle">
        <Col span={11}>6 {textConfig.PMR.Title}</Col>
        <Col span={8}>
          <DefectBox>{numberWithComma(totalPMR)}</DefectBox>
        </Col>
        <Col span={5}>
          <Text>ข้อ</Text>
        </Col>
      </Row>
      <Row type="flex" align="middle">
        <Col span={24}>{textConfig.PMR.Desc}</Col>
      </Row>
      {renderPMRdefect(
        '6.1',
        textConfig.PMR.NumOfFixedDefect,
        NumOfFixedDefect,
        '(ตรวจสอบผ่านอย่างมีเงื่อนไขโดยฝ่าย PJ-PMR)'
      )}
      {renderPMRdefect(
        '6.2',
        textConfig.PMR.NumOfFixLaterDefect,
        NumOfFixLaterDefect,
        '(ตรวจสอบผ่านอย่างมีเงื่อนไขโดยฝ่าย PJ-PMR)'
      )}
      {renderPMRdefect(
        '6.3',
        textConfig.PMR.NumOfNoFixConditionDefect,
        NumOfNoFixConditionDefect
      )}
      {renderPMRdefect(
        '6.4',
        textConfig.PMR.NumOfSansiriDefect,
        NumOfPMRSansiriDefect
      )}
      {renderPMRdefect('6.5', textConfig.PMR.NumOfPMRDefect, NumOfPMRDefect)}
    </>
  )

  const lastRemark = (
    <Row type="flex" align="middle">
      <Col span={24}>7 {textConfig.Seven}</Col>
      <Col span={24}>8 {textConfig.Eight}</Col>
      <Col span={23} offset={1}>
        {textConfig.Finally}
      </Col>
    </Row>
  )

  const getDate = (val: string) => {
    return val !== '' ? moment(val, 'DDMMYYYY') : undefined
  }

  const renderDateBox = (date: any, onChange: (date: any) => void) => {
    return (
      <>
        <Col span={5} className="form">
          <CalendarBox>
            <Calendar
              value={getDate(date)}
              disabled={false}
              onSelectedDate={onChange}
              label={''}
              datePickerPlaceholder="กรุณาใส่ วว/ดด/ปปปป"
            />
          </CalendarBox>
        </Col>
        <Col className="display">
          <CalendarBox>{toDateText(date)}</CalendarBox>
        </Col>
      </>
    )
  }

  const dateForm = (
    <Row type="flex" align="middle">
      <Col>{textConfig.Date}</Col>
      {renderDateBox(date.startDate, onUpdateStartDate)}
      <Col>ถึงวันที่</Col>
      {renderDateBox(date.endDate, onUpdateEndDate)}
    </Row>
  )

  const signBox = (user: User) => {
    return (
      <Col span={12} key={user.Seq}>
        <SignatureBox
          data={user}
          onUpdate={(data: User) => onUpdateSign(user.Seq, data)}
        />
      </Col>
    )
  }

  const renderSignForm = () => {
    let forms = []
    forms = sign.map(item => {
      return signBox(item)
    })
    return (
      <Row type="flex" align="middle">
        {forms}
      </Row>
    )
  }

  return (
    <Box>
      <Header>
        ใบรับรองคุณภาพงานส่วนกลาง <br />
        Quality Certificate
      </Header>
      <DetailContent>
        {projectDesc}
        {defectHeader}
        {defectItem}
        {pmrDefect}
        {lastRemark}
        {dateForm}
        {renderSignForm()}
      </DetailContent>
      <Header>บริษัท แสนสิริ จำกัด (มหาชน)</Header>
    </Box>
  )
}

const Box = styled.div`
  font-family: 'DB Komol Regular';
  .display {
    display: none;
  }
  .form {
    display: block;
  }

  @media print {
    line-height: 1.2;
    .display {
      display: block;
    }
    .form {
      display: none;
    }
  }
`

const Header = styled.div`
  font-family: 'DB Komol Bd';
  width: 100%;
  background-color: #538dd5;
  text-align: center;
  padding: 6px 0;
  border: 1px solid #000;
  font-size: 24px;
`

const DetailContent = styled.div`
  padding: 8px 30px;
  background-color: #fff;
  font-family: 'DB Komol Regular';
  font-size: 20px;
  @media print {
    font-size: 15px;
  }
`

const DefectBox = styled.div`
  background-color: #f2f2f2;
  text-align: right;
  padding: 4px;
  border-bottom: 5px solid #000;
`

const Defectchoice = styled.div`
  text-align: right;
  padding: 1px 4px;
`

const CalendarBox = styled.div`
  margin: 1px 5px;
`

const Text = styled.span`
  margin-left: 5px;
`

export default CertificateForm
