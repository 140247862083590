import React, { useState } from 'react'
import { Drawer, Col, Badge } from 'antd'
import styled from 'styled-components'
import { TableRow, StatusCol } from '../../Shared/Table'
import { EditIcon, TrashIcon } from 'Components/Shared/Icon'

type Props = {
  onClick?: (evt: any, item: any) => void
  item: any
  selected?: boolean
}

const RowItem: React.FC<Props> = props => {
  const { item, selected } = props
  const onClick = (evt: any) => {
    if (props.onClick) {
      props.onClick(evt, item)
    }
  }

  const bg = item.Status === 'Active' ? '#5bc79e' : '#d0494c'
  const text = item.Status === 'Active' ? 'Publish' : 'Not publish'

  return (
    <>
      <TableRow onClick={onClick} selected={selected} minHeight={60}>
        <Col lg={6}>
          <Name>{item.QCTypeName}</Name>
        </Col>
        <Col lg={4}>{item.QCTypeID}</Col>

        <Col lg={4}>{item.UnitType}</Col>

        <Col lg={4}>
          <Status style={{ backgroundColor: bg }}>{text}</Status>
        </Col>
        <Col lg={6}>
          <EditIcon onClick={() => {}} color="#8b99a7" />
        </Col>
      </TableRow>
    </>
  )
}

const Name = styled.div`
  padding-left: 25px;
  font-size: 20px;
  font-family: 'DB Helvethaica X 75 Bd';
`

const Status = styled.div`
  height: 20px;
  border-radius: 10px;
  color: white;
  text-align: center !important;
  font-size: 16px;
  padding: 5px 15px;
  font-size: 16px;
  align-items: center;
  display: inline-flex;
`

export default RowItem
