import { ActivityState, ActivityAction } from './types'
import { Event } from '../types/event'

import produce from 'immer'
import parseErrorMessage from 'utils/parseErrorMessage'

import { getType } from 'typesafe-actions'
import * as actions from './actions'

const initialState: ActivityState = {
  UploadList: {} as any[],
  Event: {
    IsLoading: false,
    SubmitError: undefined,
    SubmitSuccess: false,
    SubmitType: undefined,
    MessageSuccess: undefined,
  } as Event,
}

export default function (
  state: ActivityState = initialState,
  action: ActivityAction
): ActivityState {
  return produce(state, (draft) => {
    let list
    let payload
    switch (action.type) {
      case getType(actions.uploadFileAsyncReq.request):
        draft.Event.SubmitError = undefined
        draft.Event.IsLoading = true
        draft.Event.SubmitSuccess = false
        break
      case getType(actions.uploadFileAsyncReq.success):
        draft.Event.IsLoading = false
        draft.Event.SubmitError = undefined
        draft.Event.SubmitSuccess = true
        break
      case getType(actions.uploadFileAsyncReq.failure):
        draft.Event.IsLoading = false
        draft.Event.SubmitError = parseErrorMessage(action.payload)
        break

      default:
        return state
    }
  })
}
