import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { connect, useDispatch } from 'react-redux'
import { cloneDeep } from 'lodash'
import { Row, Col } from 'antd'

import RootState from 'typings/RootState'
import { CloseIcon } from 'Components/Shared/Icon'
import PrimaryButton from 'Components/Shared/Button/PrimaryButton'
import { DataItem } from 'features/admin/position/types'
import { CheckedIcon } from 'Components/Shared/Icon'
import { CompanyVandorItem } from 'features/admin/user/types'
import { InputText } from 'Components/Shared/Input'
import { RadioBox } from 'Components/Shared/Form'
import { SowType } from 'features/admin/position/types'

import UserBox from './UserBox'
import SOWBox from './SOWBox'

type Props = ReduxProps &
  DispatchProps & {
    item: DataItem
    onSave?: (item: DataItem) => void
    onClose: () => void
  }

const AdminDetail: React.ComponentType<Props> = props => {
  const { item } = props
  const [dataItem, setdataItem] = useState({} as DataItem)

  useEffect(() => {
    const newItem = cloneDeep(item)
    setdataItem(newItem)
  }, [item])

  const onSave = () => {
    if (props.onSave) {
      props.onSave(dataItem)
    }
  }

  const renderSowType = (
    <SowBox>
      <Row type="flex" justify="space-between" align="middle">
        {[SowType.MasterPlan, SowType.Detail].map((data, index) => {
          const isCheck =
            (data === SowType.MasterPlan && dataItem.IsMasterPlan) ||
            (data === SowType.Detail && !dataItem.IsMasterPlan)
          return (
            <Col span={12}>
              <RadioBox
                value={data}
                label={data}
                isCheck={isCheck}
                disabled={false}
                onClick={value => {
                  dataItem.IsMasterPlan =
                    value === SowType.MasterPlan ? true : false
                  setdataItem({ ...dataItem })
                }}
              />
            </Col>
          )
        })}
      </Row>
    </SowBox>
  )

  const userSelectedBox = (
    <UserSelected>
      <Row
        type="flex"
        justify="space-between"
        align="middle"
        style={{ height: '100%' }}
      >
        <Col className="ellipsis" span={21}>
          <Icon>
            <CheckedIcon />
          </Icon>
          <TextSelect>
            {dataItem.VendorCode} - {dataItem.VendorName}
          </TextSelect>
        </Col>
        <Col span={3}>
          <TextCancle
            onClick={() => {
              dataItem.VendorCode = ''
              dataItem.VendorName = ''
              setdataItem({ ...dataItem })
            }}
          >
            ยกเลิก
          </TextCancle>
        </Col>
      </Row>
    </UserSelected>
  )

  const sowSelectedBox = (
    <UserSelected>
      <Row
        type="flex"
        justify="space-between"
        align="middle"
        style={{ height: '100%' }}
      >
        <Col className="ellipsis" span={21}>
          <Icon>
            <CheckedIcon />
          </Icon>
          <TextSelect>{dataItem.SOWCode}</TextSelect>
        </Col>
        <Col span={3}>
          <TextCancle
            onClick={() => {
              dataItem.SOWCode = ''
              setdataItem({ ...dataItem })
            }}
          >
            ยกเลิก
          </TextCancle>
        </Col>
      </Row>
    </UserSelected>
  )

  const searchUserBox = (
    <UserBox
      onSave={(user: CompanyVandorItem) => {
        dataItem.VendorCode = user.VendorCode
        dataItem.VendorName = user.VendorName
        setdataItem({ ...dataItem })
      }}
    />
  )

  const searchSowBox = (
    <SOWBox
      onSave={(sowCode: string) => {
        dataItem.SOWCode = sowCode
        setdataItem({ ...dataItem })
      }}
    />
  )

  return (
    <MainPage>
      <Header>
        <CloseIcon color="#8b99a7" size={24} onClick={props.onClose} />
      </Header>
      <Row>
        <Col span={24}>
          <Title>ตั้งค่า</Title>
        </Col>
        <Col span={24}>
          <BodyArea>
            <InputText
              prefix="ชื่อ"
              value={dataItem.PositionName}
              onChange={(val: string) => {
                dataItem.PositionName = val
                setdataItem({ ...dataItem })
              }}
              placeholder="ระบุชื่อ"
            />
          </BodyArea>
        </Col>
        <Col span={24}>
          <BodyArea>
            <Col span={24}>
              <BodyArea>
                {dataItem.SOWCode && dataItem.SOWCode !== ''
                  ? sowSelectedBox
                  : searchSowBox}
              </BodyArea>
            </Col>
          </BodyArea>
        </Col>

        <Col span={24}>
          <Text>ประเภท</Text>
        </Col>
        <Col span={24}>{renderSowType}</Col>
        {!dataItem.IsMasterPlan && (
          <Col span={24}>
            <BodyArea>
              {dataItem.VendorCode && dataItem.VendorCode !== ''
                ? userSelectedBox
                : searchUserBox}
            </BodyArea>
          </Col>
        )}
      </Row>
      <Row type="flex" justify="end">
        <SaveArea>
          <PrimaryButton onClick={onSave} text="บันทึก" />
        </SaveArea>
      </Row>
    </MainPage>
  )
}

const MainPage = styled.div`
  -khtml-user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  overflow-x: scroll;
`

const Header = styled.div`
  text-align: right;
  height: 40px;

  svg {
    cursor: pointer;
  }
`

const Title = styled.div`
  color: black;
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 28px;
  margin-bottom: 14px;
`

const Text = styled.div`
  color: black;
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 20px;
  margin: 10px 0;
`

const SaveArea = styled.div`
  margin-top: 32px;
  float: right;
  width: 160px;
`

const SowBox = styled.div`
  font-family: 'DB Helvethaica X 55 Regular';
  margin-bottom: 10px;
`

const BodyArea = styled.div`
  font-family: 'DB Helvethaica X 55 Regular';
  span {
    font-size: 20px;
  }
  .ant-dropdown-trigger {
    margin-bottom: 12px;
  }
  .input-title {
    margin-bottom: 12px;
  }
`

const SearchItem = styled.div`
  padding: 8px 24px;
  font-size: 20px;
  font-family: 'DB Helvethaica X 55 Regular';
  &:hover {
    background-color: #e6f7ff;
    cursor: pointer;
  }
`

const UserSelected = styled.div`
  height: 48px;
  border-radius: 5px;
  border: solid 1px #eeeff2;
  background-color: #fff;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.03);
  padding: 0 16px;
  .ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

const TextSelect = styled.span`
  font-size: 20px;
  font-family: 'DB Helvethaica X 55 Regular';
`

const TextCancle = styled.span`
  font-size: 20px;
  font-family: 'DB Helvethaica X 55 Regular';
  color: #d0494c;
  cursor: pointer;
`

const Icon = styled.div`
  margin-right: 16px;
  float: left;
`

const mapStateToProps = (state: RootState) => {
  return {}
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminDetail)
