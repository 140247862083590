import { Col, Row } from 'antd'
import React from 'react'
import styled from 'styled-components'

interface Props {
  data: any[]
  index: number
}

const ReportTableRow: React.FC<Props> = ({ data, index }) => {
  return (
    <Item>
      <Row type="flex" justify="space-between" align="middle">
        {data.map((col: any, i: number) => {
          return (
            <Col key={i} span={col.span} className={col.isBold ? 'bold' : ''}>
              {i === 3 ? (
                <div className="defectTotal">{col.title}</div>
              ) : (
                <div>{col.title}</div>
              )}
            </Col>
          )
        })}
      </Row>
    </Item>
  )
}

const Item = styled.div`
  font-size: 18px;
  color: #000;
  font-family: 'DB Helvethaica X 55 Regular';
  border-radius: 5px;
  border: solid 1px #e4e7eb;
  padding: 10px 24px;
  margin: 4px 0;
  .bold {
    font-family: 'DB Helvethaica X 75 Bd';
  }
  .defectTotal {
    text-align: center;
    width: 50px;
  }

  @media print {
    padding: 5px 10px;
    margin: 0;
    word-break: break-word;
  }
`
//page-break-before: auto;

export default ReportTableRow
