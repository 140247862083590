import React, { useEffect } from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import TopNav from 'Components/Shared/TopNav'
import TokenExpired from 'Components/Pages/TokenExpired'

import ZoneDashboard from 'Components/QC/Dashboard/CommonArea'
import QcZoneReport from 'Components/QC/Zone/Report'

import ProjectList from 'Components/QC/ProjectList'
import TodoList from 'Components/QC/Zone/TodoList'
import PMRList from 'Components/QC/Zone/PMRList'
import PMRReport from 'Components/QC/Zone/PMRReport'

type Props = {}

const QcZoneApp: React.FunctionComponent<Props> = props => {
  return (
    <>
      <TopNav title="QC" displayProject={false} secondaryTitle="ส่วนกลาง" />
      <Switch>
        <Route exact path="/zone" component={ZoneDashboard} />
        <Route exact path="/zone/dashboard" component={ZoneDashboard} />
        <Route
          exact
          path="/zone/report/:project/:unit/:qcType/:requestID/:floor"
          component={QcZoneReport}
        />
        <Route exact path="/zone/todo/:project" component={TodoList} />
        <Route exact path="/zone/pmr/list" component={PMRList} />
        <Route
          exact
          path="/zone/pmr/report/:project/:unit/:requestID/:qctypeID/:floor"
          component={PMRReport}
        />
        <Route exact path="/:type/projects" component={ProjectList} />
        <Route exact path="/token-expired" component={TokenExpired} />
      </Switch>
    </>
  )
}

export default QcZoneApp
