import React, { useRef, useState } from 'react'
import { Col, Layout, Row } from 'antd'
import styled from 'styled-components'
import {
  ReportHeader,
  ReportLogo,
  DescItem,
  Signature,
} from 'Components/AdminQC/Report/Components'
import { DataDetail } from 'features/adminReport/commonArea/types'
import DefectItem from './DefectItem'
import { toDateText, toMomentDateTime } from 'utils/datetime'
import { DefectItem as Defect } from 'features/report/zone/types'

const { Content } = Layout

type Props = {
  data: DataDetail
}

const ReportDetail: React.ComponentType<Props> = props => {
  const componentPrint = useRef(null)
  const { data } = props
  const renderDescItem = (title: string, value: any) => {
    return <DescItem title={title} value={value || '-'} percentW={14} />
  }

  const projectName = `${data.ProjectCode}-${data.ProjectNameTH}`

  const DescContent = (
    <DesBox>
      <Row type="flex">
        {renderDescItem('โครงการ', projectName)}
        {renderDescItem('โซน', data.Unit)}
        {renderDescItem('ชั้น', data.Floor)}
        {renderDescItem('ตรวจครั้งที่', data.CheckNumber)}
        {renderDescItem(
          'วันที่ตรวจ',
          toDateText(toMomentDateTime(data.InspectDate))
        )}
        {renderDescItem(
          'วันที่ส่งรายงาน',
          toDateText(toMomentDateTime(data.CheckedDate))
        )}
        {renderDescItem('ผู้ตรวจ', `${data.CheckedBy}(${data.CheckByModule})`)}
      </Row>
    </DesBox>
  )

  const renderDefectItem = () => {
    return (
      data.DefectList &&
      data.DefectList.map((item: Defect, i: number) => {
        return <DefectItem key={i} index={i} defect={item} />
      })
    )
  }

  const DefectItemContent = renderDefectItem()

  return (
    <Content className="mid-content">
      <div className="main-content activity-detail">
        <ReportHeader
          title="รายงานตรวจส่วนกลาง"
          componentPrint={componentPrint}
        />
        <Box ref={componentPrint}>
          <ReportLogo title="รายงานตรวจส่วนกลาง - ส่วนกลางทั่วไป" />
          {DescContent}
          <DefectBox>
            <Row type="flex" justify="space-between" align="middle">
              <Col>
                <TitleSection>รายการ Defect</TitleSection>
              </Col>
              <Col>
                <TitleSection>
                  ผ่าน <Score className="pass">{data.NumOfPass}</Score>
                  ไม่ผ่าน <Score className="fail">{data.NumOfFail}</Score>
                </TitleSection>
              </Col>
            </Row>
            {DefectItemContent}
          </DefectBox>
          <Signature data={data.Signature} />
        </Box>
      </div>
    </Content>
  )
}

const Title: any = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 28px;
  color: #000;
`

const TitleSection = styled(Title)`
  font-size: 24px;
`

const Score = styled.span`
  font-size: 24px;
  margin-right: 32px;
  margin-left: 6px;
  &.pass {
    color: #5bc79e;
  }
  &.fail {
    color: #d0494c;
  }
`

const Box = styled.div`
  background-color: rgba(0, 49, 104, 0.05);
  border-radius: 8px;
  border: solid 1px #e4e7eb;
  color: #000;

  @media print {
    background-color: white;
    border: solid 0px #e4e7eb;
  }
`

const DesBox = styled.div`
  padding: 12px 24px;
  background-color: #fff;
`

const DefectBox = styled.div`
  padding: 24px;
`

export default ReportDetail
