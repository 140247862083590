import React from 'react'

type DefaultProps = {
  size?: number
  color?: string
  onClick?: () => void
}

const CloseIcon = (props: DefaultProps) => {
  const { size = 20, color = '#fff', onClick } = props
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 16 16"
      onClick={onClick}
    >
      <path
        fill={color}
        fillRule="evenodd"
        d="M8.94 8l4.2-4.193a.67.67 0 1 0-.947-.947L8 7.06l-4.193-4.2a.67.67 0 0 0-.947.947L7.06 8l-4.2 4.193a.667.667 0 0 0 0 .947.667.667 0 0 0 .947 0L8 8.94l4.193 4.2a.667.667 0 0 0 .947 0 .667.667 0 0 0 0-.947L8.94 8z"
      />
    </svg>
  )
}

export default CloseIcon
