import { ModelState, ModelAction, ModelListItem } from './types'
import produce from 'immer'
import parseErrorMessage from 'utils/parseErrorMessage'
import { getType } from 'typesafe-actions'
import * as actions from './actions'
import { cloneDeep } from 'lodash'

const initialModelSelect: ModelListItem = {
  ModelID: '',
  ModelName: '',
  ModelCode: '',
  UnitUsedCounter: 0,
  Status: 'Ready',
  UnitArea: 0,
  ConfigList: [
    {
      DirectionName: 'L',
      ImageFloorPlan: {
        ActualFileName: '',
        FileID: 0,
        FileName: '',
        FileSize: 0,
        FileURL: '',
      },
      UnitFloorName: '1',
    },
    {
      DirectionName: 'R',
      ImageFloorPlan: {
        ActualFileName: '',
        FileID: 0,
        FileName: '',
        FileSize: 0,
        FileURL: '',
      },
      UnitFloorName: '1',
    },
  ],
  UnitList: [],
  ConfigAreaList: [],
}

const initEvent = {
  IsLoading: false,
  SubmitError: '',
  SubmitSuccess: false,
  SubmitType: '',
  MessageSuccess: '',
}

const initialState: ModelState = {
  ModelList: {
    Total: 0,
    List: [],
  },
  ModelMappingList: [],
  ModelSelected: undefined,
  Event: cloneDeep(initEvent),
  ImportEvent: cloneDeep(initEvent),
}

export default function(
  state: ModelState = initialState,
  action: ModelAction
): ModelState {
  return produce(state, draft => {
    switch (action.type) {
      case getType(actions.reset):
        draft.ModelSelected = cloneDeep(initialModelSelect)
        break

      case getType(actions.getModelListReq.request):
        draft.Event.IsLoading = true
        draft.Event.SubmitError = ''
        draft.ModelSelected = undefined
        draft.ModelList = {
          Total: 0,
          List: [],
        }
        break
      case getType(actions.getModelListReq.failure):
        draft.Event.IsLoading = false
        draft.Event.SubmitError = parseErrorMessage(action.payload)
        break
      case getType(actions.getModelListReq.success):
        const ResponseData: any = action.payload.ResponseData || {}
        draft.ModelList = {
          Total: ResponseData.Total || 0,
          List: ResponseData.List || [],
        }
        draft.Event.IsLoading = false
        break

      case getType(actions.setSelectedItem):
        const item = action.payload
        if (item) {
          draft.ModelSelected = {
            ModelID: item.ModelID,
            ModelCode: item.ModelCode,
            ModelName: item.ModelName,
            UnitUsedCounter: item.UnitUsedCounter,
            Status: item.Status,
            UnitArea: item.UnitArea,
            ConfigList: item.ConfigList || [],
            UnitList: [],
            ConfigAreaList: item.ConfigAreaList || [],
          }
        } else {
          draft.ModelSelected = item
        }
        break
      case getType(actions.updateData):
        const value: any = action.payload
        draft.ModelSelected = value
        break

      case getType(actions.getItemForModelReq.request):
        draft.ImportEvent = cloneDeep(initEvent)
        draft.Event = cloneDeep(initEvent)
        draft.Event.IsLoading = true
        draft.ModelMappingList = []
        break
      case getType(actions.getItemForModelReq.failure):
        draft.Event.IsLoading = false
        draft.Event.SubmitError = parseErrorMessage(action.payload)
        break
      case getType(actions.getItemForModelReq.success):
        draft.ModelMappingList = action.payload.ResponseData
        draft.Event.IsLoading = false
        break

      case getType(actions.submitModelReq.request):
      case getType(actions.deleteModelReq.request):
        draft.ImportEvent = cloneDeep(initEvent)
        draft.Event = cloneDeep(initEvent)
        draft.Event.IsLoading = true
        break
      case getType(actions.submitModelReq.failure):
      case getType(actions.deleteModelReq.failure):
        draft.Event.IsLoading = false
        draft.Event.SubmitError = parseErrorMessage(action.payload)
        break
      case getType(actions.submitModelReq.success):
      case getType(actions.deleteModelReq.success):
        draft.Event.IsLoading = false
        break
      case getType(actions.importModel.request):
        draft.ImportEvent = cloneDeep(initEvent)
        break
      case getType(actions.importModel.failure):
        draft.ImportEvent.IsLoading = false
        draft.ImportEvent.SubmitError = parseErrorMessage(action.payload)
        break
      case getType(actions.importModel.success):
        draft.ImportEvent.IsLoading = false
        draft.ImportEvent.SubmitError = undefined
        draft.ImportEvent.SubmitSuccess = true
        draft.ImportEvent.MessageSuccess = 'Import สำเร็จ'
        break
      default:
        return state
    }
  })
}
