import { createStandardAction, createAsyncAction } from 'typesafe-actions'
import {
  GetDataListReq,
  GetDataListResp,
  GetMainConListReq,
  GetMainConResp,
  UpdateDataReq,
  CommonResp,
  GetRequestHistoryReq,
  GetRequestHistoryResp,
} from './types'

export const getDataListAction = createAsyncAction(
  'adminqc/unit/FETCH_LIST_REQUEST',
  'adminqc/unit/FETCH_LIST_SUCCESS',
  'adminqc/unit/FETCH_LIST_FAILURE'
)<GetDataListReq, GetDataListResp, any>()

export const getMainConListAction = createAsyncAction(
  'adminqc/unit/FETCH_MAINCON_LIST_REQUEST',
  'adminqc/unit/FETCH_MAINCON_LIST_SUCCESS',
  'adminqc/unit/FETCH_MAINCON_LIST_FAILURE'
)<GetMainConListReq, GetMainConResp, any>()

export const updateDataAction = createAsyncAction(
  'adminqc/unit/UPDATE_DATA_REQ',
  'adminqc/unit/UPDATE_DATA_RESP',
  'adminqc/unit/UPDATE_DATA_FAIL'
)<UpdateDataReq, CommonResp, any>()

export const getRequestHistoryAction = createAsyncAction(
  'adminqc/unit/FETCH_REQUEST_HISTORY_LIST_REQUEST',
  'adminqc/unit/FETCH_REQUEST_HISTORY_LIST_SUCCESS',
  'adminqc/unit/FETCH_REQUEST_HISTORY_LIST_FAILURE'
)<GetRequestHistoryReq, GetRequestHistoryResp, any>()

export const resetEvent = createStandardAction('admin/team/RESET_EVENT_ACTION')<
  void
>()
