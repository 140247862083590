import React, { useState } from 'react'
import { Layout } from 'antd'
import styled from 'styled-components'

import { BreadcrumbLink } from 'Components/Shared/BreadCrumb/BreadCrumb'
import { MainConChecklist } from 'Components/Activity'

import { Activity, CurrentActivity } from 'features/cmpj/activity/types'
import { MainconCheckList } from 'features/types/checklist'
import { StatusOfTask } from 'features/types/status'
import TaskHeader from 'Components/CM/Activity//TaskHeader'
import MainConTaskInfo from 'Components/CM/Activity//MainConTaskInfo'
import TaskProgress from 'Components/CM/Activity/TaskProgress'
import Breadcrumbs from './Breadcrumbs'

type Props = {
  onImageOpen(list?: any[], readOnly?: boolean): void
  onDocumentOpen(list?: any[], readOnly?: boolean): void
  onThumbnailOpen(index: number, list?: any[], readOnly?: boolean): void
  onHistoryOpen?: () => void
  originalData: Activity
  currentData: CurrentActivity
  checkListData: MainconCheckList
  wbs?: any
  breadcrumbs?: BreadcrumbLink[]
  onUpdateActivity: (val: CurrentActivity) => void
}

const { Content } = Layout

const ActivityDetail: React.FunctionComponent<Props> = ({
  onImageOpen,
  onDocumentOpen,
  onThumbnailOpen,
  originalData,
  currentData,
  checkListData,
  wbs,
  onHistoryOpen,
  breadcrumbs = [],
  onUpdateActivity,
}) => {
  const { State, TaskStatus } = originalData
  // const onActivity = (val: CurrentActivity) => {}

  const onActivity = (val: CurrentActivity) => onUpdateActivity({ ...val })

  // Mock data
  const isCM = State === 'CM'

  const renderChecklist = () => {
    let hideStatus = false
    if (isCM) {
      if (TaskStatus === StatusOfTask.INIT) {
        hideStatus = true
      } else if (
        TaskStatus === StatusOfTask.INPRO ||
        TaskStatus === StatusOfTask.REJT
      ) {
        return (
          <MainConChecklist
            checkListData={checkListData}
            onUpdateCheckList={() => {}}
            showWarningList={true}
          />
        )
      }
    }

    return (
      <MainConChecklist checkListData={checkListData} hideStatus={hideStatus} />
    )
  }

  return (
    <>
      <Breadcrumbs breadcrumbs={breadcrumbs} activity={originalData} />
      <Content className="mid-content">
        <div className="main-content activity-detail">
          <ActivityDetailArea>
            <TaskHeader
              originalData={originalData}
              onHistoryOpen={onHistoryOpen}
              wbs={wbs}
            />
            <MainConTaskInfo
              original={originalData}
              onDocumentOpen={onDocumentOpen}
              onImageOpen={onImageOpen}
              onThumbnailOpen={onThumbnailOpen}
            />
            <TaskProgress
              data={currentData}
              original={originalData}
              onDocumentOpen={onDocumentOpen}
              onImageOpen={onImageOpen}
              onThumbnailOpen={onThumbnailOpen}
              onChange={onActivity}
              // readOnly={true}
            />
            {renderChecklist()}
          </ActivityDetailArea>
        </div>
      </Content>
    </>
  )
}

const ActivityDetailArea = styled.div`
  .box-section {
    background-color: #fff;
    margin-top: 12px;
    border-radius: 5px;
    border: solid 1px #f2f2f2;
    padding: 24px;
    line-height: normal;
    &.no-space {
      padding: 0;
      margin-top: 0px;
    }
    .title {
      font-size: 20px;
      font-family: 'DB Helvethaica X 75 Bd';
    }
    .description {
      font-size: 20px;
    }
  }
  .space {
    margin-top: 24px;
  }
`

export default ActivityDetail
