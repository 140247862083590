import { translate } from 'i18n'
import TKeys from 'i18n/translationKey'
import { textToLowerCase } from './format'
import {
  DefectStatus,
  DefectStatusText,
  DefectColor,
} from 'features/types/status'

export interface TaskStatusMeta {
  color: string
  progress: string
  success?: string
  reject?: string
  statusCode: 'init' | 'appv' | 'rejt' | 'pend' | 'inpro'
  text: string
}

export interface DefectStatusMeta {
  color: string
  value: string
  text: string
  textColor: string
}

export const color = {
  GREY: '#e5e5e5',
  RED: '#d0494c',
  YELLOW: '#efc122',
  GREEN: '#5bc79e',
}

const colorMap = new Map<string, TaskStatusMeta>()

colorMap.set('init', {
  color: color.GREY,
  progress: color.YELLOW,
  statusCode: 'init',
  text: translate(TKeys.TaskLane.init_text_th),
})
colorMap.set('appv', {
  color: color.GREEN,
  progress: color.GREEN,
  statusCode: 'appv',
  text: translate(TKeys.TaskLane.appv_text_th),
})
colorMap.set('pend', {
  color: color.YELLOW,
  progress: color.YELLOW,
  success: color.GREEN,
  statusCode: 'pend',
  text: translate(TKeys.TaskLane.pend_text_th),
})
colorMap.set('inpro', {
  color: color.YELLOW,
  progress: color.GREEN,
  success: color.GREEN,
  statusCode: 'inpro',
  text: translate(TKeys.TaskLane.pend_text_th),
})
colorMap.set('rejt', {
  color: color.RED,
  progress: color.GREEN,
  reject: color.RED,
  statusCode: 'rejt',
  text: translate(TKeys.TaskLane.rejt_text_th),
})

export const getProgressColorByTaskStatus = (status: string): string => {
  const taskMeta = colorMap.get(status) as TaskStatusMeta

  return (taskMeta && taskMeta.color) || color.GREY
}

export const getTaskStatus = (status: string): TaskStatusMeta => {
  const taskMeta = colorMap.get(textToLowerCase(status)) as TaskStatusMeta

  return taskMeta || colorMap.get('init')
}

export const getProgressColor = (
  taskStatus: string,
  taskProgress: number,
  reqProgress: number,
  curProgress: number
): any => {
  const status = textToLowerCase(taskStatus)

  const taskMeta = colorMap.get(status) as TaskStatusMeta

  const statusColor = taskMeta || colorMap.get('init')
  let color
  let className
  let progress = taskProgress
  let success
  switch (status) {
    case 'inpro':
    case 'pend':
      progress = taskProgress
      success = curProgress
      className = 'task-progress-pending'

      break
    case 'rejt':
      progress = reqProgress
      success = curProgress
      className = 'task-progress-reject'

      break
    default:
      color = statusColor.progress
  }

  if (progress === success) {
    color = statusColor.progress
    className = undefined
    success = undefined
  }

  return {
    progress,
    success,
    color,
    className,
  }
}

const defectColorMap = new Map<string, DefectStatusMeta>()

defectColorMap.set('default', {
  color: '#8b99a7',
  text: '',
  value: '',
  textColor: '#8b99a7',
})

defectColorMap.set(DefectStatus.TOBEFIX, {
  color: DefectColor.TOBEFIX,
  text: DefectStatusText.TOBEFIX,
  value: DefectStatus.TOBEFIX,
  textColor: DefectColor.TOBEFIX,
})

defectColorMap.set(DefectStatus.FIXED, {
  color: DefectColor.FIXED,
  text: DefectStatusText.FIXED,
  value: DefectStatus.FIXED,
  textColor: '#000',
})

defectColorMap.set(DefectStatus.GOODWORK, {
  color: DefectColor.GOODWORK,
  text: DefectStatusText.GOODWORK,
  value: DefectStatus.GOODWORK,
  textColor: '#000',
})

defectColorMap.set(DefectStatus.PASS, {
  color: DefectColor.PASS,
  text: DefectStatusText.PASS,
  value: DefectStatus.PASS,
  textColor: '#000',
})

defectColorMap.set('Fail', {
  color: '#d0494c',
  text: 'ไม่ผ่าน',
  value: 'Fail',
  textColor: '#000',
})

export const getDefectColor = (
  defectStatus: DefectStatus | string
): DefectStatusMeta => {
  const taskMeta = defectColorMap.get(defectStatus) as DefectStatusMeta
  const statusColor = taskMeta || defectColorMap.get('default')
  return statusColor
}

export const getRandomColor = (no: number) => {
  const configColor = [
    '#FF0000',
    '#0000FF',
    '#808080',
    '#800000',
    '#808000',
    '#008000',
    '#800080',
    '#008080',
    '#000080',
  ]
  let letters = '0123456789ABCDEF'
  let color = '#'
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)]
  }
  return configColor[no] ? configColor[no] : color
}

export default colorMap
