import { sortingList } from '../../../utils/helper'
import { DataItem } from './types'

export const sortingActivityList = (
  list: DataItem[],
  type: string,
  name: string | undefined
): DataItem[] => {
  try {
    let newList: DataItem[] = list
    switch (name) {
      case 'ProjectNameTH':
      case 'ProjectTypeName':
      case 'VendorName':
      case 'ProjectCode':
        newList = sortingList(list, type, name, 'string')
        break
      default:
        newList = sortingList(list, type, name || '', 'number')
    }

    return newList
  } catch (e) {
    return list
  }
}
