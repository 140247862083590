import Axios, { AxiosRequestConfig } from 'axios'
import { APIMethod } from './APIRequestSpec'
import { APIPath } from './APIPath'
import { appConfig } from '../config'

// Maybe we will need this.
// const formHeader = {
//   headers: {
//     "Content-Type": "application/x-www-form-urlencoded",
//   },
// };

export function configWithToken(token?: string): AxiosRequestConfig {
  return {
    headers: {
      Authorization: token != null ? token : '',
      'Access-Control-Allow-Origin': '*',
    },
  }
}

const handleResp = (resp: any) => {
  return resp.data
}

const APIManager = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  call: <T = any, D = any>(
    path: APIPath,
    method: APIMethod,
    data?: D,
    config?: AxiosRequestConfig
  ): Promise<T> => {
    const url = `${appConfig.apiBaseUrl}${path}`
    switch (method) {
      case 'GET':
        return Axios.get(url, config).then(handleResp)

      case 'POST':
        return Axios.post(url, data, config).then(handleResp)

      case 'PUT':
        return Axios.put(url, data).then(handleResp)

      case 'DELETE':
        return Axios.delete(url).then(handleResp)

      case 'PATCH':
        return Axios.patch(url, data).then(handleResp)

      default:
        break
    }
    throw new Error('Method not found')
  },
  // Maybe we will need this.
  //   get: <T = any>(url: string, config?: AxiosRequestConfig): Promise<T> =>
  //     Axios.get(url, config).then(response => response.data),
  //   postForm: <T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> =>
  //     Axios.post(url, stringify(data), { ...config, ...formHeader }).then(response => response.data),
  //   post: <T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> =>
  //     Axios.post(url, data, config).then(response => response.data),
  //   put: <T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T> =>
  //     Axios.put(url, data, config).then(response => response.data),
  //   delete: <T = any>(url: string, config?: AxiosRequestConfig): Promise<T> =>
  //     Axios.delete(url, config).then(response => response.data),
}

export default APIManager
