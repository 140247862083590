import React, { useState, useEffect, useCallback } from 'react'
import RootState from 'typings/RootState'
import { connect, useDispatch } from 'react-redux'
import { Row, Col, Layout } from 'antd'
import styled from 'styled-components'
import TextArea from 'antd/lib/input/TextArea'
import EventModal from 'Components/Shared/Modal/EventModal'

import {
  selectDefect,
  selectCheckListQC,
  selectEvent,
  selecMainconList,
} from 'features/report/zone/selector'

import { submitDefectReq, updateDefect } from 'features/report/zone/actions'

import {
  userEmailSelector,
  serviceTokenSelector,
} from 'features/sensitive/sensitive.selector'

import { selectUploadEvent } from 'features/uploadfile/selector'

import RadioBox from 'Components/Shared/Input/RadioBox'

import DefectFooter from './DefectFooter'
import StatusList from './StatusList'
import DefectImage from '../DefectImage'

import {
  ZoneReport,
  DefectItem,
  CheckListForQC,
  DefectTypeReq,
  SendDefectReq,
} from 'features/report/zone/types'

import {
  JobCheckList,
  SubJobCheckList,
  DetailCheckList,
  LocationCheckList,
  MainconList,
} from 'Components/Defect/CheckList'

import projectHelper from 'utils/projectHelper'

const { Content } = Layout

type Props = {
  ProjectCode: string
  RequestID: number
  onClose?: () => void
  onCompeted?: () => void
  addMode?: boolean
  report: any
} & ReduxProps

const DefectDetail: React.FunctionComponent<Props> = props => {
  const [enableModal, setEnableModal] = useState(false)

  const noFunc = () => {}
  const dispatch = useDispatch()
  const {
    onClose = noFunc,
    onCompeted = noFunc,
    ProjectCode,
    RequestID,

    Token,
    UserEmail,

    report,
    defect,
    checkList,
    addMode = true,
    mainconList,

    event,
    uploadEvent,
  } = props

  const moduleCode = projectHelper.getModule()

  const closeEnableModal = () => setEnableModal(false)
  const onSaveCompleted = () => {
    closeEnableModal()
    onCompeted()
  }

  const title: string = addMode ? 'เพิ่ม Defect' : 'แก้ไข Defect'
  const CheckNoNotEqual = !addMode && report.CheckNumber !== defect.CheckNumber

  useEffect(() => {
    if (defect.MCVendorCode === '' && mainconList.length === 1) {
      defect.MCVendorCode = mainconList[0].MCVendorCode
      onChange(defect)
    }
  }, [])

  const onChange = (val: DefectItem) => {
    dispatch(updateDefect({ ...val }))
  }

  const onSave = () => {
    setEnableModal(true)
    const req: SendDefectReq = {
      ProjectCode,
      UserEmail,
      Token,
      RequestID,
      CheckListID: checkList.CheckListID,
      Report: report,
      Defect: defect,
      Module: moduleCode,
    }

    dispatch(submitDefectReq(req))
  }

  const updateJob = (value: number) => {
    if (value !== defect.JobTypeID) {
      const jobType = checkList.JobTypeList.find(
        item => item.JobTypeID === value
      )
      defect.JobTypeID = value
      defect.ZoneTypeID = jobType ? jobType.ZoneTypeID : 0
      defect.SubJobTypeID = 0
      defect.DetailID = 0
      defect.LocationID = 0
      onChange(defect)
    }
  }

  const updateSubJob = (value: number) => {
    if (value !== defect.SubJobTypeID) {
      defect.SubJobTypeID = value
      defect.DetailID = 0
      onChange(defect)
    }
  }

  const updateDetail = (value: number) => {
    defect.DetailID = value
    onChange(defect)
  }

  const updateLocation = (value: number) => {
    defect.LocationID = value
    onChange(defect)
  }

  const updateStatus = (value: string) => {
    defect.PinStatus = value
    onChange(defect)
  }

  const updateRemark = (value: string) => {
    defect.Remark = value
    onChange(defect)
  }

  const updateBeforeImg = (value: any) => {
    defect.ImageBefore = value
    onChange(defect)
  }

  const updateAfterImg = (value: any) => {
    defect.ImageAfter = value
    onChange(defect)
  }

  const updateSansiri = (value: any) => {
    defect.IsSansiriFixed = value
    onChange(defect)
  }

  const updateMaincon = (value: any) => {
    defect.MCVendorCode = value
    onChange(defect)
  }

  const getLocation = (jobId: number) => {
    const job =
      checkList.JobTypeList &&
      checkList.JobTypeList.find((job: any) => job.JobTypeID === jobId)
    return (job && job.LocationList) || []
  }

  const getSubJob = (jobId: number) => {
    const job =
      checkList.JobTypeList &&
      checkList.JobTypeList.find((job: any) => job.JobTypeID === jobId)
    return (job && job.SubJobTypeList) || []
  }

  const getDetail = (subjobList: any[], subJobId: number) => {
    const job = subjobList.find((job: any) => job.SubJobTypeID === subJobId)
    return (job && job.DetailList) || []
  }

  const jobDatas = checkList.JobTypeList
  const locationDatas = getLocation(defect.JobTypeID)
  const subJobDatas = getSubJob(defect.JobTypeID)
  const detailDatas = getDetail(subJobDatas, defect.SubJobTypeID)

  const BottomSection = (
    <ChecklistData>
      <Row type="flex" justify="space-between">
        <Col span={12}>
          <DropDownArea>
            <JobCheckList
              datas={jobDatas}
              active={defect.JobTypeID}
              onChange={updateJob}
              disabled={CheckNoNotEqual}
            />
            <SubJobCheckList
              datas={subJobDatas}
              active={defect.SubJobTypeID}
              onChange={updateSubJob}
              disabled={defect.JobTypeID <= 0 || CheckNoNotEqual}
            />
            <DetailCheckList
              datas={detailDatas}
              active={defect.DetailID}
              onChange={updateDetail}
              disabled={defect.SubJobTypeID <= 0 || CheckNoNotEqual}
            />

            <LocationCheckList
              datas={locationDatas}
              active={defect.LocationID}
              onChange={updateLocation}
              disabled={
                defect.JobTypeID <= 0 ||
                CheckNoNotEqual ||
                locationDatas.length === 0
              }
            />
          </DropDownArea>
        </Col>
        <Col span={12}>
          <SecondArea>
            <MainconList
              datas={mainconList}
              active={defect.MCVendorCode}
              onChange={updateMaincon}
              disabled={CheckNoNotEqual}
            />

            <SansiriProvider>
              <label>แสนสิริจัดหา</label>

              <span>
                <RadioBox
                  label="เป็น"
                  value={true}
                  isSelected={defect.IsSansiriFixed}
                  readOnly={CheckNoNotEqual}
                  onClick={updateSansiri}
                />
              </span>

              <span>
                <RadioBox
                  label="ไม่เป็น"
                  value={false}
                  isSelected={defect.IsSansiriFixed === false}
                  readOnly={CheckNoNotEqual}
                  onClick={updateSansiri}
                />
              </span>
            </SansiriProvider>

            <Header>หมายเหตุ</Header>
            <InputRemark>
              <TextArea
                value={defect.Remark}
                onChange={evt => updateRemark(evt.target.value)}
                placeholder=""
                maxLength={255}
              />
            </InputRemark>

            <Status>
              <StatusList
                value={defect.PinStatus}
                onChange={updateStatus}
                disabled={false}
              />
            </Status>
          </SecondArea>
        </Col>
      </Row>
    </ChecklistData>
  )

  const img = checkList.ImageFloorPlan ? checkList.ImageFloorPlan.FileURL : ''
  return (
    <>
      <EventModal
        event={[event]}
        err={[uploadEvent]}
        enable={enableModal}
        successCalback={onSaveCompleted}
        errorCalback={closeEnableModal}
      />
      <Content className="mid-content">
        <div className="main-content activity-detail">
          <Title>{title}</Title>
          <DefectImage
            planImg={img}
            defect={defect}
            onAddAfterImage={updateAfterImg}
            onAddBeforeImage={updateBeforeImg}
            viewMode={CheckNoNotEqual}
            canUpAfterImg={CheckNoNotEqual}
          />

          {BottomSection}
        </div>
      </Content>
      <DefectFooter
        defect={defect}
        onDelete={onClose}
        onSave={onSave}
        requireLocation={locationDatas.length > 0}
        addMode={addMode}
      />
    </>
  )
}

const Title = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 28px;

  span {
    font-family: 'DB Helvethaica X 55 Regular';
    font-size: 16px;
    color: white;
    padding: 1px 10px;
    border-radius: 10px;
    margin-left: 12px;
  }
`

const ChecklistData = styled.div`
  margin-top: 24px;
`

const Header = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 20px;
  line-height: 1;
`

const SansiriProvider = styled.div`
  display: inline-flex;
  margin-top: 10px;
  padding-bottom: 14px;

  label {
    width: 100px;
    font-size: 20px;
    font-family: 'DB Helvethaica X 75 Bd';
  }

  span {
    width: 100px;
  }
`

const DropDownArea = styled.div`
  padding-right: 15px;

  .ant-dropdown-trigger {
    margin-bottom: 14px;
    line-height: 0px;
  }
`
const SecondArea = styled.div`
  padding-left: 15px;
`

const Status = styled.div`
  margin-top: 15px;
`

const InputRemark = styled.div`
  margin-top: 6px;
  .ant-input,
  .ant-input:focus,
  .ant-input:hover {
    font-family: 'DB Helvethaica X 55 Regular';
    font-size: 20px;
    height: 45px;
    border: solid 1px #e4e7eb;
  }
`

const mapStateToProps = (state: RootState) => {
  return {
    Token: serviceTokenSelector(state),
    UserEmail: userEmailSelector(state),
    defect: selectDefect(state),
    checkList: selectCheckListQC(state),
    mainconList: selecMainconList(state),
    event: selectEvent(state),
    uploadEvent: selectUploadEvent(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(DefectDetail)
