import React, { useState } from 'react'
import { Col } from 'antd'
import styled from 'styled-components'
import { TableRow } from '../../../Shared/Table'
import { RequestHistory } from 'features/adminqc/unit/types'

type Props = {
  item: RequestHistory
}

const RowItem: React.FC<Props> = props => {
  const { item } = props

  return (
    <RowData>
      <TableRow minHeight={60} spaceBetweenRow={8} paddingLeft={25}>
        {/* <Col span={1}>{item.RequestID}</Col> */}
        <Col span={4}>{item.ProjectCode}</Col>
        <Col span={2}>{item.UnitCode}</Col>
        <Col span={2}>{item.ModelCode}</Col>
        <Col span={1}>{item.QCType}</Col>
        <Col span={2}>{item.ReqState}</Col>
        <Col span={3}>{item.ReqStatus}</Col>
        {/* <Col span={3}>{item.SendReportDate}</Col> */}
        <Col span={3}>{item.ActivityType}</Col>
        <Col span={4}>
          {item.CreateDate} - โดย {item.CreateBy}
        </Col>
      </TableRow>
    </RowData>
  )
}

const Name = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  color: #000;
`
const Icon = styled.div`
  float: left;
  margin-right: 20px;
  margin-top: 5px;
  cursor: pointer;
`

const RowData = styled.div`
  .ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

export default RowItem
