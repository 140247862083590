import React from 'react'
import styled from 'styled-components'
import { TableContent } from 'Components/Shared/Seemore'
import { TypeOfTask } from 'features/cmpj/cmdashboard/types'
import { Data } from 'features/cmpj/seemore/types'
import {
  getCmTaskStatusText,
  getDelayedDate,
  toMomentDateTime,
  toDateText,
} from 'utils/datetime'
import { Moment } from 'moment'
import { WarningIcon } from 'Components/Shared/Icon'
import { ContructionTagName } from 'Components/Shared'
import TaskProgress from 'Components/Shared/TaskProgress'
import { displayCategory } from 'utils/helper'
import iconNoImg2 from 'assets/img/ic-no-image2.png'

type DefaultProps = {
  task: Data
  pageType: number
  taskType: TypeOfTask
}
type Props = DefaultProps

const RowItem: React.FC<Props> = props => {
  const { task, pageType, taskType } = props
  let isDelayed = false
  const startDate = toDateText(toMomentDateTime(task.BaselineStartDate))
  const finishDate = toDateText(toMomentDateTime(task.BaselineFinishDate))

  const renderDelayedDate = (date: string | Moment) => {
    let content

    const delayed = getDelayedDate(date)
    if (delayed < 0) {
      isDelayed = true
      content = (
        <div className="status status-danger">
          <WarningIcon />
          <span className="countdown">
            {getCmTaskStatusText(task, taskType)}
          </span>
        </div>
      )
    } else {
      isDelayed = false
      content = (
        <div className={'status status-default'}>
          <span className="countdown">
            {getCmTaskStatusText(task, taskType)}
          </span>
        </div>
      )
    }
    return content
  }

  const getToDoRowData = (task: Data) => {
    return [
      {
        value: <Name>{task.ActivityName}</Name>,
        span: 7,
      },
      {
        value: <span>{displayCategory(task)}</span>,
        span: 3,
      },
      {
        value: <ContructionTagName name={task.ConstructionTypeName} />,
        span: 4,
      },
      {
        value: <span>{startDate}</span>,
        span: 3,
      },
      {
        value: <span>{finishDate}</span>,
        span: 3,
      },
      {
        value: <span>{renderDelayedDate(task.BaselineFinishDate)}</span>,
        span: 4,
      },
    ]
  }

  const getDoneRowData = (task: Data) => {
    return [
      {
        value: <Name>{task.ActivityName}</Name>,
        span: 7,
      },
      {
        value: <span>{displayCategory(task)}</span>,
        span: 2,
      },
      {
        value: <ContructionTagName name={task.ConstructionTypeName} />,
        span: 3,
      },
      {
        value: <span>{startDate}</span>,
        span: 3,
      },
      {
        value: <span>{finishDate}</span>,
        span: 3,
      },
      {
        value: <span>{renderDelayedDate(task.BaselineFinishDate)}</span>,
        span: 4,
      },
      {
        value:
          task.Reject && task.Reject.RejectReasonRemark === 'I' ? (
            <img src={iconNoImg2} width="30" />
          ) : (
            <></>
          ),
        span: 2,
      },
    ]
  }

  const getInprogressRowData = (task: Data) => {
    return [
      {
        value: <Name>{task.ActivityName}</Name>,
        span: 7,
      },
      {
        value: <span>{displayCategory(task)}</span>,
        span: 3,
      },
      {
        value: <ContructionTagName name={task.ConstructionTypeName} />,
        span: 4,
      },
      {
        value: <span>{finishDate}</span>,
        span: 3,
      },
      {
        value: <span>{renderDelayedDate(task.BaselineFinishDate)}</span>,
        span: 4,
      },
      {
        value: (
          <span>
            <TaskProgress task={task} />
          </span>
        ),
        span: 3,
      },
    ]
  }

  return (
    <TableContent
      contentRow={
        pageType == 2
          ? getInprogressRowData(task)
          : pageType == 1
          ? getToDoRowData(task)
          : getDoneRowData(task)
      }
      isHighlight={isDelayed}
    />
  )
}

const Name = styled.div`
  font-size: 20px;
  font-family: 'DB Helvethaica X 75 Bd';
`
export default RowItem
