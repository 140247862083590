import imgFile from 'assets/img/img-file.png'
import imgPdf from 'assets/img/ic-pdf.png'
import imgExcel from 'assets/img/ic-excel.png'
import imgDoc from 'assets/img/ic-doc.png'
import imgCsv from 'assets/img/ic-csv.png'
import { textToLowerCase } from 'utils/format'

export const getFileType = (file: any) => {
  let icon
  let type = textToLowerCase(file.type)

  if (file.name) {
    const index = file.name.lastIndexOf('.')
    type = file.name.substr(index + 1)
  }
  switch (type) {
    case 'csv':
    case 'text/csv':
      icon = imgCsv
      break
    case 'doc':
    case 'docx':
    case 'application/doc':
    case 'application/ms-doc':
    case 'application/msword':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      icon = imgDoc
      break
    case 'xls':
    case 'xlsx':
    case 'application/vnd.ms-excel':
    case 'application/msexcel':
    case 'application/x-msexcel':
    case 'application/x-ms-excel':
    case 'application/x-excel':
    case 'application/x-dos_ms_excel':
    case 'application/xls':
    case 'application/x-xls':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      icon = imgExcel
      break
    default:
      icon = imgPdf
      break
  }
  return {
    icon,
    name: file.name,
  }
}
