import React, { useState, useEffect } from 'react'
import { Row, Col } from 'antd'
import styled from 'styled-components'
import { RadioBox } from 'Components/Shared/Form'
import { ProjectListItem } from 'features/project/project.types'
import InputSearch from 'Components/Shared/Input/InputSearch'
import { getActiveProjectList } from 'features/admin/project/api'
import { GetActiveProjectsReq } from 'features/admin/project/types'
import { selectActiveProjectList } from 'features/admin/project/selector'
import { useDispatch, connect } from 'react-redux'
import {
  userEmailSelector,
  serviceTokenSelector,
} from 'features/sensitive/sensitive.selector'
import RootState from 'typings/RootState'
import {
  ProjectItem as ProjectItems,
  ModuleItem,
} from 'features/admin/user/types'
import empty from 'assets/img/project-empty.png'

type Props = {
  moduleItem?: ModuleItem
  onUpdate?: (module: ModuleItem) => void
} & ReduxProps

const ProjectBox: React.FunctionComponent<Props> = props => {
  const { UserEmail, token, projects, moduleItem, onUpdate = () => {} } = props
  const [filterList, setFilterList] = useState([] as any[])
  const [txtSearch, setTxtSearch] = useState('')

  const dispatch = useDispatch()

  useEffect(() => {
    setFilterList(projects)
    setTxtSearch('')
  }, [moduleItem && moduleItem.ModuleID])

  useEffect(() => {
    setFilterList(projects)
  }, [projects])

  useEffect(() => {
    onGetProjectList()
  }, [])

  const onGetProjectList = () => {
    const req: GetActiveProjectsReq = {
      UserEmail,
    }
    dispatch(getActiveProjectList(token, req))
  }

  const isSelected = (projectCode: string) => {
    const selected =
      moduleItem &&
      moduleItem.ProjectList &&
      moduleItem.ProjectList.find(item => item.ProjectCode === projectCode)
    return selected ? true : false
  }

  const isSelectAll = () => {
    let selectedAll = true
    projects.forEach(item => {
      const selected = isSelected(item.ProjectCode)
      if (!selected) {
        selectedAll = false
        return
      }
    })
    return selectedAll
  }

  const remove = (projectCode: string) => {
    const modules =
      moduleItem &&
      moduleItem.ProjectList &&
      moduleItem.ProjectList.filter(item => item.ProjectCode !== projectCode)
    return modules || []
  }

  const onSelectProject = (value: string) => {
    if (moduleItem && moduleItem.ProjectList) {
      let module = moduleItem
      if (isSelected(value)) {
        module.ProjectList = remove(value)
      } else {
        module.ProjectList.push({
          ProjectCode: value,
        })
      }
      onUpdate({ ...module })
    }
  }

  const onSelectAll = (value: string) => {
    if (moduleItem && moduleItem.ProjectList) {
      let module = moduleItem
      if (!isSelectAll()) {
        module.ProjectList = []
        projects.forEach(item => {
          module.ProjectList.push({
            ProjectCode: item.ProjectCode,
          })
        })
      } else {
        module.ProjectList = []
      }
      onUpdate({ ...module })
    }
  }

  const onCanAccessAll = () => {
    if (!isObjectEmpty(moduleItem) && moduleItem) {
      moduleItem.CanAccessAll = !moduleItem.CanAccessAll
      onUpdate({ ...moduleItem })
    }
  }

  const hasText = (data: string, searchTxt: string) => {
    return data.toLowerCase().includes(searchTxt.toLowerCase())
  }

  const onFilter = (text: string) => {
    setTxtSearch(text)
    const filter = projects.filter(
      item =>
        hasText(item.ProjectCode, text) || hasText(item.ProjectNameTH, text)
    )
    setFilterList([...filter])
  }

  const countProject = () => {
    let count = 0 as any
    if (moduleItem && moduleItem.ProjectList) {
      count = moduleItem.ProjectList.length
      if (moduleItem.CanAccessAll) {
        count = '*'
      }
    }
    return count
  }

  const renderProjectItem = () => {
    return (
      <Row>
        {txtSearch === '' && (
          <Col>
            <ProjectItem>
              <RadioBox
                label="เลือกทั้งหมด"
                value={'all'}
                isCheck={isSelectAll()}
                onClick={onSelectAll}
                spaceBtwText={16}
              />
            </ProjectItem>
          </Col>
        )}
        {filterList.map((project, index) => {
          return (
            <Col key={index}>
              <ProjectItem>
                <RadioBox
                  label={`${project.ProjectCode} - ${project.ProjectNameTH}`}
                  value={project.ProjectCode}
                  isCheck={isSelected(project.ProjectCode)}
                  onClick={value => onSelectProject(value)}
                  spaceBtwText={16}
                />
              </ProjectItem>
            </Col>
          )
        })}
      </Row>
    )
  }

  const projectsBox = (
    <>
      <InputSearch
        placeholder="พิมพ์ชื่องานที่ต้องการค้นหา..."
        value={txtSearch}
        onChange={onFilter}
      />
      {renderProjectItem()}
    </>
  )

  const isObjectEmpty = (value: any) => {
    return value && Object.entries(value).length === 0
  }

  const emptyBox = (
    <EmptyBox>
      <Row>
        <Col>
          <EmptyIcon>
            <img src={empty} />
          </EmptyIcon>
        </Col>
        <Col>
          <EmptyText>กรุณาเลือก “Module”</EmptyText>
        </Col>
      </Row>
    </EmptyBox>
  )

  return (
    <Project>
      <Row type="flex" justify="space-between" align="middle">
        <Col>
          <Title>โครงการที่ใช้งาน ({countProject()})</Title>
        </Col>
        <Col>
          <RadioBox
            label="เข้่าถึงทุกโครงการ"
            value={''}
            isCheck={(moduleItem && moduleItem.CanAccessAll) || false}
            onClick={() => onCanAccessAll()}
          />
        </Col>
      </Row>
      <ProjectArea>
        {isObjectEmpty(moduleItem) ? emptyBox : projectsBox}
      </ProjectArea>
    </Project>
  )
}

const Title = styled.div`
  color: black;
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 20px;
`
const Project = styled.div`
  font-family: 'DB Helvethaica X 55 Regular';
`
const ProjectArea = styled.div`
  margin: 12px 0;
  padding: 24px;
  height: 450px;
  overflow: scroll;
  border-radius: 5px;
  border: solid 1px #eeeff2;
`

const ProjectItem = styled.div`
  padding: 10.5px 0;
`

const EmptyBox = styled.div`
  height: 350px;
  -webkit-box-pack: center;
  justify-content: center;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  text-align: center;
`

const EmptyIcon = styled.div`
  img {
    width: 70px;
  }
`

const EmptyText = styled.div`
  font-size: 20px;
  color: #000;
  padding-top: 10px;
`

const mapStateToProps = (state: RootState) => {
  return {
    token: serviceTokenSelector(state),
    UserEmail: userEmailSelector(state),
    projects: selectActiveProjectList(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectBox)
