import React from 'react'
import { Input } from 'antd'

const { Search } = Input

type Props = {
  onSearch?: (val: string) => void
  placeholder?: string
}

const SearchInput: React.FunctionComponent<Props> = props => {
  const { onSearch, placeholder = 'ระบุคำที่ต้องการค้นหา...' } = props

  return (
    <div className="search-box">
      <Search placeholder={placeholder} allowClear onSearch={onSearch} />
    </div>
  )
}

export default SearchInput
