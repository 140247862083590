import React from 'react'
import styled from 'styled-components'

type Props = {
  title: string
  value: string
}

export const TaskInfo: React.FunctionComponent<Props> = ({ title, value }) => {
  return (
    <HeaderScore>
      <TextBold>{title}</TextBold>
      <Value>{value || '-'}</Value>
    </HeaderScore>
  )
}

const HeaderScore = styled.div`
  width: 100%;
  text-align: left;
  margin-bottom: 6px;
`

const TextBold = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
`

const Value = styled.div`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export default TaskInfo
