import { createStandardAction, createAsyncAction } from 'typesafe-actions';
import {
  FetchTasklaneRequest,
  FetchTasklaneResponse,
  DashboardFilter,
  DashboardQuery,
  Task,
} from './tasklane.types';

export const sampleTasklaneAction = createStandardAction(
  'SAMPLE_TASKLANE_ACTION'
)<void>();

export const refreshTasklaneAction = createStandardAction(
  'tasklanes/REFRESH_TASKLANES'
)<void>();

export const fetchTasklaneAsyncAction = createAsyncAction(
  'tasklanes/FETCH_TASKLANES_REQUEST',
  'tasklanes/FETCH_TASKLANES_SUCCESS',
  'tasklanes/FETCH_TASKLANES_FAILURE'
)<FetchTasklaneRequest, FetchTasklaneResponse, any>();

export const sortingTask = createStandardAction('tasklanes/SORTING')<string>();
export const filterDashboard = createStandardAction(
  'tasklanes/FILTER_DASHBOARD'
)<void>();
export const setFilterDashboard = createStandardAction(
  'tasklanes/CONFIG_FILTER_DASHBOARD'
)<DashboardFilter>();

export const setQueryDashboard = createStandardAction(
  'tasklanes/CONFIG_QUERY_DASHBOARD'
)<DashboardQuery>();

export const updateListAction = createStandardAction(
  'tasklanes/UPDATE_TASKLANES'
)<{ todo: Task[]; inProgress: Task[]; completed: Task[] }>();
