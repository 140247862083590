import { sortingList } from '../../../utils/helper'
import { Data } from './types'

export const sortingActivityList = (
  list: Data[],
  type: string,
  name: string | undefined
): Data[] => {
  try {
    let newList: Data[] = list
    switch (name) {
      case 'ActivityName':
        newList = sortingList(list, type, name, 'string')
        break
      case 'StartDate':
        newList = sortingList(list, type, name, 'date')
        break
      case 'FinishDate':
        newList = sortingList(list, type, name, 'date')
        break
      case 'countdown':
        newList = sortingList(list, type, 'FinishDate', 'date')
        break
      case 'ConstructionTypeName':
        newList = sortingList(list, type, name, 'string')
        break
      case 'Unit':
        newList = sortingList(list, type, name, 'string')
        break
      case 'Progress':
        newList = sortingList(list, type, name, 'number')
        break
      default:
    }

    return newList
  } catch (e) {
    return list
  }
}
