import RootState from 'typings/RootState'
import {
  DataListItem,
  ActivityFilter,
  UnittListItem,
  QCTypeListItem,
  UnitFilter,
  ZoneFilter,
  RoadConstructFilter,
  ProjectTypeItem,
  ProjectType,
  RoleListItem,
  AvtivityListItem,
} from './types'

export const selectConstructType = (state: RootState): DataListItem =>
  state.filter.ConstructType

export const selectZoneList = (state: RootState): DataListItem =>
  state.filter.ZoneList

export const selectUnitList = (state: RootState): UnittListItem =>
  state.filter.UnitList

export const selectFloorList = (state: RootState): DataListItem =>
  state.filter.FloorList

export const selectTowerList = (state: RootState): DataListItem =>
  state.filter.TowerList

export const selectQCTypeList = (state: RootState): QCTypeListItem =>
  state.filter.QCTypeList

export const selectRoleItem = (state: RootState): RoleListItem =>
  state.filter.RoleList

export const selectProjectType = (state: RootState): ProjectType[] =>
  state.filter.ProjectType.List

export const selectActivityList = (state: RootState): AvtivityListItem =>
  state.filter.ActivityList

// filter
export const selectActivityFilter = (state: RootState): ActivityFilter =>
  state.filter.activityFilter

export const selectUnitFilter = (state: RootState): UnitFilter =>
  state.filter.unitFilter

export const selectZoneFilter = (state: RootState): ZoneFilter =>
  state.filter.zoneFilter

export const selectisClearFilter = (state: RootState): boolean =>
  state.filter.isClearFilter

export const selectRoadConstructFilter = (
  state: RootState
): RoadConstructFilter => state.filter.roadConstructFilter
