import {
  ActivityAction,
  ActivityState,
  SeeMoreMeta,
  ActivityHistory,
  ActivityFilter,
} from './activity.types'
import { TaskStatus } from '../tasklane/tasklane.types'
import produce from 'immer'
import { dummyActivity } from 'utils/activityHelper'
import parseErrorMessage from 'utils/parseErrorMessage'

import { getType } from 'typesafe-actions'
import * as actions from './activity.actions'
import {
  convertDataToList,
  sortingTaskList,
  filterActivity,
} from './activity.function'
import moment from 'moment-timezone'

const initialState: ActivityState = {
  taskById: {},
  currentActivity: dummyActivity,
  originalActivity: {
    ActualStartDate: '',
    ActualFinishDate: '',
    Progress: 0,
    LastCurProgress: 0,
    LastReqProgress: 0,
    TaskStatus: 'REJT' as TaskStatus,
    Images: [],
    Documents: [],
    Remark: '',
  },
  UploadList: [],
  currentBrowsingActivityCode: undefined,
  isSubmitting: false,
  isLoading: false,
  submitError: undefined,
  submitSuccess: false,
  seeMore: {
    todo: {
      page: 1,
      totalRows: 0,
      data: {},
      list: [],
    } as SeeMoreMeta,
    'in-progress': {
      page: 1,
      totalRows: 0,
      data: {},
      list: [],
    } as SeeMoreMeta,
    complete: {
      page: 1,
      totalRows: 0,
      data: {},
      list: [],
    } as SeeMoreMeta,
    query: {
      type: 'todo',
      page: 1,
      startDate: undefined,
      endDate: undefined,
    },
  },
  showMorePages: {
    rowPerPage: 20,
    totalRows: -1,
  },
  sorting: {
    name: '',
    type: 'asc',
  },
  filter: {
    progress: [],
    status: [],
    wbs: [],
    date: {
      mode: '',
      start: undefined,
      end: undefined,
    },
  } as ActivityFilter,
  seeMoreDataFetch: false,
  isFetchingHistory: false,
  history: [],
  fetchHistoryError: '',
}

export default function(
  state: ActivityState = initialState,
  action: ActivityAction
): ActivityState {
  return produce(state, draft => {
    let list
    let payload
    switch (action.type) {
      case getType(actions.setTaskListById):
        draft.taskById = action.payload
        break
      case getType(actions.currentBrowsingActivity):
        draft.currentBrowsingActivityCode = action.payload
        break
      case getType(actions.clearSubmitError):
        draft.isSubmitting = false
        draft.submitSuccess = false
        draft.submitError = undefined
        break
      case getType(actions.updateField):
        const fieldName: string = action.payload.fieldName
        const value: any = action.payload.value

        draft.currentActivity[fieldName] = value
        break
      case getType(actions.saveActivityDetailAsyncRequest.request):
        draft.isSubmitting = true
        draft.submitSuccess = false
        draft.submitError = undefined
        break
      case getType(actions.saveActivityDetailAsyncRequest.success):
        draft.isSubmitting = false
        draft.submitSuccess = true
        draft.currentActivity = dummyActivity
        draft.originalActivity.Images = []
        draft.originalActivity.Documents = []
        break
      case getType(actions.saveActivityDetailAsyncRequest.failure):
        draft.isSubmitting = false
        draft.submitSuccess = false
        if (!action.payload) {
          draft.submitError = 'Cannot reach API server. Please try again later'
        }

        draft.submitError = parseErrorMessage(action.payload.response.data)
        break
      case getType(actions.updateSeeMoreData):
        console.log('updateSeeMoreData')
        const { type, totalRows, page, data } = action.payload
        list = convertDataToList(data)
        list = filterActivity(list, state.filter)
        list = sortingTaskList(list, state.sorting.type, state.sorting.name)

        draft.seeMore = {
          ...draft.seeMore,
          [type]: {
            totalRows,
            page,
            data,
            list,
          },
        }
        break
      case getType(actions.currentSeeMorePage):
        draft.seeMore = {
          ...draft.seeMore,
          ...action.payload,
        }

        break
      case getType(actions.fetchSeeMoreActivitiesAsyncRequest.request):
        draft.isLoading = true
        draft.submitError = undefined
        break
      case getType(actions.fetchSeeMoreActivitiesAsyncRequest.success):
        draft.isLoading = false
        break
      case getType(actions.updatePaginationShowMorePages):
        console.log('updatePaginationShowMorePages')
        draft.showMorePages = action.payload
        break
      case getType(actions.fetchSeeMoreActivitiesAsyncRequest.failure):
        draft.isLoading = false
        draft.submitError = parseErrorMessage(action.payload)
        break

      case getType(actions.sortingActivity):
        console.log('sortingActivity')
        const sorting = state.sorting
        const params = action.payload
        if (sorting.name === params.name) {
          draft.sorting.type = sorting.type === 'asc' ? 'desc' : 'asc'
        } else {
          draft.sorting.name = params.name
          draft.sorting.type = 'asc'
        }
        if (params.sort != null) {
          draft.sorting.type = params.sort
        }

        const typ = state.seeMore.query.type
        let sortItem = state.seeMore[typ]

        list = sortingTaskList(
          sortItem.list,
          draft.sorting.type,
          draft.sorting.name
        )
        draft.seeMore[typ].list = list

        break
      case getType(actions.seeMoreDataFetched):
        draft.seeMoreDataFetch = true
        break
      case getType(actions.updateOriginalActivity):
        console.log('updateOriginalActivity')
        draft.originalActivity = action.payload
        break
      case getType(actions.fetchActivityHistoryAsyncRequest.request):
        draft.isFetchingHistory = true
        draft.submitError = undefined
        break
      case getType(actions.fetchActivityHistoryAsyncRequest.success):
        const response = action.payload
        draft.history = sortByRecentDate(response.ResponseData)
        draft.isFetchingHistory = false

        break
      case getType(actions.fetchActivityHistoryAsyncRequest.failure):
        draft.isFetchingHistory = false
        draft.fetchHistoryError = parseErrorMessage(action.payload)
        draft.history = []
        break
      case getType(actions.setFilterActivity):
        draft.filter = { ...action.payload }
        break
      case getType(actions.setSeemoreActivity):
        console.log('setSeemoreActivity')
        draft.seeMore[action.payload.type].list = action.payload.list
        break
      case getType(actions.fetchActivityAsyncRequest.request):
        draft.isLoading = true
        draft.submitError = undefined
        draft.originalActivity = {
          ActualStartDate: '',
          ActualFinishDate: '',
          LastCurProgress: 0,
          LastReqProgress: 0,
          Progress: 0,
          TaskStatus: 'REJT' as TaskStatus,
          Images: [],
          Documents: [],
          Remark: '',
        }
        break
      case getType(actions.fetchActivityAsyncRequest.success):
        payload = action.payload.ResponseData
        const original = Object.assign(
          {},
          {
            ActualStartDate: payload.ActualStartDate,
            ActualFinishDate: payload.ActualFinishDate,
            LastCurProgress: payload.LastCurProgress,
            LastReqProgress: payload.LastReqProgress,
            Progress: payload.Progress,
            TaskStatus: payload.TaskStatus,
            Images: [],
            Documents: [],
            Remark: '',
          }
        )

        draft.currentActivity = payload
        draft.originalActivity = original
        draft.isLoading = false
        draft.submitError = undefined
        break
      case getType(actions.fetchActivityAsyncRequest.failure):
        draft.isLoading = false
        draft.submitError = parseErrorMessage(action.payload)
        break
      case getType(actions.uploadFileAsyncRequest.request):
        draft.isSubmitting = true
        draft.submitSuccess = false
        draft.submitError = undefined
        break
      case getType(actions.uploadFileAsyncRequest.success):
        draft.UploadList = action.payload.ResponseData
        break
      default:
        return state
    }
  })
}

const sortByRecentDate = (histories: ActivityHistory[]) => {
  const sorted = histories.sort(function(
    a: ActivityHistory,
    b: ActivityHistory
  ) {
    const date1 = a.ActionDateTime
    const date2 = b.ActionDateTime

    return moment(date2).unix() - moment(date1).unix()
  })

  return sorted
}
