import { createStandardAction, createAsyncAction } from 'typesafe-actions'
import {
  GetCheckingUnitReq,
  GetCheckingUnitRes,
  AcceptCheckingTaskReq,
  RejectCheckingTaskReq,
  PostApiResponse
} from './types'

export const GetCheckingUnitAction = createAsyncAction(
  'qc/unit/checking/FETCH_CHECKING_REQUEST',
  'qc/unit/checking/FETCH_CHECKING_SUCCESS',
  'qc/unit/checking/FETCH_CHECKING_FAILURE'
)<GetCheckingUnitReq, GetCheckingUnitRes, any>()

export const sortingTask = createStandardAction('cmpj/seemoreqc/SORTING')<{
  name: string
  sort?: string
}>()

export const AcceptTaskAction = createAsyncAction(
  'qc/unit/checking/ACCEPT_TASK_REQUEST',
  'qc/unit/checking/ACCEPT_TASK_RESPONSE',
  'qc/unit/checking/ACCEPT_TASK_FAILURE'
)<AcceptCheckingTaskReq, PostApiResponse, any>()


export const RejectTaskAction = createAsyncAction(
  'qc/unit/checking/REJECT_TASK_REQUEST',
  'qc/unit/checking/REJECT_TASK_RESPONSE',
  'qc/unit/checking/REJECT_TASK_FAILURE'
)<RejectCheckingTaskReq, PostApiResponse, any>()