import React from 'react'
import BaseIcon from './BaseIcon'

const WarningIcon = (props: any) => {
  return (
    <BaseIcon
      img="/img/ic-warning.svg"
      onClick={props.onClick}
      size={props.size}
    />
  )
}

export default WarningIcon
