import React from 'react'
import { BreadcrumbLink } from 'Components/Shared/BreadCrumb/BreadCrumb'
import SubnavBar from 'Components/Shared/SubnavBar'

type Props = {
  name?: string
  onBack?: () => void
  breadcrumbs?: BreadcrumbLink[]
}

const Breadcrumbs: React.ComponentType<Props> = props => {
  const { name, onBack = () => {}, breadcrumbs = [] } = props

  if (name) {
    breadcrumbs.push({
      url: '',
      title: 'รายละเอียด',
      active: true,
      showTitle: true,
      onClick: onBack,
    })

    breadcrumbs.push({
      url: '',
      title: name,
      active: false,
      showTitle: false,
    })
  } else {
    breadcrumbs.push({
      url: '/',
      title: 'รายละเอียด',
      active: false,
      showTitle: false,
    })
  }

  return <SubnavBar breadcrumbs={breadcrumbs} hideSearch={true} />
}

export default Breadcrumbs
