import React, { useState } from 'react'
import { Row, Col } from 'antd'
import styled from 'styled-components'
import { TableHeader as Table, TableColHeader } from 'Components/Shared/Table'
import { CheckedIcon, UncheckedIcon } from 'Components/Shared/Icon'
import { RadioBox } from 'Components/Shared/Form'

type Props = {
  onSort(name: string): void
  sorting: any
  showRadio?: boolean
  onSelectAll?: () => void
  isSelectAll?: boolean
}

const ReportHeader: React.FunctionComponent<Props> = props => {
  const {
    onSort,
    sorting,
    showRadio = false,
    onSelectAll = () => {},
    isSelectAll = false,
  } = props

  const getRadioBox = () => {
    return (
      <RadioBox
        value={''}
        label={''}
        isCheck={isSelectAll}
        onClick={onSelectAll}
        size={24}
      />
    )
  }

  const column = [
    {
      title: 'ลักษณะงานหลัก',
      span: 5,
      key: '',
    },
    {
      title: 'ลักษณะงานรอง',
      span: 4,
      key: '',
    },
    {
      title: 'รายละเอียด',
      span: 3,
      key: '',
    },
    {
      title: 'ลักษณะ Defect',
      span: 3,
      key: '',
    },
    {
      title: 'คะแนน',
      span: 2,
      key: '',
    },
    {
      title: 'สถานะ',
      span: 3,
      key: '',
    },
    {
      title: showRadio ? getRadioBox() : '',
      span: 2,
      key: '',
      sort: false,
    },
  ]

  const content = column.map((item: any, index: number) => {
    return (
      <TableColHeader
        key={index}
        span={item.span}
        //onSort={() => onSort(item.key)}
        type=""
        sort={false}
      >
        {item.title}
      </TableColHeader>
    )
  })

  return (
    <Table>
      <TableColHeader span={2} type="" sort={false} isPadding={true}>
        หมุดที่
      </TableColHeader>
      {content}
    </Table>
  )
}

export default ReportHeader
