import React from 'react'
import styled from 'styled-components'
import { UndoIcon } from 'Components/Shared/Icon'
import { Modal } from 'antd'
import iconLogout from 'assets/img/ic-approval-logout.png'

interface Props {
  onClickReturn: () => void
  titleText: any
  disabled?: boolean
  returnDesc?: string
}

const LeftBoxContent: React.FC<Props> = props => {
  const {
    onClickReturn,
    titleText,
    disabled = false,
    returnDesc = 'หากคืนผัง หมุดและงานตรวจเอกสารที่เคยทำไว้จะถูก Reset ทั้งหมด คุณยืนยันที่จะคืนผังใช่หรือไม่',
  } = props

  const modalContent = (
    <ModalContent>
      <img src={iconLogout} />
      <div className="message">
        <span>{returnDesc}</span>
      </div>
    </ModalContent>
  )

  const returnTaskModal = () => {
    Modal.confirm({
      onOk() {
        onClickReturn()
      },
      width: '327px',
      onCancel() {},
      content: modalContent,
      okText: 'ใช่',
      okButtonProps: {
        className: 'btn-rectangle ok-btn',
      },
      cancelText: 'ยกเลิก',
      cancelButtonProps: {
        className: 'btn-rectangle cancle-btn',
      },
      centered: true,
      icon: null,
      className: 'login-modal-window',
    })
  }

  return (
    <LeftBox>
      <Center>
        {titleText}
        <ResetBox
          onClick={() => {
            if (disabled) return
            returnTaskModal()
          }}
          className={disabled ? 'disabled' : ''}
        >
          <UndoIcon size={16} color={disabled ? '#8b99a7' : '#003168'} />
          <span>คืนผัง</span>
        </ResetBox>
      </Center>
    </LeftBox>
  )
}

const LeftBox = styled.div`
  width: 100%;
  height: 100%;
  border-right: solid 1px #e4e7eb;
  float: left;
  display: flex;
  align-items: center;
  padding: 0 30px;
  p {
    font-size: 20px;
    // font-family: 'DB Helvethaica X 55 Regular';
    color: #000;
    line-height: 1;
    margin-bottom: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

const ResetBox = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  color: #003168;
  font-size: 20px;
  padding-top: 5px;
  cursor: pointer;
  span {
    padding-left: 12px;
  }

  &.disabled{
    color : #8b99a7
    cursor: not-allowed;
  }
`

const Center = styled.div`
  width: 100%;
`

const ModalContent = styled.div`
  text-align: center;
  border-radius: 10px;
  .message {
    padding-top: 18px;
    span {
      font-size: 20px;
      color: #000;
    }
  }
  img {
    width: 60px;
  }
`

export default LeftBoxContent
