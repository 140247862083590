import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'

import { Layout } from 'antd'
import TopNav from 'Components/Shared/TopNav'

import AdminPrimavera from 'Components/AdminPJ/Primavera/List'
import PrimaveraDetail from 'Components/AdminPJ/Primavera/ActivityDetail'
import WelcomePage from 'Components/AdminPJ/Welcome/Administrator'
import LocalChecklist from 'Components/AdminPJ/LocalChecklist/List'

import ModelList from 'Components/AdminQC/Model/List'
import MainconChecklistDetail from 'Components/Inspection/Activity/MainconChecklistDetail'
import { QueryParamProvider } from 'use-query-params'
import ModelHouse from './AdminPJ/ModelHouse'

const AdminPJApp = () => {
  return (
    <BrowserRouter>
      <QueryParamProvider ReactRouterRoute={Route}>
        <Layout className="layout" hasSider={false}>
          <TopNav title="ADMIN PJ" />
          <Route exact path="/" component={AdminPrimavera} />

          <Switch>
            <Route exact path="/dashboard" component={WelcomePage} />
            <Route exact path="/primavera" component={AdminPrimavera} />
            <Route
              exact
              path="/primavera/:id/:status"
              component={PrimaveraDetail}
            />
            <Route exact path="/model" component={ModelList} />
            <Route exact path="/model-house" component={ModelHouse} />
            <Route exact path="/vendor" component={AdminPrimavera} />
            <Route exact path="/report" component={AdminPrimavera} />

            <Route exact path="/local-checklist" component={LocalChecklist} />
            <Route
              exact
              path="/local-checklist/:id/edit"
              component={MainconChecklistDetail}
            />
            <Route
              exact
              path="/local-checklist/create"
              component={MainconChecklistDetail}
            />
          </Switch>
        </Layout>
      </QueryParamProvider>
    </BrowserRouter>
  )
}

export default AdminPJApp
