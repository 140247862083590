import { put, take, select, takeEvery } from 'redux-saga/effects'
import { getType } from 'typesafe-actions'
import * as actions from './wbs.actions'
import * as searchAction from '../search/search.actions'
import { changeSelectedProjectAction } from 'features/selectedProject/selectedProject.actions'
import { setProjectCode } from 'features/approval/dashboard/actions'
import { fetchWbsListRequest } from './wbs.api'
import { getWbsMap } from './wbs.function'
import {
  userEmailSelector,
  serviceTokenSelector,
  getUserProfile,
} from 'features/sensitive/sensitive.selector'
import {
  projectCodeWithSuffixSelector,
  selectedProjectCodeSelector,
} from 'features/project/project.selector'
import * as loginActions from 'features/login/login.actions'
import {
  fetchWbsListAsyncAction,
  updateWbsPathMap,
  updateWbsMapParent,
  setWbsProjectCode,
  updateWbsEvent,
} from './wbs.actions'

import { getWbsProjectCode } from './wbs.selector'

import { Wbs } from './wbs.types'
import WbsPathGenerator from './wbs-path-generator'
import keyByFieldName from 'utils/keyByFieldName'

function* fetchWbsList() {
  const email = yield select(userEmailSelector)
  const token = yield select(serviceTokenSelector)
  const userProfile = yield select(getUserProfile)
  const isMasterplan = userProfile.RoleName === 'Master Plan'
  const code = yield select(
    isMasterplan ? projectCodeWithSuffixSelector : selectedProjectCodeSelector
  )

  yield put(fetchWbsListRequest({ email, projectCode: code, token }))

  const action = yield take(fetchWbsListAsyncAction.success)
  const wbsList: GenericApiResponse<Wbs[]> = action.payload
  const wbsResponseData = wbsList.ResponseData as Wbs[]
  const wbsMapsParent = getWbsMap(wbsResponseData)
  const wbsPathGenerator = new WbsPathGenerator(wbsResponseData)
  const wbsPathMaps = wbsPathGenerator.generate()
  yield put(updateWbsPathMap(wbsPathMaps))
  yield put(updateWbsMapParent(wbsMapsParent))
  const mapper: any = {}
  for (const item of wbsResponseData) {
    mapper[item.WbsID] = item.WbsIName
  }

  yield put(searchAction.updateWBS(mapper))
}

function* getWbsList(action: any) {
  const { UserEmail, ProjectCode, Token } = action.payload
  try {
    const currentCode = yield select(getWbsProjectCode)
    if (currentCode === ProjectCode) return

    yield put(updateWbsEvent(true))

    yield put(
      fetchWbsListRequest({
        email: UserEmail,
        projectCode: ProjectCode,
        token: Token,
      })
    )

    const action = yield take(fetchWbsListAsyncAction.success)
    const wbsList: GenericApiResponse<Wbs[]> = action.payload
    yield put(updateWbsEvent(true))
    const wbsResponseData = wbsList.ResponseData as Wbs[]
    const wbsMapsParent = getWbsMap(wbsResponseData)
    const wbsPathGenerator = new WbsPathGenerator(wbsResponseData)
    const wbsPathMaps = wbsPathGenerator.generate()
    yield put(updateWbsPathMap(wbsPathMaps))
    yield put(updateWbsMapParent(wbsMapsParent))
    const mapper: any = {}
    for (const item of wbsResponseData) {
      mapper[item.WbsID] = item.WbsIName
    }

    yield put(searchAction.updateWBS(mapper))
    yield put(setWbsProjectCode(ProjectCode))
    yield put(updateWbsEvent(false))
  } catch (e) {}
}

function* verifyFail(action: any) {
  const { payload = {} } = action
  let { response } = payload
  if (!response) {
    response = { status: 401 }
  }

  const { status = 200 } = response
  if (status === 401) {
    yield put(loginActions.logoutUser())
  }
  yield take(actions.fetchWbsListAsyncAction.failure)
}

function* allSaga() {
  yield takeEvery(getType(changeSelectedProjectAction), fetchWbsList)
  yield takeEvery(getType(setProjectCode), fetchWbsList)
  yield takeEvery(getType(actions.fetchWbsListAsyncAction.failure), verifyFail)
  yield takeEvery(getType(actions.getWbsList), getWbsList)
}

export default allSaga
