import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Row, Col } from 'antd'
import { ArrowNextIcon } from 'Components/Shared/Icon'
import { UnitItem } from 'features/masterData/types'
import LocationModal from './LocationModal'
import { isUnit } from 'utils/helper'

type Props = {
  unitList: UnitItem[]
  selectedList: UnitItem[]
  readOnly?: boolean
  onSaveUnit?: (item: UnitItem[]) => void
}

const UnitBox: React.FC<Props> = ({
  unitList,
  selectedList,
  readOnly = false,
  onSaveUnit = (item: UnitItem[]) => {},
}) => {
  const [showLocation, setShowLocation] = useState(false)

  const selectValue = () => {
    return selectedList
      .map(item => {
        return isUnit(item.Zone)
          ? item.Unit
          : `${item.Unit} - ชั้น ${item.Floor}`
      })
      .join()
  }

  const value = selectValue()

  const contentValue = (
    <Row type="flex" justify="space-between">
      <Col span={21}>
        <Placeholder>{value ? <Value>{value}</Value> : 'เลือก'}</Placeholder>
      </Col>
      <Col span={2}>
        <ArrowNextIcon />
      </Col>
    </Row>
  )

  return (
    <ContentView>
      <InputMultivalue onClick={() => setShowLocation(true)}>
        <Row
          type="flex"
          justify="space-between"
          align="middle"
          style={{ height: '100%' }}
        >
          <Col span={12}>พื้นที่</Col>
          <Col span={12}>{contentValue}</Col>
        </Row>
      </InputMultivalue>
      <LocationModal
        onClose={() => setShowLocation(false)}
        visible={showLocation}
        unitList={unitList}
        selectedList={selectedList}
        readonly={readOnly}
        onSave={(item: UnitItem[]) => {
          onSaveUnit(item)
          setShowLocation(false)
        }}
      />
    </ContentView>
  )
}

const ContentView = styled.div`
  width: 100%;
  margin-bottom: 14px;
`

const InputMultivalue = styled.div`
  height: 48px;
  color: black;
  text-align: left;
  background-color: white;
  border: solid 1px #e4e7eb;
  border-radius: 5px;
  font-family: 'DB Helvethaica X 55 Regular';
  font-size: 20px;
  padding: 0 15px;
  &.disabled {
    background-color: #f3f3f3;
  }
`

const Placeholder = styled.div`
  color: black;
  cursor: pointer;
  text-align: end;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const Value = styled.span`
  font-family: 'DB Helvethaica X 75 Bd';
`

export default UnitBox
