import { createStandardAction, createAsyncAction } from 'typesafe-actions'
import { GetApiResponse, PaginationType } from '../../types/common'
import { History } from 'history'

import { ZoneList, ZoneDetail, ProjectZone, ZoneType } from './types'

export const reset = createStandardAction('admin/zone/RESET_ACTION')<any>()

export const getZoneListReq = createAsyncAction(
  'admin/zone/GET_ZONE_LIST_REQUEST',
  'admin/zone/GET_ZONE_LIST_SUCCESS',
  'admin/zone/GET_ZONE_LIST_FAILURE'
)<PaginationType, GetApiResponse<ZoneList>, Error>()

export const getZoneDetailReq = createAsyncAction(
  'admin/zone/GET_ZONE_DETAIL_REQ',
  'admin/zone/GET_ZONE_DETAIL_RESP',
  'admin/zone/GET_ZONE_DETAIL_FAIL'
)<{ CheckListID: number }, GetApiResponse<ZoneDetail>, Error>()

export const getProjectZoneReq = createAsyncAction(
  'admin/zone/GET_PROJECT_ZONE_REQ',
  'admin/zone/GET_PROJECT_ZONE_RESP',
  'admin/zone/GET_PROJECT_ZONE_FAIL'
)<{ UserEmail: string }, GetApiResponse<ProjectZone[]>, Error>()

export const deleteReq = createAsyncAction(
  'admin/zone/DELETE_ZONE_REQUEST',
  'admin/zone/DELETE_ZONE_SUCCESS',
  'admin/zone/DELETE_ZONE_FAILURE'
)<{ CheckListID: number; page: PaginationType }, void, Error>()

export const clearZoneDetail = createStandardAction(
  'admin/zone/CLEAR_ZONE_DETAIL'
)<void>()

export const GetZoneTypeListReq = createAsyncAction(
  'admin/zone/GET_ZONE_TYPE_LIST_REQ',
  'admin/zone/GET_ZONE_TYPE_LIST_RESP',
  'admin/zone/GET_ZONE_TYPE_LIST_FAIL'
)<any, GetApiResponse<ZoneType[]>, Error>()

export const createUpdateZoneReq = createAsyncAction(
  'admin/zone/CREATE_UPDATE_ZONE_REQ',
  'admin/zone/CREATE_UPDATE_ZONE_RESP',
  'admin/zone/CREATE_UPDATE_ZONE_FAIL'
)<ZoneDetail, void, Error>()

export const updateCheckList = createStandardAction(
  'checklist/zone/UPDATE_CHECKLIST_DETAIL'
)<ZoneDetail>()
