import RootState from 'typings/RootState'
import { createSelector } from 'reselect'
import {
  DashboardData,
  DashboardEvent,
  DashboardFilter,
  DashboardQuery,
} from './types'

export const selectData = (state: RootState): DashboardData =>
  state.cmpjCmDashboard.data
export const selectEvent = (state: RootState): DashboardEvent =>
  state.cmpjCmDashboard.event

export const selectSorting = (state: RootState) => state.cmpjCmDashboard.sorting
export const selectFilter = (state: RootState): DashboardFilter =>
  state.cmpjCmDashboard.filter

export const selectQuery = (state: RootState): DashboardQuery =>
  state.cmpjCmDashboard.query
