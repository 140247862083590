import React, { useRef } from 'react'
import { Col, Layout, Row } from 'antd'
import styled from 'styled-components'
import {
  ReportHeader,
  ReportLogo,
  DescItem,
  TaskScore,
  StatusTag,
  Signature,
} from 'Components/AdminQC/Report/Components'
import { DataDetail } from 'features/adminReport/constructRoad/types'
import DefectItem from './DefectItem'
import { toDateText, toMomentDateTime } from 'utils/datetime'
import { DefectItem as Defect } from 'features/report/constructRoad/types'
import mapUnitStatus, { unitStatusIsPass } from 'utils/status-map'

const { Content } = Layout

type Props = {
  data: DataDetail
  onSeeDetail?: () => void
}

const ReportDetail: React.ComponentType<Props> = props => {
  const componentPrint = useRef(null)
  const { data, onSeeDetail = () => {} } = props
  const renderDescItem = (title: any, value: any) => {
    return <DescItem title={title} value={value || '-'} percentW={20} />
  }

  const getSeeDetail = () => {
    return (
      <>
        พื้นที่
        <SeeDetail onClick={onSeeDetail}>ดูรายละเอียดงาน</SeeDetail>
      </>
    )
  }

  const statusTxt = mapUnitStatus(data.CheckStatus).status
  const isPass = unitStatusIsPass(true, data.CheckStatus)
  const DescContent = (
    <DesBox>
      <Row type="flex">
        {renderDescItem('โครงการ', data.ProjectNameTH)}
        {renderDescItem('หมวดงาน', data.QCTypeName)}
        {renderDescItem(getSeeDetail(), data.Unit)}
        {renderDescItem('ผู้รับเหมาหลัก', data.MainConUser)}
        {renderDescItem(
          'ผลการตรวจ',
          <StatusTag text={statusTxt} isPass={isPass} />
        )}
        {renderDescItem(
          'วันที่ตรวจ',
          toDateText(toMomentDateTime(data.InspectDate))
        )}
        {renderDescItem(
          'วันที่ส่งรายงาน',
          toDateText(toMomentDateTime(data.CheckedDate))
        )}
        {renderDescItem('ผู้ตรวจ', data.CheckedBy)}
        {renderDescItem('ผู้ควบคุมงาน', data.ControllerUser)}
        <Col style={{ width: '20%' }}>
          <TaskScore
            checkStatus={data.CheckStatus}
            score={data.InspectionScore}
          />
        </Col>
      </Row>
    </DesBox>
  )

  const DefectItemContent = data.DefectList.map(
    (item: Defect, index: number) => {
      return <DefectItem key={index} index={index} defect={item} />
    }
  )

  return (
    <Content className="mid-content">
      <div className="main-content activity-detail">
        <ReportHeader
          title="ระหว่างก่อสร้างถนน"
          componentPrint={componentPrint}
        />
        <Box ref={componentPrint}>
          <ReportLogo title="ถนน  -  ระหว่างก่อสร้างถนน" />
          {DescContent}
          <ContentBox>{DefectItemContent}</ContentBox>
          <Signature data={data.Signature} />
        </Box>
      </div>
    </Content>
  )
}

const Box = styled.div`
  background-color: rgba(0, 49, 104, 0.05);
  border-radius: 8px;
  border: solid 1px #e4e7eb;
  color: #000;
  @media print {
    background-color: white;
    border: solid 0px #e4e7eb;
  }
`

const DesBox = styled.div`
  padding: 12px 24px;
  background-color: #fff;
`

const ContentBox = styled.div`
  padding: 24px;
`

const SeeDetail = styled.span`
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 18px;
  text-decoration: underline;
  color: #003168;
  margin-left: 12px;
  cursor: pointer;
`

export default ReportDetail
