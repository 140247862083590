import React from 'react'
import styled from 'styled-components'
import { DropdownList } from 'Components/Shared/Form'

type Props = {
  onFilterChange: (filter: any) => void
  filter: any
  projectType: any[]
  canSelectAll?: boolean
}

const ProjectTypeFilter: React.FunctionComponent<Props> = props => {
  const { onFilterChange, filter, projectType, canSelectAll = true } = props

  const mapProjectType = () => {
    let types = [] as any
    if (canSelectAll) {
      types.push({
        name: `ทั้งหมด`,
        value: '',
      })
    }

    projectType.forEach(item => {
      types.push({
        value: item.ProjectTypeID,
        name: item.ProjectTypeName,
      })
    })
    return types
  }

  return (
    <Box>
      <DropdownList
        placeholder="เลือกประเภทโครงการ"
        label="ประเภทโครงการ"
        value={filter.ProjectTypeID}
        menu={mapProjectType()}
        onChange={(val: any) => {
          filter.ProjectTypeID = val
          onFilterChange(filter)
        }}
      />
    </Box>
  )
}

const Box = styled.div`
  padding: 0 12px 12px 0;
`

export default ProjectTypeFilter
