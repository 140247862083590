import React from 'react'
import PopupMenu from 'Components/Shared/Filters/PopupMenu'
import Content from 'Components/Shared/Filters/CommonContent'

type Props = {
  onFilterChange: (filter: any) => void
  filter: any
}

const CommissioningTypeFilter: React.FunctionComponent<Props> = props => {
  const { onFilterChange, filter } = props

  const commissioningTyes = [
    {
      name: 'Q1',
      value: 1,
    },
    {
      name: 'Commissioning',
      value: 2,
    },
    {
      name: 'Safety',
      value: 3,
    },
  ]

  return (
    <PopupMenu
      label={'หมวดงาน'}
      content={
        <Content
          data={commissioningTyes}
          filter={filter.CommissionTypeList}
          onChange={data => {
            filter.CommissionTypeList = data
            onFilterChange(filter)
          }}
        />
      }
      isActive={filter.CommissionTypeList.length > 0}
    />
  )
}

export default CommissioningTypeFilter
