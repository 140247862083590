import React, { useState, useEffect } from 'react'
import { RootState } from 'typings/RootState'
import { connect } from 'react-redux'
import { ProjectListItem } from 'features/project/project.types'
import { fetchProjects } from 'features/project/project.api'
import {
  projectsByIdSelector,
  projectSubmitErrorSelector,
  tokenExpiredSelector,
  projectListStatusSelector,
  isSubmittingSelector,
} from 'features/project/project.selector'
import {
  serviceTokenSelector,
  userEmailSelector,
} from 'features/sensitive/sensitive.selector'
import { changeSelectedProjectAction } from 'features/selectedProject/selectedProject.actions'
import { selectedProjectCodeSelector } from 'features/selectedProject/selectedProject.selector'
import { forceLogin } from 'features/login/login.actions'
import { translate } from 'i18n'
import TKeys from 'i18n/translationKey'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { Dropdown } from 'antd'
import styled from 'styled-components'
import Loading from './Loading'
import SearchSelection from 'Components/Inspection/Activity/ActivityList/SearchSelection'
import { cloneDeep } from 'lodash'
import icSearch from 'assets/img/ic-search-grey.png'
import { Input } from 'antd'

type ProjectSelectorProps = {
  serviceToken: string
}

type Props = ReduxProps &
  DispatchProps &
  RouteComponentProps &
  ProjectSelectorProps

type ProjectListItemActiveInactive = ProjectListItem & { active?: boolean }
const projectEmpty = translate(TKeys.Dashboard.project_list_empty_message)

const ProjectSelector: React.FunctionComponent<Props> = ({
  projectsById,
  projectCode,
  serviceToken,
  userEmail,
  tokenExpired,
  forceLogin,
  emptyProjectList,
  fetchProjects,
  changeSelectedProjectAction,
  history,
  isSubmitting,
}) => {
  const [displayDropdown, setDisplayDropdown] = useState(false)
  const [projectFilter, setProjectFilter] = useState([] as ProjectListItem[])
  const [searchTxt, setSearchTxt] = useState('')

  useEffect(() => {
    const projects: ProjectListItem[] = Object.values(projectsById)
    setProjectFilter(projects)
    setSearchTxt('')
  }, [projectsById])

  useEffect(() => {
    filterProject()
  }, [searchTxt])

  useEffect(() => {
    fetchProjects({ email: userEmail, token: serviceToken })
  }, [userEmail, serviceToken, fetchProjects])

  const generateProjectChoices = () => {
    if (projectFilter.length === 0) return undefined

    return projectFilter.map((project: ProjectListItemActiveInactive) => {
      const isActive = project.ProjectCode === projectCode

      return (
        <ProjectListItems
          key={project.ProjectCode}
          onClick={e => {
            changeProject(project.ProjectCode)
            setDisplayDropdown(false)
            history.push('../../../../')
          }}
          className={isActive ? 'active' : ''}
        >
          {getProjectName(project)}
          <i className="icon-checked" />
        </ProjectListItems>
      )
    })
  }

  const changeProject = (code: string) => {
    changeSelectedProjectAction(code)
  }

  const hasText = (data: string, searchTxt: string) => {
    if (data && searchTxt) {
      return data.toLocaleLowerCase().includes(searchTxt.toLocaleLowerCase())
    }
    return false
  }

  const filterProject = () => {
    let list = cloneDeep(Object.values(projectsById))
    if (searchTxt !== '') {
      list = list.filter(item => {
        return (
          hasText(item.ProjectCode, searchTxt) ||
          hasText(item.ProjectNameTH, searchTxt) ||
          hasText(item.ProjectNameEN, searchTxt)
        )
      })
    }
    setProjectFilter(list)
  }

  // Handle expired token case
  useEffect(() => {
    if (tokenExpired) {
      forceLogin(true)
    }
  }, [tokenExpired, forceLogin])

  const getProjectName = (project: ProjectListItem) =>
    `${project.ProjectCode} - ${project.ProjectNameTH}`

  const selectedProject = projectsById[projectCode]
  let content
  if (isSubmitting) content = <Loading color="white" />
  else if (emptyProjectList) {
    content = projectEmpty
  } else if (selectedProject && selectedProject.ProjectCode) {
    content = getProjectName(selectedProject)
  } else {
    content = translate(TKeys.Dashboard.project_select)
  }
  const projectList = (
    <ProjectListContent>
      <SearchArea onClick={(event: any) => event.stopPropagation()}>
        <InputSearch>
          <Input
            placeholder={'ค้นหา'}
            suffix={<img src={icSearch} />}
            onChange={evt => setSearchTxt(evt.target.value)}
            value={searchTxt}
            autoFocus={true}
          />
        </InputSearch>
      </SearchArea>
      {generateProjectChoices()}
    </ProjectListContent>
  )

  return (
    <Dropdown overlay={projectList} trigger={['click']}>
      <div className="projects-list-wrapper">
        <div className="projects-list-dropdown">
          <span>
            {content}
            <img src="/ic-down-white.svg" alt="Project Lists" />
          </span>
        </div>
      </div>
    </Dropdown>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    projectsById: projectsByIdSelector(state),
    projectCode: selectedProjectCodeSelector(state),
    serviceToken: serviceTokenSelector(state),
    userEmail: userEmailSelector(state),
    submitError: projectSubmitErrorSelector(state),
    isSubmitting: isSubmittingSelector(state),
    tokenExpired: tokenExpiredSelector(state),
    emptyProjectList: projectListStatusSelector(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {
  forceLogin,
  fetchProjects,
  changeSelectedProjectAction,
}

const ProjectListContent = styled.div`
  width: 420px;
  height: auto;
  max-height: 420px;
  overflow-x: scroll;
  border-radius: 5px;
  background: #fff;
  padding: 16px 24px 8px;
  box-shadow: 0 1px 4px 0 #ccc;
  border-top: none;
  margin-top: -7px;
`

const ProjectListItems = styled.div`
  color: #000;
  line-height: normal;
  height: 40px;
  font-family: 'DB Helvethaica X 55 Regular';
  font-size: 18px;
  &:hover {
    cursor: pointer;
    color: #003168;
  }
  .icon-checked {
    display: none;
  }
  &.active {
    font-family: 'DB Helvethaica X 75 Bd';
    .icon-checked {
      display: inline;
    }
  }
`

const SearchArea = styled.div`
  margin: 10px 0 20px 0;
`

const InputSearch = styled.div`
  .ant-input-affix-wrapper {
    .ant-input {
      height: 48px;
      border-radius: 5px;
      font-size: 18px;
      font-family: 'DB Helvethaica X 55 Regular';
    }
  }

  .ant-input-affix-wrapper > .ant-input-suffix {
    img {
      width: 16px;
      height: 16px;
    }
  }
`

type DispatchProps = typeof mapDispatchToProps

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ProjectSelector))
