import React, { useState } from 'react'
import { Layout, Row, Col } from 'antd'
import styled from 'styled-components'
import { saveAs } from 'file-saver'
import { translate } from 'i18n'
import TKeys from 'i18n/translationKey'

import file from 'utils/file'
import { LIMIT_FILE } from 'config'

import Picker from '../Form/Picker'
import { TrashBtn, DownloadDisableBtn } from '../Button'
import Editor from '../Editor'

import imgCheck from 'assets/img/ic-check.png'
import { InputText } from '../Input'
import { CloseIcon } from '../Icon'

type ImageList = {
  Image: any
  Remark: string
}

type Props = {
  readOnly: boolean
  images: ImageList[]
  numberOfImage?: number
  onClose: () => void
  onThumbnailOpen: (index: number) => void
  onChange: (list: ImageList[]) => void
  breadcrumb?: any
  onUploadImage: (image: any) => void
  onEditMode?: (isEditMode: boolean) => void
}

const ImageListMode: React.ComponentType<Props> = props => {
  const [showEditor, setShowEditor] = useState(false)
  const [fileEditor, setFileEditor] = useState({
    base64: '',
    file: { name: '' },
    lastModified: 0,
  })
  const [loading, setLoading] = useState(false)
  const [showError, setShowError] = useState(false)
  const [error, setError] = useState('')

  const [edit, setEdit] = useState(false)
  const [checkList, setCheckList] = useState([] as number[])

  const {
    images,
    readOnly,
    onChange,
    breadcrumb,
    numberOfImage = 5,
    onUploadImage,
    onEditMode = () => {},
  } = props

  const addFile = async (base64: any, fileItem: any) => {
    try {
      const lastModified = fileItem.lastModified
      let resp
      if (fileItem.type === 'image/heic') {
        setLoading(true)
        resp = await file.heicToJpeg({
          file: fileItem,
          filename: fileItem.name,
        })
        base64 = resp.text
        fileItem = resp.file
        setLoading(false)
      }

      if (fileItem.size < LIMIT_FILE.image) {
        resp = await file.compress({
          value: base64,
          file: fileItem,
          limit: LIMIT_FILE.image,
        })
        base64 = resp.text
        fileItem = resp.file
      }

      setShowEditor(true)
      setFileEditor({ base64, file: fileItem, lastModified })
    } catch (e) {
      setError(translate(TKeys.Error.file_wrong))
      setShowError(true)
    }

    setLoading(false)
  }

  const onAddImage = async (base64: string) => {
    try {
      const params = {
        data: base64,
        filename: fileEditor.file.name,
        lastModified: fileEditor.lastModified,
      }
      const fileItem: any = await file.base64ToFile(params)

      // images.unshift({
      //   Image: {
      //     text: base64,
      //     file: fileItem,
      //     lastModified: fileItem.lastModified,
      //   },
      //   Remark: '',
      // })

      // onChange(images)
      onUploadImage({
        text: base64,
        file: fileItem,
        lastModified: fileItem.lastModified,
      })

      const newList: number[] = []
      checkList.forEach(item => newList.push(item + 1))
      setCheckList(newList)
    } catch (e) {
      setError(e.message)
      setShowError(true)
    }
    setShowEditor(false)
  }

  const addIndex = (index: number) => {
    checkList.push(index)
    setCheckList([...checkList])
  }

  const removeIndex = (index: number) => {
    let i = checkList.indexOf(index)
    if (i >= 0) {
      checkList.splice(i, 1)
      setCheckList([...checkList])
    }
  }

  const onDownload = () => {
    checkList.forEach(async index => {
      let item = images[index]

      let img = await file.urlToBase64({ url: item.Image.FileURL })
      await saveAs(img, item.Image.FileName)
    })
  }

  const onRemove = () => {
    if (checkList.length > 0) {
      let newList = []
      let index = 0
      for (let item of images) {
        if (checkList.indexOf(index) < 0) {
          newList.push(item)
        }
        index++
      }

      onChange(newList)
      setCheckList([])
    }
  }

  const onChangeMode = (isEdit: boolean) => {
    setEdit(isEdit)
    onEditMode(isEdit)
  }

  const textPicture = translate(TKeys.Common.picture)

  let title
  if (edit) {
    let removeBtn = readOnly ? undefined : <TrashBtn onClick={onRemove} />
    title = (
      <Col span={24}>
        <EditTitle className="std-font-regular">
          <span>
            <CloseIcon
              color="#8b99a7"
              size={24}
              onClick={() => onChangeMode(false)}
            />
          </span>
          <label>
            <DownloadDisableBtn onClick={onDownload} />
            {removeBtn}
          </label>
        </EditTitle>
      </Col>
    )
  } else {
    title = (
      <Col span={24}>
        <Title className="std-font-bold">
          <label>{textPicture}</label>
          <span className="std-font-regular" onClick={() => onChangeMode(true)}>
            {translate(TKeys.Common.pick_picture)}
          </span>
        </Title>
      </Col>
    )
  }

  const content = images.map((item, index) => {
    let btn
    let imgDiv
    let img =
      item.Image.FileID && item.Image.FileURL
        ? item.Image.FileURL
        : item.Image.text
    if (edit) {
      const hasChecked = checkList.includes(index)
      let click
      if (hasChecked) {
        click = () => removeIndex(index)
        btn = <Checked src={imgCheck} alt="" onClick={click} />
      } else {
        click = () => addIndex(index)
        btn = <Unchecked onClick={click} />
      }

      imgDiv = <LinkImage src={img} alt="" onClick={click} />
    } else {
      imgDiv = (
        <Image src={img} alt="" onClick={() => props.onThumbnailOpen(index)} />
      )
    }

    return (
      <Col span={6} style={css.col} key={index}>
        {imgDiv}
        {btn}
        <InputArea>
          <InputText
            prefix=""
            value={item.Remark}
            placeholder="หมายเหตุ"
            onChange={(val: string) => {
              images[index].Remark = val
              onChange(images)
            }}
            disabled={readOnly || edit}
          />
        </InputArea>
      </Col>
    )
  })

  return (
    <>
      <Editor
        visible={showEditor}
        image={fileEditor.base64}
        onClose={() => setShowEditor(false)}
        onOk={onAddImage}
      />
      {breadcrumb}
      <Layout.Content className="mid-content">
        <div className="main-content activity-detail">
          <DataArea>
            <Row style={{ marginBottom: '24px', marginRight: '16px' }}>
              {title}
            </Row>
            <Row>
              <Col span={6} style={css.col}>
                <Picker
                  disabled={images.length === numberOfImage || readOnly || edit}
                  loading={loading}
                  height="260"
                  width="260"
                  text={textPicture}
                  accept=".png,.jpg,.heic"
                  onChange={addFile}
                />
              </Col>
              {content}
            </Row>
          </DataArea>
        </div>
      </Layout.Content>
    </>
  )
}

const css: any = {
  lastCol: {
    width: '260px',
    marginBottom: '22px',
  },
  col: {
    textAlign: 'right',
    width: '260px',
    marginRight: '16px',
    marginBottom: '22px',
  },
}

const Title = styled.div`
  height: 30px;
  label {
    font-size: 28px;
    color: black;
    position: absolute;
    bottom: 0;
  }

  span {
    font-size: 18px;
    color: #003168;
    position: absolute;
    bottom: 0;
    right: 0;
  }

  span:hover {
    cursor: pointer;
  }
`

const EditTitle = styled.div`
  font-size: 18px;
  color: #003168;
  height: 30px;

  span:hover {
    cursor: pointer;
  }

  label {
    float: right;

    img:last-child {
      margin-left: 24px;
    }
  }
`

const CssImg = `
  width: 260px;
  height: 260px;
  object-fit: cover;
  border-radius: 5px;
`
const Image = styled.img`
  ${CssImg}
`

const LinkImage = styled.img`
  ${CssImg}
  :hover {
    cursor: pointer;
  }
`

const Checked = styled.img`
  width: 24px;
  height: 24px;
  object-fit: contain;
  position: absolute;
  z-index: 2;
  right: 8px;
  bottom: 70px;
  :hover {
    cursor: pointer;
  }
`

const Unchecked = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid white;
  position: absolute;
  z-index: 2;
  right: 8px;
  bottom: 70px;
  background-color: rgba(0, 0, 0, 0.3);
  :hover {
    cursor: pointer;
  }
`

const InputArea = styled.div`
  margin-top: 16px;
  .ant-input {
    text-align: left !important;
  }
`

const DataArea = styled.div`
  //padding-bottom: 20px;
`

export default ImageListMode
