import React from 'react'
import SubnavBar from 'Components/Shared/SubnavBar'
import { BreadcrumbLink } from 'Components/Shared/BreadCrumb/BreadCrumb'

type Props = {
  project?: string
  unit?: string
  requestID?: string
  qcType?: string
  module : 'QC' | 'CM/PJ' | 'Main Con'
}

const Breadcrumbs: React.ComponentType<Props> = props => {
  const { project, unit, requestID ,qcType ,module} = props

  let backUrl = ''
  let homePage = ''
  if(module === 'QC'){
    backUrl = `/unit/report/${project}/${unit}/${qcType}/${requestID}`
    homePage = '/unit/dashboard'
  }
  if(module === 'CM/PJ'){
    backUrl = `/qcreport/${unit}/${qcType}/${requestID}`
    homePage = '/qcdashboard'
  }

  if(module === 'Main Con'){
    backUrl = `/qcreport/${unit}/${qcType}/${requestID}`
    homePage = '/'
  }
  
  
  const breadcrumbs: BreadcrumbLink[] = [
    {
      url: homePage,
      title: 'Home',
      icon: '/home.png',
      active: true,
      showTitle: false,
    },
    {
      url: homePage,
      title: 'บอร์ด',
      active: true,
      showTitle: true,
    },
    {
      url: backUrl,
      title: 'ตรวจหน้างาน',
      active: true,
      showTitle: true,
    },
    {
      url: ``,
      title: 'รายงานการตรวจสอบ Function',
      active: false,
      showTitle: true,
    },
  ]

  return <SubnavBar breadcrumbs={breadcrumbs} hideSearch={true} />
}

export default Breadcrumbs
