import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Collapse } from 'antd'
import styled from 'styled-components'
import { translate } from 'i18n'
import { RootState } from 'typings/RootState'
import TKeys from 'i18n/translationKey'
import CheckAsOptions from 'Components/Shared/Filters/CheckAsOptions'

import { Wbs } from 'features/wbs/wbs.types'

const { Panel } = Collapse

type Props = {
  filter: any
  wbsList: Wbs[]
  onChange(filter: any): void
}

const WbsFilter: React.FunctionComponent<Props> = ({
  filter,
  onChange,
  wbsList,
}) => {
  const [display, setDisplay] = useState({} as any)

  const onDisplayChange = (key: any, status: boolean) => {
    if (status) {
      display[key] = true
    } else {
      delete display[key]
    }

    setDisplay({ ...display })
  }

  const removeKey = (key: any, list: any[]): any[] => {
    const index = list.findIndex(item => item === key)
    if (index >= 0) {
      list.splice(index, 1)
    }

    return list
  }

  const onItemToggle = (item: any, value: boolean) => {
    onDisplayChange(item.WbsID, value)
  }

  const makeCheck = (item: any, wbs: any[] = []) => {
    const { Children = [] } = item

    for (const it of Children) {
      const already: boolean = wbs.includes(it.WbsID)
      if (already === false) wbs.push(it.WbsID)

      makeCheck(it, wbs)
    }
  }

  const makeUncheck = (item: any, wbs: any[] = []) => {
    const { Children = [] } = item

    for (const it of Children) {
      wbs = removeKey(it.WbsID, wbs)
      wbs = makeUncheck(it, wbs)
    }

    return wbs
  }

  const onItemChange = (item: any, value: boolean, parent?: any) => {
    let wbs = filter.WbsList

    // checked on menu
    if (value) {
      wbs.push(item.WbsID)
      makeCheck(item, wbs)

      if (parent) {
        let checkParent = true
        for (const it of parent.Children) {
          if (wbs.includes(it.WbsID) === false) {
            checkParent = false
            break
          }
        }

        const already: boolean = wbs.includes(parent.WbsID)
        if (checkParent && already === false) {
          wbs.push(parent.WbsID)
        }
      }
    } else {
      wbs = removeKey(item.WbsID, wbs)
      wbs = makeUncheck(item, wbs)

      if (parent) {
        wbs = removeKey(parent.WbsID, wbs)
      }
    }

    filter.WbsList = wbs
    onChange(filter)
  }

  const onClear = () => {
    filter.WbsList = []
    onChange(filter)
  }

  const getSelectItem = (item: any, parent: any, i: number = 0) => {
    const { Children = [] } = item

    if (Children.length === 0) {
      return (
        <CheckAsOptions
          key={`${item.WbsID}-${i}`}
          title={item.WbsIName}
          value={filter.WbsList.includes(item.WbsID)}
          parent={parent}
          item={item}
          onChange={onItemChange}
          onToggle={onItemToggle}
        />
      )
    } else {
      const isActive = display[item.WbsID]
      const chidren = Children.map((it: any, i: number) =>
        getSelectItem(it, item, i)
      )
      return (
        <Collapse
          bordered={false}
          activeKey={isActive ? item.WbsID : undefined}
          expandIcon={panelProps => (
            <CheckAsOptions
              panel={panelProps}
              title={item.WbsIName}
              value={filter.WbsList.includes(item.WbsID)}
              parent={parent}
              isActive={isActive}
              showArrow={true}
              item={item}
              onChange={onItemChange}
              onToggle={onItemToggle}
            />
          )}
          expandIconPosition="right"
        >
          <Panel
            header=""
            key={item.WbsID}
            showArrow={true}
            style={{ border: '0' }}
          >
            {chidren}
          </Panel>
        </Collapse>
      )
    }
  }

  const content = wbsList.map((wbs: any, index: number) =>
    getSelectItem(wbs, undefined, index)
  )

  const clearBtn =
    filter.WbsList.length > 0 ? (
      <span onClick={onClear} className="filter-clear std-font-regular">
        {translate(TKeys.Common.cancel_all)}
      </span>
    ) : (
      undefined
    )
  return (
    <div className="std-font-regular" style={css.page}>
      <Row>
        <Col span={24}>
          <div className="std-font-bold" style={css.title}>
            {translate(TKeys.Dashboard.category)}
            {clearBtn}
          </div>
        </Col>
      </Row>

      <ContentList className="pop-over-container-choices">
        {content}
      </ContentList>
    </div>
  )
}

const css: any = {
  page: {
    width: '650px',
    height: 'auto',
    padding: '25px 24px',
  },
  title: {
    textAlign: 'left',
    color: 'black',
    fontSize: '20px',
    marginBottom: '25px',
  },
  cancel: {
    float: 'right',
    color: '#003168',
    fontSize: '18px',
  },
}

const ContentList = styled.div`
  .ant-collapse-borderless {
    background-color: white;
  }
`

export default WbsFilter
