import { getType } from 'typesafe-actions'
import * as actions from './project.actions'
import produce from 'immer'
import {
  ProjectAction,
  ProjectState,
  ProjectListItem,
  ProjectsById,
} from './project.types'
import parseErrorMessage from 'utils/parseErrorMessage'

const initialState: ProjectState = {
  projectsById: {},
  isSubmitting: false,
  submitError: undefined,
  submitSuccess: true,
  emptyProjectList: null,
  activeProject: 1,
  tokenExpired: undefined,
}

const keyById = (projects: ProjectListItem[]): ProjectsById => {
  let projectsById: ProjectsById = {}

  projects.forEach((project: ProjectListItem) => {
    const key = project.ProjectCode as string

    projectsById[key] = project
  })

  return projectsById
}

export default function(
  state: ProjectState = initialState,
  action: ProjectAction
): ProjectState {
  return produce(state, draft => {
    switch (action.type) {
      case getType(actions.fetchProjectAsyncAction.request):
        draft.isSubmitting = true
        draft.submitError = undefined
        break
      case getType(actions.fetchProjectAsyncAction.success):
        draft.isSubmitting = false
        const projects = action.payload.ResponseData
        if (projects.length === 0) {
          draft.emptyProjectList = true
          draft.projectsById = {}
        } else {
          draft.emptyProjectList = false
          draft.projectsById = keyById(projects)
        }
        break
      case getType(actions.fetchProjectAsyncAction.failure):
        const payload = action.payload.response
        // When payload is null it means there is no internet connection. Backend API server is
        // unreachable.
        if (!payload) {
          draft.submitError =
            'Network connection error. Please check your internet connection.'
        } else {
          const response: ApiResponse = action.payload.response.data
          const message = parseErrorMessage(response)
          draft.submitError = message
          draft.tokenExpired = message.indexOf('Token expired.') > -1
        }

        draft.projectsById = {}
        draft.isSubmitting = false
        break
      case getType(actions.projectsEmpty):
        draft.emptyProjectList = action.payload
        break
      default:
        return state
    }
  })
}
