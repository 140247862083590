import React, { useRef } from 'react'
import { Col, Layout, Row } from 'antd'
import styled from 'styled-components'
import {
  ReportHeader,
  ReportLogo,
  DescItem,
  TaskScore,
  ReportTableHeader,
} from 'Components/AdminQC/Report/Components'
import { DocumentDetail } from 'features/adminReport/unit/types'
import { TableRow } from 'Components/Shared/Table'
import { toDateText, toMomentDateTime } from 'utils/datetime'

const { Content } = Layout

type Props = {
  data: DocumentDetail
  onSeeDetail?: (activtyID: string) => void
}

const ReportDetail: React.ComponentType<Props> = props => {
  const componentPrint = useRef(null)
  const { data, onSeeDetail = () => {} } = props

  const columnsName = [
    {
      title: 'รายการ Activity Primavera',
      span: 5,
    },
    {
      title: 'รายละเอียด',
      span: 5,
    },
    {
      title: 'หมายเหตุ',
      span: 5,
    },
    {
      title: 'คะแนนที่หัก',
      span: 5,
    },
    {
      title: '',
      span: 4,
    },
  ]

  const renderDescItem = (title: any, value: any) => {
    return <DescItem title={title} value={value || '-'} percentW={20} />
  }

  const DescContent = (
    <DesBox>
      <Row type="flex" align="middle">
        <Col span={20}>
          <Row type="flex">
            {renderDescItem(
              'โครงการ',
              `${data.ProjectCode}-${data.ProjectNameTH}`
            )}
            {renderDescItem('ผู้จัดการโครงการ', data.ProjectManager)}
            {renderDescItem(
              'หมวด QC',
              `${data.QCTypeName}(QC${data.CheckNumber})`
            )}
            {renderDescItem('โมเดล', data.ModelName)}
            {renderDescItem('ยูนิต', data.Unit)}
            {renderDescItem(
              'วันที่ตรวจ',
              toDateText(toMomentDateTime(data.InspectDate))
            )}
            {renderDescItem(
              'วันที่ส่งรายงาน',
              toDateText(toMomentDateTime(data.CheckedDate))
            )}
            {renderDescItem(
              'ผู้ตรวจ',
              `${data.CheckedBy} (${data.UserPosition})`
            )}
            {renderDescItem('ผู้ควบคุมงาน', data.ControllerUser)}
            {renderDescItem('ผู้รับเหมาหลัก', data.MainConUser)}
          </Row>
        </Col>
        <Col span={4}>
          <Row type="flex">
            <Col span={24}>
              <TaskScore
                checkStatus={(data && data.DocumentStatus) || ''}
                score={(data && data.DocumentScore) || 0}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </DesBox>
  )

  const renderActivity = () => {
    if (data.ActivityList && data.ActivityList.length > 0) {
      return data.ActivityList.map(item => {
        return item.DocumentList.map((doc, index) => {
          const isCheck = doc.Status === 'Check'
          return (
            isCheck && (
              <TableRow
                minHeight={60}
                spaceBetweenRow={8}
                paddingLeft={24}
                key={index}
              >
                <Col span={5}>
                  <TextBold>{item.ActivityName}</TextBold>
                </Col>
                <Col span={5}>
                  <Text>{doc.Name}</Text>
                </Col>
                <Col span={5}>
                  <Text>{doc.Remark || '-'}</Text>
                </Col>
                <Col span={5}>
                  <TextBold>{`-${doc.Score}`}</TextBold>
                </Col>
                <Col span={4}>
                  <SeeDetail onClick={() => onSeeDetail(item.ActivityID)}>
                    ดูรายละเอียด
                  </SeeDetail>
                </Col>
              </TableRow>
            )
          )
        })
      })
    } else {
      return (
        <TableRow minHeight={60} spaceBetweenRow={8} paddingLeft={24}>
          <Col span={24}>
            <Text>ไม่มีรายการ ActivityName</Text>
          </Col>
        </TableRow>
      )
    }
  }

  return (
    <Content className="mid-content">
      <div className="main-content activity-detail">
        <ReportHeader
          title="รายงานตรวจงานมาตรฐาน (ยูนิต / แปลง)"
          componentPrint={componentPrint}
        />
        <Box ref={componentPrint}>
          <ReportLogo title="รายงานตรวจงานมาตรฐาน (ยูนิต / แปลง)" />
          {DescContent}
          <ContentBox>
            <ReportTableHeader columnsName={columnsName} />
            {renderActivity()}
          </ContentBox>
        </Box>
      </div>
    </Content>
  )
}

const Box = styled.div`
  background-color: rgba(0, 49, 104, 0.05);
  border-radius: 8px;
  border: solid 1px #e4e7eb;
  color: #000;

  @media print {
    background-color: white;
    border: solid 0px #e4e7eb;
  }
`

const DesBox = styled.div`
  padding: 12px 24px;
  background-color: #fff;
`

const ContentBox = styled.div`
  padding: 24px;
`

const SeeDetail = styled.span`
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 18px;
  text-decoration: underline;
  color: #003168;
  margin-left: 12px;
  cursor: pointer;
`

const Text: any = styled.div`
  font-family: 'DB Helvethaica X 55 Regular';
  font-size: 20px;
  color: #000;
`

const TextBold = styled(Text)`
  font-family: 'DB Helvethaica X 75 Bd';
`

export default ReportDetail
