import { all, put, select, takeEvery } from 'redux-saga/effects'
import APIManager, { configWithToken } from 'services/APIManager'
import * as loginActions from 'features/login/login.actions'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function* genericAPICalling(action: any) {
  if (action.type === 'CALL_API') {
    let payload
    if (typeof action.payload === 'function') {
      payload = yield select(action.payload)
    } else {
      payload = action.payload
    }

    // Prepare Data
    let data
    if (typeof payload.data === 'function') {
      data = yield select(payload.data)
    } else {
      // eslint-disable-next-line prefer-destructuring
      data = payload.data
    }

    // Dispatch Request Action
    const requestAction = action.meta.request(data)
    yield put(requestAction)
    let resp
    try {
      // Call API
      resp = yield APIManager.call(
        payload.path,
        payload.method,
        data,
        configWithToken(payload.token)
      )

      // Dispatch Success Action
      const successAction = action.meta.success(resp)
      yield put(successAction)
    } catch (error) {
      // Dispatch Failure Action
      let { response = {}, request = {} } = error
      if (request.status === 0) {
        response = { status: 500 }
      }
      if (response.status === 401) {
        yield put(loginActions.logoutUser())
      } else {
        const failureAction = action.meta.failure(error)
        yield put(failureAction)
      }
    }
  }
}

function* commonSaga() {
  yield all([takeEvery('CALL_API', genericAPICalling)])
}

export default commonSaga
