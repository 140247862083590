import { ActionType } from 'typesafe-actions'
import { Moment } from 'moment-timezone'
import * as actions from './actions'
import { TaskStatus } from '../../tasklane/tasklane.types'
import { UnitStatus, SendReportStatus } from 'features/types/status'
import { CommissioningType, CheckListType } from 'features/types/type'
import { Event } from '../../types/event'

export type DashboardAction = ActionType<typeof actions>

export interface StoreState {
  unit: {
    data: UnitDashboardData
    originalData: UnitDashboardData
    filter: UnitFilter
  }
  zone: {
    data: ZoneDashboardData
    originalData: ZoneDashboardData
    filter: CommonAreaFilter
  }
  commissioning: {
    data: CommissioningDashboardData
    originalData: CommissioningDashboardData
    filter: CommissioningFilter
  }
  constructPlan: SitevisitDashboardData
  auditPlan: SitevisitDashboardData
  constructRoad: ConstructRoadDashboardData
  event: Event
  query: DashboardQuery
  filter: DashboardFilter
}

export type UnitDashboardData = {
  ToDo: TodoActivity[]
  InProgress: InProgressActivity[]
}

export type ZoneDashboardData = {
  ToDo: TodoActivity[]
  InProgress: ZoneInProgressActivity[]
}

export type CommissioningDashboardData = {
  ToDo: TodoActivity[]
  InProgress: CommissionInProActivity[]
}

export type SitevisitDashboardData = {
  TotalInProgress: 0
  InProgress: SitevisitInpro[]
  TotalReturn: 0
  Return: SitevisitReturn[]
}

export type ConstructRoadDashboardData = {
  ToDo: TodoActivity[]
  InProgress: ConstructRoadInProActivity[]
}

export type ConstructRoadInProActivity = {
  ProjectCode: string
  ProjectNameTH: string
  ProjectNameEN: string
  UnitList: ConstructRoadList[]
}

export type TodoActivity = {
  ProjectCode: string
  ProjectNameTH: string
  ProjectNameEN: string
  NumberOfTask: number
}

export type UnitList = {
  RequestID: number
  Unit: string
  Zone: string
  ModelName: string
  QCTypeID: string
  QCTypeName: string
  QCTypeVersion: string
  CheckNumber: number
  HasSign: boolean
  HasImage: boolean
  InspectionScore: number
  BasePoint: number
  DocumentScore: number
  BaseDocumentPoint: number
  SentChecklist: boolean
  SentDocument: boolean
  CheckStatus: UnitStatus
  UnitDisplay: string
}

export type InProgressActivity = {
  ProjectCode: string
  ProjectNameTH: string
  ProjectNameEN: string
  UnitList: UnitList[]
}

export type ZoneList = {
  RequestID: number
  Unit: string
  Zone: string
  QCTypeID: string
  QCTypeName: string
  QCTypeVersion: string
  Floor: string
  FloorPlanName: string
  CheckNumber: number
  HasSign: string
  HasSelectedDefect: string
  NumOfPass: number
  NumOfFail: number
}

export type CommissionList = {
  ActivityID: string
  ActivityName: string
  RequestID: number
  CheckListID: number
  CheckListName: string
  CheckListType: CheckListType
  CommissioningType: CommissioningType
  CommissioningTypeID: number
  CheckStatus: 'Pass' | 'Fail' | 'None'
  CheckNumber: number
  HasSign: boolean
  HasImage: boolean
  NumOfCheckList: number
}

export type ConstructRoadList = {
  RequestID: number
  Unit: string
  Zone: string
  QCTypeID: string
  QCTypeName: string
  CheckNumber: number
  HasSign: boolean
  HasSelectPin: boolean
  InspectionScore: number
  BasePoint: number
  CheckStatus: 'Pass' | 'NotPass' | 'NotPassByRequireDefect'
}

export type ZoneInProgressActivity = {
  ProjectCode: string
  ProjectNameTH: string
  ProjectNameEN: string
  ZoneList: ZoneList[]
}

export type CommissionInProActivity = {
  ProjectCode: string
  ProjectNameTH: string
  ProjectNameEN: string
  List: CommissionList[]
}

export type SitevisitInpro = {
  ProjectCode: string
  ProjectNameTH: string
  ProjectNameEN: string
  RequestID: number
  CheckStatus: string
  CheckNumber: number
  HasSign: string
  InspectionScore: number
}

export type SitevisitReturn = {
  ProjectCode: string
  ProjectNameTH: string
  ProjectNameEN: string
  RequestID: number
  CheckStatus: string
  CheckNumber: number
  HasSign: string
  InspectionScore: number
  CheckedDate: string
  ReturnDate: string
  NumOfReturnDate: number
}

export type DataList = {
  CheckListID: string
  CheckListName: string
  ResultStatus: string
  QCTypeName: string
  CheckNumber: number
  HasSign: string
  HasImage: string
  NumOfCheckList: number
}

export interface GetActivityRequest {
  UserEmail: string
  ProjectCode: string
  QCTypeList: string[]
}

export interface GetZoneReq {
  UserEmail: string
  ProjectCode: string
  UnitList: string[]
  FloorList: string[]
}

export interface GetCommissioningReq {
  UserEmail: string
  ProjectCode: string
  CommissioningTypeList: number[]
}

export interface TaskList {
  Unit: string
  QCTypeID: string
  QCTypeVersion: string
  RequestID: number
  CheckNumber: number
  CheckListStatus: SendReportStatus
  DocumentStatus: SendReportStatus
}

export interface ZoneTaskList {
  Unit: string
  QCTypeID: string
  QCTypeVersion: string
  RequestID: number
  CheckNumber: number
  Floor: string
}

export interface ProjectList {
  ProjectCode: string
  TaskList: TaskList[]
}

export interface SendReportRequest {
  UserEmail: string
  TaskType: string
  ProjectList: ProjectList[]
}

export interface ZoneProjectList {
  ProjectCode: string
  TaskList: ZoneTaskList[]
}

export interface SendZoneReportReq {
  UserEmail: string
  TaskType: string
  ProjectList: ZoneProjectList[]
}

export interface CommissTasktList {
  RequestID: number
  CheckNumber: number
  CheckListID: number
  ActivityID: string
  CommissioningType: CommissioningType
}
export interface CommissProjectList {
  ProjectCode: string
  List: CommissTasktList[]
}

// Request
export interface SendCommissioningReportReq {
  UserEmail: string
  ProjectList: CommissProjectList[]
}

export interface ReturnTaskReq {
  UserEmail: string
  ProjectCode: string
  TaskType: string
  ID: string
  QCTypeID: string
  RequestID: number
  CheckNumber: number
}

export interface ReturnZoneReq {
  UserEmail: string
  ProjectCode: string
  Unit: string
  Floor: string
  QCTypeID: string
  RequestID: number
  CheckNumber: number
}

export interface ReturnCommissioningReq {
  UserEmail: string
  ProjectCode: string
  CheckListID: number
  ActivityID: string
  CommissioningType: number
  RequestID: number
  CheckNumber: number
}

//ConstructPlan
export interface GetConstructPlanReq {
  UserEmail: string
  ProjectCode: string[]
  CheckNumber: number[]
}

export interface SiteVisitProjectList {
  ProjectCode: string
  CheckNumber: number
  RequestID: number
}

export interface SendConstructPlanReportReq {
  UserEmail: string
  ProjectList: SiteVisitProjectList[]
}

export interface ReturnConstructReq {
  UserEmail: string
  ProjectCode: string
  RequestID: number
}

// Audit plan
export interface GetAuditPlanReq {
  UserEmail: string
  ProjectCode: string[]
  CheckNumber: number[]
}

// Construct road
export interface GetConstructRoadReq {
  UserEmail: string
  ProjectCode: string[]
  CheckNumber: number[]
}

export interface SendAuditPlanReportReq {
  UserEmail: string
  ProjectList: SiteVisitProjectList[]
}

export interface ReturnAuditPlanReq {
  UserEmail: string
  ProjectCode: string
  RequestID: number
}

//Road
export interface SendRoadConstructReportReq {
  UserEmail: string
  ProjectList: RoadProjectList[]
}

export interface ReturnRoadConstructReq {
  UserEmail: string
  ProjectCode: string
  Unit: string
  QCTypeID: string
  RequestID: number
  CheckNumber: number
}

export interface RoadProjectList {
  ProjectCode: string
  TaskList: {
    Unit: string
    QCTypeID: string
    RequestID: number
    CheckNumber: number
  }[]
}

export interface GetApiResponse<T> {
  ResponseData: T
  ResponseCode: string
  ResponseMsg: 'Success' | 'Error'
  RequestTime: string
  Responsetime: string
}

export type DashboardFilter = {
  progress: number[]
  status: TaskStatus[]
  wbs: number[]
  date: {
    mode: string
    start: undefined | Moment
    end: undefined | Moment
  }
  floor: number[]
  unit: string[]
  zone: string[]
  type: string[]
}

export type DashboardQuery = {
  date: {
    start: undefined | Moment
    end: undefined | Moment
  }
}

export type CommonAreaFilter = {
  ProjectCode: string
  ZoneList: string[]
  FloorList: string[]
}

export type CommissioningFilter = {
  ProjectCode: string
  CommissionTypeList: number[]
}

export type UnitFilter = {
  ProjectCode: string
  QCTypeList: string[]
}

export enum TypeOfTask {
  TODO = 'ToDo',
  INPROGRESS = 'InProgress',
}

export interface GetUnitDashboardResponse
  extends GetApiResponse<UnitDashboardData> {}

export interface GetZoneDashboardResponse
  extends GetApiResponse<ZoneDashboardData> {}

export interface GetCommissioningDashboardResponse
  extends GetApiResponse<CommissioningDashboardData> {}

export interface GetConstructPlanResp
  extends GetApiResponse<SitevisitDashboardData> {}

export interface GetAuditPlanResp
  extends GetApiResponse<SitevisitDashboardData> {}

export interface GetConstructRoadResp
  extends GetApiResponse<ConstructRoadDashboardData> {}

export interface PostApiResponse extends GetApiResponse<{}> {}
