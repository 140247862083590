import RootState from 'typings/RootState'
import { createSelector } from 'reselect'
import { DataList } from './types'
import { Event } from '../../types/event'

export const selectDataList = (state: RootState): DataList =>
  state.adminpjPrimavera.dataList

export const selectEvent = (state: RootState): Event =>
  state.adminpjPrimavera.event
