import RootState from 'typings/RootState'
import { createSelector } from 'reselect'
import { AuditPlanReport } from './types'
import { Event } from '../../types/event'

export const selectReport = (state: RootState): AuditPlanReport =>
  state.reportAuditPlan.report

export const selectEvent = (state: RootState): Event =>
  state.reportAuditPlan.event

export const selectEventChecklist = (state: RootState): Event =>
  state.reportAuditPlan.eventChecklist

export const selectDefect = (state: RootState) => state.reportAuditPlan.defect

export const selectCheckListQC = (state: RootState) =>
  state.reportAuditPlan.checkListQC
