import React from 'react'
import { getProgressColor } from 'utils/color-map'
import { Progress } from 'antd'
import styled from 'styled-components'

interface Props {
  task: any
}

const ProgressLine: React.FC<Props> = props => {
  const { task } = props
  const { State, TaskStatus } = task

  const getCmStatus = () => {
    switch (TaskStatus) {
      case 'INIT':
      case 'INPRO':
        return 'APPV'
      default:
        return TaskStatus
    }
  }

  let status = TaskStatus

  switch (State) {
    case 'MC':
      break
    case 'CM':
      status = getCmStatus()
      break
    case 'PJPO':
      status = TaskStatus === 'INIT' ? 'inpro' : TaskStatus
      break
    default:
      break
  }

  const taskConfig = getProgressColor(
    status,
    task.Progress,
    task.LastReqProgress,
    task.LastCurProgress
  )
  return (
    <Data>
      <Progress
        percent={taskConfig.progress}
        successPercent={taskConfig.success}
        strokeColor={taskConfig.color}
        size="small"
        format={v => `${task.Progress}%`}
        className={`task-progress ${
          taskConfig.color ? '' : taskConfig.className
        }`}
      />
    </Data>
  )
}

const Data = styled.div`
  width: 100%;
  .task-progress {
    width: 100%;
    text-align: left;
    .ant-progress-outer {
      width: 80%;
    }
    .ant-progress-text {
      padding-left: 10px;
    }
  }
`
export default ProgressLine
