import React from 'react'
import LocalChecklist from '../LocalChecklist/List'
import { RouteComponentProps } from 'react-router-dom'

type Props = RouteComponentProps

const Administrator: React.FC<Props> = props => {
  return <LocalChecklist {...props} />
}

export default Administrator
