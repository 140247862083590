import withAPIAction from 'utils/withAPIAction'
import { verifyReq } from 'utils/verify'
import {
  FetchActivityOnUnitReq,
  StartCheckingRequest,
  SendRequestToQcRequest,
  SendUrgentForCMRequest,
  SendUrgentForQCRequest,
  SendRequestToMCReq,
} from './types'
import {
  getActivityOnUnitAction,
  StartCheckingByCMAction,
  SendRequestToQcAction,
  SendUrgentForQCRequestAction,
  SendUrgentForCMRequestAction,
  SendRequestToMCAction,
} from './actions'

export const getActivityOnUnitById = (
  data: FetchActivityOnUnitReq,
  token: string
) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<FetchActivityOnUnitReq>({
    method: 'POST',
    path: 'GetActivityOnUnitById	',
    data,
    token: `Bearer ${token}`,
  })(getActivityOnUnitAction)
}

export const StartCheckingByCMApi = (
  token: string,
  data: StartCheckingRequest
) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<StartCheckingRequest>({
    method: 'POST',
    path: 'StartCheckingByCM',
    data,
    token: `Bearer ${token}`,
  })(StartCheckingByCMAction)
}

export const SendRequestToQcForChecking = (
  token: string,
  data: SendRequestToQcRequest
) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<SendRequestToQcRequest>({
    method: 'POST',
    path: 'SendRequestToQcForChecking',
    data,
    token: `Bearer ${token}`,
  })(SendRequestToQcAction)
}

export const SendUrgentRequestForCMChecking = (
  token: string,
  data: SendUrgentForCMRequest
) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<SendUrgentForCMRequest>({
    method: 'POST',
    path: 'SendUrgentRequestForCMChecking',
    data,
    token: `Bearer ${token}`,
  })(SendUrgentForCMRequestAction)
}

export const SendUrgentRequestForQCChecking = (
  token: string,
  data: SendUrgentForQCRequest
) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<SendUrgentForQCRequest>({
    method: 'POST',
    path: 'SendUrgentRequestForQCChecking',
    data,
    token: `Bearer ${token}`,
  })(SendUrgentForQCRequestAction)
}

export const SendRequestToMcForChecking = (
  token: string,
  data: SendRequestToMCReq
) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<SendRequestToMCReq>({
    method: 'POST',
    path: 'SendRequestToMcForChecking',
    data,
    token: `Bearer ${token}`,
  })(SendRequestToMCAction)
}
