import tasklaneReducer from 'features/tasklane/tasklane.reducer'
import React from 'react'
import styled from 'styled-components'

import Loading from '../Loading'

const Picker = (props: any) => {
  const {
    name = 'upload img',
    height = 100,
    width = 100,
    accept = 'image/*',
    text = 'Pickup',
    disabled = false,
    loading = false,
    customPickerBtn,
    isMultiple = true,
  } = props

  const onChange = async (evt: any) => {
    console.log('evt', evt)
    // console.log(evt.target.files)
    // const input: any = document.getElementById(name)
    // console.log('input', input.value)

    let files = evt.target.files
    if (files.length === 0) return

    // let file = files[0]

    // const reader = new FileReader();

    // reader.addEventListener('load', () => {
    //   if (props.onChange) {
    //     props.onChange(reader.result, file, props.name)
    //   }
    // });

    // reader.readAsDataURL(file)

    for (let i = 0; i < files.length; i++) {
      const file = files[i]
      const reader = new FileReader()

      reader.addEventListener('load', () => {
        if (props.onChange) {
          props.onChange(reader.result, file, props.name)
        }
      })

      reader.readAsDataURL(file)
    }
  }

  const onClick = () => {
    const input: any = document.getElementById(name)
    if (input) input.value = null
  }

  const style = {
    width: `${width}px`,
    height: `${height}px`,
    opacity: disabled ? 0.5 : undefined,
  }

  let iconCss = { fontSize: '70px', marginTop: '30%' }
  let textCss = { fontSize: '20px' }
  if (height <= 100) {
    iconCss = { fontSize: '40px', marginTop: '25%' }
    textCss = { fontSize: '16px' }
  }

  let content = loading ? (
    <div style={{ marginTop: '25%' }}>
      <Loading color="#003168" size={18} />
    </div>
  ) : (
    <div>
      <Icon style={iconCss}>+</Icon>
      <Text className="std-font-regular" style={textCss}>
        {text}
      </Text>
    </div>
  )
  return (
    <Section className={disabled ? undefined : 'pointer'}>
      <input
        id={name}
        type="file"
        accept={accept}
        style={{ display: 'none' }}
        onChange={onChange}
        onClick={onClick}
        multiple={isMultiple}
      />

      <label htmlFor={disabled || loading ? undefined : name}>
        {customPickerBtn ? (
          customPickerBtn
        ) : (
          <PickerBody style={style}>{content}</PickerBody>
        )}
      </label>
    </Section>
  )
}

const Section = styled.div`
  color: #8b99a7;
  :hover: {
    cursor: pointer;
  }
`

const Icon = styled.p`
  padding: 0px;
  margin: 0px;
  margin-bottom: 5px;
  color: #8b99a7;
  line-height: 0.5;
`

const Text = styled.p`
  padding: 0px;
  margin: 0px;
`

const PickerBody = styled.div`
  text-align: center;
  border-radius: 3px;
  border: dashed 1px #b5b4b4;
`

export default Picker
