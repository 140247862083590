import { createStandardAction, createAsyncAction } from 'typesafe-actions'
import { GetApiResponse, List, RowsList } from '../../types/common'
import { History } from 'history'

import {
  Function,
  CheckListItem,
  GetCheckListReq,
  CheckListDetail,
  UnitCheckListByCodeType,
  FunctionDetail,
} from './types'

export const getCheckListReq = createAsyncAction(
  'checklist/unit/GET_CHECK_LIST_REQ',
  'checklist/unit/GET_CHECK_LIST_RESP',
  'checklist/unit/GET_CHECK_LIST_FAIL'
)<GetCheckListReq, GetApiResponse<List<CheckListItem>>, any>()

export const getCheckListDetailReq = createAsyncAction(
  'checklist/unit/GET_CHECK_LIST_DETAIL_REQ',
  'checklist/unit/GET_CHECK_LIST_DETAIL_RESP',
  'checklist/unit/GET_CHECK_LIST_DETAIL_FAIL'
)<
  { CheckListID: number; UserEmail: string },
  GetApiResponse<CheckListDetail>,
  any
>()

export const createUpdateCheckListDetailReq = createAsyncAction(
  'checklist/unit/CREATE_UPDATE_CHECK_LIST_DETAIL_REQ',
  'checklist/unit/CREATE_UPDATE_CHECK_LIST_DETAIL_RESP',
  'checklist/unit/CREATE_UPDATE_CHECK_LIST_DETAIL_FAIL'
)<
  { check: CheckListDetail; unit: UnitCheckListByCodeType },
  CheckListDetail | null,
  Error
>()

export const deleteCheckListDetailReq = createAsyncAction(
  'checklist/unit/DELETE_CHECK_LIST_DETAIL_REQ',
  'checklist/unit/DELETE_CHECK_LIST_DETAIL_RESP',
  'checklist/unit/DELETE_CHECK_LIST_DETAIL_FAIL'
)<
  {
    CheckListID: number
    isGoBack?: boolean
    pagination?: any
    history?: History
  },
  void,
  void
>()

export const clearCheckListDetail = createStandardAction(
  'checklist/unit/CLEAR_CHECK_LIST_DETAIL'
)<void>()

export const getQCTypeListReq = createAsyncAction(
  'checklist/unit/GET_QC_TYPE_LIST_REQ',
  'checklist/unit/GET_QC_TYPE_LIST_RESP',
  'checklist/unit/GET_QC_TYPE_LIST_FAIL'
)<any, any, Error>()

export const getUnitByUnitCheckListReq = createAsyncAction(
  'checklist/unit/GET_UNIT_BY_UNIT_CHECKLIST_REQ',
  'checklist/unit/GET_UNIT_BY_UNIT_CHECKLIST_RESP',
  'checklist/unit/GET_UNIT_BY_UNIT_CHECKLIST_FAIL'
)<any, any, any>()

export const updateUnitCheckListResult = createStandardAction(
  'checklist/unit/UPDATE_UNIT_CHECKLIST_RESULT'
)<UnitCheckListByCodeType>()

export const getFunctionCheckListReq = createAsyncAction(
  'checklist/unit/GET_FUNCTION_CHECKLIST_REQ',
  'checklist/unit/GET_FUNCTION_CHECKLIST_RESP',
  'checklist/unit/GET_FUNCTION_CHECKLIST_FAIL'
)<any, GetApiResponse<RowsList<Function>>, Error>()

export const getFunctionCheckListDetailReq = createAsyncAction(
  'checklist/unit/GET_FUNCTION_CHECKLIST_DETAIL_REQ',
  'checklist/unit/GET_FUNCTION_CHECKLIST_DETAIL_RESP',
  'checklist/unit/GET_FUNCTION_CHECKLIST_DETAIL_FAIL'
)<
  { UserEmail: string; CheckListID: string },
  GetApiResponse<FunctionDetail>,
  Error
>()

export const createUpdateFunctionReq = createAsyncAction(
  'checklist/unit/CREATE_UPDATE_FUNCTION_REQ',
  'checklist/unit/CREATE_UPDATE_FUNCTION_RESP',
  'checklist/unit/CREATE_UPDATE_FUNCTION_FAIL'
)<any, any, Error>()

export const deleteFunctionReq = createAsyncAction(
  'checklist/unit/DELETE_FUNCTION_REQ',
  'checklist/unit/DELETE_FUNCTION_RESP',
  'checklist/unit/DELETE_FUNCTION_FAIL'
)<{ id: number; pagination: any }, void, Error>()

export const clearFunctionDetail = createStandardAction(
  'checklist/unit/CLEAR_FUNCTION_DETAIL'
)<void>()

export const updateCheckListDetail = createStandardAction(
  'checklist/unit/UPDATE_CHECKLIST_DETAIL'
)<CheckListDetail>()

export const updateFuncDetail = createStandardAction(
  'checklist/unit/UPDATE_FUNC_DETAIL'
)<FunctionDetail>()
