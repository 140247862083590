import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react'
import { Modal, Row, Col } from 'antd'
import CloseIcon from 'Components/Shared/Icon/CloseIcon'
import styled from 'styled-components'
import PrimaryButton from 'Components/Shared/Button/PrimaryButton'
import OutlineButton from 'Components/Shared/Button/OutlineButton'
import { InputText } from 'Components/Shared/Input'

export interface DeleteModalHandles {
  showModal: (txt: string, memo?: string, obj?: any, titel?: string) => void
}

interface Props {
  onSubmit: (obj?: any, memo?: string) => void
}

const BackwardModal = forwardRef<DeleteModalHandles, Props>(
  ({ onSubmit: onSubmitProps }, ref) => {
    const [visible, setvisible] = useState(false)
    const [text, settext] = useState('initialState')
    const [obj, setobj] = useState(undefined)
    const [titel, setTitel] = useState('')
    const [memo, setMemo] = useState('')

    const closeModal = () => {
      setvisible(false)
      setobj(undefined)
    }

    useImperativeHandle(ref, () => ({
      showModal: (txt: string, memo?: string, obj?: any, titel?: string) => {
        setvisible(true)
        settext(txt)
        setTitel(titel ?? '')
        setMemo('')
        if (obj != null) {
          setobj(obj)
        }
      },
    }))

    const onSubmit = (obj?: any, memo?: string) => {
      onSubmitProps(obj, memo)
      closeModal()
    }

    useEffect(() => {
      setMemo('')
    }, [obj])

    return (
      <ModalCheckList>
        <Modal
          centered
          visible={visible}
          width="528px"
          footer={null}
          closeIcon={<CloseIcon color="#8b99a7" size={16} />}
          title={<Header>{`${titel}`}</Header>}
          className="backward-modal"
          onCancel={closeModal}
        >
          <ModalContent>
            <Row type="flex" justify="space-around" align="middle">
              <Col span={23}>
                <InputText
                  prefix="Memo No."
                  value={memo}
                  onChange={(val: string) => {
                    setMemo(val)
                  }}
                  placeholder="ระบุ Memo No."
                />
              </Col>
              {memo.length > 20 && (
                <Col span={23}>
                  <ErrorMessage>
                    กรุณากรอกด้วยความยาวไม่เกิน 20 ตัวอักษร
                  </ErrorMessage>
                </Col>
              )}
            </Row>
            <ButtonSection>
              <Row type="flex" justify="space-around">
                <Col span={11}>
                  <OutlineButton
                    text="ยกเลิก"
                    disabled={false}
                    onClick={closeModal}
                  />
                </Col>
                <Col span={11}>
                  <PrimaryButton
                    text="ยืนยัน"
                    disabled={memo.length > 20}
                    btnType="Reject"
                    onClick={() => onSubmit(obj, memo)}
                  />
                </Col>
              </Row>
            </ButtonSection>
          </ModalContent>
        </Modal>
      </ModalCheckList>
    )
  }
)

const Header = styled.span`
  font-size: 28px;
  color: #000000;
  font-family: 'DB Helvethaica X 75 Bd';
`

const ModalCheckList = styled.div`
  .add-checklist-modal {
    .ant-modal-header {
      border-bottom: none;
    }
  }
`

const ModalContent = styled.div`
  padding: 0 24px 31px 24px;
  font-family: 'DB Helvethaica X 55 Regular';
  font-size: 28px;
  font-size: 20px;
  .text-warning {
    font-family: 'DB Helvethaica X 75 Bd';
    color: #d0494c;
  }
`

const ButtonSection = styled.div`
  padding-top: 30px;
`

const ErrorMessage = styled.div`
  color: red;
  font-size: 16px;
  padding-top: 5px;
  padding-left: 5px;
`

export default BackwardModal
