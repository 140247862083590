import { getType } from 'typesafe-actions';
import * as actions from './selectedProject.actions';
import produce from 'immer';
import {
  SelectedProjectAction,
  SelectedProjectState,
} from './selectedProject.types';

const initialState: SelectedProjectState = { projectCode: '' };

export default function(
  state: SelectedProjectState = initialState,
  action: SelectedProjectAction
): SelectedProjectState {
  return produce(state, draft => {
    switch (action.type) {
      case getType(actions.changeSelectedProjectAction):
        draft.projectCode = action.payload;
        break;
      default:
        return state;
    }
  });
}
