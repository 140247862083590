import React, { useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { RouteComponentProps, Link } from 'react-router-dom'
import moment from 'moment'
import RootState from 'typings/RootState'
import { BreadcrumbLink } from 'Components/Shared/BreadCrumb/BreadCrumb'
import { selectedProjectCodeSelector } from 'features/project/project.selector'
import Pagination from 'Components/Shared/Pagination'
import PageIndex from 'Components/Shared/Pagination/PageIndex'
import { TableHeader, StructureListPage } from 'Components/Shared/Seemore'
import { NoCard } from 'Components/Shared'
import {
  userEmailSelector,
  serviceTokenSelector,
} from 'features/sensitive/sensitive.selector'
import {
  selectData,
  selectEvent,
  selectSorting,
} from 'features/cmpj/seemore/selector'
import { getActivitySeeMoreForCMapi } from 'features/cmpj/seemore/api'
import { GetActivitySeemoreRequest } from 'features/cmpj/seemore/types'
import { sortingTask, setPageType } from 'features/cmpj/seemore/actions'
import { TypeOfTask } from 'features/cmpj/cmdashboard/types'
import RowItem from './RowItem'
import { ActivityFilterPage } from 'Components/Filter'
import {
  selectActivityFilter,
  selectisClearFilter,
} from 'features/filter/selector'
import { toDateText } from 'utils/datetime'
import { mergeArray } from 'utils/format'
import { clearFilter } from 'features/filter/actions'
import queryString from 'query-string'
import { useLocation } from 'react-router'
import { ActivityFilter } from 'features/filter/types'
import { setFilterActivity } from 'features/activity/activity.actions'

type Props = ReduxProps &
  DispatchProps &
  RouteComponentProps<{ type: TypeOfTask }>

const CmSeemore: React.ComponentType<Props> = props => {
  const {
    match,
    data,
    event,
    sorting,
    token,
    ProjectCode,
    UserEmail,
    filter,
    isClearFilter,
  } = props

  const { params } = match
  const { type } = params
  const { DataList, TotalRows } = data
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const dispatch = useDispatch()
  const searchParam = useLocation()

  let pageType = 1
  switch (type) {
    case TypeOfTask.Todo:
      pageType = 1
      break
    case TypeOfTask.Inprogress:
      pageType = 2
      break
    case TypeOfTask.Done:
      pageType = 3
      break
  }

  useEffect(() => {
    dispatch(setPageType({ pageType }))
  }, [type])

  useEffect(() => {
    if (isClearFilter) {
      onGetActivitySeeMoreForCMapi()
    }
  }, [isClearFilter])

  useEffect(() => {
    onGetActivitySeeMoreForCMapi()
  }, [ProjectCode])

  useEffect(() => {
    onGetActivitySeeMoreForCMapi(true)
  }, [perPage, page])

  const onGetActivitySeeMoreForCMapi = (isFilter: boolean = false) => {
    const data: GetActivitySeemoreRequest = {
      ProjectCode,
      UserEmail,
      PageType: pageType,
      Sort: {
        OrderBy: '', //sorting.type,
        SortBy: '', //sorting.name
      },
      StartDate: toDateText(filter.Date.StartDate || ''),
      FinishDate: toDateText(filter.Date.FinishDate || ''),
      ZoneList: [],
      FloorList: filter.FloorList,
      UnitList: mergeArray([filter.UnitList, filter.ZoneList]),
      ConstructionTypeList: filter.ConstructionTypeList,
      WbsList: filter.LeafWbsList,
      Page: page,
      RowPerPage: perPage,
    }

    // if (isFilter) {
    //   data.StartDate = toDateText(filter.Date.StartDate || '')
    //   data.FinishDate = toDateText(filter.Date.FinishDate || '')
    //   data.FloorList = filter.FloorList
    //   data.UnitList = mergeArray([filter.UnitList, filter.ZoneList])
    //   data.ConstructionTypeList = filter.ConstructionTypeList
    //   data.WbsList = filter.LeafWbsList
    // }
    dispatch(getActivitySeeMoreForCMapi(token, data))
  }

  const onSort = (name: string) => {
    dispatch(sortingTask({ name }))
  }

  const content = DataList.map((item, index) => {
    return (
      <Link to={`/activity/${item.ActivityID}${searchParam.search}`}>
        <RowItem task={item} key={index} pageType={pageType} taskType={type} />
      </Link>
    )
  })

  let title = `รายการคำขอ (${TotalRows})`
  let breadcrumbTxt = 'รายการคำขอ'
  switch (type) {
    case TypeOfTask.Inprogress:
      title = `กำลังดำเนินการ (${TotalRows})`
      breadcrumbTxt = 'กำลังดำเนินการ'
      break
    case TypeOfTask.Done:
      title = `เสร็จสิ้น (${TotalRows})`
      breadcrumbTxt = 'เสร็จสิ้น'
      break
  }

  const breadcrumbs: BreadcrumbLink[] = [
    {
      url: '/',
      title: 'Home',
      icon: '/home.png',
      active: true,
      showTitle: false,
    },
    {
      url: '/',
      title: 'บอร์ด',
      active: true,
      showTitle: true,
    },
    {
      url: '/',
      title: breadcrumbTxt,
      active: false,
      showTitle: false,
    },
  ]

  const DoneColumns = [
    {
      title: 'Activity',
      span: 7,
      key: 'ActivityName',
    },
    {
      title: 'หมวดหมู่',
      span: 2,
      key: 'Unit',
    },
    {
      title: 'ประเภทก่อสร้าง',
      span: 3,
      key: 'ConstructionTypeName',
    },
    {
      title: 'วันเริ่มงาน',
      span: 3,
      key: 'StartDate',
    },
    {
      title: 'วันสิ้นสุดงาน',
      span: 3,
      key: 'FinishDate',
    },
    {
      title: 'กำหนดการ',
      span: 4,
      key: 'countdown',
    },
    {
      title: 'Reject',
      span: 2,
      key: 'Reject',
    },
  ]

  const ToDoColumns = [
    {
      title: 'Activity',
      span: 7,
      key: 'ActivityName',
    },
    {
      title: 'หมวดหมู่',
      span: 3,
      key: 'Unit',
    },
    {
      title: 'ประเภทก่อสร้าง',
      span: 4,
      key: 'ConstructionTypeName',
    },
    {
      title: 'วันเริ่มงาน',
      span: 3,
      key: 'StartDate',
    },
    {
      title: 'วันสิ้นสุดงาน',
      span: 3,
      key: 'FinishDate',
    },
    {
      title: 'กำหนดการ',
      span: 4,
      key: 'countdown',
    },
  ]

  const InprogressColumns = [
    {
      title: 'Activity',
      span: 7,
      key: 'ActivityName',
    },
    {
      title: 'หมวดหมู่',
      span: 3,
      key: 'Unit',
    },
    {
      title: 'ประเภทก่อสร้าง',
      span: 4,
      key: 'ConstructionTypeName',
    },
    {
      title: 'วันสิ้นสุดงาน',
      span: 3,
      key: 'FinishDate',
    },
    {
      title: 'กำหนดการ',
      span: 4,
      key: 'countdown',
    },
    {
      title: 'ความคืบหน้า',
      span: 3,
      key: 'Progress',
    },
  ]

  const pageIndex = (
    <PageIndex
      currentPage={page}
      totalRows={TotalRows}
      perPage={perPage}
      onClick={page => setPage(page)}
    />
  )

  const tableHeader = (
    <TableHeader
      onSort={name => onSort(name)}
      sorting={sorting}
      headerItem={
        pageType == 2
          ? InprogressColumns
          : pageType == 1
          ? ToDoColumns
          : DoneColumns
      }
    />
  )

  const pagination = (
    <Pagination
      perPage={perPage}
      totalRows={TotalRows}
      currentPage={page}
      onClickPage={page => setPage(page)}
      onChangeNumber={number => setPerPage(number)}
    />
  )

  return (
    <StructureListPage
      isLoading={event.IsLoading}
      breadcrumbs={breadcrumbs}
      filterHeader={
        <ActivityFilterPage
          onSearch={() => {
            setPage(1)
            onGetActivitySeeMoreForCMapi(true)
          }}
        />
      }
      pagination={pageIndex}
      tableHeader={tableHeader}
      tableContent={
        data.DataList.length > 0 ? (
          content
        ) : (
          <NoCard text={`ไม่มีข้อมูล${breadcrumbTxt}`} />
        )
      }
      title={title}
      footerPagination={pagination}
    />
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    data: selectData(state),
    event: selectEvent(state),
    sorting: selectSorting(state),
    token: serviceTokenSelector(state),
    ProjectCode: selectedProjectCodeSelector(state),
    UserEmail: userEmailSelector(state),
    filter: selectActivityFilter(state),
    isClearFilter: selectisClearFilter(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(CmSeemore)
