import React from 'react'
import ImageListMode from './ImageListMode'
import ChooseImageMode from './ChooseImageMode'

type ImageList = {
  Image: any
  Remark: string
}

type Props = {
  readOnly: boolean
  images: ImageList[]
  numberOfImage?: number
  onClose: () => void
  onThumbnailOpen: (index: number) => void
  onChange: (list: ImageList[]) => void
  onUploadImage?: (image: any) => void
  selectedImage?: any[]
  onSelectImage?: (image: any) => void
  onEditMode?: (isEditMode: boolean) => void
  mode?: 'upload' | 'choose'
}

const ImageWithCaption: React.ComponentType<Props> = props => {
  const {
    images,
    readOnly,
    onChange,
    numberOfImage = 5,
    onUploadImage = () => {},
    onThumbnailOpen,
    mode = 'upload',
    selectedImage = [],
    onSelectImage = () => {},
    onEditMode = () => {},
  } = props

  return mode === 'upload' ? (
    <ImageListMode
      onClose={() => {}}
      readOnly={readOnly}
      images={images}
      onThumbnailOpen={onThumbnailOpen}
      onChange={onChange}
      numberOfImage={numberOfImage}
      onUploadImage={onUploadImage}
      onEditMode={onEditMode}
    />
  ) : (
    <ChooseImageMode
      onClose={() => {}}
      readOnly={readOnly}
      images={images}
      onThumbnailOpen={onThumbnailOpen}
      onChange={onChange}
      selectedImage={selectedImage}
      onSelectImage={onSelectImage}
    />
  )
}

export default ImageWithCaption
