import withAPIAction from 'utils/withAPIAction'
import {
  getDataListAction,
  updateDataAction,
  getActiveProjectListAction,
  updateMultiProjectAction,
  exportCurrentDataAction,
} from './actions'

import {
  GetDataListReq,
  UpdateDataReq,
  GetActiveProjectsReq,
  UpdateMultiProjectReq,
} from './types'

import { verifyReq, verifyReqNoProject } from 'utils/verify'

export const getDataList = (token: string, data: GetDataListReq) => {
  const isValid = verifyReqNoProject(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<GetDataListReq>({
    method: 'POST',
    path: 'GetProjectList',
    data,
    token: `Bearer ${token}`,
  })(getDataListAction)
}

export const exportCurrentData = (token: string, data: GetDataListReq) => {
  const isValid = verifyReqNoProject(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<GetDataListReq>({
    method: 'POST',
    path: 'GetProjectList',
    data,
    token: `Bearer ${token}`,
  })(exportCurrentDataAction)
}

export const importMultiProject = (
  token: string,
  data: UpdateMultiProjectReq
) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<UpdateMultiProjectReq>({
    method: 'POST',
    path: 'ImportMultiProject',
    data,
    token: `Bearer ${token}`,
  })(updateMultiProjectAction)
}

export const updateData = (token: string, data: UpdateDataReq) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<UpdateDataReq>({
    method: 'POST',
    path: 'UpdateProject',
    data,
    token: `Bearer ${token}`,
  })(updateDataAction)
}

export const getActiveProjectList = (
  token: string,
  data: GetActiveProjectsReq
) => {
  const isValid = verifyReqNoProject(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<GetActiveProjectsReq>({
    method: 'POST',
    path: 'GetProjectNameList',
    data,
    token: `Bearer ${token}`,
  })(getActiveProjectListAction)
}
