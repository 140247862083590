import React, { useState, useEffect } from 'react'
import { Modal, Row, Col } from 'antd'
import styled from 'styled-components'
import { SecondaryButton, PrimaryButton } from 'Components/Shared/Button'
import { Tabs } from 'antd'
import { RadioBox } from 'Components/Shared/Input'
import {
  GetSelectLocationReq,
  Location,
  UpdateSelectLocationReq,
} from 'features/report/auditRoad/types'
import {
  getSelectLocation,
  updateSelectLocation,
} from 'features/report/auditRoad/api'
import { useDispatch, connect } from 'react-redux'
import {
  selectLocationEvent,
  selectLocationList,
} from 'features/report/auditRoad/selector'
import {
  userEmailSelector,
  serviceTokenSelector,
} from 'features/sensitive/sensitive.selector'
import RootState from 'typings/RootState'
import { EventModal } from 'Components/Shared'

type Props = {
  onClose: () => void
  onCompleted: () => void
  RequestID: number
  CheckNumber: number
  ProjectCode: string
  visible?: boolean
} & ReduxProps

const LocationModal = (props: Props) => {
  const {
    onClose = () => {},
    onCompleted = () => {},
    RequestID,
    ProjectCode,
    visible,
    UserEmail,
    token,
    event,
    CheckNumber,
    Locations,
  } = props

  const [listSelected, setListSelected] = useState([] as Location[])
  const [enableModal, setEnableModal] = useState(false)
  const { LocationList } = Locations

  const dispatch = useDispatch()
  useEffect(() => {
    setEnableModal(false)
    if (visible) {
      getLocationList()
    }
  }, [visible])

  useEffect(() => {
    setListSelected([...LocationList])
  }, [LocationList])

  const getLocationList = () => {
    if (CheckNumber <= 0) return
    setEnableModal(true)
    const req: GetSelectLocationReq = {
      ProjectCode,
      UserEmail,
      RequestID,
      CheckNumber,
    }
    dispatch(getSelectLocation(token, req))
  }

  const updateSelectedLocation = () => {
    if (CheckNumber <= 0) return
    setEnableModal(true)
    const req: UpdateSelectLocationReq = {
      ProjectCode,
      UserEmail,
      RequestID,
      CheckNumber,
      LocationList: listSelected,
    }

    dispatch(updateSelectLocation(token, req))
  }

  const onSelected = (val: Location, index: number) => {
    listSelected[index].Selected = val.Selected === 'Y' ? 'N' : 'Y'
    setListSelected([...listSelected])
  }

  const isSelectAll = () => {
    let isAll = true
    listSelected.forEach(item => {
      if (item.Selected === 'N') {
        isAll = false
        return
      }
    })
    return isAll
  }

  const onSelectAll = () => {
    const selectAll = isSelectAll()
    listSelected.map(item => {
      return (item.Selected = selectAll ? 'N' : 'Y')
    })
    setListSelected([...listSelected])
  }

  const countSelected = () => {
    let count = 0
    listSelected.forEach((item: Location) => {
      if (item.Selected === 'Y') {
        count++
      }
    })
    return count
  }

  const renderContent = () => {
    return (
      <TabContent>
        <ItemList>
          {listSelected.map((item: Location, index) => {
            return (
              <Item key={index}>
                <RadioBox
                  label={item.Unit}
                  value={item}
                  spaceBtwText={16}
                  readOnly={false}
                  isSelected={item.Selected === 'Y'}
                  onClick={() => onSelected(item, index)}
                />
              </Item>
            )
          })}
        </ItemList>
      </TabContent>
    )
  }

  const count = countSelected()
  return (
    <>
      <Modal
        className="location-modal"
        title={false}
        centered
        visible={visible}
        footer={false}
        closable={false}
      >
        <Body>
          <Header>เลือกพื้นที่</Header>
          <Title>กรุณาเลือกรายการพื้นที่</Title>
          <Content>
            <Row type="flex" justify="space-between">
              <Col span={14}>
                <RadioBox
                  label={'เลือกถนนทั้งหมด'}
                  value={'all'}
                  isSelected={isSelectAll()}
                  onClick={onSelectAll}
                />
              </Col>
              <Col>
                <SelectedTxt>คุณกำลังเลือก {count} รายการ</SelectedTxt>
              </Col>
            </Row>
            {renderContent()}
          </Content>
          <ButtonSection>
            <Row type="flex" justify="space-between">
              <Col span={11}>
                <div className="btn-submit">
                  <SecondaryButton
                    text="ยกเลิก"
                    disabled={false}
                    onClick={() => onClose()}
                  />
                </div>
              </Col>
              <Col span={11}>
                <div className="btn-submit">
                  <PrimaryButton
                    text="บันทึก"
                    onClick={updateSelectedLocation}
                  />
                </div>
              </Col>
            </Row>
          </ButtonSection>
        </Body>
      </Modal>
      <EventModal
        event={[event]}
        enable={enableModal}
        successCalback={onCompleted}
      />
    </>
  )
}

const Header = styled.div`
  text-align: center;
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 24px;
  color: #000;
`

const Title = styled.span`
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 20px;
  color: #000;
`

const SelectedTxt = styled.span`
  font-size: 18px;
  color: #8b99a7
  font-family: 'DB Helvethaica X 55 Regular';
`

const Body = styled.div`
  font-family: 'DB Helvethaica X 55 Regular';
`

const ButtonSection = styled.div`
  margin-top: 32px;
`

const Content = styled.div`
  margin-top: 10px;
`

const TabContent = styled.div`
  font-family: 'DB Helvethaica X 55 Regular';
  font-size: 20px;
  color: #000;
`

const ItemList = styled.div`
  height: 224px;
  overflow: scroll;
  border-radius: 5px;
  border: solid 1px #e4e7eb;
  margin-top: 15px;
`

const Item = styled.div`
  padding: 12px 0;
  border-bottom: solid 1px #e4e7eb;
  margin: 0 16px;

  &:last-child {
    border: none;
  }
`

const mapStateToProps = (state: RootState) => {
  return {
    event: selectLocationEvent(state),
    token: serviceTokenSelector(state),
    UserEmail: userEmailSelector(state),
    Locations: selectLocationList(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LocationModal)
