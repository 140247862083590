import React from 'react'
import SubnavBar from 'Components/Shared/SubnavBar'
import { BreadcrumbLink } from 'Components/Shared/BreadCrumb/BreadCrumb'

type Props = {
  onClose?: () => void
  name ?: string
  reportPage ?: string 
}

const Breadcrumbs: React.ComponentType<Props> = props => {
  const {
    onClose = () => {},
    name = '',
    reportPage = ''
  } = props


  const breadcrumbs: BreadcrumbLink[] = [
    {
      url: '/unit/dashboard',
      title: 'Home',
      icon: '/home.png',
      active: true,
      showTitle: false,
    },
    {
      url: '/unit/dashboard',
      title: 'บอร์ด',
      active: true,
      showTitle: true,
    },
    {
      url: reportPage,
      title: 'ตรวจหน้างาน',
      active: true,
      showTitle: true,
    },
  ]

  if (name) {
    breadcrumbs.push({
      url: ``,
      title: 'เลือกรูปส่งรายงาน',
      active: true,
      showTitle: true,
      onClick: onClose,
    })

    breadcrumbs.push({
      url: ``,
      title: name,
      active: false,
      showTitle: true,
    })
  } else {
    breadcrumbs.push({
      url: ``,
      title: 'เลือกรูปส่งรายงาน',
      active: false,
      showTitle: true,
    })
  }

  return <SubnavBar breadcrumbs={breadcrumbs} hideSearch={true} />
}

export default Breadcrumbs
