import { getType } from 'typesafe-actions'
import { cloneDeep } from 'lodash'
import * as actions from './actions'
import { StoreState, ReportAction } from './types'
import produce from 'immer'
import parseErrorMessage from 'utils/parseErrorMessage'
import { ZoneState, StatusOfTask } from 'features/types/status'

import { Event } from 'features/types/event'

const initEvent = {
  IsLoading: false,
  MessageSuccess: undefined,
  SubmitError: undefined,
  SubmitSuccess: false,
  SubmitType: undefined,
} as Event

const initialState: StoreState = {
  data: {
    ActivityList: [],
    CheckNumber: 0,
    QCTypeID: '',
    QCTypeName: '',
    QCTypeVersion: '',
    Floor: '',
    FloorPlanName: '',
    FloorPlanID: 0,
    RequestID: 0,
    State: ZoneState.CMQC,
    Status: StatusOfTask.INIT,
    Unit: '',
    Zone: '',
  },
  event: cloneDeep(initEvent),
}

export default function(
  state: StoreState = initialState,
  action: ReportAction
): StoreState {
  return produce(state, draft => {
    switch (action.type) {
      case getType(actions.getActivityOnZoneAction.request):
        draft.event = cloneDeep(initEvent)
        draft.event.IsLoading = true
        draft.data = state.data
        break
      case getType(actions.getActivityOnZoneAction.success):
        const res = action.payload.ResponseData
        draft.event.IsLoading = false
        draft.data = res
        break
      case getType(actions.getActivityOnZoneAction.failure):
        draft.event.IsLoading = false
        draft.event.SubmitError = parseErrorMessage(action.payload)
        break

      case getType(actions.startCheckingAction.request):
      case getType(actions.sendToQcAction.request):
      case getType(actions.sendToMcAction.request):
      case getType(actions.sendQCEditAction.request):
        draft.event = cloneDeep(initEvent)
        draft.event.IsLoading = true
        break

      case getType(actions.startCheckingAction.success):
      case getType(actions.sendToQcAction.success):
      case getType(actions.sendToMcAction.success):
      case getType(actions.sendQCEditAction.success):
        draft.event.IsLoading = false
        draft.event.SubmitSuccess = true
        draft.event.MessageSuccess = 'ทำรายการสำเร็จ'
        break

      case getType(actions.startCheckingAction.failure):
      case getType(actions.sendToQcAction.failure):
      case getType(actions.sendToMcAction.failure):
      case getType(actions.sendQCEditAction.failure):
        draft.event.IsLoading = false
        draft.event.SubmitError = parseErrorMessage(action.payload)
        break

      default:
        return state
    }
  })
}
