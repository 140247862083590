import { ZoneState, ZoneDetail } from './types'
import produce from 'immer'
import { cloneDeep } from 'lodash'

import { Event } from '../../types/event'
import parseErrorMessage from 'utils/parseErrorMessage'
import { getType, ActionType } from 'typesafe-actions'
import * as actions from './actions'

export const original: ZoneDetail = {
  CheckListID: 0,
  CheckListName: '',
  Status: 'Ready',
  ProjectTypeID: 0,
  ProjectTypeName: '',
  ProjectList: [],
  JobTypeList: [],
}

const initEvent = {
  IsLoading: false,
  MessageSuccess: undefined,
  SubmitError: undefined,
  SubmitSuccess: false,
  SubmitType: undefined,
} as Event

const initialState: ZoneState = {
  ZoneList: {
    Total: 0,
    List: [],
  },
  ZoneDetail: cloneDeep(original),
  Event: cloneDeep(initEvent),
  ZoneEvent: cloneDeep(initEvent),
  ProjectZoneList: [],
  SelectProjectZoneList: [],
  zoneTypeList: [],
}

export type ZoneAction = ActionType<typeof actions>
export default function(
  state: ZoneState = initialState,
  action: ZoneAction
): ZoneState {
  return produce(state, draft => {
    switch (action.type) {
      case getType(actions.clearZoneDetail):
        draft.ZoneDetail = cloneDeep(original)
        break
      case getType(actions.getZoneListReq.success):
        const ResponseData = action.payload.ResponseData || {}
        draft.ZoneList = {
          Total: ResponseData.Total || 0,
          List: ResponseData.List || [],
        }
        draft.Event.IsLoading = false
        break

      case getType(actions.getZoneDetailReq.success):
        if (action.payload.ResponseData.CheckListID) {
          const zoneCheckList = action.payload.ResponseData
          zoneCheckList.ProjectList = zoneCheckList.ProjectList || []
          zoneCheckList.JobTypeList = zoneCheckList.JobTypeList || []
          zoneCheckList.ProjectList = zoneCheckList.ProjectList.filter(
            item => item.ProjectCode !== ''
          )
          draft.ZoneDetail = zoneCheckList
        }
        draft.Event.IsLoading = false
        break

      case getType(actions.GetZoneTypeListReq.success):
        draft.zoneTypeList = action.payload.ResponseData || []
        break

      case getType(actions.createUpdateZoneReq.request):
      case getType(actions.getZoneListReq.request):
      case getType(actions.getZoneDetailReq.request):
      case getType(actions.deleteReq.request):
        draft.Event = cloneDeep(initEvent)
        draft.Event.IsLoading = true
        break

      case getType(actions.createUpdateZoneReq.success):
      case getType(actions.deleteReq.success):
        draft.Event.IsLoading = false
        draft.Event.SubmitError = undefined
        draft.Event.SubmitSuccess = true
        draft.Event.MessageSuccess = 'ทำรายการสำเร็จ'
        break

      case getType(actions.createUpdateZoneReq.failure):
      case getType(actions.getZoneListReq.failure):
      case getType(actions.getZoneDetailReq.failure):
      case getType(actions.deleteReq.failure):
        draft.Event.IsLoading = false
        draft.Event.SubmitError = parseErrorMessage(action.payload)
        break
      case getType(actions.updateCheckList):
        draft.ZoneDetail = action.payload
        break

      case getType(actions.getProjectZoneReq.request):
        draft.ZoneEvent = cloneDeep(initEvent)
        draft.ZoneEvent.IsLoading = true
        draft.ProjectZoneList = []
        break

      case getType(actions.getProjectZoneReq.success):
        draft.ZoneEvent.IsLoading = false
        draft.ProjectZoneList = action.payload.ResponseData
        break

      case getType(actions.getProjectZoneReq.failure):
        draft.ZoneEvent.IsLoading = false
        draft.ZoneEvent.SubmitError = parseErrorMessage(action.payload)
        break
      default:
        return state
    }
  })
}
