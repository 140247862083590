import React, { useState, useImperativeHandle, forwardRef } from 'react'
import { Modal } from 'antd'
import styled from 'styled-components'

import { CloseIcon } from 'Components/Shared/Icon'

import InputText from 'Components/Shared/Input/InputText'
import {
  CriteriaType,
  CriteriaTypeWithoutScore,
  CheckListDetail,
} from 'features/checklist/unit/types'
import { BottomButton } from 'Components/Shared/Button'
import InputDouble from 'Components/Shared/Input/InputDouble'

export interface CriteriaHandles {
  showModal: (ct?: CriteriaType, ct2?: CriteriaTypeWithoutScore) => void
}

interface Props {
  checkListDetail?: CheckListDetail
  onOk: (text: string, ct?: CriteriaType) => void
}

const CriteriaModal = forwardRef<CriteriaHandles, Props>(
  ({ onOk: onOkProps, checkListDetail }, ref) => {
    const [error, seterror] = useState('')
    const [visible, setvisible] = useState(false)
    const [criteriaName, setCriteriaName] = useState('')
    const [point, setpoint] = useState<string>('')
    const [openWithFocusTxt, setOpenWithFocusTxt] = useState(false)
    const [openWithFocusNum, setOpenWithFocusNum] = useState(false)

    const [criteria, setCriteria] = useState<CriteriaType | undefined>(
      undefined
    )

    const [criteriaEditName, setCriteriaEditName] = useState<
      CriteriaTypeWithoutScore | undefined
    >(undefined)

    const closeModal = () => {
      setvisible(false)
      setCriteriaName('')
      setpoint('')
      seterror('')
      setOpenWithFocusTxt(false)
      setOpenWithFocusNum(false)
    }

    const onOk = () => {
      if (checkListDetail) {
        const found = checkListDetail.CriteriaList.find(c => {
          if (c.CriteriaName === criteriaName) {
            if (criteriaEditName) {
              if (criteriaEditName.CriteriaName === criteriaName) return false
            }
            return true
          }
          return false
        })
        if (found) {
          seterror('criteria นี้ได้มีอยู่แล้ว')
          return
        }
      }

      closeModal()
      let updateCri
      if (criteria && point != null) {
        criteria.Score = parseFloat(point)
        updateCri = criteria
        setCriteria(undefined)
      }
      if (criteriaEditName) {
        updateCri = criteriaEditName
        setCriteriaEditName(undefined)
      }

      onOkProps(criteriaName, updateCri)
    }

    useImperativeHandle(ref, () => ({
      showModal: (ct?: CriteriaType, ct2?: CriteriaTypeWithoutScore) => {
        setvisible(true)
        if (ct) {
          setpoint(ct.Score != null ? `${ct.Score}` : '')
          setCriteria(ct)
        }
        if (ct2) {
          setCriteriaEditName(ct2)
          setCriteriaName(ct2.CriteriaName)
        }
        setOpenWithFocusTxt(true)
        setOpenWithFocusNum(true)
      },
    }))

    const title = criteria ? 'แก้ไข' : 'เพิ่ม'
    return (
      <ModalCheckList>
        <Modal
          centered
          visible={visible}
          width="528px"
          footer={null}
          closeIcon={<CloseIcon color="#8b99a7" size={16} />}
          title={<Header>{`${title}รายการ`}</Header>}
          className="add-checklist-modal"
          onCancel={closeModal}
        >
          <ModalContent>
            <SelectType>
              <Title>
                {criteria
                  ? `${title} ${criteria.CriteriaName}`
                  : 'เพิ่ม Criteria'}
              </Title>
            </SelectType>

            {criteria ? (
              <InputDouble
                prefix=" "
                value={point}
                placeholder="กรอกคะแนน"
                onChange={val => setpoint(val)}
                openWithFocus={openWithFocusNum}
              />
            ) : (
              <InputText
                prefix=""
                placeholder="กรอก criteria"
                onChange={setCriteriaName}
                value={criteriaName}
                openWithFocus={openWithFocusTxt}
              />
            )}

            <BottomButton closeModal={closeModal} onSave={onOk} error={error} />
          </ModalContent>
        </Modal>
      </ModalCheckList>
    )
  }
)

const Header = styled.span`
  font-size: 28px;
  color: #000000;
  font-family: 'DB Helvethaica X 75 Bd';
`
const Title = styled.span`
  font-size: 20px;
  color: #000000;
  font-family: 'DB Helvethaica X 75 Bd';
`

const ModalCheckList = styled.div`
  .add-checklist-modal {
    .ant-modal-header {
      border-bottom: none;
    }
  }
`

const ModalContent = styled.div`
  padding: 0 24px 31px 24px;
  .ant-input {
    font-size: 20px;
  }
`

const SelectType = styled.div`
  padding-top: 17px;
`

export default CriteriaModal
