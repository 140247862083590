import React, { useEffect, useState, useRef } from 'react'
import { connect, useDispatch } from 'react-redux'
import RootState from 'typings/RootState'
import styled from 'styled-components'
import { Row, Col, Button } from 'antd'
import { RouteComponentProps } from 'react-router-dom'
import { BreadcrumbLink } from 'Components/Shared/BreadCrumb/BreadCrumb'
import Menu from '../../MenuAdmin'
import SubnavBar from 'Components/Shared/SubnavBar'
import {
  selectedProjectCodeSelector,
  projectsByIdSelector,
} from 'features/project/project.selector'
import {
  DataItem,
  GetDataListReq,
  UpdateDataReq,
} from 'features/adminpj/modelHouse/types'
import {
  serviceTokenSelector,
  userEmailSelector,
} from 'features/sensitive/sensitive.selector'
import {
  selectDataList,
  selectEvent,
} from 'features/adminpj/modelHouse/selector'
import { clearFilter, updateActivityFilter } from 'features/filter/actions'
import { selectActivityFilter, selectUnitList } from 'features/filter/selector'
import { getDataList, updateData } from 'features/adminpj/modelHouse/api'
import RowItem from './RowItem'
import { TableColHeader, TableHeader } from 'Components/Shared/Table'
import {
  AdminProject,
  AlertError,
  AlertSuccess,
  LoadingView,
} from 'Components/Shared'
import { changeSelectedProjectAction } from 'features/selectedProject/selectedProject.actions'
import { DropdownPopupMenu } from 'Components/Shared/Form'
import UnitContent from 'Components/Shared/Filters/UnitContent'
import { ActivityFilter, GetUnitListReq } from 'features/filter/types'
import { getUnitList } from 'features/filter/api'
import Pagination from 'Components/Shared/Pagination'

const breadcrumbs: BreadcrumbLink[] = [
  {
    url: '/dashboard',
    title: 'Home',
    icon: '/home.png',
    active: true,
    showTitle: false,
  },
  {
    url: '/mockup-unit',
    title: 'บ้าน/ห้องตัวอย่าง',
    active: false,
    showTitle: false,
  },
]

type Props = DispatchProps & RouteComponentProps & ReduxProps

const ModelHouse: React.ComponentType<Props> = props => {
  const {
    UserEmail,
    token,
    ProjectCode,
    dataList,
    event,
    projectsById,
    filter,
    unitList,
  } = props

  const [pagination, setPagination] = useState<{
    Page: number
    RowPerPage: number
  }>({
    Page: 1,
    RowPerPage: 30,
  })

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(clearFilter())
    onGetUnitList()
    onGetList()
  }, [ProjectCode, pagination, UserEmail])

  const onFilterChange = (filter: ActivityFilter) => {
    dispatch(updateActivityFilter(filter))
  }

  const onGetUnitList = () => {
    const req: GetUnitListReq = {
      ProjectCode,
      UserEmail,
      Zone: 'Resident Area',
    }
    dispatch(getUnitList(token, req))
  }

  const onGetList = () => {
    const req: GetDataListReq = {
      UserEmail,
      ProjectCode,
      UnitList: filter.UnitList,
      ModelHouseFlag: false,
      Page: pagination.Page,
      RowPerPage: pagination.RowPerPage,
    }
    dispatch(getDataList(token, req))
  }

  const onUpdateData = (unitCode: string, updateFlag: boolean) => {
    const req: UpdateDataReq = {
      UserEmail,
      ProjectCode,
      UnitCode: unitCode,
      ModelHouseFlag: updateFlag,
    }
    dispatch(updateData(token, req))
  }

  const onProjectCode = (code: string) => {
    dispatch(changeSelectedProjectAction(code))
  }

  const onClick = () => {
    dispatch(updateActivityFilter(filter))
    onGetList()
  }

  const header = (
    <Header>
      <Row type="flex" justify="space-between" align="middle">
        <Col>รายการบ้านและห้องชุด</Col>
        <Col>
          <Row type="flex" justify="space-between" align="middle">
            <Col></Col>
          </Row>
        </Col>
      </Row>
    </Header>
  )

  const filterContent = (
    <Header>
      <Row type="flex" justify="start" align="middle">
        <Col style={{ width: '360px' }}>
          <AdminProject
            projectList={projectsById}
            onSelectProject={onProjectCode}
            activeProject={ProjectCode}
          />
        </Col>
        <Col style={{ width: '360px' }}>
          <Box>
            <DropdownPopupMenu
              label={'ยูนิต'}
              placeholder="เลือกยูนิต"
              value={filter.UnitList.join(',')}
              disabled={ProjectCode === ''}
              content={
                <UnitContent
                  units={unitList.List}
                  filter={filter.UnitList}
                  onChange={data => {
                    filter.UnitList = data
                    onFilterChange(filter)
                  }}
                />
              }
              isActive={filter.UnitList.length > 0}
            />
          </Box>
        </Col>
        <Button type="primary" onClick={onClick}>
          กรองข้อมูล
        </Button>
      </Row>
    </Header>
  )

  const columns = [
    {
      key: 'Unit',
      title: 'Unit',
      span: 4,
    },
    {
      key: 'Model',
      title: 'Model',
      span: 4,
    },
    {
      key: 'UpdateBy',
      title: 'แก้ไขโดย',
      span: 4,
    },
    {
      key: 'UpdateDate',
      title: 'วันที่แก้ไขล่าสุด',
      span: 4,
    },
    {
      key: 'ModelHouseFlag',
      title: 'ตั้งค่าเป็นห้องตัวอย่าง',
      span: 4,
    },
  ]

  const content =
    dataList &&
    dataList.List.map((item: DataItem, index: number) => {
      return (
        <RowItem
          key={index}
          item={item}
          onUpdate={item => onUpdateData(item.Unit, !item.ModelHouseFlag)}
        />
      )
    })

  return (
    <>
      <LoadingView isShow={event.IsLoading} />
      <AlertSuccess
        isShow={event.SubmitSuccess ? true : false}
        text={event.MessageSuccess}
        callback={() => onGetList()}
      />
      <AlertError
        isShow={event.SubmitError ? true : false}
        text={event.SubmitError}
      />
      <SubnavBar breadcrumbs={breadcrumbs} hideSearch={true} />
      <Menu selected="model-house">
        <MainPage>
          {header}
          {filterContent}
          <TableHeader>
            {columns.map((item: any, index: number) => {
              return (
                <TableColHeader span={item.span} isPadding={true} key={index}>
                  {item.title}
                </TableColHeader>
              )
            })}
          </TableHeader>
          {content}
          <Pagination
            currentPage={pagination.Page}
            totalRows={dataList.Total}
            perPage={pagination.RowPerPage}
            onChangeNumber={(page: number) => {
              pagination.RowPerPage = page
              setPagination({ ...pagination })
            }}
            onClickPage={(page: number) => {
              pagination.Page = page
              setPagination({ ...pagination })
            }}
          />
        </MainPage>
      </Menu>
    </>
  )
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapStateToProps = (state: RootState) => {
  return {
    UserEmail: userEmailSelector(state),
    token: serviceTokenSelector(state),
    ProjectCode: selectedProjectCodeSelector(state),
    projectsById: projectsByIdSelector(state),
    dataList: selectDataList(state),
    event: selectEvent(state),
    filter: selectActivityFilter(state),
    unitList: selectUnitList(state),
  }
}

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(ModelHouse)

const MainPage = styled.div`
  padding: 40px 45px;
  width: 100%;
`

const Header = styled.div`
  margin-bottom: 10px;
  font-size: 28px;
  line-height: 1.07;
  font-family: 'DB Helvethaica X 75 Bd';
  color: #000;
`
const Box = styled.div`
  padding: 10px 12px 12px 0;
  margin-left: 10px;
`
