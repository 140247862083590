import React, { useState } from 'react'
import { Row, Col } from 'antd'
import styled from 'styled-components'
import ErrorMessage from './ErrorMessage'
import { translate } from '../../i18n'
import TKeys from '../../i18n/translationKey'
import { LIMIT_FILE } from '../../config'
import file from '../../utils/file'
import Picker from './Form/Picker'
import Editor from './Editor'
import imgPhoto from 'assets/img/ic-photo.png'
import FileEmpty from './FileEmpty'

type DefaultProps = {
  Images: any[]
  onChange?: (images: any[]) => void
  onImageOpen?: () => void
  onThumbnailOpen?: (index: number) => void
  disabled: boolean
  showMax?: number
  numberOfImage?: number
  viewMode?: boolean
  isLimit?: boolean
  showMoreTxt?: boolean
  isMultiple?: boolean
}
type Props = DefaultProps

const ImagePicker: React.FC<Props> = ({
  Images,
  onChange = (images: any[]) => {},
  onImageOpen = () => {},
  onThumbnailOpen = (index: number) => {},
  disabled,
  showMax = 2,
  numberOfImage = 5,
  viewMode = false,
  isLimit = false,
  showMoreTxt = false,
  isMultiple = false,
}) => {
  const [showEditor, setShowEditor] = useState(false)
  const [fileEditor, setFileEditor] = useState({
    base64: '',
    file: { name: '' },
    lastModified: 0,
  })
  const [loading, setLoading] = useState(false)
  const [showError, setShowError] = useState(false)
  const [error, setError] = useState('')

  const addFile = async (base64: any, fileItem: any) => {
    try {
      const lastModified = fileItem.lastModified
      setLoading(true)
      let resp
      if (fileItem.type === 'image/heic') {
        resp = await file.heicToJpeg({
          file: fileItem,
          filename: fileItem.name,
        })
        base64 = resp.text
        fileItem = resp.file
      }

      if (fileItem.size < LIMIT_FILE.image) {
        resp = await file.compress({
          value: base64,
          file: fileItem,
          limit: LIMIT_FILE.image,
        })
        base64 = resp.text
        fileItem = resp.file
      }

      if (!isMultiple) {
        setShowEditor(true)
        setFileEditor({ base64, file: fileItem, lastModified })
      } else {
        setShowEditor(false)
        setFileEditor({ base64, file: fileItem, lastModified })
        await onAddImage(base64, fileItem.name, lastModified)
      }
    } catch (e) {
      setError(translate(TKeys.Error.file_wrong))
      setShowError(true)
    }

    setLoading(false)
  }

  const onAddImage = async (
    base64: string,
    fileName = '',
    lastModified = 0
  ) => {
    try {
      const params = {
        data: base64,
        filename: fileEditor.file.name === '' ? fileName : fileEditor.file.name,
        lastModified:
          fileEditor.lastModified === 0
            ? lastModified
            : fileEditor.lastModified,
      }
      const fileItem: any = await file.base64ToFile(params)
      Images.unshift({
        text: base64,
        file: fileItem,
        lastModified: fileItem.lastModified,
      })
      onChange(Images)
      setFileEditor({
        base64: '',
        file: { name: '' },
        lastModified: 0,
      })
    } catch (e) {
      setError(e.message)
      setShowError(true)
    }
    setShowEditor(false)
  }

  const renderImg = (file: any, size: number = 0, index: number = 0) => {
    const imgContent =
      file && file.FileID && file.FileURL ? file.FileURL : file.text
    if (size > 0) {
      return (
        <ImgBlock onClick={onImageOpen}>
          <ImageMoreItem src={imgContent} />
          <span className="std-font-bold">+{size}</span>
        </ImgBlock>
      )
    } else {
      return (
        <ImgBlock onClick={() => onThumbnailOpen(index)}>
          <ImageItem src={imgContent} />
        </ImgBlock>
      )
    }
  }

  const text = translate(TKeys.ActivityDetail.picture)

  let contents = []
  let moreContent
  const size = Images.length

  if (size > 0) {
    for (let index = 1; index <= size; index++) {
      if (index >= showMax) {
        let more = size - showMax
        moreContent = renderImg(Images[showMax - 1], more, index - 1)
      } else {
        contents.push(renderImg(Images[index - 1], 0, index - 1))
      }
    }
  }

  const renderContent = contents.map((content, index) => {
    return <div key={index}>{content}</div>
  })

  return (
    <>
      <Editor
        visible={showEditor}
        image={fileEditor.base64}
        onClose={() => setShowEditor(false)}
        onOk={onAddImage}
      />
      <div className="form-label">
        <Row type="flex" justify="space-between">
          <Col>
            <>
              {text}
              {!viewMode && (
                <SubTitle>
                  {isLimit && `จำกัด ${numberOfImage} รูป `}
                  ขนาดไม่เกิน 5MB ต่อไฟล์ (.png .jpg .HEIC)
                </SubTitle>
              )}
            </>
          </Col>
          {showMoreTxt && Images.length > 0 && (
            <Col onClick={onImageOpen}>
              <>
                <LinkSeemore>ดูรูปภาพทั้งหมด</LinkSeemore>
              </>
            </Col>
          )}
        </Row>
      </div>
      <Row>
        {viewMode && Images.length <= 0 ? (
          <FileEmpty image={imgPhoto} text="ไม่มีรูปภาพ" />
        ) : (
          <ImgLine>
            {!viewMode && (
              <ImageAddbtn>
                <Picker
                  disabled={size === numberOfImage || disabled}
                  loading={loading}
                  text={text}
                  accept=".png,.jpg,.heic"
                  onChange={addFile}
                  isMultiple={isMultiple}
                />
              </ImageAddbtn>
            )}

            {renderContent}
            {moreContent}
          </ImgLine>
        )}
      </Row>
      <ErrorMessage
        visible={showError}
        text={error}
        onClose={() => setShowError(false)}
      />
    </>
  )
}

const ImgLine = styled.div`
  display: inline-flex;
`

const ImgBlock = styled.div`
  position: relative;
  text-align: center;
  span {
    color: white;
    font-size: 28px;
    position: absolute;
    top: 55px;
    left: 55px;
    transform: translate(-50%, -50%);
    cursor: pointer;
  }
`

const SubTitle = styled.span`
  color: #8b99a7;
  font-size: 16px;
  margin-left: 8px;
`

const CssImg = `
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-right: 8px;
  border-radius: 3px;
`
const ImageItem = styled.img`
  ${CssImg}
`

const ImageMoreItem = styled.img`
  ${CssImg}
  filter: brightness(0.4);
  :hover {
    cursor: pointer;
  }
`
const ImageAddbtn = styled.div`
  margin-right: 8px;
`

const LinkSeemore = styled.p`
  color: #003168;
  font-family: 'DB Helvethaica X 75 Bd';
  text-decoration: underline;
  cursor: pointer;
  font-size: 18px;
`

export default ImagePicker
