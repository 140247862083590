import React, { useEffect } from 'react'
import { Row, Col, Input } from 'antd'
import styled from 'styled-components'
import ImagePicker from 'Components/Shared/ImagePicker'
import DocumentPicker from 'Components/Shared/DocumentPicker'
import { CurrentActivity } from 'features/maincon/activity/types'
import imgHistory from 'assets/img/ic-history.png'
import {
  getMainconByStatus,
  getApprovalByStatus,
  getAdminByStatus,
} from 'utils/statusLookup'
import { RemarkBox, TaskInfo } from 'Components/Activity'
import { TaskState } from 'features/types/status'
import { isUnit } from 'utils/helper'
import { PrimaryButton } from 'Components/Shared/Button'

type Props = {
  isSubmitting: boolean
  onImageOpen: () => void
  onDocumentOpen: () => void
  onThumbnailOpen: (index: number) => void
  originalData: any
  onHistoryOpen?: () => void
  readOnly?: boolean
  wbs?: any
  onReturnTask: () => void
}

const TaskDetail: React.FunctionComponent<Props> = ({
  onImageOpen,
  onDocumentOpen,
  onThumbnailOpen,
  originalData,
  onHistoryOpen,
  readOnly = false,
  wbs,
  onReturnTask,
}) => {
  let status = getAdminByStatus(originalData.State, originalData.TaskStatus)

  const QcInfo = isUnit(originalData.Zone) ? (
    <Col>
      <TextHeader>
        หมวดงาน QC<span>{originalData.QCTypeName}</span>
      </TextHeader>
    </Col>
  ) : (
    undefined
  )
  const header = (
    <Row type="flex" justify="space-between" className="header">
      <Col span={10}>
        <Row type="flex" justify="space-between">
          <Col>
            <TextHeader>
              ตึก<span>{originalData.TowerName}</span>
            </TextHeader>
          </Col>
          <Col>
            <TextHeader>
              ชั้น<span>{originalData.FloorName}</span>
            </TextHeader>
          </Col>
          <Col>
            <TextHeader>
              ยูนิต<span>{originalData.Unit}</span>
            </TextHeader>
          </Col>
          {QcInfo}
        </Row>
      </Col>
      <Col span={14}>
        <RightConner>
          <HistoryBtn onClick={onHistoryOpen}>
            <img src={imgHistory} alt="Go to activity history" />
            ประวัติการทำงาน
          </HistoryBtn>
          <Status style={{ backgroundColor: status.color }}>
            {status.text}
          </Status>
          {originalData.ActivityStatus === 'Deleted' && (
            <Btn>
              <PrimaryButton
                text="คืนงาน"
                disabled={false}
                onClick={onReturnTask}
                btnType="Reject"
              />
            </Btn>
          )}
        </RightConner>
      </Col>
    </Row>
  )

  const maxFile = readOnly ? 4 : 3
  const filesInfo = (
    <BoxItem>
      <Row type="flex" justify="space-between">
        <Col md={12}>
          <ImagePicker
            Images={originalData.McImages}
            onChange={() => {}}
            onImageOpen={onImageOpen}
            onThumbnailOpen={onThumbnailOpen}
            disabled={readOnly}
            showMax={maxFile}
            numberOfImage={50}
            viewMode={readOnly}
          />
        </Col>
        <Col md={12}>
          <DocumentPicker
            Documents={originalData.McDocuments}
            onChange={() => {}}
            onDocumentOpen={onDocumentOpen}
            disabled={readOnly}
            showMax={maxFile}
            numberOfFile={50}
            viewMode={readOnly}
          />
        </Col>
      </Row>
      <Space />
      <RemarkBox
        onChange={() => {}}
        value={originalData.McRemark}
        readOnly={readOnly}
      />
      <Space />
    </BoxItem>
  )

  return (
    <MainView>
      {header}
      <TaskInfo originalData={originalData} wbs={wbs} />
      {filesInfo}
    </MainView>
  )
}

const MainView = styled.div``
const TextHeader = styled.div`
  font-size: 28px;
  font-family: 'DB Helvethaica X 75 Bd';
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  span {
    font-family: 'DB Helvethaica X 55 Regular';
    padding-left: 4px;
  }
`

const RightConner = styled.div`
  float: right;
`
const HistoryBtn = styled.span`
  color: #003168;
  font-size: 18px;
  font-family: 'DB Helvethaica X 75 Bd';
  img {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    margin-bottom: 5px;
  }

  :hover {
    cursor: pointer;
  }
`

const Status = styled.span`
  height: 20px;
  border-radius: 10px;
  font-size: 16px;
  color: #fff;
  text-align: center;
  line-height: 1.4;
  padding: 0px 12px;
  margin-left: 15px;

  &.init {
    background-color: #8b99a7;
  }

  &.progress {
    background-color: #003168;
  }
`

const Space = styled.div`
  margin-top: 24px;
`

const Btn = styled.div`
  width: 160px;
  float: right;
  margin-left: 24px;
`

const BoxItem = styled.div`
  background-color: #fff;
  margin-top: 12px;
  border-radius: 5px;
  border: solid 1px #f2f2f2;
  padding: 24px;
  line-height: normal;
`

export default TaskDetail
