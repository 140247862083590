import React, { useState, useEffect } from 'react'
import RootState from 'typings/RootState'
import { connect, useDispatch } from 'react-redux'
import { Layout, Row, Col } from 'antd'
import styled from 'styled-components'
import { selectEvent } from 'features/pmr/selector'
import {
  userEmailSelector,
  serviceTokenSelector,
} from 'features/sensitive/sensitive.selector'

import { selectUploadEvent } from 'features/uploadfile/selector'
import { selectDefect } from 'features/pmr/selector'
import DefectImage from 'Components/Zone/DefectImage'
import { DefectItem } from 'features/pmr/types'
import DefectInfo from '../DefectInfo'
import SaveFooter from './SaveFooter'
import { DropdownList } from 'Components/Shared/Form'

const { Content } = Layout

type Props = {
  onSendReport?: (
    item: DefectItem,
    fixReason: string,
    groupType: string
  ) => void
  floor: any
} & ReduxProps

const DefectGrouping: React.FunctionComponent<Props> = props => {
  const [fixReason, setFixReason] = useState('')
  const [groupType, setGroupType] = useState('NoFixCondition')

  const noFunc = () => {}
  const { defect, onSendReport = noFunc, floor } = props

  const fixReasonConfig = [
    {
      name: 'วัสดุ',
      value: 'วัสดุ',
    },
    {
      name: 'ออกแบบ',
      value: 'ออกแบบ',
    },
    {
      name: 'งานก่อสร้าง',
      value: 'งานก่อสร้าง',
    },
  ]

  const groupTypes = [
    {
      name: 'แก้ภายหลัง',
      value: 'FixLater',
    },
    {
      name: 'ไม่แก้แบบมีเงื่อนไข',
      value: 'NoFixCondition',
    },
  ]

  let footer = (
    <SaveFooter
      onSave={() => onSendReport(defect, fixReason, groupType)}
      disabled={
        groupType === '' || (fixReason === '' && groupType === 'NoFixCondition')
      }
    />
  )

  const onSelectGroupType = (val: string) => {
    setGroupType(val)
    if (val === 'FixLater') {
      setFixReason('')
    }
  }

  const span = 24 / groupTypes.length || 0
  const groupItem = (
    <Group>
      <Row type={'flex'}>
        {groupTypes.map((item, index) => {
          const isSelect = item.value === groupType
          return (
            <Col span={span} key={index}>
              <Item
                className={isSelect ? 'active' : ''}
                onClick={() => onSelectGroupType(item.value)}
              >
                {item.name}
              </Item>
            </Col>
          )
        })}
      </Row>
    </Group>
  )

  const dropdown = (
    <Reason>
      <DropdownList
        label="เหตุผลที่แก้แบบมีเงื่อนไข"
        value={fixReason}
        placeholder={'กรุณาเลือก'}
        menu={fixReasonConfig}
        onChange={setFixReason}
        disabled={groupType === 'FixLater'}
      />
    </Reason>
  )

  return (
    <>
      <Content className="mid-content">
        <div className="main-content activity-detail">
          <DefectInfo defect={defect} />
          <GroupingArea>
            <Title>กลุ่ม Defect</Title>
            <Row type="flex">
              <Col span={8}>{groupItem}</Col>
              <Col span={16}>{dropdown}</Col>
            </Row>
            <DefectImage
              planImg={
                defect.ImageFloorPlan
                  ? defect.ImageFloorPlan.FileURL
                  : undefined
              }
              defect={defect}
              onAddAfterImage={() => {}}
              viewMode={true}
              canUpAfterImg={false}
              withBorder={false}
              floor={floor}
            />
          </GroupingArea>
        </div>
      </Content>
      {footer}
    </>
  )
}

const GroupingArea = styled.div`
  border-radius: 5px;
  border: solid 1px #eeeff2;
  background-color: white;
  padding: 10px;
  margin-top: 24px;
`

const Title = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 20px;
  margin: 24px 15px;
`

const Group = styled.div`
  border: solid 1px #eeeff2;
  border-radius: 5px;
  margin: 0 15px;
`

const Reason = styled.div`
  padding: 0 15px;
`

const Item = styled.div`
  text-align : center;
  float : left;
  font-size: 18px;
  font-family: 'DB Helvethaica X 55 Regular';
  width: 100%;
  padding : 10px 0;
  cursor : pointer;

  &.active{
    background-color : #003168;
    color #fff;
    border-radius: 5px;
  }
`

const mapStateToProps = (state: RootState) => {
  return {
    Token: serviceTokenSelector(state),
    UserEmail: userEmailSelector(state),
    defect: selectDefect(state),
    event: selectEvent(state),
    uploadEvent: selectUploadEvent(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DefectGrouping)
