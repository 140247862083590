import { getType } from 'typesafe-actions'
import * as actions from './actions'
import { StoreState, SeemoreAction, Data } from './types'
import produce from 'immer'
import parseErrorMessage from 'utils/parseErrorMessage'
import { filterActivity } from 'utils/helper'
import { sortingActivityList } from './function'

const initialState: StoreState = {
  dataResponse: null,
  data: {
    TotalRows: 0,
    DataList: [] as Data[],
  },
  pageType: 1,
  event: {
    IsLoading: false,
    MessageSuccess: '',
    SubmitError: '',
    SubmitSuccess: false,
    SubmitType: '',
  },
  sorting: {
    name: 'FinishDate',
    type: 'asc',
  },
  query: {
    date: {
      start: undefined,
      end: undefined,
    },
  },
  filter: {
    progress: [],
    status: [],
    wbs: [],
    date: {
      mode: '',
      start: undefined,
      end: undefined,
    },
    floor: [],
    unit: [],
    zone: [],
    type: [],
  },
}

export default function(
  state: StoreState = initialState,
  action: SeemoreAction
): StoreState {
  return produce(state, draft => {
    const sorting = state.sorting
    const filter = state.filter

    switch (action.type) {
      case getType(actions.GetActivitySeeMoreForCMAction.request):
        draft.event.SubmitError = undefined
        draft.event.IsLoading = true
        draft.event.SubmitSuccess = false
        draft.data = {
          TotalRows: 0,
          DataList: [],
        }
        break
      case getType(actions.GetActivitySeeMoreForCMAction.success):
        const res = action.payload.ResponseData

        let resData = [] as Data[]
        let total = 0
        if (draft.pageType === 1) {
          total = res.ToDoTotalRows
          resData = res.ToDo
        } else if (draft.pageType === 2) {
          total = res.InProgressTotalRows
          resData = res.InProgress
        } else {
          total = res.CompleteTotalRows
          resData = res.Complete
        }

        draft.event.IsLoading = false
        const dataList = filterActivity(resData, filter)
        const dataSort = sortingActivityList(
          dataList,
          draft.sorting.type,
          draft.sorting.name
        )
        draft.data = {
          TotalRows: total,
          DataList: dataSort,
        }
        break

      case getType(actions.GetActivitySeeMoreForCMAction.failure):
        draft.event.IsLoading = false
        draft.event.SubmitError = parseErrorMessage(action.payload)
        break

      case getType(actions.sortingTask):
        const sorting = state.sorting
        const params = action.payload
        if (sorting.name === params.name) {
          draft.sorting.type = sorting.type === 'asc' ? 'desc' : 'asc'
        } else {
          draft.sorting.name = params.name
          draft.sorting.type = 'asc'
        }
        if (params.sort != null) {
          draft.sorting.type = params.sort
        }

        const list = sortingActivityList(
          draft.data.DataList,
          draft.sorting.type,
          draft.sorting.name
        )
        draft.data.DataList = list
        break

      case getType(actions.setPageType):
        const page = action.payload.pageType
        draft.pageType = page
        break

      default:
        return state
    }
  })
}
