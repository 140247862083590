import React, { useEffect } from 'react'
import styled from 'styled-components'
import { connect, useDispatch } from 'react-redux'
import SubnavBar from 'Components/Shared/SubnavBar'
import { Row, Col } from 'antd'
import { RouteComponentProps } from 'react-router-dom'
import RootState from 'typings/RootState'
import { BreadcrumbLink } from 'Components/Shared/BreadCrumb/BreadCrumb'
import { selectedProjectCodeSelector } from 'features/project/project.selector'
import {
  userEmailSelector,
  serviceTokenSelector,
} from 'features/sensitive/sensitive.selector'
import {
  selectData,
  selectEvent,
  selectSorting,
} from 'features/cmpj/road/construct/dashboard/selector'
import { sortingDashboard } from 'features/cmpj/road/construct/dashboard/actions'
import { getDashboard } from 'features/cmpj/road/construct/dashboard/api'
import {
  GetDashboardReq,
  TypeOfTask,
} from 'features/cmpj/road/construct/dashboard/types'
import { translate } from 'i18n'
import TKeys from 'i18n/translationKey'

import NoCard from 'Components/Shared/NoCard'
import Loading from 'Components/Shared/Loading'
import { ColumnHeader } from 'Components/Shared'
import RoadTab from 'Components/Shared/Tab/RoadTab'

import Menu from '../../../Menu'
import InProgressCard from './InProgressCard'
import FixFromQcCard from './FixFromQcCard'

const breadcrumbs: BreadcrumbLink[] = [
  {
    url: '/',
    title: 'Home',
    icon: '/home.png',
    active: true,
    showTitle: false,
  },
  {
    url: '/',
    title: translate(TKeys.Breadcrumb.dashboard),
    active: false,
    showTitle: false,
  },
]

type Props = ReduxProps & DispatchProps & RouteComponentProps

const CmConstructRoadDashboard: React.ComponentType<Props> = props => {
  const {
    data,
    event,
    sorting,
    token,
    ProjectCode,
    UserEmail,
    sortingTask,
    history,
  } = props

  const dispatch = useDispatch()
  useEffect(() => {
    getDashboardData()
  }, [ProjectCode])

  const getDashboardData = () => {
    const req: GetDashboardReq = {
      ProjectCode,
      UserEmail,
    }

    dispatch(getDashboard(token, req))
  }

  const gotoCreateRequest = () => {
    history.push('/construct-road/create')
  }

  const onSendRequest = () => {}

  const loading = (
    <div style={{ textAlign: 'center' }}>
      <Loading />
    </div>
  )

  const renderCard = (task: any[], type: string) => {
    let card = [] as any

    if (type === TypeOfTask.InProgress) {
      card = task.map((activity, index) => (
        <InProgressCard key={index} task={activity} />
      ))
    }

    if (type === TypeOfTask.FixFromQC) {
      card = task.map((activity, index) => (
        <FixFromQcCard key={index} task={activity} />
      ))
    }

    return card
  }

  const renderTaskLen = (
    task: any[],
    header: string,
    orderName: string,
    type: string
  ) => {
    const card =
      task && task.length > 0 ? (
        renderCard(task, type)
      ) : (
        <NoCard text="ไม่มีรายการ" />
      )
    const content = event.IsLoading ? loading : card
    const title = `${header}`
    return (
      <Col span={8}>
        <FullColumn>
          <ColumnHeader
            heading={`${title} (${task.length})`}
            orderName={orderName}
            type={sorting[type]}
            onSort={() => {
              sortingTask(type)
            }}
          />
          {content}
        </FullColumn>
      </Col>
    )
  }

  return (
    <>
      <Menu selected="road" />
      <SubnavBar breadcrumbs={breadcrumbs} hideSearch={true} />
      <RoadTab
        selected="ConstructRoad"
        onClick={gotoCreateRequest}
        visible={true}
      />
      <Space />
      <MainPage>
        <Row type="flex">
          {renderTaskLen(
            data.InProgress,
            'QC กำลังดำเนินการ ',
            'ล่าสุด',
            TypeOfTask.InProgress
          )}
          {renderTaskLen(
            data.FixFromQC,
            'ผลการตรวจ',
            'ล่าสุด',
            TypeOfTask.FixFromQC
          )}
        </Row>
      </MainPage>
    </>
  )
}

const MainPage = styled.div`
  padding: 0 120px;
  background-color: #f5f6fa;
  width: 100vw;
  -khtml-user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  overflow-x: scroll;
`

const FullColumn = styled.div`
  padding-right: 20px;
`

const Space = styled.div`
  margin: 20px 0;
`

const mapStateToProps = (state: RootState) => {
  return {
    data: selectData(state),
    event: selectEvent(state),
    sorting: selectSorting(state),
    token: serviceTokenSelector(state),
    ProjectCode: selectedProjectCodeSelector(state),
    UserEmail: userEmailSelector(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {
  sortingTask: sortingDashboard,
}

type DispatchProps = typeof mapDispatchToProps

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CmConstructRoadDashboard)
