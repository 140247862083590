import React, { useEffect, useRef, useState } from 'react'
import { Col, Layout, Row } from 'antd'
import styled from 'styled-components'
import {
  ReportHeader,
  ReportLogo,
  DescItem,
  ImageReport,
} from 'Components/AdminQC/Report/Components'
import { ContructionTagName } from 'Components/Shared'
import DocumentPicker from 'Components/Shared/DocumentPicker'
import { MainConChecklist } from 'Components/Activity'
import { connect, useDispatch } from 'react-redux'
import { getActivityById, getCheckListById } from 'features/cmpj/activity/api'
import {
  selectCheckList,
  selectOriginalActivity,
  selectEvent,
} from 'features/cmpj/activity/selector'
import RootState from 'typings/RootState'
import {
  serviceTokenSelector,
  userEmailSelector,
} from 'features/sensitive/sensitive.selector'
import DocumentDisplay from 'Components/Shared/DocumentDisplay'
import ThumbnailDisplay from 'Components/Shared/ThumbnailDisplay'
import EventModal from 'Components/Shared/Modal/EventModal'

const { Content } = Layout

type Props = {
  ProjectCode: string
  ActivityID: string
  onSetPage?: (page: string) => void
  page?: string
} & ReduxProps

const ReportDetail: React.ComponentType<Props> = props => {
  const componentPrint = useRef(null)
  const [lastPage, setLastPage] = useState('')
  const [showThump, setShowThump] = useState(false)
  const [imageSelected, setImageSelected] = useState(0)
  const [readOnly, setReadOnly] = useState(false)
  const {
    ProjectCode,
    ActivityID,
    UserEmail,
    Token,
    data,
    checkList,
    event,
    onSetPage = () => {},
    page = '',
  } = props

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(
      getActivityById(
        {
          UserEmail,
          ProjectCode,
          ActivityID,
        },
        Token
      )
    )

    dispatch(
      getCheckListById(
        {
          UserEmail,
          ProjectCode,
          ActivityID,
        },
        Token
      )
    )
  }, [UserEmail, ProjectCode, ActivityID, Token])

  const onPageClose = () => {
    onSetPage(lastPage)
    if (lastPage !== '') {
      setLastPage('')
    }
  }

  const onDocumentOpen = (list?: any[], readOnly?: boolean) => {
    onSetPage('detail.preview')
    setLastPage(page)
    setReadOnly(readOnly || false)
  }

  const onThumbnailOpen = (index: number, list?: any[], readOnly?: boolean) => {
    setLastPage(page)
    setShowThump(true)
    setReadOnly(readOnly || false)
    setImageSelected(index)
  }

  if (showThump) {
    return (
      <ThumbnailDisplay
        onClose={() => setShowThump(false)}
        readOnly={true}
        images={data.CmImages}
        index={imageSelected}
        onChange={() => {}}
      />
    )
  }

  if (page === 'detail.preview') {
    return (
      <DocumentDisplay
        onClose={onPageClose}
        readOnly={true}
        documents={data.CmDocuments}
        onChange={() => {}}
        numberOfFile={50}
        breadcrumb={null}
      />
    )
  }

  const renderDescItem = (title: any, value: any) => {
    return <DescItem title={title} value={value} percentW={20} />
  }

  const DescContent = (
    <DesBox>
      <Row type="flex">
        {renderDescItem('ตึก', data.TowerName)}
        {renderDescItem('ชั้น', data.FloorName)}
        {renderDescItem('ยูนิต', data.Unit)}
        {renderDescItem('หมวดงาน QC', data.QCTypeName)}
        {renderDescItem(
          'ประเภทก่อสร้าง',
          <ContructionTagName name={data.ConstructionTypeName} />
        )}
        {renderDescItem('แผนวันเริ่มงาน', data.BaselineStartDate)}
        {renderDescItem('แผนวันสิ้นสุดงาน', data.BaselineFinishDate)}
        {renderDescItem('แผนวันเริ่มงานปัจจุบัน', data.StartDate)}
        {renderDescItem('แผนวันสิ้นสุดงานปัจจุบัน', data.FinishDate)}
      </Row>
    </DesBox>
  )

  const renderImage = (images: any[]) => {
    return images.map((item, index) => {
      return (
        <Col span={8} onClick={() => onThumbnailOpen(index)}>
          <ImageReport
            image={item}
            canShowThump={false}
            remark={''}
            showRemark={false}
            hasMargin={true}
          />
        </Col>
      )
    })
  }

  const ImageContent = (
    <ContentBox>
      <Title>รูปภาพ</Title>
      <Row type="flex" justify="start">
        {renderImage(data.CmImages || [])}
      </Row>
    </ContentBox>
  )

  const DocumentContent = (
    <DocumentBox>
      <Title>ไฟล์แนบ</Title>
      <Row type="flex" justify="start">
        <Col span={24}>
          <DocumentPicker
            showTitle={false}
            Documents={data.CmDocuments || []}
            onChange={() => {}}
            onDocumentOpen={onDocumentOpen}
            disabled={true}
            showMax={8}
            numberOfFile={50}
            viewMode={true}
          />
        </Col>
      </Row>
    </DocumentBox>
  )

  const CheckListContent = (
    <ContentBox>
      <Title>รายการตรวจสอบ</Title>
      <MainConChecklist
        checkListData={checkList}
        hideStatus={false}
        showHeader={false}
      />
    </ContentBox>
  )

  return (
    <Content className="mid-content">
      <div className="main-content activity-detail">
        <EventModal
          name="defect"
          event={[event]}
          enable={true}
          successCalback={() => {}}
        />
        <ReportHeader
          title={data.ActivityName}
          componentPrint={componentPrint}
        />
        <Box ref={componentPrint}>
          <ReportLogo title={data.ActivityName} />
          {DescContent}
          {ImageContent}
          {DocumentContent}
          {CheckListContent}
        </Box>
      </div>
    </Content>
  )
}

const Box = styled.div`
  background-color: rgba(0, 49, 104, 0.05);
  border-radius: 8px;
  border: solid 1px #e4e7eb;
  color: #000;
`

const DesBox = styled.div`
  padding: 12px 24px;
  background-color: #fff;
`

const ContentBox = styled.div`
  padding: 24px;
  font-family: 'DB Helvethaica X 55 Regular';
`

const Title = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 24px;
  color: #000;
  margin-bottom: 12px;
`

const DocumentBox = styled.div`
  ffont-family: 'DB Helvethaica X 55 Regular';
  font-size: 24px;
  padding: 24px;
  background-color: #fff;
  color: #000;
`

const mapStateToProps = (state: RootState) => {
  return {
    data: selectOriginalActivity(state),
    checkList: selectCheckList(state),
    UserEmail: userEmailSelector(state),
    Token: serviceTokenSelector(state),
    event: selectEvent(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(ReportDetail)
