import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, connect } from 'react-redux'
import RootState from 'typings/RootState'
import styled from 'styled-components'
import { Row, Col } from 'antd'
import { RouteComponentProps } from 'react-router-dom'
import { BreadcrumbLink } from 'Components/Shared/BreadCrumb/BreadCrumb'
import SubnavBar from 'Components/Shared/SubnavBar'
import LoadingView from 'Components/Shared/LoadingView'

import { changeSelectedProjectAction } from 'features/selectedProject/selectedProject.actions'
import { fetchProjects } from 'features/project/project.api'
import {
  serviceTokenSelector,
  userEmailSelector,
} from 'features/sensitive/sensitive.selector'
import {
  selectedProjectCodeSelector,
  projectsByIdSelector,
  isSubmittingSelector,
} from 'features/project/project.selector'

import {
  getDataList,
  getMainconList,
  updateData,
} from 'features/adminqc/unit/api'
import {
  GetDataListReq,
  GetMainConListReq,
  UpdateDataReq,
  DataItem,
} from 'features/adminqc/unit/types'
import {
  selectDataList,
  selectEvent,
  selectMainconList,
} from 'features/adminqc/unit/selector'
import Menu from '../../MenuAdmin'
import { AdminProject, EventModal } from 'Components/Shared'
import { ProjectListItem } from 'features/project/project.types'
import UnitData from './UnitData'
import UnitSettingData from './UnitSettingData'

const breadcrumbs: BreadcrumbLink[] = [
  {
    url: '/dashboard',
    title: 'Home',
    icon: '/home.png',
    active: true,
    showTitle: false,
  },
  {
    url: '/floorplan',
    title: 'floorplan',
    active: false,
    showTitle: true,
  },
]

type Props = ReduxProps & DispatchProps & RouteComponentProps

const UnitPage: React.ComponentType<Props> = ({
  token,
  UserEmail,
  event,
  ProjectCode,
  projectsById,
  isProjectLoading,
  unitList,
  mainconList,
}) => {
  const [enableModal, setEnableModal] = useState(false)
  const [isUpdate, setIsUpdate] = useState(false)
  const [projectMenu, setProjectMenu] = useState<any[]>([])
  const [selctedUnit, setSelctedUnit] = useState({} as DataItem | undefined)

  const dispatch = useDispatch()

  const onProjectCode = (code: string) => {
    dispatch(changeSelectedProjectAction(code))
  }

  useEffect(() => {
    dispatch(fetchProjects({ email: UserEmail, token }))
  }, [UserEmail, token])

  useEffect(() => {
    onGetUnitList()
    setSelctedUnit(undefined)
  }, [ProjectCode])

  useEffect(() => {
    if (selctedUnit && selctedUnit.Unit) {
      onGetMainconList()
    }
  }, [selctedUnit && selctedUnit.Unit])

  useEffect(() => {
    const projects: ProjectListItem[] = Object.values(projectsById)
    const menu = projects.map(item => {
      return {
        name: `${item.ProjectCode} - ${item.ProjectNameTH}`,
        value: item.ProjectCode,
      }
    })

    setProjectMenu(menu)
  }, [projectsById])

  const onGetUnitList = () => {
    const req: GetDataListReq = {
      UserEmail,
      Zone: 'Resident Area',
      ProjectCode,
    }
    dispatch(getDataList(token, req))
  }

  const onGetMainconList = () => {
    if (!selctedUnit) return
    const req: GetMainConListReq = {
      UserEmail,
      Zone: 'Resident Area',
      ProjectCode,
      Floor: selctedUnit.Floor,
      Unit: selctedUnit.Unit,
    }
    dispatch(getMainconList(token, req))
  }

  const onUpdate = (unit: DataItem) => {
    setEnableModal(true)
    setIsUpdate(true)
    const req: UpdateDataReq = {
      UserEmail,
      ProjectCode,
      Floor: unit.Floor,
      QCTypeList: unit.QCTypeList,
      Unit: unit.Unit,
      Zone: unit.Zone,
    }
    dispatch(updateData(token, req))
  }

  const onSuccess = () => {
    setEnableModal(false)
    if (isUpdate) {
      setIsUpdate(false)
      onGetUnitList()
    }
  }

  const onSelectUnit = (unit: DataItem) => {
    setSelctedUnit(unit)
  }

  const header = (
    <Row type="flex" justify="space-between" align="middle">
      <Header>ยูนิต</Header>
      <MainSection>
        <div style={{ width: 360 }}>
          <AdminProject
            projectList={projectsById}
            onSelectProject={onProjectCode}
            activeProject={ProjectCode}
          />
        </div>
      </MainSection>
    </Row>
  )

  return (
    <>
      <EventModal
        name="user"
        event={[event]}
        enable={enableModal}
        successCalback={onSuccess}
      />
      <LoadingView isShow={isProjectLoading || event.IsLoading} />
      <SubnavBar breadcrumbs={breadcrumbs} hideSearch={true} />
      <Menu selected={'unit'}>
        <MainPage>
          {header}
          <Row type={'flex'} style={{ height: '90%' }}>
            <Col span={12} style={{ height: '100%' }}>
              <UnitBox>
                <UnitData
                  unitList={unitList.List}
                  total={unitList.Total}
                  selctedUnit={selctedUnit}
                  onClick={onSelectUnit}
                />
              </UnitBox>
            </Col>
            <Col span={12} style={{ height: '100%' }}>
              <UnitSettingBox>
                <UnitSettingData
                  unit={selctedUnit}
                  mainCon={mainconList}
                  onSave={onUpdate}
                />
              </UnitSettingBox>
            </Col>
          </Row>
        </MainPage>
      </Menu>
    </>
  )
}

const MainPage = styled.div`
  padding: 40px 45px;
  width: 100%;
  height: calc(100vh - 115px);
`

const UnitBox = styled.div`
  margin-right: 11px;
  height: 100%;
`

const UnitSettingBox = styled.div`
  margin-left: 11px;
  height: 100%;
`

const MainSection = styled.div`
  height: 50px;
  margin-bottom: 35px;
  display: flex;
`

const Header = styled.div`
  margin-bottom: 27px;
  font-size: 28px;
  line-height: 1.07;
  font-family: 'DB Helvethaica X 75 Bd';
  color: #000;
`

const mapStateToProps = (state: RootState) => {
  return {
    unitList: selectDataList(state),
    mainconList: selectMainconList(state),
    event: selectEvent(state),
    token: serviceTokenSelector(state),
    UserEmail: userEmailSelector(state),
    ProjectCode: selectedProjectCodeSelector(state),
    projectsById: projectsByIdSelector(state),
    isProjectLoading: isSubmittingSelector(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>
const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UnitPage)
