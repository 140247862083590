import React, { useState, useEffect } from 'react'
import { Modal, Row, Col, DatePicker } from 'antd'
import styled from 'styled-components'
import { SecondaryButton, PrimaryButton } from 'Components/Shared/Button'
import { DATETIME_PICKER_DATE_FORMAT } from 'config'
import moment from 'moment'

type Props = {
  task: any
  onAcceptReq: (task: any, date: string) => void
  onRejectReq: (task: any) => void
  onClose: () => void
  title?: string
}

const AcceptModal = (props: Props) => {
  const { onClose, task, onAcceptReq, onRejectReq, title = 'รับคำขอ' } = props

  const [date, setDate] = useState(null as any)

  const disabledDate = (current: any) => {
    return current && current < moment().subtract(1, 'day')
  }

  useEffect(() => {
    setDate('')
  }, [task])

  return (
    <>
      <Modal
        className="accept-modal"
        title={false}
        centered
        visible={task.ProjectCode !== undefined}
        footer={false}
        closable={true}
        closeIcon={
          <img
            src="/img/ic-cancel.svg"
            alt="close icon"
            title="close history modal"
          />
        }
        onCancel={onClose}
      >
        <Body>
          <Header>{title}</Header>
          <Project>
            {task.ProjectCode} - {task.ProjectNameTH}
          </Project>
          <Row type="flex" justify="space-between" align="middle">
            <Col span={24}>
              <div className="input-with-icon">
                <div className="label">วันเวลานัดหมาย</div>
                <DatePicker
                  className="date-picker"
                  format={DATETIME_PICKER_DATE_FORMAT}
                  placeholder={DATETIME_PICKER_DATE_FORMAT}
                  onChange={date => setDate(date)}
                  showTime={{
                    format: 'HH:mm',
                  }}
                  disabledDate={disabledDate}
                  value={date}
                  allowClear={true}
                  showToday={false}
                />
              </div>
            </Col>
          </Row>
          <ButtonSection>
            <Row type="flex" justify="start">
              <Col>
                <div className="btn-cancel">
                  <SecondaryButton
                    text="ยกเลิก"
                    disabled={false}
                    onClick={onClose}
                  />
                </div>
              </Col>
              <Col>
                <div className="btn-reject">
                  <PrimaryButton
                    text="Reject"
                    disabled={false}
                    onClick={() => onRejectReq(task)}
                    btnType="Cancel"
                  />
                </div>
              </Col>

              <Col>
                <div className="btn-submit">
                  <PrimaryButton
                    text="รับคำขอ"
                    disabled={date === ''}
                    onClick={() => onAcceptReq(task, date)}
                  />
                </div>
              </Col>
            </Row>
          </ButtonSection>
        </Body>
      </Modal>
    </>
  )
}

const Header = styled.div`
  text-align: left;
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 24px;
  color: #000;
  margin-top: -10px;
  padding: 0 8px;
  text-align: center;
`

const Body = styled.div``

const Project = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  border-radius: 8px;
  font-size: 20px;
  padding: 16px;
  text-align: center;
`

const ButtonSection = styled.div`
  margin-top: 32px;
  text-align: center;

  .btn-cancel {
    width: 90px;
  }

  .btn-submit {
    width: 100px;
    margin-left: 5px;
  }

  .btn-reject {
    width: 100px;
    margin-left: 55px;
  }
`

export default AcceptModal
