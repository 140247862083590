import withAPIAction from 'utils/withAPIAction';
import { APIPath } from 'services/APIPath';
import { searchByKeywordAsyncRequest } from './search.actions';
import { SearchByKeywordRequest } from './search.types';

export const submitSearchRequest = (
  data: SearchByKeywordRequest,
  token: string
) => {
  if (!data.UserEmail || data.ProjectCode == '-MS') {
    return { type: 'search/PAYLOAD_NOT_READY' };
  }
  if (!token) {
    return { type: 'search/MISSING_TOKEN' };
  }

  return withAPIAction<SearchByKeywordRequest>({
    method: 'POST',
    path: `${APIPath.getSearchActivity}`,
    data: {
      UserEmail: data.UserEmail,
      ProjectCode: data.ProjectCode,
      Wording: data.Wording,
      Page: data.Page,
      RowPerPage: data.RowPerPage,
    },
    token: `Bearer ${token}`,
  })(searchByKeywordAsyncRequest);
};
