import React from 'react'
import { translate } from 'i18n'
import TKeys from 'i18n/translationKey'

type NoSearchResultProps = {}

const NoSearchResultInfo: React.FunctionComponent<
  NoSearchResultProps
> = props => {
  return (
    <div
      style={{
        textAlign: 'center',
        marginTop: '144px',
        fontSize: '20px',
        fontFamily: 'DB Helvethaica X 55 Regular',
      }}
    >
      <img
        src="/img/img-nosearch.svg"
        height="144"
        width="144"
        alt="no search results"
        style={{ marginBottom: '32px' }}
      />
      <br />
      {translate(TKeys.SearchResult.search_item_not_found)}
      <br />
      {translate(TKeys.SearchResult.change_search_keyword)}
    </div>
  )
}

export default NoSearchResultInfo
