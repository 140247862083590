import React from 'react'
import { HeaderRow } from 'Components/Shared/Table'
import { TypeOfTask } from 'features/cmpj/zone/seemore/types'
import { RadioBox } from 'Components/Shared/Form'

type Props = {
  type: string
  onSort(name: string): void
  sorting: any
  showRadio?: boolean
  isSelectAll?: boolean
  onSelectAll?: () => void
}

const TableHeader: React.FunctionComponent<Props> = props => {
  const {
    onSort,
    sorting,
    type,
    isSelectAll = false,
    onSelectAll = () => {},
    showRadio = false,
  } = props

  const getRadioBox = () => {
    return (
      <RadioBox
        value={''}
        label={''}
        isCheck={isSelectAll}
        onClick={value => onSelectAll()}
        size={24}
      />
    )
  }

  const todoHeaderItems = [
    {
      title: 'โซน',
      span: 7,
      key: 'Unit',
    },
    {
      title: 'ชั้น',
      span: 5,
      key: 'Floor',
    },
    {
      title: 'จำนวนงาน',
      span: 5,
      key: 'TaskRatio',
    },
    {
      title: 'สถานะ',
      span: 5,
      key: 'Status',
    },
    {
      title: showRadio && getRadioBox(),
      span: 2,
      key: '',
      showSort: false,
    },
  ]

  const inprogressHeaderItems = [
    {
      title: 'โซน',
      span: 4,
      key: 'Unit',
    },
    {
      title: 'ชั้น',
      span: 4,
      key: 'Floor',
    },
    {
      title: 'ตรวจครั้งที่',
      span: 4,
      key: 'CheckNumber',
    },
    {
      title: 'ผ่าน',
      span: 3,
      key: 'NumOfPass',
    },
    {
      title: 'ไม่ผ่าน',
      span: 3,
      key: 'NumOfFail',
    },
    {
      title: 'กรุณาเลือก',
      span: 2,
      key: '',
      showSort: false,
    },
  ]

  const columns =
    type === TypeOfTask.TODO ? todoHeaderItems : inprogressHeaderItems
  return (
    <HeaderRow onSort={key => onSort(key)} sorting={sorting} items={columns} />
  )
}

export default TableHeader
