import React from 'react'
import { connect, useDispatch } from 'react-redux'
import styled from 'styled-components'
import { Row, Col, DatePicker, Button } from 'antd'
import moment from 'moment'

import { PrimaryButton } from 'Components/Shared/Button'

type Props = {
  onClick: () => void
  isLoading?: boolean
}

const AcceptFooter: React.FC<Props> = ({ onClick, isLoading = false }) => {
  return (
    <FooterContent>
      <Row type="flex" justify="end" align="middle">
        <Col>
          <div className="btn-submit">
            <PrimaryButton
              text="รับงาน"
              disabled={isLoading}
              onClick={onClick}
            />
          </div>
        </Col>
      </Row>
    </FooterContent>
  )
}

const FooterContent = styled.div`
  max-width: 1110px;
  margin: 0 auto;
  color: #000;
  font-size: 20px;
  padding-right: 20px;

  .btn-submit {
    width: 191px;
    text-align: right;
    button {
      border-radius: 5px;
      width: 191px;
      height: 48px;
      font-size: 20px;
    }
  }
`

export default AcceptFooter
