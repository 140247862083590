import React, { useState, useEffect, useCallback } from 'react'
import { Slider } from 'antd'
import styled from 'styled-components'
import { DropdownList } from 'Components/Shared/Form'
import { UnitReportItem } from 'features/report/unit/types'

import file from 'utils/file'
import { toPosition, toLocalPosition } from 'utils/pin'
import { PinIcon } from 'Components/Shared/Icon'

import Zoomin from './Zoomin'
import Zoomout from './Zoomout'

type Props = {
  floorList: any[]
  defectList: UnitReportItem[]
  onSelected: (item: any) => void
  onClose?: () => void
  onSave?: (floor: number, pin: number, x: number, y: number) => void
  pinSize?: any
  defect?: any // pin == undefined is create pin
  viewMode?: string
}

const ReportPin: React.FunctionComponent<Props> = props => {
  let pointX = 0,
    pointY = 0,
    lastX = 0,
    lastY = 0
  let mode: any

  const {
    onClose,
    onSave,
    pinSize = { width: 50, height: 60 },
    defect,
    floorList = [],
    defectList = [],
    onSelected,
    viewMode = 'view',
  } = props

  const [myCanvas, setMyCanvas] = useState(undefined as any)
  const [position, setPosition] = useState({ x: 0, y: 0 })
  const [imgSize, setImgSize] = useState(undefined as any)
  const [limitPosition, setLimitPosition] = useState({
    left: 0,
    top: 0,
    bottom: 1000,
    right: 1000,
    width: 25,
    height: 40,
  } as any)

  const [floor, setFloor] = useState(-1)
  const [floorMenu, setFloorMenu] = useState([] as any)
  const [zoom, setZoom] = useState(0 as number)
  const [pin, setPin] = useState({ offsetWidth: 0, offsetHeight: 0 } as any)
  const [pinList, setPinList] = useState([] as any)
  const [pinNumber, setPinNumber] = useState(-1)

  const onRefPin = useCallback(node => {
    if (node) setPin(node)
  }, [])

  useEffect(() => {
    const menuList = floorList.map((item, index) => {
      return { name: `ชั้น ${item.Floor}`, value: index }
    })

    let pinNum = defectList.length + 1
    if (defect) {
      pinNum = defect.PinNumber
      const { XAxis = 0, YAxis = 0 } = defect
      setPosition({ x: toNumber(+XAxis), y: toNumber(+YAxis) })
    }
    let floorNum = -1
    if (menuList.length > 0) {
      if (viewMode === 'edit' && defect) {
        floorNum = floorList.findIndex(item => item.Floor === defect.Floor)
      } else floorNum = 0
    }

    setPinNumber(pinNum)
    setFloorMenu(menuList)
    setFloor(floorNum)
  }, [defect, floorList])

  useEffect(() => {
    drawMyImage(myCanvas, false)
  }, [zoom])

  useEffect(() => {
    drawMyImage(myCanvas, true)
  }, [floor])

  useEffect(() => {
    if (imgSize === undefined) return
    if (floor === -1) return

    const floorNumber = floorList[floor].Floor
    const defectOnFloor = defectList.filter(
      item => item.UnitFloor === floorNumber
    )
    const { current, original } = imgSize
    const ratio = toNumber(current.width / original.width)

    const content: any[] = []
    defectOnFloor.forEach((item, index) => {
      if (item.PinNumber === pinNumber) return

      const color = getColor(item.PinStatus)
      const { XAxis = 0, YAxis = 0 } = item
      const location = toLocalPosition(
        toNumber(+XAxis * ratio),
        toNumber(+YAxis * ratio),
        pinSize.width,
        pinSize.height
      )

      const css = {
        top: `${location.y}px`,
        left: `${location.x}px`,
      }

      const rat = item.PinNumber > 9 ? 3.5 : 2.5
      const pinCss = {
        left: `${pinSize.width / rat}px`,
        fontSize: `${pinSize.width / 2}px`,
      }
      content.push(
        <Pin key={index} style={css} onClick={() => onSelected(item)}>
          <PinIcon
            width={pinSize.width}
            height={pinSize.height}
            color={color}
          />
          <span style={pinCss}>{item.PinNumber}</span>
        </Pin>
      )
    })

    setPinList(content)
  }, [imgSize, zoom, floor])

  const getColor = (status: string) => {
    if (viewMode === 'view') {
      switch (status) {
        case 'ToBeFix':
          return '#d0494c'
        case 'GoodWork':
          return '#79cadc'
        case 'Pass':
          return '#5bc79e'
        default:
          return '#e7a065'
          break
      }
    } else {
      switch (status) {
        case 'ToBeFix':
          return '#E4878A'
        case 'GoodWork':
          return '#B4E9F5'
        case 'Pass':
          return '#A8E3CD'
        default:
          return '#E7B891'
          break
      }
    }
  }

  const onZoomin = () => {
    if (zoom < 100) setZoom(zoom + 1)
  }

  const onZoomout = () => {
    if (zoom > 0) setZoom(zoom - 1)
  }

  const onRefCanvas = useCallback(node => {
    if (node) {
      setMyCanvas(node)
      drawMyImage(node, true)
    }
  }, [])

  const onMoveDown = (evt: any) => {
    if (mode !== undefined) return

    mode = 'moving'
    lastX = evt.clientX
    lastY = evt.clientY

    document.onmouseup = onMoveUp
    document.onmousemove = onMove
  }

  const onMoveUp = (evt: any) => {
    document.onmouseup = null
    document.onmousemove = null
    mode = undefined
  }

  const onMove = (evt: any) => {
    if (mode !== 'moving') return
    evt.preventDefault()

    pointX = lastX - evt.clientX
    pointY = lastY - evt.clientY
    lastX = evt.clientX
    lastY = evt.clientY

    let x = pin.offsetLeft - pointX
    let y = pin.offsetTop - pointY

    if (x < limitPosition.left) x = limitPosition.left
    else if (x > limitPosition.right - pin.offsetWidth) {
      x = limitPosition.right - pin.offsetWidth
    }

    if (y < limitPosition.top) y = limitPosition.top
    else if (y > limitPosition.bottom - pin.offsetHeight) {
      y = limitPosition.bottom - pin.offsetHeight
    }

    let ratio = 1
    if (imgSize) {
      const { current, original } = imgSize
      ratio = toNumber(current.width / original.width)
    }

    const location = toPosition(x, y, pinSize.width, pinSize.height)
    setPosition({ x: location.x / ratio, y: location.y / ratio })
  }

  const toNumber = (val: number) => {
    return Math.floor(val * 1000) / 1000
  }

  const getMaxSize = () => {
    let width = 920
    let height = 590

    if (zoom > 0) {
      const ratio = toNumber(zoom / 40)

      width = width + width * ratio
      height = height + height * ratio
    }

    return { width, height }
  }

  const drawMyImage = async (elem: any, reset: boolean = false) => {
    if (elem && floor !== -1 && floorList.length !== 0) {
      const floorItem = floorList[floor].ImageFloorPlan
      const maxSize = getMaxSize()
      const img = await file.base64ToImage(floorItem.FileURL, true)
      const imgSize = {
        width: img.width,
        height: img.height,
      }
      const newSize = file.reduceByDimension(imgSize, maxSize)
      const { width, height } = newSize
      elem.width = width
      elem.height = height

      const ctx = elem.getContext('2d')
      ctx.clearRect(0, 0, width, height)
      ctx.drawImage(img, 0, 0, width, height)

      const limit = {
        left: 0,
        top: 0,
        bottom: height,
        right: width,
        width: 25,
        height: 25,
      }
      setLimitPosition(limit)
      setImgSize({
        original: { width: img.width, height: img.height },
        current: { width, height },
      })

      if (reset && viewMode === 'add') {
        setPosition({ x: img.width / 2, y: img.height / 2 })
      }
    }
  }

  const onSaveDefect = () => {
    if (onSave) {
      const floorNumber = floor === -1 ? 0 : floorList[floor].Floor
      onSave(floorNumber, pinNumber, position.x, position.y)
    }
  }

  let cssPin
  let cssImg
  let width = 0
  let height = 0
  if (imgSize) {
    const { current, original } = imgSize
    width = current.width
    height = current.height
    cssImg = { width, height }

    if (viewMode !== 'view') {
      const ratio = toNumber(current.width / original.width)
      const location = toLocalPosition(
        toNumber(position.x * ratio),
        toNumber(position.y * ratio),
        pinSize.width,
        pinSize.height
      )
      cssPin = {
        top: `${location.y}px`,
        left: `${location.x}px`,
      }
    }
  }

  const closeBtn = onClose ? (
    <CloseBtn onClick={onClose}>ยกเลิก</CloseBtn>
  ) : (
    undefined
  )

  const saveBtn = onSave ? (
    <SaveBtn onClick={onSaveDefect}>บันทึก</SaveBtn>
  ) : (
    undefined
  )

  let editPin
  if (viewMode !== 'view') {
    const rat = pinNumber > 9 ? 3.5 : 2.5
    const pinCss = {
      left: `${pinSize.width / rat}px`,
      fontSize: `${pinSize.width / 2}px`,
    }

    editPin = (
      <EditPin ref={onRefPin} style={cssPin} onMouseDown={onMoveDown}>
        <PinIcon
          width={pinSize.width}
          height={pinSize.height}
          color={'#003168'}
        />
        <span style={pinCss}>{pinNumber}</span>
      </EditPin>
    )
  }

  return (
    <MainView>
      <ToolMenu>
        {closeBtn}
        <FloorMenu>
          <DropdownList
            label="ผังชั้น"
            value={floor}
            menu={floorMenu}
            onChange={(val: any) => setFloor(val)}
          />
        </FloorMenu>

        {saveBtn}
      </ToolMenu>

      <Plan>
        <PlanImg style={cssImg}>
          <PlanImgCanvas>
            <Canvas ref={onRefCanvas} />

            {pinList}
            {editPin}
          </PlanImgCanvas>
        </PlanImg>
      </Plan>
      <ToolBar>
        <Zoomout onClick={onZoomout} />
        <SliderTool>
          <Slider
            tooltipVisible={false}
            value={zoom}
            onChange={(val: any) => setZoom(val)}
          />
        </SliderTool>
        <Zoomin onClick={onZoomin} />
        <span>{`${zoom}%`}</span>
      </ToolBar>
    </MainView>
  )
}

const MainView = styled.div`
  width: 100%;
  margin-top: 32px;
`

const ToolMenu = styled.div`
  width: 100%;
  margin: 0 auto;
  margin-bottom: 32px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
`

const FloorMenu = styled.div`
  width: 317px;
  margin: 0px 32px;
`

const ToolBar = styled.div`
  width: 1045px;
  height: 48px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  align-items: center;
  justify-content: center;
  display: flex;
  color: white;
  background-color: #8b99a7;
  margin: 0 auto;
  padding-left: 83px;
  span {
    font-family: 'DB Helvethaica X 75 Bd';
    font-size: 24px;
    margin-left: 33px;
    width: 50px;
  }

  svg {
    cursor: pointer;
  }
`

const SliderTool = styled.div`
  width: 185px;
  height: 48px;
  padding-top: 2px;
  margin-left: 25px;
  margin-right: 25px;

  .ant-slider {
    .ant-slider-rail,
    .ant-slider-track,
    .ant-slider-step {
      height: 8px;
      border-radius: 6px;
    }

    .ant-slider-handle {
      width: 24px;
      height: 24px;
      margin-top: -8px;
      background-color: #fefefe;
      border: solid 2px #dddddd;
      z-index: 3;
    }

    .ant-slider-track {
      background-color: #79cadc;
    }
  }

  .ant-slider:hover > .ant-slider-track {
    background-color: #79cadc;
  }

  .ant-slider:hover > .ant-slider-handle {
    border-color: #79cadc !important;
  }
`

const Plan = styled.div`
  width: 1045px;
  height: 622px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  border: solid 1px #eeeff2;
  border-bottom-width: 0px;
  background-color: white;
  margin: 0 auto;
  display: block;
  align-items: center;
  justify-content: center;
  overflow: scroll;
`

const PlanImg = styled.div`
  display: flex;
  margin: 0 auto;
`

const PlanImgCanvas = styled.div`
  position: relative;
  padding: 0px;
  width: 960px;
  height: 590px;
`

export const Canvas = styled.canvas``

const Pin: any = styled.span`
  position: absolute;
  width: 50px;
  height: 60px;

  span {
    font-family: 'DB Helvethaica X 55 Regular';
    color: white;
    position: absolute;
    top: 10%;
  }
`

const EditPin = styled(Pin)`
  cursor: move;
`
const Btn: any = styled.button`
  color: white;
  width: 80px;
  padding: 4px 0;
  border-radius: 20px;
  border: none;
  cursor: pointer;
  font-family: 'DB Helvethaica X 55 Regular';
  font-size: 18px;
  &:focus {
    outline: 0;
  }
`
const CloseBtn = styled(Btn)`
  background-color: #d0494c;
`

const SaveBtn = styled(Btn)`
  background-color: #003168;
`

export default ReportPin
