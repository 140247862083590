import React from 'react'
import { Col } from 'antd'
import styled from 'styled-components'
import { TableRow } from 'Components/Shared/Table'
import { ReportLink as Link } from 'Components/AdminQC/Report/Components'
import { DataItem } from 'features/adminReport/auditRoad/types'
import { getRoadLocation } from 'utils/getText'
import { toDateText, toMomentDateTime } from 'utils/datetime'

type DefaultProps = {
  item: DataItem
}

type Props = DefaultProps

const RowItem: React.FC<Props> = ({ item }) => {
  const { ProjectCode, CheckNumber, RequestID } = item
  const path = `/report/auditRoad/${ProjectCode}/${CheckNumber}/${RequestID}`

  const renderData = (val: any, span: number = 4) => {
    return <Col span={span}>{val}</Col>
  }

  return (
    <RowModel>
      <Link to={path}>
        <TableRow minHeight={60} spaceBetweenRow={8} paddingLeft={24}>
          {renderData(
            <Bold>
              {item.ProjectCode}-{item.ProjectNameTH}
            </Bold>
          )}
          {renderData(<TxtReg>{getRoadLocation(item)}</TxtReg>)}
          {renderData(<TxtReg>{item.CheckNumber}</TxtReg>, 2)}
          {renderData(
            <TxtReg>{toDateText(toMomentDateTime(item.InspectDate))}</TxtReg>,
            2
          )}
          {renderData(<PassScore>{item.NumOfGoodWork}</PassScore>, 2)}
          {renderData(<TxtReg>{item.NumOfPass}</TxtReg>, 2)}
          {renderData(<TxtReg>{item.NumOfFixed}</TxtReg>, 2)}
          {renderData(<FailScore>{item.NumOfToBeFix}</FailScore>, 2)}
          {renderData(<TxtReg>{item.CheckedBy}</TxtReg>)}
        </TableRow>
      </Link>
    </RowModel>
  )
}

const RowModel = styled.div`
  cursor: pointer;
`

const Name = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  color: #000;
  display: flex;
  align-items: center;
  .img {
    height: 40px;
    width: 40px;
    margin-right: 22px;
  }
`

const Bold: any = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  color: #000;
  font-size: 20px;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  padding-right: 5px;
`

const TxtReg = styled(Bold)`
  font-family: 'DB Helvethaica X 55 Regular';
`

const PassScore = styled(Bold)`
  color: #5bc79e;
`

const FailScore = styled(Bold)`
  color: #d0494c;
`

export default RowItem
