import React from 'react'
import { Modal } from 'antd'

type Props = {
  isShow?: boolean
  onCloseModal : () => void
  content : any
}

const HistoryModal: React.FC<Props> = ({
  onCloseModal,
  isShow = false,
  content
}) => {

  return (
    <Modal
      title=""
      footer=""
      visible={isShow}
      onOk={onCloseModal}
      onCancel={onCloseModal}
      centered={false}
      closable={true}
      width={'1024px'}
      wrapClassName="activity-history-modal-wrapper"
      closeIcon={
        <img
          src="/img/ic-cancel.svg"
          alt="close icon"
          title="close history modal"
        />
      }
    >
      {content}
    </Modal>
  )
}

export default HistoryModal
