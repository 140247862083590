import { put, takeEvery, select, call, all } from 'redux-saga/effects'
import { APIPath } from 'services/APIPath'
import { getType } from 'typesafe-actions'
import * as actions from './actions'
import { GetZoneList } from './api'

import APIManager, { configWithToken } from 'services/APIManager'
import {
  serviceTokenSelector,
  userEmailSelector,
} from 'features/sensitive/sensitive.selector'

function* sendDeleteRequest(
  action: ReturnType<typeof actions.deleteReq.request>
) {
  try {
    const UserEmail = yield select(userEmailSelector)
    const Token = yield select(serviceTokenSelector)
    const { CheckListID, page } = action.payload

    yield call(
      APIManager.call,
      APIPath.DeleteZoneCheckList,
      'POST',
      {
        UserEmail,
        CheckListID,
      },
      configWithToken(`Bearer ${Token}`)
    )
    yield put(actions.deleteReq.success())
    yield put(GetZoneList(page))
  } catch (error) {
    yield put(actions.deleteReq.failure(error))
  }
}

function* allSaga() {
  yield all([
    yield takeEvery(getType(actions.deleteReq.request), sendDeleteRequest),
  ])
}

export default allSaga
