import React, { useEffect } from 'react'
import styled from 'styled-components'
import { connect, useDispatch } from 'react-redux'
import { Layout, Row, Col } from 'antd'
import { RouteComponentProps, Link } from 'react-router-dom'
import RootState from 'typings/RootState'
import {
  userEmailSelector,
  serviceTokenSelector,
} from 'features/sensitive/sensitive.selector'
import { selectData, selectEvent } from 'features/qc/projects/selector'
import {
  getUnitTodoForQCApi,
  getZoneTodoForQCApi,
  getCommissioningTodoForQC,
} from 'features/qc/projects/api'
import { GetProjectTodoReq, ProjectData } from 'features/qc/projects/types'
import {
  ProjectTypeName,
  ProjectType,
  ProjectTypeID,
  Type,
} from 'features/types/project'
import Breadcrumbs from './Breadcrumbs'
import ProjectItem from './ProjectItem'
import noProject from 'assets/img/img-noproject.png'
import { EventModal } from 'Components/Shared'

const { Content } = Layout

type Props = ReduxProps & DispatchProps & RouteComponentProps<{ type: string }>

const ProjectList: React.ComponentType<Props> = props => {
  const { data, token, UserEmail, event, match } = props
  const { type = 'unit' } = match.params

  const dispatch = useDispatch()
  useEffect(() => {
    const req: GetProjectTodoReq = {
      UserEmail: UserEmail,
      //ProjectCode: [],
    }

    if (type === 'unit') {
      dispatch(getUnitTodoForQCApi(token, req))
    }
    if (type === 'zone') {
      dispatch(getZoneTodoForQCApi(token, req))
    }

    if (type === 'commissioning') {
      dispatch(getCommissioningTodoForQC(token, req))
    }
  }, [dispatch])

  const filterProjectType = (type: ProjectType) => {
    if (type === ProjectType.VERTICAL) {
      return (
        data && data.filter(project => project.ProjectType === Type.VERTICAL)
      )
    } else {
      return (
        data && data.filter(project => project.ProjectType === Type.HORIZONTAL)
      )
    }
  }

  const renderProject = (projects: ProjectData[]) => {
    return projects.map(project => {
      return (
        <Link to={`/${type}/todo/${project.ProjectCode}`}>
          <ProjectItem project={project} type={type} />
        </Link>
      )
    })
  }

  const emptyProject = (
    <EmptyBox>
      <NoProject>
        <img src={noProject} />
        <p>ไม่มีข้อมูล</p>
      </NoProject>
    </EmptyBox>
  )

  const countRequest = (projects: ProjectData[]) => {
    let total = 0
    projects &&
      projects.forEach(item => {
        if (item.QCList) {
          item.QCList.forEach(task => {
            total += task.NumberOfTask
          })
        }
      })
    return total
  }

  const renderAllProject = (type: ProjectType) => {
    const projects = filterProjectType(type)
    return (
      <ContentBox>
        <TitleSecond>{`${type} (${countRequest(projects)})`} </TitleSecond>
        {projects && projects.length > 0
          ? renderProject(projects)
          : emptyProject}
      </ContentBox>
    )
  }

  return (
    <>
      <Breadcrumbs type={type} />
      <EventModal event={[event]} err={[]} />
      <Content className="mid-content">
        <div className="main-content activity-detail">
          <Header>
            <Title>โครงการขอตรวจ</Title>
          </Header>
          <Row>
            <Col span={12}>{renderAllProject(ProjectType.HORIZONTAL)}</Col>
            <Col span={12}>{renderAllProject(ProjectType.VERTICAL)}</Col>
          </Row>
        </div>
      </Content>
    </>
  )
}

const Header = styled.div`
  margin-top: 32px;
  margin-bottom: 24px;
`

const Title = styled.span`
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 28px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #000;
`

const ContentBox = styled.div`
  height: calc(100vh - 245px);
  overflow: scroll;
  padding-right: 30px;
`

const TitleSecond = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 24px;
  color: #000;
`

const EmptyBox = styled.div`
  width: 100%;
  height: 80%;
  align-items: center;
  display: grid;
  text-align: center;
`

const NoProject = styled.div`
  img {
    width: 108px;
  }
  p {
    font-size: 20px;
    font-family: 'DB Helvethaica X 55 Regular';
  }
`

const mapStateToProps = (state: RootState) => {
  return {
    data: selectData(state),
    event: selectEvent(state),
    token: serviceTokenSelector(state),
    UserEmail: userEmailSelector(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(ProjectList)
