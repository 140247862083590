import React from 'react'
import { Row, Col } from 'antd'
import styled from 'styled-components'
import imgAdd from 'assets/img/ic-add.png'

interface Props {
  text: string
  readOnly?: boolean
  level?: 'first' | 'second' | 'third'
  onClick: (level: 'first' | 'second' | 'third') => void
}

const AddCheckList: React.FC<Props> = ({
  text,
  readOnly = false,
  level = 'first',
  onClick,
}) => {
  const onAdd = () => onClick(level)

  if (readOnly) return <></>

  return (
    <CheckList className={`${level}`} onClick={onAdd}>
      <Row type="flex" justify="space-between">
        <Col className="text">
          <img src={imgAdd} />
          <span>{text}</span>
        </Col>
      </Row>
    </CheckList>
  )
}

const CheckList = styled.div`
  margin-top: 8px;
  height: 56px;
  border-radius: 5px;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.03);
  background-color: #ffffff;
  font-size: 20px;
  padding: 12px 25px;

  &.first {
    font-family: 'DB Helvethaica X 75 Bd';
  }
  &.second {
    margin-left: 24px;
  }
  &.third {
    margin-left: 48px;
  }

  font-family: 'DB Helvethaica X 75 Bd';
  color: #003168;
  cursor: pointer;
  border: 1px solid #eeeff2;
  img {
    width: 16px;
    margin-right: 13px;
    margin-top: -5px;
  }

  .text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

export default AddCheckList
