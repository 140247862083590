import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Collapse } from 'antd'
import { translate } from 'i18n'
import { RootState } from 'typings/RootState'
import TKeys from 'i18n/translationKey'
import CheckAsOptions from './CheckAsOptions'

import { ActivityFilter } from 'features/activity/activity.types'
import { MainconFilter } from 'features/maincon/dashboard/types'

import { getWbsListSelector } from 'features/wbs/wbs.selector'

const { Panel } = Collapse

type Props = {
  filter: ActivityFilter | MainconFilter
  onChange(filter: ActivityFilter | MainconFilter): void
} & ReduxProps &
  DispatchProps

const PreCons: React.FunctionComponent<Props> = ({
  filter,
  onChange,
  wbsList,
}) => {
  const [display, setDisplay] = useState({} as any)

  const onDisplayChange = (key: any, status: boolean) => {
    if (status) {
      display[key] = true
    } else {
      delete display[key]
    }

    setDisplay({ ...display })
  }

  const removeKey = (key: any, list: any[]): any[] => {
    const index = list.findIndex(item => item === key)
    if (index >= 0) {
      list.splice(index, 1)
    }

    return list
  }

  const onItemToggle = (item: any, value: boolean) => {
    onDisplayChange(item.WbsID, value)
  }

  const onItemChange = (item: any, value: boolean, parent?: any) => {
    let wbs = filter.wbs

    // checked on menu
    if (value) {
      wbs.push(item.WbsID)
      if (item.Children) {
        for (const it of item.Children) {
          const already: boolean = wbs.includes(it.WbsID)
          if (already === false) wbs.push(it.WbsID)
        }
      }

      if (parent) {
        let checkParent = true
        for (const it of parent.Children) {
          if (wbs.includes(it.WbsID) === false) {
            checkParent = false
            break
          }
        }

        const already: boolean = wbs.includes(parent.WbsID)
        if (checkParent && already === false) {
          wbs.push(parent.WbsID)
        } else if (checkParent === false && already) {
          wbs = removeKey(item.WbsID, wbs)
        }
      }
    } else {
      wbs = removeKey(item.WbsID, wbs)
      if (item.Children) {
        for (const it of item.Children) {
          wbs = removeKey(it.WbsID, wbs)
        }
      }

      if (parent) {
        wbs = removeKey(parent.WbsID, wbs)
      }
    }

    filter.wbs = wbs
    onChange(filter)
  }

  const onClear = () => {
    filter.wbs = []
    onChange(filter)
  }

  const content = wbsList.map((item, index) => {
    const { Children = [] } = item
    const chidren = Children.map((it, i) => {
      return (
        <CheckAsOptions
          key={`${index}-${i}`}
          title={it.WbsIName}
          value={filter.wbs.includes(it.WbsID)}
          parent={item}
          item={it}
          onChange={onItemChange}
          onToggle={onItemToggle}
        />
      )
    })

    const isActive = display[item.WbsID]
    return (
      <Col span={24} key={index}>
        <Collapse
          bordered={false}
          activeKey={isActive ? item.WbsID : undefined}
          expandIcon={panelProps => (
            <CheckAsOptions
              panel={panelProps}
              title={item.WbsIName}
              value={filter.wbs.includes(item.WbsID)}
              isActive={isActive}
              showArrow={true}
              item={item}
              onChange={onItemChange}
              onToggle={onItemToggle}
            />
          )}
          expandIconPosition="right"
        >
          <Panel
            header=""
            key={item.WbsID}
            showArrow={true}
            style={{ border: '0' }}
          >
            {chidren}
          </Panel>
        </Collapse>
      </Col>
    )
  })

  const clearBtn =
    filter.wbs.length > 0 ? (
      <span onClick={onClear} className="filter-clear std-font-regular">
        {translate(TKeys.Common.cancel_all)}
      </span>
    ) : (
      undefined
    )
  return (
    <div className="std-font-regular" style={css.page}>
      <Row>
        <Col span={24}>
          <div className="std-font-bold" style={css.title}>
            {translate(TKeys.Dashboard.category)}
            {clearBtn}
          </div>
        </Col>
      </Row>

      <Row className="pop-over-container-choices">{content}</Row>
    </div>
  )
}

const css: any = {
  page: {
    width: '350px',
    height: 'auto',
    padding: '25px 24px',
  },
  title: {
    textAlign: 'left',
    color: 'black',
    fontSize: '20px',
    marginBottom: '25px',
  },
  cancel: {
    float: 'right',
    color: '#003168',
    fontSize: '18px',
  },
}

const mapStateToProps = (state: RootState) => {
  return {
    wbsList: getWbsListSelector(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(PreCons)
