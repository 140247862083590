import React, { useState } from 'react'
import { withRouter, RouteComponentProps, Link } from 'react-router-dom'
import { Layout, Row, Modal, Dropdown, Menu, Col } from 'antd'
import ProjectSelector from 'Components/Shared/ProjectSelector'
import { translate } from 'i18n'
import TKeys from 'i18n/translationKey'
import RootState from 'typings/RootState'

import { connect, useDispatch } from 'react-redux'
import { getUserProfile } from 'features/sensitive/sensitive.selector'
import { updateUserRule } from 'features/sensitive/sensitive.actions'
import { logoutUser } from 'features/login/login.actions'
import { toText } from 'utils/format'
import styled from 'styled-components'
import iconLogout from 'assets/img/ic-approval-logout.png'
import vendorIcon from 'assets/img/vendor-icon.png'

const { Header } = Layout

type Props = ReduxProps &
  DispatchProps &
  RouteComponentProps & {
    title?: string
    displayProject?: boolean
    secondaryTitle?: string
    contentProject?: any
  }

const TopNav: React.FunctionComponent<Props> = ({
  userProfile,
  logoutUser,
  history,
  title = 'MASTER PLAN',
  displayProject = true,
  secondaryTitle = '',
  contentProject,
}) => {
  const dispatch = useDispatch()
  const [show, setShow] = useState(false)

  const performLogout = () => {
    Modal.confirm({
      onOk() {
        logoutUser()
      },
      width: '327px',
      onCancel() {},
      content: modalContent,
      okText: translate(TKeys.TopNav.logout_modal_ok_label),
      okButtonProps: {
        className: 'btn-rectangle ok-btn',
      },
      cancelText: translate(TKeys.TopNav.logout_modal_cancel_label),
      cancelButtonProps: {
        className: 'btn-rectangle cancle-btn',
      },
      centered: true,
      icon: null,
      className: 'login-modal-window',
    })
  }

  const onChangeModule = () => {
    dispatch(updateUserRule({ Name: '', Id: 0 }))
  }

  const modalContent = (
    <ModalContent>
      <div>
        <img src={iconLogout} />
        <div className="message">
          <span>{translate(TKeys.TopNav.logout_modal_description)}</span>
        </div>
      </div>
    </ModalContent>
  )

  const positionName =
    userProfile.Modules && userProfile.Modules.length > 0
      ? userProfile.Modules[0]
      : ''
  const menu = (
    <Menu className="logout-menu">
      <Menu.Item className="underline" style={{ padding: '16px' }}>
        <Row>
          <Col span={4}>
            <MenuUserImgContent src={userProfile.ImgUrl || vendorIcon} />
          </Col>
          <Col span={20}>
            <MenuUserContent>
              <p>
                {toText(`${userProfile.FirstName} ${userProfile.LastName}`, 40)}
              </p>
              <span>{toText(positionName, 40)}</span>
            </MenuUserContent>
          </Col>
        </Row>
      </Menu.Item>

      <Menu.Item
        className="underline"
        style={{ padding: '12px' }}
        onClick={onChangeModule}
      >
        <MenuTextContent>
          เปลี่ยน Module<span>{userProfile.RoleName}</span>
        </MenuTextContent>
      </Menu.Item>

      <Menu.Item onClick={performLogout}>
        <MenuItemContent>
          <span>{translate(TKeys.Common.logout)}</span>
        </MenuItemContent>
      </Menu.Item>
    </Menu>
  )

  const name = toText(`${userProfile.FirstName} ${userProfile.LastName}`, 17)
  const roleName = toText(userProfile.RoleName, 17)

  let css: any
  css = {
    backgroundImage: `url('${userProfile.ImgUrl || vendorIcon}')`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50% 50%',
  }

  const project = displayProject ? (
    contentProject ? (
      contentProject
    ) : (
      <ProjectSelector />
    )
  ) : (
    undefined
  )

  return (
    <Row className="top-nav-wrapper">
      <Header className="top-nav">
        <div className="nav-wrapper">
          <Dropdown overlay={menu} trigger={['click']}>
            <div className="nav-right">
              <div className="profile-section">
                <div className="profile-img" style={css} />
                <div className="profile-info">
                  <span className="name">{name}</span>
                  <span>{roleName}</span>
                </div>
                <img className="icon" src="/ic-down-white.svg" alt="" />
              </div>
            </div>
          </Dropdown>
          <div className="nav-left">
            <div className="intro">
              <Link to="/">
                <img alt="Brand logo" src="/img/img-logo.svg" />
                <RoleName>{title}</RoleName>
              </Link>
            </div>
            {project}
            {secondaryTitle && <Secondary>{secondaryTitle}</Secondary>}
          </div>
        </div>
      </Header>
    </Row>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    userProfile: getUserProfile(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {
  logoutUser,
}

type DispatchProps = typeof mapDispatchToProps

const ModalContent = styled.div`
  text-align: center;
  border-radius: 10px;
  .message {
    padding-top: 18px;
    span {
      font-size: 20px;
      color: #000;
    }
  }
  img {
    width: 60px;
  }
`

const MenuItemContent = styled.div`
  width: 100%;
  padding: 10px 0 10px 10px;
  span {
    font-family: 'DB Helvethaica X 55 Regular';
    font-size: 20px;
    cursor: pointer;
    color: #000;
  }
`

const MenuUserImgContent = styled.img`
  width: 44px;
  height: 44px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 8px;
`

const MenuUserContent = styled.div`
  font-family: 'DB Helvethaica X 55 Regular';
  padding-left: 20px;
  p {
    color: black;
    font-size: 20px;
    margin: 0px;
    padding: 0px;
    white-space: pre-line;
  }

  span {
    font-size: 18px;
    color: #8b99a7;
  }
`

const MenuTextContent = styled.div`
  font-family: 'DB Helvethaica X 55 Regular';
  color: black;
  font-size: 20px;

  span {
    float: right;
    color: #8b99a7;
  }
`

const RoleName = styled.span`
  min-width: 150px;
`

const Secondary = styled.span`
  font-size: 24px;
  margin-left: 18px;
`

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TopNav))
