import React from 'react'
import RootState from 'typings/RootState'
import { connect } from 'react-redux'
import { getUserProfile } from '../../features/sensitive/sensitive.selector'

import AdminMenu from './AdminMenu'
import AdminQCMenu from './AdminQCMenu'
import AdminPJMenu from './AdminPJMenu'

type Props = ReduxProps &
  DispatchProps & {
    selected?: string
    children?: any
  }

const Menu = (props: Props) => {
  const { userProfile, selected, children } = props
  const roleName = userProfile.RoleName

  switch (roleName) {
    case 'Report':
    case 'Admin QC':
      return <AdminQCMenu selected={selected} children={children} />
    case 'Admin PJ':
      return <AdminPJMenu selected={selected} children={children} />
    default:
      return <AdminMenu selected={selected} children={children} />
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    userProfile: getUserProfile(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>
const mapDispatchToProps = {}
type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(Menu)
