export interface GetApiResponse<T> {
  ResponseData: T
  ResponseCode: string
  ResponseMsg: 'Success' | 'Error'
  RequestTime: string
  Responsetime: string
}

export interface PostResponse {
  ResponseCode: string
  ResponseMsg: 'Success' | 'Error'
  RequestTime: string
  Responsetime: string
}

export interface PostResponse {
  ResponseCode: string
  ResponseMsg: 'Success' | 'Error'
  RequestTime: string
  Responsetime: string
}

export interface List<T> {
  Total: number
  List: T[]
}

export interface RowsList<T> {
  TotalRows: number
  List: T[]
}

export interface PaginationType {
  Page: number
  RowPerPage: number
}

export type File = {
  FileID: number
  ActualFileName: string
  FileName: string
  FileSize: number
  FileURL: string
}

export enum SiriconModuleEnum {
  CMPJ = 'CM/PJ',
  Maincon = 'Main Con',
  QC = 'QC',
  MasterPlan = 'Master Plan',
}
