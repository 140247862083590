import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { RootState } from 'typings/RootState'

import FilterButtons from 'Components/Shared/Filters/FilterButtons'

import {
  setFilterDashboard,
  filterDashboard,
} from 'features/tasklane/tasklane.actions'
import { getDashboardFilterSelector } from 'features/tasklane/tasklane.selector'

import { DashboardFilter } from 'features/tasklane/tasklane.types'

type Props = {} & ReduxProps & DispatchProps

const FilterPage: React.FunctionComponent<Props> = ({ filter }) => {
  const dispatch = useDispatch()
  const onFilterChange = (filter: DashboardFilter) => {
    dispatch(setFilterDashboard(filter))
  }

  const onFind = () => {
    dispatch(filterDashboard())
  }

  return (
    <FilterButtons filter={filter} onChange={onFilterChange} onFind={onFind} />
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    filter: getDashboardFilterSelector(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterPage)
