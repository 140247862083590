import React, { useState, useRef, useEffect } from 'react'
import memoize from 'memoize-one'
import styled from 'styled-components'

import ContentBox from 'Components/Shared/Card/ContentBox'
import {
  CheckListDetail,
  ProjectListItem as ProjectListItemUnit,
} from 'features/checklist/unit/types'
import { ProjectListItem } from 'features/project/project.types'
import { AddList, EditDeleteButton } from 'Components/Shared/Button'
import ProjectModal, { ProjectModalHandles } from './Modal/ProjectModal'
import { Row } from 'antd'

type Props = {
  projects: ProjectListItem[]
  selectProjectCode: (ProjectCode: string) => void
  showDeleteProject: (it: ProjectListItemUnit) => void
  checkListDetail: CheckListDetail
}

const filterProject = memoize((list, filterText) => {
  return list.filter((item: ProjectListItemUnit) => {
    if (filterText === '') return true
    const filterLower = filterText.toLocaleLowerCase()
    return (
      item.ProjectCode.toLocaleLowerCase().includes(filterLower) ||
      item.ProjectName.toLocaleLowerCase().includes(filterLower)
    )
  })
})

const ProjectsBox: React.ComponentType<Props> = ({
  projects,
  selectProjectCode,
  checkListDetail,
  showDeleteProject,
}) => {
  const modalRef = useRef<ProjectModalHandles>(null)

  const [projectCode, setprojectCode] = useState<string>('')
  const [filterText, setfilterText] = useState<string>('')
  const [selectProjets, setSelectProjets] = useState<ProjectListItemUnit[]>([])

  useEffect(() => {
    setSelectProjets(checkListDetail.ProjectList)
  }, [checkListDetail.ProjectList])

  const filterSelectProject: ProjectListItemUnit[] = filterProject(
    selectProjets,
    filterText
  )

  const showSelectProjectModal = () => {
    if (modalRef.current) {
      modalRef.current.showModal()
    }
  }

  const selectProjectModal = (item: ProjectListItem) => {
    const foundProject = checkListDetail.ProjectList.find(
      qc => item.ProjectCode === qc.ProjectCode
    )
    if (!foundProject) {
      const newItem = {
        ProjectCode: item.ProjectCode,
        ProjectName: item.ProjectNameTH,
        Type: 'added',
      }
      setSelectProjets(selectProjets.concat(newItem))
      checkListDetail.ProjectList.push(newItem)

      onChange(item.ProjectCode)
    }
  }

  function onChange(code: string) {
    setprojectCode(code)
    selectProjectCode(code)
  }

  function onSearch(text: string) {
    setfilterText(text)
  }

  const showDeleteModal = (it: ProjectListItemUnit) => {
    showDeleteProject(it)
  }

  return (
    <ContentBox
      title={`โครงการที่ใช้งาน (${selectProjets.length})`}
      emptyText="กรุณาเลือก “ประเภทโครงการ”"
      onSearch={onSearch}
    >
      <ListSection>
        {filterSelectProject.map((item: ProjectListItemUnit) => {
          const removeBtn = item.Type ? (
            <EditDeleteButton showDeleteModal={() => showDeleteModal(item)} />
          ) : (
            undefined
          )
          return (
            <List
              key={item.ProjectCode}
              className={item.ProjectCode === projectCode ? 'selected' : ''}
            >
              <ListText>
                <Row type="flex" justify="space-between" className="row-item">
                  <div
                    className="text-projectcode"
                    onClick={() => onChange(item.ProjectCode)}
                  >
                    <span>{`${item.ProjectCode} - ${item.ProjectName}`}</span>
                  </div>
                  {removeBtn}
                </Row>
              </ListText>
            </List>
          )
        })}
        <AddList onClick={showSelectProjectModal} />
      </ListSection>

      <ProjectModal
        ref={modalRef}
        projects={projects}
        onOk={selectProjectModal}
      />
    </ContentBox>
  )
}

const ListSection = styled.div`
  margin-top: 16px;
  height: 256px;
  overflow: auto;
  padding: 0 8px;
`

const List = styled.div`
  font-size: 20px;
  width: 100%;
  &.selected {
    border-radius: 5px;
    border: solid 1px #003168;
  }
  cursor: pointer;
`

const ListText = styled.div`
  border-bottom: 1px solid #e8ebf0;
  height: 100%;
  margin: 0 16px;
  position: relative;

  .text-projectcode {
    padding: 13px 0;
    width: 100%;
  }
  .icon-action {
    position: absolute;
    right: 0px;
    top: 5px;
    padding: 10px 0;
  }
`

export default ProjectsBox
