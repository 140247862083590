import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'

import { Layout } from 'antd'
import TopNav from 'Components/Shared/TopNav'

import ProjectSelector from 'Components/Approval/ProjectSelector'
import ApprovalDashboard from 'Components/Approval/Dashboard'
import ApprovalList from 'Components/Approval/List'
import ActivityDetail from 'Components/Approval/ActivityDetail'
import MsApprovalList from 'Components/Approval/MasterPlan/List'
import MsApprovalDetail from 'Components/Approval/MasterPlan/ActivityDetail'

type Props = {}

const ApprovalApp: React.FunctionComponent<Props> = props => {
  return (
    <BrowserRouter>
      <Layout className="layout" hasSider={false}>
        <TopNav title="APPROVAL" contentProject={<ProjectSelector />} />
        <Route exact path="/" component={ApprovalDashboard} />

        <Switch>
          <Route exact path="/list" component={ApprovalList} />
          <Route exact path="/activity/:code" component={ActivityDetail} />

          <Route exact path="/ms/list" component={MsApprovalList} />
          <Route exact path="/ms/activity/:code" component={MsApprovalDetail} />
        </Switch>
      </Layout>
    </BrowserRouter>
  )
}

export default ApprovalApp
