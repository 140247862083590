import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import {
  ConstructRoadList,
  ConstructRoadInProActivity,
  RoadProjectList,
} from 'features/qc/dashboard/types'
import { Row, Col } from 'antd'
import ProjectBox from '../../ProjectBox'
import LeftBoxContent from '../../LeftBoxContent'
import CheckIconBox from '../../CheckIconBox'
import SignDescription from '../../SignDescription'
import { RoadIcon } from 'Components/Shared/Icon'
import { numberFormat } from 'utils/format'
import { toRoute } from 'utils/helper'

interface Props {
  type?: string
  task: ConstructRoadInProActivity
  isSendReport?: boolean
  onClickReturn: (data: ConstructRoadList) => void
  onSelectLists: (projectCode: string, data: ConstructRoadList) => void
  projectList: RoadProjectList[]
}

const InprogressCard: React.FC<Props> = props => {
  const {
    task,
    isSendReport = false,
    onClickReturn,
    projectList,
    onSelectLists,
  } = props
  const { ProjectCode, ProjectNameTH, UnitList } = task

  const isSelected = (data: ConstructRoadList) => {
    let isSelect = false
    projectList.forEach(project => {
      if (project && project.ProjectCode === ProjectCode) {
        project.TaskList.forEach((task: any) => {
          if (
            task.RequestID === data.RequestID &&
            task.CheckNumber === data.CheckNumber
          ) {
            isSelect = true
          }
        })
      }
    })
    return isSelect
  }

  const scoreContent = (data: ConstructRoadList) => {
    const score = numberFormat(data.InspectionScore)
    return (
      <Row type="flex" justify="start" align="middle">
        <Col>
          <RoadIcon size={40} />
        </Col>
        <Col style={{ marginLeft: '10px' }}>
          <BoldText>{data.Unit}</BoldText>
          {data.CheckStatus === 'Pass' ? (
            <PassScore>{score} คะแนน</PassScore>
          ) : (
            <FailScore>{score} คะแนน</FailScore>
          )}
        </Col>
      </Row>
    )
  }

  const renderInspectionCheck = (data: ConstructRoadList) => {
    const isHasSign = data.HasSign
    const isHasImage = data.HasSelectPin
    const showRadio = isHasSign && isHasImage
    return (
      <Row type="flex" className="score" align="middle">
        <Col>
          {isSendReport &&
            (showRadio ? (
              <CheckIconBox
                onClick={() => onSelectLists(task.ProjectCode, data)}
                isSelected={isSelected(data)}
              />
            ) : (
              <Space />
            ))}
        </Col>
        <Col span={22}>
          <BoxScore>
            <Row type="flex" justify="space-between">
              <Col span={14}>{scoreContent(data)}</Col>
              <Col span={8}>
                <SignDescription
                  isCorrect={isHasSign}
                  label={'ลงชื่อรับทราบ'}
                />
                <SignDescription
                  isCorrect={isHasImage}
                  label={'รูปส่งรายงาน'}
                />
              </Col>
            </Row>
          </BoxScore>
        </Col>
      </Row>
    )
  }

  const renderDataList = () => {
    return UnitList.map((data: ConstructRoadList, index: number) => {
      const className = index % 2 === 1 ? 'higlight' : ''
      const params = `${ProjectCode}/${toRoute(data.Unit)}/${data.QCTypeID}/${
        data.RequestID
      }/${data.CheckNumber}`
      const link = `/construct-road/report/${params}`
      const commissDesc = <RightBox>{renderInspectionCheck(data)}</RightBox>
      return (
        <ContentBox key={index} className={className}>
          <Row type="flex">
            <Col span={6}>
              <LeftBoxContent
                onClickReturn={() => onClickReturn(data)}
                titleText={
                  <Link to={link}>
                    <p>
                      {data.QCTypeName} ({data.CheckNumber})
                    </p>
                  </Link>
                }
                returnDesc="หากคืนผัง งานที่ตรวจไว้ก่อนหน้าจะถูก Reset ทั้งหมด คุณยืนยันที่จะคืนผังใช่หรือไม่"
              />
            </Col>
            <Col span={18}>
              {isSendReport ? (
                commissDesc
              ) : (
                <Link to={link}>{commissDesc}</Link>
              )}
            </Col>
          </Row>
        </ContentBox>
      )
    })
  }

  const title = `${ProjectCode} - ${ProjectNameTH}`
  return <ProjectBox projectTitle={title} children={renderDataList()} />
}

const ContentBox = styled.div`
  height: 100px;
  border-bottom: solid 1px #e4e7eb;
  &.higlight {
    background-color: rgba(0, 49, 104, 0.03);
  }
`

const RightBox = styled.div`
  float: right;
  width: 100%;
  .score {
    padding: 12px 32px;
    height: 100px;
    border-bottom: solid 1px #e4e7eb;
  }
  .score:last-child {
    border: none;
  }
`

const BoxScore = styled.div`
  font-family: 'DB Helvethaica X 55 Regular';
  font-size: 18px;
  padding-left: 8px;
  color: #000;
`

const BoldText = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 20px;
  color: #000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
const StatusLabel = styled.div`
  width: max-content;
  border-radius: 10px;
  height: 20px;
  text-align: center;
  color: #fff;
  padding: 0 12px;
  font-family: 'DB Helvethaica X 55 Regular';
  font-size: 16px;
  line-height: 1.4;
`

const Space = styled.div`
  width: 40px;
  height: 1px;
  float: left;
`

const Score: any = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 24px;
  line-height: 1;
`
const PassScore = styled(Score)`
  color: #6bd5ae;
`

const FailScore = styled(Score)`
  color: #d0494c;
`

export default InprogressCard
