import RootState from 'typings/RootState'
import { createSelector } from 'reselect'
import { FunctionChecklist } from './types'
import { Event } from '../../types/event'

export const selectData = (state: RootState): FunctionChecklist =>
  state.reportFunction.data

export const selectEvent = (state: RootState): Event =>
  state.reportFunction.event
