import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { RootState } from 'typings/RootState'
import styled from 'styled-components'
import { getQCTypeList } from 'features/filter/api'
import { GetQCTypeListReq } from 'features/filter/types'
import { selectQCTypeList } from 'features/filter/selector'
import { Button, Col } from 'antd'
import {
  selectedProjectCodeSelector,
  projectsByIdSelector,
} from 'features/project/project.selector'
import {
  serviceTokenSelector,
  userEmailSelector,
} from 'features/sensitive/sensitive.selector'
import { QCTypeFilter, ProjectsFilter } from './Components'
import { selectUnitFilter as selectFilter } from 'features/qc/dashboard/selector'
import { UnitFilter as Filter } from 'features/qc/dashboard/types'
import { updateUnitFilter as updateFilter } from 'features/qc/dashboard/actions'
import { fetchProjects } from 'features/project/project.api'

type Props = {
  onSearch?: () => void
  withPadding?: boolean
} & ReduxProps &
  DispatchProps

const QCUnitFilter: React.FunctionComponent<Props> = props => {
  const {
    ProjectCode,
    UserEmail,
    token,
    filter,
    onSearch = () => {},
    withPadding = false,
    qctypeList,
    projectsById,
  } = props

  useEffect(() => {
    onGetQCTypeList()
    dispatch(fetchProjects({ email: UserEmail, token }))
  }, [ProjectCode, UserEmail])

  const onGetQCTypeList = () => {
    const req: GetQCTypeListReq = {
      UserEmail,
      UnitType: 'Unit',
      Status: 'Active',
    }
    dispatch(getQCTypeList(token, req))
  }

  const dispatch = useDispatch()
  const onFilterChange = (filter: Filter) => {
    dispatch(updateFilter(filter))
  }

  return (
    <MainPage className={withPadding ? 'with-padding' : ''}>
      <div className="filters">
        <Col span={24} className="filters-button">
          <ProjectsFilter
            filter={filter}
            onFilterChange={onFilterChange}
            projectsById={projectsById}
          />
          <QCTypeFilter
            onFilterChange={onFilterChange}
            filter={filter}
            qctypeList={qctypeList.List}
          />
          <Button type="primary" onClick={onSearch}>
            กรองข้อมูล
          </Button>
        </Col>
      </div>
    </MainPage>
  )
}

const MainPage = styled.div`
  &.with-padding {
    padding: 0 120px;
  }

  background-color: #f5f6fa;
`

const mapStateToProps = (state: RootState) => {
  return {
    ProjectCode: selectedProjectCodeSelector(state),
    UserEmail: userEmailSelector(state),
    token: serviceTokenSelector(state),
    filter: selectFilter(state),
    qctypeList: selectQCTypeList(state),
    projectsById: projectsByIdSelector(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(QCUnitFilter)
