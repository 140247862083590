import withAPIAction from 'utils/withAPIAction'
import { GetImageListAction, SaveImageListAction } from './actions'
import { GetImageListReq, SaveImageListReq } from './types'
import { verifyReq } from 'utils/verify'

export const getImageListByQCApi = (token: string, data: GetImageListReq) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<GetImageListReq>({
    method: 'POST',
    path: 'GetImageListByQC',
    data,
    token: `Bearer ${token}`,
  })(GetImageListAction)
}
export const saveImageListByQCApi = (token: string, data: SaveImageListReq) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<SaveImageListReq>({
    method: 'POST',
    path: 'SaveImageListByQC',
    data,
    token: `Bearer ${token}`,
  })(SaveImageListAction)
}
