import withAPIAction from 'utils/withAPIAction'
import { verifyReq } from 'utils/verify'

import {
  getTodoActivityAction,
  getProgressActivityAction,
  getQcActivityAction,
} from './actions'

import { GetActivityRequest } from './types'

export const getTodoActivityList = (
  token: string,
  data: GetActivityRequest
) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<GetActivityRequest>({
    method: 'POST',
    path: 'GetTodoActivitySeeMoreForMainCon',
    data,
    token: `Bearer ${token}`,
  })(getTodoActivityAction)
}

export const getProgressActivityList = (
  token: string,
  data: GetActivityRequest
) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<GetActivityRequest>({
    method: 'POST',
    path: 'GetInprogressActivitySeeMoreForMainCon',
    data,
    token: `Bearer ${token}`,
  })(getProgressActivityAction)
}

export const getQcActivityList = (token: string, data: GetActivityRequest) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<GetActivityRequest>({
    method: 'POST',
    path: 'GetQCActivitySeemoreForMainCon',
    data,
    token: `Bearer ${token}`,
  })(getQcActivityAction)
}
