import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { connect, useDispatch } from 'react-redux'
import { Row, Col } from 'antd'
import { RouteComponentProps } from 'react-router-dom'
import RootState from 'typings/RootState'
import SubnavBar from 'Components/Shared/SubnavBar'
import { BreadcrumbLink } from 'Components/Shared/BreadCrumb/BreadCrumb'
import Menu from '../../MenuAdmin'

import {
  userEmailSelector,
  serviceTokenSelector,
} from 'features/sensitive/sensitive.selector'

import { getDataList, updateData } from 'features/admin/email/api'
import { selectDataList, selectEvent } from 'features/admin/email/selector'
import {
  GetDataListReq,
  UpdateDataReq,
  DataList,
} from 'features/admin/email/types'
import { EventModal } from 'Components/Shared'
import EmailBox from './EmailBox'
import { DropdownList } from 'Components/Shared/Form'
import { PrimaryButton } from 'Components/Shared/Button'

const breadcrumbs: BreadcrumbLink[] = [
  {
    url: '/dashboard',
    title: 'Home',
    icon: '/home.png',
    active: true,
    showTitle: false,
  },
  {
    url: '/qc-type',
    title: 'QC Type list',
    active: false,
    showTitle: false,
  },
]

type DefultProps = {
  homepage?: boolean
}

type Props = DefultProps & ReduxProps & DispatchProps & RouteComponentProps

const EmailSetting: React.ComponentType<Props> = props => {
  const { token, UserEmail, dataList, event } = props
  const [mode, setMode] = useState('' as 'update' | 'create' | 'delete' | '')
  const [enableModal, setEnableModal] = useState(false)
  const [data, setData] = useState({} as DataList)

  const dispatch = useDispatch()

  useEffect(() => {
    onGetList()
    setEnableModal(false)
  }, [])

  useEffect(() => {
    setData(dataList)
  }, [dataList])

  const onGetList = () => {
    setEnableModal(true)
    const req: GetDataListReq = {
      UserEmail,
    }
    dispatch(getDataList(token, req))
  }

  const onUpdate = () => {
    setMode('update')
    setEnableModal(true)
    const req: UpdateDataReq = {
      UserEmail,
      EmailList: data.EmailList,
      EmailTime: data.EmailTime,
    }
    dispatch(updateData(token, req))
  }

  const onSuccess = () => {
    setEnableModal(false)
    if (mode === 'update') {
      setMode('')
      onGetList()
    }
  }

  const onUpdateEmail = (emailList: string[]) => {
    data.EmailList = emailList
    setData({ ...data })
  }

  const renderTime = () => {
    let values = []

    for (let index = 0; index < 24; index++) {
      const name = index <= 9 ? '0' + index : index
      values.push({
        name: name + ':00',
        value: name + ':00',
      })
    }

    return values
  }

  return (
    <>
      <SubnavBar breadcrumbs={breadcrumbs} hideSearch={true} />
      <EventModal
        name="defect"
        event={[event]}
        enable={enableModal}
        successCalback={onSuccess}
      />
      <Menu selected={'email-setting'}>
        <MainPage>
          <Header>
            <Row type="flex" align="middle" justify="space-between">
              <Col>
                <Title>ตั้งค่าอีเมล</Title>
              </Col>
            </Row>
          </Header>
          <DataArea>
            <Box>
              <TitleSecond>รายชื่อ Auto mail QC</TitleSecond>
              <EmailBox emailList={data.EmailList} onChange={onUpdateEmail} />
              <TitleSecond>เวลาส่ง Auto mail รายวัน</TitleSecond>
              <DropdownList
                label="เวลา"
                value={data.EmailTime}
                menu={renderTime()}
                onChange={(value: any) => {
                  data.EmailTime = value
                  setData({ ...data })
                }}
              />
            </Box>
          </DataArea>
          <SaveArea>
            <PrimaryButton onClick={onUpdate} text="บันทึก" />
          </SaveArea>
        </MainPage>
      </Menu>
    </>
  )
}

const MainPage = styled.div`
  padding: 40px 45px;
  width: 100%;
`

const SaveArea = styled.div`
  margin-top: 32px;
  width: 160px;
`

const Box = styled.div`
  width: 380px;
`

const Header = styled.div``

const Title = styled.h2`
  font-size: 28px;
  font-family: 'DB Helvethaica X 75 Bd';
  height: 30px;
`

const TitleSecond = styled.div`
  font-size: 20px;
  font-family: 'DB Helvethaica X 75 Bd';
  margin: 24px 0 8px 0;
  color: #000;
`

const DataArea = styled.div``

const mapStateToProps = (state: RootState) => {
  return {
    dataList: selectDataList(state),
    event: selectEvent(state),
    token: serviceTokenSelector(state),
    UserEmail: userEmailSelector(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmailSetting)
