import React from 'react'
import styled from 'styled-components'
import iconWaring from 'assets/img/ic-approval-logout.png'
import iconNoImg from 'assets/img/ic-no-image.png'
import iconNoImg2 from 'assets/img/ic-no-image2.png'
interface Props {
  isRejectCheck?: boolean
  size?: number
  isMaincon?: boolean
  reason?: string
}

const RejectStatusIcon = (props: Props) => {
  const { isRejectCheck = false, isMaincon = false, reason = '' } = props
  let title = ''
  if (isRejectCheck) {
    title = reason
  }

  return (
    <Center>
      {isRejectCheck && (
        <>
          <img src={iconNoImg2} width="36" />
          {/* <TextBy>{title}</TextBy> */}
        </>
      )}
    </Center>
  )
}

const Center = styled.div`
  width: 100%;
  text-align: center;
`

const TextBy = styled.p`
  font-size: 16px;
  margin-bottom: 4px;
  font-family: 'DB Helvethaica X 55 Regular';
  color: #8b99a7;
`

export default RejectStatusIcon
