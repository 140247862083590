import React, { useState, useEffect } from 'react'
import { Row, Col } from 'antd'
import CheckAsOptions from './CheckAsOptions'
import { SearchInput } from '../Form'
import { cloneDeep } from 'lodash'
import { hasText } from 'utils/getText'

interface Props {
  filter: any[]
  onChange(filter: any[]): void
  projectList: any
  hasSearch?: boolean
  projectTypeID?: number
}

const MulticheckProject: React.FunctionComponent<Props> = ({
  filter,
  onChange,
  projectList,
  hasSearch = true,
  projectTypeID = 0,
}) => {
  const [textSearch, setTextsearch] = useState('')
  const [dataFilter, setDataFilter] = useState([] as any[])

  useEffect(() => {
    const projects: any[] = getProject()
    setDataFilter(cloneDeep(projects))
    setTextsearch('')
  }, [hasSearch, projectList, projectTypeID])

  const getProject = () => {
    let projects: any[] = Object.values(projectList)
    if (projectTypeID > 0) {
      projects = projects.filter(item => item.ProjectTypeID === projectTypeID)
    }
    return projects
  }

  useEffect(() => {
    if (!hasSearch) return
    const projects: any[] = getProject()
    const list = cloneDeep(projects)
    let filter = []
    if (textSearch === '') {
      filter = list
    } else {
      filter = list.filter(
        item =>
          hasText(item.ProjectCode, textSearch) ||
          hasText(item.ProjectNameTH, textSearch)
      )
    }

    setDataFilter(filter)
  }, [textSearch])

  const onValueChange = (val: any) => {
    const index = filter.findIndex(item => item === val)
    if (index >= 0) {
      filter.splice(index, 1)
    } else {
      filter.push(val)
    }
    onChange(filter)
  }

  const onClear = () => {
    filter = []
    onChange(filter)
  }

  const content = () => {
    return (
      dataFilter &&
      dataFilter.map((item, index) => {
        let findIndex = filter.findIndex(it => it == item.ProjectCode)
        return (
          <Col span={24} key={index}>
            <CheckAsOptions
              key={item.ProjectCode}
              title={`${item.ProjectCode}-${item.ProjectNameTH}`}
              value={findIndex >= 0}
              item={item.ProjectCode}
              onChange={onValueChange}
            />
          </Col>
        )
      })
    )
  }

  const clearBtn =
    filter.length > 0 ? (
      <span onClick={onClear} className="filter-clear std-font-regular">
        ล้างการค้นหา
      </span>
    ) : (
      undefined
    )

  return (
    <div className="std-font-regular" style={css.page}>
      <Row>
        <Col span={24}>
          <div className="std-font-bold" style={css.title}>
            {hasSearch && (
              <SearchInput onSearch={setTextsearch} searchTxt={textSearch} />
            )}
            {clearBtn}
          </div>
        </Col>
      </Row>
      <Row className="pop-over-container-choices">{content()}</Row>
    </div>
  )
}

const css: any = {
  page: {
    width: '296px',
    height: 'auto',
    padding: '25px 24px',
  },
  title: {
    textAlign: 'left',
    color: 'black',
    fontSize: '20px',
    marginBottom: '25px',
  },
  cancel: {
    float: 'right',
    color: '#003168',
    fontSize: '18px',
  },
}

export default MulticheckProject
