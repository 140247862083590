import React from 'react'
import styled from 'styled-components'

const BaseImgBtn = (props: any) => {
  const { size = 16, disabled = false } = props
  const css = disabled === false ? { cursor: 'pointer' } : undefined
  return (
    <Section
      src={props.img}
      alt=""
      onClick={props.onClick}
      width={`${size} px`}
      style={css}
    />
  )
}

const Section = styled.img``

export default BaseImgBtn
