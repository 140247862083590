import withAPIAction from 'utils/withAPIAction'
import { verifyReq, verifyReqNoProject } from 'utils/verify'
import {
  getAuditRoadAction,
  sendAuditRoadAction,
  returnAuditRoadAction,
  acceptAuditRoadAction,
  RejectTasksAction,
} from './actions'

import {
  GetAuditRoadReq,
  SendAuditRoadReportReq,
  ReturnAuditRoadReq,
  AcceptAuditRoadReq,
  RejectCheckingTaskReq,
} from './types'

// Audit Road
export const getAuditRoadForQC = (token: string, data: GetAuditRoadReq) => {
  const isValid = verifyReqNoProject(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<GetAuditRoadReq>({
    method: 'POST',
    path: 'GetAuditRoadForQC',
    data,
    token: `Bearer ${token}`,
  })(getAuditRoadAction)
}

export const sendAuditRoadReportByQC = (
  token: string,
  data: SendAuditRoadReportReq
) => {
  const isValid = verifyReqNoProject(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<SendAuditRoadReportReq>({
    method: 'POST',
    path: 'SendAuditRoadReportByQC',
    data,
    token: `Bearer ${token}`,
  })(sendAuditRoadAction)
}

export const returnAuditRoadByQC = (
  token: string,
  data: ReturnAuditRoadReq
) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<ReturnAuditRoadReq>({
    method: 'POST',
    path: 'ReturnAuditRoadByQC',
    data,
    token: `Bearer ${token}`,
  })(returnAuditRoadAction)
}

export const acceptAuditRoadByQC = (
  token: string,
  data: AcceptAuditRoadReq
) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<AcceptAuditRoadReq>({
    method: 'POST',
    path: 'AcceptCheckingAuditRoadByQC',
    data,
    token: `Bearer ${token}`,
  })(acceptAuditRoadAction)
}

export const RejectCheckingTasks = (
  token: string,
  data: RejectCheckingTaskReq
) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<RejectCheckingTaskReq>({
    method: 'POST',
    path: 'RejectCheckingTask',
    data,
    token: `Bearer ${token}`,
  })(RejectTasksAction)
}
