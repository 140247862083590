import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { AddList, EditDeleteButton } from 'Components/Shared/Button'
import { Row, Col } from 'antd'
import { ConfirmDeleteModal, AddModal } from 'Components/Shared'
import { cloneDeep } from 'lodash'
import { validateEmail } from 'utils/verify'

type Props = {
  emailList: string[]
  onSave: (emailList: string[]) => void
}

const EmailBox: React.ComponentType<Props> = ({ emailList, onSave }) => {
  const [dataList, setDataList] = useState<string[]>([])
  const [mode, setMode] = useState('' as 'edit' | 'add' | 'delete')
  const [showModal, setshowModal] = useState(false)
  const [invalid, setIsInvalid] = useState(false)
  const [item, setItem] = useState<string>()

  useEffect(() => {
    setDataList(cloneDeep(emailList) || [])
  }, [emailList])

  const onCloseModal = () => {
    setshowModal(false)
    setIsInvalid(false)
  }

  const onClickSave = (value: string) => {
    if (validateEmail(value)) {
      setIsInvalid(false)
      onSubmit(value)
    } else {
      setIsInvalid(true)
    }
  }

  const onChangeValidation = (value: string) => {
    if (invalid) {
      if (validateEmail(value)) {
        setIsInvalid(false)
      } else {
        setIsInvalid(true)
      }
    }
  }

  const onSubmit = (value: string) => {
    setshowModal(false)
    let newData = dataList as string[]
    if (mode === 'add') {
      newData.push(value)
    }

    if (mode === 'edit' && item) {
      newData = dataList.map(email => {
        if (email === item) {
          return value
        }
        return email
      })
    }

    if (mode === 'delete' && item) {
      newData = dataList.filter(email => email !== item)
    }

    onSave(newData)
  }

  const onClickAdd = () => {
    setMode('add')
    setshowModal(true)
  }

  const onClickEdit = (item: string) => {
    setMode('edit')
    setItem(item)
    setshowModal(true)
  }

  const onClickDelete = (item: string) => {
    setMode('delete')
    setItem(item)
    setshowModal(true)
  }

  const list =
    dataList &&
    dataList.map((item: string, index) => {
      return (
        <Item>
          <Row type="flex" justify="space-between">
            <Col>{item}</Col>
            <Col>
              <EditDeleteButton
                showEditeModal={() => onClickEdit(item)}
                showDeleteModal={() => onClickDelete(item)}
              />
            </Col>
          </Row>
        </Item>
      )
    })

  const isAddMode = mode === 'add'
  const isEditMode = mode === 'edit'
  const isDeleteMode = mode === 'delete'

  return (
    <>
      <Box>
        {list}
        <AddList style={{ margin: 0 }} onClick={onClickAdd} />
      </Box>
      <AddModal
        visible={showModal && (isAddMode || isEditMode)}
        onClose={onCloseModal}
        onSubmit={(val: string) => onClickSave(val)}
        title={isAddMode ? 'เพิ่มอีเมล' : 'แก้ไขอีเมล'}
        placeholder="ระบุอีเมล"
        value={(isEditMode && item) || ''}
        onChange={onChangeValidation}
        warningMessage={invalid ? 'รูปแบบอีเมลไม่ถูกต้อง' : ''}
        prefix="อีเมล"
      />
      <ConfirmDeleteModal
        title="ลบรายการ"
        desc={`คุณยืนยันต้องการลบ ${item} นี้`}
        onClose={onCloseModal}
        onSubmit={() => onSubmit('')}
        visible={showModal && isDeleteMode}
      />
    </>
  )
}

const Box = styled.div`
  height: 232px;
  border-radius: 5px;
  border: solid 1px #e4e7eb;
  width: 100%;
  padding: 0 24px;
  overflow: scroll;
`

const Item = styled.div`
  padding: 12px 0;
  font-family: 'DB Helvethaica X 55 Regular';
  font-size: 20px;
  border-bottom: 1px solid #e4e7eb;
`

export default EmailBox
