import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'

import { Layout } from 'antd'
import TopNav from 'Components/Shared/TopNav'
import PmrList from 'Components/PMR/PmrList'
import PmrReport from 'Components/PMR/Report'

const PmrApp = () => {
  return (
    <BrowserRouter>
      <Layout className="layout" hasSider={false}>
        <TopNav title="PMR" displayProject={false} />
        <Route exact path="/" component={PmrList} />

        <Switch>
          <Route exact path="/list" component={PmrList} />
          <Route
            exact
            path="/report/:project/:unit/:requestID/:qctypeID/:floor"
            component={PmrReport}
          />
        </Switch>
      </Layout>
    </BrowserRouter>
  )
}

export default PmrApp
