export const validateJobType = (check: any) => {
  let job
  let subjob

  if (check.CheckListName === '') return 'กรุณาระบุชื่อ'

  if (check.JobTypeList.length === 0) return 'กรุณาเลือกลักษณะงานหลัก'

  for (let j = 0; j < check.JobTypeList.length; j++) {
    job = check.JobTypeList[j]
    const jobPath = job.JobTypeName
    if (job.SubJobTypeList.length === 0) {
      return `กรุณาเลือกลักษณะงานรองใน ${jobPath}`
    }
    for (let sj = 0; sj < job.SubJobTypeList.length; sj++) {
      subjob = job.SubJobTypeList[sj]
      const subJobPath = `${jobPath} / ${subjob.SubJobTypeName}`
      if (subjob.DetailList.length === 0) {
        return `กรุณาเลือกรายละเอียดใน ${subJobPath}`
      }
    }
  }

  return ''
}

export const validateQCType = (check: any) => {
  let job
  let subjob

  if (check.QCTypeList.length === 0) return 'กรุณาเลือกหมวดงาน'
  for (let qcttype = 0; qcttype < check.QCTypeList.length; qcttype++) {
    let qcType = check.QCTypeList[qcttype]
    if (qcType.JobTypeList && qcType.JobTypeList.length === 0) {
      return 'กรุณาเลือกลักษณะหลักงานใน ' + qcType.QCTypeName
    }

    for (let j = 0; j < qcType.JobTypeList.length; j++) {
      job = qcType.JobTypeList[j]
      const jobPath = `${qcType.QCTypeName} / ${job.JobTypeName}`
      if (job.SubJobTypeList.length === 0) {
        return `กรุณาเลือกลักษณะงานรองใน ${jobPath}}`
      }

      for (let sj = 0; sj < job.SubJobTypeList.length; sj++) {
        subjob = job.SubJobTypeList[sj]
        const subJobPath = `${jobPath} / ${subjob.SubJobTypeName}`
        if (subjob.DetailList.length === 0) {
          return `กรุณาเลือกรายละเอียดใน ${subJobPath}`
        }
      }
    }
  }
  return ''
}

/*
export const validateJobTypeNcrScore = (check: any) => {
  let job
  let subjob
  let detail
  if (check.JobTypeList.length === 0) {
    return 'กรุณาเลือกลักษณะหลักงานหลัก'
  }
  for (let j = 0; j < check.JobTypeList.length; j++) {
    job = check.JobTypeList[j]
    const jobPath = job.JobTypeName
    if (job.SubJobTypeList.length === 0) {
      return `กรุณาเลือกลักษณะงานรองใน ${jobPath}`
    }

    for (let sj = 0; sj < job.SubJobTypeList.length; sj++) {
      subjob = job.SubJobTypeList[sj]
      const subJobPath = `${jobPath} / ${subjob.SubJobTypeName}`
      if (subjob.DetailList.length === 0) {
        return `กรุณาเลือกรายละเอียดใน ${subJobPath}`
      }

      for (let d = 0; d < subjob.DetailList.length; d++) {
        detail = subjob.DetailList[d]
        const detailPath = `${subJobPath} / ${detail.DetailName}`
        if (detail.Score === 0) {
          return `กรุณากรอก เกณ์หักคะแนน ใน ${detailPath}`
        }
        if (detail.NCRScore === 0) {
          return `กรุณากรอก ระดับคะแนนที่เกิดซ้ำ ใน ${detailPath}`
        }
      }
    }
  }
  return ''
}
*/
