import React, { useState } from 'react'
import styled from 'styled-components'
import { PrimaryButton } from 'Components/Shared/Button'
import { Row, Col } from 'antd'
import RootState from 'typings/RootState'

import { CheckedIcon } from 'Components/Shared/Icon'

type Props = {
  report: any
  visible?: boolean
  onSign: () => void
  onChooseImage: () => void
}

const QcFooter: React.ComponentType<Props> = props => {
  const { report, visible = false, onSign, onChooseImage } = props
  const { State, Status } = report

  const isReadOnly = !(State === 'QC-QC' && Status === 'INPRO')
  if (isReadOnly || visible === false) {
    return <div />
  }

  const hasSign = report.HasSign ? (
    <IconBtn>
      <CheckedIcon />
      ลงชื่อรับทราบ
    </IconBtn>
  ) : (
    'ลงชื่อรับทราบ'
  )

  const hasImage = report.HasSelectedDefect ? (
    <IconBtn>
      <CheckedIcon />
      เลือกหมุดส่งรายงาน
    </IconBtn>
  ) : (
    'เลือกหมุดส่งรายงาน'
  )

  return (
    <Footer>
      <Row type="flex" justify="space-between" align="middle">
        <Col />
        <Col>
          <Button>
            <PrimaryButton text={hasSign} onClick={onSign} />
          </Button>
          <Button>
            <PrimaryButton text={hasImage} onClick={onChooseImage} />
          </Button>
        </Col>
      </Row>
    </Footer>
  )
}

const Footer = styled.div`
  position: fixed;
  bottom: 0px;
  height: auto;
  width: 100%;
  background-color: white;
  padding: 22px 80px;
  z-index: 20;
`

const Button = styled.div`
  width: 200px;
  margin-left: 16px;
  float: left;
`

const IconBtn = styled.div`
  img {
    margin-right: 10px;
  }
`

export default QcFooter
