import { takeEvery, put, select } from 'redux-saga/effects'
import * as actions from './sensitive.actions'
import * as loginActions from 'features/login/login.actions'
import { createBrowserHistory } from 'history'
import { serviceTokenSelector, getUserProfile } from './sensitive.selector'
import { saveTokenCode, saveUserProfile } from 'utils/account'

function* watchServicetokenChange() {
  const serviceToken = yield select(serviceTokenSelector)
  const userProfile = yield select(getUserProfile)
  saveTokenCode(serviceToken)
  saveUserProfile(userProfile)
}

function* showTokenExpirePopup() {
  const history = createBrowserHistory()
  history.push('/token-expired')

  // Force logout action
  yield put(loginActions.logoutUser())
}

function* sensitiveSaga() {
  yield takeEvery(
    actions.getServiceTokenAsyncAction.success,
    watchServicetokenChange
  )

  yield takeEvery(
    actions.getServiceTokenAsyncAction.failure,
    showTokenExpirePopup
  )
}

export default sensitiveSaga
