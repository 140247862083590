export const saveHideColumns = (columns: string[]) => {
  const data = {
    version: 'v1',
    data: columns,
  }
  localStorage.setItem('user_column', JSON.stringify(data))
}

export const getColumns = (): any => {
  let result = localStorage.getItem('user_column') || '{}'
  result = JSON.parse(result)
  return result
}

export const clear = () => {
  console.log('clear project')
  localStorage.removeItem('user_column_v1')
}

export const saveConfigColumns = (columns: string[], name: string) => {
  const data = {
    version: 'v1',
    data: columns,
  }
  localStorage.setItem(name, JSON.stringify(data))
}

export const getConfigColumns = (name: string): any => {
  let result = localStorage.getItem(name) || '{}'
  result = JSON.parse(result)
  return result
}

export default {
  saveHideColumns,
  getColumns,
  clear,
  saveConfigColumns,
  getConfigColumns,
}
