import { getType } from 'typesafe-actions'
import * as actions from './actions'
import { StoreState, DashboardAction } from './types'
import produce from 'immer'
import parseErrorMessage from 'utils/parseErrorMessage'
import { Event } from 'features/types/event'
import { cloneDeep } from 'lodash'

const initEvent = {
  IsLoading: false,
  MessageSuccess: undefined,
  SubmitError: undefined,
  SubmitSuccess: false,
  SubmitType: undefined,
} as Event

const initialState: StoreState = {
  data: {
    BadImage: [],
    GoodImage: [],
  },
  event: cloneDeep(initEvent),
}

export default function(
  state: StoreState = initialState,
  action: DashboardAction
): StoreState {
  return produce(state, draft => {
    switch (action.type) {
      case getType(actions.GetImageListAction.request):
        draft.event.SubmitError = undefined
        draft.event.IsLoading = true
        draft.event.SubmitSuccess = false
        draft.data = state.data
        break
      case getType(actions.GetImageListAction.success):
        const res = action.payload.ResponseData
        draft.event.IsLoading = false
        if (res) {
          draft.data = res
        }
        break
      case getType(actions.GetImageListAction.failure):
        draft.event.IsLoading = false
        draft.event.SubmitError = parseErrorMessage(action.payload)
        break

      case getType(actions.ResetEvent):
        draft.event = cloneDeep(initEvent)
        break
      case getType(actions.SaveImageListAction.request):
        draft.event.SubmitError = undefined
        draft.event.IsLoading = true
        draft.event.SubmitSuccess = false
        break
      case getType(actions.SaveImageListAction.success):
        draft.event.IsLoading = false
        draft.event.SubmitError = undefined
        draft.event.SubmitSuccess = true
        draft.event.MessageSuccess = 'บันทึกสำเร็จ'
        break
      case getType(actions.SaveImageListAction.failure):
        draft.event.IsLoading = false
        draft.event.SubmitError = parseErrorMessage(action.payload)
        break

      case getType(actions.updateImageList):
        draft.data = action.payload
        break

      default:
        return state
    }
  })
}
