import React, { useState, useRef, useEffect } from 'react'
import memoize from 'memoize-one'
import styled from 'styled-components'

import ContentBox from 'Components/Shared/Card/ContentBox'
import { AddList, EditDeleteButton } from 'Components/Shared/Button'
import ProjectSelector, {
  ProjectModalHandles,
} from 'Components/AdminQC/Modal/ProjectSelector'

import { Row } from 'antd'
import { ZoneDetail, Project, ProjectZone } from 'features/admin/zone/types'

type Props = {
  zoneDetail: ZoneDetail
  projectZoneList: ProjectZone[]
  updateCheckList: (val: ZoneDetail) => void
}

const filterProject = memoize((list, filterText) => {
  return list.filter((item: Project) => {
    if (filterText === '') return item.Selected != 'N'
    const filterLower = filterText.toLocaleLowerCase()
    return (
      (item.ProjectCode.toLocaleLowerCase().includes(filterLower) ||
        item.ProjectName.toLocaleLowerCase().includes(filterLower)) &&
      item.Selected !== 'N'
    )
  })
})

const ProjectsBox: React.ComponentType<Props> = ({
  zoneDetail,
  projectZoneList,
  updateCheckList,
}) => {
  const modalRef = useRef<ProjectModalHandles>(null)
  const [filterText, setfilterText] = useState<string>('')

  const filterSelectProject: Project[] = filterProject(
    zoneDetail.ProjectList,
    filterText
  )
  const showSelectProjectModal = (it?: Project) => {
    if (modalRef.current) {
      modalRef.current.showModal(it ? it.ProjectCode : undefined)
    }
  }

  const selectProjectModal = (val: any[]) => {
    zoneDetail.ProjectList = val
    updateCheckList(zoneDetail)
  }

  function onSearch(text: string) {
    setfilterText(text)
  }

  const list = zoneDetail.ProjectList.filter(item => item.Selected !== 'N')
  return (
    <ContentBox
      title={`โครงการที่ใช้งาน (${list.length})`}
      emptyText="กรุณาเลือก “ประเภทโครงการ”"
      onSearch={onSearch}
    >
      <ListSection>
        {filterSelectProject.map((item: Project) => {
          return (
            <List key={item.ProjectCode}>
              <ListText>
                <Row type="flex" justify="space-between" className="row-item">
                  <div className="text-projectcode">
                    <span>{`${item.ProjectCode} - ${item.ProjectName}`}</span>
                  </div>
                  <EditDeleteButton
                    showEditeModal={() => showSelectProjectModal(item)}
                  />
                </Row>
              </ListText>
            </List>
          )
        })}
        <AddList onClick={() => showSelectProjectModal()} />
      </ListSection>

      <ProjectSelector
        ref={modalRef}
        CheckListID={zoneDetail.CheckListID}
        projectList={projectZoneList}
        selectList={zoneDetail.ProjectList}
        onOk={selectProjectModal}
      />
    </ContentBox>
  )
}

const ListSection = styled.div`
  margin-top: 16px;
  height: 256px;
  overflow: auto;
  padding: 0 8px;
`

const List = styled.div`
  font-size: 20px;
  width: 100%;
`

const ListText = styled.div`
  border-bottom: 1px solid #e8ebf0;
  height: 100%;
  margin: 0 16px;
  position: relative;

  .text-projectcode {
    padding: 13px 0;
    width: 100%;
  }
  .icon-action {
    position: absolute;
    right: 0px;
    top: 5px;
    padding: 10px 0;
  }
`

export default ProjectsBox
