import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'antd'
import moment from 'moment-timezone'
import { Link } from 'react-router-dom'
import { Activity } from 'features/maincon/dashboard/types'
import { translate } from 'i18n'
import TKeys from 'i18n/translationKey'
import { WarningIcon } from 'Components/Shared/Icon'
import {
  getDelayedDate,
  getDelayedText,
  getStartText,
  toDateText,
  toTimeText,
} from 'utils/datetime'
import { TypeOfLane } from 'features/maincon/dashboard/types'
import ContructionTagName from 'Components/Shared/ContructionTagName'
import { ProgressCircle } from 'Components/Shared'
import { displayCategory } from 'utils/helper'

const text = {
  day: translate(TKeys.Common.day),
  start_in_day: translate(TKeys.TaskLane.start_within_day),
  delaye_in_day: translate(TKeys.TaskLane.delayed_by_day),
  delayed_day: 'เสร็จช้ากว่ากำหนด',
  remain: 'เหลืออีก',
  start_day: translate(TKeys.TaskLane.plan_start_date),
  end_day: translate(TKeys.TaskLane.plan_end_date),
}

interface Props {
  type?: string
  task: Activity
  wbs: any
  search?: string
  onClick?: (code: string) => void
}

const Card: React.FC<Props> = ({
  type = '',
  task,
  search = '',
  onClick,
  wbs,
}) => {
  const {
    ActivityID: id,
    ActivityName: activityTitle,
    BaselineStartDate: startDate,
    BaselineFinishDate: finishDate,
    AppointmentDate,
  } = task
  const appointmentDate = moment(AppointmentDate, 'DD/MM/YYYY HH:mm')
  const useDate = type === TypeOfLane.TODO ? startDate : finishDate
  let delayed = getDelayedDate(useDate)

  let content
  let lane
  if (delayed < 0) {
    let days = Math.abs(delayed)
    const display = getDelayedText(days, type)
    lane = <span className="lane-card-delayed" />
    content = (
      <div className="status status-danger">
        <WarningIcon />
        <span className="countdown">{display}</span>
      </div>
    )
  } else {
    const display = getStartText(delayed, type)
    content = (
      <div className={'status status-default'}>
        <span className="countdown">{display}</span>
      </div>
    )
  }

  return (
    <Link to={`/activity/${id}${search}`}>
      <MainCard>
        {lane}
        <Row type="flex" justify="space-between">
          <Col span={17}>
            {content}
            <Title>{activityTitle}</Title>
          </Col>
          {type !== TypeOfLane.TODO && (
            <Col>
              <MyProgress>
                <ProgressCircle task={task} width={48} />
              </MyProgress>
            </Col>
          )}
        </Row>
        <Row type="flex">
          <Col span={24}>
            <Detail>{displayCategory(task)}</Detail>
          </Col>
        </Row>
        <Row type="flex">
          <Col span={24}>
            <TextDate>
              {text.start_day} {startDate}
              <span>
                {text.end_day} {finishDate}
              </span>
            </TextDate>
            {type === TypeOfLane.REQUESTED && AppointmentDate && (
              <TextDate>
                วันที่นัดหมายตรวจ {toDateText(appointmentDate)},
                {toTimeText(appointmentDate)}
              </TextDate>
            )}
            {task.ConstructionTypeName && (
              <ContructionTagName name={task.ConstructionTypeName} />
            )}
          </Col>
        </Row>
      </MainCard>
    </Link>
  )
}

const MainCard = styled.div`
  background: #fff;
  border-radius: 10px;
  padding: 16px;
  background-clip: padding-box;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.03);
  margin-bottom: 12px;
  cursor: pointer;
  color: #000;
  width: 100%;
  position: relative;
`

const Title = styled.p`
  font-size: 20px;
  margin-bottom: 4px;
  font-family: 'DB Helvethaica X 75 Bd';
`

const Detail = styled.p`
  font-size: 18px;
  margin-bottom: 4px;
  font-family: 'DB Helvethaica X 55 Regular';
`

const TextDate = styled.div`
  font-size: 18px;
  font-family: 'DB Helvethaica X 55 Regular';
  span {
    margin-left: 8px;
  }
`
const MyProgress = styled.div`
  .ant-progress-text {
    font-size: 16px !important;
  }
`

export default Card
