import React, { useState } from 'react'
import styled from 'styled-components'

import { DropdownList } from 'Components/Shared/Form'

import {
  QCType,
  CheckListDetail,
  Function,
} from 'features/checklist/unit/types'
import { BottomButton } from 'Components/Shared/Button'

interface Props {
  checkListDetail: CheckListDetail
  qCTypeList: QCType[]
  functionList: Function[]
  editingObj?: QCType
  onSave: (item: QCType) => void
  closeModal: () => void
  disabled: boolean
}
function getInitialFunction(functionList: Function[], editingObj?: QCType) {
  if (editingObj) {
    for (let i = 0; i < functionList.length; i++) {
      if (functionList[i].CheckListID === editingObj.FunctionCheckListID) {
        return {
          id: functionList[i].CheckListID,
          name: functionList[i].CheckListName,
        }
      }
    }
  }
  return { id: 0, name: '' }
}

const QCTypeModal: React.FC<Props> = ({
  checkListDetail,
  qCTypeList,
  functionList,
  onSave: onSaveProp,
  closeModal,
  editingObj,
  disabled,
}) => {
  const [error, seterror] = useState('')
  const [selectQCType, setSelectQCType] = useState<QCType | null>(
    editingObj || null
  )
  const [selectFunction, setSelectFunction] = useState<{
    id: number
    name: string
  }>(() => getInitialFunction(functionList, editingObj))

  const onSave = () => {
    if (selectQCType && selectFunction.id !== 0) {
      const found = editingObj
        ? null
        : checkListDetail.QCTypeList.find(
            v => v.QCTypeName === selectQCType.QCTypeName
          )

      if (found == null) {
        onSaveProp({
          ...selectQCType,
          FunctionCheckListStatus: 'Active',
          FunctionCheckListID: selectFunction.id,
          JobTypeList: editingObj ? editingObj.JobTypeList : [],
        })
      } else {
        seterror('หมวดงานนี้ ได้มีอยู่แล้ว')
      }
    } else {
      seterror('กรุณาใส่ข้อมูลทั้งหมด')
    }
  }

  const menuChoice = () => {
    return qCTypeList.map((item: QCType) => {
      return (
        <DataItem key={item.QCTypeID} onClick={() => setSelectQCType(item)}>
          {item.QCTypeName}
        </DataItem>
      )
    })
  }

  const menuChoiceFunction = () => {
    return functionList.map((item: Function, index: number) => (
      <DataItem
        key={index}
        onClick={() =>
          setSelectFunction({ id: item.CheckListID, name: item.CheckListName })
        }
      >
        {item.CheckListName}
      </DataItem>
    ))
  }

  return (
    <MainView>
      <DropdownBox>
        <div className="dropdown-item">
          <DropdownList
            label="ชื่อ"
            value=""
            placeholder={selectQCType ? selectQCType.QCTypeName : 'กรุณาเลือก'}
            menuChoice={menuChoice()}
            disabled={true}
          />
        </div>
      </DropdownBox>

      <SelectType>
        <Title>Function</Title>

        <DropdownBox>
          <div className="dropdown-item">
            <DropdownList
              label="เลือก Checklist function"
              value="คอนโดยูนิต V1"
              placeholder={selectFunction ? selectFunction.name : 'กรุณาเลือก'}
              menuChoice={menuChoiceFunction()}
              disabled={disabled}
            />
          </div>
        </DropdownBox>
      </SelectType>

      {!disabled && (
        <BottomButton closeModal={closeModal} onSave={onSave} error={error} />
      )}
    </MainView>
  )
}

const MainView = styled.div``

const Title = styled.span`
  font-size: 20px;
  color: #000000;
  font-family: 'DB Helvethaica X 75 Bd';
`

const SelectType = styled.div`
  padding-top: 17px;
`

const DropdownBox = styled.div`
  padding-top: 14px;
  .dropdown-item {
    padding: 6px 0;
  }
`

const DataItem = styled.div`
  padding: 5px 10px;

  &:hover {
    cursor: pointer;
    color: white;
    background-color: #003168;
  }
`

export default QCTypeModal
