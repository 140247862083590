import { getType } from 'typesafe-actions'
import { cloneDeep } from 'lodash'
import * as actions from './actions'
import { StoreState, DashboardAction, DashboardData, TypeOfTask } from './types'
import produce from 'immer'
import parseErrorMessage from 'utils/parseErrorMessage'
import { sortingList } from 'utils/helper'
import { Event } from 'features/types/event'

const initData = {
  InProgress: [],
  FixFromQC: [],
} as DashboardData

const initEvent = {
  IsLoading: false,
  MessageSuccess: undefined,
  SubmitError: undefined,
  SubmitSuccess: false,
  SubmitType: undefined,
} as Event

const initialState: StoreState = {
  data: initData,
  originalData: initData,
  event: cloneDeep(initEvent),
  actionEvent: cloneDeep(initEvent),
  sorting: {
    inProgress: 'asc',
    fixFromQC: 'asc',
  },
  query: {
    date: {
      start: undefined,
      end: undefined,
    },
  },
  filter: {},
  canSendStatus: false,
}

const sortingColumn = {
  inProgress: '',
  fixFromQC: '',
}

export default function(
  state: StoreState = initialState,
  action: DashboardAction
): StoreState {
  return produce(state, draft => {
    switch (action.type) {
      case getType(actions.getDashbaordAction.request):
        draft.event = cloneDeep(initEvent)
        draft.event.IsLoading = true
        draft.data = cloneDeep(initData)
        break

      case getType(actions.getDashbaordAction.success):
        draft.event.IsLoading = false
        const response = action.payload.ResponseData
        const sorting = state.sorting
        const filter = state.filter

        const cmInProgressSort = sortingList(
          response.InProgress,
          sorting.cmInProgress,
          sortingColumn.inProgress,
          'date'
        )

        const fixFromQCSort = sortingList(
          response.FixFromQC,
          sorting.fixFromQC,
          sortingColumn.fixFromQC,
          'string'
        )

        draft.data = {
          InProgress: cmInProgressSort,
          FixFromQC: fixFromQCSort,
        }

        draft.originalData = {
          InProgress: response.InProgress,
          FixFromQC: response.FixFromQC,
        }

        break

      case getType(actions.getDashbaordAction.failure):
        draft.event.IsLoading = false
        draft.event.SubmitError = parseErrorMessage(action.payload)

        break

      case getType(actions.sortingDashboard):
        let typ: string = action.payload
        let orderBy: string = state.sorting[typ]
        orderBy = orderBy !== 'asc' ? 'asc' : 'desc'
        draft.sorting[typ] = orderBy

        if (typ === TypeOfTask.InProgress) {
          const list = sortingList(
            state.data.InProgress,
            orderBy,
            sortingColumn.inProgress,
            'date'
          )
          draft.data.InProgress = list
        } else if (typ === TypeOfTask.FixFromQC) {
          const list = sortingList(
            state.data.FixFromQC,
            orderBy,
            sortingColumn.fixFromQC,
            'string'
          )

          draft.data.FixFromQC = list
        }
        break

      case getType(actions.getCanSendStatusAction.request):
        draft.canSendStatus = false
        break
      case getType(actions.getCanSendStatusAction.success):
        draft.canSendStatus = action.payload.ResponseData.Status || false
        break
      case getType(actions.sendToQCAction.request):
        draft.actionEvent = cloneDeep(initEvent)
        draft.actionEvent.IsLoading = true
        break
      case getType(actions.sendToQCAction.success):
        draft.actionEvent.IsLoading = false
        draft.actionEvent.SubmitError = undefined
        draft.actionEvent.SubmitSuccess = true
        draft.actionEvent.MessageSuccess = 'ทำรายการสำเร็จ'
        break

      case getType(actions.getCanSendStatusAction.failure):
      case getType(actions.sendToQCAction.failure):
        draft.actionEvent.IsLoading = false
        draft.actionEvent.SubmitError = parseErrorMessage(action.payload)
        break

      default:
        return state
    }
  })
}
