import React, { useState, useEffect } from 'react'
import { RootState } from 'typings/RootState'
import { connect, useDispatch } from 'react-redux'
import { translate } from 'i18n'
import TKeys from 'i18n/translationKey'
import { withRouter, RouteComponentProps } from 'react-router-dom'
import { Dropdown } from 'antd'
import styled from 'styled-components'

import {
  userEmailSelector,
  serviceTokenSelector,
} from 'features/sensitive/sensitive.selector'
import { fetchWbsListRequest } from 'features/wbs/wbs.api'

import {
  selectList,
  selectProjectCode,
  selectEvent,
} from 'features/approval/dashboard/selector'
import { getDashboard } from 'features/approval/dashboard/api'
import { setProjectCode } from 'features/approval/dashboard/actions'
import Loading from 'Components/Shared/Loading'
import projectHelper from 'utils/projectHelper'

type Props = ReduxProps & DispatchProps & RouteComponentProps

const projectEmpty = translate(TKeys.Dashboard.project_list_empty_message)

const ProjectSelector: React.FunctionComponent<Props> = ({
  ProjectList,
  Event,
  Token,
  UserEmail,
  history,
}) => {
  const dispatch = useDispatch()
  const ProjectCode = `${projectHelper.getProjectCode()}`

  useEffect(() => {
    dispatch(getDashboard(Token, { UserEmail }))
  }, [dispatch, Token, UserEmail])

  useEffect(() => {
    dispatch(setProjectCode(ProjectCode))
  }, [ProjectCode])

  const onProjectCode = (code: string) => {
    projectHelper.saveProjectCode(code)
    history.push('/ms/list')
  }

  const getProjectName = (project: any) =>
    `${project.ProjectCode} - ${project.ProjectName}`

  const projectList = ProjectList.map((project, index) => {
    const isActive = project.ProjectCode === ProjectCode

    return (
      <ProjectListItems
        key={index}
        onClick={e => onProjectCode(project.ProjectCode)}
        className={isActive ? 'active' : ''}
      >
        {getProjectName(project)}
        <i className="icon-checked" />
      </ProjectListItems>
    )
  })

  let content
  if (Event.IsLoading) {
    content = <Loading color="white" />
  } else if (projectList.length === 0) {
    content = projectEmpty
  } else {
    const index =
      ProjectCode !== ''
        ? ProjectList.findIndex(it => it.ProjectCode === ProjectCode)
        : -1

    content =
      index === -1
        ? translate(TKeys.Dashboard.project_select)
        : getProjectName(ProjectList[index])
  }

  const projectMenu = <ProjectListContent>{projectList}</ProjectListContent>

  return (
    <Dropdown overlay={projectMenu} trigger={['click']}>
      <div className="projects-list-wrapper">
        <div className="projects-list-dropdown">
          <span>
            {content}
            <img src="/ic-down-white.svg" alt="Project Lists" />
          </span>
        </div>
      </div>
    </Dropdown>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    UserEmail: userEmailSelector(state),
    Token: serviceTokenSelector(state),
    ProjectList: selectList(state),
    Event: selectEvent(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

const ProjectListContent = styled.div`
  width: 420px;
  height: auto;
  max-height: 420px;
  overflow-x: scroll;
  border-radius: 5px;
  background: #fff;
  padding: 16px 24px 8px;
  box-shadow: 0 1px 4px 0 #ccc;
  border-top: none;
  margin-top: -7px;
`

const ProjectListItems = styled.div`
  color: #000;
  line-height: normal;
  height: 40px;
  font-family: 'DB Helvethaica X 55 Regular';
  font-size: 18px;
  &:hover {
    cursor: pointer;
    color: #003168;
  }
  .icon-checked {
    display: none;
  }
  &.active {
    font-family: 'DB Helvethaica X 75 Bd';
    .icon-checked {
      display: inline;
    }
  }
`

type DispatchProps = typeof mapDispatchToProps

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ProjectSelector))
