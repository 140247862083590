import React from 'react'
import { Row, Select } from 'antd'
import { translate } from 'i18n'
import TKeys from 'i18n/translationKey'

const { Option } = Select

type Props = {
  defaultValue: number
  totalRows: number
  choices?: number[]
  onChange: (value: number) => void
}

const PaginationShowMore: React.FunctionComponent<Props> = ({
  totalRows,
  defaultValue,
  choices = [500],
  onChange,
}) => {
  // No point in rendering dropdown that has no choices yet.
  if (!choices.length) {
    return null
  }

  return (
    <Row className="pagination-show-action">
      {translate(TKeys.Pagination.show)}
      <Select
        defaultValue={defaultValue}
        style={{ width: 'auto', margin: '0 16px' }}
        onChange={onChange}
        className="pagination-show-more-options"
      >
        {choices.map(page => (
          <Option key={page} className="show-more-option-item" value={page}>
            {page}
          </Option>
        ))}
      </Select>
      {translate(TKeys.Pagination.of)} {totalRows}{' '}
      {translate(TKeys.Pagination.entries)}
    </Row>
  )
}

export default PaginationShowMore
