import React from 'react'
import styled from 'styled-components'
import { DropdownList, DropdownPopupMenu } from 'Components/Shared/Form'
import QCTypeContent from 'Components/Shared/Filters/QCTypeContent'

type Props = {
  onFilterChange: (filter: any) => void
  filter: any
  qctypeList: any
}

const QcTypeFilter: React.FunctionComponent<Props> = props => {
  const { onFilterChange, filter, qctypeList } = props

  const getSelectedNames = () => {
    const names = filter.QCTypeList.map((id: string) => {
      const qctype =
        qctypeList && qctypeList.List.find((it: any) => it.QCTypeID == id)
      return qctype ? qctype.QCTypeName : id
    })
    return names.join(',')
  }

  return (
    <Box>
      <DropdownPopupMenu
        label={'หมวดงาน'}
        placeholder="ทั้งหมด"
        value={getSelectedNames()}
        content={
          <QCTypeContent
            qctypes={qctypeList.List}
            filter={filter.QCTypeList}
            onChange={data => {
              filter.QCTypeList = data
              onFilterChange(filter)
            }}
          />
        }
        isActive={filter.QCTypeList.length > 0}
      />
    </Box>
  )
}

const Box = styled.div`
  padding: 0 12px 12px 0;
`

export default QcTypeFilter
