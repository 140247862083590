import React from 'react'
import { BreadcrumbLink } from 'Components/Shared/BreadCrumb/BreadCrumb'
import SubnavBar from 'Components/Shared/SubnavBar'

type Props = {
  title?: string
  name?: string
  path: string
  onBack?: () => void
}

const Breadcrumbs: React.ComponentType<Props> = props => {
  const { name, path, title = 'รายการแก้ไข', onBack } = props

  const breadcrumbs: BreadcrumbLink[] = [
    {
      url: '/zone/dashboard',
      title: 'Home',
      icon: '/home.png',
      active: true,
      showTitle: false,
    },
    {
      url: '/zone/dashboard',
      title: 'ส่วนกลาง',
      active: true,
      showTitle: true,
    },
  ]

  if (name) {
    breadcrumbs.push({
      url: '',
      title: title,
      active: true,
      showTitle: true,
      onClick: onBack,
    })

    breadcrumbs.push({
      url: '',
      title: name,
      active: false,
      showTitle: false,
    })
  } else {
    breadcrumbs.push({
      url: '/',
      title: title,
      active: false,
      showTitle: false,
    })
  }

  return <SubnavBar breadcrumbs={breadcrumbs} hideSearch={true} />
}

export default Breadcrumbs
