import withAPIAction from 'utils/withAPIAction'
import {
  getPMRListAction,
  getReportAction,
  updateDefectAction,
  setGroupDefectAction,
  confirmGroupDefectAction,
} from './actions'
import {
  GetPMRReq,
  GetReportReq,
  UpdateDefectReq,
  SetGroupPMRDefectReq,
  ConfirmGroupPMRDefectReq,
} from './types'
import { verifyReqNoProject, verifyReq } from 'utils/verify'

export const GetPMRList = (token: string, data: GetPMRReq) => {
  const isValid = verifyReqNoProject(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<GetPMRReq>({
    method: 'POST',
    path: 'GetPMRList',
    data,
    token: `Bearer ${token}`,
  })(getPMRListAction)
}

export const GetPMRReport = (token: string, data: GetReportReq) => {
  const isValid = verifyReqNoProject(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<GetReportReq>({
    method: 'POST',
    path: 'GetPMRReportById',
    data,
    token: `Bearer ${token}`,
  })(getReportAction)
}

export const UpdatePMRDefect = (token: string, data: UpdateDefectReq) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<UpdateDefectReq>({
    method: 'POST',
    path: 'UpdatePMRDefect',
    data,
    token: `Bearer ${token}`,
  })(updateDefectAction)
}

export const SetGroupPMRDefect = (
  token: string,
  data: SetGroupPMRDefectReq
) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<SetGroupPMRDefectReq>({
    method: 'POST',
    path: 'SetGroupPMRDefect',
    data,
    token: `Bearer ${token}`,
  })(setGroupDefectAction)
}

export const ConfirmGroupDefectForPMR = (
  token: string,
  data: ConfirmGroupPMRDefectReq
) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<ConfirmGroupPMRDefectReq>({
    method: 'POST',
    path: 'ConfirmGroupDefectForPMR',
    data,
    token: `Bearer ${token}`,
  })(confirmGroupDefectAction)
}
