import React, { useState } from 'react'
import styled from 'styled-components'
import { Row, Col, Layout } from 'antd'
import { PrimaryButton, SecondaryButton } from 'Components/Shared/Button'
const { Footer: FooterPage } = Layout

type Props = {
  isLoading?: boolean
  visible?: boolean
  onSendReqToQC: () => void
}

const Footer: React.ComponentType<Props> = props => {
  const { isLoading = false, visible = false, onSendReqToQC } = props

  if (visible === false) return <></>

  return (
    <FooterPage className="detail-page-footer">
      <FooterView>
        <Row type="flex" justify="space-between" align="middle">
          <Col />
          <Col>
            <Button>
              <PrimaryButton
                text="ส่งคำขอแก้ไข"
                disabled={isLoading}
                onClick={onSendReqToQC}
              />
            </Button>
          </Col>
        </Row>
      </FooterView>
    </FooterPage>
  )
}

const FooterView = styled.div`
  max-width: 1110px;
  color: rgb(0, 0, 0);
  font-size: 20px;
  padding-right: 20px;
  margin: 0px auto;
`

const Button = styled.div`
  width: 200px;
  margin-left: 16px;
  float: left;
`

const Span = styled.span`
  font-size: 20px;
  font-family: 'DB Helvethaica X 55 Regular';
  color: #000;
  margin-right: 12px;
`

export default Footer
