import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import { InputSearch } from 'Components/Shared/Input'
import { getCompanyVandorList } from 'features/admin/user/api'
import { GetFilterListReq, CompanyVandorItem } from 'features/admin/user/types'
import {
  selectCompanyVandorList,
  selectVendorEvent,
} from 'features/admin/user/selector'
import { connect, useDispatch } from 'react-redux'
import RootState from 'typings/RootState'
import {
  serviceTokenSelector,
  userEmailSelector,
} from 'features/sensitive/sensitive.selector'
import { LoadingIcon } from 'Components/Shared/Icon'

type Props = {
  onSave: (user: CompanyVandorItem) => void
  placeholder?: string
  clearValue?: boolean
} & ReduxProps

const UserBox: React.ComponentType<Props> = ({
  onSave,
  token,
  UserEmail,
  userList,
  event,
  placeholder = 'ค้นหา Vendor ด้วยชื่อ หรือ Code',
  clearValue = false,
}) => {
  const [searchText, setSearchText] = useState('')
  const [query, setQuery] = useState('')

  useEffect(() => {
    onFindUser()
  }, [searchText])

  useEffect(() => {
    if (clearValue) {
      setQuery('')
    }
  }, [clearValue])

  useEffect(() => {
    const timeOutId = setTimeout(() => setSearchText(query), 1000)
    return () => clearTimeout(timeOutId)
  }, [query])

  const dispatch = useDispatch()

  const onFindUser = () => {
    const req: GetFilterListReq = {
      UserEmail,
      TextFilter: searchText,
    }
    dispatch(getCompanyVandorList(token, req))
  }

  const renderUserDataFormat = (item: CompanyVandorItem) => {
    let content = `${item.VendorCode} - ${item.VendorName}`
    return content
  }

  const renderSearchContent = (data: CompanyVandorItem[]) => {
    const loading = (
      <ContentBody>
        <LoadingIcon />
      </ContentBody>
    )
    const empty = (
      <ContentBody>
        <Txt>ไม่มีข้อมูล</Txt>
      </ContentBody>
    )

    const emptySearch = (
      <ContentBody>
        <Txt>ระบุคำที่ต้องการค้นหา อย่างน้อย 2 ตัวอักษร</Txt>
      </ContentBody>
    )

    const content =
      data && data.length > 0 ? (
        <SearchContentArea>
          {data.map((item, index) => {
            const data = renderUserDataFormat(item)
            return (
              <SearchItem
                key={index}
                onClick={() => {
                  setQuery(item.VendorName)
                  onSave(item)
                }}
              >
                {data}
              </SearchItem>
            )
          })}
        </SearchContentArea>
      ) : query.length < 2 ? (
        emptySearch
      ) : (
        empty
      )

    return event.IsLoading ? loading : content
  }

  return (
    <InputSearch
      placeholder={placeholder}
      value={query}
      onChange={(val: string) => setQuery(val)}
      showSuggess={true}
      searchContent={renderSearchContent(userList)}
    />
  )
}

const SearchContentArea = styled.div`
  max-height: 192px;
  overflow: scroll;
`

const SearchItem = styled.div`
  padding: 8px 24px;
  font-size: 20px;
  font-family: 'DB Helvethaica X 55 Regular';
  &:hover {
    background-color: #e6f7ff;
    cursor: pointer;
  }
`

const ContentBody = styled.div`
  width: 100%;
  text-align: center;
  padding: 70px 0;
  height: 192px;
`

const Txt = styled.div`
  font-size: 20px;
  font-family: 'DB Helvethaica X 55 Regular';
`

const mapStateToProps = (state: RootState) => {
  return {
    token: serviceTokenSelector(state),
    UserEmail: userEmailSelector(state),
    userList: selectCompanyVandorList(state),
    event: selectVendorEvent(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(UserBox)
