import { createStandardAction, createAsyncAction } from 'typesafe-actions'
import {
  ListReq,
  MasterDataReq,
  MasterDataReqByProject,
  GetModelTypeListResp,
  GetUnitListResp,
  MasterReqByPrimavera,
  GetPrimaveraListResp,
  GetProjectTypeListResp,
  GetUnitListReq,
} from './types'

export const getModelTypeList = createAsyncAction(
  'masterData/modelType/GET_MODEL_TYPE_REQ',
  'masterData/modelType/GET_MODEL_TYPE_RESP',
  'masterData/modelType/GET_MODEL_TYPE_FAIL'
)<ListReq, GetModelTypeListResp, any>()

export const getUnitListAction = createAsyncAction(
  'masterData/unit/GET_UNIT_REQ',
  'masterData/unit/GET_UNIT_RESP',
  'masterData/unit/GET_UNIT_FAIL'
)<GetUnitListReq, GetUnitListResp, any>()

export const getPrimaveraActivityList = createAsyncAction(
  'masterData/primavera/GE_REQ',
  'masterData/primavera/GET_RESP',
  'masterData/primavera/GET_FAIL'
)<MasterReqByPrimavera, GetPrimaveraListResp, any>()

export const resetPrimaveraActivityList = createStandardAction(
  'masterData/primavera/RESET'
)<void>()

export const getProjectTypeList = createAsyncAction(
  'masterData/projectType/GET_REQ',
  'masterData/projectType/GET_RESP',
  'masterData/projectType/GET_FAIL'
)<ListReq, GetProjectTypeListResp, any>()

export const getQcWorkAction = createAsyncAction(
  'masterData/QcWork/GE_REQ',
  'masterData/QcWork/GET_RESP',
  'masterData/QcWork/GET_FAIL'
)<any, any, any>()
