import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'

import { Layout } from 'antd'
import TopNav from 'Components/Shared/TopNav'
import WelcomePage from 'Components/Admin/Welcome/AdminQC'
import CheckUnitList from 'Components/AdminQC/CheckUnit/List'
import CheckUnitDetail from 'Components/AdminQC/CheckUnit/Detail'
import ModelListPage from 'Components/AdminQC/Model/List'
import FloorPlanListPage from 'Components/AdminQC/FloorPlan/List'
import QCCheckDocumentDetail from 'Components/AdminQC/CheckDocument/Detail'
import QCCheckFunction from 'Components/AdminQC/CheckFunction/List'
import QCCheckFunctionDetail from 'Components/AdminQC/CheckFunction/Detail'
import CheckZone from 'Components/AdminQC/CheckZone/List'
import CheckZoneDetail from 'Components/AdminQC/CheckZone/Detail'
import CheckCommissionList from 'Components/AdminQC/CheckCommission/List'
import CheckCommissionDetail from 'Components/AdminQC/CheckCommission/Detail'

import ConstructPlanList from 'Components/AdminQC/CheckConstructPlan/List'
import ConstructPlanDetail from 'Components/AdminQC/CheckConstructPlan/Detail'

import AuditPlanList from 'Components/AdminQC/CheckAuditPlan/List'
import AuditPlanDetail from 'Components/AdminQC/CheckAuditPlan/Detail'

import ReportCommissionList from 'Components/AdminQC/Report/Zone/Commissioning/List'

import RoadConstructList from 'Components/AdminQC/CheckConstructRoad/List'
import RoadConstructDetail from 'Components/AdminQC/CheckConstructRoad/Detail'

import AuditRoadList from 'Components/AdminQC/CheckAuditRoad/List'
import AuditRoadDetail from 'Components/AdminQC/CheckAuditRoad/Detail'
import UnitPage from 'Components/AdminQC/Unit'
import BackwardUnitListPage from 'Components/AdminQC/BackwardUnit'

import QcWork from 'Components/AdminQC/QcWork'
import RequestHistory from 'Components/AdminQC/History/List'

import AdminQCReportApp from './AdminQCReportApp'
import TokenExpired from 'Components/Pages/TokenExpired'

type Props = {}

const AdminQCApp: React.FunctionComponent<Props> = () => {
  return (
    <BrowserRouter>
      <Layout className="layout" hasSider={false}>
        <TopNav title="ADMIN QC" displayProject={false} />
        <Route exact path="/" component={WelcomePage} />

        <Switch>
          <Route path="/report" component={AdminQCReportApp} />
          <Route exact path="/dashboard" component={WelcomePage} />
          <Route exact path="/model" component={ModelListPage} />
          <Route exact path="/qcwork" component={QcWork} />
          <Route exact path="/backward-unit" component={BackwardUnitListPage} />

          <Route exact path="/utilities/history" component={RequestHistory} />

          <Route exact path="/check-unit" component={CheckUnitList} />
          <Route
            exact
            path="/check-unit/:id/edit"
            component={CheckUnitDetail}
          />
          <Route exact path="/check-unit/create" component={CheckUnitDetail} />

          <Route
            exact
            path="/check-document"
            component={QCCheckDocumentDetail}
          />

          <Route exact path="/check-function" component={QCCheckFunction} />
          <Route
            exact
            path="/check-function/create"
            component={QCCheckFunctionDetail}
          />
          <Route
            exact
            path="/check-function/:id/edit"
            component={QCCheckFunctionDetail}
          />

          <Route exact path="/floorplan" component={FloorPlanListPage} />
          <Route exact path="/unit" component={UnitPage} />

          <Route exact path="/check-zone" component={CheckZone} />
          <Route
            exact
            path="/check-zone/:id/edit"
            component={CheckZoneDetail}
          />
          <Route exact path="/check-zone/create" component={CheckZoneDetail} />

          <Route
            exact
            path="/check-commission"
            component={CheckCommissionList}
          />
          <Route
            exact
            path="/check-commission/create/:type"
            component={CheckCommissionDetail}
          />
          <Route
            exact
            path="/check-commission/:id/edit/:type"
            component={CheckCommissionDetail}
          />

          <Route
            exact
            path="/check-construct-plan"
            component={ConstructPlanList}
          />
          <Route
            exact
            path="/check-construct-plan/:id/edit"
            component={ConstructPlanDetail}
          />
          <Route
            exact
            path="/check-construct-plan/create"
            component={ConstructPlanDetail}
          />

          <Route exact path="/check-audit-plan" component={AuditPlanList} />
          <Route
            exact
            path="/check-audit-plan/:id/edit"
            component={AuditPlanDetail}
          />
          <Route
            exact
            path="/check-audit-plan/create"
            component={AuditPlanDetail}
          />

          <Route
            exact
            path="/report-commissioning"
            component={ReportCommissionList}
          />

          <Route
            exact
            path="/check-construct-road"
            component={RoadConstructList}
          />
          <Route
            exact
            path="/check-construct-road/:id/edit"
            component={RoadConstructDetail}
          />
          <Route
            exact
            path="/check-construct-road/create"
            component={RoadConstructDetail}
          />

          <Route exact path="/check-audit-road" component={AuditRoadList} />
          <Route
            exact
            path="/check-audit-road/:id/edit"
            component={AuditRoadDetail}
          />
          <Route
            exact
            path="/check-audit-road/create"
            component={AuditRoadDetail}
          />

          <Route exact path="/token-expired" component={TokenExpired} />
        </Switch>
      </Layout>
    </BrowserRouter>
  )
}

export default AdminQCApp
