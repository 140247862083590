import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, connect } from 'react-redux'
import RootState from 'typings/RootState'
import styled from 'styled-components'
import { Row, Col } from 'antd'
import moment, { Moment } from 'moment-timezone'
import { RouteComponentProps } from 'react-router-dom'

import { BreadcrumbLink } from 'Components/Shared/BreadCrumb/BreadCrumb'
import SubnavBar from 'Components/Shared/SubnavBar'
import EventModal from 'Components/Shared/Modal/EventModal'
import { PrimaryButton } from 'Components/Shared/Button'

import XLSX from 'xlsx'

import { getQcWork } from 'features/masterData/api'
import { selectEvent, selectQcWork } from 'features/masterData/selector'

import Menu from '../../MenuAdmin'

const breadcrumbs: BreadcrumbLink[] = [
  {
    url: '/dashboard',
    title: 'Home',
    icon: '/home.png',
    active: true,
    showTitle: false,
  },
  {
    url: '/qcwork',
    title: 'QC Work',
    active: false,
    showTitle: true,
  },
]

type Props = ReduxProps & DispatchProps & RouteComponentProps

const UnitPage: React.ComponentType<Props> = props => {
  const { qcWork, event } = props

  const [enableModal, setEnableModal] = useState(false)

  const dispatch = useDispatch()

  const onGetQcWork = () => {
    setEnableModal(true)
    props.getQcWork()
  }

  const countWork = (list: any) => {
    return list.length === 0 ? '' : list.length
  }

  const getLine = (
    index: number,
    num: any,
    project: string,
    detail: string
  ) => {
    const list: any[] = ['', '', '', '', '', '', '', '', '']

    list[index] = num
    list[7] = project
    list[8] = detail
    return list
  }

  const getDetail = (list: any = []) => {
    return list.map((it: any) => it.Name).join()
  }

  const onExport = () => {
    const names: any = [
      'ยูนิต',
      'ส่วนกลาง',
      'Commissioning/Safety',

      'Site Visit',
      'เปรียบเทียบแบบจัดสรร',
      'ถนนระหว่างก่อสร้าง',

      'รับมอบ/ก่อนหมดประกัน',
      'โครงการ',
      'รายละเอียด',
    ]

    // number of characters
    const wch = 9
    const wch_2 = 25
    const wscols = [
      { wch },
      { wch },
      { wch: wch_2 },

      { wch },
      { wch: wch_2 },
      { wch: wch_2 },

      { wch: wch_2 },
      { wch: 35 },
      { wch: 100 },
    ]
    const now: string = moment().format('DD MMMM YYYY hh:mm')
    const input: any = []
    input.push(['สรุปการจัดทีมเข้าตรวจสอบคุณภาพ'])
    input.push([`ประจำวันที่ ${now}`])
    input.push(names)

    for (const ele of qcWork.ProjectList) {
      const { ProjectCode = '', ProjectName = '' } = ele
      const project: string = `${ProjectCode} - ${ProjectName}`

      const unit = getLine(
        0,
        countWork(ele.UnitList),
        project,
        getDetail(ele.UnitList)
      )
      input.push(unit)

      const zone = getLine(
        1,
        countWork(ele.ZoneList),
        '',
        getDetail(ele.ZoneList)
      )
      input.push(zone)

      const commission = getLine(
        2,
        countWork(ele.CommissionList),
        '',
        getDetail(ele.CommissionList)
      )
      input.push(commission)

      const siteConstruct = getLine(
        3,
        countWork(ele.SiteVisitList),
        '',
        getDetail(ele.SiteVisitList)
      )
      input.push(siteConstruct)

      const siteAudit = getLine(
        4,
        countWork(ele.SiteVisitAuditList),
        '',
        getDetail(ele.SiteVisitAuditList)
      )
      input.push(siteAudit)

      const roadConstruct = getLine(
        5,
        countWork(ele.RoadList),
        '',
        getDetail(ele.RoadList)
      )
      input.push(roadConstruct)

      const roadAudit = getLine(
        6,
        countWork(ele.RoadAuditList),
        '',
        getDetail(ele.RoadAuditList)
      )
      input.push(roadAudit)
    }

    const ws = XLSX.utils.aoa_to_sheet(input)
    const ws_name = 'QC Work List'

    ws['!cols'] = wscols

    const wb = XLSX.utils.book_new()

    XLSX.utils.book_append_sheet(wb, ws, ws_name)
    XLSX.writeFile(wb, `qcwork ${now}.xlsx`)
    setEnableModal(false)
  }

  const closeEnableModal = () => setEnableModal(false)

  return (
    <>
      <EventModal
        event={[event]}
        enable={enableModal}
        successCalback={onExport}
        errorCalback={closeEnableModal}
      />
      <SubnavBar breadcrumbs={breadcrumbs} hideSearch={true} />
      <Menu selected={'qc work'}>
        <MainPage>
          <p>รายการ QC Work</p>
          <Row>
            <Col xl={6} lg={8} xs={24}>
              <PrimaryButton text="Download" onClick={onGetQcWork} />
            </Col>
          </Row>
        </MainPage>
      </Menu>
    </>
  )
}

const MainPage = styled.div`
  padding: 40px 45px;
  width: 100%;
  height: calc(100vh - 115px);
`

const mapStateToProps = (state: RootState) => {
  return {
    event: selectEvent(state),
    qcWork: selectQcWork(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>
const mapDispatchToProps = {
  getQcWork,
}

type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(UnitPage)
