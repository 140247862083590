import RootState from 'typings/RootState'
import { createSelector } from 'reselect'
import { Task } from 'features/tasklane/tasklane.types'

export const rawResponseDataSelector = (state: RootState) =>
  state.search.rawResponseData

export const getSearchSelector = (state: RootState) => state.search

export const getActivityFromSearchSelector = createSelector(
  rawResponseDataSelector,
  (rawResponse: ApiResponse<Task[]>): Task[] => {
    const data: Task[] = rawResponse.ResponseData
    // TODO: Fix when search result page is refreshed, all data are gone
    return data
  }
)

export const getSearchDataSelector = createSelector(
  getSearchSelector,
  search => search.list
)

export const isSubmittingSearchResultSelector = createSelector(
  getSearchSelector,
  search => search.isSubmitting
)

export const getSearchPaginationQuery = createSelector(
  getSearchSelector,
  search => search.searchPaginationQuery
)

export const getTotalRows = createSelector(
  getSearchSelector,
  search => search.totalRows
)

export const searchResultError = createSelector(
  getSearchSelector,
  search => search.submitError
)

export const getSearchSortingSelector = (state: RootState): any =>
  state.search.sorting
