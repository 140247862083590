import React from 'react'
import { Tab } from 'Components/Shared'

interface Props {
  selected: 'Zone' | 'Commissioning' | 'Safety'
}

const CommonAreaTab: React.FunctionComponent<Props> = props => {
  const tabItem = [
    {
      link: '/zone/dashboard',
      title: 'ส่วนกลางทั่วไป',
      isActive: props.selected == 'Zone',
    },
    {
      link: '/commissioning/dashboard',
      title: 'Commissioning & Safety',
      isActive: props.selected == 'Commissioning',
    },
  ]

  return <Tab tabItem={tabItem} />
}

export default CommonAreaTab
