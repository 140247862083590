export enum APIPath {
  example = 'v1/example',
  login = 'v1/login',
  logout = 'v1/logout',
  getProjectAuthen = 'GetProjectAuthen',
  activity = 'GetActivity',
  GetActivity = 'GetActivity',
  saveActivity = 'SaveActivity',
  uploadFileActivity = 'UploadFiles',
  getUserProfile = 'GetUserProfile',
  getWbsList = 'GetWBSList',
  getActivitySeeMore = 'GetActivitySeeMore',
  getActivityLogs = 'GetActivityLogs',
  getActivityFilter = 'GetActivityFilter',
  getSearchActivity = 'GetSearchActivity',
  getActivityById = 'GetActivityById',

  GetUnitCheckListById = 'GetUnitCheckListById',
  CreateUnitCheckList = 'CreateUnitCheckList',
  UpdateUnitCheckList = 'UpdateUnitCheckList',
  UpdateUnitByUnitCheckList = 'UpdateUnitByUnitCheckList',
  DeleteUnitCheckList = 'DeleteUnitCheckList',

  CreateModel = 'CreateModel',
  UpdateModel = 'UpdateModel',
  DeleteModel = 'DeleteModel',

  CreateFunctionCheckList = 'CreateFunctionCheckList',
  UpdateFunctionCheckList = 'UpdateFunctionCheckList',
  DeleteFunctionCheckList = 'DeleteFunctionCheckList',

  DeleteMainconCheckList = 'DeleteMainconCheckList',
  GetMasterMainconCheckList = 'GetMasterMainconCheckList',
  ImportMasterMainconCheckList = 'ImportMasterMainconCheckList',

  CreateFloorPlan = 'CreateFloorPlan',
  UpdateFloorPlan = 'UpdateFloorPlan',
  DeleteFloorPlan = 'DeleteFloorPlan',

  DeleteZoneCheckList = 'DeleteZoneCheckList',
  CreateZoneCheckList = 'CreateZoneCheckList',
  UpdateZoneCheckList = 'UpdateZoneCheckList',

  GetCommissioningCheckListById = 'GetCommissioningCheckListById',
  CreateCommissioningCheckList = 'CreateCommissioningCheckList',
  UpdateCommissioningCheckList = 'UpdateCommissioningCheckList',
  UpdateActivityByCommissioning = 'UpdateActivityByCommissioning',
  DeleteCommissioningCheckList = 'DeleteCommissioningCheckList',

  SendConstructRoadToQC = 'SendConstructRoadToQC',
}
