import React from 'react'
import styled from 'styled-components'
import {
  InProgressActivity,
  UnitList,
  ProjectList,
} from 'features/qc/dashboard/types'
import { Row, Col } from 'antd'
import { DocumentIcon, PlanIcon } from 'Components/Shared/Icon'
import { Link } from 'react-router-dom'
import ProjectBox from '../ProjectBox'
import LeftBoxContent from '../LeftBoxContent'
import ActivityScore from '../ActivityScore'
import CheckIconBox from '../CheckIconBox'
import SignDescription from '../SignDescription'
import {
  getQCType,
  getQCTypeVersionForQC,
  getQCTypeVersion,
} from 'utils/getText'

interface Props {
  type?: string
  task: InProgressActivity
  isSendReport?: boolean
  onClickReturn: (unit: UnitList) => void
  projectList: ProjectList[]
  onSelectLists: (projectCode: string, listType: string, unit: UnitList) => void
}

const InprogressCard: React.FC<Props> = props => {
  const {
    task,
    isSendReport = false,
    onClickReturn,
    onSelectLists,
    projectList,
  } = props
  const { ProjectCode, ProjectNameTH, UnitList } = task
  const defindType = {
    doc: 'document',
    checklist: 'checklist',
  }

  const isValidCorrect = (value: boolean) => (value ? true : false)

  const isSelected = (type: string, unit: UnitList) => {
    let isSelect = false
    projectList.forEach(project => {
      if (project && project.ProjectCode === ProjectCode) {
        project.TaskList.forEach(task => {
          if (task.Unit === unit.Unit && getQCType(task) === getQCType(unit)) {
            if (type === defindType.doc) {
              isSelect = task.DocumentStatus === 'Y'
            }
            if (type === defindType.checklist) {
              isSelect = task.CheckListStatus === 'Y'
            }
          }
        })
      }
    })
    return isSelect
  }

  const renderInspectionCheck = (unit: UnitList) => {
    if (unit.SentChecklist) {
      return <></>
    }
    const isCanCheck = unit.HasImage && unit.HasSign
    const unitInfo = (
      <Row type="flex" justify="space-between" align="top">
        <Col>
          <Row type="flex" align="middle">
            <Col>
              {isSendReport &&
                (isCanCheck ? (
                  <CheckIconBox
                    onClick={() =>
                      onSelectLists(
                        task.ProjectCode,
                        defindType.checklist,
                        unit
                      )
                    }
                    isSelected={isSelected(defindType.checklist, unit)}
                  />
                ) : (
                  <Space />
                ))}
            </Col>
            <Col>
              <PlanIcon size={40} />
            </Col>
            <Col>
              <BoxScore>
                <Title>ตรวจหน้างาน</Title>
                <SignDescription
                  isCorrect={isValidCorrect(unit.HasSign)}
                  label="ลงชื่อรับทราบ"
                />
                <SignDescription
                  isCorrect={isValidCorrect(unit.HasImage)}
                  label="รูปส่งรายงาน"
                />
              </BoxScore>
            </Col>
          </Row>
        </Col>
        <Col>
          <ActivityScore
            baseScore={unit.BasePoint}
            score={unit.InspectionScore}
            type="checklist"
            status={unit.CheckStatus}
          />

          <InspectText>
            {unit.CheckStatus != 'NotInspection' ? '' : '[ ไม่ประเมินคะแนน ]'}
          </InspectText>
        </Col>
      </Row>
    )

    return (
      <div className="score">
        {isSendReport ? (
          unitInfo
        ) : (
          <Link
            to={`/unit/report/${task.ProjectCode}/${unit.Unit}/${unit.QCTypeID}/${unit.RequestID}`}
          >
            {unitInfo}
          </Link>
        )}
      </div>
    )
  }

  const renderDocumentCheck = (unit: UnitList) => {
    if (unit.SentDocument) {
      return <></>
    }
    const { Unit, QCTypeID, QCTypeVersion, RequestID, CheckNumber } = unit
    const info = (
      <Row type="flex" justify="space-between" align="middle">
        <Col>
          <Row type="flex" align="middle">
            <Col>
              {isSendReport && (
                <CheckIconBox
                  onClick={() =>
                    onSelectLists(task.ProjectCode, defindType.doc, unit)
                  }
                  isSelected={isSelected(defindType.doc, unit)}
                />
              )}
              <DocumentIcon size={40} />
            </Col>
            <Col>
              <BoxScore>
                <Title>ตรวจเอกสาร</Title>
              </BoxScore>
            </Col>
          </Row>
        </Col>
        <Col>
          <ActivityScore
            baseScore={unit.BasePoint}
            score={unit.DocumentScore}
            type="document"
          />
        </Col>
      </Row>
    )

    const version = unit.QCTypeVersion ? unit.QCTypeVersion : unit.QCTypeID
    return (
      <div className="score">
        {isSendReport ? (
          info
        ) : (
          <Link
            to={`/unit/document/${task.ProjectCode}/${Unit}/${QCTypeID}/${RequestID}/${CheckNumber}/${version}?reset=yes`}
          >
            {info}
          </Link>
        )}
      </div>
    )
  }

  const getLeftBoxTitle = (unit: UnitList) => {
    return (
      <>
        <p>{unit.ModelName}</p>
        <p>{unit.UnitDisplay ?? '-'}</p>
        <p>{getQCTypeVersionForQC(unit)}</p>
      </>
    )
  }

  const renderUnitList = () => {
    return UnitList.map((unit, index) => {
      const className = index % 2 === 1 ? 'higlight' : ''
      const title = getLeftBoxTitle(unit)
      return (
        <ContentBox key={index} className={className}>
          <Row type="flex">
            <Col span={6}>
              <LeftBoxContent
                onClickReturn={() => onClickReturn(unit)}
                titleText={title}
                disabled={unit.SentChecklist || unit.SentDocument}
              />
            </Col>
            <Col span={18}>
              <RightBox>
                {renderInspectionCheck(unit)}
                {renderDocumentCheck(unit)}
              </RightBox>
            </Col>
          </Row>
        </ContentBox>
      )
    })
  }

  const title = `${ProjectCode} - ${ProjectNameTH}`
  const unitList = renderUnitList()

  return <ProjectBox projectTitle={title} children={unitList} />
}

const Title = styled.p`
  font-size: 20px;
  margin-bottom: 0;
  font-family: 'DB Helvethaica X 75 Bd';
  line-height: 1;
  color: #000;
`

const InspectText = styled.p`
  font-size: 20px;
  margin-bottom: 0;
  font-family: 'DB Helvethaica X 75 Bd';
  line-height: 1;
  color: #20a1ef;
`

const ContentBox = styled.div`
  height: 160px;
  border-bottom: solid 1px #e4e7eb;
  &.higlight {
    background-color: rgba(0, 49, 104, 0.03);
  }
`

const RightBox = styled.div`
  float: right;
  width: 100%;
  height: 160px;
  display: grid;
  align-items: center;
  .score {
    padding: 12px 32px;
    height: 80px;
    border-bottom: solid 1px #e4e7eb;
  }
  .score:last-child {
    border: none;
  }
`

const BoxScore = styled.div`
  float: right;
  margin-left: 24px;
`

const Space = styled.div`
  width: 40px;
`

export default InprogressCard
