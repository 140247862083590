import React, { useState, forwardRef, useImperativeHandle } from 'react'
import styled from 'styled-components'
import { Modal, Col, Row } from 'antd'

import { CloseIcon } from 'Components/Shared/Icon'
import { ImgCheckbox } from 'Components/Shared/Form'

import { DetailType } from 'features/checklist/unit/types'
import { InputText } from 'Components/Shared/Input'
import { BottomButton } from 'Components/Shared/Button'
import {
  CheckListDetail,
  DefectType,
} from 'features/checklist/constructPlan/types'

export interface ModalDefectHandles {
  showModal: (index: number, item?: DefectType) => void
}

export interface SelectDetailType {
  detail: DetailType
  qCTypeID: string
  jobName: string
  subJobName: string
}

interface Props {
  checkList: CheckListDetail
  onOk: (item: DefectType, index: number) => void
  disabled: boolean
}

const DefectModal = forwardRef<ModalDefectHandles, Props>(
  ({ checkList, onOk, disabled }, ref) => {
    const [visible, setvisible] = useState(false)
    const [openWithFocus, setOpenWithFocus] = useState(true)
    const [isNCR, setIsNCR] = useState(true)
    const [name, setname] = useState('')
    const [error, seterror] = useState('')
    const [currentIndex, setCurrentIndex] = useState(-1)
    const [editingObj, setEditingObj] = useState<DefectType | undefined>(
      undefined
    )

    const closeModal = () => {
      setvisible(false)
      setIsNCR(true)
      setname('')
      seterror('')
      setCurrentIndex(-1)
      setEditingObj(undefined)
      setOpenWithFocus(false)
    }

    useImperativeHandle(ref, () => ({
      showModal: (index: number, item?: DefectType) => {
        setvisible(true)
        setOpenWithFocus(true)
        if (item) {
          setIsNCR(item.IsNCR)
          setname(item.DefectTypeName)
          setEditingObj(item)
          setCurrentIndex(index)
        }
      },
    }))

    const checkTypeOptions = [
      { name: 'เป็น NCR', value: true },
      { name: 'ไม่เป็น NCR', value: false },
    ]

    const checkboxType = checkTypeOptions.map((item, index) => {
      return (
        <Col span={6} key={index}>
          <ImgCheckbox
            isCheck={item.value === isNCR}
            label={item.name}
            onClick={() => setIsNCR(item.value)}
          />
        </Col>
      )
    })

    const onSave = () => {
      if (name) {
        const found = editingObj
          ? null
          : checkList.DefectTypeList.find(v => v.DefectTypeName === name)
        if (found == null) {
          onOk(
            {
              DefectTypeID: editingObj ? editingObj.DefectTypeID : 0,
              DefectTypeName: name,
              IsNCR: isNCR,
            },
            currentIndex
          )
          closeModal()
        } else {
          seterror('ลักษณะ defect นี้ ได้มีอยู่แล้ว')
        }
      } else {
        seterror('กรุณาใส่ข้อมูลทั้งหมด')
      }
    }

    return (
      <ModalCheckList>
        <Modal
          centered
          visible={visible}
          width="528px"
          footer={null}
          closeIcon={<CloseIcon color="#8b99a7" size={16} />}
          title={<Header>{editingObj ? 'แก้ไขรายการ' : 'เพิ่มรายการ'}</Header>}
          className="add-checklist-modal"
          onCancel={closeModal}
        >
          <ModalContent>
            <SelectTypeStyle>
              <Title>เลือกประเภท Defect</Title>
              <CheckBoxSection>
                <Row>{checkboxType}</Row>
              </CheckBoxSection>

              <Box>
                <InputText
                  prefix="ชื่อ"
                  value={name}
                  onChange={setname}
                  placeholder="กรอกชื่อ"
                  openWithFocus={openWithFocus}
                  disabled={disabled}
                />
              </Box>
            </SelectTypeStyle>
            {!disabled && (
              <BottomButton
                closeModal={closeModal}
                onSave={onSave}
                error={error}
              />
            )}
          </ModalContent>
        </Modal>
      </ModalCheckList>
    )
  }
)

const Header = styled.span`
  font-size: 28px;
  color: #000000;
  font-family: 'DB Helvethaica X 75 Bd';
`

const Box = styled.div`
  padding-top: 12px;
`

const Title = styled.span`
  font-size: 20px;
  color: #000000;
  font-family: 'DB Helvethaica X 75 Bd';
`

const ModalCheckList = styled.div`
  .add-checklist-modal {
    .ant-modal-header {
      border-bottom: none;
    }
  }
`

const ModalContent = styled.div`
  padding: 0 24px 31px 24px;
`

const SelectTypeStyle = styled.div`
  padding-top: 17px;
`

const CheckBoxSection = styled.div`
  padding-top: 16px;
`
export default DefectModal
