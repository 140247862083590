import withAPIAction from 'utils/withAPIAction'

import { getCommissionListReq, getActivityByCommissioningReq } from './actions'
import { PaginationType } from 'features/types/common'

export const getCommissionList = (
  page: PaginationType,
  TextFilter: string = ''
) => {
  return withAPIAction<PaginationType>(state => ({
    method: 'POST',
    path: 'GetCommissioningCheckList',
    data: {
      ...page,
      UserEmail: state.sensitive.userEmail,
      TextFilter,
    },
    token: `Bearer ${state.sensitive.apiToken}`,
  }))(getCommissionListReq)
}

export const getActivityByCommissioning = ({
  ProjectCode,
  CheckListType,
}: {
  ProjectCode: string
  CheckListType: number
}) => {
  return withAPIAction(state => ({
    method: 'POST',
    path: 'GetActivityByCommissioning',
    data: {
      ProjectCode,
      CheckListType,
      UserEmail: state.sensitive.userEmail,
    },
    token: `Bearer ${state.sensitive.apiToken}`,
  }))(getActivityByCommissioningReq)
}
