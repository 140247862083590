import React from 'react'
import { Row, Col } from 'antd'
import { translate } from 'i18n'
import TKeys from 'i18n/translationKey'
import CheckAsOptions from './CheckAsOptions'

interface Props {
  filter: string[]
  onChange(filter: string[]): void
  constructList: string[]
}

const ConstructionTypeContent: React.FunctionComponent<Props> = ({
  filter,
  onChange,
  constructList,
}) => {
  const onValueChange = (val: string) => {
    const index = filter.findIndex(item => item === val)
    if (index >= 0) {
      filter.splice(index, 1)
    } else {
      filter.push(val)
    }

    onChange(filter)
  }

  const onClear = () => {
    filter = []
    onChange(filter)
  }

  const content = () => {
    return constructList.map((item, index) => {
      const findIndex = filter.findIndex(it => it == item)
      return (
        <Col span={24} key={index}>
          <CheckAsOptions
            key={item}
            title={item}
            value={findIndex >= 0}
            item={item}
            onChange={onValueChange}
          />
        </Col>
      )
    })
  }

  const clearBtn =
    filter.length > 0 ? (
      <span onClick={onClear} className="filter-clear std-font-regular">
        {translate(TKeys.Common.cancel_all)}
      </span>
    ) : (
      undefined
    )
  return (
    <div className="std-font-regular" style={css.page}>
      <Row>
        <Col span={24}>
          <div className="std-font-bold" style={css.title}>
            {translate(TKeys.Dashboard.type)}
            {clearBtn}
          </div>
        </Col>
      </Row>

      <Row className="pop-over-container-choices">{content()}</Row>
    </div>
  )
}

const css: any = {
  page: {
    width: '296px',
    height: 'auto',
    padding: '25px 24px',
  },
  title: {
    textAlign: 'left',
    color: 'black',
    fontSize: '20px',
    marginBottom: '25px',
  },
  cancel: {
    float: 'right',
    color: '#003168',
    fontSize: '18px',
  },
}

export default ConstructionTypeContent
