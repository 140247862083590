import React, { useMemo } from 'react'
import styled from 'styled-components'
import { Row, Col } from 'antd'
import InputText from 'Components/Shared/Input/InputText'
import DropdownList from 'Components/Shared/Form/DropdownList'
import PrimaryButton from 'Components/Shared/Button/PrimaryButton'

import ProjectsBox from './ProjectsBox'
import { ProjectTypeResp } from 'features/masterData/types'
import { ZoneDetail, ProjectZone } from 'features/admin/zone/types'
import { textToLowerCase } from 'utils/format'

type Props = {
  isActive: boolean
  isEditing: boolean
  projectType: ProjectTypeResp

  onSave: () => void
  duplicateZone: () => void

  zoneDetail: ZoneDetail
  projectZoneList: ProjectZone[]
  updateCheckList: (val: ZoneDetail) => void
}

function transformProjectType(projectType: ProjectTypeResp) {
  return projectType.List.map(item => {
    return {
      value: item.ProjectTypeID,
      name: item.ProjectTypeName,
    }
  })
}

const QCSetting: React.ComponentType<Props> = ({
  isActive,
  // isEditing,
  projectType: projectTypeProps,
  onSave,
  // duplicateZone,
  zoneDetail,
  projectZoneList,
  updateCheckList,
}) => {
  const isUsed = textToLowerCase(zoneDetail.Status) === 'used'
  const projectType = useMemo(() => transformProjectType(projectTypeProps), [
    projectTypeProps,
  ])
  const onNameChange = (val: string) => {
    zoneDetail.CheckListName = val
    updateCheckList(zoneDetail)
  }

  const onTypeChange = (val: number, index: number) => {
    zoneDetail.ProjectTypeID = val
    zoneDetail.ProjectTypeName = projectType[index].name
    updateCheckList(zoneDetail)
  }

  const LeftContent = (
    <LeftSection>
      <BoxSpace>
        <InputText
          prefix="ชื่อ"
          placeholder="กรอกชื่อ"
          value={zoneDetail.CheckListName}
          onChange={onNameChange}
          disabled={isUsed}
        />
      </BoxSpace>
      <BoxSpace>
        <DropdownList
          label="ประเภทโครงการ"
          value={zoneDetail.ProjectTypeID}
          placeholder="กรุณาเลือก"
          menu={projectType}
          onChange={onTypeChange}
          disabled={isUsed}
        />
      </BoxSpace>
    </LeftSection>
  )

  const Rightontent = (
    <RightSection>
      <BoxSpace>
        <ProjectsBox
          zoneDetail={zoneDetail}
          projectZoneList={projectZoneList}
          updateCheckList={updateCheckList}
        />
      </BoxSpace>
    </RightSection>
  )

  return (
    <div style={{ display: isActive ? '' : 'none' }}>
      <Row type="flex" justify="space-between">
        <Col span={12}>{LeftContent}</Col>
        <Col span={12}>{Rightontent}</Col>
      </Row>
      <ButtonSection>
        <Row type="flex" justify="end">
          <Col style={{ width: '200px' }}>
            <PrimaryButton text="บันทึก" onClick={onSave} />
          </Col>
        </Row>
      </ButtonSection>
    </div>
  )
}

const LeftSection = styled.div`
  padding-right: 8px;
`

const RightSection = styled.div`
  padding-left: 8px;
`
const BoxSpace = styled.div`
  margin-bottom: 16px;
`

const ButtonSection = styled.div`
  margin-top: 32px;
`

export default QCSetting
