import withAPIAction from 'utils/withAPIAction'
import {
  sendReportAction,
  getUnitForQCAction,
  getZoneForQCAction,
  getCommissioningForQCAction,
  returnTaskAction,
  sendZoneReportAction,
  returnZoneAction,
  sendCommissReportAction,
  returnCommissioningAction,
  getConstructPlanAction,
  sendConstructReportAction,
  returnConstructAction,
  getAuditPlanAction,
  sendAuditReportAction,
  returnAuditPlanAction,
  getConstructRoadAction,
  sendConstructRoadAction,
  returnConstructRoadAction,
} from './actions'
import {
  GetActivityRequest,
  SendReportRequest,
  ReturnTaskReq,
  ReturnZoneReq,
  GetZoneReq,
  SendZoneReportReq,
  GetCommissioningReq,
  SendCommissioningReportReq,
  ReturnCommissioningReq,
  GetConstructPlanReq,
  SendConstructPlanReportReq,
  ReturnConstructReq,
  GetAuditPlanReq,
  SendAuditPlanReportReq,
  ReturnAuditPlanReq,
  GetConstructRoadReq,
  SendRoadConstructReportReq,
  ReturnRoadConstructReq,
} from './types'
import { verifyReqNoProject } from 'utils/verify'

export const getUnitForQC = (token: string, data: GetActivityRequest) => {
  const isValid = verifyReqNoProject(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<GetActivityRequest>({
    method: 'POST',
    path: 'GetUnitForQC',
    data,
    token: `Bearer ${token}`,
  })(getUnitForQCAction)
}

export const SendReportByQC = (token: string, data: SendReportRequest) => {
  const isValid = verifyReqNoProject(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<SendReportRequest>({
    method: 'POST',
    path: 'SendReportByQC',
    data,
    token: `Bearer ${token}`,
  })(sendReportAction)
}

export const getZoneForQC = (token: string, data: GetZoneReq) => {
  const isValid = verifyReqNoProject(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<GetZoneReq>({
    method: 'POST',
    path: 'GetZoneForQC',
    data,
    token: `Bearer ${token}`,
  })(getZoneForQCAction)
}

export const getCommissioningForQC = (
  token: string,
  data: GetCommissioningReq
) => {
  const isValid = verifyReqNoProject(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<GetCommissioningReq>({
    method: 'POST',
    path: 'GetCommissioningForQC',
    data,
    token: `Bearer ${token}`,
  })(getCommissioningForQCAction)
}

export const ReturnTaskByQC = (token: string, data: ReturnTaskReq) => {
  const isValid = verifyReqNoProject(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<ReturnTaskReq>({
    method: 'POST',
    path: 'ReturnTaskByQC	',
    data,
    token: `Bearer ${token}`,
  })(returnTaskAction)
}

//zone
export const SendZoneReportByQC = (token: string, data: SendZoneReportReq) => {
  const isValid = verifyReqNoProject(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<SendZoneReportReq>({
    method: 'POST',
    path: 'SendZoneReportByQC',
    data,
    token: `Bearer ${token}`,
  })(sendZoneReportAction)
}

export const ReturnZoneByQC = (token: string, data: ReturnZoneReq) => {
  const isValid = verifyReqNoProject(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<ReturnZoneReq>({
    method: 'POST',
    path: 'ReturnZoneByQC',
    data,
    token: `Bearer ${token}`,
  })(returnZoneAction)
}

//Commissioning
export const SendCommissioningReportByQC = (
  token: string,
  data: SendCommissioningReportReq
) => {
  const isValid = verifyReqNoProject(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<SendCommissioningReportReq>({
    method: 'POST',
    path: 'SendCommissioningReportByQC',
    data,
    token: `Bearer ${token}`,
  })(sendCommissReportAction)
}

export const ReturnCommissioningByQC = (
  token: string,
  data: ReturnCommissioningReq
) => {
  const isValid = verifyReqNoProject(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<ReturnCommissioningReq>({
    method: 'POST',
    path: 'ReturnCommissioningByQC',
    data,
    token: `Bearer ${token}`,
  })(returnCommissioningAction)
}

// Construct plan
export const GetConstructPlan = (token: string, data: GetConstructPlanReq) => {
  const isValid = verifyReqNoProject(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<GetConstructPlanReq>({
    method: 'POST',
    path: 'GetConstructPlanForQC',
    data,
    token: `Bearer ${token}`,
  })(getConstructPlanAction)
}

export const SendConstructPlanReportByQC = (
  token: string,
  data: SendConstructPlanReportReq
) => {
  const isValid = verifyReqNoProject(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<SendConstructPlanReportReq>({
    method: 'POST',
    path: 'SendConstructPlanReportByQC',
    data,
    token: `Bearer ${token}`,
  })(sendConstructReportAction)
}

export const CancelConstructPlanByQC = (
  token: string,
  data: ReturnConstructReq
) => {
  const isValid = verifyReqNoProject(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<ReturnConstructReq>({
    method: 'POST',
    path: 'CancelConstructPlanByQC',
    data,
    token: `Bearer ${token}`,
  })(returnConstructAction)
}

// Audit plan
export const getAuditPlanForQC = (token: string, data: GetAuditPlanReq) => {
  const isValid = verifyReqNoProject(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<GetAuditPlanReq>({
    method: 'POST',
    path: 'GetAuditPlanForQC',
    data,
    token: `Bearer ${token}`,
  })(getAuditPlanAction)
}

export const sendAuditPlanReportByQC = (
  token: string,
  data: SendConstructPlanReportReq
) => {
  const isValid = verifyReqNoProject(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<SendConstructPlanReportReq>({
    method: 'POST',
    path: 'SendAuditPlanReportByQC',
    data,
    token: `Bearer ${token}`,
  })(sendAuditReportAction)
}

export const returnAuditByQC = (token: string, data: ReturnAuditPlanReq) => {
  const isValid = verifyReqNoProject(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<ReturnAuditPlanReq>({
    method: 'POST',
    path: 'CancelAuditPlanByQC',
    data,
    token: `Bearer ${token}`,
  })(returnAuditPlanAction)
}

//Construct road
export const getConstructRoadForQC = (
  token: string,
  data: GetConstructRoadReq
) => {
  const isValid = verifyReqNoProject(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<GetConstructRoadReq>({
    method: 'POST',
    path: 'GetConstructRoadForQC',
    data,
    token: `Bearer ${token}`,
  })(getConstructRoadAction)
}

export const SendConstructRoadReportByQC = (
  token: string,
  data: SendRoadConstructReportReq
) => {
  const isValid = verifyReqNoProject(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<SendRoadConstructReportReq>({
    method: 'POST',
    path: 'SendConstructRoadReportByQC',
    data,
    token: `Bearer ${token}`,
  })(sendConstructRoadAction)
}

export const ReturnConstructRoadyQC = (
  token: string,
  data: ReturnRoadConstructReq
) => {
  const isValid = verifyReqNoProject(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<ReturnRoadConstructReq>({
    method: 'POST',
    path: 'ReturnConstructRoadByQC',
    data,
    token: `Bearer ${token}`,
  })(returnConstructRoadAction)
}
