import React, { useState, useCallback } from 'react'
import RootState from 'typings/RootState'
import { connect, useDispatch } from 'react-redux'
import { Row, Col, Layout } from 'antd'
import styled from 'styled-components'
import TextArea from 'antd/lib/input/TextArea'
import EventModal from 'Components/Shared/Modal/EventModal'
import { DefectStatus } from 'features/types/status'

import {
  selectReport,
  selectDefect,
  selectCheckListQC,
  selectEvent,
} from 'features/report/auditPlan/selector'

import {
  submitDefectReq,
  updateDefect,
  resetEvent,
} from 'features/report/auditPlan/actions'

import {
  userEmailSelector,
  serviceTokenSelector,
} from 'features/sensitive/sensitive.selector'

import { selectUploadEvent } from 'features/uploadfile/selector'
import { selectUnitList } from 'features/masterData/selector'

import { UnitItem } from 'features/masterData/types'

import DefectFooter from './DefectFooter'
import DefectImage from '../DefectImage'

import {
  DefectItem,
  CheckListForQC,
  DefectTypeReq,
  SendDefectReq,
} from 'features/report/auditPlan/types'

import {
  JobCheckList,
  SubJobCheckList,
  DetailCheckList,
  LocationCheckList,
  StatusCheckList,
} from 'Components/Defect/CheckList'

const { Content } = Layout

type Props = {
  ProjectCode: string
  RequestID: number
  onClose?: () => void
  onCompeted?: () => void
  addMode?: boolean
} & ReduxProps

const DefectDetail: React.FunctionComponent<Props> = props => {
  const [enableModal, setEnableModal] = useState(false)

  const noFunc = () => {}
  const dispatch = useDispatch()
  const {
    onClose = noFunc,
    onCompeted = noFunc,
    ProjectCode,
    RequestID,

    Token,
    UserEmail,

    report,
    defect,
    checkList,
    unitList,

    addMode = true,

    event,
    uploadEvent,
  } = props

  const { CheckNumber } = report

  const closeEnableModal = () => {
    setEnableModal(false)
    dispatch(resetEvent())
  }
  const onSaveCompleted = () => {
    closeEnableModal()
    onCompeted()
  }

  const title: string = addMode ? 'เพิ่ม Defect' : 'แก้ไข Defect'
  const onChange = (val: DefectItem) => {
    dispatch(updateDefect({ ...val }))
  }

  const onSave = () => {
    setEnableModal(true)
    const req: SendDefectReq = {
      ProjectCode,
      UserEmail,
      Token,
      RequestID,

      Report: report,
      Defect: defect,
    }
    dispatch(submitDefectReq(req))
  }

  const updateJob = (value: number) => {
    if (value !== defect.JobTypeID) {
      defect.JobTypeID = value
      defect.SubJobTypeID = 0
      defect.DetailID = 0
      onChange(defect)
    }
  }

  const updateSubJob = (value: number) => {
    if (value !== defect.SubJobTypeID) {
      defect.SubJobTypeID = value
      defect.DetailID = 0
      onChange(defect)
    }
  }

  const updateDetail = (value: number) => {
    defect.DetailID = value
    onChange(defect)
  }

  const updateLocation = (value: number) => {
    defect.LocationID = value
    onChange(defect)
  }

  const updateStatus = (value: string) => {
    defect.PinStatus = value
    onChange(defect)
  }

  const updateRemark = (value: string) => {
    defect.Remark = value
    onChange(defect)
  }

  const updateImg = (value: any, name: string) => {
    switch (name) {
      case 'ImageLocation':
        defect.ImageLocation = value
        break
      case 'ImageBefore':
        defect.ImageBefore = value
        break
      case 'ImageAfter':
        defect.ImageAfter = value
        break
      default:
        break
    }

    onChange(defect)
  }

  const getJobList = (jobList: any[], jobId: number) => {
    const job = jobList.find((job: any) => job.JobTypeID === jobId)
    const subJobList = (job && job.SubJobTypeList) || []
    const locationList = (job && job.LocationList) || []
    return { subJobList, locationList }
  }

  const getDetail = (subjobList: any[], subJobId: number) => {
    const detail =
      subjobList.find((job: any) => job.SubJobTypeID === subJobId) || {}

    return detail.DetailList || []
  }

  const { subJobList, locationList } = getJobList(
    checkList.JobTypeList,
    defect.JobTypeID
  )
  const detailDatas = getDetail(subJobList, defect.SubJobTypeID)
  const BottomSection = (
    <ChecklistData>
      <Row type="flex" justify="space-between">
        <Col span={12}>
          <DropDownArea>
            <JobCheckList
              datas={checkList.JobTypeList}
              active={defect.JobTypeID}
              onChange={updateJob}
              disabled={false}
            />

            <SubJobCheckList
              datas={subJobList}
              active={defect.SubJobTypeID}
              onChange={updateSubJob}
              disabled={defect.JobTypeID <= 0}
            />

            <DetailCheckList
              datas={detailDatas}
              active={defect.DetailID}
              onChange={updateDetail}
              disabled={
                defect.SubJobTypeID <= 0 ||
                defect.PinStatus === DefectStatus.GOODWORK ||
                detailDatas.length === 0
              }
            />

            <LocationCheckList
              datas={locationList}
              active={defect.LocationID}
              onChange={updateLocation}
              disabled={defect.JobTypeID <= 0 || locationList.length === 0}
            />
          </DropDownArea>
        </Col>
        <Col span={12}>
          <SecordLen>
            <RemarkArea>
              <Header>หมายเหตุ</Header>
              <InputRemark>
                <TextArea
                  value={defect.Remark}
                  onChange={evt => updateRemark(evt.target.value)}
                  placeholder=""
                  maxLength={255}
                  style={{ height: '145px' }}
                />
              </InputRemark>
            </RemarkArea>

            <StatusCheckList
              active={defect.PinStatus}
              onChange={updateStatus}
              label="สถานะ"
            />
          </SecordLen>
        </Col>
      </Row>
    </ChecklistData>
  )

  return (
    <>
      <EventModal
        event={[event]}
        err={[uploadEvent]}
        enable={enableModal}
        successCalback={onSaveCompleted}
        errorCalback={closeEnableModal}
      />
      <Content className="mid-content">
        <div className="main-content activity-detail">
          <Title>{title}</Title>
          <DefectImage defect={defect} onImage={updateImg} />

          {BottomSection}
        </div>
      </Content>
      <DefectFooter
        defect={defect}
        onDelete={onClose}
        onSave={onSave}
        addMode={addMode}
      />
    </>
  )
}

const Title = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 28px;

  span {
    font-family: 'DB Helvethaica X 55 Regular';
    font-size: 16px;
    color: white;
    padding: 1px 10px;
    border-radius: 10px;
    margin-left: 12px;
  }
`

const ChecklistData = styled.div`
  margin-top: 24px;
`

const Header = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 20px;
  line-height: 1;
`

const DropDownArea = styled.div`
  padding-right: 15px;

  .ant-dropdown-trigger {
    margin-bottom: 14px;
    line-height: 0px;
  }
`
const RemarkArea = styled.div`
  margin-bottom: 14px;
`

const SecordLen = styled.div`
  padding-left: 15px;
  .ant-dropdown-trigger {
    margin-bottom: 14px;
    line-height: 0px;
  }
`

const InputRemark = styled.div`
  margin-top: 6px;
  .ant-input,
  .ant-input:focus,
  .ant-input:hover {
    font-family: 'DB Helvethaica X 55 Regular';
    font-size: 20px;
    height: 92px;
    border: solid 1px #e4e7eb;
  }
`

const mapStateToProps = (state: RootState) => {
  return {
    Token: serviceTokenSelector(state),
    UserEmail: userEmailSelector(state),

    report: selectReport(state),
    defect: selectDefect(state),
    checkList: selectCheckListQC(state),

    unitList: selectUnitList(state),

    event: selectEvent(state),
    uploadEvent: selectUploadEvent(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DefectDetail)
