import React from 'react'
import styled from 'styled-components'
import {
  CheckedIcon,
  UncheckedIcon,
  DisableCheckedIcon,
  DisableUncheckedIcon,
} from '../Icon'

interface Props {
  label: string
  text?: string
  value: any
  isSelected?: boolean
  readOnly?: boolean
  onClick?: (value: any, tag?: any) => void
  spaceBtwText?: number
  tag?: any
}

const RadioBox: React.FC<Props> = ({
  label = 'Please Select',
  text,
  value,
  spaceBtwText = 8,
  isSelected = false,
  readOnly = false,
  onClick = () => {},
  tag,
}) => {
  const handleClick = (evt: any) => {
    evt.stopPropagation()
    if (onClick) {
      onClick(value, tag)
    }
  }

  let css
  let click
  let checkIcon
  let color = 'black'
  if (readOnly) {
    color = isSelected ? 'black' : '#8b99a7'
    css = { color }
    checkIcon = isSelected ? (
      <DisableCheckedIcon width="16px" onClick={click} />
    ) : (
      <DisableUncheckedIcon width="16px" onClick={click} />
    )
  } else {
    click = handleClick
    css = { cursor: 'pointer', color }
    checkIcon = isSelected ? (
      <CheckedIcon width="16px" onClick={click} />
    ) : (
      <UncheckedIcon width="16px" onClick={click} />
    )
  }
  return (
    <ItemView onClick={click} style={css}>
      <Icon style={{ marginRight: spaceBtwText + 'px' }}>{checkIcon}</Icon>
      <ItemLabel>{label}</ItemLabel>
      <ItemDetail>{text}</ItemDetail>
    </ItemView>
  )
}

const ItemView = styled.div`
  font-family: 'DB Helvethaica X 55 Regular';
  font-size: 18px;
  align-items: center;
  display: flex;
  width: 100%;

  .ant-checkbox-wrapper > .ant-checkbox > .ant-checkbox-inner {
    border-radius: 0px !important;
  }
`

const ItemLabel = styled.div`
  width: 100%;
`

const ItemDetail = styled.span`
  text-align: right;
  width: 200px;
  color: #c4c3c3;
`

const Icon = styled.div`
  margin-top: -2px;
  float: left;
`

export default RadioBox
