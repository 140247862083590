import React, { useState, useEffect } from 'react'
import RootState from 'typings/RootState'
import { connect, useDispatch } from 'react-redux'
import {
  userEmailSelector,
  serviceTokenSelector,
} from 'features/sensitive/sensitive.selector'
import {
  GetPMRReport,
  UpdatePMRDefect,
  SetGroupPMRDefect,
  ConfirmGroupDefectForPMR,
} from 'features/pmr/api'
import {
  GetReportReq,
  DefectItem,
  UpdateDefectReq,
  SetGroupPMRDefectReq,
  ConfirmGroupPMRDefectReq,
} from 'features/pmr/types'
import { selectReport, selectEvent } from 'features/pmr/selector'
import { resetCurrentDefect, updateCurrentDefect } from 'features/pmr/actions'

import EventModal from 'Components/Shared/Modal/EventModal'
import ReportInfo from '../ReportInfo'
import DefectView from '../DefectView'
import { RejectModal } from 'Components/Activity'
import DefectDetail from 'Components/Zone/DefectDetail'
import { FloatButton } from 'Components/Shared/Button'
import { GetCheckListReq, GetMainconReq } from 'features/report/zone/types'
import { getCheckList, getMainconList } from 'features/report/zone/api'
import { resetDefect } from 'features/report/zone/actions'
import DefectGrouping from '../DefectGrouping'

type Props = {
  initPage?: string
  ProjectCode: string
  RequestID: number
  CheckNumber: number
  Unit: string
  Floor: string
  QCTypeID: string
  onPage?: (name: string) => void
  readOnly?: boolean
  mode: 'PMR' | 'CM' | 'QC'
} & ReduxProps

const PmrReportView: React.ComponentType<Props> = props => {
  const [page, setPage] = useState('')
  const [pageIndex, setPageIndex] = useState(1)
  const [updateMode, setUpdateMode] = useState(false)
  const [showRejectModal, setShowRejectModal] = useState(false)
  const [perPage, setPerPage] = useState(10)
  const [enableModal, setEnableModal] = useState(false)
  const [defect, setDefect] = useState({} as DefectItem)
  const dispatch = useDispatch()

  const {
    Token,
    UserEmail,
    event,
    initPage = '',
    ProjectCode,
    RequestID,
    QCTypeID,
    onPage = (name: string) => {},
    Unit,
    Floor,
    report,
    mode,
    readOnly,
  } = props

  const lastItem = report.DefectList.length === 1

  useEffect(() => {
    setPage(initPage)
    setEnableModal(false)
  }, [initPage])

  useEffect(() => {
    getPlanReport()
  }, [pageIndex, perPage])

  const getPlanReport = () => {
    setEnableModal(true)
    const req: GetReportReq = {
      ProjectCode,
      UserEmail,
      Floor,
      QCTypeID,
      RequestID,
      Unit,
      Mode: mode,
      Page: pageIndex,
      RowPerPage: perPage,
    }

    dispatch(GetPMRReport(Token, req))
  }

  const onUpdateDefect = (
    PinID: number,
    remark: string,
    status: 'Pass' | 'Fail'
  ) => {
    setEnableModal(true)
    setUpdateMode(true)
    const req: UpdateDefectReq = {
      ProjectCode,
      UserEmail,
      RequestID,
      PinID,
      PMRRemark: remark,
      LastDefect: lastItem,
      PMRStatus: status,
    }

    dispatch(UpdatePMRDefect(Token, req))
  }

  const onSetGroupPMRDefect = (
    PinID: number,
    fixReason: string,
    groupType: string
  ) => {
    setEnableModal(true)
    setUpdateMode(true)
    const req: SetGroupPMRDefectReq = {
      ProjectCode,
      UserEmail,
      RequestID,
      PinID,
      LastDefect: lastItem,
      FixReason: fixReason,
      GroupType: groupType,
    }

    dispatch(SetGroupPMRDefect(Token, req))
  }

  const onConfirmGroupPMRDefect = (
    PinID: number,
    status: 'Approve' | 'Reject'
  ) => {
    setEnableModal(true)
    setUpdateMode(true)
    const req: ConfirmGroupPMRDefectReq = {
      ProjectCode,
      UserEmail,
      RequestID,
      PinID,
      LastDefect: lastItem,
      Status: status,
    }

    dispatch(ConfirmGroupDefectForPMR(Token, req))
  }

  useEffect(() => {
    const req: GetCheckListReq = {
      ProjectCode,
      UserEmail,
      Unit,
      QCTypeID,
      Floor,
    }

    dispatch(getCheckList(Token, req))

    const reqMaincon: GetMainconReq = {
      UserEmail,
      ProjectCode,
      Unit,
      Zone: 'Common Area',
      QCTypeID,
      Floor,
    }
    dispatch(getMainconList(Token, reqMaincon))
  }, [ProjectCode, Unit, QCTypeID, Floor, RequestID])

  const onApprove = (item: DefectItem) => {
    if (mode === 'PMR') {
      onUpdateDefect(item.PinID, '', 'Pass')
    }

    if (mode === 'QC') {
      onConfirmGroupPMRDefect(item.PinID, 'Approve')
    }
  }

  const onReject = (item: DefectItem) => {
    if (mode === 'PMR') {
      setShowRejectModal(true)
      setDefect(item)
    }

    if (mode === 'QC') {
      onConfirmGroupPMRDefect(item.PinID, 'Reject')
    }
  }

  const onEdit = (item: DefectItem) => {
    let name = ''
    if (mode === 'CM') {
      name = 'manage.defect'
    } else {
      name = 'view.defect'
    }
    setPage(name)
    onPage(name)
    dispatch(updateCurrentDefect(item))
  }

  const onAdd = () => {
    const name = 'add.defect'
    setPage(name)
    onPage(name)
    dispatch(resetDefect())
  }

  const onClose = () => {
    setPage('')
    onPage('')
  }

  const onCompeted = () => {
    setPage('')
    onPage('')
    setEnableModal(false)
    setUpdateMode(false)
    getPlanReport()
    if (lastItem) {
      onPage('dashboard')
    }
  }

  const rejectModal = (
    <RejectModal
      visible={showRejectModal}
      onClose={() => setShowRejectModal(false)}
      onSubmit={(val: string) => {
        setShowRejectModal(false)
        onUpdateDefect(defect.PinID, val, 'Fail')
      }}
      title="ไม่ผ่าน"
      placeholder="เหตุผลที่ไม่ผ่านอนุมัติ"
    />
  )

  const addDefectButton = <FloatButton onClick={onAdd} />

  switch (page) {
    case 'add.defect':
      return (
        <DefectDetail
          ProjectCode={ProjectCode}
          RequestID={RequestID}
          onClose={onClose}
          addMode={page === 'add.defect'}
          onCompeted={onCompeted}
          report={report}
        />
      )
    case 'view.defect':
      return (
        <>
          <EventModal
            event={[event]}
            enable={enableModal}
            errorCalback={() => setEnableModal(false)}
            successCalback={onCompeted}
          />
          <DefectView
            onApprove={onApprove}
            onReject={onReject}
            floor={report.Floor}
          />
          {rejectModal}
        </>
      )
    case 'manage.defect':
      return (
        <>
          <EventModal
            event={[event]}
            enable={enableModal}
            errorCalback={() => setEnableModal(false)}
            successCalback={onCompeted}
          />
          <DefectGrouping
            onSendReport={(item, fixReason, groupType) =>
              onSetGroupPMRDefect(item.PinID, fixReason, groupType)
            }
            floor={report.Floor}
          />
          {rejectModal}
        </>
      )
    default:
      return (
        <>
          <EventModal
            event={[event]}
            enable={enableModal}
            errorCalback={() => setEnableModal(false)}
            successCalback={updateMode ? onCompeted : () => {}}
          />
          <ReportInfo
            onEdit={onEdit}
            page={pageIndex}
            perPage={perPage}
            setPage={setPageIndex}
            setPerPage={setPerPage}
            onApprove={onApprove}
            onReject={onReject}
            mode={mode}
          />
          {!readOnly && addDefectButton}
          {rejectModal}
        </>
      )
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    Token: serviceTokenSelector(state),
    UserEmail: userEmailSelector(state),
    event: selectEvent(state),
    report: selectReport(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PmrReportView)
