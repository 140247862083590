import { cloneDeep } from 'lodash'

import { toArray, getInt, getText, getScore, isDuplicate } from './common'

import {
  CheckListDetail,
  QCType,
  JobType,
  SubJobType,
} from 'features/checklist/unit/types'

import { original } from 'features/checklist/unit/reducer'

export const toCheckList = (data: string): any => {
  let checklist: CheckListDetail = cloneDeep(original)

  let mode = ''
  const rows = toArray(data)
  rows.forEach((row: any, index: number) => {
    const cmd = getText(row, 0).toLowerCase()
    switch (cmd) {
      case 'checklist name':
        mode = 'name'
        checklist.CheckListName = getText(row, 1)
        break

      case 'checklist type':
        mode = ''
        checklist.ProjectTypeID = getInt(row, 1)
        break

      case 'score':
        mode = 'score'
        checklist.ScoreRange = getScore(row)
        break

      case 'criteria':
        mode = 'criteria'
        addCriteria(checklist, row, index)

        break

      case 'description':
        mode = 'description'
        addDefectDetail(checklist, row, index)
        break

      case 'score range':
      case 'note':
        mode = ''
        break

      case 'end':
        return checklist

      default:
        if (mode === 'description') addDefectDetail(checklist, row, index)
        else if (mode === 'criteria') addCriteria(checklist, row, index)
        else mode = ''

        break
    }
  })

  return checklist
}

const addCriteria = (
  checklist: CheckListDetail,
  row: string[],
  index: number
) => {
  const text = getText(row, 1)
  if (text !== '') {
    isDuplicate('CriteriaName', text, checklist.CriteriaList, index)
    checklist.CriteriaList.push({
      CriteriaID: 0,
      CriteriaName: text,
    })
  }
}

const addDefectDetail = (
  checklist: CheckListDetail,
  row: string[],
  index: number
) => {
  const qcType = getText(row, 1)
  const funcId = getInt(row, 2)
  const job = getText(row, 3)
  const jobScore = getInt(row, 4)
  const subJob = getText(row, 5)
  const detail = getText(row, 6)
  const major = getText(row, 7)
  const supplier = getText(row, 8)

  if (detail === '') return

  let qcTypeItem: QCType = {
    QCTypeID: qcType,
    QCTypeName: '',
    QCTypeCode: '',
    UnitType: '',
    FunctionCheckListStatus: funcId === 0 ? 'Inactive' : 'Active',
    FunctionCheckListID: funcId,
    JobTypeList: [],
  }
  let hasIssueOn = ''

  try {
    if (qcType !== '') {
      hasIssueOn += `| หมวดงาน (QC): ${qcType} ( ${job} ) - [ ${subJob} - ${detail} ]`
      checklist.QCTypeList.push(qcTypeItem)
    }

    const qcIndex = checklist.QCTypeList.length - 1
    qcTypeItem = checklist.QCTypeList[qcIndex]

    let jobItem: JobType = {
      JobTypeID: 0,
      JobTypeName: job,
      Score: jobScore,
      SubJobTypeList: [],
      LocationList: [],
    }

    if (job !== '') {
      jobItem.LocationList = getLocationList(
        row,
        checklist.CriteriaList.length + 9,
        index
      )

      hasIssueOn += ' | หมวดงานหลัก: ' + job
      isDuplicate('JobTypeName', job, qcTypeItem.JobTypeList, index)
      qcTypeItem.JobTypeList.push(jobItem)
    }

    const jobIndex = qcTypeItem.JobTypeList.length - 1
    jobItem = qcTypeItem.JobTypeList[jobIndex]

    let subJobItem: SubJobType = {
      SubJobTypeID: 0,
      SubJobTypeName: subJob,
      DetailList: [],
    }

    hasIssueOn += ' | หมวดงานรอง: ' + subJob

    if (subJob !== '') {
      isDuplicate('SubJobTypeName', subJob, jobItem.SubJobTypeList, index)
      jobItem.SubJobTypeList.push(subJobItem)
    }

    const subJobIndex = jobItem.SubJobTypeList.length - 1
    subJobItem = jobItem.SubJobTypeList[subJobIndex]

    hasIssueOn += ' | รายละเอียด: ' + detail
    isDuplicate('DetailName', detail, subJobItem.DetailList, index)

    const detailItem = {
      DetailID: 0,
      DetailName: detail,
      IsRequired: major === 'yes',
      IsSupplier: supplier === 'yes',
      CriteriaList: getCriteriaList(row, checklist.CriteriaList, 9),
    }

    subJobItem.DetailList.push(detailItem)
  } catch (e) {
    let importErrMsg = `ตำแหน่งที่พบปัญหา ${hasIssueOn}`
    throw new Error(importErrMsg)
  }
}

const getLocationList = (row: string[], start: number, index: number) => {
  const list: any[] = []
  const len = row.length
  for (let i = start; i < len; i++) {
    const text = getText(row, i)
    if (text === '') return list

    isDuplicate('LocationName', text, list, index)
    list.push({
      LocationID: 0,
      LocationName: text,
      Score: 10,
    })
  }

  return list
}

const getCriteriaList = (row: string[], criteriaList: any[], start: number) => {
  const list: any[] = []
  const len = criteriaList.length
  for (let i = 0; i < len; i++) {
    const score = getInt(row, i + start)

    list.push({
      CriteriaID: 0,
      CriteriaName: criteriaList[i].CriteriaName,
      Score: score,
    })
  }

  return list
}
