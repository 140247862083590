import { getType } from 'typesafe-actions'
import { cloneDeep } from 'lodash'

import * as actions from './actions'
import { StoreState, ReportAction, PinList } from './types'
import produce from 'immer'
import parseErrorMessage from 'utils/parseErrorMessage'
import { sortingByNumber } from 'utils/sorting'
import { Event } from '../../types/event'

const reportQC = {
  Unit: 'Toilet Room',
  Zone: '',

  Floor: '1',
  FloorPlanName: '',
  QCTypeID: '',
  QCTypeName: '',
  QCTypeVersion: '',

  CheckDate: '',
  CheckNumber: 0,

  HasSign: false,
  HasSelectedDefect: false,
  CheckedBy: '',

  State: 'CM-QC',
  Status: 'INIT',

  DefectList: [],

  InspectDate: '',
  CheckedDate: '',
}

const checklistQC = {
  CheckListID: 0,
  CheckListName: '',

  ProjectTypeID: '',
  ProjectTypeName: '',

  FloorPlanID: '',
  UnitFloorName: '',

  ImageFloorPlan: undefined,
  JobTypeList: [],
}

const defect = {
  RequestID: 0,
  PinID: 0,
  PinNumber: 0,
  CheckNumber: 0,
  JobTypeID: 0,
  JobTypeName: '',
  SubJobTypeID: 0,
  SubJobTypeName: '',
  DetailID: 0,
  DetailName: '',
  LocationID: 0,
  LocationName: '',

  Remark: '',
  CreatedBy: '',
  Score: 0,
  PinStatus: 'Fail',

  ImageFloorPlan: undefined,
  ImageBefore: undefined,
  ImageAfter: undefined,
  IsRequired: false,
  IsSupplier: false,

  CreatedByModule: '',
  ZoneTypeID: 0,
  ZoneType: '',

  GroupType: '',
  PMRStatus: '',
  PMRRemark: '',
  FixReason: '',
  QCPMRStatus: '',

  IsSansiriFixed: false,
  MCVendorCode: '',
  VendorName: '',

  Module: '',
}

const initEvent = {
  IsLoading: false,
  MessageSuccess: undefined,
  SubmitError: undefined,
  SubmitSuccess: false,
  SubmitType: undefined,
} as Event

const initPinList = {
  PinList: [],
} as PinList

const initialState: StoreState = {
  report: cloneDeep(reportQC),
  event: cloneDeep(initEvent),
  eventChecklist: cloneDeep(initEvent),

  checkListQC: cloneDeep(checklistQC),
  defect: cloneDeep(defect),
  selectPinList: cloneDeep(initPinList),
  mainconList: [],
}

export default function(
  state: StoreState = initialState,
  action: ReportAction
): StoreState {
  return produce(state, draft => {
    switch (action.type) {
      case getType(actions.getReportAction.request):
        draft.event = cloneDeep(initEvent)
        draft.event.IsLoading = true
        draft.report = cloneDeep(reportQC)

        break
      case getType(actions.getReportAction.success):
        draft.event.IsLoading = false
        const response = action.payload.ResponseData
        response.DefectList = sortingByNumber(
          response.DefectList,
          'asc',
          'PinNumber'
        )
        draft.report = response
        break

      case getType(actions.resetDefect):
        draft.defect = cloneDeep(defect)
        break
      case getType(actions.updateDefect):
        draft.defect = action.payload
        break

      case getType(actions.getCheckListAction.request):
        draft.eventChecklist = cloneDeep(initEvent)
        draft.eventChecklist.IsLoading = true
        draft.checkListQC = cloneDeep(checklistQC)
        break

      case getType(actions.getCheckListAction.success):
        draft.eventChecklist.IsLoading = false
        const checkQC = action.payload.ResponseData
        draft.checkListQC = checkQC
        break

      case getType(actions.getCheckListAction.failure):
        draft.eventChecklist.IsLoading = false
        draft.eventChecklist.SubmitError = parseErrorMessage(action.payload)
        break

      case getType(actions.createDefectAction.request):
      case getType(actions.updateDefectAction.request):
      case getType(actions.setDefectAction.request):
      case getType(actions.updateSelectedPintAction.request):
        draft.event = cloneDeep(initEvent)
        draft.event.IsLoading = true
        break

      case getType(actions.createDefectAction.success):
      case getType(actions.updateDefectAction.success):
      case getType(actions.setDefectAction.success):
      case getType(actions.updateSelectedPintAction.success):
        draft.event.IsLoading = false
        draft.event.SubmitError = undefined
        draft.event.SubmitSuccess = true
        draft.event.MessageSuccess = 'ทำรายการสำเร็จ'
        break

      case getType(actions.getReportAction.failure):
      case getType(actions.createDefectAction.failure):
      case getType(actions.updateDefectAction.failure):
      case getType(actions.setDefectAction.failure):
      case getType(actions.getSelectedPinAction.failure):
      case getType(actions.getMainconAction.failure):
        draft.event.IsLoading = false
        draft.event.SubmitError = parseErrorMessage(action.payload)
        break

      case getType(actions.updateReport):
        draft.report = action.payload
        break
      case getType(actions.resetEvent):
        draft.event = cloneDeep(initEvent)
        break

      case getType(actions.getSelectedPinAction.request):
        draft.selectPinList = cloneDeep(state.selectPinList)
        break
      case getType(actions.getSelectedPinAction.success):
        draft.selectPinList = action.payload.ResponseData
        break

      case getType(actions.getMainconAction.request):
        draft.mainconList = []
        break

      case getType(actions.getMainconAction.success):
        const resp = action.payload.ResponseData || {}
        draft.mainconList = resp.List || []
        break

      default:
        return state
    }
  })
}
