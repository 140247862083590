import React, { useState } from 'react'
import styled from 'styled-components'
import { Row, Col, DatePicker } from 'antd'
import { DATETIME_PICKER_DATE_FORMAT } from 'config'
import moment from 'moment'

import { PrimaryButton } from 'Components/Shared/Button'

type Props = {
  onClick: (date: string) => void
  isLoading?: boolean
}

const SendToCMBar: React.FC<Props> = ({ onClick, isLoading = false }) => {
  const [selected, setSelected] = useState(null as any)

  const onSendRequest = () => {
    onClick(selected.format('YYYYMMDDHHmm'))
  }

  const disabledDate = (current: any) => {
    return current && current < moment().subtract(1, 'day')
  }

  return (
    <FooterContent>
      <Row type="flex" justify="space-between" align="middle">
        <Col span={19}>
          <div className="input-with-icon">
            <div className="label">วันเวลานัดหมาย</div>
            <DatePicker
              className="date-picker"
              format={DATETIME_PICKER_DATE_FORMAT}
              placeholder={DATETIME_PICKER_DATE_FORMAT}
              onChange={date => setSelected(date)}
              showTime={{
                format: 'HH:mm',
              }}
              disabledDate={disabledDate}
              value={selected}
              allowClear={true}
              showToday={false}
            />
          </div>
        </Col>
        <Col>
          <div className="btn-submit">
            <PrimaryButton
              text="ส่งคำขอให้ CM"
              disabled={isLoading || selected === null}
              onClick={onSendRequest}
            />
          </div>
        </Col>
      </Row>
    </FooterContent>
  )
}

const FooterContent = styled.div`
  max-width: 1110px;
  margin: 0 auto;
  color: #000;
  font-size: 20px;
  padding-right: 20px;

  .btn-submit {
    width: 191px;
    text-align: right;
    button {
      border-radius: 5px;
      width: 191px;
      height: 48px;
      font-size: 20px;
    }
  }
`

export default SendToCMBar
