import React, { useState } from 'react'

import FormChecklist from './FormChecklist'
import styled from 'styled-components'
import { FunctionChecklist } from 'features/report/function/types'
import { TaskChecklist } from '.'

type Props = {
  checkListData: FunctionChecklist
  onUpdateCheckList?: (val: FunctionChecklist) => void
  readonly?: boolean
}

const FunctionCheckList: React.FC<Props> = (props: Props) => {
  const { checkListData, onUpdateCheckList = () => {}, readonly = false } = props

  return (
    <ChecklistContent>
      {readonly ? (
        <TaskChecklist inspectionList={checkListData.InspectionList || []} />
      ) : (
        <FormChecklist
          inspectionList={(checkListData && checkListData.InspectionList) || []}
          onUpdateCheckList={val => {
            checkListData.InspectionList = val
            onUpdateCheckList(checkListData)
          }}
        />
      )}
    </ChecklistContent>
  )
}

const ChecklistContent = styled.div`
  margin-top: 40px;
`

export default FunctionCheckList
