import { getType } from 'typesafe-actions'
import * as actions from './actions'
import { StoreState, DashboardAction, DashboardData, TypeOfTask } from './types'
import produce from 'immer'
import parseErrorMessage from 'utils/parseErrorMessage'
import { sortingList } from 'utils/helper'

const initData = {
  TotalInProgress: 0,
  InProgress: [],
  TotalFixFromQC: 0,
  FixFromQC: [],
} as DashboardData

const initialState: StoreState = {
  data: initData,
  originalData: initData,
  event: {
    IsLoading: false,
    MessageSuccess: '',
    SubmitError: '',
    SubmitSuccess: false,
    SubmitType: '',
  },
  sorting: {
    inProgress: 'asc',
    fixFromQC: 'asc',
  },
  query: {
    date: {
      start: undefined,
      end: undefined,
    },
  },
  filter: {},
}

const sortingColumn = {
  inProgress: '',
  fixFromQC: '',
}

export default function(
  state: StoreState = initialState,
  action: DashboardAction
): StoreState {
  return produce(state, draft => {
    switch (action.type) {
      case getType(actions.getDashbaordAction.request):
        draft.event.SubmitError = undefined
        draft.event.IsLoading = true
        draft.event.SubmitSuccess = false
        draft.data = state.data
        break
      case getType(actions.getDashbaordAction.success):
        draft.event.IsLoading = false
        const response = action.payload.ResponseData
        const sorting = state.sorting
        const filter = state.filter

        const cmInProgressSort = sortingList(
          response.InProgress,
          sorting.cmInProgress,
          sortingColumn.inProgress,
          'date'
        )

        const fixFromQCSort = sortingList(
          response.FixFromQC,
          sorting.fixFromQC,
          sortingColumn.fixFromQC,
          'string'
        )

        draft.data = {
          InProgress: cmInProgressSort,
          FixFromQC: fixFromQCSort,
          TotalInProgress: response.TotalInProgress,
          TotalFixFromQC: response.TotalFixFromQC,
        }

        draft.originalData = {
          InProgress: response.InProgress,
          FixFromQC: response.FixFromQC,
          TotalInProgress: response.TotalInProgress,
          TotalFixFromQC: response.TotalFixFromQC,
        }

        break
      case getType(actions.getDashbaordAction.failure):
        draft.event.IsLoading = false
        draft.event.SubmitError = parseErrorMessage(action.payload)

        break

      case getType(actions.sortingDashboard):
        let typ: string = action.payload
        let orderBy: string = state.sorting[typ]
        orderBy = orderBy !== 'asc' ? 'asc' : 'desc'
        draft.sorting[typ] = orderBy

        if (typ === TypeOfTask.InProgress) {
          const list = sortingList(
            state.data.InProgress,
            orderBy,
            sortingColumn.inProgress,
            'date'
          )
          draft.data.InProgress = list
        } else if (typ === TypeOfTask.FixFromQC) {
          const list = sortingList(
            state.data.FixFromQC,
            orderBy,
            sortingColumn.fixFromQC,
            'string'
          )

          draft.data.FixFromQC = list
        }
        break

      default:
        return state
    }
  })
}
