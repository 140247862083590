import { getType } from 'typesafe-actions'
import * as actions from './actions'
import { StoreState, DashboardAction, TypeOfTask } from './types'
import produce from 'immer'
import parseErrorMessage from 'utils/parseErrorMessage'
import { filterActivity, sortingList } from 'utils/helper'
import { sortingTask } from 'utils/sorting'

const initialState: StoreState = {
  data: {
    Todo: [],
    InProgress: [],
    Done: [],
    TotalDone: 0,
    TotalProgress: 0,
    TotalTodo: 0,
  },
  originalData: {
    Todo: [],
    InProgress: [],
    Done: [],
    TotalDone: 0,
    TotalProgress: 0,
    TotalTodo: 0,
  },
  event: {
    isFetching: false,
    error: '',
  },
  sorting: {
    todo: 'asc',
    'in-progress': 'asc',
    done: 'asc',
  },
  query: {
    date: {
      start: undefined,
      end: undefined,
    },
  },
  filter: {
    progress: [],
    status: [],
    wbs: [],
    date: {
      mode: '',
      start: undefined,
      end: undefined,
    },
    floor: [],
    unit: [],
    zone: [],
    type: [],
  },
  response: {
    ResponseCode: '1',
    ResponseMsg: 'Success',
    RequestTime: '',
    Responsetime: '',
    ResponseData: {
      ToDo: [],
      InProgress: [],
      Done: [],
      TotalDone: 0,
      TotalProgress: 0,
      TotalTodo: 0,
    },
  },
}

const sortingColumn = {
  todo: 'FinishDate',
  'in-progress': 'FinishDate',
  done: 'FinishDate',
}

export default function (
  state: StoreState = initialState,
  action: DashboardAction
): StoreState {
  return produce(state, (draft) => {
    switch (action.type) {
      case getType(actions.getActivityAction.request):
        draft.event = {
          isFetching: true,
          error: '',
        }

        draft.data = state.data
        break
      case getType(actions.getActivityAction.success):
        draft.event.isFetching = false
        const response = action.payload.ResponseData
        const sorting = state.sorting
        const filter = state.filter
        let list = filterActivity(response.ToDo, filter)
        const todoSort = sortingList(
          list,
          sorting.todo,
          sortingColumn.todo,
          'date'
        )

        list = filterActivity(response.InProgress, filter)
        const inprogressSort = sortingList(
          list,
          sorting['in-progress'],
          sortingColumn['in-progress'],
          'date'
        )

        list = filterActivity(response.Done, filter)
        const doneSort = sortingList(
          list,
          sorting.done,
          sortingColumn.done,
          'date'
        )

        draft.originalData = {
          Todo: response.ToDo,
          InProgress: response.InProgress,
          Done: response.Done,
          TotalDone: response.TotalDone,
          TotalProgress: response.TotalProgress,
          TotalTodo: response.TotalTodo,
        }

        draft.data = {
          Todo: todoSort,
          InProgress: inprogressSort,
          Done: doneSort,
          TotalDone: response.TotalDone,
          TotalProgress: response.TotalProgress,
          TotalTodo: response.TotalTodo,
        }

        break
      case getType(actions.getActivityAction.failure):
        draft.event = {
          isFetching: false,
          error: parseErrorMessage(action.payload),
        }

        break

      case getType(actions.sortingDashboard):
        let typ: string = action.payload
        let orderBy: string = state.sorting[typ]
        orderBy = orderBy !== 'asc' ? 'asc' : 'desc'
        draft.sorting[typ] = orderBy
        if (typ === TypeOfTask.Todo) {
          const list = sortingTask(state.data.Todo, orderBy, sortingColumn.todo)

          draft.response.ResponseData.ToDo = list
          draft.data.Todo = list
        } else if (typ === TypeOfTask.Inprogress) {
          const list = sortingTask(
            state.data.InProgress,
            orderBy,
            sortingColumn['in-progress']
          )

          draft.response.ResponseData.InProgress = list
          draft.data.InProgress = list
        } else if (typ === TypeOfTask.Done) {
          const list = sortingTask(state.data.Done, orderBy, sortingColumn.done)

          draft.response.ResponseData.Done = list
          draft.data.Done = list
        }
        break
      case getType(actions.setFilterDashboard):
        draft.filter = { ...action.payload }
        break
      case getType(actions.filterDashboard):
        let filtterTodo = filterActivity(draft.originalData.Todo, draft.filter)
        const sortingTodo = sortingTask(
          filtterTodo,
          state.sorting.todo,
          sortingColumn.todo
        )

        let filtterInProgress = filterActivity(
          draft.originalData.InProgress,
          draft.filter
        )
        const sortingInProgress = sortingTask(
          filtterInProgress,
          state.sorting.progress,
          sortingColumn['in-progress']
        )

        let filtterDone = filterActivity(draft.originalData.Done, draft.filter)
        const sortingDone = sortingTask(
          filtterDone,
          state.sorting.done,
          sortingColumn.done
        )

        draft.data = {
          Todo: sortingTodo,
          InProgress: sortingInProgress,
          Done: sortingDone,
          TotalDone: draft.originalData.TotalDone,
          TotalProgress: draft.originalData.TotalProgress,
          TotalTodo: draft.originalData.TotalTodo,
        }
        break
      default:
        return state
    }
  })
}
