import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, connect } from 'react-redux'
import RootState from 'typings/RootState'
import styled from 'styled-components'
import { Row, Col } from 'antd'
import { RouteComponentProps } from 'react-router-dom'
import { BreadcrumbLink } from 'Components/Shared/BreadCrumb/BreadCrumb'
import SubnavBar from 'Components/Shared/SubnavBar'
import LoadingView from 'Components/Shared/LoadingView'
import Menu from '../../MenuAdmin'
import {
  serviceTokenSelector,
  userEmailSelector,
} from 'features/sensitive/sensitive.selector'
import {
  selectEvent,
  selectDataList,
  selectFilter,
  selectInitFilter,
  selectSorting,
} from 'features/adminqc/backward-unit/selector'
import { sortingTask } from 'features/adminqc/backward-unit/actions'

import Filter from 'Components/AdminQC/BackwardUnit/Filter/UnitFilter'
import { PaginationType } from 'features/types/common'
import { initPagination } from 'utils/helper'
import Pagination from 'Components/Shared/Pagination'
import { HeaderRow } from 'Components/Shared/Table'
import {
  getUnitForResetState,
  updateUnitForResetState,
} from 'features/adminqc/backward-unit/api'
import {
  GetDataListReq,
  BackwardUnitDetail,
  BackwardUnitReq,
} from 'features/adminqc/backward-unit/types'
import RowItem from './RowItem'
import { toDateText } from 'utils/datetime'
import { EventModal } from 'Components/Shared'
import DeleteModal, {
  DeleteModalHandles,
} from 'Components/Shared/Modal/DeleteModal'
import { AlertError } from 'Components/Shared'
import BackwardModal from './BackwardModal'

const breadcrumbs: BreadcrumbLink[] = [
  {
    url: '/dashboard',
    title: 'Home',
    icon: '/home.png',
    active: true,
    showTitle: false,
  },
  {
    url: '/backward-unit',
    title: 'ถอยสถานะการตรวจยูนิต',
    active: false,
    showTitle: true,
  },
]

type Props = ReduxProps & DispatchProps & RouteComponentProps

const BackwardUnitListPage: React.ComponentType<Props> = ({
  token,
  UserEmail,
  event,
  dataList,
  filter: currentFilter,
  initFilter,
  sorting,
}) => {
  const [enableModal, setEnableModal] = useState(false)
  const [isUpdate, setIsUpdate] = useState(false)
  const dispatch = useDispatch()
  const [pagination, setPagination] = useState<PaginationType>(initPagination())
  const modalRef = useRef<any>(null)
  const backwardItem = useRef<BackwardUnitDetail | null>(null)

  const [errorValidate, setErrorValidate] = useState('')
  const [enableModalValidate, setEnableModalValidate] = useState(false)

  useEffect(() => {
    onFindUnit()
  }, [pagination])

  const onFindUnit = (
    isClearFilter: boolean = false,
    click: boolean = false
  ) => {
    const filter = isClearFilter ? initFilter : currentFilter

    if (filter.ProjectCode != '' && filter.UnitCode.length > 0) {
      setEnableModal(true)
      const req: GetDataListReq = {
        UserEmail,
        ProjectCode: filter.ProjectCode,
        UnitCode: filter.UnitCode,
        QCType: filter.QCType,
        Page: pagination.Page,
        RowPerPage: pagination.RowPerPage,
      }
      dispatch(getUnitForResetState(token, req))
    } else if (click == true && filter.ProjectCode == '') {
      setEnableModalValidate(true)
      setErrorValidate('กรุณาเลือกโครงการ')
    } else if (click == true && filter.UnitCode.length == 0) {
      setEnableModalValidate(true)
      setErrorValidate('กรุณาเลือกยูนิต')
    }
  }

  const onBackward = async (obj?: any, memo?: any) => {
    if (backwardItem.current) {
      setEnableModal(true)
      const req: BackwardUnitReq = {
        UserEmail,
        ReqID: backwardItem.current.ReqID,
        Memo: memo != '' ? memo : null,
      }
      dispatch(updateUnitForResetState(token, req))
    }
  }

  const showBackwardModal = (item: BackwardUnitDetail) => {
    if (modalRef.current) {
      modalRef.current.showModal(
        'คุณยืนยันที่จะต้องการถอยสถานะการตรวจยูนิต นี้',
        '',
        null,
        'ถอยสถานะการตรวจยูนิต'
      )
      backwardItem.current = item
    }
  }

  const onSuccess = () => {
    setEnableModal(false)
    onFindUnit()
  }

  const callBackError = () => {
    setEnableModalValidate(false)
    setErrorValidate('')
  }

  const onSort = (name: string) => {
    dispatch(sortingTask({ name }))
  }

  const content = dataList.List.map(
    (item: BackwardUnitDetail, index: number) => {
      return (
        <RowItem
          key={index}
          item={item}
          showBackwardModal={showBackwardModal}
        />
      )
    }
  )

  return (
    <>
      <SubnavBar breadcrumbs={breadcrumbs} hideSearch={true} />
      <EventModal
        name="backward-unit"
        event={[event]}
        enable={enableModal}
        successCalback={onSuccess}
      />

      <AlertError
        text={errorValidate}
        isShow={enableModalValidate}
        callback={callBackError}
      />
      <Menu selected={'backward unit'}>
        <MainPage>
          <Header>ถอยสถานะการตรวจยูนิต</Header>
          <Filter
            onSearch={(isClear: boolean = false) => {
              pagination.Page = 1
              setPagination({ ...pagination })
              onFindUnit(isClear, isClear == true ? false : true)
            }}
          />
          <HeaderRow
            onSort={name => onSort(name)}
            sorting={sorting}
            items={columnsName}
          />
          {content}
          <Pagination
            currentPage={pagination.Page}
            totalRows={dataList.Total}
            perPage={pagination.RowPerPage}
            onChangeNumber={(page: number) => {
              pagination.RowPerPage = page
              setPagination({ ...pagination })
            }}
            onClickPage={(page: number) => {
              pagination.Page = page
              setPagination({ ...pagination })
            }}
          />
        </MainPage>
      </Menu>

      {/* <DeleteModal ref={modalRef} onOk={onBackward} /> */}
      <BackwardModal ref={modalRef} onSubmit={onBackward} />
    </>
  )
}

const MainPage = styled.div`
  padding: 40px 45px;
  width: 100%;
  height: calc(100vh - 115px);
`

const UnitBox = styled.div`
  margin-right: 11px;
  height: 100%;
`

const UnitSettingBox = styled.div`
  margin-left: 11px;
  height: 100%;
`

const MainSection = styled.div`
  height: 50px;
  margin-bottom: 35px;
  display: flex;
`

const Header = styled.div`
  margin-bottom: 27px;
  font-size: 28px;
  line-height: 1.07;
  font-family: 'DB Helvethaica X 75 Bd';
  color: #000;
`

const columnsName = [
  {
    title: 'ReqID',
    span: 3,
    key: 'ReqID',
  },
  {
    title: 'โครงการ',
    span: 2,
    key: 'ProjectCode',
  },
  {
    title: 'ยูนิต',
    span: 2,
    key: 'Unit',
  },
  {
    title: 'โมเดล',
    span: 3,
    key: 'ModelName',
  },
  {
    title: 'หมวดงาน',
    span: 3,
    key: 'QCTypeVersion',
  },
  {
    title: 'คะแนนหน้างาน',
    span: 3,
    key: 'InspectionScore',
  },
  {
    title: 'ผลการตรวจ',
    span: 3,
    key: 'CheckStatus',
  },
  {
    title: 'วันที่ส่งรายงาน',
    span: 3,
    key: 'SendReportDate',
  },
  {
    title: 'ถอยสถานะ',
    span: 2,
    key: 'Backward',
  },
]

const mapStateToProps = (state: RootState) => {
  return {
    event: selectEvent(state),
    token: serviceTokenSelector(state),
    UserEmail: userEmailSelector(state),
    dataList: selectDataList(state),
    filter: state.adminQcBackwardUnit.Filter,
    initFilter: state.adminQcBackwardUnit.ClearFilter,
    sorting: selectSorting(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>
const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BackwardUnitListPage)
