import React from 'react'
import { Input, Menu, Dropdown } from 'antd'
import styled from 'styled-components'
import icSearch from 'assets/img/ic-search.png'

interface Props {
  placeholder: string
  value: string
  onChange: (value: string) => void
  showSuggess?: boolean
  searchContent?: any
  hideSuffix?: boolean
  readOnly?: boolean
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void
}

const InputSearch: React.FC<Props> = ({
  placeholder,
  value,
  onChange,
  showSuggess = false,
  searchContent,
  hideSuffix = false,
  readOnly = false,
  onKeyDown = () => {},
}) => {
  const valChange = (evt: any) => {
    onChange(evt.target.value)
  }

  const Data = <Menu className="dropdown-search">{searchContent}</Menu>

  const input = (
    <Input
      className="input-title"
      placeholder={placeholder}
      onChange={valChange}
      value={value}
      suffix={!hideSuffix ? <img src={icSearch} width="16" /> : <span />}
      readOnly={readOnly}
      maxLength={255}
      onKeyDown={onKeyDown}
    />
  )

  return (
    <InputTitle>
      {showSuggess ? (
        <Dropdown overlay={Data} trigger={['click']}>
          {input}
        </Dropdown>
      ) : (
        input
      )}
    </InputTitle>
  )
}

const InputTitle = styled.div`
  .input-title {
    text-align: left;
    height: 48px;
    border-radius: 5px;
    border-color: #e4e7eb;
    background-color: #ffffff;
    font-family: 'DB Helvethaica X 55 Regular';
    font-size: 18px;
    .ant-input-suffix {
      font-size: 20px;
      padding-right: 12px;
    }

    .ant-input {
      font-size: 20px;
      font-family: 'DB Helvethaica X 75 Bd';
      padding-left: 25px;
    }
    .ant-input:placeholder-shown {
      font-family: 'DB Helvethaica X 55 Regular';
    }
  }

  .ant-input:hover,
  .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
    border-color: #e4e7eb;
  }
`
export default InputSearch
