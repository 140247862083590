import { getType } from 'typesafe-actions'
import * as actions from './actions'
import { StoreState, SeemoreAction } from './types'
import produce from 'immer'
import parseErrorMessage from 'utils/parseErrorMessage'
import { filterActivity } from 'utils/helper'
import { sortingActivityList } from './function'
import { sortingTask } from 'utils/sorting'

const initialState: StoreState = {
  totalRow: 0,
  dataList: [],
  event: {
    isFetching: false,
    error: '',
  },
  sorting: {
    name: 'StartDate',
    type: 'asc',
  },
  query: {
    date: {
      start: undefined,
      end: undefined,
    },
  },
  filter: {
    progress: [],
    status: [],
    wbs: [],
    date: {
      mode: '',
      start: undefined,
      end: undefined,
    },
    floor: [],
    unit: [],
    zone: [],
    type: [],
  },
  originalData: [],
}

export default function(
  state: StoreState = initialState,
  action: SeemoreAction
): StoreState {
  return produce(state, draft => {
    const filter = state.filter

    switch (action.type) {
      case getType(actions.getQcActivityAction.request):
      case getType(actions.getProgressActivityAction.request):
      case getType(actions.getTodoActivityAction.request):
        draft.event = {
          isFetching: true,
          error: '',
        }

        draft.dataList = []
        break
      case getType(actions.getTodoActivityAction.success):
        const toDoRes = action.payload.ResponseData

        draft.event.isFetching = false
        const todoList = filterActivity(toDoRes.ToDo, filter)
        const todoSort = sortingActivityList(
          todoList,
          draft.sorting.type,
          draft.sorting.name
        )

        draft.dataList = todoSort
        draft.totalRow = toDoRes.TotalRows
        draft.originalData = toDoRes.ToDo

        break
      case getType(actions.getProgressActivityAction.success):
        const progressRes = action.payload.ResponseData

        draft.event.isFetching = false
        const progressList = filterActivity(progressRes.Processing, filter)
        const progressSort = sortingActivityList(
          progressList,
          draft.sorting.type,
          draft.sorting.name
        )

        draft.dataList = progressSort
        draft.totalRow = progressRes.TotalRows
        draft.originalData = progressRes.Processing

        break
      case getType(actions.getQcActivityAction.success):
        const qcRes = action.payload.ResponseData
        draft.event.isFetching = false

        const qcList = filterActivity(qcRes.FixFromQC, filter)
        const qcSort = sortingActivityList(
          qcList,
          state.sorting.type,
          state.sorting.name
        )

        draft.dataList = qcSort

        break
      case getType(actions.getQcActivityAction.failure):
      case getType(actions.getProgressActivityAction.failure):
      case getType(actions.getTodoActivityAction.failure):
        draft.event = {
          isFetching: false,
          error: parseErrorMessage(action.payload),
        }
        break

      case getType(actions.sortingTask):
        const sorting = state.sorting
        const params = action.payload
        if (sorting.name === params.name) {
          draft.sorting.type = sorting.type === 'asc' ? 'desc' : 'asc'
        } else {
          draft.sorting.name = params.name
          draft.sorting.type = 'asc'
        }
        if (params.sort != null) {
          draft.sorting.type = params.sort
        }

        const list = sortingActivityList(
          draft.dataList,
          draft.sorting.type,
          draft.sorting.name
        )
        draft.dataList = list
        break
      case getType(actions.setFilterSeemore):
        draft.filter = { ...action.payload }
        break
      case getType(actions.filterSeemore):
        let filtterData = filterActivity(draft.originalData, draft.filter)
        const sortingData = sortingTask(
          filtterData,
          state.sorting.type,
          state.sorting.name
        )
        draft.dataList = sortingData
        break
      /*
      case getType(actions.updateListAction):
        const { todo = [], inProgress = [], completed = [] } = action.payload
        draft.todo = todo
        draft.inProgress = inProgress
        draft.completed = completed
        break
      case getType(actions.setFilterDashboard):
        draft.filter = { ...action.payload }
        break
      case getType(actions.setQueryDashboard):
        draft.query = { ...action.payload }
        break
        */
      default:
        return state
    }
  })
}
