import withAPIAction from 'utils/withAPIAction'
import { verifyReq } from 'utils/verify'
import { getHistoryActivityAction } from './actions'
import { GetHistoryActivityReq } from './types'

export const getHistoryActivity = (
  token: string,
  data: GetHistoryActivityReq
) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<GetHistoryActivityReq>({
    method: 'POST',
    path: 'GetQCHistoryById',
    data,
    token: `Bearer ${token}`,
  })(getHistoryActivityAction)
}
