import { cloneDeep } from 'lodash'

import { toArray, getInt, getText, isDuplicate } from './common'

import { ZoneDetail, JobType, SubJobType } from 'features/admin/zone/types'

import { original } from 'features/admin/zone/reducer'

export const toCheckList = (data: string): any => {
  let checklist: ZoneDetail = cloneDeep(original)

  let mode = ''
  const rows = toArray(data)
  rows.forEach((row: any, index: number) => {
    const cmd = getText(row, 0).toLowerCase()
    switch (cmd) {
      case 'checklist name':
        mode = ''
        checklist.CheckListName = getText(row, 1)
        break

      case 'checklist type':
        mode = ''
        checklist.ProjectTypeID = getInt(row, 1)
        break

      case 'description':
        mode = 'description'
        addDefectDetail(checklist, row, index)
        break

      case 'note':
        mode = ''
        break

      case 'end':
        return checklist

      default:
        if (mode === 'description') addDefectDetail(checklist, row, index)
        else mode = ''

        break
    }
  })

  return checklist
}

const addDefectDetail = (
  checklist: ZoneDetail,
  row: string[],
  index: number
) => {
  const job = getText(row, 1)
  const zoneType = getInt(row, 2)
  const subJob = getText(row, 3)
  const detail = getText(row, 4)
  const major = getText(row, 5)
  const supplier = getText(row, 6)

  if (detail === '') return

  let jobItem: JobType = {
    JobTypeID: 0,
    JobTypeName: job,
    SubJobTypeList: [],
    LocationList: [],
    ZoneTypeID: zoneType,
    ZoneType: '',
  }

  if (job !== '') {
    jobItem.LocationList = getLocationList(row, 7, index)
    isDuplicate('JobTypeName', job, checklist.JobTypeList, index)
    checklist.JobTypeList.push(jobItem)
  }

  const jobIndex = checklist.JobTypeList.length - 1
  jobItem = checklist.JobTypeList[jobIndex]

  let subJobItem: SubJobType = {
    SubJobTypeID: 0,
    SubJobTypeName: subJob,
    DetailList: [],
  }

  if (subJob !== '') {
    isDuplicate('SubJobTypeName', subJob, jobItem.SubJobTypeList, index)
    jobItem.SubJobTypeList.push(subJobItem)
  }

  const subJobIndex = jobItem.SubJobTypeList.length - 1
  subJobItem = jobItem.SubJobTypeList[subJobIndex]

  const detailItem = {
    DetailID: 0,
    DetailName: detail,
    IsRequired: major === 'yes',
    IsSupplier: supplier === 'yes',
  }

  isDuplicate('DetailName', detail, subJobItem.DetailList, index)
  subJobItem.DetailList.push(detailItem)
}

const getLocationList = (row: string[], start: number, index: number) => {
  const list: any[] = []
  const len = row.length
  for (let i = start; i < len; i++) {
    const text = getText(row, i)
    if (text === '') return list

    isDuplicate('LocationName', text, list, index)
    list.push({
      LocationID: 0,
      LocationName: text,
      Score: 0,
    })
  }

  return list
}
