import { put, take, takeEvery, select } from 'redux-saga/effects'
import { cloneDeep } from 'lodash'
import { getType } from 'typesafe-actions'
import * as actions from './actions'
import { saveCheckListForQC } from './api'
import { uploadFile } from '../../uploadfile/func'
import { getUserProfile } from 'features/sensitive/sensitive.selector'

function* uploadFileList(
  userEmail: string,
  token: string,
  image: any,
  username: string
) {
  if (!image) return image

  return yield uploadFile(userEmail, token, username, image)
}
/*
function* signReq(action: any) {
  const { Token, Req } = action.payload
  try {
    Req.SignImage = yield uploadFileList(Req.UserEmail, Token, Req.SignImage)
    yield put(saveCheckListForQC(Token, Req))
  } catch (e) {
    console.log('e', e.message)
  }
}
*/
function* uploadImage(action: any) {
  const { Token, UserEmail, Image, index = -1 } = action.payload
  try {
    const profile = yield select(getUserProfile)
    const { FirstName = '', LastName = '' } = profile
    const username = `${FirstName} ${LastName} (QC)`

    const image = yield uploadFileList(UserEmail, Token, Image, username)
    if (image) {
      image.text = Image.text
      yield put(actions.updateNewImage({ image, index }))
    }
  } catch (e) {
    console.log('e', e.message)
  }
}

function* allSaga() {
  // yield takeEvery(getType(actions.submitCommissionReq), signReq)
  yield takeEvery(getType(actions.uploadImage), uploadImage)
}

export default allSaga
