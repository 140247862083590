import React from 'react'
import styled from 'styled-components'
import { getDefectColor } from 'utils/color-map'
import { numberFormat } from 'utils/format'
import { DefectStatus } from 'features/types/status'

type Props = {
  pinStatus: any
  pinScore: number
  prefix?: any
  suffix?: any
}

const DefectScore: React.FunctionComponent<Props> = props => {
  const { pinStatus, pinScore, prefix, suffix } = props

  const displayScore = (pinScore: number) => {
    let score = numberFormat(pinScore)
    switch (pinStatus) {
      case DefectStatus.GOODWORK:
      case DefectStatus.PASS:
      case DefectStatus.FIXED:
        score = '+0.00'
        break
      default:
        if (pinScore > 0) {
          score = `-${score}`
        }
        break
    }
    return score
  }

  const defect = getDefectColor(pinStatus)
  return (
    <Score style={{ color: defect.textColor }}>
      {prefix}
      {displayScore(pinScore)}
      {suffix}
    </Score>
  )
}

const Score = styled.span`
  border-radius: 10px;
  padding: 2px 8px;
  font-family: 'DB Helvethaica X 75 Bd';
  display: block;
  align-items: center;
  float: left;
  line-height: 1;
`

export default DefectScore
