import RootState from 'typings/RootState'
import { createSelector } from 'reselect'
import { DataList, MainCon, RequestHistory } from './types'
import { Event } from '../../types/event'

export const selectDataList = (state: RootState): DataList =>
  state.adminqcUnit.dataList

export const selectMainconList = (state: RootState): MainCon[] =>
  state.adminqcUnit.mainconList

export const selectRequestHistory = (state: RootState): RequestHistory[] =>
  state.adminqcUnit.requestHistoryList

export const selectEvent = (state: RootState): Event => state.adminqcUnit.event
