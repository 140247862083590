import { createStandardAction, createAsyncAction } from 'typesafe-actions'
import {
  GetDataListReq,
  GetDataListResp,
  UpdateDataReq,
  CommonResp,
  GetActiveProjectsReq,
  GetActiveProjectsResp,
  UpdateMultiProjectReq,
} from './types'

export const getDataListAction = createAsyncAction(
  'admin/project/FETCH_LIST_REQUEST',
  'admin/project/FETCH_LIST_SUCCESS',
  'admin/project/FETCH_LIST_FAILURE'
)<GetDataListReq, GetDataListResp, any>()

export const exportCurrentDataAction = createAsyncAction(
  'admin/project/EXPORT_LIST_REQUEST',
  'admin/project/EXPORT_LIST_SUCCESS',
  'admin/project/EXPORT_LIST_FAILURE'
)<GetDataListReq, GetDataListResp, any>()

export const updateDataAction = createAsyncAction(
  'admin/project/UPDATE_DATA_REQ',
  'admin/project/UPDATE_DATA_RESP',
  'admin/project/UPDATE_DATA_FAIL'
)<UpdateDataReq, CommonResp, any>()

export const updateMultiProjectAction = createAsyncAction(
  'admin/project/UPDATE_MULTI_PROJECT_REQ',
  'admin/project/UPDATE_MULTI_PROJECT_RESP',
  'admin/project/UPDATE_MULTI_PROJECT_FAIL'
)<UpdateMultiProjectReq, CommonResp, any>()

export const getActiveProjectListAction = createAsyncAction(
  'admin/project/FETCH_ACTIVE_LIST_REQUEST',
  'admin/project/FETCH_ACTIVE_LIST_SUCCESS',
  'admin/project/FETCH_ACTIVE_LIST_FAILURE'
)<GetActiveProjectsReq, GetActiveProjectsResp, any>()

export const resetEvent = createStandardAction(
  'admin/project/RESET_EVENT_ACTION'
)<void>()
