import React, { useState, useEffect } from 'react'
import { Modal, Button, Row, Col } from 'antd'
import styled from 'styled-components'
import iconApproval from 'assets/img/ic-approval.png'
import { SecondaryButton, PrimaryButton } from 'Components/Shared/Button'

type Props = {
  onClose: () => void
  onSubmit: () => void
  visible?: boolean
  title?: string
}

const ApproveModal = (props: Props) => {
  const {
    visible = false,
    onClose,
    onSubmit,
    title = 'คุณต้องการอนุมัติงานนี้ใช่ไหม',
  } = props

  return (
    <Modal
      className="approve-modal"
      title={false}
      centered
      visible={visible}
      footer={false}
      closable={false}
    >
      <Body>
        <img src={iconApproval} width={60}/>
        <Header>{title}</Header>
        <ButtonSection>
          <Row type="flex" justify="center">
            <Col>
              <div className="btn-submit">
                <SecondaryButton
                  text="ยกเลิก"
                  disabled={false}
                  onClick={() => onClose()}
                />
              </div>
            </Col>
            <Col>
              <div className="btn-submit">
                <PrimaryButton
                  text="ยืนยัน"
                  onClick={() => onSubmit()}
                />
              </div>
            </Col>
          </Row>
        </ButtonSection>
      </Body>
    </Modal>
  )
}

const Header = styled.div`
  text-align: center;
  font-family: 'DB Helvethaica X 55 Regular';
  font-size: 20px;
  color: #000;
  margin-top: 16px;
`

const Body = styled.div`
  text-align: center;
`


const ButtonSection = styled.div`
  margin-top: 32px;
  .btn-submit {
    width: 132px;
    margin: 0 8px;
  }
`

export default ApproveModal
