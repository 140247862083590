import React from 'react'
import styled from 'styled-components'
import { DropdownList, DropdownPopupMenu } from 'Components/Shared/Form'
import DateDurationContent from 'Components/Shared/Filters/DateDurationContent'
import { toDateText } from 'utils/datetime'

type Props = {
  onFilterChange: (filter: any) => void
  filter: any
}

const DateFilter: React.FunctionComponent<Props> = props => {
  const { onFilterChange, filter } = props

  const dateFilter = filter.Date.Mode
    ? `${toDateText(filter.Date.StartDate || '')} ถึง ${toDateText(
        filter.Date.FinishDate || ''
      )}`
    : ''

  return (
    <Box>
      <DropdownPopupMenu
        label={'ช่วงเวลา'}
        value={dateFilter}
        placeholder="เลือกวันที่"
        content={
          <DateDurationContent
            filter={filter.Date}
            onChange={data => {
              filter.Date = data
              onFilterChange(filter)
            }}
          />
        }
        isActive={filter.Date.Mode !== ''}
      />
    </Box>
  )
}

const Box = styled.div`
  padding: 0 12px 12px 0;
`

export default DateFilter
