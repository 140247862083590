import { createStandardAction, createAsyncAction } from 'typesafe-actions'
import { GetDashboardReq, GetActivityApiResponse, Filter } from './types'

export const getDashbaordAction = createAsyncAction(
  'cmpj/commissioning/dashboard/FETCH_COMMISSIONING_DASHBOARD_REQUEST',
  'cmpj/commissioning/dashboard/FETCH_COMMISSIONING_DASHBOARD_SUCCESS',
  'cmpj/commissioning/dashboard/FETCH_COMMISSIONING_DASHBOARD_FAILURE'
)<GetDashboardReq, GetActivityApiResponse, any>()

export const sortingDashboard = createStandardAction(
  'cmpj/commissioning/dashboard/SORTING'
)<string>()

export const updateFilter = createStandardAction(
  'cmpj/commissioning/CONFIG_FILTER_DASHBOARD'
)<Filter>()

export const clearFilter = createStandardAction(
  'cmpj/commissioning/CLEAR_FILTER_DASHBOARD'
)<void>()
