import { all, takeEvery, put, select, call } from 'redux-saga/effects'
import { getType } from 'typesafe-actions'
import * as actions from './actions'
import {
  serviceTokenSelector,
  userEmailSelector,
} from 'features/sensitive/sensitive.selector'
import APIManager, { configWithToken } from 'services/APIManager'
import { APIPath } from 'services/APIPath'
import { getLocalCheckList } from './api'

function* importMasterCheckList(
  action: ReturnType<typeof actions.importMasterCheckListReq.request>
) {
  try {
    const { ProjectCode, SelectedAll, selectList } = action.payload

    const Token = yield select(serviceTokenSelector)
    const UserEmail = yield select(userEmailSelector)

    const res = yield call(
      APIManager.call,
      APIPath.ImportMasterMainconCheckList,
      'POST',
      {
        UserEmail: UserEmail,
        ProjectCode,
        SelectedAll: SelectedAll ? 'Y' : 'N',
        CheckListID: SelectedAll ? [] : selectList,
      },
      configWithToken(`Bearer ${Token}`)
    )

    yield put(actions.importMasterCheckListReq.success(res))
    yield put(getLocalCheckList(ProjectCode, 1, 20))
  } catch (e) {
    yield put(actions.importMasterCheckListReq.failure(e))
  }
}

function* localSaga() {
  yield all([
    yield takeEvery(
      getType(actions.importMasterCheckListReq.request),
      importMasterCheckList
    ),
  ])
}

export default localSaga
