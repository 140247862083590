import React, { useState } from 'react'

import AddModal from './AddModal'
import DeleteModal from './DeleteModal'
import CheckItem from './CheckItem'
import AddCheckList from './AddCheckList'

import {
  InspectionList,
  InspectionData,
  InspectionSubData,
} from 'features/types/checklist'

import { CheckListDetail } from 'features/checklist/master/types'

interface Props {
  checklist: CheckListDetail
  onChange: (val: CheckListDetail) => void
  readOnly: boolean
}

const CheckList: React.FC<Props> = ({ checklist, onChange, readOnly }) => {
  const [visibleAdd, setVisibleAdd] = useState(false)
  const [visibleDelete, setVisibleDelete] = useState(false)
  const [visibleEdit, setVisibleEdit] = useState(false)
  const [cateIndex, setCateIndex] = useState(-1)
  const [itemIndex, setItemIndex] = useState(-1)
  const [subItemIndex, setSubItemIndex] = useState(-1)
  const [deleteName, setDeleteName] = useState('')

  const onAdd = (iCate: number, iItem: number, iSubItem: number) => {
    setVisibleAdd(true)
    setCateIndex(iCate)
    setItemIndex(iItem)
    setSubItemIndex(iSubItem)
  }

  const onDelete = (
    name: string,
    iCate: number,
    iItem: number,
    iSubItem: number
  ) => {
    setVisibleDelete(true)
    setCateIndex(iCate)
    setItemIndex(iItem)
    setSubItemIndex(iSubItem)
    setDeleteName(name)
  }

  const onEdit = (iCate: number, iItem: number, iSubItem: number) => {
    setVisibleEdit(true)
    setCateIndex(iCate)
    setItemIndex(iItem)
    setSubItemIndex(iSubItem)
  }

  const closeAdd = () => setVisibleAdd(false)
  const closeDelete = () => setVisibleDelete(false)
  const closeEdit = () => setVisibleEdit(false)

  const confirmAdd = (
    name: string,
    iCate: number,
    iItem: number,
    iSubItem: number,
    seq: string
  ) => {
    try {
      if (iCate !== -1 && iItem !== -1) {
        const item: InspectionSubData = {
          Name: name,
          Status: 'NotCheck',
          Remark: '',
          Seq: seq,
          Level: 3,
        }
        checklist.InspectionList[iCate].Data[iItem].Data.push(item)
      } else if (iCate !== -1) {
        const item: InspectionData = {
          Name: name,
          Status: 'NotCheck',
          Remark: '',
          Seq: seq,
          Level: 2,
          Data: [],
        }
        checklist.InspectionList[iCate].Data.push(item)
      } else {
        const item: InspectionList = {
          InspectionCategory: name,
          Seq: seq,
          Level: 1,
          Data: [],
        }
        checklist.InspectionList.push(item)
      }

      onChange({ ...checklist })
      closeAdd()
    } catch (e) {}
  }

  const confirmDelete = () => {
    if (subItemIndex !== -1 && itemIndex !== -1 && cateIndex !== -1) {
      checklist.InspectionList[cateIndex].Data[itemIndex].Data.splice(
        subItemIndex,
        1
      )
    } else if (itemIndex !== -1 && cateIndex !== -1) {
      checklist.InspectionList[cateIndex].Data.splice(itemIndex, 1)
    } else if (cateIndex !== -1) {
      checklist.InspectionList.splice(cateIndex, 1)
    }

    onChange({ ...checklist })
    closeDelete()
  }

  const confirmEdit = (
    name: string,
    iCate: number,
    iItem: number,
    iSubItem: number
  ) => {
    try {
      if (iSubItem !== -1 && iItem !== -1 && iCate !== -1) {
        checklist.InspectionList[iCate].Data[iItem].Data[iSubItem].Name = name
      } else if (iItem !== -1 && iCate !== -1) {
        checklist.InspectionList[iCate].Data[iItem].Name = name
      } else if (iCate !== -1) {
        checklist.InspectionList[iCate].InspectionCategory = name
      }

      onChange({ ...checklist })
      closeEdit()
    } catch (e) {}
  }

  const thirdLevel = (
    data: any,
    parentNo: string,
    iCate: number,
    iItem: number
  ) => {
    const checklists = data.map((item: any, index: number) => {
      const no = `${parentNo}.${index + 1}`
      return (
        <CheckItem
          key={index}
          text={`${no} ${item.Name}`}
          level="third"
          iCate={iCate}
          iItem={iItem}
          iSubItem={index}
          onDelete={onDelete}
          onEdit={onEdit}
          readOnly={readOnly}
        />
      )
    })

    const nextIndex = data.length + 1
    return (
      <>
        {checklists}
        <AddCheckList
          text={`เพิ่มรายการข้อย่อย ${parentNo}.${nextIndex}`}
          level="third"
          readOnly={readOnly}
          onClick={() => onAdd(iCate, iItem, -1)}
        />
      </>
    )
  }

  const secondLevel = (data: any, parentNo: string, iCate: number) => {
    const checklists = data.map((item: any, index: number) => {
      const no = `${parentNo}.${index + 1}`
      return (
        <div key={index}>
          <CheckItem
            key={no}
            text={`${no} ${item.Name}`}
            level="second"
            iCate={iCate}
            iItem={index}
            onDelete={onDelete}
            onEdit={onEdit}
            readOnly={readOnly}
          />
          {item.Data && thirdLevel(item.Data, no, iCate, index)}
        </div>
      )
    })

    const nextIndex = data.length + 1
    return (
      <>
        {checklists}
        <AddCheckList
          text={`เพิ่มรายการข้อย่อย ${parentNo}.${nextIndex}`}
          level="second"
          readOnly={readOnly}
          onClick={() => onAdd(iCate, -1, -1)}
        />
      </>
    )
  }
  console.log('CheckList :', readOnly)
  const content = checklist.InspectionList.map((item: any, index: number) => {
    const no = `${index + 1}`
    return (
      <div key={index}>
        <CheckItem
          text={`${no}. ${item.InspectionCategory}`}
          level="first"
          key={index}
          iCate={index}
          onDelete={onDelete}
          onEdit={onEdit}
          readOnly={readOnly}
        />
        {item.Data && secondLevel(item.Data, no, index)}
      </div>
    )
  })

  return (
    <>
      {content}
      <AddCheckList
        text="เพิ่มรายการหลัก"
        level="first"
        readOnly={readOnly}
        onClick={() => onAdd(-1, -1, -1)}
      />

      <AddModal
        initCategory={cateIndex}
        initItem={itemIndex}
        initSubItem={subItemIndex}
        visible={visibleAdd}
        onClose={closeAdd}
        onOk={confirmAdd}
        checklist={checklist}
      />

      <DeleteModal
        visible={visibleDelete}
        onClose={closeDelete}
        onOk={confirmDelete}
        name={deleteName}
      />

      <AddModal
        initCategory={cateIndex}
        initItem={itemIndex}
        initSubItem={subItemIndex}
        visible={visibleEdit}
        onClose={closeEdit}
        onOk={confirmEdit}
        isEditMode={true}
        readOnly={readOnly}
        checklist={checklist}
      />
    </>
  )
}

export default CheckList
