import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useEffect,
} from 'react'
import { Modal, Row, Col } from 'antd'
import styled from 'styled-components'

import { CloseIcon } from 'Components/Shared/Icon'
import { PrimaryButton, OutlineButton } from 'Components/Shared/Button'
import { ProjectListItem } from 'features/project/project.types'
import SearchSelection from 'Components/Inspection/Activity/ActivityList/SearchSelection'
import { RadioBox } from 'Components/Shared/Form'

export interface ProjectModalHandles {
  showModal: () => void
}

interface Props {
  onOk: (value: ProjectListItem) => void
  projects: ProjectListItem[]
}

const ProjectModal = forwardRef<ProjectModalHandles, Props>(
  ({ onOk: onOkProps, projects: projectsProps }, ref) => {
    const [visible, setvisible] = useState(false)
    const [projects, setPropjects] = useState<ProjectListItem[]>([])
    const [filterTxt, setFilterTxt] = useState('')
    const [selectProject, setSelectProject] = useState<ProjectListItem | null>(
      null
    )

    useEffect(() => {
      setPropjects(projectsProps)
    }, [projectsProps])

    const closeModal = () => {
      setvisible(false)
    }

    const onOk = () => {
      if (selectProject) {
        onOkProps(selectProject)
        setSelectProject(null)
      }
      setvisible(false)
    }

    useImperativeHandle(ref, () => ({
      showModal: () => {
        setvisible(true)
      },
    }))

    const onChange = (value: ProjectListItem) => {
      setSelectProject(value)
    }

    const onFilter = (value: string) => {
      setFilterTxt(value)
    }

    const hasText = (data: string, searchTxt: string) => {
      if (filterTxt === '') return true
      if (data && searchTxt) {
        return data.toLocaleLowerCase().includes(searchTxt.toLocaleLowerCase())
      }
      return false
    }

    return (
      <ModalCheckList>
        <Modal
          centered
          visible={visible}
          width="528px"
          footer={null}
          closeIcon={<CloseIcon color="#8b99a7" size={16} />}
          title={<Header>{'เพิ่มโครงการที่ใช้งาน'}</Header>}
          className="add-checklist-modal"
          onCancel={closeModal}
        >
          <ModalContent>
            <SearchSelection onChange={onFilter} />

            <ListSection>
              {projects
                .filter(
                  p =>
                    hasText(p.ProjectNameTH, filterTxt) ||
                    hasText(p.ProjectNameEN, filterTxt) ||
                    hasText(p.ProjectCode, filterTxt)
                )
                .map((item: ProjectListItem, index: number) => {
                  return (
                    <div style={{ marginTop: 10 }} key={index}>
                      <RadioBox
                        label={`${item.ProjectCode} - ${item.ProjectNameTH}`}
                        value={item}
                        isCheck={
                          selectProject
                            ? item.ProjectCode === selectProject.ProjectCode
                            : false
                        }
                        onClick={onChange}
                        spaceBtwText={16}
                      />
                    </div>
                  )
                })}
            </ListSection>

            <ButtonSection>
              <Row>
                <Col span={7} offset={9}>
                  <OutlineButton
                    text="ยกเลิก"
                    disabled={false}
                    onClick={closeModal}
                  />
                </Col>
                <Col span={7} offset={1}>
                  <PrimaryButton
                    text="บันทึก"
                    disabled={false}
                    onClick={onOk}
                  />
                </Col>
              </Row>
            </ButtonSection>
          </ModalContent>
        </Modal>
      </ModalCheckList>
    )
  }
)

const Header = styled.span`
  font-size: 28px;
  color: #000000;
  font-family: 'DB Helvethaica X 75 Bd';
`

const ModalCheckList = styled.div`
  .add-checklist-modal {
    .ant-modal-header {
      border-bottom: none;
    }
  }
`

const ModalContent = styled.div`
  padding: 0 24px 31px 24px;
  .ant-input {
    font-size: 20px;
  }
`

const ButtonSection = styled.div`
  padding-top: 18px;
`

const ListSection = styled.div`
  margin-top: 16px;
  height: 290px;
  overflow-y: scroll;
  padding: 0 8px;
`

export default ProjectModal
