import React from 'react'
import { Col, Row } from 'antd'
import styled from 'styled-components'
import { TableRow } from '../../../Shared/Table'
import { DataItem } from 'features/adminpj/primavera/types'
import { ContructionTagName } from 'Components/Shared'
import { UndoIcon } from 'Components/Shared/Icon'

type DefaultProps = {
  item: DataItem
  hideColumn: any[]
  getSpan(key: string): number
  selected?: boolean
  onClick: (evt: any, item: any) => void
  defaultColumn: any
  onReturn: () => void
}

type Props = DefaultProps

const RowItem: React.FC<Props> = props => {
  const { item, hideColumn, selected, defaultColumn, onReturn } = props
  const isDeleted = item.Status === 'Deleted'

  const showColumn: any[] = defaultColumn.filter((item: any) => {
    return !hideColumn.includes(item.key)
  })

  const onClickReturn = (evt: any) => {
    evt.stopPropagation()
    onReturn()
  }

  const renderDeleted = (value: any) => {
    return (
      <Row type="flex" justify="start" align="middle">
        {isDeleted && (
          <Col style={{ paddingRight: '5px' }} onClick={onClickReturn}>
            <UndoIcon size={16} color={'#003168'} />
          </Col>
        )}
        <Col span={20}>
          <Name>{value}</Name>
        </Col>
      </Row>
    )
  }

  const allCol = (showColumn && showColumn.length) || 1
  const span = Math.floor(24 / allCol)
  let remain = 24 - allCol * span

  const renderData = (key: string, value: any) => {
    if (!hideColumn.includes(key)) {
      const add = remain > 0 ? 1 : 0
      remain = remain - add
      return (
        <Col span={span + add}>
          {key === 'ActivityID' ? renderDeleted(value) : <Name>{value}</Name>}
        </Col>
      )
    }
    return
  }

  const onClick = (evt: any) => {
    if (props.onClick) {
      props.onClick(evt, item)
    }
  }

  return (
    <>
      <TableRow
        onClick={onClick}
        minHeight={60}
        spaceBetweenRow={8}
        paddingLeft={27}
        selected={selected}
        backgroundColor={isDeleted ? 'rgba(208,73,76,0.2)' : '#fff'}
      >
        <Space />
        {renderData('ActivityID', item.ActivityID)}
        {renderData('ActivityName', item.ActivityName)}
        {renderData('Tower', item.Tower)}
        {renderData(
          'ConstructionType',
          <ContructionTagName name={item.ConstructionType} />
        )}
        {renderData('Floor', item.Floor)}
        {renderData('Zone', item.Zone)}
        {renderData('Unit', item.Unit)}
        {renderData('QCTypeID', item.QCTypeID)}
        {renderData('SOW', item.SOWCode)}
      </TableRow>
    </>
  )
}

const Name = styled.div`
  //font-family: 'DB Helvethaica X 75 Bd';
  color: #000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 4px;
`

const Space = styled.div``

export default RowItem
