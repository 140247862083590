import React, { useState } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Drawer } from 'antd'
import {
  CloseIcon,
  ConstructionIcon,
  MenuIcon,
  SiriconIcon,
} from 'Components/Shared/Icon/MenuIcon'

interface Props {
  selected?: string
}

const Menu: React.FunctionComponent<Props> = props => {
  const [display, setDisplay] = useState(false)
  const { selected } = props

  const selectedItem: any = {
    dashboard: false,
    siriconPlan: false,
  }

  switch (selected) {
    case 'dashboard':
      selectedItem.dashboard = true
      break
    case 'siriconPlan':
      selectedItem.siriconPlan = true
      break
  }

  const topIcon = display ? <CloseIcon /> : <MenuIcon />
  const css: any = { paddingLeft: display ? '55px' : '12px' }

  const getActiveClass = (isActive: boolean) => {
    return isActive ? 'selected' : ''
  }

  const content = (
    <>
      <Icon onClick={() => setDisplay(!display)}>{topIcon}</Icon>
      <Space />

      <ListIcon>
        <Link to="/">
          <IconMenu
            className={getActiveClass(selectedItem.dashboard)}
            style={css}
          >
            <ConstructionIcon active={selectedItem.dashboard} />
            {display && <label>ผู้รับเหมา</label>}
          </IconMenu>
        </Link>

        <Link to="/siricon-plan">
          <IconMenu
            className={getActiveClass(selectedItem.siriconPlan)}
            style={css}
          >
            <SiriconIcon active={selectedItem.siriconPlan} />
            {display && <label>ผังขาย/สร้าง/โอน</label>}
          </IconMenu>
        </Link>
      </ListIcon>
    </>
  )
  if (display) {
    return (
      <Drawer
        title={null}
        closable={false}
        width={255}
        placement="left"
        onClose={() => setDisplay(false)}
        visible={display}
        bodyStyle={{ padding: '0px' }}
      >
        <MainDisplayView>{content}</MainDisplayView>
      </Drawer>
    )
  }

  return <MainView>{content}</MainView>
}

const MainView = styled.div`
  background-color: #003168;
  height: 100vh;
  width: 48px;
  position: fixed;
  padding-top: 20px;
`

const MainDisplayView = styled.div`
  background-color: #003168;
  height: 100vh;
  width: 255px;
  position: fixed;
  padding-top: 20px;
`

const ListIcon = styled.div`
  width: 100%;

  .selected {
    background-color: #f5f6fa;
  }

  .selected > label {
    color: #003168;
  }
`

const Icon = styled.div`
  width: 100%;
  height: 48px;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
`

const IconMenu = styled.div`
  width: 100%;
  height: 48px;
  align-items: center;
  display: flex;
  cursor: pointer;

  label {
    margin-left: 12px;
    color: white;
    cursor: pointer;
    font-family: 'DB Helvethaica X 55 Regular';
    font-size: 18px;
  }
`

const Line = styled.div`
  border: 0px solid white;
  border-top-width: 1px;
  opacity: 0.2;
`
const BottomIcon = styled.div`
  position: absolute;
  bottom: 2px;
  width: 100%;
`

const Space = styled.div`
  margin-bottom: 150px;
`

export default Menu
