import { getType } from 'typesafe-actions'
import { cloneDeep } from 'lodash'

import * as actions from './actions'
import { StoreState, ReportAction } from './types'
import produce from 'immer'
import parseErrorMessage from 'utils/parseErrorMessage'
import { Event } from '../types/event'

const initEvent = {
  IsLoading: false,
  MessageSuccess: undefined,
  SubmitError: undefined,
  SubmitSuccess: false,
  SubmitType: undefined,
} as Event

const initActivityFilter = {
  ConstructionTypeList: [],
  Date: {
    Mode: '',
    StartDate: undefined,
    FinishDate: undefined,
  },
  FloorList: [],
  UnitList: [],
  ZoneList: [],
  WbsList: [],
  LeafWbsList: [],
}

const initUnitFilter = {
  UnitList: [],
  QCTypeList: [],
  WbsList: [],
  LeafWbsList: [],
}

const initZoneFilter = {
  ZoneList: [],
  FloorList: [],
  CheckNumber: [],
  WbsList: [],
  LeafWbsList: [],
}

const initRoadConstructFilter = {
  UnitList: [],
  QCTypeID: [],
  WbsList: [],
  LeafWbsList: [],
}

const initialState: StoreState = {
  ConstructType: {
    Event: cloneDeep(initEvent),
    List: [],
  },
  ZoneList: {
    Event: cloneDeep(initEvent),
    List: [],
  },
  UnitList: {
    Event: cloneDeep(initEvent),
    List: [],
  },
  QCTypeList: {
    Event: cloneDeep(initEvent),
    List: [],
  },
  FloorList: {
    Event: cloneDeep(initEvent),
    List: [],
  },
  TowerList: {
    Event: cloneDeep(initEvent),
    List: [],
  },
  ProjectType: {
    Event: cloneDeep(initEvent),
    List: [],
  },
  RoleList: {
    Event: cloneDeep(initEvent),
    List: [],
  },
  ActivityList: {
    Event: cloneDeep(initEvent),
    List: [],
  },
  activityFilter: cloneDeep(initActivityFilter),
  unitFilter: cloneDeep(initUnitFilter),
  zoneFilter: cloneDeep(initZoneFilter),
  roadConstructFilter: cloneDeep(initRoadConstructFilter),
  isClearFilter: true,
}

export default function(
  state: StoreState = initialState,
  action: ReportAction
): StoreState {
  return produce(state, draft => {
    switch (action.type) {
      case getType(actions.getConstructTypeAction.request):
        draft.ConstructType = {
          Event: cloneDeep(initEvent),
          List: [],
        }
        draft.ConstructType.Event.IsLoading = true
        break

      case getType(actions.getConstructTypeAction.success):
        draft.ConstructType.Event.IsLoading = false
        draft.ConstructType.List = action.payload.ResponseData.List

        break

      case getType(actions.getConstructTypeAction.failure):
        draft.ConstructType.Event.IsLoading = false
        draft.ConstructType.Event.SubmitError = parseErrorMessage(
          action.payload
        )
        break

      case getType(actions.getProjectTypeAction.request):
        draft.ProjectType = {
          Event: cloneDeep(initEvent),
          List: [],
        }
        draft.ProjectType.Event.IsLoading = true
        break

      case getType(actions.getProjectTypeAction.success):
        draft.ProjectType.Event.IsLoading = false
        draft.ProjectType.List = action.payload.ResponseData.List

        break

      case getType(actions.getProjectTypeAction.failure):
        draft.ProjectType.Event.IsLoading = false
        draft.ProjectType.Event.SubmitError = parseErrorMessage(action.payload)
        break

      case getType(actions.getZoneLisAction.request):
        draft.ZoneList = {
          Event: cloneDeep(initEvent),
          List: [],
        }
        draft.ZoneList.Event.IsLoading = true
        break

      case getType(actions.getZoneLisAction.success):
        draft.ZoneList.Event.IsLoading = false
        draft.ZoneList.List = action.payload.ResponseData.List

        break

      case getType(actions.getZoneLisAction.failure):
        draft.ZoneList.Event.IsLoading = false
        draft.ZoneList.Event.SubmitError = parseErrorMessage(action.payload)
        break

      case getType(actions.getUnitLisAction.request):
        draft.UnitList = {
          Event: cloneDeep(initEvent),
          List: [],
        }
        draft.UnitList.Event.IsLoading = true
        break

      case getType(actions.getUnitLisAction.success):
        draft.UnitList.Event.IsLoading = false
        draft.UnitList.List = action.payload.ResponseData.List

        break

      case getType(actions.getUnitLisAction.failure):
        draft.UnitList.Event.IsLoading = false
        draft.UnitList.Event.SubmitError = parseErrorMessage(action.payload)
        break

      case getType(actions.getFloorLisAction.request):
        draft.FloorList = {
          Event: cloneDeep(initEvent),
          List: [],
        }
        draft.FloorList.Event.IsLoading = true
        break

      case getType(actions.getFloorLisAction.success):
        draft.FloorList.Event.IsLoading = false
        draft.FloorList.List = action.payload.ResponseData.List

        break

      case getType(actions.getFloorLisAction.failure):
        draft.FloorList.Event.IsLoading = false
        draft.FloorList.Event.SubmitError = parseErrorMessage(action.payload)
        break

      case getType(actions.getTowerLisAction.request):
        draft.TowerList = {
          Event: cloneDeep(initEvent),
          List: [],
        }
        draft.TowerList.Event.IsLoading = true
        break

      case getType(actions.getTowerLisAction.success):
        draft.TowerList.Event.IsLoading = false
        draft.TowerList.List = action.payload.ResponseData.List

        break

      case getType(actions.getTowerLisAction.failure):
        draft.TowerList.Event.IsLoading = false
        draft.TowerList.Event.SubmitError = parseErrorMessage(action.payload)
        break

      case getType(actions.getQCTypeLisAction.request):
        draft.QCTypeList = {
          Event: cloneDeep(initEvent),
          List: [],
        }
        draft.QCTypeList.Event.IsLoading = true
        break

      case getType(actions.getQCTypeLisAction.success):
        draft.QCTypeList.Event.IsLoading = false
        draft.QCTypeList.List = action.payload.ResponseData.List

        break

      case getType(actions.getQCTypeLisAction.failure):
        draft.QCTypeList.Event.IsLoading = false
        draft.QCTypeList.Event.SubmitError = parseErrorMessage(action.payload)
        break

      case getType(actions.getActivityLisAction.request):
        draft.ActivityList = {
          Event: cloneDeep(initEvent),
          List: [],
        }
        draft.ActivityList.Event.IsLoading = true
        break

      case getType(actions.getActivityLisAction.success):
        draft.ActivityList.Event.IsLoading = false
        draft.ActivityList.List = action.payload.ResponseData.List
        break

      case getType(actions.getActivityLisAction.failure):
        draft.ActivityList.Event.IsLoading = false
        draft.ActivityList.Event.SubmitError = parseErrorMessage(action.payload)
        break

      // update filter
      case getType(actions.updateActivityFilter):
        draft.activityFilter = { ...action.payload }
        draft.isClearFilter = false
        break

      case getType(actions.updateUnitFilter):
        draft.unitFilter = { ...action.payload }
        draft.isClearFilter = false
        break

      case getType(actions.updateZoneFilter):
        draft.zoneFilter = { ...action.payload }
        draft.isClearFilter = false
        break

      case getType(actions.updateRoadConstructFilter):
        draft.roadConstructFilter = { ...action.payload }
        draft.isClearFilter = false
        break

      case getType(actions.clearFilter):
        draft.activityFilter = cloneDeep({ ...initActivityFilter })
        draft.unitFilter = cloneDeep({ ...initUnitFilter })
        draft.zoneFilter = cloneDeep({ ...initZoneFilter })
        draft.roadConstructFilter = cloneDeep({ ...initRoadConstructFilter })
        draft.isClearFilter = true
        break

      case getType(actions.getRoleListAction.request):
        draft.RoleList = {
          Event: cloneDeep(initEvent),
          List: [],
        }
        draft.RoleList.Event.IsLoading = true
        break

      case getType(actions.getRoleListAction.success):
        draft.RoleList.Event.IsLoading = false
        draft.RoleList.List = action.payload.ResponseData.List

        break

      case getType(actions.getRoleListAction.failure):
        draft.RoleList.Event.IsLoading = false
        draft.RoleList.Event.SubmitError = parseErrorMessage(action.payload)
        break

      default:
        return state
    }
  })
}
