import React, { useEffect, useState } from 'react'
import { cloneDeep } from 'lodash'
import styled from 'styled-components'
import { connect, useDispatch } from 'react-redux'
import { Layout } from 'antd'
import { RouteComponentProps } from 'react-router-dom'
import RootState from 'typings/RootState'

import {
  userEmailSelector,
  serviceTokenSelector,
} from 'features/sensitive/sensitive.selector'
import {
  selectData,
  selectReq,
  selectEvent,
  selectDocReject,
} from 'features/qc/document/selector'
import {
  getDocumentCheckList,
  saveDocumentCheckList,
} from 'features/qc/document/api'
import { GetDocumentReq, SaveDocumentReq } from 'features/qc/document/types'
import { updateData, updateRejectList } from 'features/qc/document/actions'
import EventModal from 'Components/Shared/Modal/EventModal'
import PageFooter from 'Components/CheckDocument/PageFooter'
import ContentBox from 'Components/CheckDocument/ContentBox'
import Breadcrumbs from './Breadcrumbs'
import { verifyDocumentRemark } from 'utils/verify'
const { Content } = Layout

type Props = ReduxProps &
  DispatchProps &
  RouteComponentProps<{
    project: string
    unit: string
    checkno: string
    requestID: string
    qcType: string
    qcTypeVersion: string
  }>

const DocumentCheck: React.ComponentType<Props> = props => {
  const {
    request,
    checklist,
    event,
    token,
    UserEmail,
    match,
    history,
    selected, // selected rej list used state with store ( cus when click view details link -> rej selected is lost )
  } = props
  const { params } = match
  const { project, unit, checkno, requestID, qcType, qcTypeVersion } = params

  const [enableModal, setEnableModal] = useState(false)
  const RequestID = parseInt(requestID)
  const CheckNumber = parseInt(checkno)
  const readOnly = checklist.SendDocumentStatus === 'Y'

  const dispatch = useDispatch()
  useEffect(() => {
    const req: GetDocumentReq = {
      UserEmail,
      ProjectCode: project,
      Unit: unit,
      CheckNumber,
      RequestID,
      QCTypeID: qcType,
      QCTypeVersion: qcTypeVersion,
    }

    if (
      request.ProjectCode !== project ||
      request.Unit !== unit ||
      request.CheckNumber !== CheckNumber ||
      request.RequestID !== RequestID ||
      request.QCTypeID !== qcType ||
      request.QCTypeVersion !== qcTypeVersion
    ) {
      dispatch(getDocumentCheckList(token, req))
    } else if (props.location.search !== '') {
      dispatch(getDocumentCheckList(token, req))
    }
  }, [getDocumentCheckList, match])

  const onSave = () => {
    let newChecklist = checklist

    /// Insert isReject
    if (selected.length > 0) {
      selected.map((sel: any) => {
        const findIndex = newChecklist.ActivityList.findIndex(
          i => sel.ActivityID === i.ActivityID
        )
        if (findIndex > -1) {
          newChecklist.ActivityList[findIndex]['IsReject'] = true
        }
      })
    }

    setEnableModal(true)
    const req: SaveDocumentReq = {
      UserEmail,
      ProjectCode: project,
      Unit: unit,
      CheckNumber,
      RequestID,
      QCTypeID: qcType,
      QCTypeVersion: qcTypeVersion,
      ActivityList: newChecklist.ActivityList,
    }
    dispatch(saveDocumentCheckList(token, req))
  }

  const onUpdateData = (
    key: string,
    activityID: string,
    selectedIndex: number,
    value: any
  ) => {
    const index = checklist.ActivityList.findIndex(
      item => item.ActivityID === activityID
    )
    if (index > -1 && (key === 'Status' || key === 'Remark')) {
      checklist.ActivityList[index].DocumentList[selectedIndex][key] = value
      dispatch(updateData({ ...checklist }))
    }
  }

  const closeEnableModal = () => setEnableModal(false)
  const calScore = () => {
    let total = 0
    checklist.ActivityList.forEach(ele => {
      let subScore = 0
      ele.DocumentList.forEach(item => {
        if (item.Status === 'Check') {
          subScore += item.Score
        }
      })

      total += subScore > ele.Score ? ele.Score : subScore
    })

    return 100 - total
  }

  const gotoDashboard = () => {
    setEnableModal(false)
    history.push('/unit/dashboard')
  }

  const link = `/unit/activity/${project}/${unit}/${qcType}/${requestID}/${checkno}/${qcTypeVersion}`

  const onSelected = (value: number) => {
    let newData = selected
    const resultIndex = selected.findIndex(
      (item: any) => item.ActivityID === value
    )
    if (resultIndex != -1) {
      newData.splice(resultIndex, 1)
    } else {
      newData.push({ ActivityID: value })
    }
    dispatch(updateRejectList([...selected]))
    console.log(selected)
  }

  const content = checklist.ActivityList.map((activity, index) => {
    return (
      <ContentBox
        link={`${link}/${activity.ActivityID}`}
        activityData={activity}
        key={index}
        onUpdateData={(key, index, value) =>
          onUpdateData(key, activity.ActivityID, index, value)
        }
        readOnly={readOnly}
        onSelected={value => onSelected(value)}
        selectedList={selected}
      />
    )
  })

  const score = calScore()
  const isRemarkEmpty = verifyDocumentRemark(checklist.ActivityList)

  return (
    <>
      <Breadcrumbs name="M 180" />
      <Content className="mid-content">
        <div className="main-content activity-detail">
          <Header>
            <Title>งานตรวจเอกสาร</Title>
          </Header>
          {content}
        </div>
      </Content>
      <PageFooter
        score={score}
        isPass={score > checklist.BaseDocumentPoint}
        disabled={readOnly || event.IsLoading}
        onSave={onSave}
        isReject={selected.length > 0}
      />
      <EventModal
        event={[event]}
        enable={enableModal}
        successCalback={gotoDashboard}
        errorCalback={closeEnableModal}
      />
    </>
  )
}

const Header = styled.div`
  margin-top: 32px;
`

const Title = styled.span`
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 28px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #000;
`

const mapStateToProps = (state: RootState) => {
  return {
    checklist: selectData(state),
    request: selectReq(state),
    event: selectEvent(state),
    token: serviceTokenSelector(state),
    UserEmail: userEmailSelector(state),
    selected: selectDocReject(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {
  getDocumentCheckList,
}

type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(DocumentCheck)
