import { CommonResp, GetDataListReq, GetDataListResp } from './types'
import { createAsyncAction, createStandardAction } from 'typesafe-actions'

export const getDataListAction = createAsyncAction(
  'siricon/FETCH_LIST_REQUEST',
  'siricon/FETCH_LIST_SUCCESS',
  'siricon/FETCH_LIST_FAILURE'
)<GetDataListReq, GetDataListResp, any>()

export const resetState = createStandardAction(
  'sensitive/RESET_TO_INITIAL_STATE'
)<void>()
