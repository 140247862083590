import RootState from 'typings/RootState'
import { createSelector } from 'reselect'
import { SeemoreEvent } from './types'
import { MainconFilter, MainconQuery } from '../dashboard/types'

import { Activity } from '../dashboard/types'

export const selectDataList = (state: RootState): Activity[] =>
  state.mainconSeemore.dataList

export const selectTotalRows = (state: RootState): number =>
  state.mainconSeemore.totalRow

export const selectEvent = (state: RootState): SeemoreEvent =>
  state.mainconSeemore.event

export const selectSorting = (state: RootState) => state.mainconSeemore.sorting
export const selectFilter = (state: RootState): MainconFilter =>
  state.mainconSeemore.filter

export const selectQuery = (state: RootState): MainconQuery =>
  state.mainconSeemore.query
