import React, { useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Layout } from 'antd'
import { RouteComponentProps } from 'react-router-dom'
import RootState from 'typings/RootState'
import { selectReport, selectEvent, selectDefect } from 'features/pmr/selector'

import {
  userEmailSelector,
  serviceTokenSelector,
} from 'features/sensitive/sensitive.selector'
import { selectUploadEvent } from 'features/uploadfile/selector'
import PmrReportView from 'Components/PMRReport/Report'

import Breadcrumbs from './Breadcrumbs'

const { Content } = Layout

type Props = RouteComponentProps<{
  project: string
  requestID: string
  checkNumber: string
  unit: string
  floor: string
  qctypeID: string
}> &
  ReduxProps

const PmrReport: React.ComponentType<Props> = props => {
  const [page, setPage] = useState('')
  const { match, defect } = props

  const {
    project,
    requestID,
    checkNumber,
    qctypeID,
    floor,
    unit,
  } = match.params
  const RequestID = +requestID || 0
  const CheckNumber = +checkNumber || 0
  const dispatch = useDispatch()

  useEffect(() => {
    setPage('')
  }, [project, requestID, checkNumber, qctypeID, floor, unit])

  const gotoDashboard = () => {
    props.history.push('/')
  }

  if (page === 'dashboard') {
    gotoDashboard()
  }

  const getName = () => {
    switch (page) {
      case 'add.defect':
        return 'เพิ่ม Defect'
      case 'edit.defect':
        return 'แก้ไข Defect'
      case 'view.defect':
        return `หมุดที่ ${defect.PinNumber}`
      default:
        return undefined
    }
  }

  let readOnly = false

  return (
    <>
      <Breadcrumbs
        path={`/audit-road/report/${project}/${RequestID}/${CheckNumber}`}
        title={unit}
        name={getName()}
        onBack={() => setPage('')}
      />
      <PmrReportView
        initPage={page}
        ProjectCode={project}
        RequestID={RequestID}
        CheckNumber={CheckNumber}
        Floor={floor}
        QCTypeID={qctypeID}
        Unit={unit}
        onPage={(name: string) => setPage(name)}
        readOnly={readOnly}
        mode="PMR"
      />
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    Token: serviceTokenSelector(state),
    UserEmail: userEmailSelector(state),
    report: selectReport(state),
    event: selectEvent(state),
    uploadEvent: selectUploadEvent(state),
    defect: selectDefect(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PmrReport)
