import React from 'react'
import BaseIcon from './BaseIcon'
import img from 'assets/img/ic_uncheck_rejected.png'

const UnCheckedRejectIcon = (props: any) => {
  return (
    <BaseIcon
      img={img}
      onClick={props.onClick}
      size={props.size}
      disabled={props.disabled}
    />
  )
}

export default UnCheckedRejectIcon
