import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import ContentBox from 'Components/Shared/Card/ContentBox'
import { RowSelectedItem } from '../Components'
import { DataItem } from 'features/adminqc/unit/types'
import { EmptyBox } from 'Components/Shared'
import { cloneDeep } from 'lodash'

type DefaultProps = {
  unitList: DataItem[]
  total?: number
  onClick?: (unit: DataItem) => void
  selctedUnit?: DataItem
  height?: number
}

type Props = DefaultProps

const UnitData: React.FC<Props> = props => {
  const {
    unitList,
    total = 0,
    onClick = () => [],
    selctedUnit,
    height = 470,
  } = props
  const [filterList, setFilterList] = useState([] as DataItem[])
  const [txtSearch, setTxtSearch] = useState('')

  useEffect(() => {
    setFilterList(cloneDeep(unitList))
    setTxtSearch('')
  }, [unitList])

  useEffect(() => {
    onFilter()
  }, [txtSearch])

  const hasText = (data: string, searchTxt: string) => {
    if (data && searchTxt) {
      return data.toLocaleLowerCase().includes(searchTxt.toLocaleLowerCase())
    }
    return false
  }

  const onFilter = () => {
    let list = cloneDeep(unitList)
    if (txtSearch !== '') {
      list = list.filter(item => {
        return hasText(item.Unit, txtSearch)
      })
    }
    setFilterList(list)
  }

  const content = filterList.map((it: DataItem, index: number) => {
    return (
      <RowSelectedItem
        right={null}
        key={index}
        title={it.Unit}
        onClick={() => onClick(it)}
        isSelected={selctedUnit && it.Unit === selctedUnit.Unit}
      />
    )
  })

  return (
    <ContentBox
      title={`รายการยูนิต (${total})`}
      hasSearch={true}
      onSearch={setTxtSearch}
      height={100}
      isPercent={true}
    >
      {unitList && total > 0 ? (
        <ListSection>{content}</ListSection>
      ) : (
        <EmptyBox
          text={`ไม่มีรายการยูนิต กรุณาเลือกโปรเจคใหม่`}
          height={height - 70}
        />
      )}
    </ContentBox>
  )
}

const ListSection = styled.div`
  margin-top: 16px;
  height: 100%;
  overflow: auto;
  padding: 0 8px;
  padding-bottom: 30px;
`

export default UnitData
