import React, { useState } from 'react'
import { Row } from 'antd'
import styled from 'styled-components'

import { translate } from '../../i18n'
import TKeys from '../../i18n/translationKey'
import Picker from './Form/Picker'
import ErrorMessage from './ErrorMessage'
import { toText } from '../../utils/format'
import { LIMIT_FILE } from '../../config'
import { getFileType } from '../../utils/helper'
import imgFile from '../../assets/img/img-file.png'
import FileEmpty from './FileEmpty'
import imgDocument from 'assets/img/ic-document.png'

type DefaultProps = {
  Documents: any[]
  onChange?: (docs: any[]) => void
  onDocumentOpen?: () => void
  disabled: boolean
  showMax?: number
  numberOfFile?: number
  viewMode?: boolean
  isLimit?: boolean
  showTitle?: boolean
  isMultiple?: boolean
}

type Props = DefaultProps

const DocumentPicker: React.FC<Props> = ({
  Documents,
  onChange = (docs: any[]) => {},
  onDocumentOpen = () => {},
  disabled,
  showMax = 2,
  numberOfFile = 5,
  viewMode = false,
  isLimit = false,
  showTitle = true,
  isMultiple = false,
}) => {
  const [display, setDisplay] = useState(false)

  const addFile = (text: any, file: any) => {
    if (file.size > LIMIT_FILE.document) {
      setDisplay(true)
    } else {
      getFileType(file)
      Documents.unshift({ text, file })
      onChange(Documents)
    }
  }

  const renderFile = (item: any, size: number = 0) => {
    const file = item.FileID ? { name: item.ActualFileName } : item.file
    let fileType = getFileType(file)
    let content = (
      <FileInfo>
        <img src={fileType.icon} alt="" />
        <span>{toText(fileType.name, 9)}</span>
      </FileInfo>
    )

    if (size > 0) {
      let newImg = { filter: 'brightness(0.4)' }
      return (
        <ImgMoreBlock onClick={onDocumentOpen}>
          <img style={newImg} src={imgFile} alt="" />
          {content}
          <TextOver className="std-font-bold">+{size}</TextOver>
        </ImgMoreBlock>
      )
    } else {
      return (
        <ImgBlock onClick={onDocumentOpen}>
          <img src={imgFile} alt="" />
          {content}
        </ImgBlock>
      )
    }
  }

  const textDoc = translate(TKeys.ActivityDetail.document)
  let contents = []
  let moreContent
  const size = Documents.length

  if (size > 0) {
    for (let index = 1; index <= size; index++) {
      if (index >= showMax) {
        let more = size - showMax
        moreContent = renderFile(Documents[index - 1], more)
      } else {
        contents.push(renderFile(Documents[index - 1]))
      }
    }
  }

  const renderContent = contents.map((content, index) => {
    return <div key={index}>{content}</div>
  })

  return (
    <>
      {showTitle && (
        <div className="form-label">
          {textDoc}
          {!viewMode && (
            <SubTitle>
              {isLimit && `จำกัด ${numberOfFile} ไฟล์ `}
              ขนาดไม่เกิน 10MB ต่อไฟล์ (.docx, .pdf, .xls)
            </SubTitle>
          )}
        </div>
      )}

      <Row>
        {viewMode && Documents.length <= 0 ? (
          <FileEmpty image={imgDocument} text="ไม่มีเอกสาร" />
        ) : (
          <ImgLine>
            {!viewMode && (
              <DocAddbtn>
                <Picker
                  disabled={size === numberOfFile || disabled}
                  text={textDoc}
                  name="upload file"
                  accept=".pdf,.xlsx,.xls,.docx,.doc,.csv"
                  onChange={addFile}
                  isMultiple={isMultiple}
                />
              </DocAddbtn>
            )}
            {renderContent}
            {moreContent}
          </ImgLine>
        )}
      </Row>
      <ErrorMessage
        visible={display}
        text={translate(TKeys.Error.filie_size_limit)}
        onClose={() => setDisplay(false)}
      />
    </>
  )
}

const SubTitle = styled.span`
  color: #8b99a7;
  font-size: 16px;
  margin-left: 8px;
`

const ImgLine = styled.div`
  display: inline-flex;
`

const CssImg = `
  text-align: center;
  width: 100px;
  height: 100px;
  margin-right: 8px;
  border: 1px solid #e4e7eb;
  border-radius: 3px;

  img {
    width: 35px;
    height: 40px;
    object-fit: contain;
    margin-top: 19px;
  }
`
const ImgBlock = styled.div`
  ${CssImg}
`

const ImgMoreBlock = styled.div`
  ${CssImg}
  background-color: rgba(0, 0, 0, 0.4);
  :hover {
    cursor: pointer;
  }
`

const DocAddbtn = styled.div`
  margin-right: 8px;
`

const FileInfo = styled.div`
  margin: 4px 0px 0px 4px;
  text-align: left;

  img {
    margin: 0px;
    margin-right: 4px;
    width: 16px;
    height: 16px;
  }

  span {
    font-size: 18px;
  }
`

const TextOver = styled.span`
  color: white;
  font-size: 28px;
  position: absolute;
  top: 55px;
  transform: translate(-50%, -50%);
`

export default DocumentPicker
