import React from 'react'
import ItemCheckList from './ItemList'

type Props = {
  datas: any[]
  active?: any
  onChange?: (value: any) => void
  disabled?: boolean
}

const Work: React.FunctionComponent<Props> = props => {
  const { datas, active = '', onChange, disabled = false } = props

  return (
    <ItemCheckList
      label="ประเภทงาน"
      name="WorkTypeName"
      value="WorkTypeID"
      active={active}
      list={datas}
      onChange={onChange}
      disabled={disabled}
    />
  )
}

export default Work
