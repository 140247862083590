import moment, { Moment } from 'moment-timezone'
import { DATE_FORMAT } from '../config'
import imgPdf from 'assets/img/ic-pdf.png'
import imgExcel from 'assets/img/ic-excel.png'
import imgDoc from 'assets/img/ic-doc.png'
import imgCsv from 'assets/img/ic-csv.png'
import { textToLowerCase } from './format'

import { Event } from 'features/types/event'
import { PaginationType } from 'features/types/common'

export const dateCompare = (
  a: undefined | Moment,
  b: undefined | Moment
): boolean => {
  let valA = a ? a.unix() : 0
  let valB = b ? b.unix() : 0

  return valA === valB
}

export const filterActivity = (list: any[], filter: any): any[] => {
  let filterProgress = filter.progress.length > 0
  let filterStatus = filter.status.length > 0
  let filterWbs = filter.wbs.length > 0
  let result = list.filter(item => {
    if (filterProgress) {
      let progress = filter.progress.includes(item.Progress)
      if (progress === false) return false
    }

    if (filterStatus) {
      let status = filter.status.includes(item.TaskStatus)
      if (status === false) return false
    }

    if (filterWbs) {
      let wbs = filter.wbs.includes(item.WbsID)
      if (wbs === false) return false
    }

    return true
  })

  return result
}

const sortingByString = (list: any[], type: string, name: string): any[] => {
  const options = {
    sensitivity: 'base',
  }
  if (type === 'asc') {
    list.sort((a, b) => {
      return `${a[name]}`.localeCompare(`${b[name]}`, 'en', options)
    })
  } else {
    list.sort((a, b) => {
      return `${b[name]}`.localeCompare(`${a[name]}`, 'en', options)
    })
  }
  return list
}

const sortingByStatus = (list: any[], type: string, name: string): any[] => {
  const status: any = {
    INIT: 1,
    PEND: 2,
    APPV: 3,
    REJT: 4,
  }

  if (type === 'asc') {
    list.sort((a, b) => {
      const aVal = status[`${a[name]}`] || 0
      const bVal = status[`${b[name]}`] || 0
      return bVal - aVal
    })
  } else {
    list.sort((a, b) => {
      const aVal = status[`${a[name]}`] || 0
      const bVal = status[`${b[name]}`] || 0
      return aVal - bVal
    })
  }

  return list
}

const sortingByNumber = (list: any[], type: string, name: keyof any): any[] => {
  if (type === 'asc') {
    list.sort((a, b) => {
      return Number(a[name]) - Number(b[name])
    })
  } else {
    list.sort((a, b) => {
      return Number(b[name]) - Number(a[name])
    })
  }

  return list
}

const getUnixTime = (val: any) => {
  return val ? moment(val, DATE_FORMAT).unix() : 0
}

const sortingByDate = (list: any[], type: string, name: string): any[] => {
  if (type === 'asc') {
    list.sort((a, b) => {
      const unixA = getUnixTime(a[name])
      const unixB = getUnixTime(b[name])

      return unixA - unixB
    })
  } else {
    list.sort((a, b) => {
      const unixA = getUnixTime(a[name])
      const unixB = getUnixTime(b[name])

      return unixB - unixA
    })
  }

  return list
}

const sortingByCountDate = (list: any[], type: string, name: string): any[] => {
  const today = moment()
  if (type === 'asc') {
    list.sort((a, b) => {
      const unixA = moment(a[name], DATE_FORMAT).diff(today, 'days')
      const unixB = moment(b[name], DATE_FORMAT).diff(today, 'days')

      return unixA - unixB
    })
  } else {
    list.sort((a, b) => {
      const unixA = moment(a[name], DATE_FORMAT).diff(today, 'days')
      const unixB = moment(b[name], DATE_FORMAT).diff(today, 'days')

      return unixB - unixA
    })
  }

  return list
}

export const getParamsForSorting = (params: string[], type: string): any[] => {
  let sortBy = [] as any
  params.map(param => {
    sortBy.push({
      prop: param,
      direction: type === 'asc' ? 1 : -1,
    })
  })
  return sortBy
}

export const sortingByMultiParams = (
  list: any[],
  type: string,
  params: string[]
): any[] => {
  const sortBy = getParamsForSorting(params, type)
  const sortResult = list.sort(function(a, b) {
    let i = 0,
      result = 0
    while (i < sortBy.length && result === 0) {
      result =
        sortBy[i].direction *
        (a[sortBy[i].prop].toString() < b[sortBy[i].prop].toString()
          ? -1
          : a[sortBy[i].prop].toString() > b[sortBy[i].prop].toString()
          ? 1
          : 0)
      i++
    }
    return result
  })
  return sortResult
}

export const sortingList = (
  list: any[],
  type: string,
  name: string,
  typeOfName: string
): any[] => {
  try {
    let newList: any[] = list
    switch (typeOfName) {
      case 'string':
        newList = sortingByString(list, type, name)
        break
      case 'date':
        newList = sortingByDate(list, type, name)
        break
      case 'count_date':
        newList = sortingByCountDate(list, type, name)
        break
      case 'number':
        newList = sortingByNumber(list, type, name)
        break
      case 'status':
        newList = sortingByStatus(list, type, name)
        break
      case 'iso_date':
        newList = sortingByISODate(list, type, name)
        break
      default:
    }

    return newList
  } catch (e) {
    return list
  }
}

const convertISODateToString = (isoDate: any) => {
  return moment(isoDate)
    .utc()
    .format(DATE_FORMAT)
}

const sortingByISODate = (list: any[], type: string, name: string): any[] => {
  if (type === 'asc') {
    list.sort((a, b) => {
      const unixA = moment(convertISODateToString(a[name]), DATE_FORMAT).unix()
      const unixB = moment(convertISODateToString(b[name]), DATE_FORMAT).unix()
      return unixA - unixB
    })
  } else {
    list.sort((a, b) => {
      const unixA = moment(convertISODateToString(a[name]), DATE_FORMAT).unix()
      const unixB = moment(convertISODateToString(b[name]), DATE_FORMAT).unix()
      return unixB - unixA
    })
  }

  return list
}

export const getFileType = (file: any = {}) => {
  let icon
  let type = textToLowerCase(file.type)
  if (file.name) {
    const index = file.name.lastIndexOf('.')
    type = file.name.substr(index + 1)
  }

  switch (type) {
    case 'csv':
    case 'text/csv':
      icon = imgCsv
      break
    case 'doc':
    case 'docx':
    case 'application/doc':
    case 'application/ms-doc':
    case 'application/msword':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      icon = imgDoc
      break
    case 'xls':
    case 'xlsx':
    case 'application/vnd.ms-excel':
    case 'application/msexcel':
    case 'application/x-msexcel':
    case 'application/x-ms-excel':
    case 'application/x-excel':
    case 'application/x-dos_ms_excel':
    case 'application/xls':
    case 'application/x-xls':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
      icon = imgExcel
      break
    default:
      icon = imgPdf
      break
  }
  return {
    icon,
    name: file.name,
  }
}

export const getEvent = (
  list: Event[] = [],
  errorList: Event[] = []
): Event => {
  if (list.length === 0 && errorList.length === 0) {
    return {
      IsLoading: false,
      SubmitError: undefined,
      SubmitSuccess: false,
      MessageSuccess: undefined,
    } as Event
  }
  let index = 0
  // find error first
  index = errorList.findIndex(item => {
    return item.IsLoading === true || item.SubmitError
  })

  if (index !== -1) {
    return errorList[index]
  }

  index = list.findIndex(item => {
    return item.IsLoading === true || item.SubmitError
  })

  if (index !== -1) {
    return list[index]
  }
  // find success
  index = list.findIndex(item => {
    return item.SubmitSuccess === true || item.MessageSuccess
  })

  return index !== -1 ? list[index] : list[0]
}

export const isUnit = (zoneName: string) => {
  return zoneName === 'Resident Area'
}

export const isZone = (zoneName: string) => {
  return zoneName === 'Common Area'
}

export const isCommissioning = (zoneName: string) => {
  return zoneName === 'Commissioning' || zoneName === 'Safety'
}

export const getActCategory = (task: any) => {
  const unit = isUnit(task.Zone)
  const name = unit ? task.QCTypeName : `ชั้น ${task.FloorName}`
  return `${task.Unit}/ ${name}`
}

export const displayCategory = (task: any) => {
  let name = ''
  switch (task.Zone) {
    case 'Resident Area':
      name = `${task.Unit}/${task.QCTypeName}`
      break
    case 'Common Area':
      if (task.ConstructionTypeName === 'Road') {
        name = `${task.Unit}/${task.QCTypeName}`
      } else {
        name = `${task.Unit}/ชั้น ${task.FloorName || task.Floor}`
      }
      break
    default:
      name = ''
      break
  }
  return name
}

export const toRoute = (name: string = '') => {
  return name.replace('/', '++')
}

export const fromRoute = (name: string = '') => {
  return name.replace('++', '/')
}

export const initPagination = (
  page: number = 1,
  perPage: number = 10
): PaginationType => {
  return {
    Page: page,
    RowPerPage: perPage,
  }
}
