import RootState from 'typings/RootState'
import {
  UnitDashboardData,
  ZoneDashboardData,
  DashboardFilter,
  DashboardQuery,
  CommissioningDashboardData,
  SitevisitDashboardData,
  ConstructRoadDashboardData,
  CommonAreaFilter,
  CommissioningFilter,
  UnitFilter,
} from './types'

import { Event } from '../../types/event'

export const selectUnitData = (state: RootState): UnitDashboardData =>
  state.qcUnitDashboard.unit.data

export const selectZoneData = (state: RootState): ZoneDashboardData =>
  state.qcUnitDashboard.zone.data

export const selectEvent = (state: RootState): Event =>
  state.qcUnitDashboard.event

export const selectFilter = (state: RootState): DashboardFilter =>
  state.qcUnitDashboard.filter

export const selectQuery = (state: RootState): DashboardQuery =>
  state.qcUnitDashboard.query

export const selectCommissioningData = (
  state: RootState
): CommissioningDashboardData => state.qcUnitDashboard.commissioning.data

export const selectConstructPlan = (state: RootState): SitevisitDashboardData =>
  state.qcUnitDashboard.constructPlan

export const selectAuditPlan = (state: RootState): SitevisitDashboardData =>
  state.qcUnitDashboard.auditPlan

export const selectConstructRoad = (
  state: RootState
): ConstructRoadDashboardData => state.qcUnitDashboard.constructRoad

export const selectZoneFilter = (state: RootState): CommonAreaFilter =>
  state.qcUnitDashboard.zone.filter

export const selectCommissioningFilter = (
  state: RootState
): CommissioningFilter => state.qcUnitDashboard.commissioning.filter

export const selectUnitFilter = (state: RootState): UnitFilter =>
  state.qcUnitDashboard.unit.filter
