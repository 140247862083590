import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { connect, useDispatch } from 'react-redux'
import { Layout } from 'antd'
import RootState from 'typings/RootState'

import EventModal from 'Components/Shared/Modal/EventModal'
/*
import { selectEvent } from 'features/report/commissioning/selector'
import {
  submitSignReq,
  resetEvent,
} from 'features/report/commissioning/actions'
import { SignCommissioningReq } from 'features/report/commissioning/types'
*/
import { selectEvent } from 'features/report/common/selector'
import {
  submitCommissionSignReq,
  resetEvent,
} from 'features/report/common/actions'
import { SignCommissioningReq } from 'features/report/common/types'

import {
  userEmailSelector,
  serviceTokenSelector,
} from 'features/sensitive/sensitive.selector'

import SignDialog from 'Components/Shared/SignDialog'

import { selectUploadEvent } from 'features/uploadfile/selector'

const { Content } = Layout

type FormProps = {
  checklist: any
  ActivityID: string
  RequestID: number
  display?: boolean

  onClose: () => void
  onCompleted: () => void
  onSaveChecklist?: () => void
}

type Props = FormProps & ReduxProps

const Signature: React.ComponentType<Props> = props => {
  const {
    checklist,
    token,
    UserEmail,
    ActivityID,
    RequestID,
    event,

    display = false,
    onClose,
    onCompleted,
    onSaveChecklist = () => {},
  } = props
  const [enableModal, setEnableModal] = useState(false)

  const dispatch = useDispatch()

  const onSignCompleted = () => {
    setEnableModal(false)
    dispatch(resetEvent())
    onCompleted()
  }

  const onError = () => setEnableModal(false)

  const onSignature = (Username: string, Remark: string, SignImage: any) => {
    if (onSaveChecklist) {
      onSaveChecklist()
    }

    setEnableModal(true)
    const {
      ProjectCode,
      CheckListID,
      CheckNumber,
      CommissioningTypeID,
    } = checklist

    const req: SignCommissioningReq = {
      ProjectCode,
      UserEmail,

      CheckListID,
      ActivityID,
      RequestID,
      CheckNumber,

      CommissioningType: CommissioningTypeID,

      Username,
      Remark,
      SignImage,
    }

    dispatch(submitCommissionSignReq({ token, req }))
  }

  return (
    <>
      <EventModal
        event={[event]}
        enable={enableModal}
        successCalback={onSignCompleted}
        errorCalback={onError}
      />

      <SignDialog display={display} onClose={onClose} onSave={onSignature} />
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    event: selectEvent(state),
    token: serviceTokenSelector(state),
    UserEmail: userEmailSelector(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Signature)
