import { createStandardAction, createAsyncAction } from 'typesafe-actions'
import {
  GetActivitySeemoreRequest,
  GetActivitySeemoreApiResponse,
} from './types'

export const GetActivitySeeMoreForCMAction = createAsyncAction(
  'cmpj/seemore/FETCH_CM_REQUEST',
  'cmpj/seemore/FETCH_CM_SUCCESS',
  'cmpj/seemore/FETCH_CM_FAILURE'
)<GetActivitySeemoreRequest, GetActivitySeemoreApiResponse, any>()

export const sortingTask = createStandardAction('cmpj/seemore/SORTING')<{
  name: string
  sort?: string
}>()

export const setPageType = createStandardAction('cmpj/seemore/SETPAGETYPE')<{
  pageType: number
}>()
