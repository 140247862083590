import React, { useState, useImperativeHandle, forwardRef } from 'react'
import { Modal, Row, Col, Spin, Icon } from 'antd'
import styled from 'styled-components'
import Pagination from '../../../Shared/Pagination'

import { CloseIcon, CheckedIcon, UncheckedIcon } from 'Components/Shared/Icon'
import { PrimaryButton, OutlineButton } from 'Components/Shared/Button'
import ImportModalRowItem from './ImportModalRowItem'

import { TableHeader, TableColHeader } from 'Components/Shared/Table'
import { PaginationType } from 'features/types/common'
import { CheckList, CheckListItem } from 'features/checklist/master/types'

export interface Handles {
  showModal: () => void
}

interface Props {
  onOk: (isSelectAll: boolean, selectList: string[]) => void
  checklist: CheckList
  isLoading: boolean
  getMasterCheckList: (page: PaginationType) => void
}

const ImportModal = forwardRef<Handles, Props>((props, ref) => {
  const [visible, setvisible] = useState(false)
  const [isSelectAll, setIsSelectAll] = useState(false)
  const [selectList, setSelectList] = useState<string[]>([])
  const [pagination, setPagination] = useState<PaginationType>({
    Page: 1,
    RowPerPage: 10,
  })

  const clearClose = () => {
    setvisible(false)
    setIsSelectAll(false)
    setSelectList([])
  }

  const closeModal = () => {
    setvisible(false)
  }

  const onOk = () => {
    props.onOk(isSelectAll, selectList)
    clearClose()
  }

  const nextPage = (val: number) => {
    props.getMasterCheckList({
      Page: val,
      RowPerPage: pagination.RowPerPage,
    })
  }

  const setPagePerRow = (val: number) => {
    props.getMasterCheckList({
      Page: pagination.Page,
      RowPerPage: val,
    })
  }

  useImperativeHandle(ref, () => ({
    showModal: () => {
      props.getMasterCheckList({
        Page: pagination.Page,
        RowPerPage: pagination.RowPerPage,
      })
      setvisible(true)
    },
  }))

  const onSelect = (id: string, cond: string) => {
    if (cond === 'add') {
      setSelectList(v => v.concat(id))
    } else if (cond === 'delete') {
      setSelectList(v => v.filter(d => d !== id))
    }
  }

  const content = props.checklist.List.map(
    (item: CheckListItem, index: number) => {
      return (
        <ImportModalRowItem
          item={item}
          onSelect={onSelect}
          key={index}
          isSelected={selectList.includes(item.CheckListID)}
          isSelectAll={isSelectAll}
        />
      )
    }
  )

  const toggleSetisSelectAll = () => {
    const newValue = !isSelectAll
    setIsSelectAll(newValue)
    if (newValue === true) {
      props.checklist.List.forEach(v => {
        if (v.IsRequireQC === false) {
          onSelect(v.CheckListID, 'add')
        }
      })
    } else {
      setSelectList([])
    }
  }

  const loading = (
    <LoadingBody>
      <Spin
        indicator={<Icon type="loading" style={{ fontSize: '70px' }} spin />}
      />
    </LoadingBody>
  )

  return (
    <Modal
      centered
      visible={visible}
      width={'90%'}
      footer={null}
      closeIcon={<CloseIcon color="#8b99a7" size={16} />}
      title={<Header>{'กรุณาเลือกรายการจาก Master Template'}</Header>}
      className="add-checklist-modal"
      onCancel={closeModal}
    >
      <ModalContent>
        <MainPage>
          <TableHeader paddingLeft={27}>
            <TableColHeader span={2}>Checklist ID</TableColHeader>
            <TableColHeader span={8}>Checklist name</TableColHeader>
            <TableColHeader span={5}>รายการตรวจ</TableColHeader>
            <TableColHeader span={7}>QC Required เป็นเอกสารตรวจ</TableColHeader>
            <Col span={2}>
              {isSelectAll ? (
                <CheckedIcon size={24} onClick={toggleSetisSelectAll} />
              ) : (
                <UncheckedIcon size={24} onClick={toggleSetisSelectAll} />
              )}
            </Col>
          </TableHeader>

          {props.isLoading && loading}
          {!props.isLoading && (
            <>
              <ListContent>{content}</ListContent>
              <Pagination
                currentPage={pagination.Page}
                totalRows={props.checklist.Total}
                perPage={pagination.RowPerPage}
                onChangeNumber={(page: number) => {
                  pagination.RowPerPage = page
                  setPagination({ ...pagination })
                  setPagePerRow(page)
                }}
                onClickPage={(page: number) => {
                  pagination.Page = page
                  setPagination({ ...pagination })
                  nextPage(page)
                }}
              />
              <ButtonSection>
                <Row type="flex" justify="space-between">
                  <Col style={{ flex: 0.7 }}>
                    <p className="warning">
                      * คุณสามารถลบ Checklist ที่ไม่ใช้งานภายหลังได้ (ลบได้เฉพาะ
                      Checklist ที่ QC ไม่ตรวจเอกสารเท่านั้น) ** หากไม่พบ
                      checklist ที่ต้องการ คุณสามารถกดเพิ่ม checklist
                      ด้วยตัวเองได้ หลังจาก import master เสร็จสมบูรณ์
                    </p>
                  </Col>

                  <Col style={{ flex: 0.5 }}>
                    <Row type="flex" justify="end">
                      <ButtonWrap>
                        <OutlineButton
                          text="ยกเลิก"
                          disabled={false}
                          onClick={closeModal}
                        />
                      </ButtonWrap>
                      <ButtonWrap>
                        <PrimaryButton
                          text="เริ่ม Import เข้าโครงการ"
                          disabled={false}
                          onClick={onOk}
                        />
                      </ButtonWrap>
                    </Row>
                  </Col>
                </Row>
              </ButtonSection>
            </>
          )}
        </MainPage>
      </ModalContent>
    </Modal>
  )
})

const Header = styled.span`
  font-size: 28px;
  color: #000000;
  font-family: 'DB Helvethaica X 75 Bd';
`

const ModalContent = styled.div`
  padding: 0 24px 31px 24px;

  .ant-input {
    font-size: 20px;
  }
`

const ListContent = styled.div`
  overflow-y: scroll;
  height: 380px;
`

const ButtonWrap = styled.div`
  width: 187px;
  margin-left: 24px;
  margin-top: 5px;
`

const ButtonSection = styled.div`
  .warning {
    font-size: 18px;
    font-family: 'DB Helvethaica X 55 Regular';
    color: #8b99a7;
  }
`

const MainPage = styled.div`
  width: 100%;
  font-size: 10px;
  font-family: 'DB Helvethaica X 75 Bd';
`

const LoadingBody = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  height: 125px;
`
export default ImportModal
