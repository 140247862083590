import { createStandardAction, createAsyncAction } from 'typesafe-actions';
import {
  LoginRequest,
  LoginResponse,
  LogoutRequest,
  LogoutResponse,
  UserState,
} from './login';

export const loginAsyncActions = createAsyncAction(
  'login/LOGIN_REQUEST',
  'login/LOGIN_SUCCESS',
  'login/LOGIN_FAILURE'
)<LoginRequest, LoginResponse, any>();

export const logoutAsyncActions = createAsyncAction(
  'logout/LOGOUT_REQUEST',
  'logout/LOGOUT_SUCCESS',
  'logout/LOGOUT_FAILURE'
)<LogoutRequest, LogoutResponse, any>();

export const initUserData = createStandardAction('login/INIT_USER_DATA')<
  UserState
>();

export const logoutUser = createStandardAction('login/LOGOUT_USER')<void>();
export const userLoggedOut = createStandardAction('login/USER_LOGGED_OUT')<
  string
>();

export const protectedMenuStatus = createStandardAction(
  'login/ENABLE_PROTECTED_MENU'
)<boolean>();

export const forceLogin = createStandardAction('login/FORCE_LOGIN')<boolean>();

export const initLogin = createStandardAction('login/INIT_LOGIN')<void>();

export const updateLoginMessage = createStandardAction(
  'login/UPDATE_LOGIN_MESSAGE'
)<string>();
