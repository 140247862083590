import { createStandardAction, createAsyncAction } from 'typesafe-actions'
import {
  GetPMRResponse,
  GetPMRReq,
  GetReportReq,
  GetReportResponse,
  PostApiResp,
  UpdateDefectReq,
  DefectItem,
  SetGroupPMRDefectReq,
  ConfirmGroupPMRDefectReq,
  Filter,
} from './types'

export const getPMRListAction = createAsyncAction(
  'pmr/FETCH_PMR_LIST_REQUEST',
  'pmr/FETCH_PMR_LIST_SUCCESS',
  'pmr/FETCH_PMR_LIST_FAILURE'
)<GetPMRReq, GetPMRResponse, any>()

export const getReportAction = createAsyncAction(
  'pmr/FETCH_PMR_REPORT_REQUEST',
  'pmr/FETCH_PMR_REPORT_SUCCESS',
  'pmr/FETCH_PMR_REPORT_FAILURE'
)<GetReportReq, GetReportResponse, any>()

export const updateDefectAction = createAsyncAction(
  'pmr/UPDATE_DEFECT_REQ',
  'pmr/UPDATE_DEFECT_RESP',
  'pmr/UPDATE_DEFECT_FAIL'
)<UpdateDefectReq, PostApiResp, any>()

export const setGroupDefectAction = createAsyncAction(
  'pmr/SET_GROUP_DEFECT_REQ',
  'pmr/SET_GROUP_DEFECT_RESP',
  'pmr/SET_GROUP_DEFECT_FAIL'
)<SetGroupPMRDefectReq, PostApiResp, any>()

export const confirmGroupDefectAction = createAsyncAction(
  'pmr/CONFIRM_GROUP_DEFECT_REQ',
  'pmr/CONFIRM_GROUP_DEFECT_RESP',
  'pmr/CONFIRM_GROUP_DEFECT_FAIL'
)<ConfirmGroupPMRDefectReq, PostApiResp, any>()

export const sortingTask = createStandardAction('pmr/list/SORTING')<{
  name: string
  sort?: string
}>()

export const updateCurrentDefect = createStandardAction(
  'pmr/UPDATE_DEFECT_ACTION'
)<DefectItem>()

export const resetCurrentDefect = createStandardAction(
  'pmr/RESET_DEFECT_ACTION'
)<void>()

export const sortingDefect = createStandardAction('pmr/list/SORTING_DEFECT')<{
  name: string
  sort?: string
}>()

export const updateFilter = createStandardAction('pmr/UPDATE_FILTER_ACTION')<
  Filter,
  void
>()

export const clearFilter = createStandardAction('pmr/CLEAR_FILTER_ACTION')<
  void
>()
