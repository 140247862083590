import { createStandardAction, createAsyncAction } from 'typesafe-actions'
import {
  GetActivitySeemoreRequest,
  GetActivitySeemoreForQCApiResponse,
} from './types'

export const GetActivitySeeMoreForQCAction = createAsyncAction(
  'cmpj/seemoreqc/FETCH_QC_REQUEST',
  'cmpj/seemoreqc/FETCH_QC_SUCCESS',
  'cmpj/seemoreqc/FETCH_QC_FAILURE'
)<GetActivitySeemoreRequest, GetActivitySeemoreForQCApiResponse, any>()

export const sortingTask = createStandardAction('cmpj/seemoreqc/SORTING')<{
  name: string
  sort?: string
}>()

export const setPageType = createStandardAction('cmpj/seemoreqc/SETPAGETYPE')<{
  pageType: number
}>()
