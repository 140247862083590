import React from 'react'
import { DropdownList } from 'Components/Shared/Form'
import { DefectStatus, DefectStatusText } from 'features/types/status'

type Props = {
  active?: any
  onChange?: (value: any) => void
  disabled?: boolean
  label?: string
}

const Status: React.FunctionComponent<Props> = props => {
  const { active = '', onChange, disabled = false, label = 'ผลการตรวจ' } = props

  const status = [
    {
      value: DefectStatus.TOBEFIX,
      name: DefectStatusText.TOBEFIX,
    },
    {
      value: DefectStatus.GOODWORK,
      name: DefectStatusText.GOODWORK,
    },
    {
      value: DefectStatus.PASS,
      name: DefectStatusText.PASS,
    },
    {
      value: DefectStatus.FIXED,
      name: DefectStatusText.FIXED,
    },
  ]

  return (
    <DropdownList
      label={label}
      value={active}
      menu={status}
      onChange={onChange}
      disabled={disabled}
    />
  )
}

export default Status
