import { toArray, getInt, getText } from './common'

import {
  InspectionList,
  InspectionData,
  InspectionSubData,
} from 'features/types/checklist'

export const toCheckList = (data: string): any => {
  const checklist: any = {
    Name: '',
    Type: 1,
    Q1List: [],
    CommissionList: [],
    SafetyList: [],
  }

  let mode = ''
  const rows = toArray(data)
  rows.forEach(row => {
    const cmd = getText(row, 0).toLowerCase()
    switch (cmd) {
      case 'checklist name':
        mode = 'name'
        checklist.Name = getText(row, 1)
        const typ = getText(row, 2)
        checklist.Type = typ === 'commissioning' ? 1 : 2
        break

      case 'q1':
        mode = 'q1'
        addDetail(checklist, 'Q1List', row)
        break

      case 'commissioning':
        mode = 'commissioning'
        addDetail(checklist, 'CommissionList', row)
        break

      case 'safety':
        mode = 'safety'
        addDetail(checklist, 'SafetyList', row)
        break

      case 'note':
        mode = ''
        break

      case 'end':
        return checklist

      default:
        if (mode === 'q1') addDetail(checklist, 'Q1List', row)
        else if (mode === 'commissioning')
          addDetail(checklist, 'CommissionList', row)
        else if (mode === 'safety') addDetail(checklist, 'SafetyList', row)
        else mode = ''

        break
    }
  })

  return checklist
}

const addDetail = (checklist: any, name: string, row: string[]) => {
  const cate = getText(row, 1)
  const title = getText(row, 2)
  const subTitle = getText(row, 3)

  const len = cate.length + title.length + subTitle.length
  if (len === 0) return

  let mainItem: InspectionList = {
    InspectionCategory: cate,
    Seq: '',
    Level: 1,
    Data: [],
    isHide: false,
  }

  if (cate !== '') {
    checklist[name].push(mainItem)
  }
  const mainIndex = checklist[name].length - 1
  mainItem = checklist[name][mainIndex]

  let titleItem: InspectionData = {
    Name: title,
    Status: '',
    Remark: '',
    Seq: '',
    Level: 2,
    Data: [],
  }
  if (title !== '') {
    mainItem.Data.push(titleItem)
  }

  if (subTitle.length > 0) {
    const titleIndex = mainItem.Data.length - 1
    titleItem = mainItem.Data[titleIndex]

    const subTitleItem: InspectionSubData = {
      Name: subTitle,
      Status: '',
      Remark: '',
      Seq: '',
      Level: 3,
    }

    titleItem.Data.push(subTitleItem)
  }
}
