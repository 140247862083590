import React from 'react'
import { Row, Col } from 'antd'
import styled from 'styled-components'

interface Props {
  children?: any
  onClick?: (evt: any) => void
  selected?: boolean
  minHeight?: number
  spaceBetweenRow?: number
  paddingLeft?: number
  backgroundColor?: string
  cursor?: 'default' | 'pointer'
}

const TableRow = (props: Props) => {
  const {
    selected,
    minHeight = 65,
    spaceBetweenRow = 4,
    paddingLeft = 0,
    backgroundColor = 'white',
    cursor = 'pointer',
  } = props
  const css = {
    borderRadius: '5px',
    backgroundColor,
    boxShadow: '0 6px 10px 0 rgba(0, 0, 0, 0.03)',
    margin: spaceBetweenRow + 'px 0px',
    minHeight: minHeight + 'px',
    alignItems: 'center',
    display: 'flex',
    border: '1px solid transparent',
    paddingLeft: paddingLeft + 'px',
    cursor: cursor,
  }

  if (selected) {
    css.border = '1px solid #003168'
  }

  return (
    <Line onClick={props.onClick}>
      <Row style={css}>{props.children}</Row>
    </Line>
  )
}

const Line = styled.div`
  width: 100%;
  font-family: 'DB Helvethaica X 55 Regular';
  font-size: 20px;
  color: black;
`

export default TableRow
