import withAPIAction from 'utils/withAPIAction'
import { APIPath } from 'services/APIPath'
import { fetchProjectAsyncAction } from './project.actions'
import projectHelper from 'utils/projectHelper'

interface FetchProjectRequestData {
  email: string
  token: string
}

export const fetchProjects = (data: FetchProjectRequestData) => {
  const { email = '', token = '' } = data
  if (email === '' || token === '') {
    return { type: 'project/MISSING_REQUIRED_PARAMS' }
  }

  return withAPIAction(state => ({
    method: 'POST',
    path: `${APIPath.getProjectAuthen}`,
    data: {
      UserEmail: email,
      ModuleID: projectHelper.getModuleID(),
    },
    token: `Bearer ${state.sensitive.apiToken}`,
  }))(fetchProjectAsyncAction)
}
