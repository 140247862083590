import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'antd'

interface Props {
  title: string
  right: any
  leftSpan?: number
}

const RowItem: React.ComponentType<Props> = ({
  title,
  right,
  leftSpan = 18,
}) => {
  return (
    <Item>
      <Row type="flex" justify="space-between" className="row-item">
        <Col span={leftSpan}>
          <Name>{title}</Name>
        </Col>
        <Col>{right}</Col>
      </Row>
    </Item>
  )
}

const Item = styled.div`
  font-size: 20px;
  border-bottom: 1px solid #e8ebf0;
  height: 56px;
  align-items: center;
  display: flex;
  padding: 0px 16px;

  .row-item {
    width: 100%;
  }
`

const Name = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export default RowItem
