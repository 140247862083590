import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'antd'
import { PrimaryButton, OutlineButton } from 'Components/Shared/Button'

interface Props {
  closeModal: () => void
  onSave: () => void
  error: string
}

const BottomButton = ({ closeModal, onSave, error }: Props) => {
  return (
    <ButtonSection>
      <Row align="middle">
        <Col span={7} offset={0} className="error">
          {error}
        </Col>
        <Row>
          <Col span={7} offset={9}>
            <OutlineButton
              text="ยกเลิก"
              disabled={false}
              onClick={closeModal}
            />
          </Col>
          <Col span={7} offset={1}>
            <PrimaryButton text="บันทึก" disabled={false} onClick={onSave} />
          </Col>
        </Row>
      </Row>
    </ButtonSection>
  )
}

const ButtonSection = styled.div`
  padding-top: 18px;

  .error {
    color: red;
    position: absolute;
    bottom: 0;
  }
`

export default BottomButton
