import { createStandardAction, createAsyncAction } from 'typesafe-actions'
import {
  GetReportListReq,
  GetReportListResp,
  Filter,
  GetReportDetailReq,
  GetReportDetailResp,
} from './types'

export const getReportListAction = createAsyncAction(
  'admin/report/construct_plan/GET_LIST_REQ',
  'admin/report/construct_plan/GET_LIST_RESP',
  'admin/report/construct_plan/GET_LIST_FAIL'
)<GetReportListReq, GetReportListResp, any>()

export const getReportDetailAction = createAsyncAction(
  'admin/report/construct_plan/GET_DETAIL_REQ',
  'admin/report/construct_plan/GET_DETAIL_RESP',
  'admin/report/construct_plan/GET_DETAIL_FAIL'
)<GetReportDetailReq, GetReportDetailResp, any>()

export const updateFilter = createStandardAction(
  'admin/report/construct_plan/UPDATE_FILTER_ACTION'
)<Filter, void>()

export const clearFilter = createStandardAction(
  'admin/report/construct_plan/CLEAR_FILTER_ACTION'
)<void>()

export const sortingTask = createStandardAction(
  'admin/report/construct_plan/SORTING'
)<{
  name: string
  sort?: string
}>()

export const exportReportListAction = createAsyncAction(
  'admin/report/construct_plan/EXPORT_LIST_REQ',
  'admin/report/construct_plan/EXPORT_LIST_RESP',
  'admin/report/construct_plan/EXPORT_LIST_FAIL'
)<GetReportListReq, GetReportListResp, any>()
