import React, { useState, useEffect, useCallback } from 'react'

import styled from 'styled-components'
import file from 'utils/file'
import { toLocalPosition } from 'utils/pin'
import { PinIcon } from 'Components/Shared/Icon'

type Props = {
  floorImage: string
  pinSize?: any
  pin?: any
}

const PinView: React.FunctionComponent<Props> = props => {
  const { floorImage, pinSize = { width: 24, height: 28.5 }, pin } = props

  const [myCanvas, setMyCanvas] = useState(undefined as any)
  const [imgSize, setImgSize] = useState(undefined as any)
  const [imageUrl, setImageUrl] = useState(undefined as any)

  useEffect(() => {
    drawMyImage(myCanvas)
  }, [floorImage])

  const getColor = (status: string) => {
    switch (status) {
      case 'ToBeFix':
        return '#d0494c'
      case 'GoodWork':
        return '#79cadc'
      case 'Pass':
        return '#5bc79e'
      default:
        return '#e7a065'
        break
    }
  }

  const onRefCanvas = useCallback(node => {
    if (node) {
      setMyCanvas(node)
      const rect = node.getBoundingClientRect()
      drawMyImage(node)
    }
  }, [])

  const toNumber = (val: number) => {
    return Math.floor(val * 1000) / 1000
  }

  const getMaxSize = () => {
    let width = 300
    let height = 200

    return { width, height }
  }

  const drawMyImage = async (elem: any) => {
    try {
      if (elem) {
        const maxSize = getMaxSize()
        const img = await file.base64ToImage(floorImage, true)

        const newSize: any = await file.reduceByDimension(img, maxSize)
        const { width, height } = newSize
        elem.width = width
        elem.height = height

        const ctx = elem.getContext('2d')
        ctx.drawImage(img, 0, 0, width, height)

        const color = getColor(pin.PinStatus)
        const icElem: any = document.getElementById('icon')

        const svgString = new XMLSerializer().serializeToString(icElem)

        const DOMURL = window.URL || window.webkitURL || window
        const svg = new Blob([svgString], {
          type: 'image/svg+xml;charset=utf-8',
        })
        const url = DOMURL.createObjectURL(svg)
        const ic = await file.base64ToImage(url)

        const ratio = toNumber(width / img.width)
        const location = toLocalPosition(
          toNumber(+pin.XAxis * ratio),
          toNumber(+pin.YAxis * ratio),
          pinSize.width,
          pinSize.height
        )

        ctx.drawImage(ic, location.x, location.y, pinSize.width, pinSize.height)
        const x = location.x + pinSize.width / 2 - 3
        const y = location.y + pinSize.height / 2 + 2

        ctx.fillStyle = '#FFFFFF'
        ctx.font = '14px Georgia'
        ctx.fillText(`${pin.PinNumber}`, x, y)
        const resp = await file.canvasToResult(ctx.canvas, 'before.jpg')

        setImageUrl(resp.text)
      }
    } catch (e) {
      console.log('err', e.message)
    }
  }

  const color = getColor(pin.PinStatus)
  return (
    <MainView>
      <Plan>
        <ImgCard src={imageUrl} />
      </Plan>

      <Hide>
        <Canvas ref={onRefCanvas} />
        <PinIcon
          id="icon"
          width={pinSize.width}
          height={pinSize.height}
          color={color}
        />
      </Hide>
    </MainView>
  )
}

const MainView = styled.div`
  width: 100%;
`

const Plan = styled.div`
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: white;
  margin: 0 auto;
`

const ImgCard = styled.img`
  width: 100%;
  height: 230px;
  object-fit: scale-down;
  @media print {
    height: 150px;
  }
`

export const Canvas = styled.canvas``

const Hide = styled.div`
  display: none;
`

const Pin: any = styled.span`
  position: absolute;

  span {
    color: white;
    font-size: 14px;
    position: absolute;
    top: 6%;
  }
`

const EditPin = styled(Pin)``

export default PinView
