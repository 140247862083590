import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Row, Col } from 'antd'
import { WarningIcon, CloseIcon } from 'Components/Shared/Icon'

interface Props {
  isShow: boolean
  time?: number
  text?: string
  callback?: () => void
}

const AlertError: React.FC<Props> = props => {
  const { isShow, time = 3000, text = 'Error', callback } = props
  const [showAlert, setShowAlert] = useState(false)

  // Limit Time
  // useEffect(() => {
  //   if (isShow) {
  //     setShowAlert(true)
  //     setTimeout(() => {
  //       setShowAlert(false)
  //       if (callback) {
  //         callback()
  //       }
  //     }, time)
  //   }
  // }, [isShow])

  useEffect(() => {
    if (isShow) {
      setShowAlert(true)
    }
  }, [isShow])

  const alertPopup = (
    <ErrorPopup>
      <Alert>
        <Row
          type="flex"
          justify="space-around"
          align="middle"
          style={{ height: '40px' }}
        >
          <Col span={1}>
            <Icon>
              <WarningIcon />
            </Icon>
          </Col>
          <Col span={22}> {text} </Col>
          <Col span={0.5}>
            <EndIcon
              onClick={() => {
                setShowAlert(false)
                if (callback) {
                  callback()
                }
              }}
            >
              <CloseIcon color="#c62828" />
            </EndIcon>
          </Col>
        </Row>
      </Alert>
    </ErrorPopup>
  )
  return showAlert ? alertPopup : null
}

const ErrorPopup = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 144px;
  z-index: 5000;
  margin: 0 80px;
`

const Alert = styled.div`
  width: 100%;
  height: 40px;
  background-color: rgba(240, 217, 224, 1);
  border: solid 1px #d0494c;
  border-radius: 5px;
  padding: 0 16px;
  font-size: 18px;
  font-family: 'DB Helvethaica X 55 Regular';
`

const Icon = styled.div`
  margin-right: 8px;
`
const EndIcon = styled.div`
  cursor: pointer;
  margin-top: 7px;
`

export default AlertError
