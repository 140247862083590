import withAPIAction from 'utils/withAPIAction'
import { GetUnitTodoForQCAction, GetZoneTodoForQCAction } from './actions'
import { GetProjectTodoReq } from './types'
import { verifyReqNoProject } from 'utils/verify'

export const getUnitTodoForQCApi = (token: string, data: GetProjectTodoReq) => {
  const isValid = verifyReqNoProject(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<GetProjectTodoReq>({
    method: 'POST',
    path: 'GetUnitTodoForQC',
    data,
    token: `Bearer ${token}`,
  })(GetUnitTodoForQCAction)
}

export const getZoneTodoForQCApi = (token: string, data: GetProjectTodoReq) => {
  const isValid = verifyReqNoProject(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<GetProjectTodoReq>({
    method: 'POST',
    path: 'GetZoneTodoForQC',
    data,
    token: `Bearer ${token}`,
  })(GetZoneTodoForQCAction)
}

export const getCommissioningTodoForQC = (
  token: string,
  data: GetProjectTodoReq
) => {
  const isValid = verifyReqNoProject(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<GetProjectTodoReq>({
    method: 'POST',
    path: 'GetCommissioningTodoForQC',
    data,
    token: `Bearer ${token}`,
  })(GetZoneTodoForQCAction)
}
