import { takeEvery, all, put, call, select } from 'redux-saga/effects'
import { getType } from 'typesafe-actions'
import * as actions from './actions'
import APIManager, { configWithToken } from 'services/APIManager'
import { APIPath } from 'services/APIPath'
import {
  serviceTokenSelector,
  userEmailSelector,
} from 'features/sensitive/sensitive.selector'
import { textToLowerCase } from 'utils/format'

const setSeqNumber = (list: any[]): any[] => {
  list.forEach((it1: any, i1: number) => {
    const lv1 = i1 + 1
    it1.Level = 1
    it1.Seq = `${lv1}`
    it1.Data.forEach((it2: any, i2: number) => {
      const lv2 = i2 + 1
      it2.Level = 2
      it2.Seq = `${lv1}.${lv2}`
      it2.Data.forEach((it3: any, i3: number) => {
        const lv3 = i3 + 1
        it3.Level = 3
        it3.Seq = `${lv1}.${lv2}.${lv3}`
      })
    })
  })

  return list
}

function* createUpdateCommission(
  action: ReturnType<typeof actions.creatUpdateCommissionDetailReq.request>
) {
  const {
    isSafety,
    commissionDetail,
    activityByCode,
    q1,
    commission,
    safety,
    history,
  } = action.payload
  const Token = yield select(serviceTokenSelector)
  const UserEmail = yield select(userEmailSelector)

  try {
    let res
    if (textToLowerCase(commissionDetail.Status) !== 'used') {
      if (isSafety) {
        const safetyList = setSeqNumber(safety || [])
        res = yield call(
          APIManager.call,
          commissionDetail.CheckListID
            ? APIPath.UpdateCommissioningCheckList
            : APIPath.CreateCommissioningCheckList,
          'POST',
          {
            ...commissionDetail,
            DataList: [
              {
                List: safetyList,
                CommissioningType: 3,
              },
            ],
            UserEmail,
          },
          configWithToken(`Bearer ${Token}`)
        )
      } else {
        const q1List = setSeqNumber(q1 || [])
        const comList = setSeqNumber(commission || [])
        res = yield call(
          APIManager.call,
          commissionDetail.CheckListID
            ? APIPath.UpdateCommissioningCheckList
            : APIPath.CreateCommissioningCheckList,
          'POST',
          {
            ...commissionDetail,
            DataList: [
              {
                CommissioningType: 1,
                List: q1List,
              },
              {
                CommissioningType: 2,
                List: comList,
              },
            ],
            UserEmail,
          },
          configWithToken(`Bearer ${Token}`)
        )
      }
      if (res && res.ResponseData) {
        commissionDetail.CheckListID = res.ResponseData.CheckListID
      }
    }

    for (const [code, list] of Object.entries(activityByCode)) {
      if (list.length > 0) {
        yield call(
          APIManager.call,
          APIPath.UpdateActivityByCommissioning,
          'POST',
          {
            CheckListID: commissionDetail.CheckListID,
            ProjectCode: code,
            ActivityList: list,
            UserEmail,
          },
          configWithToken(`Bearer ${Token}`)
        )
      }
    }

    yield put(actions.creatUpdateCommissionDetailReq.success())
    history.push('/check-commission')
    yield put(actions.clearEvent())
  } catch (error) {
    yield put(actions.creatUpdateCommissionDetailReq.failure(error))
  }
}

function* deleteCommission(
  action: ReturnType<typeof actions.deleteReq.request>
) {
  try {
    const Token = yield select(serviceTokenSelector)
    const UserEmail = yield select(userEmailSelector)
    const { CheckListID, history } = action.payload
    yield call(
      APIManager.call,
      APIPath.DeleteCommissioningCheckList,
      'POST',
      { UserEmail: UserEmail, CheckListID },
      configWithToken(`Bearer ${Token}`)
    )
    yield put(actions.deleteReq.success())
    history.push('/check-commission')
  } catch (error) {
    yield put(actions.deleteReq.failure(error))
  }
}

function* getCommissionDetail(
  action: ReturnType<typeof actions.getCommissionDetailReq.request>
) {
  try {
    const Token = yield select(serviceTokenSelector)
    const UserEmail = yield select(userEmailSelector)
    const { CheckListID, CommissioningType } = action.payload
    const res = yield call(
      APIManager.call,
      APIPath.GetCommissioningCheckListById,
      'POST',
      { UserEmail: UserEmail, CheckListID, CommissioningType },
      configWithToken(`Bearer ${Token}`)
    )
    yield put(
      actions.getCommissionDetailReq.success({ res, CommissioningType })
    )
  } catch (error) {
    yield put(actions.getCommissionDetailReq.failure(error))
  }
}

function* allSaga() {
  yield all([
    takeEvery(
      getType(actions.creatUpdateCommissionDetailReq.request),
      createUpdateCommission
    ),
    takeEvery(getType(actions.deleteReq.request), deleteCommission),
    takeEvery(
      getType(actions.getCommissionDetailReq.request),
      getCommissionDetail
    ),
  ])
}

export default allSaga
