import React from 'react'
import styled from 'styled-components'

const BaseImgBtn = (props: any) => {
  return <Section src={props.img} alt="" onClick={props.onClick} />
}

const Section = styled.img`
  width: 24px;
  height: 24px;

  :hover {
    cursor: pointer;
  }
`

export default BaseImgBtn
