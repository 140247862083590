import React from 'react'
import { Row, Col } from 'antd'

const sortBy = (query: string) => {
  alert(`Implement sort by ${query}`)
}

type Props = {
  items: any[]
}

const SearchDataTableHeader: React.FC<Props> = ({ items }) => {
  return (
    <Row gutter={8} className="search-result--header">
      {items.map(item => (
        <Col
          key={item.title}
          span={item.span}
          className="action-header"
          onClick={item.onClick}
        >
          {item.title}
          <img src={item.img} width={16} height={16} alt="arrow icon" />
        </Col>
      ))}
    </Row>
  )
}

export default SearchDataTableHeader
