import React, { useState } from 'react'
import styled from 'styled-components'

import { DropdownList } from 'Components/Shared/Form'
import InputText from 'Components/Shared/Input/InputText'
import { SelectType } from '..'
import {
  CheckListDetail,
  SubJobType,
  JobType,
  DetailType,
} from 'features/checklist/constructRoad/types'
import { BottomButton } from 'Components/Shared/Button'
import QcTypeDropdown from './QcTypeDropdown'

interface Props {
  selected: SelectType
  checkListDetail: CheckListDetail
  editingObj?: DetailType
  onSave: (detail: DetailType) => void
  closeModal: () => void
  disabled: boolean
}

const DetailJobModal: React.FC<Props> = ({
  checkListDetail,
  selected,
  editingObj,
  onSave: onSaveProp,
  closeModal,
  disabled,
}) => {
  const [error, seterror] = useState('')
  const [name, setname] = useState(editingObj ? editingObj.DetailName : '')

  const qctype = checkListDetail.QCTypeList[selected.qctype]
  const job = qctype.JobTypeList[selected.job]
  const subjob =
    (job && job.SubJobTypeList && job.SubJobTypeList[selected.subJob]) || []

  const changeName = (val: string) => {
    setname(val)
  }

  const onSave = () => {
    if (name && qctype && job && subjob) {
      const found = editingObj
        ? null
        : subjob.DetailList.find(v => v.DetailName === name)
      if (found == null) {
        onSaveProp({
          DetailID: editingObj ? editingObj.DetailID : 0,
          DetailName: name,
          IsRequired: editingObj ? editingObj.IsRequired : false,
          IsSupplier: editingObj ? editingObj.IsSupplier : false,
          Score: 0,
          NCRScore: 0,
        })
      } else {
        seterror('รายละเอียดนี้ ได้มีอยู่แล้ว')
      }
    } else {
      seterror('กรุณาใส่ข้อมูลทั้งหมด')
    }
  }

  const menuChoiceJob = () => {
    const result = qctype.JobTypeList.map((item: JobType, index: number) => (
      <DataItem key={index}>{item.JobTypeName}</DataItem>
    ))
    return result
  }

  const menuChoiceSubJob = () => {
    const result =
      job &&
      job.SubJobTypeList.map((item: SubJobType, index: number) => (
        <DataItem key={index}>{item.SubJobTypeName}</DataItem>
      ))
    return result
  }

  return (
    <MainView>
      <DropdownBox>
        <InputText
          prefix="ชื่อ"
          value={name}
          placeholder="กรอกชื่อ"
          onChange={changeName}
          openWithFocus
          disabled={disabled}
        />
      </DropdownBox>
      <QcTypeDropdown selected={selected} checkListDetail={checkListDetail} />

      <DropdownBox>
        <div className="dropdown-item">
          <DropdownList
            label="ลักษณะงาน"
            value=""
            placeholder={job ? job.JobTypeName : 'เลือก'}
            menuChoice={menuChoiceJob()}
            disabled={true}
          />
        </div>
      </DropdownBox>

      <DropdownBox>
        <div className="dropdown-item">
          <DropdownList
            label="ลักษณะงานรอง"
            value=""
            placeholder={subjob ? subjob.SubJobTypeName : 'กรุณาเลือก'}
            menuChoice={menuChoiceSubJob()}
            disabled={true}
          />
        </div>
      </DropdownBox>

      {!disabled && (
        <BottomButton closeModal={closeModal} onSave={onSave} error={error} />
      )}
    </MainView>
  )
}

const MainView = styled.div``

const DropdownBox = styled.div`
  padding-top: 12px;
`

const DataItem = styled.div`
  padding: 5px 10px;

  &:hover {
    cursor: pointer;
    color: white;
    background-color: #003168;
  }
`

export default DetailJobModal
