import React, { useState } from 'react'
import { Col } from 'antd'
import styled from 'styled-components'
import { TableRow, StatusCol } from '../../Shared/Table'
import { Activity } from 'features/maincon/dashboard/types'
import { Moment } from 'moment'
import { WarningIcon } from 'Components/Shared/Icon'
import TaskProgress from 'Components/Shared/TaskProgress'
import { getDelayedDate, getDelayedText, getStartText } from 'utils/datetime'
import { Link } from 'react-router-dom'
import ContructionTagName from 'Components/Shared/ContructionTagName'
import { isUnit, displayCategory } from 'utils/helper'
import RadioBox from 'Components/Shared/Form/RadioBox'
import { Checkbox } from 'Components/Shared/Form'

type Props = {
  task: Activity
  type: string
  search?: string
  selected?: boolean
  onSelect?: () => void
}

const RowItem: React.FC<Props> = props => {
  const { task, type, search, selected = false } = props
  let isDelayed

  const renderDelayedDate = (date: string | Moment) => {
    let content
    const delayed = getDelayedDate(date)
    if (delayed < 0) {
      let days = Math.abs(delayed)
      isDelayed = true
      content = (
        <div className="status status-danger">
          <WarningIcon />
          <span className="countdown">{getDelayedText(days, type)}</span>
        </div>
      )
    } else {
      isDelayed = false
      content = (
        <div className={'status status-default'}>
          <span className="countdown">{getStartText(delayed, type)}</span>
        </div>
      )
    }
    return content
  }

  const renderSpecificData = (type: string) => {
    let content
    switch (type) {
      case 'todo':
        content = (
          <>
            <Col span={3}>{task.BaselineStartDate}</Col>
            <Col span={3}>{task.BaselineFinishDate}</Col>
            <Col span={4}>
              <Text>{delayedDate}</Text>
            </Col>
          </>
        )
        break

      case 'in-progress':
        content = (
          <>
            <Col span={3}>{task.BaselineFinishDate}</Col>
            <Col span={4}>
              <Text>{delayedDate}</Text>
            </Col>
            <Col span={3}>
              <TaskProgress task={task} />
            </Col>
          </>
        )
        break
      default:
        break
    }
    return content
  }

  const delayedDate = renderDelayedDate(
    type == 'todo' ? task.BaselineStartDate : task.BaselineFinishDate
  )

  return (
    <TableRow paddingLeft={27}>
      {type === 'todo' && (
        <Col span={1}>
          <CheckboxArea>
            <Checkbox label={''} value={selected} onChange={props.onSelect} />
          </CheckboxArea>
        </Col>
      )}
      <Col span={7}>
        <StatusCol status={isDelayed ? 'cancelled' : ''} marginLeft={-27}>
          <Link to={`/activity/${task.ActivityID}${search}`}>
            <Name>{task.ActivityName}</Name>
          </Link>
        </StatusCol>
      </Col>
      <Col span={3}>{displayCategory(task)}</Col>
      <Col span={4}>
        {task.ConstructionTypeName && (
          <ContructionTagName name={task.ConstructionTypeName} />
        )}
      </Col>
      {renderSpecificData(type)}
    </TableRow>
  )
}

const Name = styled.div`
  font-size: 20px;
  font-family: 'DB Helvethaica X 75 Bd';
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const CheckboxArea = styled.div`
  text-align: center;
`

const Text = styled.div`
  color: #8b99a7;
`

const TextEllipsis = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 5px;
`

const ItemType = styled.div`
  width: max-content;
  color: white;
  background-color: #cc886f;
  font-size: 16px;
  border-radius: 10px;
  padding: 0px 12px;
  height: 20px;
  display: -webkit - inline - box;
  line-height: 1.4;
  &.lavender {
    background-color: #ab7cd8;
  }
`

export default RowItem
