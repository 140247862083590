export type TaskStatus = keyof {
  INIT: 'INIT'
  INPRO: 'INPRO'
  APPV: 'APPV'
  REJT: 'REJT'
}

export enum StatusOfTask {
  INIT = 'INIT',
  INPRO = 'INPRO',
  APPV = 'APPV',
  REJT = 'REJT',
}

export type TaskState = keyof {
  MC: 'MC'
  CM: 'CM'
  PJPO: 'PJPO'
}

export enum StateOfTask {
  MC = 'MC',
  CM = 'CM',
  PJPO = 'PJPO',
}

export enum DefectStatus {
  TOBEFIX = 'ToBeFix',
  GOODWORK = 'GoodWork',
  PASS = 'Pass',
  FIXED = 'Fixed',
}

export enum DefectStatusText {
  TOBEFIX = 'ยังไม่แก้ไข',
  GOODWORK = 'Good work',
  PASS = 'ผ่านการตรวจสอบ',
  FIXED = 'แก้ไขแล้ว',
}

export enum DefectColor {
  TOBEFIX = '#d0494c',
  GOODWORK = '#79cadc',
  PASS = '#5bc79e',
  FIXED = '#e7a065',
}

export enum UnitStatus {
  Pass = 'Pass',
  PassByFunc = 'PassByFunc',
  NotPass = 'NotPass',
  NotPassByRequireDefect = 'NotPassByReqDefect',
  NotPassByFunction = 'NotPassByFunc',
  PassToFix = 'PassToFix',
  NotInspection = 'NotInspection',
}

export enum FunctionStatus {
  Pass = 'PASS',
  NotPass = 'NOTPASS',
}

export enum InteriorStatus {
  Have = 'Have',
  None = 'None',
  No = 'No',
}

export enum InteriorStatusText {
  Have = 'มี',
  None = '',
  No = 'ไม่มี',
}

export enum SendReportStatus {
  Yes = 'Y',
  No = 'N',
  None = 'None',
}

export enum CheckListStatus {
  PASS = 'PASS',
  SKIP = 'SKIP',
  FAIL = 'FAIL',
  NOTCHECK = 'NOTCHECK',
}

export enum ZoneState {
  CMQC = 'CM-QC',
  QCQC = 'QC-QC',
}
