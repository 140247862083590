import React, { useState } from 'react'
import { Col } from 'antd'
import styled from 'styled-components'
import { TableRow, StatusCol } from '../../Shared/Table'
import { CheckedIcon, UncheckedIcon } from '../Icon'

type DefaultProps = {
  contentRow?: any[]
  isSelected?: boolean
  isHighlight?: boolean
}
type Props = DefaultProps

const RowItem: React.FC<Props> = props => {
  const { isSelected = false, contentRow = [], isHighlight = false } = props

  const getRadioBox = (onClickRadio: Function) => {
    return (
      <div onClick={() => onClickRadio()}>
        {isSelected ? <CheckedIcon size={20} /> : <UncheckedIcon size={20} />}
      </div>
    )
  }

  const content = contentRow.map(list => {
    if (list.value === 'selected') {
      return <Col span={list.span}>{getRadioBox(list.onClick)}</Col>
    } else {
      return <Col span={list.span}>{list.value}</Col>
    }
  })

  return (
    <TableRow minHeight={60} paddingLeft={24} spaceBetweenRow={8}>
      {isHighlight && (
        <Col>
          <StatusCol status="cancelled" marginLeft={-27} minHeight={60}/>
        </Col>
      )}
      {content}
    </TableRow>
  )
}

const TextGreen = styled.div`
  color: #5bc79e;
`

const TextRed = styled.div`
  color: #d0494c;
`

export default RowItem
