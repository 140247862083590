import React, { useState } from 'react'
import { translate } from 'i18n'
import TKeys from 'i18n/translationKey'
import { useDispatch } from 'react-redux'
import { performSearchRequest } from 'features/search/search.actions'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import getSearchQuery from 'utils/getSearchQuery'
import { DEFAULT_SEARCH_RESULT_ITEMS_PER_PAGE } from 'config'

type SearchBoxProps = {
  keyword: string
  seeMoreType?: string
}

type Props = SearchBoxProps & RouteComponentProps

const SearchInputBox: React.FunctionComponent<Props> = ({
  location,
  history,
  seeMoreType,
}) => {
  const { keyword, type } = getSearchQuery(location.search)
  const [clearIcon, setClearIcon] = useState(() => keyword.length > 0)
  const [searchKeyword, setSearchKeyword] = useState(keyword)
  const searchSeeMoreType = seeMoreType || type

  const dispatch = useDispatch()

  return (
    <div className="search-box">
      <input
        type="text"
        placeholder={translate(TKeys.Dashboard.search_placeholder)}
        value={searchKeyword}
        style={{ fontSize: '20px' }}
        onKeyUp={e => {
          const userSearchKeyword = e.currentTarget.value

          // Search keyword is empty, no point makeing API request, ignore it.
          if (userSearchKeyword === '' || !userSearchKeyword) {
            return
          }

          // If user hits Enter keyboard key, start search request
          if (e.keyCode === 13) {
            setSearchKeyword(userSearchKeyword)

            dispatch(
              performSearchRequest({
                // User search keyword
                keyword: userSearchKeyword,

                // Search will always begin with Page 1,
                page: 1,

                // Default items per page
                rowPerPage: Number(DEFAULT_SEARCH_RESULT_ITEMS_PER_PAGE),

                // History object is passed along so that we can redirect user to other pages easily
                history,
                type: searchSeeMoreType,
              })
            )
          }
        }}
        onChange={e => {
          const updText = e.currentTarget.value
          setSearchKeyword(updText)

          setClearIcon(updText.length > 0)
        }}
      />
      <button type="submit" name="search" className="btn-search">
        {clearIcon ? (
          <img
            src="/img/ic-close.svg"
            width={16}
            height={16}
            alt="clear search keyword"
            onClick={() => {
              setSearchKeyword('')
              setClearIcon(false)
            }}
          />
        ) : (
          <img
            src="/img/ic-search.svg"
            width={16}
            height={16}
            alt="search icon"
          />
        )}
      </button>
    </div>
  )
}

export default withRouter(SearchInputBox)
