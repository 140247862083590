/* eslint-disable */
import React, { useState, useCallback, useLayoutEffect } from 'react'
import styled from 'styled-components'
import { Input } from 'antd'

import BaseEditor from './BaseEditor'

const StickerEditor = props => {
  const [editMode, setEditMode] = useState(false)
  const [inputFocus, setInputFocus] = useState(false)
  const [current, setCurrent] = useState({
    size: { width: 100, height: 40 },
    position: { x: 0, y: 0 },
  })

  const {
    initial,
    value = {
      value: '',
      fontSize: 20,
      lineSize: 3,
      color: '#DEDEDE',
    },
    limit = {
      left: 0,
      top: 0,
      bottom: 1000,
      right: 2000,
      width: 200,
      height: 25,
    },
  } = props

  let { size, position } = current
  const { lineSize, color } = value

  useLayoutEffect(() => {
    if (initial.size) {
      current.size = initial.size
    }

    if (initial.position) {
      current.position = initial.position
    }

    setCurrent({ ...current })
    onPosition(
      current.position.x,
      current.position.y,
      current.size.width,
      current.size.height
    )
  }, [initial])

  const onPosition = (x, y, width, height) => {
    try {
      if (props.onPosition) {
        const fontSize = getFontSize(height)
        const newSize = getTextSize(value.value, fontSize)
        size = { width: newSize.width + 25, height }
        position.x = x
        position.y = y
        props.onPosition(x, y, size.width, height)

        value.fontSize = fontSize
        props.onChange(value)
        setCurrent({ size, position })
      }
    } catch (e) {
      console.log('error:', e.message)
    }
  }

  const onValidate = ({ x, y, width, height }) => {
    try {
      const fontSize = getFontSize(height)
      const newSize = getTextSize(value.value, fontSize)
      size = { width: newSize.width + 25, height }
      return x + size.width <= limit.right
    } catch (e) {
      return false
    }
  }

  const onTextChange = evt => {
    try {
      if (props.onChange) {
        value.value = evt.target.value
        props.onChange(value)

        const newSize = getTextSize(value.value, value.fontSize)
        const width = newSize.width + 25
        size = { width, height: size.height }
        setCurrent({ size, position })
        props.onPosition(position.x, position.y, width, size.height)
      }
    } catch (e) {
      console.log('text change:', e.message)
    }
  }

  const getTextSize = (val, size) => {
    const text = document.createElement('span')
    document.body.appendChild(text)

    text.style.font = `${size}px 'DB Helvethaica X 55 Regular'`
    text.style.height = 'auto'
    text.style.width = 'auto'
    text.style.position = 'absolute'
    text.style.whiteSpace = 'nowrap'
    text.innerHTML = val

    const width = Math.ceil(text.clientWidth)
    const height = Math.ceil(text.clientHeight)

    document.body.removeChild(text)

    return { width, height }
  }

  const getFontSize = height => {
    const fontSize = Math.floor(height / 1.2)

    return fontSize
  }

  const closeEditMode = () => setEditMode(false)

  const displayEditor = () => {
    setInputFocus(false)
    setEditMode(true)
  }

  let content
  if (editMode) {
    const css = {
      color: 'black',
      fontSize: value.fontSize,
      position: 'absolute',
      left: 0,
      top: 0,
      zIndex: 10,
      width: size.width,
      minWidth: '300px',
      height: size.height,
    }
    content = (
      <Input
        style={css}
        placeholder="Input a text"
        value={value.value}
        onFocus={() => setInputFocus(true)}
        onMouseLeave={inputFocus ? undefined : closeEditMode}
        onBlur={inputFocus ? closeEditMode : undefined}
        onChange={onTextChange}
      />
    )
  } else {
    const css = {
      color,
      fontSize: value.fontSize,
      width: size.width,
      height: size.height,
    }
    content = <Text style={css}>{value.value}</Text>
  }

  return (
    <BaseEditor
      isCircle={false}
      displayConner={false}
      displaySide={false}
      displayBottom={true}
      hideBorder={editMode}
      initial={current}
      limit={limit}
      onPosition={onPosition}
      onDoubleClick={displayEditor}
      onValidate={onValidate}
    >
      {content}
    </BaseEditor>
  )
}

const Text = styled.div`
  font-family: 'DB Helvethaica X 55 Regular';
  padding-left: 2px;
  line-height: 1.2;
  position: absolute;
  left: 0;
  top: 0;
  white-space: nowrap;
`

export default StickerEditor
/* eslint-enable */
