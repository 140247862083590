import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { connect, useDispatch } from 'react-redux'
import { Row, Col, Drawer } from 'antd'
import { RouteComponentProps } from 'react-router-dom'
import RootState from 'typings/RootState'
import SubnavBar from 'Components/Shared/SubnavBar'
import { BreadcrumbLink } from 'Components/Shared/BreadCrumb/BreadCrumb'
import { TableHeader, TableColHeader } from 'Components/Shared/Table'
import AdminQcTypeDetail from './Detail'
import Menu from '../../MenuAdmin'

import {
  userEmailSelector,
  serviceTokenSelector,
} from 'features/sensitive/sensitive.selector'

import {
  getDataList,
  createData,
  updateData,
  deleteData,
} from 'features/admin/team/api'
import { selectDataList, selectEvent } from 'features/admin/team/selector'
import {
  GetDataListReq,
  DataItem,
  CreateDataReq,
  UpdateDataReq,
  DeleteDataReq,
} from 'features/admin/team/types'

import RowItem from './RowItem'
import { EventModal, ConfirmDeleteModal } from 'Components/Shared'
import { PrimaryButton } from 'Components/Shared/Button'

const breadcrumbs: BreadcrumbLink[] = [
  {
    url: '/dashboard',
    title: 'Home',
    icon: '/home.png',
    active: true,
    showTitle: false,
  },
  {
    url: '/qc-type',
    title: 'QC Type list',
    active: false,
    showTitle: false,
  },
]

type DefultProps = {
  homepage?: boolean
}

type Props = DefultProps & ReduxProps & DispatchProps & RouteComponentProps

const AdminTribe: React.ComponentType<Props> = props => {
  const { token, UserEmail, dataList, event } = props
  const [mode, setMode] = useState('' as 'update' | 'create' | 'delete' | '')
  const [deleteModal, setDeleteModal] = useState(false)
  const [disPlayModal, setdisPlayModal] = useState(false)
  const [selectedItem, setSelectedItem] = useState({} as any)
  const [enableModal, setEnableModal] = useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    onGetList()
    setEnableModal(false)
  }, [])

  const onGetList = () => {
    setEnableModal(true)
    const req: GetDataListReq = {
      UserEmail,
    }
    dispatch(getDataList(token, req))
  }

  const onCreateTeam = (item: DataItem) => {
    setEnableModal(true)
    const req: CreateDataReq = {
      UserEmail,
      TeamName: item.TeamName,
      SquadList: item.SquadList || [],
    }
    dispatch(createData(token, req))
  }

  const onUpdateTeam = (item: DataItem) => {
    setEnableModal(true)
    const req: UpdateDataReq = {
      UserEmail,
      TeamID: item.TeamID || 0,
      TeamName: item.TeamName,
      SquadList: item.SquadList || [],
    }
    dispatch(updateData(token, req))
  }

  const onDeleteTeam = (item: DataItem) => {
    setDeleteModal(false)
    setEnableModal(true)
    const req: DeleteDataReq = {
      UserEmail,
      TeamID: item.TeamID || 0,
    }
    dispatch(deleteData(token, req))
  }

  const onCreate = () => {
    setSelectedItem({
      SquadList: [],
      TeamName: '',
    } as DataItem)
    setdisPlayModal(true)
    setMode('create')
  }

  const onEdit = (item: any) => {
    setSelectedItem(item)
    setdisPlayModal(true)
    setMode('update')
  }

  const onDelete = (item: any) => {
    setSelectedItem(item)
    setDeleteModal(true)
    setMode('delete')
  }

  const onCloseDrawer = () => {
    setSelectedItem({})
    setdisPlayModal(false)
    setEnableModal(false)
  }

  const onSave = (item: DataItem) => {
    if (mode === 'create') {
      onCreateTeam(item)
    }

    if (mode === 'update') {
      onUpdateTeam(item)
    }
  }

  const onSuccess = () => {
    if (mode !== '') {
      onCloseDrawer()
      setMode('')
      onGetList()
    }
  }

  const content = dataList.List.map((item: DataItem, index: number) => {
    const selected = item.TeamID === selectedItem.TeamID && mode !== 'delete'

    return (
      <RowItem
        item={item}
        selected={selected}
        onClick={() => onEdit(item)}
        onDelete={onDelete}
        key={index}
      />
    )
  })
  return (
    <>
      <SubnavBar breadcrumbs={breadcrumbs} hideSearch={true} />
      <EventModal
        name="defect"
        event={[event]}
        enable={enableModal}
        successCalback={onSuccess}
      />
      <Menu selected={'tribe'}>
        <MainPage>
          <Header>
            <Row type="flex" align="middle" justify="space-between">
              <Col>
                <Title>Tribe / Squad</Title>
              </Col>
              <Col style={{ width: '160px' }}>
                <PrimaryButton
                  text="สร้าง Tribe ใหม่"
                  disabled={event.IsLoading}
                  onClick={onCreate}
                />
              </Col>
            </Row>
          </Header>

          <TableHeader>
            <TableColHeader span={6} isPadding={true}>
              Tribe
            </TableColHeader>
            <TableColHeader span={8}>Squad</TableColHeader>
            <TableColHeader span={6} offset={1} sort={false}>
              {' '}
            </TableColHeader>
          </TableHeader>
          {content}
        </MainPage>

        <Drawer
          title={null}
          className="drawer-project"
          closable={false}
          width={400}
          visible={disPlayModal}
        >
          <AdminQcTypeDetail
            item={selectedItem}
            onSave={onSave}
            onClose={onCloseDrawer}
            isUpdateMode={mode === 'update'}
          />
        </Drawer>
        <ConfirmDeleteModal
          title="ลบรายการ"
          desc={'คุณยืนยันต้องการลบ Team นี้'}
          onClose={() => setDeleteModal(false)}
          onSubmit={() => onDeleteTeam(selectedItem)}
          visible={deleteModal}
        />
      </Menu>
    </>
  )
}

const MainPage = styled.div`
  padding: 40px 45px;
  width: 100%;
`

const Header = styled.div`
  margin-bottom: 35px;
  height: 50px;
`

const Title = styled.h2`
  font-size: 28px;
  font-family: 'DB Helvethaica X 75 Bd';
  height: 30px;
`

const mapStateToProps = (state: RootState) => {
  return {
    dataList: selectDataList(state),
    event: selectEvent(state),
    token: serviceTokenSelector(state),
    UserEmail: userEmailSelector(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminTribe)
