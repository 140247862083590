import withAPIAction from 'utils/withAPIAction'

import {
  getZoneListReq,
  getZoneDetailReq,
  getProjectZoneReq,
  GetZoneTypeListReq,
  createUpdateZoneReq,
} from './actions'
import { ZoneDetail } from './types'
import { PaginationType } from 'features/types/common'

export const GetZoneList = (page: PaginationType, TextFilter: string = '') => {
  return withAPIAction<PaginationType>(state => ({
    method: 'POST',
    path: 'GetZoneCheckList',
    data: {
      ...page,
      UserEmail: state.sensitive.userEmail,
      TextFilter,
    },
    token: `Bearer ${state.sensitive.apiToken}`,
  }))(getZoneListReq)
}

export const getZoneDetail = (CheckListID: number) => {
  return withAPIAction<{ CheckListID: number }>(state => ({
    method: 'POST',
    path: 'GetZoneCheckListById',
    data: { CheckListID, UserEmail: state.sensitive.userEmail },
    token: `Bearer ${state.sensitive.apiToken}`,
  }))(getZoneDetailReq)
}

export const getProjectZone = () => {
  return withAPIAction(state => ({
    method: 'POST',
    path: 'GetProjectOnZoneCheckList',
    data: { UserEmail: state.sensitive.userEmail },
    token: `Bearer ${state.sensitive.apiToken}`,
  }))(getProjectZoneReq)
}

export const getZoneTypeList = () => {
  return withAPIAction<any>(state => ({
    method: 'POST',
    path: 'GetZoneTypeList',
    data: {
      UserEmail: state.sensitive.userEmail,
    },
    token: `Bearer ${state.sensitive.apiToken}`,
  }))(GetZoneTypeListReq)
}

export const updateCheckList = (data: ZoneDetail) => {
  const path =
    data.CheckListID === 0 ? 'CreateZoneCheckList' : 'UpdateZoneCheckList'

  return withAPIAction<any>(state => ({
    method: 'POST',
    path,
    data: {
      ...data,
      UserEmail: state.sensitive.userEmail,
    },
    token: `Bearer ${state.sensitive.apiToken}`,
  }))(createUpdateZoneReq)
}
