import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Layout, Row, Col } from 'antd'
import { RouteComponentProps } from 'react-router-dom'
import { BreadcrumbLink } from '../../../Shared/BreadCrumb/BreadCrumb'
import SubnavBar from '../../../Shared/SubnavBar'
import Menu from '../../../MenuAdmin'
import ModuleBox from '../Components/ModuleBox'
import ProjectBox from '../Components/ProjectBox'
import EditUser from '../Components/EditUser'
import { PrimaryButton } from 'Components/Shared/Button'
import { DataItem, ModuleItem, CreateDataReq } from 'features/admin/user/types'
import { createData } from 'features/admin/user/api'
import { selectEvent } from 'features/admin/user/selector'
import {
  serviceTokenSelector,
  userEmailSelector,
} from 'features/sensitive/sensitive.selector'

import { selectRoleItem } from 'features/filter/selector'

import RootState from 'typings/RootState'
import { connect, useDispatch } from 'react-redux'
import { EventModal } from 'Components/Shared'

const breadcrumbs: BreadcrumbLink[] = [
  {
    url: '/dashboard',
    title: 'Home',
    icon: '/home.png',
    active: true,
    showTitle: false,
  },
  {
    url: '/user',
    title: 'user',
    active: false,
    showTitle: false,
  },
]

const { Content } = Layout
type Props = RouteComponentProps & ReduxProps
const UserDetail: React.ComponentType<Props> = props => {
  const { UserEmail, token, roleList, event } = props
  const [newUser, setNewUser] = useState({} as DataItem)
  const [activeModule, setActiveModule] = useState({} as any)
  const [enableModal, setEnableModal] = useState(false)

  useEffect(() => {
    setNewUser({
      UserID: '',
      UserNameTH: '',
      UserNameEN: '',
      Username: '',
      UserEmail: '',
      Status: 'Active',
      PositionName: '',
      PositionID: 0,
      RoleID: 0,
      RoleName: '',
      ModuleList: [],
      IsVendor: false,
      IsMicrosoftAccount: false,
    } as DataItem)
    setEnableModal(false)
  }, [])

  const dispatch = useDispatch()

  const onCreateUser = () => {
    setEnableModal(true)
    const req: CreateDataReq = {
      UserEmail,
      UserID: newUser.UserID,
      Username: newUser.Username,
      PositionID: newUser.PositionID,
      RoleID: newUser.RoleID,
      IsVendor: newUser.IsVendor,
      Status: newUser.Status,
      ModuleList: newUser.ModuleList,
    }
    dispatch(createData(token, req))
  }

  const onUpdate = (user: DataItem) => {
    setNewUser({ ...user })
  }

  const gotoUserListPage = () => {
    props.history.push('/user')
  }

  const onActiveModule = (module: ModuleItem) => {
    setActiveModule(module)
  }

  const onSuccess = () => {
    setEnableModal(false)
    gotoUserListPage()
  }

  const onUpdateProject = (module: ModuleItem) => {
    setActiveModule(module)
    const modules = newUser.ModuleList.map((item: ModuleItem) => {
      if (item.ModuleID === module.ModuleID) {
        return module
      } else {
        return item
      }
    })
    newUser.ModuleList = modules
    setNewUser({ ...newUser })
  }

  const isInvalidData = () => {
    if (newUser.UserID === '') {
      return true
    }
    if (!newUser.Status) {
      return true
    }
    return false
  }

  const leftSection = (
    <LeftSection>
      <EditUser user={newUser} roleList={roleList} onUpdate={onUpdate} />
    </LeftSection>
  )

  const rightSection = (
    <RightSection>
      <ProjectArea>
        <Row type="flex">
          <Col span={9}>
            <ModuleBox
              span={24}
              ModuleList={newUser.ModuleList}
              onSave={(module: ModuleItem[]) => {
                newUser.ModuleList = module
                onUpdate(newUser)
              }}
              activeModule={activeModule}
              onSelectModule={onActiveModule}
            />
          </Col>
          <Col span={14} offset={1}>
            <ProjectBox moduleItem={activeModule} onUpdate={onUpdateProject} />
          </Col>
        </Row>
      </ProjectArea>
      <ButtonSubmit>
        <PrimaryButton
          text="บันทึก"
          disabled={isInvalidData()}
          onClick={onCreateUser}
        />
      </ButtonSubmit>
    </RightSection>
  )

  return (
    <>
      <SubnavBar breadcrumbs={breadcrumbs} hideSearch={true} />
      <EventModal
        name="user"
        event={[event]}
        enable={enableModal}
        successCalback={onSuccess}
      />
      <Content className="mid-content">
        <Menu selected="user">
          <MainPage>
            {leftSection}
            {rightSection}
          </MainPage>
        </Menu>
      </Content>
    </>
  )
}

const MainPage = styled.div`
  background-color: #f5f6fa;
  width: 100%;
  height: 100%;
  -khtml-user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  overflow-x: scroll;
`

const LeftSection = styled.div`
  width: 400px;
  height: calc(100vh - 112px);
  background-color: rgba(139, 153, 167, 0.1);
  padding: 40px 24px;
  float: left;
`

const RightSection = styled.div`
  width: calc(100% - 400px);
  height: calc(100vh - 112px);
  overflow: scroll;
  padding: 40px 24px;
  float: left;
  .title {
    padding-top: 118px;
  }
`

const ProjectArea = styled.div`
  margin-top: 45px;
  border-radius: 5px;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.03);
  background-color: #fff;
  padding: 24px;
`

const ButtonSubmit = styled.div`
  width: 116px;
  float: right;
  margin-top: 8px;
`

const mapStateToProps = (state: RootState) => {
  return {
    token: serviceTokenSelector(state),
    UserEmail: userEmailSelector(state),
    roleList: selectRoleItem(state),
    event: selectEvent(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserDetail)
