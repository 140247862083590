import RootState from 'typings/RootState'
import { Event } from '../../types/event'
import { DataList, DataDetail, DocumentDetail, UnitByQCTypeList } from './types'

export const selectEvent = (state: RootState): Event =>
  state.adminReportUnit.Event

export const selectReportList = (state: RootState): DataList =>
  state.adminReportUnit.DataList

export const selectReportDetail = (state: RootState): DataDetail =>
  state.adminReportUnit.DataDetail

export const selectDocumentDetail = (state: RootState): DocumentDetail =>
  state.adminReportUnit.DocumentDetail

export const selectUnitByQCTypeList = (state: RootState): UnitByQCTypeList =>
  state.adminReportUnit.UnitByQCTypeList

export const selectFilter = (state: RootState) => state.adminReportUnit.Filter

export const selectQCTypeFilter = (state: RootState) =>
  state.adminReportUnit.QCTypeFilter

export const selectSorting = (state: RootState) => state.adminReportUnit.sorting

export const selectUnitCheckLits = (state: RootState) =>
  state.adminReportUnit.UnitCheckList
