import { getType } from 'typesafe-actions'
import * as actions from './actions'
import { StoreState, HistoryAction } from './types'
import produce from 'immer'
import parseErrorMessage from 'utils/parseErrorMessage'

const initialState: StoreState = {
  data: [],
  total: 100,
  event: {
    isFetching: false,
    error: '',
  },
}

export default function(
  state: StoreState = initialState,
  action: HistoryAction
): StoreState {
  return produce(state, draft => {
    switch (action.type) {
      case getType(actions.getHistoryActivityAction.request):
        draft.event = {
          isFetching: true,
          error: '',
        }

        draft.data = []
        draft.total = 0
        break
      case getType(actions.getHistoryActivityAction.success):
        draft.event.isFetching = false
        const response = action.payload.ResponseData
        draft.data = response
        break
      case getType(actions.getHistoryActivityAction.failure):
        draft.event = {
          isFetching: false,
          error: parseErrorMessage(action.payload),
        }

        break
      default:
        return state
    }
  })
}
