import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { connect, useDispatch } from 'react-redux'
import { Layout } from 'antd'
import { RouteComponentProps } from 'react-router-dom'
import RootState from 'typings/RootState'
import { selectedProjectCodeSelector } from 'features/project/project.selector'
import {
  userEmailSelector,
  serviceTokenSelector,
} from 'features/sensitive/sensitive.selector'
import { selectData, selectEvent } from 'features/cmpj/cmchecking/selector'
import Breadcrumbs from './Breadcrumbs'
import { ActivityList, UnitInfo } from 'Components/CM/CmChecking'
import {
  QCByUserFooter,
  QCUrgentFooter,
  UrgentModal,
} from 'Components/Activity'
import { LoadingView, AlertError, AlertSuccess } from 'Components/Shared'
import { StatusOfTask, StateOfTask } from 'features/types/status'
import {
  FetchActivityOnUnitReq,
  StartCheckingRequest,
  SendRequestToQcRequest,
  SendUrgentForCMRequest,
  SendUrgentForQCRequest,
} from 'features/cmpj/cmchecking/types'
import {
  getActivityOnUnitById,
  StartCheckingByCMApi,
  SendRequestToQcForChecking,
  SendUrgentRequestForCMChecking,
  SendUrgentRequestForQCChecking,
} from 'features/cmpj/cmchecking/api'

type Props = ReduxProps &
  DispatchProps &
  RouteComponentProps<{ unit: string; qctype: string; requestID: string }>

const QcDetail: React.ComponentType<Props> = props => {
  const [enableModal, setEnableModal] = useState(false)
  const [showCmUrgentModal, setCmUrgentModal] = useState(false)
  const [showQcUrgentModal, setQcUrgentModal] = useState(false)
  const { match, data, event, token, ProjectCode, UserEmail, history } = props
  const { ActivityList: activityList } = data
  const { params } = match
  const { unit, qctype, requestID } = params
  const { Content, Footer } = Layout

  const gotoDashboard = () => {
    history.push('../../../qcdashboard')
  }

  useEffect(() => {
    setEnableModal(false)
  }, [data])

  const dispatch = useDispatch()
  useEffect(() => {
    const params: FetchActivityOnUnitReq = {
      UserEmail,
      ProjectCode,
      QCType: qctype,
      Unit: unit,
      RequestID: parseInt(requestID),
    }
    dispatch(getActivityOnUnitById(params, token))
  }, [dispatch, UserEmail, ProjectCode, token, unit, qctype])

  const onStartCheckingByCM = () => {
    setEnableModal(true)
    const request: StartCheckingRequest = {
      ProjectCode,
      UserEmail,
      ItemList: [
        {
          Unit: data.Unit,
          QCTypeID: data.QCTypeID,
          RequestID: data.RequestID,
          ModelID: data.ModelID,
        },
      ],
    }
    dispatch(StartCheckingByCMApi(token, request))
  }

  const onSendRequestToQc = () => {
    setEnableModal(true)
    const isCMChecked =
      data.State === 'CM-QC' && data.Status === 'INPRO' ? 'Y' : 'N'
    const request: SendRequestToQcRequest = {
      ProjectCode,
      UserEmail,
      ItemList: [
        {
          Unit: data.Unit,
          QCTypeID: data.QCTypeID,
          RequestID: data.RequestID,
          IsCMChecked: isCMChecked,
        },
      ],
    }
    dispatch(SendRequestToQcForChecking(token, request))
  }

  const onSendUrgentForCM = (MemoNumber: string, Reason: string) => {
    setEnableModal(true)
    const request: SendUrgentForCMRequest = {
      ProjectCode,
      UserEmail,
      ModelID: data.ModelID,
      QCTypeID: data.QCTypeID,
      TaskID: data.Unit,
      TaskType: data.Zone === 'Common Area' ? 'zone' : 'unit',
      MemoNumber,
      Reason,
    }
    dispatch(SendUrgentRequestForCMChecking(token, request))
  }

  const onSendUrgentForQC = (MemoNumber: string, Reason: string) => {
    setEnableModal(true)
    const request: SendUrgentForQCRequest = {
      ProjectCode,
      UserEmail,
      QCTypeID: data.QCTypeID,
      TaskID: data.Unit || data.Zone,
      TaskType: data.Unit !== '' ? 'unit' : 'zone',
      MemoNumber,
      Reason,
    }
    dispatch(SendUrgentRequestForQCChecking(token, request))
  }

  const countSuccessActivity = () => {
    let successActivity = []
    successActivity =
      activityList &&
      activityList.filter(
        activity =>
          activity.Progress === 100 &&
          activity.State === StateOfTask.PJPO &&
          activity.TaskStatus === StatusOfTask.APPV
      )
    return successActivity ? successActivity.length : 0
  }

  const CmUrgentModal = (
    <UrgentModal
      title="คำขอแบ่งตรวจด้วยตัวเอง"
      onClose={() => setCmUrgentModal(false)}
      onSubmit={(memo: string, reason: string) => {
        onSendUrgentForCM(memo, reason)
        setCmUrgentModal(false)
      }}
      visible={showCmUrgentModal}
      qcType={data.QCTypeID}
    />
  )

  const QcUrgentModal = (
    <UrgentModal
      title="คำขอแบ่งตรวจให้ QC"
      onClose={() => setQcUrgentModal(false)}
      onSubmit={(memo: string, reason: string) => {
        onSendUrgentForQC(memo, reason)
        setQcUrgentModal(false)
      }}
      visible={showQcUrgentModal}
      qcType={data.QCTypeID}
    />
  )

  const successActivity = countSuccessActivity()
  const totalActivity = activityList ? activityList.length : 0
  let isSeftCheck = successActivity === totalActivity
  const isViewMode = data.State !== 'CM-QC' || data.Status !== 'INIT'
  const isCanSendUrgentRequest = data.CanSendUrgentRequest === 'Y'

  const getFooter = () => {
    return isSeftCheck ? (
      <Footer className="detail-page-footer">
        <QCByUserFooter
          onClickSendRequest={() => onSendRequestToQc()}
          onClickStartChecking={() => onStartCheckingByCM()}
          isLoading={event.IsLoading}
        />
      </Footer>
    ) : (
      <Footer className="detail-page-footer">
        {isCanSendUrgentRequest ? (
          <QCUrgentFooter
            onSendRequestForCM={() => setCmUrgentModal(true)}
            onSendRequestToQC={() => setQcUrgentModal(true)}
            isLoading={event.IsLoading}
          />
        ) : (
          <QCByUserFooter
            onClickSendRequest={() => onSendRequestToQc()}
            onClickStartChecking={() => onStartCheckingByCM()}
            isLoading={true}
          />
        )}
      </Footer>
    )
  }

  const title =
    isSeftCheck && !isViewMode ? 'พร้อมตรวจด้วยตัวเอง' : 'QC กำลังดำเนินการ'

  return (
    <>
      <AlertError
        text={event.SubmitError}
        isShow={event.SubmitError && enableModal ? true : false}
        callback={() => {}}
      />
      <AlertSuccess
        text={event.MessageSuccess}
        isShow={event.SubmitSuccess && enableModal ? true : false}
        callback={gotoDashboard}
      />
      <LoadingView isShow={event.IsLoading} />
      <Breadcrumbs name={title} />
      <Content className="mid-content">
        <div className="main-content activity-detail">
          <TextHeader>{title}</TextHeader>
          <UnitInfo data={data} />
          <ActivityList
            activityList={activityList || []}
            successActivity={successActivity}
          />
        </div>
      </Content>
      {!isViewMode && getFooter()}
      {CmUrgentModal}
      {QcUrgentModal}
    </>
  )
}

const TextHeader = styled.div`
  font-size: 28px;
  font-family: 'DB Helvethaica X 75 Bd';
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 20px;
`

const mapStateToProps = (state: RootState) => {
  return {
    data: selectData(state),
    event: selectEvent(state),
    token: serviceTokenSelector(state),
    ProjectCode: selectedProjectCodeSelector(state),
    UserEmail: userEmailSelector(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QcDetail)
