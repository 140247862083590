import React, { useState } from 'react'
import { Col } from 'antd'
import styled from 'styled-components'
import { TableRow } from '../../../Shared/Table'
import { EditIcon, TrashIcon } from '../../../Shared/Icon'
import { Link } from 'react-router-dom'
import { DataItem, ModuleItem } from 'features/admin/user/types'
import { getModuleNames } from 'utils/module-map'

type DefaultProps = {
  item: DataItem
  hideColumn: any[]
  getSpan(key: string): number
  selected?: boolean
  onClick: (evt: any, item: any) => void
}

type Props = DefaultProps

const RowItem: React.FC<Props> = props => {
  const { item, hideColumn, getSpan, selected } = props

  const renderData = (key: string, value: any) => {
    if (!hideColumn.includes(key)) {
      if (key == 'name') {
        return (
          <Col span={getSpan(key)}>
            <Name>{value}</Name>
          </Col>
        )
      } else {
        return <Col span={getSpan(key)}>{value}</Col>
      }
    }
    return
  }

  const onClick = (evt: any) => {
    if (props.onClick) {
      props.onClick(evt, item)
    }
  }

  const getStatus = (status: string) => {
    return status === 'Active' ? (
      <span>{status}</span>
    ) : (
      <RedTxt>{status}</RedTxt>
    )
  }

  return (
    <TableRow
      onClick={onClick}
      minHeight={60}
      spaceBetweenRow={8}
      paddingLeft={27}
      selected={selected}
    >
      <Space />
      {renderData('name', item.UserNameTH)}
      {renderData('email', item.UserEmail)}
      {renderData('module', getModuleNames(item.ModuleList))}
      {renderData('isVendor', item.IsVendor ? 'Vendor' : 'Sansiri')}
      {renderData('status', getStatus(item.Status))}
    </TableRow>
  )
}

const Name = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  // margin: 0px 27px;
  color: #000;
`

const Space = styled.div`
  //margin-left: 27px;
`

const RedTxt = styled.span`
  color: #d0494c;
`

export default RowItem
