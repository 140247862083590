import React, { useState } from 'react'
import { Layout, Row, Col, DatePicker } from 'antd'
import styled from 'styled-components'
import { PrimaryButton, SecondaryButton } from 'Components/Shared/Button'
import { DATETIME_PICKER_DATE_FORMAT } from 'config'
import moment from 'moment'

type Props = {
  taskSelect: any[]
  onClick: (date: string) => void
  isLoading?: boolean
  desc?: string
}
const { Content, Footer } = Layout

const TaskFooter: React.FunctionComponent<Props> = props => {
  const {
    taskSelect,
    onClick,
    isLoading = false,
    desc = 'รายการ Request',
  } = props

  const [selected, setSelected] = useState(null as any)

  const onAcceptRequest = () => {
    onClick(selected.format('YYYYMMDDHHmm'))
  }

  const disabledDate = (current: any) => {
    return current && current < moment().subtract(1, 'day')
  }

  const acceptButton = (
    <Col span={16}>
      <Row type="flex" justify="space-between" align="middle">
        <Col span={18}>
          <div className="input-with-icon">
            <div className="label">วันเวลานัดหมาย</div>
            <DatePicker
              className="date-picker"
              format={DATETIME_PICKER_DATE_FORMAT}
              placeholder={DATETIME_PICKER_DATE_FORMAT}
              onChange={date => setSelected(date)}
              showTime={{
                format: 'HH:mm',
              }}
              disabledDate={disabledDate}
              value={selected}
              allowClear={true}
              showToday={false}
            />
          </div>
        </Col>
        <Col span={5} offset={1}>
          <PrimaryButton
            text="รับ Request"
            disabled={isLoading || selected === null || taskSelect.length <= 0}
            onClick={onAcceptRequest}
          />
        </Col>
      </Row>
    </Col>
  )

  const renderTextSelected = (selected: number) => {
    if (selected > 0) {
      return <TextGreen>{selected}</TextGreen>
    } else {
      return <TextGray>{selected}</TextGray>
    }
  }

  return (
    <FooterContent>
      <Row type="flex" justify="space-between" align="middle">
        <Col>
          คุณเลือก {renderTextSelected(taskSelect.length)} {desc}
        </Col>
        {acceptButton}
      </Row>
    </FooterContent>
  )
}

const FooterContent = styled.div`
  color: #000;
  font-size: 20px;
  padding: 0 30px;
`

const TextGreen = styled.span`
  color: #5bc79e;
  padding: 0 8px;
`

const TextGray = styled.span`
  color: #8b99a7;
  padding: 0 8px;
`

export default TaskFooter
