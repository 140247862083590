import React, { useRef, useEffect } from 'react'
import { Input } from 'antd'
import styled from 'styled-components'

interface Props {
  prefix: string
  placeholder?: string
  value: string
  onChange: (value: string) => void
  onKeyDown?: (key: any) => void
  disabled?: boolean
  openWithFocus?: boolean
  type?: string
}

const InputText: React.FC<Props> = ({
  prefix,
  placeholder = '',
  value,
  onChange,
  onKeyDown,
  disabled = false,
  openWithFocus,
  type = 'text',
}) => {
  const inputRef = useRef<Input>(null)

  useEffect(() => {
    if (openWithFocus && inputRef.current) {
      inputRef.current.focus()
    }
  }, [openWithFocus])

  const onFocus = (e: any) => {
    const tempValue = e.target.value
    e.target.value = ''
    e.target.value = tempValue
  }

  const valChange = (evt: any) => {
    onChange(evt.target.value)
  }

  return (
    <InputTitle>
      <Input
        ref={inputRef}
        prefix={prefix}
        className="input-title"
        placeholder={placeholder}
        value={value}
        onChange={valChange}
        onKeyDown={onKeyDown}
        disabled={disabled}
        onFocus={onFocus}
        type={type}
      />
    </InputTitle>
  )
}

const InputTitle = styled.div`
  .input-title {
    text-align: right;
    height: 48px;
    border-radius: 5px;
    border: 1px solid #e4e7eb;
    background-color: #ffffff;
    font-family: 'DB Helvethaica X 55 Regular';
    font-size: 18px;
    .ant-input-prefix {
      font-size: 20px;
      padding-left: 6px;
      color: #000;
    }

    .ant-input {
      font-size: 20px;
      font-family: 'DB Helvethaica X 55 Regular';
      padding-right: 16px;
      border: none;
      color: #8b99a7;
    }

    .ant-input:placeholder-shown {
      font-family: 'DB Helvethaica X 55 Regular';
    }
  }

  .ant-input-affix-wrapper .ant-input:not(:first-child) {
    padding-left: 40px;
  }
  .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
    border-color: #e4e7eb;
  }
`
export default InputText
