import withAPIAction from 'utils/withAPIAction'
import { getDocumentAction, saveDocumentAction } from './actions'
import { GetDocumentReq, SaveDocumentReq } from './types'

export const getDocumentCheckList = (token: string, data: GetDocumentReq) => {
  if (token === '') {
    return { type: 'tasklane/no_token' }
  }

  return withAPIAction<GetDocumentReq>({
    method: 'POST',
    path: 'GetDocumentCheckListForQC	',
    data,
    token: `Bearer ${token}`,
  })(getDocumentAction)
}

export const saveDocumentCheckList = (token: string, data: SaveDocumentReq) => {
  if (token === '') {
    return { type: 'tasklane/no_token' }
  }

  return withAPIAction<SaveDocumentReq>({
    method: 'POST',
    path: 'SaveDocumentCheckListByQC	',
    data,
    token: `Bearer ${token}`,
  })(saveDocumentAction)
}
