import React from 'react'
import { Col } from 'antd'
import styled from 'styled-components'
import { TableRow } from 'Components/Shared/Table'
import { DataItem } from 'features/adminReport/unit/types'
import { toMomentDateTime, toDateText } from 'utils/datetime'
import { mapUnitStatus, unitStatusIsPass } from 'utils/status-map'
import {
  StatusTag,
  ReportLink as Link,
} from 'Components/AdminQC/Report/Components'
import { numberFormat } from 'utils/format'

type DefaultProps = {
  item: DataItem
}

type Props = DefaultProps

const RowItem: React.FC<Props> = ({ item }) => {
  const {
    ProjectCode,
    Unit,
    QCTypeID,
    CheckNumber,
    RequestID,
    Floor,
    CheckedByRole,
  } = item
  const unitPath = `/report/unit/${ProjectCode}/${Unit}/${QCTypeID}/${CheckNumber}/${RequestID}/${CheckedByRole}`
  const documentPath = `/report/unitdocument/${ProjectCode}/${Unit}/${QCTypeID}/${CheckNumber}/${RequestID}`
  const unitStaus = mapUnitStatus(item.CheckStatus).status
  const isPass = unitStatusIsPass(true, item.CheckStatus)
  const inSpectScore = (
    <Link to={unitPath}>
      {isPass ? (
        <PassScore>{numberFormat(item.InspectionScore)}</PassScore>
      ) : (
        <FailScore>{numberFormat(item.InspectionScore)}</FailScore>
      )}
    </Link>
  )

  const documentScore = (
    <Link to={documentPath}>
      {item.DocumentStatus === 'Pass' ? (
        <PassScore>{numberFormat(item.DocumentScore)}</PassScore>
      ) : (
        <FailScore>{numberFormat(item.DocumentScore)}</FailScore>
      )}
    </Link>
  )

  const renderData = (val: any, span: number = 2) => {
    return <Col span={span}>{val || '-'}</Col>
  }

  return (
    <RowModel>
      <TableRow minHeight={60} spaceBetweenRow={8} paddingLeft={24}>
        {renderData(
          <Bold>
            {item.ProjectCode}-{item.ProjectNameTH}
          </Bold>,
          4
        )}
        {renderData(<TxtReg>{item.Unit.split('-')[1]}</TxtReg>)}
        {renderData(<TxtReg>{item.QCTypeVersion}</TxtReg>, 1)}
        {renderData(<TxtReg>{item.CheckNumber}</TxtReg>, 1)}
        {renderData(
          <TxtReg>{toDateText(toMomentDateTime(item.InspectDate))}</TxtReg>,
          2
        )}
        {renderData(inSpectScore, 2)}
        {renderData(documentScore)}
        {renderData(<StatusTag text={unitStaus} isPass={isPass} />, 3)}
        {renderData(<TxtReg>{item.CheckedByRole}</TxtReg>, 2)}
        {renderData(<TxtReg>{item.CheckedBy}</TxtReg>, 2)}
      </TableRow>
    </RowModel>
  )
}

const RowModel = styled.div`
  .ant-row {
    cursor: auto;
  }
`

const Score: any = styled.div`
  font-size: 20px;
  font-family: 'DB Helvethaica X 75 Bd';
  text-decoration: underline;
  cursor: pointer;
`

const PassScore = styled(Score)`
  color: #63cea6;
`

const FailScore = styled(Score)`
  color: #d0494c;
`

const Bold: any = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  color: #000;
  font-size: 20px;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  padding-right: 5px;
`

const TxtReg = styled(Bold)`
  font-family: 'DB Helvethaica X 55 Regular';
`

export default RowItem
