import React, { useState } from 'react'
import { Col, Row } from 'antd'
import styled from 'styled-components'
import RadioBox from '../Form/RadioBox'
import { isShorthandPropertyAssignment } from 'typescript'
import Checkbox from '../Form/Checkbox'

interface Props {
  children?: any
  span?: number
  type?: string
  sort?: boolean
  offset?: number
  isPadding?: boolean
  onSort?: () => void
  isShowSelect?: boolean
  isSelectAll?: boolean
  onSelectAll?: () => void
}

const TableColHeader = (props: Props) => {
  const {
    type,
    sort = true,
    isPadding = false,
    isShowSelect = false,
    isSelectAll = false,
  } = props
  const getIcon = (): string => {
    switch (type) {
      case 'desc':
        return '/img/ic-down.png'
      case 'asc':
        return '/img/ic-arrow-actions.png'
      default:
        return '/img/ic-sort.svg'
    }
  }

  return (
    <Col {...props}>
      <Content style={isPadding ? { paddingLeft: '30px' } : undefined}>
        <span onClick={sort ? props.onSort : () => {}}>{props.children}</span>
        {props.onSort && sort && (
          <img
            src={getIcon()}
            width={16}
            height={16}
            alt="arrow icon"
            onClick={props.onSort}
          />
        )}
        {isShowSelect && (
          <CheckboxArea>
            <Col>
              <CheckBoxLabel>ทั้งหมด</CheckBoxLabel>
              <Checkbox
                label={''}
                value={isSelectAll}
                onChange={props.onSelectAll}
              />
            </Col>
          </CheckboxArea>
        )}
      </Content>
    </Col>
  )
}

const Content = styled.div`
  font-size: 18px;
  color: #8b99a7;
  img {
    margin-left: 4px;
  }
  cursor: pointer;
`

const CheckboxArea = styled.div`
  text-align: center;
`
const CheckBoxLabel = styled.div`
  font-size: 20px;
  font-family: 'DB Helvethaica X 55 Regular';
  padding: 0;
  text-align: left;
`

export default TableColHeader
