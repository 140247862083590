import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import {
  ZoneList,
  ZoneInProgressActivity,
  ProjectList,
} from 'features/qc/dashboard/types'
import { Row, Col } from 'antd'
import { FloorPlanIcon } from 'Components/Shared/Icon'
import ProjectBox from '../ProjectBox'
import LeftBoxContent from '../LeftBoxContent'
import CheckIconBox from '../CheckIconBox'
import SignDescription from '../SignDescription'
import ScoreStatus from '../ScoreStatus'

interface Props {
  type?: string
  task: ZoneInProgressActivity
  isSendReport?: boolean
  onClickReturn: (zone: ZoneList) => void
  onSelectLists: (projectCode: string, zone: ZoneList) => void
  projectList: ProjectList[]
}

const InprogressCard: React.FC<Props> = props => {
  const {
    task,
    isSendReport = false,
    onClickReturn,
    onSelectLists,
    projectList,
  } = props
  const { ProjectCode, ProjectNameTH, ZoneList } = task

  const isSelected = (zone: ZoneList) => {
    let isSelect = false
    projectList.forEach(project => {
      if (project && project.ProjectCode === ProjectCode) {
        project.TaskList.forEach(task => {
          if (task.Unit === zone.Unit && task.RequestID === zone.RequestID) {
            isSelect = true
          }
        })
      }
    })
    return isSelect
  }

  const renderScoreLine = (
    title: string,
    signDes: string,
    num: number,
    isPass: boolean,
    status: string
  ) => {
    return (
      <Row type="flex" justify="space-between">
        <Col span={8}>{title}</Col>
        <Col span={8}>
          <ScoreStatus num={num} isPass={isPass} />
        </Col>
        <Col span={8}>
          <SignDescription isCorrect={isCorrect(status)} label={signDes} />
        </Col>
      </Row>
    )
  }

  const isCorrect = (status: string) => status === 'Y'

  const renderInspectionCheck = (zone: ZoneList) => {
    const showRadio =
      isCorrect(zone.HasSelectedDefect) && isCorrect(zone.HasSign)
    return (
      <Row type="flex" className="score" align="middle">
        <Col>
          {isSendReport &&
            (showRadio ? (
              <CheckIconBox
                onClick={() => onSelectLists(task.ProjectCode, zone)}
                isSelected={isSelected(zone)}
              />
            ) : (
              <Space />
            ))}
          <FloorPlanIcon size={40} />
        </Col>
        <Col span={20}>
          <BoxScore>
            {renderScoreLine(
              `ตรวจครั้งที่ ${zone.CheckNumber}`,
              'หมุดส่งรายงาน',
              zone.NumOfPass,
              true,
              zone.HasSelectedDefect
            )}
            {renderScoreLine(
              `ชั้น ${zone.Floor}`,
              'ลงชื่อรับทราบ',
              zone.NumOfFail,
              false,
              zone.HasSign
            )}
          </BoxScore>
        </Col>
      </Row>
    )
  }

  const renderZoneList = (ProjectCode: string) => {
    return ZoneList.map((zone, index) => {
      const className = index % 2 === 1 ? 'higlight' : ''
      const zoneDesc = <RightBox>{renderInspectionCheck(zone)}</RightBox>
      const link = `/zone/report/${ProjectCode}/${zone.Unit}/${zone.QCTypeID}/${zone.RequestID}/${zone.Floor}`
      return (
        <ContentBox key={index} className={className}>
          <Row type="flex">
            <Col span={6}>
              <LeftBoxContent
                onClickReturn={() => onClickReturn(zone)}
                returnDesc="หากคืนผัง หมุดที่เคยทำไว้จะถูก Reset ทั้งหมด คุณยืนยันที่จะคืนผังใช่หรือไม่"
                titleText={
                  <Link to={link}>
                    <TxtBold>{zone.Unit}</TxtBold>
                  </Link>
                }
              />
            </Col>
            <Col span={18}>
              {isSendReport ? zoneDesc : <Link to={link}>{zoneDesc}</Link>}
            </Col>
          </Row>
        </ContentBox>
      )
    })
  }

  const title = `${ProjectCode} - ${ProjectNameTH}`
  const zoneList = renderZoneList(ProjectCode)

  return <ProjectBox projectTitle={title} children={zoneList} />
}

const ContentBox = styled.div`
  height: 100px;
  border-bottom: solid 1px #e4e7eb;
  &.higlight {
    background-color: rgba(0, 49, 104, 0.03);
  }
`

const RightBox = styled.div`
  float: right;
  width: 100%;
  .score {
    padding: 12px 32px;
    height: 100px;
    border-bottom: solid 1px #e4e7eb;
  }
  .score:last-child {
    border: none;
  }
`

const BoxScore = styled.div`
  margin-left: 24px;
  font-family: 'DB Helvethaica X 55 Regular';
  font-size: 18px;
  padding-left: 8px;
  color: #000;
`

const Space = styled.div`
  width: 40px;
  height: 1px;
  float: left;
`

const TxtBold = styled.p`
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 20px;
`

export default InprogressCard
