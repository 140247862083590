import React from 'react'
import { Col } from 'antd'
import styled from 'styled-components'
import { TableRow } from 'Components/Shared/Table'
import { ReportLink as Link } from 'Components/AdminQC/Report/Components'
import { DataItem } from 'features/adminReport/auditPlan/types'
import { toDateText, toMomentDateTime } from 'utils/datetime'

type DefaultProps = {
  item: DataItem
}

type Props = DefaultProps

const RowItem: React.FC<Props> = ({ item }) => {
  const { ProjectCode, CheckNumber } = item
  const path = `/report/auditplan/${ProjectCode}/${CheckNumber}`

  const renderData = (val: any, span: number = 6) => {
    return <Col span={span}>{val}</Col>
  }

  return (
    <RowModel>
      <Link to={path}>
        <TableRow minHeight={60} spaceBetweenRow={8} paddingLeft={24}>
          {renderData(
            <Bold>
              {item.ProjectCode}-{item.ProjectNameTH}
            </Bold>
          )}
          {renderData(<TxtReg>{item.CheckNumber}</TxtReg>)}
          {renderData(
            <TxtReg>{toDateText(toMomentDateTime(item.InspectDate))}</TxtReg>
          )}
          {renderData(<TxtReg>{item.CheckedBy}</TxtReg>)}
        </TableRow>
      </Link>
    </RowModel>
  )
}

const RowModel = styled.div`
  cursor: pointer;
`

const Bold: any = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  color: #000;
  font-size: 20px;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  padding-right: 5px;
`

const TxtReg = styled(Bold)`
  font-family: 'DB Helvethaica X 55 Regular';
`

export default RowItem
