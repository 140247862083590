import React, { ReactNode } from 'react'
import { Row, Col } from 'antd'

export interface CardListHeaderItem {
  title: string
  span: number
  offset?: number
  img: string
  onClick: React.MouseEventHandler<HTMLDivElement>
}

type Props = {
  items: CardListHeaderItem[]
}

const CardListHeader: React.FunctionComponent<Props> = ({ items }) => {
  return (
    <Row gutter={8} className="search-result--header">
      {items.map(item => (
        <Col
          key={item.title}
          span={item.span}
          className="action-header"
          onClick={item.onClick}
          offset={item.offset}
        >
          {item.title}
          <img src={item.img} width={16} height={16} alt="arrow icon" />
        </Col>
      ))}
    </Row>
  )
}

export default CardListHeader
