import React, { useState, useEffect } from 'react'
import { useDispatch, connect } from 'react-redux'
import RootState from 'typings/RootState'
import styled from 'styled-components'
import { RouteComponentProps } from 'react-router-dom'
import { Row, Col } from 'antd'

import SubnavBar from 'Components/Shared/SubnavBar'
import { HeaderRow } from 'Components/Shared/Table'
import Pagination from 'Components/Shared/Pagination'
import { LoadingView, AlertError } from 'Components/Shared'
import { BreadcrumbLink } from 'Components/Shared/BreadCrumb/BreadCrumb'

import { PrimaryButton } from 'Components/Shared/Button'
import ImportBtn from 'Components/Shared/Table/ImportBtn'
import { PaginationType } from 'features/types/common'
import { getCommissionList } from 'features/admin/commission/api'
import { updateCheckListDetail } from 'features/admin/commission/actions'
import { Commisson } from 'features/admin/commission/types'
import { toCheckList } from 'importer/commissioning'

import Menu from '../../../MenuAdmin'
import RowItem from './RowItem'
import { SearchInput } from 'Components/Filter'

const breadcrumbs: BreadcrumbLink[] = [
  {
    url: '/dashboard',
    title: 'Home',
    icon: '/home.png',
    active: true,
    showTitle: false,
  },
  {
    url: '/check-commission',
    title: 'ตรวจ commissioning',
    active: false,
    showTitle: true,
  },
]

type Props = ReduxProps & DispatchProps & RouteComponentProps

const CheckCommissionList: React.ComponentType<Props> = ({
  CommissionList,
  event,
  history,
}) => {
  const dispatch = useDispatch()
  const [err, setErr] = useState(undefined)
  const [searchText, setSearchText] = useState('')
  const [pagination, setPagination] = useState<PaginationType>({
    Page: 1,
    RowPerPage: 10,
  })

  useEffect(() => {
    dispatch(
      getCommissionList(
        {
          Page: pagination.Page,
          RowPerPage: pagination.RowPerPage,
        },
        searchText
      )
    )
  }, [pagination])

  const onImportFile = (data: any) => {
    try {
      const checklist = toCheckList(data)
      dispatch(updateCheckListDetail(checklist))
      const type = checklist.Type === 2 ? 2 : 1
      history.push(`/check-commission/create/${type}`)
    } catch (e) {
      console.log('import err:', e.message)
      setErr(e.message)
    }
  }

  const content = CommissionList.List.map((item: Commisson, index: number) => {
    return <RowItem key={index} item={item} />
  })

  const header = (
    <Row type="flex" justify="space-between" align="middle">
      <Col>
        <Header>ตรวจงาน Commissioning & Safety</Header>
      </Col>
      <Col>
        <MainSection>
          <Row>
            <Col style={{ float: 'right' }}>
              <ImportSection>
                <ImportBtn onChange={onImportFile} text="นำเข้า Checklist" />
              </ImportSection>
              <ButtonSection>
                <PrimaryButton
                  text="สร้าง Checklist Commissioning"
                  onClick={() => {
                    history.push(`/check-commission/create/1`)
                  }}
                />
              </ButtonSection>
              <ButtonSection>
                <PrimaryButton
                  text="สร้าง Checklist Safety"
                  onClick={() => {
                    history.push(`/check-commission/create/2`)
                  }}
                />
              </ButtonSection>
            </Col>
          </Row>
        </MainSection>
      </Col>
    </Row>
  )

  const onSearch = (val: string) => {
    if (searchText === '' && val === '') return
    setSearchText(val)
    pagination.Page = 1
    setPagination({ ...pagination })
  }

  return (
    <>
      <LoadingView isShow={event.IsLoading} />
      <AlertError
        isShow={err !== undefined}
        text={err}
        callback={() => setErr(undefined)}
      />
      <SubnavBar
        breadcrumbs={breadcrumbs}
        hideSearch={true}
        adminSearchBox={<SearchInput onSearch={onSearch} />}
      />
      <Menu selected={'check-commission'}>
        <MainPage>
          {header}

          <HeaderRow onSort={() => null} sorting={[]} items={columnsName} />
          {content}
          <Pagination
            currentPage={pagination.Page}
            totalRows={CommissionList.TotalRows}
            perPage={pagination.RowPerPage}
            onChangeNumber={(page: number) => {
              pagination.RowPerPage = page
              setPagination({ ...pagination })
            }}
            onClickPage={(page: number) => {
              pagination.Page = page
              setPagination({ ...pagination })
            }}
          />
        </MainPage>
      </Menu>
    </>
  )
}

const MainPage = styled.div`
  padding: 40px 45px;
  width: 100%;
`

const MainSection = styled.div`
  height: 50px;
  margin-bottom: 35px;
`

const ImportSection = styled.div`
  float: left;
  margin-left: 24px;
`

const ButtonSection = styled.div`
  float: left;
  margin-left: 24px;
  width: 220px;
`

const Header = styled.div`
  margin-bottom: 27px;
  font-size: 28px;
  line-height: 1.07;
  font-family: 'DB Helvethaica X 75 Bd';
  color: #000;
`

const columnsName = [
  {
    title: 'Checklist name',
    span: 11,
    key: 'ChecklistName',
    showSort: false,
  },
  {
    title: 'ประเภท',
    span: 4,
    key: 'type',
    showSort: false,
  },
  {
    title: 'แก้ไขล่าสุด',
    span: 7,
    key: 'edit',
    showSort: false,
  },
  {
    title: 'Action',
    span: 2,
    key: 'Action',
    showSort: false,
  },
]

const mapStateToProps = (state: RootState) => {
  return {
    CommissionList: state.adminCommission.CommissionList,
    event: state.adminCommission.Event,
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>
const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(CheckCommissionList)
