import React from 'react'
import { Row, Col } from 'antd'
import styled from 'styled-components'

interface Props {
  children?: any
  status?: string
  color?: string
  minHeight?: number
  marginLeft?: number
}

const StatusCol = (props: Props) => {
  const { status, color, minHeight = 65, marginLeft = 0 } = props
  let backgroundColor = ''
  if (color) {
    backgroundColor = color
  } else {
    switch (status) {
      case 'cancelled':
        // backgroundColor = '#d0494c'
        break
      default:
        backgroundColor = 'white'
    }
  }

  return (
    <Line>
      <Row>
        <Col
          span={1}
          style={{
            ...css.status,
            backgroundColor,
            minHeight: minHeight,
            marginLeft: marginLeft + 'px',
          }}
        />
        <Col span={23} style={{ ...css.info, minHeight: minHeight }}>
          {props.children}
        </Col>
      </Row>
    </Line>
  )
}

const Line = styled.div`
  span {
    width: 3px;
  }
`

const css = {
  status: {
    backgroundColor: 'green',
    borderTopLeftRadius: '5px',
    borderBottomLeftRadius: '5px',
    width: '3px',
  },
  info: {
    alignItems: 'center',
    display: 'flex',
  },
}

export default StatusCol
