import { ActionType } from 'typesafe-actions'
import * as actions from './actions'
import { Event } from 'features/types/event'

export type SeemoreAction = ActionType<typeof actions>

export interface StoreState {
  data: Activity
  event: Event
  sorting: any
  pageType: number
  dataResponse: ActivitySeemore | null
}

export interface Activity {
  TotalRows: number
  DataList: Data[]
}

export interface GetActivitySeemoreReq {
  ProjectCode: string
  UserEmail: string
  PageType: number
  Sort: any
  Page: number
  RowPerPage: number
}

export interface Data {
  RequestID: number
  Unit: string
  Zone: string
  Floor: string
  FloorPlanID: number
  FloorPlanName: string
  QCTypeID: string
  QCTypeName: string
  QCTypeVersion: string
  CheckNumber: number
  PassedSubTasks: number
  TotalSubTasks: number
  State: string
  Status: string
  NumOfPass: number
  NumOfFail: number
}

export interface ActivitySeemore {
  TotalToDo: number
  ToDo: Data[]
  TotalInProgress: number
  InProgress: Data[]
}

export interface GetActivitySeemoreiRes
  extends GetApiResponse<ActivitySeemore> {}

export interface GetApiResponse<T> {
  ResponseData: T
  ResponseCode: string
  ResponseMsg: 'Success' | 'Error'
  RequestTime: string
  Responsetime: string
}

export enum TypeOfTask {
  TODO = 'todo',
  InProgress = 'inProgress',
}
