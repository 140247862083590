import { Row, Col } from 'antd'
import React from 'react'
import styled from 'styled-components'
import empty from 'assets/img/project-empty.png'

interface ImgProps {
  text?: string
  height?: number
}

const EmptyBox: React.FunctionComponent<ImgProps> = props => {
  const { text = 'กรุณาเลือก', height = 350 } = props

  return (
    <EmpBox style={{ height: height + 'px' }}>
      <Row>
        <Col>
          <EmptyIcon>
            <img src={empty} />
          </EmptyIcon>
        </Col>
        <Col>
          <EmptyText>{text}</EmptyText>
        </Col>
      </Row>
    </EmpBox>
  )
}

const EmpBox = styled.div`
  -webkit-box-pack: center;
  justify-content: center;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  text-align: center;
`

const EmptyIcon = styled.div`
  img {
    width: 70px;
  }
`

const EmptyText = styled.div`
  font-size: 20px;
  color: #000;
  padding-top: 10px;
`

export default EmptyBox
