import React from 'react'
import { Row, Col, Progress } from 'antd'
import moment from 'moment'
import { DATE_FORMAT } from '../../../config'
import { getStatusText } from 'utils/getText'
import { getTaskStatus, getProgressColor } from 'utils/color-map'
import { Task } from 'features/tasklane/tasklane.types'
import { getMSDelayedDate, getMSDelayedText } from 'utils/datetime'

type Props = {
  type: string
  activityTitle: string
  actualStartDate: string
  actualEndDate: string
  progress: number
  status: string
  task: Task
}

const CardListRow: React.FunctionComponent<Props> = ({
  type,
  activityTitle,
  actualStartDate,
  actualEndDate,
  progress,
  status,
  task,
}) => {
  const today = moment().startOf('day')

  let baselineDate = ''

  if (actualStartDate !== '') {
    baselineDate = actualStartDate
  } else if (actualEndDate !== '') {
    baselineDate = actualEndDate
  } else {
    baselineDate = '0'
  }

  const isCompletedTask = type === 'complete'

  const useDate = type === 'todo' ? baselineDate : actualEndDate
  let delayed = moment(useDate, DATE_FORMAT).diff(today, 'days')

  if (isNaN(delayed)) {
    delayed = 0
  }

  if (isCompletedTask) {
    delayed = getMSDelayedDate(task)
  }

  let cardClassNames
  let countdown
  if (delayed < 0) {
    cardClassNames = 'search-result--row card-delayed'
    let days = Math.abs(delayed)
    let text =
      type === 'todo'
        ? `เริ่มช้ากว่ากำหนด ${days} วัน`
        : `เสร็จช้ากว่ากำหนด ${days} วัน`
    if (isCompletedTask) {
      text = getMSDelayedText(delayed, task)
    }
    countdown = (
      <span className="color-delayed">
        <img
          src="/img/ic-warning.svg"
          width={16}
          height={16}
          alt="warning icon"
        />
        {text}
      </span>
    )
  } else {
    cardClassNames = 'search-result--row'
    let text =
      type === 'todo'
        ? `เริ่มงานภายใน ${delayed} วัน`
        : `เหลืออีก ${delayed} วัน`

    if (isCompletedTask) {
      text = getMSDelayedText(delayed, task)
    }
    countdown = <span className="color-default">{text}</span>
  }

  const css = {
    color: 'black',
  }

  const mapping = getTaskStatus(status)
  const taskConfig = getProgressColor(
    task.TaskStatus,
    task.Progress,
    task.LastReqProgress,
    task.LastCurProgress
  )

  return (
    <Row className={cardClassNames}>
      <Col span={6} style={css}>
        <span className="activity-title">{activityTitle}</span>
      </Col>
      <Col span={4} style={css}>
        {task.WbsName}
      </Col>
      <Col span={2} style={css}>
        {actualStartDate}
      </Col>
      <Col span={3} style={css}>
        {actualEndDate}
      </Col>
      <Col span={4}>{countdown}</Col>
      <Col span={3} style={cssProgress}>
        <Progress
          percent={taskConfig.progress}
          successPercent={taskConfig.success}
          strokeColor={taskConfig.color}
          size="small"
          format={v => `${task.Progress}%`}
          className={`task-progress ${
            taskConfig.color ? '' : taskConfig.className
          }`}
        />
      </Col>
      <Col span={2}>
        <span style={{ color: mapping.color }}>{getStatusText(status)}</span>
      </Col>
    </Row>
  )
}

const cssProgress: any = {
  display: 'flex',
  alignItems: 'center',
}
export default CardListRow
