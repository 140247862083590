import withAPIAction from 'utils/withAPIAction'
import { verifyReq } from 'utils/verify'

import { GetModelListReq, GetItemForModelReq, ModelImportReq } from './types'
import { getModelListReq, getItemForModelReq, importModel } from './actions'

export const getModelList = (data: GetModelListReq, token: string) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<GetModelListReq>({
    method: 'POST',
    path: 'GetModelList',
    data,
    token: `Bearer ${token}`,
  })(getModelListReq)
}

export const getItemForModel = (data: GetItemForModelReq, token: string) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<GetItemForModelReq>({
    method: 'POST',
    path: 'GetUnitByProject',
    data,
    token: `Bearer ${token}`,
  })(getItemForModelReq)
}

export const ImportModel = (data: ModelImportReq, token: string) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<ModelImportReq>({
    method: 'POST',
    path: 'ImportUnit',
    data,
    token: `Bearer ${token}`,
  })(importModel)
}
