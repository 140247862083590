import React from 'react'
import { TableHeader, TableColHeader } from 'Components/Shared/Table'
import Pagination from 'Components/Shared/Pagination'

import RowItem from './RowItem'

import { CheckList, CheckListItem } from 'features/checklist/local/types'
import { PaginationType } from 'features/types/common'

type Props = {
  checklist: CheckList
  pagination: PaginationType
  setPagination: (page: PaginationType) => void
}

const LocalCheckList: React.ComponentType<Props> = ({
  checklist,
  pagination,
  setPagination,
}) => {
  const content = checklist.List.map((item: CheckListItem, index: number) => {
    return <RowItem item={item} key={index} />
  })

  return (
    <>
      <TableHeader paddingLeft={27}>
        <TableColHeader span={2}>Checklist ID</TableColHeader>
        <TableColHeader span={7}>Checklist name</TableColHeader>
        <TableColHeader span={3}>รายการตรวจ</TableColHeader>
        <TableColHeader span={3}>ผูก activity</TableColHeader>
        <TableColHeader span={3}>วันที่สร้าง</TableColHeader>
        <TableColHeader span={6}>แก้ไขล่าสุด</TableColHeader>
      </TableHeader>
      {content}
      <Pagination
        currentPage={pagination.Page}
        totalRows={checklist.TotalRows}
        perPage={pagination.RowPerPage}
        onChangeNumber={(page: number) => {
          pagination.RowPerPage = page
          setPagination({ ...pagination })
        }}
        onClickPage={(page: number) => {
          pagination.Page = page
          setPagination({ ...pagination })
        }}
      />
    </>
  )
}

export default LocalCheckList
