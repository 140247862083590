import React, { useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Layout, Row, Col, Modal } from 'antd'
import moment from 'moment-timezone'
import { RouteComponentProps } from 'react-router-dom'
import RootState from 'typings/RootState'
import styled from 'styled-components'
import {
  getOriginalActivity,
  getCurrentActivity,
  currentActivityCodeSelector,
  isSubmittingSelector,
  isLoadingSelector,
  submitErrorSelector,
  submitSuccessSelector,
  getActivityHistory,
  getActivityHistoryError,
} from 'features/activity/activity.selector'
import { translate } from 'i18n'
import TKeys from 'i18n/translationKey'
import {
  updateField,
  UpdateFieldPayload,
  currentBrowsingActivity,
  clearSubmitError,
  submitRequestActivity,
  updateOriginalActivity,
} from 'features/activity/activity.actions'

import DetailForm from './DetailForm'
import Info from './Info'
import HistoryTable from './HistoryTable'

import {
  projectSubmitErrorSelector,
  projectCodeWithSuffixSelector,
} from 'features/project/project.selector'

import {
  getActivityRequest,
  getActivityHistoryRequest,
} from 'features/activity/activity.api'
import {
  userEmailSelector,
  serviceTokenSelector,
} from 'features/sensitive/sensitive.selector'

import { getWbsPathMapSelector } from 'features/wbs/wbs.selector'
import { toSaveFormat } from 'utils/datetime'

import accountHelper from 'utils/account'
import projectHelper from 'utils/projectHelper'
import logo from 'assets/img/watermark_logo.png'
import imgHistory from 'assets/img/ic-history.png'
import { lookup } from '../../../../lookup'
import PageHandle from '../../../Shared/PageHandle'
import Breadcrumbs from '../Breadcrumbs'
import { createWaterMark } from 'utils/file'

const { Content } = Layout

type Props = ReduxProps &
  DispatchProps &
  RouteComponentProps<{ code: string }> & {
    onImageOpen(list?: any[], readOnly?: boolean): void
    onDocumentOpen(list?: any[], readOnly?: boolean): void
    onThumbnailOpen(index: number, list?: any[], readOnly?: boolean): void
  }

const ActivityDetail: React.FunctionComponent<Props> = ({
  match,
  original,
  currentActivity,
  userEmail,
  projectCode,
  updateField,
  currentActivityCode,
  serviceToken,
  wbsPathMap,

  projectError,
  activityError,
  activityLoading,
  isSubmitting,
  submitSuccess,

  history,
  activityHistory,
  fetchHistoryError,
  updateOriginalActivity,
  onImageOpen,
  onDocumentOpen,
  onThumbnailOpen,
}) => {
  const [showHistoryModal, setShowHistoryModal] = useState(false)
  const ActivityID = match.params.code

  const UserEmail = accountHelper.getUserEmail()
  const ProjectCode = `${projectCode}`

  const dispatch = useDispatch()

  useEffect(() => {
    if (submitSuccess) {
      history.push('../../dashboard')
    }
  }, [dispatch, submitSuccess])

  useEffect(() => {
    dispatch(
      getActivityRequest(
        {
          UserEmail,
          ProjectCode,
          ActivityID,
        },
        serviceToken
      )
    )

    dispatch(
      getActivityHistoryRequest(
        {
          UserEmail,
          ProjectCode,
          ActivityID,
        },
        serviceToken
      )
    )
  }, [ActivityID])

  const onChange = (data: UpdateFieldPayload) => {
    updateField(data)
  }

  const onActivityDetailSave = async () => {
    const ActualStartDate = toSaveFormat(currentActivity.ActualStartDate)
    const progress: number = currentActivity.Progress
    const req = {
      UserEmail: userEmail,
      ProjectCode: projectCode,
      ActivityID: currentActivityCode,
      ActualStartDate,
      ActualFinishDate:
        progress !== 100 ? '' : toSaveFormat(currentActivity.ActualFinishDate),
      ExpectFinishDate:
        progress === 100
          ? toSaveFormat(currentActivity.ActualFinishDate)
          : toSaveFormat(currentActivity.ExpectFinishDate),
      Progress: progress,
      Remark: original.Remark,
      Images: [],
      Documents: [],
    }

    const workProgress = translate(TKeys.ActivityDetail.work_progress)
    const createDate = translate(TKeys.ActivityDetail.created_date)
    const uploadDate = translate(TKeys.ActivityDetail.upload_date)
    const timeUnit = translate(TKeys.ActivityDetail.time_unit)

    let now = moment()
    const progressText = `${workProgress} ${progress}%`
    const uploadText = `${uploadDate} ${now.format(
      'DD/MM/YYYY HH:mm'
    )} ${timeUnit}`
    const images: any[] = []
    for (const item of original.Images) {
      const { text, file, lastModified } = item
      const createdAt = moment(item.lastModified)
      const createdText = `${createDate} ${createdAt.format(
        'DD/MM/YYYY HH:mm'
      )} ${timeUnit}`
      const label = `${progressText}   ${createdText}   ${uploadText}`
      const resp = await createWaterMark({ text, file, label, logo })
      images.push({ text, file: resp.file, lastModified })
    }
    original.Images = images

    dispatch(submitRequestActivity({ req, original, token: serviceToken }))
  }

  return (
    <>
      <Breadcrumbs progress={original.Progress} activity={currentActivity} />
      <Content className="mid-content">
        <div className="main-content activity-detail">
          <PageHandle
            visible={activityLoading}
            error={activityError}
            onBack={() => dispatch(clearSubmitError())}
          >
            <Row type="flex" justify="space-between" className="header">
              <Col>
                <h1 className="title">{currentActivity.ActivityName}</h1>
              </Col>
              <Col>
                <HistoryBtn
                  onClick={e => setShowHistoryModal(true)}
                  className="std-font-regular"
                >
                  <img src={imgHistory} alt="Go to activity history" />

                  {translate(TKeys.ActivityDetail.history_request)}
                </HistoryBtn>
                <Modal
                  title=""
                  footer=""
                  visible={showHistoryModal}
                  onOk={() => setShowHistoryModal(false)}
                  onCancel={() => setShowHistoryModal(false)}
                  centered={false}
                  closable={true}
                  width={'1024px'}
                  wrapClassName="activity-history-modal-wrapper"
                  closeIcon={
                    <img
                      src="/img/ic-cancel.svg"
                      alt="close icon"
                      title="close history modal"
                    />
                  }
                >
                  <HistoryTable
                    items={activityHistory}
                    fetchHistoryError={fetchHistoryError}
                    onImageOpen={onImageOpen}
                    onDocumentOpen={onDocumentOpen}
                  />
                </Modal>
              </Col>
            </Row>

            <Row type="flex" justify="space-around" gutter={30}>
              <Info
                originalTask={original}
                task={currentActivity}
                wbsPath={wbsPathMap[Number(currentActivity.WbsID)]}
                isSubmitting={isSubmitting}
              />
              <DetailForm
                onChange={onChange}
                onActivityDetailSave={onActivityDetailSave}
                isSubmitting={isSubmitting}
                history={history}
                onImageOpen={() => onImageOpen()}
                onDocumentOpen={() => onDocumentOpen()}
                onThumbnailOpen={(index: number) => onThumbnailOpen(index)}
              />
            </Row>
          </PageHandle>
        </div>
      </Content>
    </>
  )
}

const HistoryBtn = styled.div`
  color: #003168;
  font-size: 18px;
  img {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    margin-bottom: 5px;
  }

  :hover {
    cursor: pointer;
  }
`

const mapStateToProps = (state: RootState) => {
  return {
    currentActivity: getCurrentActivity(state),
    original: getOriginalActivity(state),
    userEmail: userEmailSelector(state),

    projectError: projectSubmitErrorSelector(state),
    projectCode: projectCodeWithSuffixSelector(state),

    currentActivityCode: currentActivityCodeSelector(state),
    serviceToken: serviceTokenSelector(state),

    activityError: submitErrorSelector(state),
    activityLoading: isLoadingSelector(state),
    submitSuccess: submitSuccessSelector(state),
    isSubmitting: isSubmittingSelector(state),

    wbsPathMap: getWbsPathMapSelector(state),
    activityHistory: getActivityHistory(state),
    fetchHistoryError: getActivityHistoryError(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {
  updateOriginalActivity,
  updateField,
  currentBrowsingActivity,
}

type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(ActivityDetail)
