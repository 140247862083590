import { getType } from 'typesafe-actions'
import { cloneDeep } from 'lodash'

import * as actions from './actions'
import { StoreState, ReportAction, SelectedPin, LocationList } from './types'
import produce from 'immer'
import parseErrorMessage from 'utils/parseErrorMessage'
import { sortingByNumber } from 'utils/sorting'
import { Event } from '../../types/event'

const initPinList = {
  PinList: [],
} as SelectedPin

const initLocationList = {
  LocationList: [],
} as LocationList

const reportQC = {
  CheckListID: 0,
  CheckListName: '',

  ProjectCode: '',
  ProjectNameTH: '',
  ProjectNameEN: '',

  CheckedDate: '',
  ReturnDate: '',
  CheckNumber: 0,

  CheckStatus: '',

  HasSign: false,
  HasLocation: false,
  HasSelectPin: false,

  State: 'CM',
  Status: 'INIT',

  DefectList: [],

  InspectDate: '',
}

const checklistQC = {
  CheckListID: 0,
  CheckListName: '',

  ProjectTypeID: 0,
  ProjectTypeName: '',

  JobTypeList: [],
}

const defect = {
  RequestID: 0,
  PinID: 0,
  PinNumber: 0,
  CheckNumber: 0,

  JobTypeID: 0,
  JobTypeName: '',
  SubJobTypeID: 0,
  SubJobTypeName: '',
  DetailID: 0,
  DetailName: '',
  FixID: 0,
  FixName: '',
  FixOther: '',

  Remark: '',
  PinStatus: 'ToBeFix',

  ImageLocation: undefined,
  ImageBefore: undefined,
  ImageAfter: undefined,
  IsRequired: false,
  IsSupplier: false,

  VerifyStatus: '',
  VerifyRemark: '',
  HowToFix: '',

  MethodTypeID: 0,
  LenOfDefect: '',
  WideOfDefect: '',
  NumOfPoint: '',
}

const initEvent = {
  IsLoading: false,
  MessageSuccess: undefined,
  SubmitError: undefined,
  SubmitSuccess: false,
  SubmitType: undefined,
} as Event

const initialState: StoreState = {
  report: cloneDeep(reportQC),
  event: cloneDeep(initEvent),
  eventChecklist: cloneDeep(initEvent),
  checkListQC: cloneDeep(checklistQC),
  defect: cloneDeep(defect),
  pinSelected: cloneDeep(initPinList),
  locationSelected: cloneDeep(initLocationList),
  locationEvent: cloneDeep(initEvent),
}

export default function(
  state: StoreState = initialState,
  action: ReportAction
): StoreState {
  return produce(state, draft => {
    switch (action.type) {
      case getType(actions.getReportAction.request):
        draft.event = cloneDeep(initEvent)
        draft.event.IsLoading = true
        draft.report = cloneDeep(reportQC)

        break
      case getType(actions.getReportAction.success):
        draft.event.IsLoading = false
        const response = action.payload.ResponseData
        response.DefectList = sortingByNumber(
          response.DefectList,
          'asc',
          'PinNumber'
        )

        draft.report = response
        break

      case getType(actions.resetDefect):
        draft.defect = cloneDeep(defect)
        break
      case getType(actions.updateDefect):
        draft.defect = action.payload
        break

      case getType(actions.getCheckListAction.request):
        draft.eventChecklist = cloneDeep(initEvent)
        draft.eventChecklist.IsLoading = true
        draft.checkListQC = cloneDeep(checklistQC)
        break

      case getType(actions.getCheckListAction.success):
        draft.eventChecklist.IsLoading = false
        const checkQC = action.payload.ResponseData
        draft.checkListQC = checkQC
        break

      case getType(actions.getCheckListAction.failure):
        draft.eventChecklist.IsLoading = false
        draft.eventChecklist.SubmitError = parseErrorMessage(action.payload)
        break

      case getType(actions.createDefectAction.request):
      case getType(actions.updateDefectAction.request):
      case getType(actions.setDefectAction.request):
      case getType(actions.verifyDefectAction.request):
      case getType(actions.resendReportAction.request):
      case getType(actions.sendEditReportAction.request):
      case getType(actions.updateSelectedPintAction.request):
        draft.event = cloneDeep(initEvent)
        draft.event.IsLoading = true
        break

      case getType(actions.createDefectAction.success):
      case getType(actions.updateDefectAction.success):
      case getType(actions.setDefectAction.success):
      case getType(actions.verifyDefectAction.success):
      case getType(actions.resendReportAction.success):
      case getType(actions.sendEditReportAction.success):
      case getType(actions.updateSelectedPintAction.success):
        draft.event.IsLoading = false
        draft.event.SubmitError = undefined
        draft.event.SubmitSuccess = true
        draft.event.MessageSuccess = 'ทำรายการสำเร็จ'
        break

      case getType(actions.getReportAction.failure):
      case getType(actions.createDefectAction.failure):
      case getType(actions.updateDefectAction.failure):
      case getType(actions.setDefectAction.failure):
      case getType(actions.verifyDefectAction.failure):
      case getType(actions.resendReportAction.failure):
      case getType(actions.sendEditReportAction.failure):
      case getType(actions.getSelectPinAction.failure):
      case getType(actions.updateSelectedPintAction.failure):
        draft.event.IsLoading = false
        draft.event.SubmitError = parseErrorMessage(action.payload)
        break

      case getType(actions.updateReport):
        draft.report = action.payload
        break
      case getType(actions.resetEvent):
        draft.event = cloneDeep(initEvent)
        break

      case getType(actions.getSelectPinAction.request):
        draft.event.IsLoading = true
        draft.pinSelected = {
          PinList: [],
        }
        break
      case getType(actions.getSelectPinAction.success):
        draft.event.IsLoading = false
        draft.pinSelected = action.payload.ResponseData
        break

      case getType(actions.getSelectLocationAction.request):
        draft.locationEvent = cloneDeep(initEvent)
        draft.locationEvent.IsLoading = true
        draft.locationSelected = {
          LocationList: [],
        }
        break
      case getType(actions.getSelectLocationAction.success):
        draft.locationEvent.IsLoading = false
        draft.locationSelected = action.payload.ResponseData
        break

      case getType(actions.updateSelectedLocationAction.request):
        draft.locationEvent = cloneDeep(initEvent)
        draft.locationEvent.IsLoading = true
        break
      case getType(actions.updateSelectedLocationAction.success):
        draft.locationEvent.IsLoading = false
        draft.locationEvent.SubmitError = undefined
        draft.locationEvent.SubmitSuccess = true
        draft.locationEvent.MessageSuccess = 'ทำรายการสำเร็จ'
        break

      case getType(actions.getSelectLocationAction.failure):
      case getType(actions.updateSelectedLocationAction.failure):
        draft.locationEvent.IsLoading = false
        draft.locationEvent.SubmitError = parseErrorMessage(action.payload)
        break

      default:
        return state
    }
  })
}
