import { CommonResp, ReqUpdateActivityProgress } from './types'
import { createAsyncAction, createStandardAction } from 'typesafe-actions'

export const updateDataAction = createAsyncAction(
  'admin/updateProgress/UPDATE_DATA_REQ',
  'admin/updateProgress/UPDATE_DATA_RESP',
  'admin/updateProgress/UPDATE_DATA_FAIL'
)<ReqUpdateActivityProgress, CommonResp, any>()

export const resetEvent = createStandardAction(
  'admin/updateProgress/RESET_EVENT_ACTION'
)<void>()
