import { createStandardAction, createAsyncAction } from 'typesafe-actions'
import {
  GetCheckListReq,
  GetCheckListResp,
  SendCommissioningReq,
  SendCommissioningEditReq,
  SaveCommissioningReq,
  CommonResp,
  CheckListForQC,
  GetCommissioningImageReq,
  GetCommissioningImageResp,
  SaveCommissioningImageReq,
} from './types'

export const getCheckListAction = createAsyncAction(
  'report/commissioning/GET_CHECKLIST_QC_REQ',
  'report/commissioning/GET_CHECKLIST_QC_RESP',
  'report/commissioning/GET_CHECKLIST_QC_FAIL'
)<GetCheckListReq, GetCheckListResp, any>()

export const saveCheckListAction = createAsyncAction(
  'report/commissioning/SAVE_CHECKLIST_QC_REQ',
  'report/commissioning/SAVE_CHECKLIST_QC_RESP',
  'report/commissioning/SAVE_CHECKLIST_QC_FAIL'
)<SaveCommissioningReq, CommonResp, any>()

export const sendCommissioningAction = createAsyncAction(
  'report/commissioning/SEND_TO_QC_REQ',
  'report/commissioning/SEND_TO_QC_RESP',
  'report/commissioning/SEND_TO_QC_FAIL'
)<SendCommissioningReq, CommonResp, any>()

export const submitCommissionReq = createStandardAction(
  'report/commissioning/SUBMIT_REQUEST'
)<any>()

export const sendCommissioningEditAction = createAsyncAction(
  'report/commissioning/SEND_EDIT_QC_REQ',
  'report/commissioning/SEND_EDIT_QC_RESP',
  'report/commissioning/SEND_EDIT_QC_FAIL'
)<SendCommissioningEditReq, CommonResp, any>()

export const updateCheckList = createStandardAction(
  'report/commissioning/UPDATE_CHECKLIST'
)<CheckListForQC>()

export const resetEvent = createStandardAction(
  'report/commissioning/RESET_EVENT'
)<void>()

export const uploadImage = createStandardAction(
  'report/commissioning/UPLOAD_IMG'
)<any>()

export const updateNewImage = createStandardAction(
  'report/commissioning/UPDATE_IMG'
)<any>()

export const getCommissioningImageAction = createAsyncAction(
  'report/commissioning/GET_IMAGE_REQ',
  'report/commissioning/GET_IMAGE_RESP',
  'report/commissioning/GET_IMAGE_FAIL'
)<GetCommissioningImageReq, GetCommissioningImageResp, any>()

export const saveCommissioningImageAction = createAsyncAction(
  'report/commissioning/SAVE_IMAGE_REQ',
  'report/commissioning/SAVE_IMAGE_RESP',
  'report/commissioning/SAVE_IMAGE_FAIL'
)<SaveCommissioningImageReq, CommonResp, any>()
