import React from 'react'
import { Link } from 'react-router-dom'

type Props = {
  to: string
}

const ReportLink: React.ComponentType<Props> = props => {
  const { to = '' } = props

  return (
    <Link to={to} target="_blank">
      {props.children}
    </Link>
  )
}

export default ReportLink
