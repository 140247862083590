import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const MenuItem = (props: any) => {
  const { item, selected } = props
  const { list } = item

  const content = list.map((it: any, index: number) => {
    const onClick: any = it.onClick
    const css: any = selected === it.key ? 'selected' : undefined
    if (onClick) {
      return (
        <Menu className={css} onClick={onClick} key={index}>
          {it.name}
        </Menu>
      )
    } else {
      return (
        <Link to={it.link} key={index}>
          <Menu className={css}>{it.name}</Menu>
        </Link>
      )
    }
  })

  return (
    <ItemView>
      <Title>{item.name}</Title>
      {content}
    </ItemView>
  )
}

const ItemView = styled.div`
  font-size: 20px;
  width: 100%;
  text-align: left;
  color: #c9c9c9;
  margin-bottom: 25px;
  img {
    float: right;
    margin-top: 12px;
  }

  .selected {
    color: white;
    background-color: rgba(121, 202, 220, 0.2);
    border-radius: 5px;
  }

  a {
    color: white;
    font-family: 'DB Helvethaica X 55 Regular';
    font-size: 20px;
    width: 208px;
  }

  a:hover {
    color: white;
  }
`

const Title = styled.div`
  font-size: 22px;
  width: 100%;
  text-align: left;
  color: #8b99a7;
  img {
    float: right;
    margin-top: 12px;
  }
`

const Menu = styled.div`
  padding: 3px 12px;
  width: 208px;
  margin-bottom: 1px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  color: white;
  :hover {
    color: white;
    background-color: rgba(121, 202, 220, 0.2);
    border-radius: 5px;
  }
`

export default MenuItem
