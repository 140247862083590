import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { RootState } from 'typings/RootState'
import { RouteComponentProps } from 'react-router-dom'
import { History } from 'history'

import { BreadcrumbLink } from 'Components/Shared/BreadCrumb/BreadCrumb'
import SubnavBar from 'Components/Shared/SubnavBar'
import EventModal from 'Components/Shared/Modal/EventModal'
import { AlertError } from 'Components/Shared'
import Menu from '../../../MenuAdmin'

import {
  clearCheckListDetail,
  createUpdateCheckListDetailReq,
  deleteCheckListDetailReq,
  updateUnitCheckListResult,
  updateCheckListDetail,
} from 'features/checklist/unit/actions'
import {
  getCheckListById,
  getQCTypeList,
  getUnitByUnitCheckList,
  getFunctionCheckList,
} from 'features/checklist/unit/api'
import {
  CheckListDetail,
  UnitCheckListByCodeType,
} from 'features/checklist/unit/types'

import {
  serviceTokenSelector,
  userEmailSelector,
} from 'features/sensitive/sensitive.selector'

import { getProjectType } from 'features/masterData/api'
import QCSetting from './Setting'
import QCList from './List'
import { selectProjectType } from 'features/masterData/selector'
import { fetchProjects } from 'features/project/project.api'
import { projectsByIdSelector } from 'features/project/project.selector'
import { ProjectListItem } from 'features/project/project.types'

const editModeBreadcrumbs: BreadcrumbLink[] = [
  {
    url: '/dashboard',
    title: 'Home',
    icon: '/home.png',
    active: true,
    showTitle: false,
  },
  {
    url: '/check-unit',
    title: 'ตรวจมาตรฐาน',
    active: true,
    showTitle: true,
  },
  {
    url: '/check-unit/:id',
    title: 'แก้ไข Checklist ตรวจงานมาตรฐาน',
    active: false,
    showTitle: true,
  },
]

function validateCheckList(
  check: CheckListDetail,
  unit: UnitCheckListByCodeType
) {
  let qc
  let job
  let subjob
  if (check.QCTypeList.length === 0) return 'กรุณาเลือกลักษณะหมวดงาน'

  for (let q = 0; q < check.QCTypeList.length; q++) {
    qc = check.QCTypeList[q]
    if (qc.FunctionCheckListID === 0) {
      return 'กรุณาเลือก checklist function ใน ' + qc.QCTypeName
    }
    if (qc.JobTypeList.length === 0) {
      return 'กรุณาเลือกลักษณะงานหลักใน ' + qc.QCTypeName
    }
    for (let j = 0; j < qc.JobTypeList.length; j++) {
      job = qc.JobTypeList[j]
      const jobPath = `${qc.QCTypeName} / ${job.JobTypeName}`
      if (job.SubJobTypeList.length === 0) {
        return `กรุณาเลือกลักษณะงานรองใน ${jobPath}`
      }
      for (let sj = 0; sj < job.SubJobTypeList.length; sj++) {
        subjob = job.SubJobTypeList[sj]
        const subJobPath = `${jobPath} / ${subjob.SubJobTypeName}`
        if (subjob.DetailList.length === 0) {
          return `กรุณาเลือกรายละเอียดใน ${subJobPath}`
        }
        /*
        for (let d = 0; d < subjob.DetailList.length; d++) {
          detail = subjob.DetailList[d]
          if (detail.CriteriaList.length < check.CriteriaList.length) {
            const detailPath = `${subJobPath}/${detail.DetailName}`
            return `กรุณากรอก criteria ให้ครบใน ${detailPath}`
          }
        }
        */
      }
    }
  }
  const unitList = Object.values(unit)
  for (let i = 0; i < unitList.length; i++) {
    for (let j = 0; j < unitList[i].ModelList.length; j++) {
      if (unitList[i].ModelList[j].CriteriaName === '') {
        return 'กรุณาใส่ criteria ในยูนิต ' + unitList[i].ModelList[j].ModelName
      }
    }
  }

  return ''
}

type Props = RouteComponentProps<{ id: string }> & ReduxProps & DispatchProps

const CheckUnitDetail: React.ComponentType<Props> = ({
  UserEmail,
  checkListDetail: checkListDetailProps,
  projectType,
  event,
  checklistEvent,
  unitEvent,

  match,
  history,
  Token,
  projectsById,
  getProjectType,
  fetchProjects: fetchProjectsProps,
  getQCTypeList,
  getUnitByUnitCheckList: getUnitByUnitCheckListProps,
  getCheckListById,
  qCTypeList,
  unitCheckListByCode: unitCheckListByCodeProps,
  unitCheckListByCodeResult,
  createUpdateCheckListDetail,
  getFunctionCheckList,
  clearCheckListDetail,
  deleteCheckListById,
  updateUnitCheckListResult,
  functionList,
  updateCheckListDetail,
}) => {
  const CheckListID = match.params.id
  const isEditing = checkListDetailProps.CheckListID !== 0

  const [enableModal, setEnableModal] = useState(false)
  const [enableModalValidate, setEnableModalValidate] = useState(false)
  const [errorValidate, setErrorValidate] = useState('')
  const [tab, setTab] = useState({ list: '', setting: 'active' })
  const [checkListDetail, setCheckListDetail] = useState<CheckListDetail>(
    checkListDetailProps
  )
  const [unitCheckListByCode, setUnitCheckListByCode] = useState<
    UnitCheckListByCodeType
  >(unitCheckListByCodeProps)

  const [projects, setprojectst] = useState<ProjectListItem[]>([])

  useEffect(() => {
    setprojectst(Object.values(projectsById))
  }, [projectsById])

  useEffect(() => {
    setCheckListDetail(checkListDetailProps)
  }, [checkListDetailProps])

  useEffect(() => {
    setUnitCheckListByCode(unitCheckListByCodeProps)
  }, [unitCheckListByCodeProps])

  useEffect(() => {
    setEnableModal(true)
    if (CheckListID) {
      getCheckListById(parseInt(CheckListID, 10))
    }
    fetchProjectsProps({ email: UserEmail, token: Token })
    getProjectType({ Page: 1, RowPerPage: 200 })

    getQCTypeList()
    getFunctionCheckList(1, 200)
    return () => {
      clearCheckListDetail()
    }
  }, [])

  const getUnitByUnitCheckList = (ProjectCode: string) => {
    if (unitCheckListByCode[ProjectCode] == null) {
      getUnitByUnitCheckListProps(ProjectCode, +CheckListID || 0)
    }
  }
  const setTabToFirst = () => setTab({ list: '', setting: 'active' })
  const setTabToSecond = () => setTab({ list: 'active', setting: '' })
  const updateUnitCheckList = (
    newObj: UnitCheckListByCodeType,
    newObjResult: UnitCheckListByCodeType
  ) => {
    setUnitCheckListByCode(newObj)
    updateUnitCheckListResult(newObjResult)
  }

  const updateCheckList = () => {
    setCheckListDetail({ ...checkListDetail })
  }

  const onSave = () => {
    const errorMsg = validateCheckList(
      checkListDetail,
      unitCheckListByCodeResult
    )

    const projectTypeItem = projectType.List.find(
      it => it.ProjectTypeID === checkListDetail.ProjectTypeID
    )
    const checklist = { ...checkListDetail }
    if (projectTypeItem) {
      checklist.ProjectTypeName = projectTypeItem.ProjectTypeName
    }

    if (errorMsg === '' && enableModalValidate == false) {
      setEnableModalValidate(false)
      setEnableModal(true)
      createUpdateCheckListDetail(checklist, unitCheckListByCodeResult)
    } else {
      setEnableModalValidate(true)
      setErrorValidate(errorMsg)
    }
  }

  const onDelete = () => {
    setEnableModal(true)
    deleteCheckListById(checkListDetailProps.CheckListID, history)
  }

  const duplicateCheckList = () => {
    checkListDetail.CheckListID = 0
    checkListDetail.CheckListName = ''
    checkListDetail.Status = 'Ready'
    checkListDetail.ProjectList = []
    updateCheckListDetail(checkListDetail)
    history.push('/check-unit/create')
  }

  const callBackErrorModal = () => {
    setEnableModalValidate(false)
    setErrorValidate('')
  }

  const gotoDashboard = () => {
    setEnableModal(false)
    history.push('/check-unit')
  }

  const closeEnableModal = () => setEnableModal(false)
  return (
    <>
      <EventModal
        event={[event, checklistEvent]}
        enable={enableModal}
        successCalback={gotoDashboard}
        errorCalback={closeEnableModal}
      />

      <AlertError
        text={errorValidate}
        isShow={enableModalValidate}
        callback={callBackErrorModal}
      />

      <SubnavBar breadcrumbs={editModeBreadcrumbs} hideSearch={true} />
      <Menu selected="check.quality">
        <MainPage>
          <Title>
            {isEditing ? 'แก้ไข' : 'สร้าง'} Checklist ตรวจงานมาตรฐาน
          </Title>
          <Tab>
            <TabItem className={tab.setting} onClick={setTabToFirst}>
              ตั้งค่า
            </TabItem>
            <TabItem className={tab.list} onClick={setTabToSecond}>
              รายการ
            </TabItem>
          </Tab>
          <Content>
            <QCSetting
              isActive={tab.setting === 'active'}
              isEditing={isEditing}
              projects={projects}
              projectType={projectType}
              checkListDetail={checkListDetail}
              updateCheckList={updateCheckList}
              unitCheckListByCode={unitCheckListByCode}
              unitCheckListByCodeResult={unitCheckListByCodeResult}
              updateUnitCheckList={updateUnitCheckList}
              getUnitByUnitCheckList={getUnitByUnitCheckList}
              onSave={onSave}
              onDelete={onDelete}
              unitLoading={unitEvent.IsLoading}
              duplicateCheckList={duplicateCheckList}
            />
            <QCList
              isActive={tab.list === 'active'}
              checkListDetail={checkListDetail}
              updateCheckList={updateCheckList}
              qCTypeList={qCTypeList}
              functionList={functionList.List}
              onSave={onSave}
            />
          </Content>
        </MainPage>
      </Menu>
    </>
  )
}

const MainPage = styled.div`
  padding: 40px 45px;
  width: 100%;
`

const Title = styled.h2`
  font-size: 28px;
  font-family: 'DB Helvethaica X 75 Bd';
`

const Tab = styled.div`
  height: 28px;
  width: 100%;
  border-bottom: 1px solid #e8ebf0;
`

const TabItem = styled.div`
  font-size: 18px;
  float: left;
  margin-right: 24px;
  color: #8b99a7;
  cursor: pointer;
  &.active {
    color: #000000;
    border-bottom: 2px solid #003168;
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.03);
  }
`

const Content = styled.div`
  margin-top: 40px;
`

const mapStateToProps = (state: RootState) => {
  return {
    UserEmail: userEmailSelector(state),
    Token: serviceTokenSelector(state),
    projectsById: projectsByIdSelector(state),
    projectType: selectProjectType(state),
    event: state.unitChecklist.Event,
    checklistEvent: state.unitChecklist.CheckListEvent,
    unitEvent: state.unitChecklist.UnitEvent,

    qCTypeList: state.unitChecklist.QCTypeList,
    checkListDetail: state.unitChecklist.CheckListDetail,
    unitCheckListByCode: state.unitChecklist.UnitCheckListByCode,
    unitCheckListByCodeResult: state.unitChecklist.UnitCheckListByCodeResult,
    functionList: state.unitChecklist.FunctionList,
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>
const mapDispatchToProps = {
  getProjectType,
  fetchProjects,
  getQCTypeList,
  getUnitByUnitCheckList,
  getCheckListById,
  clearCheckListDetail,
  getFunctionCheckList,
  updateUnitCheckListResult,
  updateCheckListDetail,

  createUpdateCheckListDetail: (
    check: CheckListDetail,
    unit: UnitCheckListByCodeType
  ) => createUpdateCheckListDetailReq.request({ check, unit }),
  deleteCheckListById: (CheckListID: number, history: any) =>
    deleteCheckListDetailReq.request({ CheckListID, isGoBack: true, history }),
}

type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(CheckUnitDetail)
