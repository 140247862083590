import React from 'react'
import styled from 'styled-components'
import { UpIcon, DownIcon } from 'Components/Shared/Icon'
type Props = {
  heading: string
  type: string | undefined
  orderName: string
  onSort(): void
}

export const ColumnHeader: React.FunctionComponent<Props> = ({
  heading,
  type,
  orderName,
  onSort,
}) => {
  const icon =
    type === 'desc' ? (
      <DownIcon onClick={onSort} />
    ) : (
      <UpIcon onClick={onSort} />
    )
  return (
    <Header>
      <span>{heading}</span>
      <div>
        <ActionIcon onClick={onSort}>{orderName}</ActionIcon>
        <Icon>{icon}</Icon>
      </div>
    </Header>
  )
}

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 20px;
  color: #000;
  padding-bottom: 16px;
`

const ActionIcon = styled.span`
  font-size: 18px !important;
  color: #8b99a7 !important;
  font-family: 'DB Helvethaica X 55 Regular';
  cursor: pointer;
`

const Icon = styled.span`
  margin-left: 8px;
`

export default ColumnHeader
