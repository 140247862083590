import React, { useState, useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import RootState from 'typings/RootState'
import { connect, useDispatch } from 'react-redux'

import {
  selectOriginalActivity,
  selectCheckList,
  selectCurrentActivity,
  selectEvent as selectEventActivity,
} from 'features/maincon/activity/selector'
import {
  getWbsPathMapSelector,
  getWbsMapParentsSelector,
  getEvent,
} from 'features/wbs/wbs.selector'
import { setCurrentActivity } from 'features/maincon/activity/actions'
import {
  getActivityById,
  getCheckListById,
} from 'features/maincon/activity/api'
import {
  userEmailSelector,
  serviceTokenSelector,
} from 'features/sensitive/sensitive.selector'
import { selectUploadEvent } from 'features/uploadfile/selector'
import { selectData, selectEvent } from 'features/maincon/history/selector'
import { GetHistoryActivityReq } from 'features/maincon/history/types'
import { getHistoryActivity } from 'features/maincon/history/api'
import { saveActionActivityForPJ } from 'features/approval/activity/api'
import { UpdateActivityReq } from 'features/approval/activity/types'
import { selectEvent as updateSelectEvent } from 'features/approval/activity/selector'
import { selectProjectCode } from 'features/approval/dashboard/selector'
import { AlertError, AlertSuccess, LoadingView } from 'Components/Shared'
import Breadcrumbs from './Breadcrumbs'
import ActivityDetail from './ActivityDetail'
import ImageDisplay from '../../Shared/ImageDisplay'
import DocumentDisplay from '../../Shared/DocumentDisplay'
import ThumbnailDisplay from '../../Shared/ThumbnailDisplay'
import { HistoryModal, HistoryTable } from 'Components/Activity'

type FormProps = {
  code: string
}

type Props = FormProps & RouteComponentProps<{ code: string }> & ReduxProps

const MainConActivity = (props: Props) => {
  const [lastPage, setLastPage] = useState('')
  const [page, setPage] = useState('')
  const [readOnly, setReadOnly] = useState(false)
  const [enable, setEnable] = useState(false)
  const [imageSelected, setImageSelected] = useState(0)
  const [imageList, setImageList] = useState([] as any[])
  const [documentList, setDocumentList] = useState([] as any[])
  const [showHistoryModal, setShowHistoryModal] = useState(false)
  const {
    Token,
    UserEmail,
    ProjectCode,
    match,
    CheckList,
    currentActivity,
    originalActivity,
    historyData,
    historyEvent,
    activityEvent,
    history,
    wbs,
    updateEvent,
    wbsEvent,
  } = props

  const ActivityID = match.params.code
  const onPageClose = () => {
    setPage(lastPage)
    if (lastPage !== '') {
      setLastPage('')
    }
  }

  const dispatch = useDispatch()

  useEffect(() => {
    setEnable(false)
  }, [originalActivity])

  useEffect(() => {
    dispatch(
      getActivityById(
        {
          UserEmail,
          ProjectCode,
          ActivityID,
        },
        Token
      )
    )

    dispatch(
      getCheckListById(
        {
          UserEmail,
          ProjectCode: ProjectCode,
          ActivityID,
        },
        Token
      )
    )
  }, [UserEmail, ProjectCode, ActivityID, Token, dispatch])

  useEffect(() => {
    const data: GetHistoryActivityReq = {
      ProjectCode,
      UserEmail,
      ActivityID,
    }

    dispatch(getHistoryActivity(Token, data))
  }, [UserEmail, ProjectCode, ActivityID, Token, dispatch])

  const callSaveActionActivityForPJ = (
    action: 'APPV' | 'REJT',
    remark: string
  ) => {
    setEnable(true)
    const data: UpdateActivityReq = {
      ProjectCode,
      UserEmail,
      ActivityID,
      Action: action,
      Reason: remark,
      Progress: originalActivity.LastReqProgress,
    }

    dispatch(saveActionActivityForPJ(data, Token))
  }

  const onAcceptTask = () => {
    callSaveActionActivityForPJ('APPV', '')
  }

  const onRejectTask = (remark: string) => {
    callSaveActionActivityForPJ('REJT', remark)
  }

  const gotoDashboard = () => {
    history.push('../list')
  }

  const onImageOpen = (list?: any[], readOnly?: boolean) => {
    setLastPage(page)
    setPage('images')
    setImageList(list ? list : [])
    setReadOnly(readOnly || false)
  }

  const onDocumentOpen = (list?: any[], readOnly?: boolean) => {
    setLastPage(page)
    setPage('documents')
    setReadOnly(readOnly || false)
    setDocumentList(list ? list : [])
  }

  const onThumbnailOpen = (index: number, list?: any[], readOnly?: boolean) => {
    setLastPage(page)
    setPage('thumbnails')
    setImageList(list ? list : [])
    setReadOnly(readOnly || false)
    setImageSelected(index)
  }

  const onChange = (val: any) => {
    dispatch(setCurrentActivity(val))
  }

  const onImageChange = (list: any[]) => {
    currentActivity.Images = list
    dispatch(setCurrentActivity({ ...currentActivity }))
    setImageList(list ? list : [])
  }

  const onDocChange = (list: any[]) => {
    currentActivity.Documents = list
    dispatch(setCurrentActivity({ ...currentActivity }))
    setDocumentList(list ? list : [])
  }

  const historyModal = (
    <HistoryModal
      isShow={showHistoryModal}
      onCloseModal={() => setShowHistoryModal(false)}
      content={
        <HistoryTable
          items={historyData}
          fetchHistoryError={historyEvent.error}
          onImageOpen={onImageOpen}
          onDocumentOpen={onDocumentOpen}
        />
      }
    />
  )

  switch (page) {
    case 'images':
      return (
        <ImageDisplay
          onClose={onPageClose}
          readOnly={readOnly}
          images={imageList}
          onThumbnailOpen={onThumbnailOpen}
          onChange={onImageChange}
          numberOfImage={50}
          breadcrumb={
            <Breadcrumbs
              activity={originalActivity}
              name="รูปภาพ"
              onClose={onPageClose}
            />
          }
        />
      )
    case 'documents':
      return (
        <DocumentDisplay
          onClose={onPageClose}
          readOnly={readOnly}
          documents={documentList}
          onChange={onDocChange}
          numberOfFile={50}
          breadcrumb={
            <Breadcrumbs
              activity={originalActivity}
              name="เอกสาร"
              onClose={onPageClose}
            />
          }
        />
      )
    case 'thumbnails':
      return (
        <ThumbnailDisplay
          onClose={onPageClose}
          readOnly={readOnly}
          images={imageList}
          index={imageSelected}
          onChange={onImageChange}
        />
      )
    default:
      return (
        <>
          <AlertError
            text={updateEvent.SubmitError}
            isShow={updateEvent.SubmitError && enable ? true : false}
          />
          <AlertSuccess
            isShow={updateEvent.SubmitSuccess && enable ? true : false}
            text={updateEvent.MessageSuccess}
            callback={() => gotoDashboard()}
          />
          <LoadingView
            isShow={
              activityEvent.IsLoading ||
              updateEvent.IsLoading ||
              wbsEvent.IsLoading
            }
          />
          <ActivityDetail
            onImageOpen={onImageOpen}
            onDocumentOpen={onDocumentOpen}
            onThumbnailOpen={onThumbnailOpen}
            checkListData={CheckList}
            originalData={originalActivity}
            currentData={currentActivity}
            onHistoryOpen={() => setShowHistoryModal(true)}
            onUpdateCurrent={onChange}
            onApproveTask={onAcceptTask}
            onRejectTask={remark => onRejectTask(remark)}
            isLoading={updateEvent.IsLoading}
            wbs={wbs}
          />
          {historyModal}
        </>
      )
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    originalActivity: selectOriginalActivity(state),
    currentActivity: selectCurrentActivity(state),
    CheckList: selectCheckList(state),
    UserEmail: userEmailSelector(state),
    Token: serviceTokenSelector(state),
    ProjectCode: selectProjectCode(state),
    wbsPathMap: getWbsPathMapSelector(state),
    historyData: selectData(state),
    historyEvent: selectEvent(state),
    activityEvent: selectEventActivity(state),
    wbs: getWbsMapParentsSelector(state),
    uploadEvent: selectUploadEvent(state),
    updateEvent: updateSelectEvent(state),
    wbsEvent: getEvent(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {
  setCurrentActivity,
}

export default connect(mapStateToProps, mapDispatchToProps)(MainConActivity)
