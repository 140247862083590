import React, { useEffect } from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import TopNav from 'Components/Shared/TopNav'
import TokenExpired from 'Components/Pages/TokenExpired'

import AuditPlanDashboard from 'Components/QC/Dashboard/SiteVisit/AuditPlan'
import AuditPlanReport from 'Components/QC/AuditPlan/Report'

type Props = {}

const QcAuditPlanApp: React.FunctionComponent<Props> = props => {
  return (
    <>
      <TopNav title="QC" displayProject={false} secondaryTitle="Site visit" />
      <Switch>
        <Route exact path="/audit-plan" component={AuditPlanDashboard} />
        <Route
          exact
          path="/audit-plan/report/:project/:requestID/:checkNumber"
          component={AuditPlanReport}
        />

        <Route exact path="/token-expired" component={TokenExpired} />
      </Switch>
    </>
  )
}

export default QcAuditPlanApp
