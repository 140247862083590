import React from 'react'
import SideMenu from '../Shared/SideMenu'

const AdminMenu = (props: any) => {
  const { selected, children } = props
  return (
    <SideMenu selected={selected} list={menu}>
      {children}
    </SideMenu>
  )
}

const menu: any = [
  {
    name: 'Project',
    list: [
      {
        name: 'รายการ Primavera',
        key: 'primavera',
        link: `/primavera`,
      },
      {
        name: 'โมเดล',
        key: 'model',
        link: `/model`,
      },
      {
        name: 'Checklist Maincon',
        key: 'local-checklist',
        link: `/local-checklist`,
      },
      {
        name: 'บ้าน/ห้องตัวอย่าง',
        key: 'model-house',
        link: '/model-house',
      },
    ],
  },
]

export default AdminMenu
