import React from 'react'
import styled from 'styled-components'

type Props = {
  title: string
  value: string
}

export const TaskInfoWaring: React.FunctionComponent<Props> = ({
  title,
  value,
}) => {
  return (
    <HeaderScore>
      <TextBold>{title}</TextBold>
      <Value>{value || '-'}</Value>
    </HeaderScore>
  )
}

const HeaderScore = styled.div`
  width: 100%;
  text-align: left;
  margin-bottom: 6px;
`

const TextBold = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
`

const Value = styled.div`
  width: 100%;
  font-size: 18px
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  color: rgb(208, 73, 76);
  font-weight: bold;
`

export default TaskInfoWaring
