import React from 'react'
import AdminProject from '../Project'
import { RouteComponentProps } from 'react-router-dom'

type Props = RouteComponentProps

const Administrator: React.FC<Props> = props => {
  return <AdminProject {...props} homepage={true} />
}

export default Administrator
