import React, { useRef } from 'react'
import { Col, Layout, Row } from 'antd'
import styled from 'styled-components'
import {
  ReportHeader,
  ReportLogo,
  DescItem,
  Signature,
} from 'Components/AdminQC/Report/Components'
import { DataDetail } from 'features/adminReport/auditPlan/types'
import DefectItem from './DefectItem'
import { toDateText, toMomentDateTime } from 'utils/datetime'

const { Content } = Layout

type Props = {
  data: DataDetail
}

const ReportDetail: React.ComponentType<Props> = props => {
  const componentPrint = useRef(null)
  const { data } = props
  const renderDescItem = (title: string, value: any) => {
    return <DescItem title={title} value={value} percentW={25} />
  }

  const DescContent = (
    <DesBox>
      <Row type="flex">
        {renderDescItem('โครงการ', `${data.ProjectCode}-${data.ProjectNameTH}`)}
        {renderDescItem('ตรวจครั้งที่', data.CheckNumber)}
        {renderDescItem(
          'วันที่ตรวจ',
          toDateText(toMomentDateTime(data.InspectDate))
        )}
        {renderDescItem('ผู้ตรวจสอบ', data.CheckedBy)}
      </Row>
    </DesBox>
  )

  const renderDefectItem = () => {
    return (
      data &&
      data.DefectList &&
      data.DefectList.map((item, index) => {
        return <DefectItem defect={item} key={index} index={index} />
      })
    )
  }

  const DefectItemContent = renderDefectItem()

  return (
    <Content className="mid-content">
      <div className="main-content activity-detail">
        <ReportHeader
          title="Site visit - เปรียบเทียบแบบจัดสรร"
          componentPrint={componentPrint}
        />
        <Box ref={componentPrint}>
          <ReportLogo title="Site visit - เปรียบเทียบแบบจัดสรร" />
          {DescContent}
          <ContentBox>{DefectItemContent}</ContentBox>
          <Signature data={data.Signature} />
        </Box>
      </div>
    </Content>
  )
}

const Title: any = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 28px;
  color: #000;
`

const TitleSection = styled(Title)`
  font-size: 24px;
`

const Box = styled.div`
  background-color: rgba(0, 49, 104, 0.05);
  border-radius: 8px;
  border: solid 1px #e4e7eb;
  color: #000;

  @media print {
    background-color: white;
    border: solid 0px #e4e7eb;
  }
`

const DesBox = styled.div`
  padding: 12px 24px;
  background-color: #fff;
  page-break-before: alway;
`

const ContentBox = styled.div`
  padding: 24px;
`

export default ReportDetail
