import { put, take, takeEvery, select, call } from 'redux-saga/effects'
import { getType } from 'typesafe-actions'
import * as actions from './actions'
import APIManager, { configWithToken } from 'services/APIManager'
import { APIPath } from 'services/APIPath'
import { Event } from 'features/types/event'

const delay = (time: any) => new Promise(resolve => setTimeout(resolve, time))

function* sendMultiReport(action: any) {
  const { Token, List } = action.payload

  let unit = ''
  const event: Event = {
    IsLoading: false,
    MessageSuccess: undefined,
    SubmitError: undefined,
    SubmitSuccess: false,
    SubmitType: undefined,
  }
  const progress: any = {
    total: List.length,
    success: 0,
    show: true,
  }
  yield put(actions.setProgress(progress))
  yield put(actions.setEvent(event))
  let params: any = undefined

  try {
    for (let index = 0; index < List.length; index++) {
      params = List[index]
      unit = params.Unit
      yield call(
        APIManager.call,
        APIPath.SendConstructRoadToQC,
        'POST',
        params,
        configWithToken(`Bearer ${Token}`)
      )

      progress.success++
      yield put(actions.setProgress(progress))
    }

    yield call(delay, 2000)
    event.IsLoading = false
    event.SubmitError = undefined
    event.SubmitSuccess = true
    event.MessageSuccess = 'ทำรายการสำเร็จ'
    progress.show = false
  } catch (e) {
    progress.show = false
    event.SubmitError = `เกิดข่้อผิดพลาด ไม่สามารถส่งรายงาน ${unit} ได้`
  }
  yield put(actions.setProgress(progress))
  yield put(actions.setEvent(event))
}

function* allSaga() {
  yield takeEvery(getType(actions.sendMultiReport), sendMultiReport)
}

export default allSaga
