import React from 'react'
import { Alert, Row } from 'antd'

type MessageBoxProps = {
  message: string
  hide?: boolean
  type?: 'success' | 'info' | 'warning' | 'error' | undefined
}

type Props = MessageBoxProps

const MessageBox: React.FunctionComponent<Props> = ({
  message,
  type = 'error',
  hide = false,
}) => {
  if (hide) {
    return null
  }

  return (
    <Row type="flex">
      <Alert
        message={message}
        type={type}
        style={{
          padding: '8px 15px',
          width: '100%',
          fontSize: '20px',
          lineHeight: '30px',
        }}
      />
    </Row>
  )
}

export default MessageBox
