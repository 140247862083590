import React from 'react'
import { connect } from 'react-redux'
import { RootState } from 'typings/RootState'
import { Row, Col } from 'antd'
import styled from 'styled-components'
import { translate } from 'i18n'
import TKeys from 'i18n/translationKey'
import imgPhoto from 'assets/img/ic-photo.png'
import imgDocument from 'assets/img/ic-document.png'
import imgFail from 'assets/img/ic-fail.png'
import imgCheck from 'assets/img/ic-check.png'
import { HistoryActivity } from 'features/maincon/history/types'
import { toDateText, toTimeText, toMomentDateTime } from 'utils/datetime'
import { Action, UserRole, Description } from 'features/types/history'

type Props = {
  items: HistoryActivity[]
  fetchHistoryError?: string
  onImageOpen(list?: any[], readOnly?: boolean): void
  onDocumentOpen(list?: any[], readOnly?: boolean): void
} & ReduxProps &
  DispatchProps

const HistoryTable: React.FunctionComponent<Props> = props => {
  if (!!props.fetchHistoryError) {
    return (
      <Row>
        <div style={{ color: '#d0494c', fontSize: '20px' }}>
          {props.fetchHistoryError} - Cannot get activity logs.
        </div>
      </Row>
    )
  }

  const replaceProgress = (wording: string, progress: number) => {
    return wording.replace('{progress}', progress.toString())
  }

  const getDescription = (
    activity: Action,
    userRole: UserRole,
    progress: number
  ) => {
    const isMC = userRole === UserRole.MC
    const isCM = userRole === UserRole.CM
    const isPJ = userRole === UserRole.PJ
    switch (activity) {
      case Action.REQUEST:
        if (isMC) {
          return Description.REQ_MC
        }
        if (isCM) {
          return replaceProgress(Description.REQ_CM, progress)
        }
      case Action.REJECT:
        if (isCM) {
          return Description.REJT_CM
        }
        if (isPJ) {
          return replaceProgress(Description.REJT_PJ, progress)
        }

      case Action.APPROVE:
        if (isPJ) {
          return replaceProgress(Description.APPV_PJ, progress)
        }
      case Action.ACCEPT:
        if (isMC) {
          return Description.ACPT_MC
        }
        if (isCM) {
          return Description.ACPT_CM
        }
      default:
        return ''
    }
  }

  const getFile = (item: HistoryActivity) => {
    switch (item.Activity) {
      case 'REJT':
      case 'APPV':
        return ''
      default:
    }

    const imgSize = item.Images.length
    const docSize = item.Documents.length

    const imgContent = (
      <>
        <img src={imgPhoto} alt="" />
        <span>{item.Images.length}</span>
      </>
    )
    const imgDiv =
      imgSize > 0 ? (
        <LabelLinkDiv onClick={() => props.onImageOpen(item.Images, true)}>
          {imgContent}
        </LabelLinkDiv>
      ) : (
        ''
      )

    const docContent = (
      <>
        <img src={imgDocument} alt="" />
        <span>{item.Documents.length}</span>
      </>
    )
    const docDiv =
      docSize > 0 ? (
        <LabelLinkDiv
          onClick={() => props.onDocumentOpen(item.Documents, true)}
        >
          {docContent}
        </LabelLinkDiv>
      ) : (
        ''
      )
    return (
      <FileDiv>
        {imgDiv}
        {docDiv}
      </FileDiv>
    )
  }

  const getStatus = (activity: string, reject: string) => {
    let icon, color, text
    switch (activity) {
      case 'REJT':
        icon = imgFail
        color = '#d0494c'
        text = reject
        break
      case 'APPV':
        icon = imgCheck
        color = '#5bc79e'
        text = translate(TKeys.ActivityHistory.status_appv)
        break
      default:
        return <Text />
    }

    return (
      <StatusDiv>
        <p style={{ color }}>
          <img src={icon} alt="text" />
          {text}
        </p>
      </StatusDiv>
    )
  }

  const renderItem = (item: HistoryActivity, index: number) => {
    const dateTime = toMomentDateTime(item.ActionDateTime)
    return (
      <Row className="std-font-regular" style={css.row} key={index}>
        <Col span={3}>
          <MainText className="std-font-bold">{toDateText(dateTime)}</MainText>
          <Text>{toTimeText(dateTime)} น.</Text>
        </Col>

        <Col span={5}>
          <Text>
            {getDescription(item.Activity, item.UserRole, item.Progress)}
          </Text>
          {getFile(item)}
        </Col>

        <Col span={5}>
          <Text>{item.Remark}</Text>
        </Col>

        <Col span={6} style={css.col}>
          <Text>{`${item.Name} / ${item.UserRole}`}</Text>
        </Col>
        <Col span={5} style={css.col}>
          {getStatus(item.Activity, item.RejectReason)}
        </Col>
      </Row>
    )
  }

  const content = props.items.map(renderItem)

  return (
    <Row className="activity-history-modal-container">
      <Row className="modal-row">
        <Col>
          <HistoryTitle className="std-font-bold">
            {translate(TKeys.ActivityDetail.history_request)}
          </HistoryTitle>
        </Col>
      </Row>

      <Body>
        <RowHeader>
          <Row>
            <Col span={3}>
              {translate(TKeys.ActivityHistory.column_date)} /{' '}
              {translate(TKeys.ActivityHistory.column_time)}
            </Col>

            <Col span={5}>
              {translate(TKeys.ActivityHistory.column_description)}
            </Col>

            <Col span={5}>{translate(TKeys.ActivityHistory.column_remark)}</Col>

            <Col span={6} style={css.col}>
              {translate(TKeys.ActivityHistory.column_role)}
            </Col>

            <Col span={5} style={css.col}>
              {translate(TKeys.ActivityHistory.column_status)}
            </Col>
          </Row>
        </RowHeader>
        <RowContent className="modal-row">{content}</RowContent>
      </Body>
    </Row>
  )
}

const Body = styled.div`
  padding: 0px 8px;
`

const HistoryTitle = styled.h2`
  color: black;
  font-size: 28px;
  line-height: 1.07;
  margin-bottom: 25px;
`

const RowHeader = styled.div`
  color: #8b99a7;
  font-size: 18px;
  margin-bottom: 26px;
  font-family: 'DB Helvethaica X 55 Regular';
  font-size: 18px;
`

const RowContent = styled.div`
  max-height: 600px;
  border-radius: 10px;
  overflow-y: auto;
`

const MainText = styled.div`
  color: black;
  font-size: 20px;
  line-height: 1.2;
`

const Text = styled.div`
  color: black;
  font-size: 20px;
  line-height: 1.2;
`

const StatusDiv = styled.div`
  display: flex;
  overflow: auto;
  font-size: 20px;
  font-family: 'DB Helvethaica X 55 Regular';
  width: 100%;
  p {
    width: 100%;
    height: auto;
    line-height: 1.2;
  }
  p > img {
    width: 16px;
    height: 16px;
    object-fit: contain;
    float: left
    bottom: 0px;
    margin-right: 8px;
    margin-top: 6px;
  }
`

const FileDiv = styled.div`
  display: inline-block;
  font-size: 20px;
`

const CssLabel: string = `
  img {
    width: 24px;
    height: 24px;
    margin-right: 4px;
    object-fit: contain;
  }

  span {
    margin-right: 16px;
    height: 24px;
  }
`

const LabelDiv = styled.span`
  ${CssLabel}
`

const LabelLinkDiv = styled.span`
  ${CssLabel}

  :hover {
    cursor: pointer;
  }
`

const css: any = {
  row: {
    border: '0px solid #eeeff0',
    borderBottomWidth: '1px',
    paddingBottom: '16px',
    marginBottom: '16px',
  },
  col: {
    paddingLeft: '15px',
  },
}

const mapStateToProps = (state: RootState) => {
  return {}
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HistoryTable)
