/* eslint-disable */
import React, { useState, useCallback, useLayoutEffect } from 'react'
import styled from 'styled-components'
import moment from 'moment-timezone'
import { writeImage } from './utils'

const MethodType = {
  start: 'start',
  end: 'end',
  drawing: 'drawing',
}

const DrawEditor = (props) => {
  let outTime
  let isDown = false
  let line
  const {
    lineSize = 1,
    color = '#DEDEDE',
    limit = {
      left: 0,
      top: 0,
      bottom: 1000,
      right: 2000,
    },
    initial = {},
  } = props

  const [cropper, setCropper] = useState({})
  const [ctx, setCtx] = useState({})
  const [history, setHistory] = useState({ index: -1, list: [] })

  const onRefCrop = useCallback((node) => {
    if (node) {
      const ctxTmp = node.getContext('2d')
      setCtx(ctxTmp)
      setCropper(node)
      node.width = limit.right
      node.height = limit.bottom
      redraw(node, initial.lineList)
    }
  }, [])

  useLayoutEffect(() => {
    setHistory({ index: -1, list: initial.lineList || [] })
    redraw(cropper, initial.lineList)
  }, [initial])

  const redraw = (elem, lineList = []) => {
    if (elem.getContext === undefined) return

    const ctx = elem.getContext('2d')
    ctx.clearRect(0, 0, limit.right, limit.bottom)

    if (lineList.length === 0) return

    writeImage('draw', ctx, {}, lineList)
  }

  const onUpdateHistory = (list) => {
    if (props.onChange) {
      props.onChange(list)
    }
  }

  const onStartLine = (e) => {
    const offset = cropper.getBoundingClientRect()
    const x = e.pageX - offset.left
    const y = e.pageY - offset.top

    processLine({ type: MethodType.start, data: { x, y } })
  }

  const onDrawingLine = (e) => {
    if (isDown !== false) {
      const offset = cropper.getBoundingClientRect()
      const x = e.pageX - offset.left
      const y = e.pageY - offset.top

      processLine({ type: MethodType.drawing, data: { list: [{ x, y }] } })
    }
  }

  const onEndLine = (e) => {
    processLine({ type: MethodType.end, data: {} })
  }

  const onMouseEnter = (e) => {
    if (outTime === undefined) return
    if (outTime.add(1, 'seconds') < moment()) {
      onEndLine()
    }
  }

  const processLine = ({ type, data }) => {
    switch (type) {
      case MethodType.start:
        const start = data
        line = {
          start,
          list: [],
          end: {},
          setting: {
            color,
            lineSize,
          },
        }
        writeLine({ data: { start }, setting: { color, lineSize } })
        isDown = true
        break
      case MethodType.drawing:
        for (const p of data.list) {
          line.list.push(p)
        }
        writeLine({ data })
        break
      case MethodType.end:
        if (isDown === true) {
          writeLine({ data: { end: {} } })
        }
        isDown = false
        history.list.push(line)
        onUpdateHistory(history.list)
        break
      default:
    }
  }

  const onMouseOut = (e) => {
    outTime = moment()
  }

  const writeLine = ({ data, setting }) => {
    if (data.start) {
      ctx.beginPath()
      ctx.strokeStyle = setting.color
      ctx.lineWidth = setting.lineSize
      const { x, y } = data.start
      ctx.moveTo(x, y)
    }

    if (data.list && data.list.length > 0) {
      for (let point of data.list) {
        ctx.lineTo(point.x, point.y)
        ctx.stroke()
      }
    }

    if (data.end) {
      ctx.closePath()
    }
  }

  const css = {
    width: `${limit.right}px`,
    height: `${limit.bottom}px`,
  }

  return (
    <Cropper style={css}>
      <Canvas
        ref={onRefCrop}
        onMouseEnter={onMouseEnter}
        onMouseOut={onMouseOut}
        onMouseDown={onStartLine}
        onMouseMove={onDrawingLine}
        onMouseUp={onEndLine}
      />
    </Cropper>
  )
}

const Cropper = styled.div`
  z-index: 100;
  position: absolute;
  left: 0px;
  top: 0px;
  background-color: transparent;
`

export const Canvas = styled.canvas`
  cursor: crosshair;
  z-index: 100;
  background-color: transparent;
`

export default DrawEditor
/* eslint-enable */
