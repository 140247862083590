import RootState from 'typings/RootState'
import { UserProfile } from './sensitive.types'

export const serviceTokenSelector = (state: RootState): string =>
  state.sensitive.apiToken

export const serviceTokenIsSubmitSelector = (state: RootState): boolean =>
  state.sensitive.isSubmitting

export const serviceTokenErrorSelector = (state: RootState): string =>
  state.sensitive.submitError

export const userEmailSelector = (state: RootState): string =>
  state.sensitive.userEmail

export const accesstokenSelector = (state: RootState): string | undefined =>
  state.sensitive.accesstoken

export const redirectToDashboardSelector = (state: RootState): boolean =>
  state.sensitive.redirectToDashboard

export const getUserProfile = (state: RootState): UserProfile =>
  state.sensitive.userInfo

export const getUserModules = (state: RootState): string[] =>
  state.sensitive.userInfo.Modules
