import React from 'react'
import { Layout } from 'antd'
import { translate } from 'i18n'
import TKeys from 'i18n/translationKey'
import { useDispatch } from 'react-redux'
import { logoutUser } from 'features/login/login.actions'
import styled from 'styled-components'

type Props = {}

const Auth: React.FunctionComponent<Props> = () => {
  const dispatch = useDispatch()

  return (
    <Layout>
      <MenuDiv>
        <img alt="Brand logo" src="/img/img-logo.svg" />
        <span>MASTER PLAN</span>
      </MenuDiv>
      <PageDiv>
        <img src="/img/noaccess.png" alt="" />
        <div>{translate(TKeys.RequireAuth.error)}</div>
        <div>{translate(TKeys.RequireAuth.contact)}</div>

        <BtnDiv onClick={() => dispatch(logoutUser())}>
          {translate(TKeys.RequireAuth.back)}
        </BtnDiv>
      </PageDiv>
    </Layout>
  )
}

const MenuDiv = styled.div`
  background-color: #003168;
  height: 64px;

  img {
    margin-left: 80px;
    width: 72px;
    height: 64px;
  }

  span {
    color: white;
    font-size: 24px;
    margin-left: 16px;
    position: absolute;
    top: 15px;
  }
`

const PageDiv = styled.div`
  text-align: center;
  background-color: #f3f5f8;
  padding-top: 10%;
  height: 100vh;
  font-size: 20px;
  color: black;

  img {
    width: 144px;
    height: 144px;
    margin-bottom: 35px;
  }
`

const BtnDiv = styled.div`
  margin: 0 auto;
  margin-top: 56px;
  background-color: #003168;
  color: white;
  padding: 10px;
  border-radius: 5px;
  font-size: 20px;
  width: 217px;
  height: 50px;

  :hover {
    cursor: pointer;
  }
`

export default Auth
