import React, { useState } from 'react'
import styled from 'styled-components'
import { PrimaryButton } from 'Components/Shared/Button'
import { Col } from 'antd'
import { ConfirmModal } from 'Components/Defect'
import { ZoneReport } from 'features/report/zone/types'

type Props = {
  report: ZoneReport
  isLoading?: boolean
  onSendReqToQCEdit: () => void
}

const RejectFooter: React.ComponentType<Props> = props => {
  const { report, isLoading = false, onSendReqToQCEdit } = props
  const [confirmModal, setConfirmModal] = useState(false)

  const invalidData = () => {
    const item = report.DefectList.find(
      item => item.PinStatus === 'Fail' && item.ImageAfter
    )
    return item ? false : true
  }

  const onMySelfSubmit = () => {
    const showWarning = invalidData()
    if (showWarning) {
      setConfirmModal(true)
    } else {
      onSendReqToQCEdit()
    }
  }

  const { CheckedBy, CheckNumber } = report

  const nextCheckNo = CheckNumber + 1
  return (
    <>
      <Col />
      <Col>
        <Button>
          {CheckedBy === 'CM' ? (
            <PrimaryButton
              text={`เริ่มตรวจ QC ด้วยตัวเอง (${nextCheckNo})`}
              disabled={isLoading}
              onClick={onMySelfSubmit}
            />
          ) : (
            <PrimaryButton
              text={`ส่งรายงานหา QC (${nextCheckNo})`}
              disabled={isLoading}
              onClick={onSendReqToQCEdit}
            />
          )}
        </Button>
      </Col>

      <ConfirmModal
        onClose={() => setConfirmModal(false)}
        onSubmit={() => {
          setConfirmModal(false)
          onSendReqToQCEdit()
        }}
        title="ยืนยันดำเนินการ"
        desc="ท่านยังแนบรูปแก้ไขในรายการหมุดไม่ครบ"
        descSecond={`ยืนยันจะเริ่มตรวจ QC ด้วยตัวอง (${nextCheckNo})`}
        visible={confirmModal}
      />
    </>
  )
}

const Footer = styled.div`
  position: fixed;
  bottom: 0px;
  height: auto;
  width: 100%;
  background-color: white;
  padding: 22px 80px;
  z-index: 20;
`

const Button = styled.div`
  width: 200px;
  margin-left: 16px;
  float: left;
`

export default RejectFooter
