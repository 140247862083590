import React, { useState, useCallback } from 'react'
import RootState from 'typings/RootState'
import { connect, useDispatch } from 'react-redux'
import { Row, Col, Layout } from 'antd'
import styled from 'styled-components'
import TextArea from 'antd/lib/input/TextArea'
import EventModal from 'Components/Shared/Modal/EventModal'
import { DefectStatus } from 'features/types/status'

import {
  selectReport,
  selectDefect,
  selectCheckListQC,
  selectEvent,
} from 'features/report/constructRoad/selector'

import {
  submitDefectReq,
  updateDefect,
  resetEvent,
} from 'features/report/constructRoad/actions'

import {
  userEmailSelector,
  serviceTokenSelector,
} from 'features/sensitive/sensitive.selector'

import { selectUploadEvent } from 'features/uploadfile/selector'
import { selectUnitList } from 'features/masterData/selector'

import { UnitItem } from 'features/masterData/types'

import {
  DefectItem,
  CheckListForQC,
  DefectTypeReq,
  SendDefectReq,
} from 'features/report/constructRoad/types'

import {
  WorkCheckList,
  JobCheckList,
  SubJobCheckList,
  DetailCheckList,
  NcrCheckList,
  StatusCheckList,
} from 'Components/Defect/CheckList'

import DefectFooter from './DefectFooter'
import DefectImage from '../DefectImage'

const { Content } = Layout

type Props = {
  ProjectCode: string
  RequestID: number
  onClose?: () => void
  onCompeted?: () => void
  addMode?: boolean
} & ReduxProps

const DefectDetail: React.FunctionComponent<Props> = props => {
  const [enableModal, setEnableModal] = useState(false)

  const noFunc = () => {}
  const dispatch = useDispatch()
  const {
    onClose = noFunc,
    onCompeted = noFunc,
    ProjectCode,
    RequestID,

    Token,
    UserEmail,

    report,
    defect,
    checkList,

    addMode = true,

    event,
    uploadEvent,
  } = props

  const { CheckNumber } = report

  const closeEnableModal = () => {
    setEnableModal(false)
    dispatch(resetEvent())
  }
  const onSaveCompleted = () => {
    closeEnableModal()
    onCompeted()
  }

  const title: string = addMode ? 'เพิ่ม Defect' : 'แก้ไข Defect'
  const onChange = (val: DefectItem) => {
    const nVal = calcScore(val)
    dispatch(updateDefect({ ...nVal }))
  }

  const onSave = () => {
    setEnableModal(true)
    const req: SendDefectReq = {
      ProjectCode,
      UserEmail,
      Token,
      RequestID,

      Report: report,
      Defect: defect,
    }
    dispatch(submitDefectReq(req))
  }

  const updateWork = (value: number) => {
    if (value !== defect.WorkTypeID) {
      defect.WorkTypeID = value
      defect.JobTypeID = 0
      defect.SubJobTypeID = 0
      defect.DetailID = 0

      onChange(defect)
    }
  }

  const updateJob = (value: number) => {
    if (value !== defect.JobTypeID) {
      defect.JobTypeID = value
      defect.SubJobTypeID = 0
      defect.DetailID = 0
      onChange(defect)
    }
  }

  const updateSubJob = (value: number) => {
    if (value !== defect.SubJobTypeID) {
      defect.SubJobTypeID = value
      defect.DetailID = 0
      onChange(defect)
    }
  }

  const updateDetail = (value: number) => {
    defect.DetailID = value
    onChange(defect)
  }

  const updateNcr = (value: number) => {
    defect.DefectTypeID = value
    defect.IsNCR = false
    const index = checkList.DefectTypeList.findIndex(
      item => item.DefectTypeID === value
    )
    if (index > -1) {
      const defectType = checkList.DefectTypeList[index]
      defect.IsNCR = defectType.IsNCR
    }
    onChange(defect)
  }

  const updateStatus = (value: string) => {
    defect.PinStatus = value
    if (value === DefectStatus.GOODWORK) {
      updateNcr(0)
      updateDetail(0)
    }
    onChange(defect)
  }

  const updateRemark = (value: string) => {
    defect.Remark = value
    onChange(defect)
  }

  const updateImg = (value: any, name: string) => {
    switch (name) {
      case 'ImageBefore':
        defect.ImageBefore = value
        break
      case 'ImageAfter':
        defect.ImageAfter = value
        break
      default:
        break
    }

    onChange(defect)
  }

  const getJob = (workList: any[], workId: number) => {
    const work = workList.find((job: any) => job.WorkTypeID === workId)
    return (work && work.JobTypeList) || []
  }

  const getSubJob = (jobList: any[], jobId: number) => {
    const job = jobList.find((job: any) => job.JobTypeID === jobId)
    return (job && job.SubJobTypeList) || []
  }

  const noDetailID = (list: any[], id: number) => {
    const index = list.findIndex(
      (item: any) => item.DetailID === id && item.PinID !== defect.PinID
    )
    return index === -1
  }

  const getDetail = (subjobList: any[], subJobId: number) => {
    const detail =
      subjobList.find((job: any) => job.SubJobTypeID === subJobId) || {}

    return detail.DetailList || []
  }

  const workDatas = checkList.WorkTypeList
  const jobDatas = getJob(workDatas, defect.WorkTypeID)
  const subJobDatas = getSubJob(jobDatas, defect.JobTypeID)
  const detailDatas = getDetail(subJobDatas, defect.SubJobTypeID)

  const isNcrScore = (val: DefectItem) => {
    if (val.IsNCR === false || val.PinStatus !== DefectStatus.TOBEFIX)
      return false

    const index = report.LastDefectList.findIndex((item: DefectItem) => {
      return (
        item.PinStatus === DefectStatus.TOBEFIX &&
        item.PinID !== val.PinID &&
        item.IsNCR &&
        item.DetailID === val.DetailID
      )
    })

    return index > -1
  }

  const calcScore = (val: DefectItem) => {
    if (val.PinStatus === DefectStatus.TOBEFIX && val.DetailID !== 0) {
      const detail = detailDatas.find(
        (item: any) => item.DetailID === val.DetailID
      )

      if (detail) {
        val.IsNCRScore = isNcrScore(val)
        val.Score = val.IsNCRScore ? detail.NCRScore : detail.Score
      }
    }
    return val
  }

  const BottomSection = (
    <ChecklistData>
      <Row type="flex" justify="space-between">
        <Col span={12}>
          <DropDownArea>
            <WorkCheckList
              datas={workDatas}
              active={defect.WorkTypeID}
              onChange={updateWork}
            />

            <JobCheckList
              datas={jobDatas}
              active={defect.JobTypeID}
              onChange={updateJob}
              disabled={defect.WorkTypeID <= 0}
            />

            <SubJobCheckList
              datas={subJobDatas}
              active={defect.SubJobTypeID}
              onChange={updateSubJob}
              disabled={defect.JobTypeID <= 0}
            />

            <DetailCheckList
              datas={detailDatas}
              active={defect.DetailID}
              onChange={updateDetail}
              disabled={
                defect.SubJobTypeID <= 0 ||
                defect.PinStatus === DefectStatus.GOODWORK ||
                detailDatas.length === 0
              }
            />
          </DropDownArea>
        </Col>
        <Col span={12}>
          <SecordLen>
            <NcrCheckList
              datas={checkList.DefectTypeList}
              active={defect.DefectTypeID}
              onChange={updateNcr}
              disabled={defect.PinStatus === DefectStatus.GOODWORK}
            />

            <RemarkArea>
              <Header>หมายเหตุ</Header>
              <InputRemark>
                <TextArea
                  value={defect.Remark}
                  onChange={evt => updateRemark(evt.target.value)}
                  placeholder=""
                  maxLength={255}
                  style={{ height: '85px' }}
                />
              </InputRemark>
            </RemarkArea>

            <StatusCheckList
              active={defect.PinStatus}
              onChange={updateStatus}
              label="สถานะ"
            />
          </SecordLen>
        </Col>
      </Row>
    </ChecklistData>
  )

  return (
    <>
      <EventModal
        event={[event]}
        err={[uploadEvent]}
        enable={enableModal}
        successCalback={onSaveCompleted}
        errorCalback={closeEnableModal}
      />
      <Content className="mid-content">
        <div className="main-content activity-detail">
          <Title>{title}</Title>
          <DefectImage defect={defect} onImage={updateImg} />

          {BottomSection}
        </div>
      </Content>
      <DefectFooter
        defect={defect}
        onDelete={onClose}
        onSave={onSave}
        addMode={addMode}
      />
    </>
  )
}

const Title = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 28px;

  span {
    font-family: 'DB Helvethaica X 55 Regular';
    font-size: 16px;
    color: white;
    padding: 1px 10px;
    border-radius: 10px;
    margin-left: 12px;
  }
`

const ChecklistData = styled.div`
  margin-top: 24px;
`

const Header = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 20px;
  line-height: 1;
`

const DropDownArea = styled.div`
  padding-right: 15px;

  .ant-dropdown-trigger {
    margin-bottom: 14px;
    line-height: 0px;
  }
`
const RemarkArea = styled.div`
  margin-bottom: 14px;
`

const SecordLen = styled.div`
  padding-left: 15px;
  .ant-dropdown-trigger {
    margin-bottom: 14px;
    line-height: 0px;
  }
`

const InputRemark = styled.div`
  margin-top: 6px;
  .ant-input,
  .ant-input:focus,
  .ant-input:hover {
    font-family: 'DB Helvethaica X 55 Regular';
    font-size: 20px;
    height: 92px;
    border: solid 1px #e4e7eb;
  }
`

const mapStateToProps = (state: RootState) => {
  return {
    Token: serviceTokenSelector(state),
    UserEmail: userEmailSelector(state),

    report: selectReport(state),
    defect: selectDefect(state),
    checkList: selectCheckListQC(state),

    unitList: selectUnitList(state),

    event: selectEvent(state),
    uploadEvent: selectUploadEvent(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DefectDetail)
