import { getType } from 'typesafe-actions'
import * as actions from './search.actions'
import { SearchAction, SearchState } from './search.types'
import { Task } from 'features/tasklane/tasklane.types'
import produce from 'immer'
import parseErrorMessage from 'utils/parseErrorMessage'
import { sortingTaskList } from 'features/activity/activity.function'

const initialState: SearchState = {
  isSubmitting: false,
  submitError: undefined,
  submitSuccess: false,
  rawResponseData: {},
  list: [],
  totalRows: 0,
  searchPaginationQuery: {
    page: 1,
    rowPerPage: 5,
    totalRows: 0,
  },
  sorting: {
    name: 'actual_end_date',
    type: 'asc',
  },
}

export default function(
  state: SearchState = initialState,
  action: SearchAction
): SearchState {
  return produce(state, draft => {
    let list
    switch (action.type) {
      case getType(actions.sampleSearchAction):
        return {
          ...state,
        }
      case getType(actions.searchByKeywordAsyncRequest.request):
        draft.isSubmitting = true
        draft.submitError = ''
        break
      case getType(actions.searchByKeywordAsyncRequest.success):
        draft.isSubmitting = false
        draft.submitSuccess = true
        draft.rawResponseData = action.payload
        const responseData = action.payload
        draft.totalRows =
          (responseData.ResponseData &&
            responseData.ResponseData.length &&
            responseData.ResponseData[0].TotalRows) ||
          0
        draft.searchPaginationQuery.totalRows = draft.totalRows
        break
      case getType(actions.searchByKeywordAsyncRequest.failure):
        draft.isSubmitting = false
        draft.submitSuccess = false
        draft.totalRows = 0
        draft.submitError = parseErrorMessage(action.payload)
        break
      case getType(actions.updateSearchTask):
        const taskList = sortingTaskList(
          action.payload,
          draft.sorting.type,
          draft.sorting.name
        )
        draft.list = taskList
        break
      case getType(actions.updateSearchPaginationQuery):
        draft.searchPaginationQuery = action.payload
        break
      case getType(actions.sortingActivity):
        const sorting = state.sorting
        const name = action.payload
        if (sorting.name === name) {
          draft.sorting.type = sorting.type === 'asc' ? 'desc' : 'asc'
        } else {
          draft.sorting.name = name
          draft.sorting.type = 'asc'
        }

        list = sortingTaskList(
          state.list,
          draft.sorting.type,
          draft.sorting.name
        )

        draft.list = [...list]
        break
      case getType(actions.updateWBS):
        const wbsPathMap: { [key in number]: string } = action.payload
        list = state.list

        list = list.map((item: Task) => {
          item.WbsName = wbsPathMap[item.WbsID]
          return item
        })
        list = sortingTaskList(list, draft.sorting.type, draft.sorting.name)

        draft.list = [...list]
        break
      default:
        return state
    }
  })
}
