import React, { useEffect, useRef } from 'react'
import { Input } from 'antd'
import styled from 'styled-components'

interface Props {
  prefix: string
  name?: string
  placeholder?: string
  value: string | number
  onChange: (value: string, name?: string) => void
  disabled?: boolean
  openWithFocus?: boolean
}

const InputDouble: React.FC<Props> = ({
  prefix,
  name,
  placeholder = '',
  value,
  onChange,
  disabled = false,
  openWithFocus,
}) => {
  const inputRef = useRef<Input>(null)

  useEffect(() => {
    if (openWithFocus && inputRef.current) {
      inputRef.current.focus()
    }
  }, [openWithFocus])

  const onEdit = (evt: any) => {
    let val = evt.target.value
    if (val === '') val = '0'
    if (!val.match(/^-?\d+\.?\d*$/)) return
    if (val.length === 2 && val.charAt(0) === '0' && val.charAt(1) !== '.') {
      val = val.slice(1)
    }

    onChange(val, name)
  }

  return (
    <InputTitle>
      <Input
        ref={inputRef}
        prefix={prefix}
        className="input-title"
        placeholder={placeholder}
        value={`${value}`}
        onChange={onEdit}
        disabled={disabled}
      />
    </InputTitle>
  )
}

const InputTitle = styled.div`
  .input-title {
    text-align: right;
    height: 48px;
    border-radius: 5px;
    border: 1px solid #e4e7eb;
    background-color: #ffffff;
    font-family: 'DB Helvethaica X 55 Regular';
    font-size: 18px;
    .ant-input-prefix {
      font-size: 20px;
      padding-left: 6px;
      color: #000;
    }

    .ant-input {
      font-size: 20px;
      font-family: 'DB Helvethaica X 55 Regular';
      padding-right: 16px;
      border: none;
      color: #8b99a7;
    }

    .ant-input:placeholder-shown {
      font-family: 'DB Helvethaica X 55 Regular';
    }
  }

  .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
    border-color: #e4e7eb;
  }
`
export default InputDouble
