import React, { useEffect, useState } from 'react'
import { Row, Col } from 'antd'
import styled from 'styled-components'
import { PrimaryButton, SecondaryButton } from 'Components/Shared/Button'
import ReactToPrint from 'react-to-print'
import moment from 'moment'
import { setTimeout } from 'timers'
import MessageBox from 'Components/messages/MessageBox'

type Props = {
  title: string
  componentPrint?: any
  showExport?: boolean
  showSave?: boolean
  onSave?: () => void
}

const ReportHeader: React.ComponentType<Props> = props => {
  const {
    title = '',
    componentPrint,
    showExport = false,
    showSave = false,
    onSave = () => {},
  } = props
  const [isShow, setIsShow] = useState(false)
  const date = moment().format('YYYY-MM-DD HH:mm')

  useEffect(() => {
    setTimeout(() => {
      setIsShow(true)
    }, 30000)
  }, [])

  return (
    <>
      {!isShow && (
        <WarningMsgBox>
          <MessageBox
            type="warning"
            message="กำลังโหลดรูปภาพรายงาน อาจใช้เวลามากกว่า 30 วินาที"
          />
        </WarningMsgBox>
      )}
      <Row type="flex" justify="space-between">
        <Col>
          <Title>{title}</Title>
        </Col>
        <Col>
          {showExport && (
            <Btn>
              <SecondaryButton
                text="Export.xisx"
                disabled={true}
                onClick={() => {}}
              />
            </Btn>
          )}
          {showSave && (
            <Btn>
              <PrimaryButton text="บันทึก" onClick={onSave} />
            </Btn>
          )}
          <Btn>
            <ReactToPrint
              trigger={() => {
                return (
                  <PrimaryButton
                    text={isShow ? 'พิมพ์' : 'กำลังเตรียมข้อมูล...'}
                    disabled={!isShow}
                  />
                )
              }}
              content={() => componentPrint.current}
              documentTitle={`${title}_${date}.pdf`}
            />
          </Btn>
        </Col>
      </Row>
    </>
  )
}

const Title = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 28px;
  color: #000;
  margin-bottom: 20px;
`

const Btn = styled.div`
  width: 160px;
  float: left;
  margin-left: 16px;
`

const WarningMsgBox = styled.div`
  position: 'absolute', left: '50%', top: '50%',
  transform: 'translate(-50%, -50%)'
`

export default ReportHeader
