import { getType } from 'typesafe-actions'
import * as actions from './actions'
import { StoreState, SeemoreAction, Data, Activity } from './types'
import produce from 'immer'
import parseErrorMessage from 'utils/parseErrorMessage'
import { sortingActivityList } from './function'
const initialState: StoreState = {
  dataResponse: null,
  data: {
    TotalRows: 0,
    DataList: [] as Data[],
  } as Activity,
  event: {
    IsLoading: false,
    MessageSuccess: '',
    SubmitError: '',
    SubmitSuccess: false,
    SubmitType: '',
  },
  sorting: {
    name: 'TaskRatio',
    type: 'desc',
  },
  pageType : 1
}

export default function(
  state: StoreState = initialState,
  action: SeemoreAction
): StoreState {
  return produce(state, draft => {
    switch (action.type) {
      case getType(actions.GetActivitySeeMoreForQCAction.request):
        draft.event.SubmitError = undefined
        draft.event.IsLoading = true
        draft.event.SubmitSuccess = false
        draft.data = {
          TotalRows: state.data.TotalRows,
          DataList: state.data.DataList,
        }
        break
      case getType(actions.GetActivitySeeMoreForQCAction.success):
        const res = action.payload.ResponseData

        let resData = [] as Data[]
        let total = 0
        if (draft.pageType === 1) {
          total = res.TotalToDo
          resData = res.ToDo || []
        } else if (draft.pageType === 2) {
          total = res.TotalInProgress
          resData = res.InProgress || []
        }

        draft.event.IsLoading = false
        const dataSort = sortingActivityList(
          resData,
          draft.sorting.type,
          draft.sorting.name
        )
        draft.data = {
          TotalRows: total,
          DataList: dataSort,
        }
        break

      case getType(actions.GetActivitySeeMoreForQCAction.failure):
        draft.event.IsLoading = false
        draft.event.SubmitError = parseErrorMessage(action.payload)
        break

      case getType(actions.sortingTask):
        const sorting = state.sorting
        const params = action.payload
        if (sorting.name === params.name) {
          draft.sorting.type = sorting.type === 'asc' ? 'desc' : 'asc'
        } else {
          draft.sorting.name = params.name
          draft.sorting.type = 'asc'
        }
        if (params.sort != null) {
          draft.sorting.type = params.sort
        }

        const list = sortingActivityList(
          draft.data.DataList,
          draft.sorting.type,
          draft.sorting.name
        )
        draft.data.DataList = list
        break

      case getType(actions.setPageType):
        const page = action.payload.pageType
        draft.pageType = page
        break
      default:
        return state
    }
  })
}
