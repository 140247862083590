import React, { useState } from 'react'
import styled from 'styled-components'
import { Collapse, Row, Col } from 'antd'
import arrowUp from 'assets/img/ic-up.png'
import arrowDown from 'assets/img/ic-down.png'
import { InspectionData } from 'features/types/checklist'
import { CheckListStatus } from 'features/cmpj/activity/types'
import RemarkBox from './RemarkBox'
import StatusIcon from './StatusIcon'

type Props = {
  index: number
  item: InspectionData
  onChange: (val: InspectionData) => void
  remarkStatus?: any[]
}

const { Panel } = Collapse

const InspectionItem: React.FC<Props> = (props: Props) => {
  const { item, remarkStatus } = props

  const arrow = (panelProps: any) => {
    return (
      <img
        src={panelProps.isActive ? arrowUp : arrowDown}
        width="16px"
        height="16px"
      />
    )
  }

  const onChangeStatus = (value: string) => {
    item.Status = value
    props.onChange(item)
  }

  const onChangeRemark = (value: string) => {
    item.Remark = value
    props.onChange(item)
  }

  const checkListContent = (
    <ChecklistView className="check-list-item">
      <Row type="flex" justify="space-between" align="middle">
        <Col span={19}>
          <TextItem>
            {item.Seq} {item.Name}
          </TextItem>
        </Col>
        <Col
          onClick={() => {
            if (item.Status === CheckListStatus.NOTCHECK) {
              onChangeStatus(CheckListStatus.PASS)
            }
          }}
        >
          <StatusIcon
            status={item.Status ? item.Status : CheckListStatus.NOTCHECK}
          />
        </Col>
      </Row>
    </ChecklistView>
  )
  return (
    <Collapse
      bordered={false}
      className="checklist"
      expandIcon={panelProps => arrow(panelProps)}
      key={props.index}
      expandIconPosition="right"
    >
      <Panel header={checkListContent} key={props.index} style={{ padding: 0 }}>
        <RemarkBox
          status={item.Status}
          remark={item.Remark}
          remarkStatus={remarkStatus}
          onChangeStatus={status => onChangeStatus(status)}
          onChangeRemark={remark => onChangeRemark(remark)}
        />
      </Panel>
    </Collapse>
  )
}

const ChecklistView = styled.div`
  padding: 16px 81px 16px 0;
  margin: 0 16px 0 24px;
  border-bottom: 1px solid #eeeff2;
  min-height: 63px;

  .ant-collapse-content-active {
    .remark {
      border-bottom: none;
      background-color: red;
    }
  }

  &.remark-box {
    padding-top: 0;
  }

  .remark-title {
    font-family: 'DB Helvethaica X 75 Bd';
  }
`

const TextItem = styled.span`
  font-size: 20px;
  color: #000;
`
export default InspectionItem
