import withAPIAction from '../../../utils/withAPIAction'
import {
  getCheckListAction,
  getCheckListByIdAction,
  deleteCheckListAction,
  createCheckListAction,
  updateCheckListAction,
  getProjectAction,
} from './actions'

import { CreateCheckListReq, UpdateCheckListReq } from './types'

// get list
export const getCheckList = (
  Page: number,
  RowPerPage: number,
  TextFilter: string = ''
) => {
  return withAPIAction(state => ({
    method: 'POST',
    path: 'GetAuditRoadCheckList',
    data: {
      UserEmail: state.sensitive.userEmail,
      Page,
      RowPerPage,
      TextFilter,
    },
    token: `Bearer ${state.sensitive.apiToken}`,
  }))(getCheckListAction)
}

// get detail
export const getCheckListById = (CheckListID: number) => {
  return withAPIAction(state => ({
    method: 'POST',
    path: 'GetAuditRoadCheckListById',
    data: { CheckListID, UserEmail: state.sensitive.userEmail },
    token: `Bearer ${state.sensitive.apiToken}`,
  }))(getCheckListByIdAction)
}

// create check list
export const createCheckList = (data: CreateCheckListReq) => {
  return withAPIAction(state => ({
    method: 'POST',
    path: 'CreateAuditRoadCheckList',
    data,
    token: `Bearer ${state.sensitive.apiToken}`,
  }))(createCheckListAction)
}

// update check list
export const updateCheckList = (data: UpdateCheckListReq) => {
  return withAPIAction(state => ({
    method: 'POST',
    path: 'UpdateAuditRoadCheckList',
    data,
    token: `Bearer ${state.sensitive.apiToken}`,
  }))(updateCheckListAction)
}

// delete check list
export const deleteCheckList = (CheckListID: number) => {
  return withAPIAction(state => ({
    method: 'POST',
    path: 'DeleteAuditRoadCheckList',
    data: { CheckListID, UserEmail: state.sensitive.userEmail },
    token: `Bearer ${state.sensitive.apiToken}`,
  }))(deleteCheckListAction)
}

export const getProjectSelector = () => {
  return withAPIAction(state => ({
    method: 'POST',
    path: 'GetProjectOnAuditRoadCheckList',
    data: { UserEmail: state.sensitive.userEmail },
    token: `Bearer ${state.sensitive.apiToken}`,
  }))(getProjectAction)
}
