import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { connect, useDispatch } from 'react-redux'
import { Layout } from 'antd'
import { RouteComponentProps } from 'react-router-dom'
import RootState from 'typings/RootState'
import {
  userEmailSelector,
  serviceTokenSelector,
  getUserProfile,
} from 'features/sensitive/sensitive.selector'
import { selectData, selectEvent } from 'features/report/function/selector'
import {
  getFunctionCheckListApi,
  saveFunctionCheckListByQCApi,
} from 'features/report/function/api'
import {
  GetFunctionChecklistReq,
  FunctionChecklist,
  SaveFunctionChecklistReq,
  CheckList,
} from 'features/report/function/types'
import { FunctionCheckList } from 'Components/Activity'
import PageFooter from './PageFooter'
import Breadcrumbs from './Breadcrumbs'
import InteriorCheckList from './InteriorCheckList'
import { UpdateData } from 'features/report/function/actions'
import { FunctionStatus, InteriorStatus } from 'features/types/status'
import { EventModal } from 'Components/Shared'
import { verifyEmptyRemark } from 'utils/verify'
import { GetUnitReportReq } from 'features/report/unit/types'
import { getUnitReport } from 'features/report/unit/api'
import {
  selectReport,
  selectEvent as reportEvent,
} from 'features/report/unit/selector'
const { Content } = Layout

type Props = ReduxProps &
  DispatchProps &
  RouteComponentProps<{
    project: string
    unit: string
    qcType: string
    checkno: string
    requestID: string
    qcTypeVersion: string
  }>

const FunctionCheck: React.ComponentType<Props> = props => {
  const {
    data,
    token,
    UserEmail,
    match,
    event,
    history,
    userProfile,
    report,
    reportEvent,
  } = props
  const [enableModal, setEnableModal] = useState(false)
  const { params } = match
  const { project, unit, checkno, requestID, qcType, qcTypeVersion } = params
  const RequestID = parseInt(requestID)
  const CheckNumber = parseInt(checkno)
  const dispatch = useDispatch()
  const curModule = userProfile.RoleName as 'QC' | 'CM/PJ' | 'Main Con'

  useEffect(() => {
    setEnableModal(false)
  }, [project, checkno, requestID, unit, dispatch])

  useEffect(() => {
    const req: GetFunctionChecklistReq = {
      UserEmail: UserEmail,
      ProjectCode: project,
      CheckNumber,
      RequestID,
      Unit: unit,
    }
    dispatch(getFunctionCheckListApi(token, req))
  }, [project, checkno, requestID, unit, dispatch])

  const onSaveFunctionChecklist = () => {
    setEnableModal(true)
    const req: SaveFunctionChecklistReq = {
      UserEmail,
      ProjectCode: project,
      Unit: unit,
      RequestID,
      QCType: qcType,
      QCTypeVersion: qcTypeVersion,
      CheckList: getDataCheckList(data.CheckList),
      CheckListID: data.CheckListID.toString(),
      CheckNumber,
      FunctionStatus: data.FunctionStatus,
      InspectionList: data.InspectionList,
    }
    dispatch(saveFunctionCheckListByQCApi(token, req))
  }

  useEffect(() => {
    const params: GetUnitReportReq = {
      UserEmail,
      ProjectCode: project,
      QCType: qcType,
      Unit: unit,
      RequestID: parseInt(requestID),
      Page: 1,
      RowPerPage: 100,
    }
    dispatch(getUnitReport(token, params))
  }, [token, project, UserEmail])

  const isReadOnly = () => {
    const state = report.State
    const status = report.Status
    let readonly = true
    const isSend = data.SendCheckStatus === 'Y'
    if (state === 'CM-QC' && status === 'INPRO') {
      if (curModule === 'CM/PJ') {
        readonly = false
      }
    }

    if (state === 'QC-QC' && status === 'INPRO' && !isSend) {
      if (curModule === 'QC') {
        readonly = false
      }
    }

    return readonly
  }

  const getDataCheckList = (CheckList: CheckList[]) => {
    return CheckList.map((item: CheckList) => {
      if (item.Status === '') {
        item.Status = InteriorStatus.None
      }
      return item
    })
  }

  const updateInteriorCheckList = (val: any) => {
    data.CheckList = val
    dispatch(UpdateData({ value: { ...data } }))
  }

  const updateFunctionCheckList = (val: FunctionChecklist) => {
    dispatch(UpdateData({ value: { ...val } }))
  }

  const updateFunctionStatus = (status: FunctionStatus) => {
    data.FunctionStatus = status
    dispatch(UpdateData({ value: { ...data } }))
  }

  const backToReport = () => {
    setEnableModal(false)
    if (curModule === 'QC') {
      history.push(`/unit/report/${project}/${unit}/${qcType}/${requestID}`)
    }

    if (curModule === 'CM/PJ') {
      history.push(`/qcreport/${unit}/${qcType}/${requestID}`)
    }
  }

  const isEmptyRemark = verifyEmptyRemark(data.InspectionList)
  const readonly = isReadOnly()

  return (
    <>
      <Breadcrumbs
        project={project}
        requestID={requestID}
        unit={unit}
        qcType={qcType}
        module={curModule}
      />
      <EventModal
        event={[event, reportEvent]}
        enable={enableModal}
        successCalback={backToReport}
      />
      {!reportEvent.IsLoading && (
        <>
          <Content className="mid-content">
            <div className="main-content activity-detail">
              <Header>
                <Title>รายงานการตรวจสอบ Function</Title>
              </Header>
              <InteriorCheckList
                data={(data && data.CheckList) || []}
                onUpdate={updateInteriorCheckList}
                readonly={readonly}
              />
              <FunctionCheckList
                checkListData={data}
                onUpdateCheckList={updateFunctionCheckList}
                readonly={readonly}
              />
            </div>
          </Content>
          <PageFooter
            status={data.FunctionStatus}
            onUpdate={updateFunctionStatus}
            onSave={onSaveFunctionChecklist}
            disabled={isEmptyRemark || event.IsLoading}
            readonly={readonly}
          />
        </>
      )}
    </>
  )
}

const Header = styled.div`
  margin-top: 32px;
  margin-bottom: 24px;
`

const Title = styled.span`
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 28px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #000;
`

const mapStateToProps = (state: RootState) => {
  return {
    data: selectData(state),
    event: selectEvent(state),
    token: serviceTokenSelector(state),
    UserEmail: userEmailSelector(state),
    userProfile: getUserProfile(state),
    report: selectReport(state),
    reportEvent: reportEvent(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FunctionCheck)
