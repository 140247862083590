import React from 'react'
import imgFill from 'assets/img/ic-unit-fill.png'
import imgLine from 'assets/img/ic-unit.png'
import imgFillCM from 'assets/img/ic-unit-fill-cm.png'
import imgLineCM from 'assets/img/ic-unit-cm.png'
import BaseIcon from './BaseIcon'

type Props = {
  active?: boolean
  isCM?: boolean
}

const UnitIcon = (props: Props) => {
  const { active = false, isCM = false } = props
  const activeIcon = isCM ? imgFillCM : imgFill
  const inactiveIcon = isCM ? imgLineCM : imgLine
  return (
    <BaseIcon
      active={active}
      activeIcon={activeIcon}
      inactiveIcon={inactiveIcon}
    />
  )
}

export default UnitIcon
