import React from 'react'
import { connect } from 'react-redux'
import { RootState } from 'typings/RootState'
import { Row, Col } from 'antd'
import CardListRow from '../CardList/CardListRow'
import { Task } from 'features/tasklane/tasklane.types'
import { Link } from 'react-router-dom'

interface CardListProps {
  type: string
  page: number
  totalRows: number
  items: Task[]
}

type Props = ReduxProps & DispatchProps & CardListProps

const CardList: React.FunctionComponent<Props> = ({
  type,
  page,
  totalRows,
  items,
}) => {
  return (
    <Row className="search-reults">
      <Col span={24}>
        {items.map((item: Task) => {
          return (
            <Link
              key={`${item.ActivityID}-${Math.random()}`}
              to={{ pathname: `/activity-detail/${item.ActivityID}` }}
            >
              <CardListRow
                type={type}
                activityTitle={item.ActivityName}
                actualStartDate={item.BaselineStartDate}
                actualEndDate={item.BaselineFinishDate}
                progress={item.Progress}
                status={item.TaskStatus}
                task={item}
              />
            </Link>
          )
        })}
      </Col>
    </Row>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    keyword: '',
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(CardList)
