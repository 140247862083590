import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Row, Col, Button } from 'antd'

import { DetailType } from 'features/checklist/auditRoad/types'
import ContentBox from 'Components/Shared/Card/ContentBox'
import { RadioBox, DropdownList } from 'Components/Shared/Form'

type Props = {
  item?: DetailType
  onSave: () => void
  disabled: boolean
}

type PropsItem = {
  title: string
  isCheck: boolean
  onChange: (value: boolean) => void
  disabled: boolean
}

const ConfigItemComponent = ({
  title,
  isCheck,
  onChange,
  disabled,
}: PropsItem) => {
  return (
    <ConfigItem>
      <Row type="flex" justify="space-between">
        <Col> {title} </Col>
        <Row type="flex" justify="space-around" style={{ width: 150 }}>
          <RadioBox
            label={'ไม่'}
            value={!isCheck}
            isCheck={!isCheck}
            onClick={onChange}
            readOnly={disabled}
          />
          <RadioBox
            label={'ใช่'}
            value={!isCheck}
            isCheck={isCheck}
            onClick={onChange}
            readOnly={disabled}
          />
        </Row>
      </Row>
    </ConfigItem>
  )
}

const DetailConfigBox: React.ComponentType<Props> = ({
  item,
  onSave,
  disabled,
}) => {
  const [isDefect, setisDefect] = useState<boolean>(false)
  const [isSupplier, setisSupplier] = useState<boolean>(false)
  const [methodType, setMethodType] = useState<number>(1)

  useEffect(() => {
    if (item) {
      setisDefect(item.IsRequired)
      setisSupplier(item.IsSupplier)
      setMethodType(item.MethodTypeID)
    }
  }, [item])

  const updateDefect = (value: boolean) => {
    setisDefect(value)
    if (item) {
      item.IsRequired = value
    }
  }

  const updateSupplier = (value: boolean) => {
    setisSupplier(value)
    if (item) {
      item.IsSupplier = value
    }
  }

  const updateMethod = (val: any) => {
    setMethodType(val)
    if (item) {
      item.MethodTypeID = val
    }
  }

  return (
    <>
      <ContentBox
        title="ตั้งค่ารายละเอียด"
        isEmpty={item == null}
        hasSearch={false}
      >
        <ListSection>
          <ConfigItemComponent
            title="เป็น Major Defect หรือไม่"
            isCheck={isDefect}
            onChange={updateDefect}
            disabled={disabled}
          />
          <ConfigItemComponent
            title="เป็น Supplier หรือไม่"
            isCheck={isSupplier}
            onChange={updateSupplier}
            disabled={disabled}
          />

          <div className="dropdown-item">
            <DropdownList
              label="วิธีการวัด"
              value={methodType}
              menu={methodList}
              disabled={disabled}
              onChange={updateMethod}
            />
          </div>

          {!disabled && (
            <ButtonSection>
              <Button onClick={onSave}>บันทึก</Button>
            </ButtonSection>
          )}
        </ListSection>
      </ContentBox>
    </>
  )
}

const methodList = [
  { name: 'ตารางเมตร (กว้าง X ยาว)', value: 1 },
  { name: 'ความยาว (เมตร)', value: 2 },
  { name: 'จำนวนจุด', value: 3 },
]

const ListSection = styled.div`
  margin-top: 5px;
  overflow: auto;
  padding: 0 24px;
`

const ButtonSection = styled.div`
  margin-top: 25px;
  button {
    width: 160px;
    height: 50px;
    font-family: 'DB Helvethaica X 75 Bd';
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #003168;
    border: 1.5px solid #003168;
    border-radius: 5px;
  }
`

const ConfigItem = styled.div`
  font-family: 'DB Helvethaica X 55 Regular';
  font-size: 20px;
  padding-bottom: 10px;
`

const DataItem = styled.div`
  padding: 5px 10px;

  &:hover {
    cursor: pointer;
    color: white;
    background-color: #003168;
  }
`

export default DetailConfigBox
