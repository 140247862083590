import React from 'react'
import styled from 'styled-components'
type Props = {
  heading: string
}

export const ColumnHeader: React.FunctionComponent<Props> = ({ heading }) => {
  return (
    <Header>
      <span>{heading}</span>
    </Header>
  )
}

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 20px;
  color: #000;
  padding-bottom: 16px;
`

const ActionIcon = styled.span`
  font-size: 18px !important;
  color: #8b99a7 !important;
  font-family: 'DB Helvethaica X 55 Regular';
  cursor: pointer;
`

const Icon = styled.span`
  margin-left: 8px;
`

export default ColumnHeader
