import React, { useState } from 'react'
import { Row, Col } from 'antd'
import styled from 'styled-components'

import ThumbnailDisplay from 'Components/Shared/ThumbnailDisplay'

import ImgPicker from './ImgPicker'

type Props = {
  planImg: any
  onAddBeforeImage?: (image: any) => void
  onAddAfterImage?: (image: any) => void
  viewMode?: boolean
  defect: any
  canUpAfterImg?: boolean
  floor?: number
  withBorder?: boolean
}

const DefectImage: React.FunctionComponent<Props> = props => {
  const {
    planImg,
    onAddBeforeImage = (image: any) => {},
    onAddAfterImage = (image: any) => {},
    viewMode = false,
    defect,
    canUpAfterImg = true,
    floor,
    withBorder = true,
  } = props
  const afterImg: any = defect.ImageAfter
  const beforeImg: any = defect.ImageBefore
  const [previewImg, setPreviewImg] = useState(false)
  const [previewType, setPreviewType] = useState('' as
    | 'before'
    | 'after'
    | 'plan')
  const [currentImg, setCurrentImg] = useState([] as any)
  const afterImgViewMode = viewMode && !canUpAfterImg

  const isAfterImg = previewType === 'after'
  const readonly = isAfterImg ? afterImgViewMode : viewMode
  const thumnailPreview = (
    <ThumbnailDisplay
      onClose={() => setPreviewImg(false)}
      readOnly={readonly || previewType === 'plan'}
      images={[currentImg]}
      index={0}
      onChange={images => {
        const newImage = images[0] || undefined
        if (isAfterImg) {
          onAddAfterImage(newImage)
        } else {
          onAddBeforeImage(newImage)
        }
      }}
    />
  )

  const imagesData = (
    <>
      <Row type="flex" justify="space-around">
        <Col span={8}>
          <Box>
            <Row type="flex" justify="space-between">
              <Col>
                <Header>Floorplan</Header>
              </Col>
              {floor && (
                <Col>
                  <Desc>ชั้น {floor}</Desc>
                </Col>
              )}
            </Row>
            <FloorPlamImg
              src={planImg}
              onClick={() => {
                setPreviewImg(true)
                setPreviewType('plan')
                setCurrentImg({
                  FileID: 1,
                  FileURL: planImg,
                })
              }}
            />
          </Box>
        </Col>
        <Col span={8}>
          <ImgPicker
            title="Before"
            onChange={onAddBeforeImage}
            Image={beforeImg}
            viewMode={viewMode}
            onThumbnailOpen={images => {
              if (images) {
                setPreviewImg(true)
                setPreviewType('before')
                setCurrentImg(images)
              }
            }}
          />
        </Col>
        <Col span={8}>
          <ImgPicker
            title="After"
            onChange={onAddAfterImage}
            Image={afterImg}
            viewMode={afterImgViewMode}
            onThumbnailOpen={images => {
              if (images) {
                setPreviewImg(true)
                setPreviewType('after')
                setCurrentImg(images)
              }
            }}
          />
        </Col>
      </Row>
      {previewImg && thumnailPreview}
    </>
  )

  return withBorder ? <ImageData>{imagesData}</ImageData> : imagesData
}

const ImageData = styled.div`
  border-radius: 5px;
  border: solid 1px #eeeff2;
  background-color: white;
  margin-top: 24px;
  padding: 5px;
`
const Box = styled.div`
  padding: 15px;
  width: 100%;
`
const Header = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 20px;
  margin-bottom: 10px;
`

const FloorPlamImg = styled.img`
  border-radius: 5px;
  border: solid 1px #e4e7eb;
  height: 240px;
  width: 100%;
`

const Desc = styled.div`
  font-family: 'DB Helvethaica X 55 Regular';
  font-size: 20px;
`

export default DefectImage
