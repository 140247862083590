import React, { useState, useRef } from 'react'
import styled from 'styled-components'

import { JobType, SubJobType } from 'features/checklist/unit/types'

import ContentBox from 'Components/Shared/Card/ContentBox'
import { AddList, EditDeleteButton } from 'Components/Shared/Button'
import DeleteCheckListJobModal, {
  DeleteWithCheckHandles,
} from 'Components/Shared/Modal/DeleteWithCheckModal'
import { Row } from 'antd'
import { RowSelectedItem } from 'Components/AdminQC/Components'

interface Props {
  item?: JobType
  selected: number
  onSelected: (index: number) => void
  showModal: (tab: number) => void
  onDelete: (it: SubJobType) => void
  openModalEdit: (obj: SubJobType, index: number, tab: number) => void
  disabled: boolean
}

const SubworkBox: React.ComponentType<Props> = ({
  item,
  selected,
  onSelected: onSelectedProps,
  showModal,
  onDelete,
  openModalEdit,
  disabled,
}) => {
  const modalRef = useRef<DeleteWithCheckHandles>(null)

  function onSelected(it: SubJobType) {
    if (item) {
      const resultIndex = item.SubJobTypeList.findIndex(
        v => v.SubJobTypeName === it.SubJobTypeName
      )

      onSelectedProps(resultIndex)
    }
  }

  const showDeleteModal = (it: SubJobType) => {
    if (modalRef.current) {
      const menu = [
        `รายการภายใต้ "${it.SubJobTypeName}" จะถูกลบ`,
        `รายละเอียด (${it.DetailList.length} รายการ) จะถูกลบ`,
      ]
      const checks = [false, false]
      const menuTitle = `หมวดงาน: ${it.SubJobTypeName}`
      modalRef.current.showModal(it, menu, menuTitle, checks)
    }
  }

  const showEditeModal = (it: SubJobType) => {
    if (item) {
      const resultIndex = item.SubJobTypeList.findIndex(
        v => v.SubJobTypeName === it.SubJobTypeName
      )
      openModalEdit(it, resultIndex, 2)
    }
  }

  let content: JSX.Element[] = []
  if (item) {
    content = item.SubJobTypeList.map((it: SubJobType, index) => {
      return (
        <RowSelectedItem
          key={index}
          title={it.SubJobTypeName}
          right={
            <EditDeleteButton
              showEditeModal={() => showEditeModal(it)}
              showDeleteModal={() => showDeleteModal(it)}
              disableDelete={disabled}
            />
          }
          onClick={() => onSelected(it)}
          isSelected={index === selected}
        />
      )
    })
  }

  return (
    <>
      <ContentBox
        title="ลักษณะงานรอง"
        isEmpty={item == null}
        emptyText="กรุณาเลือก “ลักษณะงานรอง”"
      >
        <ListSection>
          {content}
          {!disabled && <AddList onClick={() => showModal(2)} />}
        </ListSection>
      </ContentBox>
      <DeleteCheckListJobModal ref={modalRef} onOk={onDelete} />
    </>
  )
}

const ListSection = styled.div`
  margin-top: 16px;
  height: 256px;
  overflow: auto;
  padding: 0 8px;
  padding-bottom: 30px;
`

export default SubworkBox
