import RootState from 'typings/RootState'
import { createSelector } from 'reselect'
import { PMRList, PMRReport, DefectItem, Filter } from './types'
import { Event } from 'features/types/event'

export const selectList = (state: RootState): PMRList => state.pmr.pmrlist

export const selectReport = (state: RootState): PMRReport => state.pmr.report

export const selectDefect = (state: RootState): DefectItem => state.pmr.defect

export const selectEvent = (state: RootState): Event => state.pmr.event

export const selectSorting = (state: RootState) => state.pmr.sorting

export const selectSortingDefect = (state: RootState) => state.pmr.defectSorting

export const selectFilter = (state: RootState): Filter => state.pmr.filter
