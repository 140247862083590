import React, { useRef } from 'react'
import { Layout, Row } from 'antd'
import styled from 'styled-components'
import {
  ReportHeader,
  ReportLogo,
  DescItem,
  Signature,
} from 'Components/AdminQC/Report/Components'
import { DataDetail } from 'features/adminReport/auditRoad/types'
import { DefectItem as Defect } from 'features/report/auditRoad/types'
import DefectItem from './DefectItem'
import { getRoadLocation } from 'utils/getText'
import { toDateText, toMomentDateTime } from 'utils/datetime'

const { Content } = Layout

type Props = {
  data: DataDetail
}

const ReportDetail: React.ComponentType<Props> = props => {
  const componentPrint = useRef(null)
  const { data } = props
  const renderDescItem = (title: string, value: any) => {
    return (
      <DescItem
        title={title}
        value={value || value === 0 ? value : '-'}
        percentW={25}
      />
    )
  }

  const ProjectName = `${data.ProjectCode}-${data.ProjectNameTH} `
  const selectedLocation = data.SelectLocationList.join(',')
  const DescContent = (
    <DesBox>
      <Row type="flex">
        {renderDescItem('โครงการ', ProjectName)}
        {renderDescItem(
          'พื้นที่',
          `${getRoadLocation(data)} ${
            data.SelectLocationList.length > 0 ? ' : ' + selectedLocation : ''
          }`
        )}
        {renderDescItem('ตรวจครั้งที่', data.CheckNumber)}
        {renderDescItem(
          'วันที่ตรวจ',
          toDateText(toMomentDateTime(data.InspectDate))
        )}
        {renderDescItem('ผู้ตรวจ', data.CheckedBy)}
        {renderDescItem('ผู้ควบคุมงาน', data.ControllerUser)}
        {renderDescItem('ผู้รับเหมา', data.MainConUserList.join(','))}
        {renderDescItem('Good work', data.NumOfGoodWork)}
        {renderDescItem('ผ่านการตรวจสอบ', data.NumOfPass)}
        {renderDescItem('แก้ไขแล้ว', data.NumOfFixed)}
        {renderDescItem('ยังไม่แก้ไข', data.NumOfToBeFix)}
      </Row>
    </DesBox>
  )

  const DefectItemContent = data.DefectList.map(
    (item: Defect, index: number) => {
      return <DefectItem key={index} index={index} defect={item} />
    }
  )

  return (
    <Content className="mid-content">
      <div className="main-content activity-detail">
        <ReportHeader
          title="ถนน  -  ตรวจรับมอบถนนและก่อนหมดประกัน"
          componentPrint={componentPrint}
        />
        <Box ref={componentPrint}>
          <ReportLogo title="ถนน  -  ตรวจรับมอบถนนและก่อนหมดประกัน" />
          {DescContent}
          <ContentBox>{DefectItemContent}</ContentBox>
          <Signature data={data.Signature} />
        </Box>
      </div>
    </Content>
  )
}

const Box = styled.div`
  background-color: rgba(0, 49, 104, 0.05);
  border-radius: 8px;
  border: solid 1px #e4e7eb;
  color: #000;
  @media print {
    background-color: white;
    border: solid 0px #e4e7eb;
  }
`

const DesBox = styled.div`
  padding: 12px 24px;
  background-color: #fff;
`

const ContentBox = styled.div`
  padding: 24px;
`

export default ReportDetail
