import RootState from '../../../typings/RootState'
import {
  CheckList,
  CheckListDetail,
  DocumentDetail,
  ProjectItem,
  UpdateActivityItem,
  GetTemplateCodeListRep,
} from './types'
import { Event } from '../../types/event'

export const selectCheckList = (state: RootState): CheckList =>
  state.masterChecklist.CheckList

export const selectEvent = (state: RootState): Event =>
  state.masterChecklist.Event

export const selectEventActivity = (state: RootState): Event =>
  state.masterChecklist.EventActivity

export const selectCheckListDetail = (state: RootState): CheckListDetail =>
  state.masterChecklist.CheckListDetail

export const selectDocument = (state: RootState): DocumentDetail =>
  state.masterChecklist.Document

export const selectCheckListActivity = (state: RootState): ProjectItem =>
  state.masterChecklist.CheckListActivity

export const selectActivityUpdate = (state: RootState): UpdateActivityItem[] =>
  state.masterChecklist.ActivityUpdate

export const selectGetTemplateCodeList = (
  state: RootState
): GetTemplateCodeListRep[] => state.masterChecklist.TemplateList
