import React from 'react'
import { Col } from 'antd'

import { TableRow } from 'Components/Shared/Table'
import {
  CheckedIconDefault,
  UncheckedIcon,
  CheckedIcon,
} from 'Components/Shared/Icon'
import { CheckListItem } from 'features/checklist/master/types'

type DefaultProps = {
  item: CheckListItem
  isSelected: boolean
  isSelectAll: boolean
  onSelect: (id: string, cond: string) => void
}

type Props = DefaultProps

const ImportModalRowItem: React.FC<Props> = ({
  item,
  isSelected,
  isSelectAll,
  onSelect,
}) => {
  return (
    <TableRow minHeight={50} spaceBetweenRow={8} paddingLeft={27}>
      <Col span={2}>{item.CheckListID}</Col>
      <Col span={8}>{item.CheckListName}</Col>
      <Col span={5}>{item.CheckItemCounter}</Col>
      <Col span={7}>{item.IsRequireQC ? 'Yes' : 'No'}</Col>
      <Col span={2}>
        {item.IsRequireQC ? (
          <CheckedIconDefault size={24} />
        ) : isSelected || isSelectAll ? (
          <CheckedIcon
            size={24}
            onClick={() => onSelect(item.CheckListID, 'delete')}
          />
        ) : (
              <UncheckedIcon
                size={24}
                onClick={() => onSelect(item.CheckListID, 'add')}
              />
            )}
      </Col>
    </TableRow>
  )
}

export default ImportModalRowItem
