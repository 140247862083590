import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { connect, useDispatch } from 'react-redux'
import { Row, Col } from 'antd'
import RootState from 'typings/RootState'
import { CloseIcon } from 'Components/Shared/Icon'
import PrimaryButton from 'Components/Shared/Button/PrimaryButton'
import { DataItem } from 'features/admin/project/types'
import { DataItem as TeamList } from 'features/admin/team/types'
import { cloneDeep } from 'lodash'
import { DropdownList } from 'Components/Shared/Form'
import EmailBox from './EmailBox'
import UserBox from './UserBox'
import { CheckedIcon } from 'Components/Shared/Icon'
import { User } from 'features/admin/user/types'

type Props = ReduxProps &
  DispatchProps & {
    item: DataItem
    teamList: TeamList[]
    onSave?: (item: DataItem) => void
    onClose: () => void
  }

const AdminDetail: React.ComponentType<Props> = props => {
  const { item, teamList = [] } = props
  const [dataItem, setdataItem] = useState({} as DataItem)

  useEffect(() => {
    const newItem = cloneDeep(item)
    setdataItem(newItem)
  }, [item])

  const onSave = () => {
    if (props.onSave) {
      props.onSave(dataItem)
    }
  }

  const getProjectTypeMenu = () => {
    return [
      {
        name: dataItem.ProjectTypeName || '',
        value: dataItem.ProjectTypeID || 0,
      },
    ]
  }

  const getTeamlistMenu = (teamList: TeamList[]) => {
    return teamList.map(team => {
      return {
        name: team.TeamName,
        value: team.TeamID || 0,
      }
    })
  }

  const getSquadlistMenu = (teamList: TeamList[], teamId: number) => {
    const team = teamList.find(team => team.TeamID === teamId)
    if (team) {
      return team.SquadList.map(squad => {
        return {
          name: squad.SquadName,
          value: squad.SquadID || 0,
        }
      })
    }
    return []
  }

  const getStatusMenu = () => {
    return [
      {
        name: 'Active',
        value: 'Active',
      },
      {
        name: 'In Active',
        value: 'Inactive',
      },
    ]
  }

  const userSelectedBox = (
    <UserSelected>
      <Row
        type="flex"
        justify="space-between"
        align="middle"
        style={{ height: '100%' }}
      >
        <Col className="ellipsis" span={21}>
          <Icon>
            <CheckedIcon />
          </Icon>
          <TextSelect>{dataItem.ManagerUserName}</TextSelect>
        </Col>
        <Col span={3}>
          <TextCancle
            onClick={() => {
              dataItem.ManagerUserName = ''
              dataItem.ManagerUserID = 0
              setdataItem({ ...dataItem })
            }}
          >
            ยกเลิก
          </TextCancle>
        </Col>
      </Row>
    </UserSelected>
  )

  const searchUserBox = (
    <UserBox
      onSave={(user: User) => {
        dataItem.ManagerUserID = user.UserID
        dataItem.ManagerUserName = user.UserNameTH
        setdataItem({ ...dataItem })
      }}
    />
  )

  return (
    <MainPage>
      <Header>
        <CloseIcon color="#8b99a7" size={24} onClick={props.onClose} />
      </Header>
      <Row>
        <Col span={24}>
          <Title>ตั้งค่าทั่วไป</Title>
        </Col>
        <Col span={24}>
          <BodyArea>
            <DropdownList
              label="ประเภทโครงการ"
              value={dataItem.ProjectTypeID}
              menu={getProjectTypeMenu()}
              onChange={(val: string) => {
                dataItem.ProjectTypeID = +val
                setdataItem({ ...dataItem })
              }}
              disabled={true}
            />
          </BodyArea>
        </Col>
        <Col span={24}>
          <BodyArea>
            <DropdownList
              label="Tribe"
              value={dataItem.TeamID}
              menu={getTeamlistMenu(teamList) || []}
              onChange={(val: string) => {
                dataItem.TeamID = +val
                setdataItem({ ...dataItem })
              }}
            />
          </BodyArea>
        </Col>
        <Col span={24}>
          <BodyArea>
            <DropdownList
              label="Squad"
              value={dataItem.SquadID}
              menu={getSquadlistMenu(teamList, dataItem.TeamID)}
              onChange={(val: string) => {
                dataItem.SquadID = +val
                setdataItem({ ...dataItem })
              }}
            />
          </BodyArea>
        </Col>
        <Col span={24}>
          <Text>ผู้จัดการโครงการ</Text>
        </Col>
        <Col span={24}>
          <BodyArea>
            {dataItem.ManagerUserID > 0 ? userSelectedBox : searchUserBox}
          </BodyArea>
        </Col>
        <Col span={24}>
          <Text style={{ marginTop: '12px' }}>รายชื่อส่งอีเมล (To)</Text>
        </Col>
        <Col span={24}>
          <EmailBox
            emailList={dataItem.ToEmailList}
            onSave={newEmail => {
              dataItem.ToEmailList = newEmail
              setdataItem({ ...dataItem })
            }}
          />
        </Col>
        <Col span={24}>
          <Text style={{ marginTop: '12px' }}>รายชื่อส่งอีเมล (Cc)</Text>
        </Col>
        <Col span={24}>
          <BodyArea>
            <EmailBox
              emailList={dataItem.CCEmailList}
              onSave={newEmail => {
                dataItem.CCEmailList = newEmail
                setdataItem({ ...dataItem })
              }}
            />
          </BodyArea>
        </Col>
        <Col span={24}>
          <BodyArea style={{ marginTop: '12px' }}>
            <DropdownList
              label="สถานะ"
              value={dataItem.Status}
              menu={getStatusMenu()}
              onChange={(val: string) => {
                dataItem.Status = val
                setdataItem({ ...dataItem })
              }}
            />
          </BodyArea>
        </Col>
      </Row>
      <Row type="flex" justify="end">
        <SaveArea>
          <PrimaryButton onClick={onSave} text="บันทึก" />
        </SaveArea>
      </Row>
    </MainPage>
  )
}

const MainPage = styled.div`
  -khtml-user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  overflow-x: scroll;
`

const Header = styled.div`
  text-align: right;
  height: 40px;

  svg {
    cursor: pointer;
  }
`

const Title = styled.div`
  color: black;
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 28px;
  margin-bottom: 14px;
`

const Text = styled.div`
  color: black;
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 20px;
  margin-bottom: 10px;
`

const SaveArea = styled.div`
  margin-top: 32px;
  float: right;
  width: 160px;
`

const BodyArea = styled.div`
  font-family: 'DB Helvethaica X 55 Regular';
  span {
    font-size: 20px;
  }
  .ant-dropdown-trigger {
    margin-bottom: 12px;
  }
  .input-title {
    margin-bottom: 12px;
  }
`

const SearchItem = styled.div`
  padding: 8px 24px;
  font-size: 20px;
  font-family: 'DB Helvethaica X 55 Regular';
  &:hover {
    background-color: #e6f7ff;
    cursor: pointer;
  }
`

const UserSelected = styled.div`
  height: 48px;
  border-radius: 5px;
  border: solid 1px #eeeff2;
  background-color: #fff;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.03);
  padding: 0 16px;
  .ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

const TextSelect = styled.span`
  font-size: 20px;
  font-family: 'DB Helvethaica X 55 Regular';
`

const TextCancle = styled.span`
  font-size: 20px;
  font-family: 'DB Helvethaica X 55 Regular';
  color: #d0494c;
  cursor: pointer;
`

const Icon = styled.div`
  margin-right: 16px;
  float: left;
`

const mapStateToProps = (state: RootState) => {
  return {}
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdminDetail)
