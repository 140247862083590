import React, { useState } from 'react'
import styled from 'styled-components'
import { PrimaryButton, SecondaryButton } from 'Components/Shared/Button'
import { Row, Col } from 'antd'

type Props = {
  isLoading?: boolean
  onSendReqToMC: () => void
  onSendReqToQC: () => void
}

const InproFooter: React.ComponentType<Props> = props => {
  const { isLoading = false, onSendReqToMC, onSendReqToQC } = props

  return (
    <>
      <Col />
      <Col>
        <Button>
          <SecondaryButton
            text="ส่งรายงานหาผู้รับเหมา"
            disabled={isLoading}
            onClick={onSendReqToMC}
          />
        </Button>
        <Button>
          <PrimaryButton
            text="ส่งรายงานหา QC"
            disabled={isLoading}
            onClick={onSendReqToQC}
          />
        </Button>
      </Col>
    </>
  )
}

const Footer = styled.div`
  position: fixed;
  bottom: 0px;
  height: auto;
  width: 100%;
  background-color: white;
  padding: 22px 80px;
  z-index: 20;
`

const Button = styled.div`
  width: 200px;
  margin-left: 16px;
  float: left;
`

const Span = styled.span`
  font-size: 20px;
  font-family: 'DB Helvethaica X 55 Regular';
  color: #000;
  margin-right: 12px;
`

export default InproFooter
