import { getType } from 'typesafe-actions'
import * as actions from './actions'
import produce from 'immer'
import { StoreState, DashboardAction } from './types'

import parseErrorMessage from 'utils/parseErrorMessage'

const initialState: StoreState = {
  list: [],
  projectCode: '',
  event: {
    IsLoading: false,
    SubmitError: undefined,
    SubmitSuccess: false,
    SubmitType: undefined,
    MessageSuccess: undefined,
  },
}

export default function(
  state: StoreState = initialState,
  action: DashboardAction
): StoreState {
  return produce(state, draft => {
    switch (action.type) {
      case getType(actions.getDashboardAction.request):
        draft.event.IsLoading = true
        draft.event.SubmitError = undefined
        draft.list = []
        break
      case getType(actions.getDashboardAction.success):
        draft.event.IsLoading = false
        draft.list = action.payload.ResponseData
        break
      case getType(actions.getDashboardAction.failure):
        draft.event.IsLoading = false
        draft.event.SubmitError = parseErrorMessage(action.payload)
        break
      case getType(actions.setProjectCode):
        draft.projectCode = action.payload
        break
      default:
        return state
    }
  })
}
