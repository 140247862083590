import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'antd'
import { InProgress } from 'features/cmpj/sitevisit/auditplan/dashboard/types'
import { toMomentDateTime, toDateText, toTimeText } from 'utils/datetime'
import { LaneColor } from 'Components/Shared'

interface Props {
  task: InProgress
}

const InProgressCard: React.FC<Props> = ({ task }) => {
  const renderStatus = () => {
    const appointmentDate = toMomentDateTime(task.AppointmentDate)
    const date = toDateText(appointmentDate)
    const time = toTimeText(appointmentDate)
    return (
      <TextStatus>
        {date} เวลา {time}
      </TextStatus>
    )
  }

  const isPass = task.Status === 'INPRO'
  return (
    <MainCard>
      <LaneColor status={isPass ? 'success' : 'fail'} />
      <Row type="flex">
        <Col span={24}>
          <Title>ตรวจครั้งที่ {task.CheckNumber}</Title>
          <Detail>วันที่นัดหมาย: {renderStatus()}</Detail>
        </Col>
      </Row>
    </MainCard>
  )
}

const MainCard = styled.div`
  background: #fff;
  border-radius: 10px;
  padding: 16px;
  background-clip: padding-box;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.03);
  margin-bottom: 12px;
  cursor: pointer;
  color: #000;
  width: 100%;
  position: relative;
`

const Title = styled.p`
  font-size: 20px;
  margin-bottom: 4px;
  font-family: 'DB Helvethaica X 75 Bd';
`

const Detail = styled.p`
  font-size: 18px;
  margin-bottom: 4px;
  font-family: 'DB Helvethaica X 55 Regular';
`

const TextStatus = styled.span`
  color: #63cea6;
`

export default InProgressCard
