import { getType } from 'typesafe-actions'
import * as actions from './actions'
import { StoreState, MainAction } from './types'
import produce from 'immer'
import { Event } from '../../types/event'
import { cloneDeep } from 'lodash'
import parseErrorMessage from 'utils/parseErrorMessage'
import { sortingTask } from 'utils/sorting'

const initEvent = {
  IsLoading: false,
  MessageSuccess: undefined,
  SubmitError: undefined,
  SubmitSuccess: false,
  SubmitType: undefined,
} as Event

const initialState: StoreState = {
  auditRoad: {
    ToDo: [],
    InProgress: [],
    Return: [],
  },
  event: cloneDeep(initEvent),
  actionEvent: cloneDeep(initEvent),
  sorting: {
    return: 'asc',
  },
}

const sortingColumn = {
  return: 'CheckedDate',
}

export default function(
  state: StoreState = initialState,
  action: MainAction
): StoreState {
  return produce(state, draft => {
    switch (action.type) {
      case getType(actions.sendAuditRoadAction.request):
      case getType(actions.returnAuditRoadAction.request):
      case getType(actions.acceptAuditRoadAction.request):
      case getType(actions.RejectTasksAction.request):
        draft.actionEvent = cloneDeep(initEvent)
        draft.actionEvent.IsLoading = true
        break

      case getType(actions.sendAuditRoadAction.failure):
      case getType(actions.returnAuditRoadAction.failure):
      case getType(actions.acceptAuditRoadAction.failure):
      case getType(actions.RejectTasksAction.failure):
        draft.actionEvent.IsLoading = false
        draft.actionEvent.SubmitError = parseErrorMessage(action.payload)
        break

      case getType(actions.sendAuditRoadAction.success):
      case getType(actions.returnAuditRoadAction.success):
      case getType(actions.acceptAuditRoadAction.success):
      case getType(actions.RejectTasksAction.success):
        draft.actionEvent.IsLoading = false
        draft.actionEvent.SubmitError = undefined
        draft.actionEvent.SubmitSuccess = true
        draft.actionEvent.MessageSuccess = 'ทำรายการสำเร็จ'
        break

      case getType(actions.getAuditRoadAction.failure):
        draft.event.IsLoading = false
        draft.event.SubmitError = parseErrorMessage(action.payload)
        break

      case getType(actions.getAuditRoadAction.request):
        draft.event = cloneDeep(initEvent)
        draft.event.IsLoading = true
        draft.auditRoad = {
          ToDo: [],
          InProgress: [],
          Return: [],
        }
        break
      case getType(actions.getAuditRoadAction.success):
        draft.event.IsLoading = false
        draft.auditRoad = action.payload.ResponseData
        break

      case getType(actions.sortingDashboard):
        let typ: string = action.payload
        let orderBy: string = state.sorting[typ]
        orderBy = orderBy !== 'asc' ? 'asc' : 'desc'
        draft.sorting[typ] = orderBy
        const list = sortingTask(
          state.auditRoad.Return,
          orderBy,
          sortingColumn.return
        )

        draft.auditRoad.Return = list
        break
      default:
        return state
    }
  })
}
