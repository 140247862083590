import React, { useEffect, useState, useCallback } from 'react'
import moment from 'moment'
import styled from 'styled-components'
import { Modal, Input } from 'antd'
import { PrimaryButton, SecondaryButton } from 'Components/Shared/Button'

import { writeImage } from 'Components/Pages/ActivityDetailPage/Editor/utils'
import file from 'utils/file'

import Editor from './Editor'
type Props = {
  display?: boolean
  loading?: boolean
  onClose: () => void
  onSave: (name: string, remark: string, img: any) => void
}

const SignDialog: React.ComponentType<Props> = props => {
  const [name, setName] = useState('')
  const [remark, setRemark] = useState('')
  const [myCanvas, setMyCanvas] = useState(undefined as any)
  const [lineList, setLineList] = useState([])

  const { display = false, loading = false, onClose, onSave } = props

  useEffect(() => {
    if (display === true) {
      setName('')
      setRemark('')
      setLineList([])
    }
  }, [display])

  const onRefCanvas = useCallback(node => {
    if (node) setMyCanvas(node)
  }, [])

  const onSaveImg = async () => {
    const ctx = myCanvas.getContext('2d')
    await writeImage('draw', ctx, undefined, lineList)

    const base64: string = myCanvas.toDataURL()
    const now = moment()
    const params = {
      data: base64,
      filename: `signature-${now.format('dd/mm/yyyy HH:mm')}.png`,
      lastModified: now.unix(),
    }
    const fileItem: any = await file.base64ToFile(params)

    onSave(name, remark, fileItem)
  }

  return (
    <Modal
      title=""
      footer=""
      visible={display}
      className="editor-view"
      onOk={onClose}
      onCancel={onClose}
      centered={false}
      closable={false}
      width="100%"
    >
      <PageView>
        <PageContent>
          <Title>ลงชื่อผู้รับทราบการตรวจสอบ</Title>
          <InputTitle>
            <Input
              placeholder="ผู้รับทราบการตรวจสอบ"
              value={name}
              disabled={loading}
              onChange={(evt: any) => setName(evt.target.value)}
            />
          </InputTitle>
          <Space />

          <Editor
            display={display}
            disabled={loading}
            onChange={(val: any) => setLineList(val)}
          />
          <Space />
          <InputTitle>
            <Input
              placeholder="หมายเหตุ"
              value={remark}
              disabled={loading}
              onChange={(evt: any) => setRemark(evt.target.value)}
            />
          </InputTitle>
          <Space />

          <Btn>
            <PrimaryButton
              text="บันทึก"
              onClick={onSaveImg}
              loading={loading}
              disabled={name === '' || lineList.length === 0}
            />
          </Btn>

          <Btn>
            <SecondaryButton
              text="ยกเลิก"
              onClick={onClose}
              disabled={loading}
            />
          </Btn>
        </PageContent>
      </PageView>
      <MyCanvas width={625} height={195} ref={onRefCanvas} />
    </Modal>
  )
}

const PageView = styled.div`
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
`

const PageContent = styled.div`
  width: 680px;
  border-radius: 5px;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.03);
  background-color: white;
  margin: 0 auto;
  padding: 26px;
`

const Title = styled.div`
  width: 100%;
  text-align: center;
  height: 30px;
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 28px;
  color: black;
  margin-bottom: 32px;
`

const Btn = styled.div`
  width: 200px;
  margin-left: 16px;
  float: right;
`

const Space = styled.div`
  padding-bottom: 12px;
`

const InputTitle = styled.div`
  input {
    height: 48px;
    border-radius: 5px;
    border: 1px solid #e4e7eb;
    background-color: #ffffff;
    font-size: 20px;
    font-family: 'DB Helvethaica X 75 Bd';
  }

  .ant-input:placeholder-shown {
    font-size: 20px;
    font-family: 'DB Helvethaica X 55 Regular';
  }

  .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
    border-color: #e4e7eb;
  }
`

const MyCanvas = styled.canvas`
  display: none;
`

export default SignDialog
