import React, { useEffect } from 'react'
import { Row, Col, Input } from 'antd'
import styled from 'styled-components'
import ImagePicker from 'Components/Shared/ImagePicker'
import DocumentPicker from 'Components/Shared/DocumentPicker'
import { CurrentActivity } from 'features/maincon/activity/types'
import imgHistory from 'assets/img/ic-history.png'
import { getMainconByStatus, getApprovalByStatus } from 'utils/statusLookup'
import { RemarkBox, TaskInfo } from 'Components/Activity'
import { TaskState } from 'features/types/status'
import { isUnit } from 'utils/helper'

type Props = {
  isSubmitting: boolean
  onImageOpen: () => void
  onDocumentOpen: () => void
  onThumbnailOpen: (index: number) => void
  onChange: (item: any) => void
  originalData: any
  currentData: CurrentActivity
  onHistoryOpen?: () => void
  readOnly?: boolean
  wbs?: any
  currentState: TaskState
}

const MCTaskDetail: React.FunctionComponent<Props> = ({
  onImageOpen,
  onDocumentOpen,
  onThumbnailOpen,
  onChange,
  originalData,
  currentData,
  onHistoryOpen,
  readOnly = false,
  wbs,
  currentState,
}) => {
  const onImage = (images: any[]) => {
    currentData.Images = images
    onChange({ ...currentData })
  }

  const onDocument = (docs: any[]) => {
    currentData.Documents = docs
    onChange({ ...currentData })
  }

  const onRemark = (value: string) => {
    currentData.Remark = value
    onChange({ ...currentData })
  }

  let status = getMainconByStatus(originalData.State, originalData.TaskStatus)
  if (currentState === 'PJPO') {
    status = getApprovalByStatus(originalData.State, originalData.TaskStatus)
  }

  const hideFileInfo =
    currentState === 'MC' &&
    originalData.TaskStatus === 'INIT' &&
    originalData.State === 'MC'

  const QcInfo = isUnit(originalData.Zone) ? (
    <Col>
      <TextHeader>
        หมวดงาน QC<span>{originalData.QCTypeName}</span>
      </TextHeader>
    </Col>
  ) : (
    undefined
  )
  const header = (
    <Row type="flex" justify="space-between" className="header">
      <Col span={12}>
        <Row type="flex" justify="space-between">
          <Col>
            <TextHeader>
              ตึก<span>{originalData.TowerName}</span>
            </TextHeader>
          </Col>
          <Col>
            <TextHeader>
              ชั้น<span>{originalData.FloorName}</span>
            </TextHeader>
          </Col>
          <Col>
            <TextHeader>
              ยูนิต<span>{originalData.Unit}</span>
            </TextHeader>
          </Col>
          {QcInfo}
        </Row>
      </Col>
      <Col span={8}>
        <RightConner>
          <HistoryBtn onClick={onHistoryOpen}>
            <img src={imgHistory} alt="Go to activity history" />
            ประวัติการทำงาน
          </HistoryBtn>
          <Status style={{ backgroundColor: status.color }}>
            {status.text}
          </Status>
        </RightConner>
      </Col>
    </Row>
  )

  const maxFile = readOnly ? 4 : 3
  const filesInfo = (
    <BoxItem>
      <Row type="flex" justify="space-between">
        <Col md={12}>
          <ImagePicker
            Images={readOnly ? originalData.McImages : currentData.Images}
            onChange={onImage}
            onImageOpen={onImageOpen}
            onThumbnailOpen={onThumbnailOpen}
            disabled={readOnly}
            showMax={maxFile}
            numberOfImage={50}
            viewMode={readOnly}
            isMultiple={true}
          />
        </Col>
        <Col md={12}>
          <DocumentPicker
            Documents={
              readOnly ? originalData.McDocuments : currentData.Documents
            }
            onChange={onDocument}
            onDocumentOpen={onDocumentOpen}
            disabled={readOnly}
            showMax={maxFile}
            numberOfFile={50}
            viewMode={readOnly}
            isMultiple={true}
          />
        </Col>
      </Row>
      <Space />
      <RemarkBox
        onChange={onRemark}
        value={readOnly ? originalData.McRemark : currentData.Remark}
        readOnly={readOnly}
      />
      <Space />
    </BoxItem>
  )

  return (
    <MainView>
      {header}
      <TaskInfo originalData={originalData} wbs={wbs} />
      {!hideFileInfo && filesInfo}
    </MainView>
  )
}

const MainView = styled.div``
const TextHeader = styled.div`
  font-size: 28px;
  font-family: 'DB Helvethaica X 75 Bd';
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  span {
    font-family: 'DB Helvethaica X 55 Regular';
    padding-left: 4px;
  }
`

const RightConner = styled.div`
  float: right;
`
const HistoryBtn = styled.span`
  color: #003168;
  font-size: 18px;
  font-family: 'DB Helvethaica X 75 Bd';
  img {
    width: 16px;
    height: 16px;
    margin-right: 8px;
    margin-bottom: 5px;
  }

  :hover {
    cursor: pointer;
  }
`

const Status = styled.span`
  height: 20px;
  border-radius: 10px;
  font-size: 16px;
  color: #fff;
  text-align: center;
  line-height: 1.4;
  padding: 0px 12px;
  margin-left: 15px;

  &.init {
    background-color: #8b99a7;
  }

  &.progress {
    background-color: #003168;
  }
`

const Space = styled.div`
  margin-top: 24px;
`

const BoxItem = styled.div`
  background-color: #fff;
  margin-top: 12px;
  border-radius: 5px;
  border: solid 1px #f2f2f2;
  padding: 24px;
  line-height: normal;
`

export default MCTaskDetail
