import React, { useState } from 'react'
import { Drawer, Col, Badge } from 'antd'
import styled from 'styled-components'

import { translate } from 'i18n'
import TKeys from 'i18n/translationKey'

import { TableRow, StatusCol } from '../../Shared/Table'
import { getDateText } from 'utils/getText'
import { DataItem } from 'features/admin/project/types'

type Props = {
  onClick?: (evt: any, item: DataItem) => void
  item: DataItem
  selected?: boolean
}

const RowItem: React.FC<Props> = props => {
  const { item, selected } = props
  const onClick = (evt: any) => {
    if (props.onClick) {
      props.onClick(evt, item)
    }
  }

  const bg = item.Status === 'Active' ? '#5bc79e' : '#efc122'
  const text = item.Status === 'Active' ? 'Publish' : 'Not publish'
  const noTeam =
    item.TeamID === 0 || item.SquadID === 0 || item.ManagerUserID === 0
  const noType = item.ProjectTypeID === 0
  const rowHeight = 60

  const notDefindTxt = <WarningText>ยังไม่ระบุ</WarningText>

  return (
    <Content>
      <TableRow onClick={onClick} selected={selected} minHeight={rowHeight}>
        <Col lg={6}>
          <StatusCol
            status={noTeam || noType ? 'cancelled' : ''}
            minHeight={rowHeight}
          >
            <Name>
              {item.ProjectCode}-{item.ProjectNameTH}
            </Name>
          </StatusCol>
        </Col>
        <Col lg={3}>{item.TeamID === 0 ? notDefindTxt : item.TeamName}</Col>
        <Col lg={3}>{item.SquadID === 0 ? notDefindTxt : item.SquadName}</Col>
        <Col lg={3}>
          {item.ProjectTypeID === 0 ? notDefindTxt : item.ProjectTypeName}
        </Col>
        <Col lg={3}>{item.ManagerUserName || notDefindTxt}</Col>
        <Col lg={3}>
          <Status style={{ backgroundColor: bg }}>{text}</Status>
        </Col>
        <Col lg={2} style={{ color: 'black' }}>
          {getDateText(item.LastSyncDate)}
        </Col>
      </TableRow>
    </Content>
  )
}

const Name = styled.div`
  padding-left: 25px;
  font-size: 20px;
  font-family: 'DB Helvethaica X 75 Bd';
`

const Content = styled.div`
  cursor: pointer;
`

const Status = styled.div`
  height: 20px;
  border-radius: 10px;
  color: white;
  text-align: center !important;
  font-size: 16px;
  padding: 5px 15px;
  font-size: 16px;
  align-items: center;
  display: inline-flex;
`

const Primavera = styled.div`
  font-size: 20px;
  p {
    margin: 0;
    line-height: 0.5;
  }
`

const WarningText = styled.span`
  color: #d0494c;
`

const Highlight = styled.div`
  width: 24px;
  height: 24px;
  background-color: #4983c4;
  border-radius: 50%;
  font-size: 10px;
  font-family: 'DB Helvethaica X 75 Bd';
  color: #fff;
  justify-content: center;
  display: flex;
  align-items: center;
  text-align: center;
  margin-left: 12px;
`

export default RowItem
