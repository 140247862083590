import { getType } from 'typesafe-actions'
import { cloneDeep } from 'lodash'

import * as actions from './actions'
import { StoreState, ReportAction } from './types'
import produce from 'immer'
import parseErrorMessage from 'utils/parseErrorMessage'
import { Event } from '../../types/event'

const checklistQC = {
  ActivityName: '',
  CheckListID: 0,
  CheckListName: '',
  CheckListTypeID: 0,
  CheckListType: '',
  CommissioningTypeID: 0,
  CommissioningType: '',
  ProjectCode: '',
  ProjectNameTH: '',
  ProjectNameEN: '',

  CheckNumber: 0,
  State: '',
  Status: '',
  CheckStatus: '',
  CheckedDate: '',

  HasSign: false,
  HasImage: false,

  ImageList: [],
  List: [],
}

const initSelectedImage = {
  ImageList: [],
}

const initEvent = {
  IsLoading: false,
  MessageSuccess: undefined,
  SubmitError: undefined,
  SubmitSuccess: false,
  SubmitType: undefined,
} as Event

const initialState: StoreState = {
  event: cloneDeep(initEvent),
  eventImg: cloneDeep(initEvent),
  checkListQC: cloneDeep(checklistQC),
  selectedImage: cloneDeep(initSelectedImage),
  originalImageList: [],
}

export default function(
  state: StoreState = initialState,
  action: ReportAction
): StoreState {
  return produce(state, draft => {
    switch (action.type) {
      case getType(actions.getCheckListAction.request):
        draft.event = cloneDeep(initEvent)
        draft.event.IsLoading = true
        draft.checkListQC = cloneDeep(checklistQC)
        break
      case getType(actions.getCheckListAction.success):
        draft.event.IsLoading = false
        const checkQC = action.payload.ResponseData
        if (checkQC) {
          draft.checkListQC = checkQC
          draft.originalImageList = checkQC.ImageList
        }
        break

      case getType(actions.getCommissioningImageAction.request):
        draft.event = cloneDeep(initEvent)
        draft.event.IsLoading = true
        draft.selectedImage = cloneDeep(initSelectedImage)
        break

      case getType(actions.getCommissioningImageAction.success):
        draft.event.IsLoading = false
        if (action.payload.ResponseData) {
          draft.selectedImage = action.payload.ResponseData
        }

        break

      case getType(actions.getCheckListAction.failure):
      case getType(actions.saveCheckListAction.failure):
      case getType(actions.sendCommissioningAction.failure):
      case getType(actions.sendCommissioningEditAction.failure):
      case getType(actions.getCommissioningImageAction.failure):
        draft.event.IsLoading = false
        draft.event.SubmitError = parseErrorMessage(action.payload)
        break

      case getType(actions.saveCheckListAction.request):
      case getType(actions.sendCommissioningAction.request):
      case getType(actions.sendCommissioningEditAction.request):
        draft.event = cloneDeep(initEvent)
        draft.event.IsLoading = true
        break

      case getType(actions.saveCheckListAction.success):
      case getType(actions.sendCommissioningAction.success):
      case getType(actions.sendCommissioningEditAction.success):
        draft.event.IsLoading = false
        draft.event.SubmitError = undefined
        draft.event.SubmitSuccess = true
        draft.event.MessageSuccess = 'ทำรายการสำเร็จ'
        break

      case getType(actions.saveCommissioningImageAction.request):
        draft.eventImg = cloneDeep(initEvent)
        draft.eventImg.IsLoading = true
        break

      case getType(actions.saveCommissioningImageAction.success):
        draft.eventImg.IsLoading = false
        draft.eventImg.SubmitError = undefined
        draft.eventImg.SubmitSuccess = true
        draft.eventImg.MessageSuccess = 'ทำรายการสำเร็จ'
        break

      case getType(actions.saveCommissioningImageAction.failure):
        draft.eventImg.IsLoading = false
        draft.eventImg.SubmitError = parseErrorMessage(action.payload)
        break

      case getType(actions.updateCheckList):
        draft.checkListQC = action.payload
        break

      case getType(actions.resetEvent):
        draft.event = cloneDeep(initEvent)
        break

      case getType(actions.updateNewImage):
        let imgList = draft.checkListQC.ImageList
        const { image, index } = action.payload
        if (index === -1) {
          imgList.unshift({
            Image: image,
            Remark: '',
          })
        } else {
          imgList[index].Image = image
        }

        draft.checkListQC.ImageList = imgList
        break
      default:
        return state
    }
  })
}
