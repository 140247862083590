import { getType } from 'typesafe-actions'
import * as actions from './tasklane.actions'
import { TasklaneAction, TasklaneState, Task } from './tasklane.types'
import { sortingTask } from './tasklane.function'
import produce from 'immer'
import parseErrorMessage from 'utils/parseErrorMessage'
import { filterActivity } from 'features/activity/activity.function'

const initialState: TasklaneState = {
  todo: [] as Task[],
  inProgress: [] as Task[],
  completed: [] as Task[],
  isFetchingTasklanes: false,
  submitError: '',
  submitSuccess: true,
  response: {
    // Keys: ResponseCode, ResponseMsg, RequestTime, ResponseTime, ResponseData are
    // defined in APISpecs from Sansiri backend.
    // Link to API Specs https://docs.google.com/spreadsheets/d/1ZYq51t7FfUcX2KwBFs3hch7WvVnQqhsSE1abrwk-3vg/edit#gid=977901557
    ResponseCode: '1',
    ResponseMsg: 'Success',
    RequestTime: '',
    Responsetime: '',
    ResponseData: {
      ToDo: [] as Task[],
      InProgress: [] as Task[],
      Complete: [] as Task[],
    },
  },
  sorting: {
    todo: 'asc',
    progress: 'asc',
    complete: 'asc',
  },
  query: {
    date: {
      start: undefined,
      end: undefined,
    },
  },
  filter: {
    progress: [],
    status: [],
    wbs: [],
    date: {
      mode: '',
      start: undefined,
      end: undefined,
    },
    floor: [],
    unit: [],
    zone: [],
    type: [],
  },
  tasks: [],
}

export default function(
  state: TasklaneState = initialState,
  action: TasklaneAction
): TasklaneState {
  return produce(state, draft => {
    switch (action.type) {
      case getType(actions.fetchTasklaneAsyncAction.request):
        draft.isFetchingTasklanes = true
        draft.todo = []
        draft.inProgress = []
        draft.completed = []
        draft.response.ResponseData = {
          ToDo: [],
          InProgress: [],
          Complete: [],
        }
        break
      case getType(actions.fetchTasklaneAsyncAction.success):
        draft.isFetchingTasklanes = false
        const payload = action.payload
        draft.response = payload
        const sorting = state.sorting
        const filter = state.filter

        let list = filterActivity(payload.ResponseData.ToDo, filter)
        const todoSort = sortingTask(list, sorting.todo, 'StartDate')

        list = filterActivity(payload.ResponseData.InProgress, filter)
        const progressSort = sortingTask(list, sorting.progress, 'FinishDate')

        list = filterActivity(payload.ResponseData.Complete, filter)
        const completeSort = sortingTask(list, sorting.complete, 'FinishDate')

        draft.todo = todoSort
        draft.inProgress = progressSort
        draft.completed = completeSort

        draft.response.ResponseData = {
          ToDo: todoSort,
          InProgress: progressSort,
          Complete: completeSort,
        }

        break
      case getType(actions.fetchTasklaneAsyncAction.failure):
        draft.isFetchingTasklanes = false
        draft.submitError = parseErrorMessage(action.payload)
        break
      case getType(actions.sortingTask):
        let typ: string = action.payload
        let orderBy: string = state.sorting[typ]
        orderBy = orderBy !== 'asc' ? 'asc' : 'desc'
        draft.sorting[typ] = orderBy

        if (typ === 'todo') {
          const list = sortingTask(state.todo, orderBy, 'StartDate')

          draft.response.ResponseData.ToDo = list
          draft.todo = list
        } else if (typ === 'progress') {
          const list = sortingTask(state.inProgress, orderBy, 'FinishDate')

          draft.response.ResponseData.InProgress = list
          draft.inProgress = list
        } else {
          const list = sortingTask(state.completed, orderBy, 'FinishDate')

          draft.response.ResponseData.Complete = list
          draft.completed = list
        }

        break
      case getType(actions.updateListAction):
        const { todo = [], inProgress = [], completed = [] } = action.payload
        draft.todo = todo
        draft.inProgress = inProgress
        draft.completed = completed
        break
      case getType(actions.setFilterDashboard):
        draft.filter = { ...action.payload }
        break
      case getType(actions.setQueryDashboard):
        draft.query = { ...action.payload }
        break
      default:
        return state
    }
  })
}
