import withAPIAction from 'utils/withAPIAction'
import { verifyReqNoProject } from 'utils/verify'
import { getDashboardAction } from './actions'
import { GetActivityReq } from './types'

export const getDashboard = (token: string, data: GetActivityReq) => {
  const isValid = verifyReqNoProject(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<GetActivityReq>({
    method: 'POST',
    path: 'GetProjectListForPJ',
    data,
    token: `Bearer ${token}`,
  })(getDashboardAction)
}
