import React, { useState } from 'react'
import { TableHeader as Table, TableColHeader } from 'Components/Shared/Table'

type Props = {
  onSort(name: string): void
  sorting: any
  hideColumn?: string[]
  defaultColumn: any
}

const TableHeader: React.FunctionComponent<Props> = props => {
  const { onSort, sorting, hideColumn = [], defaultColumn } = props

  const getIcon = (
    name: string | undefined,
    type: string,
    colName: string
  ): string => {
    if (name === colName) {
      return type !== 'desc' ? '/img/ic-arrow-actions.png' : '/img/ic-down.png'
    }

    return '/img/ic-sort.svg'
  }

  const showColumn: [] = defaultColumn.filter((item: any) => {
    return !hideColumn.includes(item.key)
  })

  const allCol = (showColumn && showColumn.length) || 1
  const span = Math.floor(24 / allCol)
  let remain = 24 - allCol * span

  const renderColumn = () => {
    return showColumn.map((item: any, index: number) => {
      const add = remain > 0 ? 1 : 0
      remain = remain - add
      return (
        <TableColHeader
          key={index}
          span={span + add}
          onSort={() => onSort(item.key)}
          type=""
          sort={false}
        >
          {item.title}
        </TableColHeader>
      )
    })
  }

  return <Table paddingLeft={27}>{renderColumn()}</Table>
}

export default TableHeader
