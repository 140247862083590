import React, { useState, useRef } from 'react'
import styled from 'styled-components'

import InputText from 'Components/Shared/Input/InputText'

import { CheckListDetail, JobType } from 'features/checklist/auditPlan/types'
import { LocationType } from 'features/checklist/unit/types'

import { TrashIcon, EditIcon } from 'Components/Shared/Icon'
import { BottomButton, AddList } from 'Components/Shared/Button'
import JobModalLocation, {
  JobHandles,
} from 'Components/AdminQC/CheckUnit/Detail/List/Modal/JobModalLocation'

import { SelectType } from '..'

interface Props {
  selected: SelectType
  checkListDetail: CheckListDetail
  editingObj?: JobType
  onSave: (job: JobType) => void
  closeModal: () => void
  disabled: boolean
}

const JobModal: React.FC<Props> = ({
  checkListDetail,
  selected,
  editingObj,
  onSave: onSaveProp,
  closeModal,
  disabled,
}) => {
  const modalRef = useRef<JobHandles>(null)
  const [error, seterror] = useState('')
  const [name, setname] = useState(editingObj ? editingObj.JobTypeName : '')
  const [locationList, setlocationList] = useState<LocationType[]>(
    editingObj ? editingObj.LocationList : []
  )

  const changeName = (val: string) => {
    setname(val)
  }

  const showModal = (item?: LocationType) => {
    if (modalRef.current) {
      modalRef.current.showModal(item)
    }
  }

  const addLocation = (item: LocationType) => {
    const newLocation = locationList.concat(item)
    setlocationList(newLocation)
  }

  const updateLocation = () => {
    setlocationList([...locationList])
  }

  const deleteLocation = (index: number) => {
    locationList.splice(index, 1)
    setlocationList([...locationList])
  }

  const onSave = () => {
    if (name !== '') {
      const found = editingObj
        ? null
        : checkListDetail.JobTypeList.find(v => v.JobTypeName === name)
      if (found == null) {
        onSaveProp({
          JobTypeID: editingObj ? editingObj.JobTypeID : 0,
          JobTypeName: name,
          LocationList: locationList,
          SubJobTypeList: editingObj ? editingObj.SubJobTypeList : [],
        })
      } else {
        seterror('ลักษณะงานหลักนี้ ได้มีอยู่แล้ว')
      }
    } else {
      seterror('กรุณาใส่ข้อมูลทั้งหมด')
    }
  }

  return (
    <div>
      <Box>
        <InputText
          prefix="ชื่อ"
          value={name}
          onChange={changeName}
          placeholder="กรอกชื่อ"
          openWithFocus
          disabled={disabled}
        />
      </Box>

      <Title>Location</Title>
      <LocationContent>
        {locationList.map((item: LocationType, index: number) => {
          return (
            <LocationItem key={index}>
              <label>{item.LocationName}</label>
              {!disabled && (
                <IconDiv>
                  <IconStyle onClick={() => showModal(item)}>
                    <EditIcon color="#8b99a7" size={16} />
                  </IconStyle>
                  <IconStyle>
                    <TrashIcon
                      onClick={() => deleteLocation(index)}
                      color="#8b99a7"
                      size={16}
                    />
                  </IconStyle>
                </IconDiv>
              )}
            </LocationItem>
          )
        })}
        {!disabled && <AddList onClick={() => showModal()} />}
      </LocationContent>

      {!disabled && (
        <BottomButton closeModal={closeModal} onSave={onSave} error={error} />
      )}

      <JobModalLocation
        ref={modalRef}
        addLocation={addLocation}
        updateLocation={updateLocation}
      />
    </div>
  )
}

const Box = styled.div`
  padding-top: 12px;
`

const Title = styled.div`
  padding-top: 17px;
  font-size: 20px;
  color: #000000;
  font-family: 'DB Helvethaica X 75 Bd';
`

const LocationContent = styled.div`
  width: 100%;
  height: 232px;
  border-radius: 5px;
  border: solid 1px #eeeff2;
  background-color: #ffffff;
  overflow: hidden;
  overflow-y: scroll;
`

const LocationItem = styled.div`
  font-family: 'DB Helvethaica X 55 Regular';
  font-size: 20px;
  margin: 0 auto;
  width: 90%;
  height: 56px;
  align-items: center;
  display: flex;
  border: 0px solid #e8ebf0;
  border-bottom-width: 1px;
  display: flex;
  justify-content: space-between;
`

const IconDiv = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`

const IconStyle = styled.div`
  padding: 0 9px;
  cursor: pointer;
`

export default JobModal
