import { createStandardAction, createAsyncAction } from 'typesafe-actions'
import {
  GetActivityReq,
  GetActivityApi,
  UpdateActivityApi,
  UpdateActivityReq,
  MSGetActivityReq,
  GetMSActivityApi,
} from './types'

export const getActivity = createAsyncAction(
  'approval/activity/FETCH_ACTIVITY_REQUEST',
  'approval/activity/FETCH_ACTIVITY_SUCCESS',
  'approval/activity/FETCH_ACTIVITY_FAILURE'
)<GetActivityReq, GetActivityApi, any>()

export const updateActivity = createAsyncAction(
  'approval/activity/UPDATE_ACTIVITY_REQUEST',
  'approval/activity/UPDATE_ACTIVITY_SUCCESS',
  'approval/activity/UPDATE_ACTIVITY_FAILURE'
)<UpdateActivityReq, UpdateActivityApi, any>()

export const updateMSActivity = createAsyncAction(
  'approval/ms_activity/UPDATE_ACTIVITY_REQUEST',
  'approval/ms_activity/UPDATE_ACTIVITY_SUCCESS',
  'approval/ms_activity/UPDATE_ACTIVITY_FAILURE'
)<UpdateActivityReq, UpdateActivityApi, any>()

export const getMSActivity = createAsyncAction(
  'approval/ms_activity/FETCH_ACTIVITY_REQUEST',
  'approval/ms_activity/FETCH_ACTIVITY_SUCCESS',
  'approval/ms_activity/FETCH_ACTIVITY_FAILURE'
)<MSGetActivityReq, GetMSActivityApi, any>()
