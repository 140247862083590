import withAPIAction from 'utils/withAPIAction'
import { fetchTasklaneAsyncAction } from './tasklane.actions'
import { FetchTasklaneRequest } from './tasklane.types'

export const fetchTasklanes = (
  path: string,
  token: string,
  data: FetchTasklaneRequest
) => {
  if (token === '') {
    return { type: 'tasklane/no_token' }
  }

  if (data.ProjectCode === '-MS') {
    return { type: 'activity/PAYLOAD_NOT_READY' }
  }

  data.Page = 1
  data.RowPerPage = 1000

  return withAPIAction<FetchTasklaneRequest>({
    method: 'POST',
    // path,
    path: data.ProjectCode === 'null-MS' ? path : 'GetActivitySeeMore',
    data,
    token: `Bearer ${token}`,
  })(fetchTasklaneAsyncAction)
}
