import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { connect, useDispatch } from 'react-redux'
import SubnavBar from 'Components/Shared/SubnavBar'
import { Row, Col } from 'antd'
import { RouteComponentProps } from 'react-router-dom'
import RootState from 'typings/RootState'
import { BreadcrumbLink } from 'Components/Shared/BreadCrumb/BreadCrumb'
import { selectedProjectCodeSelector } from 'features/project/project.selector'
import {
  userEmailSelector,
  serviceTokenSelector,
} from 'features/sensitive/sensitive.selector'
import { selectDataList, selectEvent } from 'features/siricon/selector'
import projectHelper from 'utils/projectHelper'
import * as CMMenu from 'Components/CM/Menu'
import * as QCMenu from 'Components/QC/Menu'
import * as MCMenu from 'Components/Maincon/Menu'
import * as MPMenu from 'Components/Approval/MasterPlan/Menu'
import { SiriconModuleEnum } from 'features/types/common'
import { DataItem, GetDataListReq } from 'features/siricon/types'
import { getDataList } from 'features/siricon/api'
import { DropdownList } from 'Components/Shared/Form'
import Loading from 'Components/Shared/Loading'
import { LoadingView } from 'Components/Shared'
import DocumentIcon from 'Components/Shared/Icon/DocumentIcon'
import TopNav from 'Components/Shared/TopNav'
import PrimaryButton from 'Components/Shared/Button/PrimaryButton'

const breadcrumbs: BreadcrumbLink[] = [
  {
    url: '/',
    title: 'Home',
    icon: '/home.png',
    active: true,
    showTitle: false,
  },
  {
    url: '/',
    title: 'ผังขาย/สร้าง/โอน',
    active: false,
    showTitle: true,
  },
]

type Props = ReduxProps & DispatchProps & RouteComponentProps

const SiriconPlan: React.ComponentType<Props> = props => {
  const { data, event, token, ProjectCode, UserEmail } = props
  const [label, setLabel] = useState('')
  const [selectedFile, setSelectedFile] = useState({} as DataItem)

  const dispatch = useDispatch()
  const moduleCode = projectHelper.getModule()

  useEffect(() => {
    onGetDataList()
  }, [UserEmail, token, ProjectCode])

  const onFileChange = (file?: DataItem) => {
    if (file) {
      setLabel(file.ActualFileName)
      setSelectedFile(file)
    }
  }

  useEffect(() => {
    if (data.files.filter(file => file.FileType === 'IMAGE').length == 1) {
      let img = data.files.filter(file => file.FileType === 'IMAGE')
      onFileChange(img[0])
    } else {
      setLabel('')
      setSelectedFile({} as DataItem)
    }
  }, [data])

  const onFileMenu = () => {
    return data.files
      .filter(file => file.FileType === 'IMAGE')
      .map(file => {
        return {
          name: file.ActualFileName,
          value: file.ActualFileName,
        }
      })
  }

  const onGetDataList = () => {
    const req: GetDataListReq = {
      UserEmail,
      FileType: '',
      ProjectCode,
    }

    dispatch(getDataList(token, req))
  }

  const filter = (
    <FilterBox>
      <DropdownList
        placeholder="ผัง"
        label={label ? label : 'กรุณาเลือกไฟล์'}
        value={selectedFile.ActualFileName}
        menu={onFileMenu()}
        onChange={(val: any) => {
          onFileChange(data.files.find(file => file.ActualFileName === val))
        }}
      />
    </FilterBox>
  )
  const menu = () => {
    switch (moduleCode) {
      case SiriconModuleEnum.CMPJ:
        return <CMMenu.default selected="siriconPlan" />
      case SiriconModuleEnum.MasterPlan:
        return <MPMenu.default selected="siriconPlan" />
      case SiriconModuleEnum.QC:
        return (
          <>
            <TopNav title="QC" displayProject={true} secondaryTitle="" />
            <QCMenu.default selected="siriconPlan" />
          </>
        )
      case SiriconModuleEnum.Maincon:
        return <MCMenu.default selected="siriconPlan" />
    }
    return <></>
  }

  const content = selectedFile && (
    <ImageBox>
      <Row type="flex" justify="center" align="middle">
        {selectedFile?.FileType === 'IMAGE' && (
          <ImageWrapper>
            <img src={selectedFile.FileURL} />
          </ImageWrapper>
        )}
      </Row>
    </ImageBox>
  )

  return (
    <>
      <LoadingView isShow={event.IsLoading} />
      {menu()}
      <SubnavBar breadcrumbs={breadcrumbs} hideSearch={true} />
      <Space />
      <MainPage>
        <Row type="flex" justify="center">
          <Col span={10}>{filter}</Col>
          <Col style={{ marginLeft: '2rem' }}>
            <PrimaryButton
              text="ดาวน์โหลดไฟล์ PDF"
              onClick={() => {
                const filePDF = data.files.find(
                  file =>
                    file.ActualFileName === selectedFile.ActualFileName &&
                    file.FileType === 'PDF'
                )
                window.open(filePDF!.FileURL, '_blank')
              }}
            />
          </Col>
        </Row>
        <Space />
        <Row>{content}</Row>
      </MainPage>
    </>
  )
}

const MainPage = styled.div`
  padding: 0 120px;
  background-color: #f5f6fa;
  width: 100vw;
  -khtml-user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  overflow-x: scroll;
`

const Space = styled.div`
  margin: 20px 0;
`

const FilterBox = styled.div`
  margin: 0 12px 12px 0;
  width: 100%;
`

const ImageBox = styled.div`
  margin: 1rem;
`

const ImageWrapper = styled.div`
  height: 100%;
  img {
    object-fit: contain;
    width: 100%;
  }
`

const mapStateToProps = (state: RootState) => {
  return {
    data: selectDataList(state),
    event: selectEvent(state),
    token: serviceTokenSelector(state),
    ProjectCode: selectedProjectCodeSelector(state),
    UserEmail: userEmailSelector(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(SiriconPlan)
