import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'antd'
import { Link } from 'react-router-dom'
import { QCActivity } from 'features/maincon/dashboard/types'
import { CheckStatusIcon, UnitTaskStatus } from 'Components/Shared'
import { getQCTypeVersion } from 'utils/getText'
import { unitStatusIsPass } from 'utils/status-map'

interface Props {
  type?: string
  task: QCActivity
  onClick?: (code: string) => void
}

const UnitCard: React.FC<Props> = ({ type = '', task, onClick }) => {
  const isSelfCheck = task.LastCheckedModule === 'CM'

  let isPass = unitStatusIsPass(
    isSelfCheck,
    task.CheckStatus,
    task.DocumentStatus
  )

  let laneColor
  if (!isPass) {
    laneColor = <span className="lane-card-delayed" />
  }

  return (
    <Link to={`/qcreport/${task.Unit}/${task.QCTypeID}/${task.RequestID}`}>
      <MainCard>
        {laneColor}
        <Row type="flex" justify="space-between" align="bottom">
          <Col>
            <Title>{task.Unit}</Title>
            <Detail>{getQCTypeVersion(task)}</Detail>
          </Col>
          <Col span={8}>
            <CheckStatusIcon isSelfCheck={isSelfCheck} isMaincon={true} />
          </Col>
        </Row>
        <Line />
        <Row>
          <Col span={12}>
            <Detail>คะแนนมาตรฐาน</Detail>
            <UnitTaskStatus
              score={task.InspectionScore}
              status={task.CheckStatus}
              isShowStatus={true}
            />
          </Col>
          {!isSelfCheck && (
            <Col span={12}>
              <Detail>คะแนนเอกสาร</Detail>
              <UnitTaskStatus
                score={task.DocumentScore}
                status={task.DocumentStatus}
                isShowStatus={true}
              />
            </Col>
          )}
        </Row>
      </MainCard>
    </Link>
  )
}

const MainCard = styled.div`
  background: #fff;
  border-radius: 10px;
  padding: 12px 16px 16px 16px;
  background-clip: padding-box;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.03);
  margin-bottom: 12px;
  cursor: pointer;
  color: #000;
  width: 100%;
  position: relative;
`

const Title = styled.p`
  font-size: 20px;
  margin-bottom: 4px;
  font-family: 'DB Helvethaica X 75 Bd';
`

const Detail = styled.p`
  font-size: 18px;
  margin-bottom: 4px;
  font-family: 'DB Helvethaica X 55 Regular';
`

const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e8ebf0;
  margin: 5px 0;
`

export default UnitCard
