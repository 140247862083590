import RootState from 'typings/RootState'
import { Event } from '../../types/event'
import { AuditRoadDashboard } from './types'

export const selectAuditRoad = (state: RootState): AuditRoadDashboard =>
  state.qcRoad.auditRoad

export const selectEvent = (state: RootState): Event => state.qcRoad.event
export const selectActionEvent = (state: RootState): Event =>
  state.qcRoad.actionEvent

export const selectSorting = (state: RootState) => state.qcRoad.sorting
