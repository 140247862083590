import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { connect, useDispatch } from 'react-redux'
import { Layout } from 'antd'
import { RouteComponentProps } from 'react-router-dom'
import moment from 'moment'
import RootState from 'typings/RootState'
import {
  selectReport,
  selectDefect,
  selectEvent,
} from 'features/report/auditRoad/selector'

import { selectedProjectCodeSelector } from 'features/project/project.selector'
import {
  userEmailSelector,
  serviceTokenSelector,
} from 'features/sensitive/sensitive.selector'
import { selectUploadEvent } from 'features/uploadfile/selector'
import { SendEditReportToQCReq } from 'features/report/auditRoad/types'
import { sendEditReportToQC } from 'features/report/auditRoad/api'
import AuditRoadReport from 'Components/AuditRoad/Report'
import EventModal from 'Components/Shared/Modal/EventModal'

import Breadcrumbs from './Breadcrumbs'
import Footer from './Footer'

const { Content } = Layout

type Props = RouteComponentProps<{
  requestID: string
  checkno: string
}> &
  ReduxProps

const CmAuditRoadReport: React.ComponentType<Props> = props => {
  const [page, setPage] = useState('')
  const [refresh, setRefresh] = useState(0)
  const [enableModal, setEnableModal] = useState(false)
  const {
    ProjectCode,
    UserEmail,
    Token,
    report,
    defect,
    event,
    uploadEvent,
    history,
    match,
  } = props

  const { requestID, checkno } = match.params
  const RequestID = +requestID || 0
  const CheckNumber = +checkno || 0
  const dispatch = useDispatch()

  const getName = () => {
    switch (page) {
      case 'add.defect':
        return 'เพิ่ม Defect'
      case 'edit.defect':
        return 'แก้ไข Defect'
      case 'view.defect':
        return `หมุดที่ ${defect.PinNumber}`
      default:
        return undefined
    }
  }

  const gotoDashboard = () => {
    setEnableModal(false)
    history.push('/audit-road')
  }

  const closeEnableModal = () => setEnableModal(false)
  const onRefreshPage = () => {
    onClear()
    setRefresh(moment().unix())
  }

  const onClear = () => {
    setEnableModal(false)
    setPage('')
  }

  const onSendReqToQCEdit = () => {
    setEnableModal(true)
    const req: SendEditReportToQCReq = {
      UserEmail,
      ProjectCode,
      RequestID,
      CheckNumber,
    }

    dispatch(sendEditReportToQC(Token, req))
  }

  const readOnly = !(report.State === 'CM' && report.Status === 'REJT')

  return (
    <>
      <EventModal
        event={[event]}
        err={[uploadEvent]}
        enable={enableModal}
        successCalback={gotoDashboard}
        errorCalback={closeEnableModal}
      />

      <Breadcrumbs
        path={`/audit-road/report/${RequestID}/${CheckNumber}`}
        title="ตรวจรับมอบถนนและก่อนหมดประกัน"
        name={getName()}
        onBack={() => setPage('')}
      />
      <AuditRoadReport
        title={'ตรวจรับมอบถนนและก่อนหมดประกัน'}
        initPage={page}
        ProjectCode={ProjectCode}
        RequestID={RequestID}
        CheckNumber={CheckNumber}
        onPage={(name: string) => setPage(name)}
        addMode={false}
        readOnly={readOnly}
        isShowEditImg={!readOnly}
        refresh={refresh}
      />
      <Footer
        report={report}
        visible={page === ''}
        onSend={onSendReqToQCEdit}
      />
    </>
  )
}

const MainPage = styled.div`
  background-color: #f5f6fa;
`

const mapStateToProps = (state: RootState) => {
  return {
    ProjectCode: selectedProjectCodeSelector(state),
    Token: serviceTokenSelector(state),
    UserEmail: userEmailSelector(state),
    report: selectReport(state),
    defect: selectDefect(state),
    event: selectEvent(state),
    uploadEvent: selectUploadEvent(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(CmAuditRoadReport)
