import React, { useState, useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import RootState from 'typings/RootState'
import { connect, useDispatch } from 'react-redux'
import { getWbsPathMapSelector } from 'features/wbs/wbs.selector'
import {
  userEmailSelector,
  serviceTokenSelector,
} from 'features/sensitive/sensitive.selector'
import { selectUploadEvent } from 'features/uploadfile/selector'
import {
  getMSActivityById,
  saveMSActionActivity,
} from 'features/approval/activity/api'
import {
  UpdateActivityReq,
  MSGetActivityReq,
} from 'features/approval/activity/types'
import {
  selectEvent,
  selectMsDetail,
} from 'features/approval/activity/selector'
import { selectProjectCode } from 'features/approval/dashboard/selector'
import { EventModal } from 'Components/Shared'
import Breadcrumbs from './Breadcrumbs'
import ActivityDetail from './ActivityDetail'
import ImageDisplay from 'Components/Shared/ImageDisplay'
import DocumentDisplay from 'Components/Shared/DocumentDisplay'
import ThumbnailDisplay from 'Components/Shared/ThumbnailDisplay'
import { HistoryModal } from 'Components/Activity'
import HistoryTable from 'Components/Pages/ActivityDetailPage/ActivityDetail/HistoryTable'
import { getActivityHistoryRequest } from 'features/activity/activity.api'

import { FetchActivityHistoryRequest } from 'features/activity/activity.types'

import {
  getActivityHistory,
  getActivityHistoryError,
} from 'features/activity/activity.selector'

type FormProps = {
  code: string
}

type Props = FormProps & RouteComponentProps<{ code: string }> & ReduxProps

const MSActivity = (props: Props) => {
  const [lastPage, setLastPage] = useState('')
  const [page, setPage] = useState('')
  const [readOnly, setReadOnly] = useState(false)
  const [enable, setEnable] = useState(false)
  const [imageSelected, setImageSelected] = useState(0)
  const [imageList, setImageList] = useState([] as any[])
  const [documentList, setDocumentList] = useState([] as any[])
  const [showHistoryModal, setShowHistoryModal] = useState(false)
  const {
    Token,
    UserEmail,
    ProjectCode,
    match,
    originalActivity,
    historyData,
    historyEvent,
    activityEvent,
    history,
    wbsPathMap,
  } = props

  const wbsName = wbsPathMap[originalActivity.WbsID]
  const ActivityID = match.params.code
  const projectCode = ProjectCode + '-MS'
  const onPageClose = () => {
    setPage(lastPage)
    if (lastPage !== '') {
      setLastPage('')
    }
  }

  const dispatch = useDispatch()

  useEffect(() => {
    if (ProjectCode === '') {
      gotoDashboard()
    }
  }, [ProjectCode])

  useEffect(() => {
    setEnable(false)
  }, [originalActivity])

  useEffect(() => {
    getActivity()
    getHistory()
  }, [UserEmail, ProjectCode, ActivityID, Token, dispatch])

  const getActivity = () => {
    setEnable(true)
    const data: MSGetActivityReq = {
      ProjectCode: projectCode,
      UserEmail,
      ActivityID,
    }

    dispatch(getMSActivityById(Token, data))
  }

  const getHistory = () => {
    const data: FetchActivityHistoryRequest = {
      ProjectCode: projectCode,
      UserEmail,
      ActivityID,
    }

    dispatch(getActivityHistoryRequest(data, Token))
  }

  const callSaveActionActivity = (action: 'APPV' | 'REJT', remark: string) => {
    setEnable(true)
    const data: UpdateActivityReq = {
      ProjectCode: projectCode,
      UserEmail,
      ActivityID,
      Action: action,
      Reason: remark,
      Progress: originalActivity.LastReqProgress,
    }

    dispatch(saveMSActionActivity(data, Token))
  }

  const onAcceptTask = () => {
    callSaveActionActivity('APPV', '')
  }

  const onRejectTask = (remark: string) => {
    callSaveActionActivity('REJT', remark)
  }

  const gotoDashboard = () => {
    setEnable(false)
    history.push('/ms/list')
  }

  const onImageOpen = (list?: any[], readOnly?: boolean) => {
    setLastPage(page)
    setPage('images')
    setImageList(list ? list : [])
    setReadOnly(readOnly || false)
  }

  const onDocumentOpen = (list?: any[], readOnly?: boolean) => {
    setLastPage(page)
    setPage('documents')
    setReadOnly(readOnly || false)
    setDocumentList(list ? list : [])
  }

  const onThumbnailOpen = (index: number, list?: any[], readOnly?: boolean) => {
    setLastPage(page)
    setPage('thumbnails')
    setImageList(list ? list : [])
    setReadOnly(readOnly || false)
    setImageSelected(index)
  }

  const historyModal = (
    <HistoryModal
      isShow={showHistoryModal}
      onCloseModal={() => setShowHistoryModal(false)}
      content={
        <HistoryTable
          items={historyData}
          fetchHistoryError={historyEvent}
          onImageOpen={onImageOpen}
          onDocumentOpen={onDocumentOpen}
        />
      }
    />
  )

  switch (page) {
    case 'images':
      return (
        <ImageDisplay
          onClose={onPageClose}
          readOnly={true}
          images={imageList}
          onThumbnailOpen={onThumbnailOpen}
          onChange={() => {}}
          numberOfImage={50}
          breadcrumb={
            <Breadcrumbs
              activity={originalActivity}
              name="รูปภาพ"
              onClose={onPageClose}
            />
          }
        />
      )
    case 'documents':
      return (
        <DocumentDisplay
          onClose={onPageClose}
          readOnly={true}
          documents={documentList}
          onChange={() => {}}
          numberOfFile={50}
          breadcrumb={
            <Breadcrumbs
              activity={originalActivity}
              name="เอกสาร"
              onClose={onPageClose}
            />
          }
        />
      )
    case 'thumbnails':
      return (
        <ThumbnailDisplay
          onClose={onPageClose}
          readOnly={true}
          images={imageList}
          index={imageSelected}
          onChange={() => {}}
        />
      )
    default:
      return (
        <>
          <EventModal
            name="defect"
            event={[activityEvent]}
            enable={enable}
            successCalback={() => gotoDashboard()}
          />
          <ActivityDetail
            onImageOpen={onImageOpen}
            onDocumentOpen={onDocumentOpen}
            onThumbnailOpen={onThumbnailOpen}
            originalData={originalActivity}
            onHistoryOpen={() => setShowHistoryModal(true)}
            onApproveTask={onAcceptTask}
            onRejectTask={remark => onRejectTask(remark)}
            isLoading={activityEvent.IsLoading}
            wbs={wbsName}
            historyData={historyData}
          />
          {historyModal}
        </>
      )
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    originalActivity: selectMsDetail(state),
    UserEmail: userEmailSelector(state),
    Token: serviceTokenSelector(state),
    ProjectCode: selectProjectCode(state),
    wbsPathMap: getWbsPathMapSelector(state),
    historyData: getActivityHistory(state),
    historyEvent: getActivityHistoryError(state),
    activityEvent: selectEvent(state),
    uploadEvent: selectUploadEvent(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MSActivity)
