import { takeEvery, all, put, take } from 'redux-saga/effects'
import { getType } from 'typesafe-actions'
import * as actions from '../local/actions'
import * as actionsMaster from '../master/actions'
import { getActivityByDocumentCheckList } from './api'

function* getActivityDocAfterUpdate(
  action: ReturnType<typeof actions.getActivityDocAfterUpdate>
) {
  const { projectCode, unitType } = action.payload
  yield take(actionsMaster.updateCheckListAction.success)
  yield put(getActivityByDocumentCheckList(projectCode, unitType))
}

function* allSaga() {
  yield all([
    takeEvery(
      getType(actions.getActivityDocAfterUpdate),
      getActivityDocAfterUpdate
    ),
  ])
}

export default allSaga
