import React, { useCallback, useState } from 'react'
import RootState from 'typings/RootState'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { RouteComponentProps } from 'react-router-dom'
import { Layout } from 'antd'
import { translate } from 'i18n'
import TKeys from 'i18n/translationKey'

import LoginButton from 'Components/buttons/LoginButton'
import { version } from 'config'
import file from 'utils/file'
import Loading from '../Shared/Loading'

import {
  serviceTokenIsSubmitSelector,
  userEmailSelector,
  serviceTokenErrorSelector,
} from 'features/sensitive/sensitive.selector'

import {
  changeAccesstoken,
  changeUserEmail,
  updateServiceTokenError,
} from 'features/sensitive/sensitive.actions'
import { messageSelector } from 'features/login/login.selector'
import MainconLoginPage from 'Components/Pages/MainconLoginPage'

const logo = require('assets/img/logo.png')

const { Footer, Content } = Layout

type LoginProps = {
  logged: boolean
  onRequestEmailToken(): void
}

type Props = ReduxProps & RouteComponentProps & DispatchProps & LoginProps

const Login: React.FunctionComponent<Props> = ({
  connectionInProgress,
  userEmail = 'unknown',
  loginError,
  messageError,
}) => {
  const [isMainConLogin, setIsMainConLogin] = useState(false)

  const onRefCanvas = useCallback(async node => {
    if (node) {
      const img = await file.base64ToImage(logo)
      const ctx = node.getContext('2d')
      ctx.drawImage(img, 0, 0, 240, 240)
    }
  }, [])

  const err = loginError && loginError !== '' ? loginError : messageError
  let content
  if (connectionInProgress) {
    content = (
      <div style={{ width: '100%', textAlign: 'center' }}>
        <Loading color="white" size={40} />
      </div>
    )
  } else if (err !== '') {
    const msg = translate(TKeys.Login.service_token_error)
    content = (
      <div className="error">{`${err || msg} Logged in as ${userEmail}`}</div>
    )
  }

  const loginMainconTxt = (
    <LoginOther>
      สำหรับผู้รับเหมา กรุณา
      <span onClick={() => setIsMainConLogin(true)}>คลิกที่นี่</span>
    </LoginOther>
  )

  const loginMSTxt = (
    <LoginOther>
      <span onClick={() => setIsMainConLogin(false)}>
        กลับไปหน้า เข้าสู่ระบบด้วย Sansiri account
      </span>
    </LoginOther>
  )

  return (
    <>
      <Content>
        <PageDiv>
          <MyCanvas ref={onRefCanvas} height={240} width={240} />
          {isMainConLogin ? <MainconLoginPage /> : <LoginButton />}
          {content}
          {isMainConLogin ? loginMSTxt : loginMainconTxt}
        </PageDiv>
      </Content>
      <Footer className="app-footer">{version}</Footer>
    </>
  )
}

const PageDiv = styled.div`
  padding-bottom: 46px;
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #003168;
`

const MyCanvas = styled.canvas`
  width: 240px;
  height: 240px;
  margin: 0 auto;
`

const LoginOther = styled.div`
  font-family: 'DB Helvethaica X 55 Regular';
  font-size: 20px;
  margin-top: 20px;
  color: #fff;
  span {
    margin-left: 10px;
    text-decoration: underline;
    cursor: pointer;
  }
`

const mapStateToProps = (state: RootState) => {
  return {
    connectionInProgress: serviceTokenIsSubmitSelector(state),
    messageError: messageSelector(state),
    loginError: serviceTokenErrorSelector(state),
    userEmail: userEmailSelector(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {
  changeAccesstoken,
  changeUserEmail,
  updateServiceTokenError,
}

type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(Login)
