import React from 'react'
import { Col } from 'antd'
import styled from 'styled-components'
import { TableRow } from 'Components/Shared/Table'
import { ReportLink as Link } from 'Components/AdminQC/Report/Components'
import { DataItem } from 'features/adminReport/commonArea/types'
import { toDateText, toMomentDateTime } from 'utils/datetime'

type DefaultProps = {
  item: DataItem
}

type Props = DefaultProps

const RowItem: React.FC<Props> = ({ item }) => {
  const { ProjectCode, Unit, QCTypeID, CheckNumber, RequestID, Floor } = item
  const path = `/report/zone/${ProjectCode}/${Unit}/${Floor}/${QCTypeID}/${CheckNumber}/${RequestID}`

  const renderData = (val: any) => {
    return <Col span={2}>{val}</Col>
  }

  return (
    <RowModel>
      <Link to={path}>
        <TableRow minHeight={60} spaceBetweenRow={8} paddingLeft={24}>
          {renderData(<TxtReg>{item.Tower}</TxtReg>)}
          {renderData(<TxtReg>{item.Floor}</TxtReg>)}
          {
            <Col span={3}>
              <Bold>{item.Unit}</Bold>
            </Col>
          }
          {renderData(<TxtReg>{item.CheckNumber}</TxtReg>)}
          {renderData(
            <TxtReg>{toDateText(toMomentDateTime(item.CheckedDate))}</TxtReg>
          )}
          {renderData(<TxtReg>{item.NotPassByCM}</TxtReg>)}
          {renderData(<TxtReg>{item.PassByCM}</TxtReg>)}
          {renderData(<TxtReg>{item.NotPassByQC1}</TxtReg>)}
          {renderData(<TxtReg>{item.PassByQC1}</TxtReg>)}
          {renderData(<TxtReg>{item.NotPassByQC2}</TxtReg>)}
          {
            <Col span={1}>
              <TxtReg>{item.PassByQC2}</TxtReg>
            </Col>
          }
          {<Col span={1}>{item.NumOfFail}</Col>}
          {<Col span={1}>{item.NumOfPass}</Col>}
        </TableRow>
      </Link>
    </RowModel>
  )
}

const RowModel = styled.div`
  cursor: pointer;
`

const Bold: any = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  color: #000;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  padding-right: 5px;
`

const TxtReg = styled(Bold)`
  font-family: 'DB Helvethaica X 55 Regular';
`

const PassScore = styled(Bold)`
  color: #63cea6;
`

const FailScore = styled(Bold)`
  color: #d0494c;
`

export default RowItem
