import React from 'react'
import styled from 'styled-components'
import { Row, Col, Layout } from 'antd'
import { PrimaryButton } from 'Components/Shared/Button'
import { numberFormat } from 'utils/format'

const { Footer } = Layout

interface Props {
  score?: number
  isPass?: boolean
  disabled?: boolean
  onSave?: () => void
  isReject?: boolean
}

const PageFooter: React.FC<Props> = props => {
  const {
    score = 0,
    isPass = true,
    disabled = false,
    onSave,
    isReject = false,
  } = props

  const txt = numberFormat(score)
  const content = isPass ? (
    <Score>{txt}</Score>
  ) : (
    <ScoreNotPass>{txt}</ScoreNotPass>
  )
  return (
    <Footer className="detail-page-footer">
      <FooterContent>
        <Row type="flex" justify="space-between">
          <Col>
            <Description>คะแนนรวม {content} คะแนน</Description>
          </Col>
          <Col>
            <Button>
              <PrimaryButton
                text={isReject == true ? 'Reject' : 'บันทึก'}
                btnType={isReject == true ? 'Reject' : 'Primary'}
                disabled={disabled}
                onClick={onSave}
              />
            </Button>
          </Col>
        </Row>
      </FooterContent>
    </Footer>
  )
}

const FooterContent = styled.div`
  max-width: 1110px;
  margin: 0 auto;
  color: #000;
  font-size: 20px;
  padding-right: 20px;
`

const Button = styled.div`
  width: 200px;
  float: left;
`

const Description = styled.div`
  font-family: 'DB Helvethaica X 55 Regular';
  font-size: 28px;
  color: #000;
`

const Score: any = styled.span`
  font-family: 'DB Helvethaica X 75 Bd';
  color: #5bc79e
  padding: 0 8px;
`

const ScoreNotPass = styled(Score)`
  color: #d0494c;
`

export default PageFooter
