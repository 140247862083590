import RootState from 'typings/RootState'
import { createSelector } from 'reselect'
import { DataList, ProjectItem } from './types'
import { Event } from '../../types/event'

export const selectDataList = (state: RootState): DataList =>
  state.adminProject.dataList

export const selectExportData = (state: RootState): DataList =>
  state.adminProject.exportData

export const selectEvent = (state: RootState): Event => state.adminProject.event

export const selectActiveProjectList = (state: RootState): ProjectItem[] =>
  state.adminProject.activeProjectList
