import { getType } from 'typesafe-actions'
import * as actions from './actions'
import produce from 'immer'
import { cloneDeep } from 'lodash'
import parseErrorMessage from 'utils/parseErrorMessage'

import { StoreState, ActivityAction, ActivityItem } from './types'
import { Event } from 'features/types/event'

import { Activity } from '../../cmpj/activity/types'

const mockOriginal = {} as Activity

const initEvent = {
  IsLoading: false,
  MessageSuccess: undefined,
  SubmitError: undefined,
  SubmitSuccess: false,
  SubmitType: undefined,
} as Event

const initMsDetail = {
  ActivityID: '',
  ActivityName: '',
  ActualFinishDate: '',
  ActualStartDate: '',
  BaselineFinishDate: '',
  BaselineStartDate: '',
  ExpectFinishDate: '',
  FinishDate: '',
  LastCurProgress: 0,
  LastReqProgress: 0,
  Progress: 0,
  Remark: '',
  SowID: '',
  SowName: '',
  StartDate: '',
  TaskStatus: '',
  WbsID: 0,
} as ActivityItem

const initialState: StoreState = {
  detail: cloneDeep(mockOriginal),
  event: cloneDeep(initEvent),
  msDetail: cloneDeep(initMsDetail),
}

export default function(
  state: StoreState = initialState,
  action: ActivityAction
): StoreState {
  return produce(state, draft => {
    switch (action.type) {
      case getType(actions.getActivity.request):
        draft.detail = cloneDeep(mockOriginal)
        draft.event = cloneDeep(initEvent)
        draft.event.IsLoading = true
        break
      case getType(actions.getActivity.success):
        draft.event.IsLoading = false
        draft.detail = action.payload.ResponseData
        break
      case getType(actions.getMSActivity.request):
        draft.msDetail = cloneDeep(initMsDetail)
        draft.event = cloneDeep(initEvent)
        draft.event.IsLoading = true
        break
      case getType(actions.getMSActivity.success):
        draft.event.IsLoading = false
        if (action.payload.ResponseData) {
          draft.msDetail = action.payload.ResponseData
        }

        break
      case getType(actions.getActivity.failure):
      case getType(actions.getMSActivity.failure):
        draft.event.IsLoading = false
        draft.event.SubmitError = parseErrorMessage(action.payload)
        break
      case getType(actions.updateActivity.request):
      case getType(actions.updateMSActivity.request):
        draft.event = cloneDeep(initEvent)
        draft.event.IsLoading = true
        break
      case getType(actions.updateActivity.success):
      case getType(actions.updateMSActivity.success):
        draft.event.IsLoading = false
        draft.event.SubmitError = undefined
        draft.event.SubmitSuccess = true
        draft.event.MessageSuccess = 'ทำรายการสำเร็จ'
        break
      case getType(actions.updateActivity.failure):
      case getType(actions.updateMSActivity.failure):
        draft.event.IsLoading = false
        draft.event.SubmitError = parseErrorMessage(action.payload)
        break
      default:
        return state
    }
  })
}
