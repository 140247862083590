import React, { useState, useEffect } from 'react'
import RootState from 'typings/RootState'
import { connect, useDispatch } from 'react-redux'
import { Row, Col, Layout } from 'antd'
import styled from 'styled-components'
import { DefectColor } from 'features/types/status'
import EventModal from 'Components/Shared/Modal/EventModal'

import {
  selectReport,
  selectDefect,
  selectCheckListQC,
} from 'features/report/constructRoad/selector'

import { DefectItem, CheckListForQC } from 'features/report/constructRoad/types'

import { getDefectColor } from 'utils/color-map'

import DefectStatus from 'Components/Defect/DefectStatus'
import DefectScore from 'Components/Defect/DefectScore'
import DefectImage from '../DefectImage'

const { Content } = Layout

type Props = {
  readOnly?: boolean
} & ReduxProps

const DefectView: React.FunctionComponent<Props> = props => {
  const { report, defect, checkList, readOnly = false } = props

  const title: string = `หมุดที่ ${defect.PinNumber}`
  const renderInfo = (title: string, value: string, span: number = 8) => {
    return (
      <Col span={span}>
        <DisplayItem>
          <label>{title}</label>
          <p>{value ? value : '-'}</p>
        </DisplayItem>
      </Col>
    )
  }

  const canChange =
    defect.PinStatus === 'ToBeFix' && defect.VerifyStatus !== 'Approve'

  return (
    <>
      <Content className="mid-content">
        <div className="main-content activity-detail">
          <HeaderTitle>
            <Row type="flex" justify="space-between">
              <Col>
                <Title>
                  {title}
                  <DefectStatus pinStatus={defect.PinStatus} />
                </Title>
              </Col>
              <Col>
                <Desc>
                  <DefectScore
                    pinStatus={defect.PinStatus}
                    pinScore={defect.Score}
                  />
                  <label>คะแนน</label>
                </Desc>
              </Col>
            </Row>
          </HeaderTitle>
          <MainData>
            <Row>
              {renderInfo('ประเภทงาน', defect.WorkTypeName)}
              {renderInfo('ลักษณะงานหลัก', defect.JobTypeName)}
              {renderInfo('ลักษณะงานรอง', defect.SubJobTypeName)}
            </Row>
            <Row>
              {renderInfo('รายละเอียด', defect.DetailName)}
              {renderInfo('ลักษณะ Defect', defect.DefectTypeName)}
              {renderInfo('หมายเหตุ', defect.Remark)}
            </Row>
          </MainData>
          <DefectImage
            defect={defect}
            viewMode={true}
            canUpAfterImg={!readOnly && canChange}
          />
        </div>
      </Content>
    </>
  )
}

const Title = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 28px;

  span {
    font-family: 'DB Helvethaica X 55 Regular';
    font-size: 16px;
    color: white;
    padding: 1px 10px;
    border-radius: 10px;
    margin-left: 12px;
  }
`

const MainData = styled.div`
  border-radius: 5px;
  border: solid 1px #eeeff2;
  background-color: white;
  padding: 24px;
`

const DisplayItem = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 20px;
  padding: 0 5px;
  p {
    font-family: 'DB Helvethaica X 55 Regular';
  }
`

const UnitInfo = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 28px;
  margin-bottom: 16px;
`

const Desc = styled.div`
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  label {
    font-family: 'DB Helvethaica X 55 Regular';
    font-size: 18px;
  }
`

const HeaderTitle = styled.div`
  margin-bottom: 10px;
`

const Input = styled.div`
  margin: 15px;
`

const Textarea = styled.textarea`
  width: 100%;
  border-radius: 5px;
  height: 96px;
  padding: 16px 19px;
  font-family: 'DB Helvethaica X 55 Regular';
  font-size: 20px;
  border: 1px solid #e4e7eb;
  outline: #e4e7eb auto 1px;

  &::focus {
    outline: #e4e7eb auto 1px;
  }

  &::placeholder {
    font-family: 'DB Helvethaica X 55 Regular';
    font-size: 20px;
  }
`

const Text = styled.div`
  padding: 0px 15px;
`

const mapStateToProps = (state: RootState) => {
  return {
    report: selectReport(state),
    defect: selectDefect(state),
    checkList: selectCheckListQC(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DefectView)
