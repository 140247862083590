import React from 'react'
import { Col } from 'antd'
import styled from 'styled-components'
import { TableRow } from 'Components/Shared/Table'
import { ReportLink as Link } from 'Components/AdminQC/Report/Components'
import { DataItem } from 'features/adminReport/constructRoad/types'
import { StatusTag } from 'Components/AdminQC/Report/Components'
import { numberFormat } from 'utils/format'
import { toDateText, toMomentDateTime } from 'utils/datetime'
import mapUnitStatus, { unitStatusIsPass } from 'utils/status-map'

type DefaultProps = {
  item: DataItem
}

type Props = DefaultProps

const RowItem: React.FC<Props> = ({ item }) => {
  const { ProjectCode, CheckNumber, Unit, RequestID, QCTypeID } = item
  const path = `/report/constructroad/${ProjectCode}/${Unit}/${QCTypeID}/${CheckNumber}/${RequestID}`

  const renderData = (val: any, span: number = 3) => {
    return <Col span={span}>{val}</Col>
  }

  const statusText = mapUnitStatus(item.CheckStatus).status
  const isPass = unitStatusIsPass(true, item.CheckStatus)

  const scoreFormat = numberFormat(item.InspectionScore)
  const score = isPass ? (
    <PassScore>{scoreFormat}</PassScore>
  ) : (
    <FailScore>{scoreFormat}</FailScore>
  )

  return (
    <RowModel>
      <Link to={path}>
        <TableRow minHeight={60} spaceBetweenRow={8} paddingLeft={24}>
          {renderData(
            <Bold>
              {item.ProjectCode}-{item.ProjectNameTH}
            </Bold>
          )}
          {renderData(<TxtReg>{item.Unit}</TxtReg>, 2)}
          {renderData(<TxtReg>{item.QCTypeName}</TxtReg>)}
          {renderData(<TxtReg>{item.CheckNumber}</TxtReg>, 2)}
          {renderData(<TxtReg>{item.Progress}%</TxtReg>, 2)}
          {renderData(
            <TxtReg>{toDateText(toMomentDateTime(item.InspectDate))}</TxtReg>
          )}
          {renderData(score)}
          {renderData(<StatusTag text={statusText} isPass={isPass} />)}
          {renderData(<TxtReg>{item.CheckedBy}</TxtReg>)}
        </TableRow>
      </Link>
    </RowModel>
  )
}

const RowModel = styled.div`
  cursor: pointer;
`

const Bold: any = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  color: #000;
  font-size: 20px;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  padding-right: 5px;
`

const TxtReg = styled(Bold)`
  font-family: 'DB Helvethaica X 55 Regular';
`

const PassScore = styled(Bold)`
  color: #5bc79e;
`

const FailScore = styled(Bold)`
  color: #d0494c;
`

export default RowItem
