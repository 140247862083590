import React, { useState } from 'react'
import { Col } from 'antd'
import styled from 'styled-components'

import { TableRow } from '../../Shared/Table'

type DefaultProps = {}
type Props = DefaultProps

const RowItem: React.FC<Props> = props => {
  return (
    <TableRow>
      <Col lg={4}>1</Col>
      <Col lg={4}>Id</Col>
      <Col lg={4}>Name</Col>
      <Col lg={4}>ตึก</Col>
      <Col lg={3}>ประเภท</Col>
    </TableRow>
  )
}

export default RowItem
