import React from 'react'
import { Button } from 'antd'
import styled from 'styled-components'

interface Props {
  text: any
  loading?: boolean
  disabled?: boolean
  onClick?: () => void
  btnType?: 'Primary' | 'Cancel' | 'Approve' | 'Reject'
}

const PrimaryButton: React.FC<Props> = props => {
  const {
    text,
    loading = false,
    disabled = false,
    onClick,
    btnType = 'Primary',
  } = props

  const button = (
    <Button
      type="default"
      className="btn"
      loading={loading}
      disabled={disabled}
      onClick={evt => {
        if (!onClick) return
        evt.stopPropagation()
        evt.preventDefault()
        onClick()
      }}
    >
      {text}
    </Button>
  )

  switch (btnType) {
    case 'Primary':
      return <DefaultButton>{button}</DefaultButton>
    case 'Cancel':
      return <CancelButton>{button}</CancelButton>
    case 'Approve':
      return <ApproveButton>{button}</ApproveButton>
    case 'Reject':
      return <RejectButton>{button}</RejectButton>
    default:
      return <DefaultButton>{button}</DefaultButton>
  }
}

const ButtonSection: any = styled.div`
  .btn,
  .btn:hover,
  .btn:focus {
    text-align: center;
    width: 100%;
    height: 50px;
    border-radius: 5px;
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.03);
    background-color: #003168;
    font-size: 20px;
    font-family: 'DB Helvethaica X 75 Bd';
    color: #fff;
    cursor: pointer;
  }

  .ant-btn[disabled],
  .ant-btn[disabled]:hover,
  .ant-btn[disabled]:focus {
    color: #fff;
    background-color: rgba(0, 49, 104, 0.2);
    border: none;
    cursor: not-allowed;
  }

  .ant-btn:focus,
  .ant-btn:hover {
    color: #fff;
    background-color: #184b85;
  }
`

const DefaultButton = styled(ButtonSection)`
  .btn,
  .btn:hover,
  .btn:focus {
    background-color: #003168;
    border: none;
  }
  .ant-btn:focus,
  .ant-btn:hover {
    background-color: #003168;
  }
`

const CancelButton = styled(ButtonSection)`
  .btn,
  .btn:hover,
  .btn:focus {
    background-color: #d0494c;
    border: none;
  }
  .ant-btn:focus,
  .ant-btn:hover {
    background-color: #d0494c;
  }
`

const RejectButton = styled(ButtonSection)`
  .btn,
  .btn:hover,
  .btn:focus {
    background-color: #d0494c;
    border: none;
  }
  .ant-btn:focus,
  .ant-btn:hover {
    background-color: #d0494c;
  }
  .ant-btn[disabled] {
    background-color: #f5d6d7;
  }
`

const ApproveButton = styled(ButtonSection)`
  .btn,
  .btn:hover,
  .btn:focus {
    background-color: #5bc79e;
    border: none;
  }
  .ant-btn:focus,
  .ant-btn:hover {
    background-color: #5bc79e;
  }
`

export default PrimaryButton
