import React from 'react'
import styled from 'styled-components'
import { Line } from 'Components/Shared/Chart'
import { Col, Row } from 'antd'
import moment from 'moment'
import { DataList, DataItem, ScoreList } from 'features/adminReport/score/types'
import { getRandomColor } from 'utils/color-map'

type Props = {
  datas: DataList
  qcTypeID?: string
}

const LineChart: React.ComponentType<Props> = props => {
  const { datas, qcTypeID = '' } = props

  const sortData = (months: any[]) => {
    return months.sort((a: any, b: any) => {
      return moment(a).diff(b)
    })
  }

  const formatDate = (date: string) => {
    return moment(date, 'MM/YYYY').format('MMM YYYY')
  }

  const getMonths = () => {
    let months = [] as any[]
    datas.List.forEach(item => {
      item.ScoreList.forEach(score => {
        const date = formatDate(score.CheckedMonth)
        const dup = months.find((i: any) => i === date)
        if (!dup) {
          months.push(date)
        }
      })
    })

    return sortData(months)
  }

  const months = getMonths()

  const getScore = (scoreList: ScoreList[]) => {
    let score = [] as any[]
    months.forEach((month: any) => {
      const data = scoreList.find(i => month === formatDate(i.CheckedMonth))
      score.push(data ? data.Score : null)
    })

    return score
  }

  const getDatas = () => {
    let scoreDatas = [] as any[]
    datas.List.forEach((project: DataItem, index: number) => {
      const color = getRandomColor(index)
      scoreDatas.push({
        label: `${project.ProjectCode}-${project.ProjectNameTH}`,
        data: getScore(project.ScoreList),
        fill: false,
        backgroundColor: color,
        borderColor: color,
      })
    })

    return scoreDatas
  }

  const data = () => {
    return {
      labels: months,
      datasets: getDatas(),
    }
  }

  // config graph
  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: false,
          },
        },
      ],
    },
  }

  return (
    <Content>
      <Row type="flex" justify="space-between">
        <Col>
          <Title>ตารางแสดงคะแนนเฉลี่ยสะสม {qcTypeID && `(${qcTypeID})`}</Title>
        </Col>
        <Col>
          <Desc>วันที่เริ่มใช้ระบบ {datas.Date}</Desc>
        </Col>
      </Row>
      <Graph>
        <Line data={data} options={options} />
      </Graph>
    </Content>
  )
}

const Title = styled.span`
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 24px;
  color: #000;
`

const Desc = styled.span`
  font-family: 'DB Helvethaica X 55 Regular';
  font-size: 18px;
  color: #8b99a7;
`

const Graph = styled.div`
  margin-top: 32px;
`

const Content = styled.div`
  background-color: #fff;
  padding: 32px;
  border-radius: 8px;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.03);
`

export default LineChart
