import React, { useState } from 'react'
import { Layout, Row, Col } from 'antd'
import styled from 'styled-components'
import { translate } from 'i18n'
import TKeys from 'i18n/translationKey'
import imgCheck from 'assets/img/ic-check.png'
import { InputText } from '../Input'

type ImageList = {
  Image: any
  Remark: string
}

type Props = {
  readOnly: boolean
  images: ImageList[]
  onClose: () => void
  onThumbnailOpen: (index: number) => void
  onChange: (list: ImageList[]) => void
  selectedImage?: any[]
  onSelectImage: (image: any) => void
}

const ChooseImageMode: React.ComponentType<Props> = props => {
  const { images, onChange, selectedImage = [], onSelectImage } = props

  const textPicture = translate(TKeys.Common.picture)

  let title
  title = (
    <Col span={24}>
      <Title className="std-font-bold">
        <label>{textPicture}</label>
      </Title>
    </Col>
  )

  const isSelected = (image: any) => {
    const img = selectedImage.find(item => item.Image.FileID === image.FileID)
    return img ? true : false
  }

  const content = images.map((item, index) => {
    let btn
    let imgDiv
    let img =
      item.Image.FileID && item.Image.FileURL
        ? item.Image.FileURL
        : item.Image.text
    const hasChecked = isSelected(item.Image)
    let click
    if (hasChecked) {
      btn = (
        <Checked src={imgCheck} alt="" onClick={() => onSelectImage(item)} />
      )
    } else {
      btn = <Unchecked onClick={() => onSelectImage(item)} />
    }

    imgDiv = <LinkImage src={img} alt="" onClick={click} />
    return (
      <Col span={6} style={css.col} key={index}>
        {imgDiv}
        {btn}
        <InputArea>
          <InputText
            prefix=""
            value={item.Remark}
            placeholder="หมายเหตุ"
            onChange={(val: string) => {
              images[index].Remark = val
              onChange(images)
            }}
            disabled={true}
          />
        </InputArea>
      </Col>
    )
  })

  return (
    <>
      <Layout.Content className="mid-content">
        <div className="main-content activity-detail">
          <DataArea>
            <Row style={{ marginBottom: '24px' }}>{title}</Row>
            <Row>{content}</Row>
          </DataArea>
        </div>
      </Layout.Content>
    </>
  )
}

const css: any = {
  lastCol: {
    width: '260px',
    marginBottom: '22px',
  },
  col: {
    textAlign: 'right',
    width: '260px',
    marginRight: '16px',
    marginBottom: '22px',
  },
}

const Title = styled.div`
  height: 30px;
  label {
    font-size: 28px;
    color: black;
    position: absolute;
    bottom: 0;
  }

  span {
    font-size: 18px;
    color: #003168;
    position: absolute;
    bottom: 0;
    right: 0;
  }

  span:hover {
    cursor: pointer;
  }
`

const CssImg = `
  width: 260px;
  height: 260px;
  object-fit: cover;
  border-radius: 5px;
`

const LinkImage = styled.img`
  ${CssImg}
  :hover {
    cursor: pointer;
  }
`

const Checked = styled.img`
  width: 24px;
  height: 24px;
  object-fit: contain;
  position: absolute;
  z-index: 2;
  right: 8px;
  bottom: 70px;
  :hover {
    cursor: pointer;
  }
`

const Unchecked = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid white;
  position: absolute;
  z-index: 2;
  right: 8px;
  bottom: 70px;
  background-color: rgba(0, 0, 0, 0.3);
  :hover {
    cursor: pointer;
  }
`

const InputArea = styled.div`
  margin-top: 16px;
  .ant-input {
    text-align: left !important;
  }
`

const DataArea = styled.div`
  //padding-bottom: 20px;
`

export default ChooseImageMode
