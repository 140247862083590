import React, { useState } from 'react'
import { Row, Col } from 'antd'
import styled from 'styled-components'

import ThumbnailDisplay from 'Components/Shared/ThumbnailDisplay'

import ImgPicker from './ImgPicker'

type Props = {
  onImage?: (image: any, name: string) => void
  viewMode?: boolean
  defect: any
  canUpAfterImg?: boolean
  content?: any
}

const DefectImage: React.FunctionComponent<Props> = props => {
  const {
    onImage = (image: any, name: string) => {},
    viewMode = false,
    defect,
    canUpAfterImg = true,
    content,
  } = props
  const [previewImg, setPreviewImg] = useState(false)
  const [previewType, setPreviewType] = useState('' as
    | 'before'
    | 'after'
    | 'location')
  const [currentImg, setCurrentImg] = useState([] as any)
  const afterImgViewMode = viewMode && !canUpAfterImg

  const isAfterImg = previewType === 'after'
  const readonly = isAfterImg ? afterImgViewMode : viewMode
  const thumnailPreview = (
    <ThumbnailDisplay
      onClose={() => setPreviewImg(false)}
      readOnly={readonly}
      images={[currentImg]}
      index={0}
      onChange={images => {
        const newImage = images[0] || undefined
        switch (previewType) {
          case 'location':
            onImage(newImage, 'ImageLocation')
            break
          case 'before':
            onImage(newImage, 'ImageBefore')
            break
          case 'after':
            onImage(newImage, 'ImageAfter')
            break
          default:
            break
        }
      }}
    />
  )

  return (
    <ImageData>
      <Row type="flex" justify="space-around">
        <Col span={8}>
          <ImgPicker
            title="Floorplan"
            onChange={(img: any) => onImage(img, 'ImageLocation')}
            Image={defect.ImageLocation}
            viewMode={viewMode}
            onThumbnailOpen={images => {
              if (images) {
                setPreviewImg(true)
                setPreviewType('location')
                setCurrentImg(images)
              }
            }}
          />
        </Col>
        <Col span={8}>
          <ImgPicker
            title="Before"
            onChange={(img: any) => onImage(img, 'ImageBefore')}
            Image={defect.ImageBefore}
            viewMode={viewMode}
            onThumbnailOpen={images => {
              if (images) {
                setPreviewImg(true)
                setPreviewType('before')
                setCurrentImg(images)
              }
            }}
          />
        </Col>
        <Col span={8}>
          <ImgPicker
            title="After"
            onChange={(img: any) => onImage(img, 'ImageAfter')}
            Image={defect.ImageAfter}
            viewMode={afterImgViewMode}
            onThumbnailOpen={images => {
              if (images) {
                setPreviewImg(true)
                setPreviewType('after')
                setCurrentImg(images)
              }
            }}
          />
        </Col>
      </Row>
      {previewImg && thumnailPreview}
      {content}
    </ImageData>
  )
}

const ImageData = styled.div`
  border-radius: 5px;
  border: solid 1px #eeeff2;
  background-color: white;
  margin-top: 24px;
  padding: 5px;
`
const Box = styled.div`
  padding: 15px;
  width: 100%;
`
const Header = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 20px;
  margin-bottom: 10px;
`

const FloorPlamImg = styled.img`
  border-radius: 5px;
  border: solid 1px #e4e7eb;
  height: 240px;
  width: 100%;
`

export default DefectImage
