import React from 'react'
import { Col } from 'antd'
import styled from 'styled-components'

type Props = {
  title: string
  value: any
  percentW?: number
}

const DescItem: React.ComponentType<Props> = props => {
  const { title = '', value = '', percentW = 20 } = props

  return (
    <Col style={{ width: `${percentW}%` }}>
      <DesItem>
        <div className="title">{title}</div>
        <div className="value">{value}</div>
      </DesItem>
    </Col>
  )
}

const DesItem = styled.div`
  margin-top: 6px;
  font-size: 18px;
  float: left;
  .title {
    font-family: 'DB Helvethaica X 75 Bd';
  }

  .value {
    font-family: 'DB Helvethaica X 55 Regular';
    padding-right: 5px;
    word-break: break-word;
  }
`

export default DescItem
