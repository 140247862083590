import React from 'react'
import RootState from 'typings/RootState'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { Layout, Row, Col } from 'antd'
import { DefectItem } from 'features/pmr/types'

import { selectReport } from 'features/pmr/selector'

import ReportTitle from './ReportTitle'
import DefectList from './DefectList'
import { getUserProfile } from 'features/sensitive/sensitive.selector'
import Pagination from 'Components/Shared/Pagination'

const { Content } = Layout

type Props = {
  title?: string
  onEdit?: (item: DefectItem) => void
  page?: number
  perPage?: number
  setPage?: (page: number) => void
  setPerPage?: (number: number) => void
  onApprove?: (item: DefectItem) => void
  onReject?: (item: DefectItem) => void
  mode: 'PMR' | 'CM' | 'QC'
} & ReduxProps

const ReportInfo: React.ComponentType<Props> = props => {
  const {
    report,
    onEdit = () => {},
    page = 1,
    perPage = 10,
    setPage = () => {},
    setPerPage = () => {},
    onApprove,
    onReject,
    mode,
  } = props

  const pagination = (
    <Pagination
      perPage={perPage}
      totalRows={report.TotalDefect}
      currentPage={page}
      onClickPage={setPage}
      onChangeNumber={setPerPage}
    />
  )

  return (
    <Content className="mid-content">
      <MainPage>
        <LeftContent>
          <ReportTitle report={report} mode={mode} />
        </LeftContent>
        <RightContent>
          <Row type="flex" justify="space-between" align="middle">
            <Col>
              <Title>{report.Unit}</Title>
            </Col>
          </Row>
          <DefectList
            report={report}
            onClick={onEdit}
            onApprove={onApprove}
            onReject={onReject}
            mode={mode}
          />
          {report.DefectList.length > 0 && pagination}
        </RightContent>
      </MainPage>
    </Content>
  )
}

const MainPage = styled.div`
  background-color: #f5f6fa;
`

const LeftContent = styled.div`
  width: 320px;
  height: calc(100vh - 112px);
  background-color: #fff;
  float: left;
  margin-top: 1px;
`

const RightContent = styled.div`
  width: calc(100% - 320px);
  height: calc(100vh - 100px);
  float: left;
  padding: 32px 80px 0 27px;
  overflow: scroll;
`

const Title = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 28px;
`

const mapStateToProps = (state: RootState) => {
  return {
    report: selectReport(state),
    userProfile: getUserProfile(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(ReportInfo)
