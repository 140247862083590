import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'antd'

interface Props {
  text: any
  icon: any
}

const HoverItem: React.ComponentType<Props> = ({ text, icon }) => {
  return (
    <Item>
      <Row type="flex" className="right-content">
        <Col className={'score'}>{text}</Col>
        <Col>{icon}</Col>
      </Row>
    </Item>
  )
}

const Item = styled.div`
  .right-content:hover {
    cursor: pointer;
    .icon-action {
      display: block;
    }
    .score {
      display: none;
    }
  }

  .active {
    color: #5bc79e;
    font-family: 'DB Helvethaica X 75 Bd';
  }

  .icon-action {
    display: none;
  }
  .score {
    display: block;
  }
`
export default HoverItem
