import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { connect, useDispatch } from 'react-redux'
import { Layout } from 'antd'
import { RouteComponentProps } from 'react-router-dom'
import RootState from 'typings/RootState'

import {
  sendZoneToMcChecking,
  sendZoneToQcChecking,
  sendZoneToQcEdit,
} from 'features/cmpj/zone/report/api'

import {
  SendZoneToMcReq,
  ZoneMcItem,
  SendZoneToQcReq,
  ZoneQcItem,
  SendZoneQCEditReq,
} from 'features/cmpj/zone/report/types'

import { DefectItem, ZoneReport } from 'features/report/zone/types'

import { selectReport, selectDefect } from 'features/report/zone/selector'

import { selectEvent } from 'features/cmpj/zone/report/selector'
import {
  userEmailSelector,
  serviceTokenSelector,
} from 'features/sensitive/sensitive.selector'
import { selectedProjectCodeSelector } from 'features/project/project.selector'

import ZoneDefectReport from 'Components/Zone/Report'
import Breadcrumbs from './Breadcrumbs'
import Footer from './Footer'

import EventModal from 'Components/Shared/Modal/EventModal'

const { Content } = Layout

type FormProps = {
  code: string
}

type Props = FormProps &
  RouteComponentProps<{
    unit: string
    qcType: string
    requestID: string
    floor: string
  }> &
  ReduxProps

const CmZoneReport: React.ComponentType<Props> = props => {
  const [page, setPage] = useState('')
  const [enableModal, setEnableModal] = useState(false)
  const {
    Token,
    ProjectCode,
    UserEmail,
    report,
    defect,
    event,
    history,
    match,
  } = props

  const { unit, qcType, floor, requestID } = match.params
  const RequestID = +requestID || 0
  const dispatch = useDispatch()

  const getCmTitle = () => {
    switch (report.Status) {
      case 'INIT':
        return 'รอดำเนินการ'
      case 'INPRO':
        return 'กำลังดำเนินการ'
      case 'REJT':
        return 'รายการแก้ไข'
      default:
        return 'เสร็จสิ้น'
    }
  }

  const getQcTitle = () => {
    switch (report.Status) {
      case 'INIT':
        return 'รอ QC รับคำขอ'
      case 'INPRO':
        return 'QC กำลังดำเนินการ'
      case 'REJT':
        return 'รายการแก้ไข'
      default:
        return 'เสร็จสิ้น'
    }
  }

  const getTitle = () => {
    switch (report.State) {
      case 'CM-QC':
        return getCmTitle()
      case 'QC-QC':
        return getQcTitle()
      default:
        return 'เสร็จสิ้น'
    }
  }

  const getName = () => {
    switch (page) {
      case 'add.defect':
        return 'เพิ่ม Defect'
      case 'edit.defect':
        return 'แก้ไข Defect'
      case 'view.defect':
        return `หมุดที่ ${defect.PinNumber}`
      default:
        return undefined
    }
  }

  const onSendReqToMC = () => {
    setEnableModal(true)
    const req: SendZoneToMcReq = {
      ProjectCode,
      UserEmail,
      ItemList: [
        {
          Unit: unit,
          QCTypeID: qcType,
          RequestID,
          Floor: floor,
        },
      ],
    }

    dispatch(sendZoneToMcChecking(Token, req))
  }

  const onSendReqToQC = () => {
    setEnableModal(true)
    const isCmInpro = report.State === 'CM-QC' && report.Status === 'INPRO'
    const req: SendZoneToQcReq = {
      ProjectCode,
      UserEmail,
      ItemList: [
        {
          Unit: unit,
          QCTypeID: qcType,
          RequestID,
          Floor: floor,
          IsCMChecked: isCmInpro ? 'Y' : 'N',
        },
      ],
    }

    dispatch(sendZoneToQcChecking(Token, req))
  }

  const onSendReqToQCEdit = () => {
    setEnableModal(true)
    const req: SendZoneQCEditReq = {
      ProjectCode,
      UserEmail,
      Unit: unit,
      QCTypeID: qcType,
      RequestID,
      Floor: floor,
      AfterImageList: [],
    }

    dispatch(sendZoneToQcEdit(Token, req))
  }

  const gotoDashboard = () => {
    setEnableModal(false)
    history.push('/zone/dashboard')
  }

  const closeEnableModal = () => setEnableModal(false)

  let addMode = false
  let isShowAfterImg = true
  let readOnly = true
  if (report.State === 'CM-QC') {
    addMode = report.Status === 'INPRO'
    isShowAfterImg = report.Status === 'REJT'
    readOnly = !(report.Status === 'INPRO' || report.Status === 'REJT')
  }
  return (
    <>
      <EventModal
        event={[event]}
        enable={enableModal}
        successCalback={gotoDashboard}
        errorCalback={closeEnableModal}
      />

      <Breadcrumbs
        path={`/zone/report/${unit}/${qcType}/${requestID}/${floor}`}
        title={getTitle()}
        name={getName()}
        onBack={() => setPage('')}
      />
      <ZoneDefectReport
        title={'ส่วนกลางทั่วไป'}
        initPage={page}
        ProjectCode={ProjectCode}
        Unit={unit}
        QCTypeID={qcType}
        RequestID={RequestID}
        Floor={floor}
        onPage={(name: string) => setPage(name)}
        addMode={addMode}
        readOnly={readOnly}
        isShowAfterImg={true}
      />
      <Footer
        report={report}
        isLoading={event.IsLoading}
        visible={page === ''}
        onSendReqToMC={onSendReqToMC}
        onSendReqToQC={onSendReqToQC}
        onSendReqToQCEdit={onSendReqToQCEdit}
      />
    </>
  )
}

const MainPage = styled.div`
  background-color: #f5f6fa;
`

const mapStateToProps = (state: RootState) => {
  return {
    Token: serviceTokenSelector(state),
    ProjectCode: selectedProjectCodeSelector(state),
    UserEmail: userEmailSelector(state),
    report: selectReport(state),
    defect: selectDefect(state),
    event: selectEvent(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CmZoneReport)
