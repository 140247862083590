import { createStandardAction, createAsyncAction } from 'typesafe-actions'
import {
  GetConstructTypeReq,
  GetConstructTypeResp,
  GetProjectTypeReq,
  GetProjectTypeResp,
  ActivityFilter,
  GetUnitListReq,
  GetActivityReq,
  GetActivityListResp,
  GetUnitListResp,
  GetZoneListReq,
  GetZoneListResp,
  GetFloorListReq,
  GetFloorListResp,
  GetQCTypeListReq,
  GetQCTypeListResp,
  GetRoleListResp,
  UnitFilter,
  ZoneFilter,
  RoadConstructFilter,
  GetTowerListResp,
  GetTowerListReq,
} from './types'

export const getConstructTypeAction = createAsyncAction(
  'report/ConstructType/FETCH_REPORT_REQUEST',
  'report/ConstructType/FETCH_REPORT_SUCCESS',
  'report/ConstructType/FETCH_REPORT_FAILURE'
)<GetConstructTypeReq, GetConstructTypeResp, any>()

export const getUnitLisAction = createAsyncAction(
  'filter/FETCH_UNIT_LIST_REQUEST',
  'filter/FETCH_UNIT_LIST_SUCCESS',
  'filter/FETCH_UNIT_LIST_FAILURE'
)<GetUnitListReq, GetUnitListResp, any>()

export const getZoneLisAction = createAsyncAction(
  'filter/FETCH_ZONE_LIST_REQUEST',
  'filter/FETCH_ZONE_LIST_SUCCESS',
  'filter/FETCH_ZONE_LIST_FAILURE'
)<GetZoneListReq, GetZoneListResp, any>()

export const getFloorLisAction = createAsyncAction(
  'filter/FETCH_FLOOR_LIST_REQUEST',
  'filter/FETCH_FLOOR_LIST_SUCCESS',
  'filter/FETCH_FLOOR_LIST_FAILURE'
)<GetFloorListReq, GetFloorListResp, any>()

export const getTowerLisAction = createAsyncAction(
  'filter/FETCH_TOWER_LIST_REQUEST',
  'filter/FETCH_TOWER_LIST_SUCCESS',
  'filter/FETCH_TOWER_LIST_FAILURE'
)<GetTowerListReq, GetTowerListResp, any>()

export const getQCTypeLisAction = createAsyncAction(
  'filter/FETCH_QCTYPE_LIST_REQUEST',
  'filter/FETCH_QCTYPE_LIST_SUCCESS',
  'filter/FETCH_QCTYPE_LIST_FAILURE'
)<GetQCTypeListReq, GetQCTypeListResp, any>()

export const getProjectTypeAction = createAsyncAction(
  'report/ProjectType/FETCH_REPORT_REQUEST',
  'report/ProjectType/FETCH_REPORT_SUCCESS',
  'report/ProjectType/FETCH_REPORT_FAILURE'
)<GetProjectTypeReq, GetProjectTypeResp, any>()

export const getRoleListAction = createAsyncAction(
  'report/RoleList/FETCH_REPORT_REQUEST',
  'report/RoleList/FETCH_REPORT_SUCCESS',
  'report/RoleList/FETCH_REPORT_FAILURE'
)<any, GetRoleListResp, any>()

export const getActivityLisAction = createAsyncAction(
  'filter/FETCH_ACTIVITY_LIST_REQUEST',
  'filter/FETCH_ACTIVITY_LIST_SUCCESS',
  'filter/FETCH_ACTIVITY_LIST_FAILURE'
)<GetActivityReq, GetActivityListResp, any>()

export const updateActivityFilter = createStandardAction(
  'report/filter/ACTIVITY_FILTER_ACTION'
)<ActivityFilter, void>()

export const updateUnitFilter = createStandardAction(
  'report/filter/UNIT_FILTER_ACTION'
)<UnitFilter, void>()

export const updateZoneFilter = createStandardAction(
  'report/filter/ZONE_FILTER_ACTION'
)<ZoneFilter, void>()

export const updateRoadConstructFilter = createStandardAction(
  'report/filter/ROAD_CONSTRUCT_FILTER_ACTION'
)<RoadConstructFilter, void>()

export const clearFilter = createStandardAction(
  'report/filter/CLEAR_FILTER_ACTION'
)<void>()
