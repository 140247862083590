import React, { useState, useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import RootState from 'typings/RootState'
import { connect, useDispatch } from 'react-redux'
import { isEqual } from 'lodash'

import { BreadcrumbLink } from 'Components/Shared/BreadCrumb/BreadCrumb'

import {
  selectEvent,
  selectImgEvent,
  selectCheckListQC,
  selectSelectedImage,
  selectOriginalImageList,
} from 'features/report/commissioning/selector'

import { uploadImage } from 'features/report/commissioning/actions'

import {
  GetCheckListReq,
  SaveCommissioningReq,
  GetCommissioningImageReq,
  SaveCommissioningImageReq,
  ImageItem,
} from 'features/report/commissioning/types'

import {
  getCheckListForQC,
  saveCheckListForQC,
  getCommissioningImageByQC,
  saveCommissioningImageByQC,
} from 'features/report/commissioning/api'

import {
  updateCheckList,
  resetEvent,
} from 'features/report/commissioning/actions'

import {
  userEmailSelector,
  serviceTokenSelector,
} from 'features/sensitive/sensitive.selector'
import { selectUploadEvent } from 'features/uploadfile/selector'
import { selectedProjectCodeSelector } from 'features/project/project.selector'

import EventModal from 'Components/Shared/Modal/EventModal'
import CommissionDetail from 'Components/Commissioning/Detail'

import { ConfirmDeleteModal } from 'Components/Shared'

import Breadcrumbs from './Breadcrumbs'
import Footer from './Footer'
import Signature from './Signature'

type FormProps = {}

type Props = FormProps &
  RouteComponentProps<{
    project: string
    checkListID: string
    activityID: string
    requestID: string
  }> &
  ReduxProps

const QcCommissionDetail = (props: Props) => {
  const [enableModal, setEnableModal] = useState(false)
  const [displaySign, setDisplaySign] = useState(false)
  const [page, setPage] = useState('')
  const [isConfirm, setIsConfirm] = useState(false)

  const {
    Token,
    UserEmail,

    history,
    match,
    checklist,
    event,
    eventImg,
    uploadEvent,
    selectedImages,
    originalImages,
  } = props

  const { project, checkListID, activityID, requestID } = match.params
  const CheckListID = +checkListID
  const RequestID = +requestID
  const { State, Status } = checklist
  let localImages: any[] = []

  const dispatch = useDispatch()

  useEffect(() => {
    getCheckList()
  }, [project, checkListID, activityID])

  useEffect(() => {
    getCommissioningImage()
  }, [checklist.CheckListID])

  const getCheckList = () => {
    const req: GetCheckListReq = {
      ProjectCode: project,
      UserEmail,
      CheckListID: +checkListID,
      ActivityID: activityID,
      RequestID,
    }

    dispatch(getCheckListForQC(Token, req))
  }

  const getCommissioningImage = () => {
    if (!(checklist && checklist.CommissioningTypeID)) return
    const req: GetCommissioningImageReq = {
      ProjectCode: project,
      UserEmail,
      CheckListID: +checkListID,
      ActivityID: activityID,
      RequestID,
      CheckNumber: checklist.CheckNumber,
      CommissioningType: checklist.CommissioningTypeID,
    }

    dispatch(getCommissioningImageByQC(Token, req))
  }

  const onSave = () => {
    setEnableModal(true)
    const req: SaveCommissioningReq = {
      ProjectCode: project,
      UserEmail,
      CheckListID,
      ActivityID: activityID,
      RequestID,
      CheckNumber: checklist.CheckNumber,

      CheckStatus: checklist.CheckStatus,
      CommissioningType: checklist.CommissioningTypeID,

      ImageList: checklist.ImageList,
      List: checklist.List,
    }

    dispatch(saveCheckListForQC(Token, req))
  }

  const onSaveCommissioningImage = (ImageList: any[]) => {
    setEnableModal(true)
    const notChange = isEqual(originalImages, checklist.ImageList)
    if (!notChange) {
      onSave()
    }
    const req: SaveCommissioningImageReq = {
      ProjectCode: project,
      UserEmail,
      CheckListID,
      ActivityID: activityID,
      RequestID,
      CheckNumber: checklist.CheckNumber,
      ImageList: ImageList,
      CommissioningType: checklist.CommissioningTypeID,
    }

    dispatch(saveCommissioningImageByQC(Token, req))
  }

  const filterRemark = (ImageList: ImageItem[]) => {
    return ImageList.map(item => {
      return { Image: item.Image }
    })
  }
  const onHandleLocalImage = (images: any[]) => {
    images.forEach(image => {
      let localImage = localImages.find(local => image.text === local.text)
      if (!localImage) {
        localImages.push(image)
        onUploadNewImage(image, -1)
      }
    })
  }

  const onUploadNewImage = (image: any, index: number) => {
    if (!image) return

    setEnableModal(true)
    const req = {
      Token,
      UserEmail,
      Image: image,
      index,
    }

    dispatch(uploadImage(req))
  }

  const onUpdate = (val: any) => {
    dispatch(updateCheckList({ ...val }))
  }

  const onUpdateImageList = (doc: any) => {
    console.log('doc check', doc)

    const index = doc.ImageList.findIndex((item: any) => {
      return item.Image.FileID === undefined
    })

    if (index !== -1) {
      onUploadNewImage(doc.ImageList[index].Image, index)
    } else {
      dispatch(updateCheckList({ ...doc }))
    }
  }

  const onRefreshPage = () => {
    onClear()
    getCheckList()
  }

  const onClear = () => {
    setEnableModal(false)
    setDisplaySign(false)
    setPage('')
    dispatch(resetEvent())
  }

  const successCalback = () => {
    setEnableModal(false)
    onRefreshPage()
  }

  const errorCalback = () => {
    setEnableModal(false)
    dispatch(resetEvent())
  }

  const onConfirmToSave = async () => {
    const { HasImage } = checklist
    console.log('hasImage', HasImage)

    if (HasImage === true) setIsConfirm(true)
    else onSave()
  }

  const closeEnableModal = () => setEnableModal(false)
  const onBack = () => {}
  const isReadOnly = State !== 'QC' && Status !== 'INPRO'
  const breadcrumbs: BreadcrumbLink[] = [
    {
      url: '/commissioning/dashboard',
      title: 'Home',
      icon: '/home.png',
      active: true,
      showTitle: false,
    },
    {
      url: '/commissioning/dashboard',
      title: 'ส่วนกลาง',
      active: true,
      showTitle: true,
    },
  ]

  let breadcrumb

  switch (page) {
    case 'activity':
      breadcrumbs.push({
        url: '',
        title: 'Commissioning and Safety',
        active: true,
        showTitle: true,
        onClick: () => setPage(''),
      })
      break
    case 'images':
      breadcrumb = (
        <Breadcrumbs
          title={'Commissioning and Safety'}
          name={'รูปภาพ'}
          breadcrumbs={breadcrumbs}
          onBack={() => setPage('')}
        />
      )
      break
    case 'chooseImages':
      breadcrumb = (
        <Breadcrumbs
          title={'Commissioning and Safety'}
          name={'รูปภาพ'}
          breadcrumbs={breadcrumbs}
          onBack={() => setPage('')}
        />
      )
      break
    default:
      breadcrumb = (
        <Breadcrumbs
          title={'Commissioning and Safety'}
          name={undefined}
          breadcrumbs={breadcrumbs}
          onBack={onBack}
        />
      )
      break
  }

  return (
    <>
      <EventModal
        event={[event, eventImg]}
        err={[uploadEvent]}
        enable={enableModal}
        successCalback={successCalback}
        errorCalback={errorCalback}
      />
      <Signature
        display={displaySign}
        ActivityID={activityID}
        RequestID={RequestID}
        checklist={checklist}
        onClose={() => setDisplaySign(false)}
        onCompleted={onRefreshPage}
      />

      {breadcrumb}

      <CommissionDetail
        readOnly={false}
        ActivityID={activityID}
        breadcrumbs={breadcrumbs}
        onUpdate={onUpdate}
        onUpdateImageList={onUpdateImageList}
        page={page}
        onPage={(page: string) => setPage(page)}
        onUploadImage={images => onUploadNewImage(images, -1)}
        // onUploadImage={(image) => onHandleLocalImage(image)}
        onSave={onConfirmToSave}
        selectedImage={(selectedImages && selectedImages.ImageList) || []}
        onSaveCommissioningImage={onSaveCommissioningImage}
      />
      <Footer
        checklist={checklist}
        visible={page === '' && isReadOnly === false}
        onSign={() => setDisplaySign(true)}
        onChooseImage={() => setPage('chooseImages')}
        onSave={onConfirmToSave}
        onUpdate={onUpdate}
      />
      <ConfirmDeleteModal
        onClose={() => setIsConfirm(false)}
        onSubmit={() => {
          setIsConfirm(false)
          onSave()
        }}
        title="แจ้งเตือน"
        desc="เมื่อคุณบันทึกข้อมูล รูปส่งรายงานที่เคยเลือกจะถูก Reset"
        visible={isConfirm}
      />
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    Token: serviceTokenSelector(state),
    UserEmail: userEmailSelector(state),

    checklist: selectCheckListQC(state),
    event: selectEvent(state),
    eventImg: selectImgEvent(state),
    uploadEvent: selectUploadEvent(state),
    selectedImages: selectSelectedImage(state),
    originalImages: selectOriginalImageList(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(QcCommissionDetail)
