import React from 'react'
import { Checkbox as Antd } from 'antd'
import styled from 'styled-components'

const Checkbox = (props: any) => {
  const onSelected = (evt: any) => {
    if (props.onChange) {
      props.onChange(evt.target.checked, props.name)
    }
  }

  const {
    label = 'Please Select',
    value,
    clear = false,
    disabled = false,
  } = props

  return (
    <ItemView>
      <div className="check-box">
        <Antd checked={value} onChange={onSelected}>
          {label}
        </Antd>
      </div>
    </ItemView>
  )
}

const ItemView = styled.div`
  font-family: 'DB Helvethaica X 55 Regular';
  font-size: 18px;
  color: #000000;

  .ant-checkbox-wrapper > .ant-checkbox > .ant-checkbox-inner {
    border-radius: 3.5px;
  }
  @media screen and (max-width: 425px) {
  }

  .check-box {
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #003168;
      border-color: #003168;
    }
  }
`

export default Checkbox
