import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { RootState } from 'typings/RootState'
import styled from 'styled-components'

import { getProjectType, getQCTypeList } from 'features/filter/api'
import { GetProjectTypeReq, GetQCTypeListReq } from 'features/filter/types'
import {
  selectProjectType,
  selectUnitList,
  selectQCTypeList,
} from 'features/filter/selector'
import { Col, Row } from 'antd'
import {
  projectsByIdSelector,
  selectedProjectCodeSelector,
} from 'features/project/project.selector'
import {
  serviceTokenSelector,
  userEmailSelector,
} from 'features/sensitive/sensitive.selector'
import { DropdownList, DropdownPopupMenu } from 'Components/Shared/Form'
import { fetchProjects } from 'features/project/project.api'
import { selectFilter } from 'features/adminReport/score/selector'
import { Filter } from 'features/adminReport/score/types'
import { updateFilter, clearFilter } from 'features/adminReport/score/actions'
import MulticheckProject from 'Components/Shared/Filters/MulticheckProject'

import {
  ProjectTypeFilter,
  DateFilter,
  ButtonFilter,
  CheckNumberFilter,
} from './Components'

type Props = {
  onSearch?: (isClear?: boolean) => void
  withPadding?: boolean
  onExport?: () => void
  exportData?: any[]
  isDownLoading?: boolean
} & ReduxProps &
  DispatchProps

const ScoreFilter: React.FunctionComponent<Props> = props => {
  const {
    ProjectCode,
    UserEmail,
    token,
    filter,
    projectsById,
    onSearch = () => {},
    withPadding = false,
    projectType,
    qctypeList,
    onExport,
    exportData,
    isDownLoading,
  } = props

  useEffect(() => {
    dispatch(clearFilter())
    onGetProjectType()
    onGetQCTypeList()
    dispatch(fetchProjects({ email: UserEmail, token }))
    return () => {
      dispatch(clearFilter())
    }
  }, [ProjectCode, UserEmail])

  const onGetProjectType = () => {
    const req: GetProjectTypeReq = {
      UserEmail,
      Page: 1,
      RowPerPage: 100,
    }
    dispatch(getProjectType(token, req))
  }

  const onGetQCTypeList = () => {
    const req: GetQCTypeListReq = {
      UserEmail,
      UnitType: 'Unit',
      Status: 'Active',
    }
    dispatch(getQCTypeList(token, req))
  }

  const dispatch = useDispatch()
  const onFilterChange = (filter: Filter) => {
    dispatch(updateFilter(filter))
  }

  const onClearSearch = () => {
    dispatch(clearFilter())
    onSearch(true)
  }

  const isFilter = () => {
    return (
      filter.CheckNumber > 0 ||
      filter.QCTypeID !== '' ||
      filter.Date.Mode !== '' ||
      filter.ProjectCode.length > 0 ||
      filter.ProjectTypeID !== 0
    )
  }

  const renderQcType = () => {
    return qctypeList.List.map(item => {
      return {
        name: item.QCTypeName,
        value: item.QCTypeID,
      }
    })
  }

  const selectedProject =
    filter.ProjectCode.length > 0
      ? `เลือก ${filter.ProjectCode.length} โครงการ`
      : ''

  return (
    <MainPage className={withPadding ? 'with-padding' : ''}>
      <Row type="flex" justify="start">
        <Col span={8}>
          <ProjectTypeFilter
            filter={filter}
            onFilterChange={(filter: Filter) => {
              filter.ProjectCode = []
              onFilterChange(filter)
            }}
            projectType={projectType}
          />
        </Col>
        <Col span={8}>
          <Box>
            <DropdownPopupMenu
              label={'โครงการ'}
              placeholder="เลือกโครงการ"
              value={filter.ProjectCode.join(',')}
              disabled={false}
              content={
                <MulticheckProject
                  projectList={projectsById}
                  projectTypeID={filter.ProjectTypeID}
                  filter={filter.ProjectCode}
                  onChange={data => {
                    filter.ProjectCode = data
                    onFilterChange(filter)
                  }}
                />
              }
              isActive={filter.ProjectCode.length > 0}
            />
          </Box>
        </Col>
        <Col span={8}>
          <Box>
            <DropdownList
              placeholder="เลือกหมวดงาน"
              label="หมวดงาน"
              value={filter.QCTypeID}
              menu={renderQcType()}
              onChange={(val: any) => {
                filter.QCTypeID = val
                onFilterChange(filter)
              }}
            />
          </Box>
        </Col>
        <Col span={8}>
          <CheckNumberFilter filter={filter} onFilterChange={onFilterChange} />
        </Col>
        <Col span={8}>
          <DateFilter filter={filter} onFilterChange={onFilterChange} />
        </Col>
        <Col span={8}>
          <ButtonFilter
            onClearSearch={onClearSearch}
            onSearch={onSearch}
            isFilter={isFilter()}
            onExport={onExport}
            isDownLoading={isDownLoading}
            exportData={exportData}
            fileName="รายงานคะแนนเฉลี่ย"
          />
        </Col>
      </Row>
    </MainPage>
  )
}

const MainPage = styled.div`
  margin-bottom: 24px;
  &.with-padding {
    padding: 0 120px;
  }

  background-color: #f5f6fa;
`

const Box = styled.div`
  padding: 0 12px 12px 0;
`

const mapStateToProps = (state: RootState) => {
  return {
    ProjectCode: selectedProjectCodeSelector(state),
    UserEmail: userEmailSelector(state),
    token: serviceTokenSelector(state),
    filter: selectFilter(state),
    unitList: selectUnitList(state),
    qctypeList: selectQCTypeList(state),
    projectType: selectProjectType(state),
    projectsById: projectsByIdSelector(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(ScoreFilter)
