import React from 'react'
import AdminTab from 'Components/Shared/AdminTab'

interface Props {
  selected: 'Project' | 'MainCon'
}

const ReportSQDTab: React.FunctionComponent<Props> = props => {
  const tabItem = [
    {
      link: '/report/sqdbyproject',
      title: 'ตามโครงการ',
      isActive: props.selected == 'Project',
    },
    {
      link: '/report/sqdbymaincon',
      title: 'ตามผู้รับเหมา',
      isActive: props.selected == 'MainCon',
    },
  ]

  return <AdminTab tabItem={tabItem} />
}

export default ReportSQDTab
