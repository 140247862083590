import { CheckListState } from './types'

import produce from 'immer'
import parseErrorMessage from 'utils/parseErrorMessage'

import { getType, ActionType } from 'typesafe-actions'
import * as actions from './actions'

const initialState: CheckListState = {
  CheckList: {
    TotalRows: 0,
    List: [],
  },
  Event: {
    IsLoading: false,
    SubmitError: '',
    SubmitSuccess: false,
    SubmitType: '',
    MessageSuccess: '',
  },
}

export type State = typeof initialState
type Action = ActionType<typeof actions>

export default function (
  state: CheckListState = initialState,
  action: Action
): CheckListState {
  return produce(state, draft => {
    switch (action.type) {
      case getType(actions.clearLocalCheckList):
        draft.CheckList = {
          TotalRows: 0,
          List: [],
        }
        draft.Event = {
          IsLoading: false,
          SubmitError: '',
          SubmitSuccess: false,
          SubmitType: '',
          MessageSuccess: '',
        }
        break
      case getType(actions.importMasterCheckListReq.request):
        draft.Event.IsLoading = true
        draft.Event.SubmitError = ''
        break
      case getType(actions.getCheckListReq.request):
        draft.Event.IsLoading = true
        draft.Event.SubmitError = ''
        break
      case getType(actions.importMasterCheckListReq.success):
        draft.Event.IsLoading = false
        draft.Event.SubmitError = undefined
        draft.Event.SubmitSuccess = true
        draft.Event.MessageSuccess = 'ทำรายการสำเร็จ'
        break
      case getType(actions.getCheckListReq.success):
        if (action.payload.ResponseData.List) {
          draft.CheckList = action.payload.ResponseData
        }
        draft.Event.IsLoading = false
        break

      case getType(actions.importMasterCheckListReq.failure):
      case getType(actions.getCheckListReq.failure):
        draft.Event.IsLoading = false
        draft.Event.SubmitError = parseErrorMessage(action.payload)
        break

      default:
        return state
    }
  })
}
