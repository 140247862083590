import React from 'react'
import { Modal, Spin, Icon, Progress } from 'antd'
import styled from 'styled-components'

interface Props {
  isShow: boolean
  total?: number
  success?: number
}

const LoadingProgress = (props: Props) => {
  const { isShow = false, total = 0, success = 0 } = props

  const percent = (success / total) * 100
  const text = `${success}/${total}`

  return (
    <Modal
      wrapClassName="error-box"
      style={css.page}
      visible={isShow}
      closable={false}
      mask={false}
      footer={null}
    >
      <Body>
        <Progress
          type="circle"
          percent={percent}
          format={() => text}
          className="circle-loading-progress"
          strokeWidth={6}
        />
      </Body>
    </Modal>
  )
}

const Body = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  height: 125px;
`
const css: any = {
  page: {
    marginTop: '14%',
    width: '20px',
    height: '20px',
    textAlign: 'center',
    fontSize: '20px',
    color: 'white',
  },
  spin: {
    color: 'white',
    fontSize: '70px',
  },
}

// .ant-modal{
//   .circle-loading-progress{
//     .ant-progress-text{
//       color : #fff !important;
//       font-size: 30px !important;
//     }
//     &.ant-progress-status-normal .ant-progress-circle-path{
//       stroke : #003168ed !important;
//     }

//     .ant-progress-circle-trail{
//       stroke : #f2f2f2 !important;
//     }
//   }

// }

export default LoadingProgress
