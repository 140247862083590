import React, { useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { selectEvent, selectDataList } from 'features/admin/auto-email/selector'
import {
  userEmailSelector,
  serviceTokenSelector,
} from 'features/sensitive/sensitive.selector'
import {
  projectsByIdSelector,
  selectedProjectCodeSelector,
} from 'features/project/project.selector'
import RootState from 'typings/RootState'
import { RouteComponentProps } from 'react-router-dom'
import SubnavBar from 'Components/Shared/SubnavBar'
import { BreadcrumbLink } from 'Components/Shared/BreadCrumb/BreadCrumb'
import Menu from '../../MenuAdmin'
import { Row, Col } from 'antd'
import { PrimaryButton } from 'Components/Shared/Button'
import styled from 'styled-components'
import Calendar from 'Components/Shared/Calendar'
import moment, { Moment } from 'moment'
import DropdownPopupMenu from 'Components/Shared/Form/DropdownPopupMenu'
import MulticheckProject from 'Components/Shared/Filters/MulticheckProject'
import { GetProjectTypeReq } from 'features/filter/types'
import { getProjectType } from 'features/filter/api'
import { Filter } from 'features/adminReport/unit/types'
import { selectFilter } from 'features/adminReport/unit/selector'
import { fetchProjects } from 'features/project/project.api'
import { updateFilter } from 'features/adminReport/unit/actions'
import DropdownList from 'Components/Shared/Form/DropdownList'
import { GetDataListReq, CreateDataReq } from 'features/admin/auto-email/types'
import { sendReportDate } from 'features/admin/auto-email/api'
import {
  AdminProject,
  AlertError,
  AlertSuccess,
  LoadingView,
} from 'Components/Shared'
import { translate } from 'i18n'
import TKeys from 'i18n/translationKey'
import { clearFilter } from 'features/adminReport/sqdbyproject/actions'
import { EventModal } from 'Components/Shared'

type DefultProps = {
  homepage?: boolean
}

type Props = DefultProps &
  DispatchProps &
  RouteComponentProps &
  ReduxProps & {
    onFilterChange: (filter: any) => void
    onClearSearch: () => void
    filter: any
    qctypeList: any
  }

const breadcrumbs: BreadcrumbLink[] = [
  {
    url: '/dashboard',
    title: 'Home',
    icon: '/home.png',
    active: true,
    showTitle: false,
  },
  {
    url: '/auto-email',
    title: 'Auto Email',
    active: false,
    showTitle: false,
  },
]

const AutoEmail: React.ComponentType<Props> = props => {
  const { ProjectCode, UserEmail, token, projectsById, filter, event } = props

  const dispatch = useDispatch()

  const [data, setData] = useState({} as CreateDataReq)

  useEffect(() => {
    onGetProjectType()
  }, [ProjectCode])

  useEffect(() => {
    dispatch(fetchProjects({ email: UserEmail, token }))
  }, [UserEmail, token])

  const onGetProjectType = () => {
    const req: GetProjectTypeReq = {
      UserEmail,
      Page: 1,
      RowPerPage: 100,
    }
    dispatch(getProjectType(token, req))
  }

  const onFilterChange = (filter: Filter) => {
    dispatch(updateFilter(filter))
  }

  const [mode, setMode] = useState('' as 'update' | 'create' | 'delete' | '')
  const [enableModal, setEnableModal] = useState(false)

  const onCreate = () => {
    setMode('create')
    setEnableModal(true)
    let date = data.SendReportDate
      ? moment(new Date(data.SendReportDate)).format('DDMMYYYY')
      : ''
    data.ProjectFilter = filter.ProjectCode
    setData({ ...data })
    const req: CreateDataReq = {
      UserEmail: UserEmail,
      Type: data.Type,
      SendReportDate: date,
      ProjectFilter: data.ProjectFilter,
    }
    dispatch(sendReportDate(token, req))
  }

  const onSuccess = () => {
    setEnableModal(false)
    if (mode !== '') {
      setMode('')
    }
  }

  const typeList = [
    {
      value: 'unit',
      name: 'Unit',
    },
    {
      value: 'constructroad',
      name: 'Constructroad',
    },
    {
      value: 'constructplan',
      name: 'Constructplan',
    },
    {
      value: 'commonarea',
      name: 'Commonarea',
    },
    {
      value: 'commissioning',
      name: 'Commissioning',
    },
    {
      value: 'certification',
      name: 'Certification',
    },
    {
      value: 'auditroad',
      name: 'Auditroad',
    },
    {
      value: 'auditplan',
      name: 'Auditplan',
    },
  ]

  const checkInput = () => {
    if (
      data.Type == null ||
      data.SendReportDate == null ||
      filter.ProjectCode.length == 0
    ) {
      return true
    }
  }

  return (
    <>
      <SubnavBar breadcrumbs={breadcrumbs} hideSearch={true} />
      <EventModal
        name="defect"
        event={[event]}
        enable={enableModal}
        successCalback={onSuccess}
      />
      <Menu selected={'auto-email'}>
        <MainPage>
          <Header>
            <Row type="flex" align="middle" justify="space-between">
              <Col>
                <Title>Auto Email</Title>
              </Col>
            </Row>
          </Header>
          <DataArea>
            <Box>
              <TitleSecond>รายชื่อ Auto mail</TitleSecond>

              <DropdownPopupMenu
                label={'โครงการ'}
                placeholder="เลือกโครงการ"
                value={filter.ProjectCode.join(',')}
                disabled={false}
                content={
                  <MulticheckProject
                    projectList={projectsById}
                    projectTypeID={filter.ProjectTypeID}
                    filter={filter.ProjectCode}
                    onChange={value => {
                      filter.ProjectCode = value
                      onFilterChange(filter)
                    }}
                  />
                }
                isActive={filter.ProjectCode.length > 0}
              />

              <TitleSecond>Auto mail รายวัน</TitleSecond>
              <Calendar
                value={
                  data?.SendReportDate
                    ? moment(data?.SendReportDate)
                    : undefined
                }
                onSelectedDate={(value: any) => {
                  data.SendReportDate = new Date(value).toString()
                  setData({ ...data })
                }}
              />

              <TitleSecond>เลือกประเภทของ path</TitleSecond>
              <DropdownList
                label="ประเภท"
                value={data.Type}
                menu={typeList}
                onChange={(value: any) => {
                  data.Type = value
                  setData({ ...data })
                }}
              />
            </Box>
          </DataArea>
          <SaveArea>
            <PrimaryButton
              onClick={onCreate}
              text="บันทึก"
              disabled={checkInput()}
            />
          </SaveArea>
        </MainPage>
      </Menu>
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    dataList: selectDataList(state),
    event: selectEvent(state),
    token: serviceTokenSelector(state),
    UserEmail: userEmailSelector(state),
    ProjectCode: selectedProjectCodeSelector(state),
    projectsById: projectsByIdSelector(state),
    filter: selectFilter(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

const MainPage = styled.div`
  padding: 40px 45px;
  width: 100%;
`

const SaveArea = styled.div`
  margin-top: 32px;
  width: 160px;
`

const Box = styled.div`
  width: 380px;
`

const Header = styled.div``

const Title = styled.h2`
  font-size: 28px;
  font-family: 'DB Helvethaica X 75 Bd';
  height: 30px;
`

const TitleSecond = styled.div`
  font-size: 20px;
  font-family: 'DB Helvethaica X 75 Bd';
  margin: 24px 0 8px 0;
  color: #000;
`

const ClearTxt = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  color: #003168;
  font-size: 18px;
  text-decoration: underline;
  cursor: pointer;
`

const DataArea = styled.div``

export default connect(mapStateToProps, mapDispatchToProps)(AutoEmail)
