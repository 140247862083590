/* eslint-disable */
const _keys = {
  Common: {
    cancel_all: 'Common.cancel_all',
    month: 'Common.month',
    ok: 'Common.ok',
    save: 'Common.save',
    week: 'Common.week',
    logout: 'Common.logout',
    day: 'Common.day',
    dialogOk: 'Common.dialogOk',
    picture: 'Common.picture',
    document: 'Common.document',
    pick_picture: 'Common.pick_picture',
    pick_document: 'Common.pick_document',
    cancel: 'Common.cancel',
  },
  Error: {
    login_error: 'Error.login_error',
    filie_size_limit: 'Error.filie_size_limit',
    file_wrong: 'Error.file_wrong',
  },
  Menu: {
    profile: 'Menu.profile',
  },
  Login: {
    login: 'Login.login',
    service_token_error: 'Login.service_token_error',
    connection_in_progress: 'Login.connection_in_progress',
    platform_name: 'Login.platform_name',
    logout_in_progress: 'Login.logout_in_progress',
    logout_success_message: 'Login.logout_success_message',
    login_get_token_for_username: 'Login.login_get_token_for_username',
    accesstoken_acquired: 'Login.accesstoken_acquired',
    logout_modal_description: 'Login.logout_modal_description',
  },
  Dashboard: {
    actual_end_date: 'Dashboard.actual_end_date',
    actual_start_date: 'Dashboard.actual_start_date',
    approved: 'Dashboard.approved',
    category: 'Dashboard.category',
    custom: 'Dashboard.custom',
    day_work: 'Dashboard.day_work',
    duration: 'Dashboard.duration',
    filter: 'Dashboard.filter',
    not_approved: 'Dashboard.not_approved',
    pending: 'Dashboard.pending',
    progress: 'Dashboard.progress',
    search_placeholder: 'Dashboard.search_placeholder',
    status: 'Dashboard.status',
    todo: 'Dashboard.todo',
    header_in_progress: 'Dashboard.header_in_progress',
    header_todo: 'Dashboard.header_todo',
    header_complete: 'Dashboard.header_complete',
    header_sort_by_recent_update: 'Dashboard.header_sort_by_recent_update',
    tasklane_empty_record: 'Dashboard.tasklane_empty_record',
    tasklane_loading: 'Dashboard.tasklane_loading',
    tasklane_seemore: 'Dashboard.tasklane_seemore',
    project_list_empty_message: 'Dashboard.project_list_empty_message',
    todo_label: 'Dashboard.todo_label',
    inprogress_label: 'Dashboard.inprogress_label',
    complete_label: 'Dashboard.complete_label',
    header_sort_by_last_update: 'Dashboard.header_sort_by_last_update',
    project_select: 'Dashboard.project_select',
    plan_current_start_date: 'Dashboard.plan_current_start_date',
    plan_current_end_date: 'Dashboard.plan_current_end_date',
    construction_type: 'Dashboard.construction_type',
    floor: 'Dashboard.floor',
    unit: 'Dashboard.unit',
    zone: 'Dashboard.zone',
    type: 'Dashboard.type',
    advance: 'Dashboard.advance',
    tower: 'Dashboard.tower',
  },
  Module: {
    title: 'Module.title',
  },
  ActivityDetail: {
    history_request: 'ActivityDetail.history_request',
    status_label: 'ActivityDetail.status_label',
    wbs_label: 'ActivityDetail.wbs_label',
    reject_reason: 'ActivityDetail.reject_reason',
    day_of_work_label: 'ActivityDetail.day_of_work_label',
    progress_label: 'ActivityDetail.progress_label',
    submit_button_label: 'ActivityDetail.submit_button_label',
    plan_start_date_label: 'ActivityDetail.plan_start_date_label',
    plan_end_date_label: 'ActivityDetail.plan_end_date_label',
    saving: 'ActivityDetail.saving',
    start_date: 'ActivityDetail.start_date',
    end_date: 'ActivityDetail.end_date',
    actual_start_date: 'ActivityDetail.actual_start_date',
    actual_end_date: 'ActivityDetail.actual_end_date',
    baseline_start_date: 'ActivityDetail.baseline_start_date',
    baseline_end_date: 'ActivityDetail.baseline_end_date',
    expect_end_date: 'ActivityDetail.expect_end_date',
    picture: 'ActivityDetail.picture',
    picture_note: 'ActivityDetail.picture_note',
    document: 'ActivityDetail.document',
    document_note: 'ActivityDetail.document_note',
    note: 'ActivityDetail.note',
    work_progress: 'ActivityDetail.work_progress',
    created_date: 'ActivityDetail.created_date',
    upload_date: 'ActivityDetail.upload_date',
    time_unit: 'ActivityDetail.time_unit',
  },
  TaskLane: {
    delayed_by_day: 'TaskLane.delayed_by_day',
    start_within_day: 'TaskLane.start_within_day',
    plan_start_date: 'TaskLane.plan_start_date',
    plan_end_date: 'TaskLane.plan_end_date',
    init_text_th: 'TaskLane.init_text_th',
    appv_text_th: 'TaskLane.appv_text_th',
    pend_text_th: 'TaskLane.pend_text_th',
    rejt_text_th: 'TaskLane.rejt_text_th',
  },
  TopNav: {
    logout_modal_description: 'TopNav.logout_modal_description',
    logout_modal_confirm_description: 'TopNav.logout_modal_confirm_description',
    logout_modal_ok_label: 'TopNav.logout_modal_ok_label',
    logout_modal_cancel_label: 'TopNav.logout_modal_cancel_label',
  },
  RequireAuth: {
    back: 'RequireAuth.back',
    error: 'RequireAuth.error',
    contact: 'RequireAuth.contact',
  },
  SearchResult: {
    search_results_label: 'SearchResult.search_results_label',
    search_item_not_found: 'SearchResult.search_item_not_found',
    change_search_keyword: 'SearchResult.change_search_keyword',
  },
  Breadcrumb: {
    dashboard: 'Breadcrumb.dashboard',
    home: 'Breadcrumb.home',
    search_results: 'Breadcrumb.search_results',
    seemore: 'Breadcrumb.seemore',
  },
  Pagination: {
    show: 'Pagination.show',
    of: 'Pagination.of',
    entries: 'Pagination.entries',
  },
  TokenExpired: {
    title: 'TokenExpired.title',
    content: 'TokenExpired.content',
  },
  ActivityHistory: {
    description_req: 'ActivityHistory.description_req',
    description_rejt: 'ActivityHistory.description_rejt',
    description_appv: 'ActivityHistory.description_appv',
    status_appv: 'ActivityHistory.status_appv',
    column_date: 'ActivityHistory.column_date',
    column_time: 'ActivityHistory.column_time',
    column_description: 'ActivityHistory.column_description',
    column_role: 'ActivityHistory.column_role',
    column_status: 'ActivityHistory.column_status',
    column_file: 'ActivityHistory.column_file',
    column_remark: 'ActivityHistory.column_remark',
  },
}
export default _keys
