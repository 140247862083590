/**
 * This error extraction is based on the response format from API. Please refer
 * to API Spec for format.
 *
 * {
 *    RequestTime: "2019-09-11T09:28:08.5218208Z"
 *    ResponseCode: 1,
 *    ResponseData: [{
 *      "ProjectCode": "1175",
 *      "ProjectNameTH": "Sample Project",
 *      "ProjectThaiTH": "เศรษฐสิริ จรัญฯ - ปิ่นเกล้า",
 *    }],
 *    "ResponseMsg": "Success",
 *    "ResponseTime": "2019-09-11T09:28:08.6936505Z"
 * }
 * @param response string
 */
const parseErrorMessage = (response: any): string => {
  console.log('error:', JSON.stringify(response))
  if (typeof response === 'object') {
    const responseType = response.ResponseMsg
    const responseCode = response.ResponseCode
    const data = response.ResponseData
    if (responseType === 'Error' && responseCode === 0) {
      return data
    }
  }

  if (typeof response === 'object') {
    if (
      response.response &&
      response.response.data &&
      response.response.data.ResponseData
    ) {
      return response.response.data.ResponseData
    }
  }

  if (response && response.message && typeof response.message === 'string') {
    return response.message
  }

  return 'Unhandled error'
}

export default parseErrorMessage
