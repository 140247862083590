import React from 'react'
import { Row } from 'antd'
import { Link } from 'react-router-dom'

export type BreadcrumbLink = {
  url: string
  title: string
  active?: boolean
  icon?: string
  showTitle?: boolean
  onClick?: () => void
}

const generate = (links: BreadcrumbLink[]) => {
  const noneActive = links.length - 2
  const content = links.map((link, index) => {
    const { active, showTitle, onClick } = link
    let body
    if (active) {
      let info = showTitle ? (
        link.title
      ) : (
        <img src={link.icon} alt={link.title} />
      )
      let className = index !== noneActive ? 'active' : 'inactive'
      if (onClick) {
        body = (
          <a href="#" className={className} onClick={onClick}>
            {info}
          </a>
        )
      } else {
        body = (
          <Link className={className} to={link.url}>
            {info}
          </Link>
        )
      }
    } else {
      body = <span>{link.title}</span>
    }
    if (!showTitle && !active && link.icon != null) {
      body = <img src={link.icon} alt={link.title} />
    }

    return <li key={Math.random()}>{body}</li>
  })

  return content
}

interface Props {
  breadcrumbs: BreadcrumbLink[]
}

const BreadCrumb: React.FunctionComponent<Props> = props => {
  const { breadcrumbs } = props

  return (
    <Row type="flex" className="sub-nav breadcrumb-container">
      <nav>
        <div className="breadcrumb-body">
          <ul className="breadcrumb-list">{generate(breadcrumbs)}</ul>
        </div>
      </nav>
    </Row>
  )
}

// sample
export default BreadCrumb
