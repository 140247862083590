import React from 'react'
import BaseImgBtn from './BaseImgBtn'

import img from 'assets/img/ic-rect-disable.png'

const RectDisableBtn = (props: any) => {
  return <BaseImgBtn img={img} onClick={props.onClick} />
}

export default RectDisableBtn
