import React from 'react'
import { Layout } from 'antd'
import styled from 'styled-components'
import Breadcrumbs from './Breadcrumbs'
import {
  TaskStatus,
  AcceptFooter,
  MainConChecklist,
  MCTaskDetail,
} from 'Components/Activity'
import { MainconCheckList } from 'features/types/checklist'
import { MainconTask, CurrentActivity } from 'features/maincon/activity/types'
import { StatusOfTask, StateOfTask } from 'features/types/status'
import SendToCMFooter from './SendToCMFooter'

type Props = {
  onImageOpen(list?: any[], readOnly?: boolean): void
  onDocumentOpen(list?: any[], readOnly?: boolean): void
  onThumbnailOpen(index: number, list?: any[], readOnly?: boolean): void
  originalData: MainconTask
  currentData: CurrentActivity
  checkListData: MainconCheckList
  onUpdateCurrent: (val: any) => void
  onHistoryOpen?: () => void
  onAcceptTask: () => void
  onSendRequest: (date: string) => void
  isLoading?: boolean
  wbs?: any
}

const { Content, Footer } = Layout

const ActivityDetail: React.FunctionComponent<Props> = ({
  onImageOpen,
  onDocumentOpen,
  onThumbnailOpen,
  originalData,
  currentData,
  checkListData,
  onUpdateCurrent,
  onHistoryOpen,
  onAcceptTask,
  onSendRequest,
  isLoading = false,
  wbs,
}) => {
  const cmStatusContent = (
    <TaskStatus
      originalTask={originalData}
      onDocumentOpen={onDocumentOpen}
      onImageOpen={onImageOpen}
      onThumbnailOpen={onThumbnailOpen}
    />
  )
  let footer
  let taskStatus
  let readOnly = true
  let hideStatus = false
  if (originalData.State === StateOfTask.MC) {
    if (originalData.TaskStatus === StatusOfTask.INIT) {
      footer = <AcceptFooter onClick={onAcceptTask} isLoading={isLoading} />
      hideStatus = true
    } else {
      footer = <SendToCMFooter onClick={onSendRequest} isLoading={isLoading} />
      taskStatus = cmStatusContent
      readOnly = false
    }

    footer = <Footer className="detail-page-footer">{footer}</Footer>
  } else {
    taskStatus = cmStatusContent
  }

  const images = readOnly ? originalData.McImages : currentData.Images
  const documents = readOnly ? originalData.McDocuments : currentData.Documents

  return (
    <>
      <Breadcrumbs activity={originalData} />
      <Content className="mid-content">
        <div className="main-content activity-detail">
          <ActivityDetailArea>
            <MCTaskDetail
              currentState="MC"
              isSubmitting={false}
              onImageOpen={() => onImageOpen(images, readOnly)}
              onDocumentOpen={() => onDocumentOpen(documents, readOnly)}
              onThumbnailOpen={(index: number) =>
                onThumbnailOpen(index, images, readOnly)
              }
              onChange={onUpdateCurrent}
              originalData={originalData}
              currentData={currentData}
              onHistoryOpen={onHistoryOpen}
              readOnly={readOnly}
              wbs={wbs}
            />
            {taskStatus}
            <MainConChecklist checkListData={checkListData} hideStatus={hideStatus} />
          </ActivityDetailArea>
        </div>
      </Content>

      {footer}
    </>
  )
}

const ActivityDetailArea = styled.div`
  .box-section {
    background-color: #fff;
    margin-top: 12px;
    border-radius: 5px;
    border: solid 1px #f2f2f2;
    padding: 24px;
    line-height: normal;
    &.no-space {
      padding: 0;
      margin-top: 0px;
    }
    .title {
      font-size: 20px;
      font-family: 'DB Helvethaica X 75 Bd';
    }
    .description {
      font-size: 20px;
    }
  }
  .space {
    margin-top: 24px;
  }
`

export default ActivityDetail
