import withAPIAction from 'utils/withAPIAction'
import {
  getReportListAction,
  getReportDetailAction,
  getPercentPMRAction,
  setPercentPMRAction,
  saveCertAction,
  cancelCertAction,
  exportReportListAction,
} from './actions'
import {
  GetReportListReq,
  GetReportDetailReq,
  GetPercentPMRReq,
  UpdatePercentPMRReq,
  SaveCertReq,
  CancelCertReq,
} from './types'
import { verifyReq, verifyReqNoProject } from 'utils/verify'

export const getReportList = (token: string, data: GetReportListReq) => {
  const isValid = verifyReqNoProject(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<GetReportListReq>(state => ({
    method: 'POST',
    path: 'GetCertReportList',
    data,
    token: `Bearer ${token}`,
  }))(getReportListAction)
}

export const getReportDetail = (token: string, data: GetReportDetailReq) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<GetReportDetailReq>(state => ({
    method: 'POST',
    path: 'GetCertReport',
    data,
    token: `Bearer ${token}`,
  }))(getReportDetailAction)
}

export const getPercentPMR = (token: string, data: GetPercentPMRReq) => {
  const isValid = verifyReqNoProject(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<GetPercentPMRReq>(state => ({
    method: 'POST',
    path: 'GetPercentPMR',
    data,
    token: `Bearer ${token}`,
  }))(getPercentPMRAction)
}

export const updatePercentPMR = (token: string, data: UpdatePercentPMRReq) => {
  const isValid = verifyReqNoProject(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<UpdatePercentPMRReq>(state => ({
    method: 'POST',
    path: 'UpdatePercentPMR',
    data,
    token: `Bearer ${token}`,
  }))(setPercentPMRAction)
}

export const saveCertReport = (token: string, data: SaveCertReq) => {
  const isValid = verifyReqNoProject(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<SaveCertReq>(state => ({
    method: 'POST',
    path: 'SaveCertReport',
    data,
    token: `Bearer ${token}`,
  }))(saveCertAction)
}

export const cancelCertReport = (token: string, data: CancelCertReq) => {
  const isValid = verifyReqNoProject(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<CancelCertReq>(state => ({
    method: 'POST',
    path: 'CancelCertReport',
    data,
    token: `Bearer ${token}`,
  }))(cancelCertAction)
}

export const exportReportList = (token: string, data: GetReportListReq) => {
  const isValid = verifyReqNoProject(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<GetReportListReq>(state => ({
    method: 'POST',
    path: 'GetCertReportList',
    data,
    token: `Bearer ${token}`,
  }))(exportReportListAction)
}
