import RootState from 'typings/RootState'
import { Event } from '../../types/event'
import { DataList, DataDetail, PercentPMR } from './types'

export const selectEvent = (state: RootState): Event =>
  state.adminReportCertificate.Event

export const selectReportList = (state: RootState): DataList =>
  state.adminReportCertificate.DataList

export const selectReportDetail = (state: RootState): DataDetail =>
  state.adminReportCertificate.DataDetail

export const selectFilter = (state: RootState) =>
  state.adminReportCertificate.Filter

export const selectInitFilter = (state: RootState) =>
  state.adminReportCertificate.ClearFilter

export const selectSorting = (state: RootState) =>
  state.adminReportCertificate.sorting

export const selectPercentPMR = (state: RootState): number =>
  state.adminReportCertificate.PMR.PercentPMR

export const selectPMREvent = (state: RootState): Event =>
  state.adminReportCertificate.PmrEvent
