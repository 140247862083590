import {
  CheckListState,
  CheckListAction,
  CheckListDetail,
  DocumentDetail,
  GetTemplateCodeListRep,
} from './types'

import produce from 'immer'
import parseErrorMessage from 'utils/parseErrorMessage'
import { cloneDeep } from 'lodash'

import { getType } from 'typesafe-actions'
import * as actions from './actions'

export const original: CheckListDetail = {
  CheckListID: '',
  CheckListName: '',
  Status: 'Ready',
  InspectionList: [],
  WarningList: [],
  TemplateCode: '',
  ProcurementTaskID: '',
}

export const originalDoc: DocumentDetail = {
  QCTypeID: '',
  Score: 0,
  IsRequireQC: false,
  CheckList: [],
}

const eventer = {
  IsLoading: false,
  SubmitError: '',
  SubmitSuccess: false,
  SubmitType: '',
  MessageSuccess: '',
}

const initialState: CheckListState = {
  CheckList: {
    Total: 0,
    List: [],
  },
  CheckListDetail: cloneDeep(original),
  Document: cloneDeep(originalDoc),
  CheckListActivity: {
    ProjectCode: '',
    ActivityList: [],
  },
  ActivityUpdate: [],
  Event: cloneDeep(eventer),
  EventActivity: cloneDeep(eventer),
  TemplateList: [],
}

export default function(
  state: CheckListState = initialState,
  action: CheckListAction
): CheckListState {
  return produce(state, draft => {
    switch (action.type) {
      case getType(actions.reset):
        draft.CheckListDetail = cloneDeep(original)
        draft.Document = cloneDeep(originalDoc)
        draft.ActivityUpdate = []
        draft.Event = cloneDeep(eventer)
        break

      case getType(actions.resetUpdateActivityCheckList):
        draft.ActivityUpdate = []
        break

      case getType(actions.updateCheckListDetail):
        draft.CheckListDetail = action.payload
        break

      case getType(actions.getCheckListReq.request):
        draft.Event = cloneDeep(eventer)
        draft.Event.IsLoading = true
        draft.CheckList = {
          Total: 0,
          List: [],
        }
        break
      case getType(actions.getCheckListReq.success):
        draft.CheckList = action.payload.ResponseData
        draft.Event.IsLoading = false
        break

      case getType(actions.getCheckListByIdReq.request):
        draft.Event.IsLoading = true
        draft.Event.SubmitError = ''
        draft.CheckListDetail = cloneDeep(original)
        draft.ActivityUpdate = []
        break
      case getType(actions.getCheckListByIdReq.success):
        if (action.payload.ResponseData) {
          draft.CheckListDetail = action.payload.ResponseData
        }
        draft.Event.IsLoading = false
        break

      case getType(actions.deleteCheckListByIdReq.request):
      case getType(actions.createCheckListReq.request):
      case getType(actions.updateCheckListReq.request):
      case getType(actions.updateActivityByCheckListReq.request):
        draft.Event = {
          IsLoading: true,
          SubmitError: '',
          SubmitSuccess: false,
          SubmitType: '',
          MessageSuccess: '',
        }
        break

      case getType(actions.createCheckListReq.success):
        const ResponseData: any = action.payload.ResponseData || {}
        draft.CheckListDetail.CheckListID = ResponseData.CheckListID || ''
        draft.Event.IsLoading = false
        draft.Event.SubmitSuccess = true
        break

      case getType(actions.updateCheckListReq.success):
      case getType(actions.updateActivityByCheckListReq.success):
        draft.Event.IsLoading = false
        draft.Event.SubmitSuccess = true
        draft.Event.MessageSuccess = 'แก้ไขสำเร็จ'
        break

      case getType(actions.getActivityByCheckListReq.request):
        draft.CheckListActivity = {
          ProjectCode: '',
          ActivityList: [],
        }
        draft.ActivityUpdate = []
        draft.EventActivity = cloneDeep(eventer)
        draft.EventActivity.IsLoading = true
        break

      case getType(actions.getActivityByCheckListReq.success):
        draft.CheckListActivity = action.payload.ResponseData
        draft.EventActivity.IsLoading = false
        break

      case getType(actions.updateActivityCheckList):
        draft.ActivityUpdate = action.payload
        break

      case getType(actions.getTemplateCodeListReq.request):
        draft.Event = cloneDeep(eventer)
        draft.Event.IsLoading = true
        draft.TemplateList = []
        break
      case getType(actions.getTemplateCodeListReq.success):
        const ResponseDataList: any = action.payload.ResponseData || []
        draft.TemplateList = ResponseDataList
        draft.Event.IsLoading = false
        break

      case getType(actions.getTemplateCodeListReq.failure):
      case getType(actions.createCheckListReq.failure):
      case getType(actions.updateCheckListReq.failure):
      case getType(actions.getCheckListReq.failure):
      case getType(actions.getCheckListByIdReq.failure):
      case getType(actions.getActivityByCheckListReq.failure):
      case getType(actions.deleteCheckListByIdReq.failure):
      case getType(actions.updateActivityByCheckListReq.failure):
        draft.Event.IsLoading = false
        draft.Event.SubmitError = parseErrorMessage(action.payload)
        break
      case getType(actions.deleteCheckListByIdReq.success):
        draft.Event.IsLoading = false
        draft.Event.SubmitSuccess = true
        draft.Event.MessageSuccess = 'ลบสำเร็จ'
        break

      case getType(actions.updateImport):
        draft.CheckListDetail = action.payload.Checklist
        draft.Document = action.payload.Document
        break

      default:
        return state
    }
  })
}
