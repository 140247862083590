import React, { useState } from 'react'
import { Row, Col } from 'antd'
import styled from 'styled-components'
import CardListHeader, {
  CardListHeaderItem,
} from '../../cards/CardList/CardListHeader'
import { TableHeader as Table, TableColHeader } from 'Components/Shared/Table'

type Props = {
  type: string
  onSort(name: string): void
  sorting: any
  onSelectAll?: () => void
  selectAll?: boolean
}

const TableHeader: React.FunctionComponent<Props> = props => {
  const { onSort, sorting, type } = props

  const getType = (colName: string) => {
    if (sorting.name === colName) {
      return sorting.type
    }
    return ''
  }

  const todoHeaderItems = [
    {
      title: '',
      span: 1,
      isShowSelect: true,
      showSort: false,
    },
    {
      title: 'Activity',
      span: 7,
      key: 'ActivityName',
      showSort: false,
    },
    {
      title: 'หมวดหมู่',
      span: 3,
      key: 'Unit',
    },
    {
      title: 'ประเภทก่อสร้าง',
      span: 4,
      key: 'ConstructionTypeName',
    },
    {
      title: 'วันเริ่มงาน',
      span: 3,
      key: 'StartDate',
    },
    {
      title: 'วันสิ้นสุดงาน',
      span: 3,
      key: 'FinishDate',
    },
    {
      title: 'กำหนดการ',
      span: 3,
      key: 'countdown-todo',
      showSort: false,
    },
  ]

  const inprogressHeaderItems = [
    {
      title: 'Activity',
      span: 6,
      key: 'ActivityName',
      showSort: false,
    },
    {
      title: 'หมวดหมู่',
      span: 4,
      key: 'Unit',
    },
    {
      title: 'ประเภทก่อสร้าง',
      span: 4,
      key: 'ConstructionTypeName',
    },
    {
      title: 'วันสิ้นสุดงาน',
      span: 3,
      key: 'FinishDate',
    },
    {
      title: 'วันสิ้นสุดกำหนดการงาน',
      span: 4,
      key: 'countdown-inprogress',
    },
    {
      title: 'ความคืบหน้า',
      span: 3,
      key: 'Progress',
    },
  ]

  const renderColumn = (column: any[]) => {
    return column.map((item: any, index: number) => {
      return (
        <TableColHeader
          key={index}
          offset={item.offset}
          span={item.span}
          onSort={() => onSort(item.key)}
          type={getType(item.key)}
          sort={item.showSort}
          isShowSelect={item.isShowSelect}
          onSelectAll={props.onSelectAll}
          isSelectAll={props.selectAll}
        >
          {item.title}
        </TableColHeader>
      )
    })
  }

  return (
    <Table paddingLeft={27}>
      {renderColumn(type == 'todo' ? todoHeaderItems : inprogressHeaderItems)}
    </Table>
  )
}

export default TableHeader
