import withAPIAction from 'utils/withAPIAction'
import {
  getDataListAction,
  createDataAction,
  updateDataAction,
  getSansiriListAction,
  getVandorListAction,
  getCompanyVandorListAction,
  findUserAction,
} from './actions'

import {
  GetDataListReq,
  GetFilterListReq,
  CreateDataReq,
  UpdateDataReq,
  FindUserReq,
} from './types'

import { verifyReqNoProject } from 'utils/verify'

export const getDataList = (token: string, data: GetDataListReq) => {
  const isValid = verifyReqNoProject(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<GetDataListReq>({
    method: 'POST',
    path: 'GetUserList',
    data,
    token: `Bearer ${token}`,
  })(getDataListAction)
}

export const createData = (token: string, data: CreateDataReq) => {
  const isValid = verifyReqNoProject(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<CreateDataReq>({
    method: 'POST',
    path: 'CreateUser',
    data,
    token: `Bearer ${token}`,
  })(createDataAction)
}

export const updateData = (token: string, data: UpdateDataReq) => {
  const isValid = verifyReqNoProject(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<UpdateDataReq>({
    method: 'POST',
    path: 'UpdateUser',
    data,
    token: `Bearer ${token}`,
  })(updateDataAction)
}

export const getSansiriList = (token: string, data: GetFilterListReq) => {
  const isValid = verifyReqNoProject(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<GetFilterListReq>({
    method: 'POST',
    path: 'GetSansiriUserList',
    data,
    token: `Bearer ${token}`,
  })(getSansiriListAction)
}

export const getVandorList = (token: string, data: GetFilterListReq) => {
  const isValid = verifyReqNoProject(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<GetFilterListReq>({
    method: 'POST',
    path: 'GetVendorUserList',
    data,
    token: `Bearer ${token}`,
  })(getVandorListAction)
}

export const getCompanyVandorList = (token: string, data: GetFilterListReq) => {
  const isValid = verifyReqNoProject(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<GetFilterListReq>({
    method: 'POST',
    path: 'GetVendorCompanyList',
    data,
    token: `Bearer ${token}`,
  })(getCompanyVandorListAction)
}

export const findUserList = (token: string, data: FindUserReq) => {
  const isValid = verifyReqNoProject(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<FindUserReq>({
    method: 'POST',
    path: 'FindUserList',
    data,
    token: `Bearer ${token}`,
  })(findUserAction)
}
