import React from 'react'
import { Row } from 'antd'
import SearchPaginationLinkButton from 'Components/buttons/SearchPaginationLinkButton'

const range = (start: number, end: number) => {
  const pages = [start]

  if (start === end) return pages

  for (let i = start + 1; i <= end; i++) {
    pages.push(i)
  }

  return pages
}

const generatePaginationLinks = (
  keyword: string,
  end: number,
  perPage: number,
  currentPage = 1
) => {
  const pages = range(1, end)
  return pages.map(page => (
    <li key={page}>
      <SearchPaginationLinkButton
        to={{
          pathname: '/search-results',
          search: `?keyword=${keyword}&page=${page}&rowPerPage=${perPage}`,
        }}
      >
        <div
          className={
            currentPage === page
              ? 'pagination-number active-page'
              : 'pagination-number'
          }
        >
          <span className="page">{page}</span>
        </div>
      </SearchPaginationLinkButton>
    </li>
  ))
}

type Props = {
  keyword: string
  currentPage: number
  totalRows: number
  perPage: number
}

const SearchResultPagination: React.FunctionComponent<Props> = ({
  keyword,
  currentPage,
  totalRows,
  perPage = 1, // To Prevent it from being zero
}) => {
  const totalPages = Math.ceil(totalRows / perPage)
  const firstPage = 1
  const previousPage = currentPage > 1 ? currentPage - 1 : 1
  const nextPage = currentPage === totalPages ? totalPages : currentPage + 1
  const lastPage = totalPages

  return (
    <Row className="pagination-wrapper" style={{ width: '50%' }}>
      <ul className="pagination-block">
        <li>
          <SearchPaginationLinkButton
            to={{
              pathname: '/search-results',
              search: `?keyword=${keyword}&page=${firstPage}&rowPerPage=${perPage}`,
            }}
          >
            <span className="pagination-icon ic-first">
              <img src="/img/ic-first.svg" alt="go to first page" />
            </span>
          </SearchPaginationLinkButton>
        </li>
        <li>
          <SearchPaginationLinkButton
            to={{
              pathname: '/search-results',
              search: `?keyword=${keyword}&page=${previousPage}&rowPerPage=${perPage}`,
            }}
          >
            <span className="pagination-icon ic-previous">
              <img src="/img/ic-previous.svg" alt="go to previous page" />
            </span>
          </SearchPaginationLinkButton>
        </li>
        {generatePaginationLinks(keyword, totalPages, perPage, currentPage)}
        <li>
          <SearchPaginationLinkButton
            to={{
              pathname: '/search-results',
              search: `?keyword=${keyword}&page=${nextPage}&rowPerPage=${perPage}`,
            }}
          >
            <span className="pagination-icon ic-next">
              <img src="/img/ic-next.svg" alt="go to next page" />
            </span>
          </SearchPaginationLinkButton>
        </li>
        <li>
          <SearchPaginationLinkButton
            to={{
              pathname: '/search-results',
              search: `?keyword=${keyword}&page=${lastPage}&rowPerPage=${perPage}`,
            }}
          >
            <span className="pagination-icon ic-last">
              <img src="/img/ic-last.svg" alt="go to last page" />
            </span>
          </SearchPaginationLinkButton>
        </li>
      </ul>
    </Row>
  )
}

export default SearchResultPagination
