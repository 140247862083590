import React, { useState, useEffect } from 'react'
import { Modal, Button, Row, Col, Select } from 'antd'
import CloseIcon from 'Components/Shared/Icon/CloseIcon'
import styled from 'styled-components'
import '../../../css/modal.less'
import InputText from 'Components/Shared/Input/InputText'
import { DropdownList } from 'Components/Shared/Form'
import PrimaryButton from 'Components/Shared/Button/PrimaryButton'
import OutlineButton from 'Components/Shared/Button/OutlineButton'
import Checkbox from 'Components/Shared/Form/Checkbox'

import { toText } from '../../../utils/format'

interface Props {
  visible?: boolean
  onClose?: () => void
  onOk?: () => void
  name: string
}

const DeleteModal: React.FC<Props> = ({
  visible = false,
  onClose,
  onOk,
  name,
}) => {
  const [check, setCheck] = useState(false)
  useEffect(() => {
    setCheck(false)
  }, [visible])

  const buttonAction = (
    <Row>
      <Col span={6} offset={6}>
        <OutlineButton text="ยกเลิก" disabled={false} onClick={onClose} />
      </Col>
      <Col span={11} offset={1}>
        <PrimaryButton
          text="ใช่ ฉันยืนยันลบรายการนี้"
          disabled={!check}
          onClick={onOk}
        />
      </Col>
    </Row>
  )

  const text = toText(name)
  return (
    <ModalCheckList>
      <Modal
        centered
        visible={visible}
        width="528px"
        footer={null}
        closeIcon={<CloseIcon color="#8b99a7" size={16} />}
        title={<Header>ลบรายการ</Header>}
        className="add-checklist-modal"
        onCancel={onClose}
      >
        <ModalContent>
          <span>คุณยืนยันต้องการลบ</span>&nbsp;&nbsp;
          <span className="text-warning">{text}</span>
          <CheckListWarning>
            <Checkbox
              label={`รายการภายใต้ “${text}” จะถูกลบ`}
              value={check}
              onChange={(val: any) => setCheck(val)}
            />
          </CheckListWarning>
          <ButtonSection>{buttonAction}</ButtonSection>
        </ModalContent>
      </Modal>
    </ModalCheckList>
  )
}

const Header = styled.span`
  font-size: 28px;
  color: #000000;
  font-family: 'DB Helvethaica X 75 Bd';
`

const ModalCheckList = styled.div`
  .add-checklist-modal {
    .ant-modal-header {
      border-bottom: none;
    }
  }
`

const ModalContent = styled.div`
    padding : 0 24px 31px 24px;
    font-family: 'DB Helvethaica X 55 Regular';
    font-size: 28px;
    font-size: 20px;
    .text-warning {
      font-family: 'DB Helvethaica X 75 Bd';
      color : #d0494c;
    }
  }
`

const CheckListWarning = styled.div`
  padding-top: 18px;
`

const ButtonSection = styled.div`
  padding-top: 30px;
`

export default DeleteModal
