import { put, take, takeEvery, select } from 'redux-saga/effects'
import { APIPath } from 'services/APIPath'
import { getType } from 'typesafe-actions'
import * as actions from './actions'
import * as uploadAction from '../../uploadfile/actions'
import {
  saveSignature,
  saveZoneSignature,
  saveCommissionSignature,
  saveConstructPlanSignature,
  saveAuditPlanSignature,
  saveConstructRoadSignature,
  saveAuditRoadSignature,
} from './api'

import { uploadFileReq } from '../../uploadfile/api'
import accountHelper from 'utils/account'

function* uploadFile(userEmail: string, token: string, file: any) {
  if (file) {
    const formData = new FormData()
    formData.set('UserEmail', userEmail)
    formData.append('Files', file)
    yield put(uploadFileReq(formData, token))
    const resp = yield take(uploadAction.uploadFileAsyncReq.success)
    const resultList = resp.payload.ResponseData
    return resultList[0]
  }

  return undefined
}

function* submitSignReq(action: any) {
  const { token, req } = action.payload
  const { UserEmail } = req
  try {
    req.SignImage = yield uploadFile(UserEmail, token, req.SignImage)
    yield put(saveSignature(token, req))
  } catch (e) {
    console.log('e', e.message)
  }
}

function* submitZoneSignReq(action: any) {
  const { token, req } = action.payload
  const { UserEmail } = req
  try {
    req.SignImage = yield uploadFile(UserEmail, token, req.SignImage)
    yield put(saveZoneSignature(token, req))
  } catch (e) {
    console.log('e', e.message)
  }
}

function* submitCommissionSignReq(action: any) {
  const { token, req } = action.payload
  const { UserEmail } = req
  try {
    req.SignImage = yield uploadFile(UserEmail, token, req.SignImage)
    yield put(saveCommissionSignature(token, req))
  } catch (e) {
    console.log('e', e.message)
  }
}

function* submitConstructPlanSignReq(action: any) {
  const { token, req } = action.payload
  const { UserEmail } = req
  try {
    req.SignImage = yield uploadFile(UserEmail, token, req.SignImage)
    yield put(saveConstructPlanSignature(token, req))
  } catch (e) {
    console.log('e', e.message)
  }
}

function* submitAuditPlanSignReq(action: any) {
  const { token, req } = action.payload
  const { UserEmail } = req
  try {
    req.SignImage = yield uploadFile(UserEmail, token, req.SignImage)
    yield put(saveAuditPlanSignature(token, req))
  } catch (e) {
    console.log('e', e.message)
  }
}

function* submitConstructRoadSignReq(action: any) {
  const { token, req } = action.payload
  const { UserEmail } = req
  try {
    req.SignImage = yield uploadFile(UserEmail, token, req.SignImage)
    yield put(saveConstructRoadSignature(token, req))
  } catch (e) {
    console.log('e', e.message)
  }
}

function* submitAuditRoadSignReq(action: any) {
  const { token, req } = action.payload
  const { UserEmail } = req
  try {
    req.SignImage = yield uploadFile(UserEmail, token, req.SignImage)
    yield put(saveAuditRoadSignature(token, req))
  } catch (e) {
    console.log('e', e.message)
  }
}

function* allSaga() {
  yield takeEvery(getType(actions.submitSignReq), submitSignReq)
  yield takeEvery(getType(actions.submitZoneSignReq), submitZoneSignReq)
  yield takeEvery(
    getType(actions.submitCommissionSignReq),
    submitCommissionSignReq
  )
  yield takeEvery(
    getType(actions.submitConstructPlanSignReq),
    submitConstructPlanSignReq
  )
  yield takeEvery(
    getType(actions.submitAuditPlanSignReq),
    submitAuditPlanSignReq
  )
  yield takeEvery(
    getType(actions.submitConstructRoadSignReq),
    submitConstructRoadSignReq
  )

  yield takeEvery(
    getType(actions.submitAuditRoadSignReq),
    submitAuditRoadSignReq
  )
}

export default allSaga
