import withAPIAction from 'utils/withAPIAction'
import {
  getDataListAction,
  createDataAction,
  updateDataAction,
  getReasonListAction,
} from './actions'
import { GetDataListReq, ConfigListDetailReq } from './types'
import { verifyReq } from 'utils/verify'

export const onGetConfiguration = (token: string, data: GetDataListReq) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<GetDataListReq>({
    method: 'POST',
    path: 'GetConfiguration',
    data,
    token: `Bearer ${token}`,
  })(getDataListAction)
}

export const onGetConfigurationReason = (
  token: string,
  data: GetDataListReq
) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<GetDataListReq>({
    method: 'POST',
    path: 'GetConfiguration',
    data,
    token: `Bearer ${token}`,
  })(getReasonListAction)
}

export const onCreateConfiguration = (
  token: string,
  data: ConfigListDetailReq
) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<ConfigListDetailReq>({
    method: 'POST',
    path: 'CreateConfiguration',
    data,
    token: `Bearer ${token}`,
  })(createDataAction)
}

export const onUpdateConfiguration = (
  token: string,
  data: ConfigListDetailReq
) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<ConfigListDetailReq>({
    method: 'POST',
    path: 'UpdateConfiguration',
    data,
    token: `Bearer ${token}`,
  })(updateDataAction)
}
