import { createStandardAction, createAsyncAction } from 'typesafe-actions'
import {
  GetReportReq,
  GetReportResp,
  GetCheckListReq,
  GetCheckListResp,
  DefectTypeReq,
  DefectItem,
  SendDefectReq,
  AuditRoadReport,
  SetDefectReq,
  VerifyDefectReq,
  ResendReportReq,
  SendEditReportToQCReq,
  CommonResp,
  GetSelectPinReq,
  GetSelectPinResp,
  UpdateSelectPinReq,
  GetSelectLocationReq,
  GetSelectLocationResp,
  UpdateSelectLocationReq,
} from './types'

export const getReportAction = createAsyncAction(
  'report/auditRoad/FETCH_REPORT_REQUEST',
  'report/auditRoad/FETCH_REPORT_SUCCESS',
  'report/auditRoad/FETCH_REPORT_FAILURE'
)<GetReportReq, GetReportResp, any>()

export const getCheckListAction = createAsyncAction(
  'report/auditRoad/GET_CHECKLIST_QC_REQ',
  'report/auditRoad/GET_CHECKLIST_QC_RESP',
  'report/auditRoad/GET_CHECKLIST_QC_FAIL'
)<GetCheckListReq, GetCheckListResp, any>()

export const resetDefect = createStandardAction(
  'report/auditRoad/RESET_DEFECT_ACTION'
)<void>()

export const updateDefect = createStandardAction(
  'report/auditRoad/UPDATE_DEFECT_ACTION'
)<DefectItem>()

export const createDefectAction = createAsyncAction(
  'report/auditRoad/CREATE_DEFECT_REQ',
  'report/auditRoad/CREATE_DEFECT_RESP',
  'report/auditRoad/CREATE_DEFECT_FAIL'
)<DefectTypeReq, CommonResp, any>()

export const updateDefectAction = createAsyncAction(
  'report/auditRoad/UPDATE_DEFECT_REQ',
  'report/auditRoad/UPDATE_DEFECT_RESP',
  'report/auditRoad/UPDATE_DEFECT_FAIL'
)<DefectTypeReq, CommonResp, any>()

export const setDefectAction = createAsyncAction(
  'report/auditRoad/SET_DEFECT_REQ',
  'report/auditRoad/SET_DEFECT_RESP',
  'report/auditRoad/SET_DEFECT_FAIL'
)<SetDefectReq, CommonResp, any>()

export const verifyDefectAction = createAsyncAction(
  'report/auditRoad/VERIFY_DEFECT_REQ',
  'report/auditRoad/VERIFY_DEFECT_RESP',
  'report/auditRoad/VERIFY_DEFECT_FAIL'
)<VerifyDefectReq, CommonResp, any>()

export const resendReportAction = createAsyncAction(
  'report/auditRoad/RESEND_REPORT_REQ',
  'report/auditRoad/RESEND_REPORT_RESP',
  'report/auditRoad/RESEND_REPORT_FAIL'
)<ResendReportReq, CommonResp, any>()

export const sendEditReportAction = createAsyncAction(
  'report/auditRoad/SEND_EDIT_REPORT_REQ',
  'report/auditRoad/SEND_EDIT_REPORT_RESP',
  'report/auditRoad/SEND_EDIT_REPORT_FAIL'
)<SendEditReportToQCReq, CommonResp, any>()

export const submitDefectReq = createStandardAction(
  'report/auditRoad/SUBMIT_REQUEST'
)<SendDefectReq>()

export const setHowToFixReq = createStandardAction(
  'report/auditRoad/SUBMIT_HOWTOFIX_REQUEST'
)<SendDefectReq>()

export const updateReport = createStandardAction(
  'report/auditRoad/UPDATE_REPORT_ACTION'
)<AuditRoadReport>()

export const resetEvent = createStandardAction(
  'report/auditRoad/RESET_EVENT_ACTION'
)<void>()

export const getSelectPinAction = createAsyncAction(
  'report/auditRoad/GET_SELECT_PIN_REQ',
  'report/auditRoad/GET_SELECT_PIN_RESP',
  'report/auditRoad/GET_SELECT_PIN_FAIL'
)<GetSelectPinReq, GetSelectPinResp, any>()

export const updateSelectedPintAction = createAsyncAction(
  'report/auditRoad/UPDATE_SELECTED_PIN_REQ',
  'report/auditRoad/UPDATE_SELECTED_PIN_RESP',
  'report/auditRoad/UPDATE_SELECTED_PIN_FAIL'
)<UpdateSelectPinReq, CommonResp, any>()

export const getSelectLocationAction = createAsyncAction(
  'report/auditRoad/GET_SELECT_LOCATION_REQ',
  'report/auditRoad/GET_SELECT_LOCATION_RESP',
  'report/auditRoad/GET_SELECT_LOCATION_FAIL'
)<GetSelectLocationReq, GetSelectLocationResp, any>()

export const updateSelectedLocationAction = createAsyncAction(
  'report/auditRoad/UPDATE_SELECTED_LOCATION_REQ',
  'report/auditRoad/UPDATE_SELECTED_LOCATION_RESP',
  'report/auditRoad/UPDATE_SELECTED_LOCATION_FAIL'
)<UpdateSelectLocationReq, CommonResp, any>()
