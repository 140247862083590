import withAPIAction from '../../../utils/withAPIAction'

import { getCheckListReq } from './actions'
import { FetchCheckListReq } from './types'

export const getLocalCheckList = (
  ProjectCode: string,
  Page: number,
  RowPerPage: number,
  TextFilter: string = ''
) => {
  return withAPIAction<FetchCheckListReq>(state => ({
    method: 'POST',
    path: 'GetMainconCheckList',
    data: {
      UserEmail: state.sensitive.userEmail,
      ProjectCode,
      Page,
      RowPerPage,
      TextFilter,
    },
    token: `Bearer ${state.sensitive.apiToken}`,
  }))(getCheckListReq)
}
