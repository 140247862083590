import React from 'react'
import styled from 'styled-components'

interface Props {
  title: string
  value?: string
}

const WarningText = (props: Props) => {
  const { title, value } = props

  return (
    <DisplayWarning>
      <label>{title}</label>
      <p>{value ? value : '-'}</p>
    </DisplayWarning>
  )
}

const DisplayItem: any = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 20px;
  padding: 0 5px;
  p {
    font-family: 'DB Helvethaica X 55 Regular';
  }
`

const DisplayWarning = styled(DisplayItem)`
  color: #d0494c;
`

export default WarningText
