import React, { useState } from 'react'
import { Drawer, Col, Badge } from 'antd'
import styled from 'styled-components'
import { TableRow, StatusCol } from '../../Shared/Table'
import { EditIcon, TrashIcon } from 'Components/Shared/Icon'
import { DataItem } from 'features/admin/team/types'

type Props = {
  onClick?: (evt: any, item: any) => void
  onDelete?: (item: any) => void
  item: DataItem
  selected?: boolean
}

const RowItem: React.FC<Props> = props => {
  const { item, selected, onDelete = () => {} } = props
  const onClick = (evt: any) => {
    if (props.onClick) {
      props.onClick(evt, item)
    }
  }

  const getSquadNames = () => {
    const names = item.SquadList.map(squad => {
      return squad.SquadName
    })
    return names.join(',')
  }

  return (
    <>
      <TableRow onClick={onClick} selected={selected} minHeight={60}>
        <Col lg={6}>
          <Name>{item.TeamName}</Name>
        </Col>
        <Col lg={8}>{getSquadNames()}</Col>
        <Col lg={6} offset={1}>
          <IconDiv>
            <IconStyle>
              <EditIcon onClick={onClick} color="#8b99a7" />
            </IconStyle>
            <IconStyle>
              <TrashIcon onClick={() => onDelete(item)} color="#8b99a7" />
            </IconStyle>
          </IconDiv>
        </Col>
      </TableRow>
    </>
  )
}

const Name = styled.div`
  padding-left: 25px;
  font-size: 20px;
  font-family: 'DB Helvethaica X 75 Bd';
`
const IconDiv = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
`

const IconStyle = styled.div`
  padding: 0 9px;
  cursor: pointer;
`
export default RowItem
