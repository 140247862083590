import withAPIAction from '../../utils/withAPIAction'
import { APIPath } from '../../services/APIPath'
import {
  getServiceTokenAsyncAction,
  getUserProfileAsyncAction,
  loginVendorAction,
} from './sensitive.actions'
import { ServiceTokenRequest } from './sensitive.types'
import account from 'utils/account'

interface ServiceTokenRequestInterface {
  email: string
  token: string
}

export const submitServiceTokenRequest = (
  data: ServiceTokenRequestInterface
) => {
  if (data.token === '' || data.email === '') {
    return { type: 'sensitive/no_token' }
  }

  const tokenCode = account.getTokenCode()

  // If we don't have token code, get token code from API and store it locally.
  if (tokenCode === '' || !tokenCode) {
    return withAPIAction<ServiceTokenRequest>({
      method: 'POST',
      path: APIPath.getUserProfile,
      data: {
        UserEmail: data.email,
      },
      token: `Bearer ${data.token}`,
    })(getServiceTokenAsyncAction)
  }

  // If we have token code get it from cache, if this is expired. It will be cleared
  // on next fetch API will renew token.
  return {
    type: 'sensitive/SERVICE_TOKEN_SUCCESS',
    payload: {
      ResponseData: account.getUserProfile(),
      ResponseCode: 1,
      ResponseMsg: 'Success',
      RequestTime: '',
      ResponseTime: '',
    },
  }
}

export const getUserProfileRequest = (data: ServiceTokenRequestInterface) => {
  if (data.token === '' || data.email === '') {
    return { type: 'sensitive/no_token' }
  }

  // If we don't have token code, get token code from API and store it locally.
  return withAPIAction<ServiceTokenRequest>({
    method: 'POST',
    path: APIPath.getUserProfile,
    data: {
      UserEmail: data.email,
    },
    token: `Bearer ${data.token}`,
  })(getUserProfileAsyncAction)
}

export const loginVendorReq = (token: string) => {
  return withAPIAction<any>({
    method: 'GET',
    path: 'GetVendorCheckLogin?meta=%7B"Device":"web","Version":""%7D',
    data: {},
    token: `Basic ${token}`,
  })(loginVendorAction)
}
