import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { connect, useDispatch } from 'react-redux'
import SubnavBar from 'Components/Shared/SubnavBar'
import { Row, Col } from 'antd'
import { RouteComponentProps } from 'react-router-dom'
import RootState from 'typings/RootState'
import { BreadcrumbLink } from '../../Shared/BreadCrumb/BreadCrumb'
import { selectedProjectCodeSelector } from 'features/project/project.selector'
import {
  userEmailSelector,
  serviceTokenSelector,
} from 'features/sensitive/sensitive.selector'
import {
  selectData,
  selectEvent,
  selectSorting,
} from 'features/cmpj/cmdashboard/selector'
import { sortingDashboard } from 'features/cmpj/cmdashboard/actions'
import { getActivityForCM } from 'features/cmpj/cmdashboard/api'
import {
  Activity,
  GetActivityRequest,
  TypeOfTask,
} from 'features/cmpj/cmdashboard/types'
import { translate } from 'i18n'
import TKeys from 'i18n/translationKey'
import Menu from '../Menu'
import ColumnHeader from './ColumnHeader'
import Card from './Card'
import LinkButton from 'Components/Shared/LinkButton'
import Loading from 'Components/Shared/Loading'
import { LoadingView } from 'Components/Shared'
import { NoCard } from 'Components/Shared'
import { PrimaryButton } from 'Components/Shared/Button'
import { ActivityFilterPage } from 'Components/Filter'
import {
  selectActivityFilter,
  selectisClearFilter,
} from 'features/filter/selector'
import { toDateText } from 'utils/datetime'
import { mergeArray } from 'utils/format'
import { clearFilter } from 'features/filter/actions'
import { getEvent } from 'features/wbs/wbs.selector'
import { useLocation } from 'react-router'
import queryString from 'query-string'
import { ActivityFilter } from 'features/filter/types'

const breadcrumbs: BreadcrumbLink[] = [
  {
    url: '/',
    title: 'Home',
    icon: '/home.png',
    active: true,
    showTitle: false,
  },
  {
    url: '/',
    title: translate(TKeys.Breadcrumb.dashboard),
    active: false,
    showTitle: false,
  },
]

const text = {
  todo: 'รายการคำขอ',
  in_progress: translate(TKeys.Dashboard.header_in_progress),
  start_day: 'วันที่เริ่มงาน',
  end_day: 'วันสิ้นสุดงาน',
  complete: 'เสร็จสิ้น',
}

type Props = ReduxProps & DispatchProps & RouteComponentProps

const CmDashboard: React.ComponentType<Props> = props => {
  const {
    data,
    event,
    sorting,
    token,
    ProjectCode,
    UserEmail,
    sortingTask,
    filter,
    isClearFilter,
    wbsEvent,
  } = props

  const dispatch = useDispatch()
  const searchParam = useLocation()

  useEffect(() => {
    if (isClearFilter) {
      onGetActivityForCM()
    }
  }, [isClearFilter, ProjectCode])

  const onGetActivityForCM = () => {
    if (searchParam.search) {
      const param = queryString.parse(searchParam.search)
      const activityFilter: ActivityFilter = JSON.parse(
        param.search!.toString()
      )

      filter.Date = activityFilter.Date
      filter.ConstructionTypeList = activityFilter.ConstructionTypeList
      filter.UnitList = activityFilter.UnitList
      filter.FloorList = activityFilter.FloorList
      filter.LeafWbsList = activityFilter.LeafWbsList
    }

    const data: GetActivityRequest = {
      ProjectCode,
      UserEmail,
      StartDate: toDateText(filter.Date.StartDate || ''),
      FinishDate: toDateText(filter.Date.FinishDate || ''),
      UnitList: mergeArray([filter.UnitList, filter.ZoneList]),
      ZoneList: [],
      FloorList: filter.FloorList,
      ConstructionTypeList: filter.ConstructionTypeList,
      WbsList: filter.LeafWbsList,
      SortToDo: {
        OrderBy: sorting['todo'],
        SortBy: 'FinishDate',
      },
      SortInProgress: {
        OrderBy: sorting['in-progress'],
        SortBy: 'FinishDate',
      },
      SortDone: {
        OrderBy: sorting['done'],
        SortBy: 'FinishDate',
      },
    }

    dispatch(getActivityForCM(token, data))
  }

  const renderCard = (task: Activity[], type: TypeOfTask) => {
    let card = []
    task.map((activity, index) => {
      card.push(
        <Card
          key={index}
          task={activity}
          type={type}
          search={searchParam.search}
        />
      )
    })

    if (task.length >= 10) {
      card.push(
        <LinkButton
          href={`/seemore/${type}${searchParam.search}`}
          title={'ดูเพิ่มเติม'}
        />
      )
    }
    return card
  }

  const loading = (
    <div style={{ textAlign: 'center' }}>
      <Loading />
    </div>
  )

  const renderTaskLen = (
    type: TypeOfTask,
    task: Activity[],
    header: string,
    orderName: string,
    total: number
  ) => {
    const card =
      task.length > 0 ? (
        renderCard(task, type)
      ) : (
        <NoCard text="ไม่มีรายการคำขอ" />
      )
    const content = event.isFetching ? loading : card
    return (
      <Col span={8}>
        <FullColumn>
          <ColumnHeader
            heading={`${header} (${total})`}
            orderName={orderName}
            type={sorting[type]}
            onSort={() => {
              sortingTask(type)
            }}
          />
          {content}
        </FullColumn>
      </Col>
    )
  }

  const gotoSeemorePage = () => {
    props.history.push(`/mcactivity/seemore/todo${searchParam.search}`)
  }

  const header = (
    <Header>
      <Row type="flex" justify="space-between" align="middle">
        <Col>
          <ActivityFilterPage onSearch={onGetActivityForCM} />
        </Col>
        <Col>
          <Btn>
            <PrimaryButton text="สิ่งที่ต้องทำ" onClick={gotoSeemorePage} />
          </Btn>
        </Col>
      </Row>
    </Header>
  )

  return (
    <>
      <Menu selected="maincon" />
      <SubnavBar breadcrumbs={breadcrumbs} hideSearch={true} />
      <LoadingView isShow={event.isFetching || wbsEvent.IsLoading} />
      {header}
      <MainPage>
        <Row type="flex">
          {renderTaskLen(
            TypeOfTask.Todo,
            data.Todo,
            text.todo,
            text.end_day,
            data.TotalTodo
          )}
          {renderTaskLen(
            TypeOfTask.Inprogress,
            data.InProgress,
            text.in_progress,
            text.end_day,
            data.TotalProgress
          )}
          {renderTaskLen(
            TypeOfTask.Done,
            data.Done,
            text.complete,
            text.end_day,
            data.TotalDone
          )}
        </Row>
      </MainPage>
    </>
  )
}

const MainPage = styled.div`
  padding: 0 120px;
  background-color: #f5f6fa;
  width: 100vw;
  -khtml-user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  overflow-x: scroll;
`

const Header = styled.div`
  padding: 0 120px;
  background-color: #f5f6fa;
`

const Btn = styled.div`
  width: 160px;
`

const FullColumn = styled.div`
  padding-right: 20px;
`

const mapStateToProps = (state: RootState) => {
  return {
    data: selectData(state),
    event: selectEvent(state),
    sorting: selectSorting(state),
    token: serviceTokenSelector(state),
    ProjectCode: selectedProjectCodeSelector(state),
    UserEmail: userEmailSelector(state),
    filter: selectActivityFilter(state),
    isClearFilter: selectisClearFilter(state),
    wbsEvent: getEvent(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {
  sortingTask: sortingDashboard,
}

type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(CmDashboard)
