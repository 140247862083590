import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useEffect,
} from 'react'
import { Modal, Row, Col } from 'antd'
import styled from 'styled-components'

import { CloseIcon } from 'Components/Shared/Icon'
import { PrimaryButton, OutlineButton } from 'Components/Shared/Button'
import SearchSelection from 'Components/Inspection/Activity/ActivityList/SearchSelection'
import RadioBox from 'Components/Shared/Input/RadioBox'

import { textToLowerCase } from 'utils/format'

export interface ProjectModalHandles {
  showModal: (filter?: string) => void
}

interface Props {
  onOk: (listr: any[]) => void
  CheckListID: number
  projectList: any[]
  selectList: any[]
}

const ProjectSelector = forwardRef<ProjectModalHandles, Props>(
  ({ onOk: onOkProps, CheckListID, projectList, selectList }, ref) => {
    const [visible, setvisible] = useState(false)
    const [projects, setPropjects] = useState<any[]>([])
    const [selectProjets, setSelectProjets] = useState<any[]>([])
    const [filterTxt, setFilterTxt] = useState('')

    useEffect(() => {
      if (visible === false) return

      setSelectProjets([...selectList])
    }, [selectList, visible])

    useEffect(() => {
      if (visible === false) return

      projectList.forEach(v => {
        let Selected = false
        let detail = ''
        let isReadOnly = false
        if (textToLowerCase(v.Status) === 'used') {
          isReadOnly = true
          detail =
            v.CheckListID === CheckListID
              ? 'ใช้แล้ว'
              : 'ใช้แล้วกับ Checklist อื่น'
        } else {
          const isSameID = CheckListID !== 0 && CheckListID === v.CheckListID
          //const isSelected =
          if (isSameID) {
            Selected = true
          } else {
            const checkId = v.CheckListID || 0
            if (checkId !== 0) {
              isReadOnly = true
              Selected = true
              detail = 'ผูกแล้วกับ Checklist อื่น'
            } else {
              Selected = false
            }
          }
        }
        v.isSelected = Selected
        v.detail = detail
        v.isReadOnly = isReadOnly
      })
      setPropjects(projectList)
    }, [projectList, visible])

    const closeModal = () => {
      setvisible(false)
    }

    const onOk = () => {
      onOkProps(selectProjets)
      setvisible(false)
    }

    useImperativeHandle(ref, () => ({
      showModal: (filter?: string) => {
        setvisible(true)
        if (filter) {
          setFilterTxt(filter)
        } else {
          setFilterTxt('')
        }
      },
    }))

    const onChange = (it: any, { value }: { value: boolean }) => {
      it.isSelected = value

      const index = selectProjets.findIndex(
        v => v.ProjectCode === it.ProjectCode
      )

      if (index > -1) {
        const project = selectProjets[index]
        if (project.Selected === undefined) {
          project.Selected = 'N'
          selectProjets[index] = project
        } else if (project.Selected === 'N') {
          delete project.Selected
          selectProjets[index] = project
        } else {
          selectProjets.splice(index, 1)
        }
      } else {
        selectProjets.push({
          ProjectCode: it.ProjectCode,
          ProjectName: it.ProjectNameTH,
          Selected: 'Y',
        })
      }

      setSelectProjets([...selectProjets])
    }

    const onFilter = (value: string) => {
      setFilterTxt(value)
    }

    const hasText = (data: string, searchTxt: string) => {
      if (filterTxt === '') return true
      if (data && searchTxt) {
        return data.toLocaleLowerCase().includes(searchTxt.toLocaleLowerCase())
      }
      return false
    }

    return (
      <ModalCheckList>
        <Modal
          centered
          visible={visible}
          width="528px"
          footer={null}
          closeIcon={<CloseIcon color="#8b99a7" size={16} />}
          title={<Header>{'เพิ่มโครงการที่ใช้งาน'}</Header>}
          className="add-checklist-modal"
          onCancel={closeModal}
        >
          <ModalContent>
            <SearchSelection onChange={onFilter} value={filterTxt} />

            <ListSection>
              {projects
                .filter(
                  p =>
                    hasText(p.ProjectNameTH, filterTxt) ||
                    hasText(p.ProjectNameEN, filterTxt) ||
                    hasText(p.ProjectCode, filterTxt)
                )
                .map((item: any, index: number) => {
                  const iProject = selectProjets.findIndex(project => {
                    return project.ProjectCode === item.ProjectCode
                  })

                  const selected =
                    iProject > -1
                      ? selectProjets[iProject].Selected !== 'N'
                      : item.isSelected
                  return (
                    <div style={{ marginTop: 10, marginRight: 10 }} key={index}>
                      <RadioBox
                        label={`${item.ProjectCode} - ${item.ProjectNameTH}`}
                        text={item.detail}
                        isSelected={selected}
                        readOnly={item.isReadOnly}
                        value={item}
                        onClick={onChange}
                        spaceBtwText={16}
                        tag={{ value: !selected }}
                      />
                    </div>
                  )
                })}
            </ListSection>

            <ButtonSection>
              <Row>
                <Col span={7} offset={9}>
                  <OutlineButton
                    text="ยกเลิก"
                    disabled={false}
                    onClick={closeModal}
                  />
                </Col>
                <Col span={7} offset={1}>
                  <PrimaryButton
                    text="บันทึก"
                    disabled={false}
                    onClick={onOk}
                  />
                </Col>
              </Row>
            </ButtonSection>
          </ModalContent>
        </Modal>
      </ModalCheckList>
    )
  }
)

const Header = styled.span`
  font-size: 28px;
  color: #000000;
  font-family: 'DB Helvethaica X 75 Bd';
`

const ModalCheckList = styled.div`
  .add-checklist-modal {
    .ant-modal-header {
      border-bottom: none;
    }
  }
`

const ModalContent = styled.div`
  padding: 0 24px 31px 24px;
  .ant-input {
    font-size: 20px;
  }
`

const ButtonSection = styled.div`
  padding-top: 18px;
`

const ListSection = styled.div`
  margin-top: 16px;
  height: 290px;
  overflow-y: scroll;
  padding: 0 8px;
`

export default ProjectSelector
