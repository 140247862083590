import React, { useState, useEffect } from 'react'
import RootState from 'typings/RootState'
import { connect, useDispatch } from 'react-redux'
import {
  GetReportReq,
  GetCheckListReq,
  DefectItem,
} from 'features/report/constructRoad/types'

import {
  userEmailSelector,
  serviceTokenSelector,
} from 'features/sensitive/sensitive.selector'

import { getReport, getCheckList } from 'features/report/constructRoad/api'
import {
  resetDefect,
  updateDefect,
} from 'features/report/constructRoad/actions'
import {
  selectEvent,
  selectEventChecklist,
  selectReport,
} from 'features/report/constructRoad/selector'

import { selectEvent as selectUnitEvent } from 'features/masterData/selector'

import Activity from 'Components/QC/Activity'
import Breadcrumbs from 'Components/Pages/ActivityDetailPage/Breadcrumbs'
import { BreadcrumbLink } from 'Components/Shared/BreadCrumb/BreadCrumb'
import EventModal from 'Components/Shared/Modal/EventModal'

import ReportInfo from '../ReportInfo'
import DefectDetail from '../DefectDetail'
import DefectView from '../DefectView'

type Props = {
  title: string
  initPage?: string

  ProjectCode: string
  Unit: string
  QCTypeID: string
  RequestID: number
  CheckNumber: number

  onPage?: (name: string) => void
  readOnly?: boolean
  addMode?: boolean
  isShowEditImg?: boolean
  isShowCorrectImg?: boolean
  refresh?: number
  breadcrumbs?: BreadcrumbLink[]
} & ReduxProps

const ConstructRoadReport: React.ComponentType<Props> = props => {
  const [page, setPage] = useState('')
  const [enableModal, setEnableModal] = useState(false)
  const dispatch = useDispatch()

  const {
    Token,
    UserEmail,
    event,
    eventChecklist,
    title,
    initPage = '',

    ProjectCode,
    Unit,
    QCTypeID,
    RequestID,
    CheckNumber,

    onPage = (name: string) => {},
    isShowEditImg = false,
    isShowCorrectImg = false,
    readOnly = false,
    addMode = true,
    refresh = 0,
    report,
    breadcrumbs,
  } = props

  useEffect(() => {
    setPage(initPage)
    setEnableModal(false)
  }, [initPage])

  useEffect(() => {
    const req: GetCheckListReq = {
      ProjectCode,
      UserEmail,
      QCTypeID,
    }

    dispatch(getCheckList(Token, req))
  }, [ProjectCode, QCTypeID])

  useEffect(() => {
    getPlanReport()
  }, [ProjectCode, Unit, QCTypeID, CheckNumber, RequestID, refresh])

  const getPlanReport = () => {
    const req: GetReportReq = {
      ProjectCode,
      UserEmail,
      Unit,
      QCTypeID,
      RequestID,
      CheckNumber,
    }

    dispatch(getReport(Token, req))
  }

  const onAdd = () => {
    const name = 'add.defect'
    setPage(name)
    onPage(name)
    dispatch(resetDefect())
  }

  const onViewActivity = () => {
    const name = 'activity'
    setPage(name)
    onPage(name)
    dispatch(resetDefect())
  }

  const onEdit = (item: DefectItem) => {
    const name = addMode ? 'edit.defect' : 'view.defect'
    setPage(name)
    onPage(name)
    dispatch(updateDefect(item))
  }

  const onClose = () => {
    setPage('')
    onPage('')
  }

  const onCompeted = () => {
    setPage('')
    onPage('')
    getPlanReport()
  }

  switch (page) {
    case 'add.defect':
    case 'edit.defect':
      return (
        <DefectDetail
          ProjectCode={ProjectCode}
          RequestID={RequestID}
          onClose={onClose}
          addMode={page === 'add.defect'}
          onCompeted={onCompeted}
        />
      )
    case 'view.defect':
      return <DefectView readOnly={readOnly} />

    case 'activity':
      return (
        <Activity
          breadcrumbs={breadcrumbs}
          ProjectCode={report.ProjectCode}
          ActivityID={report.ActivityID}
        />
      )

    default:
      return (
        <>
          <EventModal
            event={[event, eventChecklist]}
            enable={enableModal}
            errorCalback={() => setEnableModal(false)}
          />
          <ReportInfo
            title={title}
            onAdd={addMode && readOnly === false ? onAdd : undefined}
            onEdit={onEdit}
            onViewActivity={onViewActivity}
            isShowEditImg={isShowEditImg}
            isShowCorrectImg={isShowCorrectImg}
          />
        </>
      )
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    Token: serviceTokenSelector(state),
    UserEmail: userEmailSelector(state),
    event: selectEvent(state),
    eventChecklist: selectEventChecklist(state),
    report: selectReport(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConstructRoadReport)
