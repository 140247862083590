import React, { useState, useEffect } from 'react'
import { Modal, Row, Col } from 'antd'
import styled from 'styled-components'
import icon from 'assets/img/ic-success.png'

interface Props {
  isShow: boolean
  time?: number
  text?: string
  callback?: () => void
}

const AlertSuccess = (props: Props) => {
  const [visible, setVisible] = useState(false)
  const { isShow = false, text, time = 3000, callback } = props

  useEffect(() => {
    if (isShow) {
      setVisible(true)
      setTimeout(onTimeout, time)
    }
  }, [isShow])

  const onTimeout = () => {
    setVisible(false)
    if (callback) {
      callback()
    }
  }

  return (
    <Modal
      wrapClassName="error-box"
      style={css.page}
      visible={visible && text !== undefined}
      closable={false}
      mask={false}
      footer={null}
    >
      <Body>
        <Row>
          <Col>
            <img src={icon} width="100px" />
          </Col>
          <Col>{text}</Col>
        </Row>
      </Body>
    </Modal>
  )
}

const Body = styled.div`
  font-size: 20px;
`
const css: any = {
  page: {
    marginTop: '14%',
    width: '20px',
    height: '20px',
    textAlign: 'center',
    fontSize: '20px',
    color: 'white',
  },
  btn: {
    width: '100%',
    borderRadius: '5px',
    backgroundColor: '#003168',
    color: 'white',
    marginTop: '32px',
    fontSize: '20px',
  },
}

export default AlertSuccess
