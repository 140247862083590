import React from 'react'
import { Row, Col, Progress } from 'antd'
import { getStatusText } from 'utils/statusLookup'
import moment from 'moment-timezone'
import { Link } from 'react-router-dom'
import { Task } from 'features/tasklane/tasklane.types'
import { DATE_FORMAT } from 'config'
import { translate } from 'i18n'
import TKeys from 'i18n/translationKey'
import { getProgressColor } from 'utils/color-map'
import { textToLowerCase } from 'utils/format'
import { getMSDelayedDate, getMSDelayedText } from 'utils/datetime'

interface TaskLaneProps {
  type: string
  taskLane: Task
  isFetching?: boolean
  onClick?: (code: string) => void
}

type Props = TaskLaneProps

const TaskLaneCard: React.FC<Props> = ({
  type,
  taskLane,
  isFetching,
  onClick,
}) => {
  if (!taskLane) {
    return null
  }

  const {
    ActivityID: id,
    TaskStatus: status,
    ActivityName: activityTitle,
    BaselineStartDate: startDate,
    BaselineFinishDate: finishDate,
    ActualFinishDate: actualFinishDate,
    WbsName,
  } = taskLane

  const isCompletedTask = type === 'complete'

  const today = moment().startOf('day')

  let baselineDate = ''

  if (startDate !== '') {
    baselineDate = startDate
  } else if (finishDate !== '') {
    baselineDate = finishDate
  } else {
    baselineDate = '0'
  }

  let useDate = type === 'todo' ? baselineDate : finishDate
  let delayed = moment(useDate, DATE_FORMAT).diff(today, 'days')

  if (isNaN(delayed)) {
    delayed = 0
  }

  if (isCompletedTask) {
    delayed = getMSDelayedDate(taskLane)
  }

  let content
  let lane
  let dayTxt = translate(TKeys.Common.day)
  if (delayed < 0) {
    let days = Math.abs(delayed)
    let text =
      type === 'todo'
        ? `${translate(TKeys.TaskLane.delayed_by_day)} ${days} ${dayTxt}`
        : `เสร็จช้ากว่ากำหนด ${days} ${dayTxt}`
    if (isCompletedTask) {
      text = getMSDelayedText(delayed, taskLane)
    }
    lane = <span className="lane-card-delayed" />
    content = (
      <div className="status status-danger">
        <img
          src="/img/ic-warning.svg"
          width="16"
          height="16"
          alt="warning icon"
        />
        <span className="countdown">{text}</span>
      </div>
    )
  } else {
    let text =
      type === 'todo'
        ? `${translate(TKeys.TaskLane.start_within_day)} ${delayed} ${dayTxt}`
        : `เหลืออีก ${delayed} ${dayTxt}`

    if (isCompletedTask) {
      text = getMSDelayedText(delayed, taskLane)
    }
    content = (
      <div className={'status status-default'}>
        <span className="countdown">{text}</span>
      </div>
    )
  }

  const taskConfig = getProgressColor(
    taskLane.TaskStatus,
    taskLane.Progress,
    taskLane.LastReqProgress,
    taskLane.LastCurProgress
  )

  const taskStatus = textToLowerCase(status)

  return (
    <Link to={`/activity-detail/${id}`}>
      <Row
        className={
          isFetching
            ? 'tasklane-body lane-card info-loading'
            : 'tasklane-body lane-card'
        }
        onClick={e => (onClick ? onClick(id) : '')}
      >
        {lane}
        <Row type="flex" justify="space-between">
          <Col span={12} className="task-progress-left">
            {content}
            <h1 className="activity-title">{activityTitle}</h1>
            <h2 className="wbs">{WbsName}</h2>
          </Col>
          <Col
            span={12}
            className={`task-progress task-progress-right task-${taskStatus}`}
          >
            <Progress
              type="circle"
              percent={taskConfig.progress}
              successPercent={taskConfig.success}
              width={70}
              strokeWidth={9}
              strokeColor={taskConfig.color}
              format={percent => `${taskLane.Progress}%`}
              className={taskConfig.color ? '' : taskConfig.className}
              strokeLinecap="round"
            />
            {taskStatus !== 'init' && (
              <div className={`task-progress-label`}>
                <span className={`color-${taskStatus}`}>
                  {getStatusText(taskStatus)}
                </span>
              </div>
            )}
          </Col>
        </Row>
        <Row type="flex" justify="space-between">
          <Col span={24} className="date">
            <span className="start-date">
              {translate(TKeys.TaskLane.plan_start_date)} {startDate}
            </span>
            <span className="end-date">
              {translate(TKeys.TaskLane.plan_end_date)} {finishDate}
            </span>
          </Col>
        </Row>
      </Row>
    </Link>
  )
}

export default TaskLaneCard
