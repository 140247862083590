import { getType } from 'typesafe-actions'
import { cloneDeep } from 'lodash'

import * as actions from './actions'
import {
  StoreState,
  ReportAction,
  Filter,
  DataDetail,
  DocumentDetail,
  FilterByQCType,
} from './types'
import produce from 'immer'
import parseErrorMessage from 'utils/parseErrorMessage'
import { Event } from '../../types/event'
import { sortingActivityList } from './function'
import { UnitStatus } from 'features/types/status'
import moment from 'moment'

const initList = {
  Total: 0,
  List: [],
}
const initFilter = {
  UnitList: [],
  QCTypeList: [],
  Date: {
    FinishDate: moment().startOf('day'),
    StartDate: moment()
      .endOf('day')
      .subtract(3, 'months'),
    Mode: '3month',
  },
  ProjectCode: [],
  ProjectTypeID: 0,
  CheckedByRole: '',
} as Filter

const initQCTypeFilter = {
  UnitList: [],
  QCTypeList: [],
  Date: {
    FinishDate: moment().startOf('day'),
    StartDate: moment()
      .endOf('day')
      .subtract(3, 'months'),
    Mode: '3month',
  },
  ProjectCode: [],
  ProjectTypeID: 0,
  Supplier: '',
} as FilterByQCType

const initEvent = {
  IsLoading: false,
  MessageSuccess: undefined,
  SubmitError: undefined,
  SubmitSuccess: false,
  SubmitType: undefined,
} as Event

const initDetailt = {
  BadImageList: [],
  GoodImageList: [],
  CheckByModule: '',
  CheckNumber: 0,
  CheckStatus: UnitStatus.NotPass,
  CheckedBy: '',
  CheckedDate: '',
  ControllerUser: '',
  SummaryDefectList: [],
  DefectList: [],
  InspectionScore: 0,
  InspectDate: '',
  MainConUser: '',
  ModelName: '',
  ProjectCode: '',
  ProjectNameEN: '',
  ProjectNameTH: '',
  QCTypeID: '',
  QCTypeName: '',
  QCTypeVersion: '',
  Unit: '',
  UserPosition: '',
  Zone: '',
  ProjectManager: '',
} as DataDetail

const initDocument = {
  CheckByModule: '',
  CheckNumber: 0,
  CheckStatus: '',
  CheckedBy: '',
  CheckedDate: '',
  ControllerUser: '',
  InspectionScore: 0,
  InspectDate: '',
  MainConUser: '',
  ModelName: '',
  ProjectCode: '',
  ProjectNameEN: '',
  ProjectNameTH: '',
  ProjectManager: '',
  QCTypeID: '',
  QCTypeName: '',
  QCTypeVersion: '',
  Unit: '',
  UserPosition: '',
  Zone: '',
  ActivityList: [],
  BasePoint: 0,
  DocumentScore: 0,
  DocumentStatus: 'NotPass',
} as DocumentDetail

const initialState: StoreState = {
  Event: cloneDeep(initEvent),
  QCTypeEvent: cloneDeep(initEvent),
  DataList: cloneDeep(initList),
  UnitByQCTypeList: cloneDeep(initList),
  Filter: cloneDeep(initFilter),
  QCTypeFilter: cloneDeep(initQCTypeFilter),
  ClearFilter: cloneDeep(initFilter),
  DataDetail: cloneDeep(initDetailt),
  DocumentDetail: cloneDeep(initDocument),
  sorting: {
    name: 'ProjectCode',
    type: 'asc',
  },
  sortingQCType: {
    name: 'QCTypeName',
    type: 'asc',
  },
  UnitCheckList: {
    QCTypeList: [],
  },
  UnitDataExport: [],
  UnitExportEvent: cloneDeep(initEvent),
  UnitByQCTypeDataExport: [],
  UnitByQCTypeExportEvent: cloneDeep(initEvent),
}

export default function(
  state: StoreState = initialState,
  action: ReportAction
): StoreState {
  return produce(state, draft => {
    switch (action.type) {
      case getType(actions.getReportListAction.request):
        draft.Event = cloneDeep(initEvent)
        draft.Event.IsLoading = true
        draft.DataList = cloneDeep(initList)
        break
      case getType(actions.getReportListAction.success):
        draft.Event.IsLoading = false
        const data = action.payload.ResponseData
        const dataSort = sortingActivityList(
          data.List,
          draft.sorting.type,
          draft.sorting.name
        )
        draft.DataList = {
          Total: data.Total,
          List: dataSort,
        }
        break

      case getType(actions.getUnitByQCTypeListAction.request):
        draft.QCTypeEvent = cloneDeep(initEvent)
        draft.QCTypeEvent.IsLoading = true
        draft.UnitByQCTypeList = cloneDeep(initList)
        break
      case getType(actions.getUnitByQCTypeListAction.success):
        draft.QCTypeEvent.IsLoading = false
        const dataQCType = action.payload.ResponseData
        const dataSortQCType = sortingActivityList(
          dataQCType.List,
          draft.sorting.type,
          draft.sorting.name
        )
        draft.UnitByQCTypeList = {
          Total: dataQCType.Total,
          List: dataSortQCType,
        }
        break

      case getType(actions.getUnitCheckListAction.request):
        draft.Event = cloneDeep(initEvent)
        draft.Event.IsLoading = true
        draft.UnitCheckList = {
          QCTypeList: [],
        }
        break
      case getType(actions.getUnitCheckListAction.success):
        draft.Event.IsLoading = false
        draft.UnitCheckList = action.payload.ResponseData
        break
      case getType(actions.getReportDetailAction.request):
        draft.Event = cloneDeep(initEvent)
        draft.Event.IsLoading = true
        draft.DataDetail = cloneDeep(initDetailt)
        break
      case getType(actions.getReportDetailAction.success):
        draft.Event.IsLoading = false
        draft.DataDetail = action.payload.ResponseData
        break

      case getType(actions.getDocumentDetailAction.request):
        draft.Event = cloneDeep(initEvent)
        draft.Event.IsLoading = true
        draft.DocumentDetail = cloneDeep(initDocument)
        break
      case getType(actions.getDocumentDetailAction.success):
        draft.Event.IsLoading = false
        draft.DocumentDetail = action.payload.ResponseData
        break

      case getType(actions.getReportListAction.failure):
      case getType(actions.getReportDetailAction.failure):
      case getType(actions.getDocumentDetailAction.failure):
      case getType(actions.getUnitCheckListAction.failure):
        draft.Event.IsLoading = false
        draft.Event.SubmitError = parseErrorMessage(action.payload)
        break

      case getType(actions.getUnitByQCTypeListAction.failure):
        draft.QCTypeEvent.IsLoading = false
        draft.QCTypeEvent.SubmitError = parseErrorMessage(action.payload)
        break

      case getType(actions.sortingTask):
        const sorting = state.sorting
        const params = action.payload
        if (sorting.name === params.name) {
          draft.sorting.type = sorting.type === 'asc' ? 'desc' : 'asc'
        } else {
          draft.sorting.name = params.name
          draft.sorting.type = 'asc'
        }
        if (params.sort != null) {
          draft.sorting.type = params.sort
        }

        const list = sortingActivityList(
          draft.DataList.List,
          draft.sorting.type,
          draft.sorting.name
        )
        draft.DataList.List = list
        break

      case getType(actions.sortingQcTypeTask):
        const sortingQC = state.sortingQCType
        const paramsQC = action.payload
        if (sortingQC.name === paramsQC.name) {
          draft.sortingQCType.type = sortingQC.type === 'asc' ? 'desc' : 'asc'
        } else {
          draft.sortingQCType.name = paramsQC.name
          draft.sortingQCType.type = 'asc'
        }
        if (paramsQC.sort != null) {
          draft.sortingQCType.type = paramsQC.sort
        }

        const listQC = sortingActivityList(
          draft.UnitByQCTypeList.List,
          draft.sortingQCType.type,
          draft.sortingQCType.name
        )
        draft.UnitByQCTypeList.List = listQC
        break

      case getType(actions.updateFilter):
        draft.Filter = { ...action.payload }
        break
      case getType(actions.updateQCTypeFilter):
        draft.QCTypeFilter = { ...action.payload }
        break
      case getType(actions.clearFilter):
        draft.Filter = cloneDeep({ ...initFilter })
        draft.QCTypeFilter = cloneDeep({ ...initQCTypeFilter })
        break

      case getType(actions.exportReportListAction.request):
        draft.UnitExportEvent = cloneDeep(initEvent)
        draft.UnitExportEvent.IsLoading = true
        draft.UnitDataExport = []
        break
      case getType(actions.exportReportListAction.success):
        draft.UnitExportEvent.IsLoading = false
        if (action.payload.ResponseData) {
          const dataSortQCType = sortingActivityList(
            action.payload.ResponseData.List,
            draft.sorting.type,
            draft.sorting.name
          )
          draft.UnitDataExport = dataSortQCType
        }
        break
      case getType(actions.exportReportListAction.failure):
        draft.UnitExportEvent.IsLoading = false
        draft.UnitExportEvent.SubmitError = parseErrorMessage(action.payload)
        break

      case getType(actions.exportReportQCTypeListAction.request):
        draft.UnitByQCTypeExportEvent = cloneDeep(initEvent)
        draft.UnitByQCTypeExportEvent.IsLoading = true
        draft.UnitByQCTypeDataExport = []
        break
      case getType(actions.exportReportQCTypeListAction.success):
        draft.UnitByQCTypeExportEvent.IsLoading = false
        if (action.payload.ResponseData) {
          const dataSortQCType = sortingActivityList(
            action.payload.ResponseData.List,
            draft.sortingQCType.type,
            draft.sortingQCType.name
          )
          draft.UnitByQCTypeDataExport = dataSortQCType
        }
        break
      case getType(actions.exportReportQCTypeListAction.failure):
        draft.UnitByQCTypeExportEvent.IsLoading = false
        draft.UnitByQCTypeExportEvent.SubmitError = parseErrorMessage(
          action.payload
        )
        break

      default:
        return state
    }
  })
}
