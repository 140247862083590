import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'antd'
import { PrimaryButton, SecondaryButton } from 'Components/Shared/Button'

type Props = {
  onSendRequestToQC: () => void
  onSendRequestForCM: () => void
  isLoading?: boolean
}

const QCUrgentFooter: React.FC<Props> = ({
  onSendRequestToQC,
  onSendRequestForCM,
  isLoading = false,
}) => {
  return (
    <FooterContent>
      <Row type="flex" justify="space-between" align="middle">
        <Col>
          <div className="btn-submit">
            <SecondaryButton
              text="ส่งคำขอแบ่งตรวจให้ QC"
              disabled={isLoading}
              onClick={onSendRequestToQC}
            />
          </div>
        </Col>
        <Col>
          <div className="btn-submit">
            <PrimaryButton
              text="ส่งคำขอแบ่งตรวจด้วยตัวเอง"
              disabled={isLoading}
              onClick={onSendRequestForCM}
            />
          </div>
        </Col>
      </Row>
    </FooterContent>
  )
}

const FooterContent = styled.div`
  max-width: 1110px;
  margin: 0 auto;
  color: #000;
  font-size: 20px;
  padding-right: 20px;

  .btn-submit {
    width: 200px;
    text-align: right;
  }
`

export default QCUrgentFooter
