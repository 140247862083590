import { getType } from 'typesafe-actions'
import * as actions from './actions'
import { StoreState, SeemoreAction, Activity } from './types'
import produce from 'immer'
import parseErrorMessage from 'utils/parseErrorMessage'
import { sortingActivityList } from './function'
import { Event } from 'features/types/event'
import { cloneDeep } from 'lodash'

const initEvent = {
  IsLoading: false,
  MessageSuccess: undefined,
  SubmitError: undefined,
  SubmitSuccess: false,
  SubmitType: undefined,
} as Event

const initialState: StoreState = {
  data: {
    Total: 0,
    List: [],
  } as Activity,
  event: cloneDeep(initEvent),
  sorting: {
    name: 'CheckNumber',
    type: 'desc',
  },
  progress: {
    total: 0,
    success: 0,
    show: false,
  },
}

export default function(
  state: StoreState = initialState,
  action: SeemoreAction
): StoreState {
  return produce(state, draft => {
    switch (action.type) {
      case getType(actions.getCheckingDataAction.request):
        draft.event.SubmitError = undefined
        draft.event.IsLoading = true
        draft.event.SubmitSuccess = false
        draft.data = {
          Total: state.data.Total,
          List: state.data.List,
        }
        break
      case getType(actions.getCheckingDataAction.success):
        const res = action.payload.ResponseData

        let resData = res.List
        const total = res.Total || 0
        draft.event.IsLoading = false
        const dataSort = sortingActivityList(
          resData,
          draft.sorting.type,
          draft.sorting.name
        )
        draft.data = {
          Total: total,
          List: dataSort,
        }
        break

      case getType(actions.getCheckingDataAction.failure):
        draft.event.IsLoading = false
        draft.event.SubmitError = parseErrorMessage(action.payload)
        break

      case getType(actions.sortingTask):
        const sorting = state.sorting
        const params = action.payload
        if (sorting.name === params.name) {
          draft.sorting.type = sorting.type === 'asc' ? 'desc' : 'asc'
        } else {
          draft.sorting.name = params.name
          draft.sorting.type = 'asc'
        }
        if (params.sort != null) {
          draft.sorting.type = params.sort
        }

        const list = sortingActivityList(
          draft.data.List,
          draft.sorting.type,
          draft.sorting.name
        )
        draft.data.List = list
        break
      case getType(actions.setProgress):
        console.log('action', action)
        draft.progress = { ...action.payload }
        break

      case getType(actions.setEvent):
        console.log('action', action)
        draft.event = { ...action.payload }
        break

      case getType(actions.sendReportAction.request):
        draft.event = cloneDeep(initEvent)
        draft.event.IsLoading = true
        break

      case getType(actions.sendReportAction.success):
        draft.event.IsLoading = false
        draft.event.SubmitError = undefined
        draft.event.SubmitSuccess = true
        draft.event.MessageSuccess = 'ทำรายการสำเร็จ'
        break

      case getType(actions.sendReportAction.failure):
        draft.event.IsLoading = false
        draft.event.SubmitError = parseErrorMessage(action.payload)
        break

      default:
        return state
    }
  })
}
