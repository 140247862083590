import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Layout } from 'antd'
import { UnitReportItem } from 'features/report/unit/types'
import ReportHeader from 'Components/Defect/ReportHeader'
import ReportItem from 'Components/Defect/ReportItem'
import { FileDescription } from 'features/maincon/history/types'
import { cloneDeep } from 'lodash'
import { TaskFooter } from 'Components/Shared'

const { Content } = Layout

interface Props {
  defect: UnitReportItem[]
  imgType: 'good' | 'bad'
  selected?: FileDescription[]
  onSave?: (selected: FileDescription[]) => void
  onCancel?: () => void
}

const ChooseImage: React.FC<Props> = props => {
  const {
    defect = [],
    imgType,
    selected = [],
    onSave = () => {},
    onCancel = () => {},
  } = props
  const [selectImg, setSelectImg] = useState(cloneDeep(selected) as any[])

  useEffect(() => {
    setSelectImg(cloneDeep(selected))
  }, [selected])

  const title = imgType === 'good' ? 'เลือกรูปดี' : 'เลือกรูปไม่ดี'

  const getFileId = (defect: UnitReportItem) => {
    if (!defect.ImageAfter && !defect.ImageBefore) return ''
    const fileID =
      defect.ImageAfter && defect.ImageAfter.FileID
        ? defect.ImageAfter.FileID
        : defect.ImageBefore.FileID

    return fileID
  }

  const getFileInfo = (defect: UnitReportItem) => {
    const fileInfo = defect.ImageAfter ? defect.ImageAfter : defect.ImageBefore
    fileInfo.PinID = defect.PinID
    return fileInfo
  }

  const isSelected = (FileID: number) => {
    const findData = selectImg.find(item => item.FileID === FileID)
    return findData ? true : false
  }

  const isSelectAll = () => {
    let selectedAll = true
    if (defect.length <= 0) {
      selectedAll = false
    }
    defect.forEach(item => {
      const fileID = getFileId(item)
      const selected = isSelected(fileID)
      if (!selected) {
        selectedAll = false
        return
      }
    })
    return selectedAll
  }

  const filterData = (fileID: number) => {
    return selectImg.filter(item => item.FileID !== fileID)
  }

  const onSelect = (item: UnitReportItem) => {
    const fileID = getFileId(item)
    let newData = selectImg
    const selected = isSelected(fileID)
    if (selected) {
      newData = filterData(fileID)
    } else {
      newData.push(getFileInfo(item))
    }
    setSelectImg([...newData])
  }

  const onSelectAll = () => {
    const selectedAll = isSelectAll()
    if (selectedAll) {
      setSelectImg([])
    } else {
      let newData = [] as any
      defect.forEach(item => {
        newData.push(getFileInfo(item))
      })
      setSelectImg([...newData])
    }
  }

  const content =
    defect &&
    defect.map((item, index) => {
      if (item.ImageAfter || item.ImageBefore) {
        const fileID = getFileId(item)
        return (
          <ReportItem
            item={item}
            key={index}
            onClick={() => {}}
            showRadio={true}
            showThumbnail={true}
            isSelected={isSelected(fileID)}
            onSelect={() => onSelect(item)}
          />
        )
      }
    })

  const save = () => {
    onSave(selectImg)
  }

  return (
    <>
      <Content className="mid-content">
        <div className="main-content activity-detail">
          <Header>
            <Title>{title}</Title>
          </Header>
          <TableArea>
            <ReportHeader
              onSort={() => {}}
              sorting={{}}
              showRadio={true}
              isSelectAll={isSelectAll()}
              onSelectAll={onSelectAll}
              showThumbnail={true}
            />
            {content}
          </TableArea>
        </div>
      </Content>
      <TaskFooter total={selectImg.length} onSave={save} onCancel={onCancel} />
    </>
  )
}

const Header = styled.div`
  margin-top: 32px;
  margin-bottom: 24px;
`

const Title = styled.span`
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 28px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #000;
`

const TableArea = styled.div`
  margin-top: 20px;
`

export default ChooseImage
