import { createAsyncAction, createStandardAction } from 'typesafe-actions'
import { FetchWbsListRequest, Wbs } from './wbs.types'

export const fetchWbsListAsyncAction = createAsyncAction(
  'wbs/FETCH_WBS_LIST_REQUEST',
  'wbs/FETCH_WBS_LIST_SUCCESS',
  'wbs/FETCH_WBS_LIST_FAILURE'
)<FetchWbsListRequest, ApiResponse<Wbs[]>, any>()

export const updateWbsPathMap = createStandardAction('wbs/UPDATE_WBS_PATH_MAP')<
  Map<number, string>
>()

export const updateWbsMapParent = createStandardAction(
  'wbs/UPDATE_WBS_MAP_PARENT'
)<any>()

export const getWbsList = createStandardAction('wbs/GET_WBS_LIST')<any>()

export const setWbsProjectCode = createStandardAction('wbs/SET_PROJECT_CODE')<
  string
>()

export const updateWbsEvent = createStandardAction('wbs/UPDATE_WBS_EVENT')<
  boolean
>()
