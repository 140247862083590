import { FloorPlanState, SelectedFloorPlan } from './types'
import produce from 'immer'
import parseErrorMessage from 'utils/parseErrorMessage'
import { getType, ActionType } from 'typesafe-actions'
import * as actions from './actions'
import { cloneDeep } from 'lodash'

const initialFloorPlanSelected: SelectedFloorPlan = {
  FloorPlanID: 0,
  FloorPlanName: '',
  ImageFloorPlan: undefined,
  Status: 'Ready',
  FloorList: [],
}

const initialState: FloorPlanState = {
  FloorPlanList: {
    Total: 0,
    List: [],
  },
  FloorPlanMappingList: [],
  FloorPlanSelected: undefined,
  Event: {
    IsLoading: false,
    SubmitError: '',
    SubmitSuccess: false,
    SubmitType: '',
    MessageSuccess: '',
  },
}

export type FloorPlanAction = ActionType<typeof actions>

export default function (
  state: FloorPlanState = initialState,
  action: FloorPlanAction
): FloorPlanState {
  return produce(state, draft => {
    switch (action.type) {
      case getType(actions.reset):
        draft.FloorPlanSelected = cloneDeep(initialFloorPlanSelected)
        break

      case getType(actions.getFloorPlanListReq.request):
        draft.Event.IsLoading = true
        draft.Event.SubmitError = ''
        draft.FloorPlanSelected = undefined
        draft.FloorPlanList = {
          Total: 0,
          List: [],
        }
        break
      case getType(actions.getFloorPlanListReq.failure):
        draft.Event.IsLoading = false
        draft.Event.SubmitError = parseErrorMessage(action.payload)
        break
      case getType(actions.getFloorPlanListReq.success):
        const ResponseData = action.payload.ResponseData || {}
        draft.FloorPlanList = {
          Total: ResponseData.Total || 0,
          List: ResponseData.List || [],
        }
        draft.Event.IsLoading = false
        break

      case getType(actions.setSelectedItem):
        const item = action.payload
        if (item) {
          draft.FloorPlanSelected = {
            FloorPlanID: item.FloorPlanID,
            FloorPlanName: item.FloorPlanName,
            ImageFloorPlan: item.ImageFloorPlan,
            Status: item.Status,
            FloorList: [],
          }
        } else {
          draft.FloorPlanSelected = undefined
        }
        break
      case getType(actions.updateData):
        draft.FloorPlanSelected = action.payload
        break

      case getType(actions.getItemForFloorPlanReq.request):
        draft.Event.IsLoading = true
        draft.Event.SubmitError = ''
        draft.FloorPlanMappingList = []
        break
      case getType(actions.getItemForFloorPlanReq.failure):
        draft.Event.IsLoading = false
        draft.Event.SubmitError = parseErrorMessage(action.payload)
        break
      case getType(actions.getItemForFloorPlanReq.success):
        draft.FloorPlanMappingList = action.payload.ResponseData.List
        draft.Event.IsLoading = false
        break

      case getType(actions.updateReq.request):
      case getType(actions.deleteReq.request):
        draft.Event.IsLoading = true
        draft.Event.SubmitError = ''
        break
      case getType(actions.updateReq.failure):
      case getType(actions.deleteReq.failure):
        draft.Event.IsLoading = false
        draft.Event.SubmitError = parseErrorMessage(action.payload)
        break
      case getType(actions.updateReq.success):
      case getType(actions.deleteReq.success):
        draft.Event.IsLoading = false
        break
      default:
        return state
    }
  })
}
