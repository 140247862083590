import RootState from '../../../typings/RootState'
import { CurrentActivity, MainconTask, Event } from './types'
import { MainconCheckList } from 'features/types/checklist'

export const selectCurrentActivity = (state: RootState): CurrentActivity =>
  state.mainconActivity.CurrentActivity
export const selectOriginalActivity = (state: RootState): MainconTask =>
  state.mainconActivity.OriginalActivity
export const selectCheckList = (state: RootState): MainconCheckList =>
  state.mainconActivity.CheckList
export const selectUploadList = (state: RootState): any[] =>
  state.mainconActivity.UploadList
export const selectEvent = (state: RootState): Event =>
  state.mainconActivity.Event
