import React, { useState, useRef, useMemo, useEffect } from 'react'
import styled from 'styled-components'
import { Row, Col, Button } from 'antd'
import ContentBox from 'Components/Shared/Card/ContentBox'

import {
  DetailType,
  CriteriaType,
  CriteriaTypeWithoutScore,
} from 'features/checklist/unit/types'
import CriteriaModal, { CriteriaHandles } from '../Setting/Modal/CriteriaModal'
import { RadioBox } from 'Components/Shared/Form'

type Props = {
  item?: DetailType
  criteriaList: CriteriaTypeWithoutScore[]
  onSave: () => void
  updateCheckList: () => void
  disabled: boolean
}

type PropsItem = {
  title: string
  isCheck: boolean
  onChange: (value: boolean) => void
  disabled: boolean
}

const ConfigItemComponent = ({
  title,
  isCheck,
  onChange,
  disabled,
}: PropsItem) => {
  return (
    <ConfigItem>
      <Row>
        <Col span={14}> {title} </Col>
        <Col span={5}>
          <RadioBox
            label={'ไม่'}
            value={!isCheck}
            isCheck={!isCheck}
            onClick={onChange}
            readOnly={disabled}
          />
        </Col>

        <Col span={5}>
          <RadioBox
            label={'ใช่'}
            value={!isCheck}
            isCheck={isCheck}
            onClick={onChange}
            readOnly={disabled}
          />
        </Col>
      </Row>
    </ConfigItem>
  )
}

function combineCriteriaList(
  criteriaList: CriteriaTypeWithoutScore[],
  detail?: DetailType
): CriteriaType[] {
  const myList = detail ? detail.CriteriaList : []

  for (let i = 0; i < criteriaList.length; i++) {
    let found = false
    for (let j = 0; j < myList.length; j++) {
      if (criteriaList[i].CriteriaName === myList[j].CriteriaName) {
        found = true
        break
      }
    }
    if (found === false) {
      myList.push({
        CriteriaID: 0,
        CriteriaName: criteriaList[i].CriteriaName,
      })
    }
  }

  return myList
}

const DetailConfigBox: React.ComponentType<Props> = ({
  item,
  criteriaList,
  onSave,
  updateCheckList,
  disabled,
}) => {
  const modalRef = useRef<CriteriaHandles>(null)
  const [isDefect, setisDefect] = useState<boolean>(false)
  const [isSupplier, setisSupplier] = useState<boolean>(false)
  let currentCriteria: JSX.Element[] = []

  useEffect(() => {
    if (item) {
      setisDefect(item.IsRequired)
      setisSupplier(item.IsSupplier)
    }
  }, [item])

  const updateDefect = (value: boolean) => {
    setisDefect(value)
    if (item) {
      item.IsRequired = value
    }
  }

  const updateSupplier = (value: boolean) => {
    setisSupplier(value)
    if (item) {
      item.IsSupplier = value
    }
  }

  const showModal = (criteria: CriteriaType) => {
    if (!disabled && modalRef.current) {
      modalRef.current.showModal(criteria)
    }
  }

  const updateCriteria = (_: string, ct?: CriteriaType) => {
    if (item && ct && ct.Score != null && !isNaN(ct.Score)) {
      const found = item.CriteriaList.find(
        each => each.CriteriaName === ct.CriteriaName
      )
      if (found) {
        found.Score = ct.Score
      } else {
        item.CriteriaList.push({
          CriteriaID: 0,
          CriteriaName: ct.CriteriaName,
          Score: ct.Score,
        })
      }
      updateCheckList()
    }
  }

  const finalList = useMemo(() => combineCriteriaList(criteriaList, item), [
    criteriaList,
    item,
  ])
  currentCriteria = finalList.map((it: CriteriaType, index) => (
    <Col span={12} key={index} onClick={() => showModal(it)}>
      <IntBlock>
        <label>{it.CriteriaName}</label>
        <span>{it.Score != null ? it.Score : '-'}</span>
      </IntBlock>
    </Col>
  ))

  return (
    <>
      <ContentBox
        title="ตั้งค่ารายละเอียด"
        isEmpty={item == null}
        hasSearch={false}
      >
        <ListSection>
          <Row type="flex" align="middle">
            <Col span={12} style={{ paddingRight: '24px' }}>
              <ConfigItemComponent
                title="เป็น Defect พิเศษหรือไม่"
                isCheck={isDefect}
                onChange={updateDefect}
                disabled={disabled}
              />
              <ConfigItemComponent
                title="เป็น Supplier หรือไม่"
                isCheck={isSupplier}
                onChange={updateSupplier}
                disabled={disabled}
              />
            </Col>
            <Col span={12}>
              {!disabled && (
                <ButtonSection>
                  <Button onClick={onSave}>บันทึก</Button>
                </ButtonSection>
              )}
            </Col>
          </Row>
          <SubTitle>Criteria</SubTitle>
          <Row>{currentCriteria}</Row>
        </ListSection>
      </ContentBox>
      <CriteriaModal ref={modalRef} onOk={updateCriteria} />
    </>
  )
}

const ListSection = styled.div`
  margin-top: 16px;
  height: 256px;
  overflow: auto;
  padding: 0 24px;
`

const IntBlock = styled.div`
  padding: 0px 16px;
  font-size: 20px;
  color: black;
  border: solid 1px #e4e7eb;
  height: 48px;
  border-radius: 5px;
  font-family: 'DB Helvethaica X 55 Regular';
  margin: 4px 18px 4px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  span {
    color: #8b99a7;
  }
`

const SubTitle = styled.div`
  font-size: 20px;
  font-family: 'DB Helvethaica X 75 Bd';
`

const ButtonSection = styled.div`
  button {
    width: 116px;
    height: 50px;
    font-family: 'DB Helvethaica X 75 Bd';
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #003168;
    border: 1.5px solid #003168;
    border-radius: 5px;
    float: right;
  }
`

const ConfigItem = styled.div`
  font-family: 'DB Helvethaica X 55 Regular';
  font-size: 20px;
  padding-bottom: 16px;
`

export default DetailConfigBox
