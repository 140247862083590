import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'antd'

interface Props {
  hintList: string[]
}

const HintList: React.FC<Props> = props => {
  const { hintList } = props
  return (
    <RemarkWord>
      <Row type="flex" justify="start">
        {hintList.map((item, index) => {
          return (
            <Col offset={index > 0 ? 1 : 0}>
              <Dot /> {item}
            </Col>
          )
        })}
      </Row>
    </RemarkWord>
  )
}

const RemarkWord = styled.div`
  font-family: 'DB Helvethaica X 55 Regular';
  font-size: 18px;
`

const Dot = styled.span`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #003168;
  display: inline-block;
  margin: 0 5px 1px 0;
`

export default HintList
