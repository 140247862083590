import React from 'react'
import styled from 'styled-components'
import { getDefectColor } from 'utils/color-map'
type Props = {
  pinStatus: any
  prefix?: string
}

const DefectStatus: React.FunctionComponent<Props> = props => {
  const { pinStatus, prefix } = props

  const defect = getDefectColor(pinStatus)

  return (
    <Status style={{ backgroundColor: defect.color }}>{defect.text}</Status>
  )
}

const Status = styled.span`
  border-radius: 10px;
  padding: 2px 10px;
  font-size: 16px;
  color: white;
`

export default DefectStatus
