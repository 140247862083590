import { createStandardAction, createAsyncAction } from 'typesafe-actions'
import { PostResponse } from '../../types/common'
import { History } from 'history'

import {
  FetchCheckListReq,
  FetchCheckListByIdReq,
  CreateCheckListReq,
  UpdateCheckListReq,
  FetchActivityByCheckListReq,
  UpdateCheckListActivityReq,
  GetCheckListApi,
  GetCheckListByIdApi,
  GetActivityByCheckListApi,
  CheckListDetail,
  UpdateActivityItem,
  CreateCheckListApi,
  ImportCheckListReq,
  GetTemplateCodeListReq,
  GetTemplateCodeListApi,
} from './types'

export const reset = createStandardAction('checklist/master/RESET_ACTION')<
  void
>()

export const updateCheckListDetail = createStandardAction(
  'checklist/master/UPDATE_CHECK_LIST'
)<CheckListDetail>()

export const updateActivityCheckList = createStandardAction(
  'checklist/master/UPDATE_ACTIVITY_CHECK_LIST'
)<UpdateActivityItem[]>()

export const resetUpdateActivityCheckList = createStandardAction(
  'checklist/master/RESET_ACTIVITY_CHECK_LIST'
)<void>()

export const getCheckListReq = createAsyncAction(
  'checklist/master/GET_CHECK_LIST_REQ',
  'checklist/master/GET_CHECK_LIST_RESP',
  'checklist/master/GET_CHECK_LIST_FAIL'
)<FetchCheckListReq, GetCheckListApi, any>()

export const getCheckListByIdReq = createAsyncAction(
  'checklist/master/GET_CHECK_LIST_ID_REQ',
  'checklist/master/GET_CHECK_LIST_ID_RESP',
  'checklist/master/GET_CHECK_LIST_ID_FAIL'
)<FetchCheckListByIdReq, GetCheckListByIdApi, any>()

export const getActivityByCheckListReq = createAsyncAction(
  'checklist/master/GET_ACTIVITY_REQ',
  'checklist/master/GET_ACTIVITY_RESP',
  'checklist/master/GET_ACTIVITY_FAIL'
)<FetchActivityByCheckListReq, GetActivityByCheckListApi, any>()

export const updateActivityByCheckListReq = createAsyncAction(
  'checklist/master/UPDATE_ACTIVITY_REQ',
  'checklist/master/UPDATE_ACTIVITY_RESP',
  'checklist/master/UPDATE_ACTIVITY_FAIL'
)<UpdateCheckListActivityReq, PostResponse, any>()

export const createCheckListReq = createAsyncAction(
  'checklist/master/CREATE_CHECK_LIST_REQ',
  'checklist/master/CREATE_CHECK_LIST_RESP',
  'checklist/master/CREATE_CHECK_LIST_FAIL'
)<CreateCheckListReq, CreateCheckListApi, any>()

export const updateCheckListReq = createAsyncAction(
  'checklist/master/UPDATE_CHECK_LIST_REQ',
  'checklist/master/UPDATE_CHECK_LIST_RESP',
  'checklist/master/UPDATE_CHECK_LIST_FAIL'
)<UpdateCheckListReq, PostResponse, any>()

export const deleteCheckListByIdReq = createAsyncAction(
  'checklist/master/DELETE_CHECK_LIST_REQ',
  'checklist/master/DELETE_CHECK_LIST_RESP',
  'checklist/master/DELETE_CHECK_LIST_FAIL'
)<
  { CheckListID: string; getReq?: FetchCheckListReq; history: any },
  PostResponse,
  Error
>()

export const submitCheckListReq = createStandardAction(
  'checklist/master/SUBMIT_CHECKLIST_REQUEST'
)<any>()

export const importCheckListReq = createAsyncAction(
  'checklist/master/IMPORT_CHECK_LIST_REQ',
  'checklist/master/IMPORT_CHECK_LIST_RESP',
  'checklist/master/IMPORT_CHECK_LIST_FAIL'
)<ImportCheckListReq, CreateCheckListApi, any>()

export const updateImport = createStandardAction(
  'checklist/master/IMPORT_CHECKLIST_DATA'
)<any>()

export const getTemplateCodeListReq = createAsyncAction(
  'checklist/master/GET_TEMPLATECODE_LIST_REQ',
  'checklist/master/GET_TEMPLATECODE_RESP',
  'checklist/master/GET_TEMPLATECODE_FAIL'
)<GetTemplateCodeListReq, GetTemplateCodeListApi, any>()
