import React, { useState, forwardRef, useImperativeHandle } from 'react'
import { Modal, Row, Col } from 'antd'
import CloseIcon from '../Icon/CloseIcon'
import styled from 'styled-components'
import { RadioBox } from '../Form'
import PrimaryButton from '../Button/PrimaryButton'
import OutlineButton from '../Button/OutlineButton'

export interface DeleteWithCheckHandles {
  showModal: (obj: any, m: string[], mt: string, c: boolean[]) => void
}

interface Props {
  onOk: (obj: any) => void
}

const DeleteWithCheckModal = forwardRef<DeleteWithCheckHandles, Props>(
  ({ onOk: onOkProps }, ref) => {
    const [visible, setvisible] = useState(false)
    const [obj, setObj] = useState(null)
    const [menu, setMenu] = useState<string[]>([])
    const [menuTitle, setMenuTitle] = useState<string>('')
    const [checks, setChecks] = useState<boolean[]>([])
    const [allChecks, setAllChecks] = useState<boolean>(false)

    const closeModal = () => {
      setvisible(false)
      setChecks([])
      setAllChecks(false)
    }

    useImperativeHandle(ref, () => ({
      showModal: (o: any, m: string[], mt: string, c: boolean[]) => {
        setObj(o)
        setMenu(m)
        setChecks(c)
        setMenuTitle(mt)
        setvisible(true)
        if (m.length === 0) {
          setAllChecks(true)
        }
      },
    }))

    const onOk = () => {
      if (obj) {
        onOkProps(obj)
      }
      closeModal()
    }

    const buttonAction = (
      <Row>
        <Col span={6} offset={6}>
          <OutlineButton text="ยกเลิก" disabled={false} onClick={closeModal} />
        </Col>
        <Col span={11} offset={1}>
          <PrimaryButton
            text="ใช่ ฉันยืนยันลบรายการนี้"
            disabled={!allChecks}
            onClick={onOk}
            btnType="Reject"
          />
        </Col>
      </Row>
    )

    const onSetChecks = (val: boolean, index: number) => {
      checks[index] = val
      setChecks([...checks])
      const isAll = checks.reduce((acc, item) => {
        return acc && item
      }, true)
      setAllChecks(isAll)
    }

    return (
      <ModalCheckList>
        <Modal
          centered
          visible={visible}
          width="528px"
          footer={null}
          closeIcon={<CloseIcon color="#8b99a7" size={16} />}
          title={<Header>ลบรายการ</Header>}
          className="add-checklist-modal"
          onCancel={closeModal}
        >
          <ModalContent>
            <span>คุณยืนยันต้องการลบ</span>&nbsp;&nbsp;
            <span className="text-warning">{menuTitle}</span>
            <CheckListWarning>
              {menu.map((str, index) => {
                return (
                  <RadioBox
                    key={index}
                    value={!checks[index]}
                    label={str}
                    isCheck={checks[index]}
                    onClick={(val) => onSetChecks(val, index)}
                  />
                )
              })}
            </CheckListWarning>
            <ButtonSection>{buttonAction}</ButtonSection>
          </ModalContent>
        </Modal>
      </ModalCheckList>
    )
  }
)

const Header = styled.span`
  font-size: 28px;
  color: #000000;
  font-family: 'DB Helvethaica X 75 Bd';
`

const ModalCheckList = styled.div`
  .add-checklist-modal {
    .ant-modal-header {
      border-bottom: none;
    }
  }
`

const ModalContent = styled.div`
    padding : 0 24px 31px 24px;
    font-family: 'DB Helvethaica X 55 Regular';
    font-size: 28px;
    font-size: 20px;
    .text-warning{
      font-family: 'DB Helvethaica X 75 Bd';
      color : #d0494c;
    }
  }
`

const CheckListWarning = styled.div`
  padding-top: 18px;
`

const ButtonSection = styled.div`
  padding-top: 30px;
`

export default DeleteWithCheckModal
