import React from 'react'
import { Col } from 'antd'
import styled from 'styled-components'
import { TableRow } from 'Components/Shared/Table'
import { DataItem } from 'features/adminReport/performance/types'

type DefaultProps = {
  item: DataItem
}

type Props = DefaultProps

const RowItem: React.FC<Props> = ({ item }) => {
  const renderData = (val: any, span: number = 2) => {
    return <Col span={span}>{val}</Col>
  }

  return (
    <RowModel>
      <TableRow
        minHeight={60}
        spaceBetweenRow={8}
        paddingLeft={24}
        cursor="default"
      >
        {renderData(<Bold>{item.UserRoleName}</Bold>, 3)}
        {renderData(<TxtReg>{item.UserName}</TxtReg>, 3)}
        {renderData(<TxtReg>{item.NumOfDate}</TxtReg>)}
        {renderData(<TxtReg>{item.NumOfUnit}</TxtReg>)}
        {renderData(<TxtReg>{item.NumOfZone}</TxtReg>)}
        {renderData(<TxtReg>{item.NumOfCommissioning}</TxtReg>)}
        {renderData(<TxtReg>{item.NumOfSafety}</TxtReg>)}
        {renderData(<TxtReg>{item.NumOfConstructPlan}</TxtReg>)}
        {renderData(<TxtReg>{item.NumOfAuditPlan}</TxtReg>)}
        {renderData(<TxtReg>{item.NumOfConstructRoad}</TxtReg>)}
        {renderData(<TxtReg>{item.NumOfAuditRoad}</TxtReg>)}
      </TableRow>
    </RowModel>
  )
}

const RowModel = styled.div`
  cursor: pointer;
`

const Bold: any = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  color: #000;
  font-size: 20px;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  padding-right: 5px;
`

const TxtReg = styled(Bold)`
  font-family: 'DB Helvethaica X 55 Regular';
`

export default RowItem
