import { createStandardAction, createAsyncAction } from 'typesafe-actions'
import {
  GetProjectsResp,
  GetProjectsReq,
  PostApiResponse,
  CreatePlanReq,
} from './types'

export const GetConstructPlanProjectAction = createAsyncAction(
  'qc/sitevisit/construc/FETCH_PROJECTS_REQUEST',
  'qc/sitevisit/construc/FETCH_PROJECTS_SUCCESS',
  'qc/sitevisit/construc/FETCH_PROJECTS_FAILURE'
)<GetProjectsReq, GetProjectsResp, any>()

export const CreateConstructPlanAction = createAsyncAction(
  'qc/sitevisit/construc/CREATE_CONTRUCT_REQUEST',
  'qc/sitevisit/construc/CREATE_CONTRUCT_SUCCESS',
  'qc/sitevisit/construc/CREATE_CONTRUCT_FAILURE'
)<CreatePlanReq, PostApiResponse, any>()

export const GetAuditPlanProjectAction = createAsyncAction(
  'qc/sitevisit/construc/FETCH_PROJECTS_REQUEST',
  'qc/sitevisit/construc/FETCH_PROJECTS_SUCCESS',
  'qc/sitevisit/construc/FETCH_PROJECTS_FAILURE'
)<GetProjectsReq, GetProjectsResp, any>()

export const CreateAuditPlanAction = createAsyncAction(
  'qc/sitevisit/audit/CREATE_CONTRUCT_REQUEST',
  'qc/sitevisit/audit/CREATE_CONTRUCT_SUCCESS',
  'qc/sitevisit/audit/CREATE_CONTRUCT_FAILURE'
)<CreatePlanReq, PostApiResponse, any>()

export const sortingTask = createStandardAction('cmpj/seemoreqc/SORTING')<{
  name: string
  sort?: string
}>()
