import React, { useState, useImperativeHandle, forwardRef } from 'react'
import { Modal, Row, Col } from 'antd'
import styled from 'styled-components'

import { CloseIcon } from 'Components/Shared/Icon'
import { PrimaryButton, OutlineButton } from 'Components/Shared/Button'

import InputText from 'Components/Shared/Input/InputText'
import { LocationType } from 'features/checklist/unit/types'

export interface JobHandles {
  showModal: (obj: any) => void
}

interface Props {
  addLocation: (item: LocationType) => void
  updateLocation: () => void
}

const JobModalLocation = forwardRef<JobHandles, Props>(
  ({ addLocation: addLocationProp, updateLocation }, ref) => {
    const [visible, setvisible] = useState(false)
    const [openWithFocus, setOpenWithFocus] = useState(false)
    const [name, setname] = useState('')
    const [item, setItem] = useState<LocationType | undefined>()

    const clearClose = () => {
      setvisible(false)
      setname('')
      setItem(undefined)
      setOpenWithFocus(false)
    }

    const closeModal = () => {
      setvisible(false)
    }

    const addLocation = () => {
      if (name) {
        if (item) {
          item.LocationName = name
          updateLocation()
        } else {
          addLocationProp({
            LocationName: name,
            //TODO: should have no score to Input, waiting for remove
            Score: 1,
          })
        }

        clearClose()
      }
    }

    useImperativeHandle(ref, () => ({
      showModal: (item?: LocationType) => {
        setvisible(true)
        if (item) {
          setname(item.LocationName)
          setItem(item)
        }
        setOpenWithFocus(true)
      },
    }))

    const title = item ? 'แก้ไข' : 'เพิ่ม'

    return (
      <ModalCheckList>
        <Modal
          centered
          visible={visible}
          width="528px"
          footer={null}
          closeIcon={<CloseIcon color="#8b99a7" size={16} />}
          title={<Header>{`${title}รายการ`}</Header>}
          className="add-checklist-modal"
          onCancel={closeModal}
        >
          <ModalContent>
            <SelectType>
              <Title>{`${title} location`}</Title>
            </SelectType>

            <InputText
              prefix=""
              placeholder="กรอก location"
              onChange={setname}
              value={name}
              openWithFocus={openWithFocus}
            />
            <ButtonSection>
              <Row>
                <Col span={7} offset={9}>
                  <OutlineButton
                    text="ยกเลิก"
                    disabled={false}
                    onClick={closeModal}
                  />
                </Col>
                <Col span={7} offset={1}>
                  <PrimaryButton
                    text="บันทึก"
                    disabled={false}
                    onClick={addLocation}
                  />
                </Col>
              </Row>
            </ButtonSection>
          </ModalContent>
        </Modal>
      </ModalCheckList>
    )
  }
)

const Header = styled.span`
  font-size: 28px;
  color: #000000;
  font-family: 'DB Helvethaica X 75 Bd';
`
const Title = styled.span`
  font-size: 20px;
  color: #000000;
  font-family: 'DB Helvethaica X 75 Bd';
`

const ModalCheckList = styled.div`
  .add-checklist-modal {
    .ant-modal-header {
      border-bottom: none;
    }
  }
`

const ModalContent = styled.div`
  padding: 0 24px 31px 24px;
  .ant-input {
    font-size: 20px;
  }
`

const SelectType = styled.div`
  padding-top: 17px;
`

const ButtonSection = styled.div`
  padding-top: 18px;
`

export default JobModalLocation
