import { getType } from 'typesafe-actions'
import { cloneDeep } from 'lodash'

import * as actions from './actions'
import { StoreState, ReportAction, UnitReport } from './types'
import produce from 'immer'
import parseErrorMessage from 'utils/parseErrorMessage'
import { sortingByNumber } from 'utils/sorting'
import { Event } from '../../types/event'

const reportQC = {
  State: 'CM-QC',
  Status: 'INIT',
  TotalScore: 0,
  CheckListStatus: '',
  TotalRows: 0,
  QCTypeID: '',
  QCTypeName: '',
  QCTypeVersion: '',
  CheckNumber: 0,
  ModelName: '',
  Unit: '',
  Zone: '',
  AppointmentDate: '',
  CheckedBy: '',
  FixFromQC: [],
  FunctionStatus: 'N',
  CheckStatus: 'NotPass',
  InspectDate: '',
  CheckedDate: '',
  UnitDisplay: '',
} as UnitReport

const checklistQC = {
  CheckListID: 0,
  CheckListName: '',
  FloorList: [],
  ProjectTypeID: '',
  ProjectTypeName: '',
  ScoreRange: {
    PassMinPoint: 0,
    PassMaxPoint: 0,
    ToFixMinPoint: 0,
    ToFixMaxPoint: 0,
    NoPassMinPoint: 0,
    NoPassMaxPoint: 0,
  },
  QCTypeID: '',
  QCTypeName: '',
  FunctionCheckListStatus: '',
  FunctionCheckListID: '',
  JobTypeList: [],
}

const unitDefect = {
  ProjectCode: '',
  UserEmail: '',
  PinID: 0,
  PinNumber: 0,
  Unit: '',
  CheckListID: 0,
  RequestID: 0,
  QCTypeID: '',
  CheckNumber: 0,
  JobTypeID: 0,
  SubJobTypeID: 0,
  DetailID: 0,
  LocationID: 0,
  PinStatus: 'ToBeFix',
  Floor: 0,
  Remark: '',
  Score: 0,
  XAxis: '',
  YAxis: '',
  ImageBefore: undefined,
  ImageAfter: undefined,
  Module: '',
}

const initEvent = {
  IsLoading: false,
  MessageSuccess: undefined,
  SubmitError: undefined,
  SubmitSuccess: false,
  SubmitType: undefined,
} as Event

const initialState: StoreState = {
  report: cloneDeep(reportQC),
  event: cloneDeep(initEvent),
  eventChecklist: cloneDeep(initEvent),

  checkListQC: cloneDeep(checklistQC),
  unitDefect: cloneDeep(unitDefect),
}

export default function(
  state: StoreState = initialState,
  action: ReportAction
): StoreState {
  return produce(state, draft => {
    switch (action.type) {
      case getType(actions.getUnitReportAction.request):
        draft.event = cloneDeep(initEvent)
        draft.event.IsLoading = true
        draft.report = cloneDeep(reportQC)

        break
      case getType(actions.getUnitReportAction.success):
        draft.event.IsLoading = false
        const response = action.payload.ResponseData
        response.FixFromQC = sortingByNumber(
          response.FixFromQC,
          'asc',
          'PinNumber'
        )
        draft.report = response
        break
      case getType(actions.getUnitReportAction.failure):
        draft.event.IsLoading = false
        draft.event.SubmitError = parseErrorMessage(action.payload)
        break

      case getType(actions.resetUnitDefect):
        draft.unitDefect = cloneDeep(unitDefect)
        break
      case getType(actions.updateUnitDefect):
        draft.unitDefect = action.payload
        break

      case getType(actions.getUnitCheckListForQCReq.request):
        draft.eventChecklist = cloneDeep(initEvent)
        draft.eventChecklist.IsLoading = true
        draft.checkListQC = cloneDeep(checklistQC)
        break

      case getType(actions.getUnitCheckListForQCReq.success):
        draft.eventChecklist.IsLoading = false
        const checkQC = action.payload.ResponseData
        draft.checkListQC = checkQC
        draft.checkListQC.FloorList = checkQC.FloorList || []
        break

      case getType(actions.getUnitCheckListForQCReq.failure):
        draft.eventChecklist.IsLoading = false
        draft.eventChecklist.SubmitError = parseErrorMessage(action.payload)
        break

      case getType(actions.createUnitDefectAction.request):
      case getType(actions.updateUnitDefectAction.request):
      case getType(actions.sendDefectForQCEditAction.request):
      case getType(actions.setUnitDefectAction.request):
        draft.event = cloneDeep(initEvent)
        draft.event.IsLoading = true
        break
      case getType(actions.createUnitDefectAction.success):
      case getType(actions.updateUnitDefectAction.success):
      case getType(actions.sendDefectForQCEditAction.success):
      case getType(actions.setUnitDefectAction.success):
        draft.event.IsLoading = false
        draft.event.SubmitError = undefined
        draft.event.SubmitSuccess = true
        draft.event.MessageSuccess = 'ทำรายการสำเร็จ'
        break
      case getType(actions.createUnitDefectAction.failure):
      case getType(actions.updateUnitDefectAction.failure):
      case getType(actions.sendDefectForQCEditAction.failure):
      case getType(actions.setUnitDefectAction.failure):
        draft.event.IsLoading = false
        draft.event.SubmitError = parseErrorMessage(action.payload)
        break

      case getType(actions.mapUnitDefectData):
        const reportItem = action.payload
        const Floor = +reportItem.UnitFloorName || 0
        draft.unitDefect = {
          ProjectCode: '',
          UserEmail: '',
          CheckListID: 0,
          QCTypeID: '',
          Unit: '',
          PinID: reportItem.PinID,
          PinNumber: reportItem.PinNumber,
          RequestID: reportItem.RequestID,
          CheckNumber: reportItem.CheckNumber,
          JobTypeID: reportItem.JobTypeID,
          SubJobTypeID: reportItem.SubJobTypeID,
          DetailID: reportItem.DetailID,
          LocationID: reportItem.LocationID,
          PinStatus: reportItem.PinStatus,
          Floor,
          Remark: reportItem.Remark,
          Score: reportItem.Score,
          XAxis: reportItem.XAxis,
          YAxis: reportItem.YAxis,
          ImageBefore: reportItem.ImageBefore,
          ImageAfter: reportItem.ImageAfter,
          Module: '',
        }
        break
      case getType(actions.updateReport):
        draft.report = action.payload
        break
      case getType(actions.resetEvent):
        draft.event = cloneDeep(initEvent)
        break

      default:
        return state
    }
  })
}
