import React, { useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { RouteComponentProps, Link } from 'react-router-dom'
import RootState from 'typings/RootState'
import { BreadcrumbLink } from 'Components/Shared/BreadCrumb/BreadCrumb'
import { selectedProjectCodeSelector } from 'features/project/project.selector'
import Pagination from 'Components/Shared/Pagination'
import {
  userEmailSelector,
  serviceTokenSelector,
} from 'features/sensitive/sensitive.selector'
import {
  selectData,
  selectEvent,
  selectSorting,
} from 'features/cmpj/zone/seemore/selector'
import { getSeemoreForZone } from 'features/cmpj/zone/seemore/api'
import { GetActivitySeemoreReq, Data } from 'features/cmpj/zone/seemore/types'
import { selectEvent as selectcmcheckingEvent } from 'features/cmpj/zone/report/selector'
import { sortingTask } from 'features/cmpj/zone/seemore/actions'
import { TypeOfTask } from 'features/cmpj/qcdashboard/types'
import { StructureListPage } from 'Components/Shared/Seemore'
import { NoCard, EventModal } from 'Components/Shared'
import { StartCheckingZoneReq } from 'features/cmpj/zone/report/types'
import { startCheckingZone } from 'features/cmpj/zone/report/api'
import RowItem from './RowItem'
import TaskFooter from './TaskFooter'
import TableHeader from './TableHeader'

type Props = ReduxProps & DispatchProps & RouteComponentProps<{ type: string }>

const QcSeemore: React.ComponentType<Props> = props => {
  const {
    match,
    data,
    event,
    sorting,
    token,
    ProjectCode,
    UserEmail,
    checkingEven,
  } = props

  const { params } = match
  const { type } = params
  const [taskSelect, setTaskSelect] = useState([] as any[])
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [enableModal, setEnableModal] = useState(false)
  const { DataList, TotalRows } = data

  const dispatch = useDispatch()

  let pageType = 1
  switch (type) {
    case TypeOfTask.TODO:
      pageType = 1
      break
    case TypeOfTask.CMInProgress:
      pageType = 2
      break
  }

  useEffect(() => {
    callGetActivitySeeMoreForQC()
  }, [ProjectCode, perPage, page])

  useEffect(() => {
    dispatch(sortingTask({ name: 'TaskRatio', sort: 'desc' }))
  }, [type])

  const onClickStartChecking = () => {
    setEnableModal(true)
    const request: StartCheckingZoneReq = {
      ProjectCode,
      UserEmail,
      ItemList: taskSelect,
    }
    dispatch(startCheckingZone(token, request))
  }

  const callGetActivitySeeMoreForQC = () => {
    const data: GetActivitySeemoreReq = {
      ProjectCode,
      UserEmail,
      Sort: {},
      PageType: pageType,
      Page: page,
      RowPerPage: perPage,
    }
    dispatch(getSeemoreForZone(token, data))
  }

  let title = `พร้อมตรวจด้วยตัวเอง (${TotalRows})`
  let breadcrumbTxt = 'พร้อมตรวจด้วยตัวเอง'
  switch (type) {
    case TypeOfTask.CMInProgress:
      title = `กำลังดำเนินการ (${TotalRows})`
      breadcrumbTxt = 'กำลังดำเนินการ'
      break
  }

  const breadcrumbs: BreadcrumbLink[] = [
    {
      url: '/zone/dashboard',
      title: 'Home',
      icon: '/home.png',
      active: true,
      showTitle: false,
    },
    {
      url: '/zone/dashboard',
      title: 'บอร์ด',
      active: true,
      showTitle: true,
    },
    {
      url: '/',
      title: breadcrumbTxt,
      active: false,
      showTitle: false,
    },
  ]

  const onSort = (name: string) => {
    dispatch(sortingTask({ name }))
  }

  const isPass = (PassedSubTasks: number) => PassedSubTasks > 0

  useEffect(() => {
    setEnableModal(false)
  }, [DataList])

  const renderContent = () => {
    return DataList.map((item: Data, index) => {
      const isSelect = verifySelected(item)
      return (
        <Link
          to={`/zone/request/${item.Unit}/${item.QCTypeID}/${item.RequestID}/${
            item.Floor
          }`}
        >
          <RowItem
            type={type}
            task={item}
            key={index}
            onClick={() => onSelectTask(item)}
            isSelected={isSelect}
          />
        </Link>
      )
    })
  }

  const remove = (data: Data) => {
    return taskSelect.filter(
      item =>
        item.Unit !== data.Unit ||
        item.QCTypeID !== data.QCTypeID ||
        item.Floor !== data.Floor ||
        item.FloorPlanID !== data.FloorPlanID
    )
  }

  const verifySelectAll = () => {
    let isSelectAll = true
    DataList.map(item => {
      if (isPass(item.PassedSubTasks)) {
        const isSelect = verifySelected(item)
        if (!isSelect) {
          isSelectAll = false
          return
        }
      }
    })
    return isSelectAll
  }

  const verifySelected = (data: Data) => {
    const selectData = taskSelect.filter(item => {
      return (
        item.Unit === data.Unit &&
        item.QCTypeID === data.QCTypeID &&
        item.Floor === data.Floor &&
        item.FloorPlanID === data.FloorPlanID &&
        item.RequestID === data.RequestID
      )
    })
    return selectData.length > 0 ? true : false
  }

  const showRadio = () => {
    let show = false
    DataList.map(item => {
      if (isPass(item.PassedSubTasks)) {
        show = true
      }
    })
    return show
  }

  const onSelectTask = (item: Data) => {
    const isSelect = verifySelected(item)
    let newValue = taskSelect
    if (isSelect) {
      newValue = remove(item)
    } else {
      newValue.push({
        Unit: item.Unit,
        QCTypeID: item.QCTypeID,
        RequestID: item.RequestID,
        Floor: item.Floor,
        FloorPlanID: item.FloorPlanID,
      })
    }
    setTaskSelect([...newValue])
  }

  const onSelectAll = () => {
    const isSelectAll = verifySelectAll()
    let newValue = [] as any
    if (isSelectAll) {
      newValue = []
    } else {
      DataList.map(item => {
        if (isPass(item.PassedSubTasks)) {
          newValue.push({
            Unit: item.Unit,
            QCTypeID: item.QCTypeID,
            RequestID: item.RequestID,
            Floor: item.Floor,
            FloorPlanID: item.FloorPlanID,
          })
        }
      })
    }
    setTaskSelect([...newValue])
  }

  const pagination = (
    <Pagination
      perPage={perPage}
      totalRows={TotalRows}
      currentPage={page}
      onClickPage={page => setPage(page)}
      onChangeNumber={number => setPerPage(number)}
    />
  )

  const tableHeader = (
    <TableHeader
      type={type}
      onSort={name => onSort(name)}
      sorting={sorting}
      isSelectAll={verifySelectAll()}
      onSelectAll={onSelectAll}
      showRadio={showRadio()}
    />
  )

  const footer = (
    <TaskFooter
      taskSelect={taskSelect}
      type={type}
      onClick={onClickStartChecking}
      isLoading={event.IsLoading}
    />
  )

  return (
    <>
      <EventModal event={[event, checkingEven]} enable={enableModal} />
      <StructureListPage
        breadcrumbs={breadcrumbs}
        tableHeader={tableHeader}
        titleMargin={32}
        tableContent={
          data.DataList.length > 0 ? (
            renderContent()
          ) : (
            <NoCard text={`ไม่มีข้อมูล${breadcrumbTxt}`} />
          )
        }
        title={title}
        footerPagination={pagination}
        footer={footer}
      />
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    data: selectData(state),
    event: selectEvent(state),
    sorting: selectSorting(state),
    token: serviceTokenSelector(state),
    ProjectCode: selectedProjectCodeSelector(state),
    UserEmail: userEmailSelector(state),
    checkingEven: selectcmcheckingEvent(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {
  sortingTask,
}

type DispatchProps = typeof mapDispatchToProps

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QcSeemore)
