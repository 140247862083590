import withAPIAction from 'utils/withAPIAction'
import { verifyReq } from 'utils/verify'
import {
  getApprovalListAction,
  updateActivity,
  getMSApprovalListAction,
  updateMSActivity,
} from './actions'
import { GetActivityReq } from './types'
import { UpdateActivityReq } from '../activity/types'

export const getApproveList = (token: string, data: GetActivityReq) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<GetActivityReq>({
    method: 'POST',
    path: 'GetApproveListForPJ',
    data,
    token: `Bearer ${token}`,
  })(getApprovalListAction)
}

export const saveActionActivityForPJ = (
  data: UpdateActivityReq,
  token: string
) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<UpdateActivityReq>({
    method: 'POST',
    path: 'SaveActionActivityForPJ',
    data,
    token: `Bearer ${token}`,
  })(updateActivity)
}

export const getMSApproveList = (token: string, data: GetActivityReq) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<GetActivityReq>({
    method: 'POST',
    path: 'GetListForApprove',
    data,
    token: `Bearer ${token}`,
  })(getMSApprovalListAction)
}

export const saveMSActionActivity = (
  data: UpdateActivityReq,
  token: string
) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<UpdateActivityReq>({
    method: 'POST',
    path: 'SaveActionActivity',
    data,
    token: `Bearer ${token}`,
  })(updateMSActivity)
}
