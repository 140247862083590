import React from 'react'

import { BreadcrumbLink } from '../../Shared/BreadCrumb/BreadCrumb'
import SubnavBar from '../../Shared/SubnavBar'
import { Task } from 'features/tasklane/tasklane.types'

import { translate } from 'i18n'
import TKeys from 'i18n/translationKey'
import { lookup } from '../../../lookup'

type Props = {
  progress: number
  activity: Task
  name?: string
  onClose?: () => void
}

const Breadcrumbs: React.ComponentType<Props> = props => {
  const { progress, activity, name } = props
  const breadcrumbs: BreadcrumbLink[] = [
    {
      url: '/dashboard',
      title: 'Home',
      icon: '/home.png',
      active: true,
      showTitle: false,
    },
    {
      url: '/dashboard',
      title: translate(TKeys.Breadcrumb.dashboard),
      active: true,
      showTitle: true,
    },
  ]

  if (activity) {
    let type
    switch (progress) {
      case 0:
        type = 'todo'
        break
      case 100:
        type = 'complete'
        break
      default:
        type = 'in-progress'
    }

    let headerTitle = lookup[type]
    breadcrumbs.push({
      url: `/see-more/type/${type}/page/1`,
      title: headerTitle,
      active: true,
      showTitle: true,
    })

    breadcrumbs.push({
      url: `/activity-details/${activity.ActivityID}`,
      title: activity.ActivityName || 'activity',
      active: name ? true : false,
      showTitle: name ? true : false,
      onClick: props.onClose,
    })

    if (name) {
      breadcrumbs.push({
        url: `/activity-details/${activity.ActivityID}`,
        title: name,
        active: false,
        showTitle: false,
      })
    }
  }

  return <SubnavBar breadcrumbs={breadcrumbs} />
}

export default Breadcrumbs
