import { createStandardAction, createAsyncAction } from 'typesafe-actions'
import {
  GetDataListReq,
  GetDataListResp,
  GetFilterListReq,
  GetSansiriListResp,
  GetVandorListResp,
  CreateDataReq,
  UpdateDataReq,
  CommonResp,
  FindUserReq,
  FindUserResp,
  GetCompanyVandorListResp,
  Filter,
} from './types'

export const getDataListAction = createAsyncAction(
  'admin/user/FETCH_LIST_REQUEST',
  'admin/user/FETCH_LIST_SUCCESS',
  'admin/user/FETCH_LIST_FAILURE'
)<GetDataListReq, GetDataListResp, any>()

export const createDataAction = createAsyncAction(
  'admin/user/CREATE_DATA_REQ',
  'admin/user/CREATE_DATA_RESP',
  'admin/user/CREATE_DATA_FAIL'
)<CreateDataReq, CommonResp, any>()

export const updateDataAction = createAsyncAction(
  'admin/user/UPDATE_DATA_REQ',
  'admin/user/UPDATE_DATA_RESP',
  'admin/user/UPDATE_DATA_FAIL'
)<UpdateDataReq, CommonResp, any>()

export const getSansiriListAction = createAsyncAction(
  'admin/user/FETCH_SANSIRI_LIST_REQUEST',
  'admin/user/FETCH_SANSIRI_LIST_SUCCESS',
  'admin/user/FETCH_SANSIRI_LIST_FAILURE'
)<GetFilterListReq, GetSansiriListResp, any>()

export const getVandorListAction = createAsyncAction(
  'admin/user/FETCH_VANDOR_LIST_REQUEST',
  'admin/user/FETCH_VANDOR_LIST_SUCCESS',
  'admin/user/FETCH_VANDOR_LIST_FAILURE'
)<GetFilterListReq, GetVandorListResp, any>()

export const getCompanyVandorListAction = createAsyncAction(
  'admin/user/FETCH_COM_VANDOR_LIST_REQUEST',
  'admin/user/FETCH_COM_VANDOR_LIST_SUCCESS',
  'admin/user/FETCH_COM_VANDOR_LIST_FAILURE'
)<GetFilterListReq, GetCompanyVandorListResp, any>()

export const findUserAction = createAsyncAction(
  'admin/user/FIND_USER_LIST_REQUEST',
  'admin/user/FIND_USER_LIST_SUCCESS',
  'admin/user/FIND_USER_LIST_FAILURE'
)<FindUserReq, FindUserResp, any>()

export const updateFilter = createStandardAction(
  'admin/user/UPDATE_FILTER_DASHBOARD'
)<Filter>()

export const clearFilter = createStandardAction(
  'admin/user/CLEAR_FILTER_DASHBOARD'
)<void>()

export const resetEvent = createStandardAction('admin/user/RESET_EVENT_ACTION')<
  void
>()
