import React from 'react'
import { Col } from 'antd'
import styled from 'styled-components'
import { TableRow } from 'Components/Shared/Table'
import { ProjectListData } from 'features/pmr/types'

type DefaultProps = {
  task: ProjectListData
}
type Props = DefaultProps

const RowItem: React.FC<Props> = props => {
  const { task } = props

  return (
    <TableRow minHeight={60} paddingLeft={24} spaceBetweenRow={8}>
      <Col span={8}>{task.Unit}</Col>
      <Col span={8}>{task.Floor}</Col>
      <Col span={8}>{task.TotalDefect}</Col>
    </TableRow>
  )
}

export default RowItem
