import React, { useEffect } from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import TopNav from 'Components/Shared/TopNav'
import TokenExpired from 'Components/Pages/TokenExpired'

import ConstructPlanDashboard from 'Components/QC/Dashboard/SiteVisit/ConstructPlan'
import ConstructPlanReport from 'Components/QC/ConstructPlan/Report'

type Props = {}

const QcConstructPlanApp: React.FunctionComponent<Props> = props => {
  return (
    <>
      <TopNav title="QC" displayProject={false} secondaryTitle="Site visit" />
      <Switch>
        <Route
          exact
          path="/construct-plan"
          component={ConstructPlanDashboard}
        />

        <Route
          exact
          path="/construct-plan/report/:project/:requestID/:checkNumber"
          component={ConstructPlanReport}
        />

        <Route exact path="/token-expired" component={TokenExpired} />
      </Switch>
    </>
  )
}

export default QcConstructPlanApp
