import { DataItem } from './types'

export const formatData = (list: DataItem[]): DataItem[] => {
  let newList = [] as any

  list.forEach(item => {
    item.ScoreList.forEach(score => {
      let data = {
        ...objectWithoutKey(item, 'ScoreList'),
        ...score,
      }

      newList.push(data)
    })
  })
  return newList

  return []
}

const objectWithoutKey = (object: any, key: any) => {
  const { [key]: deletedKey, ...otherKeys } = object
  return otherKeys
}
