import { createAsyncAction } from 'typesafe-actions'
import { GetCheckingRequest, GetCheckingResponse,GetCheckListRequest,GetCheckListResponse } from './types'

export const getCheckingCommissioningByQC = createAsyncAction(
  'qc/commissioning/FETCH_CHECKING_REQUEST',
  'qc/commissioning/FETCH_CHECKING_SUCCESS',
  'qc/commissioning/FETCH_CHECKING_FAILURE'
)<GetCheckingRequest, GetCheckingResponse, any>()


export const getCommissioningCheckListForQC	 = createAsyncAction(
  'qc/commissioning/FETCH_CHECKLIST_REQUEST',
  'qc/commissioning/FETCH_CHECKLIST_SUCCESS',
  'qc/commissioning/FETCH_CHECKLIST_FAILURE'
)<GetCheckListRequest, GetCheckListResponse, any>()