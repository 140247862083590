import withAPIAction from '../../../utils/withAPIAction'

import { GetCheckListReq, UpdateCheckListReq } from './types'
import { getCheckListAction, updateCheckListAction } from './actions'

export const getDocumentList = (token: string, data: GetCheckListReq) =>
  withAPIAction<GetCheckListReq>({
    method: 'POST',
    path: 'GetDocumentCheckList',
    data,
    token: `Bearer ${token}`,
  })(getCheckListAction)

export const updateDocument = (token: string, data: UpdateCheckListReq) =>
  withAPIAction<UpdateCheckListReq>({
    method: 'POST',
    path: 'UpdateDocumentCheckList',
    data,
    token: `Bearer ${token}`,
  })(updateCheckListAction)
