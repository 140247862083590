import React, { useState } from 'react'
import { Row, Col } from 'antd'
import styled from 'styled-components'
import { TableRow, StatusCol } from 'Components/Shared/Table'
import { UnitReportItem } from 'features/report/unit/types'
import DefectStatus from './DefectStatus'
import DefectScore from './DefectScore'
import imgPhoto from 'assets/img/ic-photo.png'
import { getDefectColor } from 'utils/color-map'
import { RequireIcon } from 'Components/Shared/Icon'
import { RadioBox } from 'Components/Shared/Form'

type Props = {
  item: UnitReportItem
  onClick: (item: any) => void
  showAfterImg?: boolean
  showRadio?: boolean
  isSelected?: boolean
  onSelect?: (item: any) => void
  showThumbnail?: boolean
}

const ReportItem: React.FunctionComponent<Props> = props => {
  const {
    item,
    onClick,
    showAfterImg = false,
    showRadio = false,
    isSelected = false,
    onSelect = () => {},
    showThumbnail = false,
  } = props

  const defect = getDefectColor(item.PinStatus)

  const getRadioBox = () => {
    return (
      <Margin>
        <RadioBox
          value={''}
          label={''}
          isCheck={isSelected}
          onClick={onSelect}
          size={24}
        />
      </Margin>
    )
  }
  const color = defect.textColor
  const spanCondition = showThumbnail ? 4 : 5
  return (
    <RowView onClick={() => onClick(item)}>
      <TableRow>
        <Col span={2}>
          <StatusCol color={defect.color}>
            <Pin>{item.PinNumber}</Pin>
          </StatusCol>
        </Col>

        <Col span={spanCondition} style={{ color }}>
          {item.IsRequired && (
            <Space>
              <RequireIcon size={14} />
            </Space>
          )}
          {item.JobTypeName}
        </Col>

        <Col span={spanCondition} style={{ color }}>
          {item.SubJobTypeName}
        </Col>

        <Col span={spanCondition} style={{ color }}>
          {item.DetailName}
        </Col>

        <Col span={2}>
          <DefectScore pinStatus={item.PinStatus} pinScore={item.Score} />
        </Col>

        <Col span={3}>
          <DefectStatus pinStatus={item.PinStatus} />
        </Col>
        {showAfterImg && item.ImageAfter && (
          <Col span={2}>
            <img src={imgPhoto} alt="" width={22} />
          </Col>
        )}
        {showThumbnail && (
          <Col span={4}>
            <img
              src={
                item.ImageAfter
                  ? item.ImageAfter.FileURL
                  : item.ImageBefore.FileURL
              }
              alt=""
              width={100}
              height={70}
            />
          </Col>
        )}
        {showRadio && getRadioBox()}
      </TableRow>
    </RowView>
  )
}

const RowView = styled.div`
  font-size: 20px;
  font-family: 'DB Helvethaica X 55 Regular';
  cursor: pointer;
`

const Pin = styled.div`
  padding-left: 24px;
  font-family: 'DB Helvethaica X 75 Bd';
`

const Space = styled.div`
  padding-right: 5px;
  float: left;
`

const Margin = styled.div`
  margin-left: 4px;
`

export default ReportItem
