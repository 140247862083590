import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { connect, useDispatch } from 'react-redux'
import { RootState } from 'typings/RootState'
import { RouteComponentProps } from 'react-router-dom'
import { BreadcrumbLink } from 'Components/Shared/BreadCrumb/BreadCrumb'
import SubnavBar from 'Components/Shared/SubnavBar'
import { TableHeader, TableColHeader } from 'Components/Shared/Table'
import { AdminProject, AlertError, LoadingView } from 'Components/Shared'
import EventModal from 'Components/Shared/Modal/EventModal'
import Menu from '../../../MenuAdmin'
import { getRequestHistory } from 'features/adminqc/unit/api'
import RowItem from './RowItem'
import { Button, Col, Row } from 'antd'
import Pagination from '../../../Shared/Pagination'
import { InputSearch } from 'Components/Shared/Input'
import { GetRequestHistoryReq } from 'features/adminqc/unit/types'
import {
  ButtonFilter,
  QcTypeFilter,
} from 'Components/AdminQC/Report/Filter/Components'
import { projectsByIdSelector } from 'features/project/project.selector'
import { fetchProjects } from 'features/project/project.api'
import {
  serviceTokenSelector,
  userEmailSelector,
} from 'features/sensitive/sensitive.selector'
import { DropdownPopupMenu } from 'Components/Shared/Form'
import UnitContent from 'Components/Shared/Filters/UnitContent'
import { selectUnitList, selectQCTypeList } from 'features/filter/selector'
import { GetQCTypeListReq, GetUnitListReq } from 'features/filter/types'
import { getQCTypeList, getUnitList } from 'features/filter/api'
import { updateFilter } from 'features/adminReport/unit/actions'
import { Filter } from 'features/adminReport/unit/types'

const breadcrumbs: BreadcrumbLink[] = [
  {
    url: '/dashboard',
    title: 'Home',
    icon: '/home.png',
    active: true,
    showTitle: false,
  },
  {
    url: '/utilities/history',
    title: 'ประวัติ',
    active: false,
    showTitle: false,
  },
]

type Props = ReduxProps & DispatchProps & RouteComponentProps

const RequestHistory: React.ComponentType<Props> = ({
  data,
  event,
  projectsById,
  UserEmail,
  token,
  unitList,
  qctypeList,
}) => {
  const [err, setErr] = useState(undefined)
  const [filter, setFilter] = useState<GetRequestHistoryReq>({
    Page: 1,
    RowPerPage: 10,
    ProjectCode: '',
    QCType: '',
    RequestID: 0,
    UnitCode: '',
  })
  const [requestIdStr, setRequestIdStr] = useState('')
  const [projectCode, setProjectCode] = useState('')
  const [unitSelected, setUnitSelected] = useState<string[]>([])
  const [qcTypeSelected, setQCTypeSelected] = useState<string[]>([])
  const [_filter, _setFilter] = useState<Filter>({
    ProjectCode: [],
    ProjectTypeID: 0,
    QCTypeList: [],
    UnitList: [],
    Date: {
      Mode: '',
      StartDate: undefined,
      FinishDate: undefined,
    },
    CheckedByRole: '',
  })
  const [pagination, setPagination] = useState({
    Page: 1,
    RowPerPage: 10,
  })

  const dispatch = useDispatch()

  useEffect(() => {
    if (filter.ProjectCode !== '' || (filter && filter?.RequestID! > 0))
      fetchData()
  }, [filter])

  useEffect(() => {
    submitFilter()
  }, [pagination])

  const fetchData = () => {
    dispatch(getRequestHistory(filter))
  }

  const submitFilter = () => {
    const __filter = {
      ...filter,
      RequestID: parseInt(requestIdStr) ?? '',
      ProjectCode: projectCode,
      UnitCode: unitSelected ? unitSelected.join(',') : '',
      QCType: qcTypeSelected ? qcTypeSelected.join(',') : '',
      Page: pagination.Page,
      RowPerPage: pagination.RowPerPage,
    }
    setFilter({ ...__filter })
  }

  useEffect(() => {
    dispatch(fetchProjects({ email: UserEmail, token }))
    onGetQCTypeList()
  }, [UserEmail, token])

  useEffect(() => {
    onGetUnitList()
  }, [projectCode])

  const onGetUnitList = () => {
    const req: GetUnitListReq = {
      ProjectCode: projectCode,
      UserEmail,
      Zone: 'Resident Area',
    }
    dispatch(getUnitList(token, req))
  }

  const onGetQCTypeList = () => {
    const req: GetQCTypeListReq = {
      UserEmail,
      UnitType: 'Unit',
      Status: 'Active',
    }
    dispatch(getQCTypeList(token, req))
  }

  const clearFilter = () => {
    setFilter({})
    setRequestIdStr('')
    setProjectCode('')
    setUnitSelected([])
    setQCTypeSelected([])
  }

  const filterElement = (
    <>
      <Row type="flex" justify="start" style={{ width: '100%' }}>
        <Col span={4}>
          <FilterItem>
            <InputSearch
              placeholder="ค้นหาด้วย ID"
              value={requestIdStr}
              hideSuffix={true}
              onChange={vale => setRequestIdStr(vale)}
            />
          </FilterItem>
        </Col>
        <Col span={6}>
          <FilterItem>
            <AdminProject
              projectList={projectsById}
              onSelectProject={projectCode => setProjectCode(projectCode)}
              activeProject={projectCode}
            />
          </FilterItem>
        </Col>
        <Col span={6}>
          <FilterItem>
            <DropdownPopupMenu
              label={'ยูนิต'}
              placeholder="เลือกยูนิต"
              value={unitSelected.join(',')}
              isActive={unitSelected.length > 0}
              disabled={projectCode === ''}
              content={
                <UnitContent
                  units={unitList.List}
                  filter={unitSelected}
                  onChange={data => {
                    setUnitSelected(data)
                    _setFilter({ ..._filter, UnitList: unitSelected })
                    console.log(_filter)
                    dispatch(updateFilter(_filter))
                  }}
                />
              }
            />
          </FilterItem>
        </Col>
        <Col span={8}>
          <FilterItem>
            <QcTypeFilter
              filter={{
                QCTypeList: qcTypeSelected,
              }}
              onFilterChange={data => {
                setQCTypeSelected(data.QCTypeList)
                _setFilter({ ..._filter, QCTypeList: qcTypeSelected })
                console.log(_filter)
                dispatch(updateFilter(_filter))
              }}
              qctypeList={qctypeList}
            />
          </FilterItem>
        </Col>
      </Row>
      <Row type="flex" justify="end">
        <Col span={12}>
          <ButtonFilter
            onSearch={submitFilter}
            isFilter={true}
            showExport={false}
            onClearSearch={clearFilter}
          />
        </Col>
      </Row>
    </>
  )

  const content = data.map((item: any, index: number) => {
    return <RowItem key={index} item={item} />
  })

  return (
    <>
      <LoadingView isShow={event.IsLoading} />
      <SubnavBar breadcrumbs={breadcrumbs} hideSearch={true} />
      <AlertError
        isShow={err !== undefined}
        text={err}
        callback={() => setErr(undefined)}
      />
      <Menu selected="requestHistory">
        <MainPage>
          {filterElement}
          <TableHeader paddingLeft={25}>
            {/* <TableColHeader span={1}>ID</TableColHeader> */}
            <TableColHeader span={4}>โครงการ</TableColHeader>
            <TableColHeader span={2}>ยูนิต</TableColHeader>
            <TableColHeader span={2}>โมเดล</TableColHeader>
            <TableColHeader span={1}>หมวดงาน</TableColHeader>
            <TableColHeader span={2}>State</TableColHeader>
            <TableColHeader span={3}>Status</TableColHeader>
            {/* <TableColHeader span={3}>วันที่ส่งรายงาน</TableColHeader> */}
            <TableColHeader span={3}>กิจกรรม</TableColHeader>
            <TableColHeader span={4}>ผู้ทำรายการ</TableColHeader>
          </TableHeader>
          {content}
          <Pagination
            currentPage={pagination.Page}
            totalRows={data.length > 0 ? data[0].Total : 0}
            perPage={pagination.RowPerPage}
            onChangeNumber={(page: number) => {
              pagination.RowPerPage = page
              setPagination({ ...pagination })
            }}
            onClickPage={(page: number) => {
              pagination.Page = page
              setPagination({ ...pagination })
            }}
          />
        </MainPage>
      </Menu>
    </>
  )
}

const MainPage = styled.div`
  padding: 40px 45px;
  width: 100%;
`

const FilterItem = styled.div`
  margin: 0 0.5rem;
`

const mapStateToProps = (state: RootState) => {
  return {
    data: state.adminqcUnit.requestHistoryList,
    event: state.adminqcUnit.event,
    token: serviceTokenSelector(state),
    UserEmail: userEmailSelector(state),
    projectsById: projectsByIdSelector(state),
    unitList: selectUnitList(state),
    qctypeList: selectQCTypeList(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>
const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(RequestHistory)
