import React, { useEffect } from 'react'
import styled from 'styled-components'
import { connect, useDispatch } from 'react-redux'
import SubnavBar from 'Components/Shared/SubnavBar'
import { Row, Col } from 'antd'
import { RouteComponentProps } from 'react-router-dom'
import { translate } from 'i18n'
import TKeys from 'i18n/translationKey'

import RootState from 'typings/RootState'
import { BreadcrumbLink } from '../../Shared/BreadCrumb/BreadCrumb'
import { selectedProjectCodeSelector } from 'features/project/project.selector'
import {
  userEmailSelector,
  serviceTokenSelector,
} from 'features/sensitive/sensitive.selector'
import {
  selectData,
  selectEvent,
  selectSorting,
  selectFilter,
} from 'features/cmpj/qcdashboard/selector'
import {
  sortingDashboard,
  clearFilter,
} from 'features/cmpj/qcdashboard/actions'
import { getQCActivityList } from 'features/cmpj/qcdashboard/api'
import { GetActivityRequest, TypeOfTask } from 'features/cmpj/qcdashboard/types'

import LinkButton from 'Components/Shared/LinkButton'
import NoCard from 'Components/Shared/NoCard'
import Loading from 'Components/Shared/Loading'
import { CMUnitFilter } from 'Components/Filter'

import Menu from '../Menu'
import Tab from '../Tab'
import ColumnHeader from './ColumnHeader'
import Card from './Card'
import { getEvent } from 'features/wbs/wbs.selector'
import { LoadingView } from 'Components/Shared'
import { useLocation } from 'react-router'
import queryString from 'query-string'

const breadcrumbs: BreadcrumbLink[] = [
  {
    url: '/',
    title: 'Home',
    icon: '/home.png',
    active: true,
    showTitle: false,
  },
  {
    url: '/',
    title: translate(TKeys.Breadcrumb.dashboard),
    active: false,
    showTitle: false,
  },
]

type Props = ReduxProps & DispatchProps & RouteComponentProps

const QCDashboard: React.ComponentType<Props> = props => {
  const {
    data,
    event,
    sorting,
    token,
    ProjectCode,
    UserEmail,
    sortingTask,
    filter,
    wbsEvent,
  } = props

  const dispatch = useDispatch()
  const searchParam = useLocation()

  useEffect(() => {
    return () => {
      dispatch(clearFilter())
    }
  }, [])

  useEffect(() => {
    onGetActivity()

    onBindFilterFromParams()
  }, [ProjectCode, searchParam])

  const onBindFilterFromParams = () => {
    if (searchParam.search) {
      onSearch()
    }
  }

  const onGetActivity = () => {
    const data: GetActivityRequest = {
      ProjectCode,
      UserEmail,
      Sort: {},
      QCTypeList: filter.QCTypeList,
      UnitList: filter.UnitList,
      WbsList: filter.LeafWbsList,
    }
    dispatch(getQCActivityList(token, data))
  }

  const loading = (
    <div style={{ textAlign: 'center' }}>
      <Loading />
    </div>
  )

  const onSearch = () => {
    onGetActivity()
  }

  const renderCard = (task: any[], type: string) => {
    let card = task.map((activity, index) => (
      <Card key={index} task={activity} type={type} />
    ))

    if (type === TypeOfTask.TODO && data.TotalToDo >= 10) {
      card.push(
        <LinkButton href={`/qcseemore/${type}`} title={'ดูเพิ่มเติม'} />
      )
    }
    return card
  }

  const renderTaskLen = (
    task: any[],
    header: string,
    orderName: string,
    type: string,
    total: number
  ) => {
    const card =
      task && task.length > 0 ? (
        renderCard(task, type)
      ) : (
        <NoCard text="ไม่มีรายการ" />
      )
    const content = event.isFetching ? loading : card
    return (
      <Col span={6}>
        <FullColumn>
          <ColumnHeader
            heading={`${header} (${total})`}
            orderName={orderName}
            type={sorting[type]}
            onSort={() => {
              sortingTask(type)
            }}
          />
          {content}
        </FullColumn>
      </Col>
    )
  }

  return (
    <>
      <Menu selected="unit" />
      <SubnavBar breadcrumbs={breadcrumbs} hideSearch={true} />
      <CMUnitFilter onSearch={onSearch} withPadding={true} />
      <LoadingView isShow={event.isFetching || wbsEvent.IsLoading} />
      <MainPage>
        <Row type="flex">
          {renderTaskLen(
            data.ToDo,
            'เตรียมพร้อมตรวจด้วยตัวเอง',
            'จำนวนงาน',
            TypeOfTask.TODO,
            data.TotalToDo
          )}
          {renderTaskLen(
            data.CMInProgress,
            'กำลังดำเนินการ',
            'คะแนน',
            TypeOfTask.CMInProgress,
            data.TotalCMInProgress
          )}
          {renderTaskLen(
            data.QCInProgress,
            'QC กำลังดำเนินการ',
            'วันที่ส่งคำขอ',
            TypeOfTask.QCInProgress,
            data.TotalQCInProgress
          )}
          {renderTaskLen(
            data.FixFromQC,
            'รายการแก้ไข',
            'วันที่ล่าสุด',
            TypeOfTask.FixFromQC,
            data.TotalFixFromQC
          )}
        </Row>
      </MainPage>
    </>
  )
}

const MainPage = styled.div`
  padding: 0 120px;
  padding-top: 40px;
  background-color: #f5f6fa;
  width: 100vw;
  -khtml-user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  overflow-x: scroll;
`

const FullColumn = styled.div`
  padding-right: 20px;
`

const mapStateToProps = (state: RootState) => {
  return {
    data: selectData(state),
    event: selectEvent(state),
    sorting: selectSorting(state),
    token: serviceTokenSelector(state),
    ProjectCode: selectedProjectCodeSelector(state),
    UserEmail: userEmailSelector(state),
    filter: selectFilter(state),
    wbsEvent: getEvent(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {
  sortingTask: sortingDashboard,
}

type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(QCDashboard)
