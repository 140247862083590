import React from 'react'
import styled from 'styled-components'
import { DropdownList } from 'Components/Shared/Form'
import InputSearch from 'Components/Shared/Input/InputSearch'
import {
  CheckListStatus,
  CheckListStatusText,
} from 'features/cmpj/activity/types'

type Props = {
  status: string
  remark: string
  remarkStatus?: any[]
  onChangeStatus: (status: string) => void
  onChangeRemark: (remark: string) => void
}

const RemarkBox: React.FC<Props> = (props: Props) => {
  const {
    status,
    remark,
    remarkStatus = defStatus,
    onChangeStatus,
    onChangeRemark,
  } = props

  return (
    <ChecklistItem className="remark-box">
      <FormBox>
        <StatusBox>
          <DropdownList
            label="สถานะ"
            value={status.toUpperCase()}
            menu={remarkStatus}
            onChange={(value: any) => {
              onChangeStatus(value)
              if (value !== CheckListStatus.FAIL) {
                onChangeRemark('')
              }
            }}
          />
        </StatusBox>
        <RemarkBoxDetail>
          <InputSearch
            readOnly={false}
            placeholder="กรณีที่ไม่ผ่าน กรุณาระบุเหตุผล"
            value={remark}
            onChange={(value: string) => {
              onChangeRemark(value)
            }}
            hideSuffix={true}
          />
        </RemarkBoxDetail>
      </FormBox>
    </ChecklistItem>
  )
}

const defStatus = [
  {
    value: CheckListStatus.PASS,
    name: CheckListStatusText.PASS,
  },
  {
    value: CheckListStatus.FAIL,
    name: CheckListStatusText.FAIL,
  },
  {
    value: CheckListStatus.SKIP,
    name: CheckListStatusText.SKIP,
  },
  {
    value: CheckListStatus.NOTCHECK,
    name: CheckListStatusText.NOTCHECK,
  },
]

const ChecklistItem = styled.div`
  padding: 16px 81px 16px 0;
  margin: 0 16px 0 24px;
  border-bottom: 1px solid #eeeff2;
  height: 63px;

  .ant-collapse-content-active {
    .remark {
      border-bottom: none;
      background-color: red;
    }
  }

  &.remark-box {
    padding-top: 0;
  }

  .remark-title {
    font-family: 'DB Helvethaica X 75 Bd';
  }
`

const StatusBox = styled.div`
  width: 282px;
  margin-right: 28px;
`

const RemarkBoxDetail = styled.div`
  width: calc(100% - 310px);
`

const FormBox = styled.div`
  display: flex;
  width: 100%;
`

export default RemarkBox
