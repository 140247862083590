import withAPIAction from 'utils/withAPIAction'
import { verifyReq } from 'utils/verify'
import { GetActivitySeeMoreForCMAction } from './actions'

import { GetActivitySeemoreRequest } from './types'

export const getActivitySeeMoreForCMapi = (
  token: string,
  data: GetActivitySeemoreRequest
) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<GetActivitySeemoreRequest>({
    method: 'POST',
    path: 'GetActivitySeeMoreForCM',
    data,
    token: `Bearer ${token}`,
  })(GetActivitySeeMoreForCMAction)
}
