import { ActionType } from 'typesafe-actions'
import * as actions from './actions'
import { Event } from '../../types/event'

export type ReportAction = ActionType<typeof actions>

export interface StoreState {
  dataList: DataList
  event: Event
  sowList: SowList
  sowCodeEvent: Event
}

export type DataItem = {
  PositionID: number
  PositionName: string
  SOWCode: string
  IsMasterPlan: boolean
  VendorCode: string
  VendorName: string
}

export type DataList = {
  Total: number
  List: DataItem[]
}

export interface GetDataListReq {
  UserEmail: string
  IsMasterPlan: boolean
  Page: number
  RowPerPage: number
  TextFilter?: string
}

export interface CreateDataReq {
  UserEmail: string
  PositionName: string
  SOWCode: string
  IsMasterPlan: boolean
  VendorCode: string
}

export interface UpdateDataReq {
  UserEmail: string
  PositionID: number
  PositionName: string
  SOWCode: string
  IsMasterPlan: boolean
  VendorCode?: string
}

export interface DeleteDataReq {
  UserEmail: string

  PositionID: number
}

export interface GetFilterListReq {
  UserEmail: string
  TextFilter: string
}

export interface SowList {
  List: {
    SOWCode: string
  }[]
}

export type GetDataListResp = GetApiResp<DataList>
export interface CommonResp extends GetApiResp<{}> {}
export type FindSowResp = GetApiResp<SowList>

export interface GetApiResp<T> {
  ResponseData: T
  ResponseCode: string
  ResponseMsg: 'Success' | 'Error'
  RequestTime: string
  Responsetime: string
}

export enum SowType {
  Detail = 'Detail',
  MasterPlan = 'Master Plan',
}
