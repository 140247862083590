import { DataItem } from './types'

export const formatData = (list: DataItem[]): DataItem[] => {
  let newList = [] as any
  list.forEach(item => {
    item.ProjectList.forEach(project => {
      let data = {
        ...objectWithoutKey(item, 'ProjectList'),
        ...project,
      }

      newList.push(data)
    })
  })
  return newList
}

const objectWithoutKey = (object: any, key: any) => {
  const { [key]: deletedKey, ...otherKeys } = object
  return otherKeys
}
