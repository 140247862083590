import withAPIAction from 'utils/withAPIAction';
import { APIPath } from 'services/APIPath';

import {
  SaveActivityRequest,
  FetchActivitiesRequest,
  FetchActivityHistoryRequest,
  FetchActivityRequest,
} from './activity.types';
import {
  saveActivityDetailAsyncRequest,
  uploadFileAsyncRequest,
  fetchSeeMoreActivitiesAsyncRequest,
  fetchActivityHistoryAsyncRequest,
  fetchActivityAsyncRequest,
} from '../activity/activity.actions';

export const saveActivityRequest = (
  data: SaveActivityRequest,
  token: string
) => {
  if (token === '') {
    return { type: 'activity/no_token' };
  }

  return withAPIAction<SaveActivityRequest>({
    method: 'POST',
    path: APIPath.saveActivity,
    data,
    config: undefined,
    token: `Bearer ${token}`,
  })(saveActivityDetailAsyncRequest);
};

export const uploadFileActivityRequest = (data: any, token: string) => {
  if (token === '') {
    return { type: 'activity/no_token' };
  }
  const config = { headers: { 'Content-Type': 'multipart/form-data' } };
  return withAPIAction<any>({
    method: 'POST',
    path: APIPath.uploadFileActivity,
    data,
    config,
    token: `Bearer ${token}`,
  })(uploadFileAsyncRequest);
};

export const getActivitySeeMoreRequest = (
  path: string,
  data: FetchActivitiesRequest,
  token: string
) => {
  if (token === '') {
    return { type: 'activity/no_token' };
  }

  if (data.UserEmail === '') {
    return { type: 'activity/MISSING_USER_EMAIL' };
  }

  if (data.ProjectCode === '-MS') {
    return {
      type: 'activity/PAYLOAD_NOT_READY',
    };
  }

  return withAPIAction<FetchActivitiesRequest>({
    method: 'POST',
    path,
    data: data,
    token: `Bearer ${token}`,
  })(fetchSeeMoreActivitiesAsyncRequest);
};

export const getActivityHistoryRequest = (
  data: FetchActivityHistoryRequest,
  token: string
) => {
  if (
    !data.ProjectCode ||
    data.ProjectCode === '-MS' ||
    !data.ActivityID ||
    !data.UserEmail ||
    !token
  ) {
    return { type: 'activity/HISTORY_PAYLOAD_NOT_READY' };
  }

  return withAPIAction<FetchActivityHistoryRequest>({
    method: 'POST',
    path: APIPath.getActivityLogs,
    data,
    token: `Bearer ${token}`,
  })(fetchActivityHistoryAsyncRequest);
};

export const getActivityRequest = (
  data: FetchActivityRequest,
  token: string
) => {
  if (token === '') {
    return { type: 'activity/no_token' };
  }

  if (data.ProjectCode === '-MS') {
    return {
      type: 'activity/PAYLOAD_NOT_READY',
    };
  }

  return withAPIAction<FetchActivityRequest>({
    method: 'POST',
    path: APIPath.getActivityById,
    data,
    token: `Bearer ${token}`,
  })(fetchActivityAsyncRequest);
};
