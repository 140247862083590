import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import RootState from 'typings/RootState'
import { connect, useDispatch } from 'react-redux'
import { Layout } from 'antd'
import { RouteComponentProps } from 'react-router-dom'

import {
  submitPinReq,
  updateReport,
  submitEditDefect,
} from 'features/report/unit/actions'
import { getUnitReport, getUnitCheckListForQC } from 'features/report/unit/api'
import {
  GetUnitReportReq,
  GetCheckListForQCReq,
  UnitDefectType,
  UnitReportItem,
  SendUnitDefectReq,
  UnitReport,
  EditDefectReq,
} from 'features/report/unit/types'
import {
  selectReport,
  selectUnitDefect,
  selectEvent,
  selectEventChecklist,
  selectCheckListQC,
} from 'features/report/unit/selector'

import {
  resetUnitDefect,
  updateUnitDefect,
  mapUnitDefectData,
} from 'features/report/unit/actions'

import { selectEvent as eventRequest } from 'features/cmpj/cmchecking/selector'
import {
  userEmailSelector,
  serviceTokenSelector,
} from 'features/sensitive/sensitive.selector'
import { selectedProjectCodeSelector } from 'features/project/project.selector'
import {
  Report,
  AddUnitDefect,
  ReportPinDetail,
  PinUnitLocation,
} from 'Components/Defect'
import Breadcrumbs from './Breadcrumbs'
import CmInproFooter from './CmInproFooter'
import CmRejectFooter from './CmRejectFooter'
import {
  SendRequestToQcRequest,
  SendRequestToMCReq,
} from 'features/cmpj/cmchecking/types'
import { resetEvent as resetCmCheckingEvent } from 'features/cmpj/cmchecking/actions'
import { resetEvent as resetUnitEvent } from 'features/report/unit/actions'

import {
  SendRequestToQcForChecking,
  SendRequestToMcForChecking,
} from 'features/cmpj/cmchecking/api'

import { selectUploadEvent } from 'features/uploadfile/selector'
import { getQCTypeVersion } from 'utils/getText'
import { cloneDeep } from 'lodash'
import EventModal from 'Components/Shared/Modal/EventModal'
import projectHelper from 'utils/projectHelper'

const { Content } = Layout

type FormProps = {
  code: string
}

type Props = FormProps &
  RouteComponentProps<{ unit: string; qctype: string; requestID: string }> &
  ReduxProps

const CmUnitReport: React.ComponentType<Props> = props => {
  const [page, setPage] = useState(
    '' as
      | 'add.defect'
      | 'edit.defect'
      | 'add.defectDetail'
      | 'edit.defectDetail'
      | 'detail.pin'
      | 'edit.defect.from.add'
      | 'edit.defect.from.edit'
      | ''
  )
  const [enableModal, setEnableModal] = useState(false)
  const [selectedItem, setSelectedItem] = useState(undefined as any)
  const {
    report,
    token,
    ProjectCode,
    UserEmail,
    match,
    event,
    eventChecklist,
    eventRequest,
    unitDefect,
    checklist,
    history,
    uploadEvent,
  } = props
  const { params } = match
  const { unit, qctype, requestID } = params
  const dispatch = useDispatch()
  const RequestID = parseInt(requestID)
  const { Unit, QCTypeID, State, Status, CheckNumber, CheckedBy } = report
  const moduleCode = projectHelper.getModule()

  // :TODO
  const isCmInpro = State === 'CM-QC' && Status === 'INPRO'
  const isCmReject = State === 'CM-QC' && Status === 'REJT'
  const isReadOnly = State === 'QC-QC'
  const isTaskInpro = Status === 'INPRO'

  useEffect(() => {
    setEnableModal(false)
  }, [report, unitDefect, checklist, selectedItem])

  useEffect(() => {
    onResetEvent()
    onClear()
  }, [unit, qctype, requestID])

  useEffect(() => {
    callGetUnitReport()
    const reqCheckListQC: GetCheckListForQCReq = {
      UserEmail,
      ProjectCode,
      QCTypeID: qctype,
      Unit: unit,
    }
    dispatch(getUnitCheckListForQC(token, reqCheckListQC))
  }, [token, ProjectCode, UserEmail])

  const callGetUnitReport = () => {
    const params: GetUnitReportReq = {
      UserEmail,
      ProjectCode,
      QCType: qctype,
      Unit: unit,
      RequestID,
      Page: 1,
      RowPerPage: 1000,
    }
    dispatch(getUnitReport(token, params))
  }

  const onSendToQc = () => {
    setEnableModal(true)
    const request: SendRequestToQcRequest = {
      ProjectCode,
      UserEmail,
      ItemList: [
        {
          Unit,
          QCTypeID,
          RequestID,
          IsCMChecked: isCmInpro ? 'Y' : 'N',
        },
      ],
    }
    dispatch(SendRequestToQcForChecking(token, request))
  }

  const onSendToMc = () => {
    setEnableModal(true)
    const request: SendRequestToMCReq = {
      ProjectCode,
      UserEmail,
      ItemList: [
        {
          Unit,
          QCTypeID,
          RequestID,
        },
      ],
    }
    dispatch(SendRequestToMcForChecking(token, request))
  }

  const onSaveUnitDefect = () => {
    setEnableModal(true)
    const unitReq: UnitDefectType = {
      ...unitDefect,
      UserEmail,
      ProjectCode,
      Unit,
      CheckListID: checklist.CheckListID,
      RequestID,
      QCTypeID,
      CheckNumber,
      Score: Math.abs(unitDefect.Score),
      Module: moduleCode,
    }

    const req: SendUnitDefectReq = {
      Token: token,
      Pin: unitReq,
      CheckNumber,
    }

    dispatch(submitPinReq(req))
  }

  const onEditUnitDefect = () => {
    setEnableModal(true)
    const req: EditDefectReq = {
      UserEmail,
      ProjectCode,
      TaskID: Unit,
      TaskType: 'unit',
      CheckListID: checklist.CheckListID,
      CheckListType: 'unit',
      RequestID,
      QCTypeID,
      AfterImageList: [],
    }
    dispatch(submitEditDefect({ request: req, token }))
  }

  const refreshPage = () => {
    callGetUnitReport()
    onClear()
  }

  const getTitle = () => {
    let title = ''
    if (State === 'CM-QC') {
      switch (Status) {
        case 'INPRO':
          title = 'ตรวจหน้างาน'
          break
        case 'REJT':
          if (CheckedBy === 'QC') {
            title = 'รายการแก้ไขจาก QC'
          } else {
            title = 'รายการแก้ไขจากตัวเอง'
          }

          break
        default:
          break
      }
    }
    if (State === 'QC-QC') {
      switch (Status) {
        case 'INIT':
        case 'INPRO':
          title = 'QC กำลังดำเนินการ'
          break
        case 'REJT':
          title = 'รายงานแก้ไขจาก QC'
          break
        default:
          break
      }
    }

    return title
  }

  const renderFooter = () => {
    if (isTaskInpro) {
      return (
        <CmInproFooter
          project={ProjectCode}
          RequestID={RequestID}
          report={report}
          onSendReqToMC={onSendToMc}
          onSendReqToQC={onSendToQc}
          onCompleted={refreshPage}
          readonly={isReadOnly}
        />
      )
    }

    if (isCmReject) {
      return (
        <CmRejectFooter
          project={ProjectCode}
          RequestID={RequestID}
          onSubmit={onEditUnitDefect}
          currentCheckNo={CheckNumber + 1}
          isCmChecked={report.CheckedBy === 'CM'}
          report={report}
        />
      )
    }
  }

  const onAddDefect = (floor: number, pin: number, x: number, y: number) => {
    setPage('add.defectDetail')
    unitDefect.Floor = floor
    unitDefect.PinNumber = pin
    unitDefect.XAxis = x.toString()
    unitDefect.YAxis = y.toString()
    dispatch(updateUnitDefect(unitDefect))
  }

  const onEditDefect = (floor: number, pin: number, x: number, y: number) => {
    const addMode = page === 'edit.defect.from.add'
    const defectPage = addMode ? 'add.defectDetail' : 'edit.defectDetail'
    setPage(defectPage)
    unitDefect.Floor = floor
    unitDefect.PinNumber = pin
    unitDefect.XAxis = x.toString()
    unitDefect.YAxis = y.toString()
    dispatch(updateUnitDefect(unitDefect))
  }

  const onUpdateDefect = (value: any) => {
    dispatch(updateUnitDefect(value))
  }

  const onResetDefect = () => {
    dispatch(resetUnitDefect())
  }

  const onSelectedItem = (item: UnitReportItem) => {
    setSelectedItem(item)
    dispatch(mapUnitDefectData(item))
    if (isCmInpro) {
      setPage('edit.defectDetail')
    } else {
      setPage('detail.pin')
    }
  }

  const onClear = () => {
    setPage('')
    setSelectedItem(undefined)
    setEnableModal(false)
    onResetDefect()
  }

  const onResetEvent = () => {
    dispatch(resetCmCheckingEvent())
    dispatch(resetUnitEvent())
  }

  const gotoDashboard = () => {
    history.push('../../../qcdashboard')
  }

  const title = getTitle()
  const closeEnableModal = () => setEnableModal(false)
  let addMode = true
  let defectPage: any = ''
  switch (page) {
    case 'add.defect':
      return (
        <PinUnitLocation
          floorList={checklist.FloorList}
          viewMode="add"
          defectList={report.FixFromQC}
          onClose={() => setPage('')}
          onSave={onAddDefect}
        />
      )
    case 'edit.defect':
      return (
        <PinUnitLocation
          defect={unitDefect}
          viewMode="edit"
          floorList={checklist.FloorList}
          defectList={report.FixFromQC}
          onClose={() => setPage('edit.defectDetail')}
          onSave={onEditDefect}
        />
      )
    case 'edit.defect.from.add':
    case 'edit.defect.from.edit':
      addMode = page === 'edit.defect.from.add'
      defectPage = addMode ? 'add.defectDetail' : 'edit.defectDetail'
      return (
        <PinUnitLocation
          defect={unitDefect}
          viewMode="edit"
          floorList={checklist.FloorList}
          defectList={report.FixFromQC}
          onClose={() => setPage(defectPage)}
          onSave={onEditDefect}
        />
      )
    case 'add.defectDetail':
    case 'edit.defectDetail':
      addMode = page === 'add.defectDetail'
      defectPage = addMode ? 'edit.defect.from.add' : 'edit.defect.from.edit'
      return (
        <>
          <Breadcrumbs
            title={title}
            name={addMode ? 'เพิ่ม Defect' : `หมุดที่ ${unitDefect.PinNumber}`}
            onBack={onClear}
          />
          <EventModal
            name="defect"
            event={[event, eventRequest, eventChecklist]}
            err={[uploadEvent]}
            enable={enableModal}
            successCalback={refreshPage}
            errorCalback={closeEnableModal}
          />
          <AddUnitDefect
            unitDefect={unitDefect}
            checkList={checklist}
            onSave={onSaveUnitDefect}
            onDelete={onClear}
            onEditPin={() => setPage(defectPage)}
            onChange={onUpdateDefect}
            addMode={addMode}
            reportCheckNo={report.CheckNumber}
          />
        </>
      )
    case 'detail.pin':
      return (
        <>
          <Breadcrumbs
            title={title}
            name={`หมุดที่ ${selectedItem.PinNumber}`}
            onBack={onClear}
          />
          <EventModal
            event={[event, eventChecklist]}
            err={[uploadEvent]}
            enable={enableModal}
            successCalback={refreshPage}
          />

          <ReportPinDetail
            defectItem={cloneDeep(selectedItem)}
            onClear={onClear}
            unit={unit}
            qcType={getQCTypeVersion(report)}
            onUpdateAfterImg={item => {
              unitDefect.ImageAfter = item.ImageAfter
              dispatch(updateUnitDefect({ ...unitDefect }))
            }}
            onSave={onSaveUnitDefect}
          />
        </>
      )
    default:
      break
  }

  return (
    <>
      <EventModal
        event={[event, eventRequest, eventChecklist]}
        err={[uploadEvent]}
        enable={enableModal}
        successCalback={gotoDashboard}
        errorCalback={closeEnableModal}
      />
      <Breadcrumbs title={title} />
      <Content className="mid-content">
        <MainPage>
          <Report
            report={report}
            checklist={checklist}
            title={title}
            onAddDefect={() => setPage('add.defect')}
            isLoading={event.IsLoading}
            isViewMode={!isCmInpro}
            onSelected={onSelectedItem}
            showAfterImg={true}
          />
        </MainPage>
      </Content>
      {renderFooter()}
    </>
  )
}

const MainPage = styled.div`
  background-color: #f5f6fa;
`

const mapStateToProps = (state: RootState) => {
  return {
    report: selectReport(state),
    event: selectEvent(state),
    eventChecklist: selectEventChecklist(state),
    token: serviceTokenSelector(state),
    ProjectCode: selectedProjectCodeSelector(state),
    UserEmail: userEmailSelector(state),
    eventRequest: eventRequest(state),
    unitDefect: selectUnitDefect(state),
    checklist: selectCheckListQC(state),
    uploadEvent: selectUploadEvent(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {
  resetCmCheckingEvent,
  resetUnitEvent,
}

type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(CmUnitReport)
