import React from 'react'
import styled from 'styled-components'

interface Props {
  children: React.ReactNode | React.ReactChild
  hasFooter?: boolean
}

const MainPage: React.FC<Props> = props => {
  const { children, hasFooter = false } = props

  return (
    <Content style={{ paddingBottom: hasFooter ? '100px' : 0 }}>
      {children}
    </Content>
  )
}

const Content = styled.div`
  padding: 0 120px;
  background-color: #f5f6fa;
  width: 100vw;
  -khtml-user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  overflow-x: scroll;
`

export default MainPage
