import React from 'react'
import PopupMenu from 'Components/Shared/Filters/PopupMenu'
import Content from 'Components/Shared/Filters/CommonContent'
import { Filter } from 'features/admin/user/types'

type Props = {
  onFilterChange: (filter: any) => void
  filter: Filter
}

const UserStatusFilter: React.FunctionComponent<Props> = props => {
  const { onFilterChange, filter } = props

  const userStatus = [
    {
      name: 'Active',
      value: 'active',
    },
    {
      name: 'Inactive',
      value: 'inactive',
    },
  ]

  return (
    <PopupMenu
      label={'สถานะ'}
      content={
        <Content
          title="สถานะ"
          data={userStatus}
          filter={filter.StatusList}
          onChange={data => {
            filter.StatusList = data
            onFilterChange(filter)
          }}
          hasSearch={false}
        />
      }
      isActive={filter.StatusList && filter.StatusList.length > 0}
    />
  )
}

export default UserStatusFilter
