import React, { useState } from 'react'
import styled from 'styled-components'
import { SecondaryButton, PrimaryButton } from 'Components/Shared/Button'
import { Row, Col, Switch } from 'antd'
import { AuditPlanReport } from 'features/report/auditPlan/types'

import { Link } from 'react-router-dom'

import RejectFooter from './RejectFooter'

type Props = {
  report: AuditPlanReport
  isLoading?: boolean
  visible?: boolean
  onSend: () => void
}

const Footer: React.ComponentType<Props> = props => {
  const {
    report,
    isLoading = false,
    visible = false,

    onSend,
  } = props

  let btn
  if (visible && report.State === 'CM' && report.Status === 'REJT') {
    btn = <RejectFooter isLoading={isLoading} report={report} onSend={onSend} />
  }

  if (btn) {
    return (
      <FooterView>
        <Row type="flex" justify="space-between" align="middle">
          {btn}
        </Row>
      </FooterView>
    )
  }

  return <></>
}

const FooterView = styled.div`
  position: fixed;
  bottom: 0px;
  height: auto;
  width: 100%;
  background-color: white;
  padding: 22px 80px;
  z-index: 20;
`

const Button = styled.div`
  width: 200px;
  margin-left: 16px;
  float: left;
`

const IconBtn = styled.div`
  img {
    margin-right: 10px;
  }
`

export default Footer
