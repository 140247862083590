import React from 'react'
import styled from 'styled-components'
import { Input } from 'antd'
import icSearch from 'assets/img/ic-search-grey.png'

interface Props {
  searchTxt?: string
  onSearch?: (value: any) => void
}

const SearchInput: React.FC<Props> = ({
  searchTxt = '',
  onSearch = () => {},
}) => {
  return (
    <SearchArea onClick={(event: any) => event.stopPropagation()}>
      <InputSearch>
        <Input
          placeholder={'ค้นหา'}
          suffix={<img src={icSearch} />}
          onChange={(evt: any) => onSearch(evt.target.value)}
          value={searchTxt}
          autoFocus={true}
        />
      </InputSearch>
    </SearchArea>
  )
}

const SearchArea = styled.div`
  margin: 10px 0;
`

const InputSearch = styled.div`
  .ant-input-affix-wrapper {
    .ant-input {
      height: 48px;
      border-radius: 5px;
      font-size: 18px;
      font-family: 'DB Helvethaica X 55 Regular';
    }
  }

  .ant-input-affix-wrapper > .ant-input-suffix {
    img {
      width: 16px;
      height: 16px;
    }
  }
`

export default SearchInput
