import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import { AddList, EditDeleteButton } from 'Components/Shared/Button'
import { Row, Col } from 'antd'
import { Squad } from 'features/admin/team/types'
import { ConfirmDeleteModal, AddModal } from 'Components/Shared'

type Props = {
  squadList: Squad[]
  onAddSquad: (item: Squad) => void
  onUpdateSquad: (item: Squad) => void
  onDeleteSquad: (item: Squad) => void
}

const SquadBox: React.ComponentType<Props> = ({
  squadList,
  onAddSquad,
  onUpdateSquad,
  onDeleteSquad,
}) => {
  // const modalRef = useRef<CriteriaHandles>(null)
  const [dataList, setDataList] = useState<Squad[]>([])
  const [mode, setMode] = useState('' as 'edit' | 'add' | 'delete')
  const [showModal, setshowModal] = useState(false)
  const [invalid, setIsInvalid] = useState(false)
  const [item, setItem] = useState<Squad>()

  useEffect(() => {
    setDataList(squadList)
  }, [squadList])

  const onCloseModal = () => {
    setshowModal(false)
    setIsInvalid(false)
  }

  const onSave = (value: string) => {
    setshowModal(false)
    if (mode === 'add') {
      onAddSquad({
        SquadID: 0,
        SquadName: value,
        DeleteFlag: 'N',
      })
    }

    if (mode === 'edit' && item) {
      item.SquadName = value
      item.DeleteFlag = 'N'
      onUpdateSquad(item)
    }

    if (mode === 'delete' && item) {
      item.DeleteFlag = 'Y'
      onDeleteSquad(item)
    }
  }

  const onAdd = () => {
    setMode('add')
    setshowModal(true)
  }

  const onEdit = (item: Squad) => {
    setMode('edit')
    setItem(item)
    setshowModal(true)
  }

  const onDelete = (item: Squad) => {
    setMode('delete')
    setItem(item)
    setshowModal(true)
  }

  const list =
    dataList &&
    dataList.map((item: Squad, index) => {
      return (
        item.DeleteFlag !== 'Y' && (
          <Item>
            <Row type="flex" justify="space-between">
              <Col>{item.SquadName}</Col>
              <Col>
                <EditDeleteButton
                  showEditeModal={() => onEdit(item)}
                  showDeleteModal={() => onDelete(item)}
                />
              </Col>
            </Row>
          </Item>
        )
      )
    })

  const validateSquadName = (name: string) => {
    const invalidData =
      dataList &&
      dataList.find(data => {
        if (
          data.DeleteFlag === 'N' &&
          data.SquadName.toLowerCase() === name.toLowerCase()
        ) {
          return data
        }
      })

    if (invalidData) {
      setIsInvalid(true)
    } else {
      setIsInvalid(false)
    }
  }

  const isAddMode = mode === 'add'
  const isEditMode = mode === 'edit'
  const isDeleteMode = mode === 'delete'

  return (
    <>
      <Box>
        {list}
        <AddList style={{ margin: 0 }} onClick={onAdd} />
      </Box>
      <AddModal
        visible={showModal && (isAddMode || isEditMode)}
        onClose={onCloseModal}
        onSubmit={(val: string) => onSave(val)}
        title={isAddMode ? 'เพิ่ม Squad' : 'แก้ไข Squad'}
        placeholder="ระบุชื่อ Squad"
        value={(isEditMode && item && item.SquadName) || ''}
        onChange={validateSquadName}
        warningMessage={invalid ? 'ชื่อ Squad นี้มีอยู่แล้ว' : ''}
      />
      <ConfirmDeleteModal
        title="ลบรายการ"
        desc={'คุณยืนยันต้องการลบ Squad นี้'}
        onClose={onCloseModal}
        onSubmit={() => onSave('')}
        visible={showModal && isDeleteMode}
      />
    </>
  )
}

const Box = styled.div`
  height: 232px;
  border-radius: 5px;
  border: solid 1px #e4e7eb;
  width: 100%;
  padding: 0 24px;
  overflow: scroll;
`

const Item = styled.div`
  padding: 12px 0;
  font-family: 'DB Helvethaica X 55 Regular';
  font-size: 20px;
  border-bottom: 1px solid #e4e7eb;
`

export default SquadBox
