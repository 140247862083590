import React from 'react'
import styled from 'styled-components'
import {
  CheckedRejectIcon,
  UnCheckedRejectIcon,
  DisableCheckedIcon,
  DisableUncheckedIcon,
} from 'Components/Shared/Icon'

interface Props {
  label: string
  value: any
  isCheck?: boolean
  onClick?: (value: any) => void
  spaceBtwText?: number
  disabled?: boolean
  readOnly?: boolean
  size?: number
}

const RadioBoxReject: React.FC<Props> = ({
  label = '',
  value,
  isCheck = false,
  spaceBtwText = 8,
  disabled = false,
  readOnly = false,
  onClick,
  size = 20,
}) => {
  const handleClick = (evt: any) => {
    evt.stopPropagation()
    if (onClick && !readOnly) {
      onClick(value)
    }
  }

  let css
  let checkIcon

  if (disabled === false) {
    css = { cursor: 'pointer' }
  }
  checkIcon = isCheck ? (
    <CheckedRejectIcon disabled={disabled} size={size} />
  ) : (
    <UnCheckedRejectIcon disabled={disabled} size={size} />
  )

  return (
    <ItemView onClick={handleClick} style={css}>
      <Icon style={{ marginRight: spaceBtwText + 'px' }}>{checkIcon}</Icon>
      {label}
    </ItemView>
  )
}

const ItemView = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 28px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #000;

  .ant-checkbox-wrapper > .ant-checkbox > .ant-checkbox-inner {
    border-radius: 0px !important;
  }
`

const Icon = styled.div`
  margin-top: -2px;
  float: left;
`

export default RadioBoxReject
