import React from 'react'

import { Row, Col, Progress } from 'antd'
import { Link } from 'react-router-dom'
import { Task } from 'features/tasklane/tasklane.types'
import { getStatusText } from 'utils/getText'
import { getTaskStatus, getProgressColor } from 'utils/color-map'
import { textToLowerCase } from 'utils/format'

type Props = {
  task: Task
  schedule: string
  delayed: boolean
}

const SearchDataTableRow: React.FunctionComponent<Props> = ({
  task,
  schedule,
  delayed,
}) => {
  const delayClassName = delayed ? 'color-delayed' : 'color-default'
  const cardClassNames = delayed
    ? 'search-result--row card-delayed'
    : 'search-result--row'

  const status = textToLowerCase(task.TaskStatus)
  const mapping = getTaskStatus(status)
  const taskConfig = getProgressColor(
    task.TaskStatus,
    task.Progress,
    task.LastReqProgress,
    task.LastCurProgress
  )

  const css = {
    color: 'black',
  }

  return (
    <Link to={`/activity-detail/${task.ActivityID}`}>
      <Row className={cardClassNames}>
        <Col span={6} style={css}>
          <span className="activity-title">{task.ActivityName}</span>
        </Col>
        <Col span={4} style={css}>
          {task.WbsName}
        </Col>
        <Col span={2} style={css}>
          {task.BaselineStartDate}
        </Col>
        <Col span={3} style={css}>
          {task.BaselineFinishDate}
        </Col>
        <Col span={4}>
          <span className={delayClassName}>
            {delayed && (
              <img
                src="/img/ic-warning.svg"
                width={16}
                height={16}
                alt="warning icon"
              />
            )}
            {schedule}
          </span>
        </Col>
        <Col span={3} style={cssProgress}>
          <Progress
            percent={taskConfig.progress}
            successPercent={taskConfig.success}
            strokeColor={taskConfig.color}
            size="small"
            format={v => `${task.Progress}%`}
            className={`task-progress ${
              taskConfig.color ? '' : taskConfig.className
            }`}
          />
        </Col>
        <Col span={2}>
          <span style={{ color: mapping.color }}>{getStatusText(status)}</span>
        </Col>
      </Row>
    </Link>
  )
}

const cssProgress: any = {
  display: 'flex',
  alignItems: 'center',
}
export default SearchDataTableRow
