import { getType } from 'typesafe-actions'
import * as actions from './actions'
import {
  StoreState,
  DashboardAction,
  DashboardData,
  TypeOfTask,
  Filter,
} from './types'
import produce from 'immer'
import parseErrorMessage from 'utils/parseErrorMessage'
import { sortingList } from 'utils/helper'
import { cloneDeep } from 'lodash'

const initData = {
  TotalTodo: 0,
  Todo: [],
  TotalInProgress: 0,
  InProgress: [],
  TotalFixFromQC: 0,
  FixFromQC: [],
} as DashboardData

const initFilter = {
  CommissionTypeList: [],
  WbsList: [],
  LeafWbsList: [],
} as Filter

const initialState: StoreState = {
  data: initData,
  originalData: initData,
  event: {
    IsLoading: false,
    MessageSuccess: '',
    SubmitError: '',
    SubmitSuccess: false,
    SubmitType: '',
  },
  sorting: {
    todo: 'asc',
    inProgress: 'asc',
    fixFromQC: 'asc',
  },
  query: {
    date: {
      start: undefined,
      end: undefined,
    },
  },
  filter: cloneDeep(initFilter),
}

const sortingColumn = {
  todo: 'ActivityName',
  inProgress: 'AppointmentDate',
  fixFromQC: 'ActivityName',
}

export default function(
  state: StoreState = initialState,
  action: DashboardAction
): StoreState {
  return produce(state, draft => {
    switch (action.type) {
      case getType(actions.getDashbaordAction.request):
        draft.event.SubmitError = undefined
        draft.event.IsLoading = true
        draft.event.SubmitSuccess = false
        draft.data = state.data
        break
      case getType(actions.getDashbaordAction.success):
        draft.event.IsLoading = false
        const response = action.payload.ResponseData
        const sorting = state.sorting

        const todoSort = sortingList(
          response.Todo,
          sorting.todo,
          sortingColumn.todo,
          'string'
        )

        const cmInProgressSort = sortingList(
          response.InProgress,
          sorting.cmInProgress,
          sortingColumn.inProgress,
          'date'
        )

        const fixFromQCSort = sortingList(
          response.FixFromQC,
          sorting.fixFromQC,
          sortingColumn.fixFromQC,
          'string'
        )

        draft.data = {
          Todo: todoSort,
          InProgress: cmInProgressSort,
          FixFromQC: fixFromQCSort,
          TotalTodo: response.TotalTodo,
          TotalInProgress: response.TotalInProgress,
          TotalFixFromQC: response.TotalFixFromQC,
        }

        draft.originalData = {
          Todo: response.Todo,
          InProgress: response.InProgress,
          FixFromQC: response.FixFromQC,
          TotalTodo: response.TotalTodo,
          TotalInProgress: response.TotalInProgress,
          TotalFixFromQC: response.TotalFixFromQC,
        }

        break
      case getType(actions.getDashbaordAction.failure):
        draft.event.IsLoading = false
        draft.event.SubmitError = parseErrorMessage(action.payload)

        break

      case getType(actions.sortingDashboard):
        let typ: string = action.payload
        let orderBy: string = state.sorting[typ]
        orderBy = orderBy !== 'asc' ? 'asc' : 'desc'
        draft.sorting[typ] = orderBy

        if (typ === TypeOfTask.TODO) {
          const list = sortingList(
            state.data.Todo,
            orderBy,
            sortingColumn.todo,
            'string'
          )

          draft.data.Todo = list
        } else if (typ === TypeOfTask.InProgress) {
          const list = sortingList(
            state.data.InProgress,
            orderBy,
            sortingColumn.inProgress,
            'date'
          )

          draft.data.InProgress = list
        } else if (typ === TypeOfTask.FixFromQC) {
          const list = sortingList(
            state.data.FixFromQC,
            orderBy,
            sortingColumn.fixFromQC,
            'string'
          )

          draft.data.FixFromQC = list
        }
        break
      case getType(actions.updateFilter):
        draft.filter = { ...action.payload }
        break
      case getType(actions.clearFilter):
        draft.filter = cloneDeep(initFilter)
        break

      default:
        return state
    }
  })
}
