import React, { useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { RootState } from 'typings/RootState'
import { Row, Col } from 'antd'
import moment from 'moment'
import SearchDataTableRow from './SearchDataTableRow'
import SearchDataTableHeader from './SearchDataTableHeader'
import { Task } from 'features/tasklane/tasklane.types'
import getCountdownText from 'utils/getCountdownText'
import { translate } from 'i18n'
import TKeys from 'i18n/translationKey'
import SearchResultPagination from 'Components/links/SearchResultPagination'
import SearchResultPaginationShowMoreLink from 'Components/links/SearchResultPaginationShowMore'
import {
  performSearchRequest,
  sortingActivity,
} from 'features/search/search.actions'
import { getSearchSortingSelector } from 'features/search/search.selector'
import { getMSDelayedDate, getMSDelayedText } from 'utils/datetime'

import { withRouter, RouteComponentProps } from 'react-router-dom'

const DATE_FORMAT: string = 'DD/MM/YYYY'

const generateSearchDataTableRows = (tasks: Task[], type: string) => {
  return tasks.map(task => {
    const today = moment().startOf('day')
    /**
     * not sure with how many action use in this page
     * Fixed: add more condition about pass the type to check on this page
     * follow logic in main page at /see-more/type/:type/page/:page
     * and keep the logic about check from TaskStatus as well
     */

    const isCompletedTask = type === 'complete' || task.TaskStatus === 'APPV'
    const isTodo = type === 'todo' || task.TaskStatus === 'INIT'
    let baselineDate = ''

    if (task.BaselineStartDate !== '') {
      baselineDate = task.BaselineStartDate
    } else if (task.BaselineFinishDate !== '') {
      baselineDate = task.BaselineFinishDate
    } else {
      baselineDate = '0'
    }

    const useDate = isTodo ? baselineDate : task.BaselineFinishDate
    let delayed = isCompletedTask
      ? getMSDelayedDate(task)
      : moment(useDate, DATE_FORMAT).diff(today, 'days')

    let text

    if (isNaN(delayed)) {
      delayed = 0
    }
    if (delayed < 0) {
      if (isCompletedTask) {
        text = getMSDelayedText(delayed, task)
      } else {
        const days = Math.abs(delayed)
        text = isTodo
          ? `เริ่มช้ากว่ากำหนด ${days} วัน`
          : `เสร็จช้ากว่ากำหนด ${days} วัน`
      }
    } else {
      text = isTodo ? `เริ่มงานภายใน ${delayed} วัน` : `เหลืออีก ${delayed} วัน`

      if (isCompletedTask) {
        text = getMSDelayedText(delayed, task)
      } else {
        text = isTodo
          ? `เริ่มงานภายใน ${delayed} วัน`
          : `เหลืออีก ${delayed} วัน`
      }
    }

    return (
      <SearchDataTableRow
        key={task.ActivityID}
        task={task}
        schedule={text}
        delayed={delayed < 0}
      />
    )
  })
}

interface SearchResultProps {
  page: number
  items: Task[]
  totalItemCount: number
  rowPerPage: number
  keyword: string
  type: string
}

type Props = ReduxProps & SearchResultProps & RouteComponentProps

const SearchResults: React.FunctionComponent<Props> = ({
  page,
  totalItemCount,
  rowPerPage,
  items,
  keyword,
  history,
  sorting,
  type,
}) => {
  const [perPage, setPerPage] = useState(Number(rowPerPage))
  const dispatch = useDispatch()

  const getIcon = (
    name: string | undefined,
    type: string,
    colName: string
  ): string => {
    if (name === colName) {
      return type !== 'desc' ? '/img/ic-arrow-actions.png' : '/img/ic-down.png'
    }

    return '/img/ic-sort.svg'
  }

  const onSort = (name: string) => {
    dispatch(sortingActivity(name))
  }

  const headerItems = [
    {
      title: 'Activity',
      span: 6,
      onClick: () => onSort('activity_title'),
      img: getIcon(sorting.name, sorting.type, 'activity_title'),
    },
    {
      title: 'หมวดหมู่',
      span: 4,
      onClick: () => onSort('wbs'),
      img: getIcon(sorting.name, sorting.type, 'wbs'),
    },
    {
      title: 'วันเริ่มงาน',
      span: 2,
      onClick: () => onSort('actual_start_date'),
      img: getIcon(sorting.name, sorting.type, 'actual_start_date'),
    },
    {
      title: 'วันสิ้นสุดงาน',
      span: 3,
      onClick: () => onSort('actual_end_date'),
      img: getIcon(sorting.name, sorting.type, 'actual_end_date'),
    },
    {
      title: 'กำหนดการ',
      span: 4,
      onClick: () => onSort('countdown'),
      img: getIcon(sorting.name, sorting.type, 'countdown'),
    },
    {
      title: 'ความคืบหน้า',
      span: 3,
      onClick: () => onSort('progress'),
      img: getIcon(sorting.name, sorting.type, 'progress'),
    },
    {
      title: 'สถานะ',
      span: 2,
      onClick: () => onSort('status'),
      img: getIcon(sorting.name, sorting.type, 'status'),
    },
  ]

  return (
    <React.Fragment>
      <div className="searchresults-header " style={{ display: 'flex' }}>
        <h1 className="page-title" style={{ width: '50%' }}>
          <span className="result-counts">
            {totalItemCount}{' '}
            {translate(TKeys.SearchResult.search_results_label)}
          </span>
          {keyword}
        </h1>
        <SearchResultPagination
          keyword={keyword}
          currentPage={page}
          totalRows={totalItemCount}
          perPage={perPage}
        />
      </div>
      <Row className="search-reults">
        <Col span={24}>
          <SearchDataTableHeader items={headerItems} />
          {generateSearchDataTableRows(items, type)}
        </Col>
      </Row>

      <Row className="content-footer">
        <SearchResultPaginationShowMoreLink
          totalRows={totalItemCount}
          defaultValue={perPage}
          choices={[10, 25, 50, 100, 200]}
          onChange={value => {
            setPerPage(value)
            dispatch(
              performSearchRequest({
                keyword,
                page: 1, // page starts from number 1
                rowPerPage: value,
                history,
                type,
              })
            )
          }}
        />

        <SearchResultPagination
          keyword={keyword}
          currentPage={page}
          totalRows={totalItemCount}
          perPage={perPage}
        />
      </Row>
    </React.Fragment>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    sorting: getSearchSortingSelector(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

export default connect(mapStateToProps)(withRouter(SearchResults))
