import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'antd'
import { EditIcon, TrashIcon } from 'Components/Shared/Icon'

interface Props {
  showEditeModal?: () => void
  showDeleteModal?: () => void
  disableEdit?: boolean
  disableDelete?: boolean
}

const EditDeleteButton = ({
  showEditeModal,
  showDeleteModal,
  disableEdit,
  disableDelete,
}: Props) => {
  return (
    <Col className="icon-action">
      <Row type="flex">
        {showEditeModal && (
          <HoverIcon
            onClick={disableEdit ? () => null : showEditeModal}
            className="padding"
            style={disableEdit ? { cursor: 'initial' } : {}}
          >
            <EditIcon color="#8b99a7" size={16} />
          </HoverIcon>
        )}
        {showDeleteModal && (
          <HoverIcon
            onClick={disableDelete ? () => null : showDeleteModal}
            className="padding"
            style={disableDelete ? { cursor: 'initial' } : {}}
          >
            <TrashIcon color="#8b99a7" size={16} />
          </HoverIcon>
        )}
      </Row>
    </Col>
  )
}

const HoverIcon = styled.div`
  &.padding {
    padding: 0 5px;
  }
  cursor: pointer;
`

export default EditDeleteButton
