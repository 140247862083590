import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { connect, useDispatch } from 'react-redux'
import { Row, Col, Drawer } from 'antd'
import { RouteComponentProps } from 'react-router-dom'
import RootState from 'typings/RootState'

import { saveHideColumns, getColumns } from 'utils/configColumnHelper'
import { BreadcrumbLink } from 'Components/Shared/BreadCrumb/BreadCrumb'
import SubnavBar from 'Components/Shared/SubnavBar'
import Pagination from 'Components/Shared/Pagination'
import PrimaryButton from 'Components/Shared/Button/PrimaryButton'

import {
  userEmailSelector,
  serviceTokenSelector,
} from 'features/sensitive/sensitive.selector'

import { getDataList, updateData } from 'features/admin/user/api'
import { selectDataList, selectEvent } from 'features/admin/user/selector'

import {
  GetDataListReq,
  DataItem,
  UpdateDataReq,
} from 'features/admin/user/types'

import { getRoleList } from 'features/filter/api'
import { selectRoleItem } from 'features/filter/selector'
import { RoleListItem } from 'features/filter/types'

import AdminMenu from '../../../MenuAdmin'
import RowItem from './RowItem'
import ConfigColumn from './ConfigColumn'
import TableHeader from './TableHeader'
import UserDrawer from '../Drawer'
import { EventModal } from 'Components/Shared'
import { SearchInput } from 'Components/Filter'
import UserFilter from 'Components/Filter/UserFilter'
import { selectFilter } from 'features/admin/user/selector'

const breadcrumbs: BreadcrumbLink[] = [
  {
    url: '/dashboard',
    title: 'Home',
    icon: '/home.png',
    active: true,
    showTitle: false,
  },
  {
    url: '/user',
    title: 'user',
    active: false,
    showTitle: false,
  },
]

type Props = DispatchProps & RouteComponentProps & ReduxProps

const UserList: React.ComponentType<Props> = props => {
  const [hideColumn, setHideColumn] = useState([] as string[])
  const [disPlayModal, setdisPlayModal] = useState(false)
  const [selectedItem, setSelectedItem] = useState({} as any)
  const [enableModal, setEnableModal] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [pagination, setPagination] = useState<{
    Page: number
    RowPerPage: number
  }>({
    Page: 1,
    RowPerPage: 10,
  })

  const { dataList, roleList, event, token, UserEmail, filter } = props

  const dispatch = useDispatch()

  useEffect(() => {
    onGetList()
  }, [pagination])

  useEffect(() => {
    dispatch(getRoleList())
  }, [])

  const onGetList = () => {
    setEnableModal(true)
    const req: GetDataListReq = {
      UserEmail,
      Page: pagination.Page,
      RowPerPage: pagination.RowPerPage,
      TextFilter: searchText,
      ModuleList: filter.ModuleList || [],
      StatusList: filter.StatusList || [],
      TypeList: filter.TypeList || [],
    }
    dispatch(getDataList(token, req))
  }

  const onUpdateUser = (user: DataItem) => {
    setEnableModal(true)
    const req: UpdateDataReq = {
      UserEmail,
      UserID: user.UserID,
      Username: user.UserEmail,
      PositionID: user.PositionID,
      RoleID: user.RoleID,
      IsVendor: user.IsVendor,
      Status: user.Status,
      ModuleList: user.ModuleList,
    }

    dispatch(updateData(token, req))
  }

  useEffect(() => {
    const dataStorage = getColumns()
    const value = (dataStorage && dataStorage.data) || []
    setHideColumn([...value])
    onCloseDrawer()
  }, [])

  const remove = (array: string[], value: string) => {
    return array.filter(el => el !== value)
  }

  const onSettingColumn = (value: string) => {
    let newValue = hideColumn
    if (!hideColumn.includes(value)) {
      newValue.push(value)
    } else {
      newValue = remove(hideColumn, value)
    }
    setHideColumn([...newValue])
    saveHideColumns(newValue)
  }

  const getSpan = (key: string) => {
    const item = column.find(item => item.key == key)
    return (item && item.span) || 0
  }

  const onCloseDrawer = () => {
    setSelectedItem({})
    setdisPlayModal(false)
    setEnableModal(false)
  }

  const onOpenDrawer = (evt: any, item: any) => {
    setSelectedItem(item)
    setdisPlayModal(true)
  }

  const gotoAddUserPage = () => {
    props.history.push('/user/create')
  }

  const onSuccess = () => {
    if (selectedItem && selectedItem.UserID) {
      onCloseDrawer()
      onGetList()
    }
  }

  const content =
    dataList &&
    dataList.List.map((item: DataItem, index: number) => {
      return (
        <RowItem
          item={item}
          hideColumn={hideColumn}
          getSpan={key => getSpan(key)}
          key={index}
          selected={selectedItem && item.UserID === selectedItem.UserID}
          onClick={onOpenDrawer}
        />
      )
    })

  const onSearch = (val: string) => {
    if (searchText === '' && val === '') return
    setSearchText(val)
    pagination.Page = 1
    setPagination({ ...pagination })
  }

  const onFilter = () => {
    pagination.Page = 1
    setPagination({ ...pagination })
  }

  const header = (
    <Header>
      <Row type="flex" justify="space-between" align="middle">
        <Col>Users</Col>
        <Col>
          <Row type="flex" justify="space-between" align="middle">
            <Col>
              <ConfigColumn
                hideColumn={hideColumn}
                onHideColumn={value => onSettingColumn(value)}
                column={column}
              />
            </Col>
            <Col>
              <Btn>
                <PrimaryButton text="เพิ่ม User" onClick={gotoAddUserPage} />
              </Btn>
            </Col>
          </Row>
        </Col>
      </Row>
    </Header>
  )

  return (
    <>
      <SubnavBar
        breadcrumbs={breadcrumbs}
        hideSearch={true}
        adminSearchBox={<SearchInput onSearch={onSearch} />}
      />
      <EventModal
        name="user"
        event={[event, roleList.Event]}
        enable={enableModal}
        successCalback={onSuccess}
      />
      <AdminMenu selected="user">
        <MainPage>
          {header}
          <Row>
            <UserFilter withPadding={false} onSearch={onFilter} />
          </Row>
          <TableHeader
            onSort={() => {}}
            sorting={''}
            defaultColumn={column}
            hideColumn={hideColumn}
          />
          {content}
          <Pagination
            totalRows={(dataList && dataList.Total) || 0}
            perPage={pagination.RowPerPage}
            currentPage={pagination.Page}
            onChangeNumber={(page: number) => {
              pagination.RowPerPage = page
              setPagination({ ...pagination })
            }}
            onClickPage={(page: number) => {
              pagination.Page = page
              setPagination({ ...pagination })
            }}
          />
        </MainPage>
        <UserDrawer
          user={selectedItem}
          roleList={roleList}
          visible={disPlayModal && selectedItem}
          onSave={onUpdateUser}
          onClose={onCloseDrawer}
        />
      </AdminMenu>
    </>
  )
}

const column = [
  {
    key: 'name',
    title: 'ชื่อ',
    span: 8,
  },
  {
    key: 'email',
    title: 'อีเมล',
    span: 5,
  },
  {
    key: 'module',
    title: 'Role / Module ที่ใช้งาน',
    span: 5,
  },
  {
    key: 'isVendor',
    title: 'ประเภท',
    span: 3,
  },
  {
    key: 'status',
    title: 'สถานะ',
    span: 3,
  },
]

const MainPage = styled.div`
  padding: 40px 45px;
  width: 100%;
`

const Header = styled.div`
  //  margin-bottom: 47px;
  font-size: 28px;
  line-height: 1.07;
  font-family: 'DB Helvethaica X 75 Bd';
  color: #000;
`

const Btn = styled.div`
  width: 138px;
`

const mapStateToProps = (state: RootState) => {
  return {
    dataList: selectDataList(state),
    roleList: selectRoleItem(state),
    event: selectEvent(state),
    token: serviceTokenSelector(state),
    UserEmail: userEmailSelector(state),
    filter: selectFilter(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(UserList)
