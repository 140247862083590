import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { RootState } from 'typings/RootState'
import styled from 'styled-components'
import { getProjectType, getQCTypeList } from 'features/filter/api'
import { GetProjectTypeReq, GetQCTypeListReq } from 'features/filter/types'
import { selectProjectType, selectQCTypeList } from 'features/filter/selector'
import { Col, Row } from 'antd'
import { selectedProjectCodeSelector } from 'features/project/project.selector'
import {
  serviceTokenSelector,
  userEmailSelector,
} from 'features/sensitive/sensitive.selector'
import { selectFilter } from 'features/adminReport/sqdbymaincon/selector'
import { Filter } from 'features/adminReport/sqdbymaincon/types'
import {
  updateFilter,
  clearFilter,
} from 'features/adminReport/sqdbymaincon/actions'
import { ProjectTypeFilter, DateFilter, ButtonFilter } from './Components'
import { CompanyVandorItem } from 'features/admin/user/types'
import UserBox from 'Components/Admin/Position/Detail/UserBox'
import { DropdownList } from 'Components/Shared/Form'

type Props = {
  onSearch?: (isClear?: boolean) => void
  withPadding?: boolean
  onExport?: () => void
  exportData?: any[]
  isDownLoading?: boolean
} & ReduxProps &
  DispatchProps

const SQDByMainconFilter: React.FunctionComponent<Props> = props => {
  const {
    ProjectCode,
    UserEmail,
    token,
    filter,
    onSearch = () => {},
    withPadding = false,
    projectType,
    onExport,
    exportData,
    isDownLoading,
    qctypeList,
  } = props

  const [clearVendor, setClearVendor] = useState(false)

  useEffect(() => {
    dispatch(clearFilter())
    onGetProjectType()
    onGetQCTypeList()
    return () => {
      dispatch(clearFilter())
    }
  }, [ProjectCode, UserEmail])

  const onGetProjectType = () => {
    const req: GetProjectTypeReq = {
      UserEmail,
      Page: 1,
      RowPerPage: 100,
    }
    dispatch(getProjectType(token, req))
  }

  const onGetQCTypeList = () => {
    const req: GetQCTypeListReq = {
      UserEmail,
      UnitType: 'Unit',
      Status: 'Active',
    }
    dispatch(getQCTypeList(token, req))
  }

  const dispatch = useDispatch()
  const onFilterChange = (filter: Filter) => {
    setClearVendor(false)
    dispatch(updateFilter(filter))
  }

  const onClearSearch = () => {
    dispatch(clearFilter())
    setClearVendor(true)
    onSearch(true)
  }

  const isFilter = () => {
    return filter.Date.Mode !== '' || filter.VendorCode !== ''
  }

  const renderQcType = () => {
    return qctypeList.List.map(item => {
      return {
        name: item.QCTypeName,
        value: item.QCTypeID,
      }
    })
  }

  return (
    <MainPage className={withPadding ? 'with-padding' : ''}>
      <Row type="flex" justify="start">
        <Col span={8}>
          <ProjectTypeFilter
            filter={filter}
            onFilterChange={onFilterChange}
            projectType={projectType}
            canSelectAll={false}
          />
        </Col>
        <Col span={8}>
          <Box>
            <UserBox
              placeholder="เลือกผู้รับเหมา"
              onSave={(user: CompanyVandorItem) => {
                filter.VendorCode = user.VendorCode
                onFilterChange(filter)
              }}
              clearValue={clearVendor}
            />
          </Box>
        </Col>
        <Col span={8}>
          <Box>
            <DropdownList
              placeholder="เลือกหมวดงาน"
              label="หมวดงาน"
              value={filter.QCTypeID}
              menu={renderQcType()}
              onChange={(val: any) => {
                filter.QCTypeID = val
                onFilterChange(filter)
              }}
            />
          </Box>
        </Col>
        <Col span={8}>
          <DateFilter filter={filter} onFilterChange={onFilterChange} />
        </Col>
        <Col span={10}>
          <ButtonFilter
            onClearSearch={onClearSearch}
            onSearch={onSearch}
            isFilter={isFilter()}
            onExport={onExport}
            isDownLoading={isDownLoading}
            exportData={exportData}
            fileName="รายงาน SQD ตามผู้รับเหมา"
          />
        </Col>
      </Row>
    </MainPage>
  )
}

const MainPage = styled.div`
  margin-bottom: 24px;
  &.with-padding {
    padding: 0 120px;
  }

  background-color: #f5f6fa;
`

const Box = styled.div`
  padding: 0 12px 12px 0;
`

const mapStateToProps = (state: RootState) => {
  return {
    ProjectCode: selectedProjectCodeSelector(state),
    UserEmail: userEmailSelector(state),
    token: serviceTokenSelector(state),
    filter: selectFilter(state),
    projectType: selectProjectType(state),
    qctypeList: selectQCTypeList(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(SQDByMainconFilter)
