import withAPIAction from 'utils/withAPIAction'
import {
  getDataListAction,
  createDataAction,
  updateDataAction,
} from './actions'

import { GetDataListReq, CreateDataReq, UpdateDataReq } from './types'

import { verifyReqNoProject } from 'utils/verify'

export const getDataList = (token: string, data: GetDataListReq) => {
  const isValid = verifyReqNoProject(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<GetDataListReq>({
    method: 'POST',
    path: 'GetQCTypeList',
    data,
    token: `Bearer ${token}`,
  })(getDataListAction)
}

export const createData = (token: string, data: CreateDataReq) => {
  const isValid = verifyReqNoProject(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<CreateDataReq>({
    method: 'POST',
    path: 'CreateQCType',
    data,
    token: `Bearer ${token}`,
  })(createDataAction)
}

export const updateData = (token: string, data: UpdateDataReq) => {
  const isValid = verifyReqNoProject(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<UpdateDataReq>({
    method: 'POST',
    path: 'UpdateQCType',
    data,
    token: `Bearer ${token}`,
  })(updateDataAction)
}
