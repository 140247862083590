import RootState from 'typings/RootState'
import { createSelector } from 'reselect'
import { UnitReport } from './types'
import { Event } from '../../types/event'

export const selectReport = (state: RootState): UnitReport =>
  state.reportUnit.report

export const selectEvent = (state: RootState): Event => state.reportUnit.event

export const selectEventChecklist = (state: RootState): Event =>
  state.reportUnit.eventChecklist

export const selectUnitDefect = (state: RootState) =>
  state.reportUnit.unitDefect

export const selectCheckListQC = (state: RootState) =>
  state.reportUnit.checkListQC
