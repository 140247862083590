import RootState from '../../../typings/RootState'
import { QCType } from './types'
import { Event } from '../../types/event'

export const selectQCTypeList = (state: RootState): QCType[] =>
  state.unitChecklist.QCTypeList

export const selectEvent = (state: RootState): Event =>
  state.unitChecklist.Event

export const selectCheckListEvent = (state: RootState): Event =>
  state.unitChecklist.CheckListEvent

export const selectUnitEvent = (state: RootState): Event =>
  state.unitChecklist.UnitEvent
