import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { connect, useDispatch } from 'react-redux'
import { RootState } from 'typings/RootState'
import { RouteComponentProps } from 'react-router-dom'

import { BreadcrumbLink } from 'Components/Shared/BreadCrumb/BreadCrumb'
import SubnavBar from 'Components/Shared/SubnavBar'
import EventModal from 'Components/Shared/Modal/EventModal'
import { AlertError } from 'Components/Shared'

import {
  serviceTokenSelector,
  userEmailSelector,
} from 'features/sensitive/sensitive.selector'

import { getProjectType } from 'features/masterData/api'
import { selectProjectType } from 'features/masterData/selector'

import {
  CreateCheckListReq,
  UpdateCheckListReq,
  CheckListDetail,
} from 'features/checklist/auditRoad/types'

import {
  getCheckListById,
  createCheckList,
  updateCheckList,
  deleteCheckList,
  getProjectSelector,
} from 'features/checklist/auditRoad/api'

import {
  clearCheckListDetail,
  updateCheckListDetail,
} from 'features/checklist/auditRoad/actions'

import { validateJobType } from 'utils/validator'

import Menu from '../../../MenuAdmin'
import QCSetting from './Setting'
import QCList from './List'

const editModeBreadcrumbs: BreadcrumbLink[] = [
  {
    url: '/dashboard',
    title: 'Home',
    icon: '/home.png',
    active: true,
    showTitle: false,
  },
  {
    url: '/check-audit-road',
    title: 'ตรวจรับมอบถนนและก่อนหมดประกัน',
    active: true,
    showTitle: true,
  },
  {
    url: '',
    title: 'แก้ไข Checklist ตรวจรับมอบถนนและก่อนหมดประกัน',
    active: false,
    showTitle: true,
  },
]

type Props = RouteComponentProps<{ id: string }> & ReduxProps & DispatchProps

const CheckSiteDetail: React.ComponentType<Props> = props => {
  const { UserEmail, checkListDetail, event, projectEvent, projects } = props
  const CheckListID = props.match.params.id
  const isEditing = props.checkListDetail.CheckListID !== 0

  const [enableModal, setEnableModal] = useState(false)
  const [enableModalValidate, setEnableModalValidate] = useState(false)
  const [errorValidate, setErrorValidate] = useState('')
  const [tab, setTab] = useState({ list: '', setting: 'active' })

  const dispatch = useDispatch()

  useEffect(() => {
    if (CheckListID) {
      props.getCheckListById(parseInt(CheckListID, 10))
    }
    props.getProjectType({ Page: 1, RowPerPage: 200 })
    props.getProjectSelector()
    return () => {
      props.clearCheckListDetail()
    }
  }, [])

  const setTabToFirst = () => setTab({ list: '', setting: 'active' })
  const setTabToSecond = () => setTab({ list: 'active', setting: '' })
  const updateCheckList = (val: CheckListDetail) => {
    dispatch(updateCheckListDetail({ ...val }))
  }

  const onSave = () => {
    const errorMsg = validateJobType(checkListDetail)
    if (errorMsg === '' && enableModalValidate === false) {
      setEnableModalValidate(false)
      setEnableModal(true)
      const ProjectList = checkListDetail.ProjectList.filter(p => p.Selected)

      if (checkListDetail.CheckListID === 0) {
        const req: CreateCheckListReq = {
          ...checkListDetail,
          ProjectList,
          UserEmail,
        }
        props.createCheckList(req)
      } else {
        const req: UpdateCheckListReq = {
          ...checkListDetail,
          ProjectList,
          UserEmail,
        }
        props.updateCheckList(req)
      }
    } else {
      setEnableModalValidate(true)
      setErrorValidate(errorMsg)
    }
  }

  const onDelete = () => {
    setEnableModal(true)
    props.deleteCheckList(checkListDetail.CheckListID)
  }

  const duplicateCheckList = () => {
    checkListDetail.CheckListID = 0
    checkListDetail.CheckListName = ''
    checkListDetail.Status = 'Ready'
    checkListDetail.ProjectList = []
    dispatch(updateCheckListDetail({ ...checkListDetail }))
    props.history.push('/check-audit-road/create')
  }

  const callBackErrorModal = () => {
    setEnableModalValidate(false)
    setErrorValidate('')
  }

  const gotoDashboard = () => {
    setEnableModal(false)
    props.history.push('/check-audit-road')
  }

  const closeEnableModal = () => setEnableModal(false)

  return (
    <>
      <SubnavBar breadcrumbs={editModeBreadcrumbs} hideSearch={true} />
      <EventModal
        event={[event, projectEvent]}
        enable={enableModal}
        successCalback={gotoDashboard}
        errorCalback={closeEnableModal}
      />
      <AlertError
        text={errorValidate}
        isShow={enableModalValidate}
        callback={callBackErrorModal}
      />
      <Menu selected="check-audit-road">
        <MainPage>
          <Title>
            {isEditing ? 'แก้ไข' : 'สร้าง'} Checklist ตรวจรับมอบถนนก่อนหมดประกัน
          </Title>
          <Tab>
            <TabItem className={tab.setting} onClick={setTabToFirst}>
              ตั้งค่า
            </TabItem>
            <TabItem className={tab.list} onClick={setTabToSecond}>
              รายการ
            </TabItem>
          </Tab>
          <Content>
            <QCSetting
              isActive={tab.setting === 'active'}
              isEditing={isEditing}
              projects={projects}
              projectType={props.projectType}
              checkListDetail={checkListDetail}
              updateCheckList={updateCheckList}
              onSave={onSave}
              onDelete={onDelete}
              duplicateCheckList={duplicateCheckList}
            />
            <QCList
              isActive={tab.list === 'active'}
              checkListDetail={checkListDetail}
              updateCheckList={updateCheckList}
              onSave={onSave}
            />
          </Content>
        </MainPage>
      </Menu>
    </>
  )
}

const MainPage = styled.div`
  padding: 40px 45px;
  width: 100%;
`

const Title = styled.h2`
  font-size: 28px;
  font-family: 'DB Helvethaica X 75 Bd';
`

const Tab = styled.div`
  height: 28px;
  width: 100%;
  border-bottom: 1px solid #e8ebf0;
`

const TabItem = styled.div`
  font-size: 18px;
  float: left;
  margin-right: 24px;
  color: #8b99a7;
  cursor: pointer;
  &.active {
    color: #000000;
    border-bottom: 2px solid #003168;
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.03);
  }
`

const Content = styled.div`
  margin-top: 40px;
`

const mapStateToProps = (state: RootState) => {
  return {
    UserEmail: userEmailSelector(state),
    Token: serviceTokenSelector(state),

    projectType: selectProjectType(state),
    event: state.auditRoadChecklist.Event,
    projectEvent: state.auditRoadChecklist.ProjectEvent,
    checkListDetail: state.auditRoadChecklist.CheckListDetail,
    projects: state.auditRoadChecklist.ProjectList,
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>
const mapDispatchToProps = {
  getProjectType,

  getCheckListById,
  createCheckList,
  updateCheckList,
  deleteCheckList,
  updateCheckListDetail,
  getProjectSelector,
  clearCheckListDetail,
}

type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(CheckSiteDetail)
