import React from 'react'
import PopupMenu from 'Components/Shared/Filters/PopupMenu'
import Content from 'Components/Shared/Filters/CommonContent'
import { modulesConfig } from 'utils/module-map'
import { Filter } from 'features/admin/user/types'

type Props = {
  onFilterChange: (filter: any) => void
  filter: Filter
}

const ModuleFilter: React.FunctionComponent<Props> = props => {
  const { onFilterChange, filter } = props

  return (
    <PopupMenu
      label={'Role/Module'}
      content={
        <Content
          title="Role/Module"
          data={modulesConfig}
          filter={filter.ModuleList}
          onChange={data => {
            filter.ModuleList = data
            onFilterChange(filter)
          }}
          hasSearch={false}
        />
      }
      isActive={filter.ModuleList && filter.ModuleList.length > 0}
    />
  )
}

export default ModuleFilter
