import React from 'react'
import { Row, Col } from 'antd'
import styled from 'styled-components'
import { ProjectData, QCList } from 'features/qc/projects/types'
import projectImg from 'assets/img/project-default.png'
import { getQCTypeVersion } from 'utils/getText'

type Props = {
  project: ProjectData
  type?: string
}

const ProjectItem: React.FunctionComponent<Props> = props => {
  const { project, type = 'unit' } = props

  const renderTitle = (data: any) => {
    switch (type) {
      case 'unit':
        return getQCTypeVersion(data)
      case 'zone':
        return `ตรวจครั้งที่ ${data.CheckNumber}`
      case 'commissioning':
        return `${data.CommissioningType}(${data.CheckNumber})`
      default:
        break
    }
  }

  const renderQcType = (qcList: QCList[]) => {
    return qcList.map(qc => {
      const title = renderTitle(qc)
      return (
        <QcType>
          {title} - {qc.NumberOfTask} รายการ
        </QcType>
      )
    })
  }

  return (
    <Project>
      <Row type="flex" align="middle">
        <Col>
          <Image>
            <img src={projectImg} />
          </Image>
        </Col>
        <Col>
          <TitleSecond>
            {`${project.ProjectCode} - ${project.ProjectNameTH}`}
            {renderQcType(project.QCList)}
          </TitleSecond>
        </Col>
      </Row>
      <Line />
    </Project>
  )
}

const TitleSecond = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 24px;
  color: #000;
`

const Image = styled.div`
  img {
    width: 60px;
    height: 60px;
  }
  margin-right: 24px;
`

const Project = styled.div`
  padding-top: 20px;
  cursor: pointer;
`

const Line = styled.div`
  margin-left: 100px;
  border-bottom: 1px solid #8b99a736;
  margin-top: 20px;
`

const QcType = styled.div`
  font-family: 'DB Helvethaica X 55 Regular';
  font-size: 18px;
  color: #8b99a7;
  line-height: 1.2;
`
export default ProjectItem
