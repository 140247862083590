import React from 'react'
import styled from 'styled-components'
import { Popover } from 'antd'

import img from 'assets/img/ic-line-size.png'

type Props = {
  value: number
  onChange: (val: number) => void
}

const SizeList = [
  { size: 3, width: 6 },
  { size: 4, width: 9 },
  { size: 5, width: 12 },
  { size: 6, width: 15 },
  { size: 7, width: 18 },
]

const LineSizeToolMenu: React.ComponentType<Props> = props => {
  const list = SizeList.map((item, index) => {
    const size = `${item.width}px`
    const css = {
      width: size,
      height: size,
      backgroundColor: props.value === item.size ? '#8b99a7' : undefined,
    }

    return (
      <Item key={index} style={css} onClick={() => props.onChange(item.size)} />
    )
  })
  const content = <PageMenu>{list}</PageMenu>
  return (
    <Popover overlayClassName="tools-popup-menu" content={content}>
      <BtnItem src={img} alt="" />
    </Popover>
  )
}

const PageMenu = styled.div`
  padding: 21px 24px;
  height: 80px;
  background-color: #4e4f50;
  border-radius: 5px;
  display: flex;
  align-items: center;

  span {
    margin-left: 16px;
  }

  span:first-child {
    margin-left: 0px;
  }
`

const Item = styled.span`
  background-color: white;
  border-radius: 50%;

  :hover {
    cursor: pointer;
  }
`

const BtnItem = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 50%;

  :hover {
    cursor: pointer;
  }
`

export default LineSizeToolMenu
