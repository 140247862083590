import withAPIAction from 'utils/withAPIAction'
import {
  getReportListAction,
  getReportDetailAction,
  exportReportListAction,
} from './actions'
import { GetReportListReq, GetReportDetailReq } from './types'
import { verifyReq, verifyReqNoProject } from 'utils/verify'

export const getReportList = (token: string, data: GetReportListReq) => {
  const isValid = verifyReqNoProject(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<GetReportListReq>(state => ({
    method: 'POST',
    path: 'GetAuditRoadReportList',
    data,
    token: `Bearer ${token}`,
  }))(getReportListAction)
}

export const getReportDetail = (token: string, data: GetReportDetailReq) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<GetReportDetailReq>(state => ({
    method: 'POST',
    path: 'GetAuditRoadReport',
    data,
    token: `Bearer ${token}`,
  }))(getReportDetailAction)
}

export const exportReportList = (token: string, data: GetReportListReq) => {
  const isValid = verifyReqNoProject(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<GetReportListReq>(state => ({
    method: 'POST',
    path: 'GetAuditRoadReportList',
    data,
    token: `Bearer ${token}`,
  }))(exportReportListAction)
}
