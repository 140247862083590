import { createStandardAction, createAsyncAction } from 'typesafe-actions'
import {
  StartCheckingZoneReq,
  SendZoneToMcReq,
  SendZoneToQcReq,
  SendZoneQCEditReq,
  CommonResp,
  FetchActivityOnZoneReq,
  GetActivityZoneRes,
} from './types'

export const getActivityOnZoneAction = createAsyncAction(
  'cmpj/zone/checking/GET_ACTIVITY_REQUEST',
  'cmpj/zone/checking/GET_ACTIVITY_RESPONSE',
  'cmpj/zone/checking/GET_ACTIVITY_FAILURE'
)<FetchActivityOnZoneReq, GetActivityZoneRes, any>()

export const startCheckingAction = createAsyncAction(
  'cmpj/zone/report/START_CHECKING_REQUEST',
  'cmpj/zone/report/START_CHECKING_RESPONSE',
  'cmpj/zone/report/START_CHECKING_FAILURE'
)<StartCheckingZoneReq, CommonResp, any>()

export const sendToQcAction = createAsyncAction(
  'cmpj/zone/report/REQUEST_QC_REQUEST',
  'cmpj/zone/report/REQUEST_QC_RESPONSE',
  'cmpj/zone/report/REQUEST_QC_FAILURE'
)<SendZoneToQcReq, CommonResp, any>()

export const sendToMcAction = createAsyncAction(
  'cmpj/zone/report/REQUEST_MC_REQUEST',
  'cmpj/zone/report/REQUEST_MC_RESPONSE',
  'cmpj/zone/report/REQUEST_MC_FAILURE'
)<SendZoneToMcReq, CommonResp, any>()

export const sendQCEditAction = createAsyncAction(
  'cmpj/zone/report/REQUEST_EDIT_REQUEST',
  'cmpj/zone/report/REQUEST_EDIT_RESPONSE',
  'cmpj/zone/report/REQUEST_EDIT_FAILURE'
)<SendZoneQCEditReq, CommonResp, any>()
