import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'
import { InputSearch } from 'Components/Shared/Input'
import { GetSOWList } from 'features/admin/position/api'
import { GetFilterListReq } from 'features/admin/position/types'
import {
  selectSowList,
  selectEvent,
  selectSowEvent,
} from 'features/admin/position/selector'
import { connect, useDispatch } from 'react-redux'
import RootState from 'typings/RootState'
import {
  serviceTokenSelector,
  userEmailSelector,
} from 'features/sensitive/sensitive.selector'
import { LoadingIcon } from 'Components/Shared/Icon'

type Props = {
  onSave: (sowCode: string) => void
} & ReduxProps

const SOWBox: React.ComponentType<Props> = ({
  onSave,
  token,
  UserEmail,
  sowList,
  event,
}) => {
  const [searchText, setSearchText] = useState('')
  const [query, setQuery] = useState('')

  useEffect(() => {
    setSearchText('')
  }, [])

  useEffect(() => {
    onFindSOW()
  }, [searchText])

  useEffect(() => {
    const timeOutId = setTimeout(() => setSearchText(query), 1000)
    return () => clearTimeout(timeOutId)
  }, [query])

  const dispatch = useDispatch()

  const onFindSOW = () => {
    const req: GetFilterListReq = {
      UserEmail,
      TextFilter: searchText,
    }
    dispatch(GetSOWList(token, req))
  }

  const renderSearchContent = (data: any[]) => {
    const loading = (
      <ContentBody>
        <LoadingIcon />
      </ContentBody>
    )
    const empty = (
      <ContentBody>
        <Txt>ไม่มีข้อมูล</Txt>
      </ContentBody>
    )

    const emptySearch = (
      <ContentBody>
        <Txt>ระบุคำที่ต้องการค้นหา อย่างน้อย 2 ตัวอักษร</Txt>
      </ContentBody>
    )

    const content =
      data.length > 0 ? (
        <SearchContentArea>
          {data.map((item, index) => {
            return (
              <SearchItem key={index} onClick={() => onSave(item.SOWCode)}>
                {item.SOWCode}
              </SearchItem>
            )
          })}
        </SearchContentArea>
      ) : query.length < 2 ? (
        emptySearch
      ) : (
        empty
      )

    return event.IsLoading ? loading : content
  }

  return (
    <InputSearch
      placeholder="ค้นหา SOW Code"
      value={query}
      onChange={(val: string) => setQuery(val)}
      showSuggess={true}
      searchContent={renderSearchContent((sowList && sowList.List) || [])}
    />
  )
}

const SearchContentArea = styled.div`
  max-height: 192px;
  overflow: scroll;
`

const SearchItem = styled.div`
  padding: 8px 24px;
  font-size: 20px;
  font-family: 'DB Helvethaica X 55 Regular';
  &:hover {
    background-color: #e6f7ff;
    cursor: pointer;
  }
`

const ContentBody = styled.div`
  width: 100%;
  text-align: center;
  padding: 70px 0;
  height: 192px;
`

const Txt = styled.div`
  font-size: 20px;
  font-family: 'DB Helvethaica X 55 Regular';
`

const mapStateToProps = (state: RootState) => {
  return {
    token: serviceTokenSelector(state),
    UserEmail: userEmailSelector(state),
    sowList: selectSowList(state),
    event: selectSowEvent(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(SOWBox)
