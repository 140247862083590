import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Row, Col } from 'antd'
import RadioBox from 'Components/Shared/Input/RadioBox'
import { SelectedFloorPlan, Mapping } from 'features/admin/floorPlan/types'

type Props = {
  item: SelectedFloorPlan
  mappingList: Mapping[]
}

function findFloor(item: SelectedFloorPlan, it: Mapping) {
  return item.FloorList.findIndex(f => f.Floor === it.Floor)
}

function calculateInit(mappingList: Mapping[], item: SelectedFloorPlan) {
  let totalSelected = 0
  const isCheckAll = false
  mappingList.forEach((it: Mapping) => {
    let isSelected = false
    let detail = ''
    let isReadOnly = false
    if (it.Status && it.Status.toLocaleLowerCase() === 'used') {
      isSelected = false
      isReadOnly = true
      detail =
        it.FloorPlanID === item.FloorPlanID
          ? 'ใช้แล้ว'
          : 'ใช้แล้วกับ Floorplan อื่น'
    } else if (item.FloorPlanID !== 0 && item.FloorPlanID === it.FloorPlanID) {
      const index = findFloor(item, it)
      if (index > -1) {
        if (item.FloorList[index].Selected === 'Y') {
          isSelected = true
          totalSelected++
        } else {
          isSelected = false
        }
      } else {
        isSelected = true
        totalSelected++
      }
    } else if (it.FloorPlanID) {
      isReadOnly = true
      isSelected = true
      detail = 'ผูกแล้วกับ Floorplan อื่น'
    } else if (it.FloorPlanID === 0) {
      const index = findFloor(item, it)
      if (index > -1) {
        if (item.FloorList[index].Selected === 'Y') {
          isSelected = true
          totalSelected++
        }
      }
    }
    it.isSelected = isSelected
    it.detail = detail
    it.isReadOnly = isReadOnly
  })
  return { total: totalSelected, all: isCheckAll }
}

const MappingUnit: React.ComponentType<Props> = props => {
  const { item, mappingList } = props
  const [totalSelect, settotalSelect] = useState(0)
  const [isCheckedAll, setisCheckedAll] = useState(false)
  const [, setState] = useState(0)
  useEffect(() => {
    const { total, all } = calculateInit(mappingList, item)
    settotalSelect(total)
    setisCheckedAll(all)
    setState(t => (t += 1))
  }, [mappingList, item])

  const onCheck = (it: Mapping, { val }: { val: boolean }) => {
    it.isSelected = val
    const index = findFloor(item, it)
    if (index > -1) {
      item.FloorList.splice(index, 1)
      if (val === false) {
        settotalSelect(pre => --pre)
      }
    } else {
      item.FloorList.push({
        Floor: it.Floor,
        Selected: val ? 'Y' : 'N',
      })
      if (val === true) {
        settotalSelect(pre => ++pre)
      }
    }
    setState(t => (t += 1))
  }

  const onCheckAll = (val: boolean) => {
    let count = 0
    mappingList.forEach((it: Mapping) => {
      if (it.isReadOnly === false) {
        if (val === true) {
          ++count
        }
        if (it.isSelected !== val) {
          onCheck(it, { val: val })
        }
      }
    })
    settotalSelect(count)
    setisCheckedAll(val)
  }

  const content = mappingList.map((it: Mapping, index) => {
    return (
      <Item key={index}>
        <RadioBox
          label={it.Floor}
          text={it.detail}
          isSelected={it.isSelected}
          readOnly={it.isReadOnly}
          value={it}
          onClick={onCheck}
          tag={{ val: !it.isSelected }}
        />
      </Item>
    )
  })

  return (
    <Row>
      <Col span={24}>
        <BodyArea>
          <Title>รายการที่ต้องการผูก</Title>
          <span>คุณกำลังเลือก {totalSelect} รายการ</span>
          <ListSection>
            <RadioBox
              label={`เลือกชั้นทั้งหมด (${totalSelect})`}
              isSelected={isCheckedAll}
              value={!isCheckedAll}
              onClick={onCheckAll}
            />
            <ContentArea>{content}</ContentArea>
          </ListSection>
        </BodyArea>
      </Col>
    </Row>
  )
}

const ContentArea = styled.div`
  height: 224px;
  border-radius: 5px;
  border: solid 1px #eeeff2;
  margin-top: 15px;
  overflow-y: scroll;
`
const BodyArea = styled.div`
  margin-top: 24px;
  padding: 0px 24px;
  font-family: 'DB Helvethaica X 55 Regular';
  span {
    font-size: 20px;
  }
`

const Title = styled.div`
  color: black;
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 28px;
`
const ListSection = styled.div`
  margin-top: 12px;
`

const Item = styled.div`
  border: solid 0px #eeeff2;
  border-bottom-width: 1px;
  height: 56px;
  align-items: center;
  display: flex;
  width: 90%;
  margin: 0 auto;
  span {
    width: 265px;
  }
`

export default MappingUnit
