import React from 'react'
import { BreadcrumbLink } from 'Components/Shared/BreadCrumb/BreadCrumb'
import SubnavBar from 'Components/Shared/SubnavBar'

type Props = {}

const Breadcrumbs: React.ComponentType<Props> = props => {
  const breadcrumbs: BreadcrumbLink[] = [
    {
      url: '/report/commissioning',
      title: 'Home',
      icon: '/home.png',
      active: true,
      showTitle: false,
    },
    {
      url: '/report/commissioning',
      title: 'ตรวจรายงานส่วนกลาง',
      active: true,
      showTitle: true,
    },
    {
      url: '/report/commissioning',
      title: 'รายงานตรวจส่วนกลาง - Commissioning',
      active: false,
      showTitle: false,
    },
  ]

  return <SubnavBar breadcrumbs={breadcrumbs} hideSearch={true} />
}

export default Breadcrumbs
