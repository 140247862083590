import React, { useState, useLayoutEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import RootState from 'typings/RootState'
import { Modal } from 'antd'
import styled from 'styled-components'

import { saveAs } from 'file-saver'
import file from 'utils/file'
import {
  getOriginalActivity,
  getCurrentActivity,
} from 'features/activity/activity.selector'
import { updateOriginalActivity } from 'features/activity/activity.actions'

import TopMenu from './TopMenu'
import Editor from '../Editor'
import { NextBtn, PreviousBtn } from '../../../Shared/Button'

type FormProps = {
  index: number
  readOnly: boolean
  images?: any[]
  onClose: () => void
}

type Props = ReduxProps & DispatchProps & FormProps

const ThumbnailDisplay: React.ComponentType<Props> = props => {
  const dispatch = useDispatch()

  const [showEditor, setShowEditor] = useState(false)
  const [selected, setSelected] = useState(0)
  const [imageList, setImageList] = useState([] as any[])
  const { readOnly, images, original, onClose } = props

  useLayoutEffect(() => {
    if (readOnly) {
      setImageList(props.images || [])
    } else {
      setImageList(original.Images)
    }

    setSelected(props.index)
  }, [props.index, images, original])

  const onBack = () => {
    onClose()
  }

  const onPrevious = () => {
    const previous = selected - 1
    if (previous >= 0) {
      setSelected(previous)
    }
  }

  const onNext = () => {
    const next = selected + 1
    if (next < imageList.length) {
      setSelected(next)
    }
  }

  const onDelete = () => {
    imageList.splice(selected, 1)
    setImageList([...imageList])
    setSelected(0)
    original.Images = imageList
    dispatch(updateOriginalActivity({ ...original }))
  }

  const onDownload = async () => {
    const item = imageList[selected]

    if (readOnly) {
      const img = await file.urlToBase64({ url: item.FileURL })
      saveAs(img, item.FileName)
    } else {
      saveAs(item.file, item.file.name)
    }
  }

  const onUpdateImage = async (base64: string) => {
    const oldFile = original.Images[selected]
    const params = {
      data: base64,
      filename: oldFile.file.name,
      lastModified: oldFile.lastModified,
    }
    const fileItem: any = await file.base64ToFile(params)
    original.Images[selected] = {
      text: base64,
      file: fileItem,
      lastModified: fileItem.lastModified,
    }
    dispatch(props.updateOriginalActivity({ ...original }))
    setShowEditor(false)
  }

  const content = imageList.map((item, index) => {
    const base64 = item.FileURL ? item.FileURL : item.text
    const css = { display: selected === index ? undefined : 'none' }
    return (
      <Slider style={css} key={index}>
        <img src={base64} style={{ width: '100%' }} />
      </Slider>
    )
  })

  const menus = imageList.map((item, index) => {
    const base64 = item.FileURL ? item.FileURL : item.text
    return selected === index ? (
      <MenuActiveItem src={base64} key={index} />
    ) : (
      <MenuItem src={base64} key={index} onClick={() => setSelected(index)} />
    )
  })

  if (showEditor) {
    const img = imageList[selected]
    const base64 = img.FileURL ? img.FileURL : img.text
    return (
      <Editor
        visible={true}
        image={base64}
        onClose={() => setShowEditor(false)}
        onOk={onUpdateImage}
      />
    )
  } else {
    return (
      <Modal
        title=""
        footer=""
        visible={true}
        className="editor-view"
        onOk={onClose}
        onCancel={onClose}
        centered={false}
        closable={false}
        width="100%"
      >
        <PageView>
          <TopMenu
            readOnly={readOnly}
            onBack={onBack}
            onDelete={onDelete}
            onDownload={onDownload}
            onEdit={() => setShowEditor(true)}
          />
          <PageArea>
            <ArrowPreviousBtn>
              <PreviousBtn onClick={onPrevious} />
            </ArrowPreviousBtn>

            <SlideShow>{content}</SlideShow>
            <MenuList>{menus}</MenuList>

            <ArrowNextBtn>
              <NextBtn onClick={onNext} />
            </ArrowNextBtn>
          </PageArea>
        </PageView>
      </Modal>
    )
  }
}

const PageView = styled.div`
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
`

const ArrowPreviousBtn = styled.div`
  img {
    width 48px;
    height: 48px;
    margin-left: 16px;
  }
`

const ArrowNextBtn = styled.div`
  img {
    width 48px;
    height: 48px;
    margin-right: 16px;
  }
`

const PageArea = styled.div`
  align-items: center;
  display: flex;
  height: 90vh;
`

const MenuList = styled.div`
  position: absolute;
  bottom: 98px;
  width: 100%;
  text-align: center;
  img {
    margin-right: 10px;
  }
`

const MenuActiveItem = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 5px;
  border: solid 2px white;
  object-fit: cover;
  margin: 0px;
`

const MenuItem = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 5px;
  border: solid 2px transparent;
  object-fit: cover;
  margin: 0px;
  filter: brightness(0.5);
`

const Slider = styled.div`
  -webkit-animation-name: fade;
  -webkit-animation-duration: 1.5s;
  animation-name: fade;
  animation-duration: 1.5s;
  text-align: center;

  img {
    width: auto;
    height: auto;

    max-height: 85vh;
    object-fit: contain;
  }

  @-webkit-keyframes {
    from {
      opacity: 0.4;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes {
    from {
      opacity: 0.4;
    }
    to {
      opacity: 1;
    }
  }
`

const SlideShow = styled.div`
  max-width: 1300px;
  position: relative;
  margin: auto;
  box-sizing: border-box;

  @media screen and (max-width: 1200px) {
    max-width: 1050px;
  }

  @media screen and (max-width: 1024px) {
    max-width: 900px;
  }

  @media screen and (max-width: 768px) {
    max-width: 620px;
  }

  @media screen and (max-width: 425px) {
    max-width: 380px;
  }
`

const mapStateToProps = (state: RootState) => {
  return {
    activity: getCurrentActivity(state),
    original: getOriginalActivity(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {
  updateOriginalActivity,
}

type DispatchProps = typeof mapDispatchToProps

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ThumbnailDisplay)
