import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { connect, useDispatch } from 'react-redux'
import SubnavBar from 'Components/Shared/SubnavBar'
import { Row, Col } from 'antd'
import { RouteComponentProps, Link } from 'react-router-dom'
import RootState from 'typings/RootState'
import { selectedProjectCodeSelector } from 'features/project/project.selector'
import {
  userEmailSelector,
  serviceTokenSelector,
} from 'features/sensitive/sensitive.selector'
import {
  selectCommissioningData,
  selectEvent,
  selectCommissioningFilter,
} from 'features/qc/dashboard/selector'
import {
  getCommissioningForQC,
  SendCommissioningReportByQC,
  ReturnCommissioningByQC,
} from 'features/qc/dashboard/api'
import {
  TypeOfTask,
  TodoActivity,
  CommissionInProActivity,
  GetCommissioningReq,
  CommissionList,
  CommissTasktList,
  SendCommissioningReportReq,
  ReturnCommissioningReq,
} from 'features/qc/dashboard/types'
import { clearFilter } from 'features/qc/dashboard/actions'
import { translate } from 'i18n'
import TKeys from 'i18n/translationKey'
import { NoCard, EventModal } from 'Components/Shared'
import CommonAreaTab from 'Components/Shared/Tab/CommonAreaTab'
import LinkButton from 'Components/Shared/LinkButton'
import Loading from 'Components/Shared/Loading'
import { PrimaryButton } from 'Components/Shared/Button'
import { BreadcrumbLink } from 'Components/Shared/BreadCrumb/BreadCrumb'

import InprogressCard from './InprogressCard'
import Menu from '../../Menu'
import ColumnHeader from '../ColumnHeader'
import TodoCard from '../TodoCard'
import MainPage from '../MainPage'
import PageFooter from '../PageFooter'
import { QCCommissioningFilter } from 'Components/Filter'

const breadcrumbs: BreadcrumbLink[] = [
  {
    url: '/commissioning/dashboard',
    title: 'Home',
    icon: '/home.png',
    active: true,
    showTitle: false,
  },
  {
    url: '/commissioning/dashboard',
    title: translate(TKeys.Breadcrumb.dashboard),
    active: false,
    showTitle: false,
  },
]

type Props = ReduxProps & DispatchProps & RouteComponentProps

const ConstructRoadDashboard: React.ComponentType<Props> = props => {
  const { data, event, token, ProjectCode, UserEmail, filter } = props
  const [sendReport, setSendReport] = useState(false)
  const [projectList, setProjectList] = useState([] as any[])
  const [enableModal, setEnableModal] = useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    setEnableModal(false)
  }, [data])

  useEffect(() => {
    getDashboardData()
  }, [getCommissioningForQC, ProjectCode, dispatch])

  const getDashboardData = () => {
    const data: GetCommissioningReq = {
      UserEmail: UserEmail,
      ProjectCode: filter.ProjectCode,
      CommissioningTypeList: filter.CommissionTypeList,
    }
    dispatch(getCommissioningForQC(token, data))
  }

  const onSumitSendReport = () => {
    setEnableModal(true)
    const req: SendCommissioningReportReq = {
      UserEmail: UserEmail,
      ProjectList: mappingData(),
    }
    dispatch(SendCommissioningReportByQC(token, req))
  }

  const onReturnTask = (projectCode: string, data: CommissionList) => {
    setEnableModal(true)
    const req: ReturnCommissioningReq = {
      UserEmail: UserEmail,
      ProjectCode: projectCode,
      CheckListID: data.CheckListID,
      ActivityID: data.ActivityID,
      CommissioningType: covertToCommissionningTypeID(data.CommissioningType),
      RequestID: data.RequestID,
      CheckNumber: data.CheckNumber,
    }

    dispatch(ReturnCommissioningByQC(token, req))
  }

  useEffect(() => {
    return () => {
      dispatch(clearFilter())
    }
  }, [])

  const onSearch = () => {
    getDashboardData()
  }

  const onSendReport = () => {
    setProjectList([])
    setSendReport(true)
  }

  const onSuccess = () => {
    setEnableModal(false)
    onCancleSendReport()
    getDashboardData()
  }

  const onError = () => {
    setEnableModal(false)
    setProjectList([])
  }

  const onCancleSendReport = () => {
    setSendReport(false)
  }

  const mappingData = () => {
    return projectList.map((project, index) => {
      project.List = project.List.map((item: any, itindex: number) => {
        item.CommissioningType = covertToCommissionningTypeID(
          item.CommissioningType
        )
        return item
      })
      return project
    })
  }

  const covertToCommissionningTypeID = (type: string) => {
    switch (type) {
      case 'Q1':
        return 1
      case 'Commissioning':
        return 2
      case 'Safety':
        return 3
      default:
        return 0
    }
  }

  const isSelected = (tasks: CommissTasktList[], data: CommissionList) => {
    const result = tasks.find(
      task =>
        task.RequestID === data.RequestID &&
        task.CheckNumber === data.CheckNumber &&
        task.CheckListID === data.CheckListID &&
        task.ActivityID === data.ActivityID &&
        task.CommissioningType === data.CommissioningType
    )
    return result ? true : false
  }

  const remove = (tasks: CommissTasktList[], data: CommissionList) => {
    return tasks.filter(
      task =>
        task.RequestID !== data.RequestID ||
        task.CheckNumber !== data.CheckNumber ||
        task.CheckListID !== data.CheckListID ||
        task.ActivityID !== data.ActivityID ||
        task.CommissioningType !== data.CommissioningType
    )
  }

  const findProject = (projectList: any[], projectCode: string) => {
    const result = projectList.find(
      project => project.ProjectCode === projectCode
    )
    return result ? true : false
  }

  const pushList = (data: CommissionList) => {
    return {
      RequestID: data.RequestID,
      CheckNumber: data.CheckNumber,
      CheckListID: data.CheckListID,
      ActivityID: data.ActivityID,
      CommissioningType: data.CommissioningType,
    }
  }

  const onSelect = (projectCode: string, data: CommissionList) => {
    const isHasProject = findProject(projectList, projectCode)
    if (isHasProject) {
      const project = projectList.map(project => {
        if (project.ProjectCode === projectCode) {
          const selected = isSelected(project.List, data)
          if (selected) {
            project.List = remove(project.List, data)
          } else {
            project.List.push(pushList(data))
          }
        }
        return project
      })
      setProjectList([...project])
    } else {
      let newProject = projectList
      newProject.push({
        ProjectCode: projectCode,
        List: [pushList(data)],
      })
      setProjectList([...newProject])
    }
  }

  const onSelectAll = () => {
    let newProject = [] as any
    data.InProgress.forEach(project => {
      let tasks = [] as any
      project.List.forEach(data => {
        if (data.HasImage && data.HasSign) {
          tasks.push(pushList(data))
        }
      })
      if (tasks && tasks.length > 0) {
        newProject.push({
          ProjectCode: project.ProjectCode,
          List: tasks,
        })
      }
    })

    setProjectList([...newProject])
  }

  const renderTodoCard = (task: any[], type: string) => {
    let card = []
    task.map((activity, index) => {
      card.push(
        <Link to={`/commissioning/todo/${activity.ProjectCode}`}>
          <TodoCard key={index} task={activity} type={type} />
        </Link>
      )
    })

    if (task.length >= 1) {
      card.push(
        <LinkButton href={`/commissioning/projects`} title={'ดูเพิ่มเติม'} />
      )
    }
    return card
  }

  const renderInprogressCard = (
    task: CommissionInProActivity[],
    type: string
  ) => {
    return task.map((activity: CommissionInProActivity, index) => {
      return (
        <InprogressCard
          key={index}
          task={activity}
          type={type}
          isSendReport={sendReport}
          onClickReturn={data => onReturnTask(activity.ProjectCode, data)}
          onSelectLists={onSelect}
          projectList={projectList}
        />
      )
    })
  }

  const loading = (
    <div style={{ textAlign: 'center' }}>
      <Loading />
    </div>
  )

  const sumTodoTask = (task: TodoActivity[]) => {
    let total = 0
    task.forEach(item => {
      if (item.NumberOfTask >= 0) {
        total += item.NumberOfTask
      }
    })
    return total
  }

  const renderTodoTask = (task: TodoActivity[]) => {
    const card =
      task.length > 0 ? renderTodoCard(task, TypeOfTask.TODO) : <NoCard />
    const content = event.IsLoading ? loading : card
    const total = sumTodoTask(task)
    return (
      <Col span={8}>
        <FullColumn>
          <ColumnHeader heading={`รายการขอตรวจ (${total})`} />
          {content}
        </FullColumn>
      </Col>
    )
  }

  const sumInprogressTask = (task: CommissionInProActivity[]) => {
    let total = 0
    task.forEach((activity: CommissionInProActivity) => {
      total += activity.List.length
    })
    return total
  }

  const renderInprogressTask = (task: CommissionInProActivity[]) => {
    const card =
      task.length > 0 ? (
        renderInprogressCard(task, TypeOfTask.INPROGRESS)
      ) : (
        <NoCard text="ไม่มีรายการตรวจ กรุณาเลือก “โครงการขอตรวจ”" />
      )
    const content = event.IsLoading ? loading : card
    const total = sumInprogressTask(task)
    return (
      <Col span={16}>
        <ColumnHeader heading={`กำลังดำเนินการ (${total})`} />
        {content}
      </Col>
    )
  }

  const disableSendReport = !data.InProgress || data.InProgress.length <= 0

  const header = (
    <Header>
      <Row type="flex" justify="space-between" align="middle">
        <Col>
          <QCCommissioningFilter onSearch={onSearch} />
        </Col>
        <Col>
          <Button>
            {!sendReport ? (
              <PrimaryButton
                text="ส่งรายงาน"
                onClick={() => onSendReport()}
                disabled={disableSendReport}
              />
            ) : (
              <PrimaryButton
                text="ยกเลิก"
                onClick={() => onCancleSendReport()}
                btnType="Cancel"
              />
            )}
          </Button>
        </Col>
      </Row>
    </Header>
  )

  const countSelected = () => {
    let total = 0
    projectList.forEach(project => {
      total += project.List.length
    })
    return total
  }

  return (
    <>
      <Menu selected="commonArea" />
      <SubnavBar breadcrumbs={breadcrumbs} hideSearch={true} />
      <CommonAreaTab selected="Commissioning" />
      <EventModal
        event={[event]}
        enable={enableModal}
        successCalback={() => onSuccess()}
        errorCalback={() => onError()}
      />
      {header}
      <MainPage hasFooter={sendReport}>
        <Row type="flex">
          {renderTodoTask(data.ToDo)}
          {renderInprogressTask(data.InProgress)}
        </Row>
      </MainPage>
      {sendReport && (
        <PageFooter
          total={countSelected()}
          onSubmit={onSumitSendReport}
          label="รายการในการส่งรายงาน"
          onSelectAll={onSelectAll}
        />
      )}
    </>
  )
}

const FullColumn = styled.div`
  padding-right: 20px;
`

const Button = styled.div`
  width: 200px;
  float: left;
  margin-left: 24px;
`

const Header = styled.div`
  padding: 0 120px;
  background-color: #f5f6fa;
  margin: 40px 0;
`

const mapStateToProps = (state: RootState) => {
  return {
    data: selectCommissioningData(state),
    event: selectEvent(state),
    token: serviceTokenSelector(state),
    ProjectCode: selectedProjectCodeSelector(state),
    UserEmail: userEmailSelector(state),
    filter: selectCommissioningFilter(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {
  getCommissioningForQC,
}

type DispatchProps = typeof mapDispatchToProps

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConstructRoadDashboard)
