import React from 'react'
import RootState from 'typings/RootState'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { Layout, Row, Col } from 'antd'
import { DefectItem } from 'features/report/constructRoad/types'
import { FloatButton, SecondaryButton } from 'Components/Shared/Button'

import { selectReport } from 'features/report/constructRoad/selector'
import { getUserProfile } from 'features/sensitive/sensitive.selector'

import ReportTitle from './ReportTitle'
import DefectList from './DefectList'

const { Content } = Layout

type Props = {
  title?: string
  onAdd?: () => void
  onEdit?: (item: DefectItem) => void
  onViewActivity?: () => void
  isShowEditImg?: boolean
  isShowCorrectImg?: boolean
} & ReduxProps

const ReportInfo: React.ComponentType<Props> = props => {
  const {
    title = '',
    report,
    onAdd,
    onEdit = (item: DefectItem) => {},
    onViewActivity = () => {},
    isShowEditImg,
    isShowCorrectImg,
    userProfile,
  } = props

  const onSelectedDefect = (item: DefectItem) => {}

  const addBtn = onAdd ? <FloatButton onClick={onAdd} /> : undefined
  const isQc = userProfile.RoleName === 'QC'

  return (
    <Content className="mid-content">
      <MainPage>
        <LeftContent>
          <ReportTitle report={report} showProject={isQc} />
        </LeftContent>
        <RightContent>
          <Row type="flex" justify="space-between" align="middle">
            <Col>
              <Title>{title}</Title>
            </Col>
            <Col>
              <Btn>
                <SecondaryButton
                  text="ดูรายละเอียดงาน"
                  disabled={false}
                  onClick={onViewActivity}
                />
              </Btn>
            </Col>
          </Row>
          <DefectList
            report={report}
            onClick={onEdit}
            onSelected={onSelectedDefect}
            isShowEditImg={isShowEditImg}
            isShowCorrectImg={isShowCorrectImg}
          />
        </RightContent>
        {addBtn}
      </MainPage>
    </Content>
  )
}

const MainPage = styled.div`
  background-color: #f5f6fa;
`

const LeftContent = styled.div`
  width: 320px;
  height: calc(100vh - 112px);
  background-color: #fff;
  float: left;
  margin-top: 1px;
  overflow: scroll;
  padding-bottom: 94px;
`

const RightContent = styled.div`
  width: calc(100% - 320px);
  height: calc(100vh - 210px);
  float: left;
  padding: 32px 80px 0 27px;
  overflow: scroll;
`

const Title = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 28px;
`

const Btn = styled.div`
  width: 160px;
`

const mapStateToProps = (state: RootState) => {
  return {
    report: selectReport(state),
    userProfile: getUserProfile(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportInfo)
