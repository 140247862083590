import React from 'react'
import { Input } from 'antd'
import styled from 'styled-components'

interface Props {
  prefix?: string
  placeholder?: string
  value?: number
  onChange: (value: number) => void
  disabled?: boolean
}

const InputNumber: React.FC<Props> = ({
  prefix = '',
  placeholder = '',
  value,
  onChange,
  disabled = false,
}) => {
  const onEdit = (evt: any) => {
    onChange(parseInt(evt.target.value, 10))
  }

  return (
    <InputTitle prefix={prefix}>
      <Input
        type="number"
        prefix={prefix}
        className="input-title"
        placeholder={placeholder}
        value={value}
        onChange={onEdit}
        disabled={disabled}
      />
    </InputTitle>
  )
}

const InputTitle = styled.div`
  .input-title {
    text-align: ${props => (props.prefix ? 'right' : 'left')};
    height: 48px;
    border-radius: 5px;
    border: 1px solid #e4e7eb;
    background-color: #ffffff;
    font-family: 'DB Helvethaica X 55 Regular';
    font-size: 18px;
    .ant-input-prefix {
      font-size: 20px;
      padding-left: 6px;
      color: #000;
    }

    .ant-input {
      font-size: 20px;
      font-family: 'DB Helvethaica X 55 Regular';
      padding-right: 16px;
      border: none;
      color: #8b99a7;
    }

    .ant-input:placeholder-shown {
      font-family: 'DB Helvethaica X 55 Regular';
    }
  }

  .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
    border-color: #e4e7eb;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }
`
export default InputNumber
