import React, { useState, useEffect } from 'react'
import { Modal, Row, Col } from 'antd'
import styled from 'styled-components'
import TextArea from 'antd/lib/input/TextArea'
import { SecondaryButton, PrimaryButton } from 'Components/Shared/Button'

type Props = {
  onClose: () => void
  onSubmit: (memo: string, reason: string) => void
  visible?: boolean
  title?: string
  qcType?: string
}

const UrgentModal = (props: Props) => {
  const {
    visible = false,
    onClose,
    onSubmit,
    title = 'คำขอแบ่งตรวจด้วยตัวเอง',
    qcType = 'Q1',
  } = props
  const [memo, setMemo] = useState('')
  const [reason, setReason] = useState('')

  useEffect(() => {
    setMemo('')
    setReason('')
  }, [visible])

  return (
    <Modal
      className="urgent-modal"
      title={false}
      centered
      visible={visible}
      footer={false}
      closable={false}
    >
      <Body>
        <Header>{title}</Header>
        <InputMemo>
          <TextArea
            value={memo}
            onChange={evt => setMemo(evt.target.value)}
            placeholder="หมายเลข Memo"
            maxLength={255}
          />
        </InputMemo>
        <InputReason>
          <TextArea
            value={reason}
            onChange={evt => setReason(evt.target.value)}
            placeholder="เหตุผลคำขอแบ่งตรวจเร่งด่วน"
            maxLength={255}
          />
        </InputReason>
        <Description>
          รายการที่ความคืบหน้า 100% จะถูกแบ่งเป็น {qcType}.1 <br />
          รายการที่เหลือจะถูกแบ่งเป็น {qcType}.2
        </Description>
        <ButtonSection>
          <Row type="flex" justify="end">
            <Col>
              <div className="btn-submit">
                <SecondaryButton
                  text="ยกเลิก"
                  disabled={false}
                  onClick={() => onClose()}
                />
              </div>
            </Col>
            <Col>
              <div className="btn-submit">
                <PrimaryButton
                  text="ส่งคำขอแบ่งตรวจ"
                  disabled={memo === '' || reason === ''}
                  onClick={() => onSubmit(memo, reason)}
                />
              </div>
            </Col>
          </Row>
        </ButtonSection>
      </Body>
    </Modal>
  )
}

const Header = styled.div`
  text-align: center;
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 24px;
  color: #000;
`

const Body = styled.div``

const InputMemo = styled.div`
  margin-top: 10px;
  .ant-input,
  .ant-input:focus,
  .ant-input:hover {
    font-family: 'DB Helvethaica X 55 Regular';
    font-size: 20px;
    height: 40px;
    border: solid 1px #e4e7eb;
  }
`

const InputReason = styled.div`
  margin-top: 10px;
  .ant-input,
  .ant-input:focus,
  .ant-input:hover {
    font-family: 'DB Helvethaica X 55 Regular';
    font-size: 20px;
    height: 88px;
    border: solid 1px #e4e7eb;
  }
`

const ButtonSection = styled.div`
  margin-top: 32px;
  .btn-submit {
    width: 160px;
    margin-left: 16px;
  }
`

const Description = styled.div`
  margin-top : 14px
  font-family: 'DB Helvethaica X 55 Regular';
  font-size: 20px;
  color: #000;
`

export default UrgentModal
