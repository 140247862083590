import React, { useState, useEffect } from 'react'
import { useDispatch, connect } from 'react-redux'
import RootState from 'typings/RootState'
import styled from 'styled-components'
import { RouteComponentProps } from 'react-router-dom'
import { BreadcrumbLink } from 'Components/Shared/BreadCrumb/BreadCrumb'
import SubnavBar from 'Components/Shared/SubnavBar'
import { HeaderRow } from 'Components/Shared/Table'
import Pagination from 'Components/Shared/Pagination'
import { PaginationType } from 'features/types/common'
import {
  userEmailSelector,
  serviceTokenSelector,
} from 'features/sensitive/sensitive.selector'
import {
  getReportList,
  exportReportList,
} from 'features/adminReport/sqdbyproject/api'
import { sortingTask } from 'features/adminReport/sqdbyproject/actions'
import {
  GetReportListReq,
  DataItem,
} from 'features/adminReport/sqdbyproject/types'
import {
  selectEvent,
  selectFilter,
  selectReportList,
  selectSorting,
  selectInitFilter,
} from 'features/adminReport/sqdbyproject/selector'
import Menu from 'Components/MenuAdmin/AdminQCMenu'
import RowItem from './RowItem'
import { EventModal } from 'Components/Shared'
import Filter from 'Components/AdminQC/Report/Filter/SQDByProjectFilter'
import { toDateText } from 'utils/datetime'
import ReportTab from 'Components/Shared/Tab/ReportSQDTab'
import { initPagination } from 'utils/helper'

const breadcrumbs: BreadcrumbLink[] = [
  {
    url: '/dashboard',
    title: 'Home',
    icon: '/home.png',
    active: true,
    showTitle: false,
  },
  {
    url: '/report/sqdbyproject',
    title: 'รายงาน SQD',
    active: false,
    showTitle: true,
  },
]

type Props = ReduxProps & DispatchProps & RouteComponentProps

const ReportList: React.ComponentType<Props> = ({
  UserEmail,
  token,
  dataList,
  event,
  sorting,
  filter: currentFilter,
  initFilter,
  dataExport,
  exportEvent,
}) => {
  const dispatch = useDispatch()
  const [enableModal, setEnableModal] = useState(false)
  const [pagination, setPagination] = useState<PaginationType>(initPagination())

  useEffect(() => {
    onFindReport()
  }, [pagination])

  const onFindReport = (
    isClearFilter: boolean = false,
    isExport: boolean = false
  ) => {
    setEnableModal(true)
    const filter = isClearFilter ? initFilter : currentFilter
    const req: GetReportListReq = {
      UserEmail,
      StartDate: toDateText(filter.Date.StartDate || ''),
      EndDate: toDateText(filter.Date.FinishDate || ''),
      ProjectCode: filter.ProjectCode,
      ProjectTypeID: filter.ProjectTypeID,
      Page: isExport ? 1 : pagination.Page,
      RowPerPage: isExport ? dataList.Total : pagination.RowPerPage,
      QCTypeID: filter.QCTypeID,
    }

    if (isExport) {
      dispatch(exportReportList(token, req))
    } else {
      dispatch(getReportList(token, req))
    }
  }

  const onSuccess = () => {
    setEnableModal(false)
  }

  const onSort = (name: string) => {
    dispatch(sortingTask({ name }))
  }

  const content = dataList.List.map((item: DataItem, index: number) => {
    return <RowItem key={index} item={item} />
  })

  return (
    <>
      <SubnavBar breadcrumbs={breadcrumbs} hideSearch={true} />
      <EventModal
        name="report-constructplan"
        event={[event, exportEvent]}
        enable={enableModal}
        successCalback={onSuccess}
      />
      <Menu selected={'report.sqd'}>
        <MainPage>
          <Header>รายงาน SQD</Header>
          <ReportTab selected="Project" />
          <Filter
            onSearch={(isClear: boolean = false) => {
              pagination.Page = 1
              setPagination({ ...pagination })
              onFindReport(isClear)
            }}
            isDownLoading={exportEvent.IsLoading}
            onExport={() => onFindReport(false, true)}
            exportData={dataExport}
          />
          <HeaderRow
            onSort={name => onSort(name)}
            sorting={sorting}
            items={columnsName}
          />
          {content}
          <Pagination
            currentPage={pagination.Page}
            totalRows={dataList.Total}
            perPage={pagination.RowPerPage}
            onChangeNumber={(page: number) => {
              pagination.RowPerPage = page
              setPagination({ ...pagination })
            }}
            onClickPage={(page: number) => {
              pagination.Page = page
              setPagination({ ...pagination })
            }}
          />
        </MainPage>
      </Menu>
    </>
  )
}

const MainPage = styled.div`
  padding: 40px 45px;
  width: 100%;
`

const Header = styled.div`
  margin-bottom: 27px;
  font-size: 28px;
  line-height: 1.07;
  font-family: 'DB Helvethaica X 75 Bd';
  color: #000;
`

const columnsName = [
  {
    title: 'โครงการ',
    span: 6,
    key: 'ProjectCode',
  },
  {
    title: 'พื้นที่รวม (ตร.ม.)',
    span: 6,
    key: 'TotalArea',
  },
  {
    title: 'คะแนนเฉลี่ยครั้งแรก',
    span: 4,
    key: 'UnitScoreFirst',
  },
  {
    title: 'คะแนนเฉลี่ยครั้งสุดท้าย',
    span: 4,
    key: 'UnitScoreLast',
  },
  {
    title: 'คะแนน Site visit เฉลี่ย',
    span: 4,
    key: 'SiteVisitScore',
  },
]

const mapStateToProps = (state: RootState) => {
  return {
    dataList: selectReportList(state),
    event: selectEvent(state),
    filter: selectFilter(state),
    sorting: selectSorting(state),
    UserEmail: userEmailSelector(state),
    token: serviceTokenSelector(state),
    initFilter: selectInitFilter(state),
    dataExport: state.adminReportSQDByProject.DataExport,
    exportEvent: state.adminReportSQDByProject.ExportEvent,
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>
const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(ReportList)
