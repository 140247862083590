import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { RootState } from 'typings/RootState'
import styled from 'styled-components'

import { getUnitList, getQCTypeList } from 'features/filter/api'
import { GetQCTypeListReq } from 'features/filter/types'
import { selectUnitList, selectQCTypeList } from 'features/filter/selector'
import { Col, Row } from 'antd'

import {
  serviceTokenSelector,
  userEmailSelector,
} from 'features/sensitive/sensitive.selector'
import { DropdownList, DropdownPopupMenu } from 'Components/Shared/Form'
import { AdminProject } from 'Components/Shared'
import { fetchProjects } from 'features/project/project.api'
import { selectFilter } from 'features/adminqc/backward-unit/selector'
import { Filter } from 'features/adminqc/backward-unit/types'
import {
  updateFilter,
  clearFilter,
} from 'features/adminqc/backward-unit/actions'
import UnitContent from 'Components/Shared/Filters/UnitContent'
import { GetUnitListReq } from 'features/filter/types'
import {
  ProjectsFilter,
  DateFilter,
  ButtonFilter,
  QcTypeFilter,
} from 'Components/AdminQC/Report/Filter/Components'
import MulticheckProject from 'Components/Shared/Filters/MulticheckProject'
import { changeSelectedProjectAction } from 'features/selectedProject/selectedProject.actions'
import LoadingView from 'Components/Shared/LoadingView'
import {
  selectedProjectCodeSelector,
  projectsByIdSelector,
  isSubmittingSelector,
} from 'features/project/project.selector'
type Props = {
  onSearch?: (isClear?: boolean) => void
  withPadding?: boolean
} & ReduxProps &
  DispatchProps

const UnitFilter: React.FunctionComponent<Props> = props => {
  const {
    ProjectCode,
    UserEmail,
    token,
    filter,
    onSearch = () => {},
    withPadding = false,
    unitList,
    qctypeList,
    projectsById,
    isProjectLoading,
  } = props

  // const [unitFilter, setUnitFilter] = useState([] as any[])
  // const [searchTxtUnit, setSearchTxtUnit] = useState('')

  // const hasText = (data: string, searchTxt: string) => {
  //   if (data && searchTxtUnit) {
  //     return data
  //       .toLocaleLowerCase()
  //       .includes(searchTxtUnit.toLocaleLowerCase())
  //   }
  //   return false
  // }

  // useEffect(() => {
  //   const units: any[] = Object.values(unitList.List)
  //   setUnitFilter(units)
  // }, [unitList])

  // const getUnitItem = () => {
  //   let menu = [] as any
  //   menu.push({
  //     name: `ยูนิตทั้งหมด`,
  //     value: '',
  //   })

  //   unitFilter.forEach((item: any, index: number) => {
  //     menu.push({
  //       name: item.Unit,
  //       value: item.Unit,
  //     })
  //   })
  //   return menu
  // }

  // useEffect(() => {
  //   filterUnit()
  // }, [searchTxtUnit])

  // const filterUnit = () => {
  //   let list: any[] = Object.values(unitList.List)
  //   if (searchTxtUnit !== '') {
  //     list = list.filter((item: any) => {
  //       return hasText(item.Unit, searchTxtUnit)
  //     })
  //   }
  //   setUnitFilter(list)
  // }

  const getQCTypeItem = () => {
    let menu = [] as any
    menu.push({
      name: `ทั้งหมด`,
      value: '',
    })
    qctypeList.List.forEach((item: any, index: number) => {
      menu.push({
        name: item.QCTypeName,
        value: item.QCTypeID,
      })
    })
    return menu
  }

  useEffect(() => {
    dispatch(clearFilter())
    onGetUnitList()
    onGetQCTypeList()
    dispatch(fetchProjects({ email: UserEmail, token }))
    return () => {
      dispatch(clearFilter())
    }
  }, [ProjectCode, UserEmail])

  useEffect(() => {
    // setSearchTxtUnit('')
    onGetUnitList()
  }, [filter.ProjectCode])

  useEffect(() => {
    // setSearchTxtUnit('')
    filter.UnitCode = []
    onFilterChange(filter)
  }, [filter.ProjectCode])

  const onGetUnitList = () => {
    if (filter.ProjectCode && filter.ProjectCode != '') {
      const req: GetUnitListReq = {
        ProjectCode: filter.ProjectCode,
        UserEmail,
        Zone: 'Resident Area',
      }
      dispatch(getUnitList(token, req))
    }
  }

  const onGetQCTypeList = () => {
    const req: GetQCTypeListReq = {
      UserEmail,
      UnitType: 'Unit',
      Status: 'Active',
    }
    dispatch(getQCTypeList(token, req))
  }

  const dispatch = useDispatch()
  const onFilterChange = (filter: Filter) => {
    dispatch(updateFilter(filter))
  }

  const onClearSearch = () => {
    dispatch(clearFilter())
    onSearch(true)
  }

  const isFilter = () => {
    return true
  }

  return (
    <MainPage className={withPadding ? 'with-padding' : ''}>
      <LoadingView isShow={isProjectLoading} />
      <Row type="flex" justify="start">
        <Col span={8}>
          <Box>
            {/* Single Project Select */}
            <AdminProject
              placeholder="เลือกโครงการ"
              onSelectProject={(val: any) => {
                filter.ProjectCode = val
                onFilterChange(filter)
              }}
              activeProject={filter.ProjectCode}
              projectList={projectsById}
            />
          </Box>
        </Col>

        {/* Comment Is Multiple Select */}
        {/* <Col span={8}>
          <Box>
            <DropdownPopupMenu
              label={'โครงการ'}
              placeholder="เลือกโครงการ"
              value={filter.ProjectCode.join(',')}
              disabled={false}
              content={
                <MulticheckProject
                  projectList={projectsById}
                  projectTypeID={filter.ProjectTypeID}
                  filter={filter.ProjectCode}
                  onChange={data => {
                    filter.ProjectCode = data
                    onFilterChange(filter)
                  }}
                />
              }
              isActive={filter.ProjectCode.length > 0}
            />
          </Box>
        </Col> */}

        <Col span={8}>
          <Box>
            {/* Single Unit Select */}

            {/* <DropdownList
              label={'ยูนิต'}
              value={filter.UnitCode}
              disabled={filter.ProjectCode == ''}
              menu={getUnitItem()}
              hasSearch={true}
              onSearch={setSearchTxtUnit}
              onChange={(data: any) => {
                filter.UnitCode = data
                onFilterChange(filter)
              }}
            /> */}

            <DropdownPopupMenu
              label={'ยูนิต'}
              placeholder="เลือกยูนิต"
              value={filter.UnitCode.join(',')}
              disabled={filter.ProjectCode == ''}
              content={
                <UnitContent
                  units={unitList.List}
                  filter={filter.UnitCode}
                  onChange={data => {
                    filter.UnitCode = data
                    onFilterChange(filter)
                  }}
                />
              }
              isActive={filter.UnitCode.length > 0}
            />
          </Box>
        </Col>

        <Col span={8}>
          {/* Single QCType Select */}
          <DropdownList
            label={'หมวดงาน'}
            value={filter.QCType}
            menu={getQCTypeItem()}
            onChange={(data: any) => {
              filter.QCType = data
              onFilterChange(filter)
            }}
          />

          {/* <QcTypeFilter
            filter={filter}
            onFilterChange={onFilterChange}
            qctypeList={qctypeList}
          /> */}
        </Col>

        <Col span={16}> </Col>
        <Col span={8}>
          <ButtonFilter
            onClearSearch={onClearSearch}
            onSearch={onSearch}
            isFilter={isFilter()}
            showExport={false}
          />
        </Col>
      </Row>
    </MainPage>
  )
}

const MainPage = styled.div`
  margin-bottom: 24px;
  &.with-padding {
    padding: 0 120px;
  }

  background-color: #f5f6fa;
`

const Box = styled.div`
  padding: 0 12px 12px 0;
`
const Margin = styled.div`
  margin: 20px;
`

const mapStateToProps = (state: RootState) => {
  return {
    ProjectCode: selectedProjectCodeSelector(state),
    UserEmail: userEmailSelector(state),
    token: serviceTokenSelector(state),
    filter: selectFilter(state),
    unitList: selectUnitList(state),
    qctypeList: selectQCTypeList(state),
    projectsById: projectsByIdSelector(state),
    isProjectLoading: isSubmittingSelector(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(UnitFilter)
