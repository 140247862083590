import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

interface TabProps {
  link: string
  title: string
  isActive: boolean
}

interface Props {
  tabItem: TabProps[]
}

const Tab: React.FunctionComponent<Props> = props => {
  const { tabItem } = props

  const getClass = (isSelected: boolean) => {
    return isSelected ? 'selected' : ''
  }

  const renderTabItem = () => {
    return tabItem.map((item, index) => {
      return (
        <Link to={item.link} key={index}>
          <Item className={getClass(item.isActive)}>
            <span>{item.title}</span>
          </Item>
        </Link>
      )
    })
  }

  return (
    <MainView>
      <ItemList>{renderTabItem()}</ItemList>
    </MainView>
  )
}

const MainView = styled.div`
  margin: 24px 0;
  height: 51px;
  width: 100%;
`

const ItemList = styled.div`
  width: 100%;
  display: inline-flex;
  justify-content: start;
  border-bottom: 1px solid #e8ebf0;

  .selected {
    border-bottom-width: 3px;
    color: #003168;
    font-family: 'DB Helvethaica X 75 Bd';
  }
`

const Item = styled.div`
  color: #8b99a7;
  height: 40px;
  font-size: 18px;
  align-items: center;
  display: flex;
  margin-right: 32px;
  border: 0px solid #003168;
  cursor: pointer;
`

export default Tab
