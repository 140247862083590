import { createStandardAction, createAsyncAction } from 'typesafe-actions'
import {
  ServiceTokenRequest,
  ServiceTokenResponse,
  VendorLoginReq,
  VendorLoginResp,
} from './sensitive.types'

export const resetState = createStandardAction(
  'sensitive/RESET_TO_INITIAL_STATE'
)<void>()

export const redirecToDashboard = createStandardAction(
  'sensitive/REDIRECT_TO_DASHBOARD'
)<boolean>()

export const getServiceTokenAsyncAction = createAsyncAction(
  'sensitive/SERVICE_TOKEN_REQUEST',
  'sensitive/SERVICE_TOKEN_SUCCESS',
  'sensitive/SERVICE_TOKEN_FAILURE'
)<ServiceTokenRequest, ServiceTokenResponse, any>()

export const getUserProfileAsyncAction = createAsyncAction(
  'sensitive/USER_PROFILE_REQUEST',
  'sensitive/USER_PROFILE_SUCCESS',
  'sensitive/USER_PROFILE_FAILURE'
)<ServiceTokenRequest, ServiceTokenResponse, any>()

export const loginVendorAction = createAsyncAction(
  'sensitive/LOGIN_VENDOR_REQUEST',
  'sensitive/LOGIN_VENDOR_SUCCESS',
  'sensitive/LOGIN_VENDOR_FAILURE'
)<VendorLoginReq, VendorLoginResp, any>()

export const changeAccesstoken = createStandardAction(
  'sensitive/CHANGE_ACCESS_TOKEN'
)<string>()

export const changeUserEmail = createStandardAction(
  'sensitive/CHANGE_USER_EMAIL'
)<string>()

export const updateServiceTokenError = createStandardAction(
  'sensitive/UPDATE_SERVICE_TOKEN_ERROR'
)<string>()

export const updateUserRule = createStandardAction(
  'sensitive/UPDATE_USER_RULE'
)<{ Name: string; Id: number }>()
