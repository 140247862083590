import React from 'react'
import { Col } from 'antd'
import styled from 'styled-components'
import { TableRow } from 'Components/Shared/Table'
import { CheckedIcon, UncheckedIcon } from 'Components/Shared/Icon'
import { getQCTypeVersionForToDoList } from 'utils/getText'
import {
  toDateText,
  toTimeText,
  toMomentDateTime,
  getDelayedDate,
} from 'utils/datetime'

type DefaultProps = {
  task: any
  isSelected?: boolean
  onClick?: () => void
  type?: 'unit' | 'zone' | 'commissioning' | 'road'
}
type Props = DefaultProps

const RowItem: React.FC<Props> = props => {
  const { task, isSelected = false, onClick = () => {}, type = 'unit' } = props

  const getRadioBox = () => {
    return (
      <Radio
        onClick={evt => {
          evt.preventDefault()
          onClick()
        }}
      >
        {isSelected ? <CheckedIcon size={24} /> : <UncheckedIcon size={24} />}
      </Radio>
    )
  }

  const getRequestDate = () => {
    const requestDate = toMomentDateTime(task.RequestDate)
    const date = toDateText(requestDate)
    const time = toTimeText(requestDate)
    const dateTime = `${date}-${time}`
    return dateTime
  }

  const getDelayedText = () => {
    let content
    let lane
    const delayed = getDelayedDate(task.RequestDate)
    if (delayed < 0) {
      let days = Math.abs(delayed)
      content = <DelayedText>{`(${days} วันที่แล้ว)`}</DelayedText>
      lane = <span className="lane-card-delayed" />
    }
    return { text: content, lane }
  }

  const delay = getDelayedText()

  const unitContent = (
    <>
      {delay.lane}
      <Col span={3}>{getQCTypeVersionForToDoList(task)}</Col>
      <Col span={3}>{task.QCTypeVersion}</Col>
      <Col span={5}>{task.ModelCode}</Col>
      <Col span={5}>
        {getRequestDate()}
        {delay.text}
      </Col>
      <Col span={5}>{task.IsInspection ? '' : 'ไม่ประเมินคะแนน'}</Col>
      <Col span={3}>{getRadioBox()}</Col>
    </>
  )

  const zoneContent = (
    <>
      {delay.lane}
      <Col span={7}>{task.Unit}</Col>
      <Col span={5}>{task.Floor}</Col>
      <Col span={5}>{task.CheckNumber}</Col>

      <Col span={5}>
        {getRequestDate()}
        {delay.text}
      </Col>
      <Col span={2}>{getRadioBox()}</Col>
    </>
  )

  const commissioningContent = (
    <>
      {delay.lane}
      <Col span={7}>{task.ActivityName}</Col>
      <Col span={5}>{task.NumOfCheckList}</Col>
      <Col span={5}>
        {task.CommissioningType} ({task.CheckNumber})
      </Col>
      <Col span={5}>
        {getRequestDate()}
        {delay.text}
      </Col>

      <Col span={2}>{getRadioBox()}</Col>
    </>
  )

  const roadContent = (
    <>
      {delay.lane}
      <Col span={8}>{task.Unit}</Col>
      <Col span={8}>
        {task.QCTypeName} ({task.CheckNumber})
      </Col>
      <Col span={6}>
        {getRequestDate()}
        {delay.text}
      </Col>
      <Col span={2}>{getRadioBox()}</Col>
    </>
  )

  const renderContent = () => {
    switch (type) {
      case 'unit':
        return unitContent
      case 'zone':
        return zoneContent
      case 'commissioning':
        return commissioningContent
      case 'road':
        return roadContent
      default:
        return unitContent
    }
  }

  return (
    <TableRow minHeight={60} paddingLeft={24} spaceBetweenRow={8}>
      {renderContent()}
    </TableRow>
  )
}

const Radio = styled.div`
  margin-left: 4px;
`

const DelayedText = styled.span`
  color: #d0494c;
  padding-left: 4px;
`

export default RowItem
