import React from 'react'
import { Col, Row } from 'antd'
import styled from 'styled-components'
import {
  DescItem,
  ImageReport,
  ImageFloorplan,
} from 'Components/AdminQC/Report/Components'
import DefectScore from 'Components/Defect/DefectScore'
import { UnitReportItem } from 'features/report/unit/types'
import DefectStatus from 'Components/Defect/DefectStatus'
import DefectRequired from 'Components/Defect/DefectRequired'

type Props = {
  index: number
  defect: UnitReportItem
}

const DefectItem: React.ComponentType<Props> = props => {
  const { index = 0, defect } = props

  const renderDescItem = (title: string, value: any) => {
    return <DescItem title={title} value={value} percentW={20} />
  }

  const defectInfo = (
    <Row type="flex">
      {renderDescItem(
        `หมุดที่ ${defect.PinNumber}`,
        <DefectStatus pinStatus={defect.PinStatus} />
      )}
      {renderDescItem('หมวดงานหลัก', defect.JobTypeName)}
      {renderDescItem('หมวดงานรอง', defect.SubJobTypeName)}
      {renderDescItem('รายละเอียด', defect.DetailName)}
      <Col style={{ width: '20%' }}>
        <Score>
          <DefectScore
            pinStatus={defect.PinStatus}
            pinScore={defect.Score}
            suffix={<Desc>คะแนน</Desc>}
          />
          <DefectRequired IsRequired={defect.IsRequired} />
        </Score>
      </Col>
      {renderDescItem('', '')}
      {renderDescItem(
        'ประเภท Vendor',
        defect.IsSupplier ? 'Supplier' : 'ผู้รับเหมาหลัก'
      )}
      {renderDescItem('พื้นที่', defect.LocationName)}
      {renderDescItem('หมายเหตุ', defect.Remark)}
    </Row>
  )

  const imageInfo = (
    <Row>
      <Col span={8}>
        <FlorplanImage>
          <Title>ตำแหน่ง</Title>
          <ImageFloorplan
            image={defect.ImageFloorPlan}
            pin={defect}
            hasMargin={false}
          />
        </FlorplanImage>
      </Col>
      <Col span={8}>
        <BeforeImage>
          <Title>Before</Title>
          <ImageReport
            image={defect.ImageBefore}
            remark={''}
            showRemark={false}
            hasMargin={false}
          />
        </BeforeImage>
      </Col>
      <Col span={8}>
        <AfterImage>
          <Title>After</Title>
          <ImageReport
            image={defect.ImageAfter}
            remark={''}
            showRemark={false}
            hasMargin={false}
          />
        </AfterImage>
      </Col>
    </Row>
  )

  return (
    <Box
      style={{
        pageBreakBefore:
          index === 0 ? 'avoid' : index % 2 === 0 ? 'always' : 'avoid',
      }}
    >
      {defectInfo}
      <Line />
      {imageInfo}
    </Box>
  )
}

const Line = styled.div`
  width: 100%;
  background-color: rgba(0, 49, 104, 0.05);
  height: 1px;
  margin-top: 16px;
`

const Box = styled.div`
  margin-top: 16px;
  border-radius: 8px;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.03);
  background-color: #fff;
  padding: 24px;

  @media print {
    border: 1px solid rgba(0, 49, 104, 0.05);
    border-radius: 5px;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.03);
  }
`

const BeforeImage = styled.div`
  margin: 0 6px;
  margin-top: 12px;
`

const FlorplanImage = styled.div`
  margin-right: 12px;
  margin-top: 12px;
`

const AfterImage = styled.div`
  margin-left: 12px;
  margin-top: 12px;
`

const Title = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 20px;
  color: #000;
  margin-bottom: 12px;
`

const Desc = styled.span`
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 28px;
  margin-left: 8px;
`

const Score = styled.div`
  font-size: 28px;
`

export default DefectItem
