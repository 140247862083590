import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { connect, useDispatch } from 'react-redux'
import { Layout } from 'antd'
import { RouteComponentProps } from 'react-router-dom'
import moment from 'moment'
import RootState from 'typings/RootState'
import {
  selectReport,
  selectDefect,
  selectEvent,
} from 'features/report/constructRoad/selector'

import { selectedProjectCodeSelector } from 'features/project/project.selector'
import {
  userEmailSelector,
  serviceTokenSelector,
} from 'features/sensitive/sensitive.selector'
import { selectUploadEvent } from 'features/uploadfile/selector'

import { resetEvent } from 'features/report/constructRoad/actions'

import ConstructRoadReport from 'Components/ConstructRoad/Report'
import EventModal from 'Components/Shared/Modal/EventModal'
import { fromRoute } from 'utils/helper'

import Breadcrumbs from './Breadcrumbs'
import { BreadcrumbLink } from 'Components/Shared/BreadCrumb/BreadCrumb'

const { Content } = Layout

type Props = RouteComponentProps<{
  unit: string
  qcTypeID: string
  requestID: string
  checkno: string
}> &
  ReduxProps

const QcConstructPlanReport: React.ComponentType<Props> = props => {
  const [page, setPage] = useState('')
  const [refresh, setRefresh] = useState(0)
  const [enableModal, setEnableModal] = useState(false)

  const {
    UserEmail,
    Token,
    ProjectCode,
    report,
    defect,
    event,
    uploadEvent,
    history,
    match,
  } = props

  const { unit, qcTypeID, requestID, checkno } = match.params
  const Unit = fromRoute(unit)
  const RequestID = +requestID || 0
  const CheckNumber = +checkno || 0
  const dispatch = useDispatch()

  const getName = () => {
    switch (page) {
      case 'view.defect':
        return `หมุดที่ ${defect.PinNumber}`
      default:
        return undefined
    }
  }

  const gotoDashboard = () => {
    setEnableModal(false)
    history.push('/construct-plan')
  }

  const closeEnableModal = () => {
    setEnableModal(false)
    dispatch(resetEvent())
  }
  const onRefreshPage = () => {
    onClear()
    setRefresh(moment().unix())
  }

  const onClear = () => {
    setEnableModal(false)
    setPage('')
  }

  const breadcrumbs: BreadcrumbLink[] = [
    {
      url: '/construct-road',
      title: 'Home',
      icon: '/home.png',
      active: true,
      showTitle: false,
    },
    {
      url: '/construct-road',
      title: 'ถนน',
      active: true,
      showTitle: true,
    },
    {
      url: ``,
      title: 'ระหว่างก่อสร้างถนน',
      active: true,
      showTitle: true,
      onClick: () => setPage(''),
    },
  ]

  return (
    <>
      <EventModal
        event={[event]}
        err={[uploadEvent]}
        enable={enableModal}
        successCalback={gotoDashboard}
        errorCalback={closeEnableModal}
      />
      {page !== 'activity' && (
        <Breadcrumbs
          path={`/construct-road/report/${Unit}/${qcTypeID}/${RequestID}/${CheckNumber}`}
          title="ระหว่างก่อสร้างถนน"
          name={getName()}
          onBack={() => setPage('')}
        />
      )}

      <ConstructRoadReport
        title={'ระหว่างก่อสร้างถนน'}
        initPage={page}
        ProjectCode={ProjectCode}
        Unit={Unit}
        QCTypeID={qcTypeID}
        RequestID={RequestID}
        CheckNumber={CheckNumber}
        onPage={(name: string) => setPage(name)}
        addMode={false}
        readOnly={true}
        isShowEditImg={false}
        refresh={refresh}
        breadcrumbs={breadcrumbs}
      />
    </>
  )
}

const MainPage = styled.div`
  background-color: #f5f6fa;
`

const mapStateToProps = (state: RootState) => {
  return {
    ProjectCode: selectedProjectCodeSelector(state),
    Token: serviceTokenSelector(state),
    UserEmail: userEmailSelector(state),
    report: selectReport(state),
    defect: selectDefect(state),
    event: selectEvent(state),
    uploadEvent: selectUploadEvent(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QcConstructPlanReport)
