import { getType } from 'typesafe-actions'
import { cloneDeep } from 'lodash'
import * as actions from './actions'
import { StoreState, ReportAction, Filter } from './types'
import produce from 'immer'
import parseErrorMessage from 'utils/parseErrorMessage'
import { Event } from '../../types/event'
import { sortingActivityList } from './function'
import moment from 'moment'

const initList = {
  Total: 0,
  List: [],
}

const initFilter = {
  ProjectCode: [],
  ProjectTypeID: 0,
  Date: {
    FinishDate: moment().startOf('day'),
    StartDate: moment()
      .endOf('day')
      .subtract(3, 'months'),
    Mode: '3month',
  },
  QCTypeID: 'QCEnd',
} as Filter

const initEvent = {
  IsLoading: false,
  MessageSuccess: undefined,
  SubmitError: undefined,
  SubmitSuccess: false,
  SubmitType: undefined,
} as Event

const initialState: StoreState = {
  Event: cloneDeep(initEvent),
  DataList: cloneDeep(initList),
  Filter: cloneDeep(initFilter),
  sorting: {
    name: 'ProjectCode',
    type: 'asc',
  },
  ClearFilter: cloneDeep(initFilter),
  DataExport: [],
  ExportEvent: cloneDeep(initEvent),
}

export default function(
  state: StoreState = initialState,
  action: ReportAction
): StoreState {
  return produce(state, draft => {
    switch (action.type) {
      case getType(actions.getReportListAction.request):
        draft.Event = cloneDeep(initEvent)
        draft.Event.IsLoading = true
        draft.DataList = cloneDeep(initList)
        break
      case getType(actions.getReportListAction.success):
        draft.Event.IsLoading = false
        const data = action.payload.ResponseData
        const dataSort = sortingActivityList(
          data.List,
          draft.sorting.type,
          draft.sorting.name
        )
        draft.DataList = {
          Total: data.Total,
          List: dataSort,
        }
        break

      case getType(actions.getReportListAction.failure):
        draft.Event.IsLoading = false
        draft.Event.SubmitError = parseErrorMessage(action.payload)
        break

      case getType(actions.sortingTask):
        const sorting = state.sorting
        const params = action.payload
        if (sorting.name === params.name) {
          draft.sorting.type = sorting.type === 'asc' ? 'desc' : 'asc'
        } else {
          draft.sorting.name = params.name
          draft.sorting.type = 'asc'
        }
        if (params.sort != null) {
          draft.sorting.type = params.sort
        }

        const list = sortingActivityList(
          draft.DataList.List,
          draft.sorting.type,
          draft.sorting.name
        )
        draft.DataList.List = list
        break

      case getType(actions.updateFilter):
        draft.Filter = { ...action.payload }
        break
      case getType(actions.clearFilter):
        draft.Filter = cloneDeep({ ...initFilter })
        break

      case getType(actions.exportReportListAction.request):
        draft.ExportEvent = cloneDeep(initEvent)
        draft.ExportEvent.IsLoading = true
        draft.DataExport = []
        break
      case getType(actions.exportReportListAction.success):
        draft.ExportEvent.IsLoading = false
        if (action.payload.ResponseData) {
          draft.DataExport = action.payload.ResponseData.List
        }
        break
      case getType(actions.exportReportListAction.failure):
        draft.ExportEvent.IsLoading = false
        draft.ExportEvent.SubmitError = parseErrorMessage(action.payload)
        break

      default:
        return state
    }
  })
}
