import React, { useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import ActivityDetail from './ActivityDetail'
import ImageDisplay from './ImageDisplay'
import DocumentDisplay from './DocumentDisplay'
import ThumbnailDisplay from './ThumbnailDisplay'

type Props = RouteComponentProps<{ code: string }>

const ActivityDetailPage = (props: Props) => {
  const [lastPage, setLastPage] = useState('')
  const [page, setPage] = useState('')
  const [readOnly, setReadOnly] = useState(false)
  const [imageSelected, setImageSelected] = useState(0)
  const [imageList, setImageList] = useState([] as any[])
  const [documentList, setDocumentList] = useState([] as any[])

  const onPageClose = () => {
    setPage(lastPage)
    if (lastPage === '') {
      setImageList([])
    } else {
      setLastPage('')
    }

    setDocumentList([])
  }

  const onImageOpen = (list?: any[], readOnly?: boolean) => {
    setLastPage(page)
    setPage('images')
    setReadOnly(readOnly || false)
    setImageList(list ? list : [])
  }

  const onDocumentOpen = (list?: any[], readOnly?: boolean) => {
    setLastPage(page)
    setPage('documents')
    setReadOnly(readOnly || false)
    setDocumentList(list ? list : [])
  }

  const onThumbnailOpen = (index: number, list?: any[], readOnly?: boolean) => {
    setLastPage(page)
    setPage('thumbnails')
    setReadOnly(readOnly || false)
    setImageList(list ? list : [])
    setImageSelected(index)
  }

  switch (page) {
    case 'images':
      return (
        <ImageDisplay
          onClose={onPageClose}
          readOnly={readOnly}
          images={imageList.length > 0 ? imageList : undefined}
          onThumbnailOpen={onThumbnailOpen}
        />
      )
    case 'documents':
      return (
        <DocumentDisplay
          onClose={onPageClose}
          readOnly={readOnly}
          documents={documentList.length > 0 ? documentList : undefined}
        />
      )
    case 'thumbnails':
      return (
        <ThumbnailDisplay
          onClose={onPageClose}
          readOnly={readOnly}
          images={imageList.length > 0 ? imageList : undefined}
          index={imageSelected}
        />
      )
    default:
      return (
        <ActivityDetail
          {...props}
          onImageOpen={onImageOpen}
          onDocumentOpen={onDocumentOpen}
          onThumbnailOpen={onThumbnailOpen}
        />
      )
  }
}

export default ActivityDetailPage
