import { Moment } from 'moment'
import { ActionType } from 'typesafe-actions'
import * as actions from './actions'
import { MainconCheckList } from 'features/types/checklist'
import { TaskStatus, TaskState } from 'features/types/status'

export type ActivityAction = ActionType<typeof actions>

export type TaskById = { [key in string]: MainconTask }

export interface ActivityState {
  CurrentActivity: CurrentActivity
  OriginalActivity: MainconTask
  CheckList: MainconCheckList
  UploadList: any[]
  Event: Event
}

export type CurrentActivity = {
  Images: any[]
  Documents: any[]
  Remark: string
  SubmitDate: string
}

export type MainconTask = {
  RowID: number
  ActivityID: string
  ActivityName: string
  BaselineStartDate: string
  BaselineFinishDate: string
  StartDate: string
  FinishDate: string
  ActualStartDate: string
  ActualFinishDate: string
  SowID: string
  SowName: string
  WbsID: number
  WbsName: string
  Progress: number
  LastCurProgress: number
  LastReqProgress: number
  State: TaskState
  TaskStatus: TaskStatus
  Reason?: string
  Remark?: string
  Images: []
  Documents: []
  CmImages: []
  CmDocuments: []
  McImages: []
  McDocuments: []
  AmendmentNo: string
  Unit: string
  Building: string
  Floor: string
}

export type Event = {
  IsLoading: boolean
  SubmitError: undefined | string
  SubmitSuccess: boolean
  SubmitType: undefined | string
  MessageSuccess: string
}

export interface FetchActivityRequest {
  UserEmail: string
  ProjectCode: string
  ActivityID: string
  ActivityStatus?: string
}

export interface SendActivityReq {
  UserEmail: string
  ProjectCode: string
  ActivityID: string
  AppointmentDate: string
  Images: any[]
  Documents: any[]
  Remark: string
}

export interface GetActivityApi extends GetApiResponse<MainconTask> {}
export interface GetCheckListApi extends GetApiResponse<MainconCheckList> {}
export interface UpdateActivityApi extends GetApiResponse<{}> {}
export interface GetApiResponse<T> {
  ResponseData: T
  ResponseCode: string
  ResponseMsg: 'Success' | 'Error'
  RequestTime: string
  Responsetime: string
}

export enum SubmitType {
  DETAIL = '',
  HISTORY = '',
  ACCEPT = 'acceptActivity',
  REQUEST = '',
  CHECKLIST = '',
}
