import { UserAgentApplication } from 'msal';
import { azureAuthConfig } from '../config';

export const isIE = () => {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf('MSIE ') > -1;
  const msie11 = ua.indexOf('Trident/') > -1;

  // If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
  // const isEdge = ua.indexOf("Edge/") > -1;

  return msie || msie11;
};

export const USER_INTERACTION_TYPE = {
  // Force the user to enter their credentials on that request negating single-sign on.
  LOGIN: 'login',
  NONE: 'none',
  SELECT_ACCOUNT: 'select_account',
  CONSENT: 'consent',
};

export const GRAPH_SCOPES = {
  OPENID: 'openid',
  PROFILE: 'profile',
  USER_READ: 'User.Read',
  // MAIL_READ: 'Mail.Read',
};

export const GRAPH_ENDPOINTS = {
  ME: 'https://graph.microsoft.com/v1.0/me',
  // NOTE: Required if READ_MAIL access is needed
  // MAIL: 'https://graph.microsoft.com/v1.0/me/messages',
};

export const GRAPH_REQUESTS = {
  LOGIN: {
    // NOTE: This will force user to enter username, password everytime user
    // clicks on Login button
    // Please check https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-oauth2-implicit-grant-flow
    // for more information on login flow and parameters that can be used.
    prompt: USER_INTERACTION_TYPE.LOGIN,
    scopes: [GRAPH_SCOPES.OPENID, GRAPH_SCOPES.PROFILE, GRAPH_SCOPES.USER_READ],
  },
  // NOTE: Please follow same convention of adding new GRAPH request scopes.
  // Define as constants easy to follow
  // For e.g. To get access to mail, we need to have MAIL_READ access.
  // EMAIL: {
  //   scopes: [GRAPH_SCOPES.MAIL_READ],
  // },
};

export const msalApp = new UserAgentApplication({
  auth: {
    clientId: azureAuthConfig.clientId,
    authority: azureAuthConfig.authority,
    validateAuthority: true,
    postLogoutRedirectUri: azureAuthConfig.postLogoutRedirectUri,
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: isIE(),
  },
});
