import { createStandardAction, createAsyncAction } from 'typesafe-actions'
import {
  GetDataListReq,
  GetDataListResp,
  UpdateDataReq,
  CommonResp,
} from './types'

export const getDataListAction = createAsyncAction(
  'admin/email/FETCH_LIST_REQUEST',
  'admin/email/FETCH_LIST_SUCCESS',
  'admin/email/FETCH_LIST_FAILURE'
)<GetDataListReq, GetDataListResp, any>()

export const updateDataAction = createAsyncAction(
  'admin/email/UPDATE_DATA_REQ',
  'admin/email/UPDATE_DATA_RESP',
  'admin/email/UPDATE_DATA_FAIL'
)<UpdateDataReq, CommonResp, any>()
