import { put, takeEvery, select, all, call, delay } from 'redux-saga/effects'
import { APIPath } from 'services/APIPath'
import { getType } from 'typesafe-actions'
import {
  serviceTokenSelector,
  userEmailSelector,
} from '../../sensitive/sensitive.selector'
import * as actions from './actions'
import APIManager, { configWithToken } from 'services/APIManager'
import { getCheckList, getFunctionCheckList } from './api'
import { UnitCheckListByCodeType } from './types'

function* updateModel(
  CheckListID: number,
  UserEmail: string,
  Token: string,
  unit: UnitCheckListByCodeType
) {
  const unitList = Object.values(unit)
  if (unitList.length === 0) return

  const checklistRes = yield call(
    APIManager.call,
    APIPath.GetUnitCheckListById,
    'POST',
    { CheckListID, UserEmail: UserEmail },
    configWithToken(`Bearer ${Token}`)
  )

  const cri = checklistRes.ResponseData.CriteriaList
  const mapCriteriaId: any = {}
  for (let i = 0; i < cri.length; i++) {
    const criItem = cri[i]

    mapCriteriaId[criItem.CriteriaName] = criItem.CriteriaID
  }

  for (let index = 0; index < unitList.length; index++) {
    const unitItem = unitList[index]

    if (unitItem.ModelList.length > 0) {
      unitItem.ModelList.forEach(modal => {
        modal.CriteriaID = mapCriteriaId[modal.CriteriaName]
      })
      yield call(
        APIManager.call,
        APIPath.UpdateUnitByUnitCheckList,
        'POST',
        {
          UserEmail,
          ...unitItem,
          CheckListID,
        },
        configWithToken(`Bearer ${Token}`)
      )
    }
  }
}

function* createUpdateCheckListDetail(
  action: ReturnType<typeof actions.createUpdateCheckListDetailReq.request>
) {
  try {
    const Token = yield select(serviceTokenSelector)
    const UserEmail = yield select(userEmailSelector)
    const { check, unit } = action.payload
    const dataCheck = { UserEmail, ...check }
    // console.log('JSON.stringify(data)', JSON.stringify(dataCheck, null, 2))
    let passToReducerData = null

    if (dataCheck.Status.toLocaleLowerCase() === 'used') {
      yield updateModel(dataCheck.CheckListID, UserEmail, Token, unit)
    } else {
      const res = yield call(
        APIManager.call,
        dataCheck.CheckListID
          ? APIPath.UpdateUnitCheckList
          : APIPath.CreateUnitCheckList,
        'POST',
        dataCheck,
        configWithToken(`Bearer ${Token}`)
      )
      if (res && res.ResponseData) {
        check.CheckListID = res.ResponseData
        passToReducerData = check
      }
      yield updateModel(dataCheck.CheckListID, UserEmail, Token, unit)
    }

    yield put(actions.createUpdateCheckListDetailReq.success(passToReducerData))
  } catch (error) {
    yield put(actions.createUpdateCheckListDetailReq.failure(error))
  }
}

function* deleteCheckListDetail(
  action: ReturnType<typeof actions.deleteCheckListDetailReq.request>
) {
  try {
    const { CheckListID, isGoBack, pagination, history } = action.payload
    const Token = yield select(serviceTokenSelector)
    const UserEmail = yield select(userEmailSelector)
    yield call(
      APIManager.call,
      APIPath.DeleteUnitCheckList,
      'POST',
      { UserEmail: UserEmail, CheckListID },
      configWithToken(`Bearer ${Token}`)
    )
    yield put(actions.deleteCheckListDetailReq.success())
    if (isGoBack && history) {
      history.push('/check-unit')
    } else {
      yield delay(100)
      yield put(getCheckList(pagination.Page, pagination.RowPerPage))
    }
  } catch (error) {
    yield put(actions.deleteCheckListDetailReq.failure(error))
  }
}

function* createUpdateFunction(
  action: ReturnType<typeof actions.createUpdateFunctionReq.request>
) {
  try {
    const Token = yield select(serviceTokenSelector)
    const UserEmail = yield select(userEmailSelector)
    const data = { UserEmail: UserEmail, ...action.payload }
    let passToReducerData = null

    const res = yield call(
      APIManager.call,
      data.CheckListID
        ? APIPath.UpdateFunctionCheckList
        : APIPath.CreateFunctionCheckList,
      'POST',
      { UserEmail: UserEmail, ...action.payload },
      configWithToken(`Bearer ${Token}`)
    )
    if (res && res.ResponseData) {
      data.CheckListID = res.ResponseData.CheckListID
      passToReducerData = data
    }
    yield put(actions.createUpdateFunctionReq.success(passToReducerData))
  } catch (error) {
    yield put(actions.createUpdateFunctionReq.failure(error))
  }
}

function* deleteFunction(
  action: ReturnType<typeof actions.deleteFunctionReq.request>
) {
  try {
    const { id, pagination } = action.payload
    const Token = yield select(serviceTokenSelector)
    const UserEmail = yield select(userEmailSelector)
    yield call(
      APIManager.call,
      APIPath.DeleteFunctionCheckList,
      'POST',
      { UserEmail: UserEmail, CheckListID: id },
      configWithToken(`Bearer ${Token}`)
    )
    yield put(actions.deleteFunctionReq.success())
    yield delay(100)
    yield put(getFunctionCheckList(pagination.Page, pagination.RowPerPage))
  } catch (error) {
    yield put(actions.deleteFunctionReq.failure(error))
  }
}

function* allSaga() {
  yield all([
    takeEvery(
      getType(actions.createUpdateCheckListDetailReq.request),
      createUpdateCheckListDetail
    ),
    takeEvery(
      getType(actions.deleteCheckListDetailReq.request),
      deleteCheckListDetail
    ),
    takeEvery(
      getType(actions.createUpdateFunctionReq.request),
      createUpdateFunction
    ),
    takeEvery(getType(actions.deleteFunctionReq.request), deleteFunction),
  ])
}

export default allSaga
