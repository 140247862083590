import { getType, ActionType } from 'typesafe-actions'
import { cloneDeep } from 'lodash'
import produce from 'immer'

import * as actions from './actions'
import { CheckListState } from './types'
import parseErrorMessage from 'utils/parseErrorMessage'

const eventer = {
  IsLoading: false,
  SubmitError: '',
  SubmitSuccess: false,
  SubmitType: '',
  MessageSuccess: '',
  unitLoading: false,
}

const initialState: CheckListState = {
  Event: cloneDeep(eventer),
  QCTypeList: [],
  TowerList: [],
}

export type DocLocalState = typeof initialState
type Action = ActionType<typeof actions>

export default function(state: DocLocalState = initialState, action: Action) {
  return produce(state, (draft: DocLocalState) => {
    switch (action.type) {
      case getType(actions.clearDocument):
        draft.TowerList = []
        break
      case getType(actions.GetActivityByDocumentCheckListReq.request):
      case getType(actions.GetMasterDocumentCheckListReq.request):
        draft.Event.IsLoading = true
        break
      case getType(actions.GetActivityByDocumentCheckListReq.success):
        draft.TowerList = action.payload.ResponseData.TowerList
        draft.Event.IsLoading = false
        break

      case getType(actions.GetMasterDocumentCheckListReq.success):
        draft.Event.IsLoading = false
        draft.QCTypeList = action.payload.ResponseData.QCTypeList.sort(
          (a, b) => {
            if (a.QCTypeID < b.QCTypeID) return -1
            if (a.QCTypeID > b.QCTypeID) return 1
            return 0
          }
        )
        break
      case getType(actions.GetActivityByDocumentCheckListReq.failure):
      case getType(actions.GetMasterDocumentCheckListReq.failure):
        draft.Event.IsLoading = false
        draft.Event.SubmitError = parseErrorMessage(action.payload)
        break
      default:
        return
    }
  })
}
