import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'antd'

import { PrimaryButton, SecondaryButton } from 'Components/Shared/Button'

type Props = {
  onClickReject: () => void
  onClickAccept?: () => void
  isLoading?: boolean
}

const CmInproFooter: React.FC<Props> = ({
  onClickReject,
  onClickAccept,
  isLoading = false,
}) => {
  return (
    <FooterContent>
      <Row type="flex" justify="space-between" align="middle">
        <Col>
          <div className="btn-submit">
            <SecondaryButton
              text="ส่งกลับให้ผู้รับเหมา"
              disabled={isLoading}
              onClick={onClickReject}
            />
          </div>
        </Col>
        <Col>
          <div className="btn-submit">
            <PrimaryButton
              text="ส่งคำขออนุมัติ"
              disabled={isLoading || onClickAccept === undefined}
              onClick={onClickAccept}
            />
          </div>
        </Col>
      </Row>
    </FooterContent>
  )
}

const FooterContent = styled.div`
  max-width: 1110px;
  margin: 0 auto;
  color: #000;
  font-size: 20px;
  padding-right: 20px;

  .btn-submit {
    width: 191px;
    text-align: right;
    button {
      border-radius: 5px;
      width: 191px;
      height: 48px;
      font-size: 20px;
    }
  }
`

export default CmInproFooter
