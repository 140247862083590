import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { connect, useDispatch } from 'react-redux'
import { Layout } from 'antd'
import { RouteComponentProps } from 'react-router-dom'
import moment from 'moment'
import RootState from 'typings/RootState'
import { selectReport, selectDefect } from 'features/report/zone/selector'
import { selectEvent } from 'features/cmpj/zone/report/selector'
import {
  userEmailSelector,
  serviceTokenSelector,
} from 'features/sensitive/sensitive.selector'
import { selectUploadEvent } from 'features/uploadfile/selector'

import ZoneDefectReport from 'Components/Zone/Report'
import Breadcrumbs from './Breadcrumbs'
import QcFooter from './QcFooter'
import Signature from './Signature'
import ChoosePin from './ChoosePin'

import EventModal from 'Components/Shared/Modal/EventModal'

const { Content } = Layout

type Props = RouteComponentProps<{
  project: string
  unit: string
  qcType: string
  requestID: string
  floor: string
}> &
  ReduxProps

const QcZoneReport: React.ComponentType<Props> = props => {
  const [page, setPage] = useState('')
  const [refresh, setRefresh] = useState(0)
  const [enableModal, setEnableModal] = useState(false)
  const [displaySign, setDisplaySign] = useState(false)
  const [selectReport, setSelectReport] = useState(false)
  const { report, defect, event, uploadEvent, history, match } = props

  const { project, unit, qcType, floor, requestID } = match.params
  const RequestID = +requestID || 0
  const dispatch = useDispatch()

  const getName = () => {
    switch (page) {
      case 'add.defect':
        return 'เพิ่ม Defect'
      case 'edit.defect':
        return 'แก้ไข Defect'
      case 'view.defect':
        return `หมุดที่ ${defect.PinNumber}`
      default:
        return undefined
    }
  }

  const gotoDashboard = () => {
    setEnableModal(false)
    history.push('/zone/dashboard')
  }

  const closeEnableModal = () => setEnableModal(false)
  const onRefreshPage = () => {
    onClear()
    setRefresh(moment().unix())
  }

  const onClear = () => {
    setEnableModal(false)
    setDisplaySign(false)
    setPage('')
  }

  let addMode = false
  let readOnly = true
  if (report.State === 'QC-QC' && report.Status === 'INPRO') {
    addMode = true
    readOnly = false
  }

  if (selectReport) {
    return (
      <ChoosePin
        onClose={() => setSelectReport(false)}
        onCompleted={() => {
          setSelectReport(false)
          onRefreshPage()
        }}
        ProjectCode={project}
        Unit={unit}
        QCTypeID={qcType}
        RequestID={RequestID}
        Floor={floor}
      />
    )
  }

  return (
    <>
      <EventModal
        event={[event]}
        err={[uploadEvent]}
        enable={enableModal}
        successCalback={gotoDashboard}
        errorCalback={closeEnableModal}
      />
      <Signature
        display={displaySign}
        project={project}
        Unit={report.Unit}
        QCTypeID={report.QCTypeID}
        RequestID={RequestID}
        Floor={floor}
        onClose={() => setDisplaySign(false)}
        onCompleted={onRefreshPage}
      />
      <Breadcrumbs
        path={`/zone/report/${unit}/${qcType}/${requestID}/${floor}`}
        title="ส่วนกลางทั่วไป"
        name={getName()}
        onBack={() => setPage('')}
      />
      <ZoneDefectReport
        title={'ส่วนกลางทั่วไป'}
        initPage={page}
        ProjectCode={project}
        Unit={unit}
        QCTypeID={qcType}
        RequestID={RequestID}
        Floor={floor}
        onPage={(name: string) => setPage(name)}
        addMode={addMode}
        readOnly={readOnly}
        isShowAfterImg={true}
        refresh={refresh}
      />
      <QcFooter
        report={report}
        visible={page === ''}
        onSign={() => setDisplaySign(true)}
        onChooseImage={() => setSelectReport(true)}
      />
    </>
  )
}

const MainPage = styled.div`
  background-color: #f5f6fa;
`

const mapStateToProps = (state: RootState) => {
  return {
    Token: serviceTokenSelector(state),
    UserEmail: userEmailSelector(state),
    report: selectReport(state),
    defect: selectDefect(state),
    event: selectEvent(state),
    uploadEvent: selectUploadEvent(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QcZoneReport)
