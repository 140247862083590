import RootState from 'typings/RootState'
import { DashboardData } from './types'
import { Event } from 'features/types/event'

export const selectData = (state: RootState): DashboardData =>
  state.cmpjAuditRoad.data

export const selectEvent = (state: RootState): Event =>
  state.cmpjAuditRoad.event

export const selectActionEvent = (state: RootState): Event =>
  state.cmpjAuditRoad.actionEvent

export const selectSorting = (state: RootState) => state.cmpjAuditRoad.sorting

export const selectCanSendStatus = (state: RootState) =>
  state.cmpjAuditRoad.canSendStatus
