import React, { useState, useEffect } from 'react'
import { Modal, Row, Col, Input } from 'antd'
import styled from 'styled-components'

import InputText from 'Components/Shared/Input/InputText'
import { PrimaryButton, OutlineButton } from 'Components/Shared/Button'

import CloseIcon from 'Components/Shared/Icon/CloseIcon'

interface Props {
  visible?: boolean
  readOnly?: boolean
  onClose: () => void
  onOk: (val: string, index: number) => void
  isEditMode?: boolean
  index?: number
  text: string
}

const AddModal: React.FC<Props> = ({
  visible = false,
  readOnly = false,
  onClose,
  onOk,
  isEditMode = false,
  index = -1,
  text,
}) => {
  const [name, setName] = useState('' as string)

  useEffect(() => {
    setName(text)
  }, [text, visible])

  const onSave = () => {
    onOk(name, index)
  }

  const disabledSave = name === ''

  return (
    <ModalCheckList>
      <Modal
        centered
        visible={visible}
        width="900px"
        footer={null}
        closeIcon={<CloseIcon color="#8b99a7" size={16} />}
        title={<Header>{isEditMode ? 'แก้ไขรายการ' : 'เพิ่มรายการ'}</Header>}
        className="add-checklist-modal"
        onCancel={onClose}
      >
        <ModalContent>
          <Input.TextArea
            style={{ color: 'black', fontSize: '20px' }}
            placeholder="กรุณากรอกรายการ"
            value={name}
            onChange={evt => setName(evt.target.value)}
            rows={6}
            disabled={readOnly}
          />

          <ButtonSection>
            <Row>
              <Col span={6} offset={11}>
                <OutlineButton text="ยกเลิก" onClick={onClose} />
              </Col>
              <Col span={6} offset={1}>
                <PrimaryButton
                  text="บันทึก"
                  disabled={disabledSave}
                  onClick={onSave}
                />
              </Col>
            </Row>
          </ButtonSection>
        </ModalContent>
      </Modal>
    </ModalCheckList>
  )
}

const Header = styled.span`
  font-size: 28px;
  color: #000000;
  font-family: 'DB Helvethaica X 75 Bd';
`
const Title = styled.span`
  font-size: 20px;
  color: #000000;
  font-family: 'DB Helvethaica X 75 Bd';
`

const ModalCheckList = styled.div`
  .add-checklist-modal {
    .ant-modal-header {
      border-bottom: none;
    }
  }
`

const ModalContent = styled.div`
  padding: 0 24px 31px 24px;
`

const ButtonSection = styled.div`
  padding-top: 18px;
`

export default AddModal
