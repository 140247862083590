import React, { useEffect } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import SubnavBar from 'Components/Shared/SubnavBar'
import { Layout } from 'antd'
import { RouteComponentProps } from 'react-router-dom'
import RootState from 'typings/RootState'

import { BreadcrumbLink } from '../../Shared/BreadCrumb/BreadCrumb'
import { projectCodeWithSuffixSelector } from 'features/project/project.selector'

import {
  userEmailSelector,
  serviceTokenSelector,
} from 'features/sensitive/sensitive.selector'

import {
  selectData,
  selectEvent,
  selectSorting,
} from 'features/maincon/dashboard/selector'

import { sortingDashboard } from 'features/maincon/dashboard/actions'

import { getActivity } from 'features/maincon/dashboard/api'

import { GetActivityRequest } from 'features/maincon/dashboard/types'

import Menu from '../Menu'

const breadcrumbs: BreadcrumbLink[] = [
  {
    url: '/',
    title: 'Home',
    icon: '/home.png',
    active: true,
    showTitle: false,
  },
  {
    url: '/setting',
    title: 'setting',
    active: false,
    showTitle: false,
  },
]

type Props = ReduxProps & DispatchProps & RouteComponentProps

const CmSetting: React.ComponentType<Props> = props => {
  const { ProjectCode, UserEmail, getActivity } = props

  return (
    <>
      <Menu selected="task" />
      <SubnavBar breadcrumbs={breadcrumbs} hideSearch={true} />
      <MainPage>Coming Soon.....</MainPage>
    </>
  )
}

const MainPage = styled.div`
  padding: 0 80px;
  background-color: #f5f6fa;
  width: 100vw;
  -khtml-user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  overflow-x: scroll;
`
const FullColumn = styled.div`
  padding-right: 20px;
`

const mapStateToProps = (state: RootState) => {
  return {
    data: selectData(state),
    event: selectEvent(state),
    sorting: selectSorting(state),
    token: serviceTokenSelector(state),
    ProjectCode: projectCodeWithSuffixSelector(state),
    UserEmail: userEmailSelector(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {
  getActivity,
  sortingDashboard,
}

type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(CmSetting)
