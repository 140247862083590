import RootState from 'typings/RootState'
import { BackwardUnitList } from './types'
import { Event } from '../../types/event'

export const selectDataList = (state: RootState): BackwardUnitList =>
  state.adminQcBackwardUnit.DataList

export const selectEvent = (state: RootState): Event =>
  state.adminQcBackwardUnit.event

export const selectFilter = (state: RootState) =>
  state.adminQcBackwardUnit.Filter

export const selectInitFilter = (state: RootState) =>
  state.adminQcBackwardUnit.ClearFilter

export const selectSorting = (state: RootState) =>
  state.adminQcBackwardUnit.sorting
