import React from 'react'
import { Tab } from 'Components/Shared'

interface Props {
  selected: 'ConstructPlan' | 'AuditPlan'
}

const ConstructPlanTab: React.FunctionComponent<Props> = props => {
  const tabItem = [
    {
      link: '/construct-plan',
      title: 'ระหว่างก่อสร้าง',
      isActive: props.selected == 'ConstructPlan',
    },
    {
      link: '/audit-plan',
      title: 'เปรียบเทียบแบบจัดสรร',
      isActive: props.selected == 'AuditPlan',
    },
  ]

  return <Tab tabItem={tabItem} />
}

export default ConstructPlanTab
