import React, { useState, useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'

import {
  onGetConfiguration,
  onCreateConfiguration,
  onUpdateConfiguration,
} from 'features/admin/setting-config/api'
import {
  selectDataList,
  selectEvent,
  selectSorting,
} from 'features/admin/setting-config/selector'
import {
  GetDataListReq,
  ConfigList,
  ConfigListDetail,
  ConfigListDetailReq,
} from 'features/admin/setting-config/types'
import { sortingTask } from 'features/admin/setting-config/actions'

import {
  userEmailSelector,
  serviceTokenSelector,
} from 'features/sensitive/sensitive.selector'
import {
  projectsByIdSelector,
  selectedProjectCodeSelector,
} from 'features/project/project.selector'
import RootState from 'typings/RootState'
import { RouteComponentProps } from 'react-router-dom'
import SubnavBar from 'Components/Shared/SubnavBar'
import { BreadcrumbLink } from 'Components/Shared/BreadCrumb/BreadCrumb'
import Menu from '../../MenuAdmin'
import { Row, Col, Drawer } from 'antd'
import styled from 'styled-components'
import { PaginationType } from 'features/types/common'
import Pagination from 'Components/Shared/Pagination'
import { initPagination } from 'utils/helper'
import { HeaderRow, TableColHeader } from 'Components/Shared/Table'
import PrimaryButton from 'Components/Shared/Button/PrimaryButton'

import moment, { Moment } from 'moment'
import DropdownPopupMenu from 'Components/Shared/Form/DropdownPopupMenu'
import MulticheckProject from 'Components/Shared/Filters/MulticheckProject'
import DropdownList from 'Components/Shared/Form/DropdownList'
import {
  AdminProject,
  AlertError,
  AlertSuccess,
  LoadingView,
} from 'Components/Shared'
import { translate } from 'i18n'
import TKeys from 'i18n/translationKey'
import { EventModal } from 'Components/Shared'
import RowItem from './RowItem'
import SettingConfigDetail from './Detail'

type DefultProps = {
  homepage?: boolean
}

type Props = DefultProps & DispatchProps & RouteComponentProps & ReduxProps

const breadcrumbs: BreadcrumbLink[] = [
  {
    url: '/dashboard',
    title: 'Home',
    icon: '/home.png',
    active: true,
    showTitle: false,
  },
  {
    url: '/setting-config',
    title: 'ตั้งค่า Config',
    active: false,
    showTitle: false,
  },
]

const SettingConfig: React.ComponentType<Props> = props => {
  const { UserEmail, token, event, dataList, sorting } = props
  const [enableModal, setEnableModal] = useState(false)
  const [mode, setMode] = useState('')
  const [disPlayModal, setdisPlayModal] = useState(false)
  const [selectedItem, setSelectedItem] = useState({} as any)

  const dispatch = useDispatch()
  const [pagination, setPagination] = useState<PaginationType>(initPagination())

  useEffect(() => {
    onGetList()
  }, [pagination])

  const onGetList = () => {
    setEnableModal(true)
    const req: GetDataListReq = {
      UserEmail,
      Parameter: '',
      Active: '',
      Page: pagination.Page,
      RowPerPage: pagination.RowPerPage,
    }
    dispatch(onGetConfiguration(token, req))
  }

  const onCreate = () => {
    setSelectedItem({} as any)
    setdisPlayModal(true)
    setMode('create')
  }

  const onCloseDrawer = () => {
    setSelectedItem({})
    setdisPlayModal(false)
    setEnableModal(false)
  }

  const onOpenDrawer = (evt: any, item: any) => {
    setSelectedItem(item)
    setMode('update')
    setdisPlayModal(true)
  }

  const onSave = (item: any) => {
    if (mode === 'create') {
      onCreateConfig(item)
    }

    if (mode === 'update') {
      onUpdateConfig(item)
    }
  }

  const onSuccess = () => {
    onCloseDrawer()
    if (mode !== '') {
      setMode('')
      onGetList()
    }
  }

  const onCreateConfig = (item: ConfigListDetail) => {
    setEnableModal(true)
    const req: ConfigListDetailReq = {
      UserEmail,
      Parameter: item.Parameter,
      Value: item.Value,
      Description: item.Description,
      Remark: item.Remark != undefined ? item.Remark : '',
    }
    dispatch(onCreateConfiguration(token, req))
  }

  const onUpdateConfig = (item: ConfigListDetail) => {
    setEnableModal(true)
    const req: ConfigListDetailReq = {
      UserEmail,
      ID: item.ID,
      Parameter: item.Parameter,
      Value: item.Value,
      Description: item.Description,
      Remark: item.Remark != undefined ? item.Remark : '',
      Active: item.Active,
    }
    dispatch(onUpdateConfiguration(token, req))
  }

  const onSort = (name: string) => {
    dispatch(sortingTask({ name }))
  }

  const content = dataList.List.map((item: ConfigListDetail, index: number) => {
    return <RowItem key={index} item={item} onClick={onOpenDrawer} />
  })

  return (
    <>
      <SubnavBar breadcrumbs={breadcrumbs} hideSearch={true} />
      <EventModal
        name="defect"
        event={[event]}
        enable={enableModal}
        successCalback={onSuccess}
      />
      <Menu selected={'setting-config'}>
        <MainPage>
          <Header>
            <Row type="flex" justify="space-between" align="top">
              <Col style={{ flex: 1 }}>ตั้งค่า Config</Col>

              <Col>
                <ButtonSection>
                  <PrimaryButton text="เพิ่ม Config" onClick={onCreate} />
                </ButtonSection>
              </Col>
            </Row>
          </Header>

          <HeaderRow
            onSort={name => onSort(name)}
            sorting={sorting}
            items={columnsName}
          />

          {content}

          <Pagination
            currentPage={pagination.Page}
            totalRows={dataList.Total}
            perPage={pagination.RowPerPage}
            onChangeNumber={(page: number) => {
              pagination.RowPerPage = page
              setPagination({ ...pagination })
            }}
            onClickPage={(page: number) => {
              pagination.Page = page
              setPagination({ ...pagination })
            }}
          />
        </MainPage>

        <Drawer
          title={null}
          className="drawer-project"
          closable={false}
          width={400}
          visible={disPlayModal}
        >
          <SettingConfigDetail
            item={selectedItem}
            onSave={onSave}
            onClose={onCloseDrawer}
            isUpdateMode={mode === 'update'}
          />
        </Drawer>
      </Menu>
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  return {
    event: selectEvent(state),
    token: serviceTokenSelector(state),
    UserEmail: userEmailSelector(state),
    dataList: selectDataList(state),
    sorting: selectSorting(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

const columnsName = [
  {
    title: 'ID',
    span: 2,
    key: 'id',
  },
  {
    title: 'Parameter',
    span: 3,
    key: 'Parameter',
  },
  {
    title: 'Value',
    span: 4,
    key: 'Value',
  },
  {
    title: 'Description',
    span: 4,
    key: 'Description',
  },
  {
    title: 'Remark',
    span: 3,
    key: 'Remark',
  },
  {
    title: 'สร้างโดย',
    span: 3,
    key: 'CreateBy',
  },
  {
    title: 'สถานะ',
    span: 3,
    key: 'Active',
  },

  {
    title: 'จัดการ',
    span: 2,
    key: 'Action',
  },
]

const MainPage = styled.div`
  padding: 40px 45px;
  width: 100%;
`

const SaveArea = styled.div`
  margin-top: 32px;
  width: 160px;
`

const Box = styled.div`
  width: 380px;
`

const Header = styled.div`
  margin-bottom: 27px;
  font-size: 28px;
  line-height: 1.07;
  font-family: 'DB Helvethaica X 75 Bd';
  color: #000;
`

const Title = styled.h2`
  font-size: 28px;
  font-family: 'DB Helvethaica X 75 Bd';
  height: 30px;
`

const TitleSecond = styled.div`
  font-size: 20px;
  font-family: 'DB Helvethaica X 75 Bd';
  margin: 24px 0 8px 0;
  color: #000;
`

const ClearTxt = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  color: #003168;
  font-size: 18px;
  text-decoration: underline;
  cursor: pointer;
`

const Text = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  float: left;
  max-width: 70%;
`

const ButtonSection = styled.div`
  float: left;
  margin-left: 24px;
  width: 178px;
`

const DataArea = styled.div``

export default connect(mapStateToProps, mapDispatchToProps)(SettingConfig)
