import withAPIAction from 'utils/withAPIAction'
import {
  getReportListAction,
  getReportDetailAction,
  getDocumentDetailAction,
  getUnitByQCTypeListAction,
  getUnitCheckListAction,
  exportReportListAction,
  exportReportQCTypeListAction,
} from './actions'
import {
  GetReportListReq,
  GetReportDetailReq,
  GetDocumentDetailReq,
  GetUnitByQCTypeListReq,
  GetUnitCheckListReq,
  ExportRawReportReq,
} from './types'
import { verifyReq, verifyReqNoProject } from 'utils/verify'

export const getReportList = (token: string, data: GetReportListReq) => {
  const isValid = verifyReqNoProject(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<GetReportListReq>(state => ({
    method: 'POST',
    path: 'GetUnitReportListByUnit',
    data,
    token: `Bearer ${token}`,
  }))(getReportListAction)
}

export const getReportDetail = (token: string, data: GetReportDetailReq) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<GetReportDetailReq>(state => ({
    method: 'POST',
    path: 'GetUnitReportForUnit',
    data,
    token: `Bearer ${token}`,
  }))(getReportDetailAction)
}

export const getDocumentDetail = (
  token: string,
  data: GetDocumentDetailReq
) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<GetDocumentDetailReq>(state => ({
    method: 'POST',
    path: 'GetUnitReportForDocument',
    data,
    token: `Bearer ${token}`,
  }))(getDocumentDetailAction)
}

export const getUnitByQCTypeList = (
  token: string,
  data: GetUnitByQCTypeListReq
) => {
  const isValid = verifyReqNoProject(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<GetUnitByQCTypeListReq>(state => ({
    method: 'POST',
    path: 'GetUnitReportListByQCType',
    data,
    token: `Bearer ${token}`,
  }))(getUnitByQCTypeListAction)
}

export const getUnitCheckList = (token: string, data: GetUnitCheckListReq) => {
  const isValid = verifyReq(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<GetUnitCheckListReq>(state => ({
    method: 'POST',
    path: 'GetUnitCheckListForFilter',
    data,
    token: `Bearer ${token}`,
  }))(getUnitCheckListAction)
}

export const exportReportList = (token: string, data: GetReportListReq) => {
  const isValid = verifyReqNoProject(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<GetReportListReq>(state => ({
    method: 'POST',
    path: 'GetUnitReportListByUnit',
    data,
    token: `Bearer ${token}`,
  }))(exportReportListAction)
}

export const exportUnitByQCTypeList = (
  token: string,
  data: ExportRawReportReq
) => {
  const isValid = verifyReqNoProject(data, token)
  if (isValid !== undefined) return isValid

  return withAPIAction<ExportRawReportReq>(state => ({
    method: 'POST',
    path: 'GetUnitReportListByQCType',
    data,
    token: `Bearer ${token}`,
  }))(exportReportQCTypeListAction)
}
