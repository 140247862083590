import RootState from 'typings/RootState'
import { createSelector } from 'reselect'
import { ConstructPlanReport } from './types'
import { Event } from '../../types/event'

export const selectReport = (state: RootState): ConstructPlanReport =>
  state.reportConstructPlan.report

export const selectEvent = (state: RootState): Event =>
  state.reportConstructPlan.event

export const selectEventChecklist = (state: RootState): Event =>
  state.reportConstructPlan.event

export const selectDefect = (state: RootState) =>
  state.reportConstructPlan.defect

export const selectCheckListQC = (state: RootState) =>
  state.reportConstructPlan.checkListQC
