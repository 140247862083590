import React, { useState, useRef } from 'react'
import styled from 'styled-components'

import InputText from 'Components/Shared/Input/InputText'
import InputNumber from 'Components/Shared/Input/InputNumber'
import {
  CheckListDetail,
  JobType,
  FixType,
} from 'features/checklist/auditRoad/types'
import { TrashIcon, EditIcon } from 'Components/Shared/Icon'
import { BottomButton, AddList } from 'Components/Shared/Button'
import JobModalFix, {
  JobHandles,
} from 'Components/AdminQC/CheckAuditRoad/Detail/List/Modal/JobModalFix'

import { SelectType } from '..'

interface Props {
  selected: SelectType
  checkListDetail: CheckListDetail
  editingObj?: JobType
  onSave: (job: JobType) => void
  closeModal: () => void
  disabled: boolean
}

const JobModal: React.FC<Props> = ({
  checkListDetail,
  selected,
  editingObj,
  onSave: onSaveProp,
  closeModal,
  disabled,
}) => {
  const modalRef = useRef<JobHandles>(null)
  const [error, seterror] = useState('')
  const [name, setname] = useState(editingObj ? editingObj.JobTypeName : '')
  const [fixList, setfixList] = useState<FixType[]>(
    editingObj ? editingObj.FixList : []
  )

  const changeName = (val: string) => {
    setname(val)
  }

  const showModal = (item?: FixType) => {
    if (modalRef.current) {
      modalRef.current.showModal(item)
    }
  }

  const addFix = (item: FixType) => {
    const newVal = fixList.concat(item)
    setfixList(newVal)
  }

  const updateFix = () => {
    setfixList([...fixList])
  }

  const deleteFix = (index: number) => {
    fixList.splice(index, 1)
    setfixList([...fixList])
  }

  const onSave = () => {
    if (name !== '') {
      const found = editingObj
        ? null
        : checkListDetail.JobTypeList.find(v => v.JobTypeName === name)
      if (found == null) {
        onSaveProp({
          JobTypeID: editingObj ? editingObj.JobTypeID : 0,
          JobTypeName: name,
          FixList: fixList,
          SubJobTypeList: editingObj ? editingObj.SubJobTypeList : [],
        })
      } else {
        seterror('ลักษณะงานหลักนี้ ได้มีอยู่แล้ว')
      }
    } else {
      seterror('กรุณาใส่ข้อมูลทั้งหมด')
    }
  }

  return (
    <div>
      <Box>
        <InputText
          prefix="ชื่อ"
          value={name}
          onChange={changeName}
          placeholder="กรอกชื่อ"
          openWithFocus
          disabled={disabled}
        />
      </Box>

      <Title>วิธีแก้ไข</Title>
      <LocationContent>
        {fixList.map((item: FixType, index: number) => {
          return (
            <LocationItem key={index}>
              <label>{item.FixName}</label>
              {!disabled && (
                <IconDiv>
                  <IconStyle onClick={() => showModal(item)}>
                    <EditIcon color="#8b99a7" size={16} />
                  </IconStyle>
                  <IconStyle>
                    <TrashIcon
                      onClick={() => deleteFix(index)}
                      color="#8b99a7"
                      size={16}
                    />
                  </IconStyle>
                </IconDiv>
              )}
            </LocationItem>
          )
        })}
        {!disabled && <AddList onClick={() => showModal()} />}
      </LocationContent>

      {!disabled && (
        <BottomButton closeModal={closeModal} onSave={onSave} error={error} />
      )}

      <JobModalFix ref={modalRef} addFix={addFix} updateFix={updateFix} />
    </div>
  )
}

const Box = styled.div`
  padding-top: 12px;
`
const DataItem = styled.div`
  padding: 5px 10px;

  &:hover {
    cursor: pointer;
    color: white;
    background-color: #003168;
  }
`

const Title = styled.div`
  padding-top: 17px;
  font-size: 20px;
  color: #000000;
  font-family: 'DB Helvethaica X 75 Bd';
`

const DropdownBox = styled.div`
  padding-top: 14px;
  .dropdown-item {
    padding: 6px 0;
  }
`

const LocationContent = styled.div`
  width: 100%;
  height: 232px;
  border-radius: 5px;
  border: solid 1px #eeeff2;
  background-color: #ffffff;
  overflow: hidden;
  overflow-y: scroll;
`

const LocationItem = styled.div`
  font-family: 'DB Helvethaica X 55 Regular';
  font-size: 20px;
  margin: 0 auto;
  width: 90%;
  height: 56px;
  align-items: center;
  display: flex;
  border: 0px solid #e8ebf0;
  border-bottom-width: 1px;
  display: flex;
  justify-content: space-between;

  label {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`

const IconDiv = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`

const IconStyle = styled.div`
  padding: 0 9px;
  cursor: pointer;
`

export default JobModal
