import React from 'react'
import { Collapse } from 'antd'
import CheckAsOptions from '../Filters/CheckAsOptions'
import styled, { CSSProperties } from 'styled-components'
import arrowUp from 'assets/img/ic-up.png'
import arrowDown from 'assets/img/ic-down.png'
import {
  Tower,
  ConstructionType,
  Floor,
  ActivityItem,
  ActivityQCType,
  Activity,
} from 'features/document/local/types'
import { numberFormat } from 'utils/format'

const { Panel } = Collapse

type Props = {
  towerList: Tower[]
  onSelect: (item: Activity) => void
  search: string
}

const ArrowComponent = ({ panel }: any) => {
  return (
    <img
      src={panel.isActive ? arrowUp : arrowDown}
      className="ant-collapse-arrow"
      width="16px"
    />
  )
}

const CollapseComponent = ({ children }: { children: React.ReactNode }) => {
  return (
    <Collapse
      defaultActiveKey={['0']}
      expandIconPosition="right"
      expandIcon={panel => <ArrowComponent panel={panel} />}
      className="colapse-tree"
    >
      {children}
    </Collapse>
  )
}

const HeaderComponent = ({
  name,
  detail,
  detailStyle,
  onSelect,
}: {
  name: string
  detail?: string
  detailStyle?: CSSProperties
  onSelect?: () => void
}) => {
  return (
    <HeaderStyle
      onClick={onSelect}
      style={{ cursor: onSelect ? 'pointer' : '' }}
    >
      <Title>{name}</Title>
      <Detail style={detailStyle}>{detail}</Detail>
    </HeaderStyle>
  )
}

const getActivity = (
  items: Activity[],
  onSelect: (item: Activity) => void,
  search: string
) => {
  return items
    .filter(item => item.ActivityName.includes(search))
    .map((item, index) => {
      let detail = ''
      let detailStyle = {}
      let onClick
      if (item.CheckListID) {
        detail = item.IsRequireQC ? `${item.Score} คะแนน` : 'ไม่ตรวจเอกสาร'
        onClick = () => onSelect(item)
      } else {
        detail = 'ยังไม่ผูก Checklist'
        detailStyle = { color: '#d0494c' }
      }
      return (
        <HeaderComponent
          key={index}
          name={item.ActivityName}
          detail={detail}
          detailStyle={detailStyle}
          onSelect={onClick}
        />
      )
    })
}

const GetQCType = (
  items: ActivityQCType[],
  onSelect: (item: Activity) => void,
  search: string
) => {
  const result: JSX.Element[] = []
  items.forEach((item, index) => {
    const value = getActivity(item.ActivityList, onSelect, search)
    if (value.length !== 0) {
      const detailStyle = {
        paddingRight: 20,
        fontFamily: 'DB Helvethaica X 55 Regular',
        color: '',
      }
      let total = 0
      item.ActivityList.forEach((a: Activity) => {
        if (a.IsRequireQC) total += +a.Score
      })
      if (total < 100) {
        detailStyle.color = '#d0494c'
      } else {
        detailStyle.color = '#5bc79e'
      }
      result.push(
        <Panel
          key={index}
          header={
            <HeaderComponent
              name={`${item.QCTypeID} ${item.QCTypeName}`}
              detail={`ผลรวม ${numberFormat(total, 2)} คะแนน`}
              detailStyle={detailStyle}
            />
          }
        >
          {value}
        </Panel>
      )
    }
  })

  if (result.length) {
    return <CollapseComponent>{result}</CollapseComponent>
  }
  return null
}

const GetActivityItem = (
  items: ActivityItem[],
  onSelect: (item: Activity) => void,
  search: string
) => {
  const result: JSX.Element[] = []
  items.forEach((item, index) => {
    const value = GetQCType(item.QCTypeList, onSelect, search)
    if (value != null) {
      result.push(
        <Panel key={index} header={<HeaderComponent name={item.Unit} />}>
          {value}
        </Panel>
      )
    }
  })
  if (result.length) {
    return <CollapseComponent>{result}</CollapseComponent>
  }
  return null
}

const GetFloor = (
  items: Floor[],
  onSelect: (item: Activity) => void,
  search: string
) => {
  const result: JSX.Element[] = []
  items.forEach((item, index) => {
    const value = GetActivityItem(item.List, onSelect, search)
    if (value != null) {
      result.push(
        <Panel key={index} header={<HeaderComponent name={item.FloorName} />}>
          {value}
        </Panel>
      )
    }
  })
  if (result.length) {
    return <CollapseComponent>{result}</CollapseComponent>
  }
  return null
}

const GetConStuct = (
  items: ConstructionType[],
  onSelect: (item: Activity) => void,
  search: string
) => {
  const result: JSX.Element[] = []
  items.forEach((item, index) => {
    const value = GetFloor(item.FloorList, onSelect, search)
    if (value != null) {
      result.push(
        <Panel
          key={index}
          header={<HeaderComponent name={item.ConstructionTypeName} />}
        >
          {value}
        </Panel>
      )
    }
  })
  if (result.length) {
    return <CollapseComponent>{result}</CollapseComponent>
  }
  return null
}

const TreeCollapseDocument = ({ towerList, onSelect, search }: Props) => {
  const result: JSX.Element[] = []
  towerList.forEach((item, index) => {
    const value = GetConStuct(item.ConstructionTypeList, onSelect, search)
    if (value != null) {
      result.push(
        <Panel key={index} header={<HeaderComponent name={item.TowerName} />}>
          {value}
        </Panel>
      )
    }
  })
  if (result.length) {
    return (
      <Content>
        <CollapseComponent>{result}</CollapseComponent>
      </Content>
    )
  }
  return null
}

const Content = styled.div`
  padding: 20px 24px;
  min-height: 550px;
  max-height: 1500px;
  overflow: scroll;
  font-family: 'DB Helvethaica X 55 Regular';
  background-color: white;
  border-radius: 5px;
  .colapse-tree {
    background-color: #fff;
    border: none;
    .ant-collapse-item {
      border: none;
      .ant-collapse-header {
        .ant-collapse-arrow {
          right: 0;
        }
      }
    }
    .ant-collapse-content {
      border: none;
      .ant-collapse-content-box {
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }
`

const HeaderStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 6px;
  padding-bottom: 6px;
`

const Title = styled.span`
  font-size: 20px;
`

const Detail = styled.span`
  color: #8b99a7;
  font-size: 20px;
`

export default TreeCollapseDocument
