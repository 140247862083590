import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { RootState } from 'typings/RootState'
import styled from 'styled-components'
import { Button, Col } from 'antd'

import { getUnitList, getQCTypeList } from 'features/filter/api'
import {
  GetQCTypeListReq,
  GetUnitListReq,
  RoadConstructFilter,
} from 'features/filter/types'
import {
  selectUnitList,
  selectQCTypeList,
  selectRoadConstructFilter,
} from 'features/filter/selector'
import { clearFilter, updateRoadConstructFilter } from 'features/filter/actions'
import PopupMenu from 'Components/Shared/Filters/PopupMenu'
import QCTypeContent from 'Components/Shared/Filters/QCTypeContent'

import {
  serviceTokenSelector,
  userEmailSelector,
} from 'features/sensitive/sensitive.selector'
import UnitContent from 'Components/Shared/Filters/UnitContent'

import { getWbsList } from 'features/wbs/wbs.actions'
import { getLeafWbsList } from 'features/wbs/wbs.function'
import { getWbsListSelector } from 'features/wbs/wbs.selector'

import { WbsFilter } from './Components'

type Props = {
  onSearch?: () => void
  ProjectCode: string
  withPadding?: boolean
} & ReduxProps &
  DispatchProps

const RoadConstructFilterPage: React.FunctionComponent<Props> = props => {
  const {
    ProjectCode,
    UserEmail,
    token,
    unitList,
    qctypeList,
    filter,
    onSearch = () => {},
    withPadding = false,
    wbsList,
  } = props

  useEffect(() => {
    dispatch(clearFilter())
    onGetUnitList()
    onGetQCTypeList()
    onGetWbsList()
  }, [ProjectCode, UserEmail])

  const onGetWbsList = () => {
    const req: any = {
      ProjectCode,
      UserEmail,
      Token: token,
    }
    dispatch(getWbsList(req))
  }

  const onGetUnitList = () => {
    const req: GetUnitListReq = {
      ProjectCode,
      UserEmail,
      Zone: 'Road',
    }
    dispatch(getUnitList(token, req))
  }

  const onGetQCTypeList = () => {
    const req: GetQCTypeListReq = {
      UserEmail,
      UnitType: 'Road',
      Status: 'Active',
    }
    dispatch(getQCTypeList(token, req))
  }

  const dispatch = useDispatch()
  const onFilterChange = (filter: RoadConstructFilter) => {
    dispatch(updateRoadConstructFilter(filter))
  }

  const onClick = () => {
    filter.LeafWbsList = getLeafWbsList(wbsList, filter.WbsList)
    dispatch(updateRoadConstructFilter(filter))
    onSearch()
  }

  return (
    <MainPage className={withPadding ? 'with-padding' : ''}>
      <div className="filters">
        <Col span={24} className="filters-button">
          <WbsFilter
            onFilterChange={onFilterChange}
            filter={filter}
            wbsList={wbsList}
          />

          <PopupMenu
            label={'ยูนิต'}
            content={
              <UnitContent
                units={unitList.List}
                filter={filter.UnitList}
                useOnlyName={false}
                onChange={data => {
                  filter.UnitList = data
                  onFilterChange(filter)
                }}
              />
            }
            isActive={filter.UnitList.length > 0}
          />
          <PopupMenu
            label={'หมวดงาน QC'}
            content={
              <QCTypeContent
                qctypes={qctypeList.List}
                filter={filter.QCTypeID}
                onChange={data => {
                  filter.QCTypeID = data
                  onFilterChange(filter)
                }}
              />
            }
            isActive={filter.QCTypeID.length > 0}
          />
          <Button type="primary" onClick={onClick}>
            กรองข้อมูล
          </Button>
        </Col>
      </div>
    </MainPage>
  )
}

const MainPage = styled.div`
  &.with-padding {
    padding: 0 120px;
  }

  background-color: #f5f6fa;
`

const mapStateToProps = (state: RootState) => {
  return {
    UserEmail: userEmailSelector(state),
    token: serviceTokenSelector(state),
    filter: selectRoadConstructFilter(state),
    unitList: selectUnitList(state),
    qctypeList: selectQCTypeList(state),
    wbsList: getWbsListSelector(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RoadConstructFilterPage)
