import { createStandardAction, createAsyncAction } from 'typesafe-actions'
import { GetProjectTodoReq, GetProjectTodoRes } from './types'

export const GetUnitTodoForQCAction = createAsyncAction(
  'qc/project/FETCH_UNIT_TODO_REQUEST',
  'qc/project/FETCH_UNIT_TODO_SUCCESS',
  'qc/project/FETCH_UNIT_TODO_FAILURE'
)<GetProjectTodoReq, GetProjectTodoRes, any>()

export const GetZoneTodoForQCAction = createAsyncAction(
  'qc/project/FETCH_ZONE_TODO_REQUEST',
  'qc/project/FETCH_ZONE_TODO_SUCCESS',
  'qc/project/FETCH_ZONE_TODO_FAILURE'
)<GetProjectTodoReq, GetProjectTodoRes, any>()
