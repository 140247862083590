import React from 'react'
import styled from 'styled-components'
import iconWarning from 'assets/img/ic-warning-big.png'

type AppProps = {}
type Props = AppProps

const NotSupportPage = (props: Props) => {
  return (
    <Contrainer>
      <Content>
        <Image src={iconWarning} />
        <Title>อุปกรณ์ไม่รองรับการใช้งาน</Title>
        <Desc>
          คุณไม่สามารถใช้งานเว็บไซต์บนอุปกรณ์สมาร์ทโฟน หรือแท็บเล็ตได้
          กรุณาเปลี่ยนไปใช้งานบนคอมพิวเตอร์
        </Desc>
      </Content>
    </Contrainer>
  )
}

const Contrainer = styled.div`
  width: 100%;
  height: 100vh;
  background-color: #fff;
`

const Content = styled.div`
  text-align: center;
  width: 100%;
  padding: 28px;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
`

const Title = styled.div`
  font-size: 24px;
  font-family: 'DB Helvethaica X 75 Bd';
  color: #000;
  margin-top: 12px;
`

const Desc = styled.div`
  font-size: 20px;
  font-family: 'DB Helvethaica X 55 Regular';
  color: #000;
`

const Image = styled.img`
  cursor: pointer;
  width: 60px;
`

export default NotSupportPage
