import { sortingList, sortingByMultiParams } from '../../../utils/helper'
import { DataItem } from './types'

export const sortingActivityList = (
  list: DataItem[],
  type: string,
  name: string | undefined
): DataItem[] => {
  try {
    let newList: DataItem[] = list
    switch (name) {
      case 'NotPassByCM':
      case 'PassByCM':
      case 'NotPassByQC1':
      case 'PassByQC1':
      case 'NotPassByQC2':
      case 'PassByQC2':
      case 'NumOfPass':
      case 'NumOfFail':
        newList = sortingList(list, type, name, 'number')
        break
      case 'CheckedDate':
        newList = sortingList(list, type, name, 'date')
        break
      case 'CheckNumber':
        let params = ['CheckByModule', 'CheckNumber']
        newList = sortingByMultiParams(list, type, params)
        break
      default:
        newList = sortingList(list, type, name || '', 'string')
    }

    return newList
  } catch (e) {
    return list
  }
}
