import React from 'react'
import { Button, Popover } from 'antd'

interface Props {
  label: string
  content: any
  className?: string
  isActive?: boolean
}

const PopupMenu: React.FunctionComponent<Props> = ({
  label,
  content,
  className,
  isActive = false,
}) => {
  return (
    <Popover
      overlayClassName={className}
      placement="bottomLeft"
      content={content}
      trigger="click"
    >
      <Button className={isActive ? 'btn-active' : ''}>
        {label}
        <span className="icon-down">
          <img src="/img/arrow-down.svg" alt="down arrow" />
        </span>
      </Button>
    </Popover>
  )
}

export default PopupMenu
