import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { connect, useDispatch } from 'react-redux'
import { RootState } from 'typings/RootState'
import { RouteComponentProps } from 'react-router-dom'

import { BreadcrumbLink } from 'Components/Shared/BreadCrumb/BreadCrumb'
import SubnavBar from 'Components/Shared/SubnavBar'
import { EventModal } from 'Components/Shared'
import Menu from '../../../MenuAdmin'
import { PrimaryButton } from 'Components/Shared/Button'
import CheckList from 'Components/Inspection/CheckList'
import { CheckListDetail } from 'features/checklist/master/types'
import { getActivityByCommissioning } from 'features/admin/commission/api'
import { cloneDeep } from 'lodash'
import {
  creatUpdateCommissionDetailReq,
  clearCommissionDetail,
  deleteReq,
  clearEvent,
  getCommissionDetailReq,
} from 'features/admin/commission/actions'
import Setting from './Setting'
import { selectProjectType } from 'features/masterData/selector'
import { projectsByIdSelector } from 'features/project/project.selector'
import {
  userEmailSelector,
  serviceTokenSelector,
} from 'features/sensitive/sensitive.selector'
import { ProjectListItem } from 'features/project/project.types'
import { fetchProjects } from 'features/project/project.api'
import {
  CommissioningType,
  SelectActivityByCode,
} from 'features/admin/commission/types'
import { textToLowerCase } from 'utils/format'

const editModeBreadcrumbs: BreadcrumbLink[] = [
  {
    url: '/dashboard',
    title: 'Home',
    icon: '/home.png',
    active: true,
    showTitle: false,
  },
  {
    url: '/check-commission',
    title: 'ตรวจ commissioning',
    active: true,
    showTitle: true,
  },
  {
    url: '/check-commission/:id',
    title: 'แก้ไข commissioning',
    active: false,
    showTitle: true,
  },
]

type Props = RouteComponentProps<{ id: string; type: string }> &
  ReduxProps &
  DispatchProps

const initialAct = {
  CheckListID: '',
  CheckListName: 'name',
  Status: 'Ready',
  InspectionList: [],
  WarningList: [],
}

const CheckCommissionDetail: React.ComponentType<Props> = ({
  match,
  history,
  UserEmail,
  token,
  event,

  commissionDetail: commissionDetailProp,
  Q1Act: Q1ActProp,
  CommissionAct: CommissionActProp,
  SafetyAct: SafetyActProp,

  projectsById,

  getActivityByCommissioning,
  ActvityList,
}) => {
  const { id = '0', type = '1' } = match.params
  const CheckListID = parseInt(id)
  const isEditing = commissionDetailProp.CheckListID !== 0
  const isSafety = type === '2'
  const isUsed = textToLowerCase(commissionDetailProp.Status) === 'used'

  const dispatch = useDispatch()
  const [enableModal, setEnableModal] = useState(true)
  const [tab, setTab] = useState({
    setting: 'active',
    q1: '',
    commissioning: '',
    safety: '',
  })

  const [selectActivityByCode] = useState<SelectActivityByCode>({})
  const [commissionDetail, setCommissionDetail] = useState(commissionDetailProp)
  const [q1Act, setQ1Act] = useState<CheckListDetail>(cloneDeep(initialAct))
  const [commissionAct, setCommissionAct] = useState<CheckListDetail>(
    cloneDeep(initialAct)
  )
  const [safetyAct, setSafetyAct] = useState<CheckListDetail>(
    cloneDeep(initialAct)
  )
  const [projects, setprojectst] = useState<ProjectListItem[]>([])

  useEffect(() => {
    setprojectst(Object.values(projectsById))
  }, [projectsById])

  useEffect(() => {
    dispatch(fetchProjects({ email: UserEmail, token }))
    return () => {
      dispatch(clearCommissionDetail())
    }
  }, [])

  useEffect(() => {
    setCommissionDetail({
      ...commissionDetailProp,
      CheckListType: isSafety ? 2 : 1,
    })
  }, [commissionDetailProp])

  useEffect(() => {
    if (Q1ActProp) {
      setQ1Act({
        CheckListID: '',
        CheckListName: 'name',
        Status: 'Ready',
        InspectionList: Q1ActProp,
        WarningList: [],
      })
    }
  }, [Q1ActProp])

  useEffect(() => {
    if (CommissionActProp) {
      setCommissionAct({
        CheckListID: '',
        CheckListName: 'name',
        Status: 'Ready',
        InspectionList: CommissionActProp,
        WarningList: [],
      })
    }
  }, [CommissionActProp])

  useEffect(() => {
    if (SafetyActProp) {
      setSafetyAct({
        CheckListID: '',
        CheckListName: 'name',
        Status: 'Ready',
        InspectionList: SafetyActProp,
        WarningList: [],
      })
    }
  }, [SafetyActProp])

  useEffect(() => {
    if (CheckListID) {
      setEnableModal(true)
      dispatch(
        getCommissionDetailReq.request({
          CheckListID: CheckListID,
          CommissioningType: isSafety
            ? CommissioningType.SAFETY
            : CommissioningType.Q1,
        })
      )

      if (!isSafety) {
        dispatch(
          getCommissionDetailReq.request({
            CheckListID: CheckListID,
            CommissioningType: CommissioningType.COMMISSIONING,
          })
        )
      }
    }
  }, [])

  const onSetTab = (newData: { [key: string]: string }) => {
    setEnableModal(true)
    const iniTab = {
      setting: '',
      q1: '',
      commissioning: '',
      safety: '',
      ...newData,
    }

    setTab(iniTab)
    if (
      iniTab.commissioning === 'active' &&
      CommissionActProp == null &&
      CheckListID &&
      commissionAct.InspectionList.length === 0
    ) {
      dispatch(
        getCommissionDetailReq.request({
          CheckListID,
          CommissioningType: CommissioningType.COMMISSIONING,
        })
      )
    }
  }

  const onSave = () => {
    setEnableModal(true)
    dispatch(
      creatUpdateCommissionDetailReq.request({
        isSafety,
        commissionDetail,
        activityByCode: selectActivityByCode,
        q1: q1Act.InspectionList,
        commission: commissionAct.InspectionList,
        safety: safetyAct.InspectionList,
        history,
      })
    )
  }

  const onDelete = () => {
    setEnableModal(true)
    dispatch(deleteReq.request({ CheckListID, history }))
  }

  const onClearEvent = () => {
    dispatch(clearEvent())
    setEnableModal(false)
  }

  return (
    <>
      <EventModal
        event={[event]}
        err={[]}
        enable={enableModal}
        errorCalback={onClearEvent}
      />
      <SubnavBar breadcrumbs={editModeBreadcrumbs} hideSearch={true} />
      <Menu selected="check-commission">
        <MainPage>
          <Title>{isEditing ? 'แก้ไข' : 'สร้าง'} Checklist Commissioning</Title>
          <Tab>
            <TabItem
              className={tab.setting}
              onClick={() => onSetTab({ setting: 'active' })}
            >
              ตั้งค่า
            </TabItem>

            {!isSafety && (
              <>
                <TabItem
                  className={tab.q1}
                  onClick={() => onSetTab({ q1: 'active' })}
                >
                  Q1
                </TabItem>
                <TabItem
                  className={tab.commissioning}
                  onClick={() => onSetTab({ commissioning: 'active' })}
                >
                  Commissioning
                </TabItem>
              </>
            )}
            {isSafety && (
              <TabItem
                className={tab.safety}
                onClick={() => onSetTab({ safety: 'active' })}
              >
                Safety
              </TabItem>
            )}
          </Tab>

          {isEditing && !isUsed && (
            <div
              style={{
                position: 'absolute',
                top: 40,
                right: 220,
                width: 150,
              }}
            >
              <PrimaryButton text="ลบ" btnType="Reject" onClick={onDelete} />
            </div>
          )}
          <div style={{ position: 'absolute', top: 40, right: 45, width: 150 }}>
            <PrimaryButton text="บันทึก" onClick={onSave} />
          </div>

          <Content
            style={{
              display: tab.setting === 'active' ? '' : 'none',
              width: '100%',
            }}
          >
            <Setting
              isSafety={isSafety}
              projects={projects}
              getActivityByCommissioning={getActivityByCommissioning}
              commissionDetail={commissionDetail}
              ActvityList={ActvityList}
              selectActivityByCode={selectActivityByCode}
            />
          </Content>

          <Content style={{ display: tab.q1 === 'active' ? '' : 'none' }}>
            <CheckList
              readOnly={isUsed}
              checklist={q1Act}
              onChange={() => null}
            />
          </Content>

          <Content
            style={{ display: tab.commissioning === 'active' ? '' : 'none' }}
          >
            <CheckList
              readOnly={isUsed}
              checklist={commissionAct}
              onChange={() => null}
            />
          </Content>

          <Content style={{ display: tab.safety === 'active' ? '' : 'none' }}>
            <CheckList
              readOnly={isUsed}
              checklist={safetyAct}
              onChange={() => null}
            />
          </Content>
        </MainPage>
      </Menu>
    </>
  )
}

const MainPage = styled.div`
  position: relative;
  padding: 40px 45px;
  width: 100%;
`

const Title = styled.h2`
  font-size: 28px;
  font-family: 'DB Helvethaica X 75 Bd';
`

const Tab = styled.div`
  height: 28px;
  width: 100%;
  border-bottom: 1px solid #e8ebf0;
`

const TabItem = styled.div`
  font-size: 18px;
  float: left;
  margin-right: 24px;
  color: #8b99a7;
  cursor: pointer;
  &.active {
    color: #000000;
    border-bottom: 2px solid #003168;
    box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.03);
  }
`

const Content = styled.div`
  width: 504px;
  margin-top: 40px;
`

const mapStateToProps = (state: RootState) => {
  return {
    UserEmail: userEmailSelector(state),
    token: serviceTokenSelector(state),
    projectType: selectProjectType(state),
    projectsById: projectsByIdSelector(state),

    commissionDetail: state.adminCommission.CommissionDetail,
    Q1Act: state.adminCommission.Q1Act,
    SafetyAct: state.adminCommission.SafetyAct,
    CommissionAct: state.adminCommission.CommissionAct,
    ActvityList: state.adminCommission.ActvityList,

    event: state.adminCommission.Event,
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>
const mapDispatchToProps = {
  getActivityByCommissioning,
}

type DispatchProps = typeof mapDispatchToProps

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CheckCommissionDetail)
