import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'antd'
import { PrimaryButton, SecondaryButton } from 'Components/Shared/Button'
import { RadioBox } from 'Components/Shared/Form'

type Props = {
  onSendRequest: () => void
  onSignName: () => void
  onSendReport: () => void
  isLoading?: boolean
}

const QcFooter: React.FC<Props> = ({
  onSendRequest,
  onSignName,
  onSendReport,
  isLoading = false,
}) => {
  const renderCheckStatus = (
    <Row type="flex" justify="space-between" align="middle">
      <Col>สถานะ</Col>
      {['ผ่าน', 'ไม่ผ่าน'].map((data, index) => {
        return (
          <Col>
            <RadioBox
              value={index}
              label={data}
              isCheck={false}
              disabled={false}
              onClick={value => {}}
            />
          </Col>
        )
      })}
    </Row>
  )
  return (
    <FooterContent>
      <Row type="flex" justify="space-between" align="middle">
        <Col span={4}>{renderCheckStatus}</Col>
        <Col>
          <div className="btn-submit">
            <SecondaryButton
              text="ลงชื่อรับทราบ"
              disabled={isLoading}
              onClick={onSignName}
            />
          </div>
          <div className="btn-submit">
            <SecondaryButton
              text="เลือกรูปส่งรายงาน"
              disabled={isLoading}
              onClick={onSendReport}
            />
          </div>
          <div className="btn-submit">
            <PrimaryButton
              text="ส่งคำขออนุมัติ"
              disabled={isLoading}
              onClick={onSendRequest}
            />
          </div>
        </Col>
      </Row>
    </FooterContent>
  )
}

const FooterContent = styled.div`
  max-width: 1110px;
  margin: 0 auto;
  color: #000;
  font-size: 20px;

  .btn-submit {
    width: 150px;
    text-align: right;
    float: left;
    margin-right: 16px;
  }
`

export default QcFooter
