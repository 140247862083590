import React from 'react'
import styled from 'styled-components'
import { DefectStatus } from 'features/types/status'

interface Props {
  defect: any
}

const NcrPrefix = (props: Props) => {
  const { defect } = props
  return defect.PinStatus === DefectStatus.TOBEFIX && defect.IsNCRScore ? (
    <Prefix>(NCR ซ้ำ)</Prefix>
  ) : (
    <span />
  )
}

const Prefix = styled.div`
  font-size: 18px;
`

export default NcrPrefix
