import React from 'react'
import BaseIcon from './BaseIcon'
import siriconIcon from 'assets/img/ic-siricon.png'
import siriconIconFill from 'assets/img/ic-siricon-fill.png'

type Props = {
  active?: boolean
}

const SiriconIcon = (props: Props) => {
  const { active = false } = props
  const activeIcon = siriconIcon
  const inactiveIcon = siriconIconFill

  return (
    <BaseIcon
      active={active}
      activeIcon={activeIcon}
      inactiveIcon={inactiveIcon}
    />
  )
}

export default SiriconIcon
