import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'antd'
import { Link } from 'react-router-dom'

import { CheckStatusIcon } from 'Components/Shared'

interface Props {
  task?: any
}

const ZoneCard: React.FC<Props> = ({ task = {} }) => {
  const getLaneColor = (status: string) => {
    let lane
    switch (status) {
      case 'success':
        lane = <span className="lane-card-success" />
        break
      case 'fail':
        lane = <span className="lane-card-delayed" />
        break
      default:
        break
    }
    return lane
  }

  return (
    <Link
      to={`/zone.report/${task.Unit}/${task.QCTypeID}/${task.RequestID}/${
        task.Floor
      }`}
    >
      <MainCard>
        {getLaneColor('fail')}
        <Row type="flex" justify="space-between" align="bottom">
          <Col>
            <Title>{task.Unit}</Title>
            <Detail>{`ชั้น ${task.Floor}`}</Detail>
            <Detail>ตรวจครั้งที่ {task.CheckNumber}</Detail>
          </Col>
          <Col span={8}>
            <CheckStatusIcon
              isSelfCheck={task.LastCheckedModule === 'CM'}
              isMaincon={true}
            />
          </Col>
        </Row>
        <Line />
        <Row>
          <Col span={12}>
            <PassScore>{task.NumOfPass} ข้อ</PassScore>
            <PassStatus>ผ่าน</PassStatus>
          </Col>
          <Col span={12}>
            <FailScore>{task.NumOfFail} ข้อ</FailScore>
            <FailStatus>ไม่ผ่าน</FailStatus>
          </Col>
        </Row>
      </MainCard>
    </Link>
  )
}

const MainCard = styled.div`
  background: #fff;
  border-radius: 10px;
  padding: 16px;
  background-clip: padding-box;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.03);
  margin-bottom: 12px;
  cursor: pointer;
  color: #000;
  width: 100%;
  position: relative;
`

const Title = styled.p`
  font-size: 20px;
  margin-bottom: 4px;
  font-family: 'DB Helvethaica X 75 Bd';
`

const Detail = styled.p`
  font-size: 18px;
  margin-bottom: 4px;
  font-family: 'DB Helvethaica X 55 Regular';
`

const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e8ebf0;
  margin: 5px 0 20px 0;
`

const PassScore = styled.div`
  font-size: 24px;
  font-family: 'DB Helvethaica X 75 Bd';
  color: #5bc79e;
  line-height: 0.5;
`

const FailScore = styled.div`
  font-size: 24px;
  font-family: 'DB Helvethaica X 75 Bd';
  color: #d0494c;
  line-height: 0.5;
`

const PassStatus = styled.div`
  font-size: 16px;
  font-family: 'DB Helvethaica X 55 Regular';
  color: #5bc79e;
`

const FailStatus = styled.div`
  font-size: 16px;
  font-family: 'DB Helvethaica X 55 Regular';
  color: #d0494c;
`

export default ZoneCard
