import React from 'react'
import styled from 'styled-components'
import { SitevisitReturn } from 'features/qc/dashboard/types'
import { Row, Col, Modal } from 'antd'
import { getNumBeetwenDate } from 'utils/datetime'
import { numberFormat } from 'utils/format'

interface Props {
  task: SitevisitReturn
  type?: 'construct' | 'audit'
}

const ReturnCard: React.FC<Props> = props => {
  const { task, type = 'construct' } = props
  const {
    ProjectCode,
    ProjectNameTH,
    CheckNumber,
    InspectionScore,
    ReturnDate,
    CheckedDate,
    NumOfReturnDate,
    CheckStatus,
  } = task

  const isConstruct = type === 'construct'
  const duration = getNumBeetwenDate(CheckedDate, ReturnDate)

  let days = duration.days() + duration.months() * 30 + duration.years() * 12
  let hours = duration.hours()
  const isDelay = days - NumOfReturnDate >= 0
  if (isDelay) {
    days = days - NumOfReturnDate
  }
  const leftContent = (
    <LeftBox>
      {isDelay && <span className="lane-card-delayed" />}
      <Row type="flex" align="middle">
        <Col>
          <TxtBold>
            {ProjectCode} - {ProjectNameTH}
          </TxtBold>
          <TxtReg>ตรวจครั้งที่ {CheckNumber}</TxtReg>
        </Col>
      </Row>
    </LeftBox>
  )

  const rightContent = (
    <RightBox>
      <Row type="flex" align="middle" justify="space-around">
        {isConstruct && (
          <Col>
            <Score className={CheckStatus === 'Pass' ? 'pass' : 'fail'}>
              <p>{numberFormat(InspectionScore)}</p>
              <p>คะแนน</p>
            </Score>
          </Col>
        )}
        <Col>
          <Description className={isDelay ? 'fail' : ''}>
            <p>{isDelay ? 'เลยกำหนดตอบกลับ' : 'ระยะเวลาตอบกลับ'}</p>
            <p>
              {days} วัน {hours} ชม.
            </p>
          </Description>
        </Col>
      </Row>
    </RightBox>
  )

  return (
    <CardContent>
      <Row type="flex" align="middle">
        <Col span={12}>{leftContent}</Col>
        <Col span={12}>{rightContent}</Col>
      </Row>
    </CardContent>
  )
}

const CardContent = styled.div`
  border-radius: 10px;
  background-color: #fff;
  margin-bottom: 12px;
`

const LeftBox = styled.div`
  padding: 20px;
  border-right: 1px solid #e8ebf0;
`

const RightBox = styled.div`
  padding: 20px;
`

const TxtBold = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 20px;
  color #000;
`

const TxtReg = styled.div`
  font-family: 'DB Helvethaica X 55 Regular';
  font-size: 20px;
  color #000;
`

const Score = styled.div`
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 24px;
  &.pass {
    color: #5bc79e;
  }
  &.fail {
    color: #d0494c;
  }
  p {
    line-height: 1.1;
    margin-bottom: 1px;
  }
`

const Description = styled.div`
  font-family: 'DB Helvethaica X 55 Regular';
  font-size: 20px;
  color: #000;
  &.fail {
    color: #d0494c;
  }

  p {
    line-height: 1.1;
    margin-bottom: 1px;
  }
`

export default ReturnCard
