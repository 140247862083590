import React from 'react'
import styled from 'styled-components'
import { Row, Col, Layout } from 'antd'
import { PrimaryButton } from 'Components/Shared/Button'
import DefectScore from './DefectScore'
import { UnitDefectType } from 'features/report/unit/types'
import { DefectStatus } from 'features/types/status'
const { Footer } = Layout

type Props = {
  onDelete: () => void
  onSave: () => void
  unitDefect: UnitDefectType
  addMode?: boolean
}

const AddDefectFooter: React.FC<Props> = ({
  onDelete,
  onSave,
  unitDefect,
  addMode = true,
}) => {
  const jobEmpty = unitDefect.JobTypeID <= 0
  const subjobEmpty = unitDefect.SubJobTypeID <= 0
  const detailEmpty =
    unitDefect.PinStatus === DefectStatus.GOODWORK
      ? false
      : unitDefect.DetailID <= 0
  const locationEmpty = unitDefect.LocationID <= 0
  const beforeImgEmpty = unitDefect.ImageBefore === undefined

  const disableSave =
    jobEmpty || subjobEmpty || detailEmpty || locationEmpty || beforeImgEmpty

  const score = (
    <ScoreArea>
      <Row type="flex" align="middle">
        <Col>
          <Score>
            <DefectScore
              pinStatus={unitDefect.PinStatus}
              pinScore={unitDefect.Score}
            />
          </Score>
        </Col>
        <Col>
          <Desc>คะแนน</Desc>
        </Col>
      </Row>
    </ScoreArea>
  )

  return (
    <Footer className="detail-page-footer">
      <FooterContent>
        <Row type="flex" justify="space-between" align="middle">
          <Col>
            {addMode && (
              <Button>
                <PrimaryButton text="ลบ" onClick={onDelete} btnType="Cancel" />
              </Button>
            )}
          </Col>
          <Col>
            <Row type="flex" align="middle">
              <Col>{score}</Col>
              <Col>
                <Button>
                  <PrimaryButton
                    text="บันทึก"
                    disabled={disableSave}
                    onClick={onSave}
                  />
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </FooterContent>
    </Footer>
  )
}

const FooterContent = styled.div`
  max-width: 1110px;
  margin: 0 auto;
  color: #000;
  font-size: 20px;
  padding-right: 20px;
`

const Button = styled.div`
  width: 160px;
`

const ScoreArea = styled.div`
  margin-right: 32px;
`

const Desc = styled.span`
  font-family: 'DB Helvethaica X 55 Regular';
  font-size: 18px;
`

const Score = styled.span`
  font-size: 44px;
`

export default AddDefectFooter
