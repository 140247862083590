import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'antd'
import { PrimaryButton } from 'Components/Shared/Button'

type Props = {
  onClickReject: () => void
  onClickApprove: () => void
  isLoading?: boolean
}

const ApprovalFooter: React.FC<Props> = ({
  onClickReject,
  onClickApprove,
  isLoading = false,
}) => {
  return (
    <FooterContent>
      <Row type="flex" justify="space-between" align="middle">
        <Col>
          <div className="btn-submit">
            <PrimaryButton
              text="ไม่อนุมัติ"
              disabled={isLoading}
              onClick={onClickReject}
              btnType="Cancel"
            />
          </div>
        </Col>
        <Col>
          <div className="btn-submit">
            <PrimaryButton
              text="อนุมัติ"
              disabled={isLoading}
              onClick={onClickApprove}
              btnType="Approve"
            />
          </div>
        </Col>
      </Row>
    </FooterContent>
  )
}

const FooterContent = styled.div`
  max-width: 1110px;
  margin: 0 auto;
  color: #000;
  font-size: 20px;
  padding-right: 20px;

  .btn-submit {
    width: 160px;
  }
`

export default ApprovalFooter
