import React from 'react'
import styled from 'styled-components'
import { DropdownList } from 'Components/Shared/Form'

type Props = {
  onFilterChange: (filter: any) => void
  filter: any
}

const CheckNumberFilter: React.FunctionComponent<Props> = props => {
  const { onFilterChange, filter } = props

  const renderCheckNumber = () => {
    let checkNo = [] as any[]
    for (let index = 0; index <= 20; index++) {
      if (index === 0) {
        checkNo.push({
          name: `ทั้งหมด`,
          value: index,
        })
      } else {
        checkNo.push({
          name: `ตรวจครั้งที่ ${index}`,
          value: index,
        })
      }
    }

    return checkNo
  }

  return (
    <Box>
      <DropdownList
        label="ตรวจครั้งที่"
        value={filter.CheckNumber}
        menu={renderCheckNumber()}
        onChange={(value: any) => {
          filter.CheckNumber = value
          onFilterChange(filter)
        }}
      />
    </Box>
  )
}

const Box = styled.div`
  padding: 0 12px 12px 0;
`

export default CheckNumberFilter
