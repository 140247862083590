import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { DropdownList } from 'Components/Shared/Form'
import { User } from 'features/admin/user/types'
import { cloneDeep } from 'lodash'
import { hasText } from 'utils/getText'

type Props = {
  onFilterChange: (filter: any) => void
  filter: any
  users: User[]
}

const UserFilter: React.FunctionComponent<Props> = props => {
  const { onFilterChange, filter, users } = props
  const [searchTxt, setSearchTxt] = useState('')
  const [userFilter, setUserFilter] = useState([] as User[])

  useEffect(() => {
    setUserFilter(cloneDeep(users))
  }, [users])

  useEffect(() => {
    filterUser()
  }, [searchTxt])

  const filterUser = () => {
    let list = cloneDeep(users)
    if (searchTxt !== '') {
      list = list.filter((item: User) => {
        return (
          hasText(item.UserNameTH, searchTxt) &&
          item.RoleID === filter.UserRoleID
        )
      })
    }
    setUserFilter(list)
  }

  const renderUser = () => {
    let data = [] as any[]

    userFilter.map(user => {
      if (user.RoleID !== 0 && user.RoleID === filter.UserRoleID) {
        data.push({
          name: user.UserNameTH,
          value: user.UserID,
        })
      }
    })

    if (data.length > 0) {
      data.unshift({
        name: `ทั้งหมด`,
        value: '',
      })
    }

    return data
  }

  return (
    <Box>
      <DropdownList
        label="ผู้ตรวจ"
        value={filter.UserID}
        menu={renderUser()}
        disabled={filter.UserRoleID <= 0}
        onChange={(value: any) => {
          filter.UserID = value
          onFilterChange(filter)
        }}
        hasSearch={true}
        onSearch={setSearchTxt}
      />
    </Box>
  )
}

const Box = styled.div`
  padding: 0 12px 12px 0;
`

export default UserFilter
