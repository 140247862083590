import { sortingList } from '../../../utils/helper'
import { DataItem } from './types'

export const sortingActivityList = (
  list: DataItem[],
  type: string,
  name: string | undefined
): DataItem[] => {
  try {
    let newList: DataItem[] = list
    switch (name) {
      case 'BaseScore':
        newList = sortingList(list, type, name, 'number')
        break
      default:
        newList = sortingList(list, type, name || '', 'string')
    }

    return newList
  } catch (e) {
    return list
  }
}

export const formatData = (list: DataItem[]): DataItem[] => {
  let newList = [] as any
  list.forEach(item => {
    let qcType = [] as any
    item.ResultList.forEach(item => {
      qcType[item.QCTypeName] = item.NumOfChecked
    })
    let data = {
      ...objectWithoutKey(item, 'ResultList'),
      ...qcType,
    }
    newList.push(data)
  })
  return newList
}

const objectWithoutKey = (object: any, key: any) => {
  const { [key]: deletedKey, ...otherKeys } = object
  return otherKeys
}
