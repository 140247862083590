import React from 'react'
import styled from 'styled-components'
import { Popover } from 'antd'

import sticker from 'assets/img/ic-sticker.png'
import correct from 'assets/img/ic-correct.svg'
import uncorrect from 'assets/img/ic-uncorrect.svg'
import arrowA from 'assets/img/ic-arrow-1.svg'
import arrowB from 'assets/img/ic-arrow-2.svg'
import arrowC from 'assets/img/ic-arrow-3.svg'
import arrowD from 'assets/img/ic-arrow-4.svg'

type Props = {
  onChange: (val: string) => void
}

const StickerToolMenu: React.ComponentType<Props> = props => {
  const content = (
    <PageMenu>
      <Item src={correct} alt="" onClick={() => props.onChange(correct)} />
      <Item src={uncorrect} alt="" onClick={() => props.onChange(uncorrect)} />
      <Item src={arrowA} alt="" onClick={() => props.onChange(arrowA)} />
      <Item src={arrowB} alt="" onClick={() => props.onChange(arrowB)} />
      <Item src={arrowC} alt="" onClick={() => props.onChange(arrowC)} />
      <Item src={arrowD} alt="" onClick={() => props.onChange(arrowD)} />
    </PageMenu>
  )
  return (
    <Popover overlayClassName="tools-popup-menu" content={content}>
      <BtnItem src={sticker} alt="" />
    </Popover>
  )
}

const PageMenu = styled.div`
  padding: 21px 24px;
  height: 80px;
  background-color: #4e4f50;
  border-radius: 5px;
  display: flex;
  align-items: center;

  img {
    margin-left: 16px;
  }

  img:first-child {
    margin-left: 0px;
  }
`

const Item = styled.img`
  width: 24px;
  height: 24px;

  :hover {
    cursor: pointer;
  }
`

const BtnItem = styled.img`
  width: 24px;
  height: 24px;

  :hover {
    cursor: pointer;
  }
`

export default StickerToolMenu
