import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { InputSearch } from 'Components/Shared/Input'
import { CheckedIcon, LoadingIcon } from 'Components/Shared/Icon'
import { connect, useDispatch } from 'react-redux'
import RootState from 'typings/RootState'
import {
  serviceTokenSelector,
  userEmailSelector,
} from 'features/sensitive/sensitive.selector'
import { getDataList } from 'features/admin/position/api'
import { selectDataList, selectEvent } from 'features/admin/position/selector'
import { GetDataListReq, DataItem } from 'features/admin/position/types'
import { cloneDeep } from 'lodash'
import { Row, Col } from 'antd'
import { DataItem as UserItem } from 'features/admin/user/types'

type Props = {
  onSave: (user: UserItem) => void
  user: UserItem
  isMasterPlan?: boolean
  currentVendorCode?: string
} & ReduxProps

const SowBox: React.ComponentType<Props> = ({
  onSave,
  token,
  UserEmail,
  event,
  isMasterPlan = false,
  dataList,
  user,
  currentVendorCode = '',
}) => {
  const [filterList, setFilterList] = useState([] as DataItem[])
  const [searchText, setSearchText] = useState('')

  const dispatch = useDispatch()

  useEffect(() => {
    onGetList()
  }, [isMasterPlan])

  useEffect(() => {
    if (currentVendorCode === '') return
    onClear()
  }, [currentVendorCode])

  useEffect(() => {
    onFilter()
  }, [searchText])

  useEffect(() => {
    setFilterList(filterVendor(cloneDeep(dataList.List)))
  }, [dataList, currentVendorCode])

  const filterVendor = (list: DataItem[]) => {
    if (currentVendorCode) {
      return list.filter(item => item.VendorCode === currentVendorCode)
    } else {
      return list
    }
  }

  const onGetList = () => {
    const req: GetDataListReq = {
      UserEmail,
      IsMasterPlan: isMasterPlan,
      Page: 1,
      RowPerPage: 1000,
    }
    dispatch(getDataList(token, req))
  }

  const hasText = (data: string, searchTxt: string) => {
    if (data && searchTxt) {
      return data.toLocaleLowerCase().includes(searchTxt.toLocaleLowerCase())
    }
    return false
  }

  const onFilter = () => {
    let list = filterVendor(cloneDeep(dataList.List))
    if (searchText !== '') {
      list = list.filter(item => {
        return (
          hasText(item.PositionName, searchText) ||
          hasText(item.VendorName, searchText)
        )
      })
    }
    setFilterList(list)
  }

  const onClear = () => {
    setSearchText('')
    user.PositionID = 0
    user.PositionName = ''
    onSave(user)
  }

  const onSelectSow = (sow: DataItem) => {
    user.PositionID = sow.PositionID
    user.PositionName = sow.PositionName
    onSave(user)
  }

  const isSelected = (positionID: number) => {
    const data = filterList.find(item => item.PositionID === positionID)
    const result = data ? true : false
    // if (!result && positionID > 0) {
    //   user.PositionID = 0
    //   user.PositionName = ''
    //   onSave(user)
    // }
    return result
  }

  const selectedBox = (
    <UserSelected>
      <Row
        type="flex"
        justify="space-between"
        align="middle"
        style={{ height: '48px' }}
      >
        <Col className="ellipsis" span={21}>
          <Icon>
            <CheckedIcon />
          </Icon>
          <Text>{user.PositionName}</Text>
        </Col>
        <Col span={3}>
          <TextCancle onClick={onClear}>ยกเลิก</TextCancle>
        </Col>
      </Row>
    </UserSelected>
  )

  const renderDataFormat = (item: DataItem) => {
    let content = `${item.PositionName}`
    return content
  }

  const renderSearchContent = (data: DataItem[]) => {
    const loading = (
      <ContentBody>
        <LoadingIcon />
      </ContentBody>
    )
    const empty = (
      <ContentBody>
        <Txt>ไม่มีข้อมูล</Txt>
      </ContentBody>
    )

    const content =
      data.length > 0 ? (
        <SearchContentArea>
          {filterList.map((item, index) => {
            const data = renderDataFormat(item)
            return (
              <SearchItem key={index} onClick={() => onSelectSow(item)}>
                {data}
              </SearchItem>
            )
          })}
        </SearchContentArea>
      ) : (
        empty
      )

    return event.IsLoading ? loading : content
  }

  return isSelected(user.PositionID) ? (
    selectedBox
  ) : (
    <InputSearch
      placeholder="ค้นหา SOW"
      value={searchText}
      onChange={(val: string) => setSearchText(val)}
      showSuggess={true}
      searchContent={renderSearchContent(dataList.List)}
    />
  )
}

const SearchContentArea = styled.div`
  max-height: 192px;
  overflow: scroll;
`

const SearchItem = styled.div`
  padding: 8px 24px;
  font-size: 20px;
  font-family: 'DB Helvethaica X 55 Regular';
  &:hover {
    background-color: #e6f7ff;
    cursor: pointer;
  }
`

const ContentBody = styled.div`
  width: 100%;
  text-align: center;
  padding: 70px 0;
  height: 192px;
`

const Txt = styled.div`
  font-size: 20px;
  font-family: 'DB Helvethaica X 55 Regular';
`

const UserSelected = styled.div`
  height: 48px;
  border-radius: 5px;
  border: solid 1px #eeeff2;
  background-color: #fff;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.03);
  padding: 0 16px;
  .ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

const Text = styled.span`
  font-size: 20px;
  font-family: 'DB Helvethaica X 55 Regular';
`

const TextCancle = styled.span`
  font-size: 20px;
  font-family: 'DB Helvethaica X 55 Regular';
  color: #d0494c;
  cursor: pointer;
`

const Icon = styled.div`
  margin-right: 16px;
  float: left;
`

const mapStateToProps = (state: RootState) => {
  return {
    token: serviceTokenSelector(state),
    UserEmail: userEmailSelector(state),
    event: selectEvent(state),
    dataList: selectDataList(state),
  }
}

type ReduxProps = ReturnType<typeof mapStateToProps>

const mapDispatchToProps = {}

type DispatchProps = typeof mapDispatchToProps

export default connect(mapStateToProps, mapDispatchToProps)(SowBox)
