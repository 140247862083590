import React, { useState } from 'react'
import styled from 'styled-components'
import { Picker } from 'Components/Shared/Form'
import { Row, Col, Modal } from 'antd'
import { cloneDeep } from 'lodash'
import imgDefault from 'assets/img/empty-status-image.png'
import file from 'utils/file'
import { LIMIT_FILE } from 'config'
import { CloseIcon } from 'Components/Shared/Icon'
import { SelectedFloorPlan } from 'features/admin/floorPlan/types'

type Props = {
  item: SelectedFloorPlan
  onChangeData: (val: SelectedFloorPlan) => void
}

const ImagePlan = {
  ActualFileName: '',
  FileID: 0,
  FileName: '',
  FileSize: 0,
  FileUrl: '',
}

const ImageSetting: React.ComponentType<Props> = props => {
  const { item, onChangeData } = props
  const [showImagePreview, setShowImagePreview] = useState(false)
  const [imagePreview, setImagePreview] = useState('')
  const isUsed = item.Status.toLocaleLowerCase() === 'used'

  const setImageForUnit = (base64: any, fileItem: any) => {
    item.ImageFloorPlan = {
      ActualFileName: base64,
      FileName: fileItem,
      FileID: 0,
      FileSize: 0,
      FileUrl: '',
    }
    onChangeData(item)
  }

  const addFile = async (base64: any, fileItem: any) => {
    try {
      let resp
      if (fileItem.type === 'image/heic') {
        resp = await file.heicToJpeg({
          file: fileItem,
          filename: fileItem.name,
        })
        base64 = resp.text
        fileItem = resp.file
      }

      if (fileItem.size < LIMIT_FILE.image) {
        resp = await file.compress({
          value: base64,
          file: fileItem,
          limit: LIMIT_FILE.image,
        })
        base64 = resp.text
        fileItem = resp.file
      }
      setImageForUnit(base64, fileItem)
    } catch (e) {}
  }

  const getUrlImage = () => {
    const ImageFloorPlan = item.ImageFloorPlan || cloneDeep(ImagePlan)
    const { FileUrl, ActualFileName } = ImageFloorPlan
    return FileUrl !== '' ? FileUrl : ActualFileName
  }

  const onClickPreview = (imgUrl: any) => {
    if (!imgUrl) return
    setImagePreview(imgUrl)
    setShowImagePreview(true)
  }

  const imageUrl = getUrlImage()
  const closeIcon = <CloseIcon color="#8b99a7" size={24} />
  return (
    <Row>
      <Col span={24}>
        <ModelSetting>
          <HeaderSection>
            <Row type="flex" justify="space-between" align="middle">
              <Col>
                <Title>รูป</Title>
              </Col>
              <Col>
                <Description onClick={() => onClickPreview(imageUrl)}>
                  คลิกเพื่อดูตัวอย่างการเลือกรูปภาพ
                </Description>
              </Col>
            </Row>
          </HeaderSection>
          <Row type="flex" align="top">
            <Col span={13}>
              <ImgSetting onClick={() => onClickPreview(imageUrl)}>
                <img src={imageUrl ? imageUrl : imgDefault} alt="" />
              </ImgSetting>
            </Col>
            <Col span={11}>
              <SetImageBtn>
                <Picker
                  loading={false}
                  disabled={isUsed}
                  text={'ADA'}
                  accept=".png,.jpg,.heic"
                  onChange={addFile}
                  customPickerBtn={
                    <AddImage>
                      {imageUrl ? 'เปลี่ยนรูปภาพ' : 'อัพโหลดรูปภาพ'}
                    </AddImage>
                  }
                />
              </SetImageBtn>
              <Modal
                className="modal-preview"
                centered
                visible={showImagePreview}
                footer={null}
                onCancel={() => setShowImagePreview(false)}
                closeIcon={closeIcon}
              >
                <img
                  alt="example"
                  style={{ width: '100%' }}
                  src={imagePreview}
                />
              </Modal>
            </Col>
          </Row>
        </ModelSetting>
      </Col>
    </Row>
  )
}

const ImgSetting = styled.div`
  width: 184px;
  height: 136px;
  border-radius: 8px;
  border: solid 1px #979797;
  background-color: #ffffff;
  img {
    border-radius: 8px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &:hover {
    cursor: pointer;
  }
`

const Description = styled.span`
  font-size: 18px;
  color: #003168;
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 20px;
  text-align: center;
  margin: 10px 0;
  text-decoration: underline;
  cursor: pointer;
`

const ModelSetting = styled.div`
  background-color: rgba(139, 153, 167, 0.1);
  margin-top: 25px;
  padding: 25px;
`

const Title = styled.div`
  color: black;
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 28px;
`

const HeaderSection = styled.div`
  margin-bottom: 12px;
`

const AddImage = styled.div`
  width: 100%;
  padding: 10px 0;
  border-radius: 5px;
  border: solid 1.5px #003168;
  font-family: 'DB Helvethaica X 75 Bd';
  color: #003168;
  font-size: 20px;
  text-align: center;
`

const SetImageBtn = styled.div`
  background-color: white;
  margin-top: 25px;
`

export default ImageSetting
