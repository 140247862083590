import React, { useState, useEffect } from 'react'
import { Modal, Row, Col } from 'antd'
import styled from 'styled-components'
import { SecondaryButton, PrimaryButton } from 'Components/Shared/Button'
import { InputText } from 'Components/Shared/Input'

type Props = {
  onClose: () => void
  onSubmit: (value: string) => void
  onChange?: (value: string) => void
  visible?: boolean
  title?: string
  placeholder?: string
  value?: string
  warningMessage?: string
  prefix?: string
}

const AddModal = (props: Props) => {
  const {
    visible = false,
    onClose,
    onSubmit,
    onChange = () => {},
    title = '',
    placeholder = '',
    value = '',
    warningMessage = '',
    prefix = 'ชื่อ',
  } = props
  const [name, setName] = useState('')

  useEffect(() => {
    setName(value)
  }, [visible])

  return (
    <Modal
      className="add-modal"
      title={false}
      centered
      visible={visible}
      footer={false}
      closable={false}
    >
      <Body>
        <Header>{title}</Header>
        <Input>
          <InputText
            prefix={prefix}
            placeholder={placeholder}
            onChange={val => {
              setName(val)
              onChange(val)
            }}
            value={name}
            openWithFocus={true}
          />
        </Input>
        {warningMessage && <Warning>{warningMessage}</Warning>}
        <ButtonSection>
          <Row type="flex" justify="end">
            <Col>
              <div className="btn-submit">
                <SecondaryButton
                  text="ยกเลิก"
                  disabled={false}
                  onClick={() => onClose()}
                />
              </div>
            </Col>
            <Col>
              <div className="btn-submit">
                <PrimaryButton
                  text="บันทึก"
                  disabled={name === '' || warningMessage !== ''}
                  onClick={() => onSubmit(name)}
                />
              </div>
            </Col>
          </Row>
        </ButtonSection>
      </Body>
    </Modal>
  )
}

const Header = styled.div`
  text-align: left;
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 24px;
  color: #000;
`

const Body = styled.div``

const Input = styled.div`
  margin-top: 10px;
`

const Warning = styled.div`
  margin-top: 5px;
  font-family: 'DB Helvethaica X 75 Bd';
  font-size: 18px;
  color: #d0494c;
  width: 100%;
  text-align: right;
`

const ButtonSection = styled.div`
  margin-top: 32px;
  .btn-submit {
    width: 132px;
    margin-left: 16px;
  }
`

export default AddModal
