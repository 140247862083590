import React from 'react'
import { HeaderRow } from 'Components/Shared/Table'

type Props = {
  onSort(name: string): void
  sorting: any
}

const TableHeader: React.FunctionComponent<Props> = props => {
  const { onSort, sorting } = props

  const header = [
    {
      title: 'โซน',
      span: 8,
      key: 'Unit',
    },
    {
      title: 'ชั้น',
      span: 8,
      key: 'Floor',
    },
    {
      title: 'รอจัดกลุ่ม',
      span: 8,
      key: 'TotalDefect',
    },
  ]

  return (
    <HeaderRow onSort={key => onSort(key)} sorting={sorting} items={header} />
  )
}

export default TableHeader
