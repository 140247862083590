import React, { useState } from 'react'
import { Layout } from 'antd'
import styled from 'styled-components'
import Breadcrumbs from './Breadcrumbs'
import {
  TaskStatus,
  MainConChecklist,
  ApprovalFooter,
  RejectModal,
  MCTaskDetail,
  ApproveModal,
} from 'Components/Activity'
import { MainconCheckList } from 'features/types/checklist'
import { MainconTask, CurrentActivity } from 'features/maincon/activity/types'

type Props = {
  onImageOpen(list?: any[], readOnly?: boolean): void
  onDocumentOpen(list?: any[], readOnly?: boolean): void
  onThumbnailOpen(index: number, list?: any[], readOnly?: boolean): void
  originalData: MainconTask
  currentData: CurrentActivity
  checkListData: MainconCheckList
  onUpdateCurrent: (val: any) => void
  onHistoryOpen?: () => void
  onApproveTask: () => void
  onRejectTask: (remark: string) => void
  isLoading?: boolean
  wbs?: any
}

const { Content, Footer } = Layout

const ActivityDetail: React.FunctionComponent<Props> = ({
  onImageOpen,
  onDocumentOpen,
  onThumbnailOpen,
  originalData,
  currentData,
  checkListData,
  onUpdateCurrent,
  onHistoryOpen,
  onApproveTask,
  onRejectTask,
  isLoading = false,
  wbs,
}) => {
  const [showRejectModal, setShowRejectModal] = useState(false)
  const [showApproveModal, setShowApproveModal] = useState(false)
  const isApproval =
    originalData.State === 'PJPO' && originalData.TaskStatus === 'INIT'
  const readOnly = true
  const images = originalData.McImages
  const documents = originalData.McDocuments

  const onSubmitReject = (val: string) => {
    onRejectTask(val)
    setShowRejectModal(false)
  }

  const onSubmitApprove = () => {
    onApproveTask()
    setShowApproveModal(false)
  }


  const rejectModal = (
    <RejectModal
      visible={showRejectModal}
      onClose={() => setShowRejectModal(false)}
      onSubmit={(val: string) => onSubmitReject(val)}
      title="ไม่อนุมัติ"
      placeholder="เหตุผลที่ไม่อนุมัติ"
    />
  )

  const approveModal = (
    <ApproveModal
      visible={showApproveModal}
      onClose={() => setShowApproveModal(false)}
      onSubmit={() => onSubmitApprove()}
      title="คุณต้องการอนุมัติงานนี้ใช่ไหม"
    />
  )

  return (
    <>
      <Breadcrumbs activity={originalData} />
      <Content className="mid-content">
        <div className="main-content activity-detail">
          <ActivityDetailArea>
            <MCTaskDetail
              currentState="PJPO"
              isSubmitting={false}
              onImageOpen={() => onImageOpen(images, readOnly)}
              onDocumentOpen={() => onDocumentOpen(documents, readOnly)}
              onThumbnailOpen={(index: number) =>
                onThumbnailOpen(index, images, readOnly)
              }
              onChange={onUpdateCurrent}
              originalData={originalData}
              currentData={currentData}
              onHistoryOpen={onHistoryOpen}
              readOnly={readOnly}
              wbs={wbs}
            />
            <TaskStatus
              originalTask={originalData}
              onDocumentOpen={onDocumentOpen}
              onImageOpen={onImageOpen}
              onThumbnailOpen={onThumbnailOpen}
            />
            <MainConChecklist checkListData={checkListData} />
          </ActivityDetailArea>
        </div>
      </Content>
      {isApproval && (
        <Footer className="detail-page-footer">
          <ApprovalFooter
            onClickApprove={() => setShowApproveModal(true)}
            onClickReject={() => setShowRejectModal(true)}
            isLoading={isLoading}
          />
        </Footer>
      )}
      {rejectModal}
      {approveModal}
    </>
  )
}

const ActivityDetailArea = styled.div`
  .box-section {
    background-color: #fff;
    margin-top: 12px;
    border-radius: 5px;
    border: solid 1px #f2f2f2;
    padding: 24px;
    line-height: normal;
    &.no-space {
      padding: 0;
      margin-top: 0px;
    }
    .title {
      font-size: 20px;
      font-family: 'DB Helvethaica X 75 Bd';
    }
    .description {
      font-size: 20px;
    }
  }
  .space {
    margin-top: 24px;
  }
`

export default ActivityDetail
